/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, trueish, format_information */
(function( $ ){
	var callersWaitingWidget = $.extend({}, $.ui.widget.prototype, CUI.htmlEntityClass, CUI.bubbleInfoClass, {

		options: {
			accept_data_from_parent: true
		},

		_beforeInit: function () {
			var self = this;
			var $self = this.element;

			$self.addClass('callersWaitingWidgetType');

			self._buildHTML();

			self.options.$tr = $self.closest('tr');

			self._bubbleInfoInit('div.holdtime', 'div.holdtime');

			//	    debugLog("Callers Waiting Widget Constructed.");
			return false;
		},

		_buildHTML: function() {
			var self = this;
			var $self = this.element;

			var frag = document.createDocumentFragment();

			self.options.elem = {};

			self.options.elem.queue_name = document.createElement('div');
			self.options.elem.queue_name.className = 'queue_name';
			frag.appendChild(self.options.elem.queue_name);

			self.options.elem.holdtime = document.createElement('div');
			self.options.elem.holdtime.className = 'holdtime';
			frag.appendChild(self.options.elem.holdtime);

			self.options.elem.queue_br = document.createElement('br');
			frag.appendChild(self.options.elem.queue_br);

			self.options.elem.cid_name = document.createElement('div');
			self.options.elem.cid_name.className = 'caller_id_name';
			frag.appendChild(self.options.elem.cid_name);

			self.options.elem.cid_number = document.createElement('div');
			self.options.elem.cid_number.className = 'caller_id_number';
			frag.appendChild(self.options.elem.cid_number);

			self.options.elem.caller_br = document.createElement('br');
			frag.appendChild(self.options.elem.caller_br);

			self.options.elem.callee_id_name = document.createElement('div');
			self.options.elem.callee_id_name.className = 'callee_id_name';
			frag.appendChild(self.options.elem.callee_id_name);

			self.options.elem.callee_id_number = document.createElement('div');
			self.options.elem.callee_id_number.className = 'callee_id_number';
			frag.appendChild(self.options.elem.callee_id_number);

			$self.append(frag);
		},

		_destroy: function() {
			var self = this;
			var $self = this.element;

			if (self.options.$tr && self.options.$tr[0]) {
				self.options.$tr[0] = null;
			}
			if (self.options.$tr) {
				delete self.options.$tr;
			}

			for (var e in self.options.elem) {
				delete self.options.elem[e];
			}

			self._bubbleInfoDestroy();
			self._htmlEntityDestroy();
			//	    debugLog("Callers Waiting Widget Destroyed.");
		},

		fillData: function(data, from_self) {
			var self = this;
			var $self = this.element;
			var num_regexp = /\d+/;

			//debugLog("Callers Waiting Widget Filled:", $.extend({}, data));
			self.options.server_start_time = parseInt(data.timestamp);
			self.options.server_current_time = parseInt(data.current_time);
			self.options.start_time = new Date().getTime() / 1000;
			self.options.warning_seconds = data.bbx_queue_warning_seconds;
			self.options.critical_seconds = data.bbx_queue_critical_seconds;

			if (data.caller_caller_id_name) {
				data.caller_caller_id_name = data.caller_caller_id_name.replace(/^0+$/g, 'Unknown');
				data.caller_caller_id_name = data.caller_caller_id_name.replace(/^$/g, 'Unknown');
				data.caller_caller_id_name = data.caller_caller_id_name.replace(/\([^\)]+\)\s*/g, '');
				data.caller_caller_id_name = CUI.formatter.doFormat({ cid: data.caller_caller_id_name }, { format: { field: '@cid', formatter: 'cid_case' }});
			}
			if (data.caller_caller_id_number) {
				data.caller_caller_id_number = data.caller_caller_id_number.replace(/^0+$/g, 'Uknown');
				data.caller_caller_id_number = data.caller_caller_id_number.replace(/^$/g, 'Uknown');
				data.caller_caller_id_number = format_information(data.caller_caller_id_number, { no_auto_split: true });
			}
			if (data.callee_id_number) {
				data.callee_id_number = data.callee_id_number.replace(/^0+$/g, 'Unknown');
				data.callee_id_number = data.callee_id_number.replace(/^$/g, 'Unknown');
				data.callee_id_number = format_information(data.callee_id_number, { no_auto_split: true });
			}

			if (data.caller_caller_id_name == data.caller_caller_id_number ||
				(num_regexp.test(data.caller_caller_id_number) && data.caller_caller_id_name == 'Unknown'))
			{
				data.caller_caller_id_name = '';
			}

			CUI.quickSetText(self.options.elem.queue_name, data.bbx_queue_name);
			CUI.quickSetText(self.options.elem.cid_name, data.caller_caller_id_name);
			CUI.quickSetText(self.options.elem.cid_number, data.caller_caller_id_number);

			if (data.callee_id_name || data.callee_id_number) {
				if (data.callee_id_name) {
					data.callee_id_name = CUI.formatter.doFormat({ cid: data.callee_id_name }, { format: { field: '@cid', formatter: 'cid_case' }});
				}

				CUI.quickSetText(self.options.elem.callee_id_name, data.callee_id_name);
				CUI.quickSetText(self.options.elem.callee_id_number, data.callee_id_number);
				CUI.quickShow(self.options.elem.callee_id_name);
				CUI.quickShow(self.options.elem.callee_id_number);
				self.options.$tr.addClass('removed');
			} else {
				self.options.$tr.removeClass('removed');
				CUI.quickHide(self.options.elem.callee_id_name);
				CUI.quickHide(self.options.elem.callee_id_number);
			}

			if (trueish(data.deleted) || trueish(data.delete_delay)) {
				if (!data.callee_id_name && !data.callee_id_number && !$self.find(".abandoned")[0]) {
					$self.append("<div class='abandoned'>Abandoned</div>");
				}
			} else {
				$self.find('.abandoned').remove();
			}

			if (self.options.server_start_time > 0 && !(data.callee_id_name || data.callee_id_number)) {
				self._setCallTimeTimeout('.holdtime');
			} else {
				self._removeCallTimeTimeout();
				if (self.options.server_start_time > 0) {
					self._realUpdateCallTime();
				}
			}
		}

	});    
	add_widget('callersWaitingWidget', callersWaitingWidget);
})(jQuery);
