/* jshint jquery: true, browser: true */
/* global CUI, debugLog, getUnique */
/*
    * The addMessage function:
      addMessage(JQUERY $target, STRING type, STRING message_id, STRING message, OBJECT options { temporary: INTEGER msec });
    * General versus control-specific messages
    * Displaying and Clearing Messages
      - Custom error display on widgets: widget.customAddMessage / widget.customDelMessage
*/

(function () {

	var errorMessagesMixin = {
		addMessage: function($target /* May not be passed as a jQuery object, but is converted to one immediately */, type, message_id, mesg, options) {
			var $self = this.element;
			var self = this;
			var $messages, $messages_template, widgets, targeted = true;

			mesg = (mesg === undefined) ? 'This value is not valid.' : mesg;

			// Handle some messages that come in as an array of errors
			if ($.isArray(mesg)) {
				mesg = mesg[0];
			}

			options = options || {};

			if (typeof $target === 'string') {
				$target = $self.find('[name=' + $target + '], [ident=' + $target + '], [value-from=' + $target + ']').eq(0);
			}

			if (!$target || !$target[0]) {
				targeted = false;
				$target = $self;
			} else if (!($target instanceof jQuery)) {
				$target = $($target);
			}

			//
			// Specify an alternative message container for a given control. This is useful when you don't want error messages breaking a line, like:
			//
			//    [Textbox___][Select  \/ ]
			//    [DIV: Error messages for the textbox should show here, not after the textbox ]
			//
			// Apply an attribute of...
			//   alt-message-container="<direction> <selector>"
			// where <direction> is "next" or "prev", and <selector> is a jQuery selector. For example:
			//   alt-message-container="next .alt-messages" -- to find the next instance of the "alt-messages" class
			//   alt-message-container="prev .alt-messages" -- to find the previous instance of the "alt-messages" class
			//
			// Then, add an element somewhere above or below (as specified) that matches the selector. Note that this method
			// can be intensive the first time an error is displayed, as the whole document is scanned for the selector, so it
			// should only be used when absolutely necessary.
			//

			var alt_target = $target.attr('alt-message-container'), $matching, $alt_target, search_id, reset_id, target_idx;

			if (alt_target && alt_target.indexOf(' ')) {
				alt_target = alt_target.split(' ');
				alt_target = [alt_target.shift(), alt_target.join(' ')];

				search_id = $target.attr('id');

				if (!search_id) {
					search_id = getUnique('error-search-' + self.options.widget_id + '-');
					reset_id = true;
					$target.attr('id', search_id);
				}

				self.options._alt_targets = self.options._alt_targets || {};

				if (self.options._alt_targets[search_id] && self.options._alt_targets[search_id].closest('body')) {
					$target = self.options._alt_targets[search_id];
				} else {
					// Find all items matching the selector *or* the current target (by id). Get the index of the current target.
					$matching = $('#' + search_id + ', ' + alt_target[1]);
					target_idx = $matching.index($target);

					switch (alt_target[0]) {
						case 'prev':
							if (target_idx > 0) {
								$alt_target = $matching.eq(target_idx - 1);
							}
							break;
						// Intentional Fallthrough
						// jshint -W086
						case 'next':
						default:
						// jshint +W086
							if ($matching.length > target_idx + 1) {
								$alt_target = $matching.eq(target_idx + 1);
							}
							break;
					}

					if ($alt_target) {
						$target = $alt_target;
						self.options._alt_targets[search_id] = $alt_target;
					} else {
						debugLog('jquery.formWidget.js: Could not find alt-message-container ', alt_target, ' for ', $target);
					}
				}
			}

			// If any widget on this DOM element implements the "customAddMessage" function, pass control to that widget's function to add the message...
			widgets = $target.getCUIWidgets();
			if (widgets) {
				for (var i=0; i<widgets.length; i++) {
					if (typeof widgets[i].customAddMessage === 'function') {
						self.options._custom_message_widgets[message_id + '-' + type] = widgets[i];
						return widgets[i].customAddMessage.apply(widgets[i], arguments);
					}
				}
			}

			// ...otherwise, "after" in a DIV.

			$messages_template = $('<div class="messages" />');

			if (targeted) {
				$messages = $target.nextAll('.messages');
				var $units = $target.siblings('span.input-units');
				if (!$messages[0]) {
					$messages = $messages_template;
					if ($units[0]) { $units.after($messages); } else { $target.after($messages); }
				}

			} else {
				$messages = $target.children('.messages'); // $target is $self
				if (!$messages[0]) {
					$messages = $messages_template;
					$messages.addClass('generalMessagesformItem');
					$messages.addClass('generalMessages');
					$target.prepend($messages);
				}
			}

			var $existing = $messages.find('#' + message_id + '-' + type);

			if ($existing[0]) {
				$existing.text(mesg);
			} else if (mesg) {
				if (typeof mesg === 'object') {
					if (mesg.text) {
						mesg = mesg.text;
					} else {
						mesg = '(Unknown error)';
					}
				}

				var $new = $('<div>').attr('id', message_id + '-' + type).addClass('messageItem ' + type).text(mesg).hide();

				$messages.append($new);
				$new.slideDown('fast');
			}
		},

		delMessage: function(type, message_id) {
			var $self = this.element;
			var self = this;

			var widget = self.options._custom_message_widgets[message_id + '-' + type];

			if (widget && widget.customDelMessage) {
				return widget.customDelMessage.apply(widget, arguments);
			}

			var $elem = $self.find('#' + message_id + '-' + type);
			$elem.removeAttr('id').slideUp('fast', function () { $elem.remove(); });
		},

		clearMessages: function() {
			var self = this, $self = this.element;

			var $bound_messages = $self.find('.message').find('> *');
			var $general_messages = $self.find('.generalMessages').find('> *');

			$bound_messages.fadeOut(500, function () { $bound_messages.remove(); });
			$bound_messages.slideUp(500, function () { $bound_messages.remove(); });

			$general_messages.fadeOut(500, function () { $general_messages.remove(); });
			$general_messages.slideUp(500, function () { $general_messages.remove(); });

			$self = null;
		}	
	};

	CUI.mixin.register('errorMessages', errorMessagesMixin);

})();
