/* jshint jquery: true, unused: vars */
/* global add_widget */
/* 
   Usage:

   Use the 'closest' + 'find' options together to find an element within a closest parent

   -or-

   Use the 'selector' option to provide an arbitrary jQ selector
*/
(function( $ ){
	var classToggleButtonWidget = $.extend({}, $.ui.widget.prototype, {

		_afterInit: function() {
			var self = this, $self = this.element;

			$self.addClass('classToggleButtonWidgetType');

			self._setupClosures();

			self._bind($self.closest('.pageWidgetType'), 'screenReady', function() {
				$self.on('click', self.options.click_handler_closure);
				self._getElement();

				// Set initial text based on whether the class is there to start with or not
				if (self.options.$elem.hasClass(self.options.css_class)) {
					if (self.options.text_on) {
						$self.text(self.options.text_on);
					}
				} else {
					if (self.options.text_off) {
						$self.text(self.options.text_off);
					}
				}
			});
		},

		_setupClosures: function() {
			var self = this, $self = this.element;

			self.options.click_handler_closure = function(e) {
				self._clickHandler(e);
			};
		},

		_getElement: function() {
			var $self = this.element;
			var self = this;

			if (self.options.closest) {
				self.options.$scope = $self.closest(self.options.closest);
			} else {
				self.options.$scope = $self;
			}

			if (self.options.find) {
				self.options.$elem = self.options.$scope.find(self.options.find);
			}

			if (self.options.selector) {
				self.options.$elem = $(self.options.selector);
			}
		},

		setClassState: function (state) {
			var self = this, $self = this.element;
			if (state) {
				self.options.$elem.addClass(self.options.css_class);
				if (self.options.text_on) {
					$self.text(self.options.text_on);
				}
			} else {
				self.options.$elem.removeClass(self.options.css_class);
				if (self.options.text_off) {
					$self.text(self.options.text_off);
				}
			}

			self.options.$elem.triggerHandler('classChange');
		},

		_clickHandler: function(e) {
			var self = this, $self = this.element;

			e.preventDefault();
			e.stopPropagation();

			self.setClassState(!self.options.$elem.hasClass(self.options.css_class));
		}
	});

	add_widget('classToggleButtonWidget', classToggleButtonWidget);

})(jQuery);
