register_screen('valet_parking_detail', {
    rest: '/gui/extension/valet_parking',
    rest_container: 'valet_parking',
    elements: [
	{ entity_template: 'masthead' },
	{
	    entity_template: 'page_buttons',
	    buttons: [{
		type: 'delete',
		rest: '/gui/extension/valet_parking',
		fields: 'bbx_valet_parking_id key',
		confirm: { text: 'Are you sure you would like to delete this parking block?' }
	    }, { type: 'cancel' }, { type: 'save' }]
	},
	{ entity_template: 'page_content', elements: [
	    {
		entity_template: 'page_module',
		legend: 'Basic Information',
		form_action: '/gui/extension/valet_parking',
		buttons: [ 'help' ],
		help_id: 'parking_basic_information',
		elements: [
		    {
			entity_template: 'input_item',
			title: 'Parking Block Name:',
			input_name: 'bbx_valet_parking_name',
			type: 'text',
			input_options: { attributes: { maxlength: '255' }},
			validate: { conditions: '_TRUEISH_', message: 'Parking Block Name is required.' }
		    },
		    {
			entity_template: 'input_item',
			title: 'Music on Hold:',
			type: 'select',
			input_name: 'bbx_moh_id',
			input_options: {
			    widgets: [ 'selectPopulateWidget' ],
			    widget_options: {
				selectPopulateWidget: {
				    rest: '/gui/moh/class',
				    text_field: 'bbx_moh_name',
				    value_field: 'bbx_moh_id'
				}
			    }
			}
		    },
		    {
			entity_template: 'data_item',
			title: 'Maximum Hold Time:',
			elements: [
			    {
				entity_template: 'multiplied_input',
				attributes: {
				    type: 'text',
				    name: 'bbx_valet_parking_timeout'
				},
				multipliers: [{ text: 'Minutes', multiplier: 60 }, { text: 'Seconds', multiplier: 1 }],
				default_multiplier: 60,
				allow_none: true
			    },
			    {
				entity_template: 'container',
				'class': 'info',
				text: 'Leave this value empty to disable the feature'
			    }
			]
		    },
		    {
			entity_template: 'data_item',
			title: 'After Maximum Hold Time, Transfer To:',
			elements: [
			    {
				entity_templates: ['extension_picker_suggest','aopb_state'],
				extension_picker_suggest: {
				    name: 'bbx_valet_parking_orbit_exten',
				    value_key: 'bbx_extension_value',
				    allow_none: true
				},
				aopb_state: {
				    closest: 'form',
				    states: [{
					conditions: { a: '$bbx_valet_parking_timeout', op: '==', b: '_TRUEISH_' },
					actions: { enabled: true },
					else_actions: { enabled: false, value: null }
				    }]
				}
			    },
			    {
				entity_template: 'container',
				'class': 'info',
				text: 'If this value is blank, the operator is dialed. If the Maximum Hold Time feature is disabled, this value is ignored.'
			    }
			]
		    }
	 	]
	    },
	    {
		entity_template: 'page_module',
		legend: 'Using this Call Parking Extension'
	    },
	    {
		entity_template: 'container',
		entity: 'ul',
		'class': 'bullet-list',
		elements: [
 		    {
			entity: 'li',
			entity_template: 'format',
			format: 'To park callers automatically, attended-transfer callers to $1. You will hear the extension where the caller will be parked.',
			fields: [{ field: '@bbx_extension_value', formatter: 'extension_math', options: { side: 'begin', post_format: true } }]
		    },
		    {
			entity: 'li',
			text: 'Never blind-transfer a caller to the automatic parking extension.'
		    },
 		    {
			entity: 'li',
			entity_template: 'format',
			format: 'To manually park callers, transfer them to an unoccupied extension between $1 and $2.',
			fields: [
			    { field: '@bbx_extension_value', formatter: 'extension_math', options: { side: 'begin', offset: 1, post_format: true } },
			    { field: '@bbx_extension_value', formatter: 'extension_math', options: { side: 'end', offset: -1, post_format: true } }
			]
		    },
 		    {
			entity: 'li',
			entity_template: 'format',
			format: 'To automatically connect to the first waiting caller in this Parking block, call $1. To connect to a specific caller, dial the extension where the caller is parked.',
			fields: [{ field: '@bbx_extension_value', formatter: 'extension_math', options: { side: 'end', post_format: true } }]
		    }
		]
	    }
	]
	}
    ]
});
