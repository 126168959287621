/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/*
  Pulls data from an ancestor and mixes it with current fillData on fillData
  NOTE: This has been customized to work within a DTW, to fit the specific need (User Phone Detail) it was made for. It may no longer work in all cases. - RF
*/

(function( $ ){
	"use strict";
	var ancestorDataMixerWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			closest: '.dataHolderContainerWidget',
			widget: 'dataHolderContainerWidget',
			update_on_target_update: true
		},

		// BUG: fillData is running while the TR that the widget is in is currently detached: BNPH-7276
		// self.options.ready is set on the widget.

		_beforeInit: function () {
			var self = this, $self = this.element, wid = self.options.widget_id;
			$self.closest('tr').on('rowReady.'+wid+' rowReadySearch.'+wid, self._initADM.bind(self));
		},

		_initADM: function (e) {
			var self = this, $self = this.element, wid = self.options.widget_id, $target;
			$target = $self.closest(self.options.closest);

			if (!$target[0]) { return; } // Not actually ready yet
			$self.closest('tr').off('rowReady.'+wid).off('rowReadySearch.'+wid);
			$target.on('fillData.'+wid, self._triggeredFillData.bind(self));
			self.fillData();
		},

		_triggeredFillData: function () {
			this.fillData();
		},

		fillData: function (d, from_self) {
			var self = this, d_out;
			d = d || {};
			d_out = self._mixData(d);
			$.ui.widget.prototype.fillData.call(self, d_out, from_self);
		},

		_mixData: function (d) {
			var self = this, $self = this.element, $c, ad, d_out;
			$c = $self.closest(self.options.closest);
			self.options.data = d_out = $.extend({}, self.options.data || {}, d || {});

			if ($c.length) {
				ad = $c.getCUIWidget(self.options.widget).options.data;
				$.extend(d_out, ad);
			}

			return d_out;
		}
	});

	add_widget('ancestorDataMixerWidget', ancestorDataMixerWidget);
})(jQuery);
