/* jshint jquery: true, unused: vars */
/* global add_widget */
(function( $ ){
	var toggleButtonWidget = $.extend({}, $.ui.widget.prototype, {

		_afterInit: function() {
			var $self = $(this.element);
			var self = this;

			$self.addClass('toggleButtonWidgetType');

			self._setupClosures();

			if ('selector' in self.options) {
				$self.on('click', self.options.click_handler_closure);
			}
		},

		_setupClosures: function() {
			var $self = $(this.element);
			var self = this;

			self.options.click_handler_closure = function(e) {
				self._clickHandler(e);
			};
		},

		_clickHandler: function(e) {
			var self = this, $self = $(this.element), $parent;

			e.preventDefault();
			e.stopPropagation();

			if (self.options.closest) {
				$parent = $self.closest(self.options.closest);
			} else {
				$parent = $('body');
			}

			$(self.options.selector, $parent).toggle();
		}
	});

	add_widget('toggleButtonWidget', toggleButtonWidget);

})(jQuery);
