/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){
	var treeWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			def: {},
			ous: []
		},

		manages_own_descendant_value: true,
		value_widget: true,
		set_value_widget: true,

		_beforeInit: function () {
			var self = this, $self = this.element, $tree_wrap;

			self.options._id = 'tree-wrap-' + self.options.widget_id;

			$tree_wrap = $('<div />').attr('id', 'tree-wrap-' + self.options.widget_id);
			$self.attr('id', 'tree-' + self.options.widget_id);
			$self.wrap($tree_wrap);

			$self.empty();
			$self.append($('<img src="/images/table/miniwait.gif" style="width: 8px; height: 16px" alt="" /><span> Loading...</span>'));
		},

		_defineTree: function (data) {
			var self = this, $self = this.element, dynatree_def;

			if (!data.ous || !data.ous.length) {
				self._OUError();
				return;
			}

			dynatree_def = {
				checkbox: true,
				debugLevel: 0,
				children: data.ous,
				selectMode: 3,
				clickFolderMode: 2,
				minExpandLevel: 2,
				strings: { loading: '<img src="/images/table/miniwait.gif" style="width: 8px; height: 16px" alt="" /> Loading...' },
				onSelect: self._selectNode.bind(self)
			};

			self._initTree(dynatree_def);
		},

		_OUError: function () {
			var self = this, $self = this.element, error_message = 'No synchronization groups could be retrieved. Check your Connection Settings and binding credentials, ensure that the LDAP/Active Directory server is available, that groups have been configured, and that the binding user has adequate access permissions.';

			$('<span class="tree-widget-error"></span>').text(error_message).appendTo($self.empty().addClass('is-invalid'));
		},

		_selectNode: function (flag, node) {
			var self = this, $self = this.element, selected;

			selected = self._getSelectedNodes();
			$self.trigger('change');
		},

		// sets the ous that are selected and sets the array ref for defining a tree
		_getSelectedNodes: function() {
			var self = this, $self = this.element, selected_nodes;
			self.options.ous = [];

			selected_nodes = $self.dynatree("getTree").getSelectedNodes();
			for ( var i in selected_nodes) {
				if (selected_nodes[i] && selected_nodes[i].data.value) {
					self.options.ous.push(selected_nodes[i].data.value);
				}
			}

			self.options.ous.sort();
			self.options.children = $self.dynatree('getTree').toDict().children;

		},

		_initTree: function (tree_def) {
			var self = this, $self = this.element;

			$self.empty();
			$self.dynatree(tree_def);

			$self.dynatree('getTree').reload();
			self._getSelectedNodes();
		},

		getTree: function () {
			var self = this, $self = this.element;

			$self.empty();
			$self.append($('<img src="/images/table/miniwait.gif" style="width: 8px; height: 16px" alt="" /><span> Loading...</span>'));

			CUI.getREST('/gui/sync/ous', {}, self._defineTree.bind(self));
		},

		setValue: function (v) {
			var self = this, $self = this.element;

			self.options.ous = v;
		},

		fillData: function (d, from_self) {
			var self = this, $self = this.element;

			self.getTree();
		},

		_getWidgetValue: function () {
			var self = this, $self = this.element;

			return this._wrapValue(self.options.ous);
		}
	});

	add_widget('treeWidget', treeWidget);
})(jQuery);
