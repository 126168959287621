/* jshint jquery: true, unused: vars */
/* global add_widget */
/* **************************************************************************************************************************
  tableFilteredFlyoutSearchSelectWidget: a wrapper around flyoutSearchSelectWidget that allows you to filter the rest call
                                         based on values of the widget's dataTable

****************************************************************************************************************************/


(function( $ ){
	var tableFilteredFlyoutSearchSelectWidget = $.extend(true, {}, $.ui.flyoutSearchSelectWidget.prototype, {
		options: {
			table_filter: false
		},

		_beforeInit: function () {
			var self = this, $self = this.element;

			$self.addClass('flyoutSearchSelectWidget');
			$.ui.flyoutSearchSelectWidget.prototype._beforeInit.apply(self, arguments);
		},

		_updateSearch: function () {
			var self = this;

			if (self.options.table_filter) {
				self._addFilterSearchParams();
			}

			$.ui.flyoutSearchSelectWidget.prototype._updateSearch.apply(self, arguments);
		},

		_addFilterSearchParams: function () {
			var self = this, $self = this.element, col_vals = [], filter = [];

			if (!self.options.dtw && self.options.table_filter) {
				self.options.$dtw = $self.closest('.dataTableWidget');
				if (self.options.$dtw[0]) {
					self.options.dtw = self.options.$dtw.getCUIWidget('dataTableWidget');
				}
			}

			if (self.options.dtw) {
				col_vals = self.options.dtw.getAnyColumnData('bbx_extension_id');
				if (col_vals && col_vals.length) {
					for (var c = 0, clen = col_vals.length; c < clen; ++c) {
						if (col_vals[c] !== undefined) {
							filter.push(col_vals[c]);
						}
					}
				}
			}

			if (filter.length) {
				self.options.search_rest_params.filter = filter;
			} else {
				delete self.options.search_rest_params.filter;
			}
		}
	});

	add_widget('tableFilteredFlyoutSearchSelectWidget', tableFilteredFlyoutSearchSelectWidget);
})(jQuery);
