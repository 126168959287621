/* jshint jquery: true, browser: true, unused: vars */
/* global CUI, classCUI, debugLog, Ape, tryDecodeURIComponent */
classCUI.prototype.dataTableClass = new function () {
	/**
	* Initialization function for dataTableClass. This must be called in any class derived from this one.
	*/

	// Default options
	this.ignore_live_table_meta_keys = ['table_key'];
	this.hb_interval = 5000;

	this._is_meta_index = function (idx) {

		if (!this.options.columns[idx]) {
			debugLog('cui.dataTableClass.js: Column number ' + idx + ' does not exist in table definition.', true);

			return false;
		}
		return this._is_meta_key(this.options.columns[idx].column);
	};

	this._is_meta_key = function (key) {
		return ($.inArray(key, this.ignore_live_table_meta_keys) > -1);
	};

	this._dataTableInit = function() {
		var self = this;

		if (!self.getPageSize()) {
			self.setPageSize(10);
		}

		self.options.adding_rows = self.options.adding_rows || {};
		self.options.editing_rows = self.options.editing_rows || {};

		self.options.destroy_callback = function() {
			Ape.unBind(self.options.binding, self.options.lt.mod_cudatel_opts);
		};
	};

	/***************************************************************************************/
	/* The following block of code is the interface to the Ape event system.               */
	/***************************************************************************************/

	/**
	*
	*/
	this._liveDataTableSubscribe = function(context, mod_cudatel_opts) {
		var self = this, $self = this.element, handler_func;

		if (!self.options.data) {
			self.options.data = {};
		}

		self.options.lt = {};
		self.options.lt.mod_cudatel_opts = mod_cudatel_opts || {};
		self.options.lt.mod_cudatel_opts.ident = self.options.live_ident || (self.options.live_unique_ident && self.options.widget_id && ('ldt_' + self.options.widget_id)) || 'default';
		self.options.lt.last_serno = 0;
		self.options.lt.context = context;

		if (context) {
			handler_func = self._liveEventHandler.bind(self);
			//debugLog('Binding context: ', context, self);
			self.options.binding = Ape.bindContext(context, handler_func, self);
			self.options.joined = self.options.binding.joined || false;
			self._addDestroyCallback(self.options.destroy_callback);
		}

		if (self.options.joined) {
			self.bootstrap(self.options.lt.mod_cudatel_opts);
		} else {
			if (self._one) {
				self._one($self, 'joined_channel', function() {
					self.bootstrap(self.options.lt.mod_cudatel_opts);
				});
			} else {
				$self.one('joined_channel', function() {
					self.bootstrap(self.options.lt.mod_cudatel_opts);
				});
			}
		}
	};

	this.bootstrap = function(obj) {
		var self = this, bootstrap_func;
		obj = obj || (self.options.lt && self.options.lt.mod_cudatel_opts) || {};

		bootstrap_func = function() {
			// Copy this, so search changes don't get stuck
			var new_lt_obj = $.extend(true, {}, obj);

			self.options.lt.last_serno = -1;
			if (self.options.search) {
				$.extend(new_lt_obj.search, self.options.search);
				if (self.options.search_flags) {
					new_lt_obj.search_flags = self.options.search_flags;
				}
			}

			if (self.options.search_op) {
				new_lt_obj.search_op = self.options.search_op;
			}

			// debugLog("Sending bootstrap", self.options.lt, new_lt_obj);

			Ape.broadcast({liveArray: {command: "bootstrap", context: self.options.lt.context, name: self.options.lt.name, obj: new_lt_obj}});

			if (self.options.lt.heartbeat_timeout) {
				self._clearTimeout(self.options.lt.heartbeat_timeout);
			}

			self.options.lt.heartbeat_timeout = self._setTimeout(self._heartbeat.bind(self), self.hb_interval);
		};

		// debugLog('Search: ', self.options.search);
		if (self.options.search_parent_field && !self.options.search) {
			//debugLog('Postponing bootstrap...');
			self._one(this.element, 'fillDataParent', bootstrap_func);
		} else {
			bootstrap_func();
		}
	};

	this.changepage = function(obj) {
		var self = this, send_obj = $.extend(self.options.lt.mod_cudatel_opts, obj);
		if (!obj.search_flags) { delete self.options.lt.mod_cudatel_opts.search_flags; }
		self._clear();
		Ape.broadcast({liveArray: {command: "changepage", context: self.options.lt.context, name: self.options.lt.name, obj: send_obj}});
	};

	this._heartbeat = function() {
		var self = this;
		var mc_opts = self.options.lt.mod_cudatel_opts;
		mc_opts.hb_id = self.hb_id = (self.hb_id || 0) + 1;
		Ape.broadcast({liveArray: { command: "heartbeat", context: self.options.lt.context, name: self.options.lt.name, obj: mc_opts }});
		self.options.lt.heartbeat_timeout = self._setTimeout(self._heartbeat.bind(self), self.hb_interval);
	};

	this._liveEventHandler = function(e) {
		var self = this, $self = this.element, packet = e.json;

		//debugLog('Received event: ', e);
		if (self.options.destroyed) {
			//debugLog('We are destroyed.');
			return;
		}

		// Ignore packets sent to a different widget on the same live table
		if (self.options.lt.mod_cudatel_opts.ident && packet.ident && self.options.lt.mod_cudatel_opts.ident != packet.ident) {
			//debugLog('IDENT mismatch.');
			return;
		}

		if (packet.action.toLowerCase() === 'join_channel') {
			//debugLog('Joined a channel.');
			self.options.joined = true;
			$self.triggerHandler('joined_channel');
			return;
		}

		// Check for permission-denied errors. This should never happen, so just write a debugLog and fail
		if (packet.action === 'permission_denied') {
			debugLog('cui.dataTableClass.js: A "permission_denied" packet was received from the live data source. The data source cannot be used. ', packet, ' -- ', self.element || self);
			return;
		}

		if (packet.action === 'backend_error' && self._reportBackendError) {
			debugLog('cui.dataTableClass.js: A "backend_error" packet was received from the live data source. The data source cannot be used. ', packet, ' -- ', self.element || self);
			self._reportBackendError('backend_error', packet);
		}

		// Don't leave this debugLog active during production. It makes a copy of the data in the heap for use on the
		// console, so it causes memory growth until the browser crashes.
		// console.log('Received packet: ', packet);

		this._incomingMessageOrderer(packet);
	};

	/**
	 * Sends in-order messages normally, or starts a backlog buffer if messages arrive out of order,
	 * then replays them once order is restored. Called from this.liveEventHandler.
	 * @param  {object} packet      The contents of the Ape messages
	 */
	this._incomingMessageOrderer = function (packet) {
		var self = this, mod_cudatel_opts = self.options.lt.mod_cudatel_opts, serno = Number(packet.wireSerno), next_serno, buffer, buffer_array = [], idx;
		self.options.lt = self.options.lt || {};
		buffer = self.options.lt._reorder_buffer;

		if (packet.wireSerno === null || isNaN(serno)) {
			console.log('cui.dataTableClass.js: Failed packet integrity check, missing or invalid wireSerno: ', packet);
			self.bootstrap(mod_cudatel_opts);
			return; // Rebootstrapped
		}

		if (self.options.lt.last_serno === undefined || self.options.lt.last_serno < 0) {
			// Rebootstrapped or initial-state sernos may not be 0, so take whatever we get.
			next_serno = serno;
		} else {
			next_serno = self.options.lt.last_serno + 1;
		}

		if (buffer) {
			// A buffer exists, and must be rectified before continuing...
			buffer[serno] = packet;
			if (serno > buffer.end) {
				buffer.end = serno;
			}

			for (idx = buffer.start; idx <= buffer.end; idx++) {
				// This for-loop terminates (via return) after the first missing buffer item.
				if (!buffer[idx]) { // A buffer slot is still empty
					if (buffer.end - buffer.start > 10) {
						console.log('cui.dataTableClass.js: Too many out-of-order packets.');
						self.bootstrap(mod_cudatel_opts);
						return; // Rebootstrapped
					}
					console.log('cui.dataTableClass.js: Still unable to rectify packet order. Waiting...');
					return; // Do nothing
				} else {
					buffer_array.push(packet);
				}
			}

			// If we make it here, the buffer is full and integrity has returned. Flush it!
			delete self.options.lt._reorder_buffer;
			buffer_array.forEach(function (buffered_packet) {
				self._laOnChange(mod_cudatel_opts, buffered_packet);
			});
			return; // Successful exit after flush
		} else if (serno <= 0 || serno === next_serno) {
			// Everything is okay.
			self.options.lt.last_serno = serno;
			self._laOnChange(mod_cudatel_opts, packet);
			return; // Successful exit.
		} else if (serno > next_serno) {
			// There is a new serial-number gap...
			console.log('cui.dataTableClass.js: Serial number out of order (', self.options.lt.last_serno, '->', serno, '). Waiting for reorder.');
			buffer = self.options.lt._reorder_buffer = {
				start: self.options.lt.last_serno + 1,
				end: serno
			};
			buffer[serno] = packet;
			return; // Do nothing
		} else {
			// An earlier packet number than expected was recieved, but there was no prior gap (i.e., duplicate serno)
			console.log('cui.dataTableClass.js: Duplicate packet serial number recieved. Dropping the packet.');
			return; // Do nothing
		}
	};

	this._clear = function() {
		var self = this;

		delete self.options.data.live;
		self.clearRowCount();
		self.options.data.live = {};
	};

	/*****************************************************************************************************/
	/* This is the end of code for the interface to the Ape event system.                                */
	/*****************************************************************************************************/

	this.addingRows = function() {
		var self = this, $self = this.element, $open_row;

		if (CUI.hasKeys(self.options.adding_rows)) {
			return true;
		}

		// Look for any open add rows in nested dataTables
		$open_row = $self.find('.new_row');
		if ($open_row.length) {
			return true;
		}

		return false;
	};

	this.isBeingAdded = function(index) {
		return !!this.options.adding_rows[index];
	};

	this._setBeingAdded = function(index, is_being_added) {
		var self = this;

		if (is_being_added) {
			self.options.adding_rows[index] = true;
		} else {
			delete self.options.adding_rows[index];
		}
	};

	this.isBeingEdited = function(index) {
		return !!this.options.editing_rows[index];
	};

	// This is called from dataTableWidget, or other derivatives of DTC
	this._setBeingEdited = function(index, is_being_added) {
		var self = this;

		if (is_being_added) {
			self.options.editing_rows[index] = true;
		} else {
			delete self.options.editing_rows[index];
		}
	};


	this.clearRowCount = function() {
		this.options.row_count = 0;
	};

	this.clearRowData = function (row_num) {
		var self = this, idx, key, saved_keys = {};

		if (self.options.primary_keys) {
			for ( idx = 0; idx < self.options.primary_keys.length; idx++ ) {
				key = self.options.primary_keys[idx];
				if ( self.options.data[self.options.data_source][row_num].hasOwnProperty(key) ) {
					saved_keys[key] = self.options.data[self.options.data_source][row_num][key];
				}
			}
		}

		self.options._cleared_data = self.options._cleared_data || {};
		self.options._cleared_data[self.options.data_source] = self.options._cleared_data[self.options.data_source] || [];
		self.options._cleared_data[self.options.data_source][row_num] = $.extend({}, self.options.data[self.options.data_source][row_num]);

		self.options.data[self.options.data_source][row_num] = saved_keys;

	};

	this.unclearRowData = function (row_num) {
		var self = this;
		if (self.options._cleared_data && self.options._cleared_data[self.options.data_source] && self.options._cleared_data[self.options.data_source][row_num] !== undefined) {
			self.options.data[self.options.data_source][row_num] = $.extend({}, self.options._cleared_data[self.options.data_source][row_num], self.options.data[self.options.data_source][row_num]);
			self.purgeClearedRowData(row_num);
		}
	};

	this.purgeClearedRowData = function (row_num) {
		var self = this;
		if (self.options._cleared_data && self.options._cleared_data[self.options.data_source] && self.options._cleared_data[self.options.data_source][row_num] !== undefined) {
			self.options._cleared_data[self.options.data_source][row_num] = undefined;
		}
	};

	this._setupDataSource = function () {
		var self = this;

		if (!self.options.rest) {
			return false;
		}

		if (!self.options.data_source || (self.options.data_source && self.options.auto_data_source)) {
			self.options.data_source = self.options.rest.split('/').pop();
			// Set this to true so we know that our data source was not set in dtw definition; otherwise we shouldn't override data source
			self.options.auto_data_source = true;
		}

		return self.options.data_source;
	};

	this._setupPagination = function () {
		var self = this;

		if (!self.options.paginate) {
			return false;
		}

		if (self.options.page_size && self.options.rest) {
			if (!self.options.rest_params) {
				self.options.rest_params = {};
			}
			self.options.rest_params.rows = self.options.page_size;
			self.options.rest_params.page = 1;
		}
		return true;
	};

	this._buildColumnIndexMap = function() {
		var self = this, c, len;

		if (!self.options.columns) {
			self.options.columns = [];
		}

		self.options.column_index_map = {};
		for (c = 0, len = self.options.columns.length; c < len; ++c) {
			if (self.options.data_source == 'ROW_SOURCE') {
				self.options.column_index_map[self.options.columns[c].row_source] = c;
			} else {
				self.options.column_index_map[self.options.columns[c].column] = c;
			}
		}

		return true;
	};

	this.setInitialData = function(data) {
		var self = this, key, page_size, mod_count, page_count;

		self.options.row_count = 0;
		for (key in data) {
			self.options.data[key] = data[key];
		}
		if (data.rows) {
			self.setPageSize(data.rows);
		}
		if (data.count) {
			page_size = self.getPageSize();
			mod_count = data.count % page_size;
			page_count = (data.count - mod_count) / page_size + ((mod_count > 0 ? 1 : 0));
			self.setPageCount(page_count);
		}
		if (data.page) {
			self.setPage(data.page);
		}

		return true;
	};

	this.clearInitialData = function() {
		this.options.data = {};
		return true;
	};

	this.setOriginalData = function(data) {
		var self = this, key;
		self.options.original_data = self.options.original_data || {};
		self.options.original_row_count = 0;

		for (key in data) {
			if (typeof data[key] === 'object' && data[key] !== null) {
				self.options.original_row_count = data[key].length;
				self.options.original_data[key] = $.extend(true, {}, data[key]);
			} else {
				self.options.original_data[key] = data[key];
				self.options.original_row_count++;
			}
		}

		return true;
	};

	this.clearOriginalData = function() {
		this.options.original_data = {};
	};

	this._beforeLAOnChange = function(obj, index) { };
	this._beforeLAInit = function(obj) { };
	this._afterLAInit = function(obj) { };
	this._beforeLADelete = function(obj, index) { };
	this._afterLADelete = function(obj, index, row) { };
	this._beforeLAModify = function(obj, index) { };
	this._afterLAModify = function(obj, index, row, old_row) { };
	this._beforeLABootstrap = function(obj) { };
	this._afterLABootstrap = function(obj) { };
	this._beforeLAAdd = function(obj, index) { };
	this._afterLAAdd = function(obj, index, row) { };
	this._beforeLAClear = function(obj) { };
	this._afterLAClear = function(obj) { };
	this._beforeLAReorder = function(obj) { };
	this._afterLAReorder = function(obj) { };
	this._beforeLAHide = function(obj, index) { };
	this._afterLAHide = function(obj, index) { };
	this._beforeLAShow = function(obj, index) { };
	this._afterLAShow = function(obj, index) { };
	this._laOnChange = function(obj, la_args) {
		var self = this, $self = this.element || $(this), index, row, c, clen, r, rlen, row_obj, i, ilen, to_idx;

		if (self.options.destroyed) {
			return;
		}

		index = parseInt(la_args.arrIndex);

		self._beforeLAOnChange(obj, index);

		switch(la_args.action) {
			case 'startup':
			// debugLog("Startup packet recd:", obj, la_args, self, new Date().getTime());
			location.reload();
			break;
			case 'init':
			self._beforeLAInit(obj);
			self.options.last_serno = 0;

			if (self.options._debounce_la_modify_timeouts) {
				for (to_idx in self.options._debounce_la_modify_timeouts) {
					if (self.options._debounce_la_modify_timeouts.hasOwnProperty(to_idx) && self.options._debounce_la_modify_timeouts[to_idx] !== undefined) {
						clearTimeout(self.options._debounce_la_modify_timeouts[to_idx]);
					}
				}
				delete self.options._debounce_la_modify_timeouts;
			}

			delete self.options.data.live;
			self.clearRowCount();
			self.options.data.live = [];
			self.options.data_source = 'live';
			self.options.data.columns = la_args.data;
			self._afterLAInit(obj);
			$self.triggerHandler('liveInit');
			break;
			case 'del':
			self._beforeLADelete(obj, index);
			//	    debugLog('Rows: ', self.options.data.live);
			row = self.getRowData(index);
			if (self.options._debounce_la_modify_timeouts && self.options._debounce_la_modify_timeouts[index]) {
				clearTimeout(self.options._debounce_la_modify_timeouts[index]);
				self.options._debounce_la_modify_timeouts.splice(index, 1);
			}
			//	    debugLog('Deleting row: ', $.extend({}, row));
			self._LTRemoveRow(index);
			//	    debugLog('Rows: ', self.options.data.live);
			self._afterLADelete(obj, index, row);
			$self.triggerHandler('liveDel');
			break;
			case 'modify':
			if (!self.options.data.columns) {
				// Spurious event
				return;
			}
			// debugLog('Modify row.', index);
			self._doLAModify(obj, la_args, index);
			return;
			case 'bootstrap_data':
			self._beforeLABootstrap(obj);
			if (!self.options.columns) {
				self.options.columns = [];

				for (c = 0, clen = self.options.data.columns.length; c < clen; ++c) {
					self.options.columns.push({ 'column': self.options.data.columns[c] });
				}
				self._buildColumnIndexMap();
			}
			for (r = 0, rlen = la_args.data.length; r < rlen; ++r) {
				row = la_args.data[r];
				row_obj = {};
				for (i = 0, ilen = row.length; i < ilen; ++i) {
					row_obj[self.options.data.columns[i]] = row[i];
				}
				if (row.length) {
					//		    debugLog('Adding bootstrap row...');
					self.addRow(row_obj);
				}
			}
			self._afterLABootstrap(obj);
			$self.triggerHandler('liveBootstrapData');
			break;
			case 'add':
			self._beforeLAAdd(obj, index);
			row = [];
			if (!self.options.columns) {
				self.options.columns = [];
				if (!$.isArray(self.options.data.columns)) {
					debugLog("cui.dataTableClass.js: Invalid datatable definition for ", self.element, self.options.data);
				}
				for (c = 0, clen = self.options.data.columns.length; c < clen; ++c) {
					self.options.columns.push({ 'column': self.options.data.columns[c] });
				}
				self._buildColumnIndexMap();
			}
			row_obj = {};
			for (i = 0, ilen = la_args.data.length; i < ilen; ++i) {
				row_obj[self.options.data.columns[i]] = la_args.data[i];
			}
			//debugLog("Adding non-bootstrap row: ", row_obj);
			self.addRow(row_obj);
			//debugLog("After adding row: ", self.options.data.live);
			self._afterLAAdd(obj, index, row_obj);
			$self.triggerHandler('liveAdd');
			break;
			case 'clear':
			if (self.options._debounce_la_modify_timeouts) {
				for (to_idx in self.options._debounce_la_modify_timeouts) {
					if (self.options._debounce_la_modify_timeouts.hasOwnProperty(to_idx) && self.options._debounce_la_modify_timeouts[to_idx] !== undefined) {
						clearTimeout(self.options._debounce_la_modify_timeouts[to_idx]);
					}
				}
				delete self.options._debounce_la_modify_timeouts;
			}

			self._beforeLAClear(obj);
			self._clear();
			self._afterLAClear(obj);
			$self.triggerHandler('liveClear');
			break;
			case 'reorder':
			self._beforeLAReorder(obj);

			self._afterLAReorder(obj);
			break;
			case 'hide':
			self._beforeLAHide(obj, index);

			self._afterLAHide(obj, index);
			break;
			case 'show':
			self._beforeLAShow(obj, index);

			self._afterLAShow(obj, index);
			break;
		}

		// Be sure to add anything beyond this point to the do_la_modify_now function below-- LA modify returns out of this function after it calls _doLAModify
		$self.triggerHandler('liveEvent');
		self._afterLAOnChange(obj, la_args, index);
	};
	this._afterLAOnChange = function(obj, la_args, index) { };

	this._doLAModify = function (obj, la_args, index) {
		var self = this, $self = this.element, do_la_modify_now;
		self.options._debounce_la_modify_timeouts = self.options._debounce_la_modify_timeouts || [];

		do_la_modify_now = function () {
			var row = {}, old_row, i, ilen;
			delete self.options._debounce_la_modify_timeouts[index];

			self._beforeLAModify(obj, la_args, index);
			old_row = $.extend({}, self.getRowWidgetData(index));

			for (i = 0, ilen = la_args.data.length; i < ilen; ++i) {
				row[self.options.data.columns[i]] = la_args.data[i];
			}

			// There is no asynchronous stuff going on with this call
			// since we are not saving to the back end here

			self.setOriginalRowData(index, row);
			self._afterLAModify(obj, la_args, index, row, old_row);
			$self.triggerHandler('liveModify');
			$self.triggerHandler('liveEvent');
			self._afterLAOnChange(obj, la_args, index);
		};

		if (self.options.debounce_la_modify) {
			if (self.options._debounce_la_modify_timeouts[index]) {
				clearTimeout(self.options._debounce_la_modify_timeouts[index]);
			}
			self.options._debounce_la_modify_timeouts[index] = setTimeout(do_la_modify_now, self.options.debounce_la_modify_time || 100);
		} else {
			do_la_modify_now();
		}
	};

	this.validRowNum = function(row_num) {
		var self = this;
		/* Deletes occur with array slice which re-indexes items later in the array, so the index should never exceed the number of rows. */
		if (typeof row_num != 'number' || row_num < 0 || row_num >= self.getRowCount()) {
			return false;
		}
		return true;
	};

	this.validColNum = function(col_num) {
		var self = this;
		if (typeof col_num != 'number' || col_num < 0 || col_num >= self.options.columns.length) {
			return false;
		}
		return true;
	};

	this.setPageSize = function(rows) {
		var self = this;
		self.options.rest_params = self.options.rest_params || {};
		self.options.rest_params.rows = rows;
		return rows;
	};

	this.setPage = function(page) {
		var self = this;

		if (!self.options.rest_params) {
			self.options.rest_params = {};
		}
		self.options.rest_params.page = page;
		return page;
	};

	this.getPageSize = function() {
		var self = this, rows = 0;
		if (self.options.rest_params && self.options.rest_params.rows) {
			rows = self.options.rest_params.rows;
		}
		return rows;
	};

	this.getPage = function() {
		var self = this, page = 0;
		if (self.options.rest_params && self.options.rest_params.page) {
			page = self.options.rest_params.page;
		}
		return page;
	};

	this.getPageCount = function() {
		var self = this, count = self.options.page_count;
		return count;
	};

	this.setPageCount = function(count) {
		var self = this;
		self.options.page_count = count;
		return count;
	};

	this.getRowCount = function() {
		var self = this, c, collen, source;

		// If we do not have a self.options.row_count variable, or we have zero rows, then we always
		// recalculate how many rows we have
		if (!self.options.row_count) {
			if (!self.options.data) {
				self.options.row_count = 0;
			} else {
				if (self.options.data_source == 'ROW_SOURCE') {
					if (!self.options.data[self.options.columns[0].row_source] || !(self.options.data[self.options.columns[0].row_source] instanceof Array)) {
						self.options.row_count = 0;
					} else {
						for (c = 0, collen = self.options.columns.length; c < collen; ++c) {
							if (self.options.columns[c].row_source) {
								source = self.options.data[self.options.columns[c].row_source];
								if (source instanceof Array && source.length) {
									self.options.row_count = source.length;
									break;
								}
							}
						}
						if (!self.options.row_count) {
							self.options.row_count = 0;
						}
					}
				} else {
					if (!self.options.data[self.options.data_source] || !(self.options.data[self.options.data_source] instanceof Array)) {
						self.options.row_count = 0;
					} else {
						self.options.row_count = self.options.data[self.options.data_source].length;
					}
				}
			}
		}
		return self.options.row_count;
	};

	this.getOriginalRowCount = function() {
		var self = this;

		// If we do not have a self.options.original_row_count variable, or we have zero rows, then we always
		// recalculate how many rows we have
		if (!self.options.original_row_count) {
			if (!self.options.data) {
				self.options.original_row_count = 0;
			} else {
				if (self.options.data_source == 'ROW_SOURCE') {
					if (!self.options.original_data[self.options.columns[0].row_source] || !(self.options.original_data[self.options.columns[0].row_source] instanceof Array)) {
						self.options.row_count = 0;
					} else {
						self.options.row_count = self.options.original_data[self.options.columns[0].row_source].length;
					}
				} else {
					if (!self.options.original_data[self.options.data_source] || !(self.options.original_data[self.options.data_source] instanceof Array)) {
						self.options.original_row_count = 0;
					} else {
						self.options.originial_row_count = self.options.original_data[self.options.data_source].length;
					}
				}
			}
		}
		return self.options.original_row_count;
	};

	this.getOriginalCellData = function(row_num, col_num) {
		var self = this;

		if (!self.validRowNum(row_num)) {
			debugLog('cui.dataTableClass.js: Attempted to get cell data for row index "' + row_num + '" which is out of range or invalid in getOriginalCellData().', true);
			return undefined;
		}

		if (!self.validColNum(col_num)) {
			debugLog('cui.dataTableClass.js: Attempted to get cell data for column index "' + col_num + '" which is out of range or invalid in getOriginalCellData().', true);
			return undefined;
		}

		return self._getSourceCellData('original_data', row_num, col_num);
	};

	this.getOriginalRowData = function(row_num) {
		var self = this;

		if (!self.validRowNum(row_num)) {
			debugLog('cui.dataTableClass.js: Attempted to get data for row index "' + row_num + '" which is out of range or invalid in getOriginalRowData().', true);
			return false;
		}

		return self._getSourceRowData('original_data', row_num);
	};

	this.hasRow = function (row_num) {
		var self = this, col_key;
		if (self.options.data_source == 'ROW_SOURCE') {
			for (col_key in self.options.data) {
				if (row_num in self.options.data[col_key]) {
					return true;
				}
			}
			return false;
		} else {
			return (self.options.data[self.options.data_source] && (row_num in self.options.data[self.options.data_source]));
		}
	};

	this.getCellData = function(row_num, col_num) {
		var self = this;

		if (!self.validRowNum(row_num)) {
			debugLog('cui.dataTableClass.js: Attempted to get cell data for row index "' + row_num + '" which is out of range or invalid in getCellData().', true);
			return undefined;
		}
		if (!self.validColNum(col_num)) {
			debugLog('cui.dataTableClass.js: Attempted to get cell data for column index "' + col_num + '" which is out of range or invalid in getCellData().', true);
			return undefined;
		}

		return self._getSourceCellData('data', row_num, col_num);
	};

	this._getSourceCellData = function(source, row_num, col_num) {
		var self = this, data;

		if (!self.options[source] || !self.options.columns[col_num]) {
			return undefined;
		}

		if (self.options.data_source == 'ROW_SOURCE') {
			if (!self.options.columns[col_num].row_source || !(self.options.columns[col_num].row_source in self.options[source])) {
				return undefined;
			}
			data = self.options[source][self.options.columns[col_num].row_source][row_num];
		} else {
			//debugLog('Getting row data from: ', self.options[source]);
			if (!self.options.columns[col_num].column || !self.options[source][self.options.data_source] || !self.options[source][self.options.data_source][row_num] || !(self.options.columns[col_num].column in self.options[source][self.options.data_source][row_num])) {
				return undefined;
			}
			data = self.options[source][self.options.data_source][row_num][self.options.columns[col_num].column];
		}
		return data;
	};

	this.getExtraCellData = function(row_num, col_name) {
		var self = this, data;

		if (!self.validRowNum(row_num)) {
			debugLog('cui.dataTableClass.js: Attempted to get cell data for row index "' + row_num + '" which is out of range or invalid in getExtraCellData().', true);
			return undefined;
		}

		data = self._getSourceExtraCellData('data', row_num, col_name);
		return data;
	};

	this.getOriginalExtraCellData = function(row_num, col_name) {
		var self = this;

		if (!self.validRowNum(row_num)) {
			debugLog('cui.dataTableClass.js: Attempted to get cell data for row index "' + row_num + '" which is out of range or invalid in getExtraCellData().', true);
			return undefined;
		}

		return self._getSourceExtraCellData('original_data', row_num, col_name);
	};

	this._getSourceExtraCellData = function(source, row_num, col_name) {
		var self = this, data;

		if (!self.options[source]) {
			return undefined;
		}

		if (self.options.data_source == 'ROW_SOURCE') {
			data = self.options[source][col_name][row_num];
		} else {
			//debugLog('Getting row data from: ', self.options[source]);
			data = self.options[source][self.options.data_source][row_num][col_name];
		}
		return data;
	};


	this.setOriginalExtraRowData = function(row_num, col_name, col_val) {
		var self = this;

		//debugLog('Going to set data for column ' + col_name + ' to ', col_val);
		if (typeof col_val !== 'object' && col_val !== undefined) {
			col_val = tryDecodeURIComponent(col_val) || col_val;
		}

		if (self.options.data_source == 'ROW_SOURCE') {
			self.options.original_data[col_name][row_num] = col_val;
		} else if (self.options.original_data[self.options.data_source][row_num]) {
			if ( self.options.allow_parent_extra_row_data && self.options.original_data[col_name] && col_val === undefined) {
				self.options.original_data[self.options.data_source][row_num][col_name] = self.options.original_data[col_name];
			} else {
				self.options.original_data[self.options.data_source][row_num][col_name] = col_val;
			}
		}

		return true;
	};

	this.setExtraRowData = function(row_num, col_name, col_val) {
		var self = this;

		//debugLog('Going to set data for column ' + col_name + ' to ', col_val);
		if (!$.isArray(col_val) && typeof col_val !== 'object') {
			col_val = tryDecodeURIComponent(col_val) || col_val;
		}

		//debugLog('Setting data for column ' + col_name + ' to ', col_val);
		//debugLog('Trying to set extra row data for row ' + row_num + ' in data source ' + self.options.data_source);
		if (self.options.data_source == 'ROW_SOURCE') {
			self.options.data[col_name][row_num] = col_val;
		} else {
			//debugLog('Data source: ', self.options.data[self.options.data_source], row_num);
			if (self.options.data[self.options.data_source][row_num]) {
				self.options.data[self.options.data_source][row_num][col_name] = col_val;
			}
		}

		return true;
	};

	this.getExtraRowData = function(row_num, col_name) {
		var self = this, val;

		if (self.options.data_source == 'ROW_SOURCE') {
			val = self.options.data[col_name][row_num];
		} else {
			val = self.options.data[self.options.data_source][row_num][col_name];
		}

		return val;
	};

	this.getAllRowData = function (row_num) {
		var self = this, row_data, all_erd, erd_idx, erd_len;
		row_data = self.getRowData(row_num);

		all_erd = (self.options.extra_row_data || []).concat(self.options.primary_keys || []);

		erd_len = all_erd.length;
		for (erd_idx = 0; erd_idx < erd_len; erd_idx++) {
			if (!(all_erd[erd_idx] in row_data)) {
				row_data[all_erd[erd_idx]] = self.getExtraRowData(row_num, all_erd[erd_idx]);
			}
		}

		return row_data;
	};

	this.setOriginalCellData = function(row_num, col_num, val) {
		var self = this, $self = this.element || $(self);

		if (self._is_meta_index(col_num) && self.options.lt) {
			return false;
		}

		if (!self.validRowNum(row_num)) {
			debugLog('cui.dataTableClass.js: Attempted to set cell data for row index "' + row_num + '" which is out of range or invalid in setOriginalCellData().', true);
			return false;
		}
		if (!self.validColNum(col_num)) {
			debugLog('cui.dataTableClass.js: Attempted to set cell data for column index "' + col_num + '" which is out of range or invalid in setOriginalCellData().', true);
			return false;
		}
		if (typeof val != 'number' && typeof val != 'string' && typeof val !== 'undefined') {
			debugLog('cui.dataTableClass.js: Attempted to set cell data value for row ' + row_num + ', column ' + col_num + ' which is not a number or string in setOriginalCellData():', true, val);
			return false;
		}

		val = tryDecodeURIComponent(val) || val;

		if (!self.options.original_data) {
			self.options.original_data = {};
		}

		if (self.options.data_source == 'ROW_SOURCE') {
			if (!self.options.original_data[self.options.columns[col_num].row_source]) {
				self.options.original_data[self.options.columns[col_num].row_source] = [];
			}
			self.options.original_data[self.options.columns[col_num].row_source][row_num] = val;
		} else {
			if (!self.options.original_data[self.options.data_source]) {
				self.options.original_data[self.options.data_source] = [];
			}
			if (!self.options.original_data[self.options.data_source][row_num]) {
				self.options.original_data[self.options.data_source][row_num] = {};
			}
			self.options.original_data[self.options.data_source][row_num][self.options.columns[col_num].column] = val;
		}
		$self.triggerHandler('updateCell');
		return true;
	};

	this.setCellData = function(row_num, col_num, val) {
		var self = this, $self = this.element || $(self);

		if (self._is_meta_index(col_num) && self.options.lt) {
			return false;
		}

		if (!self.validRowNum(row_num)) {
			debugLog('cui.dataTableClass.js: Attempted to set cell data for row index "' + row_num + '" which is out of range or invalid in setCellData().', true);
			return false;
		}
		if (!self.validColNum(col_num)) {
			debugLog('cui.dataTableClass.js: Attempted to set cell data for column index "' + col_num + '" which is out of range or invalid in setCellData().', true);
			return false;
		}
		if (typeof val != 'number' && typeof val != 'string' && typeof val !== 'undefined') {
			debugLog('cui.dataTableClass.js: Attempted to set cell data value for row ' + row_num + ', column ' + col_num + ' which is not a number or string in setCellData():', true, val);
			return false;
		}

		val = tryDecodeURIComponent(val) || val;

		if (self.options.data_source == 'ROW_SOURCE') {
			self.options.data[self.options.columns[col_num].row_source][row_num] = val;
		} else {
			self.options.data[self.options.data_source][row_num][self.options.columns[col_num].column] = val;
		}

		$self.triggerHandler('updateCell');
		return true;
	};

	this.getColumnCount = function() {
		return (this.options.columns.length || 0);
	};

	this.getColumnDef = function(col_num) {
		var self = this;

		if (!self.validColNum(col_num)) {
			debugLog('cui.dataTableClass.js: Attempted to get column definition for column index "' + col_num + '" which is out of range or invalid in getColumnDef().', true);
			return false;
		}

		return self.options.columns[col_num];
	};

	this.getColumnName = function(col_num) {
		var self = this;

		if (!self.validColNum(col_num)) {
			debugLog('cui.dataTableClass.js: Attempted to get column name for column index "' + col_num + '" which is out of range or invalid in getColumnName().', true);
			return false;
		}

		return (self.options.data_source == 'ROW_SOURCE' ? self.options.columns[col_num].row_source : self.options.columns[col_num].column);
	};

	this.getColumnNum = function(col_name) {
		var self = this, col_num = -1, c, clen, name;

		for (c = 0, clen = self.getColumnCount(); c < clen; c++) {
			name = (self.options.data_source == 'ROW_SOURCE' ? self.options.columns[c].row_source : self.options.columns[c].column);
			if (name == col_name) {
				col_num = c;
				break;
			}
		}

		return col_num;
	};

	this.hasColumn = function(col_name) {
		var self = this;
		return !!((col_name in self.options.column_index_map) || self.hasExtraRowData(col_name));
	};

	this.hasExtraRowData = function(col_name) {
		var self = this, key;

		if ($.isArray(self.options.extra_row_data)) {
			return ( $.inArray(col_name, self.options.extra_row_data) !== -1 );
		} else if (self.options.extra_row_data) {
			for (key in self.options.extra_row_data) {
				if (self.options.extra_row_data[key] == col_name) {
					return true;
				}
			}
			return false;
		}
	};

	this.getColumnIndex = function(col_name) {
		var self = this;

		if (!(col_name in self.options.column_index_map)) {
			debugLog('cui.dataTableClass.js: Attempted to get column index for unknown column "' + col_name + '" in getColumnIndex(). Columns: ', true, self.options.columns);
			return false;
		}

		return self.options.column_index_map[col_name];
	};

	this.getRowData = function(row_num) {
		var self = this;

		if (!self.validRowNum(row_num)) {
			debugLog('cui.dataTableClass.js: Attempted to get data for row index "' + row_num + '" which is out of range or invalid in getRowData().', true);
			return false;
		}

		return this._getSourceRowData('data', row_num);
	};

	this._getSourceRowData = function(source, row_num) {
		var self = this, row_data = {}, c, len, col_name;
		for (c = 0, len = self.options.columns.length; c < len; ++c) {
			col_name = self.getColumnName(c);
			if (col_name !== undefined) {
				row_data[col_name] = self._getSourceCellData(source, row_num, c);
			}
		}
		return row_data;
	};

	// Returns the stored row data and extra data in the format that it should be presented to a form
	// (i.e., with array/object conversion on ROW_SOURCE tables)
	this.getRowWidgetData = function(row_num) {
		var self = this, data, key_idx, key;

		data = self.getRowData(row_num);

		if ($.isArray(self.options.extra_row_data)) {
			for (key_idx = 0; key_idx < self.options.extra_row_data.length; key_idx++) {
				if (self.options.data_source === 'ROW_SOURCE') {
					key = self.options.extra_row_data[key_idx];
					data[key] = self.options.data[key][row_num];
				} else {
					key = self.options.extra_row_data[key_idx];
					if (self.options.data && key in self.options.data[self.options.data_source][row_num]) {
						data[key] = self.options.data[self.options.data_source][row_num][key];
					}
				}
			}
		}

		return data;
	};

	this.getOriginalRowWidgetData = function(row_num) {
		var self = this, data, key_idx, key;

		data = self.getOriginalRowData(row_num);

		if ($.isArray(self.options.extra_row_data)) {
			for (key_idx = 0; key_idx < self.options.extra_row_data.length; key_idx++) {
				if (self.options.data_source === 'ROW_SOURCE') {
					key = self.options.extra_row_data[key_idx];
					data[key] = self.options.original_data[key][row_num];
				} else {
					key = self.options.extra_row_data[key_idx];
					if (key in self.options.original_data[self.options.data_source][row_num]) {
						data[key] = self.options.original_data[self.options.data_source][row_num][key];
					}
				}
			}
		}

		return data;
	};

	this._saveSuccessCallback = function (row_num, success_callback, being_added, being_edited, data, status, jqxhr) {
		var self = this, rc, col_index, key;

		if ((being_added || being_edited) && self.options.add_edit_action && self.options.add_edit_action.rest_container) {
			rc = self._validateRESTContainer(data, self.options.add_edit_action.rest_container);
		} else if (self.options.add_edit_action && self.options.add_edit_action.rest_container === undefined) {
			// We have to generate this ourselves, because the validateRESTContainer function uses self.options.rest
			rc = self._validateRESTContainer(data, self.options.add_edit_action.rest.replace(/^.+\//, ''));
		}

		data = rc ? data[rc] : data;

		if (self.options.data_source === 'live') {
			if (self.options.live_table_action_data_source) {
				data = data[self.options.live_table_action_data_source] || data;
			}
		} else if (self.options.data_source !== 'ROW_SOURCE') {
			data = data[self.options.data_source] || data;
		}

		for (key in data) {
			if (self.options.dirty_tracking) {
				if (self.hasExtraRowData(key)) {
					self.setExtraRowData(row_num, key, data[key]);
					self.setOriginalExtraRowData(row_num, key, data[key]);
				} else if (self.hasColumn(key)) {
					col_index = self.getColumnIndex(key);
					self.setCellData(row_num, col_index, data[key]);
					self.setOriginalCellData(row_num, col_index, data[key]);
				}
			} else {
				if (self.hasExtraRowData(key)) {
					self.setExtraRowData(row_num, key, data[key]);
				} else if (self.hasColumn(key)) {
					self.setCellData(row_num, self.getColumnIndex(key), data[key]);
				}
			}
		}

		if (typeof success_callback == 'function') {
			success_callback();
		}
		if (self.element) {
			self.element.triggerHandler('updateRow');
		}

		self._afterRowUpdate(row_num, data, true);

		if (being_added) {
			self.options.original_row_count++;
			self._setBeingAdded(row_num, false);
		}
		if (being_edited) {
			self._setBeingEdited(row_num, false);
		}
	};

	this.resetDirtyRow = function(row_num) {
		var self = this, dirty, orig, key;

		dirty = self.getRowWidgetData(row_num);
		orig = self.getOriginalRowWidgetData(row_num);

		for (key in dirty) {
			if (self.hasExtraRowData(key)) {
				self.setExtraRowData(row_num, key, orig[key]);
			} else {
				self.setCellData(row_num, self.getColumnIndex(key), orig[key]);
			}
		}
	};

	this._saveErrorCallback = function(row_num, row_data, error_callback, jxHQR, status, error) {
		if (typeof error_callback == 'function') {
			error_callback(jxHQR, status, error, row_num, row_data);
		}
	};

	this.setOriginalRowData = function(row_num, row_data) {
		var self = this, col_index, original_val, current_val, key;

		if (!self.validRowNum(row_num)) {
			debugLog('cui.dataTableClass.js: Attempted to set data for row index "' + row_num + '" which is out of range or invalid in setOriginalRowData().', true);
			return false;
		}
		if (!(row_data instanceof Array || (typeof row_data == 'object' && !$.isEmptyObject(row_data)))) {
			debugLog('cui.dataTableClass.js: Row data is not a valid type or is empty in setOriginalRowData(): ', true, row_data);
			return false;
		}


		for (key in row_data) {
			if (self.options.dirty_tracking) {
				if (self.hasExtraRowData(key)) {
					original_val = self.getOriginalExtraCellData(row_num, key);
					current_val = self.getExtraCellData(row_num, key);
					if (original_val == current_val) {
						self.setExtraRowData(row_num, key, row_data[key]);
					}
					self.setOriginalExtraRowData(row_num, key, row_data[key]);
				} else if (self.hasColumn(key) && (col_index = self.getColumnIndex(key)) !== false) { // Set, and compare
					col_index = self.getColumnIndex(key);
					original_val = self.getOriginalCellData(row_num, col_index);
					current_val = self.getCellData(row_num, col_index);
					if (original_val == current_val) {
						self.setCellData(row_num, col_index, row_data[key]);
					}
					self.setOriginalCellData(row_num, col_index, row_data[key]);
				}
			} else {
				if (self.hasExtraRowData(key)) {
					self.setExtraRowData(row_num, key, row_data[key]);
				} else if (self.hasColumn(key)) {
					self.setCellData(row_num, self.getColumnIndex(key), row_data[key]);
				}
			}
		}

		return true;
	};

	this._executeSaveREST = function(save_params, row_num, method, success_callback, error_callback) {
		var self = this, processed_sp;

		processed_sp = self._processSaveParams(save_params, row_num);
		save_params = processed_sp || save_params;

		// ARRGH! TODO: This is not going to properly distinguish between PUT save and POST save

		self.doREST(method || self.options.add_edit_action.method || 'PUT', self.options.add_edit_action.rest, save_params, {
			success: success_callback,
			error: error_callback
		});
	};

	this._processSaveParams = function (save_params, row_num) { return save_params; };

	this.getColumnData = function(column) {
		var self = this, col_data = [], cell_data, r_idx, num_rows;

		if (typeof column != 'string' && typeof column != 'number') {
			debugLog('cui.dataTableClass.js: Requested value for unknown column "' + column + '" in getColumnData().', true);
			return undefined;
		}

		if (typeof column == 'string') {
			column = self.getColumnIndex(column);
		}

		if (column > -1) {
			for (r_idx = 0, num_rows = self.getRowCount(); r_idx < num_rows; ++r_idx) {
				cell_data = self.getCellData(r_idx, column);
				if (parseInt(cell_data) == cell_data) {
					col_data.push(parseInt(cell_data));
				} else if (parseFloat(cell_data) == cell_data) {
					col_data.push(parseFloat(cell_data));
				} else {
					col_data.push(cell_data);
				}
			}
		}

		return col_data;
	};

	this.getAnyColumnData = function (column) {
		// This gets column data for a key that may be a real column or an extra_row_data column
		var self = this, val, col_data = [], r_idx;

		if (typeof column != 'string' && typeof column != 'number') {
			debugLog('cui.dataTableClass.js: Requested value for unknown column "' + column + '" in getAnyColumnData().', true);
			return undefined;
		}


		if (typeof column == 'string') {
			if (!self.hasExtraRowData(column) && !self.hasColumn(column)) {
				debugLog('cui.dataTableClass.js: Invalid column "' + column + '" specified in call to getAnyColumnData().', true);
				return undefined;
			}
			if (!self.hasExtraRowData(column)) {
				column = self.getColumnIndex(column);
			}
		}

		for (r_idx = 0; r_idx < self.getRowCount(); r_idx++) {
			if (typeof column === 'string' && self.hasExtraRowData(column)) {
				val = self.getExtraRowData(r_idx, column);
			} else {
				val = self.getCellData(r_idx, column);
			}
			col_data.push(val);
		}

		return col_data;
	};

	this.getAnyOriginalColumnData = function (column) {
		// This gets column data for a key that may be a real column or an extra_row_data column
		var self = this, val, r_idx, col_data = [];

		if (typeof column != 'string' && typeof column != 'number') {
			debugLog('cui.dataTableClass.js: Requested value for unknown column "' + column + '" in getAnyOriginalColumnData().', true);
			return undefined;
		}


		if (typeof column == 'string') {
			if (!self.hasExtraRowData(column) && !self.hasColumn(column)) {
				debugLog('cui.dataTableClass.js: Invalid column "' + column + '" specified in call to getAnyOriginalColumnData().', true);
				return undefined;
			}
			if (!self.hasExtraRowData(column)) {
				column = self.getColumnIndex(column);
			}
		}

		for (r_idx = 0; r_idx < self.getRowCount(); r_idx++) {
			if (typeof column === 'string' && self.hasExtraRowData(column)) {
				val = self.getOriginalExtraCellData(r_idx, column);
			} else {
				val = self.getOriginalCellData(r_idx, column);
			}
			col_data.push(val);
		}

		return col_data;
	};

	this.findRowId = function(col_name, col_val) {
		var self = this;
		var is_column = false, col, val, row_num, len;

		if (!self.getRowCount()) {
			return -1;
		}

		if (!self.hasColumn(col_name) && !self.hasExtraRowData(col_name)) {
			return -1;
		}

		if (!self.hasExtraRowData(col_name)) {
			is_column = true;
			col = self.getColumnIndex(col_name);
		}
		for (row_num = 0, len = self.getRowCount(); row_num < len; ++row_num) {
			val = '';
			if (is_column) {
				val = self.getCellData(row_num, col);
			} else {
				val = self.getExtraRowData(row_num, col_name);
			}
			if (val == col_val) {
				return row_num;
			}
		}

		return -1;
	};


	this.findRows = function(col_name, col_val) {
		var self = this;
		var is_column = false, col, rows, row_num, len, val;

		if (!self.getRowCount()) {
			return -1;
		}

		if (!self.hasColumn(col_name) && !self.hasExtraRowData(col_name)) {
			return -1;
		}

		if (!self.hasExtraRowData(col_name)) {
			is_column = true;
			col = self.getColumnIndex(col_name);
		}
		rows = [];
		for (row_num = 0, len = self.getRowCount(); row_num < len; ++row_num) {
			val = '';
			if (is_column) {
				val = self.getCellData(row_num, col);
			} else {
				val = self.getExtraRowData(row_num, col_name);
			}
			if (val == col_val) {
				rows.push(row_num);
			}
		}

		return rows;
	};


	this.addRow = function(row_data) {
		var self = this, $self = this.element || $(self), row, r, rlen, row_count, row_num, c, len, col_field, key_idx, key, ds, blank_row, col_name;

		if (!(row_data instanceof Array || (typeof row_data == 'object' && !$.isEmptyObject(row_data)))) {
			debugLog('cui.dataTableClass.js: Attempted to add a row using row data which is neither an array or object, or is empty in addRow():', true, row_data);
			return false;
		}

		row = row_data;
		if (row_data instanceof Array) {
			row = {};
			for (r = 0, rlen = row_data.length; r < rlen; ++r) {
				row[self.getColumnName(r)] = tryDecodeURIComponent(row_data[r]) || row_data[r];
			}
		}

		row_count = 0;
		row_num = self.options.row_count;
		self.options.row_count++;
		if (!self.isBeingAdded(row_num)) {
			self.options.original_row_count++;
		}
		if (self.options.data_source == 'ROW_SOURCE') {
			for (c = 0, len = self.options.columns.length; c < len; ++c) {
				col_field = self.options.columns[c].row_source;
				if (!self.options.data[col_field]) {
					self.options.data[col_field] = [];
				}
				self.options.data[col_field].push(row[col_field]);
				self.setOriginalCellData(row_num, c, row[col_field]);
			}

			if ($.isArray(self.options.extra_row_data)) {
				for (key_idx = 0; key_idx < self.options.extra_row_data.length; key_idx++) {
					key = self.options.extra_row_data[key_idx];
					self.options.data[key] = self.options.data[key] || [];
					self.options.data[key].push(row[col_field]);
					self.setOriginalExtraRowData(row_num, key, row[col_field]);
				}
			} else if (self.options.extra_row_data) {
				for (key in self.options.extra_row_data) {
					self.options.data[key] = self.options.data[key] || [];
					self.options.data[key].push(row[col_field]);
					self.setOriginalExtraRowData(row_num, key, row[col_field]);
				}
			}
		} else {
			ds = self.options.data_source;

			blank_row = {};
			for (c = 0; c < self.options.columns.length; c++) {
				col_name = self.getColumnName(c);
				blank_row[col_name] = row[col_name];
				self.setOriginalCellData(row_num, c, row[col_name]);
			}

			if ($.isArray(self.options.extra_row_data)) {
				for (key_idx = 0; key_idx < self.options.extra_row_data.length; key_idx++) {
					key = self.options.extra_row_data[key_idx];
					//		    blank_row[key] = row[key];
					self.setOriginalExtraRowData(row_num, key, row[key]);
				}
				blank_row = self.options.original_data[ds][row_num];
			} else if (self.options.extra_row_data) {
				for (key in self.options.extra_row_data) {
					blank_row[key] = row[key];
					self.setOriginalExtraRowData(row_num, key, row[key]);
				}
			}
			if (!self.options.data) {
				self.options.data = {};
			}
			if (!self.options.data[ds]) {
				self.options.data[ds] = [];
			}
			self.options.data[ds].push($.extend(true, {}, blank_row));
		}

		$self.triggerHandler('addRow');
		self._afterAddRow(row, row_num);
		return row_num;
	};

	// This is used a lot of places and needs to remain
	this._afterAddRow = function(row_data, index) { };

	this.addEmptyRow = function() {
		var self = this, empty_row = [], c, len, index;

		for (c = 0, len = self.options.columns.length; c < len; ++c) {
			empty_row.push('');
		}

		self._setBeingAdded(self.options.row_count, true);

		index = self.addRow(empty_row);

		self._afterAddEmptyRow(empty_row, index);
		return index;
	};

	// This is used a lot of places and needs to remain
	this._afterAddEmptyRow = function(row_data, index) { };

	this._multiDeleteComplete = function(row_indices, $trs, success_callback, error_callback, being_added, being_edited, response, status, error) {
		var self = this, ds = self.options.data_source, success_count = 0, error_map = {}, data = {}, error_rows = [], eidx, kidx, value, ridx, num, row_data, pkidx, pk, pkval, row_failed_to_delete;

		if (!self.options.primary_keys.length) {
			debugLog('No primary keys defined for DTW');
			self.refresh();
			return;
		}

		// Let's generate an error map so we can lookup what rows didn't delete by their primary key
		if (response && response.responseText) {
			data = $.parseJSON(response.responseText);
			if (data && data.error && data.error.multi_delete_errors) {
				for(eidx in data.error.multi_delete_errors) { // eidx is Error Index
					error = data.error.multi_delete_errors[eidx];
					for (kidx in error) { // kidx is Key Index
						value = error[kidx]; // Value is the primary key of the failed delete
						if (kidx == 'error') {continue;} // we don't want the error key

						error_map[kidx] = error_map[kidx] || {};
						error_map[kidx][value] = error; // { error: 'WHATEVER', bbx_user_id: 99 }

					}
				}
			}
		}

		row_indices.sort(); // Ensure row nums are ascending

		for (ridx = 0; ridx < row_indices.length; ridx++) {
			// After every SUCCESSFUL row delete, the dtw row index for each subsequent row will be one less. This accounts for that.
			// i.e. If we wanna delete rows [1, 2, 3], we actually just want to delete row 1, 3 times.
			num = row_indices[ridx] - success_count;
			row_data = self.getAllRowData(num);
			row_failed_to_delete = false;


			for (pkidx in self.options.primary_keys) {
				pk = self.options.primary_keys[pkidx];
				pkval = row_data[pk]; // Just the ID
				if (error_map[pk] && error_map[pk][pkval]) {
					row_failed_to_delete = true;
					// { _name: '...', error: '...' } (and some other stuff)
					error_rows.push($.extend({ _name: self.getNameOfRow(num) }, error_map[pk][pkval])); // Save the row_data of the failed row delete in this array
					self._afterDeleteRow(num, false, $trs);
				}
			}

			if (!row_failed_to_delete) {
				success_count++;
				self.options.data[ds].splice(num, 1);

				self.options.original_row_count--;
				if (typeof success_callback == 'function') {
					success_callback(num, $trs);
				}
				if (self.element) {
					self.element.triggerHandler('deleteRow');
				}
				self._afterDeleteRow(num, true, $trs.eq(ridx));
				if (being_added) {
					self._setBeingAdded(num, false);
				}
				if (being_edited) {
					self._setBeingEdited(num, false);
				}
			}
		}

		// Call the dialog if we have errors
		if (error_rows.length && typeof(error_callback)) {
			error_callback(response, status, error, undefined, $trs, error_rows);
		}
	};

	this._getDeleteSuccessCallbackRef = function(row_num, $trs, success_callback, being_added, being_edited, is_lt_remove) {
		var self = this;
		return (function() {
			var c, len, key, ds, ridx, num;
			if (self.options.live_table && !is_lt_remove) {
				// This means that this is the table-button "delete" mechanism trying to clear the row on a live table.
				// Since this is a livetable, this will collide with the livetable clearing the row when it gets the message.
				// So, we should wait until the LT says "delete" (in _LTRemoveRow) before we delete the row for real.
				return;
			} else if (self.options.data_source == 'ROW_SOURCE' ) {
				for (c = 0, len = self.options.columns.length; c < len; ++c) {
					key = self.options.columns[c].row_source;
					if (self.options.data[key] && row_num in self.options.data[key]) {
						self.options.data[key].splice(row_num, 1);
					}
				}
			} else {
				ds = self.options.data_source;
				//		debugLog('Deleting row ' + row_num + ' from ', self.options.data[ds]);
				if (self.options.data[ds] && row_num in self.options.data[ds]) {
					if (!$.isArray(row_num)) {
						// Only do this if we have a single row_num, not an array. Arrays for multi-delete are handled below
						self.options.data[ds].splice(row_num, 1);
					}

					//		    debugLog('Now it is: ', self.options.data[ds]);
				}
			}
			//	    debugLog('Done deleting the row.');

			if ($.isArray(row_num)) {
				row_num.sort(); // Ensure row nums are ascending
				for(ridx in row_num) {
					// After every row delete, the dtw row index for each subsequent row will be one less. This accounts for that.
					// i.e. If we wanna delete rows [1, 2, 3], we actually just want to delete row 1, 3 times.
					num = row_num[ridx] - ridx;

					self.options.data[ds].splice(num, 1);

					self.options.original_row_count--;
					if (typeof success_callback == 'function') {
						success_callback(num, $trs);
					}
					if (self.element) {
						self.element.triggerHandler('deleteRow');
					}
					self._afterDeleteRow(num, true, $trs.eq(ridx));
					if (being_added) {
						self._setBeingAdded(num, false);
					}
					if (being_edited) {
						self._setBeingEdited(num, false);
					}
				}
			} else {
				self.options.original_row_count--;
				if (typeof success_callback == 'function') {
					success_callback(row_num, $trs);
				}
				if (self.element) {
					self.element.triggerHandler('deleteRow');
				}
				self._afterDeleteRow(row_num, true, $trs);
				if (being_added) {
					self._setBeingAdded(row_num, false);
				}
				if (being_edited) {
					self._setBeingEdited(row_num, false);
				}
			}
		});
	};

	this._getDeleteErrorCallbackRef = function(row_num, $trs, error_callback) {
		var self = this;
		return (function(jxHQR, status, error) {
			if (typeof error_callback == 'function') {
				error_callback(jxHQR, status, error, row_num, $trs);
			}
			self._afterDeleteRow(row_num, false, $trs);
		});
	};


	this._LTRemoveRow = function(row_num) {
		// _removeRow is used to simply remove the row without performing a delete operation. This is used, for instance, on a live-table delete, when
		// the call signifies that the row has been deleted, not that it needs to be deleted.
		var self = this, being_added, being_edited;
		being_added = self.isBeingAdded(row_num);
		being_edited = self.isBeingEdited(row_num);
		( self._getDeleteSuccessCallbackRef(row_num, undefined, undefined, being_added, being_edited, true) )();
	};

	this.deleteRow = function(row_num, success_callback, error_callback, $trs) {
		var self = this, r, row, being_added, being_edited, delete_params, delete_success_callback, multi_delete_complete_callback, delete_error_callback;

		//	debugLog('Inside deleteRow with row_num: ' + row_num);

		if ($.isArray(row_num)) {
			for (r in row_num) {
				row = row_num[r];

				if (!self.validRowNum(row)) {
					debugLog('cui.dataTableClass.js: Attempted to get post data for row index "' + row_num + '" which is out of range or invalid in deleteRow.', true);
					return false;
				}
			}
		} else {
			if (!self.validRowNum(row_num)) {
				debugLog('cui.dataTableClass.js: Attempted to get post data for row index "' + row_num + '" which is out of range or invalid in deleteRow.', true);
				return false;
			}
		}

		being_added = self.isBeingAdded(row_num);
		being_edited = self.isBeingEdited(row_num);

		if (!being_added && self.options.delete_action) {
			delete_params = self._getDeleteParams(row_num);
		}

		delete_success_callback = self._getDeleteSuccessCallbackRef(row_num, $trs, success_callback, being_added, being_edited);

		if (!being_added && self.options.delete_action) {
			if ($.isArray(row_num)) {
				// With multi-delete, we must assume some deletes succeed and some fail; so we must use a generic 'complete' callback
				multi_delete_complete_callback = self._multiDeleteComplete.bind(self, row_num, $trs, success_callback, error_callback, being_added, being_edited);

				self._executeDeleteREST(delete_params, row_num, multi_delete_complete_callback, multi_delete_complete_callback);
			} else {
				delete_error_callback = self._getDeleteErrorCallbackRef(row_num, $trs, error_callback);

				self._executeDeleteREST(delete_params, row_num, delete_success_callback, delete_error_callback);
			}
		} else {
			delete_success_callback();
		}
		return true;
	};

	this._afterDeleteRow = function(row_num, succeeded, $tr) { };

	this._executeDeleteREST = function(delete_params, row_num, success_callback, error_callback) {
		var self = this;
		self.doREST(self.options.delete_action.method || 'DELETE', self.options.delete_action.rest, delete_params, {
			success: success_callback,
			error: error_callback
		});
	};

	this._getDeleteParams = function(row_num) {
		var self = this, primary_keys, post_params, r, row, row_params, k, key;

		// This is if we've been passed an array of row_nums, for when we're deleting several rows at once
		if ($.isArray(row_num) && self.options.primary_keys) {
			primary_keys = {};

			// Init post_params
			post_params = self._getBaseParams(row_num[0], self.options.delete_action);

			for (r in row_num) {
				row = row_num[r];

				row_params = self._getBaseParams(row, self.options.delete_action);

				for (k in self.options.primary_keys) {
					key = self.options.primary_keys[k];
					if (!primary_keys[key]) {
						primary_keys[key] = [];
					}
					primary_keys[key].push(row_params[key]);
				}
			}

			$.extend(post_params, primary_keys);
		} else {
			post_params = self._getBaseParams(row_num, self.options.delete_action);
		}

		post_params = self._processSubmitParams(post_params);

		self.options.delete_action.rest = self.options.delete_action.rest || self.options.rest;
		return post_params;
	};

	// Get basic params (restParams, params) for use in both DELETE and UPDATE operations. action_spec param should be the object (not the name) of the
	// sub-options for the action (delete_action or add_edit_action).
	this._getBaseParams = function (row_num, action_spec) {
		var self = this, post_params = {}, key, k_idx, row_data, pp_copy = {};

		action_spec = action_spec || self.options;

		for (key in self.options.rest_params) {
			if ($.inArray(key, ['page','rows','sortby','sort_by','searchregexp','searchstring','key']) !== -1) {
				continue;
			}

			if (action_spec.only_rest_params) {
				if ($.inArray(key, action_spec.only_rest_params) > -1) {
					post_params[key] = self.options.rest_params[key];
				}
			} else if (action_spec.filter_rest_params) {
				if ($.inArray(key, action_spec.filter_rest_params) == -1) {
					post_params[key] = self.options.rest_params[key];
				}
			} else {
				post_params[key] = self.options.rest_params[key];
			}
		}

		if ((action_spec.include_primary_keys === undefined || action_spec.include_primary_keys === true) && self.options.primary_keys) {
			row_data = self.getAllRowData(row_num);

			for (k_idx = 0; k_idx < self.options.primary_keys.length; k_idx++) {
				key = self.options.primary_keys[k_idx];
				if (key in row_data) {
					post_params[key] = row_data[key];
				}
			}
		}

		if (action_spec.include_row_data) {
			row_data = row_data || self.getAllRowData(row_num);
			for (k_idx = 0; k_idx < action_spec.include_row_data.length; k_idx++) {
				key = action_spec.include_row_data[k_idx];
				if (key in row_data) {
					post_params[key] = row_data[key];
				}
			}
		}


		if (action_spec.only_params) {
			for (k_idx=0; k_idx < action_spec.only_params.length; k_idx++) {
				key = action_spec.only_params[k_idx];
				if (key in post_params) {
					pp_copy[key] = post_params[key];
				}
			}
			post_params = pp_copy;
		} else if (action_spec.filter_params) {
			for (k_idx=0; k_idx < action_spec.filter_params.length; k_idx++) {
				delete post_params[action_spec.filter_params[k_idx]];
			}
		}

		return post_params;
	};

	// Format null/undefined values
	this._processSubmitParams = function (post_params) {
		var self = this, pp_k;
		if (self.options.null_value !== undefined) {
			for (pp_k in post_params) {
				if (post_params[pp_k] === null || post_params[pp_k] === undefined) {
					if (self.options.null_value === '_OMIT_') {
						delete post_params[pp_k];
					} else {
						post_params[pp_k] = self.options.null_value;
					}
				}
			}
		}
		return post_params;
	};
};
