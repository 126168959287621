/* jshint jquery: true, unused: vars */
/* global add_widget */
(function( $ ){
    // based off of mastheadWidget
    //
    var phoneModelMastheadWidget = $.extend({}, $.ui.widget.prototype, {
	options: {
	    template_html: '<span class="widgetType widget" name="bbx_phone_manufacturer_name" /> &mdash; <span class="name widgetType widget" name="bbx_phone_model_name" />',
	    accept_data_from_parent: true
	}
    });

    add_widget('phoneModelMastheadWidget', $.extend({}, $.ui.widget.prototype, phoneModelMastheadWidget));
})(jQuery);
