/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){
	var resizerWidget = $.extend({}, $.ui.widget.prototype, CUI.htmlEntityClass, {

		options: {
			accept_data_from_parent: true,
			closest: '.containerWidget',
			min_font_size: 63,
			max_font_size: 275,
			bubble_height: 5.5,
			bubble_width: 35,
			position: 'absolute'
		},

		_afterInit: function () {
			var self = this;
			var $self = this.element;

			$self.addClass('wallboardResizerWidgetType');

			self.options.old_win_height = 0;
			self.options.old_win_width = 0;

			if (self.options.max_font_size_docked) {
				if (self.options.max_font_size_fullscreen) {
					self.options.max_font_size = Math.max(self.options.max_font_size_docked, self.options.max_font_size_fullscreen);
				} else {
					self.options.max_font_size = self.options.max_font_size_docked;
				}
			} else if (self.options.max_font_size_fullscreen) {
				self.options.max_font_size = self.options.max_font_size_fullscreen;
			}

			self.options.$pageWidget = $self.closest('.pageWidgetType');
			var default_opts = {};
			var resizeCallback = self._resizeCallback.bind(self);

			$.extend(default_opts, self.options);
			self.options.default_opts = default_opts;

			var readyFunc = function() {
				if (self.options.closest === 'parent') {
					self.options.$base = $self.parent();
				} else {
					self.options.$base = $self.closest(self.options.closest);
				}
				self.options.$dtw = self.options.$base.find('.dataTableWidgetType,.wallboardDataTableWidgetType');
				self.options.dtw = self.options.$dtw.getCUIWidget('dataTableWidget') || self.options.$dtw.getCUIWidget('wallboardDataTableWidget');
				self.options.$content = self.options.$base.parent();
				self.options.$parent = $self.parent();

				self._calculateCharSizes();

				self._bind(self.options.$dtw, 'liveInit liveDel liveAdd liveBootstrapData liveClear filterChange', resizeCallback);
				self._bind(self.options.$dtw, 'liveModify', function() {
					if (self.options.dtw.options.resize) {
						self.options.dtw.options.resize = false;
						self._resizeCallback();
					}
				});

				self._bind(self.options.$dtw, 'clear', resizeCallback);

				if (self.options.monitor_container) {
					if (!self.options.$mon_container) {
						if (self.options.$base.is(self.options.monitor_container.selector)) {
							self.options.$mon_container = self.options.$base;
						} else {
							self.options.$mon_container = self.options.$base.find(self.options.monitor_container.selector);
						}
					}
					self._bind(self.options.$mon_container, 'classChange', resizeCallback);
				}
				self._bind($('body'), 'classChange', resizeCallback);
				self._bind($(window), 'resize', resizeCallback);
				self._bind(self.options.$base, 'classChange', resizeCallback);
				if (self.options.resize_on_resizer) {
					self._bind($self.closest('.screen,.overlay'), 'resizerResize', resizeCallback);
				}
			};
			var pageWidget = self.options.$pageWidget.getCUIWidget();
			if (pageWidget && pageWidget.isScreenReady()) {
				readyFunc();
			} else {
				self._one(self.options.$pageWidget, 'screenReady', readyFunc);
			}

			return false;
		},

		_destroy: function() {
			var self = this;
			var $self = this.element;

			delete self.options.$pageWidget;
			delete self.options.$base;
			delete self.options.$dtw;
		},

		_calculateCharSizes: function() {
			var self = this;
			var $self = this.element;

			self.options.size = {};

			var $test_div = $('<div>abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789(),- </div>');

			// Feature-sniff and get the DTW style
			var dtw_style = self.options.$dtw[0].currentStyle ? self.options.$dtw[0].currentStyle : getComputedStyle(self.options.$dtw[0]);

			// There's got to be a font-family around here somewhere
			var dtw_font = self.options.font_family || dtw_style.fontFamily || '';

			$test_div[0].style.fontSize = self.options.min_font_size + '%';
			$test_div[0].style.fontFamily = dtw_font;
			$test_div[0].style.position = self.options.position;
			$test_div[0].style.left = '-9999em';

			$test_div.attr('style', $test_div.attr('style') + '; line-height: 100% !important'); // Important rules are not supported in .css()

			$('body').append($test_div);
			self.options.size[self.options.min_font_size] = {
				width: (Math.floor(parseInt($test_div.innerWidth())) / 67),
				height: parseInt($test_div.innerHeight())
			};

			for (var sz = self.options.min_font_size+1; sz <= self.options.max_font_size; sz++) {
				$test_div.css('font-size', sz + '%');
				self.options.size[sz] = {
					width: (Math.floor(parseInt($test_div.innerWidth())) / 67),
					height: parseInt($test_div.innerHeight())
				};
				//debugLog('At font size of ' + sz + '%, the font averages ' +
				//	 self.options.size[sz].width + 'px wide and ' +
				//	 self.options.size[sz].height + 'px high.');
			}
			$test_div.remove();
		},

		/**
	 * Calculate how many lines of text will fit in the height of the screen for each font size.
	 */
		_calculateMaxLines: function() {
			var self = this;
			var $self = this.element;

			var win_height = self.options.win_height;

			if (self.options.lines) {
				delete self.options.lines;
			}
			self.options.lines = {};

			for (var sz = self.options.min_font_size; sz <= self.options.max_font_size; sz++) {
				//		debugLog('At size of ' + sz + ' with window height of ' + win_height + ' you get ' + (win_height / self.options.size[sz].height) + ' lines.');
				self.options.lines[sz] = win_height / self.options.size[sz].height;
			}
		},

		/**
	 * Calculate how many characters will fit in the width of the screen for each font size.
	 */
		_calculateMaxCols: function() {
			var self = this;
			var $self = this.element;

			var win_width = self.options.win_width;

			if (self.options.cols) {
				delete self.options.cols;
			}
			self.options.cols = {};

			for (var sz = self.options.min_font_size; sz <= self.options.max_font_size; sz++) {
				self.options.cols[sz] = win_width / self.options.size[sz].width;
			}
		},

		/**
	 * Calculate how many rows and columns of bubbles will fit on the screen for each font size.
	 */
		_calculateBubbleRowColumns: function() {
			var self = this;
			var $self = this.element;

			var win_height = self.options.win_height;
			var win_width = self.options.win_width;
			self._calculateMaxCols();   // Returns width of window, too
			self._calculateMaxLines(); // Returns height of window, too

			if (self.options.bubbles) {
				delete self.options.bubbles;
			}
			self.options.bubbles = {};

			for (var sz = self.options.min_font_size; sz <= self.options.max_font_size; sz++) {
				var rows = Math.floor(self.options.lines[sz] / self.options.bubble_height);
				//		debugLog('At font size of ' + sz + ' you get ' + self.options.lines[sz] + ' lines and ' + rows + ' of bubbles.');
				var cols = Math.floor((self.options.max_columns ? self.options.max_columns : self.options.cols[sz] / self.options.bubble_width));
				self.options.bubbles[sz] = {
					rows: rows,
					cols: cols,
					width: Math.floor(win_width / cols),
					height: Math.floor(win_height / rows),
					total: rows * cols,
					font_size_percent: sz,
					font_size_px: self.options.size[sz].height + 'px',
					font_width: self.options.size[sz].width,
					font_height: self.options.size[sz].height
				};
				//		debugLog('At font size of ' + self.options.bubbles[sz].font_height + ', we can fit ' + self.options.bubbles[sz].total + ' elements on the screen.');
			}
		},

		/**
	 * Determine the optimal size of the bubbles to fit everything on the screen
	 */
		_calculateOptimalSize: function() {
			var self = this;
			var $self = this.element;
			var sz, bubble_width;

			if (self.options.old_win_width != self.options.win_width ||
				self.options.old_win_height != self.options.win_height)
			{
				//		debugLog('Old width: ' + self.options.old_win_width + " != " + self.options.win_width + ' OR Old height ' +
				//			 self.options.old_win_height + " != " + self.options.win_height);
				self._calculateBubbleRowColumns();
			}

			var win_width = self.options.win_width;

			var max_font_size = self.options.max_font_size;
			var auto_adjust_max_width = null;
			if ($('body').hasClass('fullscreen')) {
				if (self.options.max_font_size_fullscreen) {
					max_font_size = self.options.max_font_size_fullscreen;
				}
				if (self.options.auto_adjust_max_width_fullscreen) {
					auto_adjust_max_width = self.options.auto_adjust_max_width_fullscreen;
				}
			} else {
				if (self.options.max_font_size_docked) {
					max_font_size = self.options.max_font_size_docked;
				}
				if (self.options.auto_adjust_max_width_docked) {
					auto_adjust_max_width = self.options.auto_adjust_max_width_docked;
				}
			}


			if (self.options.max_columns && self.options.max_columns == 1 && auto_adjust_max_width)
			{
				var max_width = auto_adjust_max_width;
				if (max_width[max_width.length - 1] == '%') {
					max_width = Math.floor(($(window).width() * (parseInt(max_width) / 100)));
				}
				if (self.options.max_width) {
					max_width = Math.min(self.options.max_width, max_width);
				}
				//		debugLog('Auto adjust max width: ', max_width);
				var test_max_size = self.options.min_font_size;
				for (sz = self.options.min_font_size+1; sz <= max_font_size; sz++) {
					//		    debugLog('At size ' + sz + ' font width is ' + self.options.size[sz].width + ' with ' + self.options.bubble_width + ' characters per element for element width of ' + (self.options.size[sz].width * self.options.bubble_width));
					if (sz > test_max_size && (self.options.size[sz].width * self.options.bubble_width) < max_width) {
						test_max_size = sz;
					}
				}
				max_font_size = Math.min(max_font_size, test_max_size);
				//		debugLog('Auto adjust max font size: ', max_font_size);
			}

			var elements = self.options.elements;
			var max_font_size_allowed = self.options.min_font_size;

			//	    debugLog('Options: ', self.options);
			for (sz = self.options.min_font_size; sz <= max_font_size; ++sz) {

				if (!(self.options.max_columns && self.options.max_columns == 1) &&
					self.options.bubbles[sz].total >= elements && sz > max_font_size_allowed &&
					self.options.bubbles[sz].cols >= 1 && self.options.bubbles[sz].rows >= 1)
				{

					max_font_size_allowed = sz;
				}
				if (self.options.max_columns && self.options.max_columns == 1) {
					bubble_width = self.options.bubbles[sz].font_width * self.options.bubble_width;
					if (bubble_width < win_width) {
						max_font_size_allowed = sz;
					} else {
						break;
					}
				}
			}

			//	    debugLog('Originally, we picked ' + max_font_size_allowed + ' for size to display ' + elements + ' bubbles.');
			// They already have to scroll, so see if we can at least make the bubbles
			// fit the screen width better
			if (max_font_size_allowed == self.options.min_font_size) {
				var cols = self.options.bubbles[max_font_size_allowed].cols;
				for (sz = self.options.min_font_size; sz <= max_font_size; ++sz) {
					bubble_width = self.options.bubbles[sz].font_width * self.options.bubble_width;
					if (self.options.bubbles[sz].cols == cols && bubble_width < win_width) {
						max_font_size_allowed = sz;
					} else {
						break;
					}
				}
			}
			//	    debugLog('After re-considering, we picked ' + max_font_size_allowed + ' for size.');

			return self.options.bubbles[max_font_size_allowed];
		},

		_resizeCallback: function (e) {
			var self = this;

			self.options._initial_resize = self.options._initial_resize || 0;

			if (self.options._initial_resize++ < 5) {
				self._resizeCallbackSinglePass();
				self._resizeCallbackSinglePass();
			} else if (!self.options._rc_timeout) {
				self.options._rc_timeout = setTimeout( function () {
					delete self.options._rc_timeout;
					// Do two passes, since scrolling might not show up in the first pass
					self._resizeCallbackSinglePass();
					self._resizeCallbackSinglePass();
				}, 500);
			}
		},

		_resizeCallbackSinglePass: function () {
			var self = this;
			var $self = this.element;

			// Can run async via setTimeout, so be sure it's not destroyed
			if (self.options.destroyed) { return; }

			// Due to the fact that the bubbles are all "absolute", the container has no size until we set one.
			if ($('body:not(.fullscreen)')[0]) {
				$self[0].style.height = '';
				var ht = $('#content-inner').innerHeight();
				$self[0].style.height = (ht || 0) + 'px';
			} else {
				// In fullscreen mode, this is not an issue
				$self[0].style.height = '';
			}

			var resizeCallback = self._resizeCallback.bind(self);

			if (!self.options.dtw) {
				self._one(self.options.$pageWidget, 'screenReady', resizeCallback);
				return;
			}

			var $node = self.options.$base;
			var count = 0;
			self.options.scrolling = false;
			while (!$node.hasClass('screen') && !self.options.scrolling && count < 10) {
				//debugLog('Node: ', $node[0].clientHeight, $node[0].scrollHeight, $node);
				if ($node[0].clientHeight < $node[0].scrollHeight) {
					self.options.scrolling = true;
				} else {
					self.options.scrolling = false;
				}
				$node = $node.parent();
				count++;
			}
			//debugLog('Scrolling: ', false, self.options.scrolling);

			if (self.options.monitor_container) {
				$.extend(self.options, self.options.default_opts);
				if (!self.options.$mon_container) {
					if (self.options.$base.is(self.options.monitor_container.selector)) {
						self.options.$mon_container = self.options.$base;
					} else {
						self.options.$mon_container = self.options.$base.find(self.options.monitor_container.selector);
					}
				}
				for (var css_class in self.options.monitor_container.css_classes) {
					var class_settings = self.options.monitor_container.css_classes[css_class];
					if (self.options.$mon_container.hasClass(css_class)) {
						$.extend(self.options, class_settings);
					}
				}
			}

			self.options.win_height = ($(window).height() - parseInt(self.options.$base.offset().top) - 20);
			self.options.win_width = parseInt(self.options.$base.innerWidth());
			if (self.options.scrolling) {
				self.options.win_width -= 30;
			}
			if (!self.options.win_height || !self.options.win_width) {
				return;
			}

			// Always use an actual tr count because it is always accurate.
			self.options.elements = self.options.$dtw.find('tr:not(.offline)').length;

			if (self.options.old_win_width != self.options.win_width ||
				self.options.old_win_height != self.options.win_height ||
				self.options.elements != self.options.old_elements)
			{
				//		debugLog('Old width: ' + self.options.old_win_width + " != " + self.options.win_width + ' OR Old height ' +
				//			 self.options.old_win_height + " != " + self.options.win_height + ' OR Old elems: ' +
				//			 self.options.old_elements + " != " + self.options.elements);
				self.options.size_info = self._calculateOptimalSize();
				self.options.displayed_rows = Math.ceil(self.options.elements / self.options.size_info.cols);
				self.options.old_win_width = self.options.win_width;
				self.options.old_win_height = self.options.win_height;
				self.options.old_elements = self.options.elements;
			}

			if (self.options.size_info) {
				self._resizeElements(self.options.size_info);
			}
		},

		_resizeElements: function(size_info) {
			var self = this;
			var $self = this.element;

			var win_height = self.options.win_height;
			var win_width = self.options.win_width;

			// Basing bubble width and height on font size may not make the
			// bubble as big as we can safely make it. Give it extra room if
			// there is extra room to spare.
			var bubble_width = self.options.bubble_width * size_info.font_width;
			var bubble_width_alt = win_width / size_info.cols;
			bubble_width = Math.max(bubble_width, bubble_width_alt);
			//	    debugLog(self);
			if (self.options.max_width) {
				bubble_width = Math.min(bubble_width, self.options.max_width);
				//		debugLog("Constrained width: " + self.options.max_width);
			}

			var bubble_height = size_info.height;
			var bubble_height_alt = win_height / size_info.rows;
			bubble_height = Math.max(bubble_height, bubble_height_alt);
			if (self.options.max_height) {
				bubble_height = Math.min(bubble_height, self.options.max_height);
			}

			if (self.options.max_columns && self.options.max_columns == 1 && self.options.auto_adjust_width) {
				var container_width = Math.floor(bubble_width + (0.05 * bubble_width));
				//		debugLog('Bubble width: ' + (self.options.bubble_width * size_info.font_width) + ' ; Container width: ' + (Math.floor(bubble_width + (.05 * bubble_width))));
				self.options.$base[0].style.width = container_width + 'px';
			}

			//debugLog("Using the following size info: ", size_info);

			var view_width = parseInt(self.options.$content.width());

			var left_side = 0;
			if (self.options.align == 'right') {
				left_side = view_width - parseInt(self.options.$base.width());
			}

			// Don't forget to account for border widths, padding, and margins
			// for the height and width calculations.
			var elem = 0;
			//debugLog("DTW: ", self.options.$dtw);
			var new_height = ((size_info.height + 9) * (self.options.displayed_rows) + 26);
			if (isNaN(new_height) || new_height == 26) {
				new_height = self.options.$dtw.height();
			}

			self._trResizeCallback = function () {
				var e_col = (elem % size_info.cols);
				var e_row = Math.floor(elem / size_info.cols);
				this.style.width = (bubble_width - 10) + 'px';
				this.style.height = (size_info.height - 9) + 'px';
				this.style.position = self.options.position;
				this.style.left = ((e_col * bubble_width) + left_side) + 'px';
				this.style.top = (e_row * size_info.height) + 'px';
				elem = elem + 1;
			};

			//debugLog('Setting height to: ', new_height, self.options.displayed_rows, self.options.$parent, self.options.$dtw);
			self.options.$parent.height(new_height);
			self.options.$dtw[0].style.fontSize = size_info.font_size_px;
			if (self.options.font_family) {
				self.options.$dtw[0].style.fontFamily = self.options.font_family;
			}
			self.options.$dtw[0].style.lineHeight = size_info.font_size_px;
			self.options.$dtw[0].style.top = '0px';
			self.options.$dtw[0].style.left = '0px';
			self.options.$dtw[0].style.position = self.options.position;
			self.options.$dtw.height(new_height);
			self.options.$dtw[0].width = (self.options.$dtw.parent().width()) + 'px';
			self.options.$dtw.find('tr').not((self.options.include_offline ? '' : '.offline')).each(self._trResizeCallback).find('td').each(function() {
				this.style.width = (bubble_width - 10) + 'px';
			});

			if (navigator.userAgent.indexOf('MSIE') !== -1) {
				elem = 0;
				self.options.$dtw.find('tr').not((self.options.include_offline ? '' : '.offline')).each(function() {
					var e_row = Math.floor(elem / size_info.cols);
					this.style.height = size_info.height + 'px';
					this.style.top = (e_row * (size_info.height + 1)) + 'px';
					elem = elem + 1;
				}).find('td').each(function() {
					this.style.width = (bubble_width - 10) + 'px';
				});
			}
			$self.trigger('resizerResized');
		}
	});
	add_widget('wallboardResizerWidget', resizerWidget);
})(jQuery);
