(function () {
    var contact_value_switch_elements = {
	'TEL': {
	    entity_template: 'input_item',
	    title: 'Number:',
	    type: 'text',
	    input_name: 'bbx_contact_label_value',
	    validate: { conditions: '_PHONE_NUMBER_DIGITS_', message: 'Please enter a valid phone number' }
	},
	'ADR': {
	    entity_template: 'container',
	    elements: [
		{
		    entity_template: 'input_item',
		    title: 'P.O. Box:',
		    type: 'text',
		    input_name: 'bbx_contact_label_addr_pobox'
		},
		{
		    entity_template: 'input_item',
		    title: 'Attn:',
		    type: 'text',
		    input_name: 'bbx_contact_label_addr_attn'
		},
		{
		    entity_template: 'input_item',
		    title: 'Street:',
		    type: 'text',
		    input_name: 'bbx_contact_label_addr_street',
		    validate: { conditions: '_TRUEISH_', message: 'Required field' }
		},
		{
		    entity_template: 'input_item',
		    title: 'City:',
		    type: 'text',
		    input_name: 'bbx_contact_label_addr_city',
		    validate: { conditions: '_TRUEISH_', message: 'Required field' }
		},
		{
		    entity_template: 'input_item',
		    title: 'State/Prov:',
		    type: 'text',
		    input_name: 'bbx_contact_label_addr_state',
		    validate: { conditions: '_TRUEISH_', message: 'Required field' }
		},
		{
		    entity_template: 'input_item',
		    title: 'ZIP/Postcode:',
		    type: 'text',
		    input_name: 'bbx_contact_label_addr_zip',
		    validate: { conditions: '_TRUEISH_', message: 'Required field' }
		},
		{
		    entity_template: 'input_item',
		    title: 'Country:',
		    type: 'text',
		    input_name: 'bbx_contact_label_addr_country',
		    validate: { conditions: '_TRUEISH_', message: 'Required field' }
		}
	    ]
	},
	'ORG': {
	    entity_template: 'input_item',
	    title: 'Name:',
	    type: 'text',
	    input_name: 'bbx_contact_label_value'
	},
	'EMAIL': {
	    entity_template: 'input_item',
	    title: 'Email:',
	    type: 'text',
	    input_name: 'bbx_contact_label_value',
	    validate: { conditions: '_EMAIL_', message: 'Please enter a valid email address' }
	},
	'IMPP': {
	    entity_template: 'input_item',
	    title: 'Username:',
	    type: 'text',
	    input_name: 'bbx_contact_label_value'
	}
    };

    var contact_property_switch_elements = {
	'TEL': {
	    entity_template: 'check_to_list',
	    name: 'bbx_contact_label_type',
	    values: [
		{ label: 'Work',   value: 'work' },
		{ label: 'Home',   value: 'home' },
		{ label: 'Mobile', value: 'mobile' },
		{ label: 'Fax',    value: 'fax' }
	    ]
	},
	'ADR': {
	    entity_template: 'check_to_list',
	    name: 'bbx_contact_label_type',
	    values: [
		{ label: 'Work',   value: 'work' },
		{ label: 'Home',   value: 'home' }
	    ]
	},
	'IMPP': {
	    entity_template: 'check_to_list',
	    name: 'bbx_contact_label_type',
	    values: [
		{ label: 'AIM Messenger',    value: 'aim' },
		{ label: 'ICQ',              value: 'icq' },
		{ label: 'XMPP',             value: 'xmpp' },
		{ label: 'Yahoo! Messenger', value: 'yahoo' }
	    ]
	}
    };

    register_screen('user_detail', {
	rest: '/gui/extension/user',
	rest_container: 'user',
	dataEvents: {
	    bind: {
		'primary_extension': '',
		'user': '',
		'phone_assign': ''
	    },
	    trigger: 'user'
	},
	elements: [
	    { entity_template: 'masthead' },
	    {
		entity_template: 'page_buttons',
		buttons: [
		    { type: 'extension', extension_type: 'user' },
		    { type: 'impersonate' },
		    {
			type: 'delete',
			rest: '/gui/extension/user',
			fields: 'bbx_user_id',
			confirm: { text: 'Are you sure you would like to remove this person from the system?' }
		    },
		    { type: 'cancel' },
		    { type: 'save' }
		]
	    },
	    {
		entity_template: 'page_content', elements: [
		    {
			entity_template: 'page_module',
			legend: 'Basic Information',
			buttons: ['help'],
			help_id: 'extensions_basic_information',
			form_action: '/gui/extension/user',
			form_data_js: { submit_all: false },
			elements: [
			    {
					entity_template: 'input_item',
					title: 'First Name:',
					type: 'text',
					input_name: 'bbx_contact_first_name',
					input_options: {
					    widgets: ['aopbStateWidget'],
					    attributes: { maxlength: '255' },
					    widget_options: {
						aopbStateWidget: {
							states:[
								{
									conditions: [
										{             a: '$$', op: '!=', b: '_SAFE_CHARACTERS_' },
										{ join: 'or', a: '$$', op: '==', b: '(^\\W|\\W$)', b_is: 'regexp' }
									],
									actions: { invalid: 'A Valid First Name is required' },
									else_actions: { invalid: false }
								}
							]
						}
					    }
					}
				},

				{
					entity_template: 'input_item',
					title: 'Last Name:',
					type: 'text',
					input_name: 'bbx_contact_last_name',
					input_options: {
						widgets: ['aopbStateWidget'],
						attributes: { maxlength: '255' },
						widget_options: {
							aopbStateWidget: {
								states:[
									{
										conditions: [
											{             a: '$$', op: '!=', b: '_SAFE_CHARACTERS_' },
											{ join: 'or', a: '$$', op: '==', b: '(^\\W|\\W$)', b_is: 'regexp' }
										],
										actions: { invalid: 'A Valid Last Name is required' },
										else_actions: { invalid: false }
									}
								]
							}
						}
					}
				},
			    {
				entity_template: 'data_item',
				title: 'PIN:',
				elements: [{
				    entity_template: 'form_dialog_button',
				    text: 'Change PIN',
				    title: 'Change PIN',

				    rest: '/gui/extension/user',

				    submit_button: 'Change PIN',
				    include_data: ['bbx_user_id'],
				    always_dirty: false,

				    method: 'PUT',
				    elements: [
					{
					    entity_template: 'input_item',
					    title: 'PIN:',
					    type: 'password',
					    validate: {
						conditions: [ {validator: '_DIGITS_'}, {validator: '', join: 'or'} ],
						message: 'PIN may only contain digits'
					    },
					    input_options: {
						attributes: {
						    size: '4'
						}
					    },
					    input_name: 'bbx_user_pin'
					},
					{
					    entity_template: 'input_item',
					    title: 'Confirm PIN:',
					    type: 'password',
					    input_options: {
						widgets: ['aopbStateWidget'],
						widget_options: {
						    aopbStateWidget: {
							closest: 'form',
							states: [{
							    conditions: { a: '$$', op: '==', b: '$bbx_user_pin' },
							    actions: { invalid: false },
							    else_actions: { invalid: 'PIN and verification must match to change the PIN' }
							}]
						    }
						},
						attributes: {
						    size: '4',
						    'value-from': 'bbx_user_pin'
						}
					    }
					}
				    ]
				}]
			    },
			{
				entity_template: 'aopb_container',
				private_fill_data: true,
				rest: '/gui/system/languages',
				rest_container: false,

				states: [{
				    conditions: { a: '@languages', op: '==', b: '_ARRAY_HAS_MULTIPLE_ELEMENTS_' },
				    elements: [{
					entity_template: 'input_item',
					type: 'select',
					title: 'Sound Prompt Language:',
					input_name: 'default_language',
					input_options: {
					    widgets: ['selectSelfPopulateWidget'],
					    widget_options: {
						selectSelfPopulateWidget: {
						    options_container: 'languages',
						    option_text_key: 'name',
						    option_value_key: 'value',
							after: [
								{ text: '(Default)', value: '' }
							]
						}
					    }
					}
				    }]
				}]
			},

			    {
				entity_template: 'input_item',
				title: 'Use a custom Caller ID number for Outbound Calls:',
				type: 'div',
				input_name: 'bbx_user_external_caller_id_number',
				info: 'The custom Caller ID number will be provided on outbound external calls, and will override custom Group or Gateway caller ID number settings.',
				input_options: {
				    widgets: ['aopbStateWidget', 'optionalValueTextboxWidget'],
				    widget_options: {
					optionalValueTextboxWidget: {},
					aopbStateWidget: {
					    states: [
						{
						    conditions: [
							{ a: '$$', op: '!=', b: '^\\+?[0-9]+$', b_is: 'regexp' },
							{ join: 'and', a: '[name=bbx_user_external_caller_id_number] .optional-value-checkbox', a_is: 'element', op: '==', b: ':checked', b_is: 'jq_is' }
						    ],
						    actions: { invalid: 'The Caller ID number must contain only digits' },
						    else_actions: { invalid: false }
						}
					    ]
					}
				    }
				}
			    },
			    {
				entity_template: 'input_item',
				title: 'Show this person in Contact Directory searches:',
				type: 'checkbox',
				input_name: 'bbx_contact_show_directory'
			    }
			] // END page module elements
		    }, // END page module 1

		    {
			entity_template: 'aopb_container',
			states: [{
			    conditions: { a: '@bbx_user_failed_logins', op: '>', b: '5' },
			    elements: [
				{
				    entity_template: 'page_module',
				    legend: 'Failed Login Lockout',
				    buttons: ['help'],
				    help_id: 'extensions_contact_information',
				    attributes: { 'class': 'userContactTables' },
				    elements: [
					{
					    entity_template: 'data_item',
					    title: 'Failed Login Lock-out:',
					    elements: [
						{
						    entity_template: 'action_button',
						    confirm: {
							text: 'Reset this person' + entity.rsquo + 's lockout status and allow them to log in?',
							title: 'Reset Lock-out'
						    },
						    text: 'Remove Lock-out',
						    rest: '/gui/extension/user',
						    fields: 'bbx_user_id bbx_user_failed_logins',
						    rest_params: { bbx_user_failed_logins: 0 }
						},
						{
						    entity: 'div',
						    attributes: { 'class':'info' },
						    text: 'This person has performed too many login attempts, and is temporarily locked out. To reset this immediately, check the box and Save the form.'
						}
					    ]
					}
				    ]
				}
			    ]
			}]
		    },
		    {
			entity_template: 'page_module',
			legend: 'Contact Information',
			buttons: ['help'],
			help_id: 'extensions_contact_information',
			rest: '/gui/extension/user',
			attributes: { 'class': 'userContactTables' },
			elements: [
			    {
				entity_template: 'data_table',
				accept_data_from_parent: true,
				rest_container: 'label',
				click_action: 'edit',
				rest_params: { scope: 'global' },
				add_edit_action: {
				    rest: '/gui/contact/label',
				    include_data_from_parent: ['bbx_contact_id']
				},
				delete_action: { rest: '/gui/contact/label' },
				primary_keys: ['bbx_contact_label_id'],
				secondary_keys: ['bbx_contact_id'],
				data_source: 'labels',
				name_column: 'bbx_contact_label_property',
				extra_row_data: [
				    'bbx_contact_label_id',
				    'bbx_contact_label_addr_attn',
				    'bbx_contact_label_addr_city',
				    'bbx_contact_label_addr_pobox',
				    'bbx_contact_label_addr_country',
				    'bbx_contact_label_addr_state',
				    'bbx_contact_label_addr_street',
				    'bbx_contact_label_addr_zip'
				],
				attributes: {
				    'class': 'user_contact_info'
				},
				paginate: false,
				filtering: false,
				oLanguage: {
				    sZeroRecords: 'This user has no contact information'
				},
				columns: [
				    {
					header: 'Type',
					column: 'bbx_contact_label_property',
					width: '350px',
					translate: {
					    TEL: 'Telephone Number',
					    ORG: 'Company / Organization Name',
					    ADR: 'Address',
					    EMAIL: 'Email Address',
					    IMPP: 'Instant Messenger'
					},
					add_element: {
					    entity: 'select',
					    attributes: { name: 'bbx_contact_label_property', style: 'width: 100%' },
					    select_options: [
						{ value: 'TEL',   text: 'Telephone Number' },
						{ value: 'ORG',   text: 'Company Name' },
						{ value: 'ADR',   text: 'Address' },
						{ value: 'EMAIL', text: 'Email Address' },
						{ value: 'IMPP',  text: 'Instant Messenger' }
					    ]
					},
					edit_element: {
					    entity: 'select',
					    attributes: { name: 'bbx_contact_label_property', style: 'width: 100%' },
					    select_options: [
						{ value: 'TEL',   text: 'Telephone Number' },
						{ value: 'ORG',   text: 'Company Name' },
						{ value: 'ADR',   text: 'Address' },
						{ value: 'EMAIL', text: 'Email Address' },
						{ value: 'IMPP',  text: 'Instant Messenger' }
					    ]
					}
				    },
				    {
					header: 'Locations',
					column: 'bbx_contact_label_type',
					view_element: {
					    entity_template: 'format',
					    format: {
						field: '@bbx_contact_label_type',
						formatter: 'contact_type'
					    }
					},
					edit_element: {
					    entity_template: 'switch',
					    closest: 'tr',
					    selector: '$bbx_contact_label_property',
					    switch_elements: contact_property_switch_elements,
					    default_elements: {
						entity_template: 'check_to_list',
						name: 'bbx_contact_label_type',
						values: 'none'
					    }
					},
					add_element: {
					    entity_template: 'switch',
					    closest: 'tr',
					    selector: '$bbx_contact_label_property',
					    switch_elements: contact_property_switch_elements,
					    default_elements: {
						entity_template: 'check_to_list',
						name: 'bbx_contact_label_type',
						values: 'none'
					    }
					}
				    },
				    {
					header: 'Address/Number',
					column: 'bbx_contact_label_value',
					view_element: {
					    entity: 'div',
					    attributes: { name: 'bbx_contact_label_value', style: 'white-space: pre'},
					    widgets: ['aopbFormatWidget'],
					    widget_options: {
						aopbFormatWidget: {
						    closest: 'tr',
						    states: [
							{
							    conditions: { a: '@bbx_contact_label_property', op: '==', b: 'TEL', b_is: 'literal' },
							    format: {
								format: {
								    field: '@bbx_contact_label_value',
								    formatter: 'phone_number',
								    options: { no_auto_split: true }
								}
							    }
							},
							{
							    conditions: { a: '@bbx_contact_label_property', op: '==', b: 'ADR', b_is: 'literal' },
							    format: {
								format: {
								    field: '@bbx_contact_label_value',
								    formatter: 'address'
								}
							    }
							}
						    ]
						}
					    }
					},
					add_element: {
					    entity_template: 'switch',
					    closest: 'tr',
					    selector: '$bbx_contact_label_property',
					    switch_elements: contact_value_switch_elements
					},
					edit_element: {
					    entity_template: 'switch',
					    closest: 'tr',
					    selector: '$bbx_contact_label_property',
					    switch_elements: contact_value_switch_elements
					}
				    }
				],
				row_actions: {
				    animate: false,
				    type: 'expand',
				    edit_save: { text: 'Save' },
				    edit_cancel: { text: 'Cancel' },
				    add_save: { text: 'Add' },
				    add_cancel: { text: 'Cancel' },
				    delete_row: { text: 'Delete' }
				},
				table_actions: {
				    add_row: {
					text: 'Add Contact Information',
					button_class: 'user_add_contact'
				    },
				    delete_rows: {
					text: 'Delete Selected',
					confirm: {
					    title: 'Delete Selected Contact Information',
					    text:  'Are you sure you want to delete this contact information?'
					}

				    },
				    select_row:      true,
				    select_all_rows: true
				}
			    }
			]
		    },
		    {
			entity_template: 'page_module',
			legend: 'Group Membership',
			buttons: [ "help" ],
			help_id: 'extensions_group_membership',
			elements: [
			    {
				entity_template: 'data_table',
				refresh_rest: '/gui/extension/user/groupmember',
				refresh_rest_container: 'groupmember',
				accept_initial_data_from_parent: true,
				data_source: 'groups',
				paginate: true,
				length_change: true,
				filtering: false,
				add_edit_action: { rest: '/gui/extension/user/groupmember' },
				delete_action: { rest: '/gui/extension/user/groupmember' },
				primary_keys: ['bbx_group_id'],
				extra_row_data: ['bbx_group_id','bbx_extension_id'],
				name_column: 'bbx_group_name',
				click_action: 'overlay',
				overlay_name: 'group_detail',
				overlay_params: [ 'bbx_extension_id', 'bbx_group_id' ],
				overlay_data: { key: 'bbx_group_id' },
				attributes: {
				    'class': 'user_group_membership'
				},
				oLanguage: {
				    sZeroRecords: 'This user belongs to no groups'
				},
				details_row: {
				    add_elements: [{
					entity_template: 'data_item',
					title: 'Select a group:',
					elements: [
					    {
						entity_template: 'extension_picker_select',
						type: 'group',
						primary: 1,
						table_filter: true,
						allow_nothing: false,
						name: 'bbx_group_id'
					    }
					]
				    }]
				},
				columns: [
				    {
					header: 'Group Name',
					column: 'bbx_group_name',
					sortable: true
				    }
				],
				row_actions: {
				    animate: false,
				    edit_save: { text: 'Save' },
				    edit_cancel: { text: 'Cancel' },
				    add_save: { text: 'Add' },
				    add_cancel: { text: 'Cancel' }
				},
				table_actions: {
				    add_row: {
					text:     'Join a Group',
					location: 'bottom',
					button_class: 'user_join_group'
				    },
				    delete_rows: {
					text:     'Leave Selected Groups',
					location: 'bottom',
					confirm: {
					    title: 'Leave Selected Groups',
					    text:  'End this person' + entity.rsquo + 's membership in the selected groups? If permissions are applied via this group, those permissions may be removed.'
					}
				    },
				    select_row:      true,
				    select_all_rows: true
				}
			    }
			]
		    }, // END page module "groups"
		    {
			entity_template: 'page_module',
			legend: 'Voicemail Settings',
			buttons: ['help'],
			help_id: 'send_voicemail_to_email',
			form_action: '/gui/extension/user',
			form_data_js: { submit_all: false },
			elements: [
			    {
				entity_template: 'aopb_state',
				attributes: { hidden: 'hide-on-load' },
				'class': 'additionalMessages warning',
				html: '<span class="warning">Errors were encountered when attempting to deliver mail to the address entered below. Please verify the address or contact your email administrator.',
				closest: '.pageModuleWidget',
				states: [{
				    conditions: [
					{ a: '@bbx_user_voicemail_email_bouncing', op: '==', b: '_TRUE_' },
					{ join: 'and', a: '$bbx_user_voicemail_email', a_is: 'element', op: '!=', b: '.is-dirty', b_is: 'jq_is' }
				    ],
				    actions: { visible: true },
				    else_actions: { visible: false }
				}]
			    },
			    {
				entity_template: 'input_item',
				title: 'Disable Voicemail:',
				type: 'checkbox',
				input_name: 'bbx_user_disable_voicemail'
			    },
			    {
				entity: 'div',
				widgets: ['aopbStateWidget', 'containerWidget'],
				widget_options: {
				    aopbStateWidget: {
					closest: 'form',
					states: [
					    {
						conditions: { a: '$bbx_user_disable_voicemail', op: '!=', b: '_TRUE_' },
						actions: { enabled: true, _cascade: true },
						else_actions: { enabled: false, invalid: false, _cascade: true }
					    }
					]
				    },
				    containerWidget: {
					elements: [
					    {
						entity_template: 'input_item',
						title: 'Send Voicemail to Email Address:',
						type: 'text',
						'class':'email-address-input',
						input_name: 'bbx_user_voicemail_email',
						validate: {
						    conditions: [ {validator: '_EMAIL_'}, {validator: '', join: 'or'} ],
						    message: 'Please enter a valid email address' }
					    },
					    {
						entity_template: 'input_item',
						title: 'Voicemail Format:',
						type: 'select',
						select_options: [
						    { text: 'MP3', value: 'mp3' },
						    { text: 'WAV', value: 'wav' },
						    { text: 'No Attachment', value: 'none' }
						],
						input_name: 'bbx_user_voicemail_file_type',
						input_options: {
						    widgets: ['aopbStateWidget'],
						    widget_options: {
							aopbStateWidget: {
							    closest: '.page_module',
							    states: [{
								conditions: { a: '$bbx_user_voicemail_email', op: '==', b: '_TRUEISH_' },
								actions: { enabled: true },
								else_actions: { enabled: false }
							    }]
							}
						    }
						}
					    },
					    {
						entity_template: 'input_item',
						title: 'Remove Saved Voicemail After Emailing:',
						type: 'checkbox',
						input_name: 'bbx_user_voicemail_delete_file',
						input_options: {
						    widgets: ['aopbStateWidget'],
						    widget_options: {
							aopbStateWidget: {
							    closest: '.page_module',
							    states: [{
								conditions: [
								    { a: '$bbx_user_voicemail_email', op: '==', b: '_TRUEISH_' },
								    { join: 'and', a: '$bbx_user_voicemail_file_type', op: '!=', b: 'none' }
								],
								actions: { enabled: true },
								else_actions: { enabled: false, attr: 'checked:false'}
							    }]
							}
						    }
						}
					    }
					]
				    }
				}
			    },
			    {
				entity_template: 'input_item',
				type: 'div',
				title: 'User Operator Destination:',
				input_name: 'bbx_user_operator_extension_id',
				info: 'The caller will be connected to this number when dialing '+entity.ldquo+'0'+entity.rdquo+'.',
				input_options: {
				    entity_template: 'extension_picker_select',
				    type: ['user','group','queue','conference','auto_attendant','router','phone'],
				    allow_nothing: true,
				    value_key: 'bbx_extension_id'
				}
			    }
			] // END page module elements
		    }, // END page module "Settings"

		    elgen('recording_policy', { rest: '/gui/extension/user', type: 'user' }, {help_id: 'extensions_user_call_recording' }),
		    // Elgen-generated page module

		    {
			entity_template: 'page_module',
			legend: 'Phones',
			buttons: ['help'],
			help_id: 'user_phones_list',
			elements: [
			    {
				entity_template: 'data_table',
				accept_data_from_parent: false,
				primary_keys: ['bbx_phone_id'],
				extra_row_data: ['bbx_phone_id'],
				live_table: 'bbx_phone',
				live_unique_ident: true,
				search_parent_field: 'bbx_user_id',
				click_action: 'overlay',
				overlay_name: 'user_phone_detail',
				overlay_params: [ 'bbx_phone_id' ],
				overlay_data: { key: 'bbx_phone_id' },
				name_column: 'bbx_phone_desc',
				oLanguage: {
				    sZeroRecords: 'This user has no phones'
				},
				attributes: {
				    'class': 'user_phones'
				},
				delete_action: {
				    rest: '/gui/phone/phone'
				},
				table_actions: {
				    delete_rows: {
					text: 'Remove Selected Phones from this Person',
					location: "bottom",
					confirm: {
					    title: 'Remove Selected Phones',
					    text: 'The selected phones will no longer be associated with this user. All line and button settings for the phones will be cleared. If the phones are currently connected, they will reboot and be assigned a new extension as an Unassigned Phone.'
					}
				    },

				    action_elements: {
					actions: [
					    {
						entity: 'button',
						text: 'Add a Phone',
						attributes: { 'class': 'add-phone-button' },
						widgets: [ 'overlayButtonWidget' ],
						widget_options: {
						    overlayButtonWidget: {
							overlay: 'user_phone_create',
							text: 'Add a Phone',
							pass_params: ['bbx_user_id']
						    }
						}
					    }
					],
					location: 'bottom',
					position: 'first'
				    },
				    select_row: true,
				    select_all_rows: true
				},

				columns: [
				    {
					header: 'Status',
					column: 'phone_state',
					view_element: {
					    entity_template: 'translate',
					    accept_data_from_parent: true,
					    name: 'phone_state',
					    attributes: {
						'class': 'user_phone_status'
					    },
					    translations: {
						INUSE: { text: 'In Use', css: { color: '#080' } },
						HELD: { text: 'On Hold', css: { color: '#080' } },
						IDLE: { text: 'Idle', css: { color: '' } },
						UNREG: { text: 'Offline', css: { color: '#800' } }
					    },
					    default_translation: 'Unknown'
					}
				    },
				    {
					header: 'Description',
					column: 'bbx_phone_desc',
					'class': 'user_phone_description'
				    },
				    {
					header: 'Phone Type',
					column: 'bbx_phone_model',
					'class': 'user_phone_model'
				    },
				    {
					header: 'MAC Address',
					column: 'bbx_phone_mac',
					'class': 'user_phone_mac',
					formatted: {
					    format: {
						field: 'bbx_phone_mac',
						formatter: 'mac'
					    }
					}
				    }
				]
			    }
			]
		    },

		    elgen('secondary_page_module', { type: 'user' }, {help_id: 'secondary_extensions' })
		    // Elgen-generated page module

		] // END page_content elements
	    } // END page_content
	] // END template elements
    }); // END template
})();
