/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/*
  reportingTableWidget: A single-purpose widget for getting data from /gui/reporting/run (i.e., reporting run) and rendering the variable-column
  table. Apply to a DIV.
*/

(function( $ ){
	var reportingTableWidget = $.extend(true, {}, CUI.htmlEntityClass, $.ui.widget.prototype, {
		options: {
		},

		_beforeInit: function () {
			var self = this, $self = this.element;
			CUI.postREST('/gui/reporting/run', self.options.rest_params, self._gotTableData.bind(self));
		},

		_gotTableData: function (d) {
			var self = this, $self = this.element, dtw_def, col_idx, field, col_def, $table;

			dtw_def = $.extend({
				entity_template: 'data_table',
				columns: [],
				data_source: 'data',
				paginate:      true,
				length_change: true,
				filtering:     false,
				force_basic_table: true,
				cell_tooltips: true
			}, self.options.dtw_options || {});

			for (col_idx = 0; col_idx < d.run.fields.length; col_idx++) {
				field = d.run.fields[col_idx];
				col_def = {
					column: field.value,
					header: field.text
				};

				if (field.sort_type) {
					col_def.data_type = field.sort_type;
					col_def.sort_data_type = field.sort_type + '-asc';
				}

				dtw_def.columns.push(col_def);
			}

			if (!d.run.fields.length) {
				dtw_def.columns.push({ header: 'No fields specified in report' });
			}

			$table = self.options._$table = self.getEntitiesHTML([dtw_def], {}).contents();
			$self.append($table);

			self.widgetizeChildren($self);

			$table.getCUIWidget('dataTableWidget').fillData(d.run, true);
			$table.wrap('<div class="dataTable-scroll-wrapper" />');
		},

		setValue: function () {}
	});

	add_widget('reportingTableWidget', reportingTableWidget);
})(jQuery);
