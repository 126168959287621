/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
// Adds a click handler to the element (usually a BUTTON) to go to a URL

(function( $ ){
	var backButtonWidget = $.extend({}, $.ui.pageCancelButtonWidget.prototype, {
		options: {
			href: '#',
			track_dirty: true
		},

		_beforeDOMReady: function () {
			var self = this, $self = this.element;
			self._bind($self, 'click', self._back.bind(self));
		},

		_afterFormChange: function () {
			var self = this, $self = this.element;
			if (self.options.track_dirty) {
				$.ui.pageCancelButtonWidget.prototype._afterFormChange.apply(this, arguments);
			}
		},

		_back: function () {
			window.history.back();
		}
	});

	add_widget('backButtonWidget', 'ui.backButtonWidget', 'backButtonWidget', backButtonWidget);
})(jQuery);
