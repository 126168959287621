register_screen('group_activity_detail', {
    elements: [
	{
	    entity: 'h2',
	    attributes: {
		'class': 'page_title'
	    },
	    widgets: ['formatWidget'],
	    widget_options: { formatWidget: {
		rest: '/gui/extension/group/name',
		format: 'Live Group Activity ' + entity.mdash + ' $1',
		fields: [
		    {
			field: 'bbx_group_name'
		    }
		]
	    }}
	},
	{
	    entity_template: "page_buttons" ,
	    buttons: [ { type: 'fullscreen' }, { type: 'help', help_id: 'live_group_statistics' } ]
	},
	{ entity_template: "page_content",
	  "class": "group_activity",
	  elements: [
	      {
		  entity_template: "container",
		  elements: [ {
		      entity_template: "refreshing_data_table",
		      refresh_hours: [0],

		      dtw_options: {
			  uri_id: "group_activity_group_detail",
			  row_key: 'bbx_user_id',
			  click_action: "toggle_expand",
			  live_table: "live_user_activity",
			  live_table_key: "",
			  live_table_distinct_on: "bbx_user_id",
			  live_unique_ident: true,
			  data_source: "live",
			  // wait_for_parent_filldata: true,
			  search_parent_field: "bbx_group_id",
			  page_size: 10000,
			  // wallboarding: { position: 'right' },
			  filtering: false,
			  length_chage: true,
			  paginate: true,
			  processing: false,
			  js_sort_by: [ ["total_talk_time", "desc"] ],
			  oLanguage: {
			      sZeroRecords: "Waiting for user statistics..."
			  },
			  columns: [
			      {
				  header: "User Name",
				  column: "user_name"
			      },
			      {
				  header: "Last Call",
				  column: "last_call",
				  sort_data_type: "num-html-desc",
				  view_element: {
				      entity: 'div',
				      attributes: {
					  name: 'last_call'
				      },
				      widgets: [ 'timestampDisplayWidget' ]
				  }
			      },
			      {
				  header: "On Call",
				  column: "on_call",
				  "class": "on_call_col",
				  view_element: {
				      entity: 'div',
				      attributes: {
					  name: 'on_call'
				      },
				      widgets: [ 'rowHighlightWidget' ],
				      widget_options: {
					  rowHighlightWidget: {
					      highlight_class: 'active'
					  }
				      }
				  }
			      },
			      {
				  header: "Calls Out",
				  column: "calls_out",
				  data_type: "num-html"
			      },
			      {
				  header: "Out Ext",
				  column: "calls_out_external",
				  data_type: "num-html"
			      },
			      {
				  header: "Out Int",
				  column: "calls_out_internal",
				  data_type: "num-html"
			      },
			      {
				  header: "Calls In",
				  column: "calls_in",
				  data_type: "num-html"
			      },
			      {
				  header: "Missed",
				  column: "calls_missed",
				  data_type: "num-html"
			      },
			      {
				  header: "Rejected",
				  column: "calls_rejected",
				  data_type: "num-html"
			      },
			      {
				  header: "Answered",
				  column: "calls_answered",
				  data_type: "num-html"
			      },
			      {
				  header: "Total Calls",
				  column: "total_calls",
				  data_type: "num-html"
			      },

			      /* ---

				 {
				 header: "Talk In",
				 column: "total_talk_time_in",
				 sortable: true,
				 view_element: {
				 entity: 'div',
				 attributes: {
				 name: 'total_talk_time_in'
				 },
				 widgets: [ 'formatWidget' ],
				 widget_options: {
				 formatWidget: {
				 format: {
				 field: '@total_talk_time_in',
				 formatter: 'seconds_duration',
				 options: { size: 'short' }
				 }
				 }
				 }
				 }
				 },
				 {
				 header: "Talk Out",
				 column: "total_talk_time_out",
				 sortable: true,
				 view_element: {
				 entity: 'div',
				 attributes: {
				 name: 'total_talk_time_out'
				 },
				 widgets: [ 'formatWidget' ],
				 widget_options: {
				 formatWidget: {
				 format: {
				 field: '@total_talk_time_out',
				 formatter: 'seconds_duration',
				 options: { size: 'short' }
				 }
				 }
				 }
				 }
				 },

				 --- */


 			      {
				  header: "Total Talk",
				  column: "total_talk_time",
				  data_type: "num-html",
				  sort_data_type: "num-html-asc",
				  sorting: [ "desc", "asc" ],
				  view_element: {
				      entity: 'div',
				      widgets: [ 'formatWidget' ],
				      widget_options: {
					  formatWidget: {
					      format: {
						  field: '@total_talk_time',
						  formatter: 'seconds_duration',
						  options: { size: 'short' }
					      }
					  }
				      }
				  }
			      }
			  ],
			  extra_row_data: [
			      "total_talk_time_in",
			      "total_talk_time_out",
			      "total_talk_time_out_external",
			      "total_talk_time_out_internal",
			      "average_talk_time_in",
			      "average_talk_time_out",
			      "average_talk_time_out_external",
			      "average_talk_time_out_internal",
			      "max_talk_time_in",
			      "max_talk_time_out",
			      "max_talk_time_out_external",
			      "max_talk_time_out_internal",
			      "bbx_user_id"
			  ],
			  details_row: {
			      animate: false,
			      animate_speed: 'slow',
			      view_elements: [
				  {
				      entity_template: "input_item",
				      title: "Talk Time In:",
				      type: 'div',
				      input_options: {
					  widgets: [ 'formatWidget' ],
					  widget_options: {
					      formatWidget: {
						  format: {
						      field: '@total_talk_time_in',
						      formatter: 'seconds_duration',
						      options: { size: 'short' }
						  }
					      }
					  }
				      }
				  },
				  {
				      entity_template: "input_item",
				      title: "Talk Time Out:",
				      type: 'div',
				      input_options: {
					  widgets: [ 'formatWidget' ],
					  widget_options: {
					      formatWidget: {
						  format: {
						      field: '@total_talk_time',
						      formatter: 'seconds_duration',
						      options: { size: 'short' }
						  }
					      }
					  }
				      }
				  },
				  {
				      entity_template: "input_item",
				      title: "Talk Time Out to External Parties:",
				      type: 'div',
				      input_options: {
					  widgets: [ 'formatWidget' ],
					  widget_options: {
					      formatWidget: {
						  format: {
						      field: '@total_talk_time_out_external',
						      formatter: 'seconds_duration',
						      options: { size: 'short' }
						  }
					      }
					  }
				      }
				  },
				  {
				      entity_template: "input_item",
				      title: "Talk Time Out to Internal Parties:",
				      type: 'div',
				      input_options: {
					  widgets: [ 'formatWidget' ],
					  widget_options: {
					      formatWidget: {
						  format: {
						      field: '@total_talk_time_out_internal',
						      formatter: 'seconds_duration',
						      options: { size: 'short' }
						  }
					      }
					  }
				      }
				  },
				  {
				      entity_template: "input_item",
				      title: "Average Talk Time In:",
				      type: 'div',
				      input_options: {
					  widgets: [ 'formatWidget' ],
					  widget_options: {
					      formatWidget: {
						  format: {
						      field: '@average_talk_time_in',
						      formatter: 'seconds_duration',
						      options: { size: 'short' }
						  }
					      }
					  }
				      }
				  },
				  {
				      entity_template: "input_item",
				      title: "Average Talk Time Out:",
				      type: 'div',
				      input_options: {
					  widgets: [ 'formatWidget' ],
					  widget_options: {
					      formatWidget: {
						  format: {
						      field: '@average_talk_time_out',
						      formatter: 'seconds_duration',
						      options: { size: 'short' }
						  }
					      }
					  }
				      }
				  },
				  {
				      entity_template: "input_item",
				      title: "Average Talk Time Out to External Parties:",
				      type: 'div',
				      input_options: {
					  widgets: [ 'formatWidget' ],
					  widget_options: {
					      formatWidget: {
						  format: {
						      field: '@average_talk_time_out_external',
						      formatter: 'seconds_duration',
						      options: { size: 'short' }
						  }
					      }
					  }
				      }
				  },
				  {
				      entity_template: "input_item",
				      title: "Average Talk Time Out to Internal Parties:",
				      type: 'div',
				      input_options: {
					  widgets: [ 'formatWidget' ],
					  widget_options: {
					      formatWidget: {
						  format: {
						      field: '@average_talk_time_out_internal',
						      formatter: 'seconds_duration',
						      options: { size: 'short' }
						  }
					      }
					  }
				      }
				  },
				  {
				      entity_template: "input_item",
				      title: "Max Talk Time In:",
				      type: 'div',
				      input_options: {
					  widgets: [ 'formatWidget' ],
					  widget_options: {
					      formatWidget: {
						  format: {
						      field: '@max_talk_time_in',
						      formatter: 'seconds_duration',
						      options: { size: 'short' }
						  }
					      }
					  }
				      }
				  },
				  {
				      entity_template: "input_item",
				      title: "Max Talk Time Out:",
				      type: 'div',
				      input_options: {
					  widgets: [ 'formatWidget' ],
					  widget_options: {
					      formatWidget: {
						  format: {
						      field: '@max_talk_time_out',
						      formatter: 'seconds_duration',
						      options: { size: 'short' }
						  }
					      }
					  }
				      }
				  },
				  {
				      entity_template: "input_item",
				      title: "Max Talk Time Out to External Parties:",
				      type: 'div',
				      input_options: {
					  widgets: [ 'formatWidget' ],
					  widget_options: {
					      formatWidget: {
						  format: {
						      field: '@max_talk_time_out_external',
						      formatter: 'seconds_duration',
						      options: { size: 'short' }
						  }
					      }
					  }
				      }
				  },
				  {
				      entity_template: "input_item",
				      title: "Max Talk Time Out to Internal Parties:",
				      type: 'div',
				      input_options: {
					  widgets: [ 'formatWidget' ],
					  widget_options: {
					      formatWidget: {
						  format: {
						      field: '@max_talk_time_out_internal',
						      formatter: 'seconds_duration',
						      options: { size: 'short' }
						  }
					      }
					  }
				      }
				  }
			      ]
			  }
		      } // END dtw_options
		  } ]
	      } ]
	  }
	]
    });
