/* jshint jquery: true, unused: vars */
/* global add_widget */
/* militarizeWidget: Convert a string of characters to a phonetic alphabet

OPTIONS:
  alphabet: [ array(26) ]        // The array of words to use for each letter. Nothing other than the 26 basic letters are supported
  prefix_original: true | false  // If this is false, only the militarized version, like "Whiskey Oscar Romeo Delta", is output. Otherwise, the output is like
                                 // "WORD (Whiskey Oscar Romeo Delta)"

*/

(function( $ ){
	var militarizeWidget = $.extend(true, {}, $.ui.widget.prototype, {
		set_value_widget: true,

		options: {
			template: false,
			template_html: '<span></span>',
			alphabet: [ "Alpha", "Bravo", "Charlie", "Delta", "Echo", "Foxtrot", "Golf", "Hotel", "India", "Juliet", "Kilo", "Lima", "Mike", "November", "Oscar", "Papa", "Quebec", "Romeo", "Sierra", "Tango", "Uniform", "Victor", "Whiskey", "X-Ray", "Yankee", "Zulu" ],
			prefix_original: true
		},

		_militarize: function (input) {
			var self = this;

			if (typeof input !== 'number' && typeof input !== 'string') {
				return '';
			}

			input = input.toUpperCase();
			var output = '';

			if (self.options.prefix_original) {
				output = input + ' (';
			}

			for (var i=0; i<input.length; i++) {
				var alpha = input.charCodeAt(i) - 65; // "A" is char 65
				if (alpha < 0 || alpha >= self.options.alphabet.length) {
					output += input.charAt(i) + ' ';
				} else {
					output += self.options.alphabet[alpha] + ' ';
				}
			}

			output = output.slice(0,-1);

			if (self.options.prefix_original) {
				output += ')';
			}

			return output;
		},

		setValue: function (v, name, suppress_change) {
			var $self = this.element;
			var self = this;

			if ($self.is(':input,[value]')) {
				$self.val(self._militarize(v));
			} else {
				$self.text(self._militarize(v));
			}

			return $self;

		}
	});

	add_widget('militarizeWidget', militarizeWidget);
})(jQuery);
