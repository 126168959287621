/* global classCUI */
(function () {
	classCUI.prototype.onlyKeys = function (obj, arr) {
		var idx = arr.length, out = {};

		if (!obj) { throw new Error('No object given to filter'); }
		if (!arr) { throw new Error('No array given to filter on'); }

		while (idx--) {
			if (arr[idx] in obj) {
				out[arr[idx]] = obj[arr[idx]];
			}
		}
		return out;
	};

	classCUI.prototype.alwaysKeys = function (obj, arr, fallback) {
		// Don't worry about fallback being undefined-- that's the default anyway
		var idx = arr.length, out = {};

		if (!obj) { throw new Error('No object given to filter'); }
		if (!arr) { throw new Error('No array given to filter on'); }

		while (idx--) {
			if (arr[idx] in obj) {
				out[arr[idx]] = obj[arr[idx]];
			} else {
				out[arr[idx]] = fallback;
			}
		}
		return out;
	};

	classCUI.prototype.notKeys = function (obj, arr) {
		var key, arr_idx = arr.length, arr_look = {}, out = {};

		if (!obj) { throw new Error('No object given to filter'); }
		if (!arr) { throw new Error('No array given to filter on'); }

		while (arr_idx--) {
			arr_look[arr[arr_idx]] = true;
		}

		for (key in obj) {
			if (!(key in arr_look)) {
				out[key] = obj[key];
			}
		}
		return out;
	};
})();
