/**
 * Add a "syswarn" stripe to the UI, used to show discontinuation messages
 * @param {string} text Message to show
 */
function addSyswarnStripe(syswarn) {
	var $container, $stripe, $closeButton, closed = false;
	$container = $('#warning-stripe-container');
	$container = $container[0] ? $container : $('<div id="warning-stripe-container"></div>').insertBefore('#cui-footer');

	$stripe = $('<div class="warning-stripe" style="display:none" />');
	$stripe.html(syswarn.text);

	$closeButton = $('<button class="warning-stripe-button">Close</button>');
	$closeButton.on('click', function (e) {
		if (closed) { return; }
		closed = true;
		CUI.putREST('/gui/syswarn/syswarn', { key: syswarn.key });
		$stripe.fadeOut(1000, function () { $stripe.remove(); });
	});

	$stripe.append($closeButton);
	$stripe.appendTo($container).fadeIn(500);
}
