register_screen('sounds', {
    elements: [
	{
	    entity_template: "page_title",
	    title: "Sounds"
	},
	{
	    entity_template: "page_content",
	    elements: [
		{
		    entity_template : "page_module",
		    "class": "sound_music_page_module_left",
		    legend: "Sound Categories",
		    buttons: ['help'],
		    help_id: 'sounds_categories',
		    elements: [
			{
			    entity_templates: ["data_table", "sound_music_class_list"],
			    sound_music_class_list: {
				selector: '.sound_music_file_list',
				key_name: 'bbx_sound_category_id',
				class_name_key: 'bbx_sound_category_name',
				file_key_name: 'bbx_sound_id',
				rest_path: '/gui/sound/file',
				class_description: 'category'
			    },
			    data_table: {
				rest : "/gui/sound/category",
				attributes: {
				    "class": "sound_music_class_list"
				},
				paginate: false,
				length_change: false,
				filtering: false,
				sortable: false,
				oLanguage: {
				    "sZeroRecords" : "There are no sound categories."
				},
				extra_row_data: ['bbx_sound_category_id'],
				primary_keys: ['bbx_sound_category_id'],
				columns: [
				    {
					header: "Category",
					column: 'bbx_sound_category_name',
					view_element: {
					    entity: 'div',
					    widgets: ['soundMusicClassWidget'],
					    attributes: {
						name: 'bbx_sound_category_name'
					    },
					    widget_options: {
						selector: '.sound_music_file_list',
						key_name: 'bbx_sound_category_id',
						class_name_key: 'bbx_sound_category_name'
					    }
					}
				    }
				],
				table_actions: {
				    action_elements: {
					actions: [
					    {
						entity_template: 'form_dialog_button',
						text: 'New Category',
						title: 'Add',
						submit_button: 'Add',
						rest: '/gui/sound/category',
						method: 'POST',
						form_options:{
						    "class":"sound_music_class sound_music_class_add"
						},
						widget_options: {
						    formDialogButtonWidget: {
							"class": 'sound_music_class sound_music_class_add'
						    }
						},
						elements: [
 						    {
							entity_template: 'input_item',
							title: 'New Category Name',
							input_name: 'bbx_sound_category_name',
							type: 'text'
						    }
						]
					    },
					    {
						entity_template: 'form_dialog_button',
						text: 'Delete',
						title: 'Delete Category',
						submit_button: 'Delete',
						rest: '/gui/sound/category',
						method: 'DELETE',
						widget_options: {
						    formDialogButtonWidget: {
							"class": 'sound_music_class sound_music_class_delete'
						    }
						},
						form_options: {
						    "class": 'sound_music_class sound_music_class_delete'
						},
						elements: [
						    {
							entity: 'div',
							text: 'Do you really want to delete this sound category?'
						    }
						]
					    },
					    {
						entity_template: 'form_dialog_button',
						text: 'Rename',
						title: 'Rename',
						submit_button: 'Rename',
						rest: '/gui/sound/category',
						method: 'PUT',
						include_data_as: {
						    'bbx_sound_category_id': 'bbx_sound_category_id'
						},
						widget_options: {
						    formDialogButtonWidget: {
							"class": 'sound_music_class sound_music_class_rename'
						    }
						},
						form_options: {
						    "class": 'sound_music_class sound_music_class_rename'
						},
						elements: [
 						    {
							entity_template: 'input_item',
							title: 'Category Name',
							input_name: 'bbx_sound_category_name',
							type: 'text'
						    }
						]
					    }
					],
					location: 'bottom'
				    },
				    select_row: false,
				    select_all_rows: false
				}
			    }
			}
		    ]
		},
		{
		    entity_template : "page_module",
		    "class": "sound_music_page_module_right",
		    legend: "Files",
		    buttons: ['help'],
		    help_id: 'sounds_files',
		    elements: [
			{
			    entity: 'div',
			    attributes: {
				'class': 'sound_music_no_category_message'
			    },
			    text: 'No category is selected. Please select or create one from the left-hand column.'
			},
			{
			    entity_templates: ["data_table", "sound_music_file_list"],
			    sound_music_file_list: {
				key_name: 'bbx_sound_category_id'
			    },
			    data_table: {
				on_event: {
				    event_name: 'sound_recording',
				    match_any_keys: { end_recording: 1 },
				    action: 'refresh'
				},
				attributes: {
				    "class": "sound_music_file_list"
				},
				click_action: 'edit',
				delete_action: {
				    rest: '/gui/sound/file'
				},
				page_size: 50,
				length_change: false,
				paginate: false,
				filtering: true,
				sortable: false,
				allow_scroll: false,
				oLanguage: {
				    "sZeroRecords" : "There are no sounds."
				},
				primary_keys: ['bbx_sound_id'],
				extra_row_data: ['bbx_sound_id', 'sound_category_id'],
				add_edit_action: {
				    rest: '/gui/sound/file',
				    method: 'put',
				    filter_params: ['bbx_sound_file_stream_url']
				},
				dirty_tracking: false,
				name_column: 'bbx_sound_name',
				columns: [
				    {
					header: 'Move',
					sortable: false,
					"class": "sound_music_move",
					view_element: {
					    entity: 'img',
					    attributes: {
						src: '/images/sound_move_small.png'
					    },
					    widgets: ['moverWidget'],
					    widget_options: {
						moverWidget: {
						    helper: 'icon',
						    helper_icon: '/images/sound_move_large.png',
						    helper_key: 'bbx_sound_name'
						}
					    }
					},
					edit_element: {
					    entity_template: 'form_dialog_button',
					    text: 'Move',
					    title: 'Move',
					    submit_button: 'Move',
					    rest: '/gui/sound/file',
					    method: 'PUT',
					    include_data_as: {
						'bbx_sound_id': 'bbx_sound_id',
						'sound_category_id': 'bbx_sound_category_id'
					    },
					    widget_options: {
						formDialogButtonWidget: {
						    "class": 'sound_music_file_move'
						}
					    },
					    form_options: {
						"class": 'sound_music_file_move'
					    },
					    elements: [
						{
						    entity_template: 'select_populate',
						    filter_rest_params: ['bbx_sound_category_id'],
						    name: 'bbx_sound_category_id',
						    rest: '/gui/sound/category',
						    text_field: 'bbx_sound_category_name',
						    value_field: 'bbx_sound_category_id'
						}
					    ]
					}
				    },
				    {
					header: "File Name",
					column: "bbx_sound_name",
					edit_element: {
					    entity: 'input',
					    widgets: ['widget'],
					    attributes: {
						name: 'bbx_sound_name',
						type: 'text',
						placeholder: 'File Name'
					    }
					}
				    },
 				    {
					header: 'Play/Download',
					column: 'bbx_sound_id',
					sortable: false,
					'class': 'sound_music_preview',
					view_element: {
					    entity_template: 'sound_player',
					    size: 'full',
					    allow_download: true,
					    url: '/gui/sound_file/playback',
					    include_keys: ['bbx_sound_id'],
					    params: { disposition: 'inline' },
					    download_params: { disposition: 'attachment' },
					    accept_data_from_parent: true
					},
					edit_element: {
					    entity: 'div'
					}
				    }
				],
				row_actions: {
				    animate: false,
				    animate_speed: "slow",
				    type: "expand",
				    edit_save: { text: 'Save' },
				    edit_cancel: { text: 'Cancel' },
				    add_save: { text: 'Add' },
				    add_cancel: { text: 'Cancel' },
				    delete_row: {
					text: "Delete",
					confirm: {
					    title: "Delete Sound File",
					    text: "Are you sure you want to delete the sound file?"
					}
				    }
				},
				table_actions: {
				    action_elements: {
					actions: [
					    {
						entity_template: 'form_dialog_button',
						text: 'Upload a File',
						title: 'Upload a File',
						"class": "sound_music_upload sound_music_file",
						always_dirty: false,
						form_options:{
						    "class":"sound_music_upload sound_music_file",
						    dialog_during_submit: {
							buttons: [],
							title: 'Uploading...',
							text: 'Please wait while the sound file is uploaded...',
							progress: true
						    }
						},
						submit_button: 'Upload',
						rest: '/gui/sound_file/upload',
						method: 'POST',
						elements: [
						    {
							entity: 'input',
							widgets: ['widget'],
							attributes: {
							    name: 'upload_file',
							    type: 'file'
							}
						    }
						]
					    },
					    {
						entity_template: 'form_dialog_button',
						text: 'Record A Sound',
						title: 'Record A Sound',
						"class": "sound_music_record sound_music_file",
						form_options:{
						    "class":"sound_music_record sound_music_file"
						},
						submit_button: 'Call',
						rest: '/gui/sound/file_record',
						method: 'POST',
						include_data_as_rest_params: {'bbx_sound_category_id':'bbx_sound_category_id'},
						elements: [
 						    {
							entity_template: 'input_item',
							title: 'File Name',
							input_name: 'bbx_sound_name',
							type: 'text'
						    },
 						    {
							entity_template: 'input_item',
							title: 'Call Extension',
							input_name: 'bbx_extension_value',
							type: 'text',
							input_options: {
							    entity_template: 'extension_picker_suggest',
							    type: ['user','phone'],
							    primary: 1,
							    value_key: 'bbx_extension_value'
							}
						    }
						]
					    }
					],
					location: 'bottom'
				    },
				    delete_rows: {
					text: "Delete Selected",
					location: "bottom",
					confirm: {
					    title: "Delete Selected Sound Files",
					    text: "Are you sure you want to delete the selected sound files?"
					}
				    },
				    select_row: true,
				    select_all_rows: true
				}
			    }
			}
		    ]
		}
	    ]
	}
    ] // End Screen elements
}); // End Screen def
