/* jshint -W087 */ // Debugger is used legitimately
/* exported debugValueChange, rescanGlobalVars, listGlobalVars, trackGlobalVars */
var debugValueChange, rescanGlobalVars, listGlobalVars, trackGlobalVars; // -- Functions

(function () {
	"use strict";
	var unique = 0;

	/* 
       debugValueChange(parent, property_name, OPTIONAL warn_only);

       Throw a debugger (or console.trace, if warn_only is true) when a property is changed. The value must be globally accessible, though using a .data()
       statement to get at instance vars does seem to work. Call this from the console to aid in debugging. "parent" is a ref to the container of the property
       (or window if you are tracing a global var). "property_name" is a string or number (for arrays) key-name of the property.

       EXAMPLES:

       // Break whenever self.options.data in a given widget changes.
       debugValueChange( $('.widgetSelector').data('widgetName').options, 'data' );

       // Throw a console trace, but don't break, whenever a global var changes
       debugValueChange( window, 'my_global_var', true );

    */

	debugValueChange = function (parent, property_name, warn_only) {
		var alt_name = '_DEBUG_ALT_PROPERTY_' + (++unique);

		if (typeof parent === 'undefined') {
			return 'Parent object not found';
		}

		parent[alt_name] = parent[property_name];
		Object.defineProperty(parent, property_name, {
			get: function () {
				return parent[alt_name];
			},

			set: function (value) {
				if (warn_only) {
					console.log('Watched variable changed:', property_name, ' to ', value);
					console.trace();
				} else {
					debugger;
				}

				parent[alt_name] = value;
			}
		});

		return 'Watching...';
	};

	// On load, gather a list of global variables (i.e., descendents of "window"), so listGlobalVars can compare
	// For best results, load debug.js first, so nothing else has polluted window yet.

	var orig_globals;

	rescanGlobalVars = function () {
		var g_key, count = 0;
		orig_globals = {};
		for (g_key in window) {
			count++;
			if (window.hasOwnProperty(g_key)) {
				orig_globals[g_key] = true;
			}
		}

		return count + ' children of "window" ignored.';
	};

	rescanGlobalVars();

	// Returns an array of global variables-- Can filter (show or hide) based on var name or typeof
	// listGlobalVars({ show_type: 'regex string', hide_type: 'regex string', show_name: 'regex string', hide_name: 'regex string' });

	listGlobalVars = function (options) {
		var window_keys = [], w_key;
		options = options || {};
		for (w_key in window) {
			if (window.hasOwnProperty(w_key) &&
				!orig_globals[w_key] &&
				(!options.show_type || (typeof window[w_key]).match(new RegExp(options.show_type))) &&
				(!options.hide_type || !(typeof window[w_key]).match(new RegExp(options.hide_type))) &&
				(!options.show_name || (w_key.match(new RegExp(options.show_name)))) &&
				(!options.hide_name || !(w_key.match(new RegExp(options.hide_name))))
			   ) {
				window_keys.push(w_key);
			}
		}

		window_keys.sort();
		return window_keys;
	};

	// Make a debugValueChange on all global vars
	trackGlobalVars = function (options) {
		var globals, i;
		options = options || {};
		options.hide_type = options.hide_type || 'function';
		options.hide_name = options.hide_name || '_DEBUG_ALT_PROPERTY_';
		globals = listGlobalVars(options);

		for (i=0; i<globals.length; i++) {
			try {
				debugValueChange(window, globals[i], true, 3);
			} catch (e) {
				console.log('Could not capture: ' + globals[i]);
			}
		}
	};
})();
