/* exported getMessageFromKey */
/*
  This is the beginning of an internationlizable key-to-text system. Use getMessageFromKey(msg_key) to process error messages that may be keys.
*/

var getMessageFromKey;
(function () {
	"use strict";
	var messages = {
		PHONE_ALREADY_ASSIGNED: 'This phone has already been assigned to a Person on the system. Remove the phone before trying to assign it again.',
		DNS_NOT_USABLE: 'This DNS server did not respond.',
		TEST_ERROR: 'The test operation failed.', // Used in testing only
		ADMIN_PROHIBITED: 'The administrative user is not allowed to perform this operation.',
		ADMIN_REQUIRED: 'You must be logged in as the administrative user (admin) to perform this operation.',
		PASSWORD_NOT_ALLOWED: 'This password is too simple to be used. Please choose a different password.',
		PIN_NOT_ALLOWED: 'This PIN is too simple to be used. Please choose a different PIN.',
		EXISTING_PASSWORD_INCORRECT: 'This is not the current password.',
		EXISTING_PIN_INCORRECT: 'This is not your current PIN.',
		PASSWORD_INVALID: 'This is not a valid password.',
		PIN_INVALID: 'This is not a valid PIN.',

		INTERNAL_ERROR: 'There was an internal error or problem with this submission.'
	};

	getMessageFromKey = function (msg_key) {
		if (msg_key && msg_key in messages) {
			return messages[msg_key];
		} else {
			return msg_key;
		}
	};

})();
