/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/* A single-purpose widget to launch the CCC on init and click. Apply to a button. */

(function( $ ){
	var eggScreenWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			_tried: false
		},

		_afterInit: function () {
			var self = this, $self = this.element;
			$self.eggScreen();
		}
	});
	add_widget('eggScreenWidget', eggScreenWidget);
})(jQuery);
