register_template('page_buttons', function(field_def) {

    var templates = {
	simple: {
	    entity: 'button',
	    attributes: {
		'class': 'page_button'
	    },
	    text: ''
	},

	button: {
	    entity: 'button',
	    attributes: {
		'class': 'page_button'
	    },
	    widgets: ['linkButtonWidget'],
	    widget_options: {
		linkButtonWidget: { }
	    },
	    text: ''
	},

	overlay: {
	    entity: 'button',
	    attributes: {
		'class': 'page_button'
	    },
	    widgets: ['overlayButtonWidget'],
	    widget_options: {
		overlayButtonWidget: { }
	    },
	    text: '',
	    _pass_widget_options: 'overlayButtonWidget'
	},

	message: {
	    entity: 'button',
	    attributes: {
		'class': 'page_button'
	    },
	    widgets: ['messageButtonWidget'],
	    widget_options: {
		messageButtonWidget: { }
	    },
	    text: '',
	    _pass_widget_options: 'messageButtonWidget'
	},

	'continue': {
	    entity: 'button',
	    attributes: {
		'class': 'page_save_button_style'
	    },
	    widgets: ['linkButtonWidget'],
	    widget_options: {
		linkButtonWidget: { }
	    },
	    text: 'Continue'
	},

	'save_continue': {
	    entity: 'button',
	    attributes: {
		'class': 'page_save_button_style'
	    },
	    widgets: ['saveAndLinkButtonWidget'],
	    widget_options: {
		saveAndLinkButtonWidget: {

		}
	    },
	    text: 'Continue',
	    _pass_widget_options: 'saveAndLinkButtonWidget'
	},

	'up': {
	    entity: 'button',
	    attributes: {
		'class': 'page_button'
	    },
	    widgets: ['upButtonWidget'],
	    widget_options: {
		upButtonWidget: {

		}
	    },
	    text: 'Back',
	    _pass_widget_options: 'upButtonWidget'
	},

	'save_up': {
	    entity: 'button',
	    attributes: {
		'class': 'page_save_button_style'
	    },
	    widgets: ['saveAndUpButtonWidget'],
	    widget_options: {
		saveAndUpButtonWidget: {

		}
	    },
	    text: 'Save',
	    _pass_widget_options: 'saveAndUpButtonWidget'
	},

	'save_refresh': {
	    entity: 'button',
	    attributes: {
		'class': 'page_save_button_style'
	    },
	    widgets: ['saveAndRefreshButtonWidget'],
	    widget_options: {
		saveAndRefreshButtonWidget: {

		}
	    },
	    text: 'Save',
	    _pass_widget_options: 'saveAndRefreshButtonWidget'
	},

	'delete': {
	    entity: 'button',
	    attributes: {
		'class': 'page_button page_delete_button'
	    },
	    text: 'Delete',
	    widgets: [ 'deleteButtonWidget' ],
	    widget_options: {
		deleteButtonWidget: {
		    method: 'DELETE'
		}
	    },
	    _pass_widget_options: 'deleteButtonWidget'
	},

	save: {
	    entity: 'button',
	    attributes: {
		'class': 'page_save_button'
	    },
	    widgets: [ 'pageSaveButtonWidget' ],
	    _pass_widget_options: [ 'pageSaveButtonWidget' ],
	    text: 'Save'
	},

	fax_save: {
	    entity: 'button',
	    attributes: {
		'class': 'page_save_button'
	    },
	    widgets: [ 'faxSaveButtonWidget' ],
	    _pass_widget_options: [ 'faxSaveButtonWidget' ],
	    text: 'Send Fax'
	},

	run_report: {
	    entity: 'button',
	    attributes: {
		'class': 'run_report_button'
	    },
	    widgets: [ 'runReportButtonWidget' ],
	    _pass_widget_options: [ 'runReportButtonWidget' ]
	},

	create: {
	    entity: 'button',
	    attributes: {
		'class': 'page_save_button'
	    },
	    widgets: [ 'pageCreateButtonWidget' ],
	    widget_options: {
		pageCreateButtonWidget: {

		}
	    },
	    text: 'Create',
	    _pass_widget_options: 'pageCreateButtonWidget'
	},

	cancel: {
	    entity: 'button',
	    attributes: {
		'class': 'page_cancel_button'
	    },
	    widgets: [ 'pageCancelButtonWidget' ],
	    widget_options: {
		pageCancelButtonWidget: {
		}
	    },
	    _pass_widget_options: 'pageCancelButtonWidget',
	    text: 'Cancel'
	},

	cancel_back: {
	    entity: 'button',
	    attributes: {
		'class': 'page_cancel_button page_cancel_back_button'
	    },
	    widgets: ['backButtonWidget'],
	    widget_options: {
		backButtonWidget: { }
	    },
	    text: 'Cancel',
	    _pass_widget_options: 'backButtonWidget'
	},

	back: {
	    entity: 'button',
	    attributes: {
		'class': 'page_back_button'
	    },
	    widgets: ['backButtonWidget'],
	    widget_options: {
		backButtonWidget: { track_dirty: false }
	    },
	    text: 'Go Back',
	    _pass_widget_options: 'backButtonWidget'
	},

	impersonate: {
	    entity: 'button',
	    attributes: {
		'class': 'page_impersonate_button'
	    },
	    widgets: [ 'impersonateButtonWidget' ],
	    widget_options: { impersonateButtonWidget: {} },
	    text: 'Impersonate'
	},

	dialog: {
	    entity: 'button',
	    attributes: {
		'class': 'page_button'
	    },
	    widgets: [ 'formDialogButtonWidget' ],
	    widget_options: { formDialogButtonWidget: {} },
	    _pass_widget_options: 'formDialogButtonWidget'
	},

	extension: {
	    entity: 'button',
	    attributes: {
		'class': 'page_extension_button'
	    },
	    text: 'Change Primary Extension',
	    widgets: [ 'formDialogButtonWidget', 'aopbStateWidget' ],
	    widget_options: {
		formDialogButtonWidget: {
		    form_options: {
			dataEvents: {
			    trigger: 'primary_extension'
			}
		    },
		    title: 'Change Primary Extension',
		    submit_button: 'Apply',
		    cancel_button: 'Cancel',
		    elements: [ elgen('migrate_dialog') ]
		},
		aopbStateWidget: {
		    closest: 'div',
		    states: [{
			conditions: [
			    { a: '@flag_external', op: '==', b: '_TRUE_' },
			    { join: 'or', a: '@bbx_extension_block_begin', op: '!=', b: '@bbx_extension_block_end' }
			],
			actions: { visible: false },
			else_actions: { visible: true }
		    }]
		}
	    }
	},

	fullscreen: {
	    entity: 'button',
	    attributes: {
		'class': 'page_button page_fullscreen_button'
	    },
	    widgets: [ 'fullscreenButtonWidget' ],
	    widget_options: {
		fullscreenButtonWidget: {
		    text_off: 'Full Screen',
		    text_on: 'Docked',
		    selector: 'body',
		    css_class: 'fullscreen'
		}
	    }
	},

	help: {
	    entity: 'button',
	    attributes: {
		'class': 'page_help_button',
		'type': 'button'
	    },
	    text: 'Help',
	    widgets: ['helpButtonWidget'],
	    _pass_widget_options: 'helpButtonWidget'
	}
    }

    var template_data = {
	entity: 'div',
	attributes: {
	    'class': 'page_buttons'
	},
	widgets: [ 'containerWidget' ],
	widget_options: {
	    elements: [
		{
		    entity: 'div',
		    attributes: {
			'class': 'status'
		    },
		    widgets: [ 'containerWidget' ],
		    widget_options: {
			elements: [
			    {
				entity: 'img',
				attributes: {
				    'class': 'spinner',
				    'src': '/images/cui/spinner_content.gif'
				}
			    },
			    {
				entity: 'div',
				attributes: {
				    'class': 'error'
				}
			    }
			]
		    }
		}
	    ]
	}
    };

    if (field_def.buttons) {
	for (var b = 0, blen = field_def.buttons.length; b < blen; ++b) {
	    var button = field_def.buttons[b];
	    var copy;

	    if (button && button.requires) {
		if (!checkPermissions(button.requires)) { continue; }
	    }

	    if (button && (!button.type || !templates[button.type])) {
		button.type = 'button';
	    }

	    if (button) {
		copy = $.extend(true, {}, templates[button.type]);
	    }

	    // Special cases of data-moving for certain types. For a simple "copy everything to widget_options", use
	    //   _pass_widget_options: 'widgetType'
	    // in the template def.

	    if (button && button.href && copy.widget_options.linkButtonWidget) {
		copy.widget_options.linkButtonWidget.href = button.href;
	    }

	    if (button && button.reload && copy.widget_options.saveAndUpButtonWidget) {
		copy.widget_options.saveAndUpButtonWidget.reload = button.reload;
	    }

	    if (button && button.extension_type && copy.widget_options.formDialogButtonWidget) {
		copy.widget_options.formDialogButtonWidget.form_options.rest = button.rest || ('/gui/extension/' + button.extension_type);
	    }

	    if (button && templates[button.type]._pass_widget_options) {
		var bwo = { };
		bwo[ templates[button.type]._pass_widget_options ] = button;
		$.extend(true, copy, { widget_options: bwo });
		delete copy._pass_widget_options;
	    }

	    if (button && button.text) {
		copy.text = button.text;
	    }

	    if (button && button.attributes) {
		copy.attributes = button.attributes;
	    }

	    if (button && button.options) {
		$.extend(true, copy, button.options);
	    }

	    template_data.widget_options.elements.push(copy);
	}
    }

    if (field_def.elements) {
	for(e in field_def.elements) {
	    template_data.widget_options.elements.push(field_def.elements[e]);
	}
    }

    return template_data;
});
