(function () {
	$.fn.typeout = function (directives, options) {
		options = $.extend({
			speed: 1,
			stickyscroll: true,
			onComplete: null
		}, options);

		var elem = this[0];
		var currentDirective = -1, $elem = this;
		var speedMult = options.speed ? 1 / options.speed : 1;

		var scroll = function () {
			if (!options.stickyscroll) { return; }
			elem.scrollTop = elem.scrollHeight;
		}

		var doNextDirective = function () {
			currentDirective++;
			var doNextChar;
			var directive = directives[currentDirective], $currentSpan = $('<span class="typeout" />');
			if (!directive) {
				options.onComplete && options.onComplete();
				return;
			}

			if (directive[3]) {
				var colors = directive[3].split(':');
				if (colors[0]) { $currentSpan.addClass('typeout-fg-' + colors[0]); }
				if (colors[1]) { $currentSpan.addClass('typeout-bg-' + colors[1]); }
			}

			if (!directive[1]) {
				$currentSpan.text(directive[0]).appendTo($elem);
				scroll();
				setTimeout(doNextDirective, directive[2] * speedMult);
			} else {
				var stringSplit = directive[0].split('');
				$currentSpan.text(stringSplit.shift()).appendTo($elem);
				doNextChar = function () {
					$currentSpan.text($currentSpan.text() + stringSplit.shift());
					scroll();
					if (stringSplit.length) {
						setTimeout(doNextChar, directive[1] * speedMult);
					} else if (directive[2]) {
						setTimeout(doNextDirective, directive[2] * speedMult);
					} else {
						doNextDirective();
					}
				};
				doNextChar();
			}
		};
		doNextDirective();
	};
})();
