
register_template('callers_waiting_table', function(field_def) {
    var callers = build_standard_entity_options('table', 'dataTableWidget', field_def, {
	widget_options: {
	    dataTableWidget: {
		live_table: "live_fifo_callers_waiting",
		live_table_key: "callers_waiting",
		data_source: "live",
		page_size: 10000,
		filtering: false,
		length_change: false,
		paginate: false,
		processing: false,
		js_sort_by: [ ["timestamp", "asc"] ],
		sortable: false,
		oLanguage: {
		    sZeroRecords: "No callers waiting."
		},
		extra_row_data: [
		    "caller_caller_id_name",
		    "caller_caller_id_number",
		    "callee_id_name",
		    "callee_id_number",
		    "bbx_queue_name",
		    "bbx_queue_warning_seconds",
		    "bbx_queue_critical_seconds",
		    "timestamp",
		    "current_time",
		    "deleted",
		    "delete_delay"
		],
		columns: [
		    {
			header: "Call Time",
			column: "timestamp",
			sortable: false,
			data_type: "num-html",
			sort_data_type: "num-html-asc",
			view_elements: [
			    { entity_template: "callers_waiting" }
			]
		    }
		]
	    }
	}
    });

    return callers;
});
