(function () {
    register_screen('add_ins', {
	elements: [
	    { entity_template: 'page_title', title: 'Add-Ins' },
		{
		    entity_template: "page_content",
		    buttons: [{ type: "download" }],
		    elements: [
				{
				rest: '/gui/addons/outlook_dl_info',
				entity_template: 'page_module',
				legend: 'Microsoft Outlook Click-to-Call Add-In',
				buttons: ['help'],
  		      	help_id: 'add_in',
				elements: [
					{
						entity_template: 'aopb_container',
						states: [{
							conditions: { a: '@url', op: '==', b: '_TRUEISH_' },
							else_elements: [{ entity: 'div', text: 'Information about this download could not be retrieved. Contact your system administrator for more information about the Add-In and its availability.' }],
							elements: [
								{
									entity_template: 'input_item',
									type: 'div',
									title: 'Version:',
									input_name: 'version'
								},
								{
									entity_template: 'data_item',
									title: 'Documentation:',
									elements:[
										{
											entity: 'a',
											attributes: { href: 'https://techlib.barracuda.com/display/CUDATEL/Click-to-Dial+Outlook+Add-In', target: '_blank' },
											text: 'Documentation for the Click-to-Call Add-In for Microsoft Outlook'
										},
										{
											entity: 'div',
											attributes: { 'class': 'info' },
											text: 'Please read the Installation and Start-up notes before installing this software. It contains important release notes and compatibility information.'
										}
									]
								},
								{
									entity_template: 'data_item',
									title: 'Download:',
									elements:[{
										entity_template: 'dynamic_link',
										name: 'url',
										link_text: 'Download the Click-to-Call Add-In for Microsoft Outlook',
										target: '_blank'
									}]
								}
							]
						}]
					}]
				}
		    ]
		}
	] // END template elements
    }); // END template
})(); // END scoping function
