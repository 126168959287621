(function () {
    var pass_search_rest_params = ['type', 'primary'];

    var move_search_rest_params = function (tpl) {
	var psrp_idx = pass_search_rest_params.length, psrp;

	tpl.search_rest_params = tpl.search_rest_params || {};

	while (psrp_idx--) {
	    psrp = pass_search_rest_params[psrp_idx];
	    if (psrp in tpl) {
		tpl.search_rest_params[psrp] = tpl[psrp];
		delete tpl[psrp];
	    }
	}

	return tpl;
    };

    register_template('extension_picker_suggest', function (field_def) {
	var tpl, el_def;

	tpl = $.extend({
	    entity: 'input',
	    attributes: {
		'class': 'extensionPickerSuggest',
		type: 'text'
	    },

	    widgets: ['flyoutPhoneNumberSearchWidget'],

	    widget_options: {
		flyoutPhoneNumberSearchWidget: {
		    search_rest: '/gui/extension/list',
		    message: 'Please enter a valid extension or number',
		    value_key: field_def.value_key || 'bbx_extension_value',
		    render_row: {
			entity_template: 'container',
			elements: [
			    {
				entity: 'div',
				widgets: ['extensionRowWidget']
			    }
			]
		    }
		}
	    }
	}, field_def);

	tpl = move_search_rest_params(tpl);
	el_def = build_standard_entity_options(tpl.entity, 'flyoutPhoneNumberSearchWidget', tpl);
	return el_def;
	
    });

    register_template('extension_picker_select', function (field_def) {
	var tpl, el_def;

	tpl = $.extend({
	    entity: 'div',
	    widgets: ['tableFilteredFlyoutSearchSelectWidget'],
	    attributes: {
		'class': 'extensionPickerSelect',
		style: 'min-width: 10em'
	    },
	    widget_options: {
		tableFilteredFlyoutSearchSelectWidget: {
		    search_rest: '/gui/extension/list',
		    lookup_search_rest: '/gui/extension/extension',
		    lookup_search_rest_container: 'extension',
		    render_row: {
			entity: 'div',
			widgets: ['extensionRowWidget']
		    }
		}
	    }
	}, field_def);

	tpl = move_search_rest_params(tpl);
	el_def = build_standard_entity_options(tpl.entity, 'tableFilteredFlyoutSearchSelectWidget', tpl);
	return el_def;
    });

    register_template('extension_picker_suggest_validate', function (field_def) {
	var tpl, el_def;

	tpl = $.extend({
	    entity: 'input',
	    attributes: {
//		'class': 'extensionPickerSuggest',
		type: 'text'
	    },

	    widgets: ['flyoutExtensionSearchWidget'],

	    widget_options: {
		flyoutExtensionSearchWidget: {
		    search_rest: '/gui/extension/list',
		    message: 'Please enter a valid extension',
		    value_key: field_def.value_key || 'bbx_extension_value',
		    render_row: {
			entity_template: 'container',
			elements: [
			    {
				entity: 'div',
				widgets: ['extensionRowWidget']
			    }
			]
		    }
		}
	    }
	}, field_def);

	tpl = move_search_rest_params(tpl);
	el_def = build_standard_entity_options(tpl.entity, 'flyoutExtensionSearchWidget', tpl);
	return el_def;
	
    });

})();
