/* ****************************************************************************************************************************************
 *
 *   WARNING:  changes to this screen def may affect the first run screen: firstrun_update.js , please test any changes on both screens.
 *
 ****************************************************************************************************************************************** */

register_screen('updates', {
    elements:[
		{ entity_template: 'page_title', title: 'Updates' },
		{ entity_template: 'page_buttons' },
		{ entity_template: 'page_content', elements: [
			{
				entity_templates: [ 'container', 'key_value' ],
				key_value: {
					'live_table': 'live_system_stats',
					'live_table_key': 'update',
					key_column: 'name',
					value_column: 'value'
				},

				container: {
					elements: [
						{
							entity_template: 'widget_div',
							rest_method: 'post',
							rest: '/gui/update/query',
							attributes: { style: 'display: none' }
						},
						{
							entity_template: 'page_module',
							legend: 'Subscription Information',
							buttons: ['help'],
							help_id: 'update_subscription_info',
							elements: [
								{
									entity_template: 'input_item',
									title: 'Model:',
									type: 'div',
									input_name: 'full_prodname'
								},
								{
									entity_template: 'input_item',
									title: 'Serial Number:',
									type: 'div',
									input_name: 'system_serial'
								},
								elgen('subscription_status_item', { title: 'Energize Updates Subscription', type: 'energize' }),
								elgen('subscription_status_item', { title: 'Instant Replacement Subscription', type: 'inst_replace' }),
								{
									entity_template: 'input_item',
									title: 'Product Activation State:',
									type: 'div',
									input_options: {
										widgets: ['aopbStateWidget'],
										widget_options: {
											aopbStateWidget: {
												states: [
													{
														conditions: [{ a: '@activated', op: '==', b: '_TRUEISH_' }],
														actions: { text: 'Activated' },
														else_actions: { text: 'Not Activated' },
														last_if_true: true
													},
													{
														// Add this condition so it doesn't show "Not Activated" until the fillData.
														conditions: [{ a: '@activated', op: '==', b: '_UNDEFINED_' }],
														actions: { text: ' ' }
													}
												]
											}
										}
									}
								}
							]
						},
						{
							entity_template: 'page_module',
							legend: 'Available Updates',
							buttons: ['help'],
							help_id: 'update_updates',
							elements: [
								{
									entity_template: 'data_item',
									title: 'Currently Installed Version:',
									elements: [
										{
											entity_template: 'widget_div',
											name: 'system_version'
										},
										{
											entity: 'button',
											widgets: ['releaseNotesLinkButtonWidget'],
											text: 'Release Notes',
											attributes: { name: 'system_version' },
											widget_options: {
												releaseNotesLinkButtonWidget: {
													new_window: true
												}
											}
										}
									]
								},
								{
									entity_template: 'data_item',
									title: 'Latest General Release Firmware:',
									elements: [
										{
											entity_template: 'aopb_container',
											states: [
												{
													conditions: { a: '@ga_version', op: '==', b: '@system_version' },
													elements: [
														{
															entity: 'div',
															text: 'Currently Installed'
														}
													],
													else_elements: [
														{
															entity_template: 'widget_div',
															name: 'ga_version'
														},
														{
															entity: 'button',
															widgets: ['releaseNotesLinkButtonWidget'],
															text: 'Release Notes',
															attributes: { name: 'ga_version' },
															widget_options: {
																releaseNotesLinkButtonWidget: {
																	new_window: true
																}
															}
														}
													]
												}
											]
										},
										{
											entity_template: 'aopb_container',
											states: [
												{
													conditions: [
														{ a: '@downloaded_version', op: '==', b: '@ga_version' },
														{ join: 'and', a: '@system_version', op: '!=', b: '@ga_version' },
														{ join: 'and', a: '@download_in_progress', op: '==', b: '_FALSEISH_' }
													],
													elements: [
														{
															entity_template: 'update_install_button',
															text: 'Install',
															rest:'/gui/update/apply',
															method: 'POST',
															blanker_options: {
																title: 'Installation in progress',
																text: 'Installing General Access Firmware',
																blanker: true,
																flyby: false,
																progress: true,
																icon: false,
																buttons: false,
																ping: { url: '/gui/sysinfo/rebooting', time: 30000 }
															}
														},
														{
															entity_template: 'action_button',
															text: 'Delete',
															rest: '/gui/update/delete',
															method: 'POST',
															confirm: false
														},
														{
															entity: 'div',
															attributes: { 'class': 'info' },
															text: 'The system will be taken offline while the update is being installed.'
														}
													]
												},
												{
													conditions: [
														{ a: '@downloaded_version', op: '!=', b: '@ga_version' },
														{ join: 'and', a: '@system_version', op: 'v<', b: '@ga_version' }
													],
													elements: [
														{
															entity_template: 'action_button',
															text: 'Download',
															rest: '/gui/update/download',
															confirm: {
																text: 'Are you sure you wish to download this General Access firmware version?',
																title: 'Download GA Firmware',
																buttons: ['Download','Cancel']
															},
															method: 'POST',
															widgets: ['aopbStateWidget'],
															widget_options: {
																aopbStateWidget: {
																	states: [
																		{
																			conditions: { a: '@download_in_progress', op: '!=', b: '_TRUEISH_' },
																			actions: { enabled: true },
																			else_actions: { enabled: false }
																		}
																	]
																}
															}
														},
														{
															entity_template: 'action_button',
															text: 'Cancel Download',
															rest: '/gui/update/cancel',
															method: 'POST',
															widgets: ['aopbStateWidget'],
															widget_options: {
																aopbStateWidget: {
																	states: [
																		{
																			conditions: { a: '@download_in_progress', op: '==', b: '_TRUEISH_' },
																			actions: { enabled: true },
																			else_actions: { enabled: false }
																		}
																	]
																}
															}
														},
														{
															entity: 'span',
															text: 'Verifying download....',
															attributes: { 'class': 'info', hidden: true },
															widgets: ['aopbStateWidget'],
															widget_options: {
																aopbStateWidget: {
																	states: [
																		{
																			conditions: [
																				{ a: '@download_progress', op: '==', b: '100'},
																				{ join: 'and', a: '@download_in_progress', op: '==', b: '_TRUEISH_' },
																				{ join: 'and', a: '@download_release', op: '!=', b: 'beta' }
																			],
																			actions: { visible: true },
																			else_actions: {visible: false }
																		}
																	]
																}
															}
														}
													]
												}
											]
										},
										{
											entity_templates: ['container', 'aopb_state'],
											container: {
												attributes: { hidden: true },
												elements: [
													{
														entity_template: 'progress_bar',
														name: 'download_progress',
														text_value: '0',
														min_value: 0,
														max_value: 100
													}
												]
											},
											aopb_state: {
												closest: '.containerWidget',
												states: [
													{
														conditions: [
															{ a: '@download_in_progress', op: '==', b: '_TRUEISH_' },
															{ join: 'and', a: '@download_release', op: '!=', b: 'beta' }
														],
														actions: { visible: true },
														else_actions: { visible: false }
													}
												]
											}
										}
									]
								},
								{
									entity_template: 'data_item',
									title: 'Latest Early Access Firmware:',
									elements: [
										{
											entity_template: 'aopb_container',
											states: [
												{
													conditions: { a: '@beta_version', op: '==', b: '@system_version' },
													elements: [
														{
															entity: 'div',
															text: 'Currently Installed'
														}
													],
													else_elements: [
														{
															entity_template: 'widget_div',
															name: 'beta_version'
														},
														{
															entity: 'button',
															widgets: ['releaseNotesLinkButtonWidget'],
															text: 'Release Notes',
															attributes: { name: 'beta_version' },
															widget_options: {
																releaseNotesLinkButtonWidget: {
																	new_window: true
																}
															}
														}
													]
												}
											]
										},
										{
											entity_template: 'aopb_container',
											attributes: {
												'class': 'download_aopb'
											},
											states: [
												{
													conditions: [
														{ a: '@downloaded_version', op: '==', b: '@beta_version' },
														{ join: 'and', a: '@system_version', op: '!=', b: '@beta_version' },
														{ join: 'and', a: '@download_in_progress', op: '==', b: '_FALSEISH_' }
													],
													elements: [
														{
															entity_template: 'update_install_button',
															text: 'Install',
															rest:'/gui/update/apply',
															method: 'POST',
															rest_params: { type: 'beta' },
															blanker_options: {
																title: 'Installation in progress',
																text: 'Installing Early Access Firmware',
																blanker: true,
																flyby: false,
																progress: true,
																icon: false,
																buttons: false,
																ping: { url: '/gui/sysinfo/rebooting', time: 30000 }
															}
														},
														{
															entity_template: 'action_button',
															text: 'Delete',
															rest: '/gui/update/delete',
															method: 'POST',
															confirm: {
																text: 'Are you sure you wish to delete the downloaded Early Access Firmware?',
																title: 'Delete Downloaded EA Firmware',
																buttons: ['Delete', 'Cancel']
															},
															rest_params: { type: 'beta' }
														},
														{
															entity: 'div',
															attributes: { 'class': 'info' },
															text: 'The system will be taken offline while the update is being installed.'
														}
													]
												},
												{
													conditions: [
														{ a: '@downloaded_version', op: '!=', b: '@beta_version' },
														{ join: 'and', a: '@system_version', op: 'v<', b: '@beta_version' }
													],
													elements: [
														{
															entity_template: 'action_button',
															text: 'Download',
															rest: '/gui/update/download',
															method: 'POST',
															rest_params: { type: 'beta' },
															widgets: ['aopbStateWidget'],
															widget_options: {
																aopbStateWidget: {
																	states: [
																		{
																			conditions: { a: '@download_in_progress', op: '!=', b: '_TRUEISH_' },
																			actions: { enabled: true },
																			else_actions: { enabled: false }
																		}
																	]
																}
															}
														},
														{
															entity_template: 'action_button',
															text: 'Cancel Download',
															rest: '/gui/update/cancel',
															method: 'POST',
															rest_params: { type: 'beta' },
															widgets: ['aopbStateWidget'],
															widget_options: {
																aopbStateWidget: {
																	states: [
																		{
																			conditions: { a: '@download_in_progress', op: '==', b: '_TRUEISH_' },
																			actions: { enabled: true },
																			else_actions: { enabled: false }
																		}
																	]
																}
															}
														},
														{
															entity: 'span',
															text: 'Verifying download....',
															attributes: { 'class': 'info', hidden: true },
															widgets: ['aopbStateWidget'],
															widget_options: {
																aopbStateWidget: {
																	states: [
																		{
																			conditions: [
																				{ a: '@download_progress', op: '==', b: '100'},
																				{ join: 'and', a: '@download_in_progress', op: '==', b: '_TRUEISH_' },
																				{ join: 'and', a: '@download_release', op: '==', b: 'beta' }
																			],
																			actions: { visible: true },
																			else_actions: {visible: false }
																		}
																	]
																}
															}
														}
													]
												}
											]
										},
										{
											entity_templates: ['container', 'aopb_state'],
											container: {
												elements: [
													{
														entity_template: 'progress_bar',
														name: 'download_progress',
														text_value: '0',
														min_value: 0,
														max_value: 100
													}
												]
											},
											aopb_state: {
												closest: '.containerWidget',
												attributes: { hidden: true },
												states: [
													{
														conditions: [
															{ a: '@download_in_progress', op: '==', b: '_TRUEISH_' },
															{ join: 'and', a: '@download_release', op: '==', b: 'beta' }
														],
														actions: { visible: true },
														else_actions: { visible: false }
													}
												]
											}
										}
									]
								}
							]
						}
					]
				}
			}
		]}
    ]
});
