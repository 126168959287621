/* jshint jquery: true, unused: vars */
/* global add_widget */
(function( $ ){
	var callRecordingDaysWidget = $.extend({}, $.ui.widget.prototype, {
		options: {
			// Validation options
			validate_max: undefined,           // Maximum acceptable value
			invalid_message: 'Invalid input',  // Error/invalid message

			_enabled: false
		},

		value_widget: true,
		set_value_widget: true,

		manages_own_descendent_value: true,
		manages_own_descendent_events: true,

		_beforeInit: function () {
			var self = this;
			var $self = this.element;
			var inputObjects = {};

			self.options.inputObjects = inputObjects;

			// Generate enable/disable checkbox
			inputObjects.$enableCheckbox = $('<input type="checkbox" class="enableCheckbox" />').appendTo($self);

			$('<span> for </span>').appendTo($self);

			// Generate Days Input Text
			inputObjects.$daysInput = $('<input type="text" class="daysInput" size="3" />').appendTo($self);
			inputObjects.$daysInput.keyFilter({ allow_keys_regexp: '^(NUM)?[0-9]$', allow_shift: false });

			$('<span> days</span>').appendTo($self);

			// Bind to element input events
			inputObjects.$daysInput.on('keyup', function () { self._updateValue(); });
			inputObjects.$enableCheckbox.on('keyup change', function () { self._checkboxChangeHandler(); });
		},

		_checkboxChangeHandler: function() {
			var self = this, $self = self.element;

			if ($self.find('input.enableCheckbox').is(':checked')) {
				self._enableInput();
			} else {
				self._disableInput();
			}
		},

		_validate: function(val) {
			var self = this, $self = this.element, result = false;

			if (val === '' || val === undefined || val === null) {
				result = false;
			} else {
				val = parseInt(val, 10);

				if (!isNaN(val)) {
					if (val === 0) {
						result = 0;
					} else if (val >= 0) {
						if (self.options.validate_max) {
							if (val <= self.options.validate_max) {
								result = val;
							}
						} else {
							result = val;
						}
					}
				}
			}

			if (result === false) {
				self._isInvalid();
			} else {
				self._isValid();
			}

			return result;
		},

		_isInvalid: function() {
			var self = this, $self = self.element;

			$self.addClass('is-invalid');
			$self.data('error', self.options.invalid_message);
		},

		_isValid: function() {
			var self = this, $self = self.element;

			$self.removeClass('is-invalid');
			$self.data('error', null);
		},

		_enableInput: function() {
			var self = this, $self = self.element;

			$self.find('input.daysInput').removeClass('state-disabled').enable();
			self.options._enabled = true;

			if ($self.find('input.daysInput').val() === '') {
				$self.find('input.daysInput').val(1);
			}

			self._updateValue();

			$self.trigger('change');
		},

		_disableInput: function() {
			var self = this, $self = self.element;

			$self.find('input.daysInput').val('').addClass('state-disabled').disable();
			self.options._enabled = false;

			self._updateValue();

			self._isValid();
		},

		/*
	 * @fn _updateValue(e)
	 * @brief Check for validity after an input elements value is changed, and fire the change event.
	 */
		_updateValue: function() {
			var self = this;
			var $self = $(this.element);
			var inputObjects = self.options.inputObjects;
			var textValue = inputObjects.$daysInput.val();

			var val = self._validate(textValue);

			if (val === 0) { 
				self._isInvalid();
				self.options.submit_value = 0;
			} else if (val === false) {
				// Our input did not validate
				self.options.submit_value = 0;
			} else {
				// Input DID validate
				self.options.submit_value = val;
			}

			$self.trigger('change');
		},

		_getWidgetValue: function () {
			return this._wrapValue(this.options.submit_value);
		},

		setValue: function (v) {
			var self = this, $self = this.element;
			var inputObjects, int_value;

			inputObjects = self.options.inputObjects;
			int_value = self._validate(v);

			if (int_value === false) {
				inputObjects.$daysInput.val('');
				$self.trigger('change');
				return;
			}

			if (v > 0) {
				self._enableInput();
				inputObjects.$daysInput.val(v);
				inputObjects.$enableCheckbox.prop('checked', true);
			} else {
				inputObjects.$enableCheckbox.prop('checked', false);
				self._disableInput();
			}

			self.options.submit_value = v;
			$self.trigger("change");
		}

	});

	add_widget('callRecordingDaysWidget', callRecordingDaysWidget);
})(jQuery);
