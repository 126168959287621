/* jshint jquery: true, unused: vars */
/* global add_widget */
(function( $ ){
	var dayPickerWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			days: undefined, // Defaulted in _beforeInit
			day_offset: 1,

			_days_checkboxes: []
		},

		value_widget: true,
		set_value_widget: true,

		manages_own_descendent_values: true,

		_beforeInit: function () {
			var self = this, $self = this.element, d_idx, d, $d, $cb, $container;
			$container = $('<span class="check-buttons-container" />');
			$container.attr('unselectable','on');

			$container.appendTo($self);

			self.options.days = self.options.days || [
				{ text: 'Su', value: 0 },
				{ text: 'Mo', value: 1 },
				{ text: 'Tu', value: 2 },
				{ text: 'We', value: 3 },
				{ text: 'Th', value: 4 },
				{ text: 'Fr', value: 5 },
				{ text: 'Sa', value: 6 }
			];

			for (d_idx = 0; d_idx < self.options.days.length; d_idx++) {
				d = self.options.days[d_idx];
				$d = $('<label class="check-button-label"><input type="checkbox" /><span class="check-button-title"></span></label>');
				$d.find('.check-button-title').text(d.text);
				$cb = $d.find(':checkbox');
				$cb.val(d.value + self.options.day_offset);
				self.options._days_checkboxes.push($cb);
				$container.append($d);
			}
		},

		_getWidgetValue: function () {
			var self = this, $self = this.element, d_idx, d, val_array = [];
			for (d_idx = 0; d_idx < self.options.days.length; d_idx++) {
				d = self.options.days[d_idx];
				if (self.options._days_checkboxes[d_idx].is(':checked')) {
					val_array.push(d.value + self.options.day_offset);
				}
			}
			return self._wrapValue(val_array.join(','));
		},

		setValue: function (v) {
			var self = this, $self = this.element, day_offset = self.options.day_offset, vals, vals_set = {}, range, a, b, r_ct, d_idx, d;

			v = v || '';

			// This can come in as either the standard comma-sep format (2,3,4,5), or the legacy "range" format (2-5)
			if (v.indexOf('-') !== -1) {
				// Legacy format
				range = v.split('-');

				a = range[0] - day_offset;
				b = range[1] - day_offset;

				if (a > b) {
					for (r_ct = 0; r_ct <= b; r_ct++) {
						vals_set[r_ct] = true;
					}
					for (r_ct = a; r_ct < 7; r_ct++) {
						vals_set[r_ct] = true;
					}
				} else {
					for (r_ct = a; r_ct <= b; r_ct++) {
						vals_set[r_ct] = true;
					}
				}
			} else if (v || v === 0) {
				vals = v.split(',');
				for (d_idx = 0; d_idx < vals.length; d_idx++) {
					vals_set[vals[d_idx] - day_offset] = true;
				}
			}

			// Now, vals_set is a 0-indexed array of true/undefined
			for (d_idx = 0; d_idx < self.options.days.length; d_idx++) {
				d = self.options.days[d_idx];
				if (vals_set[d_idx]) {
					self.options._days_checkboxes[d_idx].attr('checked','checked');
				} else {
					self.options._days_checkboxes[d_idx].removeAttr('checked');
				}
			}
		}
	});


	add_widget('dayPickerWidget', dayPickerWidget);
})(jQuery);
