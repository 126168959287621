/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, debugLog */
(function( $ ){
	var ldapDiscoverButtonWidget = $.extend(true, {}, $.ui.actionButtonWidget.prototype, {
		options: {
			submit_values: {},
			closest: 'form',
			servers: [],
			method: 'POST'
		},

		_afterInit: function () {
			var self = this, $self = this.element;
			$.ui.actionButtonWidget.prototype._afterInit.apply(this, arguments);
		},

		_afterConfirm: function () {
			var self = this, $self = this.element, keys, length, idx, $elem, input_value;

			keys   = self.options.include_presubmit_data;
			length = keys.length;

			// This information will be used later, in _getRESTParams
			for (idx = 0; idx < length; idx++) {
				$elem = $self.closest(self.options.closest).find('[name=' + keys[idx] + ']');
				input_value = $elem.is('input') ? $elem.val() : $elem.find('input').val();
				self.options.submit_values[keys[idx]] = input_value;
			}

			$.ui.actionButtonWidget.prototype._afterConfirm.apply(self, arguments);
		},

		_getRESTParams: function () {
			var self = this, rest_data;

			rest_data = $.ui.actionButtonWidget.prototype._getRESTParams.apply(self, arguments) || {};
			$.extend(rest_data, self.options.submit_values);

			return rest_data;
		},

		_success: function (data) {
			var self = this, $self = this.element, d_length, server, s_name;

			self.options.data = data;

			if (self.options._dialog) {
				self.options._dialog.remove();
				delete self.options._dialog;
			}

			var $closest = $self.closest(self.options.closest);
			if (!$closest[0]) {
				debugLog('jquery.fillDataButtonWidget.js: Bad "closest" parameter "', self.options.closest, '" -- ', $self);
				return;
			}

			if (self.options.rest_container) {
				data = data[self.options.rest_container];
			}
			d_length = data.length;

			if (d_length < 1) {
				// No servers found. Call this to clear the form and display the error message.
				self._notFound();
				return;
			}

			for (var s_idx = 0; s_idx < d_length; s_idx++) {
				server = data[s_idx];
				s_name = server.address;
				self.options.servers[s_idx] = {};
				for (var i in self.options.field_map) {
					var field = self.options.field_map[i];
					var mapKey, mapVal;

					if ($.isPlainObject(self.options.field_map)) {
						mapKey = field;
						mapVal = i;
					}
					else {
						mapKey = field;
						mapVal = field;
					}

					self.options.servers[s_idx][mapKey] = server[mapVal];
				}
			}

			self._populateData();

			// Send it to the superclass _success...
			return $.ui.actionButtonWidget.prototype._success.apply(this, arguments);

		},

		_notFound: function() {
			var self = this, $self = this.element, $form =  $self.closest('.dirtyFormWidget');

			new CUI.Dialog({
				title: 'Not Found',
				text: 'No server found, please check settings or use manual configuration',
				buttons: [ 'OK' ]
			});

			// If we set the server_type field to 'manual', the form will clear itself out

			if ($form) {
				$form.find('[name="server_type"]').val('manual').trigger('change');
			}
		},

		_error: function(xhr, status, error) {
			var self = this, $self = this.element;

			if (self.options._dialog) {
				self.options._dialog.remove();
				delete self.options._dialog;
			}

			// var detail_error = $.parseJSON(xhr.responseText);
			var $closest = $self.closest(self.options.closest);
			if (!$closest[0]) {
				debugLog('jquery.fillDataButtonWidget.js: Bad "closest" parameter "', self.options.closest, '" -- ', $self);
				return;
			}

			return $.ui.actionButtonWidget.prototype._error.apply(this, arguments);
		},

		_populateData: function () {
			var self = this, $self = this.element, form, d = {}, s_length, idx;

			form = $self.closest('form').getCUIWidget('dirtyFormWidget');
			s_length = self.options.servers.length;
			d.ldap_servers = [];

			for (idx = 0; idx < s_length; idx++) {
				d.ldap_servers.push(self.options.servers[idx]);
			}

			form.fillData(d, false);
		}


	}); // end widget variable definition
	add_widget('ldapDiscoverButtonWidget', ldapDiscoverButtonWidget);
})(jQuery);
