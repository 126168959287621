/*
  Flyout Search Widget--

  A widget that attaches to an INPUT field (or references one) and performs a search (via REST call, expecting paged output) on that input,
  in a dropdown list.

  USAGE:

  Apply this to a TEXT type INPUT, to show and apply suggestions of valid values.

  OPTIONS:

  // Selector to the INPUT element, under $self where the search is entered. If left undefined, then the widget itself is assumed to be applied
  // to the INPUT element.
  input_selector: undefined | 'selector',

  // Strings to use for the "No results found" and "No text entered" states
  no_results_text: 'No results found' | '...',
  no_search_text: 'Type to begin searching...' | '...',

  // REQUIRED: Element to use to render the row
  render_row: [ { element definition object(s)... } ],

  // What key's value, from the selected result, will be used to fill the INPUT element? If left undefined, the widget's NAME attribute is used as the key.
  value_key: undefined | '...key-name...',

  // Options for the REST call used for the information lookup...
  search_rest: '/path/to/controller',
  search_method: 'GET' | 'POST' | '...',
  // If undefined, automatically uses the controller action name. If false, assumes the data is unwrapped. If a key-name is given, uses that.
  search_rest_container: undefined, // undefined | false | '...key-name...'
  search_rest_params: {},
  search_string_key: 'search_string' | '...keyname...',
  search_rows: 5,

  // If true, the value of the INPUT will be changed whenever an item is navigated-to. If "false", clicking on the item, or pressing ENTER is required.
  select_on_activate: false | true,

  flyout_template_html: '...HTML string...'

  REQUIRES:

  widget.flyoutWidget mixin

  PUBLIC METHODS:

  // Update the search string, and refresh the search if it is different than the previous
  setSearchString(STRING v)

  // Paging
  pageUp()
  pageDown()

  // Set the current selection. Index should take any meta-rows into account. If literal false, any selection is removed.
  selectionSet( INTEGER zero_based_index | false )

  // Move the selection up or down 1 entry
  selectionMove( -1 | 1 )

*/

(function( $ ){
    var flyoutSearchWidget = $.extend(true, {}, $.ui.widget.prototype, CUI.mixin.get('widget.flyoutWidget'), {
	options: {

	    input_selector: undefined,   // undefined (use $self) | '.selector' (under $self)

	    // Selector for elements within the render_row object, and they will NOT be considered a row click. Use this for links and other things.
	    not_click_selector: '.button, .clickable, a[href], button',

	    allow_empty_search: false,
	    select_on_activate: true,

	    no_results_text: 'No results found.',
	    no_search_text:  'Type to begin searching...',

	    render_row: undefined, // Set in beforeInit to prevent deep copy

	    value_key: undefined, // undefined | '...'
	    // What key will be used to fill the input element when a value is picked?
	    // undefined: Use the NAME of the input element
	    // STRING value: Set the value to the property with the key given (data[s.o.display_value_key])

	    search_rest: undefined,
	    search_method: 'GET',
	    search_rest_container: undefined, // undefined (auto) | false (none) | 'container_key'
	    search_rest_params: {},
	    search_text_key: 'search_string',
	    search_rows: 5,

	    flyout_template_html: '<div class="flyout-search-content">'+
'  <div class="flyout-search-results">'+
'    <div class="flyout-search-message flyout-search-working" />'+
'    <div class="flyout-search-message flyout-search-empty">Type to begin searching...</div>'+
'    <div class="flyout-search-message flyout-search-none">No matches.</div>'+
'    <div class="flyout-search-list" />'+
'    <div class="flyout-search-page-message">Items <span class="flyout-search-start-num" />' + entity.ndash + '<span class="flyout-search-end-num" /> of <span class="flyout-search-total-num" /></div>'+
'  </div>'+
'</div>',

	    // For internal use
	    _search_list_row_count: 0 // Contains the total result row-count, including meta-action rows
	},

	_search_content_class: 'flyout-search-content',
	_search_list_wrapper_class: 'flyout-search-results',
	_search_list_class: 'flyout-search-list',

	_message_class: 'flyout-search-message',

	_working_message_class: 'flyout-search-working',
	_empty_message_class: 'flyout-search-empty',
	_no_results_message_class: 'flyout-search-none',

	_page_message_class: 'flyout-search-page-message',
	_page_start_num_class: 'flyout-search-start-num',
	_page_end_num_class: 'flyout-search-end-num',
	_page_total_num_class: 'flyout-search-total-num',

	_row_container_class: 'flyout-search-row-container',
	_selected_row_class: 'flyout-search-row-selected',

	_defocus_delay: 10, // You probably don't ever need to change this

	_beforeInit: function () {
	    var self = this, $self = this.element;
	    self.options.render_row = self.options.render_row || { entity: 'div', text: 'No row renderer!' }; // Clobber, not merge
	    if ($self.is(':text')) {
		$self.attr('autocomplete', 'off');
	    }
	},

	_beforeDOMReady: function () {
	    var self = this, $self = this.element;
	    self.options._$input = self.options.input_selector ? $self.find(self.options.input_selector) : $self;
	    self._flyoutSearchBind();
	},

	_flyoutSearchBind: function () {
	    var self = this, $input = self.options._$input, ref__keyHandlerKeyAny;

	    ref__keyHandlerKeyAny = self._keyHandlerKeyAny.bind(self);

	    self._bind($input, 'keyup', CUI.FunctionFactory.build(self._keyHandlerKeyup, self, { context: 'argument', first: 'context' }));
	    self._bind($input, 'keyup', ref__keyHandlerKeyAny);
	    self._bind($input, 'keydown', ref__keyHandlerKeyAny);
	    self._bind($input, 'keypress', ref__keyHandlerKeyAny);
	    self._bind($input, 'blur', self._defocusHandlerWait.bind(self));
	},


	// This just "preventDefault"s keys like PgUp/PgDn in browsers that need it on the keydown event (not the keyup event)
	_keyHandlerKeyAny: function (e, data) {
	    var self = this, key = new CUI.KeyCode(e.which), key_name = key.getName();
	    if (key_name in { PGUP: true, PGDN: true, ESC: true, UP: true, DOWN: true, ENTER: true }) {
		e.preventDefault();
	    }
	},

	// This actually handles "keyup" messages on self.options._$input
	_keyHandlerKeyup: function (elem, e, data) {
	    var self = this, $self = this.element, $elem = $(elem), nav_key_lookup, key = new CUI.KeyCode(e.which), key_name = key.getName(), input_value;
	    input_value = CUI.getWidgetElementValue(self.options._$input, { first_value: true });
	    nav_key_lookup = { PGUP: true, PGDN: true, ESC: true, UP: true, DOWN: true, ENTER: true };

	    self.options.flyout_clicked = false; // How can you be in two places at once, when you're not anywhere at all?

	    if (key_name in nav_key_lookup) {
		e.preventDefault();
		e.stopPropagation();
	    } else {
		self.setSearchString(input_value);
	    }

	    switch (key_name) {
	    case 'PGUP':
		self.pageUp();
		break;
	    case 'PGDN':
		self.pageDown();
		break;
	    case 'UP':
		if (self.options.flyout_visible) {
		    self.selectionMove(-1);
		} else {
		    self.setSearchString(input_value);
		}
		break;
	    case 'DOWN':
		if (self.options.flyout_visible) {
		    self.selectionMove(1);
		} else {
		    self.setSearchString(input_value);
		}
		break;
	    case 'ENTER':
		if (self.options.flyout_visible) {
		    e.preventDefault();

		    if (self.options._selection_index !== undefined) {
			self.selectionSet(self.options._selection_index, true);
		    }

		    self.hideFlyout();
		}
	    case 'ESC':
		if (self.options.flyout_visible) {
		    self._defocusHandler();
		}
	    }
	},

	// Used by _defocusHandler. Sets a flag to indicate that focus (probably) moved from the INPUT to the flyout, so we shouldn't close the flyout.
	// This flag is ONLY cleared on search, not on flyout show/hide, so always check for flyout_visible as well, when using it!
	_flyoutMousedownHandler: function (e) {
	    this.options.flyout_clicked = true;
	},

	// Set the selection when the user clicks on an item
	_rowClickHandler: function (elem, e, d) {
	    var self = this;
	    // If "closest thing matching not-clicks", plus "us" is "us", then the target was not under a not-click, then we're okay
	    if ($(e.target).closest(self.options.not_click_selector + ', .' + self._row_container_class).is(elem)) {
		self.selectionSet(self.options._$flyout.find('.' + self._search_list_class + ' > .' + self._row_container_class).index(elem), true);
		self.hideFlyout();
	    }
	},

	// Decouple textbox blur and wait a moment to be sure the focus wasn't given to the flyout (meaning the flyout should stay)
	// The timeout can be clobbered by a mousedown on the flyout
	_defocusHandlerWait: function (e) {
	    if (!this.options.flyout_visible) { return; } /// ...or don't
	    setTimeout(CUI.FunctionFactory.build(this._defocusHandler, this, { context: 'argument' }, arguments), self._defocus_delay);
	},

	// The actual defocus handler, called once we are sure the defocus was legitimate
	_defocusHandler: function (e) {
	    var self = this, $self = this.element;
	    if ( self.options.destroyed ) { return; }
	    if (!(self.options.flyout_visible && self.options.flyout_clicked)) {
		// Do not hide the flyout if this was a click from the INPUT to the flyout itself
		self.hideFlyout();
	    }
	},

	// Called when the search string changes, usually after a keyup event
	setSearchString: function (v) {
	    var self = this, $self = this.element, url, method, data, search_obj, ref__updateSearchCompleteCallback;

	    if (self.options.flyout_visible && v === self.options._search_string) {
		// Prevents arrow, nav-keys, etc. from refreshing the existing search
		return;
	    }

	    self.options._search_string = v;
	    self.options._search_page = 1;

	    if (!self.options.flyout_visible) {
		self.showFlyout(self._getFlyoutSearchInitialContent());
	    }

	    self.selectionSet(false);

	    if (!self.options._flyout_click_callback) { // One-time bind
		self.options._flyout_click_callback = true;

		self._bind(self.options._$flyout, 'mousedown', self._flyoutMousedownHandler.bind(self));

		self._delegate(
		    self.options._$flyout,
		    '.' + self._row_container_class,
		    'click',
		    CUI.FunctionFactory.build(self._rowClickHandler, self, { context: 'argument', first: 'context' })
		);
	    }

	    self._updateSearch();
	},

	// Performs the search REST call, and updates the display to "please wait".
	// Takes no params-- expects s.o._search_string and s.o._search_page to be set.
	_updateSearch: function () {
	    var self = this, $self = this.element, v = self.options._search_string, pg = self.options._search_page || 1, search_obj, data, url, method;

	    delete self.options._search_results;
	    if (self.options._active_rest_call) { self.options._active_rest_call.abort(); }

	    if (!self.options.allow_empty_search && v !== 0 && !v) {
		self._flyoutSearchState('empty');
		self.getFlyoutContentElement().find('.' + self._search_list_class).empty();
		self._afterUpdateSearch();
		return;
	    }

	    search_obj = { page: self.options._search_page, rows: self.options.search_rows };
	    search_obj[self.options.search_text_key] = v;

	    data = $.extend({}, self.options.search_rest_params, search_obj);
	    url = self.options.search_rest;
	    method = self.options.search_method;
	    ref__updateSearchCompleteCallback = self._updateSearchCompleteCallback.bind(self);

	    self._flyoutSearchState('working');
	    self.options._active_rest_call = CUI.doREST(method, url, data, ref__updateSearchCompleteCallback);
	},

	// After the REST call has come back...
	_updateSearchCompleteCallback: function (d) {
	    var self = this, $self = this.element, search, data, d_idx, el_def, $item_tplt, $item, $list, $page_display, paged, $content;
	    delete self.options._active_rest_call;

	    if (self.options.destroyed) { return; }

	    search = self._getSearchResultsFromData(d);
	    data = search.results;
	    self.options._search_list_row_count = data.length;

	    // Nuke anything that was there previously...
	    $list = self.options._$flyout.find('div.flyout-search-list');
	    $list.empty();

	    // Make some rows!
	    if (data.length) {
		self._flyoutSearchState('found');

		el_def = self.options.render_row;
		if (!$.isArray(el_def)) { el_def = [el_def]; }
		$item_tplt = $('<div />').addClass(self._row_container_class).append(CUI.htmlEntityClass.getEntitiesHTML(el_def, {}));

		for (d_idx = 0; d_idx < data.length; d_idx++) {
		    $item = $item_tplt.clone().appendTo($list).data('data_index', d_idx);
		    if ($item.find('.widgetType:not(.widgetized)')[0]) {
			widgetize_children($item);
			self.fillDataChildren(data[d_idx], true, $item);
		    }
		}

		// Handle paging and page display
		paged = false;

		if (search.meta.page > 1) {
		    self.options._allow_pgup = true;
		    paged = true;
		}

		if (search.meta.count > search.meta.page * self.options.search_rows) {
		    self.options._allow_pgdn = true;
		    paged = true;
		}

		if (paged) {
		    $content = self.getFlyoutContentElement();
		    $content.find('.' + self._page_start_num_class).text(search.meta.rows * (search.meta.page - 1) + 1);

		    if (search.meta.pages === 1) {
			$content.find('.' + self._page_end_num_class).text(search.meta.rows);
		    } else if ((search.meta.page == search.meta.pages) && !search.meta.even) {
			$content.find('.' + self._page_end_num_class).text(search.meta.count);
		    } else {
			$content.find('.' + self._page_end_num_class).text(search.meta.rows * search.meta.page)
		    }

		    $content.find('.' + self._page_total_num_class).text(search.meta.count);
		}

		self._flyoutSearchState(paged ? 'paged' : 'one_page');

	    } else {
		// If data is an empty array...
		self._flyoutSearchState('none');
	    }

	    self._afterUpdateSearch();
	},

	// A catch-all for actions required after the search is complete. Adds meta-rows, then ensures the count is correct.
	_afterUpdateSearch: function () {
	    var self = this;
	    self._addMetaRows();
	    if (self.options._search_list_row_count && typeof self.options.auto_select_index === 'number') {
		self.selectionSet(self.options.auto_select_index);
	    }
	},

	// Process a raw REST return into a { results: [...], meta: { ... } } object.
	_getSearchResultsFromData: function (d, alt_rest_container) {
	    var self = this, $self = this.element, action, rest_container, data, search, col_vals, name = $self.attr('name');

	    // Get our data in order... A function that mimics validate_rest_container etc. from Widget Base
	    // "alt_rest_container" is required for subclasses that do a different lookup the first time. It is not used in this widget.
	    rest_container = alt_rest_container || self.options.search_rest_container;

	    if (rest_container === undefined) {
		action = self.options.search_rest.replace(/^.+\//, '');
		if (action in d) {
		    rest_container = action;
		    data = d[rest_container];
		}
	    } else if (rest_container === false) {
		data = d;
	    } else {
		data = d[rest_container];
	    }

	    if (!$.isArray(data)) {
		data = [data];
	    }

	    if (self.options.expand_blocks) {
		data = self._expandBlocks(data);
	    }

	    search = self.options._search_results = {
		results: data,
		meta: {
		    count: d.count || data.length,
		    page:  d.page || 1,
		    rows:  d.rows || data.length,
		    pages: Math.ceil((d.count || data.length) / (d.rows || data.length)),
		    even: !((d.count || data.length) % (d.rows || data.length))
		}
	    };

	    return search;
	},

	// Override this to prepend or append "meta" rows, such as "nothing" in the flyoutSearchSelectWidget.
	// Be sure to update s.o._search_list_row_count after adding rows!
	_addMetaRows: function () {},

	// The name says it all...
	pageUp: function () {
	    var self = this;
	    if (self.options._allow_pgup && self.options._search_results.meta.page > 1) {
		self.selectionSet(false);
		if (--self.options._search_page <= 0) {
		    self.options._search_page = 1;
		} else {
		    self._updateSearch();
		}
	    }
	},

	// Ditto.
	pageDown: function () {
	    var self = this;
	    if (self.options._allow_pgdn && self.options._search_results.meta.pages > self.options._search_results.meta.page) {
		self.selectionSet(false);
		self.options._search_page++;
		self._updateSearch();
	    }
	},

	// Set the selection to the given index. "Explicit" param true indicates that the selection should be "picked" and not just highlighted.
	// Pass 'false' to remove any existing selection.
	selectionSet: function (index, explicit) {

	    var self = this, $rows, $row, data_index;

	    $rows = self.options._$flyout.find('.' + self._search_list_class + ' > .' + self._row_container_class);
	    $row = $rows.eq(index);

	    if (index === false) {
		delete self.options._selection_index;
		return;
	    }

	    self.options._selection_index = index;
	    $rows.removeClass(self._selected_row_class)
	    $row.addClass(self._selected_row_class);

	    if (self.options.select_on_activate || explicit) {
		data_index = $row.data('data_index');
		if (data_index !== undefined && self.options._search_results) {
		    self._pickValue(self.options._search_results.results[data_index]);
		} else {
		    self._pickMeta($row.data('meta_action'));
		}
	    }
	},

	// Move the selection up or down. "Offset" should be -1 or 1.
	selectionMove: function (offset) {
	    var self = this, $self = this.element, search = self.options._search_results, index, $flyout = self.options._$flyout, $rows;

	    // Trying to move the selection 0 places, or move it when there aren't any items selected? Why?!?
	    if (!(offset && self.options._search_list_row_count)) {
		return;
	    }

	    $rows = self.options._$flyout.find('.' + self._search_list_class + ' > .' + self._row_container_class);

	    // If it's undefined, that means that nothing is currently selected
	    if (self.options._selection_index === undefined) {
		if (offset < 0) {
		    self.selectionSet(self.options._search_list_row_count - 1);
		    return;
		} else {
		    self.selectionSet(0);
		    return;
		}
	    }

	    index = self.options._selection_index;

	    if (index + offset < 0) {
		self.pageUp();
	    } else if (index + offset >= self.options._search_list_row_count) {
		self.pageDown();
	    } else {
		self.selectionSet(index + offset);
	    }
	},

	// Handler for clicking or pressing "enter" (picking) on a row
	_pickValue: function (result) {
	    var self = this, value_key;

	    value_key = self.options.value_key;

	    if (value_key === undefined) {
		value_key = CUI.getElementName(self.options._$input);
		if (value_key === undefined) {
		    debugLog('jquery.flyoutSearchWidget.js: No NAME or s.o.value_key. Which key should I use for the value? -- ', self.element);
		    return;
		}
	    }
	    self._applyValue(result[self.options.value_key]);
	},

	// Handles "meta" action row picks.
	_pickMeta: function (meta_action) {},

	// Apply the selected value to the widget or control. You may need to override this if your selection process is more complex.
	_applyValue: function (v) {
	    var self = this, $self = this.element;

	    if (self.options._$input.is(':input')) {
		self.options._$input.val(v);
	    } else {
		self.options._$input.text(v)
	    }

	    self.options.value = v;
	    $self.trigger('change');
	},

	// This is a method, not a property, because it uses "self" parts.
	_getFlyoutSearchStates: function () {
	    var self = this;
	    return ({
		global: {
		    show: [],
		    hide: []
		},
		found: {
		    show: [self._search_list_class],
		    hide: [self._working_message_class, self._empty_message_class, self._no_results_message_class]
		},
		none: {
		    show: [self._search_list_class, self._no_results_message_class],
		    hide: [self._working_message_class, self._empty_message_class, self._page_message_class]
		},
		empty: {
		    show: [self._search_list_class, self._empty_message_class],
		    hide: [self._working_message_class, self._no_results_message_class, self._page_message_class]
		},
		working: {
		    show: [self._working_message_class],
		    hide: [self._search_list_class, self._empty_message_class, self._no_results_message_class]
		},
		one_page: {
		    hide: [self._page_message_class]
		},
		paged: {
		    show: [self._page_message_class]
		}
	    });
	},

	// Set a "state" on the flyout, that shows or hides certain elements. "State" is a keyword string.
	_flyoutSearchState: function (state) {
	    var self = this, $self = this.element, $content = self.getFlyoutContentElement().children('.flyout-search-content'), all_states, states, hide = [], show = [];
	    all_states = self._getFlyoutSearchStates();
	    states = all_states[state];

	    if (states) {
		show = (states.show || []).concat(all_states.global.show || []);
		hide = (states.hide || []).concat(all_states.global.hide || []);
	    }

	    if (show[0]) {
		$content.find('.' + show.join(', .')).show();
	    }

	    if (hide[0]) {
		$content.find('.' + hide.join(', .')).hide();
	    }
	},


	// Generate the initial DOM/jQuery elements for the flyout contents, including the search results list and any message DIVs.
	_getFlyoutSearchInitialContent: function () {
	    var self = this, $self = this.element, $content, $search;
	    $content = $(self.options.flyout_template_html);
	    $content.find('.' + [ self._page_message_class, self._empty_message_class, self._search_list_class ].join(', .')).hide();
	    return $content;
	},

	_expandBlocks: function (data) {
	    var self = this, $self = this.element, modified_data = [];
	    var data_length   = data.length;
	    var search        = self.options._search_string;
	    var search_length = search.length;
	    var first_entry, numeric_first, last_entry, numeric_last, combined;

	    for (var idx = 0; idx < data_length; idx++) {
		var block = data[idx], created_data;
		first_entry = last_entry = search;

		if (!block['bbx_extension_value'].match(/\d+-\d+/) || (search_length < ( parseInt(block['bbx_extension_block_begin'].length) - 1 ))) { continue; }

		for (var add_digit = 0; add_digit < (block['bbx_extension_block_end'].length - search_length); add_digit++) {
		    first_entry += '0';
		    last_entry  += '9';
		}
		if (first_entry < block['bbx_extension_block_begin']) { first_entry = block['bbx_extension_block_begin'] }
		if (last_entry > block['bbx_extension_block_end']) { last_entry = block['bbx_extension_block_end'] }
		numeric_first = parseInt(first_entry);
		numeric_last  = parseInt(last_entry);

		while (numeric_first <= numeric_last) {
		    created_data = $.extend({}, block)
		    created_data['bbx_extension_value'] = String(numeric_first);
		    numeric_first++;
		    modified_data.push(created_data);
		}
	    }
	    if (modified_data[0]) {
		combined = modified_data;
	    } else {
		combined = data;
	    }
	    return combined;
	}
    });

    add_widget('flyoutSearchWidget', flyoutSearchWidget);
})(jQuery);
