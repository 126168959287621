/* jshint jquery: true, unused: vars */
/* global add_widget */
/*
  A minor subclass to remove default click action when a link is used as a formDialogButton
*/

(function( $ ){
	var formDialogLinkWidget = $.extend(true, {}, $.ui.formDialogButtonWidget.prototype, {
		_beforeInit: function () {
			var self = this, $self = this.element;
			$self.attr('href', '#');
			$self.on('click', self._pd);
			$.ui.formDialogButtonWidget.prototype._beforeInit.apply(self, arguments);
		},

		_pd: function (e) { e.preventDefault(); }

	});

	add_widget('formDialogLinkWidget', formDialogLinkWidget);
})(jQuery);
