/*

page_title template creates an H2 title with either a text string or a GET-controller-pulled value (usually a name)
No formatting is applied.

USAGE:

1.) { entity_template: 'page_title', title: 'Simple Page Title' }
2.) { entity_template: 'page_title', field: 'bbx_thing_name', OPTIONAL rest: '/gui/controller/action' }

*/

register_template('page_title', function(field_def) {
    var tpl = {
	    entity: 'h2',
	    attributes: {
		'class': 'page_title'
	    }
    };

    if (field_def.title) {
	tpl = $.extend(true, tpl, {
	    widgets: [ 'containerWidget' ],
	    widget_options: {
		containerWidget: {
		    elements: [{
			entity: 'span',
			text: field_def.title
		    }]
		}
	    }
	});
	return tpl;

    } else if (field_def.field) {
	tpl = $.extend(true, tpl, {
	    widgets: [ 'widget' ],
	    attributes: { 'name': field_def.field }
	});

	if (field_def.rest) {
	    tpl = $.extend(true, tpl, { widget_options: { rest: field_def.rest } });
	}

	return tpl;
    }
});
