(function () {
    register_screen('registrations', {
	elements: [
	    { entity_template: 'page_title', title: 'Phone Registrations' },
            {
                entity_template: 'page_buttons',
                buttons: [{ type: 'help', help_id: 'reports_phone_registrations' }]
            },
	    {
		entity_template: 'page_content', elements: [
		    {
			entity_template: 'data_table',
			rest: '/gui/freeswitch/registrations/select_active',
			attributes: {
			    'class': 'reg_dataTable'
			},
			rest_params: {
			    rows: '25',
			    page: '1'
			},
			unique_id: 'uuid',
			oLanguage: {
			    sZeroRecords: 'No Registrations of this type'
			},
			columns: [
			    { header: 'Extension', column: 'extension', data_type: 'num-html', data_sort_type: 'num-html-asc' },
			    { header: 'Name', column: 'name', 'class':'break-long' },
			    { header: 'User Agent', column: 'model' },
			    { header: 'Expires', column: 'expires', view_element: { entity_template: 'epoch_date', name: 'expires', format: '%Sw %SM %D, %h:%0m:%0s %A' } },
			    {
				header: 'IP',
				column: 'network_ip',
				formatted: {
				    format: '$1$2',
				    fields: [
					{ field: '@network_ip' },
					{ field: '@network_port', formatter: 'switch', options: { if_true: ':$2', if_false: '' } }
				    ]
				}
			    },
			    {
				header: 'MAC address',
				column: 'mac',
				formatted: {
				    format: {
					field: '@mac',
					formatter: 'mac'
				    }
				}
			    }
			],
			table_actions: {
			    refresh: {text: 'Refresh'},
			    action_elements: {
				actions: [
				    {
					entity: 'span',
					text: 'Show:'
				    },
				    {
					entity: 'select',
					attributes: {
					    name: 'rest',
					    uri_id: 'dtw_rest_path'
					},
					select_options: [
					    { text: 'Active Registrations', value: '/gui/freeswitch/registrations/select_active' },
					    { text: 'Inactive Registrations', value: '/gui/freeswitch/registrations/select_inactive' },
					    { text: 'Restricted Registrations', value: '/gui/freeswitch/registrations/select_jailed' }
					],
					widgets: ['dtwRestPathChangeWidget'],
					widget_options: {
					    dtwRestPathChangeWidget: {
						selector: '.reg_dataTable'
					    }
					}
				    }
				],
				location: 'top',
				position: 'first'
			    }
			}
		    }
		] // END page_content elements
	    } // END page_content
	] // END template elements
    }); // END template
})(); // END scoping function
