/* jshint jquery: true, unused: vars */
/* global add_widget */
(function( $ ){

	var dataHolderContainerWidget = $.extend({}, $.ui.containerWidget.prototype, {
		fillData: function (d, from_self) {
			var self = this;
			self.options.data = $.extend(self.options.data || {}, d);
			$.ui.containerWidget.prototype.fillData.apply(self, arguments);
		}
	});

	add_widget('dataHolderContainerWidget', dataHolderContainerWidget);
})(jQuery);
