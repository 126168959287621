(function () {
    register_screen('modules', {
	elements: [
	    { entity_template: 'page_title', title: 'Firmware Modules' },
	    { entity_template: 'page_buttons', buttons: [ { type: 'cancel' }, { type: 'save' }] },
	    { entity_template: 'page_content', elements: [

		{
		    entity_template: 'page_module',
		    legend: 'Firmware Modules',
		    buttons: [ 'help' ],
		    help_id: 'firmware_modules',
		    elements: [
			{
			    entity_template: 'data_table',
			    rest: '/gui/defs/defs_array',
			    data_source: 'defs_array',
			    accept_data_from_parent: false,
			    primary_keys: ['name'],
			    /*
			    delete_action: {
				rest: '/gui/defs/defs'
			    },
			    */
			    table_actions: {
				/*
				select_row: true,
				select_all_rows: true,
				delete_rows: {
				    text: 'Uninstall Selected Modules',
				    location: "bottom",
				    confirm: {
					title: 'Uninstall Selected Modules',
					text:  'Uninstall the selected Firmware Modules? The features will be unavailable until the modules are installed again.',
				    }
				}
				*/
			    },
			    columns: [
				{
				    header: 'Status',
				    column: 'status',
				    view_elements: [{
					entity_template: 'switch',
					closest: '*',
					selector: '@status',
					switch_elements: {
					    'required' : [{ entity: 'div', text: 'Required' }],
					    'installed' : [{ entity: 'div', text: 'Installed' }],
					    'available' : [{
						entity_template: 'action_button',
						text: 'Install'
					    }]
					}
				    }],
				    sortable: false
				},
				{
				    header: 'Module',
				    column: 'description',
				    sortable: false
				},
				{
				    header: 'Version',
				    column: 'def_version',
				    sortable: false
				}
			    ]
			}
		    ]
		},
		{
		    entity_template: 'page_module',
		    legend: 'Security Definitions',
		    buttons: [ 'help' ],
		    help_id: 'security_defs',
		    form_action: '/gui/defs/security',
		    elements: [
			{
			    entity_template: 'input_item',
			    title: 'Automatically Update Security Definition Modules:',
			    info: 'Disabling this option may leave your system open to security risks.',
			    type: 'checkbox',
			    input_name: 'secdef_auto_update'
			}
		    ]
		}

	    ]} // END page_content
	]
    });
})();
