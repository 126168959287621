/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
// Uses browser-native fullscreen to make the page use the entire screen; removes the browser interface in supported browsers.

(function( $ ){
	var fullscreenButtonWidget = $.extend({}, $.ui.classToggleButtonWidget.prototype, {
		options: {
			_fullscreen: false
		},

		_beforeInit: function () {
			var self = this;
			self._bind($(document), 'mozfullscreenchange msfullscreenchange webkitfullscreenchange fullscreenchange', self._fsHandler.bind(self));
			$.ui.classToggleButtonWidget.prototype._beforeInit.apply(this, arguments);
		},

		_launchFullscreen: function() {
			var self = this, element = document.documentElement;

			if(element.requestFullscreen) {
				element.requestFullscreen();
			} else if(element.mozRequestFullScreen) {
				element.mozRequestFullScreen();
			} else if(element.webkitRequestFullscreen) {
				element.webkitRequestFullscreen();
			} else if(element.msRequestFullscreen) {
				element.msRequestFullscreen();
			}

			self.options._fullscreen = true;
		},

		_exitFullscreen: function() {
			var self = this;

			if(document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			}
			self.options._fullscreen = false;
		},

		_fsHandler: function () {
			var self = this, $self = this.element;

			if (
				document.mozFullScreenElement === null ||
				document.msFullscreenElement === null ||
				document.webkitFullscreenElement === null ||
				document.fullscreenElement === null
			) {
				self.options._fullscreen = false;
				self.setClassState(false);
			}
		},

		_clickHandler: function(e) {
			var $self = this.element;
			var self = this;

			e.preventDefault();
			e.stopPropagation();

			if(self.options._fullscreen){
				self._exitFullscreen();
			} else {
				self._launchFullscreen();
			}

			$.ui.classToggleButtonWidget.prototype._clickHandler.apply(self, arguments);
		}
	});

	add_widget('fullscreenButtonWidget', fullscreenButtonWidget);
})(jQuery);
