/* jshint jquery: true, unused: vars */
/* global add_widget */
/*
  Allows selecting a number from a range, given by options or by data items. Apply to a SELECT.

  OPTIONS:
  {
      start: 0 | (integer) | 'key_name',
      end:   0 | (integer) | 'key_name',
      step:  1,

      // Set custom text or a value for a given number. Used, for instance, to make "0" say "None" and return an empty string. Ignored if omitted.
      text_for_number:  {} | { (integer): 'text' }
      value_for_number: {} | { (integer): 'value' }

      accept_option_data_from_parent: true | false
  }
*/

(function( $ ){
	var selectRangeWidget = $.extend(true, {}, $.ui.widget.prototype, {
		set_value_widget: true,

		options: {
			start: 0,
			end: 0,
			step: 1,
			accept_option_data_from_parent: true,

			text_for_number: {},
			value_for_number: {},

			_requires_fillData: false
		},

		_beforeInit: function () {
			var self = this;//, $self = this.element;
			self.options._requires_fillData = (typeof self.options.start !== 'number' || typeof self.options.end !== 'number');
		},

		fillData: function (d, from_self) {
			var self = this, $self = this.element;

			if (!self.options._requires_fillData) {
				$.ui.widget.prototype.fillData.apply(self, arguments);
			}

			if (self.options.accept_option_data_from_parent || from_self) {
				self.options._stored_data = $.extend(self.options._stored_data || {}, d);
			}

			if (!self.options._options_built) {
				self._buildOptions();
			}

			$.ui.widget.prototype.fillData.apply(self, arguments);
		},

		setValue: function (v) {
			var self = this, $self = this.element;
			self.options.value = v;
			$self.val(v);
		},

		_buildOptions: function () {
			var self = this, $self = this.element, start, end, idx, $o_tpl, text, val;

			if (
				(typeof self.options.start === 'string' && !(self.options.start in self.options._stored_data)) ||
				(typeof self.options.end   === 'string' && !(self.options.end   in self.options._stored_data))
			) {
				// Not enough data!
				return false;
			}

			start = typeof self.options.start === 'string' ? Number(self.options._stored_data[self.options.start]) : self.options.start;
			end   = typeof self.options.end   === 'string' ? Number(self.options._stored_data[self.options.end])   : self.options.end;

			if (start > end) {
				end = [start, start = end][0]; // One-liner to swap the vars
			}

			$self.empty();

			$o_tpl = $('<option />');
			for (idx = start; idx <= end; idx++) {
				text = (idx in self.options.text_for_number)  ? self.options.text_for_number[idx]  : idx;
				val  = (idx in self.options.value_for_number) ? self.options.value_for_number[idx] : idx;

				$o_tpl.clone()
					.text(text)
					.val(val)
					.appendTo($self);
			}

			if (self.options.value !== undefined) { self.setValue(self.options.value); }

			self.options._options_built = true;
			return true;
		}
	});

	add_widget('selectRangeWidget', selectRangeWidget);
})(jQuery);
