/* jshint jquery: true */
/* global add_widget, trueish */
(function( $ ){
	"use strict";
	var activationLinkWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {},
		_beforeInit: function () {
			var self = this, $self = this.element;
			$self.attr('href', location.href);
		},
		fillData: function (d) {
			var self = this, $self = this.element;
			if (d.activation_url) {
				$self.attr('target', '_blank');
				$self.attr('href', d.activation_url.replace(/&amp;/g, '&') + '&connected_update=' + (trueish(d.activation_online) ? '1' : '0'));
			}
		}
	});

	add_widget('activationLinkWidget', activationLinkWidget);
})(jQuery);
