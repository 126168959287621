/* jshint jquery: true, unused: vars */
/* global CUI */
/*
  Filters any keys that do not match s.o.allow_keys_regexp. Apply to a textbox. This is a jQ-UI plugin, not a CUI widget.

  USAGE:
  $(...).keyFilter({
    allow_keys_regexp:   '<regexp string>',
    check_function_keys: false | true,
    allow_shift: false | true,
    only_shift: false | true
  })

  Note that this handles keys, not letters, so shift+key combinations that add different characters may not block or allow as expected.

  REQUIRES:
  * CUI class
  * CUI.FunctionFactory
  * CUI.KeyCode

 */

(function( $ ){
	$.widget('cui_standalone.keyFilter', {

		options: {
			allow_keys_regexp: '[0-9]',
			check_function_keys: false
		},

		_create: function () {
			var self = this, $self = this.element;
			$self.on('keydown.keyFilter', self._keyPressHandler.bind(self));
		},

		_keyPressHandler: function (e) {
			var self = this, key = new CUI.KeyCode(e.which), modifier_ok = true;

			if (key.isFunctionKey()) { return; }

			if ((e.shiftKey && !self.options.allow_shift) || (!e.shiftKey && self.options.only_shift)) {
				modifier_ok = false;
			}

			if (!key.match(self.options.allow_keys_regexp) || !modifier_ok) {
				e.preventDefault();
				e.stopPropagation();
			}
		},

		_destroy: function () {
			$('keydown.keyFilter').off();
		}
	});

})(jQuery);
