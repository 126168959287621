/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, getUnique, debugLog */
(function( $ ){
	var faxViewWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			template_html: '<button class="fax-view-view">View</button><button class="fax-view-download">Download</button><span class="fax-view-holder" style="display:none"></span>',
			accept_data_from_parent: true
		},

		_beforeInit: function () {
			var self = this, $self = this.element;
			$self.addClass('fax-view');

			if ($self.is('button')) {
				$self.attr('type','button');
			}

		},

		_beforeDOMReady: function () {
			var self = this, $self = this.element;
			self._bind($self.find('.fax-view-download'), 'click', self._doDownload.bind(self));
			self._bind($self.find('.fax-view-view'), 'click', self._doView.bind(self));
		},

		fillData: function (d) {
			var self = this, $self = this.element, dir, pages_key, pages;

			if (d.bbx_fax_inbound_id) {
				dir = self.options._dir = 'inbound';
			} else if (d.bbx_fax_outbound_id) {
				dir = self.options._dir = 'outbound';
			} else {
				return;
			}

			self.options._fax_data = d;

			pages_key = 'bbx_fax_' + dir + '_pages_total';
			pages = Number(d[pages_key]);
			self.options._link = '/gui/fax/file?bbx_fax_' + dir + '_id=' + d['bbx_fax_' + dir + '_id'];

			if ('bbx_fax_' + dir + '_pages_total' in d) {
				if (!d['bbx_fax_' + dir + '_pages_total']) {
					$self.find('.fax-view-view,.fax-view-download').disable('no_fax');
				}
			} else {
				// This is a CDR table view, most likely
				self.options._pull_fax_data = true;
			}
		},

		_doDownload: function (e) {
			var self = this, $self = this.element;
			if (e) { e.preventDefault(); }
			if (self.options._link) {
				window.location.href = self.options._link + '&format=pdf';
			} else {
				debugLog('jquery.faxViewWidget.js: Link was not created yet. Nowhere to go -- ', $self);
			}
		},

		_pullFaxDataFirst: function (d) {
			var self = this, params = {};
			if (self.options._pulling_fax_data) { return; }
			self.options._pulling_fax_data = true;
			params['bbx_fax_' + self.options._dir + '_id'] = d['bbx_fax_' + self.options._dir + '_id'];
			CUI.getREST('/gui/fax/fax', params, self._pullFaxDataHandler.bind(self));
		},

		_pullFaxDataHandler: function (d) {
			var self = this;
			delete self.options._pulling_fax_data;
			self.options._pull_fax_data = false;
			self.options._fax_data = d.fax;
			self._doView();
		},

		_doView: function (e) {
			var self = this, $self = this.element, $links, pages, p_idx, link, dir, uid = getUnique('faxgroup');

			if (self.options._pull_fax_data) {
				self._pullFaxDataFirst(self.options._fax_data);
				return;
			}

			$links = $self.find('.fax-view-holder');

			if (!self.options._view_ready) {
				dir = self.options._dir;
				pages = self.options._fax_data['bbx_fax_'+dir+'_pages_total'];

				if (!pages) {
					$self.find('.fax-view-view,.fax-view-download').disable('no_fax');
					return;
				}

				$links.empty();

				for (p_idx = 1; p_idx <= pages; p_idx++) {
					link = '/gui/fax/file?bbx_fax_' + dir + '_id=' + self.options._fax_data['bbx_fax_' + dir + '_id'] + '&page=' + p_idx + '&format=png';
					$links.append($('<span class="fax-view-item">ITEM</span>').data('fax_view', { group: uid, img: link, page: p_idx }));
				}

				$links.find('.fax-view-item').picbox({ margins: 60 }, self._getItemData, self._filterItem);
				self.options._view_ready = true;
			}

			$links.find('.fax-view-item:first').trigger('click');
		},

		_getItemData: function (el) {
			var d = $(el).data('fax_view');
			return [d.img, 'Page ' + d.page];
		},

		_filterItem: function (root_el) {
			return ($(this).data('fax_view').group === $(root_el).data('fax_view').group);
		}

	});

	add_widget('faxViewWidget', faxViewWidget);
})(jQuery);
