/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
// Adds a click handler to the element (usually a BUTTON) to go to a URL

(function( $ ){
	var releaseNotesLinkButtonWidget = $.extend({}, $.ui.linkButtonWidget.prototype, {
		set_value_widget: true,

		options: {
			href: '#'
		},

		fillData: function (d) {
			var self = this, $self = this.element;
			self.options.href = 'http://updates.cudasvc.com/cgi-bin/view_release_notes.cgi?type=bpsware&platform=2&version=' + d[ CUI.getElementName($self) ];
		},

		setValue: function (v) {
			this.options.href = 'http://updates.cudasvc.com/cgi-bin/view_release_notes.cgi?type=bpsware&platform=2&version=' + v;
		}
	});

	add_widget('releaseNotesLinkButtonWidget', 'ui.releaseNotesLinkButtonWidget', 'releaseNotesLinkButtonWidget', releaseNotesLinkButtonWidget);
})(jQuery);
