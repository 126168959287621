/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/*
  dataPollerWidget: A widget that repeatedly polls a source and fillData's its children. Apply to any element.
*/

(function( $ ){
	var dataPollerWidget = $.extend({}, $.ui.widget.prototype, CUI.htmlContainerClass, {
		options: {
			interval: 4000
		},

		fillData: function () {
			var self = this;
			if (!self.options._interval) {
				self.options._interval = setInterval(self._poll.bind(self), self.options.interval);
			}

			$.ui.containerWidget.prototype.fillData.apply(this, arguments);
		},

		_poll: function () {
			var self = this;
			delete self.options.data;
			self._dataFill();
		}

	});

	add_widget('dataPollerWidget', dataPollerWidget);
})(jQuery);
