/* jshint jquery: true, browser: true */
/* global classCUI, debugLog, isAllowed */

var register_template, execute_template, build_standard_entity_options;

(function () {
	var entity_templates = {};

	/**
     * @fn register_template(name, definition_function)
     * @brief                      Register a template definition function.
     * @param name                 The name of the template
     * @param definition_function  The function that defines the template
     */

	register_template = function (name, definition_function) {
		entity_templates[name] = definition_function;
	};

	execute_template = function (name, field_def) {
		return entity_templates[name](field_def);
	};

	/**
     * @fn build_standard_entity_options(element, widget_name, source, defaults, widget_reserved_words)
     * @brief                        Transform an "entity_template" type object into an "entity" type object.
     * @param element                The type of element to create
     * @param widget_name            The name of the widget that uses the options given
     * @param source                 The entity_template style definition of the element
     * @param defaults               Default options for the element
     * @param widget_reserved_words  Keys that will be moved to widget_options, even if they are in the "common_attributes" array below
     */

	build_standard_entity_options = function (element, widget_name, source, defaults, widget_reserved_words) {
		var dest, a, alen, key;
		dest = {
			"entity" : element
		};

		// Keys from this list, if they are found in the source, are converted to attributes on the HTML element.
		// This list can be overridden by the widget_reserved_words array param-- words in that array are removed from the "common_attributes" list and
		// those keys are processed as widget options.
		var common_attributes = [
			'action',
			'checked',
			'class',
			'disabled',
			'id',
			'max',
			'method',
			'min',
			'name',
			'uri_id',
			'novalidate',
			'placeholder',
			'required',
			'step',
			'type',
			'value'
		];

		// Keys from this list are reserved for internal use. This list cannot be overridden
		var reserved_items = [
			'select_options',
			'text',
			'html',
			'widgets',
			'widget_options',
			'entity_template',
			'entity_templates',
			'attributes',
			'sub_elements',
			'conditional_display'
		];

		// Remove common_attributes entries referenced in "widget_reserved_words" param
		widget_reserved_words = widget_reserved_words || [];
		for (var i in widget_reserved_words) {
			var pidx = $.inArray(widget_reserved_words[i], common_attributes);
			if (pidx > -1) {
				common_attributes.splice(pidx, 1);
			}
		}

		var have_attributes = false;
		for (a = 0, alen = common_attributes.length; a < alen; ++a) {
			if (common_attributes[a] in source) {
				have_attributes = true;
				break;
			}
		}

		if (have_attributes || source.attributes) {
			if (!dest.attributes) {
				dest.attributes = {};
			}

			// Flatten the most commonly used items
			for (a = 0, alen = common_attributes.length; a < alen; ++a) {
				var attr = common_attributes[a];
				if (source[attr]) { dest.attributes[attr] = source[attr]; }
			}

			// Special handling for certain attributes
			if (source.disabled) { dest.attributes.disabled = 'disabled'; }
			if (source.required) { dest.attributes.required = 'required'; }
			if (source.checked) { dest.attributes.checked = 'checked'; }
			if (source.novalidate) { dest.attributes.novalidate = 'novalidate'; }

			// Anything that isn't normally recognized as an attribute must be wrapped in an 'attributes' object
			if (source.attributes) {
				for (key in source.attributes) {
					dest.attributes[key] = source.attributes[key];
				}
			}
		}


		if (source.select_options) {
			dest.select_options = source.select_options;
		}

		if (source.text) {
			dest.text = source.text;
		}

		if (source.html) {
			dest.html = source.html;
		}

		if (source.widget_options) {
			dest.widget_options = source.widget_options;
		}

		if (source.widgets) {
			if ($.isArray(source.widgets)) {
				dest.widgets = source.widgets;
			} else {
				dest.widgets = [ source.widgets ];
			}
		}

		if (defaults) {
			$.extend(true, dest, defaults);
		}

		// If a widget was requested, build the standard widget options
		if (widget_name) {
			dest.widgets = dest.widgets || [];
			dest.widget_options = dest.widget_options || {};
			dest.widget_options[widget_name] = dest.widget_options[widget_name] || {};

			dest.widgets.push(widget_name);

			for (key in source) {
				// Only copy items to widget options that are not attributes or reserved words
				if ($.inArray(key, common_attributes) == -1 && $.inArray(key, reserved_items) == -1) {
					dest.widget_options[widget_name][key] = source[key];
				}
			}
		}




		return dest;
	};

	classCUI.prototype.htmlEntityClass = new function () {
		this._htmlEntityDestroy = function() { };

		/**
		 * @fn getHTML(field_def, data)
		 * @brief            Parse an entity/element or entity_template definition, and return a DOM node with the (unwidgetized) HTML elements
		 * @param data       Values data for the widget(s)
		 */
		this.getHTML = function(field_def, data) {
			var self = this, field, fd_entity, tmpl, new_fd = {}, key, entity_fd, e, elen, entity_template, w, wlen, filtered_data, data_js, attributes, attr, options, i, value, text, opt, selected, option, placeholder, novalidate, required, widget_data, a, attr_len, $html, h_idx, $field;

			fd_entity = field_def.entity;

			if ('conditional_display' in field_def && !field_def.conditional_display) {
				return document.createDocumentFragment();
			}

			if (field_def.entity_template && field_def.entity_template in entity_templates) {

				// We ignore field_def.entity_templates if it is set
				if (field_def.entity_templates) {
					delete field_def.entity_templates;
				}

				tmpl = field_def.entity_template;

				field_def = execute_template(tmpl, field_def);

				if (self.options && self.options.debug) {
					debugLog('cui.htmlEntityClass.js: Got field definition for "' + tmpl + '":', field_def);
				}
			} else if (field_def.entity_templates) {
				// We make a new field definition which will represent the merged result of all templates specified.
				// We start by copying in any parameters that are directly specified, excluding the 'entity_templates'
				// key and any key which represents a template in entity_templates. These will not get overridden by
				// settings from the templates themselves.
				for (key in entity_fd) {
					if (key != 'entity_templates' && $.inArray(key, field_def.entity_templates) == -1) {
						new_fd[key] = entity_fd[key];
					}
				}
				// Then, loop through the list of templates and call their template function
				for (e = 0, elen = field_def.entity_templates.length; e < elen; ++e) {
					entity_template = field_def.entity_templates[e];
					if (entity_template in entity_templates && entity_template in field_def) {
						entity_fd = entity_templates[entity_template](field_def[entity_template]);
						if (self.options && self.options.debug) {
							debugLog('cui.htmlEntityClass.js: Got field definition for "' + entity_template + '":', entity_fd);
						}
						// We use the entity of the first specified template, unless one was specified directly
						if (!new_fd.entity) {
							new_fd.entity = entity_fd.entity;
						}
						// Then we merge in the attributes, again using the first one set by a template
						if (entity_fd.attributes) {
							if (!new_fd.attributes) {
								new_fd.attributes = {};
							}
							for (key in entity_fd.attributes) {
								if (!new_fd.attributes[key]) {
									new_fd.attributes[key] = entity_fd.attributes[key];
								}
							}
						}
						// We only add widgets if they don't already exist from previously parsed templates
						if (entity_fd.widgets) {
							if (!new_fd.widgets) {
								new_fd.widgets = [];
							}
							for (w = 0, wlen = entity_fd.widgets.length; w < wlen; ++w) {
								if ($.inArray(entity_fd.widgets[w], new_fd.widgets) == -1) {
									new_fd.widgets.push(entity_fd.widgets[w]);
								}
							}
						}
						// It is expected that widget options are always built by the templates fully wrapped in
						// a container object named with the widget name.
						if (entity_fd.widget_options) {
							if (!new_fd.widget_options) {
								new_fd.widget_options = {};
							}
							for (key in entity_fd.widget_options) {
								new_fd.widget_options[key] = entity_fd.widget_options[key];
							}
						}
						// Finally, copy in any parameters that were set by this template which have not already been
						// been set initially or by past templates.
						for (key in entity_fd) {
							if (key != 'entity' && key != 'attributes' && key != 'widgets' && key != 'widget_options') {
								if (!new_fd[key]) {
									new_fd[key] = entity_fd[key];
								}
							}
						}
					}
				}
				// After building the merged field_def object, make sure we use it
				field_def = new_fd;
				if (self.options && self.options.debug) {
					debugLog('cui.htmlEntityClass.js: Merged field definitions: ', field_def);
				}
			}

			// Override the entity template's entity with the given entity, if one was given...
			if (fd_entity !== undefined) {
				field_def.entity = fd_entity;
			}

			if (data && field_def.widget_data) {
				filtered_data = {};
				for (key in field_def.widget_data) {
					filtered_data[key] = data[field_def.widget_data[key]];
				}
				data = filtered_data;
			}

			data = data || {};

			data_js = self._getDataJs(field_def, data);

			// Some attributes/properties cannot be changed after we make the element (like 'type'), so build an attributes
			// string that we can use while making the element, instead.
			attributes = [];
			if (data_js) {
				// debugLog('Data-js: ', data_js);
				attr = document.createAttribute('data-js');
				attr.value = data_js;
				attributes.push(attr);
			}
			if (field_def.attributes && typeof field_def.attributes == 'object') {
				for (key in field_def.attributes) {
					attr = document.createAttribute(key);
					attr.value = field_def.attributes[key];
					attributes.push(attr);
				}
			}

			switch (field_def.entity) {
				case 'input':
					field = document.createElement('input');

					if (!field_def.attributes || !field_def.attributes.type) {
						debugLog('cui.htmlEntityClass.js: You must specify a type attribute for an input entity. Default to "text". -- ', field_def);
						field_def.attributes = field_def.attributes || {};
						field_def.attributes.type = 'text';
					}

					switch(field_def.attributes.type) {
						case 'range':
						case 'number':
							if (field_def.min) {
								attr = document.createAttribute('min');
								attr.value = field_def.min;
								attributes.push(attr);
							}
							if (field_def.max) {
								attr = document.createAttribute('max');
								attr.value = field_def.max;
								attributes.push(attr);
							}
							if (field_def.step) {
								attr = document.createAttribute('step');
								attr.value = field_def.step;
								attributes.push(attr);
							}
							break;
						default:
							// In IE, setting this property later will fail, so let's do it here
							field.type = field_def.attributes.type;
							delete field_def.attributes.type;
							break;
					}
					break;
				case 'select':
					field = document.createElement('select');
					if (field_def.select_options && field_def.select_options.length) {
						options = field_def.select_options;
					}
					if (options) {
						for (i in options) {
							opt = options[i];
							if (!options.hasOwnProperty(i)) { continue; }

							option = document.createElement('option');
							value = document.createAttribute('value');
							value.value = (opt.value !== undefined) ? opt.value : '';
							option.setAttributeNode(value);

							text = document.createTextNode((opt.text !== undefined) ? opt.text : '');

							option.appendChild(text);
							if (opt.selected) {
								selected = document.createAttribute('selected');
								selected.value = opt.selected;
								option.setAttributeNode(selected);
							}

							field.appendChild(option);
						}
					}
					break;
				case 'textarea':
					field = document.createElement('textarea');
					break;
				default:
					field = document.createElement(field_def.entity);
					break;
			}

			if (field_def.attributes && field_def.attributes.type) {
				field.setAttribute('type', field_def.attributes.type);
				delete field_def.attributes.type;
			}

			if (field_def.placeholder) {
				placeholder = document.createAttribute('placeholder');
				placeholder.value = field_def.placeholder;
				attributes.push(placeholder);
			}
			if (field_def.required) {
				required = document.createAttribute('required');
				required.value = 'required';
				attributes.push(required);
			}
			if (field_def.novalidate) {
				novalidate = document.createAttribute('novalidate');
				novalidate.value = 'novalidate';
				attributes.push(novalidate);
			}

			for (a = 0, attr_len = attributes.length; a < attr_len; ++a) {
				field.setAttributeNode(attributes[a]);
			}

			if (field_def.text) {
				text = document.createTextNode(field_def.text);
				field.appendChild(text);
			}

			if (field_def.html) {
				// Use jQuery to normalize HTML generation
				$html = $('<div />').html(field_def.html).contents();
				for (h_idx = 0; h_idx < $html.length; h_idx++) {
					field.appendChild($html[h_idx]);
				}
			}

			if (data) {
				widget_data = document.createAttribute('widget-data');
				widget_data.value = JSON.stringify(data);
				field.setAttributeNode(widget_data);
			}

			$field = $(field);

			if (field_def.attributes && field_def.attributes['class']) {
				$field.addClass(field_def.attributes['class']);
			}

			if (field_def.widgets) {
				if (Object.prototype.toString.call(field_def.widgets) !== '[object Array]') {
					field_def.widgets = [ field_def.widgets ];
				}

				$field.addClass('widgetType');
				for (w in field_def.widgets) {
					$field.addClass(field_def.widgets[w]);
				}
			}

			if (field_def.sub_elements) {
				$field.append(self.getEntitiesHTML(field_def.sub_elements, data).contents());
			}

			return field;
		};

		/**
		 * @fn _getDataJs(field_def, data)
		 * @brief            Parses the given options data into a "data-js" string suitable to be attached to an element.
		 * @param field_def  The definition object for the element the data will be attached to
		 * @param data       Data to parse
		 * @param format     "text" or "object" (default "text") -- Determine whether the output will be given as a JSON string or as a JS object.
		 */

		this._getDataJs = function(field_def, data, format) {
			var data_js = {};

			if (field_def.widgets) {
				// Add existing widget_options to the data_js set
				$.extend(data_js, field_def.widget_options || {});

				// Push the proper data to the proper widgets, if multiple widgets are used
				if (data && field_def.widget_data_during_init) {
					var by_name = false, checked = false, widget_data_key, i;

					for (widget_data_key in data_js) {
						if (!by_name && !checked) {
							checked = true;
							for (i in field_def.widgets) {
								if (field_def.widgets[i] === widget_data_key) {
									by_name = true;
									break;
								}
							}
						}

						if (by_name) {
							$.extend(data_js[widget_data_key], { 'data' : data });
						}
					}
					if (!by_name) {
						$.extend(data_js, { 'data' : data });
					}
				}
			}

			if (format !== 'object') {
				data_js = JSON.stringify(data_js);
			}

			return data_js;
		};


		/**
	 * @fn getEntitiesHTML(elements, data)
	 * @brief           Parse an "elements" array and return a non-widgetized HTML representation. Returns a DIV containing the resulting element(s).
	 * @param elements  Array of elements.
	 * @param data      Values data for the widgets' use (passed to "getHTML" verbatim)
	 */

		this.getEntitiesHTML = function (elements, data) {
			var self = this, return_field, e_idx, e_len, field_def;

			return_field = self._getEntitiesContainer(elements, data);

			e_len = elements.length;
			for (e_idx = 0; e_idx < e_len; ++e_idx) {
				field_def = elements[e_idx];

				if (typeof field_def !== 'object' || field_def === null) {
					debugLog("cui.htmlEntityClass.js: Element in 'entities' is not an object!", field_def);
				}

				if (field_def && isAllowed(field_def)) {
					return_field.appendChild(self.getHTML(field_def, data));
				}
			}

			return $(return_field);
		};

		this._getEntitiesContainer = function () {
			return document.createElement('div');
		};
	};
})();
