/*

  This creates both router_action_detail and fmfm_router_action_detail screens, by way of the simple "for" loop (first fmfm is 0, then it's 1)

*/

(function () {

    var fmfm, idx;

    for (fmfm = 0; fmfm <= 1; fmfm++) {

	// Only show "scope" if the router is an ACR router
	var basic_elements = [{
	    entity_template: 'input_item',
	    title: 'Name:',
	    type: 'text',
	    input_name: 'bbx_callflow_name',
	    input_options: { attributes: { maxlength: '255' }}
	}];

	if (!fmfm) {
	    basic_elements.push({
		entity_template: 'data_item',
		title: 'Scope:',
		elements: [
		    {
			entity_template: 'aopb_state',
			entity: 'select',
			attributes: { name: 'bbx_callflow_public' },
			select_options: [
			    { text: 'Local (This Router)', value: 0 },
			    { text: 'Global', value: 1 }
			],

			states: [{
			    conditions: [
				{ a: '@bbx_callflow_public', op: '==', b: '_TRUEISH_' }
			    ],
			    actions: { enabled: false },
			    else_actions: { enabled: true }
			}]
		    },
		    {
			entity_template: 'aopb_state',
			'class':'warning messageStyle',
			text: 'If scope is set to Local, any rules using this action set on any other Advanced Call Routers will be deleted.',
			states: [{
			    conditions: [
				{              a: '@bbx_callflow_public', op: '==', b: '_TRUEISH_' },
				{ join: 'and', a: '$bbx_callflow_public', op: '!=', b: '_TRUEISH_' }
			    ],
			    actions: { visible: true },
			    else_actions: { visible: false }
			}]
		    }
		]
	    });
	}

	// Set up option/translate lists for actions...

	var actions = [
	    { value: 'try_phone_numbers', text: 'Call Phone Numbers', fmfm: true, acr: true },
	    { value: 'playback', text: 'Play a Sound File', fmfm: false, acr: true },
	    { value: 'ivr_dialbyname', text: 'Go to the Dial-by-name Directory', fmfm: false, acr: true },
	    { value: 'ivr_disa', text: 'Go to the Direct Inward System Access (DISA)', fmfm: false, acr: true },
	    { value: 'execute_extension', text: 'Try Calling an Extension', fmfm: false, acr: true },
	    { value: 'try_calling_registered_phones', text: 'Try Calling My Phones', fmfm: true, acr: false },
	    { value: 'campon', text: 'Keep Trying to Reach Me', fmfm: true, acr: false }
	];

	var action_select_options = [];
	var action_translate = {};

	for (idx = 0; idx < actions.length; idx++) {
	    if (actions[idx][fmfm ? 'fmfm' : 'acr']) {
		action_select_options.push({ text: actions[idx].text, value: actions[idx].value });
		action_translate[ actions[idx].value ] = actions[idx].text;
	    }
	}

	// Set up option list for finals...

	var finals = [
	    { text: 'Call My Phones', value: 'call_registered_phones',                      fmfm: true,  acr: false },
	    { text: 'Disconnect the Call', value: 'hangup',                                 fmfm: true,  acr: true },
	    { text: 'Transfer to an Extension or Number', value: 'transfer',                fmfm: true,  acr: true },
	    { text: 'Transfer to a Person'+entity.rsquo+'s Voicemail', value: 'voicemail', fmfm: true,  acr: true },
	    { text: 'Wait for a Fax' + (fmfm?'':', Then Email'), value: 'fax',                             fmfm: true,  acr: true },
	    { text: 'Transfer to Voicemail Login Prompt', value: 'voicemail_login',        fmfm: false, acr: true },
	    { text: 'Page a Group', value: 'page_group',                                    fmfm: false, acr: true },
	    { text: 'Perform an Echo Test', value: 'echo',                                  fmfm: false, acr: true },
	    { text: 'Play Milliwatt Tone', value: 'milliwatt',                              fmfm: false, acr: true },
	    { text: 'Send the Call to a Provider Gateway or Port', value: 'route',          fmfm: false, acr: true }
	], final_select_options = [];

	for (idx = 0; idx < finals.length; idx++) {
	    if (finals[idx][fmfm ? 'fmfm' : 'acr']) {
		final_select_options.push({ text: finals[idx].text, value: finals[idx].value });
	    }
	}

	// Set up page module for options...

	var options_pm = [];

	// Silent hold, Record call, Custom CID, and Web App URL (ACR only)
	if (!fmfm) {
	    options_pm = options_pm.concat([
		{
		    entity_template: 'input_item',
		    type: 'checkbox',
		    input_name: 'bbx_callflow_silent_hold',
		    title: 'Enable Silent Hold'
		},
		{
		    entity_template: 'input_item',
		    type: 'checkbox',
		    input_name: 'bbx_callflow_record_session',
		    title: 'Record the call:'
		},
		{
		    entity_template: 'input_item',
		    type: 'checkbox',
		    input_name: 'bbx_callflow_caller_id',
		    title: 'Set Custom Caller ID:'
		},
		{
		    entity_template: 'data_item',
		    title: '',
		    info: 'Caller ID Name',
		    elements: [
			{
			    entity_template: 'aopb_state',
			    entity: 'input',
			    attributes: { type: 'text' },
			    name: 'bbx_callflow_caller_id_name',
			    closest: 'form',
			    states: [
				{
				    conditions: { a: '$bbx_callflow_caller_id', op: '==', b: '_TRUEISH_' },
				    actions: { enabled: true },
				    else_actions: { enabled: false }
				},
				{
				    conditions: [
					{ a: '$$', op: '!=', b: '_TRUE_' },
					{ join: 'and', a: '$bbx_callflow_caller_id_number', op: '!=', b: '_TRUE_' },
					{ join: 'and', a: '$bbx_callflow_caller_id', op: '==', b: '_TRUEISH_' }
				    ],
				    actions: { invalid: 'A Caller ID Name, Number, or both must be specified' },
				    else_actions: { invalid: false }
				}
			    ]
			},
			{ entity: 'div', text: 'Caller ID Name', attributes: { 'class': 'info' } }
		    ]
		},
		{
		    entity_template: 'data_item',
		    title: '',
		    elements: [
			{
			    entity_template: 'aopb_state',
			    entity: 'input',
			    attributes: { type: 'text' },
			    name: 'bbx_callflow_caller_id_number',
			    closest: 'form',
			    states: [
				{
				    conditions: { a: '$bbx_callflow_caller_id', op: '==', b: '_TRUEISH_' },
				    actions: { enabled: true },
				    else_actions: { enabled: false }
				},
				{
				    conditions: [
					{ a: '$$', op: '==', b: '^(\\+|~|~\\+)?\\d{1,15}$', b_is: 'regexp' },
					{ join: 'or', a: '$$', op: '!=', b: '_TRUE_' }
				    ],
				    actions: { invalid: false, _invalid_key: 'number' },
				    else_actions: { invalid: 'You must specify a valid phone number.', _invalid_key: 'number' },
				    last_if_false: true
				},
				{
				    conditions: [
					{ a: '$bbx_callflow_caller_id', op: '==', b: '_TRUEISH_' },
					{ join: 'and', a: '$$', op: '!=', b: '_TRUE_' },
					{ join: 'and', a: '$bbx_callflow_caller_id_name', op: '!=', b: '_TRUE_' }
				    ],
				    actions: { invalid: 'A Caller ID Name, Number, or both must be specified', _invalid_key: 'missing' },
				    else_actions: { invalid: false, _invalid_key: 'missing' }
				}
			    ]
			},
			{ entity: 'div', text: 'Caller ID Number (digits only)', attributes: { 'class': 'info' } }
		    ]
		},


		{
		    entity_template: 'input_item',
		    type: 'checkbox',
		    input_name: 'bbx_callflow_popup',
		    title: 'Launch Web Application Popup:'
		},
		{
		    entity_template: 'data_item',
		    title: '',
		    elements: [
			{
			    entity_template: 'aopb_state',
			    entity: 'input',
			    attributes: { type: 'text', size: '60' },
			    name: 'bbx_callflow_popup_url',
			    closest: 'form',
			    states: [
				{
				    conditions: { a: '$bbx_callflow_popup', op: '==', b: '_TRUEISH_' },
				    actions: { enabled: true },
				    else_actions: { enabled: false }
				},
				{
				    conditions: [
					{              a: '$bbx_callflow_popup', op: '==', b: '_TRUEISH_' },
					{ join: 'and', a: '$$', op: '!=', b: '^([a-zA-Z0-9_]+ |)[-+\\.A-Za-z0-9]+://[^ ]+$', b_is: 'regexp' }
				    ],
				    actions: { invalid: 'You must specify a valid URL beginning with '+entity.ldquo+'http'+entity.rdquo+':// (or another protocol) and no spaces.' },
				    else_actions: { invalid: false }
				}
			    ]
			},
			{ entity: 'div', text: 'Web Application Popup URL', attributes: { 'class': 'info' } }
		    ]
		}
	    ]); // END concat
	} // END if !fmfm

	// Custom voicemail greeting and Call screening (FMFM only)
	if (fmfm) {
	    options_pm = options_pm.concat([
		{
		    entity_template: 'input_item',
		    type: 'checkbox',
		    input_name: 'bbx_callflow_custom_voicemail',
		    title: 'Use Custom Voicemail Greeting:'
		},
		{
		    entity_template: 'input_item',
		    type: 'select',
		    input_name: 'bbx_callflow_custom_voicemail_id',
		    select_options: [
			{ text: 'Greeting 1', value: '1' },
			{ text: 'Greeting 2', value: '2' },
			{ text: 'Greeting 3', value: '3' },
			{ text: 'Greeting 4', value: '4' },
			{ text: 'Greeting 5', value: '5' },
			{ text: 'Greeting 6', value: '6' },
			{ text: 'Greeting 7', value: '7' },
			{ text: 'Greeting 8', value: '8' },
			{ text: 'Greeting 9', value: '9' }
		    ],
		    title: '',
		    info: 'Custom Greeting',
		    input_options: {
			widgets: ['aopbStateWidget'],
			widget_options: { aopbStateWidget: {
			    states: [{
				conditions: { a: '$bbx_callflow_custom_voicemail', op: '==', b: '_TRUEISH_' },
				actions: { enabled: true },
				else_actions: { enabled: false }
			    }]
			}}
		    }
		},
		{
		    entity_template: 'input_item',
		    type: 'checkbox',
		    input_name: 'bbx_callflow_call_screening',
		    title: 'Call Screening:'
		}
	    ]); // END concat
	} // END if fmfm


	// Anon call reject, fax-email, and t.38 reinvite (on both FMFM and ACR)
	options_pm = options_pm.concat([
	    {
		entity_template: 'input_item',
		type: 'checkbox',
		input_name: 'bbx_callflow_anonymous_call_blocking',
		title: 'Reject anonymous calls:'
	    },
	    {
		entity_template: 'input_item',
		type: 'checkbox',
		input_name: 'bbx_callflow_detect_fax',
		title: 'Detect' + (fmfm?'':' and Email') + ' Faxes:'
	    },
	    {
		entity_template: 'data_item',
		title: '',
		elements: [
			{
			    entity_template: 'aopb_state',
			    entity: 'input',
			    attributes: { type: 'text' },
			    name: 'bbx_callflow_fax_email',
			    closest: 'form',
			    states: [
				{
				    conditions: { a: '$bbx_callflow_detect_fax', op: '==', b: '_TRUEISH_' },
				    actions: { enabled: true },
				    else_actions: { enabled: false }
				},
				{
				    conditions: [
					{              a: '$bbx_callflow_detect_fax', op: '==', b: '_TRUEISH_' },
					{ join: 'and', a: '$$', op: '!=', b: '^(.+@.+)' + (fmfm ? '?' : '') + '$', b_is: 'regexp' }
				    ],
				    actions: { invalid: 'You must specify a valid email address.' },
				    else_actions: { invalid: false }
				}
			    ]
			},
			{ entity: 'div', text: 'Email address for received faxes', attributes: { 'class': 'info' } },
			{
			    entity_template: ['aopb_state'],
			    rest: '/gui/mail/valid',
			    'class': 'warning messageStyle',
			    closest: 'form',
			    states: [{
				conditions: [
				    {              a: '@valid', op: '!=', b: '_TRUEISH_' },
				    { join: 'and', a: '$bbx_callflow_detect_fax', op: '==', b: '_TRUEISH_' }
				],
				actions: { text: 'Mail settings are improperly configured, and faxes will not be delivered' + (fmfm ? ' to email. Contact your administrator before using this feature.' : '. Check the '+entity.ldquo+'Mail'+entity.rdquo+' screen in the '+entity.ldquo+'Configuration'+entity.rdquo+' section to verify settings are correct.'), visible: true },
				else_actions: { text: '', visible: false }
			    }]
			}
		]
	    },
	    {
		entity_template: 'input_item',
		type: 'checkbox',
		input_name: 'bbx_callflow_t38_reinvite',
		title: 'Use T.38 Reinvite for Fax:',
		info: 'This feature requires service provider support.  Please consult your provider for more information.'
	    }
	]); // END concat


	var screen_def = {
	    rest: '/gui/' + (fmfm?'user':'extension') + '/router/callflow',
	    dataEvents: {
		bind: {
		    'callflow': 'bbx_callflow_id'
		}
	    },
	    elements: [
		{
		    entity: 'h2',
		    widgets: ['containerWidget'],
		    attributes: { 'class': 'page_title' },
		    widget_options: {
			containerWidget: {
			    elements: [{
				entity: 'span',
				widgets: ['formatWidget'],
				widget_options: {
				    formatWidget: {
					dataEvents: {
					    bind: {
						'callflow': 'bbx_callflow_id'
					    }
					},
					format: 'Action Set: $1',
					fields: [
					    {
						field: '@bbx_callflow_name',
						formatter: 'value'
					    }
					]
				    }
				}
			    }]
			}
		    }
		},
		{
		    entity_template: 'page_buttons',
		    buttons: [ { type: 'cancel' }, { type: 'save' } ]
		},
		{ entity_template: 'page_content', elements: [
		    {
			entity_template: 'page_module',
			form_action: '/gui/' + (fmfm?'user':'extension') + '/router/callflow',
			form_data_js: {
			    include_keys: ['bbx_callflow_id'],
			    dataEvents: {
				trigger: 'callflow'
			    },
			    force_reset: true,
			    confirm_if: {
				bbx_callflow_public: {
				    title: 'Confirm Scope Change',
				    text:  'Once you have changed the Scope of an action set to Global, it can no longer be changed to Local. Do you wish to continue?'
				}
			    }
			},
			legend: 'Basic Information',
			buttons: [ 'help' ],
			help_id: (fmfm?'fmfm':'routers') + '_action_basic_information',
			elements: basic_elements
		    }, // END page module "Basic Information"
		    {
			entity_template: 'page_module',
			legend: 'Action List',
			buttons: [ 'help' ],
			help_id: (fmfm?'fmfm_':'') + 'routers_action_list',
			elements: [
			    {
				entity_template: 'data_table',
				accept_data_from_parent: true,
				data_source: 'actions',
				click_action: 'edit',
				paginate: true,
				length_change: true,
				dataEvents: {
				    trigger: 'callflow'
				},

				'class': 'top-align-edit',
				allow_scroll: false,
				refresh_rest: '/gui/' + (fmfm?'user':'extension') + '/router/callflow/actions',
				refresh_rest_container: false,
				refresh_data_source: 'actions',

				primary_keys: ['bbx_callflow_action_id'],

				submit_empty_array_as: '',

				sortable: {
				    field: 'bbx_callflow_action_position',
				    also_submit: ['bbx_callflow_action_id']
				},

				table_actions: {
				    add_row: {
					text: 'Create an Action',
					location: 'bottom'
				    },
				    delete_rows: {
					text: 'Delete Selected Actions',
					location: 'bottom',
					confirm: { title: 'Delete Selected Actions', text: 'Are you sure you want to delete the selected actions?' }
				    },
				    select_row: true,
				    select_all_rows: true
				},

				row_actions: {
				    edit_cancel: { text: 'Cancel' },
				    add_cancel: { text: 'Cancel' }
				},

				add_edit_action: {
				    rest: '/gui/' + (fmfm?'user':'extension') + '/router/callflow/action',
				    rest_container: 'action',
				    filter_rest_params: ['bbx_extension_id','bbx_router_id'],
				    filter_params_add: ['bbx_callflow_action_id']
				},

				delete_action: {
				    rest: '/gui/' + (fmfm?'user':'extension') + '/router/callflow/action'
				},

				extra_row_data: [
				    'bbx_callflow_action_id',
				    'bbx_callflow_action_position',
				    'bbx_callflow_action_type',
				    'bbx_callflow_action_confirm',
				    'bbx_callflow_action_sound_id',
				    'bbx_callflow_action_timeout',
				    'bbx_callflow_action_extension_id',
				    'bbx_sound_name',
				    'bbx_extension_value',
				    'number_list',
				    'bbx_callflow_action_repeats',
				    'bbx_callflow_action_moh_id',
				    'bbx_moh_name'
				],

				columns: [
				    {
					header: 'Action',
					column: 'bbx_callflow_action_type',
					translate: action_translate,
					edit_elements: [{
					    entity: 'select',
					    attributes: { name: 'bbx_callflow_action_type' },
					    select_options: action_select_options
					}]
				    },
				    {
					header: 'Destination/Options',
					view_element: {
					    entity_template: 'aopb_container',
					    states: [
						{
						    conditions: { a: '@bbx_callflow_action_type', op: '==', b: 'playback' },
						    elements: [{ entity_template: 'widget_span', attributes: { 'value-from': 'bbx_sound_name' } }],
						    last_if_true: true
						},
						{
						    conditions: { a: '@bbx_callflow_action_type', op: '==', b: 'execute_extension' },
						    elements: [{
							entity_template: 'format',
							format: { field: '@bbx_extension_value', formatter: 'phone_number' }
						    }],
						    last_if_true: true
						},
						{
						    conditions: { a: '@bbx_callflow_action_type', op: '==', b: 'try_phone_numbers' },
						    elements: [{
							entity: 'div',
							widgets: ['callflowNumberListDisplayWidget']
						    }],
						    last_if_true: true
						},
						{
						    conditions: { a: '@bbx_callflow_action_type', op: '==', b: 'campon' },
						    elements: [{
							entity_template: 'format',
							closest: 'tr',
							format: 'Try to reach me $1 times while playing the ' +entity.ldquo+ '$2' +entity.rdquo+ ' hold music.',
							fields: [
							    { field: '@bbx_callflow_action_repeats' },
							    { field: '@bbx_moh_name' }
							]
						    }],
						    last_if_true: true
						},
						{
						    conditions: { a: '1', op: '==', b: '_TRUE_' },
						    elements: [{ entity: 'div', text: '(No configuration is required)' }]
						}
					    ]
					},
					edit_element: {
					    entity_template: 'switch',
					    closest: 'tr',
					    selector: '$bbx_callflow_action_type',
					    data_on_interactive_change: {
						bbx_callflow_action_sound_id: '',
						bbx_callflow_action_confirm: 0,
						bbx_callflow_action_extension_id: '',
						bbx_callflow_action_timeout: '30',
						number_list: ','
					    },
					    switch_elements: {
						noconf: { entity: 'div', text: '(No configuration is required)' },
						ivr_disa: 'noconf',
						ivr_dialbyname: 'noconf',
						try_calling_registered_phones: 'noconf',
						campon: [
						    {
							entity_template: 'input_item',
							type: 'text',
							title: 'Try to reach me:',
							input_name: 'bbx_callflow_action_repeats',
							input_units: 'times',
							input_options: { attributes: { size: '3' } },
							validate: { conditions: '_NUMERIC_', message: 'Please enter a valid numeric value' }
						    },
						    {
							entity_template: 'input_item',
							type: 'select',
							title: 'Play hold music:',
							input_name: 'bbx_callflow_action_moh_id',
							input_options: {
							    widgets: ['selectPopulateWidget'],
							    widget_options: {
								selectPopulateWidget: {
								    rest: '/gui/moh/class',
								    text_field: 'bbx_moh_name',
								    value_field: 'bbx_moh_id'
								}
							    }
							}
						    }
						],
						try_phone_numbers: [
						    {
								entity_templates: ['phone_number_tokenizer', 'aopb_state'],
								aopb_state: {
									closest: 'td',
									debugme: true,
									states: [{
										conditions: { a: '$$', op: '==', b: '_ARRAY_HAS_ELEMENTS_' },
										actions: { invalid: false },
										else_actions: { invalid: 'You must enter one or more numbers' }
									}]
								},
								phone_number_tokenizer: {
									allow_range: false,
									allow_plus: true,
									allow_sip: true,
									allow_star_pound: true,
									name: 'number_list',
									output_type: 'array',
									accept_data_from_parent: false,
									clear_on_success: true
								}
						    },
						    {
							entity: 'div',
							attributes: { 'class':'info' },
							text: 'Enter numbers to call, separated by commas, spaces, or new-lines. All the numbers will be called simultaneously.'
						    },
						    {
							entity_template: 'input_item',
							title: 'Ring Time:',
							type: 'text',
							size: 3,
							input_options: { attributes: { value: '30' } },
							input_name: 'bbx_callflow_action_timeout',
							validate: { conditions: '_NUMERIC_GREATER_THAN_ZERO_', message: 'Please enter a number greater than zero.' },
							info: 'seconds'
						    },
						    {
							entity_template: 'input_item',
							title: 'Confirmation Prompt:',
							type: 'checkbox',
							input_name: 'bbx_callflow_action_confirm',
							info: 'If checked, callees must press '+entity.ldquo+'1'+entity.rdquo+' to accept the call.'
						    },
						    {
							entity_template: 'data_item',
							title: 'Prompt Sound:',
							elements: [
							    {
								entity_templates: ['aopb_state','sound_picker'],
								aopb_state: {
								    closest: 'td',
								    states: [{
									conditions: { a: '$bbx_callflow_action_confirm', op: '==', b: '_TRUEISH_' },
									actions: { enabled: true, _cascade: true },
									else_actions: { enabled: false, _cascade: true }
								    }]
								},
								sound_picker: {
								    name: 'bbx_callflow_action_sound_id',
								    allow_none: false
								}
							    }
							]
						    }
						],
						playback: {
						    entity_template: 'sound_picker',
						    name: 'bbx_callflow_action_sound_id',
						    allow_none: false
						},
						execute_extension: [
						    {
							entity_template: 'data_item',
							title: 'Extension:',
							elements: [{
							    entity_template: 'extension_picker_select',
							    name: 'bbx_callflow_action_extension_id',
							    value_key: 'bbx_extension_id',
							    allow_nothing: false
							}]
						    },
						    {
							entity_template: 'input_item',
							title: 'Ring Time:',
							type: 'text',
							size: 3,
							input_options: { attributes: { value: '30' } },
							input_name: 'bbx_callflow_action_timeout',
							validate: { conditions: '_NUMERIC_GREATER_THAN_ZERO_', message: 'Please enter a number greater than zero.' },
							info: 'seconds'
						    },
						    {
							entity_template: 'input_item',
							title: 'Confirmation Prompt:',
							type: 'checkbox',
							input_name: 'bbx_callflow_action_confirm',
							info: 'If checked, callees must press '+entity.ldquo+'1'+entity.rdquo+' to accept the call.'
						    },
						    {
							entity_template: 'data_item',
							title: 'Prompt Sound:',
							elements: [
							    {
								entity_templates: ['aopb_state','sound_picker'],
								aopb_state: {
								    closest: 'td',
								    states: [{
									conditions: { a: '$bbx_callflow_action_confirm', op: '==', b: '_TRUEISH_' },
									actions: { enabled: true, _cascade: true },
									else_actions: { enabled: false, _cascade: true }
								    }]
								},
								sound_picker: {
								    name: 'bbx_callflow_action_sound_id',
								    allow_none: false
								}
							    }
							]
						    }
						]
					    }
					}
				    }
				] // END columns
			    } // END DTW
	 		]
		    }, // END page module "Actions"
		    {
			entity_template: 'page_module',
			form_action: '/gui/' + (fmfm?'user':'extension') + '/router/callflow',
			form_data_js: {
			    include_keys: ['bbx_callflow_id'],
			    dataEvents: {
				trigger: 'callflow'
			    }
			},
			legend: 'Final Action',
			buttons: [ 'help' ],
			help_id:  (fmfm?'fmfm_':'') + 'routers_final_action',
			elements: [
			    {
				entity_template: 'input_item',
				title: 'If the call is not answered:',
				input_name: 'bbx_callflow_final_action',
				type: 'select',
				select_options: final_select_options
			    },
			    {
				entity_template: 'switch',
				closest: 'form',
				selector: '$bbx_callflow_final_action',
				data_on_interactive_change: { 'bbx_callflow_final_action_extension_id': '', 'bbx_callflow_final_action_extension_value': '' },
				switch_elements: {
				    no_conf: [{
					entity_template: 'data_item',
					elements: [{ entity: 'div', text: '(No configuration is required)' }]
				    }],
				    call_registered_phones: 'no_conf',
				    hangup: 'no_conf',
				    echo: 'no_conf',
				    milliwatt: 'no_conf',
				    voicemail_login: 'no_conf',
				    transfer: [{
					entity_template: 'data_item',
					title: 'Destination:',
					elements: [{
					    entity_template: 'extension_picker_suggest',
					    name: 'bbx_callflow_final_action_extension_value'
					}]
				    }],
				    voicemail: [{
					entity_template: 'data_item',
					title: 'Destination:',
					elements: [{
					    entity_template: 'extension_picker_select',
					    name: 'bbx_callflow_final_action_extension_id',
					    value_key: 'bbx_extension_id',
					    type: 'user',
					    primary: 1,
					    allow_nothing: false
					}]
				    }],
				    fax: [
					{
					    entity_template: 'data_item',
					    title: 'Send to Email Address:',
					    elements: [
						{
						    entity: 'input',
						    attributes: {
							name: 'bbx_callflow_final_action_email',
							type: 'text'
						    },
						    widgets: ['aopbStateWidget'],
						    widget_options: { aopbStateWidget: {
							states: [{
							    conditions: { a: '$$', op: '!=', b: '^(.+@.+)' + (fmfm ? '?' : '') + '$', b_is: 'regexp' },
							    actions: { invalid: 'You must specify a valid email address' + (fmfm?' or leave this field blank.':''), debug_log: 'NO!' },
							    else_actions: { invalid: false, debug_log: 'YES' }
							}]
						    }}
						}
					    ].concat(fmfm?[
						{
						    entity: 'div',
						    attributes: {
							'class': 'info'
						    },
						    text: 'This field is optional. If a valid e-mail address is provided, faxes will be delivered directly to that e-mail address instead of the Message Center.'
						}
					    ]:[]).concat([
						{
						    entity_template: ['aopb_state'],
						    rest: '/gui/mail/valid',
						    'class': 'warning messageStyle',
						    states: [{
							conditions: [
							    // Intention here is to show this msg ONLY if mail settings are invalid AND user has typed 
							    // into email field. It should not show if the user has left that field blank.
							    { a: '@valid', op: '==', b: '_TRUEISH_' },
							    { join: 'or', a: '$bbx_callflow_final_action_email', op: '==', b: '' }
							],
							actions: { text: '', visible: false },
							else_actions: { text: 'Mail settings are improperly configured, and faxes will not be delivered' + (fmfm?' to email':'') + '.' + (fmfm ? ' Contact your administrator before using this feature.' : 'Check the '+entity.ldquo+'Mail'+entity.rdquo+' screen in the '+entity.ldquo+'Configuration'+entity.rdquo+' section to verify settings are correct.'), visible: true }
						    }]
						}
					    ])
					}
				    ],
				    page_group: [
					{
					    entity_template: 'data_item',
					    title: 'Destination:',
					    elements: [
						{
						    entity_template: 'extension_picker_select',
						    name: 'bbx_callflow_final_action_extension_id',
						    type: 'group',
						    value_key: 'bbx_extension_id',
						    primary: 1,
						    allow_nothing: false
						}
					    ]
					},
					{
					    entity_template: 'input_item',
					    title: 'Paging Method:',
					    type: 'select',
					    input_name: 'bbx_callflow_final_action_type',
					    select_options: [
						{ text: 'Live', value: 'realtime' },
						{ text: 'Record and Playback', value: 'cascade' }
					    ]
					}
				    ],
				    route: [{
					entity_template: 'data_item',
					title: 'Select a Gateway or Port:',
					elements: [{
					    entity_template : 'select_populate',
					    name: 'bbx_callflow_final_action_gw_dest',
					    rest : '/gui/gateway/combined'
					}]
				    }]
				} // END switch elements
			    } // END switch widget
			]
		    }, // END page module "Final Action"
		    {
			entity_template: 'page_module',
			form_action: '/gui/' + (fmfm?'user':'extension') + '/router/callflow',
			form_data_js: { include_keys: ['bbx_callflow_id'] },
			legend: 'Options',
			buttons: [ 'help' ],
			help_id:  (fmfm?'fmfm_':'') + 'routers_options',
			elements: options_pm
		    }
		]}
	    ]
	}; // END screen_def var declaration

	register_screen((fmfm ? 'fmfm_' : '') + 'router_action_detail', screen_def);

    } // END for fmfm loop

})();
