/* jshint jquery: true, unused: vars */
/* global CUI_WIDGET_NAMESPACE: true, debugLog, CUI, getUnique, checkPermissions */
/* exported widgetsSimilarTo, add_widget */
/**
 * @class widget(options)
 *
 * This is a base widget class to be used for loading template HTML files via AJAX and populating
 * data into the widget either through a callback function, an event, or form module.
 *
 * @par Usage:
 *
 * $(...).widget({template: '/ajax-html/yourtemplate.html', data: { yourdata } });
 *
 * or
 *
 * $(...).widget({template: '/ajax-html/yourtemplate.html', rest: '/gui/your_rest_handler' });
 *
 * or
 *
 * var mywidget = $.extend({}, $.ui.widget.prototype, {
 *     _beforeInit: function() {
 *         // Stuff to do before the base-class does template loading and data loading
 *     },
 *     _afterInit: function() {
 *         // Stuff to do after the base-class does template loading and data loading
 *     },
 *     doDataFill: function() {
 *         // Override how data is filled into the widget
 *     },
 *     value: function() {
 *         // Override how a value is set for the widget
 *     }
 * });
 * add_widget('mywidget', 'ui.mywidget', 'mywidget', mywidget);
 *
 * @par Properties:
 * @param value_widget If true, this widget will return a value for this DOM object.
 * @param set_value_widget If true, this widget's setValue will be used to show the value for the widget. If not, this widget's setValue function may not be called, if another set_value_widget also is applied to the DOM element.
 * @param data_producer Set this "true" if the widget produces data, such as a form or table widget
 *
 * @par Options:
 * @param template Specifies the location of the HTML template to load into the DOM element. If the template path does not begin with a "/", it is expected
 *                 to be relative to the "template_root" option.
 * @param template_root The location of template HTML files. Set by default in the base widget.
 * @param data Specifies data to use for filling the widget, but does not prevent a REST request. The REST request overrides data passed here.
 * @param rest Specifies the location where data can be obtained by a REST request.
 * @param rest_method Override the method (GET/POST/PUT/DELETE) for the data pull
 * @param rest_params Specifies data to send to the controller handling the REST request.
 * @param filter_rest_params An array specifying keys to REMOVE from the rest_params before submission
 * @param only_rest_params An array specifying only the keys to INCLUDE from the rest_params before submission. Ignored if filter_rest_params is set
 * @param rest_container Specifies the container key for the data
 * @param rest_not_object Flag (normally false) that, if set, will allow the rest_container-ed data to be something other than an array or object.
 *                        This is generally not the case, and this flag will rarely (if ever) need to be set.
 * @param no_initial_get Flag that skips the initial GET pull for the widget's data
 * @param allow_html If this flag is true, the widget will fill non-form fields via .html() instead of .text(). This can open up the door for injection.
 *                   This may also be handled on a per-field basis by putting an 'allow-html="allow"' attribute on the HTML element.
 * @param accept_data_from_parent If this flag is false, the widget will not use fillData to automatically fill itself
 * @param private_fill_data If this flag is true, the widget will not perform a fillDataChildren if the data comes from the widget itself, but it will still pass any data from parents along. This only applies if the widget uses the feature, or if the widget relies on the Widget Base to perform fillDataChildren
 * @param rest_params_from_closest This allows a widget to get rest params from a parent widget
 *        {closest: <selector>, widget: <widget name>, params: [<param>,...] }
 *
 * Options may be added by passing them to the widget instantiation call. If the HTML/DOM structures are
 * automatically widgetized by "widgetize_children", options are set by adding a "data-js" attribute, with
 * a JSON-string value, on the element's HTML, and/or by adding a .data('extra_widget_options') object to the
 * element's DOM element before it is auto-widgetized. The last two methods are not applied when the DOM
 * object is directly widgetized.
 *
 * Data is filled in from external sources or Form Widgets by either the control's "name" or "value-from" attributes. ("Value-from" fills in the
 * data, but form widgets do not submit that value.)
 */

/**
 * @property $template
 * @brief This is a jQuery object containing a cached copy of the template.
 */

/**
 * @property data
 * @brief This is the data that is passed in or the data that is grabbed from the REST request.
 */


// Global list of widgets
var widgets = {};

// Set the global jQ-UI namespace for CUI widgets
CUI_WIDGET_NAMESPACE = 'ui';

/**
 * @fn widgetsSimilarTo($jQ_item, widget_name)
 * @brief Find widget(s) on an item that are "similar to" other widgets, as defined by the widgets' use of the s.o.similar_to array. Many widgets do not
          support this option, so be sure the one you're looking for does. Returns an array of matching widgets.
 * @param $jQ_item The jQuery item to look on
 * @param widget_name The widget name to look for
 */

function widgetsSimilarTo($elem, name) {
	"use strict";
	var ws, w_idx, widgets = [], ss, s_idx;
	ws = $elem.getCUIWidgets();

	if (!ws) { return; }
	widgetFinderLoop: for (w_idx = 0; w_idx < ws.length; w_idx++) {
		if (ws[w_idx].options.similar_to) {
			ss = ws[w_idx].options.similar_to;
			for (s_idx = 0; s_idx < ss.length; s_idx++) {
				if (ss[s_idx] === name) {
					widgets.push(ws[w_idx]);
					continue widgetFinderLoop;
				}
			}
		}
	}

	return widgets || [];
}

/**
 * @fn add_widget(class_name, ns_widget_name, widget_func_name, widget)
 * @brief Register a jQuery UI-derived widget to a class name so it can be auto-widgetized
 * @param class_name Specifies the CSS class to associate the widget to.
 * @param ns_widget_name Specifies the namespace and widget name to use when registering the widget with jQuery.
 * @param widget_func_name This is the name to use when trying to auto-widgetize a DOM element. Usually, it is
 *                         the same as ns_widget_name without the namespace.
 * @param widget This is the widget class to register.
 */
function add_widget(class_name, ns_widget_name, widget_func_name, widget) {
	"use strict";
	if (typeof ns_widget_name == 'object') {
		widget = ns_widget_name;
		ns_widget_name = CUI_WIDGET_NAMESPACE + '.' + class_name;
		widget_func_name = class_name;
	}

	widgets[class_name] = { 'ns_widget_name' : ns_widget_name, 'widget_func_name' : widget_func_name };
	$.widget(ns_widget_name, widget);
}


/**
 * @fn $.fn.getCUIWidgets()
 * @brief Returns all widgets on the first matched object
 */
$.fn.getCUIWidgets = function () {
	"use strict";
	return this.eq(0).data('widgets');
};

/**
 * @fn $.fn.getCUIWidget(widget_name)
 * @brief Returns the widget matching the specified name, or the first widget (in the "widgets" array), on the first matched object. Returns undefined if
 *        no matching widget was found.
 * @param widget_name The widget name to return.
 */
$.fn.getCUIWidget = function (widget_name) {
	"use strict";
	var widgets;

	// This will need to be changed to include the namespace for jQ-UI 1.10
	if (widget_name) {
		return this.eq(0).data(widget_name);
	} else {
		widgets = this.eq(0).getCUIWidgets();
		return widgets ? widgets[0] : undefined;
	}
};

// Params are:
//   { allow_false: false | true, single: false | true }
$.fn.getCUIWidgetsWith = function (flag, widget_name, params) {
	"use strict";
	var widgets, widgets_out = [], w_idx;

	params = params || {};

	if (widget_name) {
		widgets = $(this).getCUIWidget(widget_name);
		widgets = widgets || [];
	} else {
		widgets = $(this).getCUIWidgets() || [];
	}

	for (w_idx = 0; w_idx < widgets.length; w_idx++) {
		if ((flag in widgets[w_idx]) && (params.allow_false || widgets[w_idx][flag])) {
			widgets_out.push(widgets[w_idx]);
			if (params.single) { break; }
		}
	}

	if (params.single) {
		widgets_out = widgets_out[0] || undefined;
	}

	return widgets_out;
};

/**
 * @fn widgetize_children(elem, OPTIONAL context)
 * @brief This function auto-widgetizes widgets that are descendants of the specified DOM element. It will
 *        widgetize descendants that are not descendants of descendant widgets. Thus, it grabs only the
 *        top-most widget in the descendant tree, not those widgets that are descendants of other widgets.
 * @param $elem This is the root of the DOM tree to search and widgetize.
 * @param context This is the "self" of the caller, if the widgetization should include cascading data/params.
 */
function widgetize_children($elem, context) {
	"use strict";
	var $widget_elems, $we, idx, wlen;
	if ($elem) {
		$elem = $($elem);
	} else {
		return;
	}

	$widget_elems = $elem.findNearest('.widgetType').not('.widgetized');

	wlen = $widget_elems.length;
	for (idx = 0; idx < wlen; idx++) {
		$we = $widget_elems.eq(idx);
		widgetize_element($we, context);
	}
}

function widgetize_element($elem, context) {
	"use strict";
	var this_widget_params = {}, widget_option_data, widget_params, widget_class, wc_array, wc_idx, wc_lookup = {};

	$elem = $($elem);

	widget_option_data = $elem.data('extra_widget_options');

	if ($elem.attr('data-js')) {
		widget_params = jQuery.parseJSON($elem.attr('data-js'));
	} else {
		widget_params = {};
	}

	if ($elem.data('data-js')) {
		$.extend(widget_params, $elem.data('data-js') || {});
	}


	if (widget_option_data) {
		$elem.removeData('extra_widget_options');
	}

	// This routine warns if you used an xxxxWidget class on a widget, but xxxxWidget doesn't exist.
	wc_array = $elem.attr('class').split(' ');

	for (wc_idx = 0; wc_idx < wc_array.length; wc_idx++) {
		// Anything that ends in "widget" except "widgetValueWidget"
		if (wc_array[wc_idx].search('[Ww]idget$') > -1 && wc_array[wc_idx] !== 'widgetValueWidget') {
			wc_lookup[wc_array[wc_idx]] = true;
		}
	}

	for (widget_class in widgets) {
		if ($elem.hasClass(widget_class)) {

			delete wc_lookup[widget_class];

			// widget_option_data allows a JS/jQ function to attach option data to something it is not directly widgetizing,
			// before the widgetizing is actually done. Options sent this way are extended atop the existing option set.


			//debugLog('With widget_class = ' + widget_class + ', element params: ', widget_params);

			//debugLog('Checking if ' + widget_class + ' is in ', widget_params);
			if (widget_params && widget_class in widget_params) {
				//debugLog('We found ' + widget_class + ' in ', widget_params);
				this_widget_params = widget_params[widget_class];
			} else if (widget_params) {
				this_widget_params = widget_params;
			}

			//debugLog('Widget params: ', this_widget_params);

			if (
				(
					this_widget_params && this_widget_params.rest_params !== undefined) &&
				context &&
				context.options &&
				context.options.rest_params !== undefined
			) {

				$.extend(this_widget_params.rest_params, context.options.rest_params);

			} else if ((!this_widget_params || (this_widget_params && !this_widget_params.rest_params )) && context && context.options && context.options.rest_params) {
				this_widget_params.rest_params = context.options.rest_params;
			}

			$.extend(this_widget_params, { 'widget_type' : widget_class });

			if (widget_option_data) {
				$.extend(true, this_widget_params, widget_option_data);
			}

			$elem[widgets[widget_class].widget_func_name](this_widget_params);
		}
	}

	for (wc_idx in wc_lookup) {
		if (!wc_lookup.hasOwnProperty(wc_idx)) {
			debugLog('jquery.widget.js: Widget class ' + wc_idx + ' was added to an element, but this is not a valid widget. (on ', $elem, ')');
		}
	}
}

(function($) {
	// Contains arguments.callee -- cannot use strict
	var widget = $.extend({}, CUI.widgetBaseRestExtend, {
		///////////////////////////////////////////////////////////////////////////////////////////
		// Static members of the class for all widgets of this type. Note, we don't actually define
		// them so we can simply test whether they are defined to determine whether we use them.
		///////////////////////////////////////////////////////////////////////////////////////////
		//template_html: '',
		///////////////////////////////////////////////////////////////////////////////////////////

		options: {
			accept_data_from_parent: true,
			template_root: '/ajax-html/templates3/',
			similar_to: 'widget'
		},

		/**
	 * @property manages_own_descendent_state
	 * @brief Determines whether this widget manages state changes (enable/disable/show/hide/etc.) of descendent widgets and inputs using its own
	 *        state-changing functions (enable(), disable(), etc.), or whether it is simply a containing widget, and descendent widgets/inputs should be
	 *        disabled manually. This setting is moot if the widget is not an element that can contain other elements. (e.g., if it is a widgetized
	 *        textbox.)
	 *
	 */
		manages_own_descendent_state: false,

		/**
	 * @property manages_own_descendent_value
	 * @brief Determines whether this widget manages any NAMEd fields or value-bearing widgets within itself. This should be set true for any value-bearing
	 *        encompassing widgets that have other value-bearing widgets or NAMEd fields within them, if the interior fields are processed and returned as
	 *        part of the encompassing widget's value.
	 */
		manages_own_descendent_value: false,

		/**
	 * @property manages_own_descendent_events
	 * @brief Determines whether this widget should stop propagation on any change/keyup/click events on elements below the widget element. If this is
	 *        true, you will need to trigger "change" on the widget element itself to signal a change in value.
	 */
		manages_own_descendent_events: false,

		/**
	 * @property requires_destroy
	 * @brief This is only used in widgets like dataTableWidget that don't explicitly destroy their children in some cases-- use this flag only when
	 * you absolutely, positively need to be destroyed in all cases. This should be checked on a per-widget basis in "parent" widgets that may not
	 * use jQuery remove() or explicitly destroy all their children before rewriting. Then again, you should be using jQuery remove() in your own code.
	 */
		requires_destroy: false,

		/**
	 * @fn widgetizeChildren(elem)
	 * @brief Runs widgetize_children on a node, with the "self" as the context
	 */
		widgetizeChildren: function (elem) {
			var self = this, idx, len;
			if (elem instanceof jQuery && elem.length > 1) {
				len = elem.length;
				for (idx=0; idx<len; idx++) {
					widgetize_children(elem.eq(idx), self);
				}
			} else {
				widgetize_children(elem, self);
			}
		},

		/**
	 * @fn getAllWidgets()
	 * @brief Get all widgets applied to this object.
	 * @return Array of widgets
	 */

		getAllWidgets: function () {
			return this.element.getCUIWidgets();
		},

		/**
	 * @fn _beforeInit(callback)
	 * @brief Override this in your child class to execute code before initialization.
	 * @param callback This is the private class member which needs to be called after the child
	 *                 class code runs. If you are going to call it, you must return true from
	 *                 _beforeInit().
	 */
		_beforeInit: function(callback) {
			return false;
		},

		/**
	 * @fn _doBeforeInit()
	 * @brief This is a private helper function for _init which allows a child class to override
	 *        _beforeInit() and either call _realInit() through the passed callback or return
	 *        false indicating that it is not calling _realInit().
	 */
		_doBeforeInit: function() {
			var self = this, $self = this.element, end_callback, fn, args;

			self._setupWidgetClosures();

			end_callback = function() {
				if (!self._beforeInit(function() { self._realInit.call(self); } )) {
					self._realInit();
				}
			};

			// Allows a function to be specified in the screendef, and run on init
			fn = self.options.fn_before_init;
			if (fn) {
				if ($.isArray(fn)) {
					fn = fn[0];
					args = fn.slice(1);
				}

				if (typeof fn === 'function') {
					fn = fn;
				} else if (typeof fn === 'string') {
					fn = CUI.getObjectAtString(fn);
				}

				if (fn) {
					fn.apply(this, args || []);
				}
			}

			self._callEventHooks('Init', end_callback);
		},

		/**
	 * @fn _init(options)
	 * @brief This is the default constructor called when you do '$("target").widget();'. All
	 *        parameters are passed like '$("target").widget({ param1: "val1", param2: "val2" })',
	 *        etc. They come into the class under this.options.* (ie. this.options.param1).
	 * @param template This specifies the HTML template to load into 'target'.
	 * @param data This is a data structure to use for loading static or pre-defined data into widget.
	 * @param rest This is the location of a REST controller to use for retrieving data.
	 * @param rest_params This is a set of parameters to pass to the REST call.
	 * @param fill_data_fn This is a callback function which can be used to override the default method
	 *                     of filling data for the widget.
	 */
		_init: function() {
			var self = this, $self = this.element;

			// Mark the current object as a widget
			$self.addClass('widgetType widgetized');

			if(!self._enforcePermissions()) {
				return;
			}

			// Store this class instance for later reference
			if (typeof $self.getCUIWidgets() != 'object') {
				$self.data('widgets', []);
			}
			$self.data('widgets').push(self);

			// This is done by the JQ UI widget factory
			//$self.data(self.options.widget_type, self);

			self.options.parent = $self.parents('.widgetType').getCUIWidget();

			self.options.widget_id = getUnique(self.options.widget_type || 'widget');

			/* Hack to make IE 7 and IE 8 workable, albeit slow */
			if (self.options.ie_selector_hack && navigator.userAgent.indexOf('MSIE 8') !== -1) {
				self._bind($self, 'ready change iehack', function() {
					if ($self.is(':last-child')) {
						$self.addClass('last-child');
					} else {
						$self.removeClass('last-child');
					}
					if ($self.is(':first-child')) {
						$self.addClass('first-child');
					} else {
						$self.removeClass('first-child');
					}
					if($self.is(':nth-child(odd)') && !$self.is('.nth-child-even')) {
						$self.addClass('nth-child-odd');
					}
					if($self.is(':nth-child(even)') && !$self.is('.nth-child-odd')) {
						$self.addClass('nth-child-even');
					}
					var $exclusion = $self.find('.widgetType *');
					$self.find('*:last-child').not($exclusion).addClass('last-child');
					$self.find('*:first-child').not($exclusion).addClass('first-child');
					$self.find('*:nth-child(odd):not(.nth-child-even)').not($exclusion).addClass('nth-child-odd');
					$self.find('*:nth-child(even):not(.nth-child-odd)').not($exclusion).addClass('nth-child-even').removeClass('nth-child-odd');
					$self.find('*.last-child:not(:last-child)').not($exclusion).removeClass('last-child');
					$self.find('*.first-child:not(:first-child)').not($exclusion).removeClass('first-child');
				});
			}

			self._doBeforeInit();
		},

		_enforcePermissions: function() {
			var self = this, $self = this.element;

			if (self.options.requires) {
				if (!$.isArray(self.options.requires)) {
					debugLog('jquery.widget.js: The "requires" attribute must be an array of permissions required to use this widget. -- ', $self);
					return false;
				}

				// From helpers.js
				return checkPermissions(self.options.requires);
			}

			return true;
		},

		_realInit: function() {
			var self = this, $self = this.element;

			// Check if we have a template which was specified to be loaded upon widget initialization
			var $template = false;

			// Set a few "flag" classes so we don't have to keep looking into data.widget all the time for flags...
			if (self.manages_own_descendent_value) { $self.addClass('widgetManagesOwnDescendentValue'); }
			// Remember: Since there can be multiple widgets on an object, you still have to loop to find out which one is the value widget!
			if (self.value_widget) { $self.addClass('widgetValueWidget'); }
			if (self.requires_destroy) { $self.addClass('widgetRequiresDestroy'); }
			if (self.data_producer) { $self.addClass('dataProducer'); }
			if (self.options.rest_params_from_closest) { self._restParamsFromClosest(); }

			if (self.manages_own_descendent_events) {
				self._bind($self, 'change click keyup', function (e) {
					if (e.target !== this) { e.stopPropagation(); }
				});
			}

			if (self.options.template_html) {
				$template = $('<div />').html(self.options.template_html).contents();
			}

			if ($template) {
				self._stuffHtml($template);
				self._doDOMReady();
			} else if (typeof self.options.template === 'string') {
				// getTemplate automatically caches the HTML and pulls from cached if available
				if (!self.options.template.match(/^\//) && self.options.template_root) {
					self.options.template = (self.options.template_root || '') + (self.options.template || '');
				}

				CUI.getTemplate(self.options.template, function (html) {
					if (html) {
						self._stuffHtml(html);
					}
					self._doDOMReady();  // <--- Asynchronous. Has to wait for HTML, so cannot be moved
				});
			} else {
				self._doDOMReady();
			}
		},

		_setupWidgetClosures: function() {
			var self = this, $self = this.element;

			self.options.live_array_on_err_callback_closure = function(obj, args) {
				self._doLAOnErrCallback(obj, args);
			};

			self.options.live_array_on_change_callback_closure = function(obj, args) {
				self._doLAOnChangeCallback(obj, args);
			};
		},

		_stuffHtml: function (html) {
			var self = this, $self = this.element, $html;

			if ($html instanceof jQuery) {
				$html = html.clone();
			} else {
				$html = $(html);
			}

			var $static_body = $html.is('.staticBody') ? $html : [];

			if (!$static_body[0]) {
				// If this is not a "staticBody" style template, wrap it in a DIV to pass it to the hook, so the jQ object can be .find()ed upon--
				// the DIV gets stripped later.
				$static_body = $('<div></div>').append($html);
			}

			// Save the template for later reference -- template may be a top-level element or one beneath
			var $template = $html.filter('.template');

			if (!$template[0]) {
				$template = $('> .template', $html);
			}

			$template.detach();
			self.options.$template = $template;

			// Pre-processing hook
			$static_body = self._processStaticBody($static_body) || $static_body; // OR on the end in case they forgot the return

			// Stuff the $staticBody contents (stripping the containing DIV) into this object's container
			self.options.inner_html = $static_body.contents();
			$self.empty().append(self.options.inner_html);
		},

		/**
	 * @fn _processStaticBody($staticBody)
	 * @brief This function is executed after the "static body" is jQuerified, but before it is written to the page. The wrapping object
	 *        ($staticBody itself) is stripped before adding the contents to the page. The function should return the new $staticBody.
	 */

		_processStaticBody: function ($staticBody) {
			return $staticBody;
		},

		/**
	 * @fn _beforeDOMReady(callback)
	 * @brief This function is executed right before the DOMReady event is fired. At this point, HTML is expected to
	 *        be loaded into the widget if needed and the DOM should no longer be changing.
	 */
		_beforeDOMReady: function(callback) {
			return false;
		},

		/**
	 * @fn isDirty(key, original_value, current_value, compare_params)
	 * @brief This function contains the logic to determine whether a given value is dirty or not. Each key/value associated with the widget is passed
	 *        individually, and the value may be an array if other controls on the form have set the value as well. Note that this function cannot indicate
	 *        clean if the widget is already dirty-- Once the widget is marked dirty, further checks are skipped. Override this as needed.
	 */

		isDirty: function (key, original_value, current_value, compare_params) {
			compare_params = $.extend({}, { loose_compare_basics: true, null_equals_blank: true, empty_array_equals_blank: true }, compare_params || {});
			if (!CUI.getElementName(this.element)) { return false; } // Unnamed elements are NEVER dirty
			return !CUI.compareObjects(original_value, current_value, compare_params);
		},

		/**
	 * @fn _doDOMReady()
	 * @brief This is a private helper function for _DOMReady which allows a child class to override
	 *        _beforeDOMReady() and either call _DOMReady() through the passed callback or return
	 *        false indicating that it is not calling _DOMReady().
	 */
		_doDOMReady: function() {
			var self = this, $self = this.element;

			var end_callback = function() {
				if (!self._beforeDOMReady(function () { self._DOMReady.call(self); } )) {
					self._DOMReady();
				}
			};
			self._callEventHooks('DOMReady', end_callback);
		},

		/**
	 * @fn _DOMReady()
	 * @brief This private helper function triggers the DOMReady event and then continues initialization
	 *        with data filling.
	 */
		_DOMReady: function() {
			var self = this, $self = this.element;

			self._trigger('DOMReady');
			$self.trigger('DOMReady');
			self._doDataFill();
		},

		_doLAOnErrCallback: function(obj, args) {
			var self = this, $self = this.element;

			if (self._laOnErr) {
				self._laOnErr(obj, args);
			}
		},

		_doLAOnChangeCallback: function(obj, args) {
			var self = this, $self = this.element, i;

			if (self.options.live_table_limit && self.options.live_table_limit == 1) {
				if (args.action == 'del' || args.action == 'clear' || args.action == 'reorder') {
					if (self._laOnChange) {
						self._laOnChange(obj, args);
					}
					return;
				}
				if (!args.data) {
					if (self._laOnChange) {
						self._laOnChange(obj, args);
					}
					return;
				}
				if (args.action == 'init') {
					for (i in args.data) {
						self.options.data[args.data[i]] = '';
					}
					self.options.data.columns = args.data;
				} else {
					if (typeof $.isArray(args.data)) {
						for (i in args.data) {
							var col = self.options.data.columns[i];
							self.options.data[col] = args.data[i];
						}
					}
				}
			}
			if (self._laOnChange) {
				self._laOnChange(obj, args);
			} else {
				self.fillData(self.options.data, true);
			}
		},

		_hookEvent: function(target, event, func) {
			var self = this, $self = this.element;

			if (!self.options.hooked_into) {
				self.options.hooked_into = [];
			}
			var hook_obj = { 'target': target, 'event': event, 'func': func };

			if (!target || !event || !func) {
				debugLog('jquery.widget.js: Target, event, and func params are required in _hookEvent to  ', hook_obj, ' -- ', $self);
			}

			self.options.hooked_into.push(hook_obj);
			target.addEventHook(event, func);
		},

		_hookData: function(target, data_event, func) {
			var self = this, $self = this.element;

			if (!self.options.data_hooked_into) {
				self.options.data_hooked_into = [];
			}
			var data_hook_obj = { 'target': target, 'data_event': data_event, 'func': func };
			self.options.data_hooked_into.push(data_hook_obj);
			target.addDataHook(data_event, func);
		},

		_unhookEvent: function(target, event, func) {
			var self = this, $self = this.element;

			if (!self.options.hooked_into) {
				return;
			}
			for(var h = 0, hlen = self.options.hooked_into.length; h < hlen; ++h) {
				var hook_obj = self.options.hooked_into[h];
				if (hook_obj.target == target && hook_obj.event == event) {
					target.removeEventHook(event, hook_obj.func);
					self.options.hooked_into.splice(h, 1);
					return;
				}
			}
		},

		_unhookData: function(target, data_event, func) {
			var self = this, $self = this.element;

			if (!self.options.data_hooked_into) {
				return;
			}
			for(var h = 0, hlen = self.options.data_hooked_into.length; h < hlen; ++h) {
				var data_hook_obj = self.options.data_hooked_into[h];
				if (data_hook_obj.target == target && data_hook_obj.event == event) {
					target.removeDataHook(event, data_hook_obj.func);
					self.options.data_hooked_into.splice(h, 1);
					return;
				}
			}
		},

		_deleteRecursive: function(source) {
			var self = this, $self = this.element;

			if (typeof source === 'string' || typeof source === 'function' || typeof source === 'number' || typeof source === 'boolean' || source === null || typeof source === 'undefined') {
				return;
			}
			if (source.nodeName || source.sDom) {
				return;
			}
			if (source instanceof Array) {
				for (var s = 0, slen = source.length; s < slen; ++s) {
					//		    if (!(source[s] instanceof jQuery)) {
					//			self._deleteRecursive(source[s]);
					//		    }
					delete source[s];
					source.splice(s, 1);
				}
				return;
			}
			for (var key in source) {
				//		if (!(source[s] instanceof jQuery)) {
				//		    self._deleteRecursive(source[key]);
				//		}
				delete source[key];
			}
		},

		_destroy: function() { },

		destroy: function() {
			var self = this, $self = this.element;

			self.options.destroyed = true;

			if (self.options.la) {
				self.options.la.destroy();
			}

			if (self.options.destroy_callbacks) {
				for (var i in self.options.destroy_callbacks) {
					self.options.destroy_callbacks[i]();
				}
			}

			if (self.options.timeouts) {
				while (self.options.timeouts.length) {
					clearTimeout(self.options.timeouts[0]);
					self.options.timeouts.splice(0, 1);
				}
			}

			if (self.options.intervals) {
				while (self.options.intervals.length) {
					clearInterval(self.options.intervals[0]);
					self.options.intervals.splice(0, 1);
				}
			}

			if (self.options.bindings) {
				while (self.options.bindings.length) {
					var binding_obj = self.options.bindings[0];
					binding_obj.$target.unbind(binding_obj.event, binding_obj.func);
					self.options.bindings.splice(0, 1);
				}
			}

			if (self.options.delegates) {
				while (self.options.delegates.length) {
					var delegate_obj = self.options.delegates[0];
					delegate_obj.$target.off(delegate_obj.event, delegate_obj.selector, delegate_obj.func);
					self.options.delegates.splice(0, 1);
				}
			}

			if (self.options.lives) {
				while (self.options.lives.length) {
					var live_obj = self.options.lives[0];
					$(document.body).off(live_obj.event, live_obj.targets, live_obj.func);
					self.options.lives.splice(0, 1);
				}
			}

			if (self.options.hooked_into) {
				while (self.options.hooked_into.length) {
					var hook_obj = self.options.hooked_into[0];
					hook_obj.target._unhookEvent(hook_obj.event, hook_obj.func);
					self.options.hooked_into.splice(0, 1);
				}
			}

			if (self.options.data_hooked_into) {
				while (self.options.data_hooked_into.length) {
					var data_hook_obj = self.options.data_hooked_into[0];
					data_hook_obj.target._unhookData(data_hook_obj.event, data_hook_obj.func);
					self.options.data_hooked_into.splice(0, 1);
				}
			}

			self._destroy();

			self._deleteRecursive(self.options);

			// That just clobbered self.options...
			self.options = { destroyed: true };

			$self.removeData('widgetData');
			$self.removeData('dataJs');
			$self.removeData('widgets');
			$self.removeData(self.options.widget_type);

			if (this.element) {
				$.Widget.prototype.destroy.apply(this, arguments);
			}

			$self.removeData('widget');
			$self.removeData();
			$self.triggerHandler('destroy');
		},

		_addDestroyCallback: function(fun) {
			var self = this, $self = this.element;

			self.options.destroy_callbacks = self.options.destroy_callbacks || [];
			self.options.destroy_callbacks.push(fun);
		},

		_addDestroyMethod: function (fun) {
			var self = this;
			// Convenience function to add a self-method destroy callback. This automatically calling FunctionFactory so you don't have to.
			return self._addDestroyCallback(fun.bind(self));
		},

		_setTimeout: function(fun, dur) {
			var self = this, $self = this.element;

			if (!self.options.timeouts) {
				self.options.timeouts = [];
			}
			var pid_loc = self.options.timeouts.length;
			var callback = function () {
				if (!self.options.timeouts) {
					// Return if it doesn't exist, because this likely means that the widget is in the process of destructing
					return;
				}
				self.options.timeouts.splice(pid_loc, 1);
				fun();
			};
			var pid = setTimeout(callback, dur);
			self.options.timeouts.push(pid);
			return { pid: pid, pid_loc: pid_loc };
		},

		_clearTimeout: function(pid) {
			var self = this, $self = this.element;

			if (!pid.pid) {
				return;
			}

			clearTimeout(pid.pid);

			if (!self.options.timeouts) {
				return;
			}

			self.options.timeouts.splice(pid.pid_loc, 1);
		},

		_setInterval: function(fun, dur) {
			var self = this, $self = this.element;
			self.options.intervals = self.options.intervals || [];

			var pid_loc = self.options.intervals.length;
			var callback = function() {
				if (!self.options.intervals) {
					// Return if it doesn't exist, because this likely means that the widget is in the process of destructing
					return;
				}
				self.options.intervals.splice(pid_loc, 1);
				fun();
			};
			var pid = setInterval(callback, dur);
			self.options.intervals.push(pid);
			return { pid: pid, pid_loc: pid_loc };
		},

		_clearInterval: function(pid) {
			var self = this, $self = this.element;

			if (!pid.pid) {
				return;
			}

			clearInterval(pid.pid);

			if (!self.options.intervals) {
				return;
			}

			self.options.intervals.splice(pid.pid_loc, 1);
		},

		_one: function($target, event, func) {
			var self = this, $self = this.element;

			self._bind($target, event, function() {
				func(event);
				self._unbind($target, event, func);
			});
		},

		_bind: function($target, event, func) {
			var self = this, $self = this.element;

			self.options.bindings = self.options.bindings || [];

			var binding_obj = { '$target': $target, 'event': event, 'func': func };
			self.options.bindings.push(binding_obj);
			$target.on(event, func);
		},

		_unbind: function($target, event) {
			var self = this, $self = this.element;

			$target.off(event);

			if (!self.options.bindings) {
				return;
			}

			for(var b = 0, blen = self.options.bindings.length; b < blen; ++b) {
				var binding_obj = self.options.bindings[b];
				if (binding_obj.$target == $target && binding_obj.event == event) {
					self.options.bindings.splice(b, 1);
					return;
				}
			}
		},

		_delegate: function($target, selector, event, func) {
			var self = this, $self = this.element;

			self.options.delegations = self.options.delegations || [];
			self.options.delegations.push({ '$target': $target, 'selector': selector, 'event': event, 'func': func });

			$target.delegate(selector, event, func);
		},

		_undelegate: function($target, selector, event) {
			var self = this, $self = this.element;

			$target.off(event, selector);

			if (!self.options.delegations) {
				return;
			}

			for(var d = 0, dlen = self.options.delegations.length; d < dlen; ++d) {
				var delegate_obj = self.options.delegations[d];
				if (delegate_obj.$target == $target && delegate_obj.selector == selector && delegate_obj.event == event) {
					self.options.delegations.splice(d, 1);
					return;
				}
			}
		},

		_live: function(target_selector, event, func) {
			var self = this, $self = this.element;
			self.options.lives = self.options.lives || [];
			self.options.lives.push({ targets: target_selector, event: event, func: func });
			$(document.body).on(event, target_selector, func);
		},

		_die: function(target_selector, event, func) {
			var self = this, $self = this.element;
			$(document.body).off(event, target_selector, live_obj.func);

			if (!self.options.lives) {
				return;
			}

			for(var l = 0, llen = self.options.lives.length; l < llen; ++l) {
				var live_obj = self.options.lives[l];
				if (live_obj.targets == target_selector && live_obj.event == event) {
					self.options.lives.splice(l, 1);
					return;
				}
			}
		},

		_cloneCallback: function(old_elem, new_elem) {
			return false;
		},

		addEventHook: function(event, callback) {
			var self = this, $self = this.element;

			if (self.options.hooks === 'undefined') {
				self.options.hooks = {};
			}

			self.options.hooks[event] = self.options.hooks[event] || [];
			self.options.hooks[event].push(callback);
		},

		removeEventHook: function(event, callback) {
			var self = this, $self = this.element;

			if (!self.options.hooks) {
				return;
			}
			if (!self.options.hooks[event]) {
				return;
			}
			var h = 0;
			var hook_callback = self.options.hooks[event][h];
			while (hook_callback != callback && h < self.options.hooks[event].length) {
				++h;
				hook_callback = self.options.hooks[event][h];
			}
			if (hook_callback == callback) {
				self.options.hooks[event].splice(h, 1);
			}
		},

		_callEventHooks: function(event, final_callback) {
			var self = this, $self = this.element, args = Array.prototype.slice.apply(arguments);

			if (typeof self.options.hooks !== 'undefined' && event in self.options.hooks) {
				var hook_number = 0;

				var callback = function() {
					var hook = self.options.hooks[event][hook_number];
					var cbargs = Array.prototype.slice.apply(arguments);
					hook_number++;
					if (hook_number == self.options.hooks[event].length) {
						if (arguments.length >= 2) {
							// Insert final_callback as first argument of arguments list
							cbargs.splice(0, 0, final_callback);
							hook.apply(self, cbargs);
						} else {
							hook(final_callback);
						}
					} else {
						if (arguments.length >= 2) {
							// Insert arguments.callee as first argument of arguments list
							cbargs.splice(0, 0, arguments.callee);
							hook.apply(self, cbargs);
						} else {
							hook(arguments.callee);
						}
					}
				};
				if (arguments.length >= 2) {
					callback.apply(self, args.slice(2));
				} else {
					callback();
				}
			} else {
				if (arguments.length >= 2) {
					final_callback.apply(self, args.slice(2) );
				} else {
					final_callback();
				}
			}
		},

		addDataHook: function(data_event, data_handler) {
			var self = this, $self = this.element;

			self.options.data_hook = self.options.data_hook || {};
			self.options.data_hook[data_event] = self.options.data_hook[data_event] || [];
			self.options.data_hook[data_event].push(data_handler);
		},

		removeDataHook: function(data_event, callback) {
			var self = this, $self = this.element, h, hook_callback;

			if (!self.options.data_hook || !self.options.data_hook[data_event]) { return; }

			h = 0;
			hook_callback = self.options.data_hook[data_event][h];
			while (hook_callback !== callback && h++ < self.options.data_hook[data_event].length) {
				hook_callback = self.options.data_hook[data_event][h];
			}

			if (hook_callback == callback) {
				self.options.data_hook[data_event].splice(h, 1);
			}
		},

		_callDataHook: function(data_event, data) {
			var self = this, $self = this.element;

			if (!self.options.data_hook) {
				return data;
			}
			if (!self.options.data_hook[data_event]) {
				return data;
			}
			for (var h = 0, hlen = self.options.data_hook[data_event].length; h < hlen; ++h) {
				data = self.options.data_hook[data_event][h](data);
			}
			return data;
		},

		_validateRESTContainer : function (d, force_rest_container) {
			var self = this, rest_container;

			/* BNPH-7101 Only return undefined if we lack both a rest action and a rest_container */
			if (!self.options.rest && !self.options.rest_container && !force_rest_container) {
				return undefined;
			}

			rest_container = force_rest_container || self.options.rest_container;

			// Set rest_container to the "action" part of the URL if that key exists in the data, and rest_container is otherwise undefined
			if (rest_container === undefined) {
				var action = self.options.rest.replace(/^.+\//, '');
				if (d && action in d) {
					rest_container = action;
				}
			}

			// We can set s.o.r_c to what we now know to be correct, as long as that's what we're dealing with
			if (!force_rest_container) {
				self.options.rest_container = rest_container;
			}

			if (typeof rest_container === 'string' && (self.options.rest_not_object || $.isArray(d[rest_container]) || $.isPlainObject(d[rest_container])) ) {
				return rest_container;
			} else {
				// If d[rest_container] is not an object or array (and the rest_not_object flag did not override that check)
				if (!force_rest_container) {
					// Don't mess with s.o.r_c if we're dealing with a forced one -- we could end up mucking up something we don't want to mess with
					self.options.rest_container = false;
				}
				return false;
			}
		},

		_markReady: function($node) {
			var self = this, $self = this.element;
			self._trigger('Ready', self.options.data);
			$self.triggerHandler('ready', [self.options.data]);
		},
		_hideNodes: function(node) {
			var self = this, $self = this.element;

			var parent = node.parentNode;
			var children = parent.childNodes;
			for (var i = 0, len = children.length; i < len; ++i)
			{
				if (children[i] != node && children[i].nodeType == 1)
				{
					/* If our node is shown (don't want to show nodes which were previously hidden) */
					var display = $(children[i]).css("display");
					if (display != "none")
					{
						/* Cache the node and it's previous state so we can restore it */
						self.options.hidden.push({
							"node": children[i],
							"display": display
						});
						children[i].style.display = "none";
					}
				}
			}

			if (parent.nodeName != "BODY")
			{
				self._hideNodes(parent);
			}
		},

		_showNodes: function(node) {
			var self = this, $self = this.element, idx, len;

			for (idx = 0, len = self.options.hidden.length; idx < len; ++idx) {
				self.options.hidden[idx].node.style.display = self.options.hidden[idx].display;
			}

			self.options.hidden.splice(0, self.options.hidden.length);
		},

		setURIParams: function(params) {
			var self = this, $self = this.element;

			if (self.options.uri_params) {
				self.options.old_uri_params = self.options.uri_params;
			}
			self.options.uri_params = params;

			for(var key in params) {
				var $node = $self.find('.widgetType[uri_id=' + key + ']');
				if ($node[0]) {
					var widget = $node.getCUIWidget();
					widget.setURIParams(params[key]);
				}
			}

			self._afterSetURIParams();
		},

		_afterSetURIParams: function() { },

		_storeURIParams: function($caller, params) {
			var self = this, $self = this.element;

			if (!$caller.attr('uri_id')) {
				debugLog('jquery.widget.js: Missing uri_id on widget attempting to set URI params: ', $caller, ' -- ', $self);
				return;
			}
			// If we don't have a uri_id set on this widget, keep it out of the URI Params chain
			var uri_id = $self.attr('uri_id');
			if (!uri_id || typeof uri_id === 'undefined') {
				if (self.options.parent && self.options.parent._storeURIParams) {
					self.options.parent._storeURIParams($caller, params);
				}
				return;
			}
			var caller_uri_id = $caller.attr('uri_id');

			self.options.uri_params = self.options.uri_params || {};
			self.options.uri_params[caller_uri_id] = params;

			if (self.options.parent && self.options.parent._storeURIParams) {
				self.options.parent._storeURIParams($self, self.options.uri_params);
			}
		},


		_dataFill: function() {
			var self = this, $self = this.element;
			// Do we have live data?
			if (!self.options.no_initial_get && self.options.live_table) {
				var tbl = self.options.live_table;
				var key = self.options.live_table_key || '';
				var distinct_on = self.options.live_table_distinct_on || '';
				var allow_null_distinct = self.options.live_table_allow_null_distinct || '';
				var order_by = self.options.live_table_order_by || '';
				var context = 'liveTable_' + tbl + '__' + key;
				var la_opts = {
					'page' : self.options.page,
					'page_size' : self.options.page_size,
					'distinct_on': distinct_on,
					'allow_null_distinct': allow_null_distinct,
					'order_by': order_by,
					'search': (self.options.search ? self.options.search : {})
				};
				//		    debugLog("Building new LA object...");
				if (self.options.native_live_table) {
					if (!self._dataTableInit) {
						$.extend(true, self, CUI.dataTableClass);
					}
					self._dataTableInit();
					self._liveDataTableSubscribe(context, la_opts);
				} else {
					throw new Error('Attempted to create a non-native liveTable-based widget. This is no longer supported.');
				}

				self.options.live = true;
				self.options.data = self.options.data || {};
				self.options.data.live = false;

			} else {
				self.options.live = false;
			}

			// Were we given a dynamic location to read from?
			if (!self.options.no_initial_get && self.options.rest) {
				var rest_params = {};
				if (typeof self.options.rest_params !== 'undefined') {
					rest_params = self.options.rest_params;
				}

				if (self.options.rest_data) {
					for (var idx in self.options.rest_data) {
						var rd_k = self.options.rest_data[idx];
						var w_data = $.parseJSON($self.attr('widget-data')) || {};
						rest_params[rd_k] = w_data[rd_k];
					}
				}

				var after_rest_callback = function (d) {
					var rest_container = self._validateRESTContainer(d);
					var d_out = rest_container ? d[rest_container] : d;

					// Has this widget been destroyed between the time the REST call was made, and now?
					if (self.options.destroyed) { return; }

					if (self.options.clear_data_on_refresh) {
						self.options.data = d_out;
					} else if ($.isArray(d_out)) {
						if ($.isArray(self.options.data)) {
							self.options.data.concat(d_out);
						} else {
							self.options.data = d_out;
						}
					} else {
						self.options.data = $.extend(self.options.data || {}, d_out);
					}
					self._doDataReady();
				}; // END after_rest_callback

				// Filter rest params

				var filtered_rest_params = $.extend(true, {}, rest_params || {});
				var rp_idx;

				if (self.options.filter_rest_params) {
					for (rp_idx = 0; rp_idx < self.options.filter_rest_params.length; rp_idx++) {
						delete filtered_rest_params[self.options.filter_rest_params[rp_idx]];
					}
				} else if (self.options.only_rest_params) {
					filtered_rest_params = {};
					for (rp_idx = 0; rp_idx < self.options.only_rest_params.length; rp_idx++) {
						if (self.options.only_rest_params[rp_idx] in rest_params) {
							filtered_rest_params[self.options.only_rest_params[rp_idx]] = rest_params[self.options.only_rest_params[rp_idx]];
						}
					}
				}

				filtered_rest_params = filtered_rest_params || {};

				// getREST has extra functionality that doREST does not, so use getREST if we're doing a GET request (or we haven't specified)
				if (self.options.rest_method && self.options.rest_method.toLowerCase() !== 'get') {
					self.doREST(self.options.rest_method, self.options.rest, filtered_rest_params, after_rest_callback);
				} else {
					self.getREST(self.options.rest, filtered_rest_params, after_rest_callback);
				}

			} else if (self.options.data) {
				self._doDataReady();
			} else if (!self.options.ready) {
				self._finish();
			}

		},


		/**
	 * @fn _doDataFill()
	 * @brief This private helper function continues initialization by getting and filling data into the
	 *        widget.
	 */
		_doDataFill: function () {
			var self = this, $self = this.element, ref__dataFill;

			// Make sure all child widgets are initialized before we try to load data
			if (!self.options.ready) {
				widgetize_children($self, self);
			}

			ref__dataFill = self._dataFill.bind(self);

			if (self.options.wait_for_parent_filldata) {
				if (self.options.have_parent_data) {
					ref__dataFill();
				} else {
					self._one($self, 'fillDataParent', ref__dataFill);
				}
			} else {
				ref__dataFill();
			}
		},

		/**
	 * @fn _beforeDataReady(callback)
	 * @brief This function is executed after data is obtained, but before it is filled into the widget.
	 */
		_beforeDataReady: function(callback) {
			return false;
		},

		/**
	 * @fn _doDataReady()
	 * @brief This is a private helper function for _DataReady which allows a child class to override
	 *        _beforeDataReady() and either call _DataReady() through the passed callback or return
	 *        false indicating that it is not calling _DataReady().
	 */
		_doDataReady: function() {
			var self = this, $self = this.element;

			var end_callback = function() {
				if (!self._beforeDataReady(function() { self._dataReady.call(self); } )) {
					self._dataReady();
				}
			};
			self._callEventHooks('DataReady', end_callback);
		},

		/**
	 * @fn _dataReady()
	 * @brief This helper function fills the data into the widget and then emits the DataReady event.
	 */
		_dataReady: function() {
			var self = this, $self = this.element;

			self.fillData.call(self, self.options.data, true);
			self._trigger('DataReady');
			$self.triggerHandler('dataReady');
			if (!self.options.ready) {
				self._finish();
			}
		},


		/**
	 * @fn _afterInit(callback)
	 * @brief This function finishes initialization of the class and can be overridden in child classes so anything
	 *        after data filling can be executed in the child class before emitting the Ready event.
	 */
		_afterInit: function(callback) {
			return false;
		},

		/**
	 * @fn _finish()
	 * @brief This is a helper function to make the callback to the child for executing code after the widgit is
	 *        fully initialized. When overriding, don't forget to call this base-class function:
	 *        $.ui.widget.prototype._finish();
	 */
		_finish: function() {
			var self = this, $self = this.element;

			// Call child class's localInit function.
			self._afterInit();

			// This is what you bind to: $('target').on('widgetReady', function () { });
			self.options.ready = true;
			var widget_data = $.parseJSON($self.attr('widget-data') || {});
			self.options.widget_data = widget_data;
			//	    $self.data('widgetData', widget_data);
			//	    var data_js = $.parseJSON($self.attr('data-js') || {});
			//	    self.options.data_js = data_js;
			//	    $self.data('dataJs', data_js);
			$self.removeAttr('data-js');
			self._trigger('Ready', self.options.data);
			$self.triggerHandler('ready', [self.options.data]);
		},

		// Allows this widget to grab rest params from a parent widget. Does not update if the parent widget's rest params change.
		// self.options.rest_params_from_closest: {closest: <selector>, widget: <widget name>, params: [<param>,...] }
		_restParamsFromClosest: function() {
			var self = this, $self = this.element, $elem, elem, p, param;

			if (!self.options.rest_params) {
				self.options.rest_params = {};
			}

			if (self.options.rest_params_from_closest) {
				$elem = $self.closest(self.options.rest_params_from_closest.closest);
				elem  = $elem.getCUIWidget(self.options.rest_params_from_closest.widget);

				for(p in self.options.rest_params_from_closest.params) {
					param = self.options.rest_params_from_closest.params[p];

					self.options.rest_params[param] = elem.options.rest_params[param];
				}
			}
		},

		getRestParams: function() {
			var self = this;
			return self.options.rest_params || {};
		},

		/**
	 * @fn fillData(d, from_self)
	 * @brief This fills the widget items with the passed in data. By default, it looks for elements
	 *        that have an ID which matches the key in the associative data array and sets its value.
	 * @param d This is an associative array of key value pairs where the key matches the ID of an
	 *          element in the DOM and the value is passed to the element based whether it is a
	 *          widget or a form element. If it is a form element, it uses $(..).val(v), and if it is
	 *          a widget, it uses $(..).widget('value', v).
	 */
		fillData: function(d, from_self) {
			var self = this, $self = this.element;

			if(!self._enforcePermissions()) { return; }

			// If we're getting data from the parent, set the flag and fire the event for that, then return if the widget doesn't want it.
			if (!from_self) {
				self.options.have_parent_data = true;
				$self.trigger('fillDataParent');
				if (!self.options.accept_data_from_parent) { return; }
			}

			// If we have a callback to fill the data, then use it
			if (d && self.options.fill_data_fn) {
				self.options.fill_data_fn(d, from_self);
			} else if (d) {
				// If there is no callback, then just the default method
				var name = CUI.getElementName($self);
				if (!self.options.private_fill_data || !from_self) {
					self.fillDataChildren(d, from_self);
				}
				if ($self.context.children.length === 0 && name && !$self.hasClass('no-auto-fill')) {
					if (d.hasOwnProperty(name)) {
						self.setValue(d[name]);
					}
				}
			}

			$self.triggerHandler('fillData', [d]);
		},

		fillDataChildren: function(d, fromSelf, $context, alt_data) {
			var self = this, $self = this.element;

			if (!($context instanceof jQuery)) {
				$context = $self;
			}

			// Find children widgets, but not descendant widgets of those children,
			// and descendant DOM elements that are widgets but not the widgets that
			// are descendants of those elements.
			$context.findNearest('.widgetType').filter('.widgetized')
				.each(function() {
				var $element = $(this), elem_widgets = $element.getCUIWidgets() || [], i, widget;
				for (i=0; i < elem_widgets.length; i++) {
					widget = elem_widgets[i];
					var data = d;
					if (typeof d === 'string') {
						// If d is a string, then it is a key. Pull data from either attr(d) (JSON string) or self.options[d]
						// This hackneyed method of data-passing is brought to you by DataTables.net:
						// DataTables.net... We're the "Can't Pass Data to a Table Cell Any Way Except An HTML String" people!
						data = ($element.attr(d) ? JSON.parse($element.attr(d)) : (self.options[d] ? self.options[d] : {}));
					}

					widget.fillData.call(widget, data, false);
				}
			});

			if ($context && $context[0] && $context[0].children.length > 0) {
				var data = d, id;

				if (typeof data === 'string' && alt_data) {
					data = alt_data;
				} else if (alt_data) {
					data = $.extend(true, data, alt_data);
				}

				for (id in data) {
					// Find named elements that are children or descendants that are not
					// children or descendants of a child or descendant widget.

					var val = data[id];

					// Do not use a '.' in a name!
					if (id.indexOf('.') === -1) {
						$context
							.findNotUnder('[name=' + id +'], [value-from=' + id + ']', '.widgetType.widgetManagesOwnDescendentValue')
							.not('.no-auto-fill')
							.each(function() {
							// Set the value of the matched elements.
							self.setValue(val, $(this));
							$(this).removeAttr('data-js').removeAttr('widget-data');
						}); // jshint ignore: line
					} else {
						// debugLog('Stop using a "." in a name attribute! Ignored name: ' + id);
					}
				}
				data = null;
			}
			$self = null;
			self = null;
			d = null;
		},

		/**
	 * @fn value(v)
	 * @brief This method sets the value of this widget's DOM element.
	 * @param v This is the value that we set the widget's DOM element to.
	 */
		setValue: function(v, name, no_change_event) {
			var self = this, $self = this.element, $fields, svw_idx, widgets;

			/*
	      Behavior of setValue as related to the set_value_widget property--

	      Element has no NAME attribute:
	      - setValue does not occur

	      Element has a NAME attribute, and the first widget found overrides setValue:
	      - setValue occurs on the first widget found (which should also have set_value_widget set, as it implements setValue)

	      Element has a NAME attribute, has one or more set_value_widget widgets applied:
	      - setValue is run only on the first set_value_widget widget found. setValue fallback does not occur.

	      Element has a NAME attribute, has no widgets with set_value_widget:
	      - setValue fallback occurs. widget.setValue is run.
	    */

			if (!self.set_value_widget && !self.options._set_value_scanned) {
				widgets = $self.getCUIWidgets();
				for (svw_idx = 0; svw_idx < widgets.length; svw_idx++) {
					if (widgets[svw_idx].set_value_widget) {
						// Prevent infinite loops: Tag the widget as scanned so if the widget calls <prototype>.setValue, it skips this part.
						widgets[svw_idx].options._set_value_scanned = true;
						return widgets[svw_idx].setValue.apply(widgets[svw_idx], arguments);
					}
				}

				// If we've made it this far, then there are no setValue widgets on this element. Continue as normal...
			}

			// Determine if we are setting the value for the DOM element of this widget or whether we are
			// setting the value for a descendant of this widget

			if (!name) {
				name = CUI.getElementName($self);
				$fields = $self;
			} else {
				if (name instanceof jQuery) {
					$fields = name;
				} else {
					if (!name.match(/\./)) {
						$fields = $self.findNotUnder('[name=' + name + '],[value-from=' + name + ']', '.widgetManagesOwnDescendentValue');
					} else {
						// debugLog('Stop using a "." in a name attribute! Ignored name: ' + name);
					}
				}
			}

			setFieldLoop: for (var idx=0; idx<$fields.length; idx++) {

				var $field = $fields.eq(idx);

				if ($field.is('.widgetType') && $field.getCUIWidget() && $field.getCUIWidget().inputValue) {
					$field.getCUIWidget().inputValue(v);
					break setFieldLoop;
				} else if ($field.is('.widgetType') && $field.not($self)[0] && $field.getCUIWidget() && $field.getCUIWidget().setValue) {
					$field.getCUIWidget().setValue(v);
					break setFieldLoop;
				} else {
					CUI.setInputElementValue($field, v, { allow_html: self.options.allow_html });
					if (self._emitChange) {
						self._emitChange($self, no_change_event);
					}
					break setFieldLoop;
				}

			}
			return $fields;
		},

		_emitChange: function($elem, no_change_event) {
			var self = this, $self = this.element;
			$elem = $elem || $self;

			if (!no_change_event) {
				($elem || $self).trigger('change', { non_interactive: true });
			}
		},

		_wrapValue: function (value) {
			// If you just have a simple value, use "return self._wrapValue(myValue)" in your getValue function
			var out = {};
			out[CUI.getElementName(this.element) || this.options.widget_id] = value;
			return out;
		},

		// Overriding this function is not recommended. You probably want to override ._getWidgetValue
		getValue: function() {
			var self = this, value;
			value = this._getWidgetValue(this.element);
			return value;
		},

		// Similar to the "classic" getValue, that only returns a string. Use this when you know your widget is only returning a single value.
		// Returns either the value associated with the element's "name" field, or the "first" element in the object. Used in validation widgets and the like
		// that only work on simple-value widgets. (Note that object order, and the "first" concept are ambiguous in JS, so you should ONLY use this in cases
		// where you know there is only ONE property in the object.)

		getFirstValue: function () {
			var self = this, $self = this.element, name = CUI.getElementName($self), value = self.getValue();
			if (value === undefined) { return undefined; }
			if (!name || !(name in value)) {
				name = CUI.firstKey(value);
				if (!name) { return undefined; } // The object was empty.
			}
			return value[name];
		},

		// Get the value of this widget. This will only be used if the widget class' "value_widget" property is set true, otherwise the name/value of the
		// INPUT field will be used (which is what this does). If you're making a value-bearing widget, you'll probably want to override this method.
		// The fallback method, shown here, just looks for the value of the INPUT control, or the first INPUT control underneath this.

		_getWidgetValue: function () {
			var self = this, $self = this.element, $input;

			if (!self.allow_fallback_getWidgetValue) {
				debugLog('jquery.widget.js Warning: Widget (' + self.options.widget_type + ') is using the fallback _getWidgetValue method. This is probably not intended behavior. If it is, set the "allow_fallback_getWidgetValue" property in the widget definition. -- ', $self);
			}

			if ($self.is(':input')) {
				// $self is the control
				$input = $self;
			} else {
				$input = $self.find(':input');
				if ($input[0]) {
					// $self contains the control
					$input = $input.eq(0);
				} else {
					// Fall back to .text for the value.
					return self._wrapValue($self.text);
				}
			}

			return self._wrapValue(self.sanitize(CUI.getInputElementValue($input)));
		},

		sanitize: function(data) {
			var self = this, $self = this.element;

			if ($.isArray(data)) {
				data = $.extend([], data);
				for (var i=0; i<data.length; i++) {
					// We don't deal with multi-level arrays
					if ($.isArray(data[i])) {
						debugLog('jquery.widget.js: A multi-level array was found in the submission data (', data, ') for a widget. It has been converted with toString, which is probably not what you actually wanted. -- ', $self);
						data[i] = data[i].toString();
					}
					data[i] = self.sanitize(data[i]);
				}
			} else if (data) {
				data = data.toString();
				data = data.replace('&', '&amp;');
				data = data.replace('<', '&lt;');
				data = data.replace('>', '&gt;');
			}

			return data;
		},

		/**
	 * @fn disable()
	 * @brief Disable the widget in the UI.
	 */
		disable: function (key) {
			var self = this, $self = this.element;

			key = key || self.options.widget_id;
			var keys = $self.data('disabled_keys') || {};
			keys[key] = true;
			$self.data('disabled_keys', keys);

			if (!self.options._disabled) {
				self.options._disabled = true;

				$self.attr('disabled', 'disabled');
				$self.addClass('state-disabled');

				self._trigger('Disabled');
				$self.triggerHandler('disabled');
			}
		},

		/**
	 * @fn enable()
	 * @brief Enable the widget in the UI.
	 */
		enable: function (key) {
			var self = this, $self = this.element, keys;

			key = key || (key === false ? 'all' : self.options.widget_id);

			if (key === 'all') {
				// "all" or boolean false are indications to just enable it, damn the consequences
				keys = false;
			} else {
				keys = $self.data('disabled_keys') || {};
				delete keys[key];
				$self.data('disabled_keys', keys);
			}

			if (!keys || $.isEmptyObject(keys)) {
				self.options._disabled = false;

				$self.removeAttr('disabled');
				$self.removeClass('state-disabled');

				self._trigger('Enabled');
				$self.triggerHandler('enabled');
			}
		},

		/**
	 * @fn show()
	 * @brief Show the widget in the UI.
	 */

		show: function() {
			var self = this, $self = this.element;

			$self.show();
			self._trigger('Show');
			$self.trigger('show');
			$self.trigger('stateChange.show');
		},

		/**
	 * @fn hide()
	 * @brief Hide the widget in the UI.
	 */

		hide: function () {
			var self = this, $self = this.element;

			$self.hide();
			self._trigger('Hide');
			$self.trigger('hide');
			$self.trigger('stateChange.hide');
		},

		/**
	 * @fn _getForeignDescendents()
	 * @brief Returns a jQuery object of inputs and widgets that are NOT handled by this widget's own state-setting (enable/disable, etc.) functions.
	 *        In its default state, this works off the manages_own_descendent_state property. If the widget needs to be more discriminating-- if some but
	 *        not all descendents are managed, for instance-- this function should be overridden to only return the unmanaged widgets and unmanaged
	 *        enclosed inputs.
	 */

		_getForeignDescendents: function () {
			var self = this, $self = this.element;
			if (self.manages_own_descendent_state) {
				return $();
			} else {
				var $widgets = $self.findNearest('.widgetType');
				var $inputs  = $self.findNotUnder(':input:not(.managedState)', '.widgetType,.managedState');
				return $widgets.add($inputs);
			}
		},

		/**
	 * @fn setState(state, value, cascade, key)
	 * @brief Central function with an alternate method for changing a function's state (show/hide/enable/disable/etc.). If cascade property is true,
	 *        also runs _setStateCascade to apply the state to all descendent widgets and inputs.
	 * @param state The name of the state to change: enabled, visible, etc. (Your "etc" may vary)
	 * @param value The truth or literal value to set the state to.
	 * @param cascade Whether or not to apply this state change to all children, recursively
	 * @param key A key used by some states to lock the state until all keys are cleared (for instance, a control will not enable until all disable keys
	 *            have been re-enabled. This key is set on all cascaded elements. Can also be set to literal true to use the current widget's ID.
	 */

		setState: function (state, value, cascade, key) {
			var self = this, $self = this.element;
			if (key === true) { key = self.options.widget_id; }

			if (cascade) {
				// If it's a cascade, setStateCascade will call the setState.
				self.setStateCascade(state, value, key);
				return;
			}

			self._setSelfState(state, value, key);
		},

		_setSelfState: function (state, value, key) {
			// _setSelfState can be overridden if the basic reaction to the state change needs to be different
			// Remember: Additional states should also be reflected in the section of cascadeStateChange below relating to non-widgetized inputs.

			var self = this, $self = this.element;

			$self.addClass('state-' + state + (value ? '-true' : '-false')).removeClass('state-' + state + (value ? '-false' : '-true'));

			switch (state) {
				case 'visible':
					self[value ? 'show' : 'hide'](key);
					break;
				case 'enabled':
					self[value ? 'enable' : 'disable'](key);
					break;
				default:
					debugLog('jquery.widget.js: Improper state change type "' + state + '" requested. -- ', $self);
					break;
			}
		},

		/*
	 * @fn _setInputInvalid($element [, message])
	 * @brief Set a given element as "invalid"
	 *
	 * DO NOT REPLACE IN CHILD
	 */
		_setInputInvalid: function ($element, message, key) {
			CUI.setValid($element, message, key || 'SELF_WIDGET');
			$element.trigger('stateChange');
		},

		/*
	 * @fn _setInputValid($element)
	 * @brief Set a given element as valid.
	 *
	 * DO NOT REPLACE IN CHILD
	 */
		_setInputValid: function ($element, key) {
			CUI.setInvalid($element, key || 'SELF_WIDGET');
			$element.trigger('stateChange');
		},


		/**
	 * @fn setStateCascade(state, value, key)
	 * @brief Change the state of the widget and its descendents (widgets and inputs). Only applies to inputs for some specific states.
	 */

		setStateCascade: function (state, value, key) {
			var self = this, $self = this.element;
			if (key === true) { key = self.options.widget_id; }

			// First things first-- actually set the state of this widget.
			self.setState(state, value, false, key);

			// Get an object full of non-managed immediate descendent widgets and inputs
			var $descendents = self._getForeignDescendents();

			// Cascade the state down to descendent widgets
			$descendents.filter('.widgetType').each( function () {
				var widget = $(this).getCUIWidget();
				widget.setStateCascade.call(widget, state, value, key);
			});

			// Cascade the state down to descendent inputs
			var $inputs = $descendents.not('.widgetType');

			if ($inputs[0]) {
				switch (state) {
					case 'visible':
						$inputs[value ? 'show' : 'hide']();
						break;
					case 'enabled':
						$inputs[value ? 'enable' : 'disable'](key);
						break;

						// If further states are added that cannot be reflected in non-widget inputs, add empty case statements here so you don't get the warning.

					default:
						debugLog('jquery.widget.js: Custom state change "' + state + '" cannot be applied to non-widget input. -- ', $self);
				}
			}

		}

	}); // END OF WIDGET

	add_widget('widget', 'ui.widget', 'widget', widget);
})(jQuery);


// -- Based on jquery.widget.js template, rev. 2011/04/08
