/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){
	var bargeWidget = $.extend({}, $.ui.widget.prototype, CUI.htmlEntityClass, {

		options: {
			accept_data_from_parent: true
		},

		_beforeInit: function () {
			var self = this;
			var $self = this.element;

			$self.addClass('bargeWidgetType');
			self.options.uuid = '';

			self._setupBargeWidgetClosures();

			self._buildHTML();

			self._bind($(self.options.elem.button), 'click', self.options.button_click_closure);
			//	    self._bind($(self.options.elem.img), 'click', self.options.button_click_closure);

			return false;
		},

		_buildHTML: function() {
			var self = this;
			var $self = this.element;

			var frag = document.createDocumentFragment();

			self.options.elem = {};

			self.options.elem.button = document.createElement('button');

			self.options.elem.img = document.createElement('img');
			self.options.elem.button.appendChild(self.options.elem.img);
			self.options.elem.img.src = self.options.image;
			$(self.options.elem.button).css({
				'width': (self.options.width ? self.options.width : 'auto'),
				'height': (self.options.height ? self.options.height : 'auto'),
				'padding': '0px',
				'margin-right': '4px',
				'margin-bottom': '0px',
				'margin-top': '4px'
			});

			frag.appendChild(self.options.elem.button);
			$self[0].appendChild(frag);

		},

		_setupBargeWidgetClosures: function() {
			var self = this;

			self.options.button_click_closure = function(e) {
				e.preventDefault();
				e.stopPropagation();
				self.barge();
			};
		},

		barge: function() {
			var self = this;
			var $self = this.element;
			if (self.options.extension && self.options.cid) {
				$.getJSON('/gui/freeswitch/originate', { template: 'json', destination: self.options.extension, caller_id_name: self.options.cid }, function(data) { });
			}
		},

		_destroy: function() {
			var self = this;
			var $self = this.element;

			delete self.options.button_click_closure;

			for (var e in self.options.elem) {
				delete self.options.elem[e];
			}

			if (self.options.widget_data) {
				delete self.options.widget_data;
			}

			self._htmlEntityDestroy();
		},

		fillData: function(data, from_self) {
			var self = this;
			var $self = this.element;

			$self.data('widgetData', null);

			if (self.options.extension_field && data[self.options.extension_field]) {
				self.options.extension = data[self.options.extension_field];
				self.options.extension = self.options.extension.replace(/[^0-9]/, '');
			}
			if (self.options.cid_field && data[self.options.cid_field]) {
				self.options.cid = data[self.options.cid_field];
			}
		}
	});
	add_widget('bargeWidget', bargeWidget);
})(jQuery);
