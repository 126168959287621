/* jshint jquery: true, browser: true, unused: vars */
/* global escape, unescape, format_information, loginData, CurrentUser */
/* exported console_log, console_log_debounced, getXSRFKey, cmp, getHeight, titleCase, parseIntNaNChar, debugAlert, debugLog, macFormat, sortObjectArray, optLc, strReplaceAll, getDimensionOffsets, setCookie, getCookie, eraseCookie, getHashParam, setHashParam, clearHashParam, formatCID, unixTime, hhmmss, uniqueArray, format_age, trueish, stringContains, entity, debugVar, isObjectEmpty, getObjectLength, tryDecodeURIComponent, checkPermissions, cmp_version, left_pad */
/**
 * @author Rudy Fleminger
 */

var console_log_debounced;

if(typeof String.prototype.trim !== 'function') {
	String.prototype.trim = function() {
		return this.replace(/^\s+|\s+$/g, '');
	};
}

(function () {
	var logs = {}, lines = {}, timers = {}, debounce_time = 1000;

	console_log_debounced = function (key) {
		var args = Array.prototype.slice.apply(arguments);
		args = args.slice(1);

		logs[key] = logs[key] || [];
		lines[key] = lines[key] || 0;

		logs[key] = logs[key].concat(args, ['\n']);
		lines[key]++;

		if (!timers[key]) {
			timers[key] = setTimeout(function () {
				delete timers[key];
				console_log.apply(window, ['Compiled console log (' + lines[key] + ' entries):\n'].concat(logs[key]));
				delete logs[key];
				delete lines[key];
			}, debounce_time);
		}
	};
})();

function console_log() {
	try {
		if (navigator.userAgent.indexOf('MSIE 8') !== -1) {
			var args = Array.prototype.slice.apply(arguments), a_idx, out = [];

			for (a_idx = 0; a_idx < args.length; a_idx++) {
				switch (args[a_idx]) {
					case undefined:
						out.push('<<undefined>>');
						break;
					case null:
						out.push('<<null>>');
						break;
					default:
						switch (typeof args[a_idx]) {
							case 'function':
								out.push('[Function object]');
								break;
							case 'object':
								try {
									out.push(JSON.stringify(args[a_idx]));
								} catch(e) {
									out.push(Object.toString.prototype.apply(args[a_idx]));
								}
								break;
							default:
								out.push(args[a_idx].toString());
								break;
						}
				}
			}

			console.log(out.join(' '));
		} else {
			console.log.apply(window.console, arguments);
		}
	} catch (err) {}
}

function getSessionID() {
	return getCookie('bps_session');
}


function getXSRFKey() {
	return getCookie('xsrfkey');
}

function cmp(a,b) {
	return a < b ? -1 : a > b ? 1 : 0;
}

function getHeight(elem) {
	var $elem;
	if (!(elem instanceof jQuery)) {
		$elem = $(elem);
	} else {
		$elem = elem;
	}

	/* Create a variable to store states and make a closure for it which recursively
     * traverses the DOM backwards and switches parent nodes to visible so the
     * browsers will set the height of the element we are trying to compute the
     * height for.
     */
	var nodes = [];
	var makeVisible = function(node) {
		var parent = node.parentNode;
		var $parent = $(parent);
		var display = $parent.css('display');
		if (display == 'none')
		{
			/* Cache the node and it's previous state so we can restore it */
			nodes.push({
				'node': parent,
				'display': display,
				'position': $parent.css('position'),
				'visibility': $parent.css('visibility')
			});
			parent.style.position = 'absolute';
			parent.style.visibility = 'hidden';
			parent.style.display = 'block';
		}


		if (parent.nodeName != "BODY")
		{
			makeVisible(parent);
		}
	};
	/******************* End of Closure ***************************/

	makeVisible($elem[0]);
	var height = $elem.height();

	for (var i = 0, len = nodes.length; i < len; ++i)
	{
		nodes[i].node.style.position = nodes[i].position;
		nodes[i].node.style.visibility = nodes[i].visibility;
		nodes[i].node.style.display = nodes[i].display;
	}
	nodes.splice(0, nodes.length);

	return height;
}

// SIMPLE title case conversion
function titleCase(string, params) {
	var parts, commonAbbrs;
	params = params || {};
	parts = string.split(' ');
	commonAbbrs = {'AL':true,'AK':true,'AS':true,'AZ':true,'AR':true,'CA':true,'CO':true,'CT':true,'DE':true,'DC':true,'FM':true,'FL':true,'GA':true,'GU':true,'HI':true,'ID':true,'IL':true,'IN':true,'IA':true,'KS':true,'KY':true,'LA':true,'ME':true,'MH':true,'MD':true,'MA':true,'MI':true,'MN':true,'MS':true,'MO':true,'MT':true,'NE':true,'NV':true,'NH':true,'NJ':true,'NM':true,'NY':true,'NC':true,'ND':true,'MP':true,'OH':true,'OK':true,'OR':true,'PW':true,'PA':true,'PR':true,'RI':true,'SC':true,'SD':true,'TN':true,'TX':true,'UT':true,'VT':true,'VI':true,'VA':true,'WA':true,'WV':true,'WI':true,'WY':true,'USA':true};


	for (var i in parts) {
		if (!(params.ignoreAbbr && commonAbbrs[parts[i]])) {
			parts[i] = parts[i].toUpperCase().substr(0,1) + parts[i].toLowerCase().substr(1,parts[i].length);
		}
	}

	return parts.join(' ');
}

////////// parseInt with fallback value
function parseIntNaNChar(number, fallback)
{
	number = parseInt(number);
	return (isNaN(number)?fallback:number);
}


function debugAlert(mesg)
{
	if (location.search.search(/js_debug/) || location.search.search(/uncompiled_js/)) {
		alert(mesg);
	}
}

function debugLog(mesg, stack_trace)
{
	var debug_log_regexp = /js_debug|uncompiled_js/;
	var args = Array.prototype.slice.call(arguments);
	if (debug_log_regexp.test(location.search)) {
		var stack_regexp = /stack_trace/;
		if (stack_regexp.test(location.search) || (typeof stack_trace === 'boolean' && stack_trace)) {
			if (typeof stack_trace === 'boolean' && stack_trace) {
				args.splice(1, 1);
			}
			var stack = new Error(mesg).stack;
			console_log.apply(this, args);
			console_log(stack);
		} else {
			console_log.apply(this, args);
		}
	}
}


////////// MAC address formatting
function macFormat(macAdds)
{
	if (macAdds.search(/^[0-9a-fA-F]{12}$/) > -1) {
		return macAdds.replace(/([0-9a-fA-F]{2})/g, '$1:').replace(/:$/,'');
	} else {
		return macAdds;
	}
}


////////// Sort an array of objects by a given key

function sortObjectArray(arr, key)
{
	return arr.sort(function (a,b) {
		if (a[key] && b[key]) {
			if (optLc(a[key]) == optLc(b[key])) {
				return 0;
			}
			else {
				return (optLc(a[key]) > optLc(b[key])) ? 1 : -1;
			}
		}
		else {
			if (!(a[key] || b[key])) {
				return 0;
			}
			else {
				return (a[key])?1:-1;
			}
		}
	});
}

function optLc(val)
{
	if (typeof(val.toLowerCase) == 'function') {
		return val.toLowerCase();
	}
	else {
		return val;
	}
}

////////// Assign hotkey to button

$.fn.hotkey = function (keycode, button) {
	$(this).bind('keyup', function (e) {
		if (e.keyCode == keycode) {
			$(button).trigger('click');
		}
	});
};

////////// String-based global replace, escaping regex characters
function strReplaceAll(haystack, needle, replace) {
	var rxNeedle = new RegExp(needle.replace(/([\[\]<\>\?\.\^\$\{\}])/g, '\\$1'),'g');
	return haystack.replace(rxNeedle, replace);
}

////////// Top/Left offsets for items with abs/rel parents

$.fn.getDimensionOffsets = function () {
	var el = this.eq(0);
	var offTop = $(el).parents().filter(function () { return $(this).css('position').match(/(relative|absolute|fixed)/); }).eq(0).offset().top;
	var offLeft = $(el).parents().filter(function () { return $(this).css('position').match(/(relative|absolute|fixed)/); }).eq(0).offset().left;
	return {top:offTop, left:offLeft};
};


////////// Cookie Handling

// From quirksmode.org, slightly modified __________________________________________________________________

function setCookie(name,value,days) {
	var expires;
	if (days) {
		var date = new Date();
		date.setTime(date.getTime()+(days*24*60*60*1000));
		expires = "; expires="+date.toGMTString();
	} else {
		expires = "";
	}
	document.cookie = name+"="+value+expires+"; path=/";
}

function getCookie(name) {
	var nameEQ = name + "=";
	var ca = document.cookie.split(';');
	for(var i=0;i < ca.length;i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ') { c = c.substring(1,c.length); }
		if (c.indexOf(nameEQ) === 0) { return c.substring(nameEQ.length,c.length); }
	}
	return null;
}

function eraseCookie(name) {
	setCookie(name,"",-1);
}

/* Hash-param handling */

function getHashParam(name) {
	var amendedHash, matched, value, valueArray, i;

	amendedHash = location.hash;
	matched = amendedHash.match( new RegExp( '[#;]' + escape(name) + '=([^;]+)' ) );

	if (matched) {
		value = matched[1];

		if (value.search(/\|/) != -1) {
			valueArray = value.split('|');
			for (i in valueArray) {
				valueArray[i] = unescape(valueArray[i]);
			}
			return valueArray;
		} else {
			return unescape(value);
		}
	} else {
		return null;
	}
}

function setHashParam(name, value, silent) {
	if (typeof value == 'object') {
		for (var i in value) {
			value[i] = escape(value[i]);
		}
		value = value.join('|');
	} else {
		value = escape(value).replace(/%7C/ig, '|');
	}

	var hash = location.hash;

	if (getHashParam(name) === null) {
		hash = '#' + escape(name) + '=' + value + ';' + hash.replace(/^#/,'');
	} else {
		hash = hash.replace( new RegExp( '([#;])' + escape(name) + '=[^;]*' ), '$1' + escape(name) + '=' + escape(value) );
	}

	if (silent) {
		location.replace(location.href.replace(/(#.*|$)/, hash));
	} else {
		location.hash = hash;
	}
}

function clearHashParam(name, silent) {
	var hash = location.hash.replace( new RegExp( '([#;])' + escape(name) + '=([^;]+;*)', 'g' ), '$1' );

	if (silent) {
		location.replace(location.href.replace(/(#.*|$)/, hash));
	} else {
		location.hash = hash;
	}
}

function formatCID(name, number, asArray) {
	var result;
	if (!number) {
		number = '(Unknown)';
	}
	if (!name || name === 'Unknown' || name === '' || (unescape(name).replace(/[^0-9a-zA-Z]/g, '') === unescape(number).replace(/[^0-9]/g, ''))) {
		result = ["", format_information(unescape(number))];
	} else {
		result = [unescape(titleCase(name, {ignoreAbbr: true})) , format_information(unescape(number)).replace(/ /g, '\u00a0').replace(/-/g,'\u2011')]; // 00a0 = nbsp, 2011 = nbhyphen
	}
	if (asArray) {
		return result;
	} else {
		if (result[0]) {
			return result.join(', ');
		} else {
			return result[1];
		}
	}
}

// Current time in seconds since unix epoch, not microseconds
function unixTime() {
	return Math.round(new Date().getTime() / 1000);
}

function hhmmss(totalSec) {
	var h,m,s;
    totalSec = Math.round(totalSec);
	h = Math.floor(totalSec / 3600) % 24;
	m = Math.floor(totalSec / 60) % 60;
	s = totalSec % 60;
	return ([h, left_pad(m, 2), left_pad(s, 2)].join(':'));
}


// uniqueArray -- remove duplicates from an array - returned array is sorted, original array is unchanged
// based on code found at http://www.martienus.com/code/javascript-remove-duplicates-from-array.html
function uniqueArray(a) {
	var b = a.slice(0); // clone the array
	b.sort();
	for (var i = 1; i < b.length; ) {
		if (b[i-1] == b[i]) {
			b.splice(i, 1);
		} else {
			i++;
		}
	}
	return b;
}

// Format a timestamp in the past
function format_age(age_secs, params) {
	params = params || {};

	var day = 86400;
	var then = new Date().add({ seconds: -age_secs });
	var moys = ['January','February','March','April','May','June','July','August','September','October','November','December'];
	var dows = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'];

	var output = '';

	var time_ampm = function (dateObj) {
		var hours = dateObj.getHours();
		var ampm = 'AM';
		if (hours === 0) {
			hours = 12;
			ampm = 'AM';
		} else if (hours > 12) {
			hours -= 12;
			ampm = 'PM';
		} else if (hours == 12) {
			ampm = 'PM';
		}
		return [hours, left_pad(dateObj.getMinutes().toString(), 2)].join(':') + ampm;
	};


	if (params.longForm) {
		output = dows[then.getDay()] + ' ' + moys[then.getMonth()] + ' ' + then.getDate();

		if (then.getFullYear() < (new Date()).getFullYear()) {
			output += ', ' + then.getFullYear();
		}
		output += ', ' + time_ampm(then);

		return output; // END ROUTINE
	}

	if (age_secs > day * 14) {
		// More than 2 wks old: Only show the date
		output = moys[then.getMonth()] + ' ' + then.getDate();
		if (then.getFullYear() < (new Date()).getFullYear()) {
			output += ', ' + then.getFullYear();
		}
	} else if (age_secs >= day * 7) {
		// 1-2 Weeks: Date and Time
		output = moys[then.getMonth()] + ' ' + then.getDate() + ', ' + time_ampm(then);
	} else if ((new Date()).getDay() != then.getDay()) {
		// 1-7 Days: Day and Time
		var dayname = (then.getDay() == (new Date()).getDay() - 1) ? 'Yesterday' : dows[then.getDay()];
		output = dayname + ', ' + time_ampm(then);
	} else {
		// Today: Show time
		output = time_ampm(then);
	}

	return output;
}

function trueish(what, params) {
	what = what || false;
	params = params || {};
	if (params.allowStringFalse && (what.toString().toLowerCase() === 'false' || what.toString().toLowerCase() === 'f')) {
		return false;
	}

	// Returns false on "0" string
	return (!what || what == '0') ? false : what;
}

var getUnique;
(function () {
	var uniqueValue = 0;
	getUnique = function (prefix, suffix) { /* GLOBAL */
		// Gets a number unique to this page load *BUT NOT UNIQUE TO ANYTHING ELSE!*
		return (prefix || '') + (++uniqueValue) + (suffix || '');
	};
})();

function stringContains(needle, hs) {
	return hs.indexOf(needle) === -1 ? false : true;
}

var entity = {
	'quot':	'"',
	'amp':	'&',
	'apos':	"'",
	'lt':	'<',
	'gt':	'>',
	'nbsp':	'\u00A0',
	'iexcl':	'\u00A1',
	'cent':	'\u00A2',
	'pound':	'\u00A3',
	'curren':	'\u00A4',
	'yen':	'\u00A5',
	'brvbar':	'\u00A6',
	'sect':	'\u00A7',
	'uml':	'\u00A8',
	'copy':	'\u00A9',
	'ordf':	'\u00AA',
	'laquo':	'\u00AB',
	'not':	'\u00AC',
	'shy':	'\u00AD',
	'reg':	'\u00AE',
	'macr':	'\u00AF',
	'deg':	'\u00B0',
	'plusmn':	'\u00B1',
	'sup2':	'\u00B2',
	'sup3':	'\u00B3',
	'acute':	'\u00B4',
	'micro':	'\u00B5',
	'para':	'\u00B6',
	'middot':	'\u00B7',
	'cedil':	'\u00B8',
	'sup1':	'\u00B9',
	'ordm':	'\u00BA',
	'raquo':	'\u00BB',
	'frac14':	'\u00BC',
	'frac12':	'\u00BD',
	'frac34':	'\u00BE',
	'iquest':	'\u00BF',
	'Agrave':	'\u00C0',
	'Aacute':	'\u00C1',
	'Acirc':	'\u00C2',
	'Atilde':	'\u00C3',
	'Auml':	'\u00C4',
	'Aring':	'\u00C5',
	'AElig':	'\u00C6',
	'Ccedil':	'\u00C7',
	'Egrave':	'\u00C8',
	'Eacute':	'\u00C9',
	'Ecirc':	'\u00CA',
	'Euml':	'\u00CB',
	'Igrave':	'\u00CC',
	'Iacute':	'\u00CD',
	'Icirc':	'\u00CE',
	'Iuml':	'\u00CF',
	'ETH':	'\u00D0',
	'Ntilde':	'\u00D1',
	'Ograve':	'\u00D2',
	'Oacute':	'\u00D3',
	'Ocirc':	'\u00D4',
	'Otilde':	'\u00D5',
	'Ouml':	'\u00D6',
	'times':	'\u00D7',
	'Oslash':	'\u00D8',
	'Ugrave':	'\u00D9',
	'Uacute':	'\u00DA',
	'Ucirc':	'\u00DB',
	'Uuml':	'\u00DC',
	'Yacute':	'\u00DD',
	'THORN':	'\u00DE',
	'szlig':	'\u00DF',
	'agrave':	'\u00E0',
	'aacute':	'\u00E1',
	'acirc':	'\u00E2',
	'atilde':	'\u00E3',
	'auml':	'\u00E4',
	'aring':	'\u00E5',
	'aelig':	'\u00E6',
	'ccedil':	'\u00E7',
	'egrave':	'\u00E8',
	'eacute':	'\u00E9',
	'ecirc':	'\u00EA',
	'euml':	'\u00EB',
	'igrave':	'\u00EC',
	'iacute':	'\u00ED',
	'icirc':	'\u00EE',
	'iuml':	'\u00EF',
	'eth':	'\u00F0',
	'ntilde':	'\u00F1',
	'ograve':	'\u00F2',
	'oacute':	'\u00F3',
	'ocirc':	'\u00F4',
	'otilde':	'\u00F5',
	'ouml':	'\u00F6',
	'divide':	'\u00F7',
	'oslash':	'\u00F8',
	'ugrave':	'\u00F9',
	'uacute':	'\u00FA',
	'ucirc':	'\u00FB',
	'uuml':	'\u00FC',
	'yacute':	'\u00FD',
	'thorn':	'\u00FE',
	'yuml':	'\u00FF',
	'OElig':	'\u0152',
	'oelig':	'\u0153',
	'Scaron':	'\u0160',
	'scaron':	'\u0161',
	'Yuml':	'\u0178',
	'fnof':	'\u0192',
	'circ':	'\u02C6',
	'tilde':	'\u02DC',
	'Alpha':	'\u0391',
	'Beta':	'\u0392',
	'Gamma':	'\u0393',
	'Delta':	'\u0394',
	'Epsilon':	'\u0395',
	'Zeta':	'\u0396',
	'Eta':	'\u0397',
	'Theta':	'\u0398',
	'Iota':	'\u0399',
	'Kappa':	'\u039A',
	'Lambda':	'\u039B',
	'Mu':	'\u039C',
	'Nu':	'\u039D',
	'Xi':	'\u039E',
	'Omicron':	'\u039F',
	'Pi':	'\u03A0',
	'Rho':	'\u03A1',
	'Sigma':	'\u03A3',
	'Tau':	'\u03A4',
	'Upsilon':	'\u03A5',
	'Phi':	'\u03A6',
	'Chi':	'\u03A7',
	'Psi':	'\u03A8',
	'Omega':	'\u03A9',
	'alpha':	'\u03B1',
	'beta':	'\u03B2',
	'gamma':	'\u03B3',
	'delta':	'\u03B4',
	'epsilon':	'\u03B5',
	'zeta':	'\u03B6',
	'eta':	'\u03B7',
	'theta':	'\u03B8',
	'iota':	'\u03B9',
	'kappa':	'\u03BA',
	'lambda':	'\u03BB',
	'mu':	'\u03BC',
	'nu':	'\u03BD',
	'xi':	'\u03BE',
	'omicron':	'\u03BF',
	'pi':	'\u03C0',
	'rho':	'\u03C1',
	'sigmaf':	'\u03C2',
	'sigma':	'\u03C3',
	'tau':	'\u03C4',
	'upsilon':	'\u03C5',
	'phi':	'\u03C6',
	'chi':	'\u03C7',
	'psi':	'\u03C8',
	'omega':	'\u03C9',
	'thetasym':	'\u03D1',
	'upsih':	'\u03D2',
	'piv':	'\u03D6',
	'ensp':	'\u2002',
	'emsp':	'\u2003',
	'thinsp':	'\u2009',
	'zwnj':	'\u200C',
	'zwj':	'\u200D',
	'lrm':	'\u200E',
	'rlm':	'\u200F',
	'ndash':	'\u2013',
	'mdash':	'\u2014',
	'lsquo':	'\u2018',
	'rsquo':	'\u2019',
	'sbquo':	'\u201A',
	'ldquo':	'\u201C',
	'rdquo':	'\u201D',
	'bdquo':	'\u201E',
	'dagger':	'\u2020',
	'Dagger':	'\u2021',
	'bull':	'\u2022',
	'hellip':	'\u2026',
	'permil':	'\u2030',
	'prime':	'\u2032',
	'Prime':	'\u2033',
	'lsaquo':	'\u2039',
	'rsaquo':	'\u203A',
	'oline':	'\u203E',
	'frasl':	'\u2044',
	'euro':	'\u20AC',
	'image':	'\u2111',
	'weierp':	'\u2118',
	'real':	'\u211C',
	'trade':	'\u2122',
	'alefsym':	'\u2135',
	'larr':	'\u2190',
	'uarr':	'\u2191',
	'rarr':	'\u2192',
	'darr':	'\u2193',
	'harr':	'\u2194',
	'crarr':	'\u21B5',
	'lArr':	'\u21D0',
	'uArr':	'\u21D1',
	'rArr':	'\u21D2',
	'dArr':	'\u21D3',
	'hArr':	'\u21D4',
	'forall':	'\u2200',
	'part':	'\u2202',
	'exist':	'\u2203',
	'empty':	'\u2205',
	'nabla':	'\u2207',
	'isin':	'\u2208',
	'notin':	'\u2209',
	'ni':	'\u220B',
	'prod':	'\u220F',
	'sum':	'\u2211',
	'minus':	'\u2212',
	'lowast':	'\u2217',
	'radic':	'\u221A',
	'prop':	'\u221D',
	'infin':	'\u221E',
	'ang':	'\u2220',
	'and':	'\u2227',
	'or':	'\u2228',
	'cap':	'\u2229',
	'cup':	'\u222A',
	'int':	'\u222B',
	'there4':	'\u2234',
	'sim':	'\u223C',
	'cong':	'\u2245',
	'asymp':	'\u2248',
	'ne':	'\u2260',
	'equiv':	'\u2261',
	'le':	'\u2264',
	'ge':	'\u2265',
	'sub':	'\u2282',
	'sup':	'\u2283',
	'nsub':	'\u2284',
	'sube':	'\u2286',
	'supe':	'\u2287',
	'oplus':	'\u2295',
	'otimes':	'\u2297',
	'perp':	'\u22A5',
	'sdot':	'\u22C5',
	'lceil':	'\u2308',
	'rceil':	'\u2309',
	'lfloor':	'\u230A',
	'rfloor':	'\u230B',
	'lang':	'\u27E8',
	'rang':	'\u27E9',
	'loz':	'\u25CA',
	'spades':	'\u2660',
	'clubs':	'\u2663',
	'hearts':	'\u2665',
	'diams':	'\u2666'
};

function debugVar(thing) {
	return (typeof thing === 'object') ? $.extend(true, {}, thing) : thing;
}


// Check to see if {} base object is empty.
function isObjectEmpty(obj) {
	var k;
	for (k in obj) {
		return false;
	}
	return true;
}

function getObjectLength(obj) {
	var count = 0, k;
	for (k in obj) {
		++count;
	}
	return count;
}

// decodeURIComponent blows up badly if you pass it a malformed percent-code, so this just wraps it in a try/catch for use when we have ambiguous data.
function tryDecodeURIComponent(encoded) {
	var decoded;
	try {
		decoded = decodeURIComponent(encoded);
	} catch (e) {

	}

	return (decoded === undefined ? encoded : decoded);
}

function checkPermissions(permission_array, value_when_no_permissions) {
	if (!$.isArray(permission_array)) {
		return (value_when_no_permissions || false);
	}

	for (var p in permission_array) {
		var perm = permission_array[p];
		var invert = false;

		if (perm.charAt(0) === '!') {
			invert = true;
			perm = perm.slice(1);
		}

		if (perm.charAt(0) === '$') {
			switch (perm) {
				case '$model_170':
				case '$model_270':
				case '$model_370':
				case '$model_470':
				case '$model_670':
					var model = perm.match(/(\d+)$/)[1];
					if ((loginData.model.indexOf(model) === 0) === invert) { return false; }
					break;
				case '$model_b':
					if ((loginData.model.indexOf('b') !== -1) === invert) { return false; }
					break;
				case '$demo':
					// JSHint complains about "!!", so it's ignored...
					if (!!loginData.demo === invert) { return false; } // jshint ignore: line
					break;
					case '$virtual':
						if (!!loginData.virtual === invert) { return false; } 
					break;
				default:
					if (!invert) { return false; }
					break;
			}
		} else {
			if ((perm in CurrentUser.permissions) === invert) { return false; }
		}
	}

	return true;
}

function cmp_version(a, b) {
	var as = a.split('.'), bs = b.split('.'), len = (as.length > bs.length) ? as.length : bs.length, idx, ae, be;
	for (idx = 0; idx < len; idx++) {
		if (!(idx in as)) {
			return -1;
		}

		if (!(idx in bs)) {
			return 1;
		}

		ae = parseInt(as[idx], 10);
		be = parseInt(bs[idx], 10);

		if (ae !== be) {
			return ((ae < be) ? -1 : 1);
		}
	}

	return 0;
}

function left_pad(value, length, fill) {
	var repeats;
	value = value.toString();
	if (value.length >= length) { return value; }
	fill = (fill === undefined ? '0' : fill).toString();
	repeats = Math.ceil((length - value.length) / fill.length);
	return ((String.prototype.repeat ? fill.repeat(repeats) : (new Array(repeats+1)).join(fill)) + value).slice(-length);
}
