/* This screen-def provides both the tdm_digital_detail and the provisioned_digital_detail screens. (See the function calls at the bottom.) */

(function () {
    var register_digital_screen = function (screen_name, prefix, rest_controller, include_troubleshooting_button) {
	var gw_prefix = '';
	if (prefix === 'bbx_provider_gateway') { gw_prefix = 'bbx_provider_gateway_'; }

	register_screen(screen_name, {
	    elements: [
		{ entity_template: 'page_title', field: prefix + '_name', rest: rest_controller },
		{ entity: "div", "attributes":{"class": "page_messages"} },
		{ entity_template: 'page_buttons',
		  buttons: [].concat(include_troubleshooting_button ? [{
		      type: 'overlay',
		      overlay: 'tdm_troubleshooting',
		      pass_params: [prefix + '_id'],
		      text: 'Troubleshooting Information'
		  }] : [], [{ type: 'cancel' }, { type: 'save' }])
		},
		{
		    entity_template: "page_content", elements: [
			{
			    entity_template: 'form',
			    no_initial_get: false,
			    confirm: { title: 'Change Port Configuration', text: 'Changing the port configuration requires restarting the Telephony Engine. All current calls on all interfaces will end, and the Hardware Providers list will be unavailable while the restart is in progress.' },
			    dataEvents: { trigger: 'hardware_external' },
			    action: rest_controller,
			    elements: [
				{
				    entity_template: 'page_module',
				    legend: 'Port Settings',
				    buttons: ['help'],
				    help_id: 'digital_port_settings',
				    elements: [
					{
					    entity_template: 'input_item',
					    type: 'select',
					    title: 'Signal Type:',
					    input_name: prefix + '_signal_type',
					    select_options: [
						{ value: 'euroisdn', text: 'Euro ISDN' },
						{ value: '5ess', text: 'Lucent 5ESS' },
						{ value: 'national', text: 'National ISDN 2' },
						{ value: 'dms100', text: 'Nortel DMS 100' }
					    ]
					},
					{
					    entity_template: 'input_item',
					    type: 'select',
					    title: 'PRI Signalling:',
					    input_name: 'signalling',
					    select_options: [
						{ value: 'pri_cpe', text: 'CPE Side' },
						{ value: 'pri_net', text: 'Network Side' }
					    ],
					    info: 'Most installations use CPE Side. Change this setting only if instructed by your provider.'
					},
					{
					    entity_template: 'input_item',
					    type: 'select',
					    title: 'Clock Source:',
					    input_name: (prefix === 'bbx_provider_gateway' ? 'bbx_provider_gateway_clock_source' : 'bbx_tdm_card_port_te_clock'),
					    select_options: [
						{ value: 'NORMAL', text: 'Normal' },
						{ value: 'MASTER', text: 'Master' }
					    ],
					    info: 'Most installations use Normal. Change this setting only if instructed by your provider.'
					},
					{
					    entity_template: 'input_item',
					    type: 'select',
					    title: 'Channel Hunt Order:',
					    input_name: gw_prefix + 'hunt_order',
					    select_options: [
						{ value: 'a', text: 'Ascending' },
						{ value: 'A', text: 'Descending' },
						{ value: 'R', text: 'Round Robin Decending' },
						{ value: 'r', text: 'Round Robin Ascending' }
					    ],
					    info: 'Default is Ascending. Change this setting only if instructed by your provider.'
					},
					{
					    entity_template: 'data_item',
					    title: 'Channels:',
					    elements: [
						{
						    entity: 'div',
						    widgets: [ 'tdmChannelWidget' ],
						    widget_options: { tdmChannelWidget: {
							signal_selector: 'select[name=' + prefix + '_signal_type]'
						    }},
						    attributes: { name: 'tdm_channels' }
						}
					    ]
					}
				    ] // END page_module 1 elements
				}, // END page_module 1

				{
				    entity_template: 'page_module',
				    legend: 'Services',
				    buttons: ['help'],
				    help_id: 'digital_port_services',
				    elements: [
					{
					    entity_template: 'input_item',
					    title: 'Use for Fax Transmission:',
					    type: 'checkbox',
					    input_name: 'outbound_fax'
					},
					{
					    entity_template: 'data_item',
					    title: 'Use T.38 Reinvite on Fax Transmission:',
					    conditional_display: !!gw_prefix, // Only show on Provisioned Ports screen
					    elements: [
						{
						    entity: 'input',
						    attributes: { type: 'checkbox', name: 'fax_enable_t38_request' },
						    widgets: ['aopbStateWidget'],
						    widget_options: { aopbStateWidget: {
							states: [{
							    conditions: { a: '$outbound_fax', op: '==', b: '_TRUEISH_' },
							    actions: { enabled: true },
							    else_actions: { enabled: false }
							}]
						    }}
						}
					    ]
					}
				    ]
				}, // END page module 2
				{
				    entity_template: 'page_module',
				    legend: 'Port Status',
				    buttons: ['help'],
				    help_id: 'analog_port_status',
				    elements: [
					{
					    entity_template: 'data_item',
					    title: 'Port Status:',
					    elements: [
						{
						    entity_template: 'hardware_port_status'
						}
					    ]
					}
				    ]
				},
				elgen('cid_selector', { type: prefix + '', buttons: ['help'], help_id: 'providers_caller_id' }) // CID page_module via element generator
			    ] // END form 1 elements
			}, // END form 1
			{
			    entity_template: 'container',
			    elements: elgen('did_page_modules') // DID form via element generator
			}
		    ] // END page_content elements
		} // END page_content
	    ] // END template elements
	}); // END template
    }; // END register_digital_screen function

    register_digital_screen('tdm_digital_detail', 'bbx_tdm_card_port', '/gui/gateway/hardware', true);
    register_digital_screen('provisioned_digital_detail', 'bbx_provider_gateway', '/gui/gateway/sip', false);

})(); // END scoping function
