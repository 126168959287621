/*
  dhmWidget: A widget that shows a minutes value in days/hours/minutes format
*/

(function( $ ){
    var dhmWidget = $.extend(true, {}, $.ui.widget.prototype, {
		set_value_widget: true,
		options: {
			template: false,
			template_selector: false,
			minute_multiplier: 60 // Incoming value is in seconds
		},

		setValue: function (v) {
			var self = this, $self = this.element, d, h, m, dhm = [];
			v = Number(v);
			v = Math.floor(v / (self.options.minute_multiplier || 1));

			d = Math.floor(v/1440);
			h = Math.floor((v - 1440 * d)/60);
			m = v % 60;
			if (d) { dhm.push(d + ' days'); }
			if (h) { dhm.push(h + ' hours'); }
			dhm.push(m + ' minutes');
			dhm = dhm.join(', ');
			$self.text(dhm);
		}
    });
    
    add_widget('dhmWidget', dhmWidget);
})(jQuery);
