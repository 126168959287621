register_screen('ldapcreate', {
    elements: [
	{ entity_template: 'page_title', title: 'Directory/LDAP Synchronization Setup' },
	{ entity_template: 'page_buttons',
	  buttons: [
	      { type: 'save_up', text: 'Continue', key: 'bbx_sync_server_id', detail: 'ldap_settings', clear_history: true, reload: true }
	  ]
	},
	{
	    entity_template: 'page_content', elements: [

		{
		    entity_template: 'page_module',
		    legend: 'Server Settings and Attributes',
		    buttons: ['help'],
		    help_id: 'ldap_synch_setup',

		    elements: [
			{
			    entity_template: 'dirty_form',
			    dirty_elements: ['test_result'],
			    dirty_true: 'PASS',
			    invalidate: [
				{
				    selector: '.ldap_settings',
				    message: 'Please re-run the test after modifying settings!'
				}
			    ],
			    required_fields: [ 'username', 'password' ],
			    action: '/gui/sync/server',
			    method: 'POST',
			    accept_data_from_parent: true,
			    elements: [
				{
				    entity_template: 'input_item',
				    input_name: 'domain_name',
				    type: 'text',
				    title: 'Domain:',
				    attributes: {
					'class': 'ldap_settings'
				},
				validate: { conditions: '^[^ ]+$', condition_is: 'regexp', operator: '==', message: 'Not a valid domain' }
				},
				{
				    entity_template: 'input_item',
				    input_name: 'username',
				    type: 'text',
				    title: 'Bind Username:',
				    attributes: {
					'class': 'ldap_settings'
				    }
				},
				{
				    entity_template: 'input_item',
				    input_name: 'password',
				    type: 'password',
				    title: 'Password:',
				    attributes: {
					'class': 'ldap_settings'
				    }
				},
				{
				    entity_template: 'data_item',
				    elements: [
					{
					    entity_template: 'ldap_discover_button',
					    text: 'Detect Server Settings',
					    rest: '/gui/sync/discover',
					    rest_container: 'discover',
					    method: 'POST',
					    select: 'server_type',
					    include_presubmit_data: [ 'domain_name', 'username', 'password' ],
					    field_map: {
						address: 'bbx_sync_server_host',
						port: 'bbx_sync_server_port',
						uid: 'bbx_sync_server_uid',
						base_dn: 'bbx_sync_server_base_dn',
						member: 'bbx_sync_server_groups_members',
						group_member: 'bbx_sync_server_members_groups'
					    },
					    dialog_during_submit: {
						title: 'Searching for Settings' + entity.hellip,
						text:  'Server settings are being detected and validated. Please wait.'
					    }
					},
					{
					    entity: 'div',
					    text: 'Enter the Domain, Bind Username, and Password to auto-detect server settings.',
					    attributes: { 'class': 'info' }
					}
				    ]
				},
				{
				    entity_template: 'data_item',
				    title: 'Server Type',
				    attributes: {
					'class': 'ldap_settings'
				    },
				    elements: [
					{
					    entity_template: 'select_container',
					    select_name: 'server_type',
					    data_source: 'ldap_servers',
					    select_value: 'bbx_sync_server_host',
					    select_text: 'bbx_sync_server_host',
					    select_first: true,
					    fields: ['bbx_sync_server_host', 'bbx_sync_server_port', 'bbx_sync_server_encryption', 'bbx_sync_server_uid',
						     'bbx_sync_server_base_dn', 'bbx_sync_server_groups_members', 'bbx_sync_server_members_groups',
						     'bbx_sync_server_auth_enabled', 'bbx_sync_server_default_auth_suffix'],
					    options: [
						{ text: 'Manual Configuration',                    value: 'manual' },
						{ text: 'Active Directory (Manual Configuration)', value: 'active_manual' },
						{ text: 'E-Directory (Manual Configuration)',      value: 'e_manual' },
						{ text: 'OpenLDAP/Generic (Manual Configuration)', value: 'generic_manual' }
					    ],
					    options_data: {
						manual: {},
						active_manual: {
						    bbx_sync_server_port: '389',
						    bbx_sync_server_uid: 'sAMAccountName',
						    bbx_sync_server_groups_members: 'memberOf',
						    bbx_sync_server_members_groups: 'member'
						},
						e_manual: {
						    bbx_sync_server_port: '389',
						    bbx_sync_server_uid: 'cn',
						    bbx_sync_server_groups_members: 'memberOf',
						    bbx_sync_server_members_groups: 'member'
						},
						generic_manual: {
						    bbx_sync_server_port: '389',
						    bbx_sync_server_uid: 'uid',
						    bbx_sync_server_groups_members: 'memberOf',
						    bbx_sync_server_members_groups: 'member'
						}
					    }
					}
				    ]
				},
				{
				    entity_template: 'input_item',
				    input_name: 'bbx_sync_server_host',
				    attributes: {
					'class': 'ldap_settings'
				    },
				    type: 'text',
				    title: 'Address:'
				},
				{
				    entity_template: 'input_item',
				    input_name: 'bbx_sync_server_port',
				    attributes: {
					'class': 'ldap_settings'
				    },
				    type: 'text',
				    title: 'Port:'
				},
				{
				    entity_template: 'input_item',
				    input_name: 'bbx_sync_server_encryption',
				    attributes: {
					'class': 'ldap_settings'
				    },
				    type: 'select',
				    title: 'Encryption:',
				    select_options: [
					{ text: 'None', value: 'none' },
					{ text: 'StartTLS', value: 'tls' },
					{ text: 'SSL', value: 'ssl' }
				    ]
				},
				{
				    entity_template: 'input_item',
				    input_name: 'bbx_sync_server_uid',
				    attributes: {
					'class': 'ldap_settings'
				    },
				    type: 'text',
				    title: 'UID Attribute'
				},
				{
				    entity_template: 'input_item',
				    input_name: 'bbx_sync_server_base_dn',
				    attributes: {
					'class': 'ldap_settings'
				    },
				    type: 'text',
				    title: 'Base DN:'
				},
				{
				    entity_template: 'input_item',
				    input_name: 'bbx_sync_server_groups_members',
				    attributes: {
					'class': 'ldap_settings'
				    },
				    type: 'text',
				    title: entity.ldquo + 'Member of' + entity.rdquo + ' Attribute:'
				},
				{
				    entity_template: 'input_item',
				    input_name: 'bbx_sync_server_members_groups',
				    attributes: {
					'class': 'ldap_settings'
				    },
				    type: 'text',
				    title: entity.ldquo + 'Group Member' + entity.rdquo + ' Attribute:'
				},
				{
				    entity_template: 'data_item',
				    title: 'Test Settings:',
				    elements: [
					{
					    entity_template: 'fill_data_button',
					    text: 'Test Server Settings',
					    rest: '/gui/sync/test',
					    field_map: [ {'test': 'test'}, {'test': 'test_result'} ],
					    include_field_data: {
						bbx_sync_server_host: 'bbx_sync_server_host',
						bbx_sync_server_port: 'bbx_sync_server_port',
						bbx_sync_server_bind_dn: 'username',
						bbx_sync_server_base_dn: 'bbx_sync_server_base_dn',
						bbx_sync_server_bind_pass: 'password',
						bbx_sync_server_type: 'bbx_sync_server_type',
						bbx_sync_server_encryption: 'bbx_sync_server_encryption'
					    },
					    method: 'POST',
					    dialog_during_submit: {
						title: 'Testing Settings' + entity.hellip,
						text:  'Server settings are being verified. Please wait.'
					    }
					}
				    ]
				},
				{
				    entity_template: 'input_item',
				    type: 'div',
				    title: 'Result:',
				    input_name: 'test',
				    info: 'Tests must be successful before continuing. After settings settings are applied you may choose groups that will be synchronized to your Barracuda Phone System.',
				    input_options: {
					text: 'Test Not Yet Run.',
					widgets: ['translateWidget'],
					widget_options: {
					    translateWidget: {
						translations: {
							'PASS': 'Test Successful',
						    '1': 'Test Successful',
						    '0': 'Failed!'
						},
						undefined_translation: 'Test Not Yet Run.'
					    }
					}
				    }
				},
				{
				    entity: 'div',
				    attributes: { name: 'test_result', hidden: true },
				    widgets: ['widget']
				}
			    ]
			}
		    ] // END page module elements
		} // END page module SERVER SETTINGS
	    ] // END page_content elements
	} // END page_content
    ] // END template elements
}); // END template
