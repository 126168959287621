(function () {
    register_screen('provisioned_ports', {
	elements: [
	    { entity_template: 'page_title', title: 'External Hardware Ports' },
	    {
		entity_template: 'page_buttons',
		buttons: [{ type: 'help', help_id: 'providers_provisioned_ports' }]
	    },
	    {
		entity_template: 'page_content', elements: [
		    {
			entity_template: 'data_table',
			rest: '/gui/gateway/siplist',
			rest_params: { sortby: 'bbx_provider_gateway_name', sortorder: 'asc', bbx_provider_gateway_provisioned: '1' },
			page_size: 25,
			data_source: 'siplist',
			primary_keys: ['bbx_provider_gateway_id'],
			extra_row_data: ['bbx_provider_gateway_id'],
			
			oLanguage: {
			    sZeroRecords: 'No hardware gateway devices have been installed or detected'
			},

			unique_id: 'bbx_provider_gateway_id',

			click_action: 'variable_overlay',
			variable_overlay: {
			    column: 'bbx_provider_gateway_type',
			    DIGITAL: {
				overlay_name: 'provisioned_digital_detail',
				overlay_params: ['bbx_provider_gateway_id']
			    },
			    ANALOG: {
				overlay_name: 'provisioned_analog_detail',
				overlay_params: ['bbx_provider_gateway_id']
			    }
			},
			delete_action: {
			    rest: '/gui/gateway/sip'
			},
			dataEvents: {
			    bind: {'provider_external': 'provider_external'}
			},
			table_actions: {
			    delete_rows: {
				text: 'Delete Selected Ports',
				location: 'both',
				confirm: { title: 'Delete Selected Ports and Devices', text: 'The selected ports, the devices they are associated with, and any other external ports associated with those devices will be removed. If the device is still connected, it will reboot and be re-added with configuration options reset.' }
			    },
			    select_row: true,
			    select_all_rows: true,
			    refresh: { text: 'Refresh' }
			},

			columns: [
			    {
				header: '',
				column: 'bbx_provider_gateway_type',
				width: '20px',
				sortable: false,
				view_elements: [{
				    entity_template: 'translate',
				    render: 'html',
				    name: 'bbx_provider_gateway_type',
				    translations: {
					ANALOG: CUI.sprite('hwanalog', 'Analog Port', 'Analog Port', true),
					DIGITAL: CUI.sprite('hwdigital', 'Digital Port', 'Digital Port', true)
				    },
				    default_translation: '',
				    undefined_translation: ''
				}]
			    },
			    {
				header: 'Port Name',
				column: 'bbx_provider_gateway_name'
			    }
			]
		    } // END data_table
		]
	    }
	]
    })
})();		   
