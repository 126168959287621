/* jshint jquery: true, unused: vars */
/* global CUI, debugLog, classCUI, getUnique, cmp_version, CurrentUser, trueish */
/*
-= IF YOU DO SOMETHING, SAY SOMETHING: KEEP DOCUMENTATION UPDATED TO PRESERVE DEVELOPER SPEED AND SANITY! =-

CUI.aopbParser

Base class for a-op-b object style state parsing. This class handles only the determination of which state(s) to apply and returns that information for
use by the subclass. The resulting action definitions are not parsed, and should be handled by a subclass.

This base class also includes utility functions for finding referenced objects.

OPTIONS:
{
    // "closest" is a jQuery selector for the an object which contains the widget element and all selector-referenced elements.
    // The element is selected by $self.closest(self.options.closest).
    closest: 'form',

    // If "strict_change_watch" is true, DOM events (not fillData) will not trigger an update unless the value of the targeted element has actually changed.
    strict_change_watch: false,

    // An array of independent rules and resulting action definitions.
    states: [
	{
	    // If there is only one condition, the array enclosure may be omitted. If the "conditions" key is omitted, the condition is considered "true",
	    // and the actions are run. This can be used to create a "fallback" state if other states use "last: true" actions.
	    conditions: [

		// A condition, in its most basic form, consists of: { a: 'operand', op: 'operator', b: 'operand' }
		// Operators are "==" and "!=". Operators ">", "<", ">=", and "<=" are also valid if both a and b are "lvalue-compatible" operands
		// If you are comparing dotted version numbers (e.g., "1.2.003.004"), prefix greater/less-than operators with "v", like "v<", "v<=", "v>", "v>=".
		// Do not prefix equality "==" or "!=" with v, as this comparison can be done as a normal string comparison
		// (selector, dataitem, literal). Other (rvalue) types merely return true or false, and are thus not comparable. See below for more details
		// regarding numeric casting in value comparison operations.

		{ a: '$server_type',   op: '==',   b: 'ftp' },

		// Boolean logic among multiple conditions can be done by having multiple conditions, with a "join" property. The "join" may be
		// either "or" or "and". "Or" is the default. All boolean operations are performed in array order, with equal precedence.
		{ join: 'and', a: '$port', op: '==', b: '21' },

		// To join with a sub-group of conditions, use another "conditions" array instead of "a", "op", and "b".
		{ join: 'and', conditions: [
		    {             a: '$active', op: '!=', b: '_TRUEISH_' },
		    { join: 'or', a: '$active', op: '==', b: 'no' }
		] }

		// Operands "a" and "b" can have a few different types. If
		//
		// selector    -- A form field, widget, or fillData selector -- autodetected if the string begins with "$", ".", or "#". The value of the first
		//                selected object is used in the comparison. Accepts the shortcut "$field_name" for "[name=field_name]" and "$$" for the widget
		//                itself. Bindings are handled by the _watch_events() function.
		// dataitem    -- Data recieved via fillData or setValue -- autodetected by the "@" prefix. Bindings are handled by _watch_events()
		// utility     -- One of a built-in set of utility functions or constants, such as _TRUEISH_ -- autodetected by the "_..._" format.
		// range       -- A range of integers, inclusive, indicated by a string "xxxx-yyyy", "<=xxxx", or ">=xxxx" -- autodetected by that format. The
				  comparison is true if the value falls within the range.
		// literal     -- Use the string as is, as a literal string -- used if no other type is detected, or set explicitly when a value would
		//                otherwise be autodetected as another type.
		// regexp      -- A literal parsed as a JS regexp. Returns _TRUE_ or _FALSE_. Not auto-detected, and must be specified by a_is/b_is. The
		//                comparison is true if the regular expression matches. If you wish to use flags on the regexp, make the operand an array:
		//                ['regexp','flags']
		// element     -- A selector that returns the jQuery object itself, only currently used for "a" type in a "jq_is" comparison. Must be specified
		//                by "a_is"
		// jq_is       -- Performs a jQuery "is" comparison on the "a" value. Only available if "a" is the type "element". Must be specified by b_is.
		//
		// To override autodetection and force a type, use "a_is" or "b_is" parameters, such as:
		//     { a: '.thisIsALiteralValue', a_is: 'literal', ... }
		//

		// In the rare case that you have a value comparison (>/</>=/>=) and *do not* wish to convert the values to a number (and use a string
		// comparison), you can prevent numeric casting by using the "a_no_cast" and "b_no_cast" params. This is rare, as using gt/lt comparisons
		// makes little sense in a non-numeric context. If the value cannot be cast to a string (Number() returns "NaN"), the value will always
		// be handled as a string, not NaN.
		//
		// { a: '$string_one', op: '>', b: '$string_two', b_no_cast: true }
		//
		// The value of b, ($string_two) will be evaluated as a string, even if it can be parsed as a number.
	    ]

	    // When parsing the rule-set, actions are returned if the conditions were successful, else_actions are returned if not.
	    // The content of these actions, and how they are acted upon, are the domain of the subclass widget.
	    actions: { },
	    else_actions: { },

	    // If an action or else_action is triggered, and the "last" property is true, no later states will be processed. If you want to be more specific,
	    // you can also set last_if_true or last_if_false, which will end on their respective cases regardless of whether an action was triggered. Note
	    // that if you set last, actions and else_actions, or set both last_if_true and last_if_false, later actions will never trigger. (This throws a
	    // warning in the console.)
	    last: true,
	    last_if_true: false,
	    last_if_false: false
	]
    }

    // If this is true, the widget will trigger on a screenReady event on the enclosing screen. This is usually desired behavior
    watch_screenReady: true,

    // If this is true, the widget will trigger on a fillData event on any ancestor of the .dataProducer class (has self.options.rest). This is usually desired
    // behavior.
    watch_producer_fillData: true,

    // If this is true, the widget will trigger on a dirty or undirty event on the data producer. This is not usually needed, except when an
    // aopb is watching the is-dirty or is-valid classes.
    watch_producer_dirty: true,

    // Watch specific events on an object under "closest". If "selector" is omitted, the "closest" element itself is watched. This uses a "delegate" on
    // the "closest" element to function.
    watch_also: [ { selector: '.selector', events: 'name of event' }, ... ]
}


REQUIREMENTS:

The subclass should be based on the standard Widget base class.

This widget requires the cmp_version(a,b) function from helpers.js to do version comparison (v<, v>, v<=, v>=)

Your widget should run _init_states() as soon as possible.

Your widget should run _watch_events() on DOM ready. The child objects being bound do not need to be available, but the "closest" object must be reachable.

If your widget or subclass overrides fillData, it must run this class' fillData at some point:
    CUI.aopbParser.fillData.apply(self, arguments);

You should implement the following method:
_perform_aopb_actions(ARRAY actions) RETURNS NOTHING
   In this function, your subclass should be parsing objects of the particular "actions/else_actions" format used in your widget. For instance, a subclass
   may implement properties such as "state", "text", or "value" to set those properties of the widget. This function takes an array of these objects and
   returns nothing.


PUBLIC METHODS:

eval_states() RETURNS ARRAY OF OBJECTS actions
    Parses the root "states" property and returns an array of "action" or "else_action" objects that result.

PRIVATE METHODS:

_init_states()
    SUBCLASSING WIDGETS *MUST* RUN THIS FUNCTION IN THEIR INITIALIZERS!
    Scans and normalizes all conditions under the states object, adding implicit parameters and cleaning up sub-optimal data structures. This should be
    run in the initialization stage of the subclass widget. It is run as needed by other methods. Runs _rescan_conditions().

_each_condition(FUNCTION callback(condition), OBJECT start_condition)
    Runs a function on a condition and all its sub-conditions.

_rescan_conditions()
    Re-scans the conditions for missing defaults and normalizes the data structure. This only needs to be run if self.options.states changes.

_watch_events(OPTIONAL STRING bind_events)
    SUBCLASSING WIDGETS SHOULD USUALLY RUN THIS IN THEIR DOM-READY FUNCTIONS.
    Begins watching all elements referenced in all conditions for the specified events. (Uses _delegate from Widget Base)

_unwatch_events(OPTIONAL STRING bind_events DEFAULT false, OPTIONAL JQ-SELECTOR selector)
    Stops watching all elements referenced in all conditions for the specified events.

OBJECT _lvalue_parsers
   { FUNCTION [type-name](a) RETURNS lvalue, ... }
       Lvalue parsers convert a typed (unprocessed) "a" value into a string or boolean which can be used as a comparison value. Add to this object if you
       want to add more value types.

OBJECT _rvalue_parsers
   { FUNCTION [type-name](a,b) RETURNS BOOLEAN success, ... }
       Rvalue parsers take a processed "a" value and compare it with an unprocessed "b" value, and output whether the comparison results in equality or
       inequality

OBJECT _lvalue_utility_functions
   { FUNCTION [type-name](a) RETURNS (any type) lvalue, ... }
   Converts various "_..._" constants into lvalue (a) values.

OBJECT _rvalue_parsers
   { FUNCTION [type-name](a,b) RETURNS BOOLEAN success, ... }
   Functions for parsing various "_..._" rvalue constants into results.


INTERNAL METHODS:

_eval_condition(OBJECT condition_object) RETURNS BOOLEAN success
    Determine whether a condition object evaluates as successful. This is used by eval_states, which you should use instead.

_get_delegate_selectors(BOOLEAN force_rescan) RETURNS STRING change_selector
    Scan for "selector" type operands, compile them into one large selector string, and returns that.

_autoset_operand_types(OBJECT condition) RETURNS NOTHING
   Sets the "a_is" and "b_is" variables on a condition if they are not set. Used by _rescan_conditions.


EZ-2-USE CUSTOMIZATION PARAMETERS FOR SUBCLASSING:

_states_key: 'states'
_conditions_key: 'conditions'
    Allows you to change the "states" and "conditions" nomenclature to something else

_operand_type_regexes (OBJECT OF OBJECTS)
    An object with regexes to match operand values to their types automatically. This object is in the form:

	{
	    typename: { regexp: '^r[e]gex?$', flags: 'i' },
	    ...
	}

    The "flags" parameter is optional. All the built-in types are in this object, so be sure to delete types you don't want to detect, and not to clobber ones
    you do.

_allow_c (BOOLEAN)
    A common and confounding typo is accidentally using { a:..., b:..., c:... } on conditions, when you meant { a:..., op:..., b:... }. If your subclass does
    not legitimately use the "c" key in conditions (usually, yours won't), then keeping this value as "false" will throw a warning if "c" appears as a key.  If
    your subclass does legitimately use "c", set this to true.

_actions_keys (OBJECT)
    By default, the values returned if a state is true or false are taken from the "actions" and "else_actions" parameters of the action object. If you would
    like different names for these keys (for instance "response" and "else_response" instead), override this variable in the format:

	_actions_keys: { 'true':'key_name', 'false':'other_key_name' }

    Both keys are required, and since "true" and "false" are reserved words, they must be quoted.

_no_condition_result (BOOLEAN): true
    What should the "result" be if a state is encountered that has no "conditions" property?

_debounce_eval_and_perform: 100 | msec | false
    If this is true, eval/perform operations will be "debounced" to one every number-of-milliseconds. If false or 0, all such operations will happen
    immediately. This feature is best left on to prevent unnecessary re-evaluation when multiple occurrences at the same time trigger re-evaluation.

*/

(function ($) {
	var needs_firefox_fix;

	classCUI.prototype.aopbParser = {
		options: {
			closest: 'form',
			watch_form_dirty: false,

			_watched_element_cache: [],
			_aopb_binds: 'click change keyup',
			_filledData: {},
			_default_condition: { a: '_NOOP_TRUE_', op: '==', b: '_NOOP_TRUE_' }
		},

		_operand_type_regexes: {
			selector: { regexp: '^[.#$]'        },
			dataitem: { regexp: '^@'              },
			utility:  { regexp: '^_.+_$'          },
			range:    { regexp: '^([<>]=[0-9]+|[0-9]+-[0-9]+)$' }
			// "literal" and "regexp" types must be specified
		},

		_states_key: 'states',
		_conditions_key: 'conditions',

		// If your subclass allows the "c" key in conditions, set this true. Otherwise, this is used for error-checking a common typo.
		// (doing { a, b, c }, not { a, op, b })
		_allow_c: false,

		// What should the result be if the state has no "condition"? Use "true" to support conditionless default states.
		_no_condition_result: true,

		// If your subclass uses a different "actions" key name than "actions" and "else_actions", change this--
		_actions_keys: {
			'true':  'actions',
			'false': 'else_actions'
		},

		watch_screenReady: true,
		watch_dtw_rowReady: true,
		watch_producer_fillData: true,
		watch_producer_dirty: false,
		watch_variable_container_change: true,
		_debounce_eval_and_perform: 100,

		_init_states: function () {
			var self = this, $self = this.element, keys, k_idx;

			if (!(self._states_key in self.options || self._conditions_key in self.options)) {
				debugLog('cui.aopbParser.js: No "states" or "conditions" option in the widget. Nothing to do! -- ', $self);
				return false;
			} else if (!(self._states_key in self.options)) {
				// If there is no "states" object, one will be constructed
				self.options[self._states_key] = [{}];

				// Add any keys to be copied to this array
				keys = [self._conditions_key, self._actions_keys['true'], self._actions_keys['false']];

				for (k_idx = 0; k_idx < keys.length; k_idx++) {
					if (keys[k_idx] in self.options) {
						self.options[self._states_key][0][keys[k_idx]] = self.options[keys[k_idx]];
					}
				}
			}

			if (!$.isArray(self.options[self._states_key]) && $.isPlainObject(self.options[self._states_key])) {
				self.options[self._states_key] = [self.options[self._states_key]];
			}

			self._rescan_conditions();
			self.options._aopb_init = true;
		},

		_each_condition: function (callback, cond) {
			var self = this, $self = this.element;

			// All conditions objects must be in array form before using this function! (Don't worry. This should be done in _init_states.)

			if (typeof cond === 'undefined') {
				for (var states_ct=0; states_ct<self.options[self._states_key].length; states_ct++) {
					if (self._each_condition(callback, self.options[self._states_key][states_ct]) === false) {
						return false;
					}
				}
				return;
			}

			if (cond[self._conditions_key]) {
				var conds = $.isArray(cond[self._conditions_key]) ? cond[self._conditions_key] : [cond[self._conditions_key]];
				for (var cond_ct = 0; cond_ct < conds.length; cond_ct++) {
					if (self._each_condition(callback, conds[cond_ct]) === false) {
						return false;
					}
				}
			}

			return callback.call(self, cond);
		},

		_rescan_conditions: function () {
			var self = this, $self = this.element;
			self._each_condition(function (cond) {
				if (cond[self._conditions_key] && !$.isArray(cond[self._conditions_key])) {
					cond[self._conditions_key] = [cond[self._conditions_key]];
				}

				if (!cond[self._conditions_key]) {

					// Can't use $.extend, because we want to only set the things that aren't set in cond, and we can't redefine cond as a new var,
					// because that would break the reference, and wouldn't actually redefine the thing cond is pointing to. So, roll our own extend:
					for (var xk in self.options._default_condition) {
						if (cond[xk] === undefined) {
							cond[xk] = self.options._default_condition[xk];
						}
					}
					var optypes = self._autoset_operand_types(cond);

					if (cond.a && cond.b && cond.c && !self._allow_c) {
						// I keep on absent-mindedly doing { a, b, c } instead of { a, op, b }. This should help indicate that, and save debug time
						debugLog('cui.aopbParser.js: Malformed condition ', cond, ' There\'s no "c". -- ', $self);
					}

					cond.a_is = optypes[0];
					cond.b_is = optypes[1];
				}
			});
		},

		_autoset_operand_types: function (cond) {
			var self = this, $self = this.element;
			var operand_types = [ cond.a_is, cond.b_is ];

			for (var key in self._operand_type_regexes) {
				if (operand_types[0] && operand_types[1]) {
					return operand_types;
				}

				if (!operand_types[0] && cond.a.toString().match(new RegExp(self._operand_type_regexes[key].regexp, self._operand_type_regexes[key].flags || ''))) {
					operand_types[0] = key;
				}
				if (!operand_types[1] && cond.b.toString().match(new RegExp(self._operand_type_regexes[key].regexp, self._operand_type_regexes[key].flags || ''))) {
					operand_types[1] = key;
				}
			}

			// Default to "literal" if nothing was matched.
			operand_types = [ operand_types[0] || 'literal', operand_types[1] || 'literal' ];
			return operand_types;
		},

		_get_delegate_selector: function (force_rescan) {
			var self = this, $self = this.element;

			if (!self.options._aopb_init) {
				self._init_states();
			}

			if (!self.options._selectors_scanned || force_rescan) {
				// Assign to "selectors_lookup" dummy object so duplicates are automatically merged
				var selectors_lookup = {}, selectors = [];

				self._each_condition(function (cond) {
					for (var ab in {a:0, b:0}) { // A fancy way of looping through the letters "a" and "b" w/o making a named array
						if (cond[ab + '_is'] === 'selector' || cond[ab + '_is'] === 'element') {
							if (cond[ab] === '$$') {
								// If there's a "$$" self-reference selector, I can't concat the reference into the selector string as such, so assign or find
								// an ID attribute, and use that for the selector.

								var id;

								if ($self.attr('id')) {
									id = $self.attr('id');
								} else {
									id = getUnique(self.options.widget_id + '-');
									$self.attr('id',id);
								}

								selectors_lookup['#'+id] = true;
							} else {
								selectors_lookup[cond[ab].replace(/^\$(.+)/, '[name=$1]')] = true;
							}
						}
					}
				});

				selectors = CUI.keys(selectors_lookup);
				self.options._delegate_selector = selectors.join(',');
				self.options._selectors_scanned = true;
			}

			return self.options._delegate_selector;
		},

		_watch_events: function (events) {
			var self = this, $self = this.element, $closest, selector = self._get_delegate_selector(), wa_idx, wa;

			$closest = $self.closest(self.options.closest);

			// Set up delegate for change events
			if (selector) {
				self._delegate(
					$closest,
					selector,
					events || self.options._aopb_binds,
					CUI.FunctionFactory.build(self._watchedEventHandler, self, { context: 'argument', first: 'context' })
				);
			}

			if (self.options.watch_also) {
				for (wa_idx = 0; wa_idx < self.options.watch_also.length; wa_idx++) {
					wa = self.options.watch_also[wa_idx];
					if (wa.selector) {
						self._delegate($closest, wa.selector, wa.events, CUI.FunctionFactory.build(self._watchedEventHandler, self, { context: 'argument', first: 'context' }));
					} else {
						self._bind($closest, wa.events, CUI.FunctionFactory.build(self._watchedEventHandler, self, { context: 'argument', first: 'context' }));
					}
				}
			}

			// Determine if bind for data events is needed
			self.options._needs_fillData = false;
			self._each_condition( function (cond) {
				if (cond.a_is === 'dataitem' || cond.b_is === 'dataitem') {
					self.options._needs_fillData = true;
					self._bind($self, 'fillData', CUI.FunctionFactory.build( self._getFillData, self, { context: 'argument' }, [cond] ));
					return false;
				}
			});

			// Watch for the screenReady event -- uses self._one from jquery.widget.js
			if (self.watch_screenReady) {
				self._one($self.closest('.pageWidgetType'), 'screenReady', function () {
					self._debouncedEvalAndPerform();
				});
			}

			if (self.watch_dtw_rowReady) {
				self._one($self.closest('tr'), 'rowReady', function () {
					self._debouncedEvalAndPerform();
				});
			}

			if (self.options.watch_form_dirty) {
				self._bind($self.closest('form'), 'dirty undirty', function () {
					self._debouncedEvalAndPerform();
				});
			}

			if (self.watch_producer_fillData) {
				self._bind($self.parents('.dataProducer'), 'fillData', function () {
					self._debouncedEvalAndPerform();
				});
			}

			if (self.options.watch_producer_dirty) {
				self._bind($self.parents('.dataProducer'), 'dirty undirty', function () {
					self._debouncedEvalAndPerform();
				});
			}

			if (self.watch_variable_container_change) {
				self._bind($self.closest('.variableContainer'), 'variableContainerChange', function () {
					self._debouncedEvalAndPerform();
				});
			}
		},

		_watchedEventHandler: function (target, e, d) {
			var self = this, $target = $(target), val, ff_ver;

			self.options.last_event_interactive = !(d && d.non_interactive);

			if (self.options.strict_change_watch) {
				val = $target.val();

				if ($target.data('aopbParser_value_was') == val) {
					return;
				}

				$target.data('aopbParser_value_was', val);
			}

			// Determine whether we need the Firefox fix below, and store this so we don't have to keep reparsing userAgent
			if (needs_firefox_fix === undefined) {
				if (navigator.userAgent.indexOf('Firefox/') !== -1) {
					ff_ver = navigator.userAgent.match(/Firefox\/([0-9]+)/);
					if (ff_ver && ff_ver[1] && Number(ff_ver[1]) < 29) {
						needs_firefox_fix = true;
					} else {
						needs_firefox_fix = false;
					}
				} else {
					needs_firefox_fix = false;
				}
			}

			if (needs_firefox_fix && $target.is('select')) {

				//
				// Here's a fun bug:
				//
				// In Firefox, if you mouse-over an option in a SELECT box, it changes the .val() returned on that. If you do this before the debounced
				// eval-and-perform, the state widget reconized the new value you are mousing over. However, you can click outside the SELECT box, and
				// the value is reset to the original. However, there are NO EVENTS WHATSOEVER (not even a "click" on the body) that indicate that anything
				// happened, so the value is never re-scanned, and the state widget thinks the value is the hovered value, while the SELECT box, and any
				// subsequent checks by other widgets, think the value is the non-hovered value.
				//
				// To fix this, if they're using Firefox, on the first interaction with any SELECT box, we collect an ID for it, then start an interval
				// loop that periodically checks if the SELECT's value has changed, and calles _debouncedEvalAndPerform if it has.
				//
				// Thanks, Firefox.
				// -- rf
				//

				self._monitorFirefoxSelectBox($target);
			}

			self._debouncedEvalAndPerform();
		},

		_monitorFirefoxSelectBox: function ($target) {
			var self = this, target_id;
			self.options._ffx_select = self.options._ffx_select || {};
			target_id = $target.attr('id');

			if (!target_id) {
				$target.attr('id', (target_id = getUnique('aopbWatched')));
			}

			self.options._ffx_select[target_id] = $target.val();

			if (!self.options._ffx_select_interval) {
				self._setInterval( CUI.FunctionFactory.build(self._checkFirefoxSelectBox, self, { context: 'argument' }), 500 );
			}
		},

		_checkFirefoxSelectBox: function () {
			var self = this, check, target, value, target_elem, new_value, need_refresh;

			check = window.check || 0;

			if (self.options.disabled) {
				return;
			}

			for (target in self.options._ffx_select) {
				if (!self.options._ffx_select.hasOwnProperty(target)) { continue; }
				value = self.options._ffx_select[target];
				target_elem = $(document.getElementById(target));
				new_value = $(target_elem).val();

				if (value !== new_value) {
					self.options._ffx_select[target] = new_value;
					need_refresh = true;
				}
			}

			if (need_refresh) {
				self._debouncedEvalAndPerform();
			}
		},

		_getFillData: function (cond, e, d) {
			var self = this, $self = this.element;

			self.options.last_event_interactive = false;

			self.options._filledData = $.extend(self.options._filledData, d);
			self._debouncedEvalAndPerform();
		},


		_debouncedEvalAndPerform: function () {
			var self = this;

			var doEvalAndPerform = function () {
				if (!self.options.destroyed && self.options[self._states_key] && self.element.closest('body')[0]) {
					var evaled = self.eval_states();
					// Only need to perform_actions if the actions have changed
					if (!CUI.compareObjects( self.options._current_actions, evaled )) {
						self.options._current_actions = evaled;
						self._perform_aopb_actions(evaled);
					}
				}
			};

			if (!self._debounce_eval_and_perform) {
				doEvalAndPerform();
			} else {
				if (self.options._ep_debounce_timer) {
					clearTimeout(self.options._ep_debounce_timer);
					delete self.options._ep_debounce_timer;
				}
				setTimeout(doEvalAndPerform, self._debounce_eval_and_perform);
			}
		},

		_unwatch_events: function (events) {
			var self = this, $self = this.element;
			self._undelegate($self.closest(self.options.closest), self._get_delegate_selector(), events || self.options._aopb_binds);
		},

		eval_states: function () {
			var self = this, $self = this.element;
			var actions = [];

			if (!self.options[self._states_key]) {
				// There are no states on this object! Did someone accidentally apply a state widget to a DOM object that didn't have states?
				return actions;
			}

			states: for (var states_ct=0; states_ct<self.options[self._states_key].length; states_ct++) {
				var state = self.options[self._states_key][states_ct];

				var success = state[self._conditions_key] ? self._eval_condition(state) : self._no_condition_result;
				var result = undefined;

				if (success && state[self._actions_keys['true']]) {
					result = 'true';
				} else if (!success && state[self._actions_keys['false']]) {
					result = 'false';
				}

				// If you have last, true, and false, nothing later will ever run. (See the documentation above for "last" options).
				if ((state.last_if_true && state.last_if_false) || (state.last && state[self._actions_keys['true']] && state[self._actions_keys['false']])) {
					debugLog('cui.aopbParser.js: The "last" option set applies to both true and false results. No further states will be considered in this widget. State: ', state, ' -- ', $self);
				}

				if (result) {
					actions.push(state[self._actions_keys[result]]);
					if (state.last) {
						break states;
					}
				}

				if ((state.last_if_true && success) || (state.last_if_false && !success)) {
					break states;
				}
			}

			return actions;
		},

		_eval_condition: function (cond) {
			var self = this, $self = this.element;
			var result = false;

			if (!self.options._aopb_init) {
				self._init_states();
			}

			if (cond[self._conditions_key]) {
				// This is a set of conditions (an array) that needs to be looped through
				for (var c_idx=0; c_idx<cond[self._conditions_key].length; c_idx++) {
					var this_cond = cond[self._conditions_key][c_idx];
					switch (this_cond.join) {
						case 'and':
							result = result && self._eval_condition(this_cond);
							break;
							// Intentional fallthrough
							// jshint -W086
						case 'or':
						default:
							// jshint +W086
							result = result || self._eval_condition(this_cond);
							break;
					}
				}
			} else {
				// This is actually a condition that needs to be evaluated
				var lvalue_parser = self._lvalue_parsers[cond.a_is], rvalue_parser = self._rvalue_parsers[cond.b_is];
				var pre_result = false;

				// If we are comparing two lvalue types, just smack them together here...
				if (!rvalue_parser && self._lvalue_parsers[cond.b_is]) {
					rvalue_parser = self._lvalue_parsers[cond.b_is];

					if (cond.op.match(/[<>]/)) {
						// This is a GT/LT comparison-- the array is parsed down in the cond.op switch
						var a = lvalue_parser.call(self, cond.a), b = rvalue_parser.call(self, cond.b);
						if (!(isNaN(a) || cond.a_no_cast)) { a = Number(a); }
						if (!(isNaN(b) || cond.b_no_cast)) { b = Number(b); }
						pre_result = [a,b];
					} else {
						// Just a normal true/false comparison
						pre_result = (lvalue_parser.call(self, cond.a) == rvalue_parser.call(self, cond.b));
					}

					// If not, and we're trying to do a value match, fail.
					// GT/LT comparisons are only valid if both types are "lvalue", because "rvalue" types do their own true/false determination
				} else if (cond.op.match(/[<>]/)) {
					debugLog('cui.aopbParser.js: Attempted to use a value comparison, but the second operand is not a type that returns a value (', cond.b_is, '). Condition: ', cond, ' -- ', $self);
					return false;
					// Or if we have lvalue and rvalue parsers, run them...
				} else if (lvalue_parser && rvalue_parser) {
					pre_result = rvalue_parser.call(self, lvalue_parser.call(self, cond.a), cond.b, cond);
					// Or if it's busted, toss metaphorical cookies...
				} else if (!lvalue_parser) {
					debugLog('cui.aopbParser.js: Improper lvalue (a) type "' + cond.a_is + '" in rule ', cond, '. Proper values are:', self._lvalue_parsers, ' -- ', $self);
					return false;
				} else if (!rvalue_parser) {
					debugLog('cui.aopbParser.js: Improper rvalue (b) type "' + cond.a_is + '" in rule ', cond, '. Proper values are:', self._rvalue_parsers, ' -- ', $self);
					return false;
				}
				switch (cond.op) {
					case '>=':
						result = (pre_result[0] >= pre_result[1]);
						break;
					case '<=':
						result = (pre_result[0] <= pre_result[1]);
						break;
					case '>':
						result = (pre_result[0] > pre_result[1]);
						break;
					case '<':
						result = (pre_result[0] < pre_result[1]);
						break;
					case '!=':
						result = !pre_result;
						break;
					case '==':
						result = !!pre_result;
						break;
					case 'v<':
						result = (cmp_version(pre_result[0], pre_result[1]) === -1);
						break;
					case 'v<=':
						result = (cmp_version(pre_result[0], pre_result[1]) < 1);
						break;
					case 'v>':
						result = (cmp_version(pre_result[0], pre_result[1]) === 1);
						break;
					case 'v>=':
						result = (cmp_version(pre_result[0], pre_result[1]) > -1);
						break;
					default:
						debugLog('cui.aopbParser.js: Invalid operator "' + cond.op + '" in rule ' + cond + '. -- ', $self);
						result = false;
						break;
				}
			}
			return result;
		},

		_lvalue_parsers: {
			// LValue parsers return the actual basic value derived from a typed operand. Only some types are allowable as lvalues.

			literal: function (a) {
				return a.toString();
			},

			selector: function (a) {
				var self = this, $self = this.element;
				var $closest = $self.closest(self.options.closest), $target;

				// Find the thing
				if (a === '$$') {
					$target = $self;
				} else {
					$target = $closest.find(a.replace(/^\$(.+)/, '[name=$1]'));
				}

				return CUI.getWidgetElementValue($target, { first_value: true });
			},

			element: function (a) {
				var self = this, $self = this.element;
				var $closest = $self.closest(self.options.closest);
				if (a === '$$') {
					return $self;
				} else {
					return $closest.find(a.replace(/^\$(.+)/, '[name=$1]'));
				}
			},

			utility: function (a) {
				// Just a caller for the _lvalue_utility_functions

				if (this._lvalue_utility_functions[a || '[Utility specification was undefined!]']) {
					return this._lvalue_utility_functions[a].apply(this, arguments);
				} else {
					debugLog('cui.aopbParser.js: Utility value "' + a + '" is not a valid rvalue utility -- ', this.element);
				}
			},

			dataitem: function (a) {
				var val;
				val = ((this.options._filledData || {})[ a.replace(/^@/, '') ]);
				if ( val === 'null' ) {
					return '';
				}
				else {
					return val;
				}
			}
		},

		_rvalue_parsers: {
			// RValue parsers take a processed lvalue "a" and an unprocessed typed rvalue "b". Return true if the two sides are functionally "equal" or
			// "correct". If a parser is not included in _rvalue_parsers (here), but an lvalue_parser exists, the_lvalue_parser will be run against "b" and
			// compared (==, with type coersion) to the existing "a", as well. There is no reason to make an rvalue parser for an existing lvalue type, unless
			// it needs to be compared in a different way.

			range: function (a,b) {
				var matches = b.match(/(-?[0-9]+)-(-?[0-9]+)/);

				if (matches && matches.length === 3) {
					return (a >= parseInt(matches[1], 10) && a <= parseInt(matches[2], 10));
				} else {
					matches = b.match(/([<>]=)(-?[0-9]+)/);
					if (matches && matches.length === 3) {
						if (matches[1] === '<=') {
							return (a <= parseInt(matches[2], 10));
						} else if (matches[1] === '>=') {
							return (a >= parseInt(matches[2], 10));
						}
					}

					debugLog('cui.aopbParser.js: Range value "' + a + '" was not in the proper format -- ', this.element);
					return false;

				}
			},

			jq_is: function (a, b) {
				if (typeof a === 'object' && a instanceof jQuery) {
					return a.is(b);
				} else {
					debugLog('cui.aopbParser.js: "b" was an "jq_is" type, but "a" was not "element" type (a:', a, ', b:', b, ') -- ', this.element);
					return false;
				}
			},

			utility: function (a,b,param) {
				// Just a caller for the _rvalue_utility_functions
				// If an _lvalue_utility_functions entry exists and an _rvalue_... does not, compares to the _lvalue_... function. Simplicity!

				if (this._rvalue_utility_functions[b]) {
					return this._rvalue_utility_functions[b].call(this, a, b, param);
				} else if (this._lvalue_utility_functions[b]) {
					return a == this._lvalue_utility_functions[b].call(this, a, param);
				} else {
					debugLog('cui.aopbParser.js: Utility value "' + a + '" is not a valid rvalue utility -- ', this.element);
				}
			},

			regexp: function (a,b) {
				// Regexps can be passed as strings, or as a [regex,flags] array. Normalize to the array:
				if (typeof b === 'string') {
					b = [b,''];
				}

				if (a === undefined || a === null) {
					return !b;
				}

				return !!a.match(new RegExp(b[0], b[1]));
			}

		},

		// Functions placed here are used to determine lvalue (a) values. If there is no override in _rvalue_utility_functions, they also are used to
		// create rvalue (b) values to compare with.
		_lvalue_utility_functions: {
			_TRUE_: function () {
				return true;
			},
			_FALSE_: function () {
				return false;
			},
			_NOOP_TRUE_: function () {  // Used for default and testing purposes
				return true;
			},
			_NOOP_FALSE_: function () { // Used for default and testing purposes
				return false;
			},
			_EMPTY_: function () {
				return '';
			},
			_USER_PERMISSIONS_: function () {
				// Returns all allowed user permissions, sorted, in a string like:
				// '"PERMISSION_ONE","PERMISSION_TWO","PERMISSION_THREE"'
				var p_key, p_out = [];
				for (p_key in CurrentUser.permissions) {
					if (!CurrentUser.permissions.hasOwnProperty(p_key)) { continue; }
					if (CurrentUser.permissions[p_key]) {
						p_out.push(p_key);
					}
				}

				return (p_out.length ? ('"' + p_out.sort().join('","') + '"') : '');
			}
		},

		// Functions placed here are used to determine outcomes, given a processed rvalue (a) and an unprocessed lvalue (b).
		_rvalue_utility_functions: {
			_DEFINED_: function (a,b) {
				return (a !== undefined);
			},
			_UNDEFINED_: function (a,b) {
				return (a === undefined);
			},
			_NULL_: function (a,b) {
				return (a === null);
			},
			_TRUEISH_: function (a,b) {
				return !!trueish(a);
			},
			_FALSEISH_: function (a,b) {
				return !trueish(a);
			},
			_TRUE_: function (a,b) {
				return !!a;
			},
			_FALSE_: function (a,b) {
				return !a;
			},
			_NOOP_TRUE_: function (a,b) {  // Used for default and testing purposes
				return true;
			},
			_NOOP_FALSE_: function (a,b) { // Used for default and testing purposes
				return false;
			},
			_NAN_: function (a,b) {
				return isNaN(a);
			},
			_NUMERIC_: function (a,b) {
				return !isNaN(a);
			},
			_NUMERIC_NONZERO_: function (a,b) {
				return (!isNaN(a) && !!parseInt(a));
			},
			_NUMERIC_NONNEGATIVE_: function (a,b) {
				var result = ( !isNaN(parseInt(a,10)) && a >= 0 );
				return result;
			},
			_NUMERIC_GREATER_THAN_ZERO_: function (a,b) {
				var result = ( !isNaN(parseInt(a,10)) && a >= 1 );
				return result;
			},
			_DIGITS_: function (a,b) {
				return (!a.toString().match(/[^0-9]/));
			},
			_IP_V4_: function (a,b) {
				var m = a.match(/^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/);
				if (!m) { return false; }
				for (var i=1; i<m.length; i++) {
					if (m[i] < 0 || m[i] > 255) { return false; }
				}
				return true;
			},
			_MAC_: function (a,b) {
				var re = /^([0-9a-f]{2}[:-]?){5}([0-9a-f]{2})$/i;
				return re.test(a);
			},
			_PORT_: function (a,b) {
				var int_a = parseInt(a, 10);
				return ((a.search(/[^0-9]/) === -1) && (a.toString().charAt(0) !== '0') && (int_a > 0) && (int_a <= 65535));
			},
			_IP_V4_MASK_: function (a,b) {
				var values = ['0','128','192','224','240','248','252','254', '255'], must_be_zero = false;
				var m = a.match('^(' + values.join('|') + ')\\.(' + values.join('|') + ')\\.(' + values.join('|') + ')\\.(' + values.join('|') + ')$');
				if (!m) { return false; }
				for (var i=1; i<m.length; i++) {
					if (m[i] !== '0' && must_be_zero) { return false; }
					if (m[i] !== '255') { must_be_zero = true; }
				}
				return true;
			},
			_ARRAY_HAS_ELEMENTS_: function (a,b) {
				return !!a && a.length;
			},
			_ARRAY_HAS_MULTIPLE_ELEMENTS_: function (a,b) {
				return !!a && a.length > 1;
			},
			_EMAIL_: function (a, b) {
				var re, emails;

				if (!a) { return false; }

				emails = a.split(/, ?/);
				re = /^[^ @]{1,64}@[^@ ]{1,255}$/;

				for (var e in emails) {
					if (!re.test(emails[e])) { return false; }
				}
				return true;
			},
			_PHONE_NUMBER_DIGITS_: function (a, b) {
				var re = /^\+?\d{1,15}$/;
				return re.test(a);
			},
			_SAFE_CHARACTERS_: function (a, b) {
				var re = /[<>]/;
				return !re.test(a);
			},
			_URL_: function (a, b) {
				var re = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
				return re.test(a);
			}
		},

		_perform_aopb_actions: function (actions) {
			if (actions.length) {
				debugLog('cui.aopbParser.js: Subclass did not override the _perform_aopb_actions function, and actions (' + actions + ') were requested. -- ', this.element);
			}
		}
	};

})(jQuery);
