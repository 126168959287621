$.fn.eggScreen = function () {
	var screenElem;
	var $self = $(this[0]);
	var initialized = false;

	$('<div class="egg-splanation"><a href="#" class="start-intro-initial"><img src="images/eggsalad/2013egg.png" width="110" height="162" alt="Easter Egg" /></a>You found the Easter Egg! See what\'s Inside!<div style="font-size: 80%">(Text screens simulated. Nothing is being changed on your system.)</div></div></div><pre class="egg-typeout-container" style="display:none" /><div class="egg-credits" style="display: none" />').appendTo($self);

	var requestFullscreen = function (el) {
		if (el.requestFullscreen) { return el.requestFullscreen(); }
		if (el.mozRequestFullScreen) { return el.mozRequestFullScreen(); }
		if (el.msRequestFullscreen) { return el.msRequestFullscreen(); }
		if (el.webkitRequestFullScreen) { return el.webkitRequestFullScreen(); }
	};

	var exitFullscreen = function () {
		if (document.exitFullscreen) { return document.exitFullscreen(); }
		if (document.mozCancelFullScreen) { return document.mozCancelFullScreen(); }
		if (document.msExitFullscreen) { return document.msExitFullscreen(); }
		if (document.webkitExitFullscreen) { return document.webkitExitFullscreen(); }
	};

	var startTypeout = function (elem) {
		var directives = [
			['Press [ ', 0, 0, 'BLU'],
			['Esc ', 0, 0, 'YEL'],
			['] to exit full-screen mode\n\n', 0, 0, 'BLU'],
			['login: ', 0, 1000],
			['easteregg', 300, 500],
			['\npassword: ', 0, 2500],
			['\n\nBarracuda Branch Exchange (BBX)\nLast login: Fri Jul 15 2016 from ann-arbor.mi.barracuda.com', 0, 500],
			['\n\n[easteregg@cudatel ~]$ ', 0, 2000],
			['./egg', 500, 1000],
			['\n\n                B P S   T e a m   p r e s e n t s . . .\n', 10, 0, 'cya'],
			[[
				'  ____  _____   _____ _',
				' |  _ \\|  __ \\ / ____| |                  _',
				' | |_) | |__) | (___ | |_ ___  _ __ _   _(_)',
				' |  _ <|  ___/ \\___ \\| __/ _ \\| \'__| | | |',
				' | |_) | |     ____) | || (_) | |  | |_| |_',
				' |____/|_|    |_____/ \\__\\___/|_|   \\__, (_)',
				'                                    __/ |',
			''].join('\n'), 10, 0, 'cya'],
			['  _______ _            ______      ', 10, 0, 'BLU'],
			['|___/', 10, 0, 'CYA'],
			['.             ______\n', 10, 0, 'BLU'],
			[[
				' |__   __| |          |  ____|        | |            |  ____|',
				'    | |  | |__   ___  | |__   __ _ ___| |_ ___ _ __  | |__   __ _  __ _',
				'    | |  | \'_ \\ / _ \\ |  __| / _` / __| __/ _ \\ \'__| |  __| / _` |/ _` |',
				'    | |  | | | |  __/ | |___| (_| \\__ \\ ||  __/ |    | |___| (_| | (_| |',
				'    |_|  |_| |_|\\___| |______\\__,_|___/\\__\\___|_|    |______\\__, |\\__, |',
				'                                                             __/ | __/ |',
				'                                                            |___/ |___/',
			''].join('\n'), 10, 0, 'blu'],
			['                  2   0   0   8   -   2   0   1   6\n\n', 10, 0, 'cya'],
			['                                     . . . a semi-interactive easter egg\n\n', 0, 2000, 'BLU'],
			['It all started in 2008 . . . ', 10, 0, 'YEL'],
			['FreeSWITCH, an open-source telephony engine created by a number of developers originally from the Asterisk project, was gaining ground and showing its strengths in versatility, performance, and stability. The project attracted the attention of Barracuda Networks, who were looking to add a business telephone system to their product line-up.\n\n', 10, 0],
			['Core FreeSWITCH developers were approached and employed to begin creating what would become the ', 10, 0],['Barracuda Phone System.\n\n', 10, 0, 'CYA'],
			['By late 2008, the project was underway. The team was tasked with taking FreeSWITCH, a powerful but complex telephony engine, and making the sort of accessible, user-friendly, feature-packed PBX that was simple for any business or organization\'s staff to work with. The core team assembled and added its first round of new developers . . .', 10, 0],

			['\n\n[easteregg@cudatel ~]$ ', 0, 2000],
			['who', 500, 500],
			['\naminessale    Nov 05\nmjerris       Nov 05\nbwest         Feb 06\n\n[easteregg@cudatel ~]$ ', 0, 1000],

			['bps install devteam\n', 200, 1500],
			['\nFound (2) Backend developers...', 0, 2000],
			[' installed.', 0, 1500],
			['\nFound (1) UI developer...', 0, 2000],
			[' installed.', 0, 1500],
			['\nFound (1) Community Manager...', 0, 1500],
			[' installed.', 0, 2000],
			['\n\n[easteregg@cudatel ~]$ ', 0, 2000],
			['who', 300, 1000],
			[[
				'',
				'aminessale    Nov 05',
				'mjerris       Nov 05',
				'bwest         Feb 06',
				'rchandler     Dec 08',
				'rfleminger    Jan 09',
				'mcollins      Jan 09',
				'wking         Jan 09',
				'',
				'[easteregg@cudatel ~]$ ',
			].join('\n'), 0, 1000],
			['# That\'s better. Now we can start building something...', 0, 2000],
			['\n[easteregg@cudatel ~]$ ', 0, 1000],
			['./buildpbx --version=0.1\n', 200, 2000],
			['\nBarracuda PBX Development Team v1.0', 0, 200, 'yel'],
			['\nFrantically working to get an alpha out by ClueCon 2009', 0, 500],
			['........', 1500, 0],
			[' Done!', 0, 2000],
			['\n\n. . . And the rest, as they say, was history . . .\n\n', 50, 0, 'CYA'],
			[' -- Press a key to continue --', 0, 0, 'blk:wht']
		];

		/* -- DEBUG -- Remove space to use --->* /
		directives = [
			['\n\n. . . Long intro goes here . . .\n\n', 10, 0, 'CYA'],
			[' -- Press a key to continue --', 0, 0, 'blk:wht']
		];
		/* -- END DEBUG -- */

		$(elem).show().typeout(directives, { speed: 1, onComplete: waitForCompleteKey });
	};

	var waitForCompleteKey = function () {
		$(window).one('keyup', function (e) {
			e.preventDefault();
			e.stopPropagation();
			exitFullscreen();
			$(screenElem).empty().hide();
			initEggs();
			initDevList();
			initialized = true;
		});
	};

	var initEggs = function () {
		if (initialized) {
			$self.find('.egg-credits').show();
			return;
		}
		var imageDims = {
			miniegg1: [28, 41],
			miniegg2: [26, 38],
			'2008egg': [102, 160],
			'2009egg': [106, 160],
			'2013egg': [110, 162]
		};
		var eggs = [
			['The First Days (intro)', '2008egg', 'start-intro'],
			['1.x/2.x Style', '2009egg', 'style-2009'],
			[null, 'miniegg1'],
			[null, 'miniegg1'],
			[null, 'miniegg1'],
			['3.x Style', '2013egg', 'style-2013'],
			[null, 'miniegg2'],
			[null, 'miniegg2'],
			[null, 'miniegg2']
		].map(function (egg, idx) {
			var out = [];
			var year = idx + 2008;
			var $image = $('<img />').attr({
				width: imageDims[egg[1]][0],
				height: imageDims[egg[1]][1],
				src: 'images/eggsalad/' + egg[1] + '.png',
				alt: 'egg'
			});
			var classes = 'egg-year egg-year-' + year;

			if (egg[2]) {
				$image = $('<a href="#">').addClass(egg[2]).append($image);
			}

			return [
				$('<td class="' + classes + '">').append($image),
				$('<td class="' + classes + '">' + year + '</td>'),
				$('<td class="' + classes + '">' + (egg[0] || '') + '</td>')
			];
		});
		var rowClasses = ['egg-image-tr','egg-year-tr', 'egg-info-tr'];

		var idx, year, egg, $table = $('<table class="egg-table">'), $tbody = $('<tbody />').appendTo($table), $tr;
		for (idx = 0; idx < 3; idx++) {
			$tr = $('<tr class="' + rowClasses[idx] + '" />');
			eggs.forEach(function (egg) { $tr.append(egg[idx]); });
			$tr.appendTo($tbody);
		}

		$table.on('click', '.style-2009', function (e) { e.preventDefault(); $('body').addClass('v2style'); });
		$table.on('click', '.style-2013', function (e) { e.preventDefault(); $('body').removeClass('v2style'); });

		$self.find('.egg-credits').append($table);
	};

	var initDevList = function () {
		var devList, $ul;
		var $ec = $self.find('.egg-credits');
		$ec.show();
		if (initialized) { return; }
		$('<h2 class="egg-credits-title">Meet your Barracuda Phone System Development Team!</h2>').appendTo($ec);
		$ul = $('<ul class="egg-credits-list" />').appendTo($ec);
		devList = [
			["Anthony Minessale",2008,2014],
			["Michael Jerris",2008,2014],
			["Brian West",2008,2014],
			["Raymond Chandler",2008,2016],
			["Rudy Fleminger",2009,2016],
			["William King",2009,2015],
			["Nicholas Belluni",2010,2016],
			["Andrew Thompson",2010,2011],
			["Philip Prindeville",2010,2010],
			["Mark Mann",2010,2011],
			["Graham Saathoff",2010,2011],
			["Ghazi Kahn",2011,2013],
			["Taranbir Singh",2011,2014],
			["Kevin Ross",2011,2014],
			["Sharon White",2011,2014],
			["Eliot Gable",2011,2016],
			["Scott Engemann",2011,2012],
			["Carlos Reyna",2011,2011],
			["David Elbel",2011,2011],
			["TJ Vanderpoel",2011,2012],
			["Jackelyn Butcher",2012,2016],
			["Robert Stevens",2012,2016],
			["Patrick Cook",2012,2012],
			["David Belair",2012,2013],
			["Shane Harrell",2012,2014],
			["Kyle King",2012,2016],
			["Ruston Phares",2013,2013],
			["Micah Monroe",2013,2014],
			["Christopher Genetti",2013,2014],
			["Jason Lantz",2013,2013],
			["Janani Chandrasekaran",2014,2016],
			["Marty Keeter",2014,2014],
			["Jeremy Gable",2014,2015],
			["Nathan Timperley",2014,2016],
			["Caitlin Barley",2015,2016],
			["Girish Nayak",2015,2016],
			["Ian Pattison",2015,2016],
			["Daniel Theisen",2015,2016]
		];

		var lis = devList.map(function (dev) {
			var cl = [], ci;
			if (dev[1] === dev[2]) {
				cl = ['egg-year-' + dev[1]];
			} else {
				for (ci = dev[1]; ci <= dev[2]; ci++) {
					cl.push('egg-year-' + ci);
				}
			}
			return '<li class="' + cl.join(' ') + '">' + dev[0] + '</li>';
		}).join('');

		$ul.html(lis);

		$self.find('.egg-table').on('mouseover', '.egg-year', function (e) { $self.find('.egg-credits-list, .egg-table').attr('data-hover-year', this.className.match(/egg-year-(\d{4})/)[1]); });
		$self.find('.egg-table').on('mouseout', '.egg-year', function (e) { $self.find('.egg-credits-list, .egg-table').removeAttr('data-hover-year'); });
	};

	var startIntro = function (e) {
		e.stopPropagation();
		e.preventDefault();
		$self.find('.egg-credits, .egg-splanation').hide();
		screenElem = $('.egg-typeout-container')[0];
		screenElem.requestFullscreen ? screenElem.requestFullscreen() : requestFullscreen(screenElem); // Firefox complains if you do this in the called function
		startTypeout(screenElem);
	};

	$self.on('click', '.start-intro', startIntro);
	$self.on('click', '.start-intro-initial', function (e) {
		if (window.ALREADY_SEEN_EGG_INTRO) {
			e.preventDefault();
			$self.find('.egg-splanation').hide();
			initEggs();
			initDevList();
			initialized = true;
		} else {
			window.ALREADY_SEEN_EGG_INTRO = true;
			startIntro(e);
		}
	});
};
