/* jshint jquery: true, unused: vars */
/* global CUI */
(function( $ ){
	var flyoutTextWidget = $.extend(true, {}, CUI.mixin.get('widget.flyoutBaseWidget'), {
		options: {
			template_html: '<input type="text" class="flyout-text" />',
			target_anchor: 'bottom left',
			self_anchor: 'top left',
			anti_clip: {
				'bottom': {
					target_anchor: 'top left',
					self_anchor: 'bottom left'
				}
			},

			$content: $('<div />'),
			positioner_class: 'text-flyout',

			// How long should we wait to know that blurring the textbox wasn't because the menu was clicked? (This should not need to change)
			_flyout_click_time_allowance: 100
		},

		_initFlyoutTextWidget: function () {
			var self = this;
			var $self = this.element;

			self._initFlyoutBaseWidget();

			var $txtbox = $self.is(':input') ? $self : $self.find('input:eq(0)');
			self.options.$target = $txtbox;

			self._bind($txtbox, 'keyup', function (e) {
				self._keyUp(new CUI.KeyCode(e.keyCode));
			});

			self._bind($txtbox, 'blur', function (e) {

				var blurTimeout;
				blurTimeout = setTimeout(function () { self.hideFlyout(); }, self.options._flyout_click_time_allowance);
				self._one(self.options.$flyout, 'click', function () {
					clearTimeout(blurTimeout);
				});

			});

		},

		_keyUp: function (keyCode) {
			// Do all your fancy key-upping things here... the param is a CUI.KeyCode object
			// You probably want
		}
	});

	CUI.mixin.register('widget.flyoutTextWidget', flyoutTextWidget);
})(jQuery);
