/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, cmp */
(function( $ ){
	var multipliedInputWidget = $.extend({}, $.ui.widget.prototype, {
		options: {
			_multipliers: [{ text: 'hours', multiplier: 60*60 } , { text: 'minutes', multiplier: 60 }, { text: 'seconds', multiplier: 1 }],
			default_multiplier: 1, // Set to the value, not the text	    

			blank_value: 0,

			// Validation options
			allow_none: false,                // If true, no input is considered valid
			validate_min: 0,                  // Minimum acceptable value 
			validate_max: undefined,          // Maximum acceptable value
			invalid_message: 'This input must have a valid value.'  // Error/invalid message
		},

		value_widget: true,
		set_value_widget: true,

		manages_own_descendent_value: true,
		manages_own_descendent_events: true,
		manages_own_descendent_state: true,

		_beforeInit: function () {
			var self = this;
			var $self = this.element;
			var inputObjects = {}, multipliers, ref__updateValue;

			self.options.multipliers = self.options.multipliers || self.options._multipliers;
			multipliers = self.options.multipliers;
			// Multipliers were being combined, not clobbered. Use a default instead.

			self.options.inputObjects = inputObjects;

			// Generate Interval Input Text
			inputObjects.$intervalInput = $('<input type="text" class="multipliedInputBase" size="3" />').appendTo($self);
			inputObjects.$intervalInput.keyFilter({ allow_keys_regexp: '^(NUM)?[0-9]$', allow_shift: false });

			// Generate Multiplier Select List
			inputObjects.$multiplierSelect = $('<select class="multipliedInputMultiplier"></select>');

			for (var i=0; i<multipliers.length; i++) {
				inputObjects.$multiplierSelect.append($('<option />', { text: multipliers[i].text, val: multipliers[i].multiplier }));
			}

			if (self.options.default_multiplier) {
				inputObjects.$multiplierSelect.val(self.options.default_multiplier);
			}

			inputObjects.$intervalInput.after(inputObjects.$multiplierSelect);

			// Bind to element input events
			ref__updateValue = self._updateValue.bind(self);
			self._bind(inputObjects.$intervalInput.add(inputObjects.$multiplierSelect), 'keyup change', ref__updateValue);
			self._bind($self, 'stateChange', ref__updateValue);
		},

		_validate: function(val, from_set_value) {
			var self = this, $self = this.element, result = false, key;

			if (val === '' || val === undefined || val === null) {
				if (self.options.allow_none) {
					result = '';
				} else {
					result = false;
				}
			} else {
				val = parseInt(val, 10);

				if (!isNaN(val)) {
					if (val >= self.options.validate_min) {
						if (self.options.validate_max) {
							if (val <= self.options.validate_max) {
								result = val;
							}
						} else {
							result = val;
						}
					}
				}
			}

			key = self.options.widget_id + '_SELF_VALIDATE';
			if (result === false && !self.options.is_invalid) {
				self.options.is_invalid = true;
				CUI.setInvalid($self, self.options.invalid_message, key);
			} else if (result !== false) {
				self.options.is_invalid = false;
				CUI.setValid($self, key);
			}

			return result;
		},

		/*
	 * @fn _updateValue(e)
	 * @brief Check for validity after an input elements value is changed, and fire the change event.
	 */
		_updateValue: function(e) {
			var self = this;
			var $self = $(this.element);
			var inputObjects = self.options.inputObjects;
			var textValue = inputObjects.$intervalInput.val();

			var base = self._validate(textValue);

			if (base === false) {
				// Our input did not validate
				self.options.submit_value = "";
			} else {
				// Input DID validate
				var multiplier_select_val = inputObjects.$multiplierSelect.val();
				var multiplier = parseInt(multiplier_select_val, 10);
				if (isNaN(multiplier) || !multiplier) { multiplier = 1; }

				self.options.submit_value = (base * multiplier);
			}

			$self.trigger('change');
		},

		_getWidgetValue: function () {
			return this._wrapValue(this.options.submit_value);
		},

		setValue: function (v) {
			var self = this, $self = this.element;
			var inputObjects, base, int_value, select_multiplier_value = 1, multipliers_desc, i;

			inputObjects = self.options.inputObjects;
			int_value = self._validate(v, true);
			multipliers_desc = self.options.multipliers.concat([]).sort(function (a,b) { return -cmp(a.multiplier, b.multiplier); });

			if (int_value === false) {
				inputObjects.$intervalInput.val('');
				inputObjects.$multiplierSelect.val(self.options.multipliers[0].multiplier);
				$self.trigger('change');
				return;
			}

			if (int_value === '') {
				inputObjects.$intervalInput.val('');
				self.options.submit_value = self.options.blank_value;
				inputObjects.$multiplierSelect.val(self.options.multipliers[0].multiplier);
				$self.trigger('change');
				return;
			}

			findMult: for (i=0; i < multipliers_desc.length; i++) {
				if (!( int_value % multipliers_desc[i].multiplier )) {
					select_multiplier_value = multipliers_desc[i].multiplier;
					break findMult;
				}
			}

			base = int_value / select_multiplier_value;

			inputObjects.$intervalInput.val(base);
			inputObjects.$multiplierSelect.val(select_multiplier_value);

			self.options.submit_value = (base * select_multiplier_value).toString();
			$self.trigger("change");
		},

		disable: function () {
			var self = this, $self = this.element;
			$.ui.widget.prototype.disable.apply(self, arguments);
			if ($self.hasClass('state-disabled')) {
				$self.find('input,select').disable(self.options.widget_id);
			}
		},

		enable: function () {
			var self = this, $self = this.element;
			$.ui.widget.prototype.enable.apply(self, arguments);
			if (!$self.hasClass('state-disabled')) {
				$self.find('input,select').enable(self.options.widget_id);
			}
		}

	});

	add_widget('multipliedInputWidget', multipliedInputWidget);
})(jQuery);
