(function () {
    register_screen('auto_attendant_create', {
	elements: [
	    { entity_template: 'page_title', title: 'Create Auto-Attendant' },
	    {
		entity_template: 'page_buttons',
		buttons: [
		    { type: 'cancel_back' },
		    {
			type: 'create',
			key: 'bbx_auto_attendant_id',
			detail: 'auto_attendant_detail'
		    }
		]
	    },
	    {
		entity_template: 'page_content', elements: [

		    {
			entity_template: 'page_module',
			legend: 'Basic Information',
			form_action: '/gui/extension/autoattendant/autoattendant',
			form_options: {
			    dataEvents: {
				trigger: 'auto_attendant',
				allow_delete: true
			    }
			},
			form_data_js: {
			    method: 'POST',
			    no_initial_get: true,
			    submit_all: true,
			    always_dirty: true
			},
			buttons: ['help'],
			help_id: 'create_automated_attendant',
			set_focus: true,
			elements: [
			    {
				entity_template: 'input_item',
				title: 'Automated Attendant Name:',
				type: 'text',
				input_name: 'bbx_auto_attendant_name',
				validate: { conditions: '_TRUE_', message: 'A Name is required.' },
				input_options: { attributes: { 'class': 'focus', maxlength: '255' } }
			    },
			    {
				entity_template: 'data_item',
				title: 'Greeting Sound:',
				elements: [
				    {
					entity_templates: ['sound_picker','aopb_state'],
					sound_picker: {
					    name: 'bbx_auto_attendant_greeting_sound',
					    allow_none: false
					},
					aopb_state: {
					    states: [{
						conditions: { a: '$$', op: '==', b: '_TRUEISH_' },
						actions: { invalid: false },
						else_actions: { invalid: 'A Greeting Sound must be selected. Use the Sound Files page (in Configuration) to set up sound files for use with Automated Attendants' }
					    }]
					}
				    }
				]
			    },
			    {
				entity_template: 'data_item',
				title: 'Short Greeting Sound:',
				elements: [
				    {
					entity_template: 'sound_picker',
					name: 'bbx_auto_attendant_short_greeting_sound'
				    }
				]
			    },
			    {
				entity_template: 'data_item',
				title: 'Invalid Action Sound:',
				elements: [
				    {
					entity_template: 'sound_picker',
					name: 'bbx_auto_attendant_invalid_sound'
				    }
				]
			    },
			    {
				entity_template: 'data_item',
				title: 'Exit Sound:',
				elements: [
				    {
					entity_template: 'sound_picker',
					name: 'bbx_auto_attendant_exit_sound'
				    }
				]
			    },
			    {
				entity_template: 'data_item',
				title: 'Transfer Sound:',
				elements: [
				    {
					entity_template: 'sound_picker',
					name: 'bbx_auto_attendant_transfer_sound'
				    }
				]
			    },
			    {
				entity_template: 'extension_select',
				name: 'bbx_extension_id'
			    }

			] // END page module elements
		    } // END page module 1
		    
		    

		] // END page_content elements
	    } // END page_content
	] // END template elements
    }); // END template
})(); // END scoping function
