(function () {
    register_screen('call_log', {
	elements: [
	    { entity_template: 'page_title', title: 'Call Log' },
	    {
		entity_template: 'page_buttons',
		buttons: [{ type: 'help', help_id: 'user_call_log' }]
	    },
	    {
		entity_template: 'page_content', elements: [
		    {
			entity_template: 'data_table',
			'class': 'callLogTable',
			rest: '/gui/cdr/personal',
			rest_params: {
			    sortby: 'start_timestamp',
			    sortorder: 'desc',
			    self_text: 'Me',
			    direction: ''
			},
			extra_row_data: ['start_time_past'],
			page_size: 25,
			columns: [
			    {
				column: 'caller_id_name',
				header: 'From',
				formatted: { format: { field: '@caller_id_name', formatter: 'cid_case' } }
			    },
			    {
				column: 'caller_id_number',
				header: 'Caller Number',
				view_element: {
				    entity_templates: ['form_dialog_link','format'],
				    form_dialog_link: {
					accept_data_from_parent: true,
					title: 'Call',
					submit_button: 'Call',
					disabled: false,
					include_data_as: { caller_id_number: 'destination' },
					rest: '/gui/freeswitch/originate',
					elements: [
					    {
						entity: 'span',
						attributes: { 'class': 'always-dirty-indicator' },
						text: 'Would you like to call this number? Your phone will ring, then you will be connected to the destination.'
					    }
					]
				    },
				    format: {
					format: { field: '@caller_id_number', formatter: 'phone_number' }
				    }
				}
			    },
			    {
				column: 'destination_name',
				header: 'To',
				formatted: { format: { field: '@destination_name', formatter: 'cid_case' } }
			    },
			    {
				column: 'destination_number',
				header: 'Destination Number',
				view_element: {
				    entity_templates: ['form_dialog_link','format'],
				    form_dialog_link: {
					accept_data_from_parent: true,
					title: 'Call',
					submit_button: 'Call',
					disabled: false,
					include_data_as: { destination_number: 'destination' },
					rest: '/gui/freeswitch/originate',
					elements: [
					    {
						entity: 'span',
						attributes: { 'class': 'always-dirty-indicator' },
						text: 'Would you like to call this number? Your phone will ring, then you will be connected to the destination.'
					    }
					]
				    },
				    format: {
					format: { field: '@destination_number', formatter: 'phone_number' }
				    }
				}
			    },
			    {
				column: 'start_timestamp',
				header: 'Time',
				formatted: { format: { field: '@start_time_past' } }
			    },
			    {
				column: 'duration',
				header: 'Duration',
				size: '4em',
				formatted: { format: { field: '@duration', formatter: 'seconds_duration' } }
			    }
			],
			table_actions: {
			    refresh: { text: 'Refresh' },
			    action_elements: {
				actions: [
				    {
					entity: 'select',
					widgets: ['dtwParamAmendWidget'],
					widget_options: {
					    dtwParamAmendWidget: {
						selector: '.dataTableWidget.callLogTable'
					    }
					},
					attributes: { name: 'direction' },
					select_options: [
					    { text: 'All Calls', value: '' },
					    { text: 'Incoming Calls', value: 'incoming' },
					    { text: 'Outgoing Calls', value: 'outgoing' }
					]
				    }
				],
				location: 'top',
				position: 'first'
			    }
			}
		    } // END DTW
		] // END page_content elements
	    } // END page_content
	] // END template elements
    }); // END template
})(); // END scoping function
