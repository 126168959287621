/* global classCUI */
(function () {
	classCUI.prototype.keys = function (obj) {
		var keys_out;
		obj = obj || {};

		if (typeof Object.keys === 'function') {
			// Native
			try {
				keys_out = Object.keys(obj);
			} catch (e) {
				// Usu. passed a non-object as obj
				return undefined;
			}

			return keys_out;
		} else {
			// Fake it
			keys_out = [];
			for (var k in obj) {
				if (obj.hasOwnProperty(k)) {
					keys_out.push(k);
				}
			}
			return keys_out;
		}
	};

	// This function is ambiguous on multiple-element objects, as the order of properties in an object is not reliable as per the JS spec.
	// However, it can be used in cases where we know we only have one key in the object.
	classCUI.prototype.firstKey = function (obj) {
		var ks = [], k;

		if (typeof Object.keys === 'function') {
			// Native -- wrapped in try/catch to prevent "not an object" errors
			try {
				ks = Object.keys(obj);
			} catch (e) {
				return undefined;
			}

			return ks[0];
		} else {
			// Fake it
			for (k in obj) {
				if (obj.hasOwnProperty(k)) {
					return k;
				}
			}

			return undefined;
		}
	};

	classCUI.prototype.hasKeys = function (obj) {
		var k;
		for (k in obj) { return true; }
		return false;
	};

	classCUI.prototype.firstValue = function (obj) {
		var k;
		for (k in obj) { return obj[k]; }
		return undefined;
	};

})();
