/* jshint jquery: true, unused: vars */
/* global add_widget, debugLog */
(function( $ ){
	var checkToListWidget = $.extend({}, $.ui.widget.prototype, {
		options: {

			// values: [{ label: 'Checkbox Name', value: 'value' }]
			value_separator: ',',

			_checkboxes: {}
		},

		value_widget: true,
		set_value_widget: true,
		manages_own_descendent_value: true,

		_beforeDOMReady: function () {
			var self = this, $self = this.element, values = self.options.values, v_idx, v, $row;

			if (!values || !values.length) {
				debugLog('jquery.checkToListWidget.js: No "values" option given. Nothing to generate. -- ', $self);
				return;
			}

			if ( values === 'none' ) {
				self.options._has_checkboxes = false;
				$self.removeClass('check-buttons-container');
				$self.append('No options.');
				return;
			} else {
				self.options._has_checkboxes = true;
				$self.addClass('check-buttons-container');
				for (v_idx = 0; v_idx < values.length; v_idx++) {
					v = values[v_idx];

					$row = $('<label class="check-button-label"><input type="checkbox" class="checkToListCheck" /><span class="check-button-title checkToListLblSpan"></span></label>');
					$row.find('.checkToListLblSpan').text(v.label);
					self.options._checkboxes[v.value] = $row;
					$self.append($row);
				}
			}
		},

		setValue: function (v) {
			var self = this, $self = this.element, split, s_idx;

			if (v === '' || v === null) { return; }
			$self.find(':checkbox').removeAttr('checked');
			split = v.split(self.options.value_separator);
			for (s_idx = 0; s_idx < split.length; s_idx++) {
				if ( self.options._checkboxes[split[s_idx]] ) {
					self.options._checkboxes[split[s_idx]].find('input').prop('checked','checked');
				}
			}
		},

		_getWidgetValue: function () {
			var self = this, $self = this.elements, c_key, $c, out_array = [];

			if (!self.options._has_checkboxes) {
				return self._wrapValue('');
			}

			for (c_key in self.options._checkboxes) {
				$c = self.options._checkboxes[c_key].find('input');
				if ( $c.attr('checked') ) {
					out_array.push(c_key);
				}
			}

			return self._wrapValue( out_array.join(self.options.value_separator || '') );
		}
	});

	add_widget('checkToListWidget', checkToListWidget);

})(jQuery);
