/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, unescape */
/*
  upButtonWidget: A page-button that navigates to the parent screen or overlay. Apply to a BUTTON.
*/

(function( $ ){
    var upButtonWidget = $.extend(true, {}, $.ui.pageButtonWidget.prototype, {
	_beforeInit: function () {
	    var self = this, $self = this.element;
	    self._bind($self, 'click', self._link.bind(self));
	    return $.ui.pageButtonWidget.prototype._beforeInit.apply(this, arguments);
	},

	// Don't factor out this function -- subclasses of this widget use this.
	_clickHandler: function (e) {
	    var self = this, $self = this.element;
	    e.preventDefault();
	    self._link();
	},

	_link: function () {
	    var self = this, hash, new_hash;

	    hash = unescape(location.hash);
	    new_hash = hash.replace(/(.*)\/overlay\/.+/, '$1');

	    if (self.options.clear_history) {
		location.replace(new_hash);
	    } else {
		location.href = new_hash;
	    }

	    if (self.options.reload) {
		location.reload(true);
	    }
	}
    });
    
    add_widget('upButtonWidget', upButtonWidget);
})(jQuery);
