/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){
	var soundMusicFileListWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {},

		_beforeInit: function() {
			var self = this, $self = self.element, $page;

			$page = $self.closest('.pageWidgetType');

			// For delegation binding/unbinding
			self.options.unique = 'unique_' + Math.floor((Math.random()*10000)+1);

			self.options.selected_id = self.options.default_class_id;

			if (!$page.hasClass('screenReady')) {
				self._one($page, 'screenReady', self._screenReadyHandler.bind(self));
			} else {
				self._screenReadyHandler();
			}
		},

		_screenReadyHandler: function () {
			var self = this, $self = this.element;

			self._setupDelegations();
			self.toggleFileList();
		},

		toggleFileList: function() {
			var self = this, $self = self.element;

			if ($self.getCUIWidget('dataTableWidget').options.rest_params[self.options.key_name]) {
				$self.getCUIWidget('dataTableWidget').show();
				$self.closest('.pageModuleWidget').find('.sound_music_no_category_message').hide();
			} else {
				$self.getCUIWidget('dataTableWidget').hide();
				$self.closest('.pageModuleWidget').find('.sound_music_no_category_message').show();
			}
		},

		_setupDelegations: function() {
			var self = this, $self = self.element;

			self._delegate(
				$('body'),
				'form.sound_music_file', 
				'SubmitSuccess.' + self.options.unique, 
				self._formSubmitSuccessHandler.bind(self)
			);

			self._delegate(
				$('body'),
				'form.sound_music_file', 
				'SubmitSuccess.' + self.options.unique, 
				self._formSubmitSuccessHandler.bind(self) 
			);

			self._delegate(
				$('body'),
				'form.sound_music_file_move', 
				'SubmitSuccess.' + self.options.unique, 
				self._formSubmitSuccessHandler.bind(self)
			);

			self._delegate(
				$self.closest('.page_module'),
				'.dataTables_actions button', 
				'click.' + self.options.unique, 
				self._buttonClickHandler.bind(self)
			);
		},

		_formSubmitSuccessHandler: function() {
			var self = this, $self = self.element, dtw = $self.getCUIWidget('dataTableWidget'), $selected = $self.find('tr.state-selected');
			if ($selected.length) { dtw.deselectRow($selected, true, true); }
			$self.find('tr.editable, td.editable').removeClass('editable');
			$self.trigger('deselected');
			$self.trigger('finished_deselected');
			dtw.options.performing_click = false;
			dtw.refresh();
		},

		_buttonClickHandler: function() {
			var self = this, $self = self.element, $form = $('body').find('form.sound_music_file' );
			if (!$self.getCUIWidget('dataTableWidget').options.rest_params[self.options.key_name]) {
				$form.closest('.CUIDialog').find('.form-dialog-submit').addClass('state-disabled').disable();
				$form.html('There is no class selected.');
			}
		},

		_doUndelegations: function() {
			var self = this, $self = self.element;

			self._undelegate(
				$('body'),
				'form.sound_music_file', 
				'SubmitSuccess.' + self.options.unique
			);

			self._undelegate(
				$('body'),
				'form.sound_music_file_move', 
				'SubmitSuccess.' + self.options.unique
			);

		},

		setLegendText: function(text) {
			var self = this, $self = self.element;

			$self.closest('.page_module_body').find('.form_legend').text('Files - ' + text);
		},

		_destroy: function() {
			var self = this, $self = self.element;

			self._doUndelegations();
		}
	});

	add_widget('soundMusicFileListWidget', soundMusicFileListWidget);
})(jQuery);
