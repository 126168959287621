/* jshint jquery: true, unused: vars */
/* global add_widget */
/*
  A variation on the formDialogButtonWidget that sets the value of the widget when the Dialog is dismissed, instead of submitting the form.
  Apply to a <button type="button">
*/

(function( $ ){
	var valueFormDialogButtonWidget = $.extend(true, {}, $.ui.formDialogButtonWidget.prototype, {
		value_widget: true,

		options: {
			value: {}
		},

		submitDialog: function () {
			var self = this, $self = this.element, $body = self.options._$dialog_body, $form = $body.find('.formWidget:eq(0)'), formWidget = $form.getCUIWidget('formWidget');
			self.options.value = formWidget.getFormValues();
			self.closeDialog();
			self._emitChange();
		},

		_getWidgetValue: function () {
			var self = this;
			return self.options.value; // No wrap!
		}

	});

	add_widget('valueFormDialogButtonWidget', valueFormDialogButtonWidget);
})(jQuery);
