function format_information(input, params) {
  /* This is a LIGHT version of format_information in JavaScript. At this time, it only implements:
   * 
   * Params:
   * ndash: false | true   // True: Use ndash as the range seperator, False (default): Use ASCII minus/hyphen as a range seperator
   *
   * x1234
   * x1234-x5678
   * 666-7777
   * (555)666-7777
   * (555)666-7777-(555)666-7789
   * 
   * params.no_ten_digit
   * params.no_extension
   * params.no_auto_split
   * 
   *  */

  if (typeof(input) == 'undefined' || input == null) {
    return;
  }
  
  var match;
  params = params || {};

  var dash = params.ndash ? '\u2013' : '-';
  
  // Format hyphen-delimited ranges
  if ((match = input.match(/(\d+)-(\d+)/)) && !params.no_auto_split) {
    return format_information(match[1]) + dash + format_information(match[2]);
  }

  if (!params.no_seven_digit) {
    if (input.search(/^(\d{3})([\da-zA-Z]{4})$/) > -1) { return input.replace(/^(\d{3})([\da-zA-Z]{4})$/, '$1-$2'); }
    if (input.search(/^1(\d{3})([\da-zA-Z]{4})$/) > -1) { return input.replace(/^1(\d{3})([\da-zA-Z]{4})$/, '1-$1-$2'); }
  }
  
  if (!params.no_ten_digit) {
    if (input.search(/^(\d{3})([\da-zA-Z]{3})([\da-zA-Z]{4})$/) > -1) { return input.replace(/^(\d{3})([\da-zA-Z]{3})([\da-zA-Z]{4})$/, '($1) $2-$3'); }
    if (input.search(/^1(\d{3})([\da-zA-Z]{3})([\da-zA-Z]{4})$/) > -1) { return input.replace(/^1(\d{3})([\da-zA-Z]{3})([\da-zA-Z]{4})$/, '1($1) $2-$3'); }
    if (input.search(/^\+1(\d{3})([\da-zA-Z]{3})([\da-zA-Z]{4})$/) > -1) { return input.replace(/^\+1(\d{3})([\da-zA-Z]{3})([\da-zA-Z]{4})$/, '+1($1) $2-$3'); }
  }

  if (!params.no_extension) {
    var ext_mark = params.ext_mark || 'x';
    if (input.search(/^(\d{1,6})$/) > -1) { return input.replace(/^(\d{1,6})$/, ext_mark + '$1'); }
  }
  
  return input;
}
