/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){
	var toTime = function (mins) {
		var h, m, a;
		mins = Number(mins);
		h = Math.floor(mins / 60);
		m = ('0' + Math.floor(mins % 60).toString()).slice(-2);
		a = (h > 11) ? ' PM' : ' AM';
		if (h > 11) { h -= 12; }
		if (h === 0) { h = 12; }
		return (h + ':' + m + a);
	};

	var timeSelectMinsRangeWidget = $.extend(true, {}, $.ui.containerWidget.prototype, {
		value_widget: true,
		manages_own_descendent_value: true,

		options: {
			elements: [
				{
					entity: 'select',
					select_options: [{ text: 'All Day', value: 'all' }, { text: 'Select Times', value: 'time' }],
					attributes: { 'class': 'time-select-range-spec' }
				},
				{
					entity_templates: ['aopb_state','container'],
					aopb_state: {
						closest: '.timeSelectMinsRangeWidget',
						states: [{
							conditions: { a: '.time-select-range-spec', op: '==', b: 'time' },
							actions: { enabled: true, _cascade: true },
							else_actions: { enabled: false, _cascade: true }
						}]
					},
					container: {
						elements: [
							{
								entity_template: 'time_select_mins',
								'class': 'time-select-range-item'
							},
							{
								entity: 'span',
								text: ' to '
							},
							{
								entity_template: 'time_select_mins',
								'class': 'time-select-range-item'
							},
							{
								entity: 'div',
								attributes: { 'class': 'time-select-range-inverse-warning info', style: 'display: none' }
							}
						]
					}
				}
			]
		},

		_beforeInit: function () {
			var self = this, $self = this.element;
			self._bind($self, 'change', self._checkInverse.bind(self));
		},

		_checkInverse: function () {
			var self = this, $self = this.element, vals = self._getValues();
			if (vals && vals[0] > vals[1]) {
				$self.find('.time-select-range-inverse-warning')
				.show()
				.html('The start time is after the end time. The time range will be in effect <b>after ' + toTime(vals[0]) + '</b> and <b>before ' + toTime(vals[1]) + '</b> on the selected days.');
			} else {
				$self.find('.time-select-range-inverse-warning').empty().hide();
			}
		},

		setValue: function (v) {
			var self = this, $self = this.element, v_arr, v_idx, $controls;
			$controls = $self.find('.time-select-range-item');

			if (v) { v_arr = v.split('-'); }

			if (v && v_arr[0] !== v_arr[1]) {
				for (v_idx = 0; v_idx < 2; v_idx++) {
					$controls.eq(v_idx).timeSelectMinsWidget('setValue', v_arr[v_idx]);
				}
				$self.find('.time-select-range-spec').val('time').trigger('change', { non_interactive: true });
			} else {
				$self.find('.time-select-range-spec').val('all').trigger('change', { non_interactive: true });
			}

		},

		_getValues: function () {
			var self = this, $self = this.element, $controls = $self.find('.time-select-range-item'), vals;
			if ($self.find('.time-select-range-spec').val() === 'all') {
				return '';
			} else {
				vals = [CUI.firstValue($controls.eq(0).timeSelectMinsWidget('getValue')), CUI.firstValue($controls.eq(1).timeSelectMinsWidget('getValue'))];
				if (vals[0] === vals[1]) {
					return '';
				} else {
					return vals;
				}
			}
		},

		_getWidgetValue: function () {
			var self = this, $self = this.element, vals;
			vals = self._getValues();
			vals = vals && vals.join('-');
			return self._wrapValue(vals);
		}
	});

	add_widget('timeSelectMinsRangeWidget', timeSelectMinsRangeWidget);
})(jQuery);
