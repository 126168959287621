/* jshint jquery: true, unused: vars */
/*
  $jq.findNotUnder(selector_a, selector_b);

  Finds items under $jq that match selector_a, but are not under elements matching selector_b (not including $jq itself).

  EXAMPLE:

  <outer>
   <findme id="1" /><!-- returned -->
    <wrapper>
    <findme id="2" /><!-- not returned -->
    <foo>
      <findme id="3" /><!-- not returned -->
    </foo>
   </wrapper>
   <findme id="4" /><!-- returned -->
   <bar>
    <findme id="5" /><!-- returned -->
   </bar>
  </outer>

  ---

  $('outer').findNotUnder('findme', 'wrapper'); // Will return #1, #4, and #5, but not #2 or #3, because they are under a <wrapper>.

----------------------------------------------------------------------------------------------------------------------------------------------

  $jq.findNearest(selector)

  Finds items under $jq that match selector, but are not under other elements matching $selector.

  EXAMPLE:

  <outer>
   <findme id="1"><!-- returned -->
    <foo>
     <findme id="2" /><!-- not returned -->
    </foo>
   </findme>
   <findme id="3" /><!-- returned -->
   <bar>
    <findme id="4" /><!-- returned -->
   </bar>
  </outer>

  ---

  $('outer').findNearest('findme'); // Will return #1, #3, and #4, but not #2, because it is under an <outer>.

*/

(function ($) {

	$.fn.findNotUnder = function (selector, selector_not_under) {
		var $self = this, $find, self_idx, find_idx, dom_closest, all_results = [];

		for (self_idx = 0; self_idx < $self.length; self_idx++) {
			$find = $self.eq(self_idx).find(selector);
			if (!$find.length) { continue; }
			for (find_idx = 0; find_idx < $find.length; find_idx++) {
				dom_closest = $find[find_idx].parentNode;
				closest:while (dom_closest) {
					if (dom_closest === $self[self_idx]) {
						// Found the context element. There are no matching items between the found element and the context. This IS a nearest match.
						all_results.push($find[find_idx]);
						break closest;
					} else if ($(dom_closest).is(selector_not_under)) {
						// Found a selector match between the found item and the context element. This is NOT a nearest match.
						break closest;
					}
					dom_closest = dom_closest.parentNode;
				}
			}
		}
		return $(all_results);
	};

	$.fn.findNearest = function (selector) {
		return this.findNotUnder(selector, selector);
	};
})(jQuery);
