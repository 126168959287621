/* jshint jquery: true, unused: vars */
/* global add_widget */
/* This class is meant to be used as a base class by other page button widgets. */

(function( $ ){
	var pageCancelButtonWidget = $.extend({}, $.ui.pageButtonWidget.prototype, {
		options: { href: '#' },
		_afterFormChange: function () {
			this[this.options.dirty ? 'enable' : 'disable']('afc');
		}
	});

	add_widget('pageCancelButtonWidget', pageCancelButtonWidget);
})(jQuery);
