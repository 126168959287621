/* Creates a data_item with a pre-made input, select, or textarea

USAGE:

{
  entity_template: 'input_item',
  title: 'Row title',
  type: 'text',
  input_name: 'bbx_input_name',
  input_units: 'days', ( displayed to the right of the input element )
  input_options: { (any other entity options for the input element) },
  info: 'Optional extra informational text included under the input'
  info_options: { (any other entity options for the info DIV ) },
  select_options: [ { options for select inputs } ],
  validate:  ** generates an aopbSelect widget or appends your conditions to already defined aopbState widget

    ** simple example:
    { conditions: "_EMAIL_", message: "Enter a valid email!" }

    ** complex example:  
    [
       {
  	  conditions: [ 
              { validator: '4', condition_is: 'literal', operator: '>=', },
              { validator: '_NUMERIC_', join: 'and' } 
	  ],
	  message: 'INVALID!'
       },
       {
          conditions: { ..... }
       }
    ]

    ** value example:
    { conditions: "_TRUEISH_", value: 10} // Will set value of field to 10 if it is a non-trueish value
}

"type" can be any "input" type, as well as "textarea", "select", or "div".
The "select_options" array is also supported for "select" types.

*/


register_template('input_item', function(field_def) {
    var entity_tag = 'input';
    var has_type_attr = true;
    // This comes in as a reference, so to keep from duplicating things every time
    // the same refence is passed, clone it and use the local version
    var local_fd = $.extend(true, {}, field_def);

    if ($.inArray(local_fd.type, ['select','textarea','div']) > -1) {
		entity_tag = local_fd.type;
		delete local_fd.type;
		has_type_attr = false;
    }

    var input_def = {
		type: local_fd.type || 'text',
		name: local_fd.input_name || '',
		attributes: local_fd.input_attributes || {}
    };

    if (entity_tag === 'div') {
		local_fd.input_options = local_fd.input_options || {};

		// If this has no "widgets" or "entity_template"...
		if (!(local_fd.input_options.widgets || local_fd.input_options.entity_template)) {
			// Set widgets to either an array of .options.widget or (if there is none) the string "widget"
			if (local_fd.input_options.widget) {
				local_fd.input_options.widgets = [ local_fd.input_options.widget ];
			} else {
				local_fd.input_options.widgets = ['widget'];
			}
		}
    }

    if (!has_type_attr) { delete input_def.type; }

    var input = build_standard_entity_options(entity_tag, '', input_def);

    delete local_fd.type;

    if (local_fd.input_options) {
		$.extend(true, input, local_fd.input_options);
		delete local_fd.input_options;
    }

    // Keys that need to be moved to options on the "input" object
    var move_to_input_options = [ 'select_options' ];
    var move_to_input_attrs = [ 'size', 'maxlength' ];

    for (var i in move_to_input_options) {
		var k = move_to_input_options[i];
		if (local_fd[k]) {
			// Looks overwrought, but you can't make an object literal with a variable key
			var new_kv = {};
			new_kv[k] = local_fd[k];

			// Extend, so we don't clobber anything there
			$.extend(true, input, new_kv);

			delete local_fd[k];
		}
    }

    if (!input.attributes) { input.attributes = {}; }

    for (var i in move_to_input_attrs) {
		k = move_to_input_attrs[i];
		if (local_fd[k]) {
			var new_kv = {};
			new_kv[k] = local_fd[k];

			// Extend, so we don't clobber anything there
			$.extend(true, input.attributes, new_kv);

			delete local_fd[k];
		}
    }

    local_fd.elements = local_fd.elements || [];
    local_fd.elements.push(input);

    if (local_fd.input_units) {
		var unit_def = {
			entity: 'span',
			text: local_fd.input_units,
			attributes: { 'class': 'input-units', style: 'margin-left: 2px' }
		}
		local_fd.elements.push(unit_def);
    }

	// If this is a password field, make a "dummy" field to keep Chrome from auto-filling it
	if (field_def.type === 'password' && !field_def.allow_autofill) {
		local_fd.elements[0].attributes.autocomplete = 'off';
		['text','password'].forEach(function (type) {
			local_fd.elements.unshift({
				entity: 'input',
				attributes: { type: type, 'class': 'autofill-spoiler', style: 'display: none !important;' }
			});
		});
	}

    if (local_fd.info) {
		var info_def = {
			entity: 'div',
			attributes: { 'class': 'info' },
			text: local_fd.info
		}

		if (local_fd.info_options) {
			$.extend(true, info_def, local_fd.info_options);
		}

		local_fd.elements.push(info_def);
    }

    if (local_fd.validate) {
		var elem = local_fd.elements[0];

		var validate_func = function(validate) {
			var operator = validate.operator || '==', actions = {}, else_actions = {};
			if (elem.widgets) {
				if ( !($.isArray(elem.widgets)) ) {
					elem.widgets = [ elem.widgets ];
				}
			} else {
				elem.widgets = [];
				elem.widget_options = {};
			}

			// If message is defined, add an invalid message to the else_actions
			if (validate.message) {
				actions.invalid = false;
				else_actions.invalid = validate.message;
			}

			// If value is defined, add a value to the else_actions
			if (validate.value) {
				else_actions.value = validate.value;
			}

			var stateWidget = {
				closest: 'dd',
				states: [
					{
						conditions: [],
						actions: actions,
						else_actions: else_actions
					}
				]
			};

			var cond = {};
			if ($.isArray(validate.conditions)) {
				for (var c in validate.conditions) {
					operator = validate.conditions[c].operator ? validate.conditions[c].operator : operator;
					cond = { a: '$$', op: operator, b: validate.conditions[c].validator };
					if (validate.conditions[c].condition_is) {
						cond.b_is = validate.conditions[c].validator_is;
					}
					if (validate.conditions[c].join) {
						cond.join = validate.conditions[c].join;
					}
					stateWidget.states[0].conditions.push(cond);
				}
			} else {
				cond = { a: '$$', op: operator, b: validate.conditions };
				if (validate.condition_is) {
					cond.b_is = validate.condition_is;
				}
				stateWidget.states[0].conditions.push(cond);
			}

			if ($.inArray('aopbStateWidget', elem.widgets) > -1 ) {
				if ( !$.isArray(elem.widget_options.aopbStateWidget.states) ) {
					elem.widget_options.aopbStateWidget.states = [ elem.widget_options.aopbStateWidget.states ];
				}
				elem.widget_options.aopbStateWidget.states.push(stateWidget.states[0]);
			} else {
				elem.widget_options['aopbStateWidget'] = stateWidget;
				elem.widgets.push('aopbStateWidget');
			}
		};

		if ($.isPlainObject(local_fd.validate)) {
			validate_func(local_fd.validate);
		} else {
			for (var indx in local_fd.validate ) {
				validate_func(local_fd.validate[indx]);
			}
		}
    }

    var dl = build_standard_entity_options('dl', 'dataItemWidget', local_fd);

    return dl;
});
