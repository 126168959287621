/* jshint jquery: true, unused: vars */
/* global add_widget */
/* A single-purpose widget for an "Edit" button on FMFM/ACR callflows, in the time/rule list */

(function( $ ){
	var editCallflowButtonWidget = $.extend(true, {}, $.ui.overlayButtonWidget.prototype, {
		options: {
			pass_all_rest_params: true
			// "overlay" param is set by the screen def, since it varies based on fmfm or not
		},

		_clickHandler: function (e) {
			var self = this, $self = this.element;
			self.options.rest_params = $.extend(self.options.rest_params || {}, { bbx_callflow_id: $self.closest('td,dd').find('[name=bbx_callflow_id],[name=bbx_callflow_id_fallback]').val() });
			$.ui.overlayButtonWidget.prototype._clickHandler.apply(this, arguments);
		}
	});

	add_widget('editCallflowButtonWidget', editCallflowButtonWidget);
})(jQuery);
