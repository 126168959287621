/*
  kcListenerWidget: A widget to listen for the Konami Code while the widget exists
*/

(function( $ ){
    var kcListenerWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			template: false,
			template_selector: false,
			sequence: [ 38, 38, 40, 40, 37, 39, 37, 39, 66, 65 ],
			onSuccessGoto: '#!/bps/easteregg'
		},

		checkKey: function (e) {
			atKey = this.options.atKey || 0;
			if (e.which === this.options.sequence[atKey]) {
				atKey++;
			} else {
				atKey = 0;
			}
			this.options.atKey = atKey;
			if (atKey === this.options.sequence.length) {
				this.options.atKey = 0;
				if (isAllowed) {
					if (isAllowed({ permission: ['ADMIN'] })) {
						location.href = this.options.onSuccessGoto;
					} else if (CUI.Dialog) {
						new CUI.Dialog({ title: 'There are no easter eggs here', text: 'That combination of keys does nothing special.' });
					} else {
						return;
					}
				} else {
					return;
				}
			}
		},

		_beforeInit: function () {
			this._bind($(document), 'keyup.klw', this.checkKey.bind(this));
		},
		_destroy: function () {
			this._unbind($(document), 'keyup.klw');
		}
    });
    
    add_widget('kcListenerWidget', kcListenerWidget);
})(jQuery);
