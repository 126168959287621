/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){
	var diagnosticsJSONWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
		},

		value_widget: true,
		set_value_widget: true,

		setValue: function () {},

		fillData: function (d) {
			var self = this, $self = this.element, $list = $('<ul>'), list_idx, length, json_data, data_array = [], $bullet;

			json_data = d[CUI.getElementName($self)];
			if (json_data) {
				data_array = JSON.parse(json_data);
				length = data_array.length;
				for ( list_idx = 0; list_idx < length; list_idx++ ) {
					$bullet = $('<li>').text(data_array[list_idx]);
					$list.append($bullet);
				}
				self.options.$list = $list;
			}

			$self.empty().append(self.options.$list);

		}

	});

	add_widget('diagnosticsJSONWidget', diagnosticsJSONWidget);
})(jQuery);
