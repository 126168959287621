/*
 * AJAX-error handler class: Displays errors in the common way, but allows subclassing to show errors differently if necessary
 * 
 * USAGE:
 *   aeh = new AJAXErrorHandler(OPTIONAL OBJECT {
 *     OPTIONAL error_text: {
 *       <error code> : <STRING error text>
 *     },
 *     OPTIONAL after_error: function (xhr, text, error) { ... }
 *   });
 *
 *   ...
 *
 *   aeh.handle(xhr, text, error);
 * 
 * AJAXErrorHandler objects can be re-used, as no state except options is stored.
 * 
 */

function AJAXErrorHandler(options) {
	$.extend(true, this.options, options || {});
};

AJAXErrorHandler.prototype = {
	options: {
		error_text: {
			session:    'Your login session has expired: Please wait',
			permission: 'You do not have the proper permission to complete the operations',
			notfound:   'There was an internal error processing your request (Resource Not Found)',
			controller: 'There was an internal error processing your request (Resource Internal Error)',
			timeout:    'There was no response from the server',
			demo:       'This operation is not allowed in demonstration mode',
			other:      'There was an internal error processing your request'
		},
		after_error: undefined
	},

	handle: function (xhr, text, error) {
		var self = this;

		if (text === 'timeout') {
			self.err_timeout(xhr, text, error);

		} else if (xhr.status == 401) {
			self.err_401(xhr, text, error);
			
		} else if (xhr.status == 404) {
			self.err_404(xhr, text, error);

		} else if (xhr.getResponseHeader('X-Error-Source') === 'catalyst' || xhr.getResponseHeader('X-Error-Source') === 'dynamic') {
			self.err_controller(xhr, text, error);

		} else if (error !== 'error' && xhr.status !== 0) {
			self.err_other(xhr, text, error);
		}

		if (this.options.after_error) {
			this.options.after_error(xhr, text, error);
		}
	},
	
	_unpack_error: function (xhr) {
		var self = this, errors;
		try {
			data = JSON.parse(xhr.responseText);
		} catch (e) {
			// The data was probably not in JSON format
			data = { error: self.options.error_text.other };
		}

		errors = (data.error ? [ data.error ] : data.errors) || undefined;
		errors = errors.map(function (val) { return ((typeof val === 'object') ? val.text : val); });  
		
		if (data.errors) {
			return data.errors;
		} else if (data.error) {
			return [data.error];
		} else {
			return [{ error: self.options.error_text.controller }];
		}
	},

	throw_error: function (error_message, xhr, text, error) {
		if (window.SUPPRESS_ERROR_DISPLAY) {
			if (window.console && window.console.error) {
				console.error('Resource error suppressed: ', error_message);
			}
		} else {
			$(window).trigger('CUIGlobalError', { text: error_message });
		}
	},
	
	session_fail: function () {
		$(window).trigger('CUISessionFailed');	
	},
	
	/// ERRORS ///

	err_timeout: function (xhr, text, error) {
		var self = this;
		self.throw_error(self.options.error_text.timeout);
	},

	err_404: function (xhr, text, error) {
		var self = this;
		self.throw_error(self.options.error_text.notfound, xhr, text, error);
	},

	err_401: function (xhr, text, error) {
		var self = this, xar;
		xar = xhr.getResponseHeader('X-Auth-Required');

		if (xar && xar.length) {
			self.throw_error(self.options.error_text.session, xhr, text, error);
			self.session_fail();
		} else if (window.loginData && loginData.demo) {
			self.throw_error(self.options.error_text.demo, xhr, text, error);			
		}
	},

	err_controller: function (xhr, text, error) {
		var self = this, errors;
		errors = self._unpack_error(xhr);
		errors.forEach(self.throw_error, self);
	},

	err_other: function (xhr, text, error) {
		var self = this;
		self.throw_error(self.options.error_text.other);
	}

};
