/* jshint jquery: true */
/* global entity, debugLog */
/*
  elgen: A set of [el]ement [gen]erator functions, used to create common elements for screen definitions that are too complex for entity_templates
  Each elgen should be a function that takes an optional params object and returns the element definition object.

  Elgens are available by the globally-scoped "elgen" function. It returns a JS object that can be put directly into an "elements" array.

  USAGE:

  {
    ...,
    elements: [
      { ... },
      elgen(name_of_elgen, OPTIONAL { params }, OPTIONAL { extend }),
      { ... }
    ]
  }

*/

var elgen;

(function () {
	"use strict";
	var auto_attendant_actions = {
		'': 'Do Nothing',
		'menu-exec-app-transfer': 'Transfer to an Extension or Number',
		'menu-exec-app-user-voicemail': 'Transfer to a Person' + entity.rsquo + 's Voicemail',
		'menu-top': 'Go to the Top Menu',
		'menu-back': 'Go Back One Menu',
		'menu-exit': 'Hang Up',
		'menu-sub': 'Go to Another Automated Attendant',
		'menu-exec-app-directory': 'Dial-By-Name Directory',
		'menu-exec-app-playback': 'Play a Sound File',
		'menu-exec-app-playback-hangup': 'Play a Sound File, then Hang Up'
	};

	var elgens = {

		// Create new elgens under this object

		recurring_backup_select: function (params) {
			// PARAMS:
			var monthly = params.monthly || false, options, select_name;

			var days_of_the_week = [
				{ value: 0, text: 'Sunday' },
				{ value: 1, text: 'Monday' },
				{ value: 2, text: 'Tuesday' },
				{ value: 3, text: 'Wednesday' },
				{ value: 4, text: 'Thursday' },
				{ value: 5, text: 'Friday' },
				{ value: 6, text: 'Saturday'}
			];
			var days_of_the_month = [];
			for (var i = 1; i < 32; i++) {
				days_of_the_month.push({ value: i, text: i });
			}
			days_of_the_month.push({value: -1, text: 'Last Day'});

			options = monthly ? days_of_the_month : days_of_the_week;
			select_name = monthly ? 'bbx_backup_recurring_day_of_month' : 'bbx_backup_recurring_day_of_week';

			var out = {
				entity: 'select',
				attributes: { 'name': select_name },
				select_options: options
			};

			return out;
		},

		admin_password_page_module: function () {
			return ({
				entity_template: 'page_module',
				legend: 'Administrator Account',
				form_action: '/gui/system/admin_password',
				buttons: ['help'],
				help_id: 'configuration_system_admin_pass',

				elements: [

					{
						entity_template: 'input_item',
						type: 'password',
						input_name: 'old_password',
						title: 'Current Password:'
					},
					{
						entity_template: 'input_item',
						type: 'password',
						input_name: 'new_password',
						title: 'New Password:'
					},
					{
						entity_template: 'input_item',
						type: 'password',
						input_name: 'new_password_confirm',
						title: 'Confirm New Password:',
						input_options: {
							widgets: ['aopbStateWidget'],
							widget_options: {
								closest: '.pageModuleWidget',
								states: {
									conditions: { a:'$$', op:'==', b:'$new_password' },
									actions: { invalid: false },
									else_actions: { invalid: 'Password and confirmation fields do not match.' }
								}
							}
						}
					}

				] // END page module elements
			}); // END page module: Password
		},

		area_code: function () {
			return (
				{
					entity_template: 'input_item',
					title: 'Area Code:',
					type: 'text',
					input_name: 'area_code',
					size: 8,
					input_options: {
						widgets: ['aopbStateWidget'],
						widget_options: { aopbStateWidget: {
							states: [{
								conditions: { a: '$$', op: '==', b: '^[0-9]{1,18}$', b_is: 'regexp' },
								actions: { invalid: false },
								else_actions: { invalid: 'Enter a valid area code.' }
							}]
						}}
					}
				}
			);
		},

		auto_attendant_action: function (params) {
			// PARAMS: digit
			var d = params.digit || 0;
			var el = {
				entity_template:'container',
				attributes: { 'class': 'key_action_' + d },
				elements: [
					{
						entity_template: 'input_item',
						title: 'When the caller presses ' + entity.ldquo + d + entity.rdquo + ':',
						type: 'select',
						input_options: { attributes: { 'class': 'actionType' + d } },
						input_name: 'bbx_auto_attendant_key_action_' + d,
						select_options: [
							{ value: '', text: auto_attendant_actions[''] },
							{ value: 'menu-exec-app-transfer', text: auto_attendant_actions['menu-exec-app-transfer'] },
							{ value: 'menu-exec-app-user-voicemail', text: auto_attendant_actions['menu-exec-app-user-voicemail'] },
							{ value: 'menu-top', text: auto_attendant_actions['menu-top'] },
							{ value: 'menu-back', text: auto_attendant_actions['menu-back'] },
							{ value: 'menu-exit', text: auto_attendant_actions['menu-exit'] },
							{ value: 'menu-sub', text: auto_attendant_actions['menu-sub'] },
							{ value: 'menu-exec-app-directory', text: auto_attendant_actions['menu-exec-app-directory'] },
							{ value: 'menu-exec-app-playback', text: auto_attendant_actions['menu-exec-app-playback'] },
							{ value: 'menu-exec-app-playback-hangup', text: auto_attendant_actions['menu-exec-app-playback-hangup'] }
						]
					},
					elgen('auto_attendant_action_options', { digit: d })
				]
			};
			return el;
		},

		auto_attendant_action_options: function (params) {
			var d = params.digit;
			var doic = {};

			var el = {
				entity_template: 'switch',
				closest: '.pageModuleWidget',
				selector: 'select[name=bbx_auto_attendant_key_action_' + d + ']',
				data_on_interactive_change: doic,
				switch_elements: {
					'': {
						entity_template: 'data_item',
						elements : [
							{
								entity: 'div',
								text: 'No operation on this digit. The caller will hear the ' + entity.ldquo + 'Invalid Sound' + entity.rdquo + ' if one is configured.'
							}
						]
					},
					'menu-exec-app-transfer': {
						entity_template: 'data_item',
						title: 'Extension or Number:',
						elements: [
							{
								entity_template: 'extension_picker_suggest',
								type: ['user','group','queue','valet_parking','conference','router','phone'],
								name: 'bbx_auto_attendant_key_arg_' + d,
								value_key: 'bbx_extension_block_begin'
							}
						]
					},
					'menu-exec-app-user-voicemail': {
						entity_template: 'data_item',
						title: 'User Extension',
						elements: [
							{
								entity_template: 'extension_picker_select',
								type: 'user',
								name: 'bbx_auto_attendant_key_arg_' + d,
								value_key: 'bbx_extension_value'
							}
						]
					},
					'menu-top': {
						entity_template: 'data_item',
						elements: [
							{
								entity: 'div',
								text: 'This digit will return the caller to the menu they originally started on.'
							}
						]

					},
					'menu-back': {
						entity_template: 'data_item',
						elements: [
							{
								entity: 'div',
								text: 'This digit will return the caller to the menu they heard prior to this one.'
							}
						]
					},
					'menu-exit': {
						entity_template: 'data_item',
						elements: [
							{
								entity: 'div',
								text: 'Disconnect the call.'
							}
						]
					},
					'menu-sub': {
						entity_template: 'data_item',
						title: 'Select Attendant',
						elements: [
							{
								entity_template: 'select_populate',
								name: 'bbx_auto_attendant_key_arg_' + d,
								rest: '/gui/extension/list',
								default_first: false,
								rest_params: {
									type: 'auto_attendant',
									primary: '1',
									sortby: 'sortname',
									rows: '1000'
								},
								text_field: 'show_name',
								value_field: 'bbx_auto_attendant_id'
							}
						]
					},
					'menu-exec-app-directory': {
						entity_template: 'data_item',
						elements: [
							{
								entity: 'div',
								text: 'Access the Dial-By-Directory'
							}
						]
					},
					'menu-exec-app-playback': {
						entity_template: 'data_item',
						title: 'Sound File:',
						elements: [
							{
								entity_template: 'sound_picker',
								name: 'bbx_auto_attendant_key_arg_' + d,
								allow_none: false
							}
						]
					},
					'menu-exec-app-playback-hangup': {
						entity_template: 'data_item',
						title: 'Sound File:',
						elements: [
							{
								entity_template: 'sound_picker',
								name: 'bbx_auto_attendant_key_arg_' + d,
								allow_none: false
							}
						]
					}
				},
				default_elements: { entity_template: 'input_item', type: 'div', input_options: { text: 'Item ' + d + ' is something else' }, title: 'What?:' }
			};
			return el;
		},

		auto_provision_page_module: function (params) {
			var out = {
				entity_template: 'page_module',
				legend: 'Automatic Provisioning',
				buttons: [ 'help' ],
				help_id: 'automatic_provisioning',
				elements: [
					{
						entity_template: 'input_item',
						title: 'Automatic Provisioning',
						type: 'select',
						input_name: 'auto_prov',
						input_options: { attributes: { 'class': 'field-select state-uniform' } },
						select_options: [
							{
								value: '1',
								text: 'Automatic Provisioning On'
							},
							{
								value: '0',
								text: 'Automatic Provisioning Off'
							}
						]
					},
					{
						entity_template: 'data_item',
						title: '',
						elements: [
							{
								entity_template: 'container',
								'class': 'info',
								elements: [
									{
										entity: 'span',
										text: 'If you are using the Phone Server on a network with other PBX devices, you may wish to turn off automatic phone provisioning. When automatic provisioning is off, phones must be manually provisioned using the Phone System' + entity.rsquo + 's IP address. See your quick-start guide or manual for provisioning instructions.'
									}
								]
							}
						]
					}
				]
			};

			if (params.form_action) {
				out.form_action = params.form_action;
			}

			return out;
		},

		cid_selector: function (params) {
			/* PARAMS:
	       type: 'bbx_provider_gateway' | 'bbx_tdm_card_port'
	    */

			var type = params.type || 'bbx_provider_gateway';

			var out = {
				entity_template: 'page_module',

				legend: "Outbound Caller ID Number",
				buttons: params.buttons || [ "help" ],
				help_id: params.help_id,
				elements: [
					{
						entity_template: 'data_item',
						title: 'Use This Number:',
						elements: [
							{
								entity: 'select',
								attributes: {
									name: type + '_force_caller_id_when'
								},
								select_options: [
									{
										value: 'NEVER',
										text:  'No custom Caller ID number'
									},
									{
										value: 'NORMAL',
										text:  'Custom Caller ID number (unless overridden)'
									},
									{
										value: 'ALWAYS',
										text:  'Force Custom Caller ID number'
									}
								]
							}
						]
					},
					{
						entity_template: 'data_item',
						title: "Custom Caller ID Number:",
						widgets: ['aopbStateWidget'],
						widget_options: {
							aopbStateWidget: {
								'closest': 'form',
								states: {
									conditions: { a: '$' + type + '_force_caller_id_when', op: '==', b: 'NEVER', b_is: 'literal' },
									actions: { enabled: false, visible: false, _cascade: true },
									else_actions: { enabled: true, visible: true, _cascade: true }
								}
							}
						},
						elements: [
							{
								entity: "input",
								widgets: ['aopbStateWidget','keyFilterInputWidget'],
								widget_options: {
									aopbStateWidget: {
										states: [{
											conditions: [
												{ a: '$$', op: '==', b: '[+0-9*#]+', b_is: 'regexp' },
												{ join: 'or', a: '$' + type + '_force_caller_id_when', op: '==', b: 'NEVER', b_is: 'literal' }
											],
											actions: { invalid: false },
											else_actions: { invalid: 'Enter a valid phone number without separators or letters' }
										}]
									},
									keyFilterInputWidget: {
										allow_keys_regexp: '(NUM)?[0-9]',
										allow_shift: false
									}
								},
								attributes: {
									name: type + '_caller_id',
									type: 'text',
									size: 32
								}
							}
						]
					}
				]
			};

			return out;
		},

		did_page_modules: function (params) {

			/* PARAMETERS:

	       submit_to: '(url)',
	       table_from: '(url)'

	    */

			params = params || {};

			return ([
				{
					entity_template: "page_module",
					legend: "Add External (DID) Numbers",
					form_action: params.submit_to || '/gui/gateway/external',
					form_data_js: { no_initial_get: true, force_reset: true, method: 'POST' },
					buttons: [ "help" ],
					help_id: 'add_external_numbers',
					elements: [
						{
							entity_template: "phone_number_tokenizer",
							name: "bbx_extension_value_mixed",
							output_type: 'comma-sep',
							accept_data_from_parent: false,
							allow_ranges: true,
							clear_on_success: true
						},
						{
							entity: 'div',
							attributes: { 'class':'info' },
							text: 'Use only numbers, separated with commas, spaces, or Return/Enter. Do not use dashes or hyphens in single phone numbers. For ranges, use two numbers separated by a dash (-). Save the form to add the new External Numbers.'
						}
					]
				},
				{
					entity_template: "page_module",
					legend: "Current External Numbers",
					buttons: [ "help" ],
					help_id: 'current_external_numbers',
					elements: [
						{
							entity_template: 'data_table',
							rest: params.table_from || '/gui/gateway/external',
							accept_data_from_parent: false,
							dataEvents: {
								bind: {
									'provider_external' : ''
								}
							},

							refresh_on: [
								{ method: 'closest', selector: '.containerWidget', event: 'SubmitSuccess' }
							],


							data_source: 'external',
							extra_row_data: ['bbx_extension_id'],
							primary_keys: [ 'bbx_extension_id' ],

							add_edit_action: false,
							delete_action: {},
							oLanguage: {
								sZeroRecords: 'No External Numbers (DIDs)'
							},

							/* These options show/hide UI features in dataTable */
							paginate:      false,
							length_change: false,
							filtering:     true,
							processing:    true,

							sortable:      false,
							details_row:   false,

							/* An array of objects, one for each column */
							columns: [
								{
									header:     'Extension',
									column:     'bbx_extension_value',
									sortable: false,
									data_type: 'num-html',
									formatted: {
										format: { field: '@bbx_extension_value', formatter: 'phone_number' }
									}
								}
							],

							/* Actions and buttons that live in each individual row */
							/* Actions and buttons that live outside the individual rows, in the table chrome */
							table_actions: {
								delete_rows: {
									text:     'Remove Selected Numbers',
									location: "bottom",

									confirm: {
										title: 'Remove Selected',
										text:  'Are you sure you want to remove the selected external numbers?'
									},
									validate_rest: '/gui/gateway/check_delete_external',
									validate_rest_container: 'check_delete_external'

								},
								/*

				  /* "select" options control checkboxes for multiple delete */
								select_row:      true,
								select_all_rows: true
							}
						}
					]
				}
			]); // END return statement
		}, // END FUNCTION elgen.did_page_modules()

		event_reporting_page_module: function () {
			return (		    {
				entity_template: 'page_module',
				legend: 'Event Notifications',
				form_action: '/gui/system/notification',
				buttons: ['help'],
				help_id: 'configuration_system_notification',

				elements: [
					{
						entity_template: 'input_item',
						type: 'select',
						input_name: 'level',
						title: 'Normal warning level:',
						select_options: [
							{ value: 0, text: 'None: Send no message' },
							{ value: 1, text: 'Emergency: System is unusable' },
							{ value: 2, text: 'Alert: Action must be taken' },
							{ value: 3, text: 'Critical: Critical conditions' },
							{ value: 4, text: 'Error: An error has occurred' },
							{ value: 5, text: 'Warning: Warning conditions' },
							{ value: 6, text: 'Notice: Normal but significant condtion' }
						]
					},
					{
						entity_template: 'input_item',
						type: 'select',
						input_name: 'interval',
						title: 'Send warning every:',
						select_options: [
							{ value: 300, text: '5 minutes' },
							{ value: 600, text: '10 minutes' },
							{ value: 1200, text: '20 minutes' },
							{ value: 1800, text: '30 minutes' },
							{ value: 3600, text: '1 hour' }
						]
					},
					{
						entity_template: 'input_item',
						type: 'select',
						input_name: 'immediate_level',
						title: 'Immediate Warning Level:',
						info: 'Immediate warnings are sent when they occur, regardless of the ' + entity.ldquo + 'Send Messages every' + entity.rdquo + ' setting.',
						select_options: [
							{ value: 0, text: 'None: Do not send immediate warnings' },
							{ value: 1, text: 'Emergency: System is unusable' },
							{ value: 2, text: 'Alert: Action must be taken immediately' },
							{ value: 3, text: 'Critical: Critical conditions' },
							{ value: 4, text: 'Error: An error has occurred' },
							{ value: 5, text: 'Warning: Warning conditions' },
							{ value: 6, text: 'Notice: Normal but significant conditions' }
						]
					},
					{
						entity_template: 'input_item',
						type: 'text',
						input_name: 'addresses',
						title: 'Email Addresses:',
						info: 'Separate multiple email addresses with commas',
						validate: {
							conditions: [
								// Emails or blank is okay
								{ validator: '_EMAIL_'  }
							],
							message: 'Please enter valid email addresses'
						}
					}
				] // END page module elements
			}); // END page module: Event Notifications
		},

		migrate_dialog: function (params) {
			params = params || {};
			var out = {
				entity: 'span',
				attributes: {
					name: 'changePrimaryExtensionSelect'
				},
				widgets: [ 'extensionSelectWidget' ],
				widget_options: {
					extensionSelectWidget: {
						allow: params.allow || 'nextfree single',
						lock_block: false,
						allow_lock_block_toggle: false,
						next_free_value: { bbx_extension_value: '' }
					}
				}
			}; // END var out

			return out;
		},

		recording_policy: function (params) {
			params = params || {};

			var pm_elements = [
				{
					entity_template: 'data_item',
					title: 'Save Recorded Calls:',
					elements: [
						{
							entity_template: 'call_recording_days',
							name: 'record_keep_days',
							validate_max: 2147483647
						}
					]
				},
				{
					entity_template: 'input_item',
					title: 'Send to Email Address:',
					type: 'text',
					input_name: 'record_mailto',
					'class': 'email-address-input',
					info: (params.type === 'group' ? 'If members of this group have call recording enabled, setting this value will send their recordings to this email address, even if Group recording is turned off.' : false),
					input_options: {
						widgets: ['aopbStateWidget'],
						widget_options: {
							aopbStateWidget: {
								closest: '.page_module',
								states: [
									( params.type != 'group' ? {
										conditions: { a: '$record_keep_days', op: '>', b: '0' },
										actions: { enabled: true },
										else_actions: { enabled: false }
									} : {} ), // don't use this condition for groups, where email can be configured w/o group call recording enabled,
									{
										conditions: [
											{             a: '$$', op: '==', b: '_EMAIL_' },
											{ join: 'or', a: '$$', op: '==', b: '', b_is: 'literal' }
										],
										actions: { invalid: false },
										else_actions: { invalid: 'Please enter a valid email address.' }
									}
								]
							}
						}
					}
				}
			]; // END page module elements

			if (params.type === 'user') {
				pm_elements.unshift({
					entity_template: 'aopb_container',
					states: [{
						conditions: { a: '@group_record_keep_days', op: '==', b: '_TRUEISH_' },
						elements: [{
							entity_template: 'format',
							'class': 'info',
							format: 'This user'+entity.rsquo+'s calls are being recorded by the group ' + entity.ldquo + '$1' + entity.rdquo + '. Recordings will be retained for at least $2 day(s)',
							fields: [
								{ field: '@group_record_name' },
								{ field: '@group_record_keep_days' }
							]
						}]
					}]
				}); // End unshift
			}

			var out = {
				entity_template: 'page_module',
				form_action: params.rest,
				legend: 'Call Recording Policy',
				buttons: ['help'],
				help_id: '',
				elements: pm_elements
			}; // END page module

			return out;
		}, // END elgens.recording_policy

		secondary_page_module: function (params) {
			params = params || {};
			var type_name = params.type == 'auto_attendant' ? 'auto attendant' : params.type;
			var out = {
				entity_template: 'page_module',
				legend: params.legend || 'Secondary Extensions',
				buttons: ['help'],
				elements: [
					{
						entity_template: 'data_table',
						click_action: '',
						data_source: params.data_source || 'secondaries',
						name_column: 'bbx_extension_value',
						clear_row_data_on_change: true,
						primary_keys: ['bbx_extension_id'],
						add_edit_action: {
							rest: params.add_rest || ('/gui/extension/' + params.type + '/secondaries'),
							filter_rest_params: ['bbx_extension_id']
						},
						delete_action: {
							rest: params.delete_rest || ('/gui/extension/' + params.type + '/secondaries'),
							filter_rest_params: ['bbx_extension_id'],
							only_params: [
								'bbx_extension_id',
								'bbx_user_id',
								'bbx_group_id',
								'bbx_queue_id',
								'bbx_conference_id',
								'bbx_router_id',
								'bbx_auto_attendant_id'
							]
						},
						paginate: false,
						length_change: false,
						filtering: false,
						oLanguage: {
							sZeroRecords: 'There are no secondary extensions mapped to this ' + type_name + '.'
						},
						attributes: {
							'class': params['class'] || params.type + '_secondary_extensions'
						},
						accept_data_from_parent: true,
						extra_row_data: [
							'bbx_extension_value',
							'extension_next_free',
							'bbx_extension_block_begin',
							'bbx_extension_block_end',
							'bbx_extension_id'
						],
						columns: [
							{
								header: params.header || 'Secondary Extension',
								column: 'bbx_extension_value',
								data_type: 'num-html',
								formatted: {
									format: {
										field: '@bbx_extension_value',
										formatter: 'phone_number'
									}
								},
								add_element: {
									entity: 'div',
									widgets: [ 'extensionSelectWidget' ],
									attributes: {
										name: 'bbx_extension_id'
									},
									widget_options: {
										extensionSelectWidget: {
											allow: 'nextfree single block external'
										}
									}
								}
							}
						],
						row_actions: {
							animate: false,
							animate_speed: 'slow',
							type: 'expand',
							edit_save: { text: 'Save' },
							edit_cancel: { text: 'Cancel' },
							add_save: { text: 'Add' },
							add_cancel: { text: 'Cancel' },
							delete_row: {
								text: 'Delete',
								confirm: {
									title: 'Confirm Secondary Extension Removal',
									text: 'Are you sure you would like to remove this secondary extension?'
								}
							}
						},
						table_actions: {
							add_row: {
								text: params.add_text || 'Add Secondary Extension',
								location: 'bottom',
								button_class: params.add_button_class || params.type + '_add_secondary_extension'
							},
							delete_rows: {
								text: 'Delete Selected',
								location: 'bottom',
								confirm: {
									title: 'Delete Selected Extensions',
									text: 'Are you sure you want to remove the selected extensions?'
								}
							},
							select_row: true,
							select_all_rows: true
						}
					}
				]
			};

			return out;
		}, // END elgens.secondary_page_module


		subscription_status_item: function (params) {
			var title = params.title;
			var type  = params.type;

			var out = {
				entity_template: 'input_item',
				title: title + ':',
				type: 'div',
				input_options: {
					widgets: ['aopbContainerWidget'],
					widget_options: {
						aopbContainerWidget: {
							states: [{
								conditions: { a: '@' + type + '_status', op: '==', b: '1' },
								elements: [{
									entity_template: 'format',
									format: 'Active until $1',
									fields: {
										field: '@' + type + '_expiration',
										formatter: 'epoch',
										options: { format: '%Ww, %SM %d, %Y' }
									}
								}],
								else_elements: [{
									entity_template: 'translate',
									name: type + '_status',
									translations: {
										'-1': { text: "Database Error" },
										'-2': { text: "Terminated" },
										'-3': { text: "Not Purchased or Expired" },
										'-4': { text: "Out of Region" },
										'-9': { text: "Cannot connect to Update Server" },
										'3': { text: "Demonstration Unit" },
										'4': { text: "Developer Unit" },
										'5': { text: "Evaluation Unit" }
									}
								}]
							}]
						}
					}
				}
			};

			return out;
		},

		support_tunnel_page_module: function () {
			return (	    {
				entity_templates: [ 'page_module', 'key_value' ],
				key_value: {
					live_table: 'live_system_stats',
					key_column: 'name',
					value_column: 'value'
				},
				page_module: {
					'class': 'page_module_body',
					legend: 'Support Tunnel Connection',
					buttons: ['help'],
					help_id: 'configuration_support_tunnel_connection',
					elements: [
						{
							entity_template: 'input_item',
							type: 'div',
							title: 'System Serial:',
							input_name: 'system_serial'
						},
						{
							entity_template: 'input_item',
							type: 'div',
							title: 'Support Tunnel Key:',
							input_name: 'pin',
							input_options: {
								widgets: ['militarizeWidget', 'aopbStateWidget'],
								widget_options: {
									militarizeWidget: {},
									aopbStateWidget: {

										states: [
											{
												conditions: { a: '@support_tunnel_status', op: '==', b: 'open' },
												actions: { visible: true }
											},
											{
												conditions: { a: '@support_tunnel_status', op: '==', b: 'closed' },
												actions: { visible: false }
											},
											{
												conditions: { a: '@support_tunnel_status', op: '==', b: 'connecting' },
												actions: { visible: true }
											},
											{
												conditions: { a: '@support_tunnel_status', op: '!=', b: '^(open|closed)', b_is: 'regexp' },
												actions: { visible: true }
											}
										]
									}
								}
							}
						},
						{
							entity_template: 'input_item',
							type: 'div',
							input_name: 'message',
							title: 'Support Tunnel Messages:'
						},
						{
							entity_template: 'data_item',
							title: 'Support Tunnel State:',
							elements: [
								{
									entity: 'div',
									widgets: ['aopbStateWidget'],
									widget_options: {
										aopbStateWidget: {
											watch_also: [{ events: 'SubmitSuccess' }],
											states: [
												{
													conditions: { a: '@support_tunnel_status', op: '==', b: 'open' },
													actions: { value: 'Active' }
												},
												{
													conditions: { a: '@support_tunnel_status', op: '==', b: 'closed' },
													actions: { value: 'Inactive' }
												},
												{
													conditions: { a: '@support_tunnel_status', op: '==', b: 'connecting' },
													actions: { value: 'Connecting...' }
												},
												{
													conditions: { a: '@support_tunnel_status', op: '!=', b: '^(open|connecting|closed)', b_is: 'regexp' },
													actions: { value: ' ' }
												}
											]
										}
									}
								}
							]
						},
						{
							entity_template: 'data_item',
							title: '',
							elements: [
								{
									entity_templates: ['action_button'],
									action_button: {
										'class': 'support_tunnel_start',
										text: 'Start Support Tunnel',
										rest: '/gui/configure/support_tunnel_start',
										method: 'POST'
									}
								},
								{
									entity_templates: ['action_button'],
									action_button: {
										'class': 'support_tunnel_stop',
										text: 'Stop Support Tunnel',
										rest: '/gui/configure/support_tunnel_stop',
										method: 'POST'
									}
								},
								{
									entity: 'span',
									widgets: ['supportTunnelStatusWidget']
								}
							]
						}
					]
				}
			});
		},

		phone_model_detail: function () {
			var out = {
				entity_template: 'page_module',
				legend: 'Advanced Information',
				buttons: [ 'help' ],
				help_id: 'phones_advanced_information',
				elements: [
					{
						entity_template: 'list_generation',
						data_items: {
							allow_sla: { title: 'Allows Shared Line Appearance (SLA):', type: 'boolean' },
							button_type_park: { title: 'Allows Park Call buttons:', type: 'boolean' },
							button_type_speeddial: { title: 'Allows Speed Dial buttons:', type: 'boolean' },
							button_type_blf: { title: 'Allows Busy Lamp Field (BLF) buttons:', type: 'boolean' },
							button_type_transfer: { title: 'Allows Transfer Call buttons:', type: 'boolean' },
							button_type_repeat: { title: 'Allows buttons to be repeated:', type: 'boolean' },
							button_type_dnd: { title: 'Allows Do Not Disturb buttons:', type: 'boolean' },
							lines_per_phone: { title: 'Maximum Lines:' },
							total_programable_buttons: { title: 'Total programable buttons:' },
							reboot: { title: 'Key combination to reboot phone:' },
							'default': { title: 'Key combination to restore phone to default:' },
							upload: { title: 'Key combination to initiate log/configuration upload:' },
							available_ring_tone_count: { title: 'Total available ringtones:' },
							max_directory_entries: { title: 'Maximum directory entries:' }
						}
					}
				]
			};

			return out;
		}


	}; // END elgens object


	elgen = function (name, params, extend) {
		if (!elgens[name]) {
			debugLog('elgens.js: No elgen by the name of ', name);
			return { entity: 'div', text: 'Elgen not found: ' + name };
		} else if (!$.isFunction(elgens[name])) {
			debugLog('elgens.js: Elgen key exists, but is not a function (', name, ') -- ', elgens[name]);
			return { entity: 'div', text: 'Elgen is not a function: ' + name };
		}

		var out = elgens[name](params || {});

		if (extend) {
			var clobber = (extend.clobber === true);
			$.extend(!clobber, out, extend);
		}

		return out;
	};

})();
