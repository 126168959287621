/* jshint jquery: true, unused: vars */
/* global add_widget */
(function( $ ){
	var flyoutExtensionSearchWidget = $.extend(true, {}, $.ui.flyoutPhoneNumberSearchWidget.prototype, {
		options: {
			validate_rest: '/gui/extension/valid',
			validate_container: false
		},

		_validateInput: function () {
			var self = this, $self = this.element, submitParams = {}, $messages_template, mesg;

			submitParams.bbx_extension_value = self.options._$input.val();
			$messages_template = $('<div class="messages" />');
			mesg = self.options.message || 'Invalid entry!';

			self.getREST(self.options.validate_rest, submitParams, {
				success: function (data, textStatus, jqXHR) { self._validateSuccess(data, textStatus, jqXHR); },
				error: function (jqXHR, textStatus, errorThrown) { self._validateError(jqXHR, textStatus, errorThrown); }
			});

		},

		_validateSuccess: function (data, textStatus, jqXHR) {
			var self = this, $self = this.element, container, valid;

			if (self.options.destroyed) { return; }

			container = self.options.validate_container || self.options.validate_rest.replace(/^.+\//, '');

			valid = data[container].valid;

			if (valid) {
				$self.removeClass('is-invalid');
			} else {
				$self.addClass('is-invalid');
				$self.data('error', self.options.message || 'Invalid Entry!');
			}

			$self.trigger('stateChange');
		},

		_validateError: function (jqXHR, textStatus, errorThrown) { }


	});

	add_widget('flyoutExtensionSearchWidget', flyoutExtensionSearchWidget);
})(jQuery);
