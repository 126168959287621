/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/* ************************************************************************************************************

Name: selectContainerWidget

This widget allows you to populate a form based on the value of a select box option.  Any data passed in should
be an array of objects.

*********************************************************************************************************** */

(function( $ ){
	var selectContainerWidget = $.extend(true, {}, $.ui.containerWidget.prototype, {
		options: {
			closest: 'form',
			data_source: '',     // REQUIRED - this is key for the object that contains the data you need
			options: [],         // the select options that are added before fillData
			select_value: '',    // REQUIRED - the name that should be used for the populated select option value and reference the data
			select_text: false,  // the name that should be used for the populated select option text (defaults to select_value if not given)
			select_first: false, // make the first populated select option the selected option

			_prev_selected: false, // internal state variable
			_data_objects: {}      // internal storage of filled data and given data by key.
		},

		_beforeInit: function () {
			var self = this, $self = this.element, $select, $option, idx;

			$select = $('<select>');
			if (self.options.select_name) { $select.prop('name', self.options.select_name); }

			for (idx = 0; idx < self.options.options.length; idx++) {
				$option = self.options.options[idx];
				$select.append('<option value="' + $option.value + '">' + $option.text + '</option>');
			}
			self.options.$select_elem = $select;
			$self.append('<div id="selectDiv">').append($select);
			$self.append('<div id="formDiv">');

			$self.on('change', self._fillForm.bind(self));

			self._buildDataObject();
		},

		fillData: function (d, from_self) {
			var self = this, $self = this.element;

			if (self.options.data_source && d[self.options.data_source]) {
				self.options.filled_data = d[self.options.data_source];
			}
			else {
				return;
			}

			self._populateSelect();

		},

		_buildDataObject: function () {
			var self = this, $self = this.element, o_data, opt_key, data, fields, fields_length, f_idx;

			o_data = self.options.options_data;
			fields = self.options.fields;
			fields_length = self.options.fields.length;

			for (opt_key in o_data) {
				data = {};
				for (f_idx = 0; f_idx < fields_length; f_idx++) {
					if (o_data[opt_key][fields[f_idx]]) {
						data[fields[f_idx]] = o_data[opt_key][fields[f_idx]];
					}
					else {
						data[fields[f_idx]] = '';
					}
				}
				self.options._data_objects[opt_key] = data;
			}
		},

		_populateSelect: function () {
			var self = this, $self = this.element, length, obj, o_idx, $option, select_text = false;
			length = self.options.filled_data.length;

			if (self.options.select_text) {
				select_text = self.options.select_text;
			}
			for (o_idx = 0; o_idx < length; o_idx++) {
				obj = self.options.filled_data[o_idx];
				$option = $('<option>');
				$option.val(obj[self.options.select_value]);

				if (select_text) {
					$option.prop('text', obj[select_text]);
				}
				else {
					$option.prop('text', obj[self.options.select_value]);
				}

				self.options._data_objects[obj[self.options.select_value]] = obj;
				self.options.$select_elem.append($option);

				if (o_idx === 0 && self.options.select_first) {
					$option.prop('selected', 'selected');
					self._fillForm();
				}

			}

		},

		_fillForm: function () {
			var self = this, $self = this.element, selected, data, field_name, $field;

			selected = self.options.$select_elem.val();

			if (selected != self.options._prev_selected) {
				data = self.options._data_objects[selected];
				for (field_name in data) {
					$field = $self.closest(self.options.closest).find('[name=' + field_name + ']');
					$field.val(data[field_name]);
				}

				self.options._prev_selected = selected;
			}
		}
	});

	add_widget('selectContainerWidget', selectContainerWidget);
})(jQuery);
