/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/*
Implementation of CUI.aopbParser (for input parsing) and $.ui.stateWidget (for action). Allows setting simple states, classes, CSS, and valid/invalid state
based on a-op-b-object formatted conditions.

EXAMPLE (*):

{
    ...,

    states: [
	conditions: [
	    {             a: '$$',           op: '==', b: '_TRUEISH_' },
	    { join: 'or', a: '$auto_select', op: '==', b: '_TRUEISH_' }
	],
	actions: {
	    invalid: false
	},
	else_actions: {
	    invalid: 'This option must have a non-zero value, or Auto Select must be turned on'
	}
    ]
}

* If you have problems, check the documentation on the constituent classes, as something might have changed:
For information on a-op-b syntax, see classes/cui.aopbParser.js
For information on actions, see jquery.stateWidget.js

*/

(function( $ ){
	"use strict";
    var aopbStateWidget = $.extend(true, {}, $.ui.stateWidget.prototype, CUI.aopbParser, {
	_prefer_own_widget: false,
	_beforeInit: function () {
	    this._init_states();
	},

	_beforeDOMReady: function () {
	    this._watch_events();
	},

	_perform_aopb_actions: function (all_actions) {
	    return this._perform_state_actions.apply(this, arguments);
	}
    });
    
    add_widget('aopbStateWidget', aopbStateWidget);
})(jQuery);
