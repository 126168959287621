/* jshint jquery: true, unused: vars, multistr: true */
/* global CUI, add_widget, entity, debugLog, widgetize_children */
/*
  Flyout Search Select Widget--

  A widget to allow search and selecting an item from a REST-queried list. Does not allow arbitrary input.
  FlyoutSearchSelectWidget is a subclass of flyoutSearchWidget.

  USAGE:

  Apply this to a DIV.

  OPTIONS:

  Note: Many options are inherited from flyoutSearchWidget. See jquery.flyoutSearchWidget.js for details.

  // Allow "nothing", an empty value, to be selected. If this is false, the value still may be "nothing" if no data is given to the widget, but the value
  // cannot be subsequently changed to "nothing" later.
  allow_nothing: true | false,

  // The value to be returned when "Nothing" is selected
  nothing_value: '' | '...value...' | { ... },

  // Additional values to be considered "nothing" when set by setValue. Set this to an empty array "[]" or it will be defaulted
  nothing_values: [ array of values ] | [null, undefined, ''],

  // From the search result selected, what key's value will the widget return?
  // undefined:    Uses the NAME attribute of the widget, only returns one value
  // STRING value: Returns only the given key's value, and uses the NAME attribute of the widget for the key name
  // ARRAY value:  Returns values for the given keys, using their original names, and ignoring any NAME attributes
  value_key:  undefined | '...' | ['...', ... ]

  // From the values given by fillData, what will be used to make the initial search, to initially fill in the select-box with information?
  // undefined:    Uses the key from s.o.value_key, or the NAME attribute of the widget as the key, and the setValue value as the value
  // false:        Uses ALL keys and values from fillData
  // STRING value: Uses the given key, and the setValue value as the value
  // ARRAY value:  Uses the given key(s), and the corresponding values from fillData
  search_key: undefined | false | '...' | ['...', ... ],

  // If a different REST call is needed for the initial lookup, use initial_search_rest and initial_search_method. Both fallback to the normal search_rest
  // and search_method if omitted. If the return is a single object, it will be put into an array.
  lookup_search_rest: undefined | '/path/to/controller',
  lookup_search_method: 'GET' | 'PUT' | 'POST' | 'DELETE',
  lookup_search_rest_container: undefined | 'container',

  // If no initial lookup should be performed, set this false
  perform_lookup: true,

  // If value_key (array style) specified properties are missing, omit_undefined will omit the properties from the value object entirely, if true.
  omit_undefined: true | false,

  // Otherwise, specify a default value for undefined or missing properties. (This only applies when value_key is an ARRAY.)
  set_undefined_to: '',

  // Element definitions for rows displayed in the list (render_row) and as the current selection (render_selected_row).
  // If render_selected_row is undefined, it will use the render_row option.
  render_row: [ { ... } ],
  render_selected_row: [ { ... } ] | undefined,

*/

(function( $ ){
	var flyoutSearchSelectWidget = $.extend(true, {}, $.ui.flyoutSearchWidget.prototype, {
		options: {

			template_html: '<div class="flyout-search-select-button"></div>',
			flyout_template_html: '<div class="flyout-search-content">'+
'<div class="flyout-search-select-search-wrap">'+
'<input type="text" class="flyout-search-select-search-input" />'+
'</div>'+
'<div class="flyout-search-results">'+
'<div class="flyout-search-message flyout-search-working" />'+
'<div class="flyout-search-message flyout-search-none">No results found.</div>'+
'<div class="flyout-search-list" />'+
'<div class="flyout-search-page-message">Items <span class="flyout-search-start-num" />' + entity.ndash + '<span class="flyout-search-end-num" /> of <span class="flyout-search-total-num" /></div>'+
'</div>'+
'</div>',

			perform_lookup: true,
			allow_nothing: true,
			nothing_value: '',
			nothing_text:  'Nothing',
			nothing_icon: undefined,
			// nothing_values: [null,undefined,''], // -- This is clobbered in beforeInit if it is undefined by a subclass or option setting
			nothing_error: 'You must select a value.',
			select_on_activate: false,
			value_key: undefined,
			search_key: undefined,
			omit_undefined: true,
			set_undefined_to: '',
			render_row: undefined,
			render_selected_row: undefined,
			auto_select_index: 0, // This will cause the first result to be highlighted automatically (so hitting Enter will select it)

			// For internal use
			_meta_values: [],
			_value_is_nothing: false
		},

		value_widget: true,
		set_value_widget: true,

		// CSS classnames, for use in subclass overrides
		_fss_input_class: 'flyout-search-select-search-input',
		_fss_button_class: 'flyout-search-select-button',
		_fss_button_row_container_class: 'flyout-search-select-button-row-container',

		// Shim in a calls to self._flyoutSearchSelectBind -- bind to the click, not to a key event, since the input is under the flyout.
		_beforeInit: function () {
			var self = this, $self = this.element;
			if (!self.options.allow_nothing) {
				// By default, the selector is set to "nothing", so it's immediately invalid.
				$self.addClass('is-invalid').data('error', self.options.nothing_error);
			}

			// Clobber, don't combine
			if (self.options.nothing_values === undefined) {
				self.options.nothing_values = [null,undefined,''];
			}

			$.ui.flyoutSearchWidget.prototype._beforeInit.apply(self, arguments);
			self._flyoutSearchSelectBind();
		},

		// Bind the click on the "button"
		_flyoutSearchSelectBind: function () {
			var self = this, $self = this.element;
			self._bind($self, 'click', self._fssClickHandler.bind(self));
		},

		// Shim in a call to set the initial value to "nothing", pending data reciept from fillData.
		_beforeDOMReady: function () {
			var self = this;
			$.ui.flyoutSearchWidget.prototype._beforeDOMReady.apply(self, arguments);
			self._applyNothingResult();
		},

		// We need to defer _flyoutSearchBind to after the flyout is built, so clobber the original function (which happens on key trigger),
		// and assign it to a different name...
		_flyoutSearchBind: function () { },
		_flyoutSearchProtoBind: $.ui.flyoutSearchWidget.prototype._flyoutSearchBind,

		// Handler for clicking on $self, to open or close the flyout.
		_fssClickHandler: function (e) {
			var self = this, $self = this.element;

			if ($self.hasClass('state-disabled')) { return; }

			if (self.options.flyout_visible) {
				self.hideFlyout();
			} else {
				self.options.flyout_clicked = false;
				self._bind($self, 'mousedown', self._flyoutMousedownHandler.bind(self));
				self.setSearchString('');
			}
		},

		// Perform the initial ("auto") search, to find the proper entry to display, when data is recieved.
		_autoSearch: function (data) {
			var self = this, url, method, ref__autoSearchCompleteCallback;

			data = $.extend({ rows: 1, page: 1 }, data);
			url = self.options.lookup_search_rest || self.options.search_rest;
			method = self.options.lookup_search_method || self.options.search_method;
			ref__autoSearchCompleteCallback = self._autoSearchCompleteCallback.bind(self);
			self.options._as_rest_call = CUI.doREST(method, url, data, ref__autoSearchCompleteCallback);
		},

		// Completion callback for previous.
		_autoSearchCompleteCallback: function (d) {
			var self = this, search, row;

			if (self.options.destroyed) { return; }

			search = self._getSearchResultsFromData(d, self.options.lookup_search_rest_container);
			row = search.results[0];
			if (row) {
				self._pickValue(row);
			} else {
				debugLog('jquery.flyoutSearchSelectWidget.js: No search result in autosearch (autoSearchCompletedCallback) -- ', self.element);
			}
		},

		// Add the "nothing" row.
		_addMetaRows: function () {
			var self = this, $list = self.options._$flyout.find('.' + self._search_list_class), $nothing;

			if (self.options.allow_nothing && !(self.options._search_results && self.options._search_results.results.length)) {
				$nothing = $('<div />').text(self.options.nothing_text || 'Nothing').addClass(self._row_container_class + ' ' + 'flyout-search-select-meta-none');
				if (self.options.nothing_icon) {
					$nothing.css({ 'background-image': self.options.nothing_icon });
				}

				$nothing.data('meta_action', 'nothing');
				$list.prepend($nothing);
				self.options._search_list_row_count++;
			}

			self.getFlyoutContentElement().find('.' + self._search_list_class).show();
		},

		// Handler for the "nothing" row.
		_pickMeta: function (action) {
			var self = this;
			switch (action) {
				case 'nothing':
					self._applyNothingResult();
					self._applyValue(self.options.nothing_value);
					break;
			}
		},

		// Pull the proper value(s) from the selected item.
		_pickValue: function (result) {
			var self = this, $self = this.element, vk_idx, vk, vr, value;

			if ($.isArray(self.options.value_key)) {
				value = {};
				vkLoop: for (vk_idx = 0; vk_idx < self.options.value_key.length; vk_idx++) {
					vk = self.options.value_key[vk_idx];
					vr = result[vk];
					if (vr === undefined) {
						if (self.options.omit_undefined) {
							continue vkLoop;
						} else {
							value[vk] = self.options.set_undefined_to;
						}
					} else {
						value[vk] = result[vk];
					}
				} // END vkLoop
			} else if (typeof self.options.value_key === 'string' || self.options.value_key === undefined) {
				vk = (self.options.value_key === undefined) ? CUI.getElementName($self) : self.options.value_key;
				vr = result[vk];

				if (vr === undefined) {
					value = self.options.set_undefined_to;
				} else {
					value = vr;
				}
			} else {
				debugLog('jquery.flyoutSearchSelectWidget.js: Improper type in s.o.value_key (', self.options.value_key, ') -- ', self.element);
			}

			self._applyResult(result);
			self._applyValue(value);

			if ($self.data('error') === self.options.nothing_error) {
				$self.removeClass('is-invalid').removeData('error');
			}
			$self.trigger('change');
		},


		// Apply the entire selected row to the "button" that shows the selected row, for display
		_applyResult: function (result) {
			var self = this, $self = this.element, el_def, $button, $item;

			$button = $self.find('.' + self._fss_button_class);
			el_def = self.options.render_selected_row || self.options.render_row;

			if (!$.isArray(el_def)) { el_def = [el_def]; }

			var $els = CUI.htmlEntityClass.getEntitiesHTML([{
				entity: 'div',
				widgets: ['containerWidget'],
				widget_options: { containerWidget: {
					accept_data_from_parent: false,
					elements: el_def
				}}
			}], {});

			$item = $els
				.addClass(self._row_container_class + ' ' + self._fss_button_row_container_class)
				.appendTo($button.empty());

			if ($item.find('.widgetType:not(.widgetized)')[0]) {
				widgetize_children($item);
				self.fillDataChildren(result, true, $els.children());
			}

		},

		// Apply a "nothing" selection to the "button"
		_applyNothingResult: function () {
			var self = this, $self = this.element, $nothing, $button;
			$button = $self.find('.' + self._fss_button_class);
			$nothing = $('<div />').text(self.options.nothing_text || 'Nothing').addClass(self._row_container_class + ' ' + 'flyout-search-select-meta-none');
			$nothing.appendTo($button.empty());
		},

		// Apply the selected value to the widget, and signal a change.
		_applyValue: function (value) {
			var self = this;
			self.options._raw_value = value;
			if (typeof value !== 'object') {
				value = self._wrapValue(value);
			}

			self.options.value = value;
			self._emitChange();
		},

		// Shim that sets s.o._$input to the textbox in the flyout, after it's created.
		showFlyout: function () {
			var self = this, $self = this.element, $input;
			$.ui.flyoutSearchWidget.prototype.showFlyout.apply(self, arguments);

			$input = self.getFlyoutContentElement().find('.' + self._fss_input_class);

			if (!self.options._$input.is($input)) {
				self.options._$input = $input;
				self._flyoutSearchProtoBind();
			}

			$input.focus();
		},


		// Pretty straightforward.
		_getWidgetValue: function () {
			return this.options.value;
		},

		setValue: function (v) {
			var self = this, $self = this.element, nvs, nv_idx, sk, rest_params;

			if (v === undefined) { return; }
			if (v === self.options._raw_value) { return; }

			self.options._raw_value = v;
			// All the "nothing" values
			nvs = self.options.nothing_values.concat([self.options.nothing_value]);

			for (nv_idx = 0; nv_idx < nvs.length; nv_idx++) {
				if (v === nvs[nv_idx]) {
					self.options._value_is_nothing = true;
					self.options.value = self._wrapValue(v);
					self._applyNothingResult();
					return;
				}
			}

			// If s.o.search_key is undefined, it implies we should use s.o.value_key
			sk = (self.options.search_key === undefined) ? self.options.value_key : self.options.search_key;

			// If sk is still undefined, it means it should be the element name...
			if (sk === undefined) { sk = CUI.getElementName($self); }

			// If we are using multiple keys to search, or we are explicitly using fillData (sk === false) then we need to wait for fillData.
			if (sk === false || $.isArray(sk)) {
				self.options.value = self._wrapValue(v);
				return;
			}

			rest_params = {};
			rest_params[sk] = v;
			if (self.options.perform_lookup) {
				self._autoSearch(rest_params);
			}
		},

		fillData: function (d, from_self) {
			var self = this, sk, sk_idx, data = {};

			sk = (self.options.search_key === undefined) ? self.options.value_key : self.options.search_key;

			if (from_self || self.options.accept_data_from_parent) {
				if (typeof sk === 'string' || sk === undefined) {
					self.setValue(d[ CUI.getElementName(self.element) ]);
					return;
				}

				self.options.data = d;

				if (sk === false) {
					data = d;
				} else if ($.isArray(sk)) {
					for (sk_idx = 0; sk_idx < sk.length; sk_idx++) {
						if (d[sk[sk_idx]] === undefined) {
							if (!self.options.omit_undefined) {
								data[sk[sk_idx]] = self.options.set_undefined_to;
							}
						} else {
							data[sk[sk_idx]] = d[sk[sk_idx]];
						}
					}
				}

				if (self.options.perform_lookup) {
					self._autoSearch(data);
				}
			}
		},

		// A shim to add the "_empty_message_class" to all hide actions in all states. We never want to see it in this widget.
		_getFlyoutSearchStates: function () {
			var states = $.ui.flyoutSearchWidget.prototype._getFlyoutSearchStates.call(this);
			states.global.hide.push( this._empty_message_class );
			return states;
		}

	});

	add_widget('flyoutSearchSelectWidget', flyoutSearchSelectWidget);
})(jQuery);
