(function () {
    register_screen('easteregg', {
	elements: [
	    { entity_template: 'page_title', title: 'Super Secret Hidden Easter Egg' },
	    {
		entity_template: 'page_content',
		elements: [
		    {
			entity: 'div',
				widgets: ['eggScreenWidget']
		    }
		]
	    }
	] // END template elements
    }); // END template

})(); // END scoping function
