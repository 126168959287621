/* jshint jquery: true, unused: vars */
/* global add_widget */
/*
 * @class deleteButtonWidget(options)
 *
 * The delete button widgit is used to delete records from record viewing screens. It is a child class of actionButtonWidget.
 *
 * @par Usage:
 *
 * data-js is used to pass an associated array. The array key holds the condition and value holds the state actions.
 *
 * @param rest sets the url that will be used for an Ajax REST execution. This param is optional and if not found no Ajax action will
 *        be performed.
 *
 * @param fields is a space seperated list that filters the rest_params options to limit params sent to the Ajax REST action.
 *        e.g.: 'key bbx_user_id bbx_extension_id'
 *
 * @param confirm sets the confirm message for a user confirmation box and launches the confirmation box when the users clicks the button.
 *
 * data-js='{ "rest" : "/path", ["fields" : "field_name field_name field_name"], ["confirm" : "confirmation message"] }'
 *
 */

(function( $ ){

    var deleteButtonWidget = $.extend({}, $.ui.actionButtonWidget.prototype, {
	options: {
            confirm: {
		title: 'Confirm Delete',
		text: 'Are you sure you want to delete this object? This operation cannot be undone.',
		buttons: ['Delete', 'Cancel']
	    },
	    type: 'delete',
	    method: 'DELETE'
        },

	/*
	 * @fn _onSuccess()
	 * @brief Do browser history back after successfully deleting a record.
	 */
	_success : function (d) {
	    var location = window.location;
	    var matched;
	    if (location && location.href && (matched = location.href.match(/(.*)\/overlay/))) {
		window.location = matched[1];
	    } else {
		history.back();
	    }
	   
	}
	
    });

    add_widget('deleteButtonWidget', 'ui.deleteButtonWidget', 'deleteButtonWidget', deleteButtonWidget);
})(jQuery);
