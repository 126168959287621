/* jshint jquery: true, unused: vars */
/* global CUI, debugLog, entity, add_widget */
/*
 * @class actionButtonWidget(options)
 *
 * This is a generic action button class that can be used alone or a child class can be derived from actionButtonWidget.
 *
 * @par Usage:
 *
 * data-js is used to pass an associated array. The array key holds the condition and value holds the state actions.
 *
 * @param rest sets the url that will be used for an Ajax REST execution. This param is optional and if not found no Ajax action will
 *        be performed.
 *
 * @param method sets the Ajax REST action method. e.g. get, post, put, delete.
 *
 * @param fields is a space seperated list that filters the rest_params options to limit params sent to the Ajax REST action.
 *        e.g.: 'key bbx_user_id bbx_extension_id'
 *
 * @param confirm Sets the confirm message for a user confirmation box and launches the confirmation box when the users clicks the button.
 * @param disssalog_during_submit Show a progress Dialog after submit, but before completion
 * @param dialog_after_success Show a dialog presented after completion.
 * @param dialog_after_error Show a dialog presented after completion.
 *
 * confirm | dialog_during_submit | dialog_after_success: {
 *  title:          <title> | <default message>,
 *  text:           <text>  | <default message>,
 *  buttons:        [ <ok button text>, <cancel button text> ] | <default button set>,
 *  value_callback:            <function> | <default function>,
 *  value_callback_auto_close: true | <false except dialog_during_submit>,
 *  values: [<first button value>, <second button value>] | [true, false]
 * }
 *
 * @param include_data An array of keys from fillData to send values along with the request.
 *
 * @param include_data_as An object, in the form { data_name: new_name, ... } to insert values and change the key name.
 *
 * @param include_field_data An Array of keys that coorespond to fields on the screen that need to be sent along with the request.
 *
 * @param reload This will reload the screen/overlay by calling a fill data on the screen/overlay
 *
 * @param refresh This will refresh the closest form
 *
 * @param up_on_success This will navigate your screen back one level(overlay)
 *
 */

(function( $ ){

    var actionButtonWidget = $.extend({}, $.ui.widget.prototype, {
	options: {
	    method: "PUT",
	    closest: 'form',
	    reload: false,
	    refresh: false,
	    visual_feedback: false,
	    debounce: 200,
	    _debounce_timer: false
	},

	_beforeInit: function () {
	    var self = this;

	    if (self.options.confirm) {
		self.options.confirm = $.extend({
		    title: 'Confirm Operation',
		    text:  'Are you sure you would like to perform this operation?',
		    buttons: [ 'Yes', 'No' ],
		    values: [ true, false ],
		    callbacks: [function () {
			CUI.Dialog.cancel.call(this);
			self._afterConfirm();
		    }, CUI.Dialog.cancel]
		}, self.options.confirm);
	    }

	    if (self.options.dialog_during_submit) {
		self.options.dialog_during_submit = $.extend({
		    title: 'Operation In Progress' + entity.hellip,
		    text:  'The operation is currently in progress. Please wait...',
		    progress: true,
		    buttons: false
		}, self.options.dialog_during_submit);
	    }

	    if (self.options.dialog_after_success) {
		self.options.dialog_after_success = $.extend({
		    title: 'Operation Completed',
		    text:  'The operation is complete.',
		    buttons: [ 'OK' ],
		    callbacks: [ CUI.Dialog.cancel ]
		}, self.options.dialog_after_success);
	    }
	},

	_afterInit: function () {
	    var self = this, $self = this.element;
	    $self.addClass('actionButtonType');
	    if (self.options.visual_feedback) {
		$self.wrap('<span class="spinner">');
	    }
	    self._bind($self, 'click', self._debounceClickHandler.bind(self));
	},

	// Store any data included via "include_data" or "include_data_as"

	fillData: function (d, from_self) {
	    var self = this, $self = self.element, filtered_d, to_rename_d = {}, renamed_d = {}, rn_d_keys, rn_d_kidx;

	    if (from_self) { return $.ui.widget.prototype.fillData.apply(self, arguments); }

	    if (self.options.include_data_as) {
		// Get original keys...
		rn_d_keys = CUI.keys(self.options.include_data_as);
		// Pull those properties out, as they are the only ones we're working with
		to_rename_d = CUI.onlyKeys(d, rn_d_keys);

		// Loop through the original keys, and copy from the original properties to the new property keys
		for (rn_d_kidx = 0; rn_d_kidx < rn_d_keys.length; rn_d_kidx++) {
		    renamed_d[ self.options.include_data_as[ rn_d_keys[rn_d_kidx] ] ] = to_rename_d[ rn_d_keys[rn_d_kidx] ];
		}
	    }

	    if (self.options.include_data && self.options.include_data[0]) {
		filtered_d = CUI.onlyKeys(d, self.options.include_data);
	    }

	    if ((self.options.include_data && self.options.include_data[0]) || self.options.include_data_as) {
		self.options._stored_data = self.options._stored_data || {};
		$.extend(self.options._stored_data, filtered_d, renamed_d);
	    }

	    $.ui.widget.prototype.fillData.apply(self, arguments);
	},

	/*
	 * @fn _doDataFill()
	 * @brief Function is called to stop base widget class from doing an implicit REST request.
	 */
	_doDataFill: function () {
	    this._doDataReady(); // Skip the implicit REST request
	},

	_debounceClickHandler: function () {
	    var self = this, $self = this.element;
	    var ref__clickHandler = self._clickHandler.bind(self);

	    if (!self.options.debounce) {
		self._clickHandler();
	    }
	    else {
		if (self.options._debounce_timer) {
		    clearTimeout(self.options._debounce_timer);
		    self.options._debounce_timer = false;
		}
		self.options._debounce_timer = setTimeout(ref__clickHandler, self.options.debounce);
	    }
	},

	/*
	 * @fn _clickHandler()
	 * @brief Handles the on click command.
	 */
	_clickHandler: function () {
	    var self = this, $self = this.element;

	    if (self.options.confirm) {
		self._showConfirmDialog();
	    } else {
		self._afterConfirm();
	    }
	},

	/*
	 * @fn _showConfirmDialog()
	 * @brief This function is called to create the action confirmation box.
	 */
	_showConfirmDialog: function () {
	    var self = this;
	    self.options._dialog = new CUI.Dialog(self.options.confirm);
	},

	/*
	 * @fn _afterConfirm
	 * @brief This function continues the click processing after confirmation. It is also called to skip confirmation box when
	 *        no confirm param is given.
	 */
	_afterConfirm: function () {
	    var self = this, $self = this.element;
	    
	    if (self.options.dialog_during_submit) {
		self.options._dialog = new CUI.Dialog(self.options.dialog_during_submit);
	    }

	    if (self.options.visual_feedback) {
		$self.closest('span.spinner').append($('<img src="/images/cui/spinner_tab.gif" style="width: 8px; height: 16px" alt="" />'));
	    }

	    if (self.options.rest) {
		self._doAction();
	    } else {
		self._complete();
	    }
	},

	/*
	 * @fn _getFieldValues()
	 * @brief This function is used to get the values that are found on the screen form that are not present in fillData
	 *
	 */
	_getFieldValues: function () {
	    var self = this, $self = this.element, idx, $elem, f_values = {};

	    for (idx in self.options.include_field_data) {
		$elem = $self.closest(self.options.closest).find(':input[name="' + self.options.include_field_data[idx] + '"]');

		// if data was given as key:value, use the key as the param name
		if ($elem && $.isPlainObject(self.options.include_field_data)) {
		    var val = CUI.getWidgetElementValue($elem, { first_value: true });
		    f_values[idx] = val;
		}
		else if ($elem) {
		    f_values[self.options.include_field_data[idx]] = $elem.val();
		}
		else {
		    debugLog("jquery.actionButtonWidget.js: No input field found for field(s) in include_field_data -- ", $self);
		}

	    }

	    return f_values;
	},

	_link: function () {
	    var self = this, hash = decodeURIComponent(location.hash), new_hash;
	    new_hash = hash.replace(/(.*)\/overlay\/.+/, '$1');
	    location.href = new_hash;
	    if (self.options.reload) {
		location.reload(true);
	    }
	},

	/*
	 * @fn _getRESTParams
	 * @brief This functions is used to setup rest parameters for the action button. A child class can override this function if it requires
	 *        different specifications for the ajax request params.
	 */
	_getRESTParams: function () {
	    var self = this, $self = this.element, rest_params = {}, rp_idx, rp_copy;

	    if (self.options.rest_params) {
		// Set rest_params variable it self.options.rest_params is found.
		if (typeof self.options.fields == "string" && self.options.fields.length > 0) {
		    // If fields is a string then split string using spaces and filter options.rest_params
		    var arrFields = self.options.fields.split(" ");

		    for (var i=0; i<arrFields.length; i++) {
			if (arrFields[i] in self.options.rest_params) {
			    rest_params[arrFields[i]] = self.options.rest_params[arrFields[i]];
			}
		    }
		} else {
		    // Else set rest_params to self.options.rest_params
		    rest_params = self.options.rest_params;
		}

		if (self.options.filter_rest_params) {
		    for (rp_idx = 0; rp_idx < self.options.filter_rest_params.length; rp_idx++) {
			delete rest_params[self.options.filter_rest_params[rp_idx]];
		    }
		} else if (self.options.only_rest_params) {
		    rp_copy = {};
		    for (rp_idx = 0; rp_idx < self.options.only_rest_params.length; rp_idx++) {
			rp_copy[self.options.only_rest_params[rp_idx]] = self.options.rest_params[self.options.only_rest_params[rp_idx]];
		    }
		    rest_params = rp_copy;
		}
	    }

	    // Add in stored data from fillData "include_data" processing
	    $.extend(rest_params, self.options._stored_data || {});

	    // Add in values from the fields on the screen
	    if (self.options.include_field_data) {
		$.extend(rest_params, self._getFieldValues() || {});
	    }

	    // Because our button can be clicked over and over we are not overriding the rest_params option.
	    return rest_params;
	},

	/*
	 * @fn _doAction
	 * @brief This function filters params and calls the Ajax REST command.
	 */
	_doAction: function () {
	    var self = this, $self = this.element, rest_params;

	    rest_params = self._getRESTParams();

	    if (self.options.method) {
		var method = self.options.method;
		self.doREST(method, self.options.rest, rest_params, {
		    success: self._success.bind(self),
		    error: self._error.bind(self)
		});

	    } else {
		debugLog("jquery.actionButtonWidget.js: Did not receive method for ajax call -- ", $self);
	    }
	},

	_success: function(d) {
	    var self = this, $self = this.element;
	    self.options.data = d;

	    if (self.options.up_on_success) {
		self._link();
	    }
	    
	    if (self.options._dialog) {
		self.options._dialog.remove();
		delete self.options._dialog;
	    }
	    
	    if (self.options.dialog_after_success) {
		self.options._dialog = new CUI.Dialog(self.options.dialog_after_success);
	    }
	    
	    $self.trigger('Success');
	    self._trigger('Success');

	    self._complete();
	},

	_error: function(xhr, status, error) {
	    var self = this, $self = this.element;

	    if (self.options._dialog) {
		self.options._dialog.remove();
		delete self.options._dialog;
	    }

	    if (!error) {
		error = "An internal error occurred while processing your request.";
	    }

	    $self.data('error', error);
	    $self.addClass('error');

	    if (self.options.dialog_after_error) {
		self.options._dialog = new CUI.Dialog($.extend({ text: error }, self.options.dialog_after_error));
	    }

	    $self.trigger('Error');
	    self._trigger('Error');

	    self._complete();
	},

	_complete: function(xhr, status, error) {
	    var self = this, $self = this.element, pw;

	    if (self.options.visual_feedback) {
		$self.closest('span.spinner').find('img:last-child').remove();
	    }

	    pw = $self.closest('.pageWidgetType').getCUIWidget('pageWidget');
	    
	    if (pw && $self.hasClass('error')) {
		pw.addMessage($self.text(), $self.data('error'));
	    } else if (pw) {
		pw.delMessage('error', $self.text());
	    }

	    if (self.options.reload && !self.options.up_on_success) {
		location.reload(true);
	    }

	    if (self.options.refresh) {
		$self.closest('form').getCUIWidget('formWidget').refresh();
	    }
	}

    });

    add_widget('actionButtonWidget', 'ui.actionButtonWidget', 'actionButtonWidget', actionButtonWidget);
})(jQuery);
