/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/*
  Time Duration Widget:  display a running time duration on a screen.
*/

(function( $ ){
	var timeDurationWidget = $.extend({}, $.ui.widget.prototype, CUI.ServerTime, {
		set_value_widget: true,

		options: {
			accept_data_from_parent: true,
			input_multiplier: 1
		},

		setValue: function (v) {
			var self = this, $self = this.element;
			if (!isNaN(v)) {
				self.options.start_time = Math.floor(Number(v) / (self.options.input_multiplier || 1));
			}
		},

		_beforeDOMReady: function () {
			var self = this;
			self._bind($(window), 'tick', self._updateClock.bind(self));
		},

		_updateClock: function () {
			var self = this, $self = this.element, start_time = self.options.start_time, duration;
			if (self.options.start_time === undefined) { return; }
			duration = start_time ? Math.floor((self.getMilliepoch() / 1000)) - self.options.start_time : 0;
			$self.text(self._format(duration));

		},

		_format: function (time) {
			return CUI.formatter.doFormat({ time: time }, { format: { field: '@time', formatter: 'seconds_duration', options: {size: 'short_space'} }});
		}
	});

	add_widget('timeDurationWidget', timeDurationWidget);
})(jQuery);

