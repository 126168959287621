register_screen('router_detail', {
    rest: '/gui/extension/router/router',
    dataEvents: {
		bind: {
			'primary_extension': '',
			'router_refresh': '',
			'router': '',
			'callflow': ''
		},
		allow_delete: true
    },
    elements: [
		{ entity_template: 'masthead' },
		{
			entity_template: 'page_buttons',
			buttons: [
				{ type: 'extension', extension_type: 'router' },
				{
					type: 'delete',
					rest: '/gui/extension/router',
					fields: 'bbx_router_id key',
					confirm: { text: 'Are you sure you would like to delete this Advanced Call Router?' },
					dataEvents: {
						trigger: 'router'
					}
				},
				{ type: 'cancel' },
				{ type: 'save' }
			]
		},
		{ entity_template: 'page_content', elements: [
			{
				entity_template: 'page_module',
				legend: 'Basic Information',
				form_action: '/gui/extension/router',
				form_options: {
					dataEvents: {
						trigger: 'router'
					}
				},
				buttons: [ 'help' ],
				help_id: 'routers_basic_information',
				elements: [
					{
						entity_template: 'input_item',
						input_name: 'bbx_router_name',
						input_options: { attributes: { maxlength: '255' }},
						type: 'text',
						validate: { conditions: '_TRUEISH_', message: 'A Name is required.' },
						title: 'Router Name:'
					}
	 			]
			}, // END page module "Basic Information"
			{
				entity_template: 'page_module',
				legend: 'Rules and Actions',
				buttons: [ 'help' ],
				help_id: 'routers_rules_actions',
				elements: [
					{
						entity_template: 'data_table',
						accept_initial_data_from_parent: true,
						click_action: 'edit',
						submit_only_dirty: false,

						paginate: false,
						filtering: false,
						
						data_source: 'conditions',

						sortable: {
							field: 'bbx_router_condition_map_weight'
						},

						name_column: 'bbx_callflow_name',

						rest: '/gui/extension/router/conditions',

						refresh_rest: '/gui/extension/router/conditions',
						refresh_rest_container: false,
						refresh_data_source: 'conditions',

						allow_parent_extra_row_data: true,
						'class': 'top-align-edit',
						extra_row_data: [
							'callflow_summary',
							'bbx_mode_names',
							'bbx_mode_ids',
							'bbx_router_condition_map_id',
							'bbx_router_condition_map_tod_min_day',
							'bbx_router_condition_map_tod_wday',
							'bbx_router_condition_map_specific_date',
							'bbx_router_condition_map_recurring_date',
							'date_type',
							'bbx_router_condition_map_regex_metadata',
							'bbx_router_condition_map_regex_val',
							'bbx_callflow_name',
							'bbx_callflow_id',
							'bbx_router_id',
							'filter_type',
							'filter_value'
						],

						primary_keys: ['bbx_router_condition_map_id'],

						dataEvents: { trigger: 'router_refresh', bind: {'callflow':''}, allow_delete: true },
						add_edit_action: {
							rest: '/gui/extension/router/condition',
							rest_container: 'condition',
							filter_params: [
								'key',
								'bbx_extension_id',
								'bbx_router_condition_map_name',
								'bbx_router_condition_map_regex_metadata',
								'bbx_router_condition_map_val'
							]
						},

						delete_action: {
							rest: '/gui/extension/router/condition'
						},

						row_actions: {
							edit_cancel: { text: 'Cancel' },
							add_cancel: { text: 'Cancel' }
						},

						table_actions: {
							add_row: {
								text: 'Add a Rule',
								location: 'bottom'
							},
							delete_rows: {
								text: 'Delete Selected Rules',
								location: 'bottom',
								confirm: { title: 'Delete Selected Rules', text: 'Are you sure you want to delete the selected rules?' }
							},
							action_elements: {
								location: 'bottom',
								actions: [{
									entity_template: 'overlay_button',
									text: 'Manage Action Sets',
									overlay: 'router_actions',
									pass_params: ['bbx_router_id']
								}]
							},
							select_row: true,
							select_all_rows: true
						},
						columns: [
							{
								header: 'Time/Number Rule',
								sortable: false,
								width: '45%',
								view_element: {
									entity_template: 'router_rule_display'
								},
								edit_elements: [
									{
										entity_template: 'data_item',
										title: 'Mode:',
										elements: [{
											entity_template: 'multi_select_populate',
											name: 'bbx_mode_ids',
											rest: '/gui/mode/modes',
											none_text: '(All Modes)',
											none_value: '',
											text_field: 'bbx_mode_name',
											value_field: 'bbx_mode_id'
										}]
									},
									{
										entity_template: 'input_item',
										title: 'Days:',
										type: 'select',
										input_name: 'date_type',
										select_options: [
											{ text: 'Any Day', value: 'always' },
											{ text: 'Days of the Week', value: 'weekly' },
											{ text: 'Dates Every Year', value: 'yearly' },
											{ text: 'Specific Dates', value: 'once' }
										]
									},
									{
										entity_template: 'switch',
										closest: 'td',
										selector: '[name=date_type]',
										switch_elements: {
											weekly: [
												{
													entity_template: 'data_item',
													title: '',
													elements: [{
														entity_template: 'day_picker',
														name: 'bbx_router_condition_map_tod_wday'
													}]
 												}
											],
											yearly: [
												{
													entity_template: 'data_item',
													title: '',
													elements: [
														{
															entity: 'div',
															attributes: { name: 'bbx_router_condition_map_recurring_date' },
															widgets: ['dateRangePickerWidget'],
															widget_options: { dateRangePickerWidget: {
																date_format: 'mm/dd',
																show_year: false
															}}
														}
													]
												}
											],
											once: [
												{
													entity_template: 'data_item',
													title: '',
													elements: [
														{
															entity: 'div',
															attributes: { name: 'bbx_router_condition_map_specific_date' },
															widgets: ['dateRangePickerWidget'],
															widget_options: { dateRangePickerWidget: {
																date_format: 'mm/dd/yy'
															}}
														}
													]
												}
											]
										}
									}, // END switch
									{
										entity_template: 'data_item',
										title: 'Time:',
										elements: [{
											entity: 'div',
											widgets: ['timeSelectMinsRangeWidget'],
											attributes: { name: 'bbx_router_condition_map_tod_min_day' }
										}]
									},
									{
										entity_template: 'input_item',
										title: 'Caller:',
										input_name: 'filter_type',
										type: 'select',
										select_options: [
											{ text: 'Any callers', value: '' },
											{ text: 'Caller'+entity.rsquo+'s number starts with', value: 'cid_start' },
											{ text: 'Caller'+entity.rsquo+'s number ends with', value: 'cid_end' },
											{ text: 'Caller'+entity.rsquo+'s number is', value: 'cid_exact' },
											{ text: 'Caller'+entity.rsquo+'s number matches a regular expression', value: 'cid_regex' }
										]
									},
									{
										entity_templates: ['input_item','aopb_state'],
										aopb_state: {
											closest: 'td',
											states: [{
												conditions: { a: '$filter_type', op: '!=', b: '' },
												actions: { enabled: true, _cascade: true },
												else_actions: { enabled: false, _cascade: true }
											}]
										},
										input_item: {
											title: 'Match:',
											input_name: 'filter_value',
											type: 'text'
										}
									}
								]
							},
							{
								header: 'Action Set',
								column: 'bbx_callflow_name',
								sortable: false,
								view_elements: [
									{
										entity: 'div',
										attributes: { name: 'bbx_callflow_name' }
									},
									{
										entity: 'div',
										attributes: { name: 'callflow_summary', 'class':'info' }
									}
								],
								edit_elements: [
									{
										entity_template: 'select_populate',
										rest: '/gui/extension/router/callflows',
										before: [{ text: '(New Action Set)', value: '' }],
										rest_params_from_closest: {
											closest: 'table',
											widget: 'dataTableWidget',
											params: ['bbx_router_id']
										},
										only_rest_params: ['bbx_router_id'],
										dataEvents: {
											bind: {
												'callflow': '',
												'router_refresh': ''
											},
											allow_delete: true
										},
										text_field: 'bbx_callflow_name',
										value_field: 'bbx_callflow_id',
										name: 'bbx_callflow_id'
									},
									{
										// This works around BNPH-8041 -- the no-auto-fill is overruled by the aopbStateWidget
										entity_templates: ['aopb_state', 'container'],
										entity: 'span',
										aopb_state: {
											closest: 'tr',
											states: [{
												conditions: { a: '$bbx_callflow_id', op: '==', b: '' },
												actions: { enabled: true, visible: true, _cascade: true },
												else_actions: { enabled: false, visible: false, _cascade: true }
											}]
										},
										container: { elements: [
											{
												entity: 'input',
												widgets: ['aopbStateWidget'],
												attributes: {
													type: 'text',
													name: 'bbx_callflow_name',
													'class': 'no-auto-fill'
												},
												widget_options: {
													aopbStateWidget: {
														closest: 'tr',
														states: [
															{
																conditions: [
																	{ a: '$bbx_callflow_name', op: '!=', b: '' },
																	{ join: 'OR', a: '$bbx_callflow_id', op: '!=', b: '' }
																],
																actions: { invalid: false },
																else_actions: { invalid: 'A name is required' }
															}
														]
													}
												}
											}
										]}
									},
									{
										entity: 'button',
										attributes: { type: 'button' },
										widgets: ['editCallflowButtonWidget','aopbStateWidget'],
										widget_options: {
											editCallflowButtonWidget: {
												overlay: 'router_action_detail'
											},
											aopbStateWidget: {
												closest: 'tr',
												states: [{
													conditions: { a: '$bbx_callflow_id', op: '==', b: '' },
													actions: { visible: false },
													else_actions: { visible: true }
												}]
											}
										},
										text: 'Edit'
									},
									{
										entity: 'div',
										attributes: { 'class': 'info' },
										text: 'To add or edit action sets, use the '+entity.ldquo+'Manage Action Sets'+entity.rdquo+' button at the bottom of the table. To add a new action set, select '+entity.ldquo+'(New Action Set)'+entity.rdquo+' and enter a name.'
									}
								],
								add_elements: [
									{
										entity_template: 'select_populate',
										rest: '/gui/extension/router/callflows',
										rest_data: ['bbx_router_id'],
										text_field: 'bbx_callflow_name',
										value_field: 'bbx_callflow_id',
										before: [{ text: '(New empty action set)', value: '' }],
										name: 'bbx_callflow_id'
									},
									{
										entity: 'div',
										attributes: { 'class': 'info' },
										text: 'To add or manage actions, use the '+entity.ldquo+'Manage Action Sets'+entity.rdquo+' button at the bottom of the table.'
									}
								]

							}
						] // END columns
					} // END DTW
	 			]
			}, // END page module "Rules and Actions"
			{
				entity_template: 'page_module',
				form_action: '/gui/extension/router',
				form_options: {
					dataEvents: {
						trigger: 'router'
					}
				},
				buttons: [ 'help' ],
				help_id: 'routers_default_action',
				legend: 'Default Action',
				elements: [
					{
						entity_template: 'data_item',
						title: 'Default Action Set:',
						elements: [
							{
								entity_template: 'select_populate',
								rest: '/gui/extension/router/callflows',
								dataEvents: {
									bind: {
										'callflow': '',
										'router_refresh': ''
									},
									allow_delete: true
								},
								only_rest_params: ['bbx_router_id'],
								text_field: 'bbx_callflow_name',
								value_field: 'bbx_callflow_id',
								name: 'bbx_callflow_id_fallback'
							},
							{
								entity: 'button',
								attributes: { type: 'button' },
								widgets: ['editCallflowButtonWidget'],
								widget_options: { editCallflowButtonWidget: {
									overlay: 'router_action_detail'
								}},
								text: 'Edit'
							},
							{
								entity_template: 'overlay_button',
								text: 'Manage Action Sets',
								overlay: 'router_actions',
								pass_params: ['bbx_router_id']
							},
							{
								entity: 'div',
								attributes: { 'class': 'info' },
								text: 'If no rules match, this action will take place. To add or manage actions, use the '+entity.ldquo+'Manage Action Sets'+entity.rdquo+' button.'
							}
						]
					}
				]
			},
			elgen('secondary_page_module', { type: 'router' }, {help_id: 'secondary_extensions' })
		]}
    ]
});
