/* jslint browser: true, jquery: true */

/*
 * ┌────────────────────┐
 * │ ┌────────────────┐◀---------- Content element (.bubble('get_content_element'))
 * │ │ Bubble Content │ │          css: { color: ..., border: ..., 'background-color': ..., ... }
 * │ └─────── ▶┊---┊◀------------- tip_width: 10
 * └───────────┐  ▶┊--┊◀---------- tip_position: "bottom right"
 *              ╲  │               tip_offset: 10
 *               ╲ │
 *                ╲│◀----------- tip_point_offset: [30, 30]
 *                 ╔════════╗    append_to_target: false | true
 *                 ║ TARGET ║    target_anchor: "top left"
 *                 ╚════════╝    target_anchor_offset: [0,0]
 * 
 * AUTHOR:
 * Rudy Fleminger / Barracuda Networks, All Rights Reserved / 2015-03-24
 * 
 * REQUIRES:
 * jQuery, A modern browser supporting inline SVG (IE9+)
 * jquery.ns.js
 * jquery.bubble.js.css (from jquery.bubble.js.less)
 * 
 * USAGE:
 * Creates a bubble relative to a target element.
 * $(target).bubble({ ...options... });
 * 
 * OPTIONS:
 * css: undefined | { ...css directives... }, // CSS applied to the bubble. Border weight/color and background color are also applied to the tip.
 * 
 * tip_position: 'bottom left' || STRING,  // Position of the callout tip along the bubble's edge. Two words (top/left/bottom/right), seperated by a space: First is the edge, is the position on that edge.
 * tip_offset: 0 | NUMBER,    // Position, in pixels, that the callout tip is offset from the corner of the bubble.
 * tip_point_offset: [0, 20] | ARRAY:[ NUMBER, NUMBER ], // X/Y offset of the tip point from the center of the tip base. X is horizontal and Y is vertical, but positive values always lead away from the bubble.
 * 
 * target_anchor: 'top left' | STRING, // Corner of the target that the tip points to. Two words (top/left/bottom/right) separated by a space.
 * target_anchor_offset: [0, 0] | ARRAY:[ NUMBER, NUMBER ], // X/Y offset of the tip point
 * 
 * append_to_target: false | true, // If true, the bubble is appended to the target element. If false, the bubble is positioned to point at the target element, but is appended to the BODY.
 * force_relative_target: true | false, // Only used if append_to_target is true. If the target is not relative, absolute, or sticky positioned, this applies relative position to the target
 * warn_relative_target: true | false, // Only used if append_to_target and force_relative_target are true. If this is true and the target is made relative, a console warning will be displayed.
 * 
 * image_pad: 20 | NUMBER
 * 
 * html: undefined | html string | jQuery object, // Set the initial HTML in the bubble. Can only be used during initialization
 * text: undefined | text string, // Set the initial text in the bubble. Can only be used during initialization. Ignored if the html option is set.
 * 
 * METHODS:
 * $(target).bubble('anchor'); // Re-position the bubble if the target has moved
 * $(target).bubble('render'); // Rebuild the tip and reposition. May be necessary after options changes
 * $(target).bubble('apply_css'); // Apply changes to the bubble's CSS to the tip and positioning
 * $(target).bubble('option', { ...options... }); // Set options. May require "render" after setting, to apply.
 * $(target).bubble('get_content_element'); RETURNS jQuery object // Return the content element where content should be placed.
 * $(target).bubble('get_bubble_parent'); RETURNS jQuery object // Return the parent of all the bubble elements. This should only be used for things like fade effects-- use get_content_element for bubble content and sizing.
 * $(target).bubble('show'); // Hmm... I wonder what this does?
 * $(target).bubble('hide'); // This one, too... curious.
 * $(target).bubble('destroy'); // Remove a bubble and remove the bubble plugin from the target element
 * 
 * STYLING:
 * Aside from the "css" option, CSS styling on the ".cui-bubble-content" element will be reflected in the bubble and (to a limited degree) in the tip.
 * 
*/

(function () {
	"use strict";
	$.widget('cui3.bubble', {
		options: {
			
			tip_position: 'bottom left',
			target_anchor: 'top left',
			target_anchor_offset: [0,0],
			tip_width: 20,
			tip_point_offset: [0, 20],
			tip_offset: 0,
			image_pad: 20,
			border_width: 'auto',

			append_to_target: false,
			sync_to_target: false,
			force_relative_target: true,
			warn_relative_target: true,
			
			text: undefined,
			html: undefined,
			css: undefined,
			z_index: undefined
		},
		_create: function () {
			var self = this, $self = this.element;
			self.options._$target = this.element;
			
			self.render();

			if (self.options.html) {
				self.options._$content_element.html(self.options.html);
			} else if (self.options.text) {
				self.options._$content_element.text(self.options.text);
			}
		},
		render: function () {
			var self = this, $self = this.element, tip_position, $anchor, $tip, $bubble, $content, width_index, height_index, width_flip, height_flip, image_dims, points, p_str, $polyline, extents;
			tip_position = self.options.tip_position.split(' ');

			if (self.options._$tip) {
				self.options._$tip.remove();
			}

			if (!self.options._$anchor) { self.options._$anchor = $('<div class="cui-bubble-anchor" />'); }
			if (!self.options._$bubble) { self.options._$bubble = $('<div class="cui-bubble" />'); }
			if (!self.options._$content_element) { self.options._$content_element = $('<div class="cui-bubble-content" />').appendTo(self.options._$bubble); }

			$anchor = self.options._$anchor;
			$bubble = self.options._$bubble;
			$bubble.appendTo(self.options._$anchor);
			$content = self.options._$content_element;

			if (self.options.z_index !== undefined) { self.options._$anchor.css('z-index', self.options.z_index); }

			$bubble.css({
				top: '',
				right: '',
				bottom: '',
				left: '',
				'margin-top': '',
				'margin-bottom': '',
				'margin-right': '',
				'margin-left': '',
				'min-width': '',
				'min-height': '',
				position: 'absolute'
			});

			width_index = /(left|right)/.test(tip_position[0]) ? 1 : 0;
			height_index = 0 + !width_index;

			width_flip = /(bottom|right)/.test(tip_position[1]);
			height_flip = /(top|left)/.test(tip_position[0]);

			points = [[0,0],[],[0,0]];
			points[1] = [].concat(self.options.tip_point_offset);
			points[1][width_index] += self.options.tip_width / 2;
			if ((self.options.tip_point_offset[height_index] < 0) !== height_flip) {
				points[1][height_index] *= -1;	
			}
			points[2][width_index] = self.options.tip_width;

			extents = points.reduce(function (out, pt, idx, arr) {
				if (pt[0] < out[0]) { out[0] = pt[0]; }
				if (pt[0] > out[1]) { out[1] = pt[0]; }
				if (pt[1] < out[2]) { out[2] = pt[1]; }
				if (pt[1] > out[3]) { out[3] = pt[1]; }
				return out;
			}, [0,0,0,0]); // [ minx, maxx, miny, maxy ]

			points = points.map(function (el, idx, arr) {
				el[0] -= extents[0];
				el[1] -= extents[2];
				return el;
			});
			image_dims = [extents[1] - extents[0], extents[3] - extents[2]];

			p_str = points.reduce(function (str, pt, idx, arr) { return (str + pt.join(',') + ' '); }, '').slice(0, -1);

			$tip = self.options._$tip = $('<svg class="cui-bubble-tip" version="1.1" xmlns="http://www.w3.org/2000/svg" />');

			$tip.css({
				width: image_dims[0],
				height: image_dims[1]
			});

			self.options._$polyline = $polyline = $.ns('<polyline class="cui-bubble-tip-polyline" />', 'svg').attr('points', p_str);
			$tip.append($polyline);
			$tip.appendTo($bubble);
			$anchor.appendTo('body');

			self.anchor();

			var css_rule = {
				padding: self.options.image_pad + 'px',
				position: 'absolute'
			};

			var opposite = { top: 'bottom', right: 'left', bottom: 'top', left: 'right' };
			css_rule['padding-' + opposite[tip_position[0]]] = '0';

			var shift_amount;
			// Shift amount between first point and middle
			shift_amount = width_flip ? image_dims[width_index] - points[2][width_index] : points[0][width_index];

			css_rule[tip_position[0]] = -(image_dims[height_index] + self.options.image_pad) + 'px';
			css_rule[tip_position[1]] = self.options.tip_offset - shift_amount - self.options.image_pad + 'px';
			$tip.css(css_rule);

			$content.css('min-' + (width_index ? 'height' : 'width'), self.options.tip_offset + self.options.tip_width);
			$bubble.css(tip_position[0], 0);
			$bubble.css(tip_position[1], (width_flip ? 1 : -1) * self.options.tip_point_offset[width_index] - self.options.tip_width / 2 - self.options.tip_offset);

			// TODO: Include border width
			if (tip_position[0] === 'left') { $bubble.css('margin-left', image_dims[0]); }
			if (tip_position[0] === 'right') { $bubble.css('margin-right', image_dims[0]); }
			if (tip_position[0] === 'top') { $bubble.css('margin-top', image_dims[1]); }
			if (tip_position[0] === 'bottom') { $bubble.css('margin-bottom', image_dims[1]); }

			self.apply_css();
		},

		apply_css: function () {
			var self = this, $self = this.element, $content = self.options._$content_element, $polyline = self.options._$polyline, measure, width_index, tip_side;

			if (self.options['class']) { self.options._$content_element.addClass(self.options['class']); }

			if (typeof self.options.css === 'object') {
				$content.css(self.options.css);
			}

			width_index = /^(left|right)/.test(self.options.tip_position) ? 1 : 0;
			tip_side = self.options.tip_position.split(' ')[0];
			measure = /^(top|bottom)/.test(tip_side) ? 'Height' : 'Width';

			var border_width = self.options.border_width;
			if (border_width === null || isNaN(Number(self.options.border_width))) {
				// Find the relevant border width by zeroing it and measuring the difference
				border_width = $content['outer' + measure]();
				var backup = $content.attr('style');
				$content.css('border-' + tip_side + '-width', '0');
				border_width -= $content['outer' + measure]();
				if (backup) {
					$content.attr('style', backup);
				} else {
					$content.removeAttr('style');
				}
			}

			$content.css('margin', -border_width);
			$polyline.css('stroke-width', border_width);
			$polyline.css('stroke', $content.css('border-' + tip_side + '-color'));
			$polyline.attr('fill', $content.css('background-color'));
		},

		anchor: function () {
			var self = this, $self = this.element, $anchor = self.options._$anchor, css, target_pos;
			css = { position: 'absolute', top: '', bottom: '', left: '', right: '' };

			if (self.options.append_to_target) {
				if (self.options.force_relative_target && !/^(absolute|relative|fixed|sticky)$/.test($self.css('position'))) {
					if (self.options.warn_relative_target && window.console && window.console.warn) {
						console.warn('Setting target to relative positioning to apply a bubble: ', $self);
					}
					$self.css('position', 'relative');
				}

				if (self.options.target_anchor.indexOf('left') > -1) {
					css.left = self.options.target_anchor_offset[0];
				} else if (self.options.target_anchor.indexOf('right') > -1) {
					css.right = -self.options.target_anchor_offset[0] || 0;
				}

				if (self.options.target_anchor.indexOf('top') > -1) {
					css.top = self.options.target_anchor_offset[1];
				} else if (self.options.target_anchor.indexOf('bottom') > -1) {
					css.bottom = -self.options.target_anchor_offset[1] || 0;
				}

				$anchor.appendTo($self);

			} else {
				$anchor.appendTo('body');
				target_pos = $self.offset();

				if (self.options.target_anchor.indexOf('left') > -1) {
					css.left = target_pos.left + self.options.target_anchor_offset[0];
				} else if (self.options.target_anchor.indexOf('right') > -1) {
					css.left = target_pos.left + $self.innerWidth() - self.options.target_anchor_offset[0] || 0;
				}

				if (self.options.target_anchor.indexOf('top') > -1) {
					css.top = target_pos.top + self.options.target_anchor_offset[1];
				} else if (self.options.target_anchor.indexOf('bottom') > -1) {
					css.top = target_pos.top + $self.innerHeight() - self.options.target_anchor_offset[1] || 0;
				}

				$anchor.appendTo('body');
			}
			$anchor.css(css);
		},

		get_content_element: function () {
			return this.options._$content_element;
		},

		show: function () {
			this.options._$anchor.show();
		},
		hide: function () {
			this.options._$anchor.hide();
		},

		destroy: function () {
			var version = $.ui.version.split('.');
			$.Widget.prototype.destroy.apply(this, arguments);
			if (version[0] < 1 || (version[0] === '1' && version[1] < 9)) {
				this._destroy.apply(this, arguments);
			}
		},
		_destroy: function () {
			var self = this, k;
			if (self.destroyed) { return; }
			self.destroyed = true;
			self.options._$anchor.remove();
			for (k in self.options) {
				if (k.indexOf('_$') === 0 && self.options.hasOwnProperty(k)) {
					delete self.options[k];
				}
			}
		}
	});
})();
