/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, trueish */
/*
  A single-purpose widget to show and set the SLA selector on the phone buttons table.
*/

(function( $ ){
    var buttonSlaWidget = $.extend(true, {}, $.ui.widget.prototype, {
	options: {
	    edit_mode: false,
	    sla_rest: '/gui/phone/button',
	    flags: { sla: 'shared_line_appearance', barge: 'shared_line_appearance_barge' },
	    text: { sla: 'SLA On', barge: 'SLA+Barge', '': 'SLA Off', unavailable: '(Not Available)' }
	},

	value_widget: true,
	
	_beforeInit: function () {
	    var self = this, $self = this.element;
	    if (self.options.edit_mode) {
		$self.append('<select />'); // Prevent flicker by adding in an empty SELECT
	    }
	},

	fillData: function (d, from_self) {
	    var self = this, $self = this.element, $pd_holder;

	    if (self.options._unavailable) { return; }

	    if (!self.options._phone_data) {
		$pd_holder = $self.closest('.dataHolderContainerWidget');
		self.options._phone_data = $pd_holder.getCUIWidget('dataHolderContainerWidget').options.data;
		if (self.options._phone_data) {
		    self._setupView();
		} else {
		    // No phone data, try again once it's filled
		    $pd_holder.one('fillData', self.fillData.bind(self, d, from_self));
		    return;
		}
	    }

	    self.options.data = d;
	    
	    if (d.bbx_phone_button_id && self.options._phone_data && trueish(self.options._phone_data.allow_sla)) {
		self._requestStatus(d.bbx_phone_button_id);
	    } else if (d.bbx_phone_button_id && self.options._phone_data) {
		self._unavailable();
	    }
	},

	_setupView: function () {
	    var self = this, $self = this.element, phone = self.options._phone_data, $select;

	    if (self.options.edit_mode) {
		self.options._$select = $select = $('<select />');

		$select.append($('<option />').val('').text(self.options.text['']));
		$select.append($('<option />').val('sla').text(self.options.text.sla));

		if (trueish(phone.allow_sla_barge)) {
		    $select.append($('<option />').val('barge').text(self.options.text.barge));
		}

		$self.empty().append($select);
	    }
	},

	_requestStatus: function (id) {
	    var self = this;
	    CUI.getREST(self.options.sla_rest, { bbx_phone_button_id: id }, self._receiveStatus.bind(self));
	},

	_receiveStatus: function (d) {
	    var self = this, $self = this.element, data = d.button, states = {}, state;

	    if (self.options.destroyed) { return; }
	 
	    states.sla = trueish(data[self.options.flags.sla]);
	    states.barge = trueish(data[self.options.flags.barge]);

	    if (states.barge) {
		state = 'barge';
	    } else if (states.sla) {
		state = 'sla';
	    } else {
		state = '';
	    }

	    // Set the value or text to the proper value or text.
	    self.options._original_value = state;

	    if (self.options.edit_mode) {
		self.options._$select.val(state);
	    } else {
		$self.text(self.options.text[state]);
	    }
	},

	isDirty: function (key, was, is) {
	    return (is !== this.options._original_value);
	},

	_getWidgetValue: function () {
	    return this._wrapValue(this.options._$select.val());
	},

	_unavailable: function () {
	    var self = this, $self = this.element;
	    self.options._unavailable = true;
	    $self.text(self.options.text.unavailable);
	}
    });
    
    add_widget('buttonSlaWidget', buttonSlaWidget);
})(jQuery);
