/* jshint jquery: true, unused: vars */
/* global add_widget, trueish */
// TODO:
//


(function( $ ) {

	var wallboardDataTableWidget = $.extend(true, {}, $.ui.dataTableWidget.prototype, {
		options: {
			debounce_la_modify: true,
			debounce_la_modify_time: 100
		},

		_afterDataTableWidgetBeforeInit: function() {
			var self = this;

			// Load a global custom filtering function so we hit the custom fnFilterCustom function code above
			$.fn.dataTableExt.afnFiltering.push(
				function(oSettings, aData, iDataIndex) { 
					var dtw = $(oSettings.nTable).getCUIWidget('wallboardDataTableWidget');

					if (dtw && oSettings && aData && iDataIndex >= 0) {
						return dtw.filterRow.call(dtw, oSettings, aData, iDataIndex);
					}

					return true;
				}
			);
		},

		_afterLAModify: function(obj, la_args, index, row, old_row) {
			var self = this;
			var $self = this.element;
			var new_on_call = 0, old_on_call = 0;

			if (old_row.call_state === 'ACTIVE' || old_row.call_state === 'RINGING' || old_row.call_state === 'HELD' ||
				old_row.call_state === 'DEFER' || old_row.call_state === 'WRAPUP')
			{
				old_on_call = 1;
			} else {
				old_on_call = 0;
			}

			if (row.call_state === 'ACTIVE' || row.call_state === 'RINGING' || row.call_state === 'HELD' ||
				row.call_state === 'DEFER' || row.call_state === 'WRAPUP')
			{
				new_on_call = 1;
			} else {
				new_on_call = 0;
			}

			if (new_on_call != old_on_call) {
				var callback = function () {
					self.options.$oTable.fnDraw(true);
					self.options.resize = true;
					$self.trigger('liveModify');
					self.options.timeout_id = 0;
				};

				if (!self.options.timeout_id) {
					self.options.timeout_id = self._setTimeout(callback, 200);
				}

			}
		},

		_afterLADelete: function () {
			var self = this;
			$.ui.dataTableWidget.prototype._afterLADelete.apply(self, arguments);
			self.options.$oTable.fnDraw();
		},

		filterRow: function (oSettings, aData, iDataIndex) {
			var self = this, $self = $(this), row, num_rows, alt_row_count;

			if (aData === undefined || iDataIndex === undefined) {
				return true;
			}

			if (!self.hasRow(iDataIndex)) { return false; }

			var displayed = self.getExtraRowData(iDataIndex, "_displayed");
			if (!displayed) {
				displayed = false;
			}

			var call_state_col_num = self.getColumnNum("call_state");
			var this_call_state;
			if (call_state_col_num >= 0) {
				this_call_state = self.getCellData(iDataIndex, call_state_col_num);
			} else {
				this_call_state = self.getExtraRowData(iDataIndex, "call_state");
			}
			if (this_call_state && this_call_state.length) {
				// This row has a call up for this user, so we must show it
				if (!displayed) {
					self.options.resize = true;
					self.setExtraRowData(iDataIndex, '_displayed', true);
				}
				return true;
			}

			var user_id_col_num = self.getColumnNum("bbx_user_id");
			var this_user_id;
			if (user_id_col_num >= 0) {
				this_user_id = self.getCellData(iDataIndex, user_id_col_num);
			} else {
				this_user_id = self.getExtraRowData(iDataIndex, "bbx_user_id");
			}

			// Check to see if we are already displaying a row with this user id.
			for (row = 0, num_rows = oSettings.aiDisplay.length; row < num_rows; ++row) {
				var disp_user_id;
				if (user_id_col_num >= 0) {
					disp_user_id = self.getCellData(oSettings.aiDisplay[row], user_id_col_num);
				} else {
					disp_user_id = self.getExtraRowData(oSettings.aiDisplay[row], "bbx_user_id");
				}
				if (oSettings.aiDisplay[row] === iDataIndex) {
					// We hit the place in the display list where our current row is at, so break the loop
					break;
				}
				if (disp_user_id === this_user_id) {
					// We are already showing this user, so don't show another row for this user
					if (displayed) {
						self.options.resize = true;
						self.setExtraRowData(iDataIndex, '_displayed', false);
					}
					return false;
				}
			}

			// Check to see if we are GOING to show a row for this user ID based on call state
			var alt_rows = self.findRows("bbx_user_id", this_user_id);
			var val = 0;
			var best_timestamp = 0;
			var best_timestamp_primary = 0;
			var best_row = -1;
			var best_row_primary = -1;
			var last_state_change_col = self.getColumnNum("last_state_changed");
			var state_start_col = self.getColumnNum("state_start");
			var bridge_start_col = self.getColumnNum("bridge_start");
			var primary_col = self.getColumnNum("primary_extension");
			for (row = 0, alt_row_count = alt_rows.length; row < alt_row_count; ++row) {
				var row_idx = alt_rows[row];
				var alt_call_state;
				var is_primary = false;
				if (call_state_col_num >= 0) {
					alt_call_state = self.getCellData(row_idx, call_state_col_num);
				} else {
					alt_call_state = self.getExtraRowData(row_idx, "call_state");
				}
				if (primary_col >= 0) {
					is_primary = self.getCellData(row_idx, primary_col);
				} else {
					is_primary = self.getExtraRowData(row_idx, "primary_extension");
				}
				is_primary = (is_primary !== 'f' && trueish(is_primary));

				if (row_idx != iDataIndex && alt_call_state && alt_call_state.length) {
					// We are going to show this alternate row already, so don't show this row
					if (displayed) {
						self.options.resize = true;
						self.setExtraRowData(iDataIndex, '_displayed', false);
					}
					return false;
				}

				var last_state_changed;
				if (last_state_change_col >= 0) {
					val = self.getCellData(row_idx, last_state_change_col);
				} else {
					val = self.getExtraRowData(row_idx, "last_state_changed");
				}
				last_state_changed = (isNaN(val) || typeof val === 'undefined' || val === '' ? 0 : parseFloat(val));
				//debugLog('Last state changed: ', last_state_changed, val);
				if (last_state_changed > 0) {
					if (best_row < 0 || best_timestamp < last_state_changed) {
						best_row = row_idx;
						best_timestamp = last_state_changed;
					}
					if (is_primary && (best_row_primary < 0 || best_timestamp_primary < last_state_changed || best_timestamp_primary === 0)) {
						//debugLog("New best row primary based on last_state_changed: ", row_idx, last_state_changed);
						best_row_primary = row_idx;
						best_timestamp_primary = last_state_changed;
					}
				}

				var state_start;
				if (state_start_col >= 0) {
					val = self.getCellData(row_idx, state_start_col);
				} else {
					val = self.getExtraRowData(row_idx, "state_start");
				}
				state_start = (isNaN(val) || typeof val === 'undefined' || val === '' ? 0 : parseFloat(val));
				//debugLog('State start: ', state_start, val);
				if (state_start > 0) {
					if (best_row < 0 || best_timestamp < state_start) {
						best_row = row_idx;
						best_timestamp = state_start;
					}
					if (is_primary && (best_row_primary < 0 || best_timestamp_primary < state_start || best_timestamp_primary === 0)) {
						//debugLog("New best row primary based on state_start: ", row_idx, state_start);
						best_row_primary = row_idx;
						best_timestamp_primary = state_start;
					}
				}

				var bridge_start;
				if (bridge_start_col >= 0) {
					val = self.getCellData(row_idx, bridge_start_col);
				} else {
					val = self.getExtraRowData(row_idx, "bridge_start");
				}
				bridge_start = (isNaN(val) || typeof val === 'undefined' || val === '' ? 0 : parseFloat(val));
				//debugLog('Bridge start: ', bridge_start, val);
				if (bridge_start > 0) {
					if (best_row < 0 || best_timestamp < bridge_start) {
						best_row = row_idx;
						best_timestamp = bridge_start;
					}
					if (is_primary && (best_row_primary < 0 || best_timestamp_primary < bridge_start || best_timestamp_primary === 0)) {
						//debugLog("New best row primary based on bridge_start: ", row_idx, bridge_start);
						best_row_primary = row_idx;
						best_timestamp_primary = bridge_start;
					}
				}

				if (last_state_changed <= 0 && state_start <= 0 && bridge_start <= 0) {
					if (is_primary && (best_row_primary < 0 || best_timestamp_primary < bridge_start || best_timestamp_primary === 0)) {
						best_row_primary = row_idx;
						best_timestamp_primary = bridge_start;
					}
				}
				//debugLog("Is primary: ", false, is_primary, best_timestamp_primary, last_state_changed, state_start, bridge_start, best_row_primary);
			}

			if (best_row_primary != -1) {
				//debugLog("Best row is primary row: ", best_row_primary, iDataIndex);
				best_row = best_row_primary;
			}

			if (best_row >= 0 && best_row != iDataIndex) {
				// There is a better row based on more recent activity
				if (displayed) {
					self.options.resize = true;
					self.setExtraRowData(iDataIndex, '_displayed', false);
				}
				return false;
			} else if (best_row >= 0 && best_row === iDataIndex) {
				if (!displayed) {
					self.options.resize = true;
					self.setExtraRowData(iDataIndex, '_displayed', true);
				}
				return true;
			}

			// We have no other better row to display for this user, so show this one
			if (!displayed) {
				self.options.resize = true;
				self.setExtraRowData(iDataIndex, '_displayed', true);
			}
			return true;
		}
	});

	add_widget('wallboardDataTableWidget', wallboardDataTableWidget);

})(jQuery);
