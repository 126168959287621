/* jshint jquery: true, unused: vars */
/* global add_widget */
(function( $ ){
	var phoneButtonsDataTableWidget = $.extend(true, {}, $.ui.dataTableWidget.prototype, {
		fillData: function (d) {
			var self = this, $self = this.element;
			self.options.phone_data = $.extend(self.options.phone_data || {}, d);
			$.ui.dataTableWidget.prototype.fillData.apply(self, arguments);
			$self.trigger('fillPhoneData');
		},

		_isRowSortable: function (row_num, row_data) {
			return (Number(row_data.bbx_phone_button_position) !== 1);
		}
	});

	//    phoneButtonsDataTableWidget._isRowClickable = phoneButtonsDataTableWidget._isRowSortable;

	add_widget('phoneButtonsDataTableWidget', phoneButtonsDataTableWidget);
})(jQuery);
