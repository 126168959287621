/*
  multiSelectPopulateWidget: A widget that needs documentation. Apply to a DIV or other block element.
*/

(function( $ ){
    var multiSelectPopulateWidget = $.extend(true, {}, $.ui.widget.prototype, {
		value_widget: true,

		options: {
			template: false,
			template_selector: false,

			none_text: '(None)',
			none_value: ''
		},

		_beforeInit: function () {
			var self = this, $self = this.element, $list, $none, $none_cx;
			
			self.options._$list = $list = $('<ul class="mdc-list" />');
			
			$none = $('<li class="mdc-none"><label><input type="checkbox" checked="checked" value="" /><span class="mdc-text" /></label></li>');
			$none.find('input').val(self.options.none_value);
			$none.find('.mdc-text').text(self.options.none_text);

			$list.append($none);
						
			$none_cx = $none.find('input');
			$none_cx.on('click change', self._noneChangeHandler.bind(self, $none_cx, $list));
			self._noneChangeHandler($none_cx, $list);

			$list.appendTo($self);
		},

		_noneChangeHandler: function ($checkbox, $list) {
			var $self = this.element, is_none = $checkbox[0].checked;
			$list.find('li:not(.mdc-none) input[type=checkbox]').each(function () { this.disabled = is_none; });
			$list.toggleClass('none-selected', is_none);
		},

		_populate: function (d) {
			var self = this, $self = this.element, $list = self.options._$list, values = self.options.data;
			
			$list.find('li:not(.mdc-none)').remove();
			
			d.forEach(function (item, idx) {
				var $item, text, value, is_checked;
			
				if (self.options.value_field in item) {
					value = item[self.options.value_field];
				} else {
					return;
				}

				text = (self.options.text_field in item) ? item[self.options.text_field] : value;					
	
				$item = $('<li><label><input type="checkbox" value="" /><span class="mdc-text" /></label></li>');
				$item.find('.mdc-text').text(text);
				$item.find('input').val(value);
				$item.appendTo($list);
			});

			self.options._populated = true;
			self._applyValues();
		},
		
		_applyValues: function () {
			var self = this, $self = this.element, $list = self.options._$list, val_lookup = {}, has_values = false, $none_cx;
			if (!self.options._vals) { return; }
			if (!self.options._populated) { return; }

			self.options._vals.forEach(function (val) {
				if (val || val === 0) { val_lookup[val.toString()] = true; }
			});

			self.options._$list.find('li:not(.mdc-none) input[type=checkbox]').each(function (idx, cx) {
				cx.checked = !!val_lookup[cx.value];
				if (val_lookup[cx.value]) {
					has_values = true;
				}
			});

			$none_cx = self.options._$list.find('li.mdc-none input[type=checkbox]');
			$none_cx[0].checked = !has_values;
			self._noneChangeHandler($none_cx, self.options._$list);
		},
		
		fillData: function (d, from_self) {
			var self = this, $self = this.element, name, vals;
			name = CUI.getElementName($self);

			if (from_self) {
				self._populate(d);
			} else if (name in d) {
				vals = d[name];
				self.setValue(vals);
			}
		},

		setValue: function (vals) {
			var self = this, $self = this.element;
			if (!$.isArray(vals)) {
				vals = [vals];
			}
			self.options._vals = vals;
			self._applyValues();
		},

		_getWidgetValue: function () {
			var self = this, $self = this.element, vals;
			
			// This check MUST short-circuit first. If you refactor this so it doesn't, be sure to check that only the enabled checkboxes are being tallied up below!
			if (self.options._$list.find('li.mdc-none input[type=checkbox]:checked')[0]) {
				vals = self.options.none_value;
			} else {
				vals = [];
				self.options._$list.find('li:not(mdc-none) input[type=checkbox]:checked').each(function (idx, elem) {
					vals.push(elem.value);
				});
			}

			return self._wrapValue(vals);
		}

    });

    
    add_widget('multiSelectPopulateWidget', multiSelectPopulateWidget);
})(jQuery);
