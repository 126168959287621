/* jshint jquery: true, browser: true */
/* global classCUI, debugLog */
(function ($) {
	var allowable_drift = 1000, server_offset = 0;

	var updateServerOffset = function (server_epoch) {
		var server_milliepoch, new_offset;

		if (!server_epoch || isNaN(server_epoch)) {
			debugLog('cui.serverTime.js: No server time given to update_server_offset, using system time without an offset');
			server_offset = 0;
			return 0;
		}

		server_milliepoch = Number(server_epoch) * 1000;
		new_offset = server_milliepoch - (new Date()).getTime();

		if (Math.abs(new_offset - server_offset) > allowable_drift) {
			server_offset = new_offset;
		}
	};

	$(window).on('meteor_all', function (e,d) {
		updateServerOffset(d.msgTime);
	});

	classCUI.prototype.ServerTime = {
		getMilliepoch: function () {
			return (new Date()).getTime() + server_offset;
		}
	};

})(jQuery);
