/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){
	"use strict";
	var num_regexp = /\d+/, true_regexp = /^t/i;

	var agentMonitorWidget = $.extend({}, $.ui.widget.prototype, CUI.htmlEntityClass, CUI.bubbleInfoClass, {
		options: {
			accept_data_from_parent: true,
			wrapup_duration: 10,
			defer_duration: 10
		},

		_beforeInit: function () {
			var self = this;
			var $self = this.element;

			$self.addClass('agentMonitorWidgetType');
			self.options.$tr = $self.closest('tr');
			self.options.last_state = '';

			self._setupAgentMonitorClosures();

			self._buildHTML();

			self._bubbleInfoInit('div.timestamp', 'div.timestamp');

			return false;
		},

		_afterInit: function () {
			var self = this;
			var $self = this.element;

			self.options.$dtw = $self.closest('.wallboardDataTableWidget');
			if (self.options.$dtw) {
				self.options.dtw = self.options.$dtw.getCUIWidget('wallboardDataTableWidget');
			}
		},

		_buildHTML: function() {
			var self = this;
			var $self = this.element;

			var frag = document.createDocumentFragment();

			self.options.elem = {};

			self.options.elem.agent_name = document.createElement('div');
			self.options.elem.agent_name.className = 'agent_name';

			self.options.elem.agent_name_text = document.createElement('div');
			self.options.elem.agent_name.appendChild(self.options.elem.agent_name_text);
			self.options.elem.agent_name_missed = document.createElement('div');
			self.options.elem.agent_name_missed.className = 'missed';
			self.options.elem.agent_name.appendChild(self.options.elem.agent_name_missed);

			frag.appendChild(self.options.elem.agent_name);

			self.options.elem.extension = document.createElement('div');
			self.options.elem.extension.className = 'extension';
			frag.appendChild(self.options.elem.extension);

			self.options.elem.owner = document.createElement('div');
			self.options.elem.owner.className = 'owner';
			frag.appendChild(self.options.elem.owner);

			self.options.elem.call_state = document.createElement('div');
			self.options.elem.call_state.className = 'call_state';
			frag.appendChild(self.options.elem.call_state);

			self.options.elem.call_br = document.createElement('br');
			frag.appendChild(self.options.elem.call_br);

			self.options.elem.call_info = document.createElement('div');
			self.options.elem.call_info.className = 'call_info';
			frag.appendChild(self.options.elem.call_info);

			self.options.elem.cid_name = document.createElement('div');
			self.options.elem.cid_name.className = 'cid_name';
			self.options.elem.call_info.appendChild(self.options.elem.cid_name);

			self.options.elem.cid_number = document.createElement('div');
			self.options.elem.cid_number.className = 'cid_number';
			self.options.elem.call_info.appendChild(self.options.elem.cid_number);

			self.options.elem.timestamp = document.createElement('div');
			self.options.elem.timestamp.className = 'timestamp';
			frag.appendChild(self.options.elem.timestamp);

			self.options.elem.queue = document.createElement('div');
			self.options.elem.queue.className = 'queue';
			frag.appendChild(self.options.elem.queue);

			self.options.elem.queue_br = document.createElement('br');
			frag.appendChild(self.options.elem.queue_br);

			self.options.elem.phone_desc = document.createElement('div');
			self.options.elem.phone_desc.className = 'phone_desc';
			frag.appendChild(self.options.elem.phone_desc);

			self.options.elem.phone_model = document.createElement('div');
			self.options.elem.phone_model.className = 'phone_model';
			frag.appendChild(self.options.elem.phone_model);

			$self.append(frag);

			self.options.$time = $self.find('div.timestamp');
			self.options.$tr = $self.closest('tr');
		},

		_setupAgentMonitorClosures: function() {
			var self = this;

			self.options.finish_wrapup_closure = function() {
				self._finishWrapup();
			};
			self.options.finish_defer_closure = function() {
				self._finishDefer();
			};
		},

		_destroy: function() {
			var self = this;
			var $self = this.element;

			delete self.options.$tr;
			if (self.options.$time) {
				delete self.options.$time;
			}

			delete self.options.finish_wrapup_closure;
			delete self.options.finish_defer_closure;

			for (var e in self.options.elem) {
				delete self.options.elem[e];
			}

			if (self.options.widget_data) {
				delete self.options.widget_data;
			}

			self._bubbleInfoDestroy();
			self._htmlEntityDestroy();
		},

		_finishWrapup: function() {
			var self = this;
			var $self = this.element;

			self.options.$tr.removeClass('wrapup');
			self._clearCountdown();
			self._setAgentStatus();
			self._setCountdownInfo();
		},

		_finishDefer: function() {
			var self = this;
			var $self = this.element;

			self.options.$tr.removeClass('defer');
			self._clearCountdown();
			self._setAgentStatus();
			self._setCountdownInfo();
		},

		_setAgentStatus: function() {
			var self = this;
			var $self = this.element;
			var data = self.options.data;

			self.options.js_used_wrapup = ((new Date().getTime() / 1000) - self.options.original_start_time);
			self.options.remaining_defer = Math.ceil(self.options.defer_duration - (self.options.server_used_wrapup + self.options.js_used_wrapup));
			self.options.remaining_wrapup = Math.ceil(self.options.wrapup_duration - (self.options.server_used_wrapup + self.options.js_used_wrapup));

			self.options.last_in_defer_state = self.options.in_defer_state;
			self.options.last_in_wrapup_state = self.options.in_wrapup_state;
			self.options.in_defer_state = self.options.remaining_defer >= 1 && self.options.last_call_missed && self.options.last_call_from_queue ? true : false;

			self.options.in_wrapup_state = !!(self.options.remaining_wrapup >= 1 && self.options.last_active_call_state !== 'RINGING' && !self.options.last_call_missed && self.options.last_call_from_queue);
			self.options.in_defer_state = data.call_state ? false : self.options.in_defer_state;
			self.options.in_wrapup_state = data.call_state ? false : self.options.in_wrapup_state;

			//debugLog('', '', 'last call from queue:', self.options.last_call_from_queue, '; last call missed: ', self.options.last_call_missed, '; wrapup: ', self.options.in_wrapup_state, '; defer: ', self.options.in_defer_state, '; remaining wrapup: ', self.options.remaining_wrapup);

			// Call State Display (HELD, RINGING, On Call)
			if (self.options.in_wrapup_state) {
				CUI.quickSetText(self.options.elem.call_state, 'Wrapup');
			} else if (self.options.in_defer_state) {
				CUI.quickSetText(self.options.elem.call_state, 'Defer');
			} else if (!data.call_state) {
				CUI.quickSetText(self.options.elem.call_state, data.agent_status_name.toLowerCase());
			} else if (data.call_state == 'ACTIVE' || data.call_state == 'UNHOLD' || data.call_state == 'EARLY') {
				CUI.quickSetText(self.options.elem.call_state, 'On Call');
			} else if (data.call_state == 'RINGING' || data.call_state == 'RING_WAIT') {
				CUI.quickSetText(self.options.elem.call_state, 'Ringing');		
			} else if (data.call_state) {
				CUI.quickSetText(self.options.elem.call_state, data.call_state.toLowerCase());
			} else {
				CUI.quickSetText(self.options.elem.call_state, '');
			}

			// Queue Display
			var have_queue_state = false;
			if (data.fifo_name == 'Manual Call' || (data.bbx_queue_name && data.bbx_queue_name !== null)) {
				CUI.quickSetText(self.options.elem.queue, (
					data.fifo_name == 'Manual Call' ? data.fifo_name : (
						data.bbx_queue_name !== null && data.bbx_queue_name != 'undef' ? data.bbx_queue_name : ''
					)
				));
				CUI.quickShow(self.options.elem.queue);
				have_queue_state = true;
			} else {
				CUI.quickHide(self.options.elem.queue);
			}

			// Missed Calls Display
			if (data.agent_missed_calls > 0 && data.call_state != 'ACTIVE' && data.call_state != 'EARLY' &&
				data.call_state != 'UNHOLD' && data.call_state != 'HELD' && !self.options.$tr.hasClass('unavailable'))
			{
				//		debugLog('Missed calls: ' + data.agent_missed_calls);
				CUI.quickSetText(self.options.elem.agent_name_missed, data.agent_missed_calls);

				if (self.options.in_defer_state) {
					self.options.$tr.addClassDefer('missed_calls_flash');
					self._setTimeout(function() {
						if (!self.options.$tr.hasClass('active') && !self.options.$tr.hasClass('held')) {
							self.options.$tr.removeClassDefer('missed_calls_flash').addClassDefer('missed_calls').applyClassDefer();
						} else {
							self.options.$tr.removeClassDefer('missed_calls_flash').applyClassDefer();
						}
					}, 4500);
				} else {
					if (!self.options.$tr.hasClass('active') && !self.options.$tr.hasClass('held'))
					{
						self.options.$tr.addClassDefer('missed_calls');
					}
				}
			} else {
				self.options.$tr.removeClassDefer('missed_calls');
			}


			// Offline color change
			if (data.agent_status_name == 'Offline') {
				self.options.$tr.addClassDefer('offline');
				self.options.$tr.removeClassDefer('missed_calls');
			} else {
				self.options.$tr.removeClassDefer('offline');
			}

		},

		_setCountdownInfo: function() {
			var self = this, $self = this.element;
			var data = self.options.data;

			//debugLog('In wrapup: ', self.options.in_wrapup_state, '; In defer: ', self.options.in_defer_state, '; Server Used Wrapup: ', self.options.server_used_wrapup, '; Wrapup remaining: ', self.options.remaining_wrapup, '; Defer remaining: ', self.options.remaining_defer);

			// State-based color changes and countdown / timers
			if (self.options.in_wrapup_state) {
				self.options.server_state_start_time = parseInt(self.options.last_call_ended);
				self.options.$tr.addClassDefer('wrapup').removeClassDefer('active held ringing defer missed_calls');
				self._setCountdownTimeout(self.options.remaining_wrapup, self.options.finish_wrapup_closure);
			} else if (self.options.in_defer_state) {
				self.options.server_state_start_time = parseInt(self.options.last_call_ended);
				self.options.$tr.addClassDefer('defer').removeClassDefer('active ringing held wrapup');
				self._setCountdownTimeout(self.options.remaining_defer, self.options.finish_defer_closure);
			} else if (data.call_state == 'ACTIVE' || data.call_state == 'UNHOLD' || data.call_state == 'EARLY') {
				self.options.$tr.addClassDefer('active').removeClassDefer('ringing defer held wrapup missed_calls');
				self.options.server_start_time = parseInt(data.bridge_start);
				self._removeCountdownTimeout();
				self._clearCounter();
			} else if (data.call_state == 'RINGING' || data.call_state == 'RING_WAIT') {
				self.options.$tr.addClassDefer('ringing').removeClassDefer('active defer held wrapup');
				//self.options.server_start_time = parseInt(data.last_state_change);
				self.options.server_start_time = 0;
				self._removeCountdownTimeout();
				self._clearCounter();
			} else if (data.call_state == 'HELD') {
				self.options.$tr.addClassDefer('held').removeClassDefer('active ringing defer wrapup missed_calls');
				self.options.server_start_time = parseInt(data.last_state_change);
				self._removeCountdownTimeout();
			} else {
				self.options.$tr.removeClassDefer('held active ringing defer wrapup');
				if (data.agent_status_name == 'Available') {
					self.options.server_start_time = parseInt(data.state_start) || parseInt(data.last_state_change);
					self.options.start_time = new Date().getTime() / 1000;
				} else {
					self.options.server_start_time = parseInt(data.agent_status_last_changed);
				}
				self._removeCountdownTimeout();
				self._clearCounter();
			}


			if (self.options.server_start_time > 0 && !self.options.in_defer_state && !self.options.in_wrapup_state && ( data.call_state != 'RINGING' || data.call_state != 'RING_WAIT' )) {
				self._setCallTimeTimeout();
			} else {
				self._removeCallTimeTimeout();
			}

			if (!self.options.last_agent_status_name || data.agent_status_name != self.options.last_agent_status_name) {
				if (!self.options.dtw) {
					self.options.$dtw = $self.closest('.wallboardDataTableWidget');
					if (self.options.$dtw) {
						self.options.dtw = self.options.$dtw.getCUIWidget('wallboardDataTableWidget');
					}
				}
				if (self.options.dtw) {
					self.options.dtw.options.resize = true;
				}
			}
			self.options.last_agent_status_name = data.agent_status_name;

			self.options.$tr.applyClassDefer();


		},

		fillData: function(data, from_self) {
			var self = this;
			var $self = this.element;

			$self.data('widgetData', null);

			//debugLog('Data: ', data);

			self.options.data = data;
			self.options.defer_duration = (data.state_duration ? data.state_duration : self.options.defer_duration ? self.options.defer_duration : 10);
			self.options.wrapup_duration = (data.state_duration ? data.state_duration : self.options.wrapup_duration ? self.options.wrapup_duration : 10);

			self.options.server_current_time = data.current_time;
			self.options.start_time = new Date().getTime() / 1000;
			self.options.original_start_time = self.options.start_time;
			self.options.warning_seconds = data.bbx_user_status_warning_seconds;
			self.options.critical_seconds = data.bbx_user_status_critical_seconds;
			self.options.last_call_ended = (data.last_call_end ? parseFloat(data.last_call_end) : 0);
			self.options.last_call_missed = true_regexp.test(data.last_call_missed) ? true : false;
			self.options.last_call_from_queue = true_regexp.test(data.last_call_from_queue) ? true : false;
			self.options.server_used_wrapup = self.options.server_current_time - self.options.last_call_ended;
			if (data.call_state) { self.options.last_active_call_state = data.call_state; }

			if (data.caller_caller_id_name) {
				data.caller_caller_id_name = data.caller_caller_id_name.replace(/^0+$/g, 'Unknown');
				data.caller_caller_id_name = data.caller_caller_id_name.replace(/^$/g, 'Unknown');
			}
			if (data.caller_caller_id_number) {
				data.caller_caller_id_number = data.caller_caller_id_number.replace(/^0+$/g, 'Unknown');
				data.caller_caller_id_number = data.caller_caller_id_number.replace(/^$/g, 'Unknown');
			}
			if (data.caller_caller_id_name == data.caller_caller_id_number || 
				(num_regexp.test(data.caller_caller_id_number) && data.caller_caller_id_name == 'Unknown'))
			{
				data.caller_caller_id_name = '';
			}
			if (data.caller_caller_id_name) {
				data.caller_caller_id_name = data.caller_caller_id_name.replace(/\([^\)]+\)\s*/g, '');
				data.caller_caller_id_name = CUI.formatter.doFormat({ cid: data.caller_caller_id_name }, { format: { field: '@cid', formatter: 'cid_case' }});
			}
			if (data.caller_caller_id_number) {
				data.caller_caller_id_number = CUI.formatter.doFormat({ cid_num: data.caller_caller_id_number }, { format: { field: '@cid_num', formatter: 'phone_number', options: { no_auto_split: true }}});
			}
			if (data.agent_extension_value) {
				data.agent_extension_value = CUI.formatter.doFormat({ ext: data.agent_extension_value }, { format: { field: '@ext', formatter: 'phone_number', options: { no_auto_split: true } }});
			}

			CUI.quickSetText(self.options.elem.agent_name_text, data.agent_name);
			CUI.quickSetText(self.options.elem.extension, data.agent_extension_value);
			CUI.quickSetText(self.options.elem.phone_desc, data.bbx_phone_desc);
			CUI.quickSetText(self.options.elem.phone_model, data.bbx_phone_model);

			if (data.extension_owner != data.agent_name) {
				CUI.quickSetText(self.options.elem.owner, data.extension_owner);
				CUI.quickShow(self.options.elem.owner);
			} else {
				CUI.quickHide(self.options.elem.owner);
			}


			/* BNPH-6923 - Split this into two divs inside call_info div to try to resolve issue.
	     * if (data.caller_caller_id_name || data.caller_caller_id_number) {
	     *     self.options.elem.call_info.innerHTML = data.caller_caller_id_name + '<br/>' + data.caller_caller_id_number;
	     * } else {
	     *     self.options.elem.call_info.innerHTML = '<br/>';
	     * }
	     */

			if (data.caller_caller_id_name) {
				CUI.quickSetText(self.options.elem.cid_name, data.caller_caller_id_name);
			} else {
				CUI.quickSetText(self.options.elem.cid_name, "");
			}

			if (data.caller_caller_id_number) {
				CUI.quickSetText(self.options.elem.cid_number, data.caller_caller_id_number);
			} else {
				CUI.quickSetText(self.options.elem.cid_number, '');
			}

			// Agent Status Color Change
			if (!data.call_state && data.agent_status_name == 'Available') {
				self.options.$tr.addClassDefer('available').removeClassDefer('unavailable offline');
			} else if (!data.call_state && data.agent_status_name == 'Offline') {
				self.options.$tr.removeClassDefer('available unavailable').addClassDefer('offline');
			} else if (data.call_state) {
				self.options.$tr.removeClassDefer('available offline unavailable');
			} else {
				self.options.$tr.removeClassDefer('available offline').addClass('unavailable');
			}

			self._setAgentStatus();
			self._setCountdownInfo();
		}

	});
	add_widget('agentMonitorWidget', agentMonitorWidget);
})(jQuery);
