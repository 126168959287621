/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/*
  Generates a DTW that searches on the current day, and refreshes itself after a certain hour. This widget does NOT extend DTW, it creates a DTW under itself.
  (This is because the current-time data must be pulled first, and it is far easier to defer *making* a DTW, than it is to defer *intializing* a created DTW.)

  OPTIONS:
  {
    refresh_hours: [ <array of hour numbers (in server-local time) to refresh on> ],
    dtw_options: { <All options to be passed to the DTW. "search" is extended by this widget, and search_op is set to "AND"> }
  }

  Apply to a DIV, or other container capable of holding a TABLE. Do not apply this to a TABLE.
*/

(function( $ ){
	var refreshingDataTableWidget = $.extend(true, {}, $.ui.widget.prototype, CUI.htmlEntityClass, {
		options: {
			refresh_hours: undefined
		},

		_beforeInit: function () {
			this._getTime();
		},

		// Either build (first time) the table, or update search and refresh it (subsequent times)
		_refreshTable: function () {
			var self = this, $self = this.element, $table_div, $table, dtw, dtw_def;

			if (self.options._$table) {
				dtw = self.options._$table.getCUIWidget('dataTableWidget');
				dtw.options.search = self.options.search;
				dtw.refresh();
			} else {
				dtw_def = $.extend(true, {
					entity_template: 'data_table',
					search_op: 'AND'
				}, self.options.dtw_options, { search: self.options.search });

				$table_div = self.getEntitiesHTML([dtw_def], {});
				$table = self.options._$table = $table_div.children();
				$self.append($table);
				self.widgetizeChildren($self);
			}
		},

		// Check the time
		_getTime: function () {
			var self = this, $self = this.element;
			if (self.options.destroyed) { return; }

			CUI.getREST('/gui/system/timezone', {}, self._timeDataHandler.bind(self));
		},

		// Receive the checked time, and actually start the timer
		_timeDataHandler: function (d) {
			var self = this, $self = this.element, now, this_hour, upcoming_hour, next_hour, h_idx, h, time_left, rh_sorted;

			if (self.options.destroyed) { return; }

			d = window.FAKE_TZDATA || d.timezone;

			// WARNING: "now" is offset by d.offset. Using UTC time functions will result in the proper server-local time.
			now = new Date(d.time * 1000 + d.offset * 1000);
			this_hour = now.getUTCHours();

			// If this is the FIRST time we've run, run refreshAction once right now...
			if (!self.options._not_first) {
				self.options._not_first = true;
				self._refreshAction(now);
			}

			// The hour is NOW! (This only happens if s.o._n_h is set, so this can only happen on the timer return)
			if (self.options._next_hour !== undefined && this_hour >= self.options._next_hour) {
				self._refreshTime(d);
			}

			upcoming_hour = this_hour + 1;
			next_hour = self.options.refresh_hours[0];

			rh_sorted = self.options.refresh_hours.sort(function (a,b) { return a-b; });
			for (h_idx = 1; h_idx < rh_sorted.length; h_idx++) {
				h = rh_sorted[h_idx];
				if (upcoming_hour >= (h || 24)) {
					next_hour = h;
				}
			}

			time_left = (next_hour || 24) - upcoming_hour + ((60 - now.getUTCMinutes()) / 60);

			self.options._next_hour = next_hour;

			if (time_left <= 1) {
				self._setTimeout(self._getTime.bind(self, d.timezone), time_left * 3600000);
			} else {
				self._setTimeout(self._getTime.bind(self, d.timezone), 3600000);
			}
		},

		_refreshTime: function (d) {
			var self = this, $self = this.element, now;

			if (self.options.destroyed) { return; }

			delete self.options._next_hour;

			// WARNING: "now" is offset by d.offset. Using UTC time functions will result in the proper server-local time.
			now = new Date(d.time * 1000 + d.offset * 1000);
			self._refreshAction(now);
			self._getTime();
		},

		// Actually do whatever it is we need to do when the hour happens
		_refreshAction: function (offsetDate) {
			var self = this, $self = this.element, filter;

			if (self.options.destroyed) { return; }

			filter = offsetDate.getUTCFullYear() + '-' + (offsetDate.getUTCMonth() + 1).toString().replace(/^([0-9])$/, '0$1') + '-' + offsetDate.getUTCDate().toString().replace(/^([0-9])$/, '0$1');

			if (self.options.include_hour_in_filter) {
				filter += ' ' + (('0' + offsetDate.getUTCHours().toString()).slice(-2)) + ':00:00';
			}

			self.options.search = self.options.search || {};
			self.options.search.summary_date = '^' + filter + '$';

			if (self.options.ready) {
				self._refreshTable();
			}
		}

	});

	add_widget('refreshingDataTableWidget', refreshingDataTableWidget);
})(jQuery);
