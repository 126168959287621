/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, debugLog */
/*
  This is a container which changes content based on the value of another element<NOT IMPLEMENTED YET>, or incoming data</NOT IMPLEMENTED YET>

  REQUIRES:
  CUI.getWidgetElementValue (in cui.inputElementValue.js)

  OPTIONS:
  {
      closest: 'form',
      selector: '.selector-to-item', // jQuery selectors or $by_name selector
      switch_elements: {
	  'value_a': [ { element definition }, { element definition }, { element definition }, ... ],
	  'value_b': [ { element definition }, { element definition }, { element definition }, ... ],
	  'value_link': 'key_of_another_switch_element', // Allows multiple values to use the same element definition
	  ...
      },
      default_elements: [ ... ] | { ... },
      invalid_action: 'empty' | 'default',
      data_on_interactive_change: { ... }, // Set some fillData keys only when the change is "interactive"
      init_before_data: false // If true, the switch will initialize before data is received. This is only useful for no_initial_get forms.
  }

*/

(function( $ ){
    var switchWidget = $.extend(true, {}, $.ui.widget.prototype, {
	options: {
	    closest: 'form',
	    selector: 'input:eq(0)', // Override me!
	    invalid_action: 'empty'
	},

	_beforeInit: function () {
	    var self = this, $self = self.element;
	    $self.addClass('variableContainer');

	    if (self.options.selector.charAt(0) === '@') {
		self.options._data_selector = self.options.selector.slice(1);
	    }

	    if (self.options.init_before_data) {
		self._updateSwitchValue(undefined, false, true);
	    }
	},

	fillData: function (d, from_self) {
	    var self = this, $self = this.element;
	    self.options._data_store = $.extend({}, self.options._data_store || {}, d);
	    $.ui.widget.prototype.fillData.apply(self, arguments);
	    if ('_data_selector' in self.options) {
		self._setFromFillData(d, from_self);
	    } else {
		self._handleChange($self.closest(self.options.closest).find(self._parseSelector()), {}, {non_interactive:true});
	    }
	},

	_setFromFillData: function (d, from_self) {
	    var self = this;
	    if (self.options._data_selector in d) {
		self._updateSwitchValue(d[self.options._data_selector]);
	    }
	},

	_beforeDOMReady: function () {
	    var self = this, $self = this.element;
	    if (!('_data_selector' in self.options)) {
		self._watchEvents();
	    }
	},

	_parseSelector: function () {
	    // This does NOT support $$, because that is absurd.
	    return this.options.selector.replace(/\$([-_a-z0-9]+)/g, '[name=$1]');
	},

	_watchEvents: function () {
	    var self = this, $self = this.element;
	    var ref__handleChange = CUI.FunctionFactory.build(self._handleChange, self, { context: 'argument', first: 'context' });
	    var ref__handleFillData = self._handleFillData.bind(self);

	    self._delegate($self.closest(self.options.closest), self._parseSelector(), 'click change', ref__handleChange);
            self._bind($self.parents('.dataProducer'), 'fillData', ref__handleFillData);
	    self._bind($self.closest('tr'), 'rowReady', ref__handleFillData);
	},

	_handleFillData: function () {
	    var self = this, $self = this.element;
	    self._handleChange($self.closest(self.options.closest).find(self._parseSelector()), {}, {non_interactive:true});
	},

	_handleChange: function (elem, e, d) {
	    var self = this, $self = this.element, $elem = $(elem);
	    if (self.options.destroyed) { return; }
	    self._updateSwitchValue(CUI.getWidgetElementValue($elem, { first_value: true }), (d && !(d.non_interactive)));
	},
	
	_updateSwitchValue: function (val, interactive, force) {
	    var self = this, el_def;

	    if (self.options._previous_val !== val || force) {
		// Something actually changed!
		self.options._previous_val = val;
		
		// Handle "linked" string-value switch_elements entries
		el_def = (val in self.options.switch_elements) ? self.options.switch_elements[val] : self.options.default_elements;

		while (typeof el_def === 'string') {
		    el_def = self.options.switch_elements[el_def];
		}
		
		if (el_def) {
		    self._refreshWith(el_def, interactive ? self.options.data_on_interactive_change : undefined);
		} else {
		    self._handleInvalid(val);
		}
	    }
	},

	_handleInvalid: function (val) {
	    var self = this, $self = this.element;
	    if (self.options.invalid_action === 'remove') {
		self._clear();
	    } else {
		self._refreshWith(self.options.default_elements || [{ entity: 'span' }]);
	    }
	    debugLog('jquery.switchWidget.js: Unexpected value: ', val, $self);

	},

	_clear: function () {
	    var $self = this.element;
	    $self.empty();
	},

	_refreshWith: function (el_def, new_data) {
	    var self = this, $self = this.element, $content, ewo, fill_data;

	    fill_data = new_data ? $.extend(true, {}, self.options._data_store || {}, new_data || {}) : self.options._data_store || {};
	    
	    if (Object.prototype.toString.call(el_def) === '[object String]') {
		$self.text(el_def);
	    } else {
		// This is an element definition or array of elements -- render it
		if (!(el_def instanceof Array)) { el_def = [el_def]; }
		$content = CUI.htmlEntityClass.getEntitiesHTML(el_def, self.options.data);
		self._clear();
		$self.append($content);
		if ($content.find('.widgetType:not(.widgetized)')[0]) {
		    // $.extend(self.options.rest_params, { test: 'foo' });

		    if (self.options.rest_params) {
			ewo = $content.data('extra_widget_options') || {};
			$.extend(ewo, { rest_params: self.options.rest_params });
			$content.children().data('extra_widget_options', ewo);
		    }

		    self.options._children_widgetized_ok = true;
		    self.widgetizeChildren($content);
		    self.fillDataChildren(fill_data, true);
		    self.options._$current_element = $content;
		}
	    }
	    $self.trigger('formElementChange');
	    $self.trigger('variableContainerChange');
	}

    });

    add_widget('switchWidget', switchWidget);
})(jQuery);
