register_screen('auto_attendant_detail', {
    rest: '/gui/extension/autoattendant/autoattendant',
    dataEvents: {
	bind: {
	    'auto_attendant': '',
	    'primary_extension': ''
	}
    },
    elements: [
	{ entity_template: 'masthead' },
	{
	    entity_template: 'page_buttons',
	    buttons: [
		{ type: 'extension', extension_type: 'auto_attendant', rest: '/gui/extension/autoattendant' },
		{
		    type: 'delete',
		    rest: '/gui/extension/autoattendant/autoattendant',
		    fields: 'bbx_auto_attendant_id key',
		    confirm: {
			title: 'Delete Auto Attendant',
			text: 'Are you sure you would like to delete this Automated Attendant?'
		    },
		    dataEvents: {
			trigger: 'auto_attendant'
		    }
		},
		{ type: 'cancel' },
		{ type: 'save' }
	    ]
	},
	{ entity_template: 'page_content', elements: [
	    {
		entity_template: 'form',
		rest: '/gui/extension/autoattendant/autoattendant',
		dataEvents: {
		    trigger: 'auto_attendant'
		},
		elements: [

		    {
			entity_template: 'page_module',
			legend: 'Basic Information',
			buttons: ['help'],
			help_id: 'automated_attendant_basic_information',
			elements: [
			    {
				entity_template: 'input_item',
				type: 'text',
				input_name: 'bbx_auto_attendant_name',
				input_options: { attributes: { maxlength: '255' }},
				validate: { conditions: '_TRUE_', message: 'A Name is required.' },
				title: 'Automated Attendant Name:'
			    },
			    {
				entity_template: 'data_item',
				title: 'Greeting Sound:',
				elements: [
				    {
					entity_template: 'sound_picker',
					name: 'bbx_auto_attendant_greeting_sound',
					allow_none: false
				    }
				]
			    },
			    {
				entity_template: 'data_item',
				title: 'Short Greeting Sound:',
				elements: [
				    {
					entity_template: 'sound_picker',
					name: 'bbx_auto_attendant_short_greeting_sound'
				    }
				]
			    },
			    {
				entity_template: 'data_item',
				title: 'Invalid Sound:',
				elements: [
				    {
					entity_template: 'sound_picker',
					name: 'bbx_auto_attendant_invalid_sound'
				    }
				]
			    },
			    {
				entity_template: 'data_item',
				title: 'Exit Sound:',
				elements: [
				    {
					entity_template: 'sound_picker',
					name: 'bbx_auto_attendant_exit_sound'
				    }
				]
			    },
			    {
				entity_template: 'data_item',
				title: 'Transfer Sound:',
				elements: [
				    {
					entity_template: 'sound_picker',
					name: 'bbx_auto_attendant_transfer_sound'
				    }
				]
			    }
			]
		    },
		    {
			entity_template:'page_module',
			legend: 'Digit Actions',
			buttons: ['help'],
			help_id: 'automated_attendant_digit_actions',
			elements: [
			    {
				entity_template: 'dial_pad',
				play_tones: true,
				star_char: 'star',
				pound_char: 'pound',
				'class': 'theDialPad never-submit'
			    },
			    {
				entity_template: 'show_hide_switch',
				closest: '.page_module',
				selector: '.theDialPad',
				id_prefix: 'key_action_',
				switch_elements: {
				    '1': elgen('auto_attendant_action', { digit: 1 }),
				    '2': elgen('auto_attendant_action', { digit: 2 }),
				    '3': elgen('auto_attendant_action', { digit: 3 }),
				    '4': elgen('auto_attendant_action', { digit: 4 }),
				    '5': elgen('auto_attendant_action', { digit: 5 }),
				    '6': elgen('auto_attendant_action', { digit: 6 }),
				    '7': elgen('auto_attendant_action', { digit: 7 }),
				    '8': elgen('auto_attendant_action', { digit: 8 }),
				    '9': elgen('auto_attendant_action', { digit: 9 }),
				    '0': elgen('auto_attendant_action', { digit: 0 }),
				    'pound': elgen('auto_attendant_action', { digit: 'pound' }),
				    'star': elgen('auto_attendant_action', { digit: 'star' })
				},
				default_elements: {
				    entity_template: 'input_item',
				    type: 'div',
				    input_options: { text: 'Choose a number to edit the associated action.' }
				}
			    }
			]
		    },
		    {
			entity_template: 'page_module',
			legend: 'Digit Actions Summary',
			buttons: ['help'],
			help_id: 'automated_attendant_digit_action_summary',
			elements: [
			    {
				entity: 'div',
				text: ''
			    },
			    {
				entity_template: 'dirty_state',
				selector: '.digitActionSummaryWidget',
				undirty_actions:
				{
				    text: 'These are the currently selected digit actions, click a digit above to change its action',
				    css: { 'text-align': 'center', 'font-style': 'italic' },
				    color: 'black'
				},
				dirty_actions:
				{
				    text: 'Some changes shown below have not been applied. Press the '+entity.ldquo+'Save'+entity.rdquo+' button at the top of the page to save them.',
				    css: { 'text-align': 'center',  'font-style': 'italic' },
				    color: 'red'
				}
			    },
			    {
				entity_template: 'digit_action_summary'
			    },
			    {
				entity_template: 'data_item',
				elements: [
				    {
					entity_template: 'action_button',
					text: 'Reset Visit Counts',
					rest: '/gui/extension/autoattendant/reset',
					method: 'POST',
					refresh: true,
					include_data: ['bbx_auto_attendant_id'],
					widgets: ['dirtyStateWidget'],
					widget_options: {
					    dirtyStateWidget: {
						dirty_actions: {
						    enabled: false,
						    text: 'Save/Cancel changes to reset visit counts'
						},
						undirty_actions: {
						    enabled: true,
						    text: 'Reset Visit Counts'
						}
					    }
					}
				    }
				]
			    }
			]
		    },
		    {
			entity_template: 'page_module',
			legend: 'Extension Dialing',
			buttons: ['help'],
			help_id: 'automated_attendant_extension_dialing',
			elements: [
			    {
				entity_template: 'input_item',
				type: 'checkbox',
				title: 'Callers can Dial an Extension',
				input_name: 'bbx_auto_attendant_flag_ext_dial'
			    },
			    {
				entity_template: 'input_item',
				type: 'text',
				title: 'Prefix the dialed number with:',
				input_name: 'bbx_auto_attendant_dial_prefix',
				input_options: {
				    widgets: ['aopbStateWidget'],
				    widget_options: {
					aopbStateWidget: {
					    closest: '.page_module',
					    states: [
						{
						    conditions: { a: '$bbx_auto_attendant_flag_ext_dial', op: '==', b: '_TRUEISH_' },
						    actions: { enabled: true },
						    else_actions: { enabled: false }
						},
						{
						    conditions: { a: '$$', op: '==', b: '^([0-9#*]+|)$', b_is: 'regexp' },
						    actions: { invalid: false },
						    else_actions: { invalid: 'Invalid: Please enter digits, *, or #.' }
						}
					    ]
					}
				    }
				}
			    }
			]
		    },
		    {
			entity_template: 'page_module',
			legend: 'Prompt Repeat',
			form_action: '/gui/extension/autoattendant/autoattendant',
			buttons: ['help'],
			help_id: 'automated_attendant_prompt_repeat',
			elements: [
			    {
				entity_template: 'data_item',
				title: 'Repeat Prompts Every:',
				elements: [
				    {
					entity: 'input',
					attributes: {
					    type: 'text',
					    name: 'bbx_auto_attendant_timeout',
					    size: '4'
					},
					widgets: ['fixedMultipliedInputWidget', 'aopbStateWidget'],
					widget_options: {
					    fixedMultipliedInputWidget: {
						multiplier: 1000
					    },
					    aopbStateWidget: {
						states: {
						    conditions: [
							{ a: '$$', op: '>', b: '0' }
						    ],
						    actions: { invalid: false },
						    else_actions: { invalid: 'Please enter a number, 1 second or more' }
						}
					    }
					}
				    },
				    {
					entity: 'span',
					text: ' second(s)'
				    }
				]
			    },
			    {
				entity_template: 'data_item',
				title: 'Repeat the Prompt:',
				elements: [
				    {
					entity: 'input',
					attributes: {
					    type: 'text',
					    name: 'bbx_auto_attendant_max_timeouts',
					    size: '4'
					},
					widgets: [ 'aopbStateWidget', 'keyFilterInputWidget' ],
					widget_options: {
					    keyFilterInputWidget: {
						allow_keys_regexp: '(NUM)?[0-9]',
						allow_shift: false
					    },
					    aopbStateWidget: {
						states: {
						    conditions: [
							{ a: '$$', op: '==', b: '_NUMERIC_NONNEGATIVE_' },
							{ join: 'and', a: '$$', op: '>', b: '0' }
						    ],
						    actions: { invalid: false },
						    else_actions: { invalid: 'Please enter a number 1 or greater' }
						}
					    }
					}
				    },
				    {
					entity: 'span',
					text: ' time(s) before exiting.'
				    }
				]
			    }
			]
		    }

		] // END form elements
	    },
	    elgen('secondary_page_module', {
		type: 'auto_attendant',
		get_rest: '/gui/extension/autoattendant/autoattendant',
		add_rest: '/gui/extension/autoattendant/secondaries',
		delete_rest: '/gui/extension/autoattendant/secondaries'
	    }, { help_id: 'secondary_extensions' } )

	]} // end page content template
    ]
});
