(function () {
    register_screen('find_me', {
	elements: [
	    { entity_template: 'page_title', title: 'Find-Me Forwarding Setup' },
            {
                entity_template: 'page_buttons',
                buttons: [{ type: 'help', help_id: 'find_me_setup' }]
            },
	    {
		entity_template: 'page_content', elements: [
		    {
			entity_template: 'data_table',
			rest: '/gui/extension/user/myextensions',
			rest_params: {
			},
			click_action: 'overlay',
			overlay_name: 'fmfm_router_detail',
			overlay_params: [
			    'bbx_extension_id'
			],
			overlay_data: {
			},
			dataEvents: {
			    bind: {
				'findme': ''
			    }
			},
			page_size: 1000,
			sortable: false,
			paginate: false,
			unique_id: 'uuid',
			attributes: {
			    "class": 'cdr_dataTable'
			},
			extra_row_data: ['bbx_extension_id'],
			columns: [
			    {
				header: 'My Extensions',
				column: 'bbx_extension_value',
				sortable: false,
				formatted: { format: { field: '@bbx_extension_value', formatter: 'phone_number' } }
			    },
			    {
				header: 'Find-Me Call Forwarding Active',
				column: 'router',
				sortable: false,
				translate: {
				    0: 'Inactive: Normal Calling',
				    1: 'Active: Use Rules and Actions'
				}
			    }
			]
		    } // END DTW
		] // END page_content elements
	    } // END page_content
	] // END template elements
    }); // END template
})(); // END scoping function
