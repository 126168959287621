register_screen('sip_providers', {
    id: 'providers_sip_provider_accounts',
    elements: [
	{
	    entity_template: 'page_title',
	    title: 'SIP Providers'
	},
	{
	    entity_template: 'page_buttons',
	    buttons: [ { text:'Add a Provider', href: '#!/bps/sip_providers/overlay/provider_gateway_create/{}', attributes: { 'class':'page_save_button_style' } }, {'type': 'help', help_id:'providers_sip_provider_accounts'} ]
	},
	{
	    entity_template: 'page_content',
	    elements: [
		{
		    entity_template: 'data_table',
		    rest: '/gui/gateway/siplist',
		    rest_params: { sortby: 'bbx_provider_gateway_name', sortorder: 'asc', bbx_provider_gateway_provisioned: '0' },
		    page_size: 25,
		    data_source: 'siplist',
		    primary_keys: ['bbx_provider_gateway_id'],
		    unique_id: 'bbx_provider_gateway_id',
		    click_action: 'overlay',
		    overlay_name: 'provider_gateway_detail',
		    overlay_params: [
			'bbx_provider_gateway_id'
		    ],
		    overlay_data: {
			key: 'bbx_provider_gateway_id'
		    },
		    dataEvents: {
			bind: {
			    'provider_external': ''
			},
			allow_delete: true
		    },
		    extra_row_data: ['bbx_provider_gateway_flag_inbound', 'bbx_provider_gateway_flag_outbound', 'bbx_provider_gateway_id'],
		    name_column: 'bbx_provider_gateway_name',
		    columns: [
			{
			    header: 'Name',
			    column: 'bbx_provider_gateway_name'
			},
			{
			    header: 'Account',
			    column: 'bbx_provider_gateway_host'
			},
			{
			    header: 'Provider',
			    column: 'bbx_provider_name',
			    sortable: false
			},
			{
			    header: 'Services',
			    column: 'bbx_provider_gateway_flag_inbound',
			    view_element: {
				entity: 'div',
				widgets: ['aopbStateWidget'],
				widget_options: {
				    aopbStateWidget: {
					states: [
					    {
						conditions: [
						    {              a: '@bbx_provider_gateway_flag_inbound',  op: '==', b: '_TRUEISH_' },
						    { join: 'and', a: '@bbx_provider_gateway_flag_outbound', op: '==', b: '_TRUEISH_' }
						],
						actions: { text: 'In | Out' },
						last: true
					    },
					    {
						conditions: { a: '@bbx_provider_gateway_flag_inbound', op: '==', b: '_TRUEISH_' },
						actions: { text: 'In' },
						last: true
					    },
					    {
						conditions: { a: '@bbx_provider_gateway_flag_outbound', op: '==', b: '_TRUEISH_' },
						actions: { text: 'Out' },
						last: true
					    },
					    {
						actions: { text: 'Unknown' }
					    }
					]
				    }
				}
			    },
			    sortable: false
			},
			{
			    header: 'Status',
			    column: 'bbx_provider_gateway_state',
			    sortable: false,
			    view_element: {
				entity: 'div',
				widgets: ['mappedValueWidget'],
				widget_options: {
				    mappedValueWidget: {
					name: 'bbx_provider_gateway_state',
					maps: [
					    { match: 'NOREG', output: 'No Registration Required' },
					    { match: 'REGED', output: 'Registered' },
					    { match: 'UNREGED', output: 'Refreshing Registration' },
					    { match: 'REFRESH', output: 'Refreshing Registration' },
					    { match: 'REGISTER', output: 'Registration Required' },
					    { match: 'TRYING', output: 'Trying to register' },
					    { match: 'FAILED', output: 'Registration Failed' },
					    { match: 'FAIL_WAIT', output: 'Retrying Registration' },
					    { match: 'TIMEOUT', output: 'Registration Timed Out' },
					    { match: 'EXPIRED', output: 'Registration Expired' },
					    { output: 'Unknown' }
					]
				    }
				}
			    },
			    sortable: false
			}
		    ],
		    delete_action: {
			rest: '/gui/gateway/sip'
		    },
		    table_actions : {
			delete_rows: {
			    text: 'Delete Selected',
			    location: 'both',
			    confirm: { title: 'Delete SIP Providers', text: 'External numbers assigned to this provider will be deleted permanently. Advanced Call Routers directing calls to this provider must be re-configured after deleting this Provider. This cannot be undone.' }
			},
			select_row: true,
			select_all_rows: true,
			delete_row: { text: 'Delete' },
			refresh: { text: 'Refresh' }
		    }
		}
	    ]
	}
    ]
});
