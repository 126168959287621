register_screen('group_activity', {
    elements: [
	{ entity_template: 'page_title', title: 'Live Group Activity'},
        {		   
            entity_template: 'page_buttons',
            buttons: [{ type: 'help', help_id: 'live_group_activity' }]
        },
	{ entity_template: 'page_link', links: [ ] },
	{
	    entity_template: 'page_content', elements: [
		{
		    entity_template: 'data_table',
		    page_size: 25,
		    rest: '/gui/extension/occupied_groups',
		    rest_params: {
			primary: 1,
			sortby: 'bbx_extension_value'
		    },
		    extra_row_data: { 'type' : 'type' },
		    dataEvents: {
			bind: {
			    dataEvents: ''
			}
		    },
		    unique_id: 'bbx_extension_id',
		    click_action: 'overlay',
		    overlay_name: 'group_activity_detail',
		    overlay_params: [ 'bbx_group_id' ],
		    overlay_widget_params: { 'group_activity_group_detail': { 'expansion_values': []}},
		    overlay_data: {
			key: 'bbx_group_id'
		    },
		    columns: [
			{
			    'header': '',
			    view_element: { entity: 'div', html: CUI.sprite('group', 'Group', 'Group', true) },
			    width: 20
			},
			{ 'header': 'Extension', 'column': 'bbx_extension_value', width: '7em' },
			{ 'header': 'Group Name', 'column': 'show_name' }				
		    ],
		    table_actions: {
			refresh: { text: 'Refresh' }
		    }
		} // END DTW

	    ] // END page_content elements
	} // END page_content
    ] // END screen elements
});
