/* jshint jquery: true, unused: vars, multistr: true */
/* global add_widget */
(function( $ ){
    var dialPadWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			template_html: '<table><tbody>'+
'<tr>'+
'<td><button type="button" value="1"><span class="dialPadNum">1</span><span class="dialPadLett">&nbsp;</span></button></td>'+
'<td><button type="button" value="2"><span class="dialPadNum">2</span><span class="dialPadLett">ABC</span></button></td>'+
'<td><button type="button" value="3"><span class="dialPadNum">3</span><span class="dialPadLett">DEF</span></button></td>'+
'</tr>'+
'<tr>'+
'<td><button type="button" value="4"><span class="dialPadNum">4</span><span class="dialPadLett">GHI</span></button></td>'+
'<td><button type="button" value="5"><span class="dialPadNum">5</span><span class="dialPadLett">JKL</span></button></td>'+
'<td><button type="button" value="6"><span class="dialPadNum">6</span><span class="dialPadLett">MNO</span></button></td>'+
'</tr>'+
'<tr>'+
'<td><button type="button" value="7"><span class="dialPadNum">7</span><span class="dialPadLett">PQRS</span></button></td>'+
'<td><button type="button" value="8"><span class="dialPadNum">8</span><span class="dialPadLett">TUV</span></button></td>'+
'<td><button type="button" value="9"><span class="dialPadNum">9</span><span class="dialPadLett">WXYZ</span></button></td>'+
'</tr>'+
'<tr>'+
'<td><button type="button" value="s"><span class="dialPadNum">*</span><span class="dialPadLett">&nbsp;</span></button></td>'+
'<td><button type="button" value="0"><span class="dialPadNum">0</span><span class="dialPadLett">OPER</span></button></td>'+
'<td><button type="button" value="p"><span class="dialPadNum">#</span><span class="dialPadLett">&nbsp;</span></button></td>'+
'</tr>'+
'</tbody></table>',
			star_char: '*',
			pound_char: '#',
			play_tones: false
		},

		value_widget: true,
		set_value_widget: true,

		_beforeDOMReady: function () {
			var self = this, $self = this.element;

			self._setupAudioElements();

			self._delegate($self, 'button', 'click', function (e) {
				var $elem = $(this), raw_val = $elem.attr('value'), cooked_val, tone;

				if (isNaN(raw_val)) {
					cooked_val = (raw_val === 'p' ? self.options.pound_char : self.options.star_char);
					tone = (raw_val === 'p' ? 'pound' : 'star');
				} else {
					cooked_val = tone = Number(raw_val);
				}

				self.options.current_value = cooked_val;

				if (self.options.play_tones) {
					// Find the audio element
					var audio = $self.find('.' + 'dialPad_' + cooked_val)[0];
					// Check to see if our browser supports MP3 playback in audio elements
					if(audio.canPlayType('audio/mp3') === '') {
						// No - fall back to old method
						$.soundEffect('/sounds/' + tone + '.mp3');
					} else {
						// Yes - play that thing
						audio.play();
					}
				}

				self._emitChange();
				self._setActiveButton(raw_val);
			});

			self._bind($self.closest('.pageWidgetType'), 'screenReady', function (e) {
				self.options.current_value = '1';
				self._emitChange();
				self._setActiveButton(1);
			});
		},

		// Create the audio elements in the page that will play the sounds
		_setupAudioElements: function() {
			var self = this, $self = this.element;

			// These are the buttons we will need audio elements for
			var buttons = [0,1,2,3,4,5,6,7,8,9,'pound','star'];

			for(var b in buttons) {
				// Create the audio element
				var $audio = $('<audio>');

				// Set its properties
				$audio
					.attr('class', 'dialPad_' + buttons[b])
					.attr('src', '/sounds/' + buttons[b] + '.mp3')
					.attr('preload', 'auto');

				// Add it to the dialPadWidget's body
				$audio.appendTo($self);
			}
		},

		_setActiveButton: function (btn) {
			var self = this, $self = this.element;
			$self.find('button').removeClass('active');
			$self.find('button[value=' + btn + ']').addClass('active');
		},

		_normalizeValue: function (raw_val) {
			var self = this, $self = this.element, num = Number(raw_val);
			if (isNaN(num)) {
				if (raw_val === self.options.pound_char || raw_val === self.options.star_char) {
					return raw_val;
				} else {
					return undefined;
				}
			} else {
				return (( num > 9 ) ? undefined : num);
			}
		},

		_getButtonFromValue: function (val) {
			var self = this;
			// Get a button number, given the cooked input
			
			switch (val) {
			case self.options.pound_char:
				return 'p';
			case self.options.star_char:
				return 's';
			default:
				return val;
			}
		},

		_getWidgetValue: function () {
			return this._wrapValue(this.options.current_value);
		},

		setValue: function (v) {
			var self = this, $self = this.element;
			self.options.current_value = self._normalizeValue(v);
			self._setActiveButton(self._getButtonFromValue(self.options.current_value));
		},

		_destroy: function() {
			this.element.find('audio').remove();
		}

    });
    
    add_widget('dialPadWidget', dialPadWidget);
})(jQuery);
