/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, trueish, loginData */
/* This class is meant to be used as a base class by other page button widgets. */

(function( $ ){
    var pageSaveButtonWidget = $.extend({}, $.ui.pageButtonWidget.prototype, {
		options: {
			_has_tip: false
		},
		
		_afterFormChange: function () {
			var self = this, $self = this.element;

			if (self.options.dirty && !self.options.invalid) {
				self.enable('afc');
				self._showTip();
			}

			if (!self.options.dirty || self.options.invalid) {
				self.disable('afc');
				if (self.options._has_tip) { self._dismissTip(); }
			}
		},

		_showTip: function () {
			var self = this, $self = this.element, $tip;
			if ( !(self.options._has_tip || (window.loginData && trueish(window.loginData.seen_help_hint))) ) {
				$tip = $('<div>Click the Save button to save your changes.</div><div><button class="tip-dismiss" type="button" style="width: 100%"> OK </button></div>');
				$self.bubble({
					html: $tip,
					css: {
						'background-color': '#209920',
						padding: '5px 9px',
						color: '#fff',
						'min-width': '15em',
						'border': '2px solid #1A731A',
						'border-radius': '5px',
						'box-shadow': '2px 2px 5px 0 rgba(0,0,0,0.3)'
					},
					attach_to_target: true,
					tip_position: 'bottom right',
					tip_width: 15,
					tip_point_offset: [20, 15],
					target_anchor_position: 'top left'
				});

				self._one($tip.find('.tip-dismiss'), 'click', self._dismissTip.bind(self));
				self.options._has_tip = true;
			}
		},

		_dismissTip: function () {
			this.element.bubble('destroy');
			if (window.loginData && loginData.bbx_user_id) {
				CUI.putREST('/gui/user/help_hint', { help_hint: 1 });
				loginData.seen_help_hint = 1;
			}
		}
    });

    add_widget('pageSaveButtonWidget', pageSaveButtonWidget);
})(jQuery);
