register_screen('user_phone_create', {
    elements: [
	{ entity_template: 'page_title', title: 'Add a Phone' },
	{
	    entity_template: 'page_buttons',
	    buttons: [
		{
		    type: 'back',
		    text: 'Cancel'
		},
		{
		    type: 'save_up',
		    text: 'Save',
		    key: 'bbx_user_id',
		    detail: 'user_detail'
		}
	    ]
	},
	{ entity_template: 'page_content', elements: [
	    {
		entity_template: 'form',
		method: 'POST',
		action: '/gui/phone/phone',
		no_initial_get: true,
		submit_all: true,
		elements: [
		    {
			entity_template: 'page_module',
			legend: 'Phone Type',
			buttons: [ 'help' ],
			help_id: 'user_phone_add_type',
			elements: [
			    {
				entity_template: 'input_item',
				title: 'Phone Type',
				type: 'select',
				select_options: [
				    { text: 'Select an Unassigned Phone', value: 'phone' },
				    { text: 'Enter a phone' + entity.rsquo + 's MAC address', value: 'mac' },
				    { text: 'Add a Generic SIP Device', value: 'sip' }
				],
				input_options: {
				    attributes: {
					'class': 'phone-type'
				    }
				}
			    }
	 		]
		    }, // END page module 1
		    {
			entity_template: 'page_module',
			legend: 'Phone',
			elements: [{
			    entity_template: 'aopb_container',
			    closest: 'form',
			    strict_change_watch: true,
			    states: [
				{
				    conditions: { a: '.phone-type', op: '==', b: 'phone' },
				    last_if_true: true,
				    elements: [{
					entity_templates: ['data_table', 'aopb_state'],
					data_table: {
					    name: 'bbx_phone_id',
					    rest: '/gui/extension/list',
					    rest_params: {
						primary: 1,
						type: 'phone',
						sortby: 'bbx_extension_value'
					    },
					    extra_row_data: ['bbx_phone_id'],
					    unique_id: 'bbx_phone_id',
					    return_selected: 'bbx_phone_id',
					    click_action: 'select',
					    select_single: true,
					    table_actions: {
						refresh: { text: 'Refresh' }
					    },
					    columns: [
						{
						    header: 'Ext.',
						    column: 'bbx_extension_value',
						    'class': 'extension-column'
						},
						{
						    header: 'Name',
						    column: 'show_name'
						}
					    ]
					},
					aopb_state: {
					    states: [{
						conditions: {a: '$$', op: '!=', b: '_TRUEISH_' },
						actions: { invalid: 'Select a phone' },
						else_actions: { invalid: false }
					    }]
					}
				    }] // END elements
				}, // END state
				{
				    conditions: { a: '.phone-type', op: '==', b: 'mac' },
				    last_if_true: true,
				    elements: [{
					entity_template: 'input_item',
					type: 'text',
					title: 'Phone MAC address',
					input_name: 'bbx_phone_mac',
					input_options: {
					    widgets: [ 'aopbStateWidget' ],
					    widget_options: {
						aopbStateWidget: {
						    closest: '.pageModuleWidget',
						    states: [{
							conditions: { a: '$$', op: '==', b: '_MAC_' },
							actions: { invalid: false },
							else_actions: { invalid: 'Invalid MAC address' }
						    }]
						}
					    }
					}
				    }]
				},
				{
				    conditions: { a: '.phone-type', op: '==', b: 'sip' },
				    last_if_true: true,
				    elements: [
					{
					    entity: 'div',
					    attributes: {
						'class': 'always-dirty-indicator',
						style: 'text-align: center;'
					    },
					    text: 'No configuration is required to add a Generic SIP Device. Configure the device using the Registration Information found in the device' + entity.rsquo + 's detail page.'
					}
				    ]
				}
			    ] // END states array
			}]
		    }
		]
	    }]
	}
    ]
});


