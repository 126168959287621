/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, isAllowed, format_information, trueish */
(function( $ ){
	var peopleOnCallWidget = $.extend({}, $.ui.widget.prototype, CUI.htmlEntityClass, CUI.bubbleInfoClass, {

		options: {
			accept_data_from_parent: true
		},

		_beforeInit: function () {
			var self = this;
			var $self = this.element;

			$self.addClass('peopleOnCallWidgetType');
			self.options.$tr = $self.closest('tr');
			self.options.last_state = '';

			self._setupPeopleOnCallClosures();

			self._buildHTML();

			self.options.$time = $self.find('div.timestamp');
			self.options.$tr = $self.closest('tr');

			self._bubbleInfoInit('div.timestamp', 'div.timestamp');

			return false;
		},

		_buildHTML: function() {
			var self = this;
			var $self = this.element;

			var frag = document.createDocumentFragment();

			self.options.elem = {};

			self.options.elem.show_name = document.createElement('div');
			self.options.elem.show_name.className = 'show_name';
			frag.appendChild(self.options.elem.show_name);

			self.options.elem.extension = document.createElement('div');
			self.options.elem.extension.className = 'extension';
			frag.appendChild(self.options.elem.extension);

			self.options.elem.extension_owner = document.createElement('div');
			self.options.elem.extension_owner.className = 'owner';
			frag.appendChild(self.options.elem.extension_owner);

			self.options.elem.call_state = document.createElement('div');
			self.options.elem.call_state.className = 'call_state';
			frag.appendChild(self.options.elem.call_state);

			self.options.elem.name_br = document.createElement('br');
			frag.appendChild(self.options.elem.name_br);

			if (isAllowed('VIEW_SWITCHBOARD')) {

				self.options.elem.callee_id_name = document.createElement('div');
				self.options.elem.callee_id_name.className = 'callee_id_name';
				frag.appendChild(self.options.elem.callee_id_name);

				self.options.elem.callee_id_number = document.createElement('div');
				self.options.elem.callee_id_number.className = 'callee_id_number';
				frag.appendChild(self.options.elem.callee_id_number);

			}

			self.options.elem.timestamp = document.createElement('div');
			self.options.elem.timestamp.className = 'timestamp';
			frag.appendChild(self.options.elem.timestamp);

			self.options.elem.phone_br = document.createElement('br');
			frag.appendChild(self.options.elem.phone_br);

			self.options.elem.phone_desc = document.createElement('div');
			self.options.elem.phone_desc.className = 'phone_desc';
			frag.appendChild(self.options.elem.phone_desc);

			self.options.elem.phone_mac = document.createElement('div');
			self.options.elem.phone_mac.className = 'phone_mac';
			frag.appendChild(self.options.elem.phone_mac);

			$self.append(frag);
		},

		_setupPeopleOnCallClosures: function() {
			var self = this;

		},

		_destroy: function() {
			var self = this;
			var $self = this.element;

			delete self.options.$time;
			delete self.options.$tr;

			for (var e in self.options.elem) {
				delete self.options.elem[e];
			}

			self._htmlEntityDestroy();
		},

		fillData: function(data, from_self) {
			var self = this, $self = this.element;
			var call_state;

			$self.data('widgetData', null);

			self.options.server_start_time = parseFloat(data.last_state_change);
			self.options.server_current_time = data.current_time;
			self.options.start_time = new Date().getTime() / 1000;

			if (data.caller_id_name) {
				data.caller_id_name = data.caller_id_name.replace(/\([^\)]+\)\s*/g, '');
				data.caller_id_name = CUI.formatter.doFormat({ cid: data.caller_id_name }, { format: { field: '@cid', formatter: 'cid_case' }});
			}
			if (data.callee_id_name) {
				data.callee_id_name = data.callee_id_name.replace(/\([^\)]+\)\s*/g, '');
				data.callee_id_name = CUI.formatter.doFormat({ cid: data.callee_id_name }, { format: { field: '@cid', formatter: 'cid_case' }});
			}
			if (data.caller_id_number) {
				data.caller_id_number = CUI.formatter.doFormat( {cid_num: data.caller_id_number}, {format: {field: '@cid_num', formatter: 'phone_number', options: { no_auto_split: true }}} );
			}
			if (data.callee_id_number) {
				data.callee_id_number = CUI.formatter.doFormat( {ceid_num: data.callee_id_number}, {format: {field: '@ceid_num', formatter: 'phone_number', options: { no_auto_split: true }}} );
			}
			if (data.bbx_extension_value) {
				data.bbx_extension_value = CUI.formatter.doFormat( {ext: data.bbx_extension_value}, {format: {field: '@ext', formatter: 'phone_number', options: { no_auto_split: true }}} );
			}
			if (data.destination_formatted) {
				data.destination_formatted = format_information(data.destination_formatted);
			}

			CUI.quickSetText(self.options.elem.show_name, data.show_name);
			CUI.quickSetText(self.options.elem.extension, data.bbx_extension_value);
			if (data.extension_owner && data.extension_owner != data.show_name) {
				CUI.quickSetText(self.options.elem.extension_owner, data.extension_owner);
				CUI.quickShow(self.options.elem.extension_owner);
			} else {
				CUI.quickHide(self.options.elem.extension_owner);
			}

			CUI.quickSetText(self.options.elem.phone_desc, data.bbx_phone_desc);
			CUI.quickSetText(self.options.elem.phone_mac, data.bbx_phone_mac);

			switch (data.call_state) {
				case 'ACTIVE':
					call_state = 'On Call';
					break;
				case 'RING_WAIT':
				case 'RINGING':
					call_state = 'Ringing';
					break;
				case 'HELD':
					call_state = 'Held';
					break;
				case '':
					call_state = 'Idle';
					break;
				default:
					call_state = data.call_state;
			}
			CUI.quickSetText(self.options.elem.call_state, call_state);
			if (!data.call_state) {
				self.options.server_start_time = data.last_state_change;
			}

			var cid_name, cid_number;
			if (data.callee_id_name == data.show_name) {
				cid_name = data.caller_id_name;
				cid_number = data.caller_id_number;
			} else {
				cid_name = data.callee_id_name;
				cid_number = data.callee_id_number;
			}

			if (cid_name || cid_number) {
				CUI.quickSetText(self.options.elem.callee_id_name, cid_name);
				CUI.quickSetText(self.options.elem.callee_id_number, cid_number);
				CUI.quickShow(self.options.elem.callee_id_name);
				CUI.quickShow(self.options.elem.callee_id_number);
			} else {
				CUI.quickHide(self.options.elem.callee_id_name);
				CUI.quickHide(self.options.elem.callee_id_number);
			}

			if (data.missed_calls > 0 && !data.call_state) {
				CUI.quickSetText(self.options.elem.calls, data.missed_calls);
				CUI.quickShow(self.options.elem.missed);
				CUI.quickShow(self.options.elem.calls);

				self.options.$tr.addClassDefer('missed_calls_flash');
				self._setTimeout(function() {
					self.options.$tr.removeClassDefer('missed_calls_flash').addClassDefer('missed_calls').applyClassDefer();
				}, 4500);
			} else {
				CUI.quickHide(self.options.elem.missed);
				CUI.quickHide(self.options.elem.calls);
			}

			if (!data.destination_number && !trueish(data.online, {allowStringFalse: true}) && !data.call_start) {
				self.options.$tr.addClassDefer('inactive').removeClassDefer('online');
			} else {
				self.options.$tr.addClassDefer('online').removeClassDefer('inactive');
			}

			if (data.call_state == 'ACTIVE') {
				self.options.$tr.addClassDefer('active').removeClassDefer('ringing held');
			} else if (data.call_state == 'RINGING' || data.call_state == 'RING_WAIT') {
				self.options.$tr.addClassDefer('ringing').removeClassDefer('active held');
			} else if (data.call_state == 'HELD') {
				self.options.$tr.addClassDefer('held').removeClassDefer('active ringing');
				self.options.server_start_time = data.last_state_change;
			} else {
				self.options.$tr.removeClassDefer('held active ringing');
				self.options.server_start_time = data.last_state_change;
			}

			if ( self.options.server_start_time > 0 && (data.call_state != 'RINGING' || data.call_state != 'RING_WAIT') && data.call_state !== '' ) {
				self._setCallTimeTimeout();
			} else {
				self._removeCallTimeTimeout();
			}

			self.options.$tr.applyClassDefer();

			for (var d in data) {
				delete data[d];
			}
		}

	});    
	add_widget('peopleOnCallWidget', peopleOnCallWidget);
})(jQuery);
