/* jshint jquery: true, unused: vars */
/* global CUI, classCUI, Ape, add_widget, debugLog, setCookie, trueish, widgetize_children, getMessageFromKey, checkPermissions, widgetsSimilarTo */
/* global AJAXErrorHandler */ // -- May or may not be defined
/* global validUserID, validUsername, loginData */
// TODO:
//
// We need to support creating a row template with widgets
// and their appropriate params set for when the table is
// editable. We need to widgetize during insertion of the
// template rows.
//
// Need to implement a search area above the results for columns
// defined as searchable in the column definition.
//

(function( $ ) {

	// Sorting routines have been moved to dataTableSort.js

	$.fn.dataTableExt.oApi.fnAddOpenRow = function ( oSettings, nParent, nTr ) {
		oSettings.aoOpenRows.push({
			"nTr" : (nTr instanceof jQuery ? nTr[0] : nTr),
			"nParent" : (nParent instanceof jQuery ? nParent[0] : nParent)
		});

	};

	$.fn.dataTableExt.oApi.fnRemoveOpenRow = function( oSettings, nTr )	{
		var i;

		if (nTr instanceof jQuery) {
			nTr = nTr[0];
		}

		for ( i=oSettings.aoOpenRows.length-1 ; i>=0 ; i-- )
		{
			if ( oSettings.aoOpenRows[i].nParent == nTr )
			{
				oSettings.aoOpenRows.splice( i, 1 );
				return 0;
			}
		}
		return 1;
	};

	/*
     * Function: fnLengthChange
     * Purpose:  Change the number of records on display
     * Returns:  array:
     * Inputs:   object:oSettings - DataTables settings object
     *           int:iDisplay - New display length
     */

	$.fn.dataTableExt.oApi.fnLengthChange = function ( oSettings, tableWrapper, iDisplay ) {
		oSettings._iDisplayLength = iDisplay;
		oSettings.oApi._fnCalculateEnd( oSettings );

		/* If we have space to show extra rows (backing up from the end point - then do so */
		if ( oSettings._iDisplayEnd == oSettings.aiDisplay.length ) {
			oSettings._iDisplayStart = oSettings._iDisplayEnd - oSettings._iDisplayLength;
			if ( oSettings._iDisplayStart < 0 ) {
				oSettings._iDisplayStart = 0;
			}
		}

		if ( oSettings._iDisplayLength == -1 ) {
			oSettings._iDisplayStart = 0;
		}

		oSettings.oApi._fnDraw( oSettings );
		tableWrapper.find('select').val( iDisplay );
	};

	$.fn.dataTableExt.oApi.fnFilterClear  = function ( oSettings ) {
		var i, n, iLen;

		/* Remove global filter */
		oSettings.oPreviousSearch.sSearch = "";

		/* Remove the text of the global filter in the input boxes */
		if ( typeof oSettings.aanFeatures.f != 'undefined' ) {
			n = oSettings.aanFeatures.f;
			for ( i=0, iLen=n.length ; i<iLen ; i++ ) {
				$('input', n[i]).val( '' );
			}
		}

		/* Remove the search text for the column filters - NOTE - if you have input boxes for these filters, these will need to be reset */
		for ( i=0, iLen=oSettings.aoPreSearchCols.length ; i<iLen ; i++ ) {
			oSettings.aoPreSearchCols[i].sSearch = "";
		}

		/* Redraw */
		oSettings.oApi._fnReDraw( oSettings );
	};

	$.fn.dataTableExt.oApi.fnProcessingIndicator = function ( oSettings, onoff ) {
		if( typeof(onoff) == 'undefined' )
		{
			onoff=true;
		}
		this.oApi._fnProcessingDisplay( oSettings, onoff );
	};

	$.fn.dataTableExt.oPagination = {
		full_numbers: {
			fnInit: function( oSettings, nPaging, fnCallbackDraw ) {
				var nFirst = document.createElement( 'span' );
				var nPrevious = document.createElement( 'span' );
				var nList = document.createElement( 'span' );
				var nNext = document.createElement( 'span' );
				var nLast = document.createElement( 'span' );

				nFirst.innerHTML = oSettings.oLanguage.oPaginate.sFirst;
				nPrevious.innerHTML = oSettings.oLanguage.oPaginate.sPrevious;
				nNext.innerHTML = oSettings.oLanguage.oPaginate.sNext;
				nLast.innerHTML = oSettings.oLanguage.oPaginate.sLast;

				var oClasses = oSettings.oClasses;
				nFirst.className = oClasses.sPageButton+" "+oClasses.sPageFirst;
				nPrevious.className = oClasses.sPageButton+" "+oClasses.sPagePrevious;
				nNext.className= oClasses.sPageButton+" "+oClasses.sPageNext;
				nLast.className = oClasses.sPageButton+" "+oClasses.sPageLast;

				nPaging.appendChild( nFirst );
				nPaging.appendChild( nPrevious );
				nPaging.appendChild( nList );
				nPaging.appendChild( nNext );
				nPaging.appendChild( nLast );

				$(nFirst).on( 'click.DT', function () {
					$(this).closest('.dataTables_wrapper').find('table').trigger('firstPage');
					if ( oSettings.oApi._fnPageChange( oSettings, "first" ) )
					{
						fnCallbackDraw( oSettings );
					}
				} );

				$(nPrevious).on( 'click.DT', function() {
					$(this).closest('.dataTables_wrapper').find('table').trigger('previousPage');
					if ( oSettings.oApi._fnPageChange( oSettings, "previous" ) )
					{
						fnCallbackDraw( oSettings );
					}
				} );

				$(nNext).on( 'click.DT', function() {
					$(this).closest('.dataTables_wrapper').find('table').trigger('nextPage');
					if ( oSettings.oApi._fnPageChange( oSettings, "next" ) )
					{
						fnCallbackDraw( oSettings );
					}
				} );

				$(nLast).on( 'click.DT', function() {
					$(this).closest('.dataTables_wrapper').find('table').trigger('lastPage');
					if ( oSettings.oApi._fnPageChange( oSettings, "last" ) )
					{
						fnCallbackDraw( oSettings );
					}
				} );

				/* Take the brutal approach to cancelling text selection */
				$('span', nPaging)
					.on( 'mousedown.DT', function () { return false; } )
					.on( 'selectstart.DT', function () { return false; } );

				/* ID the first elements only */
				if ( oSettings.sTableId !== '' && typeof oSettings.aanFeatures.p == "undefined" )
				{
					nPaging.setAttribute( 'id', oSettings.sTableId+'_paginate' );
					nFirst.setAttribute( 'id', oSettings.sTableId+'_first' );
					nPrevious.setAttribute( 'id', oSettings.sTableId+'_previous' );
					nNext.setAttribute( 'id', oSettings.sTableId+'_next' );
					nLast.setAttribute( 'id', oSettings.sTableId+'_last' );
				}

			},

			fnUpdate: function( oSettings, fnCallbackDraw ) {
				if ( !oSettings.aanFeatures.p )
				{
					return;
				}

				var iPageCount = $.fn.dataTableExt.iFullNumbersShowPages;
				var iPageCountHalf = Math.floor(iPageCount / 2);
				var iPages = Math.ceil((oSettings.fnRecordsDisplay()) / oSettings._iDisplayLength);
				var iCurrentPage = Math.ceil(oSettings._iDisplayStart / oSettings._iDisplayLength) + 1;
				var sList = "";
				var iStartButton, iEndButton, i, iLen;
				var oClasses = oSettings.oClasses;

				/* Pages calculation */
				if (iPages < iPageCount)
				{
					iStartButton = 1;
					iEndButton = iPages;
				}
				else
				{
					if (iCurrentPage <= iPageCountHalf)
					{
						iStartButton = 1;
						iEndButton = iPageCount;
					}
					else
					{
						if (iCurrentPage >= (iPages - iPageCountHalf))
						{
							iStartButton = iPages - iPageCount + 1;
							iEndButton = iPages;
						}
						else
						{
							iStartButton = iCurrentPage - Math.ceil(iPageCount / 2) + 1;
							iEndButton = iStartButton + iPageCount - 1;
						}
					}
				}

				/* Build the dynamic list */
				for ( i=iStartButton ; i<=iEndButton ; i++ )
				{
					if ( iCurrentPage != i )
					{
						sList += '<span class="'+oClasses.sPageButton+'">'+i+'</span>';
					}
					else
					{
						sList += '<span class="'+oClasses.sPageButtonActive+'">'+i+'</span>';
					}
				}

				/* Loop over each instance of the pager */
				var an = oSettings.aanFeatures.p;
				var anButtons, anStatic;
				var fnClick = function() {
					/* Use the information in the element to jump to the required page */
					var iTarget = (this.innerHTML * 1) - 1;
					oSettings._iDisplayStart = iTarget * oSettings._iDisplayLength;
					fnCallbackDraw( oSettings );
					return false;
				};
				var fnFalse = function () { return false; };

				for ( i=0, iLen=an.length ; i<iLen ; i++ )
				{
					if ( an[i].childNodes.length === 0 )
					{
						continue;
					}

					/* Build up the dynamic list forst - html and listeners */
					var qjPaginateList = $('span:eq(2)', an[i]);
					qjPaginateList.html( sList );
					$('span', qjPaginateList).on( 'click.DT', fnClick ).on( 'mousedown.DT', fnFalse )
						.on( 'selectstart.DT', fnFalse );

					/* Update the 'premanent botton's classes */
					anButtons = an[i].getElementsByTagName('span');
					anStatic = [
						anButtons[0], anButtons[1],
						anButtons[anButtons.length-2], anButtons[anButtons.length-1]
					];
					$(anStatic).removeClass( oClasses.sPageButton+" "+oClasses.sPageButtonActive+" "+oClasses.sPageButtonStaticDisabled );
					if ( iCurrentPage == 1 )
					{
						anStatic[0].className += " "+oClasses.sPageButtonStaticDisabled;
						anStatic[1].className += " "+oClasses.sPageButtonStaticDisabled;
					}
					else
					{
						anStatic[0].className += " "+oClasses.sPageButton;
						anStatic[1].className += " "+oClasses.sPageButton;
					}

					if ( iPages === 0 || iCurrentPage == iPages || oSettings._iDisplayLength == -1 )
					{
						anStatic[2].className += " "+oClasses.sPageButtonStaticDisabled;
						anStatic[3].className += " "+oClasses.sPageButtonStaticDisabled;
					}
					else
					{
						anStatic[2].className += " "+oClasses.sPageButton;
						anStatic[3].className += " "+oClasses.sPageButton;
					}
				}
			}
		},

		two_button: {

			fnInit: function( oSettings, nPaging, fnCallbackDraw ) {
				var nPrevious, nNext, nPreviousInner, nNextInner, nPreviousTitle, nNextTitle;

				/* Store the next and previous elements in the oSettings object as they can be very
		 		* usful for automation - particularly testing
		 		*/
				nNextTitle = document.createElement('span');
				nPreviousTitle = document.createElement('span');
				if ( !oSettings.bJUI )
				{
					nPrevious = document.createElement( 'div' );
					nNext = document.createElement( 'div' );
					nNext.appendChild( nNextTitle );
					nPrevious.appendChild( nPreviousTitle );
				}
				else
				{
					nPrevious = document.createElement( 'a' );
					nNext = document.createElement( 'a' );

					nNextInner = document.createElement('span');
					nNextInner.className = oSettings.oClasses.sPageJUINext;
					nNext.appendChild( nNextTitle );
					nNext.appendChild( nNextInner );

					nPreviousInner = document.createElement('span');
					nPreviousInner.className = oSettings.oClasses.sPageJUIPrev;
					nPrevious.appendChild( nPreviousInner );
					nPrevious.appendChild( nPreviousTitle );
				}

				nNextTitle.className = oSettings.oClasses.sPageButtonTitle || 'pagination-title';
				nNextTitle.innerText = oSettings.oLanguage.oPaginate.sNext;

				nPreviousTitle.className = oSettings.oClasses.sPageButtonTitle || 'pagination-title';
				nPreviousTitle.innerText = oSettings.oLanguage.oPaginate.sPrevious;

				nPrevious.className = oSettings.oClasses.sPagePrevDisabled;
				nNext.className = oSettings.oClasses.sPageNextDisabled;

				nPrevious.title = oSettings.oLanguage.oPaginate.sPrevious;
				nNext.title = oSettings.oLanguage.oPaginate.sNext;

				nPaging.appendChild( nPrevious );
				nPaging.appendChild( nNext );

				$(nPrevious).on( 'click', function() {
					var $table = $(this).closest('.dataTables_wrapper').find('table');
					widgetsSimilarTo($table, 'dataTableWidget')[0]._widgetCleanup();
					$table.trigger('previousPage');
					if ( oSettings.oApi._fnPageChange( oSettings, "previous" ) )
					{
						/* Only draw when the page has actually changed */
						fnCallbackDraw( oSettings );
					}
				} );

				$(nNext).on( 'click', function() {
					var $table = $(this).closest('.dataTables_wrapper').find('table');
					widgetsSimilarTo($table, 'dataTableWidget')[0]._widgetCleanup();
					$table.trigger('nextPage');
					if ( oSettings.oApi._fnPageChange( oSettings, "next" ) )
					{
						fnCallbackDraw( oSettings );
					}
				} );

				/* Take the brutal approach to cancelling text selection */
				$(nPrevious).on( 'selectstart.DT', function () { return false; } );
				$(nNext).on( 'selectstart.DT', function () { return false; } );

				/* ID the first elements only */
				if ( oSettings.sTableId !== '' && typeof oSettings.aanFeatures.p == "undefined" )
				{
					nPaging.setAttribute( 'id', oSettings.sTableId+'_paginate' );
					nPrevious.setAttribute( 'id', oSettings.sTableId+'_previous' );
					nNext.setAttribute( 'id', oSettings.sTableId+'_next' );
				}
			},

			fnUpdate: function( oSettings, fnCallbackDraw ) {
				if ( !oSettings.aanFeatures.p )
				{
					return;
				}
				// Get the dtw
				var $tr = $(oSettings.oApi._fnGetTrNodes(oSettings));
				var $table = $tr.closest('table');
				var dtw = false;
				if ($table[0]) {
					dtw = $table.getCUIWidget('dataTableWidget');

					if (!dtw) {
						dtw = widgetsSimilarTo($table, 'dataTableWidget')[0];
					}
				}

				// paged data needs to return rows, count, and page for prev/next buttons to work
				var rows = false, total_records = false, page = false, pages = false;
				if (dtw && dtw.options.data) {
					var data      = dtw.options.data;
					rows          = parseInt(data.rows);
					total_records = parseInt(data.count);
					page          = parseInt(data.page);

					if ( rows < total_records ) {
						pages = Math.round(total_records / rows );
					} else {
						pages = 1;
					}
				}

				/* Loop over each instance of the pager */
				var an = oSettings.aanFeatures.p;
				for ( var i=0, iLen=an.length ; i<iLen ; i++ )
				{
					if ( an[i].childNodes.length !== 0 )
					{
						// Previous Page
						an[i].childNodes[0].className = ( page && page != 1 ) ?
							oSettings.oClasses.sPagePrevEnabled : oSettings.oClasses.sPagePrevDisabled;
						// Next Page
						an[i].childNodes[1].className = ( pages && page < pages ) ?
							oSettings.oClasses.sPageNextEnabled : oSettings.oClasses.sPageNextDisabled;
					}
				}
			}
		}
	};

	var dataTableWidget = $.extend({}, $.ui.widget.prototype, CUI.dataTableClass, CUI.htmlEntityClass, CUI.mixin.get('errorMessages'), {
		options: {
			rest_container: false,
			page_size: 25,
			ie_selector_hack: true,
			length_change: true, // BNPH-6961
			dirty_tracking: true,
			new_row_check: {}, // Object, not array, so it doesn't use memory filling in intermediate values
			empty_array_value: '', // Set to undefined to default to the actual empty array
			null_value: '', // Set to undefined to pass through the null value
			allow_parent_extra_row_data: false,  // extra row data can use parent values if not present in data_source
			allow_scroll: true, // allows the dataTable to set the page position when its contents change
			sortable_reorder_removes_self: true,
			enable_add_row: false,  // when adding a new row, enable add button using initial column values
			suppress_form_value: false, // If true, this table will NOT report a widget value (will return {})
			clear_row_data_on_change: false,  // when row inputs change, clear out any data from before the change (primary keys preserved)
			submit_empty_array_as: [], // Submit empty arrays as a specified value. If left [], the key will not be submitted.
			name_column: '', // Specify the name of a column used when referencing specific rows in a human-readable way (ie. row-specific error messages)
			filter_regexp_flags: 'i', // Specify regexp flags to be used on search -- ONLY included when doing interactive filtering
			submit_only_dirty: true,
			filtering: true,
			cell_tooltips: false,

			// Extension filtering adds an extension picker to allow picking a certain extension for filtering (usually a group or queue).
			// If the flag is true, the options are used. Otherwise they are ignored.
			extension_filter: false,
			extension_filter_options: undefined,

			// This is explicitly combined and deleted in s._beforeInit -- set and reference s.o.extension_filter_options
			_extension_filter_options: {
				type_text: 'By Extension',
				nothing_text: 'Show All',
				value_key: 'bbx_extension_value',
				la_filter_field: 'bbx_extension_value', // Only used on live tables. Can be an array, too.
				type: 'group',
				primary: true,
				list_field: false // Similar to search_in_list-- set this if the database field consists of a list of values in string form, like "[1,2,3]"
			},

			// This will cause the DTW to be considered invalid while a row is open; mostly useful when this DTW is inside another DTW row to prevent
			// prematurely saving the parent DTW's row while the inner DTW is still editing/adding
			invalid_while_row_open: false,

			// If searching on formatted fields (where x is added to an ext, or dashes to a 10-digit, etc.), specify a regex or regexes that will be used to
			// filter the query string and be passed along as additional search queries. This can be passed as a single regex object or as an array of regexes.
			post_process_filter: false,

			_tooltips: [],

			// paginate: <default false for liveTables, true for non-live>
			// length_change: <default false for liveTables, true for non-live>

			_dt_disable_key: 'dt_disabled',
			_filter_mode: 'text'
		},

		value_widget: true,
		manages_own_descendent_value: true,
		manages_own_descendent_events: true,

		// This flag determines whether getAllRowData (true) or getRowData (false) is used to get data for the insertRowActions call. This extra data is needed
		// on some widgets that have to pass that extra data on to the _isRow...() functions. Leave this false unless you need it.
		extra_data_to_row_actions: false,

		customAddMessage: function ($target, type, message_id, message, options) {
			var self = this, $self = this.element;
			var $wrap = $self.closest('.cui-table-datatables-wrapper'), $messages = $wrap.children('.messages');
			if (!$messages[0]) {
				$messages = $('<div class="messages dataTableMessage generalMessages" />');
				$wrap.prepend($messages);
			}

			var $existing = $messages.find('#' + message_id + '-' + type);

			if ($existing[0]) {
				$existing.text(message);
			} else {
				var $new = $('<div>').attr('id', message_id + '-' + type).addClass('messageItem ' + type).text(message).hide();
				$messages.append($new);
				$new.slideDown('fast');
			}
		},

		customDelMessage: function (type, message_id) {
			$('#' + message_id + '-' + type).slideUp('fast', function () { $(this).remove(); });
		},

		// Get the 'name' of a row, as defined by the name_column attribute. This allows the row to be easily identified in error messages
		getNameOfRow: function (row_num) {
			var self = this, $self = this.element, translate, data;

			// Is name_column set on this DTW?
			if (self.options.name_column) {
				// Loop through columns to check current col for translate
				for (var i in self.options.columns) {
					var col = self.options.columns[i];
					// Is this is the name_column?
					if (col.column === self.options.name_column && col.translate) {
						// Set aside the translation mapping for later
						translate = col.translate;
						continue;
					}
				}

				data = self.getRowWidgetData(row_num)[self.options.name_column];
				// Is translate configured on this column? If so, let's get the translation
				if (translate && translate[data]) {
					return translate[data];
				} else {
					// ..or just return the data.
					return data;
				}
			} else {
				return false;
			}
		},

		_dataTableWidgetBeforeInit: function() {
			var self = this;
			// Set this widget "similar to" a dataTableWidget
			self.options.similar_to = (self.options.similar_to || []).concat(['dataTableWidget']);

			self.element.addClass('dataTableWidget');

			if (!self.options.rest_params) {
				self.options.rest_params = {};
			}

			if (self.options.table_actions && self.options.table_actions.delete_rows && typeof self.options.table_actions.delete_rows.confirm === 'undefined') {
				// Default this to active
				self.options.table_actions.delete_rows.confirm = {};
			}

			if (self.options.search_parent_field && self.options.rest_params && self.options.rest_params[self.options.search_parent_field]) {
				self.options.search = self.options.search || {};
				self.options.search[self.options.search_parent_field] = '^' + self.options.rest_params[self.options.search_parent_field] + '$';
			}

			// Search in list allows you to search for something inside a list stored in a single column, i.e. "[1,2,3]"
			// It takes an object: { widget_param, search_param }
			// widget_param is the key that this widget is receiving as data
			// search_param is the key in the actual database table you are searching on
			// Ex: { widget_param: 'bbx_queue_id', search_param: 'queue_memberships' }
			// Widget receives bbx_queue_id from rest_params, but needs to search on queue_memberships in the live_fifo_agents table

			if (self.options.search_in_list && self.options.rest_params) {
				self.options.search = self.options.search || {};
				var widget_param;
				if (self.options.search_in_list.widget_param && self.options.rest_params[self.options.search_in_list.widget_param]) {
					widget_param = self.options.rest_params[self.options.search_in_list.widget_param];
				}

				if (widget_param) {
					self.options.search[self.options.search_in_list.search_param] = "^" + "\\[" + widget_param + "\\]$|^\\[" + widget_param + ",|," + widget_param + ",|," + widget_param + "\\]$";
				}
			}

			if (self.options.search_user_id) {
				self.options.search = self.options.search || {};
				self.options.search[self.options.search_user_id] = '^' + validUserID + '$';
			}

			if (self.options.search_user_name) {
				self.options.search = self.options.search || {};
				self.options.search[self.options.search_user_name] = '^' + validUsername + '$';
			}

			if (self.options.table_actions && self.options.table_actions.delete_rows && typeof self.options.table_actions.delete_rows.confirm === 'object') {
				self.options.table_actions.delete_rows.confirm = $.extend({
					text: 'Are you certain you wish to delete all selected rows?',
					title: 'Confirm Delete',
					ok_button: 'Yes',
					cancel_button: 'No'
				}, self.options.table_actions.delete_rows.confirm);
			}

			if (self.options.paginate) {
				self.setPageSize(self.options.page_size);
			}

			if (self._afterDataTableWidgetBeforeInit && typeof self._afterDataTableWidgetBeforeInit == 'function') {
				self._afterDataTableWidgetBeforeInit();
			}

			if (self.options.on_event) {
				if (self.options.on_event.event_name) {
					self.options.binding = Ape.bindContext(self.options.on_event.event_name, self._eventHandler.bind(self), self);
					self._addDestroyCallback(self._destroyEventBinding.bind(self));
				}
			}

			self._IDTag();
		},

		_IDTag: function ($elem) {
			if (location.search.indexOf('debugmode') === -1) { return; }

			var self = this, $self = this.element, screen, class_name;
			$elem = $elem || $self;

			if ('tag_class' in self.options) {
				if (self.options.tag_class) {
					$elem.addClass(self.options.tag_class);
				}
				return;
			} else {
				screen = $self.closest('.screen,.overlay').attr('screen-name');

				if (self.options.live_table) {
					source = [ self.options.live_table, self.options.live_table_key ];
				} else {
					source = [ (self.options.rest || self.options.refresh_rest || (self.options.add_edit_action && self.options.add_edit_action.rest) || '').replace(/^\/gui\//,'') ];
				}
				source = source || [ 'no-source' ];
				source = source.join('-');
				class_name = ['dtid', screen, source].join('-').replace(/[^a-zA-Z0-9-]/g, '-');
				class_name = class_name.replace(/-+$/, '');
				$self.addClass(class_name);

				self.options.tag_class = class_name;
			}
		},

		_destroyEventBinding: function() {
			Ape.unBind(this.options.binding);
		},

		_destroy: function() {
			delete this.options.$pageWidget;
			delete this.options.pageWidget;
			delete this.options.$oTable;
			delete this.options.$wrapper;
			delete this.options.$header;
			delete this.options.$footer;
			delete this.options.$tbody;
			delete this.options.$drag_tr;
			delete this.options.$rows;
			delete this.options.$search;
			delete this.options.$filter;
			delete this.options.$details_template;
		},

		_eventHandler: function(data, e) {
			var $self = this.element, self = this, key, matches = true, mcount = 0, acount = 0;

			if (!data || !data.json) {
				debugLog('jquery.dataTableWidget.js: Invalid event passed to eventHandler: ', data, ' -- ', $self);
				return;
			}

			if (!self.options.on_event) {
				// The feature is unnecessary
				return;
			}

			if (self.options.on_event.match_func) {
				mcount++;
			}
			if (self.options.on_event.match_any_keys) {
				mcount++;
			}
			if (self.options.on_event.match_all_keys) {
				mcount++;
			}
			if (mcount > 1) {
				debugLog('jquery.dataTableWidget.js: Invalid match specification for on_event definition in DTW: only one match type may be specified. -- ', $self);
				return;
			}

			if (self.options.on_event.action_func) {
				acount++;
			}
			if (self.options.on_event.action) {
				acount++;
			}
			if (acount > 1) {
				debugLog('jquery.dataTableWidget.js: Invalid action specification for on_event definition in DTW: only one action type may be specified. -- ', $self);
				return;
			}

			if (self.options.on_event.match_func && typeof self.options.on_event.match_func === 'function') {
				matches = self.options.on_event.match_func(self, data, e);
			} else if (self.options.on_event.match_any_keys && $.isPlainObject(self.options.on_event.match_any_keys)) {
				matches = false;
				for (key in self.options.on_event.match_any_keys) {
					if ((key in (data || {}).json && data.json[key] == self.options.on_event.match_any_keys[key]) || (key in (data || {}).json && self.options.on_event.match_any_keys[key] === true)) {
						matches = true;
						break;
					}
				}
			} else if (self.options.on_event.match_all_keys && $.isPlainObject(self.options.on_event.match_all_keys)) {
				matches = true;
				for (key in self.options.on_event.match_all_keys) {
					if (!(key in data.json) || (self.options.on_event.match_all_keys[key] !== false && data.json[key] != self.options.on_event.match_all_keys[key]) || (self.options.on_event.match_all_keys[key] === true)) {
						matches = false;
						break;
					}
				}
			}

			if (matches) {
				if (self.options.on_event.action_func && typeof self.options.on_event.action_func === 'function') {
					self.options.on_event.action_func(self, data, e);
				} else if (self.options.on_event.action) {
					if (self.options.on_event.action == 'refresh') {
						self.refresh();
					}
				}
			}
		},

		hide: function() {
			var $self = this.element;
			var self = this;

			var $wrapper = $self.closest('.cui-table-datatables-wrapper');

			$wrapper.hide();
			$self.hide();
			self.options._data_table_hidden = true;

			self._trigger('Hide');
			$self.trigger('hide');
			$self.trigger('stateChange.hide');
		},

		show: function() {
			var $self = this.element;
			var self = this;

			var $wrapper = $self.closest('.cui-table-datatables-wrapper');
			$wrapper.show();
			$self.show();

			self.options._data_table_hidden = false;

			self._trigger('Show');
			$self.trigger('show');
			$self.trigger('stateChange.show');
		},

		disable: function (key) {
			var self = this, $self = this.element, $dtw_wrap = false;
			if (key === 'dataTableInit') {
				key = false;
			}
			$.ui.widget.prototype.disable.apply(self, arguments);

			if ($self.hasClass('state-disabled')) {
				$dtw_wrap = $self.closest('.dataTables_wrapper');
				if (!$dtw_wrap.length) { self._one($self, 'dataTableInit', self.disable.bind(self))}
				$dtw_wrap.findNotUnder('input,button',$self).disable(self.options._dt_disable_key);
				$self.find('> tbody > tr > td.checkbox-column input').disable(self.options._dt_disable_key);
				$self.find('> tbody > tr > td.drag-handles').addClass('drag-handles-disabled');
			}
		},

		enable: function (key) {
			var self = this, $self = this.element, $dtw_wrap = false;
			if (key === 'dataTableInit') {
				key = false;
			}
			$.ui.widget.prototype.enable.apply(self, arguments);

			if (!$self.hasClass('state-disabled')) {
				$dtw_wrap = $self.closest('.dataTables_wrapper');
				if (!$dtw_wrap.length) { self._one($self, 'dataTableInit', self.enable.bind(self))}
				$dtw_wrap.findNotUnder('input,button',$self).enable(self.options._dt_disable_key);
				$self.find('> tbody > tr > td.checkbox-column input').enable(self.options._dt_disable_key);
				$self.find('> tbody > tr > td.drag-handles-disabled').removeClass('drag-handles-disabled');
				self.refresh();
			}
		},

		_beforeInit: function() {
			var self = this, $self = this.element, col_idx;

			$self.addClass('dataTableWidgetType');

			if ($self.attr('name') === undefined) {
				$self.addClass('never-submit');
			}

			if (self.options.live_table) {
				self.options.native_live_table = true;
			}

			// Remove any columns that fail a "requires" permission check...
			col_idx = self.options.columns.length;
			while (col_idx--) { // DO NOT convert this to a "for" loop! The splicing means we have to go backwards!
				if (self.options.columns[col_idx].requires && !checkPermissions(self.options.columns[col_idx].requires)) {
					self.options.columns.splice(col_idx, 1);
				}
			}

			// Rename the delete row methods so that outside entities
			// call our version of deleteRow. This allows us to complete
			// asynchronous DELETE REST requests before calling the
			// dataTableClass version of deleteRow.
			self._deleteDataTableRow = self.deleteRow;        // self.deleteDataTableRow now references cui.dataTableClass.deleteRow
			self.deleteRow = self._deleteDataTableWidgetRow;  // self.deleteRow now references dataTableWidget._deleteDataTableWidgetRow

			self.options.$pageWidget = $self.closest('.pageWidgetType');
			self.options.pageWidget = self.options.$pageWidget.getCUIWidget();

			self.options.action_width = 0;

			self.options.row_action_type = 'expand';
			if (self.options.row_actions && self.options.row_actions.type && self.options.row_actions.type == 'inline') {
				self.options.row_action_type = 'inline';
			}

			if (self.options.live_table) {
				// Set up some reasonable defaults specifically for live tables -- most of these make no sense on LTs
				self.options.paginate = (self.options.paginate === undefined) ? false : self.options.paginate;
				self.options.length_change = (self.options.paginate === undefined) ? false : self.options.paginate;
			} else {
				// Ditto for non-live tables
				self.options.paginate = (self.options.paginate === undefined) ? true : self.options.paginate;
				self.options.length_change = (self.options.length_change === undefined) ? true : self.options.length_change;
			}

			self.options.extension_filter_options = $.extend(true, {}, self.options._extension_filter_options, self.options.extension_filter_options || []);
			delete self.options._extension_filter_options;

			self._dataTableInit();
			self._setupDataSource();
			self._setupPagination();
			self._buildColumnIndexMap();
			self._dataTableWidgetBeforeInit();

			if (self.options.click_action) {
				$self.addClass('clickable');
			}
		},

		_beforeDOMReady: function() {
			var $self = this.element;
			var self = this;

			$self.append(document.createElement('thead'));
			$self.append(document.createElement('tbody'));
		},

		_setupSearchTooltip: function() {
			var self = this, $self = this.element, $search = $self.closest('.dataTables_wrapper').find('input.search');

			if (self.options.search_tooltip) {
				$search.attr('title', self.options.search_tooltip);
			}
		},

		// Enable bindings for tooltips
		_setupCellTooltips: function() {
			var self = this, $self = this.element;

			// Bindings
			if (self.options.cell_tooltips) {
				$self.on('mouseout', 'td', function(e){
					self._removeAllTooltips();
				});

				$self.on('mouseenter', 'td', function(e){
					self._showCellTooltip($(this), e);
				});
			}
		},

		// All the actual tooltip-showing stuff is in here
		_showCellTooltip: function($cell, event) {
			var self = this, $self = this.element;

			$(document).on('mousemove.tooltips', function(e){
				tooltip.event = e; // So that we are storing the most recent cursor position in case we have to update w/o an event handy
				updatePosition(tooltip, e);
			});

			// Update the position of the tooltip
			var updatePosition = function(tooltip, event) {
				if (tooltip.displayed) {
					// This changes if we upgrade jQ UI! -- Set the tooltip's position based on mousemove events
					tooltip.$tooltip.position({
						my: "left top",
						of: event || tooltip.event,
						offset: '10 10'
					});
				}
			};

			// Create the tooltip DOM object
			var createTooltip = function(label) {
				var $tooltip = $("<div>")
				.addClass('tooltip')
				.text(label);
				return $tooltip;
			};

			var displayTooltip = function(tooltip) {
				tooltip.$tooltip.appendTo("body");
				tooltip.displayed = true;
				updatePosition(tooltip);
			};

			var tooltip      = { displayed: false };
			tooltip.$tooltip = createTooltip(self.options.columns[$cell.parent().children().index($cell)].header);
			tooltip.timeout  = setTimeout(function(){
				displayTooltip(tooltip);
			}, 500);

			self.options._tooltips.push(tooltip);
		},

		// Clear all existing tooltips
		_removeAllTooltips: function() {
			var self = this, $self = this.element, t;

			$(document).off('mousemove.tooltips'); // Unbind mousemove

			// Clear out all existing timeouts for displaying tooltips
			for (t in self.options._tooltips) {
				var tooltip = self.options._tooltips[t];

				clearTimeout(tooltip.timeout); // Cancel the timeout so the tooltip doesn't show up if it hasn't already

				if (tooltip.displayed) {
					tooltip.$tooltip.remove(); // Remove tooltip from DOM
				}
			}

			self.options._tooltips = [];
		},

		_afterInit: function() {
			var $self = this.element;
			var self = this;

			self.options.$page_modules = $self.closest('div.page_module');

			if (!self.options.disable_cui_wrapper) {
				$self.addClass('cui-table');
				if (self.options.large_table) {
					$self.addClass('cui-table-large');
				}
			}

			// Setup tooltips if our DTW def has them enabled
			if (self.options.cell_tooltips) {
				self._setupCellTooltips();
			}

			if (self.options.refresh_on) {
				for(var key in self.options.refresh_on) {
					var obj = self.options.refresh_on[key];
					if (obj.method && obj.event) {
						switch(obj.method) {
							case 'find':
								if (obj.selector) {
									self._bind($self.find(obj.selector), obj.event, self.refresh.bind(self));
								} else {
									debugLog('jquery.dataTableWidget.js: No selector specified for refresh_on method "find" using event "' + obj.event + '". -- ', $self);
								}
								break;
							case 'self':
							       self._bind($self, obj.event, self.refresh.bind(self));
								break;
							case 'closest':
								if (obj.selector) {
									self._bind($self.closest(obj.selector), obj.event, self.refresh.bind(self));
								} else {
									debugLog('jquery.dataTableWidget.js: No selector specified for refresh_on method "closest" using event "' + obj.event + '".');
								}
								break;
							case 'closest_find':
								if (obj.closest && obj.find) {
									self._bind($self.closest(obj.closest).find(obj.find), obj.event, self.refresh.bind(self));
								} else {
									debugLog('jquery.dataTableWidget.js: No "closest" selector or no "find" selector specified for refresh_on method "closest_find" using event "' + obj.event + '".');
								}
								break;
							case 'delegate':
								if (obj.target && obj.selector) {
									self._delegate($(obj.target), obj.selector, obj.event, self.refresh.bind(self));
								} else {
									debugLog('jquery.dataTableWidget.js: No target or selector specified for refresh_on method "delegate" using event "' + obj.event  + '"');
								}
								break;
						}
					}
				}
			}

			if (self.options.collapse_to_module) {
				$self.closest('.dataTables_wrapper, .cui-table-datatables-wrapper').addClass('collapse-to-module');
			}

			if (self.options.search_tooltip) {
				self._setupSearchTooltip();
			}

			// Remove the DataTables_sort_wrapper class, because that gives us the "finger" pointer when the col isn't sortable
			for(var col_i in self.options.columns) {
				col_i = parseInt(col_i);
				var column = self.options.columns[col_i];

				if(column.sortable === false) {
					$self.find('.DataTables_sort_wrapper').eq(col_i + self.options.col_offset).addClass('no_pointer');
				}
			}
		},

		// This is called on page change in order to clean up any widgets that require destruction (ex: soundPlayerWidget)
		// To make use of this for a widget class, set the property "requires_destroy" to true in the class
		_widgetCleanup: function() {
			var self = this, $self = this.element;

			$self.find('.widgetRequiresDestroy').each(function(){
				$(this).remove();
			});
		},

		_afterSetURIParams: function () {
			var self = this, $self = this.element, col_name, loc, search, popup, key, val_key, val, row_val, row_params, row_id, $tr, $details_tr, $node, params, widget;

			//debugLog('Inside _afterSetURIParams.');

			if (!self.options.uri_params && !self.options.old_uri_params) {
				return;
			}

			//debugLog('NEW URI Params: ', self.options.uri_params);
			//debugLog('OLD URI Params: ', self.options.old_uri_params);


			if (self.options.details_row && self.options.row_key) {
				col_name = self.options.row_key;

				//debugLog('Window Location: ', window.location);
				if (window.location.search.match(/embedded/) && self.options.uri_params.wallboard) {
					loc = window.location;
					search = window.location.search || '';
					search = search.replace(/&?embedded&?/, '');
					search = search.replace(/&?embedded=1&?/, '');
					search = search.replace(/^\?$/, '');
					loc = (window.location.origin ? window.location.origin : '') + window.location.pathname + search + window.location.hash;
					//debugLog('Origin: ', window.location.origin, '; Pathname: ', window.location.pathname, '; Search: ', search, '; Hash: ', window.location.hash);
					setCookie('embedded_popup_url', loc);
					popup = window.open('/embedded_popup.html', 'wallboard', "status=1,toolbar=1,resizable=1,location=1,menubar=1,titlebar=1,scrollbars=1,left=0,top=0,fullscreen=1");
					self.options.hide_wbbut = false;
					self.options.uri_params.hide_wbbut = false;
					self._wallboardMode(false, false);
				} else {
					if (self.options.uri_params.wallboard && !self.options.in_wallboard_mode) {
						self._wallboardMode(true, true);
					} else if (!self.options.uri_params.wallboard && self.options.old_uri_params && self.options.old_uri_params.wallboard && self.options.in_wallboard_mode) {
						self._wallboardMode(false, true);
					}
				}

				if (self.options.uri_params.expansion_values) {

					for (val_key in self.options.uri_params.expansion_values) {
						val = self.options.uri_params.expansion_values[val_key];
						row_id = self.findRowId(col_name, val);
						if (row_id >= 0) {
							$tr = $(self.options.$oTable.fnGetNodes(row_id));
							self.selectRow($tr, true);
						}
					}
				}

				if (self.options.uri_params &&
					(self.options.uri_params.expansion_values || (
					!self.options.uri_params.expansion_values && self.options.old_uri_params && self.options.old_uri_params.expansion_values
				)))
				{
					// Deselect any rows not marked as selected in the URI params
					$self.find('tr.state-selected').not($self.find('tr tr.state-selected, tr.details_row')).each(function() {
						var $tr = $(this);
						var row_num = self.options.$oTable.fnGetPosition(this);
						var row_data = self.getRowWidgetData(row_num);
						var row_val = row_data[self.options.row_key];

						if (!self.options.uri_params.expansion_values || self.options.uri_params.expansion_values.indexOf(row_val) == -1) {
							self.deselectRow($tr, true);
						}
					});
				}

				for (key in self.options.uri_params) {
					if (key.match(/^row_/)) {
						row_val = key.replace(/^row_/, '');
						row_params = self.options.uri_params[key];
						row_id = self.findRowId(col_name, row_val);
						if (row_id >= 0) {
							$tr = $(self.options.$oTable.fnGetNodes(row_id));
							for(key in row_params) {
								$node = $tr.find('.widgetType[uri_id=' + key + ']');
								if ($node[0]) {
									widget = $node.getCUIWidget();
									widget.setURIParams(row_params[key]);
								}
							}
						}
					}
					if (key.match(/^row_detail_/)) {
						row_val = key.replace(/^row_detail_/, '');
						row_params = self.options.uri_params[key];
						row_id = self.findRowId(col_name, row_val);
						if (row_id >= 0) {
							$tr = $(self.options.$oTable.fnGetNodes(row_id));
							$details_tr = $tr.nextAll().eq(0);
							for(key in row_params) {
								params = row_params[key];
								$node = $details_tr.find('.widgetType[uri_id=' + key + ']');
								if ($node[0]) {
									widget = $node.getCUIWidget();
									widget.setURIParams(params);
								}
							}
						}
					}
				} // End of For Loopp

				// Look through the old URI params to see if we stopped passing URI params to a row
				for (key in self.options.old_uri_params) {
					if (key.match(/^row_/) && !self.options.uri_params[key]) {
						row_val = key.replace(/^row_/, '');
						row_params = self.options.old_uri_params[key];
						row_id = self.findRowId(col_name, row_val);
						if (row_id >= 0) {
							$tr = $(self.options.$oTable.fnGetNodes(row_id));
							for(key in row_params) {
								$node = $tr.find('.widgetType[uri_id=' + key + ']');
								if ($node[0]) {
									widget = $node.getCUIWidget();
									widget.setURIParams({});
								}
							}
						}
					}
					if (key.match(/^row_detail_/) && !self.options.uri_params[key]) {
						row_val = key.replace(/^row_detail_/, '');
						row_params = self.options.old_uri_params[key];
						row_id = self.findRowId(col_name, row_val);
						if (row_id >= 0) {
							$tr = $(self.options.$oTable.fnGetNodes(row_id));
							$details_tr = $tr.nextAll().eq(0);
							for(key in row_params) {
								params = row_params[key];
								$node = $details_tr.find('.widgetType[uri_id=' + key + ']');
								if ($node[0]) {
									widget = $node.getCUIWidget();
									widget.setURIParams({});
								}
							}
						}
					}
				} // End of For Loop
			}

			if (self.options.uri_params.hide_wbbut) {
				self.options.$wrapper.find('.fg-toolbar').eq(0).find('.wallboard_button').hide();
			}

		},

		_initURIParams: function() {
			var self = this;
			var $self = this.element;

			if (!self.options.row_key || !$self.attr('uri_id')) {
				return;
			}
			if (!self.hasColumn(self.options.row_key)) {
				return;
			}

			self.options.uri_params = {};
		},

		_storeURIParams: function($caller, params) {
			var self = this;
			var $self = this.element;

			var param_type;
			var $row;

			if (!$caller.attr('uri_id')) {
				debugLog('WARNING: Missing uri_id on widget attempting to set URI params: ', $caller);
				return;
			}
			if (!$self.attr('uri_id')) {
				if (self.options.parent && self.options.parent._storeURIParams) {
					self.options.parent._storeURIParams($caller, params);
				}
			}
			if (!self.options.row_key || !$self.attr('uri_id')) {
				return;
			}
			if (!self.hasColumn(self.options.row_key)) {
				return;
			}
			var caller_uri_id = $caller.attr('uri_id');

			// Figure out which row these URI params belong to and whether we are setting them for an open detail row
			var $source_tr = $caller.closest('tr');
			if ($source_tr.hasClass('details_row')) {
				param_type = 'row_detail_';
				$row = $source_tr.prevAll().eq(0);
			} else {
				param_type = 'row_';
				$row = $source_tr;
			}

			if ($row[0]) {
				var row_num = self.options.$oTable.fnGetPosition($row[0]);
				var row_data = self.getRowWidgetData(row_num);
				var row_val = row_data[self.options.row_key];
				param_type += row_val;

				if (!self.options.uri_params) {
					self._initURIParams();
				}

				self.options.uri_params[param_type] = {};
				self.options.uri_params[param_type][caller_uri_id] = params;

				if (self.options.parent && self.options.parent._storeURIParams) {
					self.options.parent._storeURIParams($self, self.options.uri_params);
				}
			}
		},

		_afterDTDraw: function () {
			this.element.triggerHandler('redraw');
		},

		_IERowHack: function() {
			var self = this, $self = this.element;

			$self.find('tr').not($self.find('.widgetType tr')).each(function() {
				var $tr = $(this);
				if ($tr.is(':last-child')) {
					$tr.addClass('last-child');
				} else {
					$tr.removeClass('last-child');
				}
				if ($tr.is(':first-child')) {
					$tr.addClass('first-child');
				} else {
					$tr.removeClass('first-child');
				}
				if($tr.is(':nth-child(odd)')) {
					$tr.addClass('nth-child-odd');
				} else {
					$tr.removeClass('nth-child-odd');
				}
				if($tr.is(':nth-child(even)')) {
					$tr.addClass('nth-child-even');
				} else {
					$tr.removeClass('nth-child-even');
				}
			});

		},

		filter: function(str) {
			var self = this, $self = this.element;

			if (self.options.processing) {
				self.options.$oTable.fnProcessingIndicator(true);
			}
			if (!str.length) {
				self.options.$oTable.fnFilterClear();
			}

			var execute_filter = function() {
				var old_search, c, len, cols, col_name, f_idx, search, f, filter, filtered_query;
				if (self.options.processing) {
					self.options.$oTable.fnProcessingIndicator(true);
				}

				if (self.options.live) {
					old_search = '';
					if (self.options.search) {
						old_search = self.options.search;
					}

					self.options.search = {};
					self.options.search_flags = false;

					if (str) {
						if (self.options.filter_regexp_flags) {
							self.options.search_flags = self.options.filter_regexp_flags;
						}

						if (self.options._filter_mode === 'text') {
							cols = [].concat(self.options.columns || []).concat(self.options.filter_extra_columns || []);
							for (c = 0, len = cols.length; c < len; ++c) {
								col_name = cols[c];
								if (typeof col_name === 'object') {
									col_name = col_name.column;
								}
								self.options.search[col_name] = str;
							}

							if (self.options.search_in_list && !self.options.search_in_list.widget_param && self.options.search_in_list.search_param) {
								self.options.search = self.options.search || {};
								self.options.search[self.options.search_in_list.search_param] = '[\\[,]' + str + '[\\],]';
								// "^" + "\\[" + str + "\\]$|^\\[" + str + ",|," + str + ",|," + str + "\\]$";
							}

						} else {
							if (self.options.extension_filter_options.list_field) {
								str = '[\\[,]' + str + '[\\],]';
							}

							if (!$.isArray(self.options.extension_filter_options.la_filter_field)) {
								self.options.extension_filter_options.la_filter_field = [self.options.extension_filter_options.la_filter_field];
							}

							for (f_idx = 0; f_idx < self.options.extension_filter_options.la_filter_field.length; f_idx++) {
								self.options.search[self.options.extension_filter_options.la_filter_field[f_idx]] = str;
							}
						}

						if (self.options.search_in_list && !self.options.search_in_list.widget_param && self.options.search_in_list.search_param) {
							self.options.search = self.options.search || {};
							self.options.search[self.options.search_in_list.search_param] = "^" + "\\[" + str + "\\]$|^\\[" + str + ",|," + str + ",|," + str + "\\]$";
						}

					} else {
						self.options.search = {};
					}

					self.bootstrap();
				} else {
					if (!self.options.rest_params) {
						self.options.rest_params = {};
					}
					self.options.rest_params.page = 1;

					if (!self.options.post_process_filter) {
						search = str;
					} else {
						// This is the post_process_filter section -- this is where we add additional search_strings that have been filtered by a regex
						// An example of this would be if we want to search both "x326" and "326" if the user types in the first one.
						// The regex that is passed in for post_process_filter defines what characters will get removed from the search.
						search = [str];

						if ($.isArray(self.options.post_process_filter)) {
							for (f in self.options.post_process_filter) {
								filter = new RegExp(self.options.post_process_filter[f], "g");
								filtered_query = str.replace(filter, "");
								search.push(filtered_query);
							}
						} else {
							filtered_query = str.replace(new RegExp(self.options.post_process_filter, "g"), "");
							search.push(filtered_query);
						}
					}

					self.options.rest_params.search_string = search;
					self._pageFirst();
				}
			};

			if (self.options.search_pid) {
				self._clearTimeout(self.options.search_pid);
			}
			self.options.search_pid = self._setTimeout(execute_filter, 100);
		},

		_lengthChangeCallback: function(elem, e) {
			var self = this, $self = this.element, page_size;

			page_size = self.sanitize($(elem).val());
			self.options.page_size = page_size;
			if (!self.options.live) {
				self.setPageSize(page_size);
			}
			// TODO: Make this intelligent so we fix their offset and increase the number of rows seen or
			// decrease the number seen according to the new page size. If they asked for more than the remaining
			// number of rows from offset to the end of the data, then decrease offset until we either show everything
			// or show the number of rows they asked for. As they scroll left, re-window when they hit the beginning of
			// the table, etc....
			self._pageFirst();
		},

		_filterChangeCallback: function (elem, e) {
			var self = this, $self = this.element;
			self.filter(self.sanitize($(elem).val()));
			$self.triggerHandler("filterChange");
		},

		// Resets the additional row counter and removes the rows added to our page size
		_resetAdditionalRows: function() {
			var self = this;

			if (self.options._additional_rows) {
				self.setPageSize(self.getPageSize() - self.options._additional_rows);
				self.options._additional_rows = 0;
			}
		},

		_pageFirst: function() {
			var self = this, $self = this.element;

			self._resetAdditionalRows();

			if (self.options.processing) {
				self.options.$oTable.fnProcessingIndicator(true);
			}

			if (!self.options.live_table) {
				self._setupRefreshRest();

				self.options.rest_params.page = 1;
				delete self.options.data;
				self._doDataFill();
			}
		},

		_pagePrevious: function() {
			var self = this, $self = this.element;

			self._resetAdditionalRows();

			if (!self.options.live_table) {
				if (self.options.rest_params.page && self.options.rest_params.page > 0) {
					if (self.options.processing) {
						self.options.$oTable.fnProcessingIndicator(true);
					}
					self.options.rest_params.page--;
					delete self.options.data;
					self._doDataFill();
				}
			}
		},

		_pageNext: function() {
			var self = this, $self = this.element;

			self._resetAdditionalRows();

			if (!self.options.live_table) {
				if (self.options.page_count) {
					if (self.options.rest_params.page && self.options.rest_params.page < self.options.page_count) {
						if (self.options.processing) {
							self.options.$oTable.fnProcessingIndicator(true);
						}
						self.options.rest_params.page++;
						delete self.options.data;
						self._doDataFill();
					} else if (!self.options.rest_params.page) {
						if (self.options.processing) {
							self.options.$oTable.fnProcessingIndicator(true);
						}
						self.options.rest_params.page = 2;
						delete self.options.data;
						self._doDataFill();
					}
				}
			}
		},

		_pageLast: function() {
			var self = this, $self = this.element;

			self._resetAdditionalRows();

			if (!self.options.live_table) {
				if (self.options.page_count) {
					if (self.options.processing) {
						self.options.$oTable.fnProcessingIndicator(true);
					}
					self.options.rest_params.page = self.options.page_count;
					delete self.options.data;
					self._doDataFill();
				}
			}
		},

		_beforeLAOnChange: function(obj, la_args, index) {
			var self = this, $self = this.element;

			if (self.options.processing) {
				self.options.$oTable.fnProcessingIndicator(true);
			}
			var done_changing = function() {
				if (self.options.processing) {
					self.options.$oTable.fnProcessingIndicator(false);
				}
			};
			if (self.options.live_array_change_pid) {
				self._clearTimeout(self.options.live_array_change_pid);
			}
			self.options.live_array_change_pid = self._setTimeout(done_changing, 200);
		},

		_beforeLAInit: function(obj, la_args, index) {
			var self = this, $self = this.element;

			if (self.options.$oTable) {
				if (self.options.clear_table_timeout) {
					self._clearTimeout(self.options.clear_table_timeout);
				}
				self.options.clear_table_timeout = self._setTimeout(self._clearTable.bind(self), 300);
			}
			$self.trigger('beforeInit');
		},

		_beforeLAAdd: function(obj, la_args, index) {
			var self = this, $self = this.element;

			if (self.options.clear_table_timeout) {
				self._clearTimeout(self.options.clear_table_timeout);
				self.options.$oTable.fnClearTable(false);
			}
			self.options.clear_table_timeout = null;
			$self.trigger('beforeAdd');
		},

		_afterLAAdd: function(obj, la_args, index, row) {
			var self = this, $self = this.element;

			if (navigator.userAgent.indexOf('MSIE 8') !== -1) {
				self._IERowHack();
			}
		},

		_afterLADelete: function(obj, la_args, index, row) {
			var self = this, $self = this.element;

			if (navigator.userAgent.indexOf('MSIE 8') !== -1) {
				self._IERowHack();
			}
		},

		_beforeLABootstrap: function(obj, la_args) {
			var self = this, $self = this.element;

			if (self.options.clear_table_timeout) {
				// debugLog('Got a bootstrap after a clear, holding off on the clear.');
				self._clearTimeout(self.options.clear_table_timeout);
				self.options.$oTable.fnClearTable(false);
			}
			// debugLog('Disabling screen updates.');
			self.options.clear_table_timeout = null;
			self.options.inhibit_refresh = true;
			$self.trigger('bootstrapStarted');
		},

		_afterLABootstrap: function(obj, la_args) {
			var self = this, $self = this.element;

			// debugLog('Redrawing table.');
			if (self.options.$oTable) {
				self.options.$oTable.fnDraw();
			}
			self.options.inhibit_refresh = false;
			self._afterSetURIParams();
			if (navigator.userAgent.indexOf('MSIE 8') !== -1) {
				self._IERowHack();
			}
			$self.trigger('bootstrapFinished');
		},

		_afterLAClear: function(obj, la_args, index) {
			var self = this, $self = this.element;

			if (self.options.$oTable) {
				if (self.options.clear_table_timeout) {
					self._clearTimeout(self.options.clear_table_timeout);
				}
				self.options.clear_table_timeout = self._setTimeout(self._clearTable.bind(self), 300);
			}
		},

		// Update a row that is currently in edit mode, but has been updated by a live event
		_updateEditableRow: function($tr, row_data) {
			var $self = this.element, self = this, $tds, td_idx, $td, $fields, f_idx, f_val, dirty_tds = {}, aaData;

			$tds = self._editGetEditableTDs($tr);

			for (td_idx = 0; td_idx < $tds.length; td_idx++) {
				$td = $tds.eq(td_idx);
				$fields = $td.find('.widgetType.widgetValueWidget, :input[name]').filter('.is-dirty').eq(0);

				for (f_idx = 0; f_idx < $fields.length; f_idx++) {
					f_val = CUI.getWidgetElementValue( $fields.eq(f_idx) );
					$.extend(row_data, f_val);
					dirty_tds[td_idx] = true;
				}
			}

			// Update the row with a non-editable version of the data, but suppress search indexing
			aaData = self._buildRowFromObject(row_data);
			$tds.children().remove(); // Force widget clean up to take place

			// Make the new row editable
			$tr.find('td.editable').removeClass('editable');
			self._edit($tr);

			$tr.triggerHandler('submitRowData');
			$tr = null;
			$tds = null;
		},

		_LADataArrayToObject: function (data_array) {
			// Probably unused, and likely can be removed (in another branch)
			// TODO: BUG! BNPH-11739 -- This function uses undeclared "row" and "i" vars, and returns nothing. It's either broken, unused, or magical.
			var self = this, idx, i, row;
			// s.o.d.columns contains an array of column names

			for (idx = 0; idx < data_array.length; idx++) {
				row[self.options.data.columns[idx]] = data_array[i];
			}
		},

		_buildRowFromEvent: function(data) {
			// Probably unused, and likely can be removed (in another branch)
			var self = this;
			self._buildRowFromObject( self._LADataArrayToObject(data) );
		},

		_buildRowFromObject: function (row) {
			var self = this, $self = this.element, row_array = [], col_idx, cols_len, aaData;
			cols_len = self.options.columns.length;

			// ++col_idx is used because it's faster, it does not differ in effect from col_idx++
			for (col_idx = 0; col_idx < cols_len; ++col_idx) {
				row_array.push(row[ self.options.columns[col_idx].column ]);
			}

			// aaData is the data, formatted for DT.net
			aaData = [ row_array ];
			aaData = self._insertWidgets(aaData, row, 'view');
			aaData = self._insertRowActions(aaData, undefined, row);
			return aaData;
		},

		fillData: function(d, from_self) {
			var $self = this.element;
			var self = this;
			var idfp = self.options._get_parent_data, idfp_key, idfp_idx, idfp_k_idx, data_key, widget_param, self_data, $oTable, c, len, col_def, sort_data_type, tdclass, r, rlen, row_widget_data, $tr, wbposition;

			// Scan for "include_data_from_parent" directives, only the first time
			if (idfp === undefined) {
				idfp = [];
				for (idfp_key in self.options) {
					if ((typeof self.options[idfp_key] === 'object') && 'include_data_from_parent' in self.options[idfp_key]) {
						idfp.push(idfp_key);
					}
				}
				self.options._get_parent_data = idfp;
			}

			// Scan the keys for relevant data...
			if (!from_self && idfp.length) {
				self.options._selected_parent_data = self.options._selected_parent_data || {};

				for (idfp_k_idx = 0; idfp_k_idx < idfp.length; idfp_k_idx++) {
					idfp_key = idfp[idfp_k_idx];
					if (!$.isArray(self.options[idfp_key].include_data_from_parent)) { continue; } // Ignore strange things.

					self.options._selected_parent_data[idfp_key] = self.options._selected_parent_data[idfp_key] || {};

					// And add it to selected_parent_data
					for (idfp_idx = 0; idfp_idx < self.options[idfp_key].include_data_from_parent.length; idfp_idx++) {
						data_key = self.options[idfp_key].include_data_from_parent[idfp_idx];
						if (data_key in d) {
							self.options._selected_parent_data[idfp_key][data_key] = d[data_key];
						}
					}
				}
			}

			//debugLog("In fillData", d, from_self, self.options);
			if (!from_self && !self.options.accept_data_from_parent && !self.accept_initial_data_from_parent) {
				// Store the parent's data for 'field' based data filtering using values from the parent
				self.options.parent_data = d;

				// search_parent_field allows you to specify a field from the parent's passed data to use for
				// searching when this widget pulls its data
				//debugLog("Parent data: ", d);

				if (self.options.search_parent_field && d[self.options.search_parent_field]) {
					self.options.search = self.options.search || {};
					self.options.search[self.options.search_parent_field] = '^' + d[self.options.search_parent_field] + '$';
				}

				// Search in list allows you to search for something inside a list stored in a single column, i.e. "[1,2,3]"
				// It takes an object: { widget_param, search_param }
				// widget_param is the key that this widget is receiving as data
				// search_param is the key in the actual database table you are searching on
				// Ex: { widget_param: 'bbx_queue_id', search_param: 'queue_memberships' }
				// Widget receives bbx_queue_id from fillData, but needs to search on queue_memberships in the live_fifo_agents table

				if (self.options.search_in_list && self.options.search_in_list.widget_param && d[self.options.search_in_list.widget_param]) {
					self.options.search = self.options.search || {};

					widget_param = d[self.options.search_in_list.widget_param];
					self.options.search[self.options.search_in_list.search_param] = "^" + "\\[" + widget_param + "\\]$|^\\[" + widget_param + ",|," + widget_param + ",|," + widget_param + "\\]$";
				}

				if (self.options.search_user_id) {
					self.options.search = self.options.search || {};
					self.options.search[self.options.search_user_id] = '^' + validUserID + '$';
				}

				if (self.options.search_user_name) {
					self.options.search = self.options.search || {};
					self.options.search[self.options.search_user_name] = '^' + validUsername + '$';
				}

				self.options.have_parent_data = true;
				$self.trigger('fillDataParent');

				if (!self.options.accept_data_from_parent && !self.options.accept_initial_data_from_parent && self.options.rest) {
					return;
				}
			}

			if (from_self && self.options.search_parent_field && !self.options.search) {
				// A search field was set, but we have not yet gotten the parent's data, or it doesn't
				// have the requested field. In this case, we cannot search for the right rows, so we
				// don't have any data to display.

				self.options._stored_self_data = d;
				return;
			}

			// We need to set self.options.data here so we have it for adding / editing rows
			if ( (from_self || self.options.accept_data_from_parent || self.options.accept_initial_data_from_parent) && !self.addingRows() && !self.options.performing_click) {
				if (typeof d == 'object' && !$.isEmptyObject(d)) {
					// restore buttons
					if ( (self.options.$header || self.options.$footer) && self.options.ready) {
						self._restoreRowManipulation();
					}

					// clear data regarding open rows
					self.options.adding_rows = {};
					self.options.editing_rows = {};
					self.options.performing_click = false;

					self.clearRowCount();
					self.clearInitialData();
					self.clearOriginalData();
					self.setInitialData(d);
					self.setOriginalData(d);

					// Clear the "Select All" checkbox if it is checked, since all rows are being re-written
					$self.find('> thead .select-all-checkbox:checkbox').removeAttr('checked');

					// Remove the "initial" flag, since it's no longer "initial" any more
					self.options.accept_initial_data_from_parent = false;
				}
			} else {
				if (self.options._stored_self_data) {
					self_data = self.options._stored_self_data;
					delete self.options._stored_self_data;
					self.fillData(self.options._stored_self_data, true);
				}

				return; // Nothing to see here... this data fill does not matter to this table.
			}

			// NOTHING PAST THIS POINT WILL HAPPEN IF THE FILLDATA DOES NOT APPLY TO THIS TABLE!
			// (i.e., if the fillData is not from_self, and the table is not currently taking outside data)

			self.options.aaData = [];
			self.options.aoColumns = [];
			$oTable = false;
			if (self.options.$oTable) {
				$oTable = self.options.$oTable;
			}

			self._buildAAData();

			self.options.col_offset = 0;

			if (!self.options.aoColumns.length) {
				if (self.options.sortable) {
					self.options.aoColumns.push( {
						'sTitle' : '&nbsp;',
						'sClass' : 'drag-handles' + (self.options._disabled ? ' drag-handles-disabled' : ''),
						'bSortable' : false
					});

					self.options.col_offset = 1; // Update this if more columns are added or removed!
					self.options.js_sort_by = [ [ self.options.sortable.field, "asc" ] ];
				}

				if (!('_allow_select' in self.options)) {
					self.options._allow_select = checkPermissions((self.options.table_actions || {}).select_requires, true);
				}

				if (self.options.table_actions && self.options._allow_select && (self.options.table_actions.select_row || self.options.table_actions.select_all_rows || self.options.return_selected)) {
					if ((self.options.table_actions.select_all_rows || self.options.return_selected) && !self.options.select_single) {
						self.options.aoColumns.push( {
							'sTitle' : '<input type="checkbox" class="select-all-checkbox" />',
							'bSortable' : false,
							'sClass' : 'select_action checkbox-column'
						} );
					} else {
						self.options.aoColumns.push(
							{
								sTitle : '<div>&nbsp;</div>',
								sClass: 'checkbox-column select-checkbox-column',
								bSortable: false
							}
						);
					}
					self.options.col_offset++;
				}

				// Also build a list of headers for the rows
				for (c = 0, len = self.getColumnCount(); c < len; ++c) {
					col_def = self.options.columns[c];
					sort_data_type = '';
					if (self.options.sortable && self.options.sortable.field) {
						if (col_def.column == self.options.sortable.field) {
							col_def.data_type = 'numeric';
							col_def.sort_data_type = 'numeric-asc';
							col_def.sorting = [ 'asc' ];
						}
					}
					if (col_def.edit_field) {
						sort_data_type = 'dom-text';
						if (col_def.edit_element && col_def.edit_element.entity == 'select') {
							sort_data_type = 'dom-select';
						} else if (col_def.edit_element && col_def.edit_element.entity == 'input' && col_def.edit_element.attributes && col_def.edit_element.attributes.type == 'checkbox') {
							sort_data_type = 'dom-checkbox';
						}
					} else {
						sort_data_type = (col_def.sort_data_type ? col_def.sort_data_type : 'text');
					}
					tdclass = col_def['class'] ? col_def['class'] : '';
					if (col_def.submit_on_change) {
						tdclass += ' submit_on_change';
					}
					self.options.aoColumns.push( {
						'sTitle' : col_def.header,
						'sClass' : tdclass,
						'aaSorting' : (col_def.sorting ? col_def.sorting : [ 'asc', 'desc' ]),
						'sSortDataType' : sort_data_type,
						'sType' : (col_def.data_type ? col_def.data_type : 'html'),
						'bSortable' :  (self.options.sortable ? false : 'sortable' in col_def ? trueish(col_def.sortable) : true),
						'bVisible' : ('visible' in col_def ? trueish(col_def.visible) : true),
						'sWidth' : (col_def.width ? col_def.width : null)
					} );
				}

				if (self.hasActionColumn()) {
					self.options.aoColumns.push({
						'sTitle' : 'Actions',
						'sClass' : 'actions',
						'bSortable' : false,
						'bVisible' : true,
						'sSortDataType': 'dom-text',
						'sWidth' : self.options.action_width + 'px'
					});
				}

			}

			self.options.data_table_options = {
				'aaData': self.options.aaData,
				'aoColumns': self.options.aoColumns,
				'bJQueryUI': true,
				'bScrollInfinite' : self.options.infinite_scroll ? self.options.infinite_scroll : false,
				'iDisplayLength' : (self.getPageSize() || 10),
				'bPaginate': ('paginate' in self.options ? trueish(self.options.paginate) : true),
				'bLengthChange': ('length_change' in self.options ? trueish(self.options.length_change) : true),
				'bFilter': ('filtering' in self.options ? trueish(self.options.filtering) : true),
				'bSort': ('sorting' in self.options ? trueish(self.options.sorting) : true),
				'bInfo': ('info' in self.options ? trueish(self.options.info) : false),
				'bAutoWidth': ('auto_width' in self.options ? trueish(self.options.auto_width) : false),
				'sPaginationType': (self.options.pagination_type ? self.options.pageination_type : "two_button"),
				'aLengthMenu': self.options.length_menu || [[10, 25, 50, 100], [10, 25, 50, 100]],
				'fnDrawCallback': self._afterDTDraw.bind(self),
				'bProcessing': ('processing' in self.options ? trueish(self.options.processing) : true),
				'sDom': '<"H"l' + (self.options.builtin_filter ? 'f' : '') + 'r>t<"F"ip>'
			};

			if (self.options.rest) {
				$.extend(self.options.data_table_options, { 'aaSorting' : [] });
			}

			if (self.options.oLanguage) {
				$.extend(self.options.data_table_options, { 'oLanguage': self.options.oLanguage });
			}

			// Make the widget into a dataTable
			if (!self.options.$oTable) {
				$oTable = $self.dataTable(self.options.data_table_options);

				self.options.$oTable = $oTable;
				self.options.$wrapper = $oTable.closest('div');
				self.options.$header = self.options.$wrapper.find('> div:first');
				self.options.$footer = self.options.$wrapper.find('> div:last');

				if (self.options.extension_filter && self.options.filtering) {
					self.options.$header.append(self._buildExtensionFilter());
				} else if (!self.options.builtin_filter && self.options.filtering) {
					self.options.$header.append('<div class="data-table-widget-custom-filter">Filter: <input type="text" class="search" placeholder="Filter Results" /></div>');
				}

				self._insertGlobalActions();
				self._bindActions();

				$self.trigger('dataTableInit');
			} else {
				$oTable.fnClearTable();
				$oTable.fnAddData(self.options.aaData);
				if (self.options.paginate) {
					$oTable.fnLengthChange($oTable, self.options.page_size);
				}
			}

			$oTable.find('th.actions, td.actions').css('width', self.options.action_width + 'px');

			widgetize_children($oTable, self);
			for (r = 0, rlen = self.getRowCount(); r < rlen; ++r) {
				row_widget_data = self.getRowWidgetData(r);
				$tr = $($oTable.fnGetNodes(r));
				self.fillDataChildren('widget-data', false, $tr, row_widget_data);
				$tr = null;
			}

			// For certain things, like checkboxes, selects, and value widgets, we need to do a setValue on them
			self._doSetValue($oTable);

			if (!self.options.disable_cui_wrapper) {
				if (!$oTable.closest('div.cui-table-datatables-wrapper')[0]) {
					$oTable.closest('div').wrap('<div class="cui-table-datatables-wrapper" />');
					self._IDTag($self.closest('.cui-table-datatables-wrapper'));
				}

				// The dataTable may be hidden on start, or before we get this call, so check. _data_table_hidden is set in self.hide/show
				if (self.options._data_table_hidden) {
					$oTable.closest('.cui-table-datatables-wrapper').hide();
				}
			}

			if (!self.options.bound) {
				self.options.bound = true;
				self.options.$tbody = $self.find('tbody');
				self._delegate(self.options.$tbody, 'button.add-save', 'click', CUI.FunctionFactory.build(self._clickAddSaveButton, self, { context: 'argument', first: 'context' }));
				self._delegate(self.options.$tbody, 'button.edit-save', 'click', CUI.FunctionFactory.build(self._clickEditSaveButton, self, { context: 'argument', first: 'context' }));
				self._delegate(self.options.$tbody, 'button.add-cancel', 'click', CUI.FunctionFactory.build(self._clickAddCancelButton, self, { context: 'argument', first: 'context' }));
				self._delegate(self.options.$tbody, 'button.edit-cancel', 'click', CUI.FunctionFactory.build(self._clickEditCancelButton, self, { context: 'argument', first: 'context' }));

				self._delegate(self.options.$tbody, 'a.move-up, a.move-down', 'click', self._clickMoveUpDownCallback.bind(self));

				if (self.options.wallboarding && !self.options.hide_wbbut) {
					wbposition = (self.options.wallboarding.position && self.options.wallboarding.position == 'left' ? 'left' : 'right');
					self.options.$wrapper.find('.fg-toolbar').eq(0).append(
						'<div class="dataTables_wallboard_button"><button type="button" class="wallboard_button">Wallboard View</button></div>'
					).last().find('button').css({ 'float': wbposition });
					self._delegate(self.options.$wrapper, '.dataTables_wallboard_button button', 'click', CUI.FunctionFactory.build(self._clickWallboardButton, self, { context: 'argument', first: 'context' }));
				}
			}
			if (!self.options.rows_clickable) {
				self.options.rows_clickable = true;
				self._makeRowsClickable(self.options.aaData);
			}
			if (self.options.sortable) {
				self.options.$tbody.sortable({
					distance: '15',

					items: 'tr:not(.no-sort)',

					start: function(event, ui) {
						self.options.$drag_tr = $(ui.item);
						self.options.drag_start_pos = ui.item.prevAll().length;
						$(this).addClass('in_transit');
						if (self.options.$drag_tr.hasClass('state-selected')) {
							//			    debugLog("Dragging selected TR");
							//			    debugLog(self.options.$tbody.children());
							self.options.was_selected = true;
							// The sortable system makes a clone and inserts it before the real element, so we need to
							// find the original element and close any open rows before dragging
							self._closeSelected(self.options.$tbody.find('tr.state-selected').eq(1));
						}

						if (self.options._$move_handle) {
							self.options._$move_handle.bubble('destroy');
							delete self.options._$move_handle;
						}
					},
					stop: function(event, ui) {
						if (self.options.was_selected) {
							self.options.was_selected = false;
							self._openSelected(self.options.$drag_tr);
						}
					},
					handle: '.drag-handles:not(.drag-handles-disabled)',
					update: self._dragDropCallback.bind(self)
				});

				self._undelegate(self.options.$tbody, 'tr:not(.no-sort) td.drag-handles', 'click.dtwDragHandles');
				self._delegate(self.options.$tbody, 'tr:not(.no-sort) td.drag-handles', 'click.dtwDragHandles', function () {
					var $this = $(this);

					if (self.options._disabled) { return; }
					if (self.options.$tbody.hasClass('ui-sortable-disabled')) { return; }

					if (self.options.$tbody.hasClass('in_transit')) {
						// Ignore drag clicks
						self.options.$tbody.removeClass('in_transit');
						return;
					}

					if (self.options._$move_handle) {
						self.options._$move_handle.bubble('destroy');
						self.options._$move_handle.closest('tr').removeClass('activeMoveButtons');
					}

					self.options._$move_bubble = $('<div class="dataTableWidgetMoveBubbleContent"><a href="#" class="move-up"><img src="/images/moveup.png" width="15" height="15"></a><a href="#" class="move-down"><img src="/images/movedown.png" width="15" height="15"></a></div>');

					if (!self.options._$move_handle || (self.options._$move_handle && !self.options._$move_handle.is($this))) {
						self.options._$move_handle = $(this).bubble({
							html: self.options._$move_bubble,
							z_index: 1000,
							append_to_target: true,
							tip_position: 'left top',
							target_anchor: 'top right',
							tip_offset: 20,
							tip_width: 15,
							target_anchor_offset: [-7, 7],
							tip_point_offset: [10, 0],
							'class': 'dataTableWidget-move-button-bubble'
						});

						self.options._$move_handle.closest('tr').addClass('activeMoveButtons');

					} else if (self.options._$move_handle) {
						delete self.options._$move_handle;
					}

				});
			}
			if (!self.options.rest || self.options.live_table) {
				self.sort();
			}

			for (r = 0; r < self.getRowCount(); ++r) {
				if (self.isBeingEdited(r)) {
					$tr = $(self.options.$oTable.fnGetNodes(r));
					self.options.performing_click = false;
					self._clickRow($tr, undefined);
				}
			}

			$self.closest('.dataTables_wrapper').find('.dataTables_length select').val(self.options.page_size);
			self.options.$oTable.fnProcessingIndicator(false);
			$self.trigger('dataTableFinish');
			$self.trigger('change');
		},

		_getCellAndValue: function($field) {
			var self = this, $self = this.element;

			var $td = $field.closest('td');
			var $tr = $td.closest('tr');
			var row_num = self.options.$oTable.fnGetPosition($tr[0]);
			var td_pos = self.options.$oTable.fnGetPosition($td[0])[2];

			if (self.options.col_offset) {
				td_pos -= self.options.col_offset;
			}

			var td_val = self.getCellData(row_num, td_pos);

			return { cell: $td, value: td_val };
		},

		_doSetValue: function($obj) {
			var $self = this.element;
			var self = this;

			if ($obj.is('tr.details_row')) {
				return;
			}

			var restriction = '';
			if ($obj.is('tr')) {
				restriction = '>';
			} else if ($obj.is($self)) {
				restriction = '>tr >';
			}

			var $objs = $obj
			.find([restriction, 'td .widgetValueWidget, ', restriction, 'td :input'].join(''))
			.not([restriction, 'td.select_action .widgetValueWidget, ', restriction, 'td.select_action :input'].join(''))
			.not('tr.details_row > td .widgetValueWidget, tr.details_row > td :input');

			var td;
			for (var i=0; i<$objs.length; i++) {
				td = self._getCellAndValue($objs.eq(i));
				self._setTdValue(td.cell, td.value);
			}
		},

		// This is called by formWidget when doing a save/cancel on the page
		cancelEditMode: function() {
			var self = this, $self = this.element;

			// We only need to go through this rigamarole if the table is named and will be returning a value. Otherwise, it is a self-submitting table.
			if (CUI.getElementName($self) !== undefined) {
				// clear data regarding open rows
				self.options.adding_rows = {};
				self.options.editing_rows = {};
				self.options.performing_click = false;

				self._editDeselectLast();
				self._restoreRowManipulation();
			}
		},

		_setupRefreshRest: function() {
			var $self = this.element;
			var self = this;

			if (self.options.accept_data_from_parent && !self.options.rest && !self.options.live_table && !self.options._after_refresh_mode) {
				if (self.options.refresh_rest) {

					self.options._after_refresh_mode = true; // No sense in doing this every time...

					self.options.rest = self.options.refresh_rest;
					self.options.rest_params = self.options.refresh_rest_params || self.options.rest_params;
					if ('refresh_data_source' in self.options) { self.options.data_source = self.options.refresh_data_source; }
					if ('refresh_rest_container' in self.options) { self.options.rest_container = self.options.refresh_rest_container; }
				} else {
					debugLog('jquery.dataTable.widget: Refresh attempted on a non-live table, but the original data was from a parent, and no refresh_rest option was found. Cannot refresh. -- ', $self);
				}
			}
		},

		refresh: function(e) {
			var $self = this.element;
			var self = this;

			// This can be called directly as an event handler, and if that's the case, the event needs stop-prop on it.
			if (e && e.stopPropagation) { e.stopPropagation(); }

			self._setupRefreshRest();

			if (!self.options.rest) {
				return;
			}

			delete self.options.data;

			if (self.options._$move_handle) {
				self.options._$move_handle.bubble('destroy');
				delete self.options._$move_handle;
			}

			// debugLog('Refreshing data: ', self.options);
			self._doDataFill();

			$self.find('> thead > th .select-all-checkbox').removeAttr('checked');
		},

		// This is called as part of _doDataFill (in widget base) -- we need to wedge our RestDataHook in here...
		_dataFill: function () {
			var self = this, $self = this.element, extra_rest_params, new_rest_path, param;

			// Check to see if our rest path has changed

			new_rest_path = self._callDataHook('RestPathChange', self.options.rest);

			if (new_rest_path) {
				self.options.rest = new_rest_path;
				self._setupDataSource();
			}

			// Check to see if we have new rest params

			extra_rest_params = self._callDataHook('RestRequest', self.options.rest_params);

			if (extra_rest_params) {
				// Use CUI.combineObjects because we need to preserve "undefined" values (thanks, jQuery!)
				self.options.rest_params = CUI.combineObjects(self.options.rest_params || {}, extra_rest_params || {});

				// Remove any params that have undefined as the value; we want to remove these from the rest params
				for(param in extra_rest_params) {
					if(extra_rest_params[param] === undefined) {
						delete self.options.rest_params[param];
					}
				}

			}

			// We now return you to your regularly scheduled programming...
			$.ui.widget.prototype._dataFill.apply(self, arguments);
		},

		_clearTable: function () {
			var self = this, $self = this.element;
			self.options.$oTable.fnClearTable();
			delete self.options.clear_table_timeout;
			$self.trigger('clear');
		},

		sort: function() {
			var self = this;

			if (self.options.sort_timeout) {
				self._clearTimeout(self.options.sort_timeout);
			}

			// We have to trigger resize every time sort is called or elements are out of place for 100ms or longer when they first show up
			this.element.triggerHandler('sortRows');
			self.options.sort_timeout = self._setTimeout(self._sort.bind(self), 100);
		},

		// Sorting actually happens here-- this is debounced in "sort()" above
		_sort: function() {
			var self = this, $self = this.element, $trs, tr_idx;

			if (!self.options.js_sort_by) {
				return;
			}

			for (var cols = 0, num_cols = self.options.js_sort_by.length; cols < num_cols; cols++) {
				if (isNaN(parseInt(self.options.js_sort_by[cols][0]))) {
					var col_num = self.getColumnNum(self.options.js_sort_by[cols][0]);
					if (self.options.col_offset) {
						col_num += self.options.col_offset;
					}
					self.options.js_sort_by[cols][0] = col_num;
				}
			}
			self.options.$oTable.fnSort(self.options.js_sort_by);

			$trs = $self.find('> tbody > tr');

			for (tr_idx = 0; tr_idx < $trs.length; tr_idx++) {
				var $tr = $trs.eq(tr_idx);
				self._markRowAbilities($tr, tr_idx + 1);
				$tr.triggerHandler('rowReady');
				$tr.triggerHandler('rowSortReady');
			}

		},

		_markRowAbilities: function ($tr) {
			var self = this, $self = this.element;
			if (!self.options.sortable && !self.options.click_action) { return; } // Don't bother

			var row_num, row_data;
			row_num = self.options.$oTable.fnGetPosition($tr[0]);

			// If row_num is null/false/undefined, then this is probably a message row in an empty table
			if (!row_num && row_num !== 0) { return; }

			row_data = self.getRowData(row_num);

			if (self.options.sortable) {
				$tr.toggleClass('no-sort', !self._isRowSortable(row_num, row_data));
			}

			if (self.options.click_action) {
				$tr.addClass('clickable option-selectable');
				$tr.toggleClass('no-click', !self._isRowClickable(row_num, row_data));
			}
		},

		_addSearchRow: function() {
			var self = this, $self = this.element, $dt_search, c, col_def, $editable, td_num;
			// This function is unused and can be removed (in another branch)
			$dt_search = $('<div class="dataTables_search" />');
			$dt_search.append('<span class="search">Search: </span>');
			for (c = 0; c < self.options.columns.length; c++) {
				col_def = self.options.columns[c];
				if (col_def.searchable) {
					$editable = self._getFieldHTML(td_num, '', 'edit');
					self._bind($editable, 'keyup change click formElementChange', CUI.FunctionFactory.build(self._editChangeCallback, self, { context: 'argument', first: 'context' }));
					$editable = $('<div class="field" />').append($editable);
					$editable.prepend(['<span class="label">',col_def.header,'</span>'].join(''));
					$dt_search.append($editable);
				}
			}
			$dt_search.insertAfter(self.options.$header);
			widgetize_children($self, self);
			self.fillDataChildren('widget-data', false, $(this));
		},

		_makeRowsClickable: function() {
			var $self = this.element;
			var self = this;

			self.options.$rows = $self.find('tr');

			if (self.options.click_action) {
				self.options.$rows.addClass('clickable option-selectable');
				self._delegate($self, 'tr:not(.no-click)', 'click', function (e,d) {
					var $target = $(e.target);

					// Ignore if this is from a sub-table
					if (!$self.find('>tbody>tr').index(this) && e.target === this) {
						return;
					}

					// Ignore if this is a click on a toolbar/dtw chrome of a sub-table, or any child element of such
					if ($(e.target).closest('.ui-toolbar')[0] && $(e.target).closest('.state-selected')[0]) {
						return;
					}

					// Ignore if the click is on a link or input
					if ($target.closest('a,:input,label').closest('tr').is(this)) {
						return;
					}

					// Ignore if the click is on an interactive widget (add as necessary)
					if ($target.closest('.soundPlayerWidget')[0]) {
						return;
					}

					// Ignore if the click was on a "checkbox" column
					var $has_select_action = $(e.target).closest('td.select_action, td.drag-handles');
					if ($has_select_action[0] && $has_select_action[0] === $(e.target).closest('td')[0]) { return; }

					// Otherwise, perform the click
					self._clickRow(this, e, d);
				});
			}

			self._delegate($self, 'input.selected-checkbox', 'click', CUI.FunctionFactory.build(self._clickSelectedCheckbox, self, { context: 'argument', first: 'context' }));
			self._delegate($self, 'input.select-all-checkbox', 'click', CUI.FunctionFactory.build(self._clickSelectAllCheckbox, self, { context: 'argument', first: 'context' }));
		},

		hasActionColumn: function() {
			var $self = this.element;
			var self = this;

			if (self.options.row_action_type == 'inline') {
				if (self.options.click_action == 'edit' || (self.options.row_actions && self.options.row_actions.delete_row)) {
					return true;
				}
			}
			return false;
		},

		_insertRowActions: function(aaDataRow, mode, row_data) {
			var $self = this.element, self = this, actions = [];

			if (self.options.row_action_type == 'inline') {
				if (self.options.click_action == 'edit' || (self.options.table_actions && self.options.table_actions.add_row)) {
					if (!self.options.action_width_set) {
						self.options.action_width += 75;
					}

					if (self.options.click_action == 'edit') {
						actions.push(['<button type="button" class="edit-save">',
									  (self.options.row_actions && self.options.row_actions.edit_save && self.options.row_actions.edit_save.text ?
									   self.options.row_actions.edit_save.text : 'Save'), '</button>'].join(''));
						if (self.options.row_actions && self.options.row_actions.edit_cancel) {
							actions.push(['<button type="button" class="edit-cancel">',
										  (self.options.row_actions.edit_cancel.text || 'Cancel'),'</button>'].join(''));
							if (!self.options.action_width_set) {
								self.options.action_width += 75;
							}
						}
					} else {
						actions.push(['<button type="button" class="add-save" style="display: none;">',
									  (self.options.row_actions && self.options.row_actions.add_save && self.options.row_actions.add_save.text ?
									   self.options.row_actions.add_save.text : 'Add'), '</button>'].join(''));
						if (self.options.row_actions && self.options.row_actions.add_cancel) {
							actions.push(['<button type="button" class="add-cancel">',
										  (self.options.row_actions.add_cancel.text || 'Cancel'),'</button>'].join(''));
							if (!self.options.action_width_set) {
								self.options.action_width += 75;
							}
						}
					}
				}
			}

			if (!('_allow_select' in self.options)) {
				self.options._allow_select = checkPermissions((self.options.table_actions || {}).select_requires, true);
			}

			if (self.options._allow_select && ((self.options.table_actions && self.options.table_actions.select_row) || self.options.return_selected)) {
				if (self._isRowSelectable(row_data) && !self.options._disabled) {
					aaDataRow.unshift('<input type="checkbox" class="selected-checkbox" />');
				} else {
					aaDataRow.unshift('<input type="checkbox" class="non-selectable-checkbox" disabled="disabled" />');
				}
			}

			if (self.options.sortable) {
				aaDataRow.unshift('&nbsp;');
			}
			if (self.options.row_action_type == 'inline' && self.options.row_actions && self.options.row_actions.delete_row) {
				if (!self.options.action_width_set) {
					self.options.action_width += 75;
				}
				actions.push('<button type="button" class="delete-row">',(self.options.row_actions.delete_row.text || 'Delete'),'</button>');
			}

			actions = actions.join('');
			if (actions.length) {
				aaDataRow.push(actions);
			}
			self.options.action_width_set = true;

			return aaDataRow;
		},

		_insertWidgets: function(aaDataRow, widget_data, mode) {
			var self = this;

			if (self.options.force_basic_table) {
				return aaDataRow;
			}

			// This method should always be called before row actions are inserted
			for (var c = 0, collen = aaDataRow.length; c < collen; ++c) {
				var $field = self._getFieldHTML(c, aaDataRow[c], mode, widget_data);
				if ($field instanceof jQuery) {
					aaDataRow[c] = $field.html();
				}
			}

			return aaDataRow;
		},

		_buildAADataRow: function(row_num, mode, row_data) {
			var self = this, $self = this.element, aaDataRow = [], c, clen, key, row_widget_data, extra_row_data;

			if (!row_data) {
				row_data = self.extra_data_to_row_actions ? self.getAllRowData(row_num) : self.getRowData(row_num);
			}

			for (c = 0, clen = self.getColumnCount(); c < clen; ++c) {
				key = self.getColumnName(c);
				aaDataRow.push(row_data[key]);
			}

			row_widget_data = self.getRowWidgetData(row_num);
			if (mode === 'add') {
				$.extend(row_widget_data, (self.options._selected_parent_data || {}).add_edit_action || {});
			}

			aaDataRow = self._insertWidgets(aaDataRow, row_widget_data, mode);
			aaDataRow = self._insertRowActions(aaDataRow, mode, $.extend({}, extra_row_data || {}, row_data));

			return aaDataRow;
		},

		_buildAAData: function() {
			var self = this, $self = this.element, aaData = [], r, rows, aaDataRow;
			for (r = 0, rows = self.getRowCount(); r < rows; ++r) {
				aaDataRow = self._buildAADataRow(r, 'view');
				aaData.push(aaDataRow);
			}
			//	    debugLog(aaData);
			self.options.aaData = aaData;
		},

		_insertGlobalActions: function() {
			var $self = this.element;
			var self = this;

			var $oTable = self.options.$oTable;
			var $header = self.options.$header;
			var $footer = self.options.$footer;

			if (!self.options.$wrapper) {
				self.options.$wrapper = $self.closest('.dataTables_wrapper');
			}
			self._delegate(self.options.$wrapper, '.dataTables_length select', 'change', CUI.FunctionFactory.build(self._lengthChangeCallback, self, { context: 'argument', first: 'context' }));
			if (!self.options.global_bindings) {
				self._bind($oTable, 'firstPage', self._pageFirst.bind(self));
				self._bind($oTable, 'previousPage', self._pagePrevious.bind(self));
				self._bind($oTable, 'nextPage', self._pageNext.bind(self));
				self._bind($oTable, 'lastPage', self._pageLast.bind(self));
				if (!self.options.$tbody) {
					self.options.$tbody = $oTable.find('tbody');
				}
				self._delegate(self.options.$tbody, '.submit_on_change input, .submit_on_change select, .submit_on_change textarea, .submit_on_change wigetType', 'change', CUI.FunctionFactory.build(self._submitOnChange, self, { context: 'argument', first: 'context' }));
				self.options.global_bindings = true;
			}

			if (!self.options.table_actions) {
				return;
			}

			var actions_top = '';
			var actions_bottom = '';
			if (self.options.table_actions.refresh) {
				var refresh_html = ['<button type="button" class="refresh">',(self.options.table_actions.refresh.text || 'Refresh'),'</button>'].join('');
				if (!self.options.table_actions.refresh.location) {
					self.options.table_actions.refresh.location = 'both';
				}
				if (self.options.table_actions.refresh.location == 'bottom' || self.options.table_actions.refresh.location == 'both') {
					actions_bottom += refresh_html;
				}
				if (self.options.table_actions.refresh.location == 'top' || self.options.table_actions.refresh.location == 'both') {
					actions_top += refresh_html;
				}
			}
			if (self.options.table_actions.add_row) {
				var add_row_html = ['<button type="button" class="add-row ' + (self.options.table_actions.add_row.button_class || '') + '">',(self.options.table_actions.add_row.text || 'Add Row'),'</button>'].join('');
				if (!self.options.table_actions.add_row.location) {
					self.options.table_actions.add_row.location = 'both';
				}
				if (self.options.table_actions.add_row.location == 'bottom' || self.options.table_actions.add_row.location == 'both') {
					actions_bottom += add_row_html;
				}
				if (self.options.table_actions.add_row.location == 'top' || self.options.table_actions.add_row.location == 'both') {
					actions_top += add_row_html;
				}
			}
			if (self.options.table_actions.delete_rows) {
				var delete_rows_html = ['<button type="button" class="delete-selected" disabled="disabled">',(self.options.table_actions.delete_rows.text || 'Delete Selected'),'</button>'].join('');
				if (!self.options.table_actions.delete_rows.location) {
					self.options.table_actions.delete_rows.location = 'both';
				}
				if (self.options.table_actions.delete_rows.location == 'bottom' || self.options.table_actions.delete_rows.location == 'both') {
					actions_bottom += delete_rows_html;
				}
				if (self.options.table_actions.delete_rows.location == 'top' || self.options.table_actions.delete_rows.location == 'both') {
					actions_top += delete_rows_html;
				}
			}
			if (self.options.table_actions.action_elements) {
				var action_elements = self._getFieldHTML(-1, '', 'table_actions').html();
				var location_first = (self.options.table_actions.action_elements.position === 'first');
				if (!self.options.table_actions.action_elements.location) {
					self.options.table_actions.action_elements.location = 'both';
				}
				if (self.options.table_actions.action_elements.location == 'top' || self.options.table_actions.action_elements.location == 'both') {
					actions_top = (location_first ? action_elements : '') + actions_top + (location_first ? '' : action_elements);
				}
				if (self.options.table_actions.action_elements.location == 'bottom' || self.options.table_actions.action_elements.location == 'both') {
					actions_bottom = (location_first ? action_elements : '') + actions_bottom + (location_first ? '' : action_elements);
				}
			}
			if (actions_top.length > 1) {
				actions_top = ['<div class="clear"></div><div class="dataTables_actions">',actions_top,'</div><div class="clear"></div>'].join('');
				$header.prepend(actions_top);
				widgetize_children($header, self);
				$header.find('.dataTables_actions').children().not('.add-row, .delete-selected').addClass('table-action');
				self.fillDataChildren('widget-data', false, $header);
			}
			if (actions_bottom.length > 1) {
				actions_bottom = ['<div class="clear"></div><div class="dataTables_actions">',actions_bottom,'</div><div class="clear"></div>'].join('');
				$footer.append(actions_bottom);
				widgetize_children($footer, self);
				$footer.find('.dataTables_actions').children().not('.add-row, .delete-selected').addClass('table-action');
				self.fillDataChildren('widget-data', false, $footer);
			}

			// Prevent cascading state actions from affecting header/footer buttons, since they aren't DOM-contained within the widget
			$footer.add($header).addClass('managedState');
			if (self.options._disabled) {
				$footer.find('button').disable(self.options._dt_disable_key);
			}
		},

		_bindActions: function() {
			var $self = this.element;
			var self = this;

			$self.on('click', 'button.delete-row',  CUI.FunctionFactory.build(self._clickDeleteRow, self, { context: 'argument', first: 'context' }, [false]));

			if (!self.options.$wrapper) {
				self.options.$wrapper = $self.closest('div');
			}

			self._delegate(self.options.$wrapper, 'button.delete-selected', 'click', CUI.FunctionFactory.build(self._clickDeleteSelected, self, { context: 'argument', first: 'context' }));
			self._delegate(self.options.$wrapper, 'button.add-row', 'click', CUI.FunctionFactory.build(self._clickAddRow, self, { context: 'argument', first: 'context' }));
			self._delegate(self.options.$wrapper, 'button.refresh', 'click', self.refresh.bind(self));
			self._delegate(self.options.$wrapper, 'button.table-action', 'click', CUI.FunctionFactory.build(self._clickTableActionButton, self, { context: 'argument', first: 'context' }));

			self.options.$filter = self.options.$wrapper.find('.dataTables_filter, .data-table-widget-custom-filter');

			if (self.options.filter_in_page_title) {
				$self.addClass('filter-in-page-title');
				$self.closest('.screen').find('.page_title').before(self.options.$filter);
			}

			// Extension_filter param means that the alternative (text/group) filter is being used.
			if (self.options.extension_filter) {
				self._bind(self.options.$filter.find('.dtw-filter-extension-widget'), 'change', function (e,d) {
					var filter_title = CUI.firstValue($(this).getCUIWidget('flyoutSearchSelectWidget').getValue());
					self.filter(filter_title);
				});
			} else {
				var $contents = self.options.$filter.contents();
				if ($contents[0]) {
					CUI.quickSetText($contents[0], (self.options.filter_title || 'Filter: '));
				}
			}

			self._bind(self.options.$filter.find('input.search'), 'keyup', CUI.FunctionFactory.build(self._filterChangeCallback, self, { context: 'argument', first: 'context' }));
			self._bind($self, 'rowReady', self._scrollTo.bind(self));

		},

		_clickRow: function(node, e) {
			var $self = this.element;
			var self = this;
			var $node = $(node);

			if (e) {
				e.stopPropagation();
				if (!$(e.target).is('a,button,input')) { e.preventDefault(); }
			}

			if (!self.getRowCount() || $node.is('.move-up, .move-down, .drag_handle')) {
				// Abort on clicks that aren't on actual rows
				return;
			}

			//	    debugLog('Click action: ', self.options.click_action);
			if (!self.options.click_action || ($node.hasClass('state-selected') && !(self.options.click_action == 'expand' || self.options.click_action == 'toggle_expand')) || $node.hasClass('action_row') || $node.hasClass('details_row')) {
				//		debugLog('It is selected, is an action row, or a details row.');
				return;
			}

			if (!$node.hasClass('editable') && !$node.hasClass('details_row') && !$node.hasClass('add-mode')){
				if (self._checkRowChangeInterrupt(e, node)) {
					// The process was interrupted (by a dialog)
					return;
				}
			}

			// If we have a sub-dtw in edit mode, bail. This will sometimes cause weird issues
			if ($self.find('table tr.state-selected')[0]) {
				return;
			}

			if (!$node.is('tr')) {
				$node = $node.closest('tr');
			}

			switch (self.options.click_action) {
				case 'select':
					$node.find('> td.checkbox-column > .selected-checkbox:checkbox').click();
					break;
				case 'variable_overlay':
					self._variableShowOverlay($node);
					self.deselectRow($node); // Immediately deselect the node, because overlay selections should not remain selected
					break;
				case 'toggle_expand':
					if ($node.hasClass('state-selected')) {
						self.deselectRow($node);
					} else {
						self.selectRow($node);
					}
					break;
				case 'expand':
					$self.find('tr.state-selected').not($node).each(function() {
						self.deselectRow($(this));
					});
					if ($node.hasClass('state-selected')) {
						self.deselectRow($node);
					} else {
						self.selectRow($node);
					}
					break;
				case 'overlay':
					self._showOverlay($node);
					self.deselectRow($node); // Immediately deselect the node, because overlay selections should not remain selected
					break;
				case 'screen':
					self._showScreen($node);
					self.deselectRow($node); // Immediately deselect the node, because overlay selections should not remain selected
					break;
				case 'edit':
					// Disable row manipulation while an edit/add row is open
					self._disableRowManipulation();

					self.options.performing_click = true;

					// Only go into edit mode if we have an edit element to use for the clicked row.
					if (self.options.details_row && (self.options.details_row.edit_element || self.options.details_row.edit_elements)) {
						self._edit($node);
					} else {
						for (var key in self.options.columns) {
							if (self.options.columns[key].edit_element || self.options.columns[key].edit_elements) {
								self._edit($node);
								break;
							}
						}
					}
					break;
			}
		},

		// This checks whether changing from row to row needs to be interrupted because the previous row has to be saved. Return true to block the change.
		_checkRowChangeInterrupt: function (e, node) {
			var self = this, dialog, dirty = !!self.element.find('.edit-save:not([disabled=disabled]), .add-save:not([disabled=disabled])')[0], invalid = !!self.element.find('.is-invalid:not([disabled=disabled]')[0], adding = self.addingRows();

			if (!dirty && !invalid) {
				return false; // Row is clean, so there is no need to interrupt execution of _clickRow above.
			} else {
				// We're dirty and/or invalid, so let's handle that below by creating a dialog box and returning true
				// Note the ternary expressions for switching between invalid/dirty messages/callbacks/buttons
				if (!self.options.check_row_change_interrupt) {
					dialog = new CUI.Dialog({
						title: (invalid ? "Invalid Row" : "Modified Row"),
						text: (invalid ? "The row you are currently editing contains invalid data. You may either discard changes, or cancel and return to editing the row." : "The row you are currently editing contains changed data. You may either save changes, or cancel and return to editing the row." ),
						buttons: [(invalid ? "Discard" : "Save"),"Cancel"],
						callbacks: [
							function () {
								// Click on the add-cancel/add-save, or edit-cancel/edit-save buttons as appropriate
								// This is done to preserve any behavior associated with the callbacks for these buttons
								self.options.check_row_change_interrupt = true;

								if (self.options.bind_clickRow_to_rowReady) {
									// Bind to rowReady so that clicking the new row only happens once the row is refreshed. Requires a flag option in dtw def
									self._one($(node), 'rowReady', self._clickRow.bind(self, node));
								}

								if (adding) {
									self.element.find('.add-' + (invalid ? 'cancel' : 'save')).trigger('click'); // cancel if invalid, save if dirty
								} else {
									self.element.find('.edit-' + (invalid ? 'cancel' : 'save')).trigger('click'); // cancel if invalid, save if dirty
								}
								$(dialog.$dialog).trigger('close_dialog'); // close the dialog

								if (!self.options.bind_clickRow_to_rowReady) {
									self._clickRow(node, e);
								}
							},
							CUI.Dialog.cancel // Cancel the dialog with no-op; continue editing row
						]
					});
					return true;
				}
			}
		},

		_clickEditSaveButton: function(node, e) {
			var $self = this.element;
			var self = this;
			var $node = $(node);

			e.stopPropagation();
			e.preventDefault();

			if ($node.is('button.edit-save')) {
				// When the row is finished being deselected, we need to make sure that if we were adding a row, that
				// we mark the row as saved and no longer being added so we can add another row.
				self._editDeselectLast();
				self._restoreRowManipulation();
			}
		},

		_clickEditCancelButton: function(node, e) {
			var $self = this.element;
			var self = this;
			var $node = $(node);

			e.stopPropagation();
			e.preventDefault();

			if ($node.is('button.edit-cancel')) {
				self._editDeselectLast($node, true);
				self._restoreRowManipulation();
			}
		},

		_clickAddSaveButton: function(node, e) {
			var $self = this.element;
			var self = this;
			var $node = $(node);

			if ( e.isPropagationStopped() ) { return; }
			e.stopPropagation();
			e.preventDefault();

			if ($node.is('button.add-save')) {
				// When the row is finished being deselected, we need to make sure that if we were adding a row, that
				// we mark the row as saved and no longer being added so we can add another row.
				self._editDeselectLast();
				self._restoreRowManipulation();
			}
		},

		_clickAddCancelButton: function(node, e) {
			var $self = this.element;
			var self = this;
			var $node = $(node);

			if ( e.isPropagationStopped() ) { return; }
			e.stopPropagation();
			e.preventDefault();

			if ($node.is('button.add-cancel')) {
				self._clickDeleteRow(node, true, e);
				self._setBeingAdded(self.options.row_count, false);
				self._restoreRowManipulation();
			}
		},

		_clickTableActionButton: function (node, e) {
			var self = this, $self = this.element;

			if (self.addingRows()) {
				self._clickDeleteRow(node, true, e);
				self._setBeingAdded(self.options.row_count, false);
				self._restoreRowManipulation();
			}
			if (self.options.performing_click) {
				self.options.performing_click = false;
				self._editDeselectLast(node, true);
				self._restoreRowManipulation();
			}
		},

		_wallboardMode: function(on_off, suppress_uri_param_update) {
			var $self = this.element;
			var self = this;

			if (!self.options.hidden) {
				self.options.hidden = [];
			}

			var $button = self.options.$wrapper.find('button.wallboard_button');


			if (on_off && !self.options.in_wallboard_mode) {
				self._hideNodes(self.options.$wrapper[0]);
				$('body').addClass('fullscreen');
				self.options.in_wallboard_mode = true;
				if ($button[0]) {
					$button.text('Normal View');
				}
			} else if (!on_off && self.options.in_wallboard_mode) {
				self._showNodes(self.options.$wrapper[0]);
				$('body').removeClass('fullscreen');
				self.options.in_wallboard_mode = false;
				if ($button[0]) {
					$button.text('Wallboard View');
				}
			}

			if (!suppress_uri_param_update && self.options.row_key && $self.attr('uri_id') && self.hasColumn(self.options.row_key)) {
				if (!self.options.uri_params) {
					self._initURIParams();
				}

				if (on_off) {
					self.options.uri_params.wallboard = true;
				} else {
					delete self.options.uri_params.wallboard;
				}

				if (self.options.parent && self.options.parent._storeURIParams) {
					self.options.parent._storeURIParams($self, self.options.uri_params);
				}
			}

		},

		_clickWallboardButton: function(node, e) {
			var $self = this.element;
			var self = this;

			if (e) {
				e.stopPropagation();
				e.preventDefault();
			}

			if (!self.options.in_wallboard_mode) {
				if (/embedded/.test(window.location.search)) {
					//		    debugLog("Hiding wallboard button.");
					self.options.hide_wbbut = true;
					if (!self.options.uri_params) {
						self.options.uri_params = {};
					}
					self.options.uri_params.hide_wbbut = true;
					if (self.options.parent && self.options.parent._storeURIParams) {
						self.options.parent._storeURIParams($self, self.options.uri_params);
					}
				}
				self._wallboardMode(true);
			} else {
				self._wallboardMode(false);
			}
		},

		_clickMoveUpDownCallback: function(e) {
			var $self = this.element;
			var self = this;
			var $target, $node;

			if (e) { e.preventDefault(); }
			if (!self.options.ready) { return; }

			$target = $(e.target);
			if (!$target.closest('table.dataTableWidget').is($self)) { return; } // Bail if we're getting events from tables within tables

			$node = $target.closest('.move-up,.move-down');

			var $tr = $node.closest('tr');
			var $prev_trs = $tr.prevAll().not('.no-sort');
			var $next_trs = $tr.nextAll().not('.no-sort');

			if (($node.hasClass('move-up') && !$prev_trs.length) || ($node.hasClass('move-down') && !$next_trs.length)) {
				return;
			}

			self.options.$drag_tr = $tr;
			self.options.drag_start_pos = $prev_trs.length;

			if ($node.hasClass('move-up')) {
				$tr.insertBefore($prev_trs.eq(0));
			} else {
				$tr.insertAfter($next_trs.eq(0));
			}
			self._dragDropCallback(e, { item: $tr });
		},

		_dragDropCallback: function(event, ui) {
			var $self = this.element, self = this;
			var $current, $sibling, current_pos, current_data, change_data = {}, sibling_pos, sibling_data, new_index_offset = 0, submit_keys, rs_k, sk_i;

			if (!self.options.ready) { return; }

			// Did it actually move?
			if (self.options.drag_start_pos === ui.item.prevAll().length) { return; }

			$current = ui.item;
			$sibling = $current.nextAll('tr:not(.detailsRow)').eq(0);

			if (!$sibling[0]) {
				// No "next" row, try "previous"...
				$sibling = $current.prevAll('tr:not(.detailsRow)').eq(0);
				new_index_offset = 1; // The new index will be 1 more than, not the same as, the previous one.
			}

			if (!$sibling[0]) {
				// This should never happen--
				debugLog('jquery.dataTableWidget.js: No adjacent TR siblings available to determine the reordered row\'s position. Are there other rows in this table? (row=', $current, ') -- ', $self);
			}

			sibling_pos = self.options.$oTable.fnGetPosition($sibling[0]);
			current_pos = self.options.$oTable.fnGetPosition($current[0]);

			current_data = {};
			sibling_data = {};

			if (self.options.data_source === 'ROW_SOURCE') {
				rsLoop: for (rs_k in self.options.data) {
					if (!self.options.data.hasOwnProperty(rs_k)) { continue rsLoop; }
					current_data[rs_k] = self.options.data[rs_k][current_pos];
					sibling_data[rs_k] = self.options.data[rs_k][sibling_pos];
				}
			} else {
				sibling_data = self.options.data[self.options.data_source][sibling_pos];
				current_data = self.options.data[self.options.data_source][current_pos];
			}

			if (self.options.sortable.also_submit === undefined) {
				// This should only happen if you didn't specify s.o.s.also_submit, which is rare and not recommended, but possible. Default to "everything".
				submit_keys = CUI.keys(current_data);
			} else {
				submit_keys = [].concat(self.options.sortable.also_submit); // Copy, because we're going to mangle this if it copies by ref
			}

			for (sk_i = 0; sk_i < submit_keys.length; sk_i++) {
				if (submit_keys[sk_i] in current_data && current_data[submit_keys[sk_i]] !== null && current_data[submit_keys[sk_i]] !== undefined) {
					change_data[submit_keys[sk_i]] = current_data[submit_keys[sk_i]];
				} else if (!(submit_keys[sk_i] in current_data) && (submit_keys[sk_i] in self.options.rest_params)) {
					change_data[submit_keys[sk_i]] = self.options.rest_params[submit_keys[sk_i]];
				}
			}

			if (CUI.firstKey(sibling_data) === undefined) {
				// This shouldn't happen
				debugLog('jquery.dataTableWidget.js: Could not find the sibling data at row ', sibling_pos, ' (row,sibling=', $current, $sibling, ') -- ', $self);
				return;
			}

			if (isNaN(sibling_data[self.options.sortable.field])) {
				// This might happen, but if it does, it's your fault--
				debugLog('jquery.dataTableWidget.js: Could not find the positioning data (key=', self.options.sortable.field, ') in the sibling row (row,data=', $current, sibling_data, ') -- ', $self);
				return;
			} else {

				if (sibling_data[self.options.sortable.field] > current_data[self.options.sortable.field] && self.options.sortable_reorder_removes_self) {
					new_index_offset--;
				}

				change_data[self.options.sortable.field] = Number(sibling_data[self.options.sortable.field]) + new_index_offset;
			}

			var doRefresh = function () { self.refresh(); };

			self.options.add_edit_action.real_method = (self.options.add_edit_action || {}).method || 'PUT';
			self._executeSaveREST(change_data, current_pos, undefined, doRefresh, doRefresh);

			return;
		},

		_clickDeleteSelected: function(node, e) {
			var self = this, $self = this.element, $node = $(node), was_paginated;

			was_paginated = self.options.paginate ? (self.options.data.count > self.options.data.rows) : false;

			e.stopPropagation();
			e.preventDefault();



			if ($node.is('button.delete-selected')) {
				var $trs = $self.find('tr.selected-checked');

				var delete_rows = function() {
					var count = $trs.size(), delete_closures, success_fn, error_fn;
					// Each time we delete a row, the row_num of the remaining rows can change
					// Mulitple deletes are a PITA!
					// When we issue a delete, it is asynchronous. So, we loop
					// through a bunch and get their network transactions started,
					// but then at some point, they start finishing before we
					// finish getting all of them started. When they finish,
					// they start modifying the very source of our row numbers
					// and TR references for doing additional deletes. So, we
					// need to make closures around the requests and chain call
					// them while each one waits for the previous to finish!

					if (self.options.multi_delete) {
						var rows_to_delete = [];

						$trs.each(function(i, elem){
							rows_to_delete.push(self.options.$oTable.fnGetPosition(elem));
							$(elem).find('.selected-checkbox').removeAttr('checked');
							$(elem).removeClass('selected-checked');
						});

						self._deleteDataTableRow(rows_to_delete, self._deleteSuccess.bind(self), self._deleteError.bind(self), $trs);
					} else {
						success_fn = self._deleteSuccess.bind(self);
						error_fn = self._deleteError.bind(self);

						// stop dataEvents from firing until the last delete, noDataEventTrigger is set to false on the last delete
						self.options.noDataEventTrigger = true;

						var delete_callback = function(item) {
							var $tr = $self.find('tr.selected-checked').eq(0);
							if ($tr && $tr[0]) {
								var row_num = self.options.$oTable.fnGetPosition($tr[0]);
								$tr.find('.selected-checkbox').removeAttr('checked');
								$tr.removeClass('selected-checked');
								if (item == count - 1) {
									self.options.noDataEventTrigger = false;
								}
								self._deleteDataTableRow(row_num, success_fn, error_fn, $tr);
							}
						};

						if (Array.prototype.fill) {
							// Limited support, but if it's available, use it:
							delete_closures = (new Array(count)).fill(delete_callback);
						} else {
							delete_closures = [];
							for (var c = 0; c < count; ++c) { delete_closures.push(delete_callback); }
						}

						// OMFG this sucks. There must be an easier way!
						var delete_next = function(item) {
							// Call the delete closure
							if (delete_closures[item] && typeof delete_closures[item] == 'function') {
								var next_delete = arguments.callee;
								var trigger_next_delete = function() {
									next_delete(item+1);
								};

								// For some reason, this fails (only triggers once) with self._one. $self.one does the trick, though.
								// I suspect it's something with the loop-de-loop of closures-- that self is being clobbered or that
								// it's messing with the bind tracking. I'm just going with what works for now. Given the immediate
								// nature of this function, I don't think we'll encounter the hanging binds that self._one solves.
								$self.one('DeleteFinished', trigger_next_delete);

								delete_closures[item](item);
							} else {
								// Uncheck the "delete all" checkbox, if the user had that checked
								$self.find('> thead > th .select-all-checkbox').removeAttr('checked');

								// The table should be refreshed if it is sortable, to reset the indices
								// It should also be refreshed if it is paginated, to show new rows below the current page fold
								if (self.options.sortable || was_paginated) {
									self.refresh();
								}
							}
						};
						delete_next(0);
					}
				}; // END delete_rows

				var confirm_closure = function(confirmed, idx, $node) {
					if(confirmed) {
						// Uncheck the "select all" checkbox if it is checked
						$self.find('.select-all-checkbox').removeAttr('checked');
						$self.closest('.dataTables_wrapper').findNotUnder('.delete-selected', '.dataTableWidget').disable('add_in_progress');
						delete_rows();
					}
				};

				var post_validate_closure = function () {
					var confirm_delete;
					// If the user already had to confirm the delete because of a "warn" return, don't do it again
					if (self.options._delete_validate_confirmed) {
						delete self.options._delete_validate_confirmed;
						confirm_closure(true, 0, $node);
						return;
					}

					if (self.options.table_actions && self.options.table_actions.delete_rows && self.options.table_actions.delete_rows.confirm) {
						confirm_delete = self.options.table_actions.delete_rows.confirm;

						new CUI.Dialog({
							title: confirm_delete.title,
							text: confirm_delete.text,
							buttons: [ confirm_delete.ok_button, confirm_delete.cancel_button ],
							values: [ true, false ],
							value_callback: confirm_closure,
							value_callback_args: $node
						});

						return;
					} else {
						confirm_closure(true, 0, $node);
					}
				};

				if (self.options.table_actions && self.options.table_actions.delete_rows && self.options.table_actions.delete_rows.validate_rest) {
					self._validateDeleteAction(post_validate_closure);
				} else {
					post_validate_closure();
				}

			}
		},

		_validateDeleteAction: function (post_validate_closure) {
			var self = this, $self = this.element, $trs, num_rows, tr_idx, dp_key, row_num, delete_params, all_delete_params = {}, chunked_delete_params, chunk_idx, total_items, chunk_items, item_length, chunk_length, item_idx, url_overhead, param_overhead;

			$trs = $self.find('> tbody > tr.selected-checked');

			num_rows = $trs.length;

			if (!num_rows) {
				post_validate_closure();
				return;
			}

			for (tr_idx = 0; tr_idx < num_rows; tr_idx++) {
				row_num = self.options.$oTable.fnGetPosition($trs[tr_idx]);
				delete_params = self._getDeleteParams(row_num);

				// If all_delete_params has a key that this DP does not, push an empty string.
				for (dp_key in all_delete_params) {
					if (!all_delete_params.hasOwnProperty(dp_key)) { continue; }
					if (!(dp_key in delete_params)) {
						all_delete_params[dp_key].push('');
					}
				}

				for (dp_key in delete_params) {
					if (!delete_params.hasOwnProperty(dp_key)) { continue; }

					// If this DP has a key that all_delete_params does not, make a new entry in all_delete_params
					if (!(dp_key in all_delete_params)) {
						all_delete_params[dp_key] = new Array(tr_idx);
						for (var idx=0; idx < tr_idx;) { all_delete_params[dp_key][idx++] = ''; }
					}
					all_delete_params[dp_key].push(delete_params[dp_key]);
				}
			}

			// Break the request into chunks, if necessary, to prevent too-long URLs

			chunked_delete_params = [{}];
			url_overhead = self.options.table_actions.delete_rows.validate_rest.length;
			param_overhead = 2;
			total_items = all_delete_params[CUI.firstKey(all_delete_params)].length;
			chunk_idx = 0;
			chunk_items = 0;
			chunk_length = url_overhead;

			for (item_idx = 0; item_idx < total_items; item_idx++) {
				item_length = param_overhead;

				for (dp_key in all_delete_params) {
					item_length += dp_key.length + all_delete_params[dp_key][item_idx].length;
				}

				if ((chunk_length + item_length > 1000) && chunk_items) {
					chunk_items = 0;
					chunk_length = url_overhead;
					chunk_idx++;
					chunked_delete_params.push({});
				}

				for (dp_key in all_delete_params) {
					chunked_delete_params[chunk_idx][dp_key] = chunked_delete_params[chunk_idx][dp_key] || [];
					chunked_delete_params[chunk_idx][dp_key].push(all_delete_params[dp_key][item_idx]);
				}

				chunk_length += item_length;
				chunk_items++;
			}

			self.options._delete_action_chunks_count = chunked_delete_params.length;

			for (chunk_idx = 0; chunk_idx < chunked_delete_params.length; chunk_idx++) {
				CUI.getREST(self.options.table_actions.delete_rows.validate_rest, chunked_delete_params[chunk_idx], self._validateDeleteActionHandler.bind(self, post_validate_closure, chunk_idx));
			}
		},

		_validateDeleteActionHandler: function (post_validate_closure, chunk_idx, d) {
			var self = this, container, data, has_prevent, has_warn, prevent = [], warn = [], idx, $messages, $dialog_body;

			if (self.options.destroyed) { return; }

			self.options._delete_action_response_chunks = self.options._delete_action_response_chunks || { prevent: [], warn: [] };

			container = self.options.table_actions.delete_rows.validate_rest_container;
			data = container ? d[container] : d;

			self.options._delete_action_response_chunks.prevent[chunk_idx] = data.prevent || [];
			self.options._delete_action_response_chunks.warn[chunk_idx] = data.warn || [];

			if (!--self.options._delete_action_chunks_count) {
				// All chunked checks are available, combine them and check for errors

				for (idx = 0; idx < self.options._delete_action_response_chunks.prevent.length; idx++) {
					prevent = prevent.concat(self.options._delete_action_response_chunks.prevent[idx]);
				}

				for (idx = 0; idx < self.options._delete_action_response_chunks.warn.length; idx++) {
					warn = warn.concat(self.options._delete_action_response_chunks.warn[idx]);
				}

				delete self.options._delete_action_response_chunks;
				delete self.options._delete_action_chunks_count;

				$messages = $('<ul class="bullet-list" />');

				if (prevent.length) {
					for (idx = 0; idx < prevent.length; idx++) {
						if (prevent[idx] !== undefined) {
							has_prevent = true;
							$messages.append($('<li />').text(prevent[idx]));
						}
					}
				}

				if (!has_prevent && warn && warn.length) {
					for (idx = 0; idx < warn.length; idx++) {
						if (warn[idx] !== undefined) {
							has_warn = true;
							$messages.append($('<li />').text(warn[idx]));
						}
					}
				}

				if (has_prevent) {
					$dialog_body = $('<div>Some of these items cannot be deleted. Please de-select them and try again.</div>');
					$dialog_body.append($messages);

					new CUI.Dialog({
						title: 'Cannot Delete all the Requested Items',
						body: $dialog_body,
						buttons: ['OK'],
						callbacks: [CUI.Dialog.cancel]
					});

					return;
				} else if (has_warn) {
					$dialog_body = $('<div>Deleting these items may affect other parts of the system. Are you sure you would like to delete these elements?</div>');
					$dialog_body.append($messages);

					new CUI.Dialog({
						title: 'Confirm Delete',
						body: $dialog_body,
						buttons: ['Delete', 'Cancel'],
						callbacks: [function () {
							self.options._delete_validate_confirmed = true;
							CUI.Dialog.cancel.apply(this);
							post_validate_closure();
						}, CUI.Dialog.cancel]
					});

					return;

				} else {
					post_validate_closure();
				}
			} // END if (!--s.o._delete_action_chunks_count)
		},

		_clickDeleteRow: function(node, skip_confirm, e) {
			var self = this, $self = this.element, $tr, row_num, perform_delete_row, confirm_delete;

			if (e) {
				e.stopPropagation();
				e.preventDefault();
			}

			$tr = $self.find('tr.state-selected').not('.action_row, .details_row');
			row_num = self.options.$oTable.fnGetPosition($tr[0]);
			perform_delete_row = function( really ) {
				if (really) {
					self._deleteDataTableRow(row_num, self._deleteSuccess.bind(self), self._deleteError.bind(self), $tr);
					self.options.performing_click = false;
					self._restoreRowManipulation();
				}
			};

			if (!skip_confirm && !self.isBeingAdded(row_num) && self.options.row_actions && self.options.row_actions.delete_row && self.options.row_actions.delete_row.confirm) {
				confirm_delete = self.options.row_actions.delete_row.confirm;
				if (typeof confirm_delete != 'object') {
					confirm_delete = {};
				}
				if (!confirm_delete.text) {
					confirm_delete.text = 'Are you certain you wish to delete the selected row?';
				}
				if (!confirm_delete.title) {
					confirm_delete.title = 'Confirm Row Deletion';
				}
				if (!confirm_delete.ok_button) {
					confirm_delete.ok_button = " Yes ";
				}
				if (!confirm_delete.cancel_button) {
					confirm_delete.cancel_button = " No ";
				}

				new CUI.Dialog({
					title: confirm_delete.title,
					text: confirm_delete.text,
					buttons: [ confirm_delete.ok_button, confirm_delete.cancel_button ],
					values: [ true, false ],
					value_callback: perform_delete_row
				});
			} else {
				perform_delete_row(true);
			}
		},

		// This gets renamed to deleteRow in beforeInit
		_deleteDataTableWidgetRow: function (row, confirm) {
			var self = this, $self = this.element;

			var row_num = -1;
			if (row instanceof jQuery) {
				row_num = self.options.$oTable.fnGetPosition(row[0]);
			} else if (typeof row === 'number') {
				row_num = row;
			} else {
				return;
			}

			if (confirm) {
				self._clickDeleteRow($(self.options.$oTable.fnGetNodes(row_num)), undefined, undefined);
			} else {
				delete self.options.new_row_check[row_num];
				self._deleteDataTableRow(row_num);
			}
		},

		deleteRow: function() {
			var self = this;
			self.options.$oTable.fnProcessingIndicator(true);
			return classCUI.prototype.dataTableClass.deleteRow.apply(self, arguments);
		},

		_afterDeleteRow: function(row_num, success, $tr) {
			var self = this, $self = this.element, row, original_data, key, i;

			$tr = $tr || $(self.options.$oTable.fnGetNodes(row_num));

			self.options.$oTable.fnProcessingIndicator(false);
			if (success) {
				self._setBeingEdited(row_num, false);
				self._setBeingAdded(row_num, false);
				self.deselectRow($tr, true, true);
				$tr.children().children().remove(); // Force destroy operations on widgets, cannot use empty()
				$tr = null;
				row = self.options.$oTable.fnDeleteRow(row_num);
				row.nTr = null;
				delete row.nTr;
				if (navigator.userAgent.indexOf('MSIE 8') !== -1) {
					self._IERowHack();
				}
				self.options.row_count--;

				// Remove this row from original data, so that functions checking for the table's original data will be accurate.
				original_data = self.options.original_data[self.options.data_source];

				if (self.options.data_source == 'ROW_SOURCE') {
					for (key in original_data) {
						original_data[key].splice(row_num, 1);
					}
				} else {

					delete original_data[row_num];

					for (i in original_data) {
						if (i > row_num) {
							original_data[i - 1] = original_data[i];
						}
					}
				}

				$self.trigger('RowDeleted');
				$self.trigger('change', { non_interactive: true });

				$self.trigger('DeleteFinished');
			}
		},

		_getDeleteParams: function(row_num) {
			var $self = this.element, self = this, params;
			params = $.extend({},
							  (self.options._selected_parent_data || {}).delete_action || {},
							  CUI.dataTableClass._getDeleteParams.apply(self, arguments) || {},
							  self._callDataHook('DeletePostParams', params) || {}
							 );
			return params;
		},

		_deleteSuccess: function() {
			var self = this, $self = this.element;

			self.options.$wrapper.removeClass('state-error');
			$self.removeData('error');
			$self.removeClass('is-dirty');
			self.options.$wrapper.removeClass('is-invalid');
			$self.trigger('DeleteSuccess');
		},

		// $trs is not used in this instance, but it's included for consistency with _deleteSuccess, or in case future subclasses need it
		// It may not be set, so do not rely on it.
		_deleteError: function(jxHQR, status, error, row_num, $trs, error_rows) {
			var self = this, $self = this.element, error_data, row_name, error_title;

			self.options.$wrapper.addClass('state-error');

			error_data = jxHQR.status < 500 ? jQuery.parseJSON(jxHQR.responseText) : jxHQR.responseText;
			if (error_data.error) {
				error_data = error_data.error;
			}

			// Generate the title
			if (error_rows) {
				error_title = 'Error Deleting Rows';
			} else {
				row_name = self.getNameOfRow(row_num);
				error_title = (row_name ? 'Error Deleting ' + row_name : 'Error Deleting the Row');
			}

			if (jxHQR.status < 400) {
				$self.data('error', error);
				new CUI.Dialog({ title: error_title, text: error });
			} else if (typeof error_data === 'string') {
				if (error_data.length > 1000) {
					error_data = 'There was an unspecified error deleting the row.';
				}
				new CUI.Dialog({ title: error_title, text: error_data });
			} else {
				self.addErrorDialog(error_data, error_title, error_rows);

				if (window.AJAXErrorHandler) {
					new AJAXErrorHandler().handle(jxHQR, status, error);
				}
			}
		},

		// this is a function for adding an error dialog when error data is returned as an object
		addErrorDialog: function (error_data, error_title, error_rows) {
			var self = this, $self = this.element, errors = [], errors_lookup = {}, field, e_idx, $errors = $('<ul class="error-list" />');

			for (field in error_data) {
				switch(error_data[field]) {
					case "extension_in_use":
						errors.push('That extension is in use.');
						break;
					case "NOTFOUND":
						errors.push('Some requested information was not found.');
						break;
					default:
						if (error_rows) {
							for (e_idx in error_rows) {
								errors.push(error_rows[e_idx]._name + ': ' + getMessageFromKey(error_rows[e_idx].error));
							}
						} else {
							// If it's all-caps and underscores, it's most likely a key
							if (error_data[field].search(/^[A-Z0-9_]+$/) === -1) {
								errors.push(error_data[field]);
							} else {
								errors.push('There was an unspecified error performing the operation.');
							}
						}
						break;
				}
			}

			// Deduplicate and fill the jQ object...
			for (e_idx = 0; e_idx < errors.length; e_idx++) {
				if (!errors_lookup[errors[e_idx]]) {
					errors_lookup[errors[e_idx]] = true;
					$errors.append($('<li />').text(errors[e_idx]));
				}
			}

			new CUI.Dialog({ title: error_title, body: $errors });
		},

		_clickAddRow: function(node, e) {
			var self = this, $self = this.element, $node = $(node);
			e.stopPropagation();
			e.preventDefault();

			if (!$node.is('button.add-row')) {
				return;
			}
			if (self.addingRows()) {
				// Two "finds" so sizzle doesn't have to take the easy parts
				$self.find('tr:not(.action_row, .details_row)').find('input:first').focus();
				return;
			}

			self._disableRowManipulation();

			// Set up a callback for after we do our deselection
			self._one($self, 'deselected', self._afterAddRowDeselection.bind(self));
			self._editDeselectLast(undefined, true);
		},

		_afterAddRowDeselection: function () {
			var self = this, index, $tr;
			index = self.addEmptyRow();
			$tr = $(self.options.$oTable.fnGetNodes(index));
			$tr.trigger('rowReady');
		},

		_disableRowManipulation: function () {
			var self = this, $self = this.element, $buttons;

			$buttons = $self.closest('.dataTables_wrapper').find('.dataTables_actions').find('.add-row,.delete-selected');
			$buttons.disable('add_in_progress');
			if (self.options.$tbody.hasClass('ui-sortable')) {
				if (self.options._$move_handle) {
					self.options._$move_handle.bubble('destroy');
					delete self.options._$move_handle;
				}

				self.options.$tbody.sortable('disable');
			}
		},

		_restoreRowManipulation: function () {
			var self = this, $self = this.element, $buttons;
			$buttons = $self.closest('.dataTables_wrapper').find('.dataTables_actions').find('.add-row,.delete-selected');
			$buttons.css('color', '').enable('add_in_progress');
			if (self.options.$tbody && self.options.$tbody.hasClass('ui-sortable')) {
				self.options.$tbody.sortable('enable');
			}
			self._toggleDeleteSelected();
		},

		// This gets called when adding a row from live array events,
		// as well as when a blank row is added. It adds the row to the
		// table in the browser.
		_afterAddRow: function(row_data, index) {
			var self = this, $self = this.element, empty = true, key, mode, aaDataRow, aaData, rc_idx;

			//debugLog("Added row: ", index, row_data);

			for (key in row_data) {
				if (row_data[key]) {
					empty = false;
					break;
				} else {
					row_data[key] = '&nbsp;';
				}
			}

			mode = empty ? 'add' : 'view';

			aaDataRow = self._buildAADataRow(index, mode, row_data);
			aaData = [ aaDataRow ];

			self.options._row_cache = self.options._row_cache || [];

			// In rare cases, an LDT will start sending rows before the DTW is built -- use self.options._row_cache to save those
			if (self.options.$oTable && self.options._row_cache && self.options._row_cache.length) {
				self.options._row_cache.push({ data: aaData, index: index });
				for (rc_idx = 0; rc_idx < self.options._row_cache.length; rc_idx++) {
					self._DTWAddRowToTable(self.options._row_cache[rc_idx]);
				}
				delete self.options._row_cache;
			} else if (self.options.$oTable) {
				self._DTWAddRowToTable({ data: aaData, index: index, row_data: row_data });
			} else {
				self.options._row_cache.push({ data: aaData, index: index, row_data: row_data });
			}
		},

		_DTWAddRowToTable: function (aaData_and_index) {
			var self = this, $self = this.element, $tr, aaData = aaData_and_index.data, index = aaData_and_index.index, row_data = aaData_and_index.row_data;

			self.options.$oTable.fnAddData(aaData, (self.options.inhibit_refresh || self.addingRows() ? false : true));
			if (!self.options.inhibit_refresh && self.addingRows()) {
				self.options.$oTable.fnDraw(false);
			}

			$tr = $(self.options.$oTable.fnGetNodes(index));

			if (self.options.sortable) {
				$tr.toggleClass('no-sort', !self._isRowSortable(index + 1, row_data));
			}

			if (self.options.click_action) {
				$tr.addClass('clickable option-selectable');
				$tr.toggleClass('no-click', !self._isRowClickable(index + 1, row_data));
			}

			self._bindEditFields($tr);

			widgetize_children($tr, $.ui.widget.prototype);
			self.fillDataChildren('widget-data', false, $tr, self.getRowWidgetData(index));

			if (self.options.live_table || (!self.options.rest && !self.addingRows())) {
				self.sort();
			}

			if (navigator.userAgent.indexOf('MSIE 8') !== -1) {
				self._IERowHack();
			}
			$tr = null;
			$self.trigger('change', { non_interactive: true });
		},

		_afterAddEmptyRow: function(row_data, index) {
			var self = this, $self = this.element, after_added_row_deselect;

			// Prevent $tr from being closed by asynchronous closure
			(function doFirst() {
				var $tr;

				self.options.new_row_check[index] = true;

				// Allow an extra row in the page size when adding a new item
				if (self.options.paginate || self.options.length_change) {
					self.options.$oTable.fnLengthChange(self.options.$oTable, self.getPageSize() + 1);
				}

				$tr = $(self.options.$oTable.fnGetNodes(index));
				$tr.addClass('new_row');

				if (navigator.userAgent.indexOf('MSIE 8') !== -1) {
					self._IERowHack();
				}

				$tr.find('button.add-save').show();
				$tr.find('button.add-cancel').show();
				self.selectRow($tr);
				$tr.addClass('add-mode');
				$tr.find('input:first').focus();
			})();

			// This closure is safe as it only closes references to self, row_data, and index
			// When this row is deselected, make sure we mark it such so we can add another row...
			after_added_row_deselect = function() {
				var self = this;
				var $tr = $(self.options.$oTable.fnGetNodes(index));

				delete self.options.new_row_check[index];

				if (self.addingRows()) {
					$tr.removeClass('new_row');
					if (self.options.paginate) {
						self.options.$oTable.fnLengthChange(self.options.$oTable, self.getPageSize());
					}
				}
			};

			self._one($self.parent(), 'finished_deselected', function() {
				self._one($self.parent(), 'deselected', after_added_row_deselect.bind(self));
			});
		},

		_clickSelectAllCheckbox: function(node, e) {
			var $self = this.element, self = this, $node = $(node), $to_skip, $checked, $unchecked;

			e.stopPropagation();

			if ($node.is('input.select-all-checkbox')) {
				$to_skip   = $self.find('.dataTables_wrapper tr input.selected-checkbox');
				$unchecked = $node.closest('table').find('tr input.selected-checkbox:not(:checked)').not($to_skip);
				$checked   = $node.closest('table').find('tr input.selected-checkbox:checked').not($to_skip);

				if ($node.is(':checked')) {
					$unchecked.trigger('click', { non_interactive: true });
				} else {
					$checked.trigger('click', { non_interactive: true });
				}
			}
		},

		_clickSelectedCheckbox: function(elem, e, data) {
			var $self = this.element, self = this, $elem = $(elem);
			data = data || {};

			e.stopPropagation();

			if ($elem.is('input.selected-checkbox')) {
				var $tr = $elem.closest('tr');
				if (self.options.select_single) {
					$self
						.find('tr.selected-checked')
						.not($tr)
						.removeClass('selected-checked')
						.find('input.selected-checkbox')
						.removeAttr('checked')
						.end()
						.triggerHandler('toggleSelect');
				}
				$tr.toggleClass('selected-checked');
				$tr.triggerHandler('toggleSelect');

				self._toggleDeleteSelected();
				$self.trigger('change', { non_interactive: true });
			}

			// Remove the "Select All" checkbox if this one was unchecked

			if (!$elem.attr('checked') && !data.non_interactive) {
				$elem.closest('table').find('> thead input.select-all-checkbox').removeAttr('checked');
			}
		},

		_toggleDeleteSelected: function () {
			var self = this, $self = this.element, $nested_delete, $delete_buttons;
			$nested_delete = $self.find('.dataTables_wrapper button.delete-selected');
			$delete_buttons = self.options.$wrapper.find('button.delete-selected').not($nested_delete);

			if ($self.find('tr.selected-checked')[0] && (!self.options.adding_rows || CUI.keys(self.options.adding_rows).length === 0)){
				$delete_buttons.css('color', '').enable('add_in_progress');
			} else {
				$delete_buttons.disable('add_in_progress');
			}
		},

		_buildRowParams: function($tr) {
			var self = this, $self = this.element, params = {}, widget_params = {}, source_row, data_source = false, row_num;

			if ($tr.data('params') && $tr.data('params').length > 1) { return; }

			row_num = self.options.$oTable.fnGetPosition($tr[0]);
			if (!self.options.data || row_num < 0) { return; }

			if (self.options.data_source == 'ROW_SOURCE') {
				// TODO: Row sourced data source here.
			} else {
				data_source = self.options.data[self.options.data_source];
			}

			if (!data_source || !data_source[row_num]) {
				return;
			}

			source_row = data_source[row_num];

			if (self.options.overlay_params) {
				for (var p in self.options.overlay_params) {
					var param_name = self.options.overlay_params[p];
					var param_val = source_row[param_name];
					params[param_name] = param_val;
				}
			}
			if (self.options.overlay_widget_params) {
				// Make a copy
				$.extend(true, widget_params, self.options.overlay_widget_params);
			}

			$.extend(params, self.options.overlay_data);

			$tr.data('params', JSON.stringify(params));
			$tr.data('widget_params', JSON.stringify(widget_params));
		},

		_edit: function(node) {
			var self = this, $self = this.element, $tr, row_num;

			$tr = $(node);
			row_num = self.options.$oTable.fnGetPosition($tr[0]);

			// We must perform deselection of any selected rows before we try to make the newly clicked
			// row editable. Deselection may result in an asynchronous save, and that must succeed before
			// we can fully deselect any previously editable rows and make a new one editable.

			self._one($self, 'deselected', self._afterEditDeselect.bind(self, $tr, row_num));
			self._editDeselectLast($tr);
		},

		_afterEditDeselect: function ($tr, row_num) {
			var self = this, $self = this.element, $tds, td_idx, vis_td_idx = 0, $td, td_val, row_data, $editable, real_td_idx;
			$tds = self._editGetEditableTDs($tr).not('td.editable');

			self.selectRow($tr).addClass('editable');

			// Note that we're checking vis_td_idx in the for loop, not the iterator td_idx
			for (td_idx = 0; vis_td_idx < $tds.length; td_idx++) {
				if (self.options.columns[td_idx].visible === false) { continue; }

				$td = $tds.eq(vis_td_idx);
				$td.data('index', td_idx);
				$td.addClass('editable');
				td_val = self.getCellData(row_num, td_idx);

				// Get the HTML that represents the field in an editable way and set the value to the
				// value in the cell.
				row_data = self.getRowWidgetData(row_num);
				$editable = self._getFieldHTML(td_idx, td_val, 'edit', row_data);

				if ($editable && $editable[0]) {
					real_td_idx = (self.options.col_offset || 0) + td_idx;

					$td.children().remove(); // Clean up widgets
					self.options.$oTable.fnUpdate($editable.html(), row_num, real_td_idx, false, false);

				}

				vis_td_idx++;
			}

			self.options.$oTable.fnDraw(self.options.rest ? false : true);
			widgetize_children($tr, self);
			self.fillDataChildren('widget-data', false, $tr, row_data);
			self._bindEditFields($tr);

			self._setBeingEdited(row_num, true);

			$tr.triggerHandler('rowReady');
		},

		_viewMode: function ($tr) {
			var self = this, $self = this.element, $tds, cd_idx, col_def, td_num = 0, td_val, $td, row_num, row_data, $field_html, field_html, offset_col_num;
			$tr = $($tr);

			$tds = self._editGetEditableTDs($tr).not('td.editable');
			row_num = self.options.$oTable.fnGetPosition($tr[0]);

			//for (td_num = 0; td_num < $tds.length; td_num++) {
			td_num = 0; // td_num tracks the actual TD we're on, cd_idx tracks the column def. Since hidden columns generate no TDs, these can be different!
			for (cd_idx = 0; cd_idx < self.options.columns.length; cd_idx++) {
				col_def = self.options.columns[cd_idx];

				if (col_def.visible === false) { continue; } // <------ Continue on hidden columns

				$td = $tds.eq(td_num);
				$td.data('index', cd_idx);
				td_val = self.getCellData(row_num, cd_idx);

				// Get the HTML that represents the field in a non-editable way and set the value to the
				// value in the cell.
				row_data = self.getRowWidgetData(row_num);
				$field_html = self._getFieldHTML(cd_idx, td_val, 'view', row_data);

				if ($field_html && $field_html[0]) {
					field_html = $field_html.html();
					// offset_col_num = self.options.col_offset ? td_num + self.options.col_offset : td_num;
					offset_col_num = cd_idx + (self.options.col_offset || 0);
					$td.children().remove(); // Clean up widgets
					self.options.$oTable.fnUpdate(field_html, row_num, offset_col_num, false, false);

				}

				++td_num;
			}
			self.options.$oTable.fnDraw(self.options.rest ? false : true);
			widgetize_children($tr, self);
			self.fillDataChildren('widget-data', false, $tr, row_data);
			self._bindEditFields($tr);

			$tr.triggerHandler('rowReady');
			$tr.add($tds).removeClass('editable');
		},

		_scrollTo: function (e) {
			var self = this, $self = this.element, offset = 0, $page, $page_module, $children, idx, $dtw_wrapper, dtw_offset;

			$page = $self.closest('.cui-page-content');
			$page_module = self.options.$page_modules;

			if ($page[0] && $page_module[0] && self.options.allow_scroll) {
				$children = $page.children();
				for (idx = 0; idx < $children.length; idx++) {
					if ($children[idx] === $page_module[0]) { break; }
					offset += $($children[idx]).height();
				}
				$dtw_wrapper = $self.closest('.cui-table-datatables-wrapper');
				dtw_offset = $dtw_wrapper.position().top;
				$page.scrollTop( offset + dtw_offset );
			}
		},

		_getFieldHTML: function(td_num, val, type, row_data) {
			var self = this, $self = this.element, formatted_val, col_def, widget_element, widget_elements, $field;

			if (td_num >= 0) {
				col_def = self.options.columns[td_num];
			}

			// We do some crazy jugling here to get either the edit field / entity or the view field / entity
			// into field_def
			if (col_def && (type == 'edit' || (type == 'add' && !col_def.add_element))) {
				if (col_def.edit_element) {
					widget_element = col_def.edit_element;
				}
				if (col_def.edit_elements) {
					widget_elements = col_def.edit_elements;
				}
			} else if (col_def && type == 'view') {
				if (col_def.view_element) {
					widget_element = col_def.view_element;
				}
				if (col_def.view_elements) {
					widget_elements = col_def.view_elements;
				}

				// Fallback in case no elements are specified -- just display the value
				if (!col_def.view_element && !col_def.view_elements) {

					// Processing translate/format --
					// 1.) If there is a valid translation, use it
					// 2.) If translate is set, and there is a default translation, use that
					// 3.) If the value is undefined or an empty string and there is an undefined translation, use that
					// 4.) Otherwise, if formatted is set, format the value
					// 5.) If format is not on, and there was no translation made, just pass the raw value

					if (col_def.translate && val in col_def.translate) {
						formatted_val = col_def.translate[val];
					} else if (col_def.translate && 'translate_default' in col_def) {
						formatted_val = col_def.translate_default;
					} else if ((val === '' || val === undefined) && 'translate_undefined' in col_def) {
						formatted_val = col_def.translate_undefined;
					} else if (col_def.formatted) {
						formatted_val = CUI.formatter.doFormat(row_data, col_def.formatted);
					} else {
						formatted_val = val;
					}

					widget_element = { entity: 'span', text: formatted_val };
				}

			} else if (col_def && type == 'add') {
				if (col_def.add_element) {
					widget_element = col_def.add_element;
				}
				if (col_def.add_elements) {
					widget_elements = col_def.add_elements;
				}
			} else if (type == 'details_add' && self.options.details_row && (self.options.details_row.add_element || self.options.details_row.add_elements)) {
				if (self.options.details_row.add_element) {
					widget_element = self.options.details_row.add_element;
				}
				if (self.options.details_row.add_elements) {
					widget_elements = self.options.details_row.add_elements;
				}
			} else if ((type == 'details_edit' && self.options.details_row) || (type == 'details_add' && self.options.details_row && !(self.options.details_row.add_element || self.options.details_row.add_elements))) {
				if (self.options.details_row.edit_element) {
					widget_element = self.options.details_row.edit_element;
				}
				if (self.options.details_row.edit_elements) {
					widget_elements = self.options.details_row.edit_elements;
				}
			} else if (type == 'details_view' && self.options.details_row) {
				if (self.options.details_row.view_element) {
					widget_element = self.options.details_row.view_element;
				}
				if (self.options.details_row.view_elements) {
					widget_elements = self.options.details_row.view_elements;
				}
			} else if (type == 'row_actions') {
				if (self.options.row_actions && self.options.row_actions.action_element) {
					widget_element = self.options.row_actions.action_element;
				}
				if (self.options.row_actions && self.options.row_actions.action_elements) {
					widget_elements = self.options.row_actions.action_elements;
				}
			} else if (type == 'table_actions') {
				if (self.options.table_actions && self.options.table_actions.action_elements && self.options.table_actions.action_elements.actions) {
					widget_elements = self.options.table_actions.action_elements.actions;
				}
			}
			if (!widget_element && !widget_elements) {
				return undefined;
			}
			// If they have a widget_element and an array of elements, push the single element onto the end of the array
			if (widget_element && widget_elements && widget_elements.length) {
				widget_elements.push(widget_element);
			}
			// If only widget_element was specified, then put it into an array
			if (!widget_elements && widget_element) {
				widget_elements = [];
				widget_elements.push(widget_element);
			}

			$field = self.getEntitiesHTML(widget_elements, row_data);

			// Add the "sorting SPAN"...
			if (type !== 'row_actions' && type !== 'global_actions' && val !== undefined && val !== null) {
				$('<span style="display: none" />').text(val.toString()).prependTo($field);
			}

			return $field;
		},

		_editChangeCallback: function(elem, e) {
			var self = this, $self = this.element, $elem = $(elem), $dtw, new_values, value_widget, existing_values, v_key, is_dirty, $td, $tr, $action_row, row_num, $elems, $closest_widget, $invalids;

			if ($elem.hasClass('selected-checkbox')) { return; }

			// Make sure the element doesn't belong to an inner dataTable
			$dtw = $elem.closest('tr').closest('.dataTableWidget');
			if ( $dtw && $dtw[0] !== $self[0] ) { return; }

			// A parent widget may have thrown a formElementChange event, so be sure this is actually the element we're looking for...
			if (!$elem.is('.widgetValueWidget,:input') && e.type == 'formElementChange') {
				$elems = $elem.findNotUnder('.widgetValueWidget,:input', '.widgetType.widgetManagesOwnDescendentValue');
				$elems.trigger('change', { non_interactive: true });
				return;
			}

			$td = $elem.closest('td');
			$tr = $td.closest('tr');
			$action_row = $tr.next();

			if ($tr.hasClass('details_row')) {
				row_num = self.options.$oTable.fnGetPosition($tr.prev()[0]);
			} else {
				row_num = self.options.$oTable.fnGetPosition($tr[0]);
			}

			// This might be a component of a widget-- check to see if it is. If there's a value widget between $elem and $tr, then that is
			// actually what changed

			$closest_widget = $elem.closest('.widgetManagesOwnDescendentValue, tr');
			if (!$closest_widget.is($tr)) {
				$elem = $closest_widget;
			}

			value_widget = $elem.getCUIWidgetsWith('value_widget', undefined, { single: true });
			new_values = CUI.getWidgetElementValue($elem) || {};
			existing_values = self.getAllRowData(row_num) || {};

			// Compare values using isDirty to determine dirtiness
			if (value_widget) {
				for (v_key in new_values) {
					if (!new_values.hasOwnProperty(v_key)) { continue; }
					is_dirty = value_widget.isDirty(v_key, existing_values[v_key], new_values[v_key]);
					$elem.toggleClass('is-dirty', is_dirty);
					if (is_dirty) { break; }
				}
			} else {
				v_key = CUI.firstKey(new_values);
				$elem.toggleClass('is-dirty', !CUI.compareObjects(existing_values[v_key], new_values[v_key], { loose_compare_basics: true, null_equals_blank: true } ));
			}

			// Determine dirty state of the dataTable Widget using is-dirty
			self.computeDirtyState();

			// Determine if there are any invalid entries in the row being edited
			self.options.valid = true;

			$self.trigger('change'); // This is so that forms may be able to track the dirty-state of a row being edited

			$invalids = $tr.find('.is-invalid').filter('[name], [ident], [value-from], .valid-check').not('[disabled], .state-disabled, .state-enabled-false');

			if ($invalids.length) {

				$invalids.each(function() {
					var $this = $(this), loc, identifier_part, identifier, error, widget;

					/* jshint -W084 */ // -- Yes, these are assignments, not comparisons
					if (identifier_part = $this.attr('name')) {
						identifier = 'name-' + identifier_part;
					} else if (identifier_part = $this.attr('ident')) {
						identifier = 'ident-' + identifier_part;
					} else if (identifier_part = $this.attr('value-from')) {
						identifier = 'value-from-' + identifier_part;
					} else {
						widget = $this.getCUIWidget();
						if (widget && widget.options.widget_id) {
							identifier = 'widget-id-' + widget.options.widget_id;
						} else {
							debugLog('jquery.dataTableWidget.js: Attempted to add an error state on an object with no identifying properties: ', loc, ' -- ', $self);
						}
					}

					// This is an assignment, not a comparison
					if (error = $this.data('error')) {
						self.addMessage($this, 'error', identifier, error);
					}

					/* jshint +W084 */

				}); // END $invalids.each

				self.options.valid = false;
				self.options.$page_modules.addClass('state-error');
				self.options.$wrapper.addClass('is-invalid');
				$action_row.find('.edit-save, .add-save').disable().addClass('state-disabled');
			} else {
				self.options.$page_modules.removeClass('state-error');
				self.options.$wrapper.removeClass('is-invalid');
				$action_row.find('.edit-save, .add-save').enable().removeClass('state-disabled');
			}

			$tr.find('[name], [ident], [value-from], .valid-check').not($invalids).each(function() {
				var $this = $(this), loc, identifier, widget;

				loc = $this.attr('name') || $this.attr('ident') || $this.attr('value-from');
				if ($this.attr('name')) {
					identifier = 'name-' + loc;
				} else if ($this.attr('ident')) {
					identifier = 'ident-' + loc;
				} else if ($this.attr('value-from')) {
					identifier = 'value-from-' + loc;
				} else {
					widget = $this.getCUIWidget();
					if (widget && widget.options.widget_id) {
						identifier = 'widget-id-' + widget.options.widget_id;
					} else {
						debugLog('jquery.dataTableWidget.js: Attempted to remove an error state on an object with no identifying properties: ', $this, ' -- ', $self);
					}
				}

				self.delMessage('error', identifier);
			}); // END not($invalids).each

			$self.trigger('validation');
		},

		computeDirtyState: function() {
			var $self = this.element, self = this, $dirty_elements, $action_row;
			$action_row = $self.find('tr.action_row');
			self.options.dirty = false;

			if (self.addingRows() && self.options.enable_add_row) {
				self.options.dirty = true;
				$self.addClass('is-dirty');
				$action_row.find('.edit-save, .add-save').enable().removeClass('state-disabled');
				return;
			}

			// This is useful to let a non-dtw element indicate that a dtw is dirty if it exists. This is used, for instance, if there is an aopbContainer
			// state that has no config, but should be considered dirty and submittable nonetheless.
			if ($self.find('.always-dirty-indicator')[0]) {
				self.options.dirty = true;
				$self.addClass('is-dirty');
				$action_row.find('.edit-save, .add-save').enable().removeClass('state-disabled');
				return;
			}

			$dirty_elements = $self.find('[name].is-dirty, [ident].is-dirty, [value-from].is-dirty, .valid-check.is-dirty').not('[disabled], .state-disabled, .state-enabled-false');

			if ($dirty_elements.length) {
				self.options.dirty = true;
				$self.addClass('is-dirty');
				$action_row.find('.edit-save, .add-save').enable().removeClass('state-disabled');
				return;
			} else {
				self.options.dirty = false;
				$self.removeClass('is-dirty');
				$action_row.find('.edit-save, .add-save').disable().addClass('state-disabled');
				return;
			}
		},

		addMessage: function($target /* May not be passed as a jQuery object, but is converted to one immediately */, type, message_id, mesg, options) {
			var self = this, $self = this.element;
			self.options._custom_message_widgets = self.options._custom_message_widgets || {};
			return $.ui.formWidget.prototype.addMessage.apply(this, arguments);
		},

		delMessage: function(type, message_id) {
			var $self = this.element, self = this;
			self.options._custom_message_widgets = self.options._custom_message_widgets || {};
			return $.ui.formWidget.prototype.delMessage.apply(this, arguments);
		},

		clearMessages: function() {
			var self = this, $self = this.element, $bound_messages;
			$bound_messages = $self.find('.message').find('> *');
			$bound_messages.fadeOut(500);
			$bound_messages.slideUp(500, function () { $(this).remove(); });
		},

		_bindEditFields: function($tr) {
			var self = this, $self = this.element;
			if ($tr.hasClass('bound-edit')) { return; }

			// If this row has a sibling "details_row" style add form, include it in the fun too!
			$tr = $tr.add($tr.next('tr.details_row'));

			$tr.addClass('bound-edit');

			self._delegate($tr, 'input, textarea, .widgetValueWidget', 'keyup change click stateChange', CUI.FunctionFactory.build(self._editChangeCallback, self, { context: 'argument', first: 'context' }));
			self._delegate($tr, 'select', 'change stateChange', CUI.FunctionFactory.build(self._editChangeCallback, self, { context: 'argument', first: 'context' }));
			self._delegate($tr, 'div', 'variableContainerChange', CUI.FunctionFactory.build(self._editChangeCallback, self, { context: 'argument', first: 'context' }));
		},

		_editGetOptionsSource: function(url) {
			var self = this, $self = this.element, options = [];

			// TODO: Allow pulling of an array of data for populating select
			// list options

			return options;
		},

		_editGetEditableTDs: function($tr) {
			return $tr.children('td').not('.actions, .primary_key, .select_action, .move-up, .move-down, .drag-handles');
		},

		_submitOnChange: function(elem, e) {
			var self = this, $self = this.element, $tr, row_num;

			if (!self.options.ready) { return; }
			e.stopPropagation();

			$tr = $(elem).closest('tr');
			row_num = self.options.$oTable.fnGetPosition($tr[0]);

			self._saveRow($tr, row_num);
		},

		_setTdValue: function($td, td_val) {
			var self = this, $self = this.element, $field;

			$field = $td.find('.widgetValueWidget, :input').eq(0);
			if ($field[0]) {
				self.setValue(td_val, $field, true);
			}
		},

		_getElementValue: function ($elem) {
			var out;
			if ($elem.hasClass('widgetType')) {
				CUI.getWidgetElementValue($elem);
			} else {
				// Not a widget. Just get the value.
				out = [];
				out[CUI.getElementName($elem) || '_NO_NAME'] = CUI.getInputElementValue($elem);
				return out;
			}
		},

		// Was: _getAllValues
		_getTRValues: function($root, only_dirty) {
			var $self = this.element, self = this, $elems, values = {}, $el, el_idx, $tds, $closest_td, td_index, td_colname, el_value, v_key;

			if ($root.is('.widgetType.widgetValueWidget, :input')) {
				return CUI.getWidgetElementValue($root);
			}

			// Otherwise, this is a container...
			$elems = $root.find('.widgetType.widgetValueWidget, :input')
				.not($root.find('.selected-checkbox, .widgetManagesOwnDescendentValue :input, .widgetManagesOwnDescendentValue .widgetType, .dataTableWidget, .dataTableWidget :input '));

			if (only_dirty) {
				$elems = $elems.filter('.is-dirty, .always-submit');
			}

			elementLoop: for (el_idx = 0; el_idx < $elems.length; el_idx++) {
				$el = $elems.eq(el_idx);

				if ( $el.is(':input:not([name]):not(.widgetValueWidget)') ) {
					// If the INPUT control is not named, it may be defaulting to the name of the column. We assume this is so if there are no
					// named controls or value-bearing widgets in the closest TD (IF statement below). If this is the case, we need to look up
					// the index of the closest TD and use that to get the object key for the output object.

					if (!$tds) { $tds = self._editGetEditableTDs($root); }
					$closest_td = $el.closest('td');
					td_index = $tds.index($closest_td);

					// If -1, then this is a "utility" column, like a drag or checkbox column
					if (td_index === -1) { continue elementLoop; }

					td_colname = self.getColumnName(td_index);

					if (td_index > -1 && td_colname !== undefined && !$closest_td.find('.widgetType.widgetValueWidget,input[name]')[0]) {
						// If there are no other named elements in the indexed TD, then use this input as the value
						el_value = {};
						el_value[td_colname] = $el.val();
					} else {
						// This is not a value-bearing input and there are other value-bearing inputs or widgets in this TD, or this is not a
						// column that returns a value. Skip it.
						continue elementLoop;
					}
				} else {
					// The input control is named, or is a value-bearing widget. Process it accordingly.
					el_value = CUI.getWidgetElementValue($el);
				}

				// ... At this point, one way or another, el_value in an object with key-value pairs for $el. (Or we hit the CONTINUE above.) ...

				// Combine the el_value object with the row values object, making/extending arrays where necessary
				valueLoop: for (v_key in el_value) {
					if (!el_value.hasOwnProperty(v_key)) { continue valueLoop; }
					if (!(v_key in values) || values[v_key] === undefined) {
						values[v_key] = el_value[v_key];
					} else if ($.isArray(values[v_key])) {
						values[v_key].push(el_value[v_key]);
					} else {
						values[v_key] = [ values[v_key], el_value[v_key] ];
					}
				}
			}

			return values;
		},

		_processSaveParams: function (save_params) {
			var self = this, sp, sp_key;

			// Handle submit_empty_array_as value swapping if that param is not an empty array
			if (!$.isArray(self.options.submit_empty_array_as) || self.options.submit_empty_array_as.length) {
				sp = $.extend(true, {}, save_params);

				for (sp_key in sp) {
					if (!sp.hasOwnProperty(sp_key)) { continue; }
					if ($.isArray(sp[sp_key]) && !sp[sp_key].length) {
						sp[sp_key] = self.options.submit_empty_array_as;
					}
				}

				return sp;
			} else {
				return;
			}
		},

		_beforeParams: function (post_params, callback, success_callback, error_callback) {
			return false;
		},

		_doParams: function(post_params, success_callback, error_callback) {
			var self = this, end_callback = function(post_params, success_callback, error_callback) {
				var save_rest = function(params, succ_callback, err_callback) {
					self._performSaveREST(params, succ_callback, err_callback);
				};

				if(!self._beforeSaveREST(post_params, save_rest, success_callback, error_callback)) {
					save_rest(post_params, success_callback, error_callback);
				}
			};

			self._callEventHooks('saveRow', end_callback, post_params, success_callback, error_callback);
		},

		setOriginalRowData: function (row_num, row_data) {
			var ret = classCUI.prototype.dataTableClass.setOriginalRowData.apply(this, arguments);
			this._afterRowUpdate(row_num, row_data, true);
			return ret;
		},

		_afterRowUpdate: function(row_num, row_data, success) {
			var self = this, $self = this.element, row_widget_data, $tr, $tds, $td, td_idx, real_td_num, td_val, $field, $span, $widget, cd_idx, col_def, cn, $details_tr;

			if (success) {
				row_widget_data = self.getRowWidgetData(row_num);
				$tr = $(self.options.$oTable.fnGetNodes(row_num));

				if ($tr.hasClass('editable')) {
					self._updateEditableRow($tr, row_data);
					return;
				}

				if (self.options.sortable) {
					$tr.toggleClass('no-sort', !self._isRowSortable(row_num, row_data));
				}

				if (self.options.click_action) {
					$tr.toggleClass('no-click', !self._isRowClickable(row_num, row_data));
				}

				$tds = self._editGetEditableTDs($tr);

				$tr[0] = null;
				$tr.context = null;

				td_idx = 0;
				for (cd_idx=0; cd_idx < self.options.columns.length; cd_idx++) {
					col_def = self.options.columns[cd_idx];
					if (col_def.visible === false) { continue; } // <------ Continue on hidden columns

					$td = $tds.eq(td_idx);

					// Before we update what is displayed in the table, try to
					// get any view widgets for this cell
					cn = self.getColumnName(cd_idx);
					td_val = row_data[cn];
					// td_val = row_data[self.getColumnName(cd_idx)];

					// Try to update without rebuilding widgets first. If that fails, then rebuild widgets.
					$span = $td.find('span');
					$widget = $td.find('> .widgetType.widgetized');

					if ($span[0] && $widget[0]) {
						$span.eq(0).text(td_val);
						$widget.getCUIWidget().fillData(row_widget_data, false);
					} else {
						$field = self._getFieldHTML(cd_idx, td_val, 'view', row_widget_data);
						if ($field && $field[0]) {
							td_val = $field.html();
						}

						real_td_num = (self.options.col_offset || 0) + cd_idx;

						$td.children().remove(); // Force widget clean up to take place

						self.options.$oTable.fnUpdate(td_val || '', row_num, real_td_num, false);

						widgetize_children($td);
						self.fillDataChildren('widget-data', false, $td, row_widget_data);
					}
					self.options.$oTable.fnDraw(self.options.rest ? false : true);

					++td_idx; // Iterate td_idx only if the continue didn't happen

				}

				$td = null;
				$field = null;

				// Check if we have an open detail row
				if (self.options.click_action && (self.options.click_action == 'toggle_expand' || self.options.click_action == 'expand') &&
					!self.options.details_row.disable_update_on_row_update)
				{
					$tr = $(self.options.$oTable.fnGetNodes(row_num));
					if ($tr.hasClass('state-selected')) {
						$details_tr = $tr.nextAll().eq(0);
						// debugLog('Updating detail row: ', $details_tr);
						self.fillDataChildren(row_widget_data, false, $details_tr, row_widget_data);
					}
				}
			}

			$self = null;
			if (self.options.live_table || !self.options.rest) {
				self.sort();
			}
		},


		_editDeselectLast: function($cur_tr, suppress_update) {
			var self = this, $self = this.element, $prev_tr, row_num, $tds, _success, _failure;

			$prev_tr = $self.find('tr.state-selected').not($cur_tr || $()).not('.action_row, .details_row');

			// If this is NOT a.) a transition from one item to another, or b.) a Save action, then the following block is all that needs to be done:
			if (!$prev_tr[0]) {
				$self.trigger('deselected');
				$self.trigger('finished_deselected');
				return;
			}

			// This only continues during a transition or save event...

			row_num = parseInt(self.options.$oTable.fnGetPosition($prev_tr[0]));
			$tds = self._editGetEditableTDs($prev_tr);

			if (!suppress_update && self.options.clear_row_data_on_change && row_num !== undefined) {
				self.clearRowData(row_num);
			}

			///// START INTERNAL FUNCTIONS /////

			// If our REST request succeeds (assuming there is one), then we need to finish by making the row
			// not editable again. We also need to trigger anything that should occur after the row is not
			// editable anymore.
			_success = function(d) {
				self.purgeClearedRowData(row_num);
				$tds.removeClass('editable');
				self._setBeingEdited(row_num, false);
				self._setBeingAdded(row_num, false);

				self._setBeingEdited(row_num, false);
				self._setBeingAdded(row_num, false);
				self.deselectRow($prev_tr);
				$prev_tr.removeClass('editable');
				$self.trigger('change', { non_interactive: true });
				self.options.performing_click = false;
				self.options.check_row_change_interrupt = false;

				if (self.options.refresh_after_edit && !suppress_update) { self.refresh(); }
			};

			// If our REST request does not succeed, we need to prevent any change to a new row being selected / editable
			_failure = function(jxHQR, status, error, row_num, row_data) {
				self.unclearRowData(row_num);

				if ($cur_tr && $cur_tr[0]) {
					self.deselectRow($cur_tr);
					$cur_tr.removeClass('editable');
				}
				var error_data = jQuery.parseJSON(jxHQR.responseText);
				if (error_data) {
					error_data = error_data.error ? error_data.error : error_data;
					if ( typeof error_data === 'string') {
						new CUI.Dialog({ text: error_data, title: 'Error' });
					} else {
						self.addErrorDialog(error_data, 'Error');
					}
				} else if (loginData.demo && jxHQR.getResponseHeader('X-Reason') === 'Role Failed') {
					new CUI.Dialog({ text: 'This action is not allowed in Demonstration Mode.', title: 'Not Allowed' });
				} else {
					new CUI.Dialog({ text: 'An error occurred while attempting to save your changes.', title: 'Error' });
				}
				self.options.performing_click = false;
			};

			///// END INTERNAL FUNCTIONS /////

			if (suppress_update) {
				self.resetDirtyRow(row_num);
				_success();
			} else {
				// Do the submit
				self.submitRow(row_num, $prev_tr, _success, _failure);

				// Fire a change
				self._trigger('change', { non_interactive: true });
			}
		},

		// Was setRowData in cui.dataTableClass.js
		// Submit a row, given the row index
		submitRow: function (row_num, $tr, success_callback, error_callback) {
			var self = this, $self = this.element, being_added, being_edited, post_params, row_form_data, $details_row, remove_keys, rk_idx, callbacks = {};

			being_added = self.isBeingAdded(row_num);
			being_edited = self.isBeingEdited(row_num);

			post_params = {};

			if (!self.validRowNum(row_num)) {
				debugLog('cui.dataTableClass.js: Attempted to set data for row index "' + row_num + '" which is out of range or invalid in submitRow().', true);
				return false;
			}

			// Collect all applicable data for the row and put it in row_data so we can attempt to submit it
			// Start with the widgets and controls of the TR itself...

			row_form_data = self._getTRValues($tr, !(being_added || !(self.options.dirty_tracking && self.options.submit_only_dirty)));

			// Add in an associated details_row, if there is one...
			$details_row = $tr.next('tr.details_row');

			if ($details_row[0]) {
				row_form_data = CUI.combineParams(row_form_data, self._getTRValues($details_row, self.options.dirty_tracking && self.options.submit_only_dirty));
			}

			if (self.options.add_edit_action) {

				post_params = {};

				// "Base" data such as pagination, rest_params, included row data
				$.extend(post_params, self._getBaseParams(row_num, self.options.add_edit_action));
				$.extend(post_params, (self.options._selected_parent_data || {}).add_edit_action || {});  // Parent data via include_parent_data
				$.extend(post_params, row_form_data); // Data we've collected from the row form
				post_params = self._processSubmitParams(post_params);    // Remove UNDEFINED and NULLs, etc.

				self.options.add_edit_action.rest = self.options.add_edit_action.rest || self.options.rest;

				if (being_added) {
					// Remove filtered keys
					remove_keys = self.options.add_edit_action.filter_params_add || [];

					if (remove_keys.length) {
						for (rk_idx = 0; rk_idx < remove_keys.length; rk_idx++) {
							delete post_params[remove_keys[rk_idx]];
						}
					}
				}
			}

			callbacks.ok = self._saveSuccessCallback.bind(self, row_num, success_callback, being_added, being_edited);
			callbacks.error = self._saveErrorCallback.bind(self, row_num, row_form_data, error_callback);

			if (self.options.add_edit_action) {
				self._executeSaveREST(post_params, row_num, being_added ? 'POST' : undefined, callbacks.ok, callbacks.error);
			} else {
				self._saveSuccessCallback(row_num, success_callback, being_added, being_edited, row_form_data);
			}

			return true;
		},

		_getSubmissionData: function () {
			// Internal function

			var $self = this.element, self = this, out = {}, $trs, tr_idx, row_num, c, len, col_def, ds, dsd, ds_idx, dsi_k;

			if (self.options.return_selected) {
				$trs = $self.find('tr.selected-checked');

				if (self.options.return_selected === true) {
					out = [];

					for (tr_idx = 0; tr_idx < $trs.length; tr_idx++) {
						out.push(self.getRowWidgetData(self.options.$oTable.fnGetPosition($trs.eq(tr_idx))));
					}
				} else if (typeof self.options.return_selected === 'string') {
					// Get one from the current row(s)

					if (!(self.options.$oTable && $trs.length)) {
						// Fail if we are getting the value before the table has been created, or if nothing is selected
						return (self.options.select_single ? '' : []);
					}

					if (self.options.select_single) {
						row_num = self.options.$oTable.fnGetPosition($trs[0]);
						out[self.options.return_selected] = self.getRowWidgetData(row_num)[self.options.return_selected];
						return out;
					} else {
						out[self.options.return_selected] = [];

						for (tr_idx = 0; tr_idx < $trs.length; tr_idx++) {
							row_num = self.options.$oTable.fnGetPosition($trs[tr_idx]);
							out[self.options.return_selected].push( self.getRowWidgetData(row_num)[self.options.return_selected] );
						}

						return out;
						// Results in: { column_you_picked: [ 'array', 'of', 'values' ] }
					}
				} else if ($.isArray(self.options.return_selected)) {
					// TODO: Handle multiple columns
				}
			} else {
				// Return EVERYTHING!

				if (!self.options.data) { return []; }

				if (self.options.data_source === 'ROW_SOURCE') {
					for (c = 0, len = self.getColumnCount(); c < len; ++c) {
						col_def = self.getColumnDef(c);

						if (
							!self.options.filter_row_source_columns ||
							(
								self.options.filter_row_source_columns instanceof Array &&
								self.options.filter_row_source_columns.indexOf(col_def.row_source) == -1
							)
						) {
							out[col_def.row_source] = self.options.data[col_def.row_source];
						}
					}
				} else {
					// Convert an array of objects into an object of arrays...
					ds = self.options.data_source;
					dsd = self.options.data[ds];
					for (ds_idx=0; ds_idx < dsd.length; ds_idx++) {
						for (dsi_k in dsd[ds_idx]) {
							if (!dsd[ds_idx].hasOwnProperty(dsi_k)) { continue; }
							out[dsi_k] = out[dsi_k] || [];
							out[dsi_k].push(dsd[ds_idx][dsi_k]);
						}
					}
				}
			}

			return out;

		},

		_getWidgetValue: function() {
			var self = this, $self = this.element, value, out, name, k;

			if (self.options.suppress_form_value) { return {}; }

			value = self._getSubmissionData();
			// Returns: { name_you_picked: 'single' | [ 'array', 'of', 'values' ] }

			if (self.options.empty_array_value !== undefined) {
				for (k in value) {
					if ($.isArray(value[k]) && value[k].length === 0) {
						value[k] = self.options.empty_array_value;
					}
				}
			}

			name = CUI.getElementName($self);

			if (name && typeof self.options.return_selected === 'string') {
				out = {};
				out[name] = value[self.options.return_selected];
				return out;
			} else {
				return value;
			}
		},

		hasActionRow: function() {
			return !!(this.options.row_action_type == 'expand' && (this.options.row_actions || this.options.click_action == 'edit'));
		},

		hasDetailRow: function() {
			return !!this.options.details_row;
		},

		showActionRow: function($tr) {
			var self = this, $self = this.element, added_action = false, $actions, animate, speed, $opened_row;

			$actions = $('<div class="container" />');

			if (self.options.click_action == 'edit' && !self.addingRows()) {
				$actions.append(['<button type="button" class="edit-save">',
								 (self.options.row_actions && self.options.row_actions.edit_save && self.options.row_actions.edit_save.text ?
								  self.options.row_actions.edit_save.text : 'Save'), '</button>'].join(''));
				if (self.options.row_actions && self.options.row_actions.edit_cancel) {
					$actions.append(['<button type="button" class="edit-cancel">',
									 (self.options.row_actions.edit_cancel.text || 'Cancel'),'</button>'].join(''));
				}
				added_action = true;
			} else if (self.options.click_action == 'edit' || self.addingRows()) {
				$actions.append(['<button type="button" class="add-save state-disabled">',
								 (self.options.row_actions && self.options.row_actions.add_save && self.options.row_actions.add_save.text ?
								  self.options.row_actions.add_save.text : 'Add'), '</button>'].join(''));
				if (self.options.row_actions && self.options.row_actions.add_cancel) {
					$actions.append(['<button type="button" class="add-cancel">',
									 (self.options.row_actions.add_cancel.text || 'Cancel'),'</button>'].join(''));
				}
				added_action = true;
			}

			if (self.options.row_actions && !self.addingRows()) {
				if (self.options.row_actions.delete_row) {
					$actions.append(['<button type="button" class="delete-row">',(self.options.row_actions.delete_row.text || 'Delete'),'</button>'].join(''));
					added_action = true;
				}
				if (self.options.row_actions.action_elements) {
					$actions.append(self._getFieldHTML(-1, '', 'row_actions').contents());
					added_action = true;
				}
			}

			if (added_action) {
				animate = false;
				speed = 'slow';
				if (self.options.row_actions && self.options.row_actions.animate) {
					animate = true;
					if (self.options.row_actions.animate_speed) {
						speed = self.options.row_actions.animate_speed;
					}
				}
				$opened_row = self._openRow($tr, $('<div />').append($actions).contents(), "actions", animate, speed);
				$opened_row.addClass('action_row option-selectable state-selected cui-state-selected');
				self.options.action_row_open = true;
				widgetize_children($opened_row);
				self.fillDataChildren('widget-data', false, $opened_row);
				if (self.options.enable_add_row) {
					$opened_row.find('.add-save').removeClass('state-disabled');
				} else {
					$opened_row.find('.add-save').disable();
				}
			}
		},

		hideActionRow: function($tr) {
			var self = this, $self = this.element, animate = false, speed = 'slow';

			if (self.options.action_row_open) {
				if (self.options.row_actions.animate) {
					animate = true;
					if (self.options.row_actions.animate_speed) {
						speed = self.options.row_actions.animate_speed;
					}
				}
				self._closeRow($tr, animate, speed);
				self.options.action_row_open = false;
			}
		},

		showDetailRow: function($tr) {
			var self = this, $self = this.element, $field, animate, speed, row_obj, $new_tr;

			if (!self.options.details_row) {
				return;
			}

			// In toggle_expand rows, the details_row may already exist. Bail early if that is the case.
			if ($tr.next().hasClass('details_row')) {
				return;
			}

			var row_num = self.options.$oTable.fnGetPosition($tr[0]);

			var row_data = self.getRowWidgetData(row_num);
			for (var col in row_data) {
				if (!row_data[col]) {
					row_data[col] = '';
				}
			}

			if (self.addingRows()) {
				$field = self._getFieldHTML(-1, '', 'details_add', row_data);

				if (self.options.table_actions && self.options.table_actions.add_row) {
					if (self.options.table_actions.add_row['class']) {
						$tr.addClass(self.options.table_actions.add_row['class']);
					}
					if ((self.options.details_row.add_element || self.options.details_row.add_elements) && !self.options.table_actions.add_row.show_with_details) {
						$tr.addClass('hide-row');
					}
				}

			} else if (self.options.click_action == 'edit') {
				$field = self._getFieldHTML(-1, '', 'details_edit', row_data);
			} else {
				$field = self._getFieldHTML(-1, '', 'details_view', row_data);
			}
			if ($field && $field[0]) {
				self.options.$details_template = $('<div class="details_container" />').append($field.contents());
			}

			if (self.options.$details_template) {
				animate = false;
				speed = 'slow';
				if (self.options.details_row.animate) {
					animate = true;
					if (self.options.details_row.animate_speed) {
						speed = self.options.details_row.animate_speed;
					}
				}
				row_obj = {};
				row_obj.options = {};
				$.extend(row_obj.options, { data: row_data });
				$new_tr = self._openRow($tr, $('<div />').append(self.options.$details_template).contents(), "", animate, speed);
				if (self.options.no_highlight_details_row) {
					$new_tr.addClass('details_row');
				} else {
					$new_tr.addClass('details_row option-selectable state-selected cui-state-selected');
				}

				widgetize_children($new_tr, row_data);
				//debugLog("Row data: ", row_data);
				self.fillDataChildren('widget-data', false, $new_tr, row_data);
			} else {
				debugLog('jquery.dataTableWidget.js: No details row fields or templates specified. -- ', $self);
			}
		},

		hideDetailRow: function($tr) {
			var self = this, $self = this.element;

			if (!self.options.details_row || !$tr.next().hasClass('details_row')) {
				return;
			}

			if (self.options.table_actions && self.options.table_actions.add_row) {
				if (self.options.table_actions.add_row['class']) {
					$tr.removeClass(self.options.table_actions.add_row['class']);
				}
				if (!self.options.table_actions.add_row.show_with_details) {
					$tr.removeClass('hide-row');
				}
			}
			var animate = false, speed = 'slow';
			if (self.options.details_row.animate) {
				animate = true;
				if (self.options.details_row.animate_speed) {
					speed = self.options.details_row.animate_speed;
				}
			}
			self._closeRow($tr, animate, speed);
		},

		selectRow: function($tr, suppress_uri_param_update) {
			var self = this, $self = this.element;

			if ($tr.hasClass('state-selected')) {
				return $tr;
			}

			$tr.addClass('option-selectable state-selected cui-state-selected');
			self._openSelected($tr);

			if (self.options.invalid_while_row_open) {
				// Invalidate the DTW itself while editing and remove this after we're done (for DTWs inside other DTW rows)
				// Mark this dtw as invalid while a row is open
				self.options.$wrapper.addClass('is-invalid valid-check');
			}

			// Check dirty state right away to prevent save buttons being enabled as soon as edit-mode is entered, as seen in BNPH-9110
			self.computeDirtyState();

			if (!suppress_uri_param_update && self.options.row_key && $self.attr('uri_id') && self.hasColumn(self.options.row_key)) {
				if (self.options.click_action == 'toggle_expand' || self.options.click_action == 'expand') {
					if (!self.options.uri_params) {
						self._initURIParams();
					}

					if (!self.options.uri_params.expansion_values) {
						self.options.uri_params.expansion_values = [];
					}

					var row_val = self.getRowWidgetData(self.options.$oTable.fnGetPosition($tr[0]))[self.options.row_key];
					self.options.uri_params.expansion_values.push(row_val);

					if (self.options.parent && self.options.parent._storeURIParams) {
						self.options.parent._storeURIParams($self, self.options.uri_params);
					}
				}
			}

			return $tr;
		},

		deselectRow: function($tr, suppress_uri_param_update, suppress_view_mode) {
			var self = this, $self = this.element, row_num, adding_row, row_data, row_val, expansion_idx;
			row_num = self.options.$oTable.fnGetPosition($tr[0]);
			adding_row = self.isBeingAdded(row_num);

			$tr = $($tr);

			if (!$tr.hasClass('state-selected')) {
				return $tr;
			}


			if (adding_row) {
				// This should only be true in cases where the row has been deselected abruptly, such as switching screens.
				self._deleteDataTableRow(row_num, self._deleteSuccess.bind(self), self._deleteError.bind(self), $tr);
				return $tr;
			}

			if (self.options.invalid_while_row_open) {
				// Invalidate the DTW itself while editing and remove this after we're done (for DTWs inside other DTW rows)
				self.options.$wrapper.removeClass('is-invalid valid-check');
			}

			self._closeSelected($tr);
			$tr.removeClass('option-selectable state-selected cui-state-selected');

			if (!suppress_view_mode) {
				self._viewMode($tr);
			}

			if (!suppress_uri_param_update && self.options.row_key && $self.attr('uri_id') && self.hasColumn(self.options.row_key)) {
				if (self.options.click_action == 'toggle_expand' || self.options.click_action == 'expand') {
					if (self.options.uri_params && self.options.uri_params.expansion_values) {
						row_data = self.getRowWidgetData(row_num);
						row_val = row_data[self.options.row_key];
						expansion_idx = self.options.uri_params.expansion_values.indexOf(row_val);

						self.options.uri_params.expansion_values.splice(expansion_idx, 1);

						if (self.options.parent && self.options.parent._storeURIParams) {
							self.options.parent._storeURIParams($self, self.options.uri_params);
						}
					}
				}
			}

			$self.trigger('deselected');
			$self.trigger('finished_deselected');
			return $tr;
		},

		_openSelected: function($tr) {
			var self = this, $self = this.element, location = 'bottom';

			if(!$tr || !($tr instanceof jQuery)) {
				return;
			}

			if (self.options.row_actions && self.options.row_actions.location) {
				location = self.options.row_actions.location;
			}
			if (self.hasActionRow() && (location == 'bottom' || location == 'both')) {
				self.showActionRow($tr);
			}
			if (self.hasDetailRow()) {
				self.showDetailRow($tr);
				self._bindEditFields($tr.next('.details_row'));
			}
			if (self.hasDetailRow() && self.hasActionRow() && (location == 'top' || location == 'both')) {
				self.showActionRow($tr);
			}
		},

		_closeSelected: function($tr) {
			var self = this, $self = this.element, location;

			if(!$tr || !($tr instanceof jQuery)) {
				return;
			}

			location = 'bottom';
			if (self.options.row_actions && self.options.row_actions.location) {
				location = self.options.row_actions.location;
			}
			if (self.hasDetailRow() && self.hasActionRow() && (location == 'top' || location == 'both')) {
				self.hideActionRow($tr);
			}
			if (self.hasDetailRow()) {
				self.hideDetailRow($tr);
			}
			if (self.hasActionRow() && (location == 'bottom' || location == 'both')) {
				self.hideActionRow($tr);
			}
		},

		_openRow: function ($tr, $contents, css_class, animate, animate_speed) {
			var self = this, $self = this.element, speed, colspan, $new_tr, $container;

			speed = (animate_speed ? animate_speed : 'slow');
			colspan = self.options.$oTable.find('tr:first').find('td').length;
			$new_tr = $(['<tr><td ', (css_class ? ['class="', css_class, '" '].join('') : ''), 'colspan="', colspan, '">', (animate ? '<div class="animate_container"></div>' : ''), '</td></tr>'].join(''));
			$container = $new_tr.find('.animate_container');

			if ($container[0]) {
				$container.append($contents);
				$container.show();
				$container.css({'height': '200px'});
			} else {
				$new_tr.find('td').append($contents);
			}

			$new_tr.insertAfter($tr);
			self.options.$oTable.fnAddOpenRow($tr, $new_tr);
			$new_tr.trigger('RowOpened');

			if (animate) {
				$container.slideDown(speed, function() {
					$new_tr.trigger('RowOpenAnimateFinished');
				});
			} else {
				$new_tr.trigger('RowOpenAnimateFinished');
			}

			// fix for BNPH-9554

			if( self.options.paginate && (self.options.data.count >= self.options.data.rows) ) {
				self.setPageSize(self.getPageSize() + 1);
				self.options._additional_rows = (self.options._additional_rows ? self.options._additional_rows + 1 : 1);
			}

			return $new_tr;
		},

		_closeRow: function ($tr, animate, animate_speed) {
			var $self = this.element;
			var self = this;

			var speed = (animate_speed ? animate_speed : 'slow');
			var $close_tr = $tr.nextAll().eq(0);
			var remove_tr = function () {
				$close_tr.children().children().remove();
				self.options.$oTable.fnRemoveOpenRow($tr);
				$close_tr.remove();
				$self.trigger('RowClosed');
			};

			if (animate) {
				var $container = $close_tr.find('.animate_container');
				$container.slideUp(speed, remove_tr);
			} else {
				remove_tr();
			}
		},

		_variableShowOverlay: function($tr) {
			var self = this, $self = this.element, va, row_num, row_data, overlay_info;

			if (!self.options.variable_overlay) {
				debugLog('Missing variable_overlay option definition for variable_overlay click action.');
				return;
			}

			va = self.options.variable_overlay;

			if (!va.column) {
				debugLog('Missing column definition in variable_overlay definition for variable_overlay click action.');
				return;
			}

			if (!self.hasColumn(va.column)) {
				debugLog('Referenced column for variable_overlay click action does not exist in table.');
				return;
			}
			row_num = self.options.$oTable.fnGetPosition($tr[0]);
			row_data = self.getRowWidgetData(row_num);

			if (!va[row_data[va.column]]) {
				debugLog('Variable overlay definition does not contain an overlay information definition for the case where "' + va.column + ' = ' + row_data[va.column] + '".');
				return;
			}

			overlay_info = va[row_data[va.column]];

			if (overlay_info.overlay_name) {
				self.options.overlay_name = overlay_info.overlay_name;
			}
			if (overlay_info.overlay_params) {
				self.options.overlay_params = overlay_info.overlay_params;
			}
			if (overlay_info.overlay_widget_params) {
				self.options.overlay_widget_params = overlay_info.overlay_widget_params;
			}
			if (overlay_info.overlay_data) {
				if (!self.options.overlay_data) {
					self.options.overlay_data = {};
				}
				$.extend(self.options.overlay_data, overlay_info.overlay_params);
			}
			self._showOverlay($tr);
		},

		_showOverlay: function($tr) {
			var self = this, $self = this.element, pageWidget, params, widget_params, $previous_selected, $selected_arr;

			pageWidget = self.options.pageWidget;

			self._buildRowParams($tr);
			params = $tr.data('params');
			widget_params = $tr.data('widget_params');
			if (!params) {
				debugLog('dataTableWidget::_showOverlay(): Something went horribly wrong.');
				return;
			}

			// We need to figure out what direction to tell the pageWidget to use for doing the overlay animation. So, we get
			// a set of selected rows and we figure out whether the one we clicked on is above or below the previously selected
			// row. If it is below, we slide up to simulate scrolling down. If it is above, we slide down to simulate scrolling
			// up.
			$previous_selected = $tr.closest('table').find('tr.state-selected').not('.action_row, .details_row');
			self.selectRow($tr);
			$tr.addClass('current');
			if ($previous_selected.hasClass('state-selected')) {
				// Something was previously selected, so try to figure out whether it is up or down in the list
				$selected_arr = $tr.closest('table').find('tr.state-selected').not('.action_row, .details_row');

				if ($selected_arr.eq(1).hasClass('current')) {
					if (pageWidget.showOverlay(self.options.overlay_name, params, widget_params, $tr, true)) {
						self.deselectRow($selected_arr.eq(0));
						$selected_arr.eq(1).removeClass('current');
					} else {
						self.deselectRow($selected_arr.eq(1)).removeClass('current');
					}
				} else {
					if (pageWidget.showOverlay(self.options.overlay_name, params, widget_params, $tr, false)) {
						self.deselectRow($selected_arr.eq(1));
						$selected_arr.eq(0).removeClass('current');
					} else {
						self.deselectRow($selected_arr.eq(0)).removeClass('current');
					}
				}
			} else {
				if (!pageWidget.showOverlay(self.options.overlay_name, params, widget_params, $tr, false)) {
					self.deselectRow($tr).removeClass('current');
				}
			}
			$tr.removeClass('current');
		},

		_reportBackendError: function (error, packet) {
			var self = this, do_what, dialog;

			if (self.options._reported_backend_error) { return; }
			self.options._reported_backend_error = true;

			if (checkPermissions(['ADMIN'])) {
				do_what = 'contact Barracuda Networks Support for assistance in resolving this issue.';
			} else {
				do_what = 'contact your system administrator regarding this issue.';
			}

			dialog = new CUI.Dialog({
				important: true,
				title: 'Error Connecting to Live Data Source',
				text: 'There was an unrecoverable error connecting to a live data source. If this problem persists, ' + do_what,
				blanker: true,
				prevent_navigation: true,
				buttons: ['Log Out', 'Reload Screen', 'OK'],
				callbacks: [
					function () {
						$.postREST('/gui/login/logout', {}, {
							complete: function () { window.location.reload(); }
						});
					},
					function () { window.location.reload(); },
					function () { dialog.remove(); }
				],
				flyby: false
			});

			dialog.flyby('Error "' + error + '" in packet ' + (packet.ident || '(No ID)') + ' of table "' + self.options.live_table + '".');
		},

		_buildExtensionFilter: function () {
			var self = this, $self = this.element, $wrap, $type, $text, $ext_filter;
			$wrap = $('<div class="data-table-widget-custom-filter group-filter"></div>');
			$wrap.text(self.options.filter_title || 'Filter: ');

			$type = $('<select><option value="TEXT">Text Search</option><option value="GROUP">By Group</option></select>').appendTo($wrap);
			$type.find('option:eq(1)').text(self.options.extension_filter_options.type_text);

			$text = $('<input type="text" class="search" placeholder="Filter Results" />').appendTo($wrap);
			$ext_filter = $('<div class="extensionPickerSelect flyoutSearchSelectWidget dtw-filter-extension-widget" />').flyoutSearchSelectWidget({
				search_rest: '/gui/extension/list',
				lookup_search_rest: '/gui/extension/extension',
				lookup_search_rest_container: 'extension',
				nothing_text: self.options.extension_filter_options.nothing_text,
				render_row: {
					entity: 'div',
					widgets: ['extensionRowWidget']
				},
				value_key: self.options.extension_filter_options.value_key,
				search_rest_params: {
					type: self.options.extension_filter_options.type,
					primary: self.options.extension_filter_options.filter ? 1 : 0
				}
			});

			$ext_filter.appendTo($wrap);

			self._bind($type, 'change', function (e,d) {
				var text_mode = (this.value === 'TEXT');
				$text.toggle(text_mode);
				$ext_filter.toggle(!text_mode);
				self.options._filter_mode = text_mode ? 'text' : 'extension';

				// if (!d.initial) {
				$text.val('');
				$ext_filter.getCUIWidget('flyoutSearchSelectWidget').setValue('');

				// TODO: Swap this with the filter change callback, once the filter change callback is pulled out
				self.filter('');
				$self.triggerHandler("filterChange");
				// }
			});

			$type.triggerHandler('change');

			return $wrap;
		},

		// Override these in child widgets -- return true if the row with the given attributes can be done-to
		_isRowSortable: function (row_num, row_data) { return true; },
		_isRowClickable: function (row_num, row_data) { return true; },
		_isRowSelectable: function (row_data) {
			return true;
		}
	});

	add_widget('dataTableWidget', dataTableWidget);

})(jQuery);
