/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){
	var runReportButtonWidget = $.extend({}, $.ui.pageButtonWidget.prototype, {
		options: {
			overlay: 'report_view',
			pass_params: [ 'bbx_reporting_id' ]
		},	

		_afterFormChange: function () {
			var self = this, $self = this.element;

			if (self.options.dirty) {
				self.disable('afc');
			} else {
				self.enable('afc');
			}
		},

		_afterInit: function() {
			var self = this, $self = this.element;
			$self.addClass('overlayButtonWidgetType');
			self._bind($self, 'click', self._clickHandler.bind(self));
		},

		_clickHandler: function(e) {
			var self = this, $self = this.element, params = self.options.params || {}, $scr, scr_w, pp_idx;

			if (self.options.pass_params) {
				$scr = $self.closest('.overlay,.pageWidgetType');
				scr_w = $scr.getCUIWidget('pageWidget') || $scr.getCUIWidget();
				if (scr_w.options.rest_params) {
					for (pp_idx = 0; pp_idx < self.options.pass_params.length; pp_idx++) {
						if (self.options.pass_params[pp_idx] in scr_w.options.rest_params) {
							params[self.options.pass_params[pp_idx]] = params[self.options.pass_params[pp_idx]] || scr_w.options.rest_params[self.options.pass_params[pp_idx]];
						}
					}
				}
			}

			this.element.closest('.pageWidgetType').getCUIWidget().showOverlay(this.options.overlay, params, {}, this.element);
		}

	});

	add_widget('runReportButtonWidget', runReportButtonWidget);
})(jQuery);
