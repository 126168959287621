/* jshint jquery: true, unused: vars */
/* global add_widget */
(function( $ ){
	var epochDateWidget = $.extend({}, $.ui.widget.prototype, {
		set_value_widget: true,

		options: {
			starting_value: 0,
			invalid_date_error: '(Not a valid date)',
			format: '%L',
			// Example: "%Ww %Y-%M-%D %h:%0m:%0s %A"
			allow_html: false
		},

		_getAMPM: function (date) {
			// Just yank any letters off the locale string. If there are none, the locale is 24h clock, and return an empty string.
			var ampm_match = date.toLocaleTimeString().match(/[a-zA-Z]+$/);
			return ampm_match ? ampm_match[0] : '';
		},

		_getDayWord: function (date) {
			// i18n these words, once we get the framework in to do so.
			var days = [
				'Sunday',
				'Monday',
				'Tuesday',
				'Wednesday',
				'Thursday',
				'Friday',
				'Saturday'
			];

			return days[date.getDay()];
		},

		_getDayWordShort: function (date) {
			// i18n these words, once we get the framework in to do so.
			var days = [
				'Sun',
				'Mon',
				'Tue',
				'Wed',
				'Thu',
				'Fri',
				'Sat'
			];

			return days[date.getDay()];
		},

		_getMonthWord: function (date) {
			// i18n these words, once we get the framework in to do so.
			var months = [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December'
			];

			return months[date.getMonth()];
		},

		_getMonthWordShort: function (date) {
			// i18n these words, once we get the framework in to do so.
			var months = [
				'Jan',
				'Feb',
				'Mar',
				'Apr',
				'May',
				'Jun',
				'Jul',
				'Aug',
				'Sep',
				'Oct',
				'Nov',
				'Dec'
			];

			return months[date.getMonth()];
		},

		_zero_pad: function (str, len) {
			len = len || 2;
			str = str.toString();
			var strlen = str.length;
			if (strlen >= len) {
				return str;
			} else {
				return new Array(len - strlen + 1).join('0') + str;
			}
		},

		_formatDate: function (date) {
			var self = this;

			if (self.options.format === '%L') {
				// This is the default-- don't waste cycles on parsing it
				return date.toLocaleString();
			} else {
				var formatted = self.options.format;
				var parts = {
					// Prefixing "0" on most numeric values (ex: %0m for minutes) will render the value padded to two places, with a leading "0"
					// Prefixing "W" for weekdays and months will give the word, "S" will give the short (abbreviated) word (ex: %Ww or %Sw for weekday)

					// %L - Locale-dependent full string value
					'L': date.toLocaleString(),

					// %d / %0d - Day of the month (1-31)
					'D': date.getDate(),

					// %w / %0w - Weekday (1-7)
					'w': date.getDay() + 1,

					// %Ww / %Sw - Weekday (Sunday-Saturday / Sun-Sat)
					'Ww': self._getDayWord(date),
					'Sw': self._getDayWordShort(date),

					// %M / %0M - Month (1-12)
					'M': date.getMonth() + 1,

					// %WM / %SM - Month (January-December / Jan-Dec)
					'WM': self._getMonthWord(date),
					'SM': self._getMonthWordShort(date),

					// %y - 4-digit year
					'Y': date.getFullYear(),

					// %h / %0h - Hour (locale-dependent 12/24 hour, either 1-12 or 0-23)
					'h': date.toLocaleTimeString().replace(/^\D*/, '').match(/^\d+/)[0],

					// %H / %0H - Hour (24-hour zero-indexed 0-23)
					'H': date.getHours(),

					// %a - AM/PM (Locale-dependent 'am', 'pm', or '')
					'a': self._getAMPM(date).toLowerCase(),

					// %A - AM/PM (Locale-dependent 'AM', 'PM', or '')
					'A': self._getAMPM(date).toUpperCase(),

					// %m / %0m - Minutes (0-59)
					'm': date.getMinutes(),

					// %s / %0s - Seconds
					's': date.getSeconds()
				};

				for (var k in parts) {
					var has_matches = formatted.indexOf('%' + k);
					var has_pad_matches = formatted.indexOf('%0' + k);
					var repl = parts[k];

					if (has_matches > -1) {
						formatted = formatted.replace(new RegExp('%' + k, 'g'), repl);
					}

					if (has_pad_matches > -1) {
						formatted = formatted.replace(new RegExp('%0' + k, 'g'), self._zero_pad(repl, 2));
					}

				}

				return formatted;
			}
		},

		setValue: function(val, name) {
			var $self = this.element;
			var self = this;
			var str_out;
			var unix_epoch = parseInt(val, 10);

			if (unix_epoch) {
				if (isNaN(unix_epoch)) {
					str_out = self.options.invalid_date_error || '';
				} else {
					var date = new Date(unix_epoch * 1000);
					str_out = self._formatDate(date);
				}
			}

			if ($self.is(':input')) {
				$self.val(str_out);
			} else if (self.allow_html) {
				$self.html(str_out);
			} else {
				$self.text(str_out);
			}
		}

	});

	add_widget('epochDateWidget', epochDateWidget);

})(jQuery);
