/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){
	var soundMusicClassWidget = $.extend(true, {}, $.ui.dropTargetWidget.prototype, {
		options: {
			selector: undefined,              // The selector for the dataTableWidget we are binding to
			id: 0,                            // The primary key id of this class
			change_class_on_move: false       // Should dropping a file on this class change the selected class to this one?
		},

		_beforeInit: function() {
			var self = this, $self = self.element, $page;

			// Bind click event to our click handler
			self._bind($self.closest('td'), 'click', self._clickHandler.bind(self));

			self.options._$parent_widget = $self.closest('.soundMusicClassListWidget');
			self.options._parent_widget = self.options._$parent_widget.getCUIWidget('soundMusicClassListWidget');

			if (self.options._parent_widget) {
				self.options.key_name = self.options._parent_widget.options.key_name;
				self.options.file_key_name = self.options._parent_widget.options.file_key_name;
				self.options.class_name_key = self.options._parent_widget.options.class_name_key;
				self.options.system_flag = self.options._parent_widget.options.system_flag;
				self.options.rest_path = self.options._parent_widget.options.rest_path;
			}

			$page = $self.closest('.pageWidgetType');

			if (!$page.hasClass('screenReady')) {
				self._one($page, 'screenReady', self._screenReadyHandler.bind(self));
			} else {
				self._screenReadyHandler();
			}
		},

		_thisClassSelected: function() {
			var self = this, $self = self.element;

			if (self.options._parent_widget) {
				return self.options._parent_widget.options.selected_id == self.options.id;
			}
		},

		_dataFill: function() {
			var self = this, $self = self.element;
			$.ui.widget.prototype._dataFill.apply(self, arguments);

			if (self.options._parent_widget) {
				self.options.id = self.options.widget_data[self.options._parent_widget.options.key_name];

				if (self._thisClassSelected() ) {
					self._checkIfSystemClass();
					self._setCSSClass();
					self.options._parent_widget.setLegendText(self.getName());
				}

				if (typeof self.options._parent_widget.options.selected_id === 'undefined') {
					self.options._parent_widget.changeClass(self);
					self._setCSSClass();
				}
			}
		},

		getName: function() {
			var self = this;

			return self.options.widget_data[self.options.class_name_key];
		},

		_screenReadyHandler: function () {
			var self = this, $self = this.element;	    
		},

		_clickHandler: function(event, ui) {
			var self = this, $self = self.element;
			self._changeClass();
		},

		_setCSSClass: function(edit) {
			var self = this, $self = self.element;

			$('.sound_music_class_selected').removeClass('sound_music_class_selected state-selected');
			$self.closest('tr').addClass('sound_music_class_selected');
			$self.closest('tr').addClass('state-selected');
		},

		_changeClass: function() {
			var self = this, $self = self.element;

			self.options._parent_widget.changeClass(self);

			self._checkIfSystemClass();
			self._setCSSClass();
		},

		_checkIfSystemClass: function() {
			var self = this, $self = self.element;

			if (self.options.widget_data[self.options.system_flag]) {
				self.options._parent_widget.disableDTWButtons();
			} else {
				self.options._parent_widget.enableDTWButtons();
			}
		},

		_drop: function(event, ui) {
			var self = this, $self = self.element, mover = ui.draggable.getCUIWidget('moverWidget');
			var player = ui.draggable.closest('tr').find('.soundPlayerWidget');
			player.getCUIWidget('soundPlayerWidget')._destroy();
			self._moveFile(mover.options.widget_data[self.options.file_key_name], self.options.widget_data[self.options.key_name]);
		},

		_moveFile: function(file_id, class_id) {
			var self = this, $self = self.element, options = {};

			options[self.options.key_name] = class_id;
			options[self.options.file_key_name] = file_id;

			CUI.doREST('PUT', self.options.rest_path, options, self._moveSuccess.bind(self));
		},

		_moveSuccess: function(response, status, jqXHR) {
			var self = this, $self = self.element;
			if (self.options.change_class_on_move) {
				self._changeClass();
			} else {
				self.options._parent_widget.options._target_widget.refresh();
			}
		}
	});

	add_widget('soundMusicClassWidget', soundMusicClassWidget);
})(jQuery);
