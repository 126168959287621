(function () {
	register_screen('system_settings', {
		elements: [
			{ entity_template: 'page_title', title: 'System' },
			{ entity_template: 'page_buttons', buttons: [ { type: 'cancel' }, { type: 'save' }] },
			{
				entity_template: 'page_content', elements: [
					{
						entity_template: 'page_module',
						legend: 'System Mode',
						form_action: '/gui/mode/active',
						buttons: ['help'],
						help_id: 'modes_current',
						requires: ['SWITCH_MODES'],
						elements: [
							{
								entity_template: 'data_item',
								title: 'System Mode:',
								type: 'select',
								elements: [
								{
									entity_template: 'select_populate',
									name: 'bbx_mode_id',
									rest: '/gui/mode/enabled_modes',
									text_field: 'bbx_mode_name',
									value_field: 'bbx_mode_id',
									attributes: { 'class': 'manual-mode-set' }
							}
						]
					}
				] // END page module elements
			}, // END page module
					{
						entity_template: 'form',
						action: '/gui/system/system',
						submit_all: false,
						requires: ['VIEW_SYSTEM_SETTINGS'],
						confirm_if: {
							timezone: {
								title: 'Confirm Change',
								text:  'Changing the system time zone may result in a momentary lapse in call processing or a few seconds of instability. Do you wish to continue?'
							}
						},
						elements: [
							{
								entity_template: 'page_module',
								legend: 'Time Zone',
								buttons: ['help'],
								help_id: 'configuration_system_time_zone',

								elements: [
									{
										entity_template: 'input_item',
										type: 'select',
										input_name: 'timezone',
										title: 'Time Zone:',
										input_options: {
											widgets: ['selectPopulateWidget'],
											widget_options: {
												rest: '/gui/system/timezones'
											}
										}
									},
									{
										entity_template: 'data_item',
										title: 'Current Server Date/Time:',
										elements: [{
											entity_template: 'time_check'
										}]
									}
								] // END page module elements
							}, // END page module: Time zone
							{
								entity_template: 'aopb_container',
								private_fill_data: true,
								rest: '/gui/system/languages',
								rest_container: false,

								// Sound prompt language selector-- only show this when there is more than one language available
								states: [{
									conditions: { a: '@languages', op: '==', b: '_ARRAY_HAS_MULTIPLE_ELEMENTS_' },
									elements: [{
										entity_template: 'page_module',
										legend: 'Sound Prompt Language',
										buttons: ['help'],
										help_id: 'configuration_system_language',
										elements: [{
											entity_template: 'input_item',
											type: 'select',
											title: 'Sound Prompt Language:',
											input_name: 'language',
											input_options: {
												widgets: ['selectPopulateWidget'],
												widget_options: {
													selectPopulateWidget: {
														rest: '/gui/system/languages',
														text_field: 'name',
														value_field: 'value',
														default_first: true
													}
												}
											}
										}]
									}]
								}]
							}, // END aopb'd page module: Language
							{
								entity_template: 'page_module',
								legend: 'Network Time Protocol (NTP) Server',
								buttons: ['help'],
								help_id: 'configuration_system_ntp',

								elements: [
									{
										entity_template: 'input_item',
										type: 'text',
										input_name: 'ntp',
										title: 'NTP Server Address:',
										info: 'You may provide multiple server addresses separated by commas. Leave this value blank to use the default NTP server.'
									}
								] // END page module elements
							}, // END page module: NTP
							{
								entity_template: 'page_module',
								legend: 'Syslog Server',
								buttons: ['help'],
								help_id: 'configuration_system_syslog',

								elements: [
									{
										entity_template: 'input_item',
										input_name: 'syslog_server',
										title: 'Syslog Server IP Address:',
										info: 'You may provide multiple server IP addresses or hostnames separated by commas.'
									}
								] // END page module elements
							}, // END page module: Syslog Server
							{
								entity_template: 'page_module',
								legend: 'Administrator Account',
								buttons: ['help'],
								help_id: 'configuration_system_admin_pass',

								elements: [

									{
										entity_template: 'input_item',
										type: 'password',
										input_name: 'old_password',
										title: 'Current Password:',
										input_options: { widgets: ['submitOnlyWidget'] }
									},
									{
										entity_template: 'input_item',
										type: 'password',
										input_name: 'new_password',
										title: 'New Password:',
										input_options: { widgets: ['submitOnlyWidget'] }
									},
									{
										entity_template: 'input_item',
										type: 'password',
										input_name: 'new_password_confirm',
										title: 'Confirm New Password:',
										input_options: {
											widgets: ['aopbStateWidget','submitOnlyWidget'],
											widget_options: { aopbStateWidget: {
												closest: '.pageModuleWidget',
												states: {
													conditions: { a:'$$', op:'==', b:'$new_password' },
													actions: { invalid: false },
													else_actions: { invalid: 'Password and confirmation fields do not match.' }
												}
											}}
										}
									}

								] // END page module elements
							},
							{
								entity_template: 'page_module',
								legend: 'CNAM Lookup',
								buttons: ['help'],
								help_id: 'configuration_system_cnam',
								elements: [
									{
										entity_template: 'input_item',
										title: 'Enable CNAM Lookup:',
										type: 'checkbox',
										input_name: 'enable_cnam'
									},
									{
										entity_template: 'input_item',
										type: 'text',
										input_name: 'cnam_lookup_url',
										title: 'Lookup URL:',
										input_options: {
											attributes: { size: '40' },
											widgets: ['aopbStateWidget'],
											widget_options: {
												aopbStateWidget: {
													closest: '.pageModuleWidget',
													states: {
														conditions: { a:'$enable_cnam', op:'==', b:'_TRUEISH_' },
														actions: { enabled: true },
														else_actions: { enabled: false }
													}
												}
											}
										}
									}
								]
							},
							{
								entity_template: 'page_module',
								legend: 'Security Definition Updates',
								buttons: ['help'],
								help_id: 'configuration_system_secdef',
								elements: [
									{
										entity_template: 'input_item',
										title: 'Security Definition Updates:',
										type: 'select',
										input_name: 'secdef_enabled',
										info: 'Barracuda Security Definitions are enabled by default and automatically update regularly. They provide the maximum level of protection from emerging security threats for the Barracuda Phone System and all Barracuda products.',
										select_options: [
											{
												text: 'Disabled',
												value: '0'
											},
											{
												text: 'Enabled',
												value: '1'
											}
										]
									}
								]
							} // END page module: SecDef
						] // END Form Elements
					}, // End Form Def
					// ---- SNIP here when the beta reporting goes away ----

					{
						entity_template: 'aopb_container',
						rest: '/gui/system/reporting',
						watch_self_fillData: true,
						states: [{
							conditions: { a: '@reporting_allowed', op: '==', b: '_TRUEISH_' },
							elements: [
								{
									entity_template: 'page_module',
									form_action: '/gui/system/reporting',
									legend: 'Daily Reports',
									elements: [
										{
											entity_template: 'input_item',
											title: 'Enable Daily Reporting:',
											input_name: 'reporting_enabled',
											type: 'checkbox'
										},

										{
											entity_templates: ['aopb_state', 'container'],
											aopb_state: {
												states: [{
													conditions: { a: '$reporting_enabled', op: '==', b: '_TRUEISH_' },
													actions: { enabled: true, _cascade: true },
													else_actions: { enabled: false, _cascade: true }
												}]
											},
											container: { elements: [
												{
													entity_template: 'input_item',
													title: 'E-mail Address:',
													info: 'Separate multiple addresses with commas.',
													input_name: 'reporting_addresses',
													type: 'text'
												},
												{
													entity_template: 'input_item',
													title: 'Reports for Individuals:',
													input_name: 'reporting_individuals',
													type: 'checkbox'
												},
												{
													entity_template: 'input_item',
													title: 'Reports for Groups:',
													input_name: 'reporting_groups',
													type: 'checkbox'
												},
												{
													entity_template: 'input_item',
													title: 'Reports for Individuals by Group:',
													input_name: 'reporting_individuals_by_group',
													type: 'checkbox'
												},
												{
													entity_template: 'input_item',
													title: 'Reports for Queues:',
													input_name: 'reporting_queues',
													type: 'checkbox'
												},
												{
													entity_template: 'input_item',
													title: 'Reports for Individuals by Queue:',
													input_name: 'reporting_individuals_by_queue',
													type: 'checkbox'
												}
											]} // END container and elements
										} // END aopb_state/container
									] // END page_module elements
								} // END page_module
							] // END elements
						}] // END states
					}, // END aopb_container
					{
						entity_template: 'page_module',
						legend: 'Call Recording',
						form_action: '/gui/record/recformat',
						buttons: ['help'],
						help_id: 'set_recording_file_type',
						elements: [
							{
								entity_template: 'input_item',
								title: 'CDR Call Recording File Format:',
								type: 'select',
								select_options: [
								    { text: 'MP3', value: 'mp3' },
								    { text: 'WAV', value: 'wav' }
								],
								input_name: 'bbx_record_format'
						    }
						]
					}
				] // END page_content elements
			} // END page_content
		] // END template elements
	}); // END template
})(); // END scoping function
