/* jshint jquery: true, unused: vars */
/* global add_widget */
(function( $ ){
	var statusTrue = function (val) {
		if (!val) { return false; }
		if (typeof(val) == "string" && val.search(/^[0f]/i) !== -1) { return false; }
		return true;
	};

	var hardwarePortStatusWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			template_html: '<div class="hardware_port_status"></div>',

			_port_status: '',
			_port_alarms: []
		},

		_displayStatus: function() {
			var self = this, $self = this.element, status = "OK", status_class = "green-alarm";

			if(self.options._port_status == "missing") {
				status = "Hardware Failure: Port Offline";
				status_class = "red-alarm";
			} else if($.inArray("red", self.options._port_alarms) !== -1) {
				status = "Red Alarm: Lost connectivity";
				status_class = "red-alarm";
			} else if($.inArray("yellow", self.options._port_alarms) !== -1) {
				status = "Yellow Alarm: Remote Side lost connectivity";
				status_class = "yellow-alarm";
			} else if($.inArray("blue", self.options._port_alarms) !== -1) {
				status = "Blue Alarm: Intermediate disruption";
				status_class = "blue-alarm";
			} else if($.inArray("rai", self.options._port_alarms) !== -1) {
				status = "Remote Yellow Alarm: Other side reporting remote connectivity lost";
				status_class = "remote-yellow-alarm";
			} else if($.inArray("ais", self.options._port_alarms) !== -1) {
				status = "Remote Blue Alarm: Other side reporting intermediate disruption";
				status_class = "remote-blue-alarm";
			} else if($.inArray("general", self.options._port_alarms) !== -1) {
				status = "General Alarm Condition";
				status_class = "orange-alarm";
			}

			status_class = status_class + " tdm-alarm";

			$self.find('.hardware_port_status').text(status).attr('class', status_class);
		},

		_removeAlarm: function(alarm) {
			var self = this, $self = this.element;

			var index = self.options._port_alarms.indexOf(alarm);

			if (index !== -1) {
				self.options._port_status.alarms(index, 1);
			}
		},

		fillData: function(d) {
			var self = this, $self = this.element;
			self.options.data = d;
			self.options._port_status = d.bbx_tdm_card_port_status;

			if(typeof(d.bbx_tdm_card_port_alarm_red) !== "undefined") {
				if(statusTrue(d.bbx_tdm_card_port_alarm_red)) {
					self.options._port_alarms.push('red');
				} else {
					self._removeAlarm('red');
				}
			}

			if(typeof(d.bbx_tdm_card_port_alarm_blue) !== "undefined") {
				if(statusTrue(d.bbx_tdm_card_port_alarm_blue)) {
					self.options._port_alarms.push('blue');
				} else {
					self._removeAlarm('blue');
				}
			}

			if(typeof(d.bbx_tdm_card_port_alarm_yellow) !== "undefined") {
				if(statusTrue(d.bbx_tdm_card_port_alarm_yellow)) {
					self.options._port_alarms.push('yellow');
				} else {
					self._removeAlarm('yellow');
				}
			}

			if(typeof(d.bbx_tdm_card_port_alarm_rai) !== "undefined") {
				if(statusTrue(d.bbx_tdm_card_port_alarm_rai)) {
					self.options._port_alarms.push('rai');
				} else {
					self._removeAlarm('rai');
				}
			}

			if(typeof(d.bbx_tdm_card_port_alarm_ais) !== "undefined") {
				if(statusTrue(d.bbx_tdm_card_port_alarm_ais)) {
					self.options._port_alarms.push('ais');
				} else {
					self._removeAlarm('ais');
				}
			}

			if(typeof(d.bbx_tdm_card_port_alarm_general) !== "undefined") {
				if(statusTrue(d.bbx_tdm_card_port_alarm_general)) {
					self.options._port_alarms.push('general');
				} else {
					self._removeAlarm('general');
				}
			}

			self._displayStatus();

			$.ui.widget.prototype.fillData.apply(self, arguments);
		}
	});

	add_widget('hardwarePortStatusWidget', hardwarePortStatusWidget);
})(jQuery);
