register_screen('backup', {
    rest: '/gui/backup/site',
    rest_container: 'site',
    elements: [
	{ entity_template: 'page_title', title: 'Backup' },
	{
	    entity_template: 'page_buttons',
	    buttons: [{ type: 'cancel' }, { type: 'save' }]
	},
	{
	    entity_template: 'page_content', elements: [
		{
		    entity_template: 'form',
		    action: '/gui/backup/site',
		    submit_all: false,
		    force_reset: true,
		    filter_return_data: true,
		    dataEvents: {
			trigger: 'siteChange'
		    },
		    elements: [
			{
			    entity_template: 'page_module',
			    legend: 'Basic Information',
			    buttons: [ 'help' ],
			    help_id: 'backup_settings',
			    elements: [
				{
				    entity_template: 'input_item',
				    title: 'Server Type:',
				    type: 'select',
				    select_options: [
					{ text: 'None', value: 'unset' },
					{ text: 'SMB', value: 'smb' },
					{ text: 'FTP', value: 'ftp' }
				    ],
				    input_name: 'bbx_backup_site_type'
				},
				{
				    entity_templates: ['container', 'aopb_state'],
				    container: {
					elements: [
					    {
						entity_template: 'input_item',
						title: 'Server Address:',
						type: 'text',
						input_options: {
						    widgets: [ 'aopbStateWidget' ],
						    widget_options: {
							"aopbStateWidget" : {
							    states: [{
								conditions: [
								    { a: '$$', op: '==', b: '_TRUEISH_' },
								    { join: 'or', a: '$bbx_backup_site_type', op: '==', b: 'unset' }
								],
								actions: { invalid: false },
								else_actions: { invalid: 'A Server Address is required' }
							    }]
							}
						    }
						},
						input_name: 'bbx_backup_site_host'
					    },
					    {
						entity_template: 'input_item',
						title: 'Port:',
						type: 'text',
						input_name: 'bbx_backup_site_port',
						input_options: {
						    attributes: { size: 5 },
						    widgets: [ 'aopbStateWidget' ],
						    widget_options: {
							aopbStateWidget: {
							    _aopb_binds: 'change', // Kind of a hack so it will let you erase the entire FTP box, but not keep it empty
							    states: [
								{
								    conditions: { a: '$bbx_backup_site_type', op: '==', b: 'smb' },
								    actions: { enabled: false },
								    else_actions: { enabled: true }
								},
								{
								    conditions: [
									{ a:'$$', op: '==', b: '' },
									{ join: 'and', a:'$bbx_backup_site_type', op: '==', b: 'ftp' }
								    ],
								    actions: { value: '21' }
								},
								{
								    conditions: { a: '$$', op: '!=', b: '0-65535' },
								    actions: { invalid: 'Invalid port number' },
								    else_actions: { invalid: false }
								}
							    ]
							}
						    }
						}
					    },
					    {
						entity_template: 'input_item',
						title: 'Username:',
						type: 'text',
						input_name: 'bbx_backup_site_user'
					    },
					    {
						entity_template: 'input_item',
						title: 'Password:',
						type: 'password',
						input_name: 'bbx_backup_site_pass'
					    },
					    {
						entity_template: 'input_item',
						title: 'Share (SMB):',
						type: 'text',
						input_name: 'bbx_backup_site_share',
						input_options: {
						    widgets: ['aopbStateWidget'],
						    widget_options: {
							aopbStateWidget: {
							    states: [
								{
								    conditions: [
									{ a: '$bbx_backup_site_type', op: '==', b: 'smb' },
									{ join: 'and', a: '$bbx_backup_site_share', op: '!=', b: '_TRUEISH_' }
								    ],
								    actions: { invalid: 'SMB share is required' },
								    else_actions: { invalid: false }
								},
								{
								    conditions: { a: '$bbx_backup_site_type', op: '!=', b: 'smb' },
								    actions: { enabled: false },
								    else_actions: { enabled: true }
								}
							    ]
							}
						    }
						}
					    },
					    {
						entity_template: 'input_item',
						title: 'Domain (SMB):',
						type: 'text',
						input_name: 'bbx_backup_site_domain',
						input_options: {
						    widgets: ['aopbStateWidget'],
						    widget_options: {
							aopbStateWidget: {
							    states: [
								{
								    conditions: { a: '$bbx_backup_site_type', op: '!=', b: 'smb' },
								    actions: { enabled: false },
								    else_actions: { enabled: true }
								}
							    ]
							}
						    }
						}
					    },
					    {
						entity_template: 'input_item',
						title: 'Folder/Path:',
						type: 'text',
						input_name: 'bbx_backup_site_path'
					    },
						{
						    entity_template: 'input_item',
						    title: 'Notify on system restore:',
						    type: 'checkbox',
						    input_name: 'notify_on_system_restore'
						},
						{
						    entity_template: 'input_item',
						    title: 'Notify on system backup:',
						    type: 'checkbox',
						    input_name: 'notify_on_system_backup'
						},
					    {
						entity_template: 'data_item',
						title: '',
						widgets: ['aopbStateWidget'],
						widget_options: {
						    aopbStateWidget: {
							states: [
							    {
								conditions: [
								    { a: '$bbx_backup_site_type', op: '==', b: 'smb' },
								    { join: 'and', a: '$bbx_backup_site_share', op: '==', b: '_TRUEISH_' }
								],
								actions: { enabled: true, _cascade: true },
								else_actions: { enabled: false, _cascade: true }
							    },
							    {
								conditions: [
								    { a: '$bbx_backup_site_host', op: '!=', b: '_TRUEISH_' },
								    { join: 'or', a: '$bbx_backup_site_type', op: '==', b: 'unset' }
								],
								actions: { enabled: false, _cascade: true },
								else_actions: { enabled: true, _cascade: true }
							    }
							]
						    }
						},

						elements: [
						    {
							entity_template: 'action_button',
							text: 'Test Backup Settings',
							rest: '/gui/backup/test',
							include_data: ['bbx_backup_site_id'],
							include_field_data: [
							    'bbx_backup_site_type',
							    'bbx_backup_site_host',
							    'bbx_backup_site_port',
							    'bbx_backup_site_user',
							    'bbx_backup_site_pass',
							    'bbx_backup_site_share',
							    'bbx_backup_site_domain',
							    'bbx_backup_site_path'
							],
							dialog_during_submit: {
							    title: 'Testing' + entity.hellip,
							    text: 'The connection and settings are being verified. Please wait.'
							},
							dialog_after_success: {
							    title: 'Test Complete',
							    text: 'The backup test completed successfully.'
							},
							dialog_after_error: {
							    title: 'Test Failed',
							    text: 'The backup test failed. Check your settings, ensure that the server is available, and try again.'
							},
							method: 'GET',
							error_message: 'Backup Test Failed'
						    }
						]
					    }
					]
				    },
				    aopb_state: {
					states: [
					    {
						conditions: { a: '$bbx_backup_site_type', op: '==', b: 'unset' },
						actions: { enabled: false, _cascade: true },
						else_actions: { enabled: true, _cascade: true }
					    }
					]
				    }
				}
			    ] // END elements
			}, // END page_module
			{
			    entity_template: 'page_module',
			    legend: 'Call Detail Records',
			    buttons: [ 'help' ],
			    help_id: 'backup_cdrs',
			    elements: [
				{
				    entity_template: 'input_item',
				    title: 'Archive and Clean Up CDRs during backup:',
				    type: 'checkbox',
				    input_name: 'bbx_backup_site_archive_cdrs'
				},
				{
				    entity_template: 'data_item',
				    title: 'Remove and Archive records older than:',
				    info: 'Older records will be saved as a CSV file in the backup location, and will be removed from the system.',
				    elements: [
					{
					    entity: 'input',
					    type: 'text',
					    widgets: ['aopbStateWidget'],
					    attributes: {
						'alt-message-container': 'next .alt-messages',
						size: 3,
						name: 'bbx_backup_site_cdr_keep_months'
					    },
					    widget_options: {
						aopbStateWidget: {
						    states: [
							{
							    conditions: { a: '$bbx_backup_site_archive_cdrs', op: '==', b: '_TRUEISH_' },
							    actions: { enabled: true },
							    else_actions: { enabled: false, invalid: false },
							    last_if_false: true
							},
							{
							    conditions: { a: '$$', op: '==', b: '^[0-9]+$', b_is: 'regexp' },
							    actions: { invalid: false },
							    else_actions: { invalid: 'Enter the number of months.' }
							}
						    ]
						}
					    }
					},
					{
					    entity: 'span',
					    text: ' months'
					},
					{
					    entity: 'div',
					    attributes: { 'class' : 'alt-messages' }
					}
				    ]
				}
			    ]
			}, // END page module
				{
					entity_template: 'page_module',
					legend: 'Synchronization',
					buttons: [ 'help' ],

					help_id: 'restore_from_another_bps',
					elements: [
						{
							entity_template: 'input_item',
							title: 'Receive and Apply Backups From Another Barracuda Phone System:',
							type: 'checkbox',
							input_name: 'bbx_backup_site_auto_restore',
							info: 'Apply any backups, immediately after they are made, from a selected source system. This feature should not be enabled if this is an active system. Enabling it may remove existing data when the backup is applied.'
						},
						{
							entity_template: 'data_item',
							title: 'Serial of source Barracuda Phone System',
							info: 'Serial Number of Source System:',
							elements: [
								{
									entity: 'input',
									type: 'text',
									widgets: ['aopbStateWidget'],
									attributes: { size: 6, name: 'bbx_backup_site_auto_restore_serial' },
									widget_options: {
										aopbStateWidget: {
											states: [
												{
													conditions: { a: '$bbx_backup_site_auto_restore', op: '==', b: '_TRUEISH_' },
													actions: { enabled: true },
													else_actions: { enabled: false, invalid: false },
													last_if_false: true
												},
                        {
                          conditions: {a: '$$', op: '==', b: '^[0-9]+$',
                          b_is: 'regexp'
                          },
                          actions: {invalid: false},
                          else_actions: {invalid: 'Enter the serial number of the Barracuda Phone System you want to sync from'}
                        }
											]
										}
									}
								}
							]
						}// End Entity Template
					]
				},
				{
			    entity_template: 'page_module',
			    legend: 'Perform Backup',
			    buttons: [ 'help' ],
			    help_id: 'perform_backup',
			    elements: [
				{
				    entity_template: 'data_item',
				    elements: [
					{
					    entity_templates: [ 'container', 'key_value' ],
					    key_value: {
						'live_table': 'live_system_stats',
						'live_table_key': 'backup',
						key_column: 'name',
						value_column: 'value'
					    },
					    container: {
						elements: [
						    {
							entity_template: 'action_button',
							text: 'Perform Backup Now',
							rest: '/gui/backup/run',
							include_data: [ 'bbx_backup_site_id' ],
							method: 'PUT',
							'class': 'backup_start',
							widgets: ['aopbStateWidget'],
							widget_options: {
							    aopbStateWidget: {
								states: {
								    conditions: { a: '@bbx_backup_site_id', op: '==', b: '_TRUEISH_' },
								    actions: { enabled: true },
								    else_actions: { enabled: false }
								}
							    }
							}
						    },
						    {
							entity_template: 'action_button',
							text: 'Cancel Running Backup',
							rest: '/gui/backup/cancel_running',
							include_data: [ 'bbx_backup_site_id' ],
							method: 'POST',
							'class': 'backup_stop'
						    },
						    {
							entity: 'span',
							widgets: [ 'backupStatusWidget' ]
						    }
						]
					    }
					}
				    ]
				}
			    ]
			},
			{
			    entity_template: 'page_module',
			    legend: 'Scheduled Backups',
			    buttons: [ 'help' ],
			    help_id: 'schedules',
			    elements: [
				{
				    entity_templates: ['data_table', 'aopb_state'],
				    data_table: {
					click_action: 'edit',
					rest: '/gui/backup/recurring',
					allow_scroll: false,
					submit_only_dirty: true,
					enable_add_row: true,
					clear_row_data_on_change: true,
					dataEvents: {
					    bind: { 'siteChange': '' }
					},
					extra_row_data: [
					    'bbx_backup_site_id',
					    'bbx_backup_recurring_id',
					    'bbx_backup_recurring_type',
					    'bbx_backup_recurring_day_of_week',
					    'bbx_backup_recurring_day_of_month',
					    'bbx_backup_recurring_archive_cdrs',
					    'bbx_backup_recurring_cdr_keep_months'
					],
					dirty_exclusion_lists: [
					    [ 'bbx_backup_recurring_day_of_week', 'bbx_backup_recurring_day_of_month' ]
					],
					primary_keys: ['bbx_backup_recurring_id'],
					click_action: 'edit',
					add_edit_action: {
					    rest: '/gui/backup/recurring',
					    include_data_from_parent: ['bbx_backup_site_id']
					},
					delete_action: {
					    rest: '/gui/backup/recurring'
					},
					paginate:      false,
					length_change: false,
					filtering:     false,
					processing:    false,
					sortable:      false,
					accept_data_from_parent: false,
					oLanguage: {
					    sZeroRecords: 'No Scheduled Backups'
					},
					columns: [
					    {
						header: 'Monthly/Weekly',
						view_element: {
						    entity: 'div',
						    widgets: ['aopbStateWidget'],
						    widget_options: {
							aopbStateWidget: {
							    states: [
								{
								    conditions: { a: '@bbx_backup_recurring_day_of_month', op: '==', b: '_TRUE_' },
								    actions: { text: 'Monthly' },
								    else_actions: { text: 'Weekly' }
								}
							    ]
							}
						    }
						},
						add_element: {
						    entity: 'select',
						    attributes: {
							name: 'bbx_backup_recurring_type',
							'class': 'recurring_select'
						    },
						    select_options: [
							{
							    value: 'Monthly',
							    text: 'Monthly'
							},
							{
							    value: 'Weekly',
							    text: 'Weekly'
							}
						    ]
						},
						edit_element: {
						    entity: 'select',
						    attributes: {
							name: 'bbx_backup_recurring_type',
							'class': 'recurring_select_edit'
						    },
						    select_options: [
							{
							    value: 'Monthly',
							    text: 'Monthly'
							},
							{
							    value: 'Weekly',
							    text: 'Weekly'
							}
						    ],
						    widgets:['aopbStateWidget'],
						    widget_options: {
							aopbStateWidget: {
							    states: [
								{
								    conditions: { a: '@bbx_backup_recurring_day_of_month', op: '==', b: '_TRUEISH_' },
								    actions: { value: 'Monthly' },
								    else_actions: { value: 'Weekly' }
								}
							    ]
							}
						    }
						}
					    },
					    {
						header: 'Day',
						view_element: {
						    entity: 'div',
						    widgets: ['aopbFormatWidget'],
						    widget_options: {
							aopbFormatWidget: {
							    states: {
								conditions: { a: '@bbx_backup_recurring_day_of_month', op: '==', b: '_TRUE_' },
								format: {
								    format: '$1 of the month',
								    fields: [{
									field: '@bbx_backup_recurring_day_of_month',
									formatter: 'day_of_the_month'
								    }]
								},
								else_format: {
								    format: {
									field: '@bbx_backup_recurring_day_of_week',
									formatter: 'value_to_weekday'
								    }
								}
							    }
							}
						    }
						},
						add_element: {
						    entity_template: 'switch',
						    closest: 'tr',
						    selector: '.recurring_select',
						    switch_elements: {
							'Monthly': elgen('recurring_backup_select', { monthly : true }),
							'Weekly' : elgen('recurring_backup_select')
						    }
						},
						edit_element: {
						    entity_template: 'switch',
						    closest: 'tr',
						    selector: '.recurring_select_edit',
						    switch_elements: {
							'Monthly': elgen('recurring_backup_select', { monthly : true }),
							'Weekly' : elgen('recurring_backup_select')
						    }
						}
					    },
					    {
						header: 'Time of the Day',
						column: 'bbx_backup_recurring_time_of_day',
						add_element: {
						    entity_template: 'time_select',
						    name: 'bbx_backup_recurring_time_of_day',
						    closest: 'td',
						    display_output: true,
						    minute_interval: true,
						    allow_seconds: false
						},
						edit_element: {
						    entity_template: 'time_select',
						    name: 'bbx_backup_recurring_time_of_day',
						    closest: 'td',
						    display_output: true,
						    minute_interval: true,
						    allow_seconds: false
						}
					    }
					],

					// Actions and buttons that live in each individual row
					row_actions: {
					    // "type" determines whether the buttons will be inline on the table row ("inline"), or in an expand-on-click ("expand")
					    animate: false,
					    type: 'expand',
					    edit_save: { text: 'Save' },
					    edit_cancel: { text: 'Cancel' },
					    add_save: { text: 'Add' },
					    add_cancel: { text: 'Cancel' },
					    delete_row: {
						text: 'Delete',
						confirm_delete: {
						    title: 'Delete this schedule entry?',
						    text: 'The backup will no longer be run on this schedule. Delete the entry?'
						}
					    },

					    // Allows custom action buttons-- Only available on "expand" type
					    action_elements: []
					},

					// Actions and buttons that live outside the individual rows, in the table chrome
					table_actions: {
					    add_row: {
						text: 'Add Schedule',
						location: 'bottom'
					    },

					    delete_rows: {
						text: 'Delete Selected Schedules',
						location: 'bottom',
						confirm: {
						    title: 'Delete Selected?',
						    text: 'Are you sure you want to delete the selected backup schedules?'						}
					    },

					    // "select" options control checkboxes for multiple delete
					    select_row: true,
					    select_all_rows: true
					}
				    },
				    aopb_state: {
					states: {
					    conditions: { a: '@bbx_backup_site_id', op: '==', b: '_TRUEISH_' },
					    actions: { enabled: true, _cascade: true },
					    else_actions: { enabled: false, _cascade: true }
					}
				    }
				}
			    ]
			}
		    ]
		},
		{
		    entity_template: 'page_module',
		    legend: 'Completed/Running Backups',
		    buttons: [ 'help' ],
		    help_id: 'recent_backups',
		    elements: [
			{
			    entity_template: 'data_table',
			    live_table: 'bbx_backup_run',
			    add_edit_action: false,
			    delete_action: false,
			    allow_scroll: false,
			    oLanguage: {
				sZeroRecords: 'No Completed Backups Found'
			    },
			    extra_row_data: ['bbx_backup_run_id', 'bbx_backup_run_backup_file', 'bbx_backup_site_id'],
			    /* These options show/hide UI features in dataTable */
			    paginate:      true,
			    length_change: true,
			    filtering:     false,
			    processing:    false,
			    js_sort_by: [ ["bbx_backup_run_id", "desc"] ],
			    sortable:      false,

			    columns: [
				{
				    column: 'bbx_backup_run_id',
				    visible: false,
				    data_type: 'numeric',
				    sort_data_type: 'numeric-desc'
				},
				{
				    header: 'Started',
				    column: 'bbx_backup_run_started',
				    formatted: {
					format: {
					    field: '@bbx_backup_run_started',
					    formatter: 'date',
					    options: { strip_micro: true }
					}
				    }
				},
				{
				    header: 'Completed',
				    column: 'bbx_backup_run_completed',
				    formatted: {
					format: {
					    field: '@bbx_backup_run_completed',
					    formatter: 'date',
					    options: { strip_micro: true }
					}
				    }
				},
				{
				    header: 'Filename',
				    view_element: {
					entity_template: 'container',
					width: '160px',
					elements: [
					    {
						entity: 'span',
						attributes: { name: 'bbx_backup_run_backup_file' }
					    },
					    {
						entity_template: 'blanker_button',
						text: 'Restore Backup',
						confirm: {
						    title: 'Restore Backup',
						    text: 'Restoring a backup will cause downtime, continue?'
						},
						rest: '/gui/backup/restore',
						method: 'GET',
						live: true,
						context: 'restore',
						flyby_start: 'restore_start',
						flyby_complete: 'restore_end',
						suppress_ape_reload: true,
						blanker_options: {
						    title: 'Backup restore in progress...',
						    text: 'To protect data integrity, the administrative interface is locked while the restore is in progress.',
						    blanker: true,
						    flyby: 'Restoring...',
						    progress: true,
						    buttons: ['Log Out'],
						    callbacks: [function () { self._hideBlanker(); logout(); }],
						    ping: { url: '/gui/sysinfo/restoring', time: 20000 }
						},
						include_data_as: {
						    'bbx_backup_site_id': 'bbx_backup_site_id',
						    'bbx_backup_run_backup_file': 'file'
						},
						method: 'GET',
						widgets: ['aopbStateWidget'],
						widget_options: {
						    aopbStateWidget: {
							closest: 'tr',
							states: [
							    {
								conditions: [
								    { a: '@bbx_backup_run_status', op: '==', b: 'Completed' },
								    { join: 'and', a: '@bbx_backup_run_backup_file', op: '==', b: '_TRUEISH_' }
								],

								actions: { visible: true },
								else_actions: { visible: false }
							    }
							]
						    }
						}
					    }
					]
				    }
				},
				{
				    // New, Running, Cancelled, Completed, <error text>
				    header: 'Status',
				    column: 'bbx_backup_run_status',
				    translate: {
					New: 'Pending',
					Running: 'In Progress',
					Cancelled: 'Cancelled',
					Completed: 'Completed'
				    }
				}
			    ]
			}
		    ]
		}, // END page_module 2
		{
		    entity_template: 'page_module',
		    legend: 'Backups on Server',
		    buttons: [ 'help' ],
		    help_id: 'backups_on_server',
		    elements: [
			{
			    entity_template: 'data_table',
			    attributes: { 'class': 'backup-list-dtw' },
			    accept_data_from_parent: true,
			    add_edit_action: false,
			    delete_action: false,
			    data_source: 'list',
			    oLanguage: {
				sZeroRecords: 'No backups. Click ' + entity.ldquo + 'Retrieve Backup List from Server' + entity.rdquo + ' to retrieve or refresh this list.'
			    },
			    extra_row_data: [ 'bbx_backup_site_id' ],
			    /* These options show/hide UI features in dataTable */
			    paginate:      true,
			    length_change: false,
			    filtering:     false,
			    processing:    false,
			    sortable:      false,
			    js_sort_by: [ ["date", "desc"] ],
			    table_actions: {
				action_elements: {
				    location: 'bottom',
				    actions: [
					{
					    entity_template: 'rest_data_fill_button',
					    text: 'Retrieve Backup List from Server',
					    rest: '/gui/backup/list',
					    container_mapping: false,
					    dialog_during_submit: {
						title: 'Retrieving Backup File List' + entity.hellip,
						text: 'The list of files is being retrieved from the backup server. This may take a moment.'
					    },
					    error_message: 'There was a problem retrieving the list',
					    error_ok_button: 'OK',
					    selector: '.backup-list-dtw'
					}
				    ]
				}
			    },

			    columns: [
				{
				    header: 'Date',
				    column: 'date'
				},
				{
				    header: 'Filename',
				    column: 'filename'
				},
				{
				    header: 'Firmware Version',
				    column: 'version'
				},
				{
				    header: 'Action',
				    view_element: {
					entity_template: 'blanker_button',
					text: 'Restore Backup',
					confirm: {
					    title: 'Restore Backup',
					    text: 'Restoring a backup will cause downtime, continue?'
					},
					rest: '/gui/backup/restore',
					method: 'GET',
					live: true,
					context: 'restore',
					flyby_start: 'restore_start',
					flyby_complete: 'restore_end',
					suppress_ape_reload: true,
					blanker_options: {
					    title: 'Backup restore in progress...',
					    text: 'To protect data integrity, the administrative interface is locked while the restore is in progress.',
					    blanker: true,
					    flyby: 'Restoring...',
					    progress: true,
					    buttons: ['Log Out'],
					    callbacks: [function () { self._hideBlanker(); logout(); }],
					    ping: { url: '/gui/sysinfo/restoring', time: 20000 }
					},
					include_data_as: {
					    'bbx_backup_site_id': 'bbx_backup_site_id',
					    'filename': 'file'
					}
				    }
				}
			    ]
			}
		    ]
		} //End Page Module
	    ] // END elements
	} // END page_content
    ]
});
