/* ApeConnection - provide a wrapper around interfacing with APE

ApeConnection connects to Ape via 1 of 2 ways, websockets or long polling.
Ape actually supports more methods, but they're not terribly useful, so we don't try them.

If websockets are not available natively, we try to use flash-websockets.

The result of calling new ApeConnection will give you an object representing the APE connection. You should only
initialize Ape once

The most common APE functions are subscribe (which takes the name of a channel, or an array of channel names)
and unsubscribe (which takes a channel name).

Events can be read by creating a listener function, either for all events using
<apeInstance>.eventSystem.addListener('channel', <function>) or by listening to a single channel using
<apeInstance>.addChannelListener(<channelName>, <function>). See the documentation for these functions for more
details.

To use the old, jQuery-event-based event model, where jQuery events are triggered on the window whenever an event
comes in, add the handler ApeConnection.jqChannelEventBridge to the "channel" event. This can be easily achieved by
the second "initialListeners" param when instantiating the ApeConnection:

  var <apeInstance> = new ApeConnection(<onConnectFunction>, { channel: ApeConnection.jqChannelEventBridge });

If this is used, events will be triggered on $(window) with the channel name prefixed with 'meteor_', so
'system_status' channel events are handled by doing a $(window).bind('meteor_system_status', function(e, data) {...})
or some approximation.

For debugging there are the following options:

Ape.debug=true => print all APE messages to the console
Ape.subscriptions => return current list of channels subscribed to to the console
Ape.connectionType => return the current connection type (native websocket, flash websocket, long polling)

*/

/*
   "Context" in these functions is the unique 'liveTable_' + <table name> + '__' + <unique key>
   name for this particular connection. It serves as a "stream identifier" when there may be
   multiple streams from the same table, but with different parameters, up simultaneously.
*/

/*
NOTE: This file has been made completely independent from jQuery. You should ONLY use jQuery features in the
jqChannelEventBridge function, if at all.
 */

/* TODO

Be smarter about what method we use to connect based on previous attempts.  Right now we always try ws first.
Ape Channel history branch has a bunch of support history
We should implement subscription/binding management with timeouts for screens so we don't need to subscribe, then bind,
and never unsub when we nav away from a screen

*/

// Prevent Esc from killing WebSocket in Firefox-- Block Esc at the "document" object
if (/(Firefox|Iceweasel)\//.test(navigator.userAgent)) {
	document.addEventListener('keydown', function (e) {
		if (e.which === 27) {
			console.log('ApeConnection.js: Blocked "Esc" key to prevent socket interruption. (This is okay)');
			e.preventDefault();
		}
	});
}

if (navigator.appName === 'Microsoft Internet Explorer' && navigator.userAgent.indexOf('MSIE 9.') !== -1) {
	// IE9 beta appears to have a caching bug with flash, so stop it from caching the swf
	WEB_SOCKET_SWF_LOCATION = '/js/web-socket-js/WebSocketMain.swf?' + Math.random();
} else {
	WEB_SOCKET_SWF_LOCATION = '/js/web-socket-js/WebSocketMain.swf';
}
//WEB_SOCKET_DEBUG = true;

var ApeConnection;

(function () {
	// IIFE for scoping

	/* // For debug in XHRGetSimple:
	var u = 0;
	var unique = function () { return ++u; }
	*/

	var APE_INITIAL_TIMEOUT = 6000;
	var APE_RECONNECT_TIMEOUT = 3000;
	var APE_ALIVE_TIMEOUT = 60000; // Time between any messages before considering the connection dead
	var MAX_ERRORS_BEFORE_RELOAD = 30; // Number of errors of a specific type before reload is attempted
	var RELOAD_ON_ERROR_TYPES = ["BAD_SESSID", "BAD_SESSION", "BAD_JSON"]; // Types of errors to check; string or array of strings
	var MAX_APE_FAILURES = 6;
	var MAX_APE_FAILURES_WS = 2;
	var APE_FAILURES = 0;
	var APE_READY = false;

	var APE_FAIL_DIALOG = {
		title: 'Connection Lost',
		text:  'The connection from your browser to the Phone System has been interrupted. There may be network problems, or the system may be down for maintenance. Please wait...',
		blanker: true,
		progress: true,
		buttons: []
	};

	// this function is annoying, make it a noop
	window.webSocketError = function() {};

	/**
	 * @callback xhrAbortCallback
	 * @param {XMLHttpRequest} xhr
	 *
 	 * @callback xhrErrorCallback
 	 * @param {XMLHttpRequest} xhr
 	 *
 	 * @callback xhrSuccessCallback
 	 * @param {XMLHttpRequest} xhr
 	 *
	 * XHRGetSimple: A replacement for jQuery's $.ajax, that only handles simple GET requests
	 * @param {Object} options - Options for the request, similar to $.ajax
	 * @param {string} options.url - URL to fetch
	 * @param {xhrAbortCallback} options.abort
	 * @param {xhrErrorCallback} options.error
	 * @param {xhrSuccessCallback} options.success
	 * @returns {XMLHttpRequest}
	 */
	var XHRGetSimple = function (options) {
		var xhr = new XMLHttpRequest();
		//var uid = unique();

		xhr.onreadystatechange = function () {
			if (xhr.readyState !== 4) { return; }
			if (xhr.status === 0) {
				options.abort && options.abort(xhr);
				return;
			}

			if (xhr.status < 200 || xhr.status > 299) {
				options.error && options.error(xhr);
				return;
			}

			var response = xhr.responseText;
			options.success && options.success(response, xhr.status, xhr);
		}; // END onreadystatechange

		xhr.onerror = function (errorType) {
			options.error && options.error(xhr);
		};

		xhr.ontimeout = xhr.onerror.bind(this, 'timeout');
		var url = options.url;

		if (options.cache && url.indexOf('?') === -1) {
			var urlParts, urlParams;
			urlParts = url.split('?', 1);
			urlParams = urlParts[1] ? urlParts[1].split('&') : [];
			urlParams.push('_=' + Math.floor(Math.random() * 10000000000));
			url = urlParts[0] + '?' + urlParams.join('&');
		}

		xhr.open('get', url);
		xhr.send();
		return xhr;
	};

	/**
	 * WebSocket interface to an APE connection
	 * @param {ApeConnection} connection - The ApeConnection instance that spawned the ApeConnectionWS
	 */
	var ApeConnectionWS = function (connection) {
		var chl = 0;
		var connecttimeout;
		var lastError;
		var url;
		var port;
		var https = (location.protocol === "https:");
		var protocol = https ? 'wss://' : 'ws://';
		var sessionid = null;
		var keepalive = null;
		var connected = false;
		var debug = true;
		var open = false;
		var _this = this;

		switch (APE_FAILURES || 0) {
			case 0:
				// Normal port
				port = https ? 7839 : 7838;
				break;
			case 1:
				// Fallback to http/s port
				port = location.port;
				break;
			default: // 2+
				// If that doesn't work, use the default ports
				port = https ? 7839 : 7838;
		}

		url = protocol + document.domain + ':' + port + '/6/';

		try {
			console.log("Establishing a live data connection: Attempt #" + (APE_FAILURES + 1) + " via WebSocket to " + url);
			this.ws = new WebSocket(url);
		} catch (err) {
			lastError = err;
		}

		if (lastError || !this.ws || !("send" in this.ws)) {
			connection.connect_failed(lastError ? lastError : "");
			return;
		}

		connecttimeout = setTimeout(function() {
			_this.disconnect();
			connection.connect_failed();
		}, 5500); /* 500 milliseconds longer than flash timeout */

		/**
		 * Subscribe to one or more Ape channels, if not already subscribed
		 * @param  {string|array} channels Channel/s to subscribe to. Can be a string (one channel) or array (multiple at once)
		 */
		this.subscribe = function(channels) {
			if (!Array.isArray(channels)) {
				channels = [channels];
			}
			var do_join = function() {
				var join_msg = JSON.stringify([{cmd: 'JOIN', sessid: sessionid, chl: chl++, params: { channels: channels } }]);
				_this.ws.send(join_msg);
				connection.eventSystem.triggerListener('join', { sessionid: sessionid });
			};
			if (_this.connected) {
				do_join();
			} else {
				//debugLog("Not connected, scheduling JOIN callback.");
				connection.eventSystem.listenOnce('connect', do_join);
			}
		};

		/**
		 * unsubscribe to one or more channels
		 * @param  {string|array} channel Channel/s to unsubscribe from
		 * @param  {object} metadata_obj Object with information like pagination, search, etc.
		 */
		this.unsubscribe = function(channel, metadata_obj) {
			metadata_obj = metadata_obj || {};
			var do_unsubscribe = function() {
				var mesg = JSON.stringify([{cmd: 'LEFT', sessid: sessionid, chl: chl++, params: { data: metadata_obj, channel: channel } }]);
				_this.ws.send(mesg);
			};
			if (_this.connected) {
				do_unsubscribe();
			} else {
				connection.eventSystem.listenOnce('connect', do_unsubscribe);
			}
		};

		/*
		 * Send a message back to the Ape server.
		 * Used by dataTableClass for bootstrap, changepage, and heartbeat actions
		 */
		this.broadcast = function(data) {
			var ape_obj = this;
			var do_broadcast = function() {
				var broadcast_msg = JSON.stringify([{cmd: 'BROADCAST', sessid: sessionid, chl: chl++, params: { data: data } }]);
				//debugLog("Sending: ", broadcast_msg);
				ape_obj.ws.send(broadcast_msg);
			};
			if (_this.connected) {
				//debugLog("Already connected, sending BROADCAST.");
				do_broadcast();
			} else {
				//debugLog("Not connected, scheduling BROADCAST.");
				connection.eventSystem.listenOnce('connect', do_broadcast);
			}
		};

		this.login = function(params) {
			if (!params) {
				params = {};
			}
			var loginstr = JSON.stringify([{cmd: 'CONNECT', chl: chl++, params: params}]);
			this.ws.send(loginstr);
		};

		this.disconnect = function() {
			_this.connected = false;
			this.ws.onclose = function() {};
			this.ws.onopen = function() {};
			this.ws.close();
			connection.eventSystem.triggerListener('disconnect');
		};

		this.ws.onopen = function() {
			open = true;
			connection.open();
		};

		this.ws.onclose = function() {
			clearTimeout(connecttimeout);
			clearInterval(keepalive);
			keepalive = null;
			if (open) {
				connection.disconnected();
			} else {
				connection.eventSystem.triggerListener('disconnect');
				connection.connect_failed();
			}
		};

		this.ws.onerror = function() {
			console.log("error", arguments);
		};

		this.id = function() { return sessionid; };

		this._getKeepAliveRef = function(sessionid) {
			return (function() {
				_this.ws.send(JSON.stringify([{cmd: 'CHECK', sessid: sessionid, chl: chl++, params: {}}]));
			});
		};

		this.ws.onmessage = function(data) {
			var messages = JSON.parse(data.data);
			for(var i in messages) {
				if (!keepalive && messages[i].raw == 'LOGIN') {
					clearTimeout(connecttimeout);
					console.log('A live data connection was successfully established.');
					_this.connected = true;
					connection.eventSystem.triggerListener('connect');

					sessionid = messages[i].data.sessid;
					keepalive = setInterval(_this._getKeepAliveRef(sessionid), 30000);
					APE_READY = 1;
					if (connection.onConnect) {
						connection.onConnect(connection);
					}
				}
				connection.message(messages[i]);
			}
			messages = null;
			data = null;
		};

	};

	/**
	 * Long-poll interface to an APE connection
	 * @param {ApeConnection} connection - The ApeConnection instance that spawned the ApeConnectionWS
	 */
	var ApeConnectionLP = function (connection) {
		var url = location.protocol + '//' + location.host + '/event/';
		var chl = 0;
		var sessionid = null;
		var disconnect = false;

		if (APE_FAILURES > MAX_APE_FAILURES) {
			if (!hasblanked && CUI.Dialog) { hasblanked = true; new CUI.Dialog(APE_FAIL_DIALOG); }
			APE_FAILURES = 0;
		}

		console.log("Establishing a live data connection: Attempt #" + (APE_FAILURES + 1) + " via long-poll to " + url);

		XHRGetSimple({
			url: url,
			cache: true,
			success: function() {
				// APE sends a 200 OK if we just hit the base URL
				connection.open();
			},
			error: function() {
				connection.connect_failed();
			}
		});

		var poll = function() {
			var reaper;
			var req = XHRGetSimple({
				url: url + '?' + escape(JSON.stringify([{cmd: 'CHECK', sessid: sessionid, chl: chl++, params: {} }])),
				cache: true,
				success: function(data) {
					clearTimeout(reaper);
					var messages = JSON.parse(data);
					for (var i in messages) {
						connection.message(messages[i]);
					}
					if (!disconnect) {
						poll();
					}
				},
				error: function() {
					console.log('poll failure');
					connection.disconnected();
				}
			});

			reaper = setTimeout(function() {
				// abort the request, a new one will be started automatically by the success handler
				req.abort();
			}, 30000);
		};

		this.id = function() { return sessionid; };

		this.login = function(params) {
			if (!params) {
				params = {};
			}

			XHRGetSimple({
				url: url +'?'+ escape(JSON.stringify([{cmd: 'CONNECT', chl: chl++, params: params}])),
				cache: true,
				success: function(data) {
					var messages = JSON.parse(data);
					for (var i in messages) {
						if (messages[i].raw == 'LOGIN') {
							sessionid = messages[i].data.sessid;
							APE_READY = 1;
							if (connection.onConnect) {
								connection.onConnect(connection);
							}
						}
						connection.message(messages[i]);
					}
					// MARK 1
					setTimeout(poll, 1000);
				},
				error: function() {
					connection.disconnected();
				}
			});

		};

		this.subscribe = function(channels) {
			if (! Array.isArray(channels)) {
				channels = [channels];
			}

			XHRGetSimple({
				url: url +'?' + escape(JSON.stringify([{cmd: 'JOIN', sessid: sessionid, chl: chl++, params: { channels: channels }}])),
				cache: true,
				success: function(data) {
					var messages = JSON.parse(data);
					for (var i in messages) {
						connection.message(messages[i]);
					}
				}
			});
		};

		this.unsubscribe = function(channel, metadata_obj) {
			metadata_obj = metadata_obj || {};
			XHRGetSimple({
				url: url + '?' + escape(JSON.stringify([{cmd: 'LEFT', sessid: sessionid, chl: chl++, params: { channel: channel, data: metadata_obj }}])),
				cache: true,
				success: function(data) {
					var messages = JSON.parse(data);
					for (var i in messages) {
						connection.message(messages[i]);
					}
				}
			});
		};

		this.broadcast = function(data) {
			XHRGetSimple({
				url: url +'?' + escape(JSON.stringify([{cmd: 'BROADCAST', sessid: sessionid, chl: chl++, params: { data: data }}])),
				cache: true,
				success: function(data) {
					var messages = JSON.parse(data);
					for (var i in messages) {
						connection.message(messages[i]);
					}
				}
			});
		};

		this.disconnect = function() {
			disconnect = true;
		};
	};

	/**
	 * The base ApeConnection class
	 * @param {Function=} onConnect - Function to run after initial success
	 * @param {Object=} initialListeners - A set of event listeners to apply upon creation
	 * @param {Array=} initialListeners.any - An array of event-handling functions
	 * @param {Array=} initialListeners.noeventconnection - An array of event-handling functions
	 * @param {Array=} initialListeners.connect - An array of event-handling functions
	 * @param {Array=} initialListeners.disconnect - An array of event-handling functions
	 * @param {Array=} initialListeners.timeout - An array of event-handling functions
	 * @param {Array=} initialListeners.channel - An array of event-handling functions
	 * @param {Array=} initialListeners.join - An array of event-handling functions
	 * @param {Object=} initialListeners.channelEvents - An object with keys being channel names, and values being an array of event-handling functions
	 */
	ApeConnection = function (onConnect, initialListeners) {
		var connection, disconnecttime, alivetimer, connecttimer, avoidWS;

		var retries = 0;
		var subscriptions = ['meteor_alive'];
		var hasblanked = false;
		var _this = this;

		this.connected = false;
		this.debug = false;
		this.no_reload = false;
		this.context_subs = {};
		this.context_bindings = {};
		this.context_bind_id = 0;

		this.eventSystem = new EventSystem(this, initialListeners);
		this.onConnect = onConnect;

		var noconnfunction = function() { _this.eventSystem.triggerListener('noeventconnection'); };
		var deadfunction = function() {
			console.log("did not receive periodic alive event");
			noconnfunction();
			if (!hasblanked && CUI.Dialog) { hasblanked = true; new CUI.Dialog(APE_FAIL_DIALOG); }
			hasblanked = true;
			APE_FAILURES = 0;
			_this.disconnected();
		};

		connecttimer = setTimeout(noconnfunction, APE_INITIAL_TIMEOUT);

		this.open = function() {
			// if this is the first connect, or we managed to reconnect within the time limit
			var openTime = new Date().getTime();
			var reloadAfter;

			if (location.search.search(/uncompiled_js/)) {
				reloadAfter = 600000000;
			} else {
				reloadAfter = 600000;
			}

			if (!disconnecttime || ((openTime - disconnecttime) < reloadAfter && !hasblanked)) {
				clearTimeout(connecttimer);
				retries = 0;
				connection.login({
					session: getCookie('bps_session')
				});
			} else if (!this.no_reload) { // stuff is stale
				console.log('disconnected for ', new Date().getTime() - disconnecttime);
				// refresh after a random interval from 0 to 20 seconds
				setTimeout(function() { location.reload(); }, Math.floor(Math.random() * 20000));
			}
		};

		this.subscribe = function(channels) {
			if (Array.isArray(channels)) {
				for (var i in channels) {
					if (channels[i].indexOf(subscriptions) == -1) {
						subscriptions.push(channels[i]);
					}
				}
			} else {
				if (channels.indexOf(subscriptions) == -1) {
					subscriptions.push(channels);
				}
			}

			if (this.connected) {
				if (_this.debug) {
					console.log("subscribing to", channels);
				}
				connection.subscribe(channels);
			}
		};

		this.unsubscribe = function(channel, metadata_obj) {
			var idx;
			if ((idx = channel.indexOf(subscriptions)) != -1) {
				subscriptions.splice(idx, 1);
			}
			if (this.connected) {
				if (_this.debug) {
					console.log("unsubscribing from " + channel);
				}
				connection.unsubscribe(channel, metadata_obj);
			}
		};

		this.id = function() { return connection.id(); };
		this.broadcast = function(o) { return connection.broadcast(o); };

		// Logs the number of instances of an error code
		this.errors = {};
		this.logError = function(err) {
			this.errors[err] = (this.errors[err] || 0) + 1;
		};

		// Returns true when the number of a specific type of error are greater than a max threshold (inclusive)
		// errorToCheck can be a single string or an array of strings to check against
		this.checkErrors = function(max, errorToCheck) {
			var errors = this.errors;
			if (!max) { return false; } // Max is 0 or no max provided -- invalid request
			errorToCheck = errorToCheck || Object.keys(errors); // Check all errors by default
			var doCheck = function (err) { return errors[err] && errors[err] >= max; }; // Abstract this out...
			return Array.isArray(errorToCheck) ? errorToCheck.some(doCheck) : doCheck(errorToCheck); // ...so that we can run the same check on either an array or a single value
		};

		this.disconnected = function(conn_fail) {
			this.connected = false;
			clearTimeout(alivetimer);
			if (!hasblanked) {
				clearTimeout(connecttimer);
				connecttimer = setTimeout(noconnfunction, APE_RECONNECT_TIMEOUT);
			}
			if (!conn_fail) {
				// if we were actually connected, store when we were disconnected
				disconnecttime = new Date().getTime();
			}
			if (connection && "disconnect" in connection) {
				connection.disconnect();
			}
			var that = this;

			if (conn_fail) {
				APE_FAILURES++;
			}

			if (APE_FAILURES > MAX_APE_FAILURES) {
				if (!hasblanked && CUI.Dialog) { hasblanked = true; new CUI.Dialog(APE_FAIL_DIALOG); }
				APE_FAILURES = 0;
			}

			setTimeout(function() {
				if (typeof WebSocket != "undefined" && APE_FAILURES < MAX_APE_FAILURES_WS) {
					connection = new ApeConnectionWS(that, APE_FAILURES);
				} else {
					connection = new ApeConnectionLP(that, APE_FAILURES);
				}
				retries++;
			}, 500 * retries);
		};

		this.connect_failed = function(err) {
			console.log("connect failed: " + (err ? err : ""));
			this.disconnected(true);
		};

		this.bindContext = function (context, callback, callbackThis) {
			var instance = {
				callback: callbackThis ? callback.bind(callbackThis) : callback,
				orig_context: context,
				context: context.toLowerCase(),
				instance_id: _this.context_bind_id,
				joined: false
			};

			_this.context_subs[instance.context] = (_this.context_subs[instance.context] || 0) + 1;
			_this.context_bindings[instance.context] = _this.context_bindings[instance.context] || {};
			_this.context_bindings[instance.context][_this.context_bind_id] = instance;
			_this.context_bind_id++;

			if (_this.context_subs[instance.context] == 1) {
				_this.subscribe(instance.orig_context);
			} else {
				instance.joined = true;
			}

			return instance;
		};

		this.unBind = function (instance, metadata_obj) {
			var do_unsubscribe = false;

			if (_this.context_subs[instance.context]) {
				_this.context_subs[instance.context]--;
				if (!_this.context_subs[instance.context]) {
					do_unsubscribe = true;
				}
			}

			if (_this.context_bindings[instance.context]) {
				if (_this.context_bindings[instance.context][instance.instance_id]) {
					if (do_unsubscribe) {
						_this.unsubscribe(instance.orig_context, metadata_obj);
					}
					delete _this.context_bindings[instance.context][instance.instance_id];
				}
			}
		};

		this.message = function(msg) {
			var context = (msg.data.channel || '').toLowerCase();
			window.sendDebugLog && sendDebugLog(msg, 'APEMessage');

			if (_this.debug) {
				console.log(msg.data.channel, msg);
			}

			switch (msg.raw) {
				case 'LOGIN':
					this.connected = true;
					if (subscriptions.length > 0) {
						if (_this.debug) {
							console.log("resubscribing to", subscriptions);
						}
						connection.subscribe(subscriptions); // apply any queued subscriptions, or subscriptions from before a disconnect
					}
					alivetimer = setTimeout(deadfunction, APE_ALIVE_TIMEOUT);
					break;
				case 'MSG':
					// NEW Event System

					if (!msg.data.data) {
						msg.data.data = {};
					}

					if (!msg.data.data.name) {
						msg.data.data.name = "NO_NAME";
					}

					if (/^user_/.test(context)) {
						context = 'user_notify';
					}

					if (_this.context_bindings && _this.context_bindings[context]) {
						for (var key in _this.context_bindings[context]) {
							_this.context_bindings[context][key].callback({ 'channel': context, 'msgTime': msg.time, 'json': msg.data.data });
						}
					}

					// OLD WAY

					_this.eventSystem.triggerChannelListener(msg.data.channel, msg);
					if (msg.data.channel == 'meteor_alive') {
						clearTimeout(alivetimer);
						alivetimer = setTimeout(deadfunction, APE_ALIVE_TIMEOUT);
					}

				break;

				case 'ERR':
					this.logError(msg.data.value);

					// Fix for issue where bad session may cause endless retries to backend; Reloads after X # of a specific error.
					// We can probably come up with a better solution but I think that would require refactoring much of this file -cg
					if (this.checkErrors(MAX_ERRORS_BEFORE_RELOAD, RELOAD_ON_ERROR_TYPES)) {
						// Some errors in RELOAD_ON_ERROR_TYPES were over-threshold. Before reloading, console.log the ones that are:
						console.log('Reloading browser window; reached error threshold for one or more errors:');
						RELOAD_ON_ERROR_TYPES.forEach(function (err) {
							if (this.errors[err] >= MAX_ERRORS_BEFORE_RELOAD) {
								console.log(' . . . ', err, ': ' + this.errors[err] + ' instances)');
							}
						});
						location.reload();
					}

					switch (msg.data.value) {
						// other harmless errors go here
						case 'ALREADY_ON_CHANNEL':
						case 'UNKNOWN_CHANNEL':
						case 'NOT_IN_CHANNEL':
						break;

						case 'BAD_CHANNEL_PERMISSION':
						console.log("unable to join channel ", msg.data.channel);
						break;

						case 'CANT_JOIN_CHANNEL':
						console.log("cant join channel ", msg.data.channel);
						break;

						case 'BAD_SESSID':
						case 'BAD_SESSION':
						console.log('bad session');
						this.disconnected(true);
						break;

						// other notable but simply reportable errors go here
						case 'BAD_JSON':
						default:
						console.log('error: ', msg.data.value, msg);
						this.disconnected();
						break;
					}
					default:
				break;
			}
			msg = null;
		};

		this.subscriptions = function() {
			return subscriptions;
		};

		this.connectionType = function() {
			if (connection instanceof ApeConnectionLP) {
				return "long polling";
			} else if (connection instanceof ApeConnectionWS) {
				if (connection.ws.__flash) {
					return "flash websocket";
				} else {
					return "native websocket";
				}
			}
			return "not connected";
		};

		// try websockets first (unless we're on a support tunnel)
		if (typeof WebSocket != "undefined" && !document.domain.match('support.barracudanetworks.com') && location.search.indexOf('uselongpoll') === -1) {
			connection = new ApeConnectionWS(this);
			var that = this;
		} else {
			connection = new ApeConnectionLP(this);
		}

		this.ready = function() { return APE_READY; };
	};

	var EventSystem = function (ape, initialListeners, initialChannelListeners) {
		// Event listener system to fire on Ape-wide events (connect, disconnect, timeout, cancel)
		// and on channel events (by channel name). To use: (apeConnection is an ApeConnection instance)
		//
		//  apeConnection.eventSystem.addListener(<STRING eventName>, <FUNCTION fn>);
		//  apeConnection.eventSystem.removeListener(<STRING eventName>, <FUNCTION fn>);
		//  apeConnection.eventSystem.addChannelListener(<STRING channelName>, <FUNCTION fn>);
		//  apeConnection.eventSystem.removeChannelListener(<STRING channelName>, <FUNCTION fn>);
		//
		// The fn callback will be run as:
		//   fn(<ApeConnection instance>, <OBJECT metadata | undefined>, <STRING channelOrEventName>);
		//
		// Similar to DOM events (addEventListener, etc.), events-function pairs are only added once, and
		// removals MUST contain a reference to the same function used when setting up the event. (Which is
		// to say, if you ever want to remove an event, a reference must be retained.)
		//
		// The functions:
		//  apeConnection.eventSystem.triggerListener(<STRING eventName>, <OPTIONAL OBJECT metadata>);
		//  apeConnection.eventSystem.triggerChannelListener(<STRING channelName>, <OPTIONAL OBJECT metadata>);
		// are also included, but should only be used from within ApeConnection.js
		//

		initialListeners = initialListeners || [];
		initialChannelListeners = initialChannelListeners || {};

		var eventListeners = {
			any: [],
			noeventconnection: [],
			connect: [],
			disconnect: [],
			timeout: [],
			channel: [],
			join: [],
			channelEvents: initialChannelListeners
		};

		var getListeners = function (e) {
			return Array.isArray(eventListeners[e]) ? eventListeners[e] : false;
		};

		this.addListener = function (e, fn) {
			var lset = getListeners(e);
			if (lset) {
				if (lset.indexOf(fn) === -1) {
					lset.push(fn);
					return true;
				} else {
					// Do not double-add the same event listener
					return false;
				}
			} else {
				throw new Error('<ApeConnection>.addListener called with invalid event name: ' + e);
			}
		};

		this.removeListener = function (e, fn) {
			var lset = getListeners(e), lidx;
			if (lset) {
				lidx = lset.indexOf(fn);
				if (lidx === -1) {
					return false;
				} else {
					lset.splice(lidx, 1);
					return true;
				}
			} else {
				throw new Error('<ApeConnection>.removeListener called with invalid event name: ' + e);
			}
		};

		this.listenOnce = function (e, fn) {
			var _this, fnOut;
			fnOut = function () {
				fn.apply(this, arguments);
				_this.removeListener(e, fnOut);
			};
			return this.addListener(e, fnOut);
		};

		this.addChannelListener = function (c, fn) {
			var lset = this.event.channelEvents[c] = eventListeners.channelEvents[c] || [];
			if (lset.indexOf(fn) === -1) {
				lset.push(fn);
				return true;
			} else {
				// Do not double-add the same event listener
				return false;
			}
		};

		this.removeChannelListener = function (c, fn) {
			var lset = this.event.channelEvents[c], lidx;
			if (!lset || !lset.length) {
				return false;
			}
			lidx = lset.indexOf(fn);
			if (lidx === -1) {
				return false;
			} else {
				lset.splice(lidx, 1);
				return true;
			}
		};

		this.triggerListener = function (e, data) {
			var lset = getListeners(e);
			if (lset) {
				lset.forEach(function (fn) { fn(ape, data, e); });
				if (e !== 'any') {
					this.triggerListener('any', { event: e, eventData: data });
				}
			} else {
				throw new Error('ApeConnection triggered an event name that does not exist: ' + e);
			}
		};

		this.triggerChannelListener = function (c, data) {
			var lset = eventListeners.channelEvents[c];
			if (lset && lset.length) {
				lset.forEach(function (fn) { fn(ape, data, c); });
			}
			this.triggerListener('channel', { channel: c, data: data });
			// Note that this does NOT error out if no event handlers exist, so be sure you have the param order right!
		};

		(function (_this) {
			var key, listeners;
			if (initialListeners) {
				for (key in eventListeners) {
					if (!(eventListeners.hasOwnProperty(key) && initialListeners.hasOwnProperty(key) && initialListeners[key])) { continue; }
					listeners = initialListeners[key];
					listeners = (Array.isArray(listeners)) ? listeners : [ listeners ];
					listeners.forEach(function (listener) {
						_this.addListener(key, listener);
					});
				}
			}
		})(this);
	};

	// A handler that throws jQuery events for consumption by existing/old modules that use jQuery window events instead
	// of direct bindings. Attach by adding the function on the "channel" event during ApeConnection initialization:
	//  var apeConnection = new ApeConnection(onConnectFunction, { channel: ApeConnection.jqMessageHandler });

	ApeConnection.jqChannelEventBridge = function (ape, meta) {
		var msg = meta.data, $ = window.jQuery;
		if (!$) {
			throw new Error('jqMessageHandler attempted to handle an event, but jQuery is not loaded');
		}

		$(window).trigger('meteor_'+msg.data.channel, {
			channel: msg.data.channel,
			json: msg.data.data,
			msgTime: msg.time
		}).trigger('meteor_all', {
			channel: msg.data.channel,
			json: msg.data.data,
			msgTime: msg.time
		});
	};

})();
