/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/*
  FORMATTER WIDGET: A wrapper around CUI.formatter to replace formatWidget.
  For the options format, see cui.formatter.js.
*/

(function( $ ){
	var formatWidget = $.extend(true, {}, $.ui.widget.prototype, CUI.formatter, {
		options: {
			accept_data_from_parent: true
		},

		fillData: function (d) {
			this.setValue(this.doFormat(d));
		}
	});

	add_widget('formatWidget', formatWidget);
})(jQuery);

