/* jshint jquery: true, unused: vars */
(function ($) {
	$.fn.isScrolledOff = function () {
		var offScreen = false;
		this.each(function () {
			var $curr = $(this);
			var myTop = $(this).offset().top;


			// Do-while loop because I want at least one pass where $curr is "body"
			do {
				$curr = $curr.offsetParent();

				if ($curr[0].scrollHeight > $curr.height()) {
					var viewTop = $curr.offset().top;
					var viewBtm = viewTop + $curr.height();
					if (myTop < viewTop || myTop > viewBtm) {
						offScreen = true;
					}
				}
			} while (!$curr.is('body') && !offScreen);

		});

		return offScreen;

	};

})(jQuery);
