/* jshint jquery: true, browser: true */
/* global classCUI */
/*
  Functions for quickly storing and retrieving mixin code on the global CUI object

  CUI.mixin.register(OBJECT obj, STRING name);
  CUI.mixin.get(STRING name); // RETURNS OBJECT
  CUI.mixin.mix(OBJECT obj, STRING name) // RETURNS OBJECT
*/

classCUI.prototype.mixin = {
	register: function (name, obj) {
		this._mixins = this._mixins || {};
		this._mixins[name] = obj;
		return this._mixins[name];
	},

	get: function (name) {
		// Used when including a mixin-- makes a copy
		// Ex: newWidget = $.extend(true, CUI.mixin.get('widget.mixWidget'), { ...

		var mixin = this._mixins[name];
		if (!mixin) { throw(new Error('Could not find mixin: ' + name)); }
		return $.extend(true, {}, mixin);
	},

	refTo: function (name) {
		// Used when trying to make method calls back to the original mixin-- do NOT use this in a case where the reference may be modified!
		// Ex: CUI.mixin.refTo('widget.mixWidget').origFn.apply(this, arguments);

		var mixin = this._mixins[name];
		if (!mixin) { throw('Could not find mixin: ' + name); }
		return mixin;
	},

	mix: function (a, name) {
		var mixin = this._mixins[name];
		if (!mixin) { throw('Could not find mixin: ' + name); }
		return $.extend(true, a, mixin);
	}
};
