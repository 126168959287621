/* jshint jquery: true, unused: vars */
/* global add_widget */
/* A single-purpose widget to show a line's number, with a class and TITLE attribute that shows the line status */

(function( $ ){
	var phoneButtonNumberStatusWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			states: {
				na: { text: 'Unknown', bgcolor: '#ccc', fgcolor: '#000' },
				UNREG: { text: 'Offline', fgcolor: '#333', bgcolor: '#ccc' },
				IDLE:  { text: 'Idle', bgcolor: '#000', fgcolor: '#fff' },
				HELD:  { text: 'On Hold', bgcolor: '#800', fgcolor: '#fff' },
				INUSE: { text: 'On Call', bgcolor: '#060', fgcolor: '#0f0' }
			}
		},

		fillData: function (d) {
			var self = this, $self = this.element, state, css = {};
			if (!d.bbx_phone_button_id) { return; } // Not the info we're looking for

			state = self.options.states[d.line_state] || self.options.states.na;

			$self.text(d.bbx_phone_button_position);
			$self.attr('title', state.text);

			css.color = state.fgcolor || '';
			css['background-color'] = state.bgcolor || '';
			css.border = state.border ? '1px solid ' + state.border : '';

			$self.css(css);
		}
	});

	add_widget('phoneButtonNumberStatusWidget', phoneButtonNumberStatusWidget);
})(jQuery);
