/* jshint browser: true, jquery: true */
/*
  $.fn.disable: Disable a form field, tag it with a specific "disabler", and do not allow re-enabling it until all "disablers" have been removed
               (note that disable/enable must always use these functions-- .attr is not overridden). This allows multiple functions to disable
	        an element, and prevents the element from being re-enabled until all interested parties are satisfied.

  USAGE (object param form):
  $(...).disable({ key: 'unique_name' });
  $(...).enable({ key: 'unique_name' | '_all' });

  (key param form):
  $(...).disable('unique_name');
  $(...).enable('unique_name' | '_all');

*/

(function($) {
	$.fn.disable = function(params) {
		var $elems = this;
		if (typeof params === 'string') {
			params = { key: params };
		}

		params = $.extend({ key: '_unspecified' }, params || {});
		$elems.each(function () {
			var $elem = $(this);

			var keys = $elem.data('disableKeys') || {};
			keys[params.key] = true;
			$elem
			.data('disableKeys', keys)
			.attr('disabled', 'disabled')
			.trigger('enable-disable', params.key);

		});
		return this;
	};

	$.fn.enable = function(params) {
		var $elems = this;
		if (typeof params === 'string') {
			params = { key: params };
		}

		params = $.extend({ key: '_unspecified' }, params || {});

		$elems.each(function () {
			var $elem = $(this);
			var keys = $elem.data('disableKeys') || {};
			delete keys[params.key];

			if ($.isEmptyObject(keys)) {
				$elem
				.removeAttr('disabled')
				.trigger('enable-disable', params.key);
			}

			$elem.data('disableKeys', keys);
		});
		return this;
	};
})(jQuery);
