/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){
	var num_sort = function (a,b) { return a-b; }; // Define it here so we're not needlessly creating it each time
			
	var phoneNumberTokenizerWidget = $.extend(true, {}, $.ui.textTokenizerWidget.prototype, {
		options: {
			allow_range: true,
			allow_plus: false,
			allow_star_pound: false,
			allow_sip: false
		},

		_processTokenText: function (value) {
			var self = this;
			return CUI.formatter.doFormat({ value: value }, { format: { field: '@value', formatter: 'phone_number', options: { ndash: true, no_auto_split: !self.options.allow_range } } });
		},

		_processTokenValue: function (value) {
			var self = this;

			if (value === null || value === undefined) {
				return undefined;
			}

			if (self.options.allow_sip && value.indexOf('sip:') === 0) {
				// Exit early, SIP URIs allow most anything
				return value;
			}

			if (self.options.allow_ranges && (value.match(/[0-9]+-/g) || []).length === 1) {
				value = value.replace(/[^-0-9]/g, '');
				if (value.search(/^[0-9]+-[0-9]+$/) !== 0) {
					return undefined;
				}
			} else {
				value = value.replace(/[^0-9*#]/g, '');
			}


			if (value === '') { return undefined; }

			return value;
		},

		_processTokenElement: function ($token, value) {
			var self = this;
			if (value.indexOf('-') > -1 && self.options.allow_range) {
				$token.css({ 'background-color': '#ccf' });
				$token.prepend('<img src="/images/textTokenizerWidget/block.png" width="12" height="10" style="padding-right: 0.5em; vertical-align: middle" alt="Block: " title="This is a Block incorporating multiple numbers" />');
				return $token;
			} else {
				return $token;
			}
		},

		_parseCurrentText: function (value, blur) {

			/* OVERRIDE ME! --------------------------------------------------------------------------------------------------------------------------------

	       This function takes the value of the textarea after each keypress, and returns an array of any new tokens, with the last element being the
	       new value of the textbox (any "leftovers"). If no new token should be added, return an array with the original value as the only element.

	       WARNINGS:
	         As an optimization, the current text in the textarea is only changed if tokens are returned.

	    */

			var self = this, split_rx, tokens, token, tokens_out = [], t_idx, dashes, parens;

			split_rx = '[ ,\n\t]+';
			tokens = value.split(new RegExp(split_rx));
			for (t_idx = 0; t_idx < tokens.length; t_idx++) {
				token = tokens[t_idx];
				if (token === '') {
					continue;
				}

				if (self.options.allow_sip && token.indexOf('sip:') === 0) {
					tokens_out.push(token);
				} else {
					// debugLog('token is', token);

					token = token.replace(/[^\*\#\.\+\(\)\-0-9]/g, '');
					if (!self.options.allow_star_pound) {
						token = token.replace(/[\*\#]/g, '');
					}
					// debugLog('Keep first strip:', token);

					dashes = (token.match(/-/g) || []).length;
					parens = (token.match(/[()]/g) || []).length;
					if (dashes !== 1 || parens > 0 || !self.options.allow_range) {
						// Prevent (555)666-7777 and 555-666-7777 from becoming ranges
						token = token.replace(/[^\*\#\+\+0-9]/g,'');

						if (!self.options.allow_plus) {
							token = token.replace(/\+/g,'');
						}

						if (!self.options.allow_star_pound) {
							token = token.replace(/[*#]/g,'');
						}

						// debugLog('Keep aggressive strip:', token);
					} else {
						token = token.replace(/[^-0-9]/g,'');

						// Make sure the parts are in order
						token = token
							.split(/-/)
							.sort(num_sort)
							.join('-');

						// debugLog('Keep dash-allowed strip:', token);
					}

					if (token !== '') { tokens_out.push(token); }
				}
			}

			if ((blur || value.match(new RegExp(split_rx + '$'))) && tokens_out.length) { tokens_out.push(''); }

			return tokens_out;
		}

	});
	add_widget('phoneNumberTokenizerWidget', phoneNumberTokenizerWidget);
})(jQuery);
