/*
  networkFirewallBlurbWidget: Build the blurb for which HTTP ports are blocked/allowed in the WAN firewall page.
*/

(function( $ ){
    var networkFirewallBlurbWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			template: false,
			template_selector: false,
			static_text: 'TCP 21, UDP 69, UDP 123',
			add_fields: [['http_port', 80]]
		},
		beforeDOMReady: function () {
			var self = this, $self = this.element;
			$self.addClass('info').text(self.getBlurb());
		},
		getBlurb: function () {
			var self = this, af = self.options.add_fields, out = [], data = self.options.data || {};
			out = self.options.add_fields.map(function (af) {
				var ports = ['TCP ' + af[1]];

				if (data[af[0]] && data[af[0]] != af[1]) {
					ports.push('TCP ' + data[af[0]]);
				}

				return ports.join(', ');
			});
			if (self.options.static_text) {
				out.unshift(self.options.static_text);
			}
			return (out.join(', '));
		},
		fillData: function (d) {
			var self = this, $self = this.element;
			self.options.data = $.extend({}, self.options.data, d);
			$self.addClass('info').text(self.getBlurb());
		}
    });
    
    add_widget('networkFirewallBlurbWidget', networkFirewallBlurbWidget);
})(jQuery);
