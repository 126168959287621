register_screen('agent_manager', {
    elements: [
	{ entity_template: "page_title", title: "Agent Manager" },
	{
	    entity_template: "page_buttons",
	    buttons: [{ type: "fullscreen" }, {'type': 'help', help_id:'switchboard_agent_manager'}],
	    elements: [
		{
		    entity: "button",
		    attributes: {
			"class": "page_button page_fullscreen_button"
		    },
		    widgets: [ "classToggleButtonWidget" ],
		    widget_options: {
			classToggleButtonWidget: {
			    text_off: "Hide Phones",
			    text_on: "Show Phones",
			    closest: ".screen",
			    find: ".agentmgr_agents",
			    css_class: "hide_phone_info"
			}
		    }
		}
	    ]
	},
	{ entity_template: "page_content",
	  "class": "agentmgr switchboard",
	  elements: [
	      {
		  entity_template: "container",
		  id: "agentmgr_agents",
		  "class": "agentmgr_agents hide_phone_info",
		  elements: [
		      {
			  entity_template: "agent_manager_table",
			  name: "agentmanager_agents"
		      },
		      {
			  entity_template: "wallboard_resizer",
			  min_font_size: 90,
			  max_font_size_docked: 200,
			  max_font_size_fullscreen: 250,
			  max_columns: 1,
			  bubble_width: 90,
			  bubble_height: 1.65,
			  include_offline: true
		      }
		  ]
	      }
	  ]
	}
    ]
});
