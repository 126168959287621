/* jshint jquery: true, unused: vars, -W018 */ // W018: "Confusing use of !"
/* global add_widget */
/* This class may be used as a base class by other page button widgets, or alone, to provide simple enable/disable based on dirty. */

(function( $ ){
    var dirtyPageButtonWidget = $.extend({}, $.ui.pageButtonWidget.prototype, {
	options: {
	    disable_on_dirty: true // Set to false to disable on clean
	},	
	_afterFormChange: function () {
	    var self = this, $self = this.element;
	    if (!!(self.options.disable_on_dirty) === !!(self.options.dirty)) {
		self.disable(self.options.widget_id);
	    } else {
		self.enable(self.options.widget_id);
	    }
	}
    });

    add_widget('dirtyPageButtonWidget', dirtyPageButtonWidget);
})(jQuery);
