/* jshint jquery: true, unused: vars */
/* global add_widget, Highcharts */
/*
 * <div class="widgetType irregularTimeChartWidget" data-js='{
 *     "live_table" : "system_stats",
 *     "key_name" : "cpu_usage",
 *     "key_column" : "stat_name",
 *     "value_column" : "stat_value"
 * }' ></div>
 *
 */

(function( $ ){
	var irregularTimeChartWidget = $.extend({}, $.ui.widget.prototype, {
		options: {
			renderTo: 'container-irregular',
			title: 'irregularTimeChart',
			yaxis_max: 100,
			units: '%',
			default_data: [{name: 'empty',
							data: [[1234,1]]}]
		},


		_afterInit: function() {
			var $self = this.element;
			var self = this;

			self.options.$container = $(document.createElement('div'));
			self.options.$container.prop('id', 'container-irregular');
			$self.append(self.options.$container);

			// Correct? to check for data exists and is defined.
			if (self.options.values) {
				self._redrawChart();
			}
		},


		_laOnChange: function(obj, args) {
			this._redrawChart();
		},


		fillData: function(d) {},

		setValue: function(key, v) {
			var self = this, $self = this.element, row;

			/*
			 * Update single piece of the pie, or multiple pieces of the pie.
			 */
			for ( row in self.options.values) {
				if (self.options.values[row].key == key ) {
					self.options.values[row].y = parseInt(v);
					break;
				}
			}
		},

		_redrawChart: function() {
			var self = this, $self = this.element, row, series;

			if (self.options.chart instanceof Highcharts.Chart) {
				self.options.$container.css('height', self.options.$container.css('height'));
			}

			var table_values;
			if (self.options.data && self.options.data.live) {
				/*
		 		 * Using liveTable data.
		 		 */
				for ( row in self.options.data.live) {
					if ( self.options.primary_fields[self.options.data.live[row].name]) {
						var series_pointer;
						for ( series in table_values ) {
							if ( table_values[series].name === self.options.labels[self.options.data.live[row].name] ) {
								series_pointer = series;
								break;
							}
						}

						/*
						 * If the series was found, then check size of series if too large, pop off the end, then push point.
						 * If not too large, then just push point.
						 * If the series is not found, then create a new series.
						 */
						if (table_values){
							if (series_pointer) {
								if (table_values[series_pointer].data.length > 20) {
									// Todo: add handling of too large of an array.
								} else {
									table_values[series_pointer].data.push([self.options.data.live[row].epoch,
																			self.options.data.live[row].value]);
								}
							} else {
								table_values.push({name: self.options.labels[self.options.data.live[row].name],
												   data:[[self.options.data.live[row].epoch, self.options.data.live[row].value]]
												  });
							}
						} else {
							table_values = [{name: self.options.labels[self.options.data.live[row].name],
											 data:[[self.options.data.live[row].epoch, self.options.data.live[row].value]]
											}];
						}
					}
				}
			} else {
				/*
		 * Using non liveTable data.
		 */
			}
			self.options.chart = new Highcharts.Chart({
				chart: {
					renderTo: self.options.renderTo,
					type: 'spline'
				},
				credits: {
					enabled: false
				},
				title: {
					text: self.options.title
				},
				tooltip: {
					formatter: function() {
						return 'details';
					}
				},
				xAxis: {
					type: 'datetime'
				},
				yAxis:{
					title: {
						text: '%'
					},
					min: 0,
					max: self.options.yaxis_max
				},
				series: table_values //self.options.default_data
			});
		}

	});
	add_widget('irregularTimeChartWidget', irregularTimeChartWidget);

})(jQuery);
