/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/* A single-purpose widget to display a cloned phone line's clone source, if there is one, in the User Phones Buttons table */

(function( $ ){
	var phoneCloneLookupWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			$phone_table: false
		},

		_beforeDOMReady: function () {
			var self = this, $self = this.element;
			var $page = $self.closest('.page_module');
			var $table = $page.find('.phoneButtonsDataTableWidget');

			if ($table[0]) { self.options.$phone_table = $table; }
		},

		fillData: function (d) {
			var self = this, $self = this.element;

			if (!d.bbx_phone_button_id) { return; } // Ignore: Inherited data from somewhere else
			if (self.options.$phone_table && self.options.$phone_table.hasClass('is-destroying')) { return; }  // bypass live data race condition

			if (d.bbx_phone_button_value !== self.options.last_phone_value) {
				self.options.last_phone_value = d.bbx_phone_button_value;
				if (d.bbx_phone_button_type === 'line') {
					// Re-lookup: The value has been changed, and is a line
					CUI.getREST('/gui/phone/button', { bbx_phone_button_id: d.bbx_phone_button_id }, self._applyNewValue.bind(self, d));
				} else {
					// Changed, but not a line (so it can't be a clone line)-- just show the value w/o a lookup
					self._applyNewValue(d, undefined);
				}
			}
		},

		_applyNewValue: function (row, rest_data) {
			var self = this, $self = this.element, data = rest_data ? rest_data.button : {}, from = '';

			if (self.options.destroyed) { return; }

			if (data.clone_line_number) {
				from = ' (from ' + data.clone_phone_name + ')';
			}

			$self.text(CUI.formatter.doFormat({ value: row.bbx_phone_button_value, from: from }, {
				format: '$1$2',
				fields: [
					{
						field: '@value',
						formatter: 'phone_number'
					},
					{
						field: '@from'
					}
				]
			}));
		}
	});

	add_widget('phoneCloneLookupWidget', phoneCloneLookupWidget);
})(jQuery);
