(function () {
    register_screen('firewall', {
	elements: [
	    { entity_template: 'page_title', title: 'Security' },
	    { entity_template: 'page_buttons', buttons: [{ type: 'cancel' }, { type: 'save' }] },
	    {
		entity_template: 'page_content', elements: [
		    {
			entity_template: 'page_module',
			legend: 'WAN Port Firewall Rules',
			form_action: '/gui/system/ip_acl',
			form_data_js: { rest_container: 'wan_ip_acl' },
			buttons: ['help'],
			help_id: 'wan_port_firewall',

			elements: [
			    {
				entity: 'p',
				attributes: { style: 'text-align: center; margin-bottom: 1em' },
				html: 'All WAN port traffic is blocked by default. ' + entity.ldquo + 'Allow' + entity.rdquo + ' rules must be added to allow WAN port traffic. IP ranges entered in this section override Allow/Block rules below.'
			    },
			    {
				entity_template: 'aopb_state',
				'class': 'additionalMessages',
				attributes: { hidden: 'hide-on-load' },
				watch_form_dirty: true,
				html: '<span class="general">Use the '+entity.ldquo+'Save'+entity.rdquo+' button at the top of the page to apply your changes to this table.</span>',
				closest: '.pageModuleWidget',
				watch_producer_dirty: true,
				states: [{
				    conditions: { a: '[name=wan_ip_acl]', a_is: 'element', op: '==', b: '.is-dirty', b_is: 'jq_is' },
				    actions: { visible: true },
				    else_actions: { visible: false }
				}]
			    },
			    {
				entity_template: 'data_table',
				data_source: 'ROW_SOURCE',
				name: 'wan_ip_acl',
				accept_data_from_parent: true,
				click_action: 'edit',
				primary_keys: [],
				filtering: false,
				length_change: false,
				paginate: false,
				processing: false,
				oLanguage: {
				    sZeroRecords: 'No WAN firewall rules.'
				},
				columns: [
				    {
					header: 'ACL IP Address',
					row_source: 'wan_acl_ip',
					edit_element: {
					    entity: 'input',
					    attributes: {
						name: 'wan_acl_ip',
						type: 'text',
						placeholder: 'WAN Address'
					    },
					    widgets: [ 'aopbStateWidget' ],
					    widget_options: {
						aopbStateWidget: {
						    closest: 'td',
						    states: [{
							conditions: { a: '$$', op: '==', b: '_IP_V4_' },
							actions: { invalid: false },
							else_actions: { invalid: 'Invalid WAN address' }
						    }]
						}
					    }
					}
				    },
				    {
					header: 'ACL Subnet Mask',
					row_source: 'wan_acl_netmask',
					edit_element: {
					    entity: 'input',
					    attributes: {
						name: 'wan_acl_netmask',
						type: 'text',
						placeholder: 'WAN Subnet Mask'
					    },
					    widgets: [ 'aopbStateWidget' ],
					    widget_options: {
						aopbStateWidget: {
						    closest: 'td',
						    states: [{
							conditions: { a: '$$', op: '==', b: '_IP_V4_MASK_' },
							actions: { invalid: false },
							else_actions: { invalid: 'Invalid WAN Subnet Mask' }
						    }]
						}
					    }
					}
				    },
				    {
					header: 'ACL Action',
					row_source: 'wan_acl_action',
					edit_element: {
					    entity: 'select',
					    attributes: {
						name: 'wan_acl_action'
					    },
					    select_options: [
						{
						    value: 'allow',
						    text: 'Allow',
						    selected: true
						}, {
						    value: 'block',
						    text: 'Block',
						    selected: false
						}
					    ]
					},
					view_element: {
					    entity: 'span',
					    widgets: [ 'formatWidget' ],
					    widget_options: {
						formatWidget: {
						    format: {
							field: '@wan_acl_action',
							formatter: 'uc'
						    }
						}
					    }
					}
				    }
				],
				row_actions: {
				    animate: false,
				    animate_speed: 'slow',
				    type: 'expand',
				    edit_save: { text: 'Save' },
				    edit_cancel: { text: 'Cancel' },
				    add_save: { text: 'Add' },
				    add_cancel: { text: 'Cancel' },
				    delete_row: {
					text: 'Delete',
					confirm: {
					    title: 'Delete WAN Firewall Rule',
					    text: 'Are you sure you want to remove the WAN firewall rule?'
					}
				    }
				},
				table_actions: {
				    add_row: {
					text: 'Add WAN Firewall Rule',
					location: 'bottom'
				    },
				    delete_rows: {
					text: 'Delete Selected',
					location: 'bottom',
					confirm: {
					    title: 'Delete Selected',
					    text: 'Are you sure you want to remove the selected WAN firewall rules?'
					}
				    },
				    select_row: true,
				    select_all_rows: true,
				    delete_row: { text: 'Delete' }
				}
			    } // END DTW
			]
		    }, // END page_module 1
		    {
			entity_template: 'page_module',
			legend: 'WAN Port Blocking',
			form_action: '/gui/system/wan_firewall',
			buttons: ['help'],
			help_id: 'wan_port_blocking',

			elements: [
			    {
					entity_template: 'data_item',
					title: 'Phone Provisioning, Web Management:',
					elements: [
						{
							entity: 'select',
							attributes: { name: 'wan_basic_mgmt' },
							select_options: [
								{ value: 'block', text: 'Block' },
								{ value: 'allow', text: 'Allow' }
							]
						},
						{
							entity: 'div',
							widgets: ['networkFirewallBlurbWidget'],
							widget_options: {
								networkFirewallBlurbWidget: {
									static_text: 'TCP 21, UDP 69, UDP 123',
									add_fields: [['http_port', 80]]
								}
							}
						}
					]
			    },
			    {
				entity_template: 'input_item',
				type: 'select',
				title: 'Secondary Web Management Port:',
				info: 'TCP 8000',
				input_name: 'wan_compat_mgmt',
				select_options: [
				    { value: 'block', text: 'Block' },
				    { value: 'allow', text: 'Allow' }
				]
			    },
			    {
					entity_template: 'data_item',
					title: 'Secure Web Management:',
					elements: [
						{
							entity: 'select',
							input_name: 'wan_secure_mgmt',
							select_options: [
								{ value: 'block', text: 'Block' },
								{ value: 'allow', text: 'Allow' }
							]
						},
						{
							entity: 'div',
							widgets: ['networkFirewallBlurbWidget'],
							widget_options: {
								networkFirewallBlurbWidget: {
									static_text: '',
									add_fields: [['https_port', 443]]
								}
							}
						}
					]
			    },
			    {
				entity_template: 'input_item',
				type: 'select',
				title: 'Network Time Protocol:',
				info: 'UDP 123',
				input_name: 'wan_ntp',
				select_options: [
				    { value: 'block', text: 'Block' },
				    { value: 'allow', text: 'Allow' }
				]
			    },
			    {
				entity_template: 'input_item',
				type: 'select',
				title: 'Telephone Service Providers:',
				info: 'TCP+UDP 5060, UDP 16384' + entity.ndash + '32768',
				input_name: 'wan_service_providers',
				select_options: [
				    { value: 'block', text: 'Block' },
				    { value: 'allow', text: 'Allow' }
				]
			    },
			    {
				entity_template: 'input_item',
				type: 'select',
				title: 'External Phones:',
				info: 'TCP 2000, TCP+UDP 5065, UDP 16384' + entity.ndash + '32768',
				input_name: 'wan_external_phones',
				select_options: [
				    { value: 'block', text: 'Block' },
				    { value: 'allow', text: 'Allow' }
				]
			    }
			]
		    } // END page_module 2
		] // END page_content elements
	    } // END page_content
	] // END template elements
    }); // END template
})(); // END scoping function
