/* jshint jquery: true */
/* global widgetize_children, browserClasses */
/* exported logout, validUsername, validUserID, loginData, SUPPRESS_ERROR_DISPLAY, CONNECTIVITY_BLANKER */

// init.js by Rudy Fleminger
// Functions and handlers for initializing on page load or change
// TODO simplify this to act more like we do in the communication command center login process

var validUsername = false; // For display purposes only.
var validUserID = false;
var loginData = {};
var SUPPRESS_ERROR_DISPLAY, DEBUGMODE;

if (typeof CONNECTIVITY_BLANKER === 'undefined') {
	var CONNECTIVITY_BLANKER = false;
}

function logout()
{
	"use strict";
	SUPPRESS_ERROR_DISPLAY = true; // Because the backend logout can beat the frontend refresh

	$.ajax({
		url: '/gui/login/logout',
		type: 'POST',
		complete: function () {
			location.reload();
		}
	});
	$(window).trigger('logout');
}

$(document).ready(function () {
	"use strict";
	var ua_match, version;

	eraseCookie('xsrfkey');

	// If this needs to be updated in the future, take note:
	// This check will NOT detect or distinguish IE 11 or later ("MSIE" was removed from the UA in IE11)
	ua_match = navigator.userAgent.match(/MSIE ([\d\.]+)/);
	if (ua_match) {
		version = Number(ua_match[1]);
		if (version < 9) {
			location.href = '/errors/unsupported.html';
			return;
		}
	}

	if (location.search.indexOf('debugmode') > -1) {
		DEBUGMODE = true; // global
		$('body').addClass('debugmode'); // used for CSS debug
	}

	widgetize_children($('#cui-content-inner'));
});
