/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){
	var overlayButtonWidget = $.extend({}, $.ui.widget.prototype, {
		options: {
			overlay: undefined,
			params: {},
			data_to_params: [],
			pass_params: undefined,
			pass_all_rest_params: false
		},

		fillData: function (d, from_self) {
			var self = this, p_idx;
			if (typeof d === 'object' && self.options.data_to_params.length) {
				for (p_idx = 0; p_idx < self.options.data_to_params.length; p_idx++) {
					if (self.options.data_to_params[p_idx] in d) {
						self.options.params[self.options.data_to_params[p_idx]] = d[self.options.data_to_params[p_idx]];
					}
				}
			}
		},

		_afterInit: function() {
			var self = this, $self = this.element;
			$self.addClass('overlayButtonWidgetType');
			self._bind($self, 'click', self._clickHandler.bind(self));
		},

		_clickHandler: function(e) {
			var self = this, $self = this.element, params = self.options.params || {}, $scr, scr_w, pp_idx;

			if (self.options.pass_all_rest_params && self.options.rest_params) {
				params = self.options.rest_params;
			}

			if (self.options.pass_params) {
				$scr = $self.closest('.overlay,.pageWidgetType');
				scr_w = $scr.getCUIWidget('pageWidget') || $scr.getCUIWidget();
				if (scr_w.options.rest_params) {
					for (pp_idx = 0; pp_idx < self.options.pass_params.length; pp_idx++) {
						if (self.options.pass_params[pp_idx] in scr_w.options.rest_params) {
							params[self.options.pass_params[pp_idx]] = params[self.options.pass_params[pp_idx]] || scr_w.options.rest_params[self.options.pass_params[pp_idx]];
						}
					}
				}
			}

			this.element.closest('.pageWidgetType').getCUIWidget('pageWidget').showOverlay(this.options.overlay, params, {}, this.element);
		}
	});

	add_widget('overlayButtonWidget', overlayButtonWidget);

})(jQuery);
