(function () {
    register_screen('ldap_undelete', {
	elements: [
	    { entity_template: 'page_title', title: 'Manage Deleted People' },
	    {
		entity_template: 'page_content', elements: [
		    {
			entity_template: 'page_module',
			legend: 'Deleted People',
			buttons: ['help'],
			help_id: 'ldap_deleted_users',
			elements: [
			    {
				entity_template: 'data_table',
				rest: '/gui/sync/deleted_users',
				data_source: 'deleted_users',
				primary_keys: ['bbx_user_id'],
				extra_row_data: ['bbx_user_id'],
				delete_action: { rest: '/gui/sync/undelete_user' },
				table_actions: {
				    select_row: true,
				    select_all_rows: true,

				    refresh: {
					text: 'Refresh',
					location: 'both'
				    },
				    delete_rows: {
					text: 'Undelete Selected People',
					location: 'both',
					confirm: false
				    }
				},

				columns: [
				    {
					column: 'bbx_user_full_name',
					sortable: false
				    }
				]
			    }
			]
		    }
		]
	    }
	]
    });
})();
