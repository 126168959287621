/* jshint jquery: true, unused: vars */
/* global add_widget, entity, cmp */
/* Display router rules (days, time, regex) in a form suitable for viewing (not editing). Apply to a SPAN or any other container element */

(function( $ ){
	var routerRuleDisplayWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
		},

		_beforeInit: function () {
			return $.ui.widget.prototype._beforeInit.apply(this, arguments);
		},

		fillData: function (d) {
			var self = this, $self = this.element, rdays, rtimes, doy, doy_parts, doy_components, days, times, hour, min, pm, day_names, match_types, curr_output = '', output = [], match, idx, inseq, month_names;
			if (!d.bbx_router_condition_map_id) { return; }
			
			var push_output = function () {
				if (curr_output) {
					output.push(curr_output);
					curr_output = '';
				}
			};

			day_names = [undefined, 'Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
			match_types = {
				_unknown: 'Unknown match type with subject:',
				cid_start: 'only if the caller'+entity.rsquo+'s number starts with',
				cid_end: 'only if the caller'+entity.rsquo+'s number ends with',
				cid_exact: 'only if the caller'+entity.rsquo+'s number is exactly',
				cid_regex: 'if the caller'+entity.rsquo+'s number matches the Regular Expression'
			};

			rdays = d.bbx_router_condition_map_tod_wday;
			doy = d.bbx_router_condition_map_recurring_date || d.bbx_router_condition_map_specific_date;
			rtimes = d.bbx_router_condition_map_tod_min_day;

			month_names = ['January','February','March','April','May','June','July','August','September','October','November','December'];

			if (d.bbx_mode_names && d.bbx_mode_names.length) {
				curr_output = 'mode' + (d.bbx_mode_names.length > 1 ? 's ' : ' ') + entity.ldquo;
				if (d.bbx_mode_names.length === 2) {
					curr_output += d.bbx_mode_names[0] + entity.rdquo + ' or ' + entity.ldquo + d.bbx_mode_names[1] + entity.rdquo;
				} else {
					curr_output += d.bbx_mode_names.join(entity.rdquo + ', ' + entity.ldquo) + entity.rdquo;
				}
			} else {
				curr_output = 'all modes';
			}

			push_output();

			if (doy) {
				doy_parts = doy.split('-');
				doy_components = doy_parts[0].split('/');
				curr_output += (month_names[Number(doy_components[0]) - 1] + ' ' + Number(doy_components[1]) + (doy_components[2] ? ', ' + doy_components[2] : ''));

				// Careful: Legacy dates may not be ranges!
				doy_components = doy_parts[1] ? doy_parts[1].split('/') : undefined;
				if (doy_components && doy_parts[0] !== doy_parts[1]) {
					curr_output += entity.ndash + (month_names[Number(doy_components[0]) - 1] + ' ' + Number(doy_components[1]) + (doy_components[2] ? ', ' + doy_components[2] : ''));
				}
			} else if (rdays) {
				match = rdays.match(/^(\d+)-(\d+)$/);
				if (match) {
					// Simple (legacy) date range
					if (match[1] === match[2]) {
						// Single days on legacy ACRs are expressed as a range like "3-3". Only show the day once.
						curr_output += day_names[match[1]];
					} else {
						// This is an actual legacy-style range.
						curr_output += day_names[match[1]] + entity.ndash + day_names[match[2]];
					}
				} else if (rdays.search(/^[\d,]+$/) > -1) {
					// Complex (new-style) date range
					days = rdays.split(',').sort(function (a,b) { return cmp(Number(a),Number(b)); });
					dayLoop: for (idx = 0; idx < days.length; idx++) {
						days[idx] = Number(days[idx]);

						if (inseq) {
							if (days[idx+1] && Number(days[idx+1]) === days[idx] + 1) {
								continue dayLoop;
							} else {
								inseq = false;
								curr_output += entity.ndash + day_names[days[idx]];
							}
						} else {
							curr_output += (idx ? ', ' : '') + day_names[days[idx]];

							if (days[idx+1] && Number(days[idx+1]) === days[idx] + 1) {
								inseq = true;
							}
						}
					}
				} else {
					curr_output += '(Unknown day range)';
				}
			} else {
				curr_output = 'any day';
			}

			push_output();

			if (rtimes) {
				times = rtimes.split('-');

				for (idx = 0; idx < 2; idx++) {
					pm = false;
					hour = Math.floor(Number(times[idx]) / 60);

					if (hour === 0) {
						hour = 12;
					} else if (hour === 12) {
						pm = true;
					} else if (hour > 12) {
						hour -= 12;
						pm = true;
					}

					min = times[idx] % 60;
					if (min < 10) { min = '0' + min; }

					curr_output += hour + ':' + min + (pm ? 'PM' : 'AM');

					if (idx === 0) { curr_output += entity.ndash; }
				}
			} else {
				curr_output = 'any time';
			}

			push_output();

			// cid_start cid_end cid_exact cid_regex
			if (d.bbx_router_condition_map_regex_metadata) {
				curr_output += match_types[d.bbx_router_condition_map_regex_metadata] + ' ' + entity.ldquo;
				switch (d.bbx_router_condition_map_regex_metadata) {
					case 'cid_start':
						curr_output += d.bbx_router_condition_map_regex_val.replace(/^\^/, '');
						break;
					case 'cid_end':
						curr_output += d.bbx_router_condition_map_regex_val.replace(/\$$/, '');
						break;
					case 'cid_exact':
						curr_output += d.bbx_router_condition_map_regex_val.replace(/^\^(.*)\$$/, '$1');
						break;
					default:
						curr_output += d.bbx_router_condition_map_regex_val;
				}

				curr_output += entity.rdquo;
			} else {
				curr_output = 'any caller';
			}

			push_output();

			curr_output = output.join(', ');

			// Capitalize
			curr_output = curr_output.charAt(0).toUpperCase() + curr_output.slice(1);
			$self.text(curr_output);
		}
	});

	add_widget('routerRuleDisplayWidget', routerRuleDisplayWidget);
})(jQuery);
