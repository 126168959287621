/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/*
  A widget to show the current time directly from the server, refreshed every 30s or after a containing form submits. Used on the System Configuration page.
  Apply to a DIV.
*/

(function( $ ){
	var timeCheckWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			update_frequency: 30000
		},

		_beforeDOMReady: function () {
			var self = this, $self = this.element, $form;

			$form = $self.closest('form');
			if ($form.length) {
				self._bind($form, 'SubmitSuccess', self._update.bind(self));
			}

			self._setInterval(self._update.bind(self), self.options.update_frequency);

			self._update();
		},

		_update: function () {
			var self = this;
			if (self.options.destroyed) { return; }
			CUI.getREST('/gui/system/timezone', {}, self._setTime.bind(self));
		},

		_setTime: function (d) {
			var self = this, $self = this.element, time = d.timezone.time, offset = d.timezone.offset, zone = d.timezone.timezone, offset_date, date_string;
			if (self.options.destroyed) { return; }
			// This will make a GMT time that is equal to the local time on the server. This Date object is technically wrong by -(offset)...
			offset_date = new Date((time + offset) * 1000);
			// ...but we read it as GMT, then lop of the "GMT" from the string, and put in the zone we have.
			date_string = offset_date.toUTCString().replace(/:\d{2} GMT$/, ' (' + zone + ')');

			$self.text(date_string);
		}
	});

	add_widget('timeCheckWidget', timeCheckWidget);
})(jQuery);
