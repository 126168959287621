/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){
	var backupStatusWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			image: '/images/cui/spinner_content.gif',
			closest: 'dd',
			start_button: 'button.backup_start',
			stop_button: 'button.backup_stop'
		},

		_beforeInit: function() {
			var self = this, $self = self.element;

			self.options.$start = $self.closest(self.options.closest).find(self.options.start_button);
			self.options.start = self.options.$start.getCUIWidget('actionButtonWidget');
			self.options.$stop = $self.closest(self.options.closest).find(self.options.stop_button);
			self.options.stop = self.options.$stop.getCUIWidget('actionButtonWidget');

			self._bind(self.options.$start, 'click', self._buttonClickHandler.bind(self));
			self._bind(self.options.$stop, 'click', self._buttonClickHandler.bind(self));
		},

		_beforeDOMReady: function() {
			var self = this, $self = this.element;

			$self.html($('<img>').attr('src', self.options.image));
			self.options.$image = $self.find('img');
			self.options.$image.hide();

			self._disableBothButtons();
		},

		_buttonClickHandler: function(e) {
			var self = this, $self = self.element;

			// Disable both buttons, the appropriate button will be enabled during fillData when a status update is received

			self._disableBothButtons();

			// Show spinner because we've clicked either stop or start

			self.options.$image.show();
		},

		_disableStartButton: function(key) {
			var self = this, $self = this.element;
			if (self.options.$start) {
				self.options.start.setState('enabled', false, true, key);
			}
		},

		_enableStartButton: function(key) {
			var self = this, $self = this.element;
			if (self.options.$start) {
				self.options.start.setState('enabled', true, true, key);
			}
		},

		_disableStopButton: function(key) {
			var self = this, $self = this.element;
			if (self.options.$stop) {
				self.options.stop.setState('enabled', false, true, key);
			}
		},

		_enableStopButton: function(key) {
			var self = this, $self = this.element;
			if (self.options.$stop) {
				self.options.stop.setState('enabled', true, true, key);
			}
		},

		_disableBothButtons: function(key) {
			var self = this, $self = this.element;

			key = key || 'status-widget';
			self._disableStopButton(key);
			self._disableStartButton(key);
		},

		_enableBothButtons: function(key) {
			var self = this, $self = this.element;

			key = key || 'status-widget';
			self._enableStopButton(key);
			self._enableStartButton(key);
		},

		_started: function() {
			var self = this, $self = this.element;
			self._enableStopButton('status-widget');
			self._disableStartButton('status-widget');
		},

		_stopped: function() {
			var self = this, $self = this.element;
			self._enableStartButton('status-widget');
			self._disableStopButton('status-widget');
		},

		fillData: function(d) {
			var self = this, $self = this.element;

			if (d.error) {
				self._stopped();
				self.options.$image.hide();
			} else if (d.backup_status) {
				self.options.backup_status = d.backup_status;

				switch(d.backup_status) {
					case 'CANCELED':
					case 'ERROR':
					case 'UNKNOWN':
					case 'COMPLETE':
						self._stopped();
						self.options.$image.hide();
						break;
					case 'SCHEDULED':
					case 'RUNNING':
						self._started();
						self.options.$image.show();
						break;
				}
			}

			$.ui.widget.prototype.fillData.apply(self, arguments);
		},

		_destroy: function() {
			var self = this, $self = self.element;
			self._unbind(self.options.$start, 'click');
			self._unbind(self.options.$stop, 'click');
		}
	});

	add_widget('backupStatusWidget', backupStatusWidget);
})(jQuery);
