/* jshint jquery: true, unused: vars */
/* global add_widget */
(function( $ ){
	var removePhoneActionButtonWidget = $.extend(true, {}, $.ui.actionButtonWidget.prototype, {
		options: {
		},

		_afterConfirm: function () {
			var self = this, $self = this.element, $screen, $phone_table, $destroyActionWidget;

			$screen = $self.closest('.cui-page-content');
			$phone_table = $screen.find('.phoneButtonsDataTableWidget');
			$destroyActionWidget = $screen.find('.destroyActionWidget');

			if ($phone_table[0]) { $phone_table.addClass('is-destroying'); }
			if ($destroyActionWidget[0]) { $destroyActionWidget.addClass('stop-action'); }

			$.ui.actionButtonWidget.prototype._afterConfirm.apply(self, arguments);
		}
	});

	add_widget('removePhoneActionButtonWidget', removePhoneActionButtonWidget);
})(jQuery);
