/* jshint jquery: true, browser: true */
/* global classCUI */
(function ($) {
	/*

      A bare-DOM method to set an element's text. Do not use this if the element in question may have DOM objects with attached data, as there is no jQuery
      garbage collection run on the nodes, and if jQuery data does exist on the overwritten nodes, memory leak may occur.

     */

	classCUI.prototype.quickSetText = function (elem, content) {
		var node;

		node = (elem instanceof jQuery) ? elem[0] : elem;

		if (!node) { return elem; }

		if (typeof node.textContent !== 'undefined') {
			node.textContent = content;
		} else if (typeof node.innnerText !== 'undefined') {
			node.innerText = content;
		} else {
			$(elem).text(content || '');
		}

		return elem;
	};
})(jQuery);
