/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, entity */
(function( $ ){
	var auto_attendant_actions = {
		'': 'Do Nothing',
		'menu-exec-app-transfer': 'Transfer to an Extension or Number',
		'menu-exec-app-user-voicemail': 'Transfer to a Person' + entity.rsquo + 's Voicemail',
		'menu-top': 'Go to the Top Menu',
		'menu-back': 'Go Back One Menu',
		'menu-exit': 'Hang Up',
		'menu-sub': 'Go to Another Automated Attendant',
		'menu-exec-app-directory': 'Dial-By-Name Directory',
		'menu-exec-app-playback': 'Play a Sound File',
		'menu-exec-app-playback-hangup': 'Play a Sound File, then Hang Up'
	};

	var needs_detail = {
		'menu-exec-app-user-voicemail' : true,
		'menu-exec-app-transfer': true,
		'menu-sub': true,
		'menu-exec-app-playback': true,
		'menu-exec-app-playback-hangup': true
	};

	var digitActionSummaryWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			db_table: 'bbx_autoattendant_key_action_', // the database table that contains the AA's key actions
			summary: [],
			$action_elements: {},
			action_values: {},
			dirty_actions: {},
			$key_count: {},
			key_args: {}
		},

		_beforeInit : function () {
			var self = this, $self = this.element, key, letters, key_text, $dl = $('<dl>'), $el, el_idx;
			$self.addClass("digitActionSummaryWidgetType");

			letters = ['', 'ABC', 'DEF', 'GHI', 'JKL', 'MNO', 'PQRS', 'TUV', 'WXYZ', 'OPER', '', ''];

			for (el_idx = 1; el_idx <= 12; el_idx++) {
				switch (el_idx) {
					case 10:
						key = key_text = 0;
						break;
					case 11:
						key = 'pound';
						key_text = '#';
						break;
					case 12:
						key = 'star';
						key_text = '*';
						break;
					default:
						key = key_text = el_idx;
				}

				$el = $('<dt><span class="dialPadButtonStyle" style="display: inline-block"><span class="dialPadNum">' + key_text + '</span><span class="dialPadLett">' + letters[el_idx-1] + '</span></span></dt>' +
						'<dd><span class="key_action"></span><span class="key_arg"></span><span class="info key_count"</span></dd>');

				self.options.$action_elements['bbx_auto_attendant_key_action_' + key] = $el;
				self.options.key_args['bbx_auto_attendant_key_arg_' + key] = '';
				$dl.append($el);
			}
			$self.append($dl);

			self._delegate(
				$self.closest('form').find('.theDialPad').closest('.page_module'),
				':input',
				'change',
				self._handleActionChange.bind(self)
			);

			self._bind($self.closest('form'), 'SubmitSuccess', self._refreshSummary.bind(self));
		},

		_handleActionChange: function (e, d) {
			var self = this, $self = this.element, target_name, $target = $(e.target), $to_update, $span, new_val, new_text;
			target_name = $target.attr('name');
			new_val = $target.val();

			// update the key action text
			if (target_name && self.options.$action_elements[target_name]) {
				if (new_val === self.options.action_values[target_name]) { return; }
				self.options.action_values[target_name] = new_val;
				$to_update = self.options.$action_elements[target_name];
				$span = $to_update.find('.key_action');
				new_text = auto_attendant_actions[new_val];
				$span.text(new_text);
				$span = $to_update.find('.key_arg');
				$span.text('');
				self.computeDirtyState();
			}
		},

		_refreshSummary: function (e, d) {
			var self = this;
			setTimeout(function () {
				var formWidget = $(e.target).getCUIWidget('formWidget');
				if (formWidget) { formWidget.refresh(); }
			}, 200);
		},

		computeDirtyState: function() {
			var self = this, $self = this.element, key, orig_value, current_value;
			self.options.dirty = false;

			if (self.options.action_values && self.options.data) {
				for (key in self.options.action_values) {
					orig_value = self.options.data[key];
					current_value = self.options.action_values[key];
					if (orig_value === current_value) {
						self.options.dirty_actions[key] = false;
					} else {
						self.options.dirty = true;
						self.options.dirty_actions[key] = true;
					}
				}
			}
			if (self.options.dirty) {
				$self.addClass('is-dirty');
				$self.trigger('dirty');
			} else {
				$self.removeClass('is-dirty');
				$self.trigger('undirty');
			}
		},

		fillData: function (d) {
			var self = this, $self = this.element, $to_set, $div, val, count_key, count_val, key_arg, arg_val;
			self.options.data = $.extend(true, self.options.data, d);

			for (var key in d) {
				// jshint -W084
				if ($to_set = self.options.$action_elements[key]) { 
				// jshint +W084
					
					// set the action on the digit
					$div = $to_set.find('.key_action');
					val = d[key];
					$div.text(auto_attendant_actions[val]);
					self.options.action_values[key] = val;

					// set the count on the digit
					count_key = key.replace('action', 'count');
					count_val = d[count_key];
					if (count_val || self.options.$key_count[count_key]) {
						$div = $to_set.find('.key_count');
						if (count_val) {
							$div.text(' - visits: ' + count_val);
						}
						else {
							$div.text('');
						}
						self.options.$key_count[count_key] = count_val;
					}

					if (needs_detail[val]) {
						key_arg = key.replace('action', 'data');
						arg_val = d[key_arg];
						$div = $to_set.find('.key_arg');
						$div.text(' (' + arg_val + ') ');
						self.options.key_args[key_arg] = arg_val;
					}
				}
			}
			self.computeDirtyState();
		}
	});

	add_widget('digitActionSummaryWidget', digitActionSummaryWidget);
})(jQuery);
