/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){
	var soundMusicClassListWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			selector: undefined,            // The selector for the dataTableWidget we are binding to
			key_name: "bbx_moh_id",         // The name of the key that represents this sound/music class identifier; ex: 'bbx_moh_id'
			class_name_key: 'bbx_moh_name', //
			file_key_name: 'bbx_moh_file_id',
			system_flag: "bbx_moh_system",  // The name of the key that designates 'system' classes
			rest_path: '/gui/moh/file',     // REST path for moving files between classes; ex: '/gui/moh/file'
			change_class_on_move: false,    // Should dropping a file on this class change the selected class to this one?
			class_description: 'class'      // How should we refer to the collection of files?
		},

		_beforeInit: function() {
			var self = this, $self = self.element, $page;

			$page = $self.closest('.pageWidgetType');

			// For delegation binding/unbinding
			self.options.unique = 'unique_' + Math.floor((Math.random()*10000)+1);

			if (!$page.hasClass('screenReady')) {
				self._one($page, 'screenReady', self._screenReadyHandler.bind(self));
			} else {
				self._screenReadyHandler();
			}
		},

		_screenReadyHandler: function () {
			var self = this, $self = this.element;

			self._setupDelegations();

			// Setup our linked dataTableWidget so we can modify its rest_params later

			if (self.options.selector) {
				var $closest = $self.closest('.pageWidgetType');
				var $linked = $closest.find(self.options.selector);

				if ($linked[0]) {
					self.options._$target_widget = $linked;
					self.options._target_widget = $linked.getCUIWidget('dataTableWidget');

					if (self.options._target_widget.options.rest_params[self.options.key_name]) {
						self.options.selected_id = self.options._target_widget.options.rest_params[self.options.key_name];
					}
				}
			}
		},

		_setupDelegations: function() {
			var self = this, $self = self.element;

			self._delegate(
				$self.closest('.page_module'),
				'button.sound_music_class_rename', 
				'click.' + self.options.unique,
					self._renameClickHandler.bind(self)
			);

			self._delegate(
				$self.closest('.page_module'),
				'button.sound_music_class_delete', 
				'click.' + self.options.unique, 
				self._deleteClickHandler.bind(self)
			);

			self._delegate(
				$('body'),
				'form.sound_music_class', 
				'SubmitSuccess', 
					self._formSubmitSuccessHandler.bind(self)
			);
		},

		_doUndelegations: function() {
			var self = this, $self = self.element;

			self._undelegate(
				$self.closest('.page_module'),
				'button.sound_music_class_rename', 
				'click.' + self.options.unique
			);

			self._undelegate(
				$self.closest('.page_module'),
				'button.sound_music_class_delete', 
				'click.' + self.options.unique 
			);

			self._undelegate(
				$('body'),
				'form.sound_music_class', 
				'SubmitSuccess.' + self.options.unique
			);

			self._undelegate(
				$('body'),
				'form.sound_music_class_delete', 
				'SubmitSuccess.' + self.options.unique
			);

		},

		_formSubmitSuccessHandler: function() {
			var self = this, $self = self.element;
			if ($self.getCUIWidget('dataTableWidget')) {
				$self.getCUIWidget('dataTableWidget').refresh();
			}
		},

		_switchToDefault: function(selected) {
			var self = this, $self = self.element, $default;	    

			// Switch to the first class that isn't the previously selected one (since that one may have been deleted, for instance)

			$self.find('.soundMusicClassWidget').each(function(index, elem){
				if (typeof selected === 'undefined' || $(this).getCUIWidget('soundMusicClassWidget').options.id != selected) {
					$default = $(this);
				}
			});

			if ($default) {
				self.changeClass($default.getCUIWidget('soundMusicClassWidget'));
			} else {
				self.changeClass(null);
			}
		},

		_renameClickHandler: function(event, ui) {
			var self = this, $self = self.element, $form = $('body').find('form.sound_music_class_rename' ), $hidden_input;
			if (!self.options.selected_id) {
				$form.closest('.CUIDialog').find('.form-dialog-submit').addClass('state-disabled').disable();
				$form.html('There is no ' + self.options.class_description  + ' selected.');
			} else {
				$hidden_input = $('<input>').attr('type','hidden').attr('name',self.options.key_name).val(self.options.selected_id);
				$form.find('input[name="' + self.options.key_name + '"]').remove();
				$form.append($hidden_input);
			}
		},

		_deleteClickHandler: function(event, ui) {
			var self = this, $self = self.element, $form = $('body').find('form.sound_music_class_delete'), $hidden_input;
			if (!self.options.selected_id) {
				$form.closest('.CUIDialog').find('.form-dialog-submit').addClass('state-disabled').disable();
				$form.html('There is no ' + self.options.class_description  + ' selected.');
			} else {
				$form.addClass('is-dirty');
				$form.find('input[name="' + self.options.key_name + '"]').remove();
				$hidden_input = $('<input>').attr('type','hidden').attr('name',self.options.key_name).val(self.options.selected_id);
				$form.append($hidden_input);
				$form.one('SubmitSuccess', self._switchToDefault.bind(self, self.options.selected_id));
			}
		},

		changeClass: function(classWidget) {
			var self = this, $self = self.element, target = self.options._target_widget, id, form_dialog_buttons, params;

			if (classWidget) {
				id = classWidget.options.id;
				self.options._selected_widget = classWidget;
			} else {
				id = 0;
				self.options._selected_widget = null;
				self.options.selected_id = 0;
			}

			self.options.selected_id = id;

			// If our target has been set up, modify the target's rest_params
			if (target && typeof id !== 'undefined') {

				target.element.find('.soundPlayerWidget').each(function(){
					$(this).getCUIWidget('soundPlayerWidget')._destroy();
				});

				target.options.rest_params[self.options.key_name] = id;

				if (!target.options.rest) {
					target.options.rest = self.options.rest_path;
				}

				target.refresh();

				// Make sure that target DTW's add file/stream buttons have the proper rest_params

				form_dialog_buttons = self.options._$target_widget.closest('.cui-table-datatables-wrapper').find('.formDialogButtonWidget');

				form_dialog_buttons.each(function(index, elem){
					var widget = $(this).getCUIWidget('formDialogButtonWidget');
					if (widget) {
						params = widget.options.form_options.rest_params || {};
						params[self.options.key_name] = id;
						widget.options.form_options.rest_params = params;
					}
				});

				target.element.getCUIWidget('soundMusicFileListWidget').toggleFileList();
			}

			if (id > 0) {
				self.setLegendText(classWidget.getName());
			} else {
				self.setLegendText('No ' + self.options.class_description.charAt(0).toUpperCase() + self.options.class_description.slice(1)  + ' Selected');
			}
		},

		setLegendText: function(text) {
			var self = this, $self = self.element;

			if (self.options._$target_widget) {
				self.options._$target_widget.getCUIWidget('soundMusicFileListWidget').setLegendText(text);
			}
		},

		disableDTWButtons: function() {
			var self = this, $self = self.element;
			$self.closest('.cui-table-datatables-wrapper').find('button.sound_music_class_delete').addClass('state-disabled').disable();
			$self.closest('.cui-table-datatables-wrapper').find('button.sound_music_class_rename').addClass('state-disabled').disable();
		},

		enableDTWButtons: function() {
			var self = this, $self = self.element;
			$self.closest('.cui-table-datatables-wrapper').find('button.sound_music_class_delete').removeClass('state-disabled').enable();
			$self.closest('.cui-table-datatables-wrapper').find('button.sound_music_class_rename').removeClass('state-disabled').enable();
		},

		_destroy: function() {
			var self = this, $self = self.element;

			self._doUndelegations();
		}
	});

	add_widget('soundMusicClassListWidget', soundMusicClassListWidget);
})(jQuery);
