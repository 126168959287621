/* jshint jquery: true, unused: vars */
/* global add_widget */
/*
Maps input values (via fillData) to static output values, using either regexp or string matches. The first match is the one used.

OPTIONS FORMAT:
{
    name: 'input_name',
    render: 'html' | 'text' | 'value' | 'setValue',
    maps: [
	{ match: '...', output: 'ellipsis', regex: true | false, flags: undefined | '(regexp flags)' },
	...
	{ output: 'default output' } // A map without a "match" parameter will always match.
    ],
}
*/

(function($){

	var mappedValueWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			maps: [],
			default_pass: true,
			accept_data_from_parent: true,
			render: 'setValue'
		},

		fillData: function (d, from_self) {
			var self = this, $self = this.element;
			var name = $self.attr('name') || $self.attr('value-from') || self.options.name;
			var in_value, out_value;

			in_value = d[name];
			match_loop: for (var i=0; i<self.options.maps.length; i++) {

				var map = self.options.maps[i];
				if (map.match) {
					if ( (map.regex && in_value.search(new RegExp(map.match, map.flags || '')) > -1) || (!map.regex && in_value == map.match) ) {
						out_value = map.output || '';
						break match_loop;
					}
				} else {
					out_value = map.output;
					break match_loop;
				}

			}

			if (out_value === undefined && self.options.default_pass) {
				out_value = in_value;
			}

			switch (self.options.render) {
				case 'html':
					if (out_value instanceof jQuery) {
						out_value = out_value.clone(true);
					}
					$self.html(out_value || '');
					break;
				case 'text':
					$self.text(out_value || '');
					break;
				case 'value':
					$self.val(out_value || '');
					break;
					// Intentional fallthrough is intentional...
					// jshint -W086
				case 'setValue':
				default:
					self.setValue(out_value || '');
					break;
			}
			// jshint +W086
		}
	});
	add_widget('mappedValueWidget', mappedValueWidget);
})(jQuery);
