register_screen('fmfm_router_actions', {
    elements: [
	{ entity_template: 'page_title', title: 'Manage Router Action Sets' },
	{ entity_template: 'page_content', elements: [
	    {
		entity_template: 'page_module',
		legend: 'Action Sets',
		buttons: [ 'help' ],
		help_id: 'find_me_manage_action_sets',
		elements: [
		    { 
			entity_template: 'data_table',
			rest: '/gui/user/router/callflows',
			table_actions: {
			    add_row: {
				text: 'Create an Action Set',
				location: 'bottom'
			    },
			    delete_rows: {
				text: 'Delete Selected Action Sets',
				location: 'bottom',
				confirm: { title: 'Delete Selected Action Sets', text: 'Are you sure you want to delete the selected actions? Any rules using these actions will be deleted.' },

				validate_rest: '/gui/user/router/check_delete_callflow',
				validate_rest_container: 'check_delete_callflow'
			    },
			    select_row: true,
			    select_all_rows: true
			},

			click_action: 'overlay',
			paginate: true,
			length_change: true,
			dataEvents: {
			    trigger: 'callflow',
			    bind: {
				'callflow': ''
			    },
			    allow_delete: true
			},
			primary_keys: ['bbx_callflow_id'],
			delete_action: {
			    rest: '/gui/user/router/callflow',
			    only_rest_params: [],
			    include_row_data: ['bbx_callflow_id'],
			    dataEvents: {
				trigger: 'callflow'
			    }
			},
			add_edit_action: {
			    rest: '/gui/user/router/callflow',
			    rest_container: 'callflow',
			    primary_key: 'bbx_callflow_id',
			    only_rest_params: []
			},

			row_actions: {
			    add_cancel: { text: 'Cancel' }
			},			

			overlay_name: 'fmfm_router_action_detail',
			overlay_params: [
			    'bbx_callflow_id'
			],
			overlay_data: {
			    key: 'bbx_callflow_id'
			},

			extra_row_data: [
			    'actions',
			    'bbx_callflow_anonymous_call_blocking',
			    'bbx_callflow_caller_id',
			    'bbx_callflow_caller_id_name',
			    'bbx_callflow_caller_id_number',
			    'bbx_callflow_detect_fax',
			    'bbx_callflow_fax_email',
			    'bbx_callflow_final_action',
			    'bbx_callflow_final_action_email',
			    'bbx_callflow_final_action_extension_id',
			    'bbx_callflow_final_action_extension_value',
			    'bbx_callflow_final_action_provider_gateway_id',
			    'bbx_callflow_final_action_tdm_card_port_id',
			    'bbx_callflow_final_action_type',
			    'bbx_callflow_id',
			    'bbx_callflow_name',
			    'bbx_callflow_popup',
			    'bbx_callflow_popup_url',
			    'bbx_callflow_public',
			    'bbx_callflow_record_session',
			    'bbx_callflow_saved',
			    'bbx_callflow_silent_hold',
			    'bbx_callflow_t38_reinvite',
			    'bbx_router_id',
			    'bbx_user_id'
			],

			columns: [
			    {
				header: 'Action Set Name',
				column: 'bbx_callflow_name',
				add_element: {
				    entity: 'input',
				    attributes: { type: 'text', name: 'bbx_callflow_name', size: 40 }
				}
			    },
			    {
				header: 'Summary',
				width: '50%',
				column: 'summary',
				add_element: { entity: 'div', text: 'The Action Set can be edited after it has been added' }
			    }
			] // END columns
		    } // END DTW
	 	]
	    }//, // END page module "Actions"
	]}
    ]
});
