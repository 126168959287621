/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/* global CurrentUser */
(function( $ ){
    var eavesdropWidget = $.extend({}, $.ui.widget.prototype, CUI.htmlEntityClass, {

	options: {
	    accept_data_from_parent: true
	},

	_beforeInit: function () {
	    var self = this;
	    var $self = this.element;

	    $self.addClass('eavesdropWidgetType');
	    self.options.uuid = '';

	    self._setupEavesdropWidgetClosures();

	    self._buildHTML();

	    self._bind($(self.options.elem.button), 'click', self.options.button_click_closure);
//	    self._bind($(self.options.elem.img), 'click', self.options.button_click_closure);

	    return false;
	},

	_buildHTML: function() {
	    var self = this;
	    var $self = this.element;

	    var frag = document.createDocumentFragment();

	    self.options.elem = {};

	    self.options.elem.button = document.createElement('button');

	    self.options.elem.img = document.createElement('img');
	    self.options.elem.button.appendChild(self.options.elem.img);
	    self.options.elem.img.src = self.options.image;
	    $(self.options.elem.button).css({
		'width': (self.options.width ? self.options.width : 'auto'),
		'height': (self.options.height ? self.options.height : 'auto'),
		'padding': '0px',
		'margin-right': '4px',
		'margin-bottom': '0px',
		'margin-top': '4px'
	    });

	    frag.appendChild(self.options.elem.button);
	    $self[0].appendChild(frag);

	},

	_setupEavesdropWidgetClosures: function() {
	    var self = this;

	    self.options.button_click_closure = function(e) {
		e.preventDefault();
		e.stopPropagation();
		self.eavesdrop();
	    };
	},

	eavesdrop: function() {
	    var self = this;
	    var $self = this.element;

	    if (CurrentUser.permissions.EAVESDROP) {
		if (self.options.uuid) {
		    $.getJSON('/gui/freeswitch/eavesdrop', { template: 'json', uuid: self.options.uuid }, function(data) { });
		}
	    }
	},

	_destroy: function() {
	    var self = this;
	    var $self = this.element;

	    delete self.options.button_click_closure;

	    for (var e in self.options.elem) {
		delete self.options.elem[e];
	    }

	    if (self.options.widget_data) {
		delete self.options.widget_data;
	    }

	    self._htmlEntityDestroy();
	},

	fillData: function(data, from_self) {
	    var self = this;
	    var $self = this.element;

	    $self.data('widgetData', null);

	    if (self.options.uuid_field && data[self.options.uuid_field]) {
		self.options.uuid = data[self.options.uuid_field];
	    } else if (self.options.alt_uuid_field && data[self.options.alt_uuid_field]) {
		self.options.uuid = data[self.options.alt_uuid_field];
	    }
	}
    });
    add_widget('eavesdropWidget', eavesdropWidget);
})(jQuery);
