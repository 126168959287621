/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, trueish */
/*
  This widget is used when fillData/setValue should not fill an input with the current value, but should instead clear it and ignore the set value.
  This is used for inputs like a Password input, which only recieves a "success" value from the backend.

  It shows dirty on any non-falsy value, and clears its value on setValue.

  Apply to a textbox or other INPUT.

*/

(function( $ ){
	var submitOnlyWidget = $.extend(true, {}, $.ui.widget.prototype, {
		value_widget: true,

		options: {
			clear_on_data: true,
			clear_on_form_submit: true,
			clear_only_if_trueish: true
		},

		_beforeDOMReady: function () {
			var self = this, $self = this.element;
			if (self.options.clear_on_form_submit) {
				self._bind($self.closest('form'), 'SubmitSuccess', self._clear.bind(self));
			}
		},

		_clear: function () {
			this.element.val('');
			this.element.trigger('change');
		},

		isDirty: function (key, orig, curr) {
			if (curr) { return true; }
		},

		fillData: function (d) {
			var self = this, $self = this.element, v;

			v = d[CUI.getElementName($self)];

			if (self.options.clear_on_data && (trueish(v) || !self.options.clear_only_if_trueish)) {
				$self.val('');
			}
		},

		_getWidgetValue: function () {
			return this._wrapValue(this.element.val());
		}
	});

	add_widget('submitOnlyWidget', submitOnlyWidget);
})(jQuery);
