/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, debugLog */
/*

  TODO: Document this thoroughly

  OPTIONS:
  {
    text_field:  'key_name' | <omit>, // Omit if using text_format
    text_format: <omit> | { ... CUI.formatter format object ... }, // Use only text_field if the text does not require formatting
    value_field: 'key_name',

    // Add static options before or after the filled ones
    before: [{ text: 'Text of the OPTION', value: 'value' }, ...],
    after: [{ text: 'Text of the OPTION', value: 'value' }, ...],

    options_container: key_name | <omit>  // For select options arrays that are under a key name

    // TODO: Not sure what these do, offhand. Document them.
    store_row_data: ???,
    default_first: true | false,
    clear_data_on_refresh: true | false
  }

  Apply to a SELECT control.

*/

(function( $ ){

	var selectPopulateWidget = $.extend({}, $.ui.widget.prototype, {
		value_widget: true,
		manages_own_descendent_events: true,
		manages_own_descendent_value: true,
		allow_fallback_getWidgetValue: true,

		options: {
			text_field: "name",
			text_format: undefined,
			value_field: "value",
			store_row_data: false,
			default_first: true,
			use_last_value: false, // if fillData doesn't contain the key matching the name of this input, use last value that was found
			clear_data_on_refresh: true,
			before: [],
			after: [],
			options_container: false
		},

		_beforeInit: function() {
			var $self = this.element;
			var self = this;

			// REMOVE THIS ONCE ALL CALLERS ARE CLEAN!!!

			if (self.options.name_field) {
				debugLog('jquery.selectPopulateWidget.js: The name_field option has been renamed to text_field. Please change your screen definitions accordingly. -- ', $self);
				self.options.text_field = self.options.name_field;
			}

			// !!!NEALC ERA SRELLAC LLA ECNO SIHT EVOMER

			self.options._root_df = document.createDocumentFragment();
		},

		/*
	 * @fn _afterInit()
	 * @brief This is a private function called by a parrent object when it is time to initialize the object.
	 */
		_afterInit: function () {
			var $self = this.element;
			var self = this;

			$self.addClass('selectPopulateWidgetType');
		},

		_deferredSetSelectValue: function (val) {
			var self = this, go;

			if (!val) { return; }
			go = function () {
				self._setSelectValue(val);
				self.element.trigger('change');
			};

			if (self.options.ready) {
				go();
			} else {
				self.element.one('ready', go);
			}
		},

		_setSelectValue: function (val) {
			var self = this, $self = this.element;
			if (val === undefined) { val = self.options.default_val; }
			$self.val(val);
		},

		_addOptionGroup: function (row) {
			var $self = this.element;
			var self = this;

			if (typeof self.options.category_field == "string" &&
				(!self.options.cur_optgroup || self.options.cur_optgroup.label != row[self.options.category_field])) {
				var optgroup = document.createElement("optgroup");
				optgroup.label = row[self.options.category_field];
				self.options.cur_optgroup = optgroup;
				self.options._root_df.appendChild(optgroup);
			}
		},

		_addOptionFromData: function (row) {
			var self = this, attrs, option, text, val;

			attrs = self._getOptionElementAttrs(row);
			text = attrs.text;
			val = attrs.value;

			if (text && val) {
				option = self._addOption(text, val);
				if (self.options.store_row_data) {
					$(option).data('row_data', row);
				}
			}
		},

		_addStaticOption: function (text, val) {
			var self = this;

			// Used to add "before" and "after" options. This is just a stub so subclasses can handle static options differently than data options.
			self._addOption(text, val);
		},

		_addOption: function (text, val) {
			var self = this, option, value;

			// Build all options on a documentFragment so that we can
			// append them all to the DOM in one go -- much faster when
			// dealing with lots of options

			option = document.createElement('option');

			value = document.createAttribute('value');
			value.value = val;

			text = document.createTextNode(text);

			option.appendChild(text);
			option.setAttributeNode(value);

			if (self.options.cur_optgroup) {
				self.options.cur_optgroup.appendChild(option);
			} else {
				self.options._root_df.appendChild(option);
			}

			return option;
		},

		_getOptionElementAttrs: function (row) {
			var self = this, text;

			if (self.options.text_format) {
				text = CUI.formatter.doFormat(row, self.options.text_format);
			} else {
				text = row[self.options.text_field];
			}

			return ({ text: text, value: row[self.options.value_field] });
		},

		_addGroupedOptions: function (row_set) {
			var self = this, row, category_field_in_item_object = false, item;

			if (typeof self.options.category_field == "string" && typeof self.options.items_field == "string") {
				category_field_in_item_object = true;
			}

			if (row_set[self.options.items_field] instanceof Array) {
				for (var i=0, len=row_set[self.options.items_field].length; i<len; i++) {
					row = row_set[self.options.items_field][i];
					if (category_field_in_item_object && self.options.category_field in row_set[self.options.items_field][i]) {
						self._addOptionGroup(row);
					}
					self._addOptionFromData(row);
				}
			} else {
				for (item in row_set[self.options.items_field]) {
					row = row_set[self.options.items_field][item];
					if (category_field_in_item_object && self.options.category_field in row_set[self.options.items_field][item]) {
						self._addOptionGroup(row);
					}
					self._addOptionFromData(row);
				}
			}
		},

		fillData: function(d, from_self) {
			var self = this, $self = this.element, row_idx, row_count, r_idx, name = $self.attr('name'), col_vals = [], o_idx;
			if (typeof d !== 'object') { return; }
			if (self.options.options_container && d[self.options.options_container]) { d = d[self.options.options_container]; }

			if (!self.options.dtw && self.options.table_filter) {
				self.options.$dtw = $self.closest('.dataTableWidget');
				if (self.options.$dtw[0]) {
					self.options.dtw = self.options.$dtw.getCUIWidget('dataTableWidget');
				}
			}

			if (from_self) {
				$self.empty();

				if (self.options.before) {
					for (o_idx = 0; o_idx < self.options.before.length; o_idx++) {
						self._addStaticOption(self.options.before[o_idx].text, self.options.before[o_idx].value);
					}
				}

				if (self.options.dtw) {
					col_vals = self.options.dtw.getColumnData(name);
					if (!col_vals[0]) {
						row_count = self.options.dtw.getRowCount();
						for (r_idx = 0; r_idx < row_count; r_idx++) {
							col_vals.push(self.options.dtw.getExtraRowData(r_idx, name));
						}
					}
				}

				for (row_idx = 0; row_idx < d.length; row_idx++) {
					var filtered = false;
					var val = d[row_idx][self.options.value_field];
					if (self.options.table_filter) {
						for (var c = 0, clen = col_vals.length; c < clen; ++c) {
							if (col_vals[c] == val) {
								filtered = true;
								break;
							}
						}
					}

					if (!self.options.table_filter || !filtered) {
						if (self.options.category_field && String(self.options.category_field) in d[row_idx]) {
							self._addGroupedOptions(d[row_idx]);
						} else {
							self._addOptionFromData(d[row_idx]);
						}
					}
				}

				if (self.options.after) {
					for (o_idx = 0; o_idx < self.options.after.length; o_idx++) {
						self._addStaticOption(self.options.after[o_idx].text, self.options.after[o_idx].value);
					}
				}

				$self[0].appendChild(self.options._root_df);

				if ( self.options.default_val === undefined && self.options._last_value === undefined ) {
					if (self.options.use_last_value) { 
						self.options.default_val = self.options._last_value;
					} else if (self.options.default_first) {
						self.options.default_val = $self.find('option:eq(0)').val();
					}
					self._deferredSetSelectValue(self.options.default_val);
				} else {
					self._deferredSetSelectValue(self.options._last_value);
				}
				$self.trigger('change');
			} else if (name && name in d) {
				self._deferredSetSelectValue(d[name]);
				self.options._last_value = d[name];
				$self.trigger('change');
			}
		}
	});

	add_widget('selectPopulateWidget', selectPopulateWidget);
})(jQuery);
