/* jshint jquery: true, browser: true */
/* global classCUI */
(function ($) {

/*
  CUI.FunctionFactory: Builds callbacks that avoid excess closures, in a simple, all-purpose manner

  The callback function will be called with arguments in the following order (omitting any that are not configured):
      callback(<"first">, <args in array>, <"last">, <arguments passed on call>)
  with context from "context"

  USAGE:

  CUI.FunctionFactory.build(
    FUNCTION callback_ref,
    OBJECT argument_context,
    OPTIONAL OBJECT {
                 context: 'argument' | 'context',
        OPTIONAL first:    undefined | 'argument' | 'context',
        OPTIONAL last:     undefined | 'argument' | 'context',
    },
    OPTIONAL [arg1, arg2, ...]
  ); RETURNS FUNCTION

  The "argument_context" argument specifies what "this" will be in the called function, if "context" is set to "argument".
  The "first" and "last" options prepend or append an item to the argument list with the specified context object.
  The args array specifies more arguments to pass through (Inserted after "first" if it exists, and after any arguments passed directly to the callback,
    but before "last")
  In all cases, the value "context" refers to the actual "this" context when the resulting function is called (e.g., the DOM object in a jQuery trigger).
    The value "argument" takes the current_context argument passed in (usually the widget where the callback was created). In
    "first" and "last", an undefined or missing value will omit the argument.

  EXAMPLE (within a Widget):

    someBindingMethod: function () {
    // Oh no! Closing "$self" is a leaky idea!
	var self = this, $self = this.element;

	// Make self.clickCallback into a safe callback that retains its knowledge of self
	var callback = CUI.FunctionFactory.build(self.someJQueryEventCallback, self, { context: 'argument', first: 'context' });
	self._bind('div.clicky', 'click', callback);
    },

    // Define your callback function separately...
    clickCallback: function (element, event, data) {

	// "this" is still the widget, because you passed "self" in as the context, and had "context: 'argument'".
	// The "element" arg has been prepended, and is the DIV element (jQuery's "this"), because you had "first: 'context'".

	var self = this, $self = this.element;

	// ...

    }

*/

	// Function factory "options" object--
	classCUI.prototype.FunctionFactory = {
		default_options: {
			// 'context' is the "this" variable as passed to the resulting function when it is called
			// 'argument' is the context argument passed to the function factory (usually the context of the builder)

			context: 'argument', // 'context' | 'argument' -- What will "this" be in the called function
			first: undefined,    // undefined | 'context' | 'argument' -- Set (prepend) the first argument to the function
			last:  undefined     // undefined | 'context' | 'argument' -- Set (append) the last argument to the function
		},

		build: function (fn, context, options, args) {
			var first, last;
			options = $.extend({}, classCUI.prototype.FunctionFactory.default_options, options || {});

			if (args) {
				args = Array.prototype.slice.call(args); // Array...like?
			}

			if (typeof fn !== 'function') {
				throw 'cui.functionFactory.js: Function argument is not a function';
			}

			switch (options.first) {
				case 'context':
					first = undefined;
					break;
				case 'argument':
					first = [context];
					break;
				default:
					first = [];
			}

			switch (options.last) {
				case 'context':
					last = undefined;
					break;
				case 'argument':
					last = [context];
					break;
				default:
					last = [];
			}

			return classCUI.prototype.FunctionFactory._build(fn, first, last, (options.context === 'argument') ? context : undefined, args);
		},

		_build: function (fn, first, last, context, passed_args) {
			// If first or last are false, the local context "this" is used instead. To omit first or last, pass an empty array.
			// (this is handled by "build" above)
			// The call will be: fn.apply(context, first, args_in_build_array, last, args_to_callback);

			// Uncomment for debugging, then look in the inspector to see the stack at build time
			// var stack_on_create = new Error('Built').stack.toString();

			var fn_out = function () {

				var my_args;
				my_args = Array.prototype.slice.call(arguments,0);
				return fn.apply(context || this, (first || [this]).concat((passed_args || []), (last || [this]), (my_args || []) ));
			};
			return fn_out;
		}


	};

})(jQuery);
