/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){
	var timeSelectMinsWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
		},

		value_widget: true,
		set_value_widget: true,
		manages_own_descendent_value: true,

		_beforeInit: function () {
			var self = this, $self = this.element, $sel, idx;

			$self.addClass('time-select-container');
			$self.css('white-space', 'nowrap');

			// Hours
			$sel = $('<select class="time-select-hours" />');
			for (idx = 1; idx <= 11; idx++) {
				$sel.append($('<option />').val(idx).text(idx));
			}

			$sel.append($('<option value="0">12</option>'));
			$self.append($sel);

			// Minutes
			$sel = $('<select class="time-select-mins" />');

			for (idx = 0; idx <= 55; idx += 5) {
				$sel.append($('<option />').val(idx).text(idx < 10 ? '0' + idx : idx));
			}

			$self.append($sel);

			// AM/PM
			$self.append('<select class="time-select-ampm"><option value="a">AM</option><option value="p">PM</option></select>');
			self._bind($self.find('select.time-select-hours'), 'change', self._parseSelects.bind(self));
		},

		_parseSelects: function () {
			var self = this, $self = this.element, $h, h, m, $a;
			$h = $self.find('.time-select-hours');
			m = Number($self.find('.time-select-mins').val());
			$a = $self.find('.time-select-ampm');

			h = Number($h.val());
			// 0 selected
			if ($a.val() === 'p') {
				h += 12;
			}

			return (h * 60 + m);
		},

		setValue: function (v) {
			var self = this, $self = this.element, time, h, m;
			time = Number(v);
			h = Math.floor(v / 60);
			m = v % 60;

			if (h >= 12) {
				h -= 12;
				$self.find('.time-select-ampm').val('p');
			} else {
				$self.find('.time-select-ampm').val('a');
			}

			$self.find('.time-select-hours').val(h);
			$self.find('.time-select-mins').val(m);
		},

		_getWidgetValue: function () {
			var self = this, $self = this.element;
			return self._wrapValue(self._parseSelects());
		}
	});

	add_widget('timeSelectMinsWidget', timeSelectMinsWidget);
})(jQuery);
