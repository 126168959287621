register_screen('group_create', {
    elements: [
	{ entity_template: 'page_title', title: 'Create Group' },
	{
	    entity_template: 'page_buttons',
	    buttons: [
		{ type: 'cancel_back' },
		{
		    type: 'create',
		    key: 'bbx_group_id',
		    detail: 'group_detail'
		}
	    ]
	},
	{ entity_template: 'page_content', elements: [
	    {
		entity_template: 'page_module',
		legend: 'Add New Group',
		form_action: '/gui/extension/group',
		form_data_js: {
		    method: 'POST',
		    no_initial_get: true,
		    submit_all: true,
		    always_dirty: true
		},
		buttons: [ 'help' ],
		help_id: 'add_new_group',
		create: {
		    detail: 'group_detail',
		    params: [ 'bbx_extension_id', 'bbx_group_id' ],
		    key: 'bbx_group_id'
		},
		set_focus: true,
		elements: [
		    {
			entity_template: 'input_item',
			title: 'Group Name:',
			input_name: 'bbx_group_name',
			type: 'text',
			validate: { conditions: "_TRUE_", message: 'Group Name is required.' },
			input_options: { attributes: { 'class': 'focus', maxlength: '255' } }
		    },
		    {
			
			name: 'bbx_extension_value',
			entity_template: 'extension_select'
		    }
	 	]
	    }
	]
	}
    ]
});
