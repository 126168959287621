/* jshint jquery: true, unused: vars */
/* global add_widget */
// This widget is for the Function column of the Lines and Utility Buttons DTW on user_phone_detail. It removes unnecessary options from the SELECT element if we have more buttons than the phone has support for lines. It requires the same widget_options as ancestorDependentSelectWidget.
(function( $ ){
	var phoneButtonFunctionWidget = $.extend(true, {}, $.ui.ancestorDependentSelectWidget.prototype, {
		options: {
			options_to_disable: ['line','repeat'], // Which options to disable if our row number is > max lines
			row_number_widget: 'phoneButtonNumberStatusWidget' // This is the widget we are looking for within the row to find the row number
		},

		_beforeInit: function () {
			var self = this, $self = this.element;

			// Grab the current row's number -- starting from 1 (it's the button number, not an index)
			self.options._row_number = $self.closest('tr').find('.phoneButtonNumberStatusWidget').text();

			$.ui.ancestorDependentSelectWidget.prototype._beforeInit.apply(self, arguments);
		},

		// Overriding this function in order to add additional checking for whether or not an option should be included
		_testOption: function(option) {
			var self = this, $self = this.element, widget = self.options._widget, row_number = self.options._row_number;
			var result = $.ui.ancestorDependentSelectWidget.prototype._testOption.apply(self, arguments);

			if (result) {
				// ancestorDependentSelectWidget reports that our option is valid, but let's do an additional check based on max_lines
				return (option.value !== 'line' && option.value !== 'repeat') || (parseInt(widget.options.data.max_lines) >= parseInt(row_number));
			} else {
				return false;
			}
		}
	});

	add_widget('phoneButtonFunctionWidget', phoneButtonFunctionWidget);
})(jQuery);
