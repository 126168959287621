/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, widgetize_children */
/*

  Form Dialog Button Widget:

  A button that pops up a Dialog containing a form. The button itself does nothing until the form is submitted. Can be applied to a button or link-anchor.

  OPTIONS:
  {
    title:          'Dialog title',
    submit_button:  'Submit Button Text',
    submit_first:   true | false,           // Is the SUBMIT button first or second?
    cancel_button:  'Cancel Button Text',
    include_data:   ['key_name', 'key_name', ...],      // Include data from the (button) parent's REST/fillData in the request.
    include_data_as: { source_name: output_name, ... }, // Include data from the parent, and rename the keys
    disabled:       true | false,                       // Should the button initialize disabled?

    // All data is passed at the time of button press!

    elements: [ { ... } ],                  // The contents of the form

    // If this option is provided, use a different template for the form besides form (i.e. 'dirty_form')

    form_widget_template: 'form',

    // These options are passed to the form...
    rest:           '/gui/controller/url',
    method:         'POST',
    submit_all:     true | false,           // Default true, unlike the normal formWidget!
    no_initial_get: true | false,           // Default true, unlike the normal formWidget!

    form_options:   { ... } // Other options, not shown above, to be passed to the form template/form widget
  }

*/

(function( $ ){
	var preventDefault = function (e) { e.preventDefault(); };

	var formDialogButtonWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			title: 'Enter Information',
			submit_button: 'Apply',
			cancel_button: 'Cancel',
			submit_first: true,
			submit_all: true,
			no_initial_get: true,
			disabled: false,
			always_dirty: true,
			include_data_as_rest_params: undefined,

			_initial_dialog: false
		},

		fillData: function (d, from_self) {
			var self = this, filtered_d, to_rename_d = {}, renamed_d = {}, rn_d_keys, rn_d_kidx;

			if (self.options.include_data_as) {
				// Get original keys...
				rn_d_keys = CUI.keys(self.options.include_data_as);
				// Pull those properties out, as they are the only ones we're working with
				to_rename_d = CUI.onlyKeys(d, rn_d_keys);

				// Loop through the original keys, and copy from the original properties to the new property keys
				for (rn_d_kidx = 0; rn_d_kidx < rn_d_keys.length; rn_d_kidx++) {
					renamed_d[ self.options.include_data_as[ rn_d_keys[rn_d_kidx] ] ] = to_rename_d[ rn_d_keys[rn_d_kidx] ];
				}
			}

			if (self.options.include_data && self.options.include_data[0]) {
				filtered_d = CUI.onlyKeys(d, self.options.include_data);
			}

			if ((self.options.include_data && self.options.include_data[0]) || self.options.include_data_as) {
				self.options._stored_data = self.options._stored_data || {};
				$.extend(self.options._stored_data, filtered_d, renamed_d);
			}


			if (self.options.include_data_as_rest_params) {
				var rp_key;
				self.options.rest_params = self.options.rest_params || {};
				for (var rp_idx = 0; rp_idx < self.options.include_data_as_rest_params.length; rp_idx++) {
					rp_key = self.options.include_data_as_rest_params[rp_idx];
					if (rp_key in d) {
						self.options.rest_params[rp_key] = d[rp_key];
					}
				}
			}

			$.ui.widget.prototype.fillData.apply(self, arguments);
		},

		_beforeInit: function () {
			var self = this, $self = this.element;
			var move_to_form = ['rest', 'rest_container', 'rest_params', 'no_initial_get', 'submit_all', 'method' ];

			// Copy self.options just in case something else is using the reference
			self.options = $.extend(true, {}, self.options);
			self.options.form_options = self.options.form_options || {};

			// Move form-specific fields to "form_options"
			self.options.form_options = $.extend(true, {}, self.options.form_options, CUI.onlyKeys(self.options, move_to_form));
			self.options = CUI.notKeys(self.options, move_to_form);

			self._bind($self, 'click', preventDefault);
			self._bind($self, 'click', self._createDialogEvent.bind(self));

			self._addDestroyCallback(self.closeDialog.bind(self));
		},

		_beforeDOMReady: function() {
			var self = this, $self = self.element;

			if (self.options['class']) {
				$self.addClass(self.options['class']);
			}

			if (self.options.disabled) {
				$self.addClass('state-disabled').disable();
			}
		},

		_createDialogEvent: function () { this._createDialog(); }, // Stub this in in case we need to do any other event/bind handling later

		_createDialog: function () {
			var self = this, $self = this.element, $body = $('<div />'), $content, dialog, form_def, buttons, callbacks, ref_closeDialog, ref_submitDialog;

			ref_closeDialog = self.closeDialog.bind(self);
			ref_submitDialog = self.submitDialog.bind(self);

			buttons = [
				self.options.submit_first ? self.options.submit_button : self.options.cancel_button,
				self.options.submit_first ? self.options.cancel_button : self.options.submit_button
			];

			callbacks = [
				self.options.submit_first ? ref_submitDialog : ref_closeDialog,
				self.options.submit_first ? ref_closeDialog : ref_submitDialog
			];

			if (self.options.submit_button === false) {
				buttons.splice(self.options.submit_first ? 0 : 1, 1);
				callbacks.splice(self.options.submit_first ? 0 : 1, 1);
			}

			dialog = new CUI.Dialog({
				title: self.options.title,
				body: $body,
				buttons: buttons,
				callbacks: callbacks
			});

			self.options._$dialog_body = $body;
			self.options._dialog = dialog;
			self.options._initial_dialog = true;

			form_def = {
				entity_template: self.options.form_widget_template || 'form', // We can supply an alternate template, such as 'dirty_form'
				elements: $.isArray(self.options.elements) ? self.options.elements : self.options.elements,
				data: self.options._stored_data || {},
				always_dirty: self.options.always_dirty, // Hack -- Remove this once we get injected-data dirty tracking working
				submit_all: true,   // Hack -- ditto
				rest_params: self.options.rest_params || {}
			};

			$.extend(true, form_def, self.options.form_options, { rest_params: self.options._stored_data || {} });
			// getEntitiesHTML returns the content wrapped in a dummy DIV.

			var html = CUI.htmlEntityClass.getEntitiesHTML( [form_def], (self.options._stored_data || {}) );

			$content = $(html);
			$body.append($content);

			// Bind before widgetization: The events in question may happen during widgetization itself
			self._bind($body, 'dirty.fdbw', self._formValidateEvent.bind(self));
			self._bind($body, 'undirty.fdbw', self._formValidateEvent.bind(self));

			self._bind($body, 'SubmitSuccess.fdbw', self._formSuccessEvent.bind(self));
			self._bind($body, 'SubmitError.fdbw', self._formFailedEvent.bind(self));

			self._bind($body, 'validation.fdbw', self._formValidateEvent.bind(self));

			$body.closest('.CUIDialog').find('button:eq(' + (self.options.submit_first ? 0 : 1)  + ')').addClass('form-dialog-submit');
			$body.closest('.CUIDialog').find('button:eq(' + (self.options.submit_first ? 1 : 0)  + ')').addClass('form-dialog-cancel');

			widgetize_children($content);
			$content = $content.children();

			self.options._children_widgetized_ok = true;
		},

		_formValidateEvent: function () {
			var self = this, $self = this.element, dialog = self.options._dialog, $body = self.options._$dialog_body;

			if ($body.find('.is-invalid')[0] || !$body.find('form.is-dirty')[0]) {
				// The form can NOT be submitted
				dialog.setButtonState(self.options.submit_first ? 0 : 1, 'disabled', 'fdbw-not-ready');
			} else {
				// The form CAN be submitted
				dialog.setButtonState(self.options.submit_first ? 0 : 1, 'enabled', 'fdbw-not-ready');
			}
		},

		_formSuccessEvent: function () {
			var self = this, $self = this.element;
			self.closeDialog();
		},

		_formFailedEvent: function () {
			var self = this, $self = this.element;
			var $form = self.options._$dialog_body.find('form');
			var formWidget = $form[0] ? $form.getCUIWidget('formWidget') : false;
			self.options._$dialog_body.closest('.CUIDialog').find('.form-dialog-submit').enable('in_progress');
			if (formWidget) { formWidget.computeDirtyState(); }
		},

		closeDialog: function () {
			// A "mini-destroy" for when the dialog goes away...
			var self = this, $self = this.element, dialog = self.options._dialog, $body = self.options._$dialog_body;

			// If this isn't there, either we've already closed the dialog, or we never opened one, so do nothing.
			// (This is called as a widget destroy callback, so this is a very real possibility.)
			if (!self.options._dialog) { return; }

			self._unbind($body, 'dirty.fdbw');
			self._unbind($body, 'undirty.fdbw');
			self._unbind($body, 'validation.fdbw');
			self._unbind($body, 'SubmitSuccess.fdbw');
			self._unbind($body, 'SubmitFailure.fdbw');

			$body.remove();
			delete self.options._$dialog_body;

			dialog.remove();
			delete self.options._dialog;
		},

		submitDialog: function () {
			var self = this, $self = this.element, $body = self.options._$dialog_body;

			var form   = $body.find('.formWidgetType');
			var widget = form.is('.dirtyFormWidget') ? form.getCUIWidget('dirtyFormWidget') : form.getCUIWidget('formWidget');
			widget.submit();
			$body.closest('.CUIDialogBody').find('.form-dialog-submit').disable('in_progress');
		}

	});

	add_widget('formDialogButtonWidget', formDialogButtonWidget);
})(jQuery);
