(function () {
    register_screen('queue_abandoned_cdrs', {
		elements: [
			{ entity_template: 'page_title', title: 'Abandoned Queue Calls' },
			{
				entity_template: 'page_buttons',
				buttons: [{ type: 'help', help_id: 'reports_call_detail_records' }]
			},
			{
				entity_template: 'page_content', elements: [
					{
						entity_template: 'data_table',
						rest: '/gui/cdr/cdr',
						rest_params: {
							sortby: 'end_timestamp',
							sortorder: 'desc',
							since: '1 day',
							abandoned_calls: 1
						},
						page_size: 25,
						data_source: 'cdr',
						unique_id: 'uuid',
						attributes: {
							"class": 'cdr_dataTable'
						},
						extra_row_data: ['bbx_fax_inbound_id','bbx_fax_outbound_id','record_file_name', 'bbx_cdr_id'],
						columns: [
							{
								header: 'Leg',
								column: 'direction',
								translate: {
									inbound: 'Inb.',
									outbound: 'Outb.'
								},
								default_translate: ''
							},
							{
								header: 'Call End',
								column: 'end_timestamp',
								search_field: 'datetime',
								data_type: 'date',
								sort_data_type: 'numeric-desc',
								view_element: {
									entity: 'div',
									attributes: {
										name: 'end_timestamp'
									},
									widgets: [ 'timestampDisplayWidget' ]
								}
							},
							{
								header: 'Call Answered',
								column: 'answer_timestamp',
								search_field: 'datetime',
								view_element: {
									entity: 'div',
									attributes: {
										name: 'answer_timestamp'
									},
									widgets: [ 'timestampDisplayWidget' ]
								}
							},
							{
								header: 'Caller Name',
								column: 'caller_id_name',
								'class': 'break-long',
								search_field: 'datetime'
							},
							{
								header: 'Caller Number',
								column: 'caller_id_number',
								search_field: 'text',
								'class': 'break-long nowrap',
								formatted: {
									format: {
										field: '@caller_id_number',
										formatter: 'phone_number',
										options: {}
									}
								}
							},
							{
								header: 'Destination Name',
								column: 'destination_name',
								'class': 'break-long',
								search_field: 'text'
							},
							{
								header: 'Destination Number',
								column: 'destination_number',
								'class': 'break-long nowrap',
								search_field: 'text',
								formatted: {
									format: {
										field: '@destination_number',
										formatter: 'phone_number',
										options: {}
									}
								}
							},
							{
								header: 'Billable Time',
								column: 'billsec',
								formatted: {
									format: {
										field: '@billsec',
										formatter: 'seconds_duration',
										options: {}
									}
								},
								sort_data_type: 'num-html-pre',
								data_type: 'num-html'
							},
							{
								header: 'Recorded File/Fax',
								sortable: false,
								view_element: {
									entity: 'div',
									attributes: { name: 'record_file_name' },
									widgets: ['aopbContainerWidget'],
									widget_options: {
										aopbContainerWidget: {
											states: [
												{
													conditions: { a: '@record_file_name', op: '==', b: 'mp3$', b_is: 'regexp' },
													elements: [
														{
															entity_template: 'sound_player',
															size: 'full',
															allow_download: true,
															url: '/gui/cdr/recording',
															include_keys: ['record_file_name'],
															params: { disposition: 'inline' },
															download_params: { disposition: 'attachment' },
															accept_data_from_parent: true
														}
													],
													last_if_true: true
												},
												{
													conditions: [
														{             a: '@bbx_fax_inbound_id',  op: '==', b: '_TRUEISH_' },
														{ join: 'or', a: '@bbx_fax_outbound_id', op: '==', b: '_TRUEISH_' }
													],
													elements: [
														{
															entity_template: 'fax_view',
															'class': 'small-button-margins nowrap center'
														}
													]
												}
											]
										}
									}
								}
							}
						],
						table_actions: {
							refresh: { text: 'Refresh' },
							action_elements: {
								actions: [
									{
										entity: 'div',
										sub_elements: [
											{
												entity: 'span',
												widgets: [ 'cdrDateRangeSelectorWidget', 'dtwParamAmendWidget' ],
												widget_options: {
													dtwParamAmendWidget: {
														selector: '.cdr_dataTable'
													}
												}
											},
											{
												entity: 'label',
												sub_elements: [
													{
														entity: 'input',
														widgets: ['dtwParamAmendWidget'],
														widget_options: { dtwParamAmendWidget: { selector: '.cdr_dataTable' } },
														attributes: { type: 'checkbox', name: 'show_outbound', value: '1' }
													},
													{
														entity: 'span',
														text: 'Show Outbound Leg'
													}
												]
											}										]
									},
									{
										entity: 'button',
										text: 'Download CSV',
										widgets: [ 'csvButtonWidget' ],
										widget_options: {
											csvButtonWidget: {
												rest: '/gui/cdr/csv'
											}
										}
									}
								],
								location: 'top',
								position: 'first'
							}
						}
					} // END DTW
				] // END page_content elements
			} // END page_content
		] // END template elements
    }); // END template
})(); // END scoping function
