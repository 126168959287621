/* jshint jquery: true, browser: true */
/* global CUI, classCUI */
(function ($) {

	var PopDialogClass = function (options) {
		if (this === window) {
			return new CUI.PopDialog(options);
		}
		this.options = $.extend(false, this.options, options);
		this._build();
		return this;
	};

	var global_options = {
		parent_selector: '#cui-body',
		fade_in_time: 250,
		fade_out_time: 500,
		$container: undefined,
		drop_height_px: 20
	};

	PopDialogClass.prototype = {
		options: {
			text: 'Something Happened',
			timeout: 10000,
			render: 'text'
		},

		_build: function () {
			var self = this, $pop, $content, ref_remove;

			if (!global_options.$container) {
				self._initFirst();
			}

			$pop = $('<div class="popDialog"><div class="popDialogContent" /></div>');
			$content = $pop.children('.popDialogContent');

			self.options.render = (self.options.render === 'html' ? 'html' : 'text');

			// Calls $content.text or $content.html
			$content[self.options.render](self.options.text);

			$pop
			.css({ top: (global_options.drop_height_px) + 'px', position: 'relative' })
			.animate({ opacity: 0 }, 0)
			.appendTo(global_options.$container)
			.animate({ opacity: 1, top: 0 }, global_options.fade_in_time);

			ref_remove = self.remove.bind(self);

			setTimeout(ref_remove, self.options.timeout);
			$pop.one('click', ref_remove);

			self.options._$pop = $pop;
		},

		remove: function () {
			var self = this, $pop = self.options._$pop;
			if (self.options.removed) { return; }
			self.options.removed = true;

			$pop
			.animate({ top: (-global_options.drop_height_px) + 'px', opacity: 0 }, global_options.fade_out_time, function () {
				setTimeout(function () { self._destroy(); }, 100);
			});
		},

		_destroy: function () {
			var self = this;
			self.options._$pop.remove();
			delete self.options._$pop;

			if (!global_options.$container.children().length) {
				self._destroyLast();
			}
		},

		_initFirst: function () {
			global_options.$container = $('<div class="popDialogContainer"></div>');
			$(global_options.parent_selector).append(global_options.$container);
		},

		_destroyLast: function () {
			global_options.$container.remove();
			delete global_options.$container;
		}
	};

	classCUI.prototype.PopDialog = PopDialogClass;

})(jQuery);
