/* jshint jquery: true, unused: vars */
/* global add_widget */
/* Submits static data in a form or table row. Specify data in the "data" property. If s.o.data is an object, it is used as is. If not, the value is keyed
   to the name and has the full value as the value. */

(function( $ ){
	var staticValueWidget = $.extend(true, {}, $.ui.containerWidget.prototype, {
		value_widget: true,

		_beforeInit: function () {
			var self = this, value;
			value = self.option.data;

			if (!$.isPlainObject(value)) {
				value = self._wrapValue(value);
			}

			self.options._value_data = value;
			delete self.options.data;
		},

		_getWidgetValue: function () {
			return this.options._value_data;
		}
	});

	add_widget('staticValueWidget', staticValueWidget);
})(jQuery);
