/* jshint jquery: true, unused: vars */
/* global CUI, debugLog */
/*

This is a mixin to add a "flyout" capability for use in menus or combo boxes.

- Generates the flyout box
- Positioning of the flyout box
  - Proper positioning when the box runs off-screen
  - Update events for re-positioning
- Method for passing information to/from the flyout box
- Visually indicating "wait" state
* This widget does NOT handle list item display or navigation, it ONLY handles displaying and positioning the flyout DIV

REQUIRES:
jQuery scrollParent function (usu. provided by jquery UI core)

SUBCLASSING:

* The subclass MUST call _flyoutWidgetInit in the initialization method

TODO: Document me (further)

*/

(function( $ ){
	var flyoutWidgetMixin = {
		options: {
			/* An ordered list of valid positionings to try if the default causes the flyout to run off the screen. The first positioning will be tried first.

	         anchor: [ { target: 'nw' | 'ne' | 'sw' | 'se', box: 'nw' | 'ne' | 'sw' | 'se' }, { ... }, ... ]

	      "target" is the corner of the widgetized element ($self) where the box is anchored, and "box" is the corner of the flyout that is anchored
	       to that point. By default, the widget will check to see if the box is off the edge of the screen in the default (first) position. If it is, the
	       box will be moved to the first suitable anchoring in the array that does not go off the screen. If none is suitable, the first one anchoring
	       will be used. If there is only one entry in the array, this checking is not done.

	    */

			flyout_anchor: [
				{ target: 'sw', box: 'nw' },
				{ target: 'nw', box: 'sw' },
				{ target: 'se', box: 'ne' },
				{ target: 'ne', box: 'se' }
			],

			target_type: 'selector',    // 'selector' | 'mouse' | 'coords'
			target_selector: false,     // jQ selector | false
			flyout_min_width: 'target', // 'target' | CSS measurement string | number (of pixels) | false
			flyout_min_width_compensate: true, // true | false // Compensate for flyout borders when calculating width. Only works w/"target" or pixel widths.

			watch_anchoring: true, // true | false | 'auto' // Whether or not to maintain the position of the flyout when the target scrolls
			auto_close: false, // true | false               // Whether or not to remove the flyout when the target is no longer visible
			// auto_close might not be necessary

			hide_flyout_during_scroll: true,
			hide_flyout_when_target_offscreen: true,

			/*
	      anchor_child: $(...) | 'selector' | false,

	      Set this to a selector or jQuery object (to be searched by $self.find) that is the "target" to anchor to. If this is falsy, $self will be used.
	      This is needed if your widget is, for instance, a containing DIV that spans the width of the container, but the target object is an element
	      inside it.

	      This value is parsed each the flyout is shown.
	    */
			flyout_anchor_child: undefined,
			_flyout_base_html: '<div class="flyout-widget-positioner"><div class="flyout-widget-box"><div class="flyout-widget-container" /></div></div>',

			// These options are used internally, and should not be initially set
			_$flyout: undefined,
			_flyout_visible: false,
			_watch_anchoring: false,
			_flyout_hide_keys: {},
			_box_compensate_width: 0
		},


		// Classnames, should your subclass need to redefine them...
		_positioner_class: 'flyout-widget-positioner',
		_box_class:        'flyout-widget-box',
		_container_class:  'flyout-widget-container',

		_buildFlyout: function () {
			var self = this, $self = this.element, $flyout, $box;
			if (self._addDestroyCallback) { self._flyoutAddDestructor(); }

			self.options._$flyout = $flyout = $(self.options._flyout_base_html);

			if (self.options.flyout_min_width_compensate) {
				// Find the amount to compensate on the box width for borders

				$flyout.appendTo('body').css({ top: -1000, left: -1000 });
				$box = $flyout.find('.' + self._box_class);
				self.options._box_compensate_width = $box.outerHeight() - $box.innerHeight();
				$flyout.detach().css({ top: '', left: '' });
			}

			if (self.options.auto_close) {
				if (self.options._watcherInt) { clearInterval(self.options._watcherInt); }
				self.options._watcherInt = setInterval(self._watchFlyout.bind(self), 500);
			}

			return $flyout;
		},

		_flyoutAddDestructor: function () {
			if (this.options._flyoutAddedDestructor) { return; }
			this.options._flyoutAddedDestructor = true;
			this._addDestroyCallback(this._destroyFlyout.bind(this));
		},

		_watchFlyout: function () {
			var self = this, $self = this.element, $target = self.options._$target;
			if (!$target.closest('body')[0]) {
				self._destroyFlyout();
			}
		},

		_destroyFlyout: function () {
			var self = this;
			if (self.options._watcherInt) {
				clearInterval(self.options._watcherInt);
				delete self.options._watcherInt;
			}

			self.options._$flyout.remove();
			self.options._$flyout = null;
			self.options._$target = null;
		},

		showFlyout: function (initial_content) {
			var self = this, $self = this.element, $flyout;

			// Assignment = is intentional!
			if (!($flyout = self.options._$flyout)) {
				$flyout = self._buildFlyout();
			}

			if (initial_content) {
				$flyout.find('.' + self._container_class).html(initial_content);
			}

			self.options.flyout_visible = true;
			self.options._$flyout.appendTo('body').show();
			self.repositionFlyout();
		},

		repositionFlyout: function () {
			var self = this, $self = this.element, anchors = self.options.flyout_anchor;
			if (!$.isArray(anchors)) { anchors = [anchors]; }
			self._anchorFlyout();
		},

		hideFlyout: function () {
			this.options.flyout_visible = false;
			this.options._$flyout.hide();
		},

		_anchorFlyout: function (is_reposition) {
			var self = this;
			switch (self.options.target_type) {
				case 'mouse':
					self._anchorFlyoutToMouse();
					break;
				case 'coords':
					self._anchorFlyoutToCoords();
					break;
				// Intentional fallthrough
				// jshint -W086
				case 'selector':
				default:
				//jshint +W086
					self._anchorFlyoutToSelector();
					break;
			}
		},

		_anchorFlyoutToMouse: function () {
			throw new Error('Target type "mouse" is not yet supported. Perhaps you should write it.');
		},

		_anchorFlyoutToCoords: function () {
			throw new Error('Target type "coords" is not yet supported. Perhaps you should write it.');
		},

		_anchorFlyoutToSelector: function () {
			var self = this, $self = this.element, anchor = self.options.flyout_anchor, dir_map, $target, $positioner, $box, target_pos, target_rel_pos, a_idx_raw, a_idx, css = {}, set_position = false, $scroll_parent, scroll_pos, ref__flyoutScrollHandler, $zi_search, zi_max, zindex;
			dir_map = { n: 'top', s: 'bottom', w: 'left', e: 'right' };
			$target = self.options._$target = self.options.target_selector ? $self.find(self.options.target_selector) : $self;
			$positioner = self.options._$flyout;
			$box = $positioner.find('.' + self._box_class);
			$scroll_parent = $target.scrollParent();
			target_pos = $target.offset();

			if (!$.isArray(anchor)) {
				anchor = self.options.flyout_anchor = [ { target: 'sw', box: 'nw' } ];
				debugLog('jquery.flyoutWidget.js: self.options.flyoutAnchor is not an array. Set to ', anchor, '. -- ', $self);
			}

			if (anchor.length === 1) {
				self.options.watch_anchoring = false;
			}

			// Hide the box if the target is off-screen
			if (self.options.hide_flyout_when_target_offscreen) {
				target_rel_pos = $target.relative($scroll_parent);
				target_rel_pos.bottom = target_rel_pos.top + $target.outerHeight();
				target_rel_pos.right = target_rel_pos.top + $target.outerWidth();

				if ( target_rel_pos.bottom < 0 ||
					target_rel_pos.right < 0 ||
					target_rel_pos.top > $scroll_parent.innerHeight() ||
					target_rel_pos.left > $scroll_parent.innerWidth() ) {

					self.options._flyout_hide_keys.offscreen = true;
					self.options._$flyout.hide();
				} else {
					delete self.options._flyout_hide_keys.offscreen;
				}
			}

			anchorLoop: for (a_idx_raw = 0; a_idx_raw <= anchor.length; a_idx_raw++) {
				a_idx = (a_idx_raw === anchor.length ? 0 : a_idx_raw);

				// Place the positioner
				css = { top: '', left: '', bottom: '', right: '' };

				switch (anchor[a_idx].target.charAt(0)) {
					case 'n':
						css.top = target_pos.top;
						break;
					case 's':
						css.top = target_pos.top + $target.outerHeight();
						break;
				}

				switch (anchor[a_idx].target.charAt(1)) {
					case 'w':
						css.left = target_pos.left;
						break;
					case 'e':
						css.left = target_pos.left + $target.outerWidth();
						break;
				}

				// The positioner is a 0x0 positioning DIV that is always placed by top, left, so we can use .offset to position it, and that way
				// we can position it even if we allow parenting to something other than "body".
				$positioner.offset(css);

				// Place the box
				css = { top: '', left: '', bottom: '', right: '' };

				if (self.options.flyout_min_width === 'target') {
					// Find "target" literal
					css['min-width'] = ($target.outerWidth() - self.options._box_compensate_width) + 'px';
				} else if (self.options.flyout_min_width.toString().search(/^[0-9]+ ?(px)?$/) > -1) {
					// Find numeric or "px" values
					css['min-width'] = (Number(self.options.flyout_min_width.match(/^[0-9]+/)) - self.options._box_compensate_width) + 'px';
				} else if (self.options.flyout_min_width !== false && self.options.flyout_min_width !== undefined) {
					// Find anything else
					css['min-width'] = self.options.flyout_min_width;
				}

				css[ dir_map[ anchor[a_idx].box.charAt(0) ] ] = 0;
				css[ dir_map[ anchor[a_idx].box.charAt(1) ] ] = 0;
				$box.css(css);

				// Check the positioning
				scroll_pos = $box.relative($scroll_parent);
				scroll_pos.bottom = $box.outerHeight() + scroll_pos.top;
				scroll_pos.right = $box.outerWidth() + scroll_pos.left;

				if (
					scroll_pos.top >= 0 &&
					scroll_pos.left >= 0 &&
					scroll_pos.bottom <= $scroll_parent.innerHeight() &&
					scroll_pos.right <= $scroll_parent.innerWidth()
				) {
					set_position = anchor[a_idx];
					break anchorLoop;
				}
			} // END anchorLoop

			set_position = set_position || anchor[0];

			ref__flyoutScrollHandler = self._flyoutScrollHandler.bind(self);
			self._bind($scroll_parent, 'scroll', ref__flyoutScrollHandler);
			self._bind($(window), 'resize', ref__flyoutScrollHandler);
			if (!$scroll_parent.is(document)) {
				self._bind($(document), 'scroll', ref__flyoutScrollHandler);
			}

			$zi_search = $self;
			zi_max = 0;


			while ($zi_search[0] !== document && $zi_search.length !== 0) {
				zindex = $zi_search.css('zIndex');
				zindex = zindex === 'auto' ? undefined : zindex;
				if (zindex > zi_max) { zi_max = zindex; }
				$zi_search = $zi_search.parent();
			}

			if (zi_max !== undefined) {
				$positioner.css('z-index', Number(zi_max) + 1);
			}
		},

		_flyoutScrollHandler: function () {
			var self = this, $self = this.element, timeout = 100, now = (new Date()).getTime();

			if (!self.options._scroll_hide && self.options.hide_flyout_during_scroll) {
				self.options._flyout_hide_keys.scroll = true;
				self.options._$flyout.hide();
			}

			if (self.options._scroll_timeout) { clearTimeout(self.options._scroll_timeout); }
			if (now - self.options._last_scroll < Math.floor(timeout * 0.9)) {
				self.options._scroll_timeout = setTimeout(self._flyoutScrollHandler.bind(self), timeout);
				return;
			}

			// If we're here, then this is a debounced scroll...
			delete self.options._flyout_hide_keys.scroll;

			if (self.options.flyout_visible && !CUI.firstKey(self.options._flyout_hide_keys)) {
				self.options._$flyout.show();
			}

			self.options._last_scroll = now;
			self.repositionFlyout();
		},

		_flyoutWidgetInit: function () {
			var self = this, $self = this.element;
			self.options.destroy_callbacks = self.options.destroy_callbacks || [];
			self.options.destroy_callbacks.push( self._make_exec(self._flyoutWidgetDestroy) );
		},

		_flyoutWidgetDestroy: function () {
			var self = this, $self = this.element;
			if (self.options._$flyout) {
				// Explicit removal destroys all sub-widgets
				self.options._$flyout.children().remove();
				self.options._$flyout.remove();
			}
		},

		getFlyoutContentElement: function () {
			return this.options._$flyout.find('.' + this._container_class);
		}

	};

	CUI.mixin.register('widget.flyoutWidget', flyoutWidgetMixin);
})(jQuery);
