register_screen('diags', {
    elements: [
	{ entity_template: 'page_title', title: 'Diagnostics' },
	{
	    entity_template: 'page_buttons',
	    buttons: [{ type: 'help', help_id: 'diags' }]
	},
	{
	    entity_template: 'page_content', elements: [
		{
		    entity_template: 'form',
		    rest: '/gui/diagnostics/diags',
		    method: 'POST',
		    no_initial_get: true,

		    elements: [
			{
			    entity_template: 'data_table',
			    uri_id: 'diag_main',
			    row_key: 'bbx_diag_id',
			    name: 'run_diags',
			    live_table: 'bbx_diag',
			    click_action: 'toggle_expand',
			    extra_row_data: [
				'bbx_diag_id',
				'bbx_diag_name',
				'bbx_diag_last_completed',
				'bbx_diag_pass_count',
				'bbx_diag_fail_count',
				'bbx_diag_status'
			    ],
			    keep_edit_mode_on_refresh: true,
			    oLanguage: {
				sZeroRecords: 'No Diagnostic tests found'
			    },
			    return_selected: 'bbx_diag_name',
			    no_highlight_details_row: true,

			    /* These options show/hide UI features in dataTable */
			    paginate:      false,
			    length_change: false,
			    filtering:     false,
			    processing:    false,

			    columns: [
				{
				    header: 'Test',
				    column: 'bbx_diag_desc'
				},
				{
				    header: 'Last Completed',
				    column: 'bbx_diag_last_completed',
				    translate_undefined: 'Never',
				    formatted: {
					format: {
					    field: '@bbx_diag_last_completed',
					    formatter: 'date',
					    options: { strip_micro: true }
					}
				    }
				},
				{
				    header: 'Status',
				    column: 'bbx_diag_status',
				    width: '10em'
				},
				{
				    header: 'Passed',
				    column: 'bbx_diag_pass_count',
				    width: '6em'
				},
				{
				    header: 'Failed',
				    column: 'bbx_diag_fail_count',
				    width: '6em'
				},
				{
				    header: 'Completed',
				    column: 'bbx_diag_completed_tests',
				    width: '7em'
				},
				{
				    header: 'Total Tests',
				    column: 'bbx_diag_total_tests',
				    width: '7em'
				}
			    ],

			    table_actions: {
				select_row: true,
				select_all_rows: true,

				action_elements: {
				    actions: [
					{
					    entity: 'button',
					    attributes: {
						type: 'submit'
					    },
					    text: 'Run Selected Tests'
					}
				    ],
				    location: 'bottom'
				}
			    },

			    details_row: {
				view_elements: [
				    {
					entity_template: 'data_table',
					uri_id: 'diag_sub',
					live_table: 'bbx_diag_test',
					live_unique_ident: true,
					accept_data_from_parent: false,
					search_parent_field: 'bbx_diag_id',
					oLanguage: {
					    sZeroRecords: 'No Test Results Found'
					},
					extra_row_data: ['bbx_diag_id'],
					paginate:      false,
					length_change: false,
					filtering:     false,
					processing:    false,

					columns: [
					    {
						header: 'Sub-Test',
						column: 'bbx_diag_test_name'
					    },
					    {
						header: 'Result',
						column: 'bbx_diag_test_result'
					    },
					    {
						header: 'Problems',
						column: 'bbx_diag_test_info',
						view_element: {
						    entity: 'div',
						    attributes: { name: 'bbx_diag_test_info' },
						    widgets: ['diagnosticsJSONWidget']
						}
					    },
					    {
						header: 'Solutions',
						column: 'bbx_diag_test_solutions',
						view_element: {
						    entity: 'div',
						    attributes: { name: 'bbx_diag_test_solutions' },
						    widgets: ['diagnosticsJSONWidget']
						}
					    }
					]
				    }
				]
			    }
			} // END DATATABLE ELEMENT DEF

		    ]// END FORM ELEMENTS ARRAY
		} // END FORM ELEMENT DEF
	    ] // END PAGE CONTENT ELEMENTS ARRAY
	} // END PAGE CONTENT ELEMENT DEF
    ] // END SCREEN ELEMENTS ARRAY
});
