
register_template('text_tokenizer', function(field_def) {
    var display = build_standard_entity_options('div', 'textTokenizerWidget', field_def);
    return display;
});

register_template('phone_number_tokenizer', function(field_def) {
    var display = build_standard_entity_options('div', 'phoneNumberTokenizerWidget', field_def);
    return display;
});
