/* jshint jquery: true, unused: vars */
/* global add_widget */
(function( $ ){
	// This started as a widget, but it turns out all it needs is a template. Still keeping it a widget, though, since we might want something more in the
	// future.

	var mastheadWidget = $.extend({}, $.ui.widget.prototype, {
		options: {
			template_html: '<span class="extension widgetType formatWidget" data-js=\'{ "format" : { "field" : "bbx_extension_value", "formatter" : "phone_number" } }\'></span> &mdash; <span class="name widgetType widget" name="show_name" /> <span style="visibility: hidden" name="bbx_extension_value" class=name widgetType widget"></span>',
			accept_data_from_parent: true
		}
	});

	add_widget('mastheadWidget', $.extend({}, $.ui.widget.prototype, mastheadWidget));
})(jQuery);
