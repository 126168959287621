(function () {
    register_screen('firstrun_validate', {
	allow_nav_on_dirty: true,
	elements: [
	    { entity_template: 'page_title', title: 'Validate and Complete Setup' },
	    { entity_template: 'page_buttons', buttons: [{ type: 'save_refresh', text: 'Complete Setup', blanker: { wait: 5000, title: 'Setup Complete', text: 'Initial setup is complete. One moment while we refresh the page...' } }] },
	    {
		entity_template: 'page_content', elements: [
		    {
			entity_template: 'page_module',
			legend: 'Settings Check',
			buttons: ['help'],
			help_id: 'firstrun_settings_check',
			elements: [
			    {
				entity: 'p',
				attributes: { 'class': 'bodyCopy' },
				text: 'Once all settings have been properly configured, click the ' + entity.ldquo + 'Complete Setup' + entity.rdquo + ' button at the top right of the page to complete setup and enter Administrator mode. If you need to fix a problem, click the proper page on the left-hand navigation.'
			    },
			    {
				entity_template: 'form',
				method: 'GET', // This is a dummy form, it submits nothing
				rest: '/gui/first_run/validate',
				elements: [
				    {
					entity_template: 'data_item',
					title: '',
					elements: [{
					    entity_template: 'form_refresh_button',
					    text: 'Check again...'
					}]
				    },
				    {
					entity_template: 'data_item',
					title: 'Activation:',
					elements: [{
					    entity: 'div',
					    attributes: { 'class': 'valid-check' },
					    widgets: ['aopbStateWidget'],
					    widget_options: {
						aopbStateWidget: {
						    states: [{
							conditions: { a: '@activated', op: '==', b: '_TRUEISH_' },
							actions: { text: 'OK', color: '#080', invalid: false },
							else_actions: { text: 'Not Activated', color: '#c00', invalid: 'Your Barracuda Phone System must be Activated to continue.' }
						    }]
						}
					    }
					}]
				    },
				    {
					entity_template: 'data_item',
					title: 'System Update:',
					elements: [{
					    entity: 'div',
					    attributes: { 'class': 'valid-check' },
					    widgets: ['aopbStateWidget'],
					    widget_options: {
						aopbStateWidget: {
						    states: [{
							conditions: { a: '@update', op: '==', b: '_TRUEISH_' },
							actions: { text: 'OK', color: '#080', invalid: false },
							else_actions: { text: 'Not updated', color: '#c00', invalid: 'Upgrade to the latest firmware version before continuing.' }
						    }]
						}
					    }
					}]
				    },
				    {
					entity_template: 'data_item',
					title: 'Extension Blocks:',
					elements: [{
					    entity: 'div',
					    attributes: { 'class': 'valid-check' },
					    widgets: ['aopbStateWidget'],
					    widget_options: {
						aopbStateWidget: {
						    states: [{
							conditions: { a: '@blocks', op: '==', b: '_TRUEISH_' },
							actions: { text: 'OK', color: '#080', invalid: false },
							else_actions: { text: 'None Configured', color: '#c00', invalid: 'Extension Blocks must be configured.' }
						    }]
						}
					    }
					}]
				    },
				    {
					entity_template: 'data_item',
					title: 'Administrator Password:',
					elements: [{
					    entity: 'div',
					    attributes: { 'class': 'valid-check' },
					    widgets: ['aopbStateWidget'],
					    widget_options: {
						aopbStateWidget: {
						    states: [{
							conditions: { a: '@admin_password', op: '==', b: '_TRUEISH_' },
							actions: { text: 'OK', color: '#080', invalid: false },
							else_actions: { text: 'Not Changed', color: '#c00', invalid: 'The Administrator Password is still using the default value.' }
						    }]
						}
					    }
					}]
				    },
				    {
					entity_template: 'data_item',
					title: 'Area Code:',
					elements: [{
					    entity: 'div',
					    attributes: { 'class': 'valid-check' },
					    widgets: ['aopbStateWidget'],
					    widget_options: {
						aopbStateWidget: {
						    states: [{
							conditions: { a: '@area_code', op: '==', b: '_TRUEISH_' },
							actions: { text: 'OK', color: '#080', invalid: false },
							else_actions: { text: 'Not Set', color: '#c00', invalid: 'You must set an area code.' }
						    }]
						}
					    }
					}]
				    },
				    {
					entity_template: 'data_item',
					title: 'Notification Email Address:',
					elements: [{
					    entity: 'div',
					    attributes: { 'class': 'valid-check' },
					    widgets: ['aopbStateWidget'],
					    widget_options: {
						aopbStateWidget: {
						    states: [{
							conditions: { a: '@notification_email', op: '==', b: '_TRUEISH_' },
							actions: { text: 'OK', color: '#080', invalid: false },
							else_actions: { text: 'Not Configured', color: '#c00', invalid: 'You must specify an email address for notifications.' }
						    }]
						}
					    }
					}]
				    },
				    { entity: 'div', attributes: { style: 'display: none', 'class': 'always-dirty-indicator' } }
				]
			    },
			    {
				entity_template: 'form',
				method: 'PUT', // This actually updates the first run status, but it will not be submitted if the previous form is invalid
				rest: '/gui/system/firstrun',
				rest_params: { step: 5 }, // Step 5, for UI v2.x-3.x upgrade compatibility
				attributes: { css: 'display: none; margin: 0; padding: 0' },
				elements: [
				    { entity: 'div', attributes: { style: 'display: none', 'class': 'always-dirty-indicator' } }
				]
			    }
			]
		    },
		    {
			entity_template: 'page_module',
			legend: 'Troubleshooting',
			buttons: ['help'],
			help_id: 'firstrun_settings_troubleshooting',
			elements: [
			    {
				entity: 'p',
				attributes: { 'class': 'bodyCopy' },
				text: 'If troubleshooting is required, you may be directed by Support technicians to open a Support Tunnel.'
			    },
			    {
				entity_template: 'data_item',
				title: 'Support Tunnel:',
				elements: [
				    {
					entity_template: 'overlay_button',
					overlay: 'firstrun_support_tunnel',
					params: {},
					text: 'Support Tunnel Setup'
				    }
				]
			    }
			]
		    }

		] // END page_content elements
	    } // END page_content
	] // END template elements
    }); // END template
})(); // END scoping function
