/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){
	var supportTunnelStatusWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			image: '/images/cui/spinner_content.gif',
			closest: 'dd',
			start_button: 'button.support_tunnel_start',
			stop_button: 'button.support_tunnel_stop',

			tunnel_enabled: true
		},

		_beforeInit: function() {
			var self = this, $self = self.element, $page;

			$page = $self.closest('.pageWidgetType');

			if (!$page.hasClass('screenReady')) {
				self._one($page, 'screenReady', self._screenReadyHandler.bind(self));
			} else {
				self._screenReadyHandler();
			}
		},

		_beforeDOMReady: function() {
			var self = this, $self = this.element;

			$self.html($('<img>').attr('src', self.options.image));
			self.options.$image = $self.find('img');
			self.options.$image.hide();
		},

		_screenReadyHandler: function () {
			var self = this, $self = this.element;

			self.options.$start = $self.closest(self.options.closest).find(self.options.start_button);
			self.options.$stop = $self.closest(self.options.closest).find(self.options.stop_button);
			self.options.$enabled_form = $self.closest('form');
			self.options.enabled_form = self.options.$enabled_form.getCUIWidget('formWidget');

			self._bind(self.options.$start, 'click', self._buttonClickHandler.bind(self, 'start'));
			self._bind(self.options.$stop, 'click', self._buttonClickHandler.bind(self, 'stop'));
			self._bind(self.options.$enabled_form, 'SubmitSuccess producerFillData', self._tunnelEnabledChangeHandler.bind(self));

			self._stopped();
		},

		_tunnelEnabledChangeHandler: function() {
			var self = this, $self = self.element, data = self.options.enabled_form.options.data;

			if (data && typeof data.tunnel_enabled !== 'undefined') {
				self.options.tunnel_enabled = (data.tunnel_enabled ? true : false);
			}

			if (!self.options.tunnel_enabled) {
				if (self.options.support_tunnel_status !== 'closed' && self.options.support_tunnel_status) {
					self.options.$stop.trigger('click');
				}
				self._disableBothButtons('tunnel-disabled');
			} else {
				self._setButtonState(self.options.$start, true, 'tunnel-disabled');
				self._setButtonState(self.options.$stop, true, 'tunnel-disabled');
			}
		},

		_buttonClickHandler: function(last_clicked, e) {
			var self = this, $self = self.element;

			self.options.last_clicked = last_clicked;

			// Disable both buttons, the appropriate button will be enabled during fillData when a status update is received

			self._disableBothButtons();

			// Show spinner because we've clicked either stop or start

			self.options.$image.show();
		},

		_setButtonState: function ($button, enabled, key) {
			var self = this;
			$button[enabled ? 'enable' : 'disable'](key);
			$button.toggleClass('state-disabled', !!$button.is('[disabled]'));
		},

		_disableStartButton: function(key) {
			var self = this, $self = this.element;
			if (self.options.$start) {
				self._setButtonState(self.options.$start, false, key);
			}
		},

		_enableStartButton: function(key) {
			var self = this, $self = this.element;
			if (self.options.$start) {
				self._setButtonState(self.options.$start, true, key);
				self._disableStopButton(key);
			}
		},

		_disableStopButton: function(key) {
			var self = this, $self = this.element;
			if (self.options.$stop) {
				self._setButtonState(self.options.$stop, false, key);
			}
		},

		_enableStopButton: function(key) {
			var self = this, $self = this.element;

			if (self.options.$stop) {
				self._setButtonState(self.options.$stop, true, key);
				self._disableStartButton(key);
			}
		},

		_disableBothButtons: function(key) {
			var self = this, $self = this.element;

			key = key || 'status-widget';
			self._disableStopButton(key);
			self._disableStartButton(key);
		},

		_started: function() {
			var self = this, $self = this.element;
			self._enableStopButton('status-widget');
		},

		_stopped: function() {
			var self = this, $self = this.element;
			self._enableStartButton('status-widget');
		},

		fillData: function(d) {
			var self = this, $self = this.element;

			if (d.error) {
				// An error has been passed from the backend, so clearly we aren't connected/can't connect
				self._stopped();
				self.options.$image.hide();
			}

			if (d.support_tunnel_status) {
				self.options.support_tunnel_status = d.support_tunnel_status;

				if (d.support_tunnel_status === 'connecting') {
					// Support tunnel is in the process of opening; may have been initiated via another browser;
					self._disableBothButtons();
					self.options.$image.show();
				} else if (self.options.last_clicked !== 'start' && d.support_tunnel_status === 'closed') {
					// Checking for last_clicked, because we may get a status of 'closed' shortly after clicking 'start', before we get 'connecting'
					// In that case, we don't want to assume that the tunnel is closed and ready to be opened again.
					self._stopped();
					self.options.$image.hide();
					self.options.last_clicked = undefined;
				} else if (d.support_tunnel_status === 'open') {
					self._started();
					self.options.$image.hide();
					self.options.last_clicked = undefined;
				}
			}

			$.ui.widget.prototype.fillData.apply(self, arguments);
		}
	});

	add_widget('supportTunnelStatusWidget', supportTunnelStatusWidget);
})(jQuery);
