/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){
    var moverWidget = $.extend(true, {}, $.ui.widget.prototype, {
	options: {
	    handle: false,    // Do we want to specify a child element to use as a handle? This can be a selector or an element
	    scope: "default", // Scope for drop targets; if set here and on the droppable, limits what droppables will accept this

	    // The helper is what will travel along with the mouse cursor while dragging. 'Clone' copies the current element, while 
	    // "icon" uses an image with data (usually a name) specified by the helper_text or helper_text_key
	    helper: "clone" | "icon"
	    // helper_text: undefined | 'Static Text',    // A static label for an "icon" helper
	    // helper_key:  undefined | 'key_name',       // Dynamic text from a key (from widget data) for the "icon" helper
	    // helper_icon: undefined | '/path/to/image', // The icon image. Required if helper is "icon"
	},

	_beforeDOMReady: function () {
	    var self = this, $self = this.element;

	    self._initDraggable();
	},

	_beforeInit: function() {
	    var self = this, $self = self.element;

	    if (self.options.helper === 'icon') {
			self.options.helper = self._buildHelper.bind(self);
	    }
	},

	_buildHelper: function () {
	    var self = this, $helper, helper_text;
	    $helper = $('<div class="mover-helper" />');
	    
	    if (self.options.helper_icon) {
		$helper.append($('<img class="mover-helper-img" />').attr('src', self.options.helper_icon));
	    }
	    
	    if (self.options.helper_text || self.options.helper_key) {
		helper_text = self.options.helper_text !== undefined ? self.options.helper_text : self.options.widget_data[self.options.helper_key];
		$helper.append($('<div class="mover-helper-text" />').text(helper_text));
	    }

	    return $helper;
	},

	_initDraggable: function() {
	    var self = this, $self = this.element;
	    $self.css( 'cursor', 'pointer' );
	    $self.draggable({
			handle: self.options.handle,
			helper: self.options.helper,
			scope: self.options.scope,
			zindex: 9999999,
			appendTo: '.screen',
			containment: 'window',
			cursor: 'pointer',
			start: self._startHandler.bind(self),
			stop: self._stopHandler.bind(self),
			drag: self._dragHandler.bind(self)
	    });
	},

	_startHandler: function(event, ui) {
	    var self = this, $self = this.element;
	},

	_dragHandler: function(event, ui) {
	    var self = this, $self = this.element;
	},

	_stopHandler: function() {
	    var self = this, $self = this.element;
	},

	_destroy: function() {
	    var self = this, $self = this.element;
	    $self.droppable('destroy');
	}
    });
    
    add_widget('moverWidget', moverWidget);
})(jQuery);
