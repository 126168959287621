/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/**
 * @class textTokenizerWidget(options)
 *
 * When applied to a dom element will create a display field and a textarea. From the text area
 * input will be parsed for predefined tokens which will be sent to the appendToken function.
 *
 *
 */

(function( $ ){
	var textTokenizerWidget = $.extend({}, $.ui.widget.prototype, {

		value_widget: true,
		set_value_widget: true,

		manages_own_descendent_events: true,
		manages_own_descendent_value: true,

		allow_empty_token_data: false,        // Show tokens from fillData/setValue that only consist of an empty string?
		allow_single_empty_token_data: false, // Show a blank token if the fillData/setValue only consists of one empty string? (Implies a_e_d_t: true)

		options: {
			// Include the parsed value remaining in the textbox when returning the widget's value (note that this does not affect tokenization and changes to
			// the value on blur, which always happens. This is useful, though, to cause a dirty-tracking hit when nothing has triggered tokenization yet.
			include_remaining_in_value: true,
			trigger_change_on_keypress: true,

			$tokenTemplate: $('<div class="token"><span class="tokenText"></span><a href="#" class="opDeleteToken" title="Delete this item"><img src="/images/textTokenizerWidget/closeX.png" width="9" height="9" alt="Delete" /></a></div>'),
			output_type: 'array', // array | comma-sep | space-sep
			tokens_editable: true
		},

		_beforeInit: function() {
			var self = this, $self = this.element;

			$self.addClass('textTokenizerWidgetType');

			if (!self.options.template_html) {
				self.options.template_html = $self.contents();
			}

		},

		_removeToken: function ($token) {
			var self = this;
			var $self = this.element;

			if ($token == 'last') {
				$token = $('.tokens > .token:last', $self);
			} else if (!$token || !$token[0]) {
				return;
			}

			$token.remove();
			self._resetTextareaPosition();
			$self.trigger('change');
		},

		_editToken: function (elem, e, d) {
			var self = this, $self = this.element, $token = $(elem).closest('.token'), token_prev_value = $token.data('tokenValue'), $textbox;

			if (!self.options.tokens_editable) { return; }

			$textbox = $('<input type="text" class="tokenEdit" />').val(token_prev_value);
			self._one($textbox, 'blur.tokenEdit', self._editTokenApply.bind(self, $token));
			self._bind($textbox, 'keyup', CUI.FunctionFactory.build(self._editTokenKeyHandler, self, { context: 'argument', first: 'context' }));

			$token.children('.tokenText').replaceWith($textbox);
			$textbox[0].select();

			self._editTokenResize($token);
		},

		_editTokenKeyHandler: function (elem, e) {
			var self = this, $self = this.element, key = new CUI.KeyCode(e.which), $token = $(elem).closest('.token');

			e.stopPropagation();

			switch (key.getName()) {
				case 'ESC':
					self._editTokenCancel($token);
					break;
				case 'ENTER':
					self._editTokenApply($token);
					break;
				default:
					self._editTokenResize($token);
			}
		},

		_editTokenApply: function ($token) {
			var self = this, $self = this.element, $edit, raw_value, text, value, $new_text_element;

			$edit = $token.children('.tokenEdit');

			raw_value = $edit.val();
			value = self._processTokenValue(raw_value);
			text = self._processTokenText(value);

			if (value === undefined) {
				self._editTokenCancel($token);
			}

			$new_text_element = self.options.$tokenTemplate.find('.tokenText').clone();
			$new_text_element.text(text);
			$token.data('tokenValue', value);

			$edit.replaceWith($new_text_element);
			self._resetTextareaPosition();
			$self.trigger('change');
		},

		_editTokenCancel: function ($token) {
			var self = this, $self = this.element, $edit, raw_value, text, $new_text_element;
			$edit = $token.children('.tokenEdit');

			raw_value = $token.data('tokenValue');
			text = self._processTokenText(raw_value);

			$new_text_element = self.options.$tokenTemplate.find('.tokenText').clone();
			$new_text_element.text(text);

			$edit.replaceWith($new_text_element);
			self._resetTextareaPosition();
		},

		_editTokenResize: function ($token) {
			var self = this, token_value, $edit, $sizer, width;

			$edit = $token.children('.tokenEdit');
			token_value = $edit.val();

			$sizer = $('<div class="tokenSizer" />').css({ position: 'absolute', left: '-999em', visibility: 'hidden' }).text(token_value);
			$sizer.appendTo($token);
			width = $sizer.width();
			$sizer.remove();

			// Add, roughly, an extra character's width at the end, to prevent jumpiness when characters are added
			$edit.css('width', width + (token_value ? (width / token_value.length) : 0) + 3 + 'px');

			self._resetTextareaPosition();
		},

		_clearTokens: function () {
			// This does NOT trigger a change after clearing!
			var self = this;
			var $self = this.element;

			$('.tokens > .token', $self).remove();
			self._resetTextareaPosition();
		},

		_processStaticBody: function ($staticBody) {
			var self = this, $self = this.element;
			$staticBody.html('<div class="textTokenizerWrap"><div class="textTokenizerDisplay"><div class="tokens"></div></div><textarea class="textTokenizerTextArea"></textarea></div>');
			self.options._$textarea = $staticBody.find('textarea.textTokenizerTextArea');
			return $staticBody;
		},

		_beforeDOMReady: function () {
			var self = this, $self = this.element, $textarea;
			$textarea = self.options._$textarea;

			self._bind($textarea, 'keyup keydown', CUI.FunctionFactory.build(self._textareaKeyHandler, self, { context: 'argument', first: 'context' }));

			if (self.options.clear_on_success) {
				$self.closest('form').on('SubmitSuccess', self._clearTokens.bind(self));
			}

			self._bind($textarea, 'focus', function (e) {
				self.options.long_blur = false;
				self.options.focus = true;
			});

			self._bind($textarea, 'blur', function (e) {
				self.options.focus = false;
				self.parse();
			});

			self._delegate($self, '.tokenText', 'click', CUI.FunctionFactory.build(self._editToken, self, { context: 'argument', first: 'context' }));
		},

		_textareaKeyHandler: function (textarea, e) {
			var self = this, $self = this.element, caret = self._getCaret();
			if (e.type === 'keydown' && CUI.KeyCode.codeToName(e.which) === 'BACKSPACE' && ((caret[0] === 0 && caret[1] === 0) || $(textarea).val() === '')) {
				self._removeToken('last');
			} else if (e.type === 'keyup' && CUI.KeyCode.codeToName(e.which) !== 'BACKSPACE') {
				self.parse();
			}

			if (self.options.trigger_change_on_keypress) {
				$self.trigger('change');
			}
		},

		_getCaret: function () {
			var self = this;
			var $self = this.element;

			var $textbox = $('textarea', $self);
			var textboxElement = $textbox[0];

			var start = 0;
			var end = 0;

			if( document.selection ){
				// AIEEEEEE!
				var range = document.selection.createRange();
				var stored_range = range.duplicate();
				stored_range.moveToElementText( textboxElement );
				stored_range.setEndPoint( 'EndToEnd', range );
				start = stored_range.text.length - range.text.length;
				end = start + range.text.length;
			} else if (textboxElement.selectionStart || textboxElement.selectionStart == '0') {
				start = textboxElement.selectionStart;
				end = textboxElement.selectionEnd;
			}

			return [start, end];
		},

		_resetTextareaPosition: function ($token) {
			var self = this;
			var $self = this.element;
			var $textarea = self.options._$textarea;
			var right, top, height;

			if (!$token || !$token[0]) {
				$token = $('.tokens > .token:last', $self);
			}

			if ($token[0]) {
				right = $token.position().left + $token.outerWidth();
				top = $token.position().top;
				height = $token.outerHeight();
			} else {
				right = 0;
				top = 0;
				height = false;
			}

			$textarea.css({
				'text-indent': right + 'px',
				'padding-top': top,
				'line-height': height ? height+'px' : ''
			});


			if (self.options.focus) {
				$textarea.focus();
			}
		},

		_processTokenText: function (value) {

			/* OVERRIDE ME! --------------------------------------------------------------------------------------------------------------------------------

	       This function takes the value of an individual token, and returns the text to be displayed in the token.

	    */

			return value;
		},

		_processTokenValue: function (value) {

			/* OVERRIDE ME! --------------------------------------------------------------------------------------------------------------------------------

	       This function takes the value of an individual token, and returns the value to be submitted. Return "undefined" if the token is invalid. This
	       causes edit actions to fail when the new value is invalid.

	    */

			return value;
		},

		_processTokenElement: function ($token, value) {

			/* OVERRIDE ME! --------------------------------------------------------------------------------------------------------------------------------

	       This function takes a jQuery element with the token, and the value of an individual token. It returns a jQuery element that is the new or
	       modified token element. Use this to add CSS styles or classes, or add specific elements to tokens.

	       WARNINGS:
	          1.) The element has not been added to the page at the time this function is executed.
		  2.) Avoid intensive tasks-- this is run on every token individually. To change the token template, override the template in self.options.

	    */

			return $token;
		},

		_parseCurrentText: function (value, blur) {

			/* OVERRIDE ME! --------------------------------------------------------------------------------------------------------------------------------

	       This function takes the value of the textarea after each keypress, and returns an array of any new tokens, with the last element being the
	       new value of the textbox (any "leftovers"). If no new token should be added, return an array with the original value as the only element.

	       The default example given tokenizes strings of contiguous "word" characters.

	       WARNINGS:
	         As an optimization, the current text in the textarea is only changed if tokens are returned.

	    */

			var capture = value.match(/\b\w+\b\W/g);
			var leftover = value;
			var tokens = [];

			if (capture) {
				for (var i=0; i<capture.length; i++) {
					var chunk = capture[i];
					var keep = chunk.match(/\w+/)[0];
					leftover = leftover.replace(chunk, ''); // This is NOT global, so it will only match the first instance of "chunk", the one we want
					tokens.push(keep);
				}
			}

			tokens.push(leftover); // The last "token" is the value for the textarea
			return tokens;
		},

		_processTokenValuesArrayOut: function (values) {
			/* OVERRIDE ME! --------------------------------------------------------------------------------------------------------------------------------

	       This function takes the array of tokens as it is about to be submitted, and returns the output in the format to be submitted to the form.

	    */

			var self = this;

			if (self.options.output_type === 'comma-sep') {
				return values.join(',');
			} else if (self.options.output_type === 'space-sep') {
				return values.join(' ');
			} else {
				return values;
			}
		},

		_processTokenValuesArrayIn: function (values) {
			/* OVERRIDE ME! --------------------------------------------------------------------------------------------------------------------------------

	       This function takes the incoming value and processes it into an array of tokens to be added to the widget.

	    */

			if (!$.isArray(values)) {
				return [values];
			} else {
				return values;
			}
		},

		_getProcessedRemaining: function () {
			var self = this, $textarea = self.options._$textarea;
			return self._processTokenValue($textarea.val());
		},

		appendToken: function(value, remaining) {
			var $self = this.element;
			var self = this;

			var procValue = self._processTokenValue(value);
			var text = self._processTokenText(procValue);

			if (procValue === undefined) {
				return;
			}

			var $token = self.options.$tokenTemplate.clone();
			$token
			.data('tokenValue', procValue || '')
			.find('.tokenText').text(text);
			$('.opDeleteToken', $token).on('click', function (e) {
				e.preventDefault();
				self._removeToken($(this).closest('.token'));
			});

			$token = self._processTokenElement($token, procValue);

			$('.textTokenizerDisplay > .tokens', $self).append($token);

			if (!remaining) {
				// If the batch processor in "parse" gives us a "remaining", wait until it is zero (the batch is done), because resetting the position is
				// too intensive to do every time
				self._resetTextareaPosition($token);
				$self.trigger('change');
			}
		},

		parse: function () {
			var $self = this.element;
			var self = this;
			var $textarea = self.options._$textarea;

			var tokens = self._parseCurrentText($textarea.val(), !self.options.focus);

			var newText = tokens.pop();

			if (tokens.length) {
				for (var i=0; i<tokens.length; i++) {
					var token = tokens[i];
					self.appendToken(token, tokens.length - 1 - i);
				}
				$textarea.val(newText || '');
			}

		},

		setValue: function (v) {
			var self = this;

			if (v === undefined || v === null) { v = []; }

			var values = self._processTokenValuesArrayIn(v);

			self._clearTokens();

			if (values.length === 1 && values[0] === '' && self.options.allow_single_empty_token_data) {
				self.appendToken('', 0);
			} else {
				for (var i=0; i<values.length; i++) {
					if (values[i] !== '' || self.options.allow_empty_token_data) {
						self.appendToken(values[i], values.length - 1 - i);
					}
				}
			}
		},

		_getWidgetValue: function () {
			var self = this, $self = self.element, values = [], remaining;

			$('.token', $self).each(function (idx, elm) {
				values.push($(elm).data('tokenValue'));
			});

			if (self.options.include_remaining_in_value) {
				remaining = self._getProcessedRemaining();
				if (remaining !== undefined) {
					values.push(remaining);
				}
			}

			values = self._processTokenValuesArrayOut(values);

			return self._wrapValue(values);
		}
	});
	add_widget('textTokenizerWidget', textTokenizerWidget);
})(jQuery);
