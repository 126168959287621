var sendDebugLog = function () {};
var enableRemoteLogging = function (port) {
	var scriptTag = document.createElement('script');
	scriptTag.setAttribute('src', 'http://127.0.0.1:' + (port || 55555) + '/client.js');
	document.body.appendChild(scriptTag);
};

if (window.ALLOW_REMOTE_LOGGING) {
	(function () {
		var debugLogMatch = location.search.match(/sendlog:(\d+)/);
		if (debugLogMatch) {
			enableRemoteLogging(debugLogMatch[1]);
		} else if (typeof ALLOW_REMOTE_LOGGING === 'number') {
			enableRemoteLogging(ALLOW_REMOTE_LOGGING);
		}
	})();
}
