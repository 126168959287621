(function () {
    register_screen('event_log', {
	elements: [
	    { entity_template: 'page_title', title: 'Event Log' },
	    {
		entity_template: 'page_buttons',
		buttons: [{ type: 'help', help_id: 'configuration_event_log' }]
	    },
	    {
		entity_template: 'page_content', elements: [

		    {
			entity_template: 'data_table',
			rest: "/gui/eventlog/eventlog",
			rest_params: {
			    sortby: 'bbx_eventlog_date_time',
			    sortorder: 'desc',
			    since: '1 day'
			},
			search_tooltip: 'Filter by the Message column',
			attributes: { 'class': 'event-log-dt' },
			table_actions: {
			    refresh: { text: "Refresh", location: "both" },
			    action_elements: {
				actions: [
				    {
					entity: 'div',
					widgets: [ 'dateRangeSelectorWidget', 'dtwParamAmendWidget' ],
					widget_options: {
					    dtwParamAmendWidget: {
						selector: '.event-log-dt'
					    },
					    dateRangeSelectorWidget: {
						ranges: ['last_hour', 'last_day', 'last_week', 'last_month', 'all']
					    }
					}
				    }
				],
				location: 'top',
				position: 'first'
			    }
			},
			page_size: 50,
			columns: [
			    {
				header: "Date",
				column: "bbx_eventlog_date_time"
			    },
			    {
				header: "Priority",
				column: "bbx_eventlog_priority"
			    },
			    {
				header: 'User Name',
				column: 'bbx_eventlog_fullname'
			    },
			    {
				header: 'IP Address',
				column: 'bbx_eventlog_ip_addr'
			    },
			    {
				header: "Message",
				column: "bbx_eventlog_message"
			    }
			]
		    }

		] // END page_content elements
	    } // END page_content
	] // END template elements
    }); // END template
})(); // END scoping function
