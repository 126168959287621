/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, debugLog */
/* IPv4 Input Widget
 *
 * @class ipv4InputWidget(options)
 *
 */

(function( $ ){

	var ipv4InputWidget = $.extend({}, $.ui.widget.prototype, {
		value_widget: true,
		set_value_widget: true,

		manages_own_descendent_value: true,
		manages_own_descendent_events: true,

		options: {
			template: '/ajax-html/ipv4Input.html'
		},

		_beforeDOMReady: function () {
			var self = this;
			var $self = $(this.element);

			// Initialize inputObjects object.
			self.inputObjects = {
				inputArray: [], // We add all inputs to this array for easy pass throughs.
				ipv4value: ''
			};

			// Register Input Objects.
			self._registerInputObjects();
			// debugLog("inputObjects", self.inputObjects, self);

			//Bind input commands
			self._bindInputEvents();
		},

		/*
	 * @fn _registerInputObjects()
	 * @brief Register input elements, set visual states, and initialize data objects.
	 */
		_registerInputObjects: function () {
			var self = this;
			var $self = $(this.element);
			var inputObjects = self.inputObjects;
			var $inputObjs = $self.find("input");

			// If we find title register title element and set title text.
			if (self.options.title) {
				inputObjects.title = $($self.find(".ipv4InputTitle"));
				inputObjects.title.text(self.options.title);
			}

			// Loop through inputObjs elements and register all ipv4 inputs.
			if ($inputObjs && $inputObjs.length == 4) {
				$inputObjs.each(function () {
					var $elem = $(this);
					var regexMatch;

					if ((regexMatch = $elem.attr("ident").match(/ipv4_([0-3])/))) {
						inputObjects.inputArray[parseInt(regexMatch[1])] = $elem;
					}
				});
			} else {
				debugLog("jquery.ipv4InputWidget.js: Unable to register all input objects. -- ", $self);
			}

		},

		/*
	 * @fn _bindInputEvents()
	 * @brief Bind to change and keyup events for objects registered input elements.
	 */
		_bindInputEvents: function () {
			var self = this;
			var $self = $(this.element);
			var inputObjects = self.inputObjects;

			// Period key trigger callback.
			self.options.onPeriodCallback = function ($currentTarget, eventType, keys) {
				self._onPeriodtrigger($currentTarget, eventType, keys);
			};

			self.options.onFilterCallback = function (e, filter, character) {
				self._onIPByteChange(e, filter, character);
			};

			self.options.onEventCallback = function (e) {
				self._updateState(e);
			};

			// Input ipv4[0] callbacks, filters and trigger.
			CUI.keyCapture.addFilter(inputObjects.inputArray[0], "[0-9]", self.options.onFilterCallback);
			//CUI.keyCapture.addFilter(inputObjects.inputArray[0], $.ui.keyCode.BACKSPACE, self.options.onEventCallback);
			CUI.keyCapture.addTrigger(inputObjects.inputArray[0], ["."], self.options.onPeriodCallback);
			$(inputObjects.inputArray[0]).on("change keyup", self.options.onEventCallback);

			// Input ipv4[1] callbacks, filters and trigger.
			CUI.keyCapture.addFilter(inputObjects.inputArray[1], "[0-9]", self.options.onFilterCallback);
			CUI.keyCapture.addTrigger(inputObjects.inputArray[1], ["."], self.options.onPeriodCallback);
			$(inputObjects.inputArray[1]).on("change keyup", self.options.onEventCallback);

			// Input ipv4[2] callbacks, filters and trigger.
			CUI.keyCapture.addFilter(inputObjects.inputArray[2], "[0-9]", self.options.onFilterCallback);
			CUI.keyCapture.addTrigger(inputObjects.inputArray[2], ["."], self.options.onPeriodCallback);
			$(inputObjects.inputArray[2]).on("change keyup", self.options.onEventCallback);

			// Input ipv4[3] callbacks, filters.
			CUI.keyCapture.addFilter(inputObjects.inputArray[3], "[0-9]", self.options.onFilterCallback);
			$(inputObjects.inputArray[3]).on("change keyup", self.options.onEventCallback);

		},

		setValue: function(v) {
			var self = this;
			var $self = $(this.element);
			var inputObjects = self.inputObjects;
			var regexMatch;

			if (!v) { return; }

			if ((regexMatch = v.match(/^([0-9]+)[.]([0-9]+)[.]([0-9]+)[.]([0-9]+)$/))) {

				self.inputObjects.ipv4value = v;

				for (var i=0; i<4; i++) {
					inputObjects.inputArray[i].val(regexMatch[i+1]);
					self._ValidateIPByte(inputObjects.inputArray[i]);
				}

				// We may want to unbind and rebind after this trigger call.
				$self.triggerHandler('change');
			} else {
				debugLog("jquery.ipv4InputWidget.js: Input set value", v, "is invalid. Pleae use a valid IPv4 format. -- ", $self);
			}
		},

		_getWidgetValue: function () {
			var self = this;
			return this._wrapValue(self.inputObjects.ipv4value);
		},

		_updateState: function(e) {
			var self = this;
			var $self = $(this.element);
			var inputObjects = self.inputObjects;
			var $currentTarget = $(e.target);
			var ipv4value = inputObjects.inputArray[0].val() + "." + inputObjects.inputArray[1].val() + "." + inputObjects.inputArray[2].val() + "." + inputObjects.inputArray[3].val();

			self._ValidateIPByte($currentTarget);

			// Set ipv4 value
			if (self.inputObjects.ipv4value != ipv4value) {
				self.inputObjects.ipv4value = ipv4value;
				$self.trigger("change");
			}
		},

		/*
	 * @fn _onIPByteChange(e)
	 * @brief Event binding for change or keypress callbacks.
	 */
		_onIPByteChange: function (e, filter, character) {
			var self = this;
			var $currentTarget = $(e.target);
			var value = $currentTarget.val();

			if (character) {
				var strStart = value.substring(0, $currentTarget[0].selectionStart);
				var strEnd = value.substring($currentTarget[0].selectionEnd, value.length);
				$currentTarget.val(strStart + character + strEnd);
			}

			self._updateState(e);
		},

		_ValidateIPByte: function ($element) {
			var self = this;
			var $self = $(this.element);
			var inputObjects = self.inputObjects;
			var strValue = $element.val();
			var value = parseInt(strValue);

			if (strValue.length === 0 || !strValue.match(/[0-9]+/)) {
				self._setInputInvalid($element, "IP byte must be present and must be an Integer.");
				return;
			}


			if ($element[0] == inputObjects.inputArray[0][0]) {
				if (value > 0 && value < 256) {
					self._setInputValid($element);
				} else {
					self._setInputInvalid($element, "IP byte must be within 1-255.");
				}
			} else if ($element[0] == inputObjects.inputArray[1][0]) {
				if (value >= 0 && value < 256) {
					self._setInputValid($element);
				} else {
					self._setInputInvalid($element, "IP byte must be within 0-255.");
				}
			} else if ($element[0] == inputObjects.inputArray[2][0]) {
				if (value >= 0 && value < 256) {
					self._setInputValid($element);
				} else {
					self._setInputInvalid($element, "IP byte must be within 0-255.");
				}
			} else if ($element[0] == inputObjects.inputArray[3][0]) {
				if (value > 0 && value < 255) {
					self._setInputValid($element);
				} else {
					self._setInputInvalid($element, "IP byte must be within 1-254.");
				}
			}
		},

		/*
	 * @fn _onPeriodtrigger($currentTarget, eventType, keys)
	 * @brief Event binding function to capture when the period key is pressed.
	 */
		_onPeriodtrigger: function ($currentTarget, eventType, keys) {
			var self = this;
			var $self = $(this.element);
			var inputObjects = self.inputObjects;

			for (var i=0; i<inputObjects.inputArray.length; i++) {
				if ($currentTarget[0] == inputObjects.inputArray[i][0] && $currentTarget.is(".is-valid")) {
					inputObjects.inputArray[i+1].focus();
				}
			}
		}

	});

	add_widget('ipv4InputWidget', 'ui.ipv4InputWidget', 'ipv4InputWidget', ipv4InputWidget);
})(jQuery);
