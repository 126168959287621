(function () {
    register_screen('user_personal_options', {
		elements: [
			{ entity_template: 'page_title', title: 'Personal Options' },
			{ entity_template: 'page_buttons',
			  buttons: [{ type: 'cancel' }, { type: 'save' }]
            },
			{ entity_template: 'page_content', elements: [
				{
					entity_template: 'page_module',
					legend: 'Contact Information',
					buttons: [ 'help' ],
					help_id: 'user_contact_info',
					elements: [
						{
							entity_template: 'data_item',
							title: 'Contact Information:',
							elements: [{
								entity_template: 'popup_button',
								text: 'Edit Your Contact Information...',
								width: 600,
								height: 600,
								url: '/utilities/contact/#edit?bbx_contact_id=me'
							}]
						}
					]
				},
				{
					entity_template: 'page_module',
					legend: 'Change PIN',
					buttons: [ 'help' ],
					help_id: 'user_change_pin',
					form_action: '/gui/user/config/pin',
					form_data_js: { no_initial_get: true },
					elements: [
						{
							entity_template: 'input_item',
							info: 'If you do not know your current PIN, contact your Phone System administrator to change your PIN.',
							type: 'password',
							input_name: 'old_password',
							title: 'Current PIN:',
							input_options: { widgets: ['submitOnlyWidget'] }
						},
						{
							entity_template: 'input_item',
							type: 'password',
							input_name: 'new_password',
							title: 'New PIN:',
							input_options: {
								widgets: ['submitOnlyWidget', 'aopbStateWidget'],
								widget_options: { aopbStateWidget: {
									states: [{
										conditions: { a: '$$', op: '==', b: '(^$|^[0-9]{4,}$)', b_is: 'regexp' },
										actions: { invalid: false },
										else_actions: { invalid: 'PIN must be 4 or more digits.' }
									}]
								}}
							}
						},
						{
							entity_template: 'input_item',
							type: 'password',
							title: 'Confirm New PIN:',
							input_name: 'confirm_password', // Not used in the submission, but needed for clearing on Cancel
							input_options: {
								widgets: ['aopbStateWidget'],
								widget_options: { aopbStateWidget: {
									closest: '.pageModuleWidget',
									states: {
										conditions: { a:'$$', op:'==', b:'$new_password' },
										actions: { invalid: false },
										else_actions: { invalid: 'New PIN and confirmation fields do not match.' }
									}
								}}
							}
						}
					]
				}, // END page module

				{

					entity_template: 'aopb_container',
					private_fill_data: true,
					rest: '/gui/system/languages',
					rest_container: false,
					// Sound prompt language selector-- only show this when there is more than one language available
					states: [{
						conditions: { a: '@languages', op: '==', b: '_ARRAY_HAS_MULTIPLE_ELEMENTS_' },
						elements: [{

							entity_template: 'page_module',
							legend: 'Sound Prompt Language',
							buttons: ['help'],
							help_id: 'configuration_system_language',
							form_action: '/gui/user/config/language',
							elements: [{
								entity_template: 'data_item',
								title: 'Sound Prompt Language:',
								elements: [{
									entity_template: 'select_populate',
									name: 'language',
									rest: '/gui/system/languages',
									text_field: 'name',
									value_field: 'value',
									after: [
										{ text: '(Default)', value: '' }
									]
								}]
							}]

						}]
					}]
				} // END aopb'd page module: Language


			]} // END page content
		] // END template elements
    }); // END template
})(); // END scoping function
