/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, getUnique, debugLog */
/*
  aopbSelectPopulateWidget: A widget to create a SELECT box, filled by a REST call, that can return different name/value pairs based on the values returned
                            from the REST call. The value of the SELECT box is retrieved by the parent's fillData trickle-down.

  USAGE/EXAMPLE:

  This widget is used when you need one SELECT element, with options filled by a REST call, to return different name/value pairs for different selections.
    The classic (and original) case is that of a selector that may contain Gateway IDs or Port IDs. The list is filled by a REST controller that returns a
    combined list of gateways and ports, and the widget should return either { gateway_id: 123 } or { port_id: 123 } based on whether the row contains either
    a valid gateway ID or port ID.

    widget_options : {
	aopbSelectPopulateWidget: {
	    // REST call that returns the array of information to create the OPTION elements. Should return in the form:
	    //     { rest_controller: [ { ... }, { ... } ] }
	    // Each object (row) in the array is processed into an OPTION element, using the rules in the parsers array.
	    rest: '/path/to/rest_controller',

	    parsers: [
		{
		    // See cui.aopbParser.js for the proper formatting of aopb conditions.
		    // The ONLY supported "a" operands are "@dataitems", which pull from the row returned in the rest controller. The only supported "b"
		    // operands are:
		    //    dataitem -- '@bbx_key_name'
		    //    utility  -- '_TRUE_'
		    //    range    -- '1-100'
		    //    literal  -- 'literal string'
		    //    regexp   -- '[aeiou]+' (must have "b_is" param -- see cui.aopbParser.js)
		    //
		    // Selector, element, and jq_is operands are NOT supported.

		    conditions: [
			{              a: '@data_item_key', op: '==', b: '...' },
			{ join: 'and', a: '@other_item_key', op: '==', b: '...' },
			...
		    ],

		    // Optional (one or the other): Build the option as such if the conditions evaluate TRUE
		    // The widget will submit as { [name]: [value] }, and the OPTION element will show as "[text]"
		    option: {
			text:  '@data_item_for_true_name',
			name:  'name_if_true',
			value: '@data_item_for_true_value'
		    },

		    // Optional (one or the other): Build the option as such if the conditions evaluate FALSE
		    else_option: {
			text:  '@data_item_for_false_name',
			name:  'name_if_false',
			value: '@data_item_for_false_value'
		    },

		    // Name, text, and value will be pulled from the REST data if they are prefixed by "@", or interpreted literally if not.
		    // Option and else_options are optional, but at least one should be given. Note that if you have multiple parsers, only the LAST result
		    // is used. In the case of multiple parsers, you should probably use the "last_if_true" or "last_if_false" flags to break out of the
		    // list when a proper value is reached.

		    last_if_true:  true,  // Optional (none, one, or the other) -- do not process remaining parsers if this condition evaluates TRUE
		    last_if_false: true   // Optional (none, one, or the other) -- do not process remaining parsers if this condition evaluates FALSE

		},
		...
	    ] 
	}
    } // END widget_options


  IMPLEMENTATION DETAILS:

  This widget disables the event-watching and automatic evaluation functionality of its prototype CUI.aopbParser. It runs the aopb evaluation manually while
  the SELECT box is being created, in the _getEffectiveRowAttrs method, run for each row. The output, consisting of text, a name, and a value, are associated
  with "dummy" VALUE attributes for the SELECT. Since the SELECT is read as a widget, and never directly, the _getWidgetValue method retrieves the stored name
  and value, keyed off the SELECT's val().

*/

(function( $ ){
	"use strict";
	var null_fn = function () { };

	var aopbSelectPopulateWidget = $.extend({}, CUI.aopbParser, $.ui.selectPopulateWidget.prototype, {

		options: {
			allow_fallback_getWidgetValue: false
		},

		_states_key: 'parsers',
		_conditions_key: 'conditions',
		_actions_keys: { 'true': 'option', 'false': 'else_option' },

		// Override a bunch of things we don't use -- we will be running eval_states manually
		_get_delegate_selector: function () { return false; },
		_watch_events: null_fn,
		_unwatch_events: null_fn,
		_debouncedEvalAndPerform: null_fn,
		_perform_aopb_actions: null_fn,

		_getEffectiveRowAttrs: function (row_data, attr) {
			// attr should be "text", "name", or "value"
			var self = this, $self = this.element, raw_directives, directives = {}, attrs_out = {};
			self.options._filledData = row_data; // "_filledData" is CUI.aopbParser nomenclature
			raw_directives = self.eval_states(); // Returns an array of matching directives. We only want the last.
			directives = raw_directives[raw_directives.length - 1]; // === { text: "@...", value: '@...' }

			if (attr === undefined) {
				for (attr in directives) {
					attrs_out[attr] = self._parseDirective(row_data, directives[attr]);
				}

				return attrs_out;
			} else {
				if (attr in directives) {
					return self._parseDirective(row_data, directives[attr]); // Will passthru undefined if the directive's key was not in the row_data
				}
			}
		},

		_parseDirective: function (row_data, directive) {
			// Converts directives, e.g. the string "@key_name", into the proper value. Only supports "@key" or "literal string" for now, but subroutined
			// so features can be added if needed. Returns undefined if an "@key_name" is supplied but "key_name" is not in the data.

			var key; // The key from the directive

			if (directive.charAt(0) === '@') {
				key = directive.substring(1);
				return ((key in row_data) ? row_data[key] : undefined);
			} else {
				return directive;
			}
		},

		_beforeInit: function () {
			var self = this, $self = this.element;
			self._init_states();
			$.ui.selectPopulateWidget.prototype._beforeInit.apply(self, arguments);
		},

		_setSelectValue: function (val) {
			var self = this, $self = this.element, tmp_val, attrs;

			if (val === undefined) { return; }

			if (!$.isPlainObject(val)) {
				tmp_val = {};
				tmp_val[$self.attr('name') || '_NO_NAME'] = val;
				val = tmp_val;
			}

			attrs = self._getEffectiveRowAttrs(val);

			if (self.options._pseudovalues_by_name_value[attrs.name] && self.options._pseudovalues_by_name_value[attrs.name][attrs.value]) {
				$self.val(self.options._pseudovalues_by_name_value[attrs.name][attrs.value]);
			}

		},

		_addStaticOption: function (text, value) {
			// Override this so "value" can be a { name: '...', value: '...' } object
			var self = this, pval;

			pval = getUnique(self.options.widget_id + '_pval_');

			self.options._attrs_by_pseudovalue[pval] = { name: value.name, text: text, value: value.value };

			self.options._pseudovalues_by_name_value[value.name] = self.options._pseudovalues_by_name_value[value.name] || {};
			self.options._pseudovalues_by_name_value[value.name][value.value] = pval;

			$.ui.selectPopulateWidget.prototype._addStaticOption.call(self, text, pval);
		},

		_getOptionElementAttrs: function (row) {
			// In this routine, we add the pseudo-value mapping, and return the pseudo-value. This isn't technically what this routine is for, but in SPW
			// the only place this is called is the perfect place to make the pval. (The pseudo-value is a constructed string for the VALUE attribute that
			// is mapped back into one or more name-value pairs when the widget's value is read.)

			var self = this, attrs, pval = getUnique(self.options.widget_id + '_pval_');
			attrs = self._getEffectiveRowAttrs(row);

			// Map name/value pairs to a pseudovalue
			if (attrs && attrs.name) {
				self.options._pseudovalues_by_name_value[attrs.name] = self.options._pseudovalues_by_name_value[attrs.name] || {};

				if (attrs.value in self.options._pseudovalues_by_name_value[attrs.name]) {
					// Attribute set already exists-- use the existing pseudovalue
					pval = self.options._pseudovalues_by_name_value[attrs.name][attrs.value];
				} else {
					// Attribute set does not already exist-- create it
					self.options._pseudovalues_by_name_value[attrs.name][attrs.value] = pval;

					// Map the pseudovalue to attributes
					self.options._attrs_by_pseudovalue[pval] = attrs;
				}

			} else {
				debugLog('jquery.aopbSelectPopulateWidget.js: No matching name or value was found given the supplied data (', row, ') -- ', self.element);
			}

			return ({ text: attrs.text, value: pval });
		},

		_getWidgetValue: function () {
			var self = this, $self = this.element, pval, attrs, output = {};
			pval = $self.val();
			attrs = self.options._attrs_by_pseudovalue[pval] || { name: $self.attr('name') || '_NO_NAME', value: '' };

			if (pval) {
				output[attrs.name] = attrs.value;
				return output;
			} else {
				debugLog('jquery.aopbSelectPopulateWidget.js: Attempt to retrieve widget value before the widget was ready');
				return {}; // This shouldn't happen
			}	    
		},

		fillData: function (d, from_self) {
			var self = this;
			if (from_self) {
				$.ui.selectPopulateWidget.prototype.fillData.apply(self, arguments);
			} else {
				self._deferredSetSelectValue(d);
			}
		}


	});

	// Deep combinations:
	$.extend(true, aopbSelectPopulateWidget, {
		options: {
			store_row_data: true,
			_pseudovalues_by_name_value: {},
			_attrs_by_pseudovalue: {}
		}
	});

	add_widget('aopbSelectPopulateWidget', aopbSelectPopulateWidget);

})( jQuery );
