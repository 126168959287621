/* jshint jquery: true, browser: true */
/* global CUI, classCUI */
/*
  A quick tool to make image sprites from sprite images where all sprites are the same size.

  Define sprite images and sprites in the "sprite_defs" variable, in the form...

  {
    image: '/path_to_image',
    w: integer width of a single sprite,
    h: integer height of a single sprite,
    sprites: {
      sprite_name: { x: x-index of sprite, y: y-index of sprite } // Indices count the number of "w" x "h" blocks, not pixels. Omitting is okay if it is 0.
    }
  }

  Generate a sprite "image" using the function:

    html_string = CUI.sprite('alt text', 'title text', true);
    $jq_object  = CUI.sprite('alt text', 'title text', false);

  the boolean permission indicates whether the HTML will be returned as an HTML string or as a jQuery object.
*/

(function ($) {
	classCUI.prototype.sprite_defs = [
		{
			image: '/images/typeicons/typeicons_sprite_24.png',
			w: 20,
			h: 20,
			sprites: {
				user: { x: 0 },
				group: { x: 1 },
				queue: { x: 2 },
				conference: { x: 3 },
				valet_parking: { x: 4 },
				auto_attendant: { x: 5 },
				router: { x: 6 },
				phone: { x: 7 },
				disa: { x: 8 },
				group_page: { x: 9 },
				voicemail: { x: 10 },
				echo_test: { x: 11 },
				milliwatt_test: { x: 12 },
				record_file: { x: 13 },
				provider: { x: 14 },
				site: { x: 15 },
				agent_access: { x: 16 },
				contact: { x: 17 },
				hwanalog: { x: 18 },
				hwdigital: { x: 19 },
				vm_message: { x: 20 },
				fax_message: { x: 21 }
			}
		}
	];

	classCUI.prototype.sprite = function (sprite_id, alt_text, title_text, as_source) {
		var sprite_defs = CUI.sprite_defs, sprite_def, sd_idx, $container, $wrap, $image;
		sd_loop: for (sd_idx = 0; sd_idx < sprite_defs.length; sd_idx++) {
			if (sprite_defs[sd_idx].sprites[sprite_id]) {
				sprite_def = sprite_defs[sd_idx];
				break sd_loop;
			}
		}

		if (!sprite_def) {
			return undefined;
		}


		$container = $('<span class="sprite-container" />');
		$image = $('<img class="sprite-image" />');

		$container.css({
			display: 'inline-block',
			width: sprite_def.w + 'px',
			height: sprite_def.h + 'px',
			overflow: 'hidden',
			position: 'relative'
		});

		$image.css({
			position: 'absolute',
			left: (-(sprite_def.sprites[sprite_id].x || 0) * sprite_def.w) + 'px',
			top: (-(sprite_def.sprites[sprite_id].y || 0) * sprite_def.h) + 'px'
		}).attr({
			src: sprite_def.image,
			alt: alt_text || ''
		}).appendTo($container);

		if (title_text) {
			$image.attr('title', title_text);
		}

		if (as_source) {
			$wrap = $('<div />').append($container);
			return $wrap.html();
		} else {
			return $container;
		}
	};
})(jQuery);
