/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/* Choose a date range using two textboxes. Unlike the dateRangeSelectWidget, this does not have the "Last Day", etc., fields. Apply to a DIV */

// KNOWN ISSUE/TODO -- If incoming dates are in anything except "mm/dd/yyyy-mm/dd/yyyy" format, then the setValue parsing needs to be changed to allow this.
//                     The current incarnation is a quick fix to get this widget into production, as this is all it needs to do right now.

(function( $ ){
    var dateRangePickerWidget = $.extend(true, {}, $.ui.widget.prototype, {
	options: {
	    date_format: 'mm/dd/yy',
	    allow_change_year: true,
	    auto_order: true,
	    textbox_width: undefined,
	    separator: '-',
	    show_year: true
	},

	value_widget: true,
	manages_own_descendent_value: true,

	_beforeInit: function () {
	    var self = this, $self = this.element, $container;
	    $container = self.options._$pickers = $('<span />').addClass('drp-container')
		.append($('<input type="text" class="drp-date-start" readonly="readonly" /><span class="drp-between">to</span><input type="text" class="drp-date-end" readonly="readonly" />'));
	    if (self.options.textbox_width) {
		$container.find('input').css('width', self.options.textbox_width);
	    }
	},

	_beforeDOMReady: function () {
	    var self = this, $self = this.element;
	    self.options._$pickers.appendTo($self);
	    self._initializeDatePickers();
	},
	
	_initializeDatePickers: function () {
	    var self = this;
	    self.options._$pickers.find('input').datepicker({
		showAnim: 'fadeIn',
		changeMonth: true,
		changeYear: self.options.show_year,
		dateFormat: self.options.date_format,
		maxDate: self.options.max_date || undefined,
		autoSize: true,
		onSelect: self._datepickerSelectHandler.bind(self)
	    }).datepicker("setDate", new Date());
	    self._datepickerSelectHandler();
	},

	_datepickerSelectHandler: function () {
	    var self = this, $self = this.element;
	    self._updateValue();
	    $self.trigger('change');
	},

	_updateValue: function () {
	    var self = this, $self = this.element, values = [], dates = [], swapped = false;
	    dates = [ $self.find('.drp-date-start').datepicker('getDate') || new Date(), $self.find('.drp-date-end').datepicker('getDate') || new Date() ];

	    swapped = self.options.auto_order ? (dates[0].getTime() > dates[1].getTime()) : false;
	    values = [$self.find('.drp-date-' + (swapped ? 'end' : 'start')).val(), $self.find('.drp-date-' + (swapped ? 'start' : 'end')).val()];
	    
	    self.options._value = values.join(self.options.separator);
	},

	setValue: function (v) {
	    var self = this, $self = this.element, dates;
	    self.options._value = v || '';

	    if (!v) { return; }

	    dates = v.split(self.options.separator);
	    $self.find('.drp-date-start').datepicker('setDate', dates[0]);
	    $self.find('.drp-date-end').datepicker('setDate', dates[1] || dates[0]);
	},

	_getWidgetValue: function () {
	    var self = this;
	    self._updateValue(); // Just for good measure
	    return self._wrapValue(self.options._value);
	}
    });
    
    add_widget('dateRangePickerWidget', dateRangePickerWidget);
})(jQuery);
