/* jshint jquery: true, unused: vars */
/* global add_widget */
(function( $ ){
	var codecPickerWidget = $.extend(true, {}, $.ui.dualListPickWidget.prototype, {
		options: {
			available_rest: '/gui/system/available_codecs',
			available_rest_container: 'available_codecs',

			available_category_title: 'set',
			available_category_container: 'items',

			available_item_title: 'printed_name',
			item_id: 'value',

			available_title: 'Available Codecs',
			selected_title: 'Active Codecs',

			submit_empty_array_as: ''
		},

		_buildSelectedItemDetail: function (item) {
			var $basic, rate_text = '';
			$basic = this._buildItemDetail(item).addClass(this._selected_item_class);

			if (item.priority.rate) {
				rate_text = Math.round(item.priority.rate / 1000) + 'kHz';
			} else if (item.priority.video) {
				rate_text = 'VIDEO';
			}

			$basic.append($('<span class="codec-info" />').text(rate_text));
			return $basic;
		},

		_buildAvailableItemDetail: function (item) {
			var $basic = this._buildItemDetail(item).addClass(this._available_item_class);
			$basic.append($('<span class="codec-info" />'));
			return $basic;
		}


	});

	add_widget('codecPickerWidget', codecPickerWidget);
})(jQuery);
