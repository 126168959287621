(function () {
    var SC_IF_INBOUND = {
	closest : 'form',
	states: [{
	    conditions: { a: '$bbx_provider_gateway_flag_inbound' , op: '==', b: '_TRUEISH_' },
	    actions: { enabled: true, visible: true },
	    else_actions: { enabled: false, visible: false }
	}]
    };

    var SC_IF_OUTBOUND = {
	closest : 'form',
	states: [{
	    conditions: { a: '$bbx_provider_gateway_flag_outbound' , op: '==', b: '_TRUEISH_' },
	    actions: { enabled: true, visible: true },
	    else_actions: { enabled: false, visible: false }
	}]
    };

    var SC_LOCAL_REG = {
	closest : 'form',
	states: [{
	    conditions: { a: '$local_registration' , op: '==', b: '_TRUEISH_' },
	    actions: { visible: true },
	    else_actions: { visible: false }
	}]
    };

    register_screen('provider_gateway_create', {
	elements: [
	    { entity_template: 'page_title', title: 'Add a SIP account' },
	    { entity: 'div', 'attributes': {'class': 'page_messages'} },
	    { entity_template: 'page_buttons', buttons: [
		{ type: 'cancel_back' },
		{
		    type: 'create',
		    key: 'bbx_provider_gateway_id',
		    detail: 'provider_gateway_detail'
		}
	    ]},
	    { entity_template: 'page_content', elements:
	      [ /* Page Content */
		  {
		      entity_template: 'form',
		      no_initial_get: true,
		      data: {
			  bbx_provider_gateway_port: 5060,
			  bbx_provider_id: 1,
			  bbx_provider_gateway_expire: 3600,
			  bbx_provider_gateway_flag_inbound: 1,
			  bbx_provider_gateway_flag_outbound: 1,
			  bbx_provider_gateway_flag_register: 1,
			  bbx_moh_id: 1,
			  bbx_provider_gateway_force_caller_id_when: 'NEVER',
			  codecs: ['PCMU','PCMA']
		      },
		      dataEvents: {
			  trigger: 'provider_external'
		      },
		      submit_all: true,
		      always_dirty: true,
		      action: '/gui/gateway/sip',
		      method: 'POST',
		      elements: [
			  
			  {
			      entity_template: 'page_module',
			      legend: 'Basic Information',
			      buttons: ['help'],
			      help_id: 'providers_basic_information',
			      
			      elements: [
				  {
				      entity_template: 'input_item',
				      title: 'Provider Type:',
				      type: 'select',
				      input_name: 'bbx_provider_id',
				      input_options: {
					  attributes: {
					      
					  },
					  widgets: [ 'providerFillerWidget' ],
					  widget_options: {
					      providerFillerWidget: { }
					  }
				      }
				  },
				  {
				      entity_template: 'input_item',
				      title: 'Gateway Name:',
				      input_name: 'bbx_provider_gateway_name',
				      type: 'text',
				      input_options: { attributes: { maxlength: '255' }},
				      validate: { conditions: '_TRUEISH_', message: 'Please enter a Gateway Name' }
				  },
				  {
				      entity_template: 'input_item',
				      title: 'Host:',
				      input_name: 'bbx_provider_gateway_host',
				      type: 'text',
				      validate: { conditions: '_TRUEISH_', message: 'Please enter a Host' }
				  },
				  {
				      entity_template: 'input_item',
				      title: 'Realm:',
				      input_name: 'bbx_provider_gateway_realm',
				      type: 'text',
				      input_options: {
					  widgets: ['copyTextboxWidget'],
					  widget_options: {
					      copyTextboxWidget: {
						  closest: '.page_module_body',
						  source: '$bbx_provider_gateway_host'
					      }
					  }
				      }
				  },
				  {
				      entity_template: 'input_item',
				      title: 'Port:',
				      input_name: 'bbx_provider_gateway_port',
				      type: 'text',
				      size: 8,
				      input_options: {
					  widgets: [ 'aopbStateWidget' ],
					  widget_options: { aopbStateWidget: { states: [ {

					      conditions: [
						  {             a: '$$', op: '==', b: '_PORT_' },
						  { join: 'or', a: '$$', op: '==', b: '', b_is: 'literal' }
					      ],
					      actions: { invalid: false },
					      else_actions: { invalid: 'Please enter a valid port number' }

					  } ] } }
				      }
				  },
				  {
				      entity_template: 'input_item',
				      title: 'Username:',
				      input_name: 'bbx_provider_gateway_username',
				      type: 'text',
				      input_options: {
					  widgets: ['aopbStateWidget'],
					  widget_options: {
					      states: [{
						  conditions: [
						      { a: '$$', op: '!=', b: '_TRUE_' },
                                                      { join: 'and', a: '$bbx_provider_id', op: '!=', b: '3' },
						      { join: 'and', a: '$bbx_provider_gateway_flag_register', op: '==', b: '_TRUEISH_' }
						  ],
						  actions: { invalid: 'A Username is required to register to the provider' },
						  else_actions: { invalid: false }
					      }]
					  }
				      }
				  },
				  {
				      entity_template: 'input_item',
				      title: 'Authorization Username:',
				      input_name: 'bbx_provider_gateway_auth_username',
				      type: 'text',
				      input_options: {
					  widgets: ['copyTextboxWidget'],
					  widget_options: {
					      copyTextboxWidget: {
						  closest: '.page_module_body',
						  source: '$bbx_provider_gateway_username'
					      }
					  }
				      }
				  },
				  {
				      entity_template: 'input_item',
				      title: 'Password:',
				      input_name: 'bbx_provider_gateway_password',
				      type: 'text',
				      input_options: {
					  widgets: ['aopbStateWidget'],
					  widget_options: {
					      watch_also: [{ selector: '[name=bbx_provider_gateway_flag_register]', events: 'enabled disabled stateChange' }],
					      states: [{
						  conditions: [
						      { a: '$$', op: '!=', b: '_TRUE_' },
                                                      { join: 'and', a: '$bbx_provider_id', op: '!=', b: '3' },
						      { join: 'and', a: '$bbx_provider_gateway_flag_register', op: '==', b: '_TRUEISH_' }
						  ],
						  actions: { invalid: 'A Password is required to register to the provider' },
						  else_actions: { invalid: false }
					      }]
					  }
				      }
				  }
			      ]
			  },
			  {
			      entity_template: 'page_module',
			      legend: 'Provider Registration',
			      buttons: ['help'],
			      help_id: 'providers_provider_registration',
			      elements: [
				  {
				      entity_template: 'input_item',
				      title: 'Register to Provider:',
				      input_name: 'bbx_provider_gateway_flag_register',
				      type: 'checkbox',
				      input_options: {
                                          widgets: ['aopbStateWidget'],
                                          widget_options: {
                                              states: [{
                                                  conditions: [
                                                      { a: '$bbx_provider_id', op: '==', b: '3' }
                                                  ],
                                                  actions: { enabled: false, value: false },
                                                  else_actions: { enabled: true }
                                              }]
                                          }
                                      }
				  },
				  {
				      entity_template: 'input_item',
				      title: 'Registration Expire Time (Seconds):',
				      input_name: 'bbx_provider_gateway_expire',
				      type: 'text',
				      info: 'minimum recommended setting: 30s',
				      input_options: {
					  widgets: [ 'aopbStateWidget' ],
					  widget_options: {
					      aopbStateWidget: {
						  closest: '.pageModuleWidget',
						  states: [
						      {
							  conditions: { a: '$bbx_provider_gateway_flag_register', op: '==', b: '_TRUEISH_' },
							  actions: { enabled: true },
							  else_actions: { enabled: false },
							  last_if_false: true
						      },
						      {
							  conditions: [ { a: '$$', op: '==', b: '_NUMERIC_NONZERO_' },
									{ join: 'and',  a: '$$', op: '<', b: '4294967296' },
									{ join: 'and',  a: '$$', op: '>', b: '0' }],
							  actions: { invalid: false },
							  else_actions: { invalid: 'Invalid Registration Expire Time.' }
						      }
						  ]
					      }
					  }
				      }
				  }
			      ]
			  },
			  {
			      entity_template: 'page_module',
			      legend: 'Services',
			      buttons: ['help'],
			      help_id: 'providers_services',
			      elements: [
				  {
				      entity_template: 'input_item',
				      title: 'Inbound Calls:',
				      input_name: 'bbx_provider_gateway_flag_inbound',
				      type: 'checkbox'
				  },
				  {
				      entity_template: 'input_item',
				      title: 'Outbound Calls:',
				      input_name: 'bbx_provider_gateway_flag_outbound',
				      type: 'checkbox'
				  },
				  {
				      entity_template: 'input_item',
				      widgets: ['aopbStateWidget'],
				      title: 'Outbound Fax:',
				      input_name: 'outbound_fax',
				      type: 'checkbox',
				      widget_options: {
					  aopbStateWidget: {
					      closest: 'form',
					      states: [
						  {
						      conditions: { a: '$bbx_provider_gateway_flag_outbound', op: '==', b: '_TRUE_' },
						      actions: { enabled: true, _cascade: true },
						      else_actions: { enabled: false, invalid: false, _cascade: true }
						  }
					      ]
					  }
				      }
				  }
			      ]
			  },
			  {
			      entity_templates: [ 'page_module', 'aopb_state' ],
			      aopb_state: SC_IF_INBOUND,
			      page_module: {
				  legend: 'Inbound Registration',
				  buttons: ['help'],
				  help_id: 'providers_inbound_registration',
				  elements: [
				      {
					  entity_template: 'input_item',
					  title: 'Allow Inbound Registration:',
					  input_name: 'local_registration',
					  type: 'checkbox'
				      },
				      {
					  entity_templates: ['data_item', 'aopb_state'],
					  aopb_state: {
					      'closest': 'form',
					      states: [{
						  conditions: { a: '$local_registration', op: '==', b: '_TRUEISH_' },
						  actions: { visible: true },
						  else_actions: { visible: false }
					      }]
					  },
					  data_item: {
					      title: '',
					      elements: [
						  {
						      entity: 'div',
						      text: 'Inbound Registration credentials will be generated when the SIP account is added',
						      attributes: { 'class':'info' }
						  }
					      ]
					  }
				      }
				  ]

			      }
			  },
			  {
			      entity_templates: ["container", "aopb_state"],
			      aopb_state: SC_IF_OUTBOUND,
			      container: {
				  elements: [ elgen('cid_selector', { type: 'bbx_provider_gateway', buttons: ['help'], help_id: 'providers_caller_id' }) ]
			      }
			  },
			  {
			      entity_templates: ['page_module', 'aopb_state'],
			      aopb_state: SC_IF_OUTBOUND,
			      page_module: {
				  legend: 'Music on Hold',
				  buttons: ['help'],
				  help_id: 'providers_music_on_hold',
				  elements: [
				      {
					  entity_template: 'input_item',
					  title: 'Music on Hold Class:',
					  type: 'select',
					  input_name: 'bbx_moh_id',
					  input_options: {
					      widgets: ['selectPopulateWidget'],
					      widget_options: {
						  selectPopulateWidget: {
						      rest: '/gui/moh/class',
						      text_field: 'bbx_moh_name',
						      value_field: 'bbx_moh_id'
						  }
					      }
					  }
				      }
				  ]
			      }
			  },
			  {
			      entity_template: 'page_module',
			      legend: 'Codec Restriction',
			      buttons: ['help'],
			      help_id: 'providers_codec_restriction',
			      elements: [
				  {
				      entity_template: 'data_item',
				      title: 'Codec Restriction:',
				      elements: [
					  // Initial values are in the static data of the formWidget above -- PCMA,PCMU
					  {
					      entity_template: 'codec_picker',
					      name: 'codecs',
					      selected_title: 'Selected Codecs'
					  }
				      ]
				  },
				  {
				      entity_template: 'data_item',
				      title: '',
				      elements: [
					  {
					      entity: 'div',
					      attributes: { 'class': 'info' },
					      text: 'Codec restriction is only in place if there are codecs in the list. If the list is empty, restriction is disabled and all active codecs are allowed on this gateway.'
					  }
				      ]
				  }
			      ]
			  }
		      ]
		  }
	      ]}
	]
    });

})();
