register_screen('queue_manager', {
    elements: [
	{ entity_template: "page_title", "title" : "Queue Manager" },
	{
	    entity_template: "page_buttons",
	    buttons: [{ type: "fullscreen" }, {'type': 'help', help_id:'switchboard_queue_manager'}]
	},
	{ entity_template: "page_content",
	  "class": "switchboard queuemgr",
	  elements: [
	      {
		  entity_template: "container",
		  id: "queuemgr_agents",
		  "class": "queuemgr_agents hide_phone_info",
		  elements: [
		      {
			  entity_template: "agent_manager_table",
			  name: "queuemanager_agents",
			  filter_title: 'Filter Agent List: ',
			  live_unique_ident: true,
			  sort_by: 'call_state'
		      },
		      {
			  entity_template: "wallboard_resizer",
			  min_font_size: 90,
			  max_font_size_docked: 140,
			  max_font_size_fullscreen: 250,
			  max_columns: 1,
			  align: 'left',
			  include_offline: true,
			  bubble_width: 30,
			  min_bubble_height: 28,
			  bubble_height: 3.25
		      }
		  ]
	      },
	      {
		  entity_template: "container",
		  "class": "queuemgr_queues",
		  elements: [
		      {
			  entity_template: "data_table",
			  name: "queuemanager_queues",
			  "class": "queuemanager_queues",
			  live_table: "live_fifo_queues",
			  live_table_key: "fifo_queues",
			  live_unique_ident: true,
			  data_source: "live",
			  disable_cui_wrapper: false,
			  delay_delete: 5000,
			  no_highlight_details_row: true,
			  filtering: false,
			  length_change: false,
			  paginate: false,
			  processing: false,
			  sortable: false,
			  oLanguage: {
			      sZeroRecords: "No queues."
			  },
			  click_action: "toggle_expand",
			  extra_row_data: [ "bbx_queue_name", "bbx_extension_value", "bbx_queue_warning_seconds", "bbx_queue_warning_calls",
					   "bbx_queue_critical_seconds", "bbx_queue_critical_calls", "bbx_queue_outbound_name", "active_agents",
					    "total_agents", "callers_waiting", "total_calls", "current_time", "timestamp", "bbx_queue_id" ],
			  details_row: {
			      // We have a single view element with a container
			      // because the wallBoardResizerWidget searches for
			      // closest container before finding dataTables to
			      // resize.
			      view_elements: [
				  {
				      entity_template: "container",
				      "class" : "bubble_table hide_phone_info hide_offline",
				      elements: [
					  {
					      entity_template: "wallboard_data_table",
					      live_table: "live_fifo_agents",
					      live_table_key: "fifo_agents",
					      live_unique_ident: true,
					      data_source: "live",
					      wait_for_parent_filldata: true,
					      accept_data_from_parent: false,
					      search_in_list: {
						  widget_param: 'bbx_queue_id',
						  search_param: 'queue_membership'
					      },
					      search_op: "AND",
					      search: {
						  agent_status_name: 'Available|Held|Ringing'
					      },
					      page_size: 10000,
					      filtering: true,
					      length_change: false,
					      paginate: false,
					      processing: false,
					      sortable: false,
					      oLanguage: {
						  sZeroRecords: "Waiting for active calls..."
					      },
					      extra_row_data: [ "bbx_queue_name", "fifo_name", "agent_extension_value", "caller_caller_id_name",
								"caller_caller_id_number", "bridge_start", "agent_status_name", "agent_status_last_changed",
								"bbx_user_status_warning_seconds", "bbx_user_status_critical_seconds",
								"bbx_queue_consumer_wrapup_time", "bbx_queue_outbound_name", "call_state", "current_time",
								"last_state_change", "deleted", "delete_delay", "agent_missed_calls", "state_duration",
								"state_start", "bbx_phone_desc", "bbx_phone_model", "total_missed_calls", "extension_owner",
								"bbx_user_id", "last_call_end", "last_call_missed", "primary_extension",
								"last_call_from_queue" ],
					      columns: [
						  {
						      header: "Active and Ringing Agents",
						      column: "agent_name",
						      sortable: false,
						      view_elements: [
							  { entity_template: "agent_monitor" }
						      ]
						  }
					      ]
					  }
				      ]
				  },
				  {
				      entity_template: "container",
				      "class" : "bubble_table hide_phone_info hide_offline",
				      elements: [
					  {
					      entity_template: "data_table",
					      live_table: "live_fifo_callers_waiting",
					      live_table_key: "callers_waiting",
					      data_source: "live",
					      live_unique_ident: true,
					      wait_for_parent_filldata: true,
					      accept_data_from_parent: false,
					      search_parent_field: "bbx_queue_id",
					      page_size: 10000,
					      filtering: false,
					      length_change: false,
					      paginate: false,
					      processing: false,
					      js_sort_by: [ ["timestamp", "asc"] ],
					      sortable: false,
					      oLanguage: {
						  sZeroRecords: "Waiting for incoming calls..."
					      },
					      extra_row_data: [ "caller_caller_id_name", "caller_caller_id_number", "callee_id_name", "callee_id_number",
								"bbx_queue_id", "bbx_queue_name", "bbx_queue_warning_seconds", "bbx_queue_critical_seconds",
								"timestamp", "current_time", "deleted", "delete_delay", "fifo_callers_id", "fifo_callers_uuid",
								"fifo_name", "table_key" ],
					      columns: [
						  {
						      header: "Callers Waiting In Queue",
						      column: "timestamp",
						      sortable: false,
						      data_type: "num-html",
						      sort_data_type: "num-html-asc",
						      view_elements: [
							  { entity_template: "callers_waiting" }
						      ]
						  }
					      ]
					  },
					  {
					      entity_template: "wallboard_resizer",
					      closest: "parent",
					      min_font_size: 80,
					      max_font_size_docked: 200,
					      max_font_size_fullscreen: 250,
					      max_width: 250,
					      bubble_width: 24,
					      bubble_height: 2.2
					  }
				      ]
				  }
			      ] /* view_elements */
			  },
			  columns: [
			      {
				  header: "Queue",
				  column: "bbx_queue_name",
				  sortable: false,
				  view_elements: [
				      {
					  entity_template: "queue_monitor",
					  trim_name: false
				      }
				  ]
			      }
			  ]
		      }
		  ]
	      }
	  ]
	}
    ]
});
