/* jshint jquery: true, unused: vars */
/* global add_widget, debugLog */
(function( $ ){
	var watcherWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			template: false,
			watch: {
				// closest: 'selector',
				// find:    'selector',
				event:      'change',
				auto_init:   true
			},
			interpret: {
				closest:   false,
				find:      false,
				attribute: 'value',
				data_key:  false,
				data_object_key: false
			}
		},

		_displayNewValue: function () {
			var self = this;
			var $self = this.element;
			var val_out = '';

			var $watch_element = self.options.$watch_element;
			var $interpret_closest = self.options.interpret.closest ? $watch_element.closest(self.options.interpret.closest) : $watch_element;
			var $interpret_element = self.options.interpret.find ? $interpret_closest.find(self.options.interpret.find) : $interpret_closest;

			if (!$interpret_element[0]) {
				debugLog('jquery.watcherWidget.js: Could not find the interpret/find object');
				return;
			}

			switch (self.options.interpret.attribute) {
				case 'value':
					val_out = self.options.$watch_element.val();
					break;
				case 'data':
					if (!self.options.interpret.data_key) {
						val_out = 'jquery.watcherWidget.js: No data_key on a data attribute find';
						break;
					}

					var data = $interpret_element.data(self.options.interpret.data_key);
					data = self.options.interpret.data_object_key ? data[self.options.interpret.data_object_key] : data;
					val_out = data;
					break;

				default:
					val_out = self.options.$interpret_element.attr(self.options.interpret.attribute);
			}

			$self.text(val_out);
		},

		_beforeDOMReady: function () {
			var self = this;
			var $self = this.element;

			var $watch_closest = self.options.watch.closest ? $self.closest(self.options.watch.closest) : $self;
			var $watch_element = self.options.watch.find ? $watch_closest.find(self.options.watch.find) : $watch_closest;

			self.options.$watch_element = $watch_element;

			if (self.options.watch.find) {
				self._delegate($watch_closest, self.options.watch.find || '*', self.options.watch.event, function (e) {
					self._displayNewValue();
				});
			} else {
				self._bind($watch_closest, self.options.watch.event, function (e) {
					self._displayNewValue();
				});
			}
		}
	});

	add_widget('watcherWidget', watcherWidget);
})(jQuery);
