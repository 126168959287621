/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/*
  This will mark the widget invalid if a value is picked that is already in the surrounding or specified DTW. Apply this as a second widget to the 
*/

(function( $ ){
	var newRowValidatorWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			apply_to: 'self', // 'self' | 'child' -- which input control are we watching?
			invalid_reason: 'The selected value already exists in the table',
			exact_type_match: false // Use === to make comparison
		},

		_wasFound: function (name, val) {
			var self = this, $self = this.element, col_vals, c_idx, $dtw;

			if (!self.options._dtw) {
				$dtw = $self.closest('.dataTableWidget');
				self.options._dtw = $dtw.getCUIWidget('dataTableWidget');
			}

			if (!self.options._dtw) { return false; }

			col_vals = self.options._dtw.getAnyOriginalColumnData(name);

			for (c_idx = 0; c_idx < col_vals.length; ++c_idx) {
				if (self.options.exact_type_match ? (col_vals[c_idx] === val) : (col_vals[c_idx] == val)) {
					return true;
				}
			}

			return false;
		},

		_getFieldElement: function () {
			var self = this, $self = this.element;
			if (self.options.apply_to === 'child') {
				return $self.findNotUnder(':input[name],.widgetValueWidget', '.widgetType.widgetManagesOwnDescendentValue').eq(0);
			} else {
				return $self;
			}
		},

		_getNameValue: function () {
			var self = this, $self = this.element, $w, val, name;
			$w = self.options._$field_element = self._getFieldElement();
			val = $w[0] ? CUI.getWidgetElementValue($w) : undefined;
			name = val ? CUI.firstKey(val) : 'NO_NAME';
			val = val[name];
			return ({ name: name, value: val });
		},

		_setInvalid: function (state) {
			var self = this, $self = this.element, reason = self.options.invalid_reason, $f_el = self.options._$field_element || self._getFieldElement();

			if (state) {
				CUI.setInvalid($f_el, reason, self.options.widget_id);
			} else { 
				CUI.setValid($f_el, self.options.widget_id);
			}
		},

		_doValidation: function () {
			var self = this, $self = this.element, nv, found;
			nv = self._getNameValue();
			found = self._wasFound(nv.name, nv.value);
			self._setInvalid( found );
		},

		_beforeInit: function () {
			var self = this, $self = this.element;
			self._one($self, 'ready', self._watchElements.bind(self));
		},

		_watchElements: function () {
			var self = this, $self = this.element, $fe = self._getFieldElement();
			self._bind($fe, 'change', self._doValidation.bind(self));
		}
	});

	add_widget('newRowValidatorWidget', newRowValidatorWidget);
})(jQuery);
