/* jshint jquery: true, unused: vars */
/* global add_widget */
(function ($) {
	var helpButtonWidget = $.extend({}, $.ui.widget.prototype, {
		options: {
			help_id: 'unknown_help'
		},

		_beforeInit: function () {
			var self = this, $self = this.element;
			// Make this a button type=button if it is not one. Attr throws an error in IE, so use setAttribute, which is compatible w/IE GTE 8
			try {
				$self[0].setAttribute('type','button');
			} catch (e) { }
		},

		_afterInit: function () {
			var self = this;
			var $self = this.element;

			self.options._clickHandler = function () { self._clickHandler.apply(self, arguments); };
			$self.on('click', self.options._clickHandler);
		},

		_clickHandler: function(e) {
			var self = this;
			e.preventDefault();
			e.stopPropagation();

			// Make the help "window" popup
			var helpWin = window.open('/help.html#' + self.options.help_id,'help_popup','width=450,height=400,location=0,menubar=0,scrollbars=1,status=0,toolbar=0,resizable=1');
			helpWin.focus();
		}
	});

	add_widget('helpButtonWidget', helpButtonWidget);
})(jQuery);
