/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, widgetize_children */
/*
Implementation of CUI.aopbParser that renders different contents depending upon the result.

EXAMPLE (*):
{
    ...,
    states: [
        {
	    conditions: [
	        {             a: '$$',           op: '==', b: '_TRUEISH_' },
		{ join: 'or', a: '$auto_select', op: '==', b: '_TRUEISH_' }
	    ],
	    elements: [
		{ ... Element definition ... }
	    ],
	    else_elements: [
		{ ... Element definition ... }
	    ]
	}
    ]
}

* If you have problems, check the documentation on the constituent classes, as something might have changed:
For information on a-op-b syntax, see classes/cui.aopbParser.js

*/

(function( $ ){
	"use strict";
	var aopbContainerWidget = $.extend(true, {}, CUI.htmlEntityClass, $.ui.widget.prototype, CUI.aopbParser, {
		_prefer_own_widget: false,
		_actions_keys: { 'true':'elements', 'false':'else_elements' },
		_beforeInit: function () {
			var self = this, $self = this.element;
			$self.addClass('variableContainer');
			self._init_states();
		},

		_beforeDOMReady: function () {
			var self = this, $self = this.element;
			self._watch_events();
		},

		fillData: function (d) {
			var self = this, $self = this.element;
			if (!self.options._children_widgetized_ok && $self.find('.widgetType:not(.widgetized)')[0]) {
				widgetize_children($self);
			}

			$.ui.widget.prototype.fillData.apply(self, arguments);

			if (self.options.watch_self_fillData) {
				self._getFillData(undefined, undefined, d);
			}

			self.options.data = $.extend(self.options.data || {}, d);
		},

		_perform_aopb_actions: function (el_actions) {
			var self = this, $self = this.element, idx, all_elements = [], $content, fill_data = {}, widget_data = {};

			if (self.options.destroyed) { return; }

			if (self.options.data_on_interactive_change && self.options.last_event_interactive) {
				fill_data = $.extend(true, {}, (self.options.data || {}), self.options.data_on_interactive_change);
				widget_data = $.extend(true, {}, (self.options.widget_data || {}), self.options.data_on_interactive_change);
			} else {
				fill_data = self.options.data || {};
				widget_data = self.options.widget_data || {};
			}

			for (idx=0; idx<el_actions.length; idx++) { all_elements = all_elements.concat(el_actions[idx]); }

			if (all_elements[0]) {
				$content = self.getEntitiesHTML(all_elements, self.options.data);
				$self.empty().append($content);

				if ($content.find('.widgetType:not(.widgetized)')[0]) {
					self.widgetizeChildren($content);
					self.options._children_widgetized_ok = true;
					self.fillDataChildren(fill_data, true, $self, widget_data);
				}
			} else {
				$self.empty();
			}

			// Trigger this event so formWidgets know to re-scan for dirtiness
			$self.trigger('formElementChange');
			$self.trigger('variableContainerChange');
		}
	});

	add_widget('aopbContainerWidget', aopbContainerWidget);
})(jQuery);
