/* jshint jquery: true, unused: vars */
/* global add_widget */
/* global UserStatus */
(function( $ ){
	var userStatusWidget = $.extend({}, $.ui.formDialogButtonWidget.prototype, {
		options: {
			text: 'Agent Status',
			title: 'Change Agent\'s Status',
			submit_button: 'Ok',
			submit_first: true,
			submit_all: true,
			form_options: { always_dirty: false },
			cancel_button: 'Cancel',
			no_initial_get: true,
			rest: '/gui/user/status_by_uid',
			elements: [],
			include_data: ['bbx_user_id', 'bbx_user_status_id']
		},

		_beforeInit: function () {
			var self = this;
			self._buildStatusOptions();
			return $.ui.formDialogButtonWidget.prototype._beforeInit.apply(self, arguments);
		},

		fillData: function (d) {
			var self = this, $self = this.element;
			self.options.current_status_id = d.bbx_user_status_id;
			$self.text(d.agent_status_name);
			self.options.title = 'Change ' + d.agent_name + '\'s Status';
			return $.ui.formDialogButtonWidget.prototype.fillData.apply(self, arguments);
		},

		_buildStatusOptions: function () {
			var self = this, $self = this.element;
			var elements_def = [], status_options = [], status_idx;

			for (status_idx = 0; status_idx < UserStatus.length; status_idx++) {
				status_options.push({ text: UserStatus[status_idx].bbx_user_status_name, value: UserStatus[status_idx].bbx_user_status_id });
			}

			elements_def = {
				entity_template: 'input_item',
				title: 'User Status',
				type: 'select',
				select_options: status_options,
				input_name: 'bbx_user_status_id'
			};

			self.options.elements = [ elements_def ];
		}
	});    
	add_widget('userStatusWidget', userStatusWidget);
})(jQuery);
