/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, debugLog, getXSRFKey, getUnique, getMessageFromKey, AJAXErrorHandler */
/**
 * @class formWidget
 *
 * The formWidget class implements basic form functionality. Primarily, submits the form, performs dirty tracking,
 * and hooks into validation classes which validate the form's input. It also handles error messages for the form
 * and resetting the form. It provides various hooks and events which can be used to extend the functionality of
 * the form, or which can override or interrupt actions that the formWidget takes.
 *
 * To use the formWidget class, simply apply it to a form like so:
 *
 * <form class="widgetType formWidget" action="/gui/controller">
 *
 * It will take over handling the save and cancel buttons, any submit or reset typed buttons, performing dirty
 * tracking, form error message handling and validation widget message handling, etc.
 *
 * To programmatically change form data, use the setFormValue() method, which allows you to set a value for a
 * named form element, and specify whether the action dirties the form. There are other methods that allow
 * programmatically interacting with the form, as well. See the additional documentation on methods below for further
 * details.
 *

 OPTIONS:

 // Set this true if the form should be "always dirty", submittable at any time. This option is deprecated.
 always_dirty: false | true,

 // Show a confirmation box before proceeding with the form submission. The confirmation box ONLY blocks the given form, even if others on the page are
 // to be submitted by a page "Save" button.
 confirm: {
   title:          <title> | 'Confirm Change',
   text:           <text>  | 'Are you sure you want to apply these changes?',
   buttons:        [ <ok button text>, <cancel button text> ] | ['Apply Changes', 'Cancel'],
   value_callback:            <function> | <default function>,
   value_callback_auto_close: true | false,
   values: [<first button value>, <second button value>] | [true, false]
 },

 // Show a confirmation dialog only if a given value is being submitted
 // NOTE: Since this is processed before values are pulled, this works by looking for $self.find('.is-dirty[name=<name>]'). This works for the required cases
 //       but be aware that this is the case. Also, TODO: Fix this fact.
 confirm_if: {
   <field name>: { <Dialog definition (see "confirm" above)> }
 },

 // Show a progress Dialog after submit, but before completion
 dialog_during_submit: <CUI.Dialog specification>,

 // Show a Dialog after a successful submit
 dialog_after_success: <CUI.Dialog specification>,

 // Set this true to prevent pageWidget from submitting this form.
 disable_form_submission: false | true,

 // This will remove any widget data that is not present in the returned rest data
 filter_return_data: false | true,

 // Force the form to reset (re-GET) after a submission is complete. Overrides force_reset_if (below).
 force_reset: false | true,

 // Force the form to reset (re-GET) after a submission, if any of the listed values were submitted.
 force_reset_if: false | [ <key>, ... ],

 // Array of keys in rest_params to explicitly add to the form submission. If this option is false or omitted, rest_params keys are included via the
 // default behavior.
 include_keys: false | [ <array of key names> ],

 // Array of keys in REST pull data to add to the form submission. Only keys that are defined, and that do not exist already when the form submits
 // are included.
 include_data_keys: false | [ <array of key names > ],

 // Whether or not values that were changed after submit will be indicated visually
 indicate_changed_values: false | true,

 // The form's "method"
 method: 'PUT' | 'POST' | 'GET' | 'DELETE',

 // Value to convert NULL/UNDEFINED to on submit
 submit_null_value_as: '' | false (leave as is) | 'string value' | '_OMIT_',

 // The URL to which the form gets data and makes submissions
 rest: "/path/to/controller"

 // Any extra params to send to the REST controller
 rest_params: { <key/values of params> },

 // If true, and the form has a FILE INPUT, returning an object with an "error" key in it will be considered an error. This is usually required because
 // FILE INPUT submissions are done in a way that does not preserve HTTP status information.
 soft_upload_errors: true | false,

 // If true, all values in the form are submitted, whether they are dirty or not. This is distinct from "always_dirty" in that the form must still
 // be dirty to submit, but it will submit all values.
 submit_all: false | true,

 // If a control attempts to submit an empty array ([]), this value will be used instead. If left as an empty array ([]), then the key/value will be omitted
 // in most cases
 submit_empty_array_as: [] | [ <array of values> ] | <string value>


 INTERNALLY-USED SELF.OPTIONS PROPERTIES:
 self.options.$page_modules
 self.options._confirm_ok
 self.options._custom_message_widgets
 self.options._dirtyHandlerBacklog
 self.options.dirtyHandler
 self.options.dirty_handler_pid
 self.options.dirty_handler_timeout
 self.options.originalData
 self.options.reset_closure
 self.options.submit_closure
 self.options.submit_error_closure
 self.options.submit_success_closure
 self.options._submit_dialog

 INTERNAL, BUT USEFUL FOR READING
 self.options.data
 self.options.dirty
 self.options.valid


  * ERROR/MESSAGE HANDLING
    * When are messages added? (addMessage)

      1.) _realSubmitSuccess: When a submission comes back with different data than was entered, a message is displayed.
      2.) _realSubmitSuccess: The message "Successfully Saved Your Settings" is displayed on submit
      3.) _realSubmitError:   A general error message is shown when the error code is <400 (not sure about the significance of this)
      4.) _realSubmitError:   A specific error message is shown when the return has an "error" property
      5.) computeValidState / addErrorMessage: Specific error messages are added when fields are marked "invalid"

    * When are messages removed? (delMessage)

      1.) _realSubmitSuccess: All errors are removed
      x.) _realSubmitSuccess: Timed remove of the "bgchange" message -- remove this, and add a way to have temporary messages
      2.) computeValidState / removeErrorMessage: Remove error messages on no-longer-invalid fields

    * Message display is now handled in mixin.errorMessages.js

*/

(function( $ ){
	var formWidget = $.extend({}, $.ui.widget.prototype, CUI.htmlContainerClass, CUI.mixin.get('errorMessages'), {
		options: {
			_custom_message_widgets: {},
			force_reset: false,
			filter_return_data: false,
			disable_form_submission: false,  // pageWidget will not find this form for submission
			submit_empty_array_as: [],
			soft_upload_errors: true,
			clear_file_input_on_success: false,  // if the form has file inputs, clear their values after submit success
			confirm: false,
			compare_params: { loose_compare_basics: true, null_equals_blank: true, empty_string_equals_zero: true },
			indicate_changed_values: false,
			submit_null_value_as: ''
			// method: 'PUT'
			// include_keys: false // ['rest_params_key_1', 'rest_params_key_2', ...], // -- Set this to only pull some key/values from the rest_params
		},

		data_producer: true,

		/** @name Initialization Methods
	 *  This section contains methods related to initialization of formWidget and its children.
	 */
		/**@{*/
		/**
	 * @fn _beforeInit()
	 * @brief This method executes prior to template loading and data loading by the base widget class. It pulls
	 *        the REST controller location from the action attribute on the form if it is not found in the data-js
	 *        parameters attribute.
	 */
		_beforeInit: function() {
			var self = this, $self = this.element, default_confirm, c_idx;

			// First, check to make sure REST is set appropriately
			if (!self.options.rest) {
				self.options.rest = $self.attr('action');
			}

			if ($self.closest('div.page_module')[0]) {
				self.options.$page_modules = $self.closest('div.page_module');
			} else {
				self.options.$page_modules = $self.find('div.page_module');
			}

			self._bind($self, 'valueWidgetRefresh', function (e, updated_data) {
				e.stopPropagation();
			});

			default_confirm = {
				title: 'Confirm Change',
				text: 'Are you sure you want to apply these changes?',
				buttons: ['Apply Changes', 'Cancel'],
				value_callback: self._confirmSubmit.bind(self),
				value_callback_auto_close: true,
				values: [true, false]
			};

			if (self.options.confirm) {
				if (self.options.confirm.cancel_first) {
					self.options.confirm.values = [false,true];
					delete self.options.confirm.cancel_first;
				}

				self.options.confirm = $.extend({}, default_confirm, $.isPlainObject(self.options.confirm) ? self.options.confirm : {});
			}

			if (self.options.confirm_if) {
				for (c_idx in self.options.confirm_if) {
					if (!self.options.confirm_if.hasOwnProperty(c_idx)) { continue; }
					if (self.options.confirm_if[c_idx].cancel_first) {
						self.options.confirm_if[c_idx].values = [false,true];
						delete self.options.confirm_if[c_idx].cancel_first;
					}
					self.options.confirm_if[c_idx] = $.extend({}, default_confirm, self.options.confirm_if[c_idx]);
				}
			}

			return false;
		},

		/**
	 * @fn _afterInit()
	 * @brief After template and data loading occur in the base widget class, this method builds an array of all
	 *        the original values for the form elements. Then it creates closure callbacks for use throughout form
	 *        events, installs callbacks on the submit and reset buttons, computes the original dirty state,
	 *        computes the original valid state, and installs change handlers on all the form elements.
	 */
		_afterInit: function() {
			var $self = this.element;
			var self = this;

			if (!self.options.data) {
				self.options.data = {};
			}

			if (!self.options.disable_form_submission) {
				$self.addClass('formWidgetType');
			}

			$self.toggleClass('submit-all', self.options.submit_all);

			// When we get here, the base class widget has already pulled any
			// data and stuck it into self.options.data. That will always represent
			// the current data. We need to save it as the original for dirty
			// tracking purposes.
			self._buildOriginalData();

			// We need to keep the object context with all callbacks, so build a closure for each
			self._defineClosureCallbacks();

			// Install the submission and cancel handlers
			self.installSubmitHandlers();
			self.installCancelHandlers();

			// Compute the original dirty state
			self.computeDirtyState();
			self.computeValidState();

			// Everything under this form that is supposed to be a widget has already
			// been widgitized if they are based on the widget class. Install our
			// change handlers on everything we track.
			self._installChangeHandlers();

			return false;
		},

		/**
	 * @fn _defineClosureCallbacks()
	 * @brief This method is called by _afterInit() and is responsible for actually performing the definition
	 *        of the closure callbacks used throughout the form events.
	 */
		_defineClosureCallbacks: function() {
			var $self = this.element;
			var self = this;

			// Closure callback for submission function
			self.options.submit_closure = function(e) {
				e.preventDefault();
				e.stopPropagation();
				self.submit(e);
			};
			// Closure callback for reset function
			self.options.reset_closure = function(e) {
				e.preventDefault();
				e.stopPropagation();
				self.reset(e);
			};

			// Closure callback for successful form submission
			self.options.submit_success_closure = function(d) {
				if (self.options._submit_dialog) {
					self.options._submit_dialog.remove();
					delete self.options._submit_dialog;
				}
				self._doSubmitSuccess(d);
			};

			// Closure callback for errored form submission
			self.options.submit_error_closure = function(jqXHR, status, error) {
				if (self.options._submit_dialog) {
					self.options._submit_dialog.remove();
				}
				self._doSubmitError(jqXHR, status, error);
			};

			// This is an enclosure to make sure when the event handler is called, we get back to our object's dirtyHandler().
			self.options.dirtyHandler = function(e) {
				var $this = $(this);

				// False-alarm cases...
				if (e.target !== this && $this.hasClass('managesOwnDescendentEvents')) { return; }
				if ($this.closest('.managesOwnDescendentEvents').closest($self)[0]) { return; }

				// This closure delays calling the dirtyHandler until all change
				// requests stop coming in. This makes us only call it once for all
				// requests.

				self.options.dirty_handler_timeout = function() {
					self._dirtyHandler(e);
					delete self.options.dirty_handler_pid;
					delete self.options.dirty_handler_timeout;
				};
				if (self.options.dirty_handler_pid) {
					self._clearTimeout(self.options.dirty_handler_pid);
				}

				self.options._dirtyHandlerBacklog = self.options._dirtyHandlerBacklog || [];
				self.options._dirtyHandlerBacklog.push(e.target);
				self.options.dirty_handler_pid = self._setTimeout(self.options.dirty_handler_timeout, 100);
			};
		},

		/**
	 * @fn _buildOriginalData()
	 * @brief Here, we build an associative array which contains a copy of all of the original values for the form
	 *        elements. If a value was not present in the database, but is present on the form, it uses the default
	 *        value of the form as the original value.
	 */
		_buildOriginalData: function() {
			var $self = this.element;
			var self = this;
			var $named;

			var form_info, form_values;

			// Then find all named form elements and make sure we have a key for them and an initial
			// value for submission -- value-from fields are ignored, as they are not tracked.
			$named = $self.find('[name]');
			$named.each(function() {
				// This part builds a key for each named element if there is not one already
				var name = $(this).attr('name');
				if (!(name in self.options.data)) {
					// Only do this if we need to
					if (!form_info) {
						form_info = self._getFormValues();
						form_values = form_info.values;
					}

					self.options.data[name] = form_values[name];
				}
			});

			// Make a copy of the original data for comparison...
			self.options.originalData = $.extend(true, {}, self.options.data);
		},
		/**@}*/


		/** @name Child Class Override Functionality
	 *  This section contains methods which a child class may override to alter behavior of the formWidget.
	 */
		/**@{*/
		/**
	 * @fn _dirtyHandler(e)
	 * @brief The _dirtyHandler() method is used to process form element dirtying. It can be overriden in
	 *        a child class if the formWidget default for dirty handling is not sufficient.
	 *
	 *        Upon the reciept of a dirty event (from a sub-element), this sets self.options.data to the current
	 *        state of the form, then checks it for dirtiness.
	 *
	 * @params e The event that dirtied the form element.
	 */
		_dirtyHandler: function (e) {
			this.computeDirtyState();
			this.computeValidState(true);
		},

		_realSubmit: function() {
			var self = this, $self = this.element, submit_all, rest_params = {}, i, key, value, form_info, form_data, key_elem, include_filled_data, dk_idx, dk, empty_array_check, null_check, post_data, method, attr_node, $fileInputs, fri_idx, $form, k, v, vv, $orig, $clone, pd_key;
			submit_all = self.options.submit_all || $self.hasClass('submit-all');

			// This makes a copy of the structure instead of a pointer/reference to it
			rest_params = {};

			// First, clear messages before submit to prevent them stacking up
			self.clearMessages();

			// self.options.rest_params is the GET REST params sent on the URL hash to the detail screen
			if (self.options.rest_params) {
				if (self.options.include_keys) {
					// If there is an include_keys option, only add the included keys
					if (typeof self.options.include_keys === 'string') {
						// May be a string if only one key was specified
						self.options.include_keys = [self.options.include_keys];
					}

					for (i=0; i<self.options.include_keys.length; i++) {
						key = self.options.include_keys[i];
						value = self.options.rest_params[key];
						if (typeof value !== 'undefined') {
							rest_params[key] = value;
						}
					}
					// If there is a key field, only send that and the 'key' entry (e.g.: { key: 'bbx_user_id', bbx_user_id: 123 })
				} else if ('key' in self.options.rest_params) {
					key = self.options.rest_params.key;
					value = self.options.rest_params[key];

					if (value) {
						rest_params[key] = value;
					} else {
						debugLog('jquery.formWidget.js: Key ', key, ' specified but not found in ', self.options.rest_params);
					}
				} else {
					// Otherwise, dump in everything
					for (key in self.options.rest_params) {
						rest_params[key] = self.options.rest_params[key];
					}
				}
			}

			// Get the form data
			form_info = self._getFormValues(undefined, !submit_all);
			form_data = form_info.values;
			key_elem = form_info.key_element_map;

			// Add anything specified in the s.o.include_data_keys array
			include_filled_data = {};
			if (self.options.include_data_keys && self.options.include_data_keys.length) {
				for (dk_idx = 0; dk_idx < self.options.include_data_keys.length; dk_idx++) {
					dk = self.options.include_data_keys[dk_idx];
					if (!(dk in form_data) && (dk in self.options.data)) {
						include_filled_data[dk] = self.options.data[dk];
					}
				}
			}

			$.extend(form_data, include_filled_data);

			// Gather the REST params and merge with form data for posting
			post_data = $.extend({}, rest_params, form_data);
			method = 'PUT';
			attr_node = $self[0].getAttributeNode('method');

			if (self.options.method) {
				method = self.options.method;
			} else if (attr_node && (attr_node.specified || !('specified' in attr_node))) {
				// The method needs to be detected like this because IE defaults it to GET
				method = $self.attr('method');
			}


			$fileInputs = $self.find('input[type=file]');

			// The FILE inputs' "values" (usu. only the file path as text) will be in the post_data-- it needs to be removed
			$fileInputs.each(function (idx, elm) {
				var $fi = $(elm), fiName = $fi.attr('name'), fiData = post_data[fiName], i, thisFiVal;

				if ($.isArray(fiData)) {
					var fiVal = $fi.val();

					// If the self.data item contains an array (multiple controls), find the one that matches and pull it out
					for (i=0; i<fiData.length; i++) {
						thisFiVal = fiData[i];
						if (fiVal == thisFiVal) {
							fiData.splice(i, 1);
							break;
						}
					}
				} else {
					// If it's a plain value, just delete it
					delete post_data[fiName];
				}
			});

			empty_array_check = (!$.isArray(self.options.submit_empty_array_as) || self.options.submit_empty_array_as.length);
			null_check = (self.options.submit_null_value_as !== false && self.options.submit_null_value_as !== undefined);

			if (null_check || empty_array_check) {
				for (pd_key in post_data) {
					if (!post_data.hasOwnProperty(pd_key)) { continue; }

					// If s.o.submit_empty_array_as is not an empty array, then post-process the post_data object to convert empty arrays
					// to the s.o.submit_empty_array_as value
					if (empty_array_check && ($.isArray(post_data[pd_key]) && !post_data[pd_key].length)) {
						post_data[pd_key] = self.options.submit_empty_array_as;
					}

					// If the value is NULL or UNDEFINED, use the submit_null_value_as directive
					if (null_check && (post_data[pd_key] === null || post_data[pd_key] === undefined)) {
						post_data[pd_key] = self.options.submit_null_value_as;
					}
				}
			}

			// Check for "force_reset_if" directives
			if (self.options.force_reset_if && self.options.force_reset_if.length) {
				for (fri_idx = 0; fri_idx < self.options.force_reset_if.length; fri_idx++) {
					if (self.options.force_reset_if[fri_idx] in post_data) {
						self.options._force_reset_once = true;
						break;
					}
				}
			}

			if ($fileInputs[0]) {
				// If there are file inputs, we need to make a "dummy" form to submit

				$form = $('<form method="POST" style="position: absolute; left: -999em"></form>');
				$form.attr('action', self.options.rest);
				$form.attr('method', 'POST');

				for (k in post_data) {
					v = post_data[k];

					if ($.isArray(v)) {
						for (i=0; i<v.length; i++) {
							vv = v[i];
							$('<input type="hidden" />').attr('name', k).val(vv).appendTo($form);
						}
					} else {
						$('<input type="hidden" />').attr('name', k).val(v).appendTo($form);
					}
				}

				$fileInputs.each(function () {
					$orig = $(this);

					// Put in "dummy" file fields and move the real ones to the new form
					$clone = $orig
					.clone()
					.disable('dummy-file-input')
					.insertAfter($orig);

					$orig.data('dummy', $clone).appendTo($form);
				});

				$fileInputs.appendTo($form);
				$form
					.appendTo('body')
					.ajaxForm({
					dataType: 'json',
					data: { xsrfkey: getXSRFKey() },
					beforeSerialize: function () {
						if (self.options.dialog_during_submit) {
							if (self.options._submit_dialog) {
								self.options._submit_dialog.remove();
								delete self.options._submit_dialog;
							}

							self.options._submit_dialog = new CUI.Dialog(self.options.dialog_during_submit);
						}
					},
					success: function (data, status, xhr, $f) {
						if (self.options.destroyed) { return; }
						// Move the "dummy" fields back
						$form.find('input[type=file]').each(function (idx, elm) {
							$orig = $(this);
							$clone = $(this).data('dummy');
							if ($orig && $orig[0]) {
								$orig.insertAfter($clone);
								if (self.options.clear_file_input_on_success) { $orig.val(''); }
								$clone.remove();
							}
						});
						$form.remove();

						if (self.options.soft_upload_errors && data.error) {
							xhr.status = 999;
							self.options.submit_error_closure.call(this, xhr, 'error', data.error);
						} else {
							self.options.submit_success_closure.apply(this, arguments);
						}
					}
				})
					.trigger('submit');
			} else {
				// If there aren't any FILE inputs, just do a doREST
				if (self.options.dialog_during_submit) {
					if (self.options._submit_dialog) {
						self.options._submit_dialog.remove();
						delete self.options._submit_dialog;
					}

					self.options._submit_dialog = new CUI.Dialog(self.options.dialog_during_submit);
				}

				self.doREST(method, self.options.rest, post_data, {
					success: self.options.submit_success_closure,
					error: self.options.submit_error_closure
				});
			}
		},

		_beforeSubmitSuccess: function(callback) {
			return false;
		},

		_doSubmitSuccess: function(d) {
			var $self = this.element;
			var self = this;

			var end_callback = function () {
				if (!self._beforeSubmitSuccess(function() { self._realSubmitSuccess.call(self, d); })) {
					self._realSubmitSuccess(d);
				}
			};

			// Give people a chance to hook into and cancel form submission asynchronously
			self._callEventHooks('SubmitSuccess', end_callback);
		},

		_realSubmitSuccess: function(d) {
			var self = this, $self = this.element, key;

			if (self.options.destroyed) { return; }

			self.options.$page_modules.removeClass('state-loading').removeClass('state-error');
			self.delMessage('error', 'form');
			$self.removeData('error');

			var $filter_out_modules = self.options.$page_modules;
			var rc = self._validateRESTContainer(d);
			var d_out = rc ? d[rc] : d;

			// If both force_reset and no_initial_get are set, we want to reset the form to blank data, not the return data, so skip this
			if (!(self.options.no_initial_get && (self.options.force_reset || self.options._force_reset_once))) {
				d = $.extend({}, self.options.data || {}, d_out);

				for (key in d) {
					if (self.options.originalData[key] != d[key]) {
						$filter_out_modules = $filter_out_modules.not($self.find('[name=' + key + ']').closest('.page_module'));
						self.options.originalData[key] = d[key];
					}

					if (self.options.data[key] != d[key]) {
						if (self.options.indicate_changed_values) {
							self.addMessage(key, 'mesg', 'bgchange', 'One or more of your settings was modified by another user or by server-side validation.');
							var $elem = $self.find('[name=' + key + ']');
							$elem.addClass('is-dirty');
							$elem.effect('pulsate', { times: 6 }, 1000);
							setTimeout(function() { $elem.removeClass('is-dirty'); }, 7000);
							setTimeout(function() { self.delMessage('mesg', 'bgchange'); }, 30000);
						}
					}

					self.setValue(d[key], key);
					self.options.data[key] = d[key];
				}

				if (self.options.filter_return_data) {
					self.options.data = {};
					for (key in d_out) {
						self.options.data[key] = d_out[key];
					}
					self.options.originalData = $.extend({}, self.options.data);
				}

				//self.addMessage(self.options.$page_modules.not($filter_out_modules).find('.message'), 'success', 'submit', 'Successfuly saved your settings.');
			}

			self.computeDirtyState();
			self.computeValidState();

			self._trigger('SubmitSuccess');
			$self.trigger('SubmitSuccess');

			if (self.options.dialog_after_success) {
				new CUI.Dialog($.extend({
					title: 'Operation Completed Successsfully',
					text: 'The operation completed successfully.',
					buttons: ['OK']
				}, self.options.dialog_after_success || {}));
			}

			if (self.options.force_reset) {
				self.reset();
			} else if (self.options._force_reset_once) {
				delete self.options._force_reset_once;
				self.reset();
			}
		},

		_beforeSubmitError: function(callback) {
			return false;
		},

		_doSubmitError: function(jxHQR, status, error) {
			var $self = this.element;
			var self = this;

			var end_callback = function () {
				if (!self._beforeSubmitError(function() { self._realSubmitError.call(self, jxHQR, status, error); })) {
					self._realSubmitError(jxHQR, status, error);
				}
			};

			// Give people a chance to hook into and cancel form submission asynchronously
			self._callEventHooks('SubmitError', end_callback);
		},

		_realSubmitError: function(jxHQR, status, error) {
			var self = this, $self = this.element, field;

			if (self.options.destroyed) { return; }

			self.options.$page_modules.addClass('state-error').removeClass('state-loading');

			var error_data = jQuery.parseJSON(jxHQR.responseText);
			if (error_data && error_data.error) {
				error_data = error_data.error;
			}
			if (jxHQR.status < 400) {
				$self.data('error', error);
				self.addMessage($self.find('.message:first'), 'error', 'form', error);
			} else {
				if (typeof error_data === 'string') {
					self.addMessage(undefined, 'error', getUnique('unspecifiedError'), getMessageFromKey(error_data));
				} else {
					for (field in error_data) {
						self.addMessage(field, 'error', 'name-' + field, getMessageFromKey(error_data[field]));
					}
				}
				if (window.AJAXErrorHandler) {
					new AJAXErrorHandler().handle(jxHQR, status, error);
				}
			}

			self._trigger('SubmitError');
			$self.trigger('SubmitError');
		},

		_installChangeHandlers: function() {
			var $self = this.element;
			var self = this;

			// For right now, all we want to track is form elements


			// Find form fields that have a name attribute and are not under a
			// descendant widget
			self._delegate($self, ':input[name], :input[value-from]', 'click.fwch keyup.fwch change.fwch enable-disable.fwch', self.options.dirtyHandler);
			self._delegate($self, '.widgetType.widgetValueWidget', 'change.fwch disabled.fwch enabled.fwch', self.options.dirtyHandler);
			self._bind($self, 'stateChange', self.options.dirtyHandler);
			self._bind($self, 'dataTableInit', self.options.dirtyHandler);

			// Track events coming up
			$self.on('formElementChange', self.options.dirtyHandler);
		},

		_removeChangeHandlers: function() {
			var $self = this.element;
			var self = this;

			$self.on('.fwch', ':input[name], :input[value-from]', self.options.dirtyHandler);
			$self.on('.fwch', '.widgetType.widgetValueWidget', self.options.dirtyHandler);
		},
		/**@}*/


		/** @name Public Methods and Programmatic Interaction Capabilities
	 *  This section contains methods which may be called programmatically to interract with the formWidget.
	 */
		/**@{*/
		/**
	 * @fn setFormValue(name, val, avoid_dirty)
	 * @brief This method sets the value for a form element based on name and specifies whether it should dirty the form.
	 * @param name The name of the element to set the value for.
	 * @param val The value to set the element to.
	 * @param avoid_dirty If true, it will not dirty the form. It will set the original value and the current value. If false
	 *                    or not present, then it will dirty the form by only setting the current value.
	 */
		setFormValue: function(name, val, avoid_dirty, source) {
			var $self = this.element;
			var self = this;

			if (!('data' in self.options)) {
				self.options.data = {};
			}
			if (!('originalData' in self.options)) {
				self.options.originalData = {};
			}

			var $target = $self.find('[name=' + name + ']');
			if ($target.is(':checkbox')) {
				if ($target.is(':checked')) {
					val = 1;
				} else {
					val = 0;
				}
			}
			self.options.data[name] = val;
			if (avoid_dirty) {
				self.options.originalData[name] = val;
			}

			self.fillData(self.options.data);
		},

		refresh: function() {
			var $self = this.element;
			var self = this;

			// Clear out any current data knowledge
			if ('data' in self.options) {
				delete self.options.data;
			}
			if ('originalData' in self.options) {
				delete self.options.originalData;
			}

			// We use this call back function to reset the dirty state of the form
			// after the data has been refreshed
			var finish_refresh = function() {
				// Set up the original values again
				self._buildOriginalData();

				self._installChangeHandlers();

				self.computeDirtyState();
				self.computeValidState();
			};

			// Unbind the change handlers so we don't try dirty tracking changes during
			// the fillData call.
			self._removeChangeHandlers();

			// When the data is finished being loaded, then we need to refresh the dirty state
			self._one($self, 'dataReady', finish_refresh);

			// Force a new getREST request for data and force new fillData run
			self._doDataFill();
		},

		/**
	 * @fn computeValidState()
	 * @brief Forces the formWidget to re-compute the validity of the form based on feedback from any validation
	 *        widgets which may have updated their validity due to various interactions. This will also enable
	 *        or disable the submit and reset buttons on the form based on dirty state and valid state. This is
	 *        generally called after computing the dirty state using computeDirtyState().
	 *
	 * @pre Expects the dirty state to already be known and up-to-date. Expects validation widgets to have already
	 *      tested validity of individual form elements and marked them valid or invalid, setting error messages
	 *      as appropriate in the data['error'] stash on each element. Fields which are invalid are expected to have
	 *      the class 'is-invalid' applied to them.
	 * @post Upon completion, error messages from invalid fields will be in the form's message area. If invalid
	 *       fields are present, an alert icon will be displayed in the form legend, the 'state-error' class
	 *       will be applied to the containing page_module, the submit button will be disabled, and the reset
	 *       button will be lit.
	 */
		computeValidState: function(force_check) {
			var $self = this.element;
			var self = this;

			var removeErrorMessage = function() {
				var $this = $(this);
				var loc, identifier;

				loc = $this.attr('name') || $this.attr('ident') || $this.attr('value-from');
				if ($this.attr('name')) {
					identifier = 'name-' + loc;
				} else if ($this.attr('ident')) {
					identifier = 'ident-' + loc;
				} else if ($this.attr('value-from')) {
					identifier = 'value-from-' + loc;
				} else {
					var widget = $this.getCUIWidget();
					if (widget && widget.options.widget_id) {
						identifier = 'widget-id-' + widget.options.widget_id;
					} else {
						debugLog('jquery.formWidget.js: Called removeErrorMessage on an object with no identifying properties: ', $this, ' -- ', $self);
					}
				}

				self.delMessage('error', identifier);
			};

			var addErrorMessage = function() {
				var $this = $(this);
				var loc, identifier_part, identifier, error;

				// Yes, these are assignments, not comparisons
				/* jshint -W084 */
				if (identifier_part = $this.attr('name')) {
					identifier = 'name-' + identifier_part;
				} else if (identifier_part = $this.attr('ident')) {
					identifier = 'ident-' + identifier_part;
				} else if (identifier_part = $this.attr('value-from')) {
					identifier = 'value-from-' + identifier_part;
				} else if (widget && widget.options.widget_id) {
					identifier = 'widget-id-' + widget.options.widget_id;
				} else {
					var widget = $this.getCUIWidget();
					if (widget && widget.options.widget_id) {
						identifier = 'widget-id-' + widget.options.widget_id;
					} else {
						debugLog('jquery.formWidget.js: Called addErrorMessage on an object with no identifying properties: ', loc, ' -- ', $self);
					}
				}

				// This is an assignment, not a comparison
				if (error = $this.data('error')) {
					self.addMessage($this, 'error', identifier, error);
				}
				/* jshint +W084 */
			};

			if (self.options.dirty || force_check) {
				self.options.valid = true;
				var $invalids = $self.find('[name].is-invalid, [ident].is-invalid, [value-from].is-invalid, .valid-check.is-invalid').not('[disabled], .state-disabled, .state-enabled-false');
				if ($invalids[0]) {
					$invalids.each(addErrorMessage);
					self.options.valid = false;
					self.options.$page_modules.addClass('state-error');
					$self.addClass('is-invalid');
				} else {
					self.options.$page_modules.removeClass('state-error');
					$self.removeClass('is-invalid');
				}
				$self.find('[name], [ident], [value-from], .valid-check').not($invalids).each(removeErrorMessage);
			} else {
				self.clearMessages();
				self.options.$page_modules.closest('div.page_module').removeClass('state-error');
			}

			self._trigger('Validation');
			$self.trigger('validation');
		},

		/**
	 * @fn _getFormValues
	 * @brief Reads values from form fields and widgets. Returns an object with the values, and a field-to-control mapping object:
	 *          { key_element_map: { fieldname: [ $control, $control, $control... ], ... }, values: { key: value, ... } }
	 *        This is for formWidget internal use, and should not be overridden.
	 *
	 *        If multiple controls use the same key, they will be made into an array. If a value returned is an array, it will be merged into the current
	 *        array (it will NOT be placed as a reference in one position of the array). If a value is undefined, and a later control sets the value, the
	 *        undefined will be clobbered with the new value. If a value exists and a control sets undefined, it will be ignored.
	 *
	 *        If the option "only_dirty" is true, then widget/HTML params for dirty/always-submit/never-submit will be followed, and only submittable dirty
	 *        fields will be returned.
	 */
		_getFormValues: function ($controls, only_dirty) { // $controls is optional-- it's there for when you already have the set and don't want to waste CPU re-pulling
			var self = this, $self = this.element;
			var values = {}, key_element_map = {}, cidx, is_value_widget = false;

			$controls = $controls || $self.find(':input[name],.widgetType.widgetValueWidget').not($self.find('.widgetManagesOwnDescendentValue').find('[name],.widgetType')).not('[disabled],.state-disabled');

			controlLoop: for (cidx = 0; cidx < $controls.length; cidx++) {
				var $c = $controls.eq(cidx), widgets, class_is_dirty = $c.hasClass('is-dirty'), class_always_submit = $c.hasClass('always-submit') || self.options.submit_all;
				is_value_widget = false;

				if (only_dirty && $c.hasClass('never-submit')) { continue controlLoop; }

				// This CANNOT be replaced with CUI.getWidgetValues, because that doesn't do dirty/never/always checking, nor does it make the key_element_map.

				if ($c.hasClass('widgetValueWidget')) {
					widgets = $c.getCUIWidgets();

					widgetPerElementLoop: for (var widx = 0; widx < widgets.length; widx++) {
						var widget = widgets[widx], widget_value, f_key;
						if (widget.value_widget) {
							is_value_widget = true;

							// Determine whether this widget is submittable (when using the only_dirty param)
							if (only_dirty && (
								widget.options.never_submit ||
								!(class_is_dirty || class_always_submit || widget.options.always_submit)
							)) { continue widgetPerElementLoop; }

							widget_value = widget.getValue();

							keyPerWidgetLoop: for (f_key in widget_value) {
								if (!widget_value.hasOwnProperty(f_key)) { continue; }

								// Add $c to the key-element map
								key_element_map[f_key] = key_element_map[f_key] ? key_element_map[f_key].add($c) : $c;

								// Add the data to the values object-- Combine multiple values for a key, or array-values, into one array
								if (f_key in values && values[f_key] !== undefined) {
									if (widget_value[f_key] !== undefined) { // If the widget value is undefined, and a defined value exists, skip it
										if ($.isArray(values[f_key])) {
											if ($.isArray(widget_value[f_key])) {
												// Widget and existing are arrays: Concat
												values[f_key] = values[f_key].concat(widget_value[f_key]);
											} else {
												// Widget is not an array, existing is: Push
												values[f_key].push(widget_value[f_key]);
											}
										} else {
											if ($.isArray(widget_value[f_key])) {
												// Widget is an array, existing is not: Array-ify existing, and concat with widget value
												values[f_key] = [ values[f_key] ].concat(widget_value[f_key]);
											} else {
												// Widget nor existing are arrays: Combine them into one.
												values[f_key] = [ values[f_key], widget_value[f_key] ];
											}
										}
									}
								} else {
									// There is no existing value: Just set it
									values[f_key] = widget_value[f_key];
								}
							} // keyPerWidgetLoop
						}
					} // widgetPerElementLoop
				} // if (is a widget) -- Don't "else" this! The element might have widgets, but no value-bearing widgets! Check "is_value_widget".

				var el_key = CUI.getElementName($c), el_value;

				if (!is_value_widget && el_key ) { // Intentionally blocking el_key = "" too, using a loose truth test

					// Determine whether this widget is submittable (when using the only_dirty param)
					if (only_dirty && !(class_is_dirty || class_always_submit )) { continue controlLoop; }

					// This is just a named DOM element (with no value-bearing widgets). Just get the value from it.
					el_value = CUI.getInputElementValue($c);

					// Add the data to the values object, make/extend arrays if the keys already exist
					if (el_key in values) {
						if (values[el_key] !== undefined) {
							if ($.isArray(values[el_key])) {
								if ($.isArray(el_value)) {
									values[el_key] = values[el_key].concat(el_value);
								} else {
									values[el_key].push(el_value);
								}
							} else {
								values[el_key] = [ values[el_key], el_value ];
							}
						}
					} else {
						values[el_key] = el_value;
					}

					// Add $c to the key-element map
					key_element_map[el_key] = key_element_map[el_key] ? key_element_map[el_key].add($c) : $c;
				}

			}

			return ({ key_element_map: key_element_map, values: values });
		},

		/**
	 * @fn getFormValues(only_dirty)
	 * @brief Reads values from form fields and widgets. Returns an object with the values.
	 *
	 *        If the option "only_dirty" is true, then widget/HTML params for dirty/always-submit/never-submit will be followed, and only submittable dirty
	 *        fields will be returned.
	 *
	 *        This is an externally-usable interface to _getFormValues.
	 */

		getFormValues: function (only_dirty, include_xsrfkey) {
			var self = this, values;
			values = self._getFormValues(undefined, only_dirty);

			if (!include_xsrfkey) {
				delete values.values.xsrfkey;
			}

			return values.values;
		},

		/**
	 * @fn computeDirtyState()
	 * @brief Forces the formWidget to re-compute the dirty state of each field in the form and the dirty state of the
	 *        entire form as a whole.
	 */
		computeDirtyState: function() {
			var $self = this.element, self = this;
			var $controls, form_info, form_values, key_elem, simple_dirty, key, el_idx, $el, w_idx, ws, has_widgets;

			// Remove this hack once we get the external-data dirty tracking working correctly. Don't use this option.
			if (self.options.always_dirty) {
				self.options.dirty = true;
				self._applyDirtyState();
				return;
			}

			// This is useful to let a non-form element indicate that a form is dirty if it exists. This is used, for instance, if there is an aopbContainer
			// state that has no config, but should be considered dirty and submittable nonetheless.
			if ($self.find('.always-dirty-indicator')[0]) {
				self.options.dirty = true;
				self._applyDirtyState();
				return;
			}

			$controls = $self.find(':input[name],.widgetType.widgetValueWidget').not($self.find('.widgetManagesOwnDescendentValue').find('[name],.widgetType')).not('[disabled],.state-disabled');
			form_info = self._getFormValues($controls);
			form_values = form_info.values;
			key_elem = form_info.key_element_map;

			// Assume we are not dirty
			self.options.dirty = false;
			$controls.removeClass('is-dirty');

			keyLoop: for (key in form_values) {
				simple_dirty = undefined;
				el_idx = key_elem[key].length;
				elementLoop: while (el_idx--) {
					$el = key_elem[key].eq(el_idx);
					has_widgets = false;
					if ($el.hasClass('is-dirty')) { continue elementLoop; } // Can't get dirtier than dirty!

					if ($el.is('.widgetType') && !$el.data('dummyFor')) {
						ws = $el.getCUIWidgets();
						w_idx = ws.length;
						widgetLoop: while (w_idx--) {
							if (ws[w_idx].value_widget) {
								has_widgets = true;
								if (ws[w_idx].isDirty(key, self.options.originalData[key], form_values[key], self.options.compare_params)) {
									self.options.dirty = true;
									$el.addClass('is-dirty');
								}
							}
						}
					} // No "else" -- the widgets may exist, but be irrelevant

					if (!has_widgets) {
						// Set simple_dirty if it has not been defined, otherwise, use the existing value.
						simple_dirty = (simple_dirty === undefined) ? !CUI.compareObjects(form_values[key], self.options.originalData[key], self.options.compare_params ) : simple_dirty;
						if (simple_dirty) {
							self.options.dirty = true;
							$el.addClass('is-dirty');
						}
					}
				}
			}

			self._applyDirtyState();
		},

		_applyDirtyState: function () {
			var self = this, $self = this.element;

			if (self.options.dirty) {
				$self.addClass('is-dirty');
			} else {
				$self.removeClass('is-dirty');
			}

			if (self.options.dirty) {
				self._trigger('Dirty');
				$self.trigger('dirty');
			} else {
				self._trigger('Undirty');
				$self.trigger('undirty');
			}
		},

		/*
	enableSubmitButtons: function() {
	    var $self = this.element;
	    var self = this;

	    $self.find('.module_save_button, .validated_button, [type=submit]').removeAttr('disabled').removeClass('state-disabled');
	},

	enableCancelButtons: function() {
	    var $self = this.element;
	    var self = this;

	    $self.find('.module_cancel_button, [type=reset]').removeAttr('disabled').removeClass('state-disabled');
	},

	disableSubmitButtons: function() {
	    var $self = this.element;
	    var self = this;

	    $self.find('.module_save_button, .validated_button, [type=submit]').attr('disabled', 'disabled').addClass('state-disabled');
	},

	disableCancelButtons: function() {
	    var $self = this.element;
	    var self = this;

	    $self.find('.module_cancel_button, [type=reset]').attr('disabled', 'disabled').addClass('state-disabled');
	},
	*/

		reset: function(e) {
			var self = this, $self = this.element, key;

			if (e) {
				e.preventDefault();
				e.stopPropagation();
			}

			// Reset the current values
			for (key in self.options.originalData) {

				// Set by copy... by any means necessary!
				if ($.isArray(self.options.originalData[key])) {
					self.options.data[key] = $.extend(true, [], self.options.originalData[key]);
				} else if (typeof self.options.originalData[key] === 'object' && self.options.originalData[key]) {
					self.options.data[key] = $.extend(true, {}, self.options.originalData[key]);
				} else {
					self.options.data[key] = self.options.originalData[key];
				}

				var $elem = $self.find('[name=' + key +'],[value-from=' + key + ']');
				$elem.removeClass('is-dirty');
				CUI.forceValid($elem);
				$elem.removeClass('is-invalid');
				$elem.removeClass('is-valid');
			}

			// Re-enable add+delete on any DTWs on the screen
			$self.closest('.cui-page-content').find('.dataTableWidget').each(function() {
				var dtw = $(this).getCUIWidget('dataTableWidget');
				if (dtw) {
					dtw.cancelEditMode();
				}
			});

			// Re-fill the form
			self._removeChangeHandlers();
			self.fillData(self.options.data, true);
			self.computeDirtyState();
			self.computeValidState();
			self._installChangeHandlers();

			// Remove any global error messages
			self.clearMessages();

			$self.trigger('formReset');
			self._trigger('FormReset');
		},

		validate: function() {
			var $self = this.element;
			var self = this;

			self.computeValidState();
			self.computeDirtyState();
		},

		_confirmSubmit: function (confirmed) {
			var self = this;

			if (confirmed) {
				if (self.options._confirms) { self.options._confirms.pop(); }
				self.submit();
			} else {
				delete self.options._confirms;
				self._abortSubmit();
			}
		},

		_abortSubmit: function () {
			this.element.trigger('SubmitAbort');
		},

		submit: function () {
			var self = this, $self = this.element, c_key;

			self.validate();

			// handle any dataTables on the screen
			$.each($self.closest('.cui-page-content').find('.dataTableWidget'), function() {
				var $dtw = $(this);
				var dtw  = $dtw.getCUIWidget('dataTableWidget');

				if (dtw) {
					// submit dtw that has enabled add button
					var $add_button = $dtw.find('button.add-save').not('.state-disabled');
					var $edt_button = $dtw.find('button.edit-save').not('.state-disabled');
					$add_button.trigger('click');
					$edt_button.trigger('click');
				}
			});

			if (self.options.valid) {
				if (self.options._confirms && !self.options._confirms.length) {
					// Confirms were set, but all have been dismissed
					delete self.options._confirms;
				} else {
					// Confirms either are not set, or this is the first submit attempt
					self.options._confirms = [];
					if (self.options.confirm) {
						self.options._confirms.push(self.options.confirm);
					}

					for (c_key in (self.options.confirm_if || {})) {
						if ($self.find('.is-dirty[name=' + c_key + ']').length) {
							self.options._confirms.push(self.options.confirm_if[c_key]);
						}
					}
				}

				if (self.options._confirms && self.options._confirms.length) {
					// Confirms exist from an in-progress submit
					new CUI.Dialog(self.options._confirms[self.options._confirms.length - 1]);
					return;
				}

				// Clean up if we made an empty array but didn't need it
				delete self.options._confirms;

				self.options.$page_modules.addClass('state-loading');
				self._realSubmit();
			}
		},

		fillData: function () {
			$.ui.widget.prototype.fillData.apply(this, arguments);
			this.element.trigger('producerFillData');
		},

		installSubmitHandlers: function() {
			var $self = this.element;
			var self = this;

			self._bind($self, 'submit', self.options.submit_closure);
			self._bind($self.find('.module_save_button, [type=submit]'), 'click', self.options.submit_closure);
		},

		installCancelHandlers: function() {
			var $self = this.element;
			var self = this;

			self._bind($self.find('.module_cancel_button, [type=reset]'), 'click', self.options.reset_closure);
			self._bind($self.find('.module_reset_button, [type=reset]'), 'click', self.options.reset_closure);
		}
		/**@}*/

	});

	add_widget('formWidget', 'ui.formWidget', 'formWidget', formWidget);
})(jQuery);
