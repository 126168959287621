(function () {

    var dt = [{
	requires: ['EDIT_REPORTS'],
	entity_template: 'data_table',
	rest: '/gui/reporting/list',
	extra_row_data: ['bbx_reporting_id'],
	dataEvents: {
	    allow_delete: true,
	    bind: {
		'reporting': ''
	    }
	},
	delete_action: {
	    rest: '/gui/reporting/report',
	    method: 'DELETE',
	    only_rest_params: []
	},
	primary_keys: ['bbx_reporting_id'],
	unique_id: 'bbx_reporting_id',
	click_action: 'overlay',
	overlay_name: 'report_detail',
	overlay_params: ['bbx_reporting_id'],
	overlay_data: {
	    key: 'bbx_reporting_id'
	},
	columns: [
	    {
		header: 'Report Name',
		column: 'bbx_reporting_name'
	    }
	],
	table_actions: {
	    delete_rows: {
		text: 'Delete Selected'
	    },
	    select_row: true,
	    select_all_rows: true
	}
    }];
    
    dt[1] = $.extend(true, {}, dt[0]);
    dt[1].requires = ['VIEW_REPORTS', '!EDIT_REPORTS'];
    dt[1].overlay_name = 'report_view';
    dt[1].table_actions = {};

    register_screen('statistical_reports', {
	elements: [
	    {
		entity_template: 'page_title',
		title: 'Statistical Reports'
	    },
	    {
		entity_template: 'page_buttons',
		buttons: [
		    {
			type: 'button',
			href: '#!/bps/statistical_reports/overlay/report_create/{}',
			text: 'Create New Report',
				attributes: { 'class': 'page_create_button' },
			requires: ['CREATE_REPORTS']
		    },
		    {
			type: 'help',
			help_id: 'reports_list'
		    }
		]
	    },
	    {
		entity_template: 'page_content',
		elements: [
		    dt[0],
		    dt[1]
		]
	    }
	]
    });
})();
