/* jshint jquery: true, unused: vars */
/* global add_widget */
/**
 * @class copyTextboxWidget(options)
 *
 * This widget keeps the value of the applied textbox ($self) the same as that of the source textbox (closest -> source), unless the text in
 * the applied textbox changes (change event). Currently, this widget is NOT recommended for "Edit" mode forms-- only for "Create" mode forms
 * because existing values or fillDatas are not compensated for.
 *
 * @par Usage:
 *
 * $(...).copyTextboxWidget({
 *     closest: 'form',
 *     source:  '$textbox_name'
 * });
 *
 * @par Options:
 * @param closest jQuery selector, found via "closest", to the "root" element to search for the source object. Defaults to the closest "form".
 * @param source The source object with the value to be cloned. Can be "$input_element_name", ".class", or "#id".
 */

(function( $ ){
	var copyTextboxWidget = $.extend({}, $.ui.widget.prototype, {
		options: {
			template: false,
			closest: 'form',  // jQuery selector, interpreted by the "closest", and used as the root object to search for "source"
			source: false,    // Use "$name", ".class", or "#id" notation

			_changed: false,
			$_source: $()
		},

		_beforeDOMReady: function () {
			var self = this;
			var $self = this.element;

			$self.on('change', function (e,d) {
				if (d && d.non_interactive) { return; }
				self.options._changed = true;
			});

			var $root;

			if (self.options.closest) {
				$root = $self.closest(self.options.closest);
			} else {
				$root = $('body');
			}

			var source = self.options.source;
			var $_source;

			if (source.charAt(0) === '$') {
				$_source = $root.find('[name=' + source.replace(/^\$/,'') + ']:eq(0)');
			} else {
				$_source = $root.find(source);
			}

			self.options.$_source = $_source;

			$_source.on('keyup.sourcekeyup', function () {
				if (self.options._changed) {
					$_source.off('keyup.sourcekeyup');
				} else {
					$self.val($_source.val());
				}
			});

			return false;
		}
	});

	add_widget('copyTextboxWidget', $.extend({}, $.ui.widget.prototype, copyTextboxWidget));
})(jQuery);
