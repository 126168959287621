register_screen('fmfm_router_detail', {
    elements: [
	{
	    entity_template: 'container',
	    entity: 'h2',
	    'class':'page_title',
	    rest: '/gui/user/router/router',
	    elements: [
		{
		    entity: 'span',
		    text: 'Find-Me Forwarding for ' 
		},
		{
		    entity_template: 'format',
		    entity: 'span',
		    format: { field: '@bbx_extension_value', formatter: 'phone_number' }
		}
	    ]
	},
	{
	    entity_template: 'page_buttons',
	    buttons: [{ type: 'cancel' }, { type: 'save' }]
	},
	{
	    entity_template: 'page_content',
	    form_action: '/gui/user/router/router',
	    form_data_js: { force_reset: true },
	    dataEvents: {
		trigger: 'findme',
		bind: {
		    'callflow': ''
		},
		allow_delete: true
	    },
	    elements: [
		{
		    entity_template: 'page_module',
		    legend: 'Activate Find-Me Forwarding',
		    buttons: [ 'help' ],
		    help_id: 'find_me_activate',
		    elements: [
			{
			    entity_template: 'input_item',
			    title: 'Find-Me Forwarding:',
			    info: 'Setting this to '+entity.ldquo+'Inactive'+entity.rdquo+' will clear all rules upon Save.',
			    type: 'select',
			    input_name: 'router',
			    select_options: [{ text: 'Inactive (Call My Phones)', value: '0' }, { text: 'Active (Use Rules and Actions)', value: '1' }]
			}
		    ]
		},
		{
		    entity_template: 'aopb_container',
		    states: [{
			conditions: { a: '@router', op: '==', b: '_TRUEISH_' },
			elements: [
			    {
				entity_templates: ['aopb_state', 'container'],
				aopb_state: {
				    states: [{
					conditions: { a: '$router', op: '==', b: '_TRUEISH_' },
					actions: { visible: true, enabled: true, _cascade: true },
					else_actions: { visible: false, enabled: false, _cascade: true }
				    }]
				},
				container: {
				    elements: [
					{ /* */
					    entity_template: 'page_module',
					    legend: 'Rules and Actions',
					    buttons: [ 'help' ],
					    help_id: 'find_me_rules_actions',
					    elements: [
						{
						    entity_template: 'data_table',
						    accept_intial_data_from_parent: true,
						    click_action: 'edit',

						    suppress_form_value: true,

						    paginate: false,
						    filtering: false,
						    data_source: 'conditions',

						    rest: '/gui/user/router/conditions',
						    refresh_rest: '/gui/user/router/conditions',
						    refresh_rest_container: false,
						    refresh_data_source: 'conditions',

						    submit_only_dirty: false,
						    
						    allow_parent_extra_row_data: true,
						    'class': 'top-align-edit',
						    extra_row_data: [
							'callflow_summary',
							'bbx_mode_names',
							'bbx_mode_ids',
							'bbx_router_condition_map_id',
							'bbx_router_condition_map_tod_min_day',
							'bbx_router_condition_map_tod_wday',
							'bbx_router_condition_map_specific_date',
							'bbx_router_condition_map_recurring_date',
							'date_type',
							'bbx_router_condition_map_regex_metadata',
							'bbx_router_condition_map_regex_val',
							'bbx_callflow_name',
							'bbx_callflow_id',
							'bbx_router_id',
							'filter_type',
							'filter_value'
						    ],

						    sortable: {
							field: 'bbx_router_condition_map_weight',
							also_submit: ['bbx_router_condition_map_id']
						    },
						    dataEvents: {
							trigger: 'rules_and_actions',
							bind: { 
							    'callflow': '' 
							},
							allow_delete: true
						    },
						    primary_keys: ['bbx_router_condition_map_id'],
						    add_edit_action: {
							rest: '/gui/user/router/condition',
							rest_container: 'condition',
							include_data_from_parent: ['bbx_router_id'],
							filter_params: [
							    'bbx_router_condition_map_name',
							    'bbx_router_condition_map_regex_metadata',
							    'bbx_router_condition_map_val'
							]
						    },

						    delete_action: {
							rest: '/gui/user/router/condition'
						    },

						    row_actions: {
							edit_cancel: { text: 'Cancel' },
							add_cancel: { text: 'Cancel' }
						    },

						    table_actions: {
							add_row: {
							    text: 'Add a Rule',
							    location: 'bottom'
							},
							delete_rows: {
							    text: 'Delete Selected Rules',
							    location: 'bottom',
							    confirm: { title: 'Delete Selected Rules', text: 'Are you sure you want to delete the selected rules?' }
							},
							action_elements: {
							    location: 'bottom',
							    actions: [{
								entity_template: 'overlay_button',
								text: 'Manage Action Sets',
								overlay: 'fmfm_router_actions',
								pass_params: ['bbx_extension_id']
							    }]
							},
							select_row: true,
							select_all_rows: true
						    },
						    columns: [
							{
							    header: 'Time/Number Rule',
							    sortable: false,
							    width: '45%',
							    view_element: {
								entity_template: 'router_rule_display'
							    },
							    edit_elements: [
									{
										entity_template: 'data_item',
										title: 'Mode:',
										elements: [{
											entity_template: 'multi_select_populate',
											name: 'bbx_mode_ids',
											rest: '/gui/mode/modes',
											none_text: '(All Modes)',
											none_value: '',
											text_field: 'bbx_mode_name',
											value_field: 'bbx_mode_id'
										}]
									},
								{
								    entity_template: 'input_item',
								    title: 'Days:',
								    type: 'select',
								    input_name: 'date_type',
								    select_options: [
									{ text: 'Any Day', value: 'always' },
									{ text: 'Days of the Week', value: 'weekly' },
									{ text: 'Dates Every Year', value: 'yearly' },
									{ text: 'Specific Dates', value: 'once' }
								    ]
								},
								{
								    entity_template: 'switch',
								    closest: 'td',
								    selector: '[name=date_type]',
								    switch_elements: {
									weekly: [
									    {
										entity_template: 'data_item',
										title: '',
										elements: [{
										    entity_template: 'day_picker',
										    name: 'bbx_router_condition_map_tod_wday'
										}]
 									    }
									],
									yearly: [
									    {
										entity_template: 'data_item',
										title: '',
										elements: [
										    {
											entity: 'div',
											attributes: { name: 'bbx_router_condition_map_recurring_date' },
											widgets: ['dateRangePickerWidget'],
											widget_options: { dateRangePickerWidget: {
											    date_format: 'mm/dd',
											    show_year: false
											}}
										    }
										]
									    }
									],
									once: [
									    {
										entity_template: 'data_item',
										title: '',
										elements: [
										    {
											entity: 'div',
											attributes: { name: 'bbx_router_condition_map_specific_date' },
											widgets: ['dateRangePickerWidget'],
											widget_options: { dateRangePickerWidget: {
											    date_format: 'mm/dd/yy'
											}}
										    }
										]
									    }
									]
								    }
								}, // END switch
								{
								    entity_template: 'data_item',
								    title: 'Time:',
								    elements: [{
									entity: 'div',
									widgets: ['timeSelectMinsRangeWidget'],
									attributes: { name: 'bbx_router_condition_map_tod_min_day' }
								    }]
								},
								{
								    entity_template: 'input_item',
								    title: 'Caller:',
								    input_name: 'filter_type',
								    type: 'select',
								    select_options: [
									{ text: 'Any callers', value: '' },
									{ text: 'Caller'+entity.rsquo+'s number starts with', value: 'cid_start' },
									{ text: 'Caller'+entity.rsquo+'s number ends with', value: 'cid_end' },
									{ text: 'Caller'+entity.rsquo+'s number is', value: 'cid_exact' },
									{ text: 'Caller'+entity.rsquo+'s number matches a regular expression', value: 'cid_regex' }
								    ]
								},
								{
								    entity_templates: ['input_item','aopb_state'],
								    aopb_state: {
									closest: 'td',
									states: [{
									    conditions: { a: '$filter_type', op: '!=', b: '' },
									    actions: { enabled: true, _cascade: true },
									    else_actions: { enabled: false, _cascade: true }
									}]
								    },
								    input_item: {
									title: 'Match:',
									input_name: 'filter_value',
									type: 'text'
								    }
								}
							    ]
							},
							{
							    header: 'Action Set',
							    column: 'bbx_callflow_name',
							    sortable: false,
							    view_elements: [
								{
								    entity: 'div',
								    attributes: { name: 'bbx_callflow_name' }
								},
								{
								    entity: 'div',
								    attributes: { name: 'callflow_summary', 'class':'info' }
								}
							    ],
							    edit_elements: [
								{
								    entity_template: 'select_populate',
								    rest: '/gui/user/router/callflows',
								    before: [{ text: '(New Action Set)', value: '' }],
								    rest_data: ['bbx_router_id'],
								    only_rest_params: ['bbx_router_id'],
								    text_field: 'bbx_callflow_name',
								    value_field: 'bbx_callflow_id',
								    name: 'bbx_callflow_id',
								    dataEvents: {
									bind: { 
									    'callflow': '' 
									},
									allow_delete: true
								    }
								},
								{
								    // This works around BNPH-8041 -- the no-auto-fill is overruled by the aopbStateWidget
								    entity_templates: ['aopb_state', 'container'],
								    entity: 'span',
								    aopb_state: {
									closest: 'tr',
									states: [{
									    conditions: { a: '$bbx_callflow_id', op: '==', b: '' },
									    actions: { enabled: true, visible: true, _cascade: true },
									    else_actions: { enabled: false, visible: false, _cascade: true }
									}]
								    },
								    container: { elements: [
									{
									    entity: 'input',
									    attributes: {
										type: 'text',
										name: 'bbx_callflow_name',
										'class': 'no-auto-fill'
									    }
									}
								    ]}
								},
								{
								    entity: 'button',
								    attributes: { type: 'button' },
								    widgets: ['editCallflowButtonWidget','aopbStateWidget'],
								    widget_options: {
									editCallflowButtonWidget: {
									    overlay: 'fmfm_router_action_detail'
									},
									aopbStateWidget: {
									    closest: 'tr',
									    states: [{
										conditions: { a: '$bbx_callflow_id', op: '==', b: '' },
										actions: { visible: false },
										else_actions: { visible: true }
									    }]
									}
								    },
								    text: 'Edit'
								},
								{
								    entity: 'div',
								    attributes: { 'class': 'info' },
								    text: 'To add or edit action sets, use the '+entity.ldquo+'Manage Action Sets'+entity.rdquo+' button at the bottom of the table. To add a new action set, select '+entity.ldquo+'(New Action Set)'+entity.rdquo+' and enter a name.'
								}
							    ],
							    add_elements: [
								{
								    entity_template: 'select_populate',
								    rest: '/gui/user/router/callflows',
								    text_field: 'bbx_callflow_name',
								    value_field: 'bbx_callflow_id',
								    before: [{ text: '(New empty action set)', value: '' }],
								    name: 'bbx_callflow_id'
								},
								{
								    entity: 'div',
								    attributes: { 'class': 'info' },
								    text: 'To add or manage actions, use the '+entity.ldquo+'Manage Action Sets'+entity.rdquo+' button at the bottom of the table.'
								}
							    ]
							}
						    ] // END columns
						} // END DTW
	 				    ] // END page module elements
					}, // END page module
					{
					    entity_template: 'page_module',
					    legend: 'Default Actions',
					    buttons: [ 'help' ],
					    help_id: 'find_me_default_actions',
					    elements: [
						{
						    entity_template: 'data_item',
						    title: 'Default Action Set:',
						    elements: [
							{
							    entity_template: 'select_populate',
							    rest: '/gui/user/router/callflows',
							    only_rest_params: ['bbx_extension_id','bbx_router_id'],
							    text_field: 'bbx_callflow_name',
							    value_field: 'bbx_callflow_id',
							    name: 'bbx_callflow_id_fallback',
							    dataEvents: {
								bind: {
								    'callflow': '',
								    'rules_and_actions': ''
								},
								allow_delete: true
							    },
							    use_last_value: true,
							    default_first: false
							},
							{
							    entity: 'button',
							    attributes: { type: 'button' },
							    widgets: ['editCallflowButtonWidget'],
							    widget_options: { editCallflowButtonWidget: {
								overlay: 'fmfm_router_action_detail'
							    }},
							    text: 'Edit'
							},
							{
							    entity_template: 'overlay_button',
							    text: 'Manage Action Sets',
							    overlay: 'fmfm_router_actions',
							    pass_params: ['bbx_extension_id']
							},
							{
							    entity: 'div',
							    attributes: { 'class': 'info' },
							    text: 'If no rules match, this action will take place. To add or manage actions, use the '+entity.ldquo+'Manage Action Sets'+entity.rdquo+' button.'
							}
						    ]
						}
	 				    ]
					} // END page module "Default Actions"
				    ] // END container elements
				} // End container definition
			    } // End aopb_state+container
			],
			// End aopb_container elements for state: @router == _TRUEISH_
			else_elements: [

			    // Aopb_container elements for state @router != _TRUEISH_ -- show the "please save" message if they just selected "Active"

			    {
				entity_templates: ['aopb_state','page_module'],
				aopb_state: {
				    closest: '.cui-page-content',
				    states: [{
					conditions: { a: '$router', op: '==', b: '_TRUEISH_' },
					actions: { visible: true, enabled: true, _cascade: true },
					else_actions: { visible: false, enabled: false, _cascade: true }
				    }]
				},
				page_module: {
				    legend: 'Default Action Set',
				    buttons: ['help'],
				    help_id: 'find_me_default_action_set',
				    elements: [
					{
					    entity_template: 'input_item',
					    title: 'Default Action Set:',
					    info: 'To use an existing Action Set by default, select it here. Otherwise, a new Action Set will be created.',
					    type: 'select',
					    input_name: 'bbx_callflow_id_fallback',
					    input_options: {
						widgets: ['selectPopulateWidget'],
						widget_options: { selectPopulateWidget: {
						    rest: '/gui/user/router/callflows',
						    only_rest_params: ['bbx_extension_id','bbx_router_id'],
						    before: [{ text: '(New Action Set)', value: '' }],
						    text_field: 'bbx_callflow_name',
						    value_field: 'bbx_callflow_id'
						}}
					    }
					},
					{
					    entity_template: 'aopb_container',
					    closest: 'form',
					    states: [{
						conditions: { a: '$bbx_callflow_id_fallback', op: '!=', b: '_TRUEISH_' },
						elements: [{
						    entity_template: 'input_item',
						    input_name: 'bbx_callflow_name',
						    type: 'text',
						    title: 'Default Action Set Name:',
						    info: 'To set up this Action Set, use the ' + entity.ldquo + 'Edit' + entity.rdquo + ' button in the Default Action section, shown after clicking ' + entity.ldquo + 'Save' + entity.rdquo + '.',
						    input_options: {
							widgets: ['aopbStateWidget'],
							widget_options: { aopbStateWidget: {
							    states: [{
								conditions: { a: '$$', op: '==', b: '_TRUE_' },
								actions: { invalid: false },
								else_actions: { invalid: 'You must specify a name for the Action Set' }
							    }]
							}}
						    }
						}]
					    }]
					},
					{
					    entity: 'div',
					    attributes: { 'style':'text-align: center; padding-top: 1em' },
					    text: 'To continue editing Rules and Actions, click '+entity.ldquo+'Save'+entity.rdquo+' to activate Find-Me Forwarding.'
					}
				    ]
				}
			    }
			]
		    }]
		}
	    ]}
    ]
});
