/* jshint jquery: true, unused: vars */
/* global add_widget */
/*
  Fixed Multiplied Input Widget:

  Apply this to an INPUT field if the field needs to get/put values with a multiplier. For instance, a numeric input that requires milliseconds, but which
  is presented to the user in seconds.

  OPTIONS:

  {
    multiplier: 1 // The value which the user's input is to be multiplied by
  }

*/

(function( $ ){
	var fixedMultipliedInputWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			multiplier: 1
		},

		value_widget: true,
		set_value_widget: true,

		_beforeInit: function () {
			var self = this, $self = this.element;
			self.options.multiplier = isNaN(self.options.multiplier) ? 1 : Number(self.options.multiplier);
			$self.keyFilter({ allow_keys_regexp: '(NUM)?[0-9]', allow_shift: false });
		},

		_getWidgetValue: function () {
			var self = this, $self = this.element, value = $self.val();
			value = isNaN(value) ? NaN : Number(value);
			return self._wrapValue(value * self.options.multiplier);
		},

		setValue: function (v) {
			v = isNaN(v) ? 0 : Number(v);
			v = v / this.options.multiplier;
			$.ui.widget.prototype.setValue.apply(this, Array.prototype.splice.call(arguments, 0, 1, v));
		}

	});

	add_widget('fixedMultipliedInputWidget', fixedMultipliedInputWidget);
})(jQuery);
