/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/*
  optionalValueTextboxWidget: A widget that needs documentation. Apply to a DIV or other block element.
*/

(function( $ ){
	var optionalValueTextboxWidget = $.extend(true, {}, $.ui.widget.prototype, {
		value_widget: true,
		set_value_widget: true,
		options: {
		},

		_beforeInit: function () {
			var self = this, $self = this.element;
			self.options._$checkbox = $('<input type="checkbox" class="optional-value-checkbox" />');
			self.options._$textbox =  $('<input type="text" class="optional-value-textbox" />');

			$self.append($('<div>').append(self.options._$checkbox));
			$self.append($('<div>').append(self.options._$textbox));

			self._bind(self.options._$checkbox, 'click change', self._updateActiveState.bind(self));

			self._updateActiveState();
		},

		_updateActiveState: function () {
			var self = this, $self = this.element;
			if (self.options._$checkbox.is(':checked')) {
				self.options._$textbox.enable('no_value');
				self.options._checked = true;
			} else {
				self.options._$textbox.disable('no_value');
				self.options._checked = false;
			}

			$self.trigger('change');
		},

		setValue: function (v, name, no_change_event) {
			var self = this, $self = this.element;

			if (v === undefined || v === '' || v === null) {
				self.options._$checkbox.removeAttr('checked');
			} else {
				self.options._$checkbox.attr('checked', 'checked');
			}

			if (v === undefined) { v = ''; }
			self.options._$textbox.val(v);
			self._updateActiveState();
		},

		_getWidgetValue: function () {
			var self = this;
			if (self.options._checked) {
				return self._wrapValue(self.options._$textbox.val());
			} else {
				return self._wrapValue('');
			}
		}
	});

	add_widget('optionalValueTextboxWidget', optionalValueTextboxWidget);
})(jQuery);
