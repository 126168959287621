(function () {
    register_screen('firstrun_system', {
	elements: [
	    { entity_template: 'page_title', title: 'System Settings' },
	    { entity_template: 'page_buttons', buttons: [{ type: 'cancel', text: 'Reset Form' }, { type: 'save_continue', href: '#!/bps/firstrun_validate', text: 'Continue' }] },
	    {
		entity_template: 'page_content', elements: [
		    elgen('admin_password_page_module'),
		    {
			entity_template: 'page_module',
			legend: 'Area Code',
			buttons: [ 'help' ],
			help_id: 'area_code',
			form_action: '/gui/system/area_code',
			elements: [
			    elgen('area_code')
	 		]
		    },
		    elgen('auto_provision_page_module', { form_action: '/gui/system/phones' }),
		    elgen('event_reporting_page_module')
		] // END page_content elements
	    } // END page_content
	] // END template elements
    }); // END template
})(); // END scoping function
