if (!$) {
	throw new Error('chromeIsPlainObjectShim must be loaded AFTER jQuery');
}

(function () {

	// Tester/tester is not a plain object
	var Tester = function () {};
	Tester.prototype = {};
	var tester = new Tester;
	
	// But jQuery under Chrome 53 sometimes thinks it is
	if ($.isPlainObject(tester)) { // Returns false under Chrome >53
		console && console.warn && console.warn('This browser failed a test of $.isPlainObject. Using shim method instead.');

		var oldIsPlainObject = $.isPlainObject;

		// Bad Chrome! You get a shim!
		var newIsPlainObject = (function (obj) {
			if (!obj || obj.__proto__ === ({}).__proto__) {
				return oldIsPlainObject(obj);
			} else {
				return false;

			}
		}).bind($);

		// Sanity check
		if (newIsPlainObject(tester) || !newIsPlainObject({})) {
			console && console.warn && console.warn('chromeIsPlainObjectShim does not appear to be working properly.');
		}

		// Shim over top of the old $.isPlainObject
		$.isPlainObject = newIsPlainObject;
	}

})();
