/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){
	var destroyActionWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			no_initial_get: true,
			closest: 'pageModuleWidget'
		},

		_afterInit: function () {
			var self = this, $self = this.element;
			if (self.options.selector) {
				self._bind($self.closest(self.options.closest).find(self.options.selector), 'destroy', self._widgetDestroyHandler.bind(self));
			}
		},

		_destroy: function() {
			var self = this, $self = this.element;

			if (!$self.hasClass('stop-action')) {
				self._widgetDestroyHandler();
			}
		},

		_widgetDestroyHandler: function() {
			var self = this, $self = this.element, fields = {}, field;

			if (self.options.rest) {
				if (self.options.fields) {
					for (var key in self.options.fields) {
						field = self.options.fields[key];
						if (self.options.data && self.options.data[field]) {
							fields[field] = self.options.data[field];
						}
					}
				}
				if (self.options.rest_params) {
					for (field in self.options.rest_params) {
						fields[field] = self.options.rest_params[field];
					}
				}
				var method = (self.options.method ? self.options.method : 'POST');
				CUI.doREST(method, self.options.rest, fields, {});
			}
		},

		fillData: function(d, from_self) {
			var self = this, $self = this.element;
			self.options.data = d;
		}

	});

	add_widget('destroyActionWidget', destroyActionWidget);
})(jQuery);
