(function () {
    register_screen('statistical_reports_unavailable', {
	elements: [
	    {
		entity_template: 'page_title',
		title: 'Statistical Reports'
	    },
	    {
		entity_template: 'page_content',
		elements: [
		    {
			entity: 'div',
			text: 'Reporting data must be re-processed after a recent upgrade. Statistical reports will be unavailable until this process is complete. Refresh your browser and check again in a few minutes.',
			attributes: { 'class':'screen-unavailable-message' }
		    }
		]
	    }
	]
    });
})();
