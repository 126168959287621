/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, getUnique */
(function( $ ){
	var soundPickerWidget = $.extend({}, $.ui.widget.prototype, {
		value_widget: true,
		set_value_widget: true,

		manages_own_descendent_value: true,
		manages_own_descendent_state: true,

		options: {
			allow_none: true,
			template_html: '<span id="sound-picker-template"><select class="soundPickerCategory"></select><span class="soundPickerSoundsWrap"></span><span class="soundPickerPlayer"></span><span class="template"><span class="soundPickerHasNone">(There are no sound files on this Server.)</span></span></span>',
			rest : '/gui/sound/category_populated',
			rest_params : { _sortby: 'bbx_sound_category_name', _sortorder: 'asc' },
			rest_cache_time : 3000,
			rest_container : false,

			_disabled: false,
			_disabled_keys: {}
		},

		_beforeInit: function () {
			var self = this;
			self.options.uid = getUnique('uid');
		},

		disable: function (key) {
			var self = this, $self = this.element;
			var $category_select = $self.find('select.soundPickerCategory');
			var $picker_select   = $self.find('select.soundPickerSounds');
			var $player = $self.find('.soundPickerPlayer');

			key = key || 'NO_KEY';

			$category_select.disable(key);
			$picker_select.disable(key);
			$player.hide();

			self.options._disabled_keys[key] = true;
			self.options._disabled = true;

			$.ui.widget.prototype.disable.apply(this, arguments);
		},

		enable: function (key) {
			var self = this, $self = this.element;
			var $category_select = $self.find('select.soundPickerCategory');
			var $picker_select   = $self.find('select.soundPickerSounds');
			var $player = $self.find('.soundPickerPlayer');

			key = key || 'NO_KEY';

			$category_select.enable(key);
			$picker_select.enable(key);
			if ($picker_select.val()) {
				$player.show();
			}

			delete self.options._disabled_keys[key];

			if (!CUI.hasKeys(self.options._disabled_keys)) {
				self.options._disabled = false;
			}

			$.ui.widget.prototype.enable.apply(this, arguments);
		},

		_processStaticBody : function ($staticBody) {
			var self = this;
			var $self = this.element;

			var $selCat = $('select.soundPickerCategory', $staticBody);

			$selCat.on('change', function (e) {
				self._setSoundSelection($selCat.val());
			});

			return $staticBody;
		},

		_setSoundSelection : function(cat_id, sound_id) {
			var self = this, $self = self.element;

			if (self.options._built_category_data) {
				self._doSetSoundSelection(cat_id, sound_id);
			} else {
				$self.one('builtCategoryData', self._doSetSoundSelection.bind(self, cat_id, sound_id));
			}
		},

		_doSetSoundSelection: function (cat_id, sound_id) {
			// This will ALWAYS run after the category list is pulled (see the end of _setSoundSelection)
			var self = this, $self = this.element;

			if (!cat_id && sound_id) {
				var sound_id_category = self.options.categories_by_sound_id[sound_id];
				cat_id = sound_id_category ? sound_id_category.bbx_sound_category_id : '';
			}

			if (self.options.allow_none) {
				cat_id = cat_id || (self.options.categories[0] ? self.options.categories[0].bbx_sound_category_id : null) || 'NONE';
			} else if (self.options.categories[0]) {
				cat_id = cat_id || self.options.categories[0].bbx_sound_category_id;
			} else {
				var $hasNone = self.options.$template.find('.soundPickerHasNone').clone();
				$self.html($hasNone);
				$self.addClass('is_invalid');
			}

			var $sounds = $('<select class="soundPickerSounds"></select>');
			var catSounds = cat_id ? (self.options.categories_by_id[cat_id].bbx_sound_ids || []) : [];

			if (self.options._disabled) {
				for (var d_k in self.options._disabled_keys) {
					$sounds.disable(d_k);
				}
			}

			for (var i=0; i<catSounds.length; i++) {
				var sound = catSounds[i];
				var sid = catSounds[i].bbx_sound_id === 'NONE' ? '' : catSounds[i].bbx_sound_id;

				$sounds.append(
					$('<option></option>')
					.val(sid)
					.text(sound.bbx_sound_name)
				);
			}

			$('.soundPickerCategory', $self).val(cat_id);

			if (sound_id) {
				$sounds.val(sound_id);
			}

			$('.soundPickerSoundsWrap', $self)
			.empty()
			.append($sounds);

			var $playerWrap = $('.soundPickerPlayer', $self).css('display', '');

			// If this is falsy, then they picked "none"
			if ($sounds.val()) {
				if ($playerWrap.hasClass('widgetized')) {
					$playerWrap.soundPlayerWidget('setMedia', '/gui/sound_file/playback', { bbx_sound_id: $sounds.val() });
				} else {
					$playerWrap.addClass('soundPlayerWidget').soundPlayerWidget({
						size: 'full',
						allow_download: true,
						url: '/gui/sound_file/playback',
						params: {
							'bbx_sound_id' : $sounds.val()
						}
					});
				}
			} else {
				$playerWrap.hide();
			}

			if (self.options._disabled) { $playerWrap.hide(); }

			$sounds.on('change', function () {
				if ($sounds.val()) {
					$playerWrap.soundPlayerWidget('setMedia', '/gui/sound_file/playback', { bbx_sound_id: $sounds.val() });
				} else {
					// $playerWrap.hide();
				}
			});

			$self.trigger('change');

		}, // END INTERNAL FUNCTION doSSS

		_afterInit : function () {
			var self = this;
			var $self = self.element;

			if (!self.options._has_been_set) {
				self._setSoundSelection();
			}
		},

		_buildCategoryData: function (cat_data) {
			var self = this, cat, cat_idx, snd, snd_idx;
			self.options.categories = cat_data;
			self.options.categories_by_id = {};
			self.options.categories_by_sound_id = {};

			if (self.options.allow_none) {
				var noneCat = {
					bbx_sound_ids: [
						{
							bbx_sound_name: '(No sound selected)',
							bbx_sound_id: 'NONE'
						}
					],
					bbx_sound_category_id: 'NONE',
					bbx_sound_category_name: '(None)'
				};

				self.options.categories.unshift(noneCat);
			}

			for (cat_idx = 0; cat_idx < cat_data.length; cat_idx++) {
				cat = cat_data[cat_idx];
				self.options.categories_by_id[cat.bbx_sound_category_id] = cat;
				for (snd_idx = 0; snd_idx < cat.bbx_sound_ids.length; snd_idx++) {
					snd = cat.bbx_sound_ids[snd_idx];
					self.options.categories_by_sound_id[snd.bbx_sound_id] = cat;
				}
			}
		},

		_buildCategoriesSelect: function (categories) {
			var self = this, $self = this.element, cat, cat_idx;
			self._buildCategoryData(categories);

			var $cats = $('.soundPickerCategory', $self).empty();
			for (cat_idx=0; cat_idx < self.options.categories.length; cat_idx++) {
				cat = self.options.categories[cat_idx];
				$cats.append($('<option />').val(cat.bbx_sound_category_id).text(cat.bbx_sound_category_name || '(Untitled)'));
			}
			self.options._built_category_data = true;
			$self.trigger('builtCategoryData');
		},

		fillData: function (d, fromSelf) {
			var self = this;
			var $self = self.element;

			if (fromSelf) {
				self._buildCategoriesSelect(d.category_populated);
			}

			$.ui.widget.prototype.fillData.apply(this, arguments);
		},

		setValue: function (val) {
			var self = this, $self = self.element;
			// _setSoundSelection may defer until the category is loaded!

			self.options._has_been_set = true;
			self._setSoundSelection(undefined, val);
		},

		_getWidgetValue: function () {
			var self = this, $self = this.element;
			if (self.options._disabled) {
				return {};
			} else {
				return this._wrapValue($self.find('select.soundPickerSounds').val());
			}
		},

		_updateState: function (e) {
			var self = this;
			var $self = self.element;
			$self.trigger("change");
		}
	});

	add_widget('soundPickerWidget', $.extend({}, $.ui.widget.prototype, soundPickerWidget));
})(jQuery);
