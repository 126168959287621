/* jshint jquery: true */
// Tailor jQuery to our liking

jQuery.ajaxSettings.traditional = true; // Use "traditional" array-param mode instead of new PHP-friendly x[] mode

// set some AJAX defaults globally
$.ajaxSetup({
    cache: false,
    type: 'POST',
    dataType: 'text',
    timeout: 10 * 60 * 1000
});

