(function () {
    register_screen('report_view', {
	rest: '/gui/reporting/report',
	elements: [
	    {
		entity_template: 'format',
		entity: 'h2',
		'class':'page_title',
		format: '$1',
		fields: [{ field: 'bbx_reporting_name' }]
	    },
	    {
		entity_template: 'page_buttons',
		buttons: [
		    {
			type: 'simple',
			text: 'Download (CSV)',
			options: {
			    widgets: ['linkButtonWidget', 'dirtyPageButtonWidget'],
			    widget_options: { linkButtonWidget: {
				include_rest_params: ['bbx_reporting_id'],
				include_token: true,
				href: '/gui/reporting/csv'
			    }}
			}
		    },
		    { "type" : "fullscreen" },
		    {
			type: 'back',
			text: 'Back'
		    }
		]
	    },
	    {
		entity_template: 'page_content', elements: [
		    {
			entity: 'div',
			widgets: ['reportingTableWidget']
		    }
		] // END page content elements
	    } // END page content
	] // END page elements
    }); // END screen def
})();
