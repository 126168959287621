/* ****************************************************************************************************************************************
 *
 *  WARNING:  changes to this screen def may affect the first run screen: firstrun_network.js , please test any changes on both screens.
 *
 ****************************************************************************************************************************************** */

register_screen('network', {
    elements: [
		{ entity_template: 'page_title', title: 'Network' },
		{ entity_template: 'page_buttons',
		  buttons: [{ type: 'cancel' }, { type: 'save' }]
		},
		{ entity_template: 'page_content',
		  elements: [ {
			  entity_template: 'form',
			  dialog_during_submit: {
				  buttons: [],
				  title: 'Saving Network..',
				  text: 'Please wait while the network changes are applied...',
				  progress: 'true'
			  },

			  rest: '/gui/system/network',
			  method: 'POST',

			  rest_container: 'network',

			  // This gets DELETED in firstrun_network screendef-- if this position changes, be sure to update that.
			  confirm: {
				  title: 'Change Network Settings Now?',
				  text: 'Applying Network settings may drop calls that are currently in progress.',
				  buttons: ['Apply Changes', 'Cancel']
			  },
			  /*	      widgets: [ 'aopbStateWidget' ],
						  widget_options: {
						  aopbStateWidget: {
						  closest: 'form',
						  states: [
						  {
						  conditions: { a: '@ha_self_ip', op: '!=', b: '_TRUEISH_'},
						  actions: { enabled: true, _cascade: true },
						  else_actions: { enabled: false, _cascade: true }
						  }
						  ]
						  }
						  },*/
			  elements: [

				  {
					  entity_template: 'page_module',
					  legend: 'Settings',
					  buttons: ['help'],
					  help_id: 'configuration_network_general_settings',
					  elements: [
						  {
							  entity_template: 'input_item',
							  type: 'text',
							  input_name: 'system_default_hostname',
							  title: 'Hostname:',
							  input_options: {
								  widgets: ['aopbStateWidget' ],
								  widget_options: {
									  aopbStateWidget: {
										  states: [
											  {
												  conditions: { a: '$$', op: '==', b: '^[-a-zA-Z0-9]+$', b_is: 'regexp' },
												  actions: { invalid: false },
												  else_actions: { invalid: 'Invalid Hostname.' }
											  }
										  ]
									  }
								  }
							  }
						  }, // END
						  {
							  entity_template: 'input_item',
							  type: 'text',
							  input_name: 'system_default_domain',
							  title: 'Domain:',
							  input_options: {
								  attributes: { maxlength: '253'},
								  widgets: ['aopbStateWidget' ],
								  widget_options: {
									  aopbStateWidget: {
										  states: [
											  {
												  conditions: { a: '$$', op: '==', b: '^[a-zA-Z0-9]([.a-zA-Z0-9-]*[.a-zA-Z0-9]|\.?)$', b_is: 'regexp' },
												  actions: { invalid: false },
												  else_actions: { invalid: 'Invalid Domain.' }
											  }
										  ]
									  }
								  }
							  }
						  }, // END
						  {
							  entity_template: 'input_item',
							  type: 'text',
							  input_name: 'system_primary_dns_server',
							  title: 'Primary DNS Server:',
							  input_options: {
								  widgets: ['aopbStateWidget' ],
								  widget_options: {
									  aopbStateWidget: {
										  states: [
											  {
												  conditions: { a: '$$', op: '==', b: '_IP_V4_' },
												  actions: { invalid: false },
												  else_actions: { invalid: 'Invalid Primary DNS Server Address.' }
											  }
										  ]
									  }
								  }
							  }
						  }, // END
						  {
							  entity_template: 'input_item',
							  type: 'text',
							  input_name: 'system_secondary_dns_server',
							  title: 'Secondary DNS Server:',
							  input_options: {
								  widgets: ['aopbStateWidget' ],
								  widget_options: {
									  aopbStateWidget: {
										  states: [
											  {
												  conditions: { a: '$$', op: '==', b: '_IP_V4_' },
												  actions: { invalid: false },
												  else_actions: { invalid: 'Invalid Secondary DNS Server IP Address.' }
											  }
										  ]
									  }
								  }
							  }
						  }, // END
						  {
							  entity_template: 'input_item',
							  type: 'checkbox',
							  input_name: 'no_verify_dns',
							  title: 'Skip DNS Verification:',
							  info: 'Select this option if your configured DNS servers cannot currently be reached (for instance, if you are configuring the server before it gets connected to the network). This will skip the test to ensure they are valid and reachable.'
						  } // END
					  ]
				  }, // END page module 1 (Settings)
				  {
					  entity_template: 'page_module',
					  legend: 'LAN Interface',
					  buttons: ['help'],
					  help_id: 'configuration_network_lan_interface',
					  elements: [
						  {
							  entity_template: 'input_item',
							  type: 'text',
							  input_name: 'system_ip',
							  title: 'IP Address:',
							  input_options: {
								  widgets: ['aopbStateWidget' ],
								  widget_options: {
									  aopbStateWidget: {
										  states: [
											  {
												  conditions: { a: '$$', op: '==', b: '_IP_V4_' },
												  actions: { invalid: false },
												  else_actions: { invalid: 'Invalid LAN IP Address.' }
											  }
										  ]
									  }
								  }
							  }
						  }, // END
						  {
							  entity_template: 'input_item',
							  type: 'text',
							  input_name: 'system_netmask',
							  title: 'Subnet Mask:',
							  input_options: {
								  widgets: ['aopbStateWidget'],
								  widget_options: {
									  states: {
										  conditions: [
											  { a: '$$', op: '==', b: '_IP_V4_MASK_' }
										  ],
										  actions: { invalid: false },
										  else_actions: { invalid: 'Invalid LAN Interface Subnet Mask' }
									  }
								  }
							  }
						  }, // END
						  {
							  entity_template: 'input_item',
							  type: 'text',
							  input_name: 'system_gateway',
							  title: 'Gateway:',
							  input_options: {
								  widgets: ['aopbStateWidget' ],
								  widget_options: {

									  aopbStateWidget: {
										  states: [
											  {
												  conditions: [
													  { a: '$wan_enabled', op: '==', b: '_TRUEISH_' },
													  { join: 'and', a: '$system_force_internal', op: '!=', b: '_TRUEISH_' }
												  ],
												  actions: { enabled: false },
												  else_actions: { enabled: true }
											  },
											  {
												  conditions: { a: '$$', op: '==', b: '_IP_V4_' },
												  actions: { invalid: false },
												  else_actions: { invalid: 'Invalid LAN Gateway IP Address.' }
											  }
										  ]
									  }

								  }
							  }
						  }, // END
						  {
							  entity_template: 'input_item',
							  type: 'checkbox',
							  input_name: 'system_force_internal',
							  title: 'Use LAN as Default Gateway:',
							  input_options: {
								  widgets: ['aopbStateWidget'],
								  widget_options: {

									  aopbStateWidget: {
										  closest: 'form',
										  states: [
											  {
												  conditions: { a: '$wan_enabled', op: '==', b: '_TRUEISH_' },
												  actions: { enabled: true },
												  else_actions: { enabled: false }
											  }
										  ]
									  }

								  }
							  }
						  }, // END
						  {
							  entity_template: 'input_item',
							  type: 'checkbox',
							  input_name: 'lan_behind_nat',
							  title: 'Behind NAT Router:'
						  }, // END
						  {
							  entity_template: 'input_item',
							  type: 'text',
							  input_name: 'external_inet_ip',
							  title: 'External IP Address:',
							  input_options: {
								  widgets: ['aopbStateWidget'],
								  widget_options: {

									  aopbStateWidget: {
										  closest: '.page_module_body',
										  states: [
											  {
												  conditions: { a: '$lan_behind_nat', op: '==', b: '_TRUEISH_' },
												  actions: { enabled: true },
												  else_actions: { enabled: false }
											  },
											  {
												  conditions: { a: '$$', op: '==', b: '_IP_V4_' },
												  actions: { invalid: false },
												  else_actions: { invalid: 'Invalid LAN External IP Address.' }
											  }
										  ]
									  }
								  }
							  }
						  },
						  {
							  entity_template: 'data_item',
							  title: ' ',
							  elements: [
								  {
									  entity_template: 'fill_data_button',
									  closest: '.page_module',
									  field_map: [{ ip: 'external_inet_ip' }],
									  include_data_as: { system_ip: 'ip' },
									  rest: '/gui/system/stun',
									  method: 'GET',
									  rest_container: false,
									  text: 'Auto-Detect External IP Address',
									  widgets: ['aopbStateWidget'],
									  widget_options: {
										  aopbStateWidget: {
											  closest: '.page_module_body',
											  states: [{
												  conditions: { a: '$lan_behind_nat', op: '==', b: '_TRUEISH_' },
												  actions: { enabled: true },
												  else_actions: { enabled: false }
											  }]
										  }
									  }
								  }
							  ]
						  }
					  ]
				  }, // END page module 2 (LAN)
				  {
					  entity_template: 'page_module',
					  legend: 'WAN Interface',
					  help_id: 'configuration_network_wan_interface',
					  buttons: ['help'],
					  elements: [
						  {
							  entity_template: 'aopb_state',
							  'class': 'additionalMessages',
							  attributes: { hidden: 'hide-on-load' },
							  html: '<span class="general">Disabling the WAN interface requires the LAN gateway to be configured in the section above.</span>',
							  states: [{
								  conditions: [
									  { a: '$wan_enabled', op: '==', b: '_FALSEISH_' },
									  { join: 'and', a: '$system_gateway', op: '!=', b: '_IP_V4_' },
									  { join: 'and', a: '@wan_enabled', op: '==', b: '_TRUEISH_' }

								  ],
								  actions: { visible: true },
								  else_actions: { visible: false }
							  }]
						  },
						  {
							  entity_template: 'input_item',
							  type: 'checkbox',
							  input_name: 'wan_enabled',
							  title: 'WAN Interface Enabled:'
						  }, // END
						  {
							  entity: 'div',
							  widgets: [ 'containerWidget', 'aopbStateWidget' ],
							  widget_options: {
								  aopbStateWidget: {
									  closest: '.page_module_body',
									  states: {
										  conditions: { a: '$wan_enabled', op: '==', b: '_TRUE_' },
										  actions: { enabled: true, _cascade: true },
										  else_actions: { enabled: false, _cascade: true }
									  }
								  },
								  containerWidget: {
									  elements: [
										  {
											  entity_template: 'input_item',
											  type: 'text',
											  input_name: 'wan_ip',
											  title: 'IP Address:',
											  input_options: {
												  widgets: ['aopbStateWidget'],
												  widget_options: {
													  aopbStateWidget: {
														  states: [
															  {
																  conditions: { a: '$$', op: '!=', b: '_IP_V4_' },
																  actions: { invalid: 'Invalid WAN IP Address entered.' },
																  else_actions: { invalid: false },
																  last_if_true: true
															  },
															  {
																  conditions: [
																	  { a: '$$', op: '==', b: '$system_ip' }
																  ],
																  actions: { invalid: 'WAN IP cannot be the same as LAN IP' },
																  else_actions: { invalid: false },
																  last_if_true: true
															  }
														  ]
													  }
												  }
											  }
										  }, // END
										  {
											  entity_template: 'input_item',
											  type: 'text',
											  input_name: 'wan_netmask',
											  title: 'Subnet Mask:',
											  input_options: {
												  widgets: ['aopbStateWidget'],
												  widget_options: {
													  aopbStateWidget: {
														  states: [
															  {
																  conditions: { a: '$$', op: '==', b: '_IP_V4_MASK_' },
																  actions: { invalid: false },
																  else_actions: { invalid: 'Invalid WAN Subnet Mask entered.' }
															  }
														  ]
													  }
												  }
											  }
										  }, // END
										  {
											  entity_template: 'input_item',
											  type: 'text',
											  input_name: 'wan_gateway',
											  title: 'Gateway:',
											  input_options: {
												  widgets: ['aopbStateWidget'],
												  widget_options: {
													  aopbStateWidget: {
														  closest: '.formWidget',
														  states: [
															  {
																  conditions: { a: '$$', op: '==', b: '_IP_V4_' },
																  actions: { invalid: false },
																  else_actions: { invalid: 'Invalid WAN Gateway Address entered.' }
															  },
															  {
																  conditions: { a: '$system_force_internal', op: '==', b: '_TRUEISH_' },
																  actions: { enabled: false },
																  else_actions: { enabled: true }
															  }
														  ]
													  }
												  }
											  }
										  }, // END
										  {
											  entity_template: 'input_item',
											  type: 'checkbox',
											  input_name: 'wan_behind_nat',
											  title: 'Behind NAT Router'
										  }, // END
										  {
											  entity: 'div',
											  widgets: [ 'containerWidget', 'aopbStateWidget' ],
											  widget_options: {
												  aopbStateWidget: {
													  closest: '.page_module_body',
													  states: {
														  conditions: { a: '$wan_behind_nat', op: '==', b: '_TRUE_' },
														  actions: { enabled: true, _cascade: true },
														  else_actions: { enabled: false, _cascade: true }
													  }

												  },
												  containerWidget: {
													  elements: [
														  {
															  entity_template: 'input_item',
															  type: 'text',
															  input_name: 'external_wan_ip',
															  title: 'External IP Address',
															  input_options: {
																  widgets: ['aopbStateWidget'],
																  widget_options: {
																	  aopbStateWidget: {
																		  closest: '.page_module_body',
																		  states: [
																			  {
																				  conditions: { a: '$wan_behind_nat', op: '==', b: '_TRUEISH_' },
																				  actions: { enabled: true },
																				  else_actions: { enabled: false },
																				  last_if_false: true
																			  },
																			  {
																				  conditions: { a: '$$', op: '==', b: '_IP_V4_' },
																				  actions: { invalid: false },
																				  else_actions: { invalid: 'Invalid WAN External IP Address entered.' }
																			  }
																		  ]
																	  }
																  }
															  }
														  }, // END
														  {
															  entity_template: 'data_item',
															  title: ' ',
															  elements: [
																  {
																	  entity_template: 'fill_data_button',
																	  closest: '.page_module',
																	  field_map: [{ ip: 'external_wan_ip' }],
																	  include_data_as: { wan_ip: 'ip' },
																	  rest: '/gui/system/stun',
																	  method: 'GET',
																	  rest_container: false,
																	  text: 'Auto-Detect External IP Address',
																	  widgets: ['aopbStateWidget'],
																	  widget_options: {
																		  aopbStateWidget: {
																			  closest: '.page_module_body',
																			  states: [{
																				  conditions: [
																					  {              a: '@wan_enabled', op: '==', b: '_TRUEISH_' },
																					  { join: 'and', a: '$wan_behind_nat', op: '==', b: '_TRUEISH_' },
																					  { join: 'and', a_is: 'element', a: '[name=wan_enabled].is-dirty,[name=wan_ip].is-dirty,[name=wan_netmask].is-dirty, [name=wan_gateway].is-dirty', op: '!=', b:'_ARRAY_HAS_ELEMENTS_' }
																				  ],
																				  actions: { enabled: true },
																				  else_actions: { enabled: false }
																			  }]
																		  }
																	  }
																  }
															  ]
														  }
													  ]
												  }
											  }
										  } // END container/cascading enable-disable
									  ]
								  }
							  }
						  } // END containing DIV
					  ]
				  }, // END page module 3 (WAN)
				  {
					  entity_template: 'page_module',
					  legend: 'Web Interface HTTP/HTTPS Ports',
					  buttons: ['help'],
					  help_id: 'configuration_network_http_admin_ports',
					  elements: [
						  {
							  entity_template: 'input_item',
							  type: 'text',
							  title: 'HTTP Web Interface Port:',
							  input_name: 'http_port',
							  info: 'Changing this value may require anyone using the Web interface to change bookmarks or URL to indicate the new port number',
							  input_options: {
								  widgets: ['aopbStateWidget'],
								  widget_options: { aopbStateWidget: {
									  states: [
										  {
											  conditions: [
												  {             a: '$$', op: '==', b: '^(1023|49151)$', b_is: 'regexp' },               // IANA port reservations (according to Wikipedia)
												  { join: 'or', a: '$$', op: '==', b: '^(21|22|53|69|123|843|7838)$', b_is: 'regexp' }, // BPS port reservations (from admin guide)
												  { join: 'or', a: '$$', op: '==', b: '5060-5070', b_is: 'range' },                     // SIP port range
												  { join: 'or', a: '$$', op: '==', b: '16384-32768', b_is: 'range' }                    // RTP port range
											  ],
											  actions: { invalid: 'This port is in use by other services' },
											  last_if_true: true
										  },
										  {
											  conditions: [
												  {              a: '$$', op: '==', b: '^[0-9]+$', b_is: 'regexp' }, // 1+ digits
												  { join: 'and', a: '$$', op: '==', b: '1-65535', b_is: 'range' }    // Number in range
											  ],
											  actions: { invalid: false },
											  else_actions: { invalid: 'Enter a port number from 1-65535' }
										  }
									  ]
								  }},
								  attributes: { maxlength: '5', size: '5' }
							  }
						  },
						  {
							  entity_template: 'input_item',
							  type: 'text',
							  title: 'HTTPS (Secure) Web Interface Port:',
							  input_name: 'https_port',
							  info: 'Changing this value may require anyone using the secure Web interface (HTTPS) to change bookmarks or URL to indicate the new port number',
							  input_options: {
								  widgets: ['aopbStateWidget'],
								  widget_options: { aopbStateWidget: {
									  states: [
										  {
											  conditions: [
												  {             a: '$$', op: '==', b: '^(1023|49151)$', b_is: 'regexp' },               // IANA port reservations (according to Wikipedia)
												  { join: 'or', a: '$$', op: '==', b: '^(21|22|53|69|123|843|7838)$', b_is: 'regexp' }, // BPS port reservations (from admin guide)
												  { join: 'or', a: '$$', op: '==', b: '5060-5070', b_is: 'range' },                     // SIP port range
												  { join: 'or', a: '$$', op: '==', b: '16384-32768', b_is: 'range' },                   // RTP port range
												  { join: 'or', a: '$$', op: '==', b: '$http_port' }                                    // Same as the other
											  ],
											  actions: { invalid: 'This port is in use by other services' },
											  last_if_true: true
										  },
										  {
											  conditions: [
												  {              a: '$$', op: '==', b: '^[0-9]+$', b_is: 'regexp' }, // 1+ digits
												  { join: 'and', a: '$$', op: '==', b: '1-65535', b_is: 'range' }    // Number in range
											  ],
											  actions: { invalid: false },
											  else_actions: { invalid: 'Enter a port number from 1-65535' }
										  }
									  ]
								  }},
								  attributes: { maxlength: '5', size: '5' }
							  }
						  }
					  ]
				  },
				  {
					  entity_template: 'page_module',
					  legend: 'HTTP Proxy',
					  buttons: ['help'],
					  help_id: 'configuration_network_http_proxy',
					  elements: [

						  {
							  entity_template: 'input_item',
							  type: 'text',
							  input_name: 'update_proxy_host',
							  title: 'HTTP Proxy Server Address:'
						  }, // END
						  {
							  entity_template: 'input_item',
							  type: 'text',
							  input_name: 'update_proxy_port',
							  title: 'Port:',
							  input_options: {
								  attributes: { size: 5 },
								  widgets: ['aopbStateWidget'],
								  widget_options: {
									  aopbStateWidget: {
										  states: [
											  {
												  conditions: { a: '$$', op: '==', b: '0-65535' },
												  actions: { invalid: false },
												  else_actions: { invalid: 'Not a valid HTTP Proxy Port' }
											  }
										  ]
									  }
								  }
							  }
						  }, // END
						  {
							  entity_template: 'input_item',
							  type: 'text',
							  input_name: 'update_proxy_user',
							  title: 'Username:'
						  }, // END
						  {
							  entity_template: 'input_item',
							  type: 'password',
							  input_name: 'update_proxy_password',
							  title: 'Password:'
						  }// END
					  ]
				  }, // END page module 5 (Proxy)
				  {
					  entity_template: 'page_module',
					  legend: 'Static Routes',
					  buttons: ['help'],
					  help_id: 'configuration_network_static_routes',
					  always_submit: true,
					  elements: [
						  {
							  entity_template: 'aopb_state',
							  'class': 'additionalMessages',
							  attributes: { hidden: 'hide-on-load' },
							  watch_form_dirty: true,
							  html: '<span class="general">Use the '+entity.ldquo+'Save'+entity.rdquo+' button at the top of the page to apply your changes to this table.</span>',
							  closest: '.pageModuleWidget',
							  states: [{
								  conditions: { a: '[name=static_routes]', a_is: 'element', op: '==', b: '.is-dirty', b_is: 'jq_is' },
								  actions: { visible: true },
								  else_actions: { visible: false }
							  }]
						  },
						  {
							  entity_template: 'data_table',
							  name: 'static_routes',
							  data_source: 'ROW_SOURCE',
							  click_action: 'edit',
							  paginate: false,
							  length_change: false,
							  filtering: false,
							  processing: false,
							  accept_data_from_parent: true,
							  submit_only_dirty: false,
							  oLanguage: {
								  sZeroRecords: 'No static routes.'
							  },
							  columns: [
								  {
									  header: 'Subnet Address',
									  row_source: 'subnet_ip',
									  edit_element: {
										  entity: 'input',
										  attributes: {
											  name: 'subnet_ip',
											  type: 'text',
											  placeholder: 'Subnet Address'
										  },
										  widgets: [ 'aopbStateWidget' ],
										  widget_options: {
											  aopbStateWidget: {
												  closest: 'td',
												  states: [{
													  conditions: { a: '$$', op: '==', b: '_IP_V4_' },
													  actions: { invalid: false },
													  else_actions: { invalid: 'Invalid Subnet Address' }
												  }]
											  }
										  }
									  }
								  },
								  {
									  header: 'Subnet Mask',
									  row_source: 'subnet_mask',
									  edit_element: {
										  entity: 'input',
										  attributes: {
											  name: 'subnet_mask',
											  type: 'text',
											  placeholder: 'Subnet Mask'
										  },
										  widgets: [ 'aopbStateWidget' ],
										  widget_options: {
											  aopbStateWidget: {
												  closest: 'td',
												  states: [{
													  conditions: { a: '$$', op: '==', b: '_IP_V4_MASK_' },
													  actions: { invalid: false },
													  else_actions: { invalid: 'Invalid Subnet Mask' }
												  }]
											  }
										  }
									  }
								  },
								  {
									  header: 'Gateway Address',
									  row_source: 'subnet_gateway',
									  edit_element: {
										  entity: 'input',
										  attributes: {
											  name: 'subnet_gateway',
											  type: 'text',
											  placeholder: 'Gateway Address'
										  },
										  widgets: [ 'aopbStateWidget' ],
										  widget_options: {
											  aopbStateWidget: {
												  closest: 'td',
												  states: [{
													  conditions: { a: '$$', op: '==', b: '_IP_V4_' },
													  actions: { invalid: false },
													  else_actions: { invalid: 'Invalid Gateway Address' }
												  }]
											  }
										  }

									  }
								  }
							  ],
							  row_actions: {
								  animate: false,
								  animate_speed: 'slow',
								  type: 'expand',
								  edit_save: { text: 'Save' },
								  edit_cancel: { text: 'Cancel' },
								  add_save: { text: 'Add' },
								  add_cancel: { text: 'Cancel' },
								  delete_row: {
									  text: 'Delete',
									  confirm: {
										  title: 'Delete Route',
										  text: 'Are you sure you want to remove the static route?'
									  }
								  }
							  },
							  table_actions: {
								  add_row: {
									  text: 'Add Static Route',
									  location: 'bottom'
								  },
								  delete_rows: {
									  text: 'Delete Selected',
									  location: 'bottom',
									  confirm: {
										  title: 'Delete Selected',
										  text: 'Are you sure you want to remove the selected static routes?'
									  }
								  },
								  select_row: true,
								  select_all_rows: true
							  }
						  }
					  ]
				  } // END page module 6 (Static Routes)
			  ]
		  } ]
		}
    ]
});
