/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, debugLog */
/*

NOTES:

This is a superclass to allow performing actions on the widget via a simple key/value object

When subclassing, additional actions may be added to the "_actions" object property.

The setState function (of widget base) is not run on this widget unless no other widgets are applied to the object. Otherwise, the first widget in the
$self.getCUIWidgets() array that is not this widget has the setting applied. Generally speaking, this should not be a primary (first-in-list) widget unless it
is the only widget. To override this behavior in subclassed widgets, set the _prefer_own_widget property in the subclass to true.

OVERRIDABLE PROPERTIES:
_prefer_own_widget: false | true  // Determines whether or not state change actions are done on this widget or the next if there are multiple widgets on
				  // the object.

Actions may be added in subclasses by extending the "_actions" object.


USAGE (in subclass):

self._perform_state_actions( {
    action_name: action_param,
    action_name: action_param,
    ...
} );

Any property of an action/else_action object that is named with a leading underscore (_xxx) is ignored (unless an action explicity exists for it, though that
is not recommended). This allows for extra "parameters" in the action object that the action method can read. In the default action set, these are:

_cascade:   false | true // Some state changes may be "cascadable", in that the element and all its child elements change state (this is useful for disabling
			 // an entire section of a form, for instance.

_valid_key: 'unique-id'  // This option should be set if there are multiple action/else-action sets that have the "invalid" action. The unique ID makes it so
			 // if one action causes a valid state, an invalid state from another action is not cleared. A matching _valid_key must be included in
			 // both the "action" and "else_action" objects. Key states are maintained on the widget.

*/

(function( $ ){
	var stateWidget = $.extend(true, {}, $.ui.widget.prototype, {
		_prefer_own_widget: false,

		options: {
			state_count: { /* state_name: count, ... */ } // "refcounting" for states that must all be cleared in order to actually be cleared
		},

		setState: function () {
			var self = this, $self = this.element, fn_args = arguments, widgets = this.getAllWidgets(), val;

			if (self._prefer_own_widget || widgets.length === 1) {
				val = $.ui.widget.prototype.setState.apply(self, fn_args);
			} else if (widgets[0] !== self) {
				val = widgets[0].setState.apply(widgets[0], fn_args);
			} else {
				val = widgets[1].setState.apply(self, fn_args);
			}
			$self.trigger('stateChange');
			return val;
		},

		_actions: {
			'_default' : function (name, param, obj) {
				// The default action just passes the state change to widget.setState. This includes "visible" and "enabled" states.
				this.setState(name, param, obj._cascade, true);
			},

			'invalid': function (param, obj) {
				var self = this, $self = this.element, key = obj._invalid_key;
				if (param) {
					CUI.setInvalid($self, param, key || self.options.widget_id);
				} else {
					CUI.setValid($self, key || self.options.widget_id);
				}
			},
			'addClass': function (param) {
				var self = this, $self = this.element;
				$self.addClass(param);
			},
			'removeClass': function (param) {
				var self = this, $self = this.element;
				$self.addClass(param);
			},
			'css': function (param) {
				var self = this, $self = this.element;
				$self.css(param);
			},
			'color': function (param) {
				var self = this, $self = this.element;
				$self.css({ color: param });
			},
			'value': function (param) {
				var self = this, $self = this.element;
				self.setValue(param, undefined, true);
			},
			'text': function (param) {
				var self = this, $self = this.element;
				$self.text(param);
			},
			'html': function (param) {
				var self = this, $self = this.element;
				$self.html(param);
			},
			'debug_log': function (param) {
				var self = this, $self = this.element;
				debugLog('DebugLog from stateWidget:', param, ' -- ', $self);
			},
			'attr': function (param) {
				var $self = this.element, match;

				if (param.indexOf(':') < 1) { // Position 0 is not allowed either
					debugLog('jquery.stateWidget.js: "attr" state parameter did not recieve a value -- ', $self);
					return;
				}

				match = param.match(/^([^:]+):(.*)/);
				if (match[2] === 'false') {
					$self.attr(match[1], false);
				} else {
					$self.attr(match[1], match[2]);
				}
			}
		},

		_perform_state_actions: function (all_actions) {
			var self = this, $self = this.element;
			if ($.isPlainObject(all_actions)) {
				all_actions = [all_actions];
			}

			for (var i=0; i<all_actions.length; i++) {
				var action_set = all_actions[i];
				actions: for (var k in action_set) {
					if (self._actions[k]) {
						self._actions[k].call(self, action_set[k], action_set);
					} else if (k.charAt(0) == '_') {
						// Underscore-prefixed properties are options, not actions
						continue;
					} else {
						self._actions._default.call(self, k, action_set[k], action_set);
					}
				}
			}
			$self.trigger('stateChange');
		}
	});

	add_widget('stateWidget', stateWidget);
})(jQuery);
