register_screen('sound-category-detail', {
    "elements" : [
	{ "entity_template" : "page_title", "title" : "" },
	{ "entity_template" : "page_buttons" },
	{ "entity_template" : "page_content", "elements" : [
	    {
		"entity_template" : "page_module",
		"legend" : "Sound Category Details",
		"form_action" : "/gui/sound/category",
		"buttons" : [ "save", "cancel" ],
		"elements" : [
		    {
			"entity_template" : "data_item",
			"title" : "Category Name",
			"elements" : [ {
			    "entity" : "input",
			    "attributes" : {
				"name" : "bbx_sound_category_name",
				"type" : "text"
			    }
			} ]
		    }
	 	]
	    }
	]
	}
    ]
});
