register_screen('stats_graphs', {
    elements: [
	{ entity_template: 'page_title', title: 'System Utilization' },
	    {
		entity_template: 'page_buttons',
		buttons: [{ type: 'help', help_id: 'stats_graph' }]
	    },
	{ entity_template: 'page_content',
	  elements: [
	      {
		  entity_template: 'page_module',
		  legend: 'Active Call Sessions',
		  elements: [
		      {
			  entity: 'div',
			  widgets: ['timeSeriesWidget'],
			  widget_options: {
			      timeSeriesWidget: {
				  rest: '/gui/graph/data',
				  type: 'sessions',
				  unit: '',
				  title: 'Active Call Sessions',
				  x_axis: 'Time',
				  y_axis: 'Active Sessions',
				  renderTo: 'sessions_timeSeries'
			      }
			  }
		      }
		  ]
	      },
	      {
		  entity_template: 'page_module',
		  legend: 'LAN Traffic',
		  elements: [
		      {
			  entity: 'div',
			  widgets: ['timeSeriesWidget'],
			  widget_options: {
			      timeSeriesWidget: {
				  rest: '/gui/graph/data',
				  type: 'lan',
				  unit: 'B',
				  title: 'LAN Traffic',
				  formatter: 'traffic',
				  x_axis: 'Time',
				  y_axis: 'Traffic in bytes',
				  renderTo: 'lan_timeSeries'
			      }
			  }
		      }
		  ]
	      },
	      {
		  entity_template: 'aopb_container',
		  watch_self_fillData: true,
		  rest: '/gui/system/wanenabled',
		  states: [{
		      conditions: { a: '@wanenabled', op: '==', b: '_TRUEISH_' },
		      elements: [{
			  entity_template: 'page_module',

			  legend: 'WAN Traffic',
			  elements: [
			      {
				  entity: 'div',
				  widgets: ['timeSeriesWidget'],
				  widget_options: {
				      timeSeriesWidget: {
					  accepts_data_from_parent: false,
					  rest: '/gui/graph/data',
					  type: 'wan',
					  title: 'WAN Traffic',
					  formatter: 'traffic',
					  x_axis: 'Time',
					  y_axis: 'Traffic in bytes',
					  unit: 'B',
					  renderTo: 'wan_timeSeries'
				      }
				  }
			      }
			  ]
		      }]
		  }]
	      }
	  ]
	}
    ]
});
