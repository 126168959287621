/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, trueish */
/*
  Widget for marking a fax or voicemail read or unread. Apply to a SELECT.
*/
(function( $ ){
	var readUnreadWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			rest_urls: { fax: '/gui/fax/fax', voicemail: '/gui/voicemail/voicemail' },

			_id_vars: {},
			_read_key: undefined,
			_type: undefined
		},

		_beforeInit: function () {
			var self = this, $self = this.element;
			$self.empty().append('<option value="0">Unread</option><option value="1">Read</option>');
			self._bind($self, 'change', self._changeHandler.bind(self));
		},

		fillData: function (d) {
			var self = this, $self = this.element;
			if (d.bbx_fax_inbound_id) {
				self.options._id_vars = { bbx_fax_inbound_id: d.bbx_fax_inbound_id };
				self.options._type = 'fax';
				self.options._read_key = 'bbx_fax_inbound_read_time';
			} else if (d.voicemail_msgs_id) {
				self.options._id_vars = { voicemail_msgs_id: d.voicemail_msgs_id };
				self.options._type = 'voicemail';
				self.options._read_key = 'read_epoch';
			}

			if (self.options._read_key) {
				$self.val(trueish(d.read));
			}
		},

		_changeHandler: function () {
			var self = this, $self = this.element, output = {};
			if (self.options._read_key) {
				output[self.options._read_key] = $self.val();
				$.extend(output, self.options._id_vars);
				CUI.putREST(self.options.rest_urls[self.options._type], output);
			}
		}

	});

	add_widget('readUnreadWidget', readUnreadWidget);
})(jQuery);
