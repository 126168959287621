/* jshint jquery: true, unused: vars */
/* global add_widget */
/* global validUserID, SUPPRESS_ERROR_DISPLAY: true */
/*
 * @class impersonateButtonWidget(options)
 *
 * The impersonate button widgit is used from a user record page and logs you in as the user. It is a child class of actionButtonWidget.
 *
 * @par Usage:
 *
 * data-js is used to pass an associated array. The array key holds the condition and value holds the state actions.
 *
 * @param rest sets the url that will be used for an Ajax REST execution. This param is optional and if not found no Ajax action will
 *        be performed.
 *
 * @param fields is a space seperated list that filters the rest_params options to limit params sent to the Ajax REST action.
 *        e.g.: 'key bbx_user_id bbx_extension_id'
 *
 * @param confirm sets the confirm message for a user confirmation box and launches the confirmation box when the users clicks the button.
 *
 * data-js='{ "rest" : "/path", ["fields" : "field_name field_name field_name"], ["confirm" : "confirmation message"] }'
 *
 */

(function( $ ){

	var impersonateButtonWidget = $.extend({}, $.ui.actionButtonWidget.prototype, {
		options: {
			rest: '/gui/login/impersonate',
			confirm: {
				title: 'Impersonate this person?',
				text: 'Are you sure you want to login as this person? You will have to log out and back in as your current user when finished.'
			},
			ok_button: 'Impersonate',
			cancel_button: 'Cancel',
			method: 'post'
		},

		fillData: function(d) {
			var self = this, $self = this.element;
			if (d && d.bbx_user_id && d.bbx_user_id == validUserID) {
				$self.disable('userIsSelf');
			}
		},

		/*
		 * @fn _onSuccess()
		 * @brief On successful login, suppress error display, remove hash, and reload to enter user screen.
		 */
		_success : function (d) {
			SUPPRESS_ERROR_DISPLAY = true;
			window.location.hash = '';
			window.location.reload();
		}

	});

	add_widget('impersonateButtonWidget', 'ui.impersonateButtonWidget', 'impersonateButtonWidget', impersonateButtonWidget);
})(jQuery);
