/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, trueish */
(function( $ ){

	var pad = function (v) {
		v = (v || '').toString();
		return (v ? ('0' + v).slice(-2) : '00');
	};

	var timeSelectWidget = $.extend({}, $.ui.widget.prototype, {
		value_widget: true,
		manages_own_descendent_value: true,
		set_value_widget: true,

		options: {
			closest: 'form',
			clock_ampm: true,
			allow_hours: true,
			allow_minutes: true,
			minute_interval: false,
			allow_seconds: true,
			display_output: false,
			default_time: '00:00:00',  // 24hr format

			_time: {}
		},

		_beforeInit: function () {
			var self = this, $self = this.element, i, $hour_select, $minute_select, $second_select, $ampm_select, $time_display, default_hms, opts;

			// Note to the future: The string-concatenation building these options lists is safe, since all the values are known to be just numbers.

			default_hms = self.options.default_time.split(':');

			// Hour Selector
			opts = '';
			for(i = (self.options.clock_ampm ? 1 : 0); i <= (self.options.clock_ampm ? 12 : 23); i++){
				opts += '<option value="' + i + '">' + i + '</option>';
			}

			$hour_select = $('<select class="time_select">' + opts + '</select>');

			if(!self.options.allow_hours){
				$hour_select.attr('disabled', 'disabled');
			}

			$self.append($hour_select);
			self.options.$hour_select = $hour_select;

			// Minute Selector
			opts = '';
			if (self.options.minute_interval) {
				for (i = 0; i < 60; i+=15) {
					opts += '<option value="' + (i ? i : '00') + '">' + (i ? i : '00') + '</option>';
				}
			} else {
				for(i = 0; i <= 59 ; i++){
					i = (i < 10) ? '0' + i : i;
					opts += '<option value="' + i + '">' + i + '</option>';
				}
			}

			$minute_select = $('<select class="time_select">' + opts + '</select>');

			if(!self.options.allow_minutes){
				$minute_select.attr('disabled', 'disabled');
			}

			self.options.$minute_select = $minute_select;
			$self.append($minute_select);

			// Second Selector
			if (self.options.allow_seconds) {
				opts = '';

				for (i = 0; i <= 59 ; i++) {
					i = (i < 10) ? '0' + i : i;
					opts += '<option value="' + i + '">' + i + '</option>';
				}

				$second_select = $('<select class="time_select">' + opts + '</select>');

				$self.append($second_select);
				self.options.$second_select = $second_select;
				self.options.$second_select.val(default_hms[2]);

			} else {
				// If seconds are not allowed then we'll set the value to '00'.
				self.options._time.seconds = "00";
			}

			// Insert an AM/PM Selector
			if (self.options.clock_ampm) {
				// If clock_ampm is true, we need to add the AM/PM selector, else we don't add it to the DOM.
				$ampm_select = $('<select>');
				$ampm_select.append('<option value="am">AM</option><option value="pm">PM</option>');

				self.options.$ampm_select = $ampm_select;
				$self.append($ampm_select);
			}

			if (self.options.display_label) {
				$time_display = $('<span class="time_display"></span>');
			}

			// self.options._time.output = self.options.default_time;

			self.setValue(self.options.default_time);

			self._delegate($self.closest(self.options.closest), 'select', 'click change', self._changeHandler.bind(self));
		},

		_changeHandler: function(e) {
			var self = this, $self = this.element, hour_select_value, minute_select_value, $second_select;

			if (self.options.destroyed) { return; }

			hour_select_value = Number(self.options.$hour_select.val());
			minute_select_value = Number(self.options.$minute_select.val());

			// Setting Hours
			// Adjusting for AM/PM
			// If the time format is in 12H, we need to account for the AM/PM selector and adjust time accordingly
			if (hour_select_value === 0) {
				self.options._time.hours = 0;
			} else if (self.options.clock_ampm) {
				if (self.options.$ampm_select.val() === 'pm') {
					if (hour_select_value === 12) {
						hour_select_value = 12;
					} else {
						hour_select_value += 12;
					}
				} else if (hour_select_value === 12) {
					hour_select_value = 0;
				}
			}

			self.options._time.hours = Number(hour_select_value);
			self.options._time.minutes = Number(minute_select_value);

			// Setting Seconds
			if (self.options.allow_seconds) {
				self.options._time.seconds = Number($second_select.val());
			}

			self._updateValue();

			$self.trigger('change');
		},

		_updateValue: function () {
			var self = this;
			self.options._time.output = pad(self.options._time.hours) + ':' + pad(self.options._time.minutes) + ':' + pad(self.options._time.seconds);
		},

		_showTime : function () {
			var self = this, $self = this.element, set_hour;

			if (trueish(self.options.clock_ampm)) {
				if (self.options._time.hours >= 12) {
					set_hour = (self.options._time.hours === 12 ? 12 : (self.options._time.hours - 12) );
					self.options.$ampm_select.val("pm");
				} else {
					set_hour = (self.options._time.hours === 0 ? 12 : self.options._time.hours);
					self.options.$ampm_select.val("am");
				}
			}

			self.options.$hour_select.val(set_hour);
			self.options.$minute_select.val(('0' + self.options._time.minutes.toString()).slice(-2));
			if (self.options.allow_seconds) {
				self.options.$second_select.val(('0' + self.options._time.seconds.toString()).slice(-2));
			}
		},

		setValue: function(v) {
			var self = this, $self = this.element;

			if (v && typeof v == "string") {
				var time_match;

				if((time_match = v.match(/([0-9]{1,2}):([0-9]{2}):([0-9]{2})/))) {
					// Sets the time of the widget
					self.options._time.hours   = Number(time_match[1]);
					self.options._time.minutes = Number(time_match[2]);
					self.options._time.seconds = Number(time_match[3]);

					self._updateValue();
					self._showTime();
				}
			}
		},

		_getWidgetValue: function() {
			return this._wrapValue(this.options._time.output);
		}
	});

	add_widget('timeSelectWidget', timeSelectWidget);
})(jQuery);
