/* jslint jquery: true */
/* global widgetsSimilarTo, cmp */
/* DataTable sorting routines */

(function ($) {
	"use strict";
	
	// Given an HTML string, return the contents of the first SPAN, or the contents of the entire string (if the first elements is not a SPAN), lowercase, with
	// HTML tags stripped. Used to extract sortable text from rows that may have hidden data.
	var getSortText = function (raw_html) {
		var match;

		if (!raw_html && raw_html !== 0) { return ''; }
		raw_html = raw_html.toString();

		match = raw_html.match(/^\s*<span[^>]+>(.+?)<\/span>/);
		if (match) {
			match = match[1].replace(/<.*?>/g, '').toLowerCase();
		} else {
			match = raw_html.replace(/<.*?>/g, '').toLowerCase();
		}

		return match;
	};

	function afnSortDataDTWCallback ( oSettings, iColumn, iVisColumn ) {
		var col_data = [];
		var $tr = $(oSettings.oApi._fnGetTrNodes(oSettings));
		var $table = $tr.closest('table');
		if ($table[0]) {
			var dtw = $table.getCUIWidget('dataTableWidget');

			if (!dtw) {
				dtw = widgetsSimilarTo($table, 'dataTableWidget')[0];
			}

			if (dtw.options.col_offset) {
				iColumn -= dtw.options.col_offset;
			}
			col_data = dtw.getColumnData(iColumn);
		}
		$table.triggerHandler('sortRows');
		return col_data;
	}

	$.fn.dataTableExt.afnSortData['num-html-asc'] = afnSortDataDTWCallback;
	$.fn.dataTableExt.afnSortData['num-html-desc'] = afnSortDataDTWCallback;
	$.fn.dataTableExt.afnSortData['numeric-asc'] = afnSortDataDTWCallback;
	$.fn.dataTableExt.afnSortData['numeric-desc'] = afnSortDataDTWCallback;
	$.fn.dataTableExt.afnSortData['dmhs-asc'] = afnSortDataDTWCallback;
	$.fn.dataTableExt.afnSortData['dmhs-desc'] = afnSortDataDTWCallback;
	$.fn.dataTableExt.afnSortData['string'] = afnSortDataDTWCallback;

	$.fn.dataTableExt.afnSort = function ( oSettings, iColumn, iVisColumn, sSortDataType ) {
		var bContinue = true;
		var $tr = $(oSettings.oApi._fnGetTrNodes(oSettings));
		var $table = $tr.closest('table');

		if ($table[0]) {
			var dtw = $table.getCUIWidget('dataTableWidget');

			if (!dtw) {
				dtw = widgetsSimilarTo($table, 'dataTableWidget')[0];
			}

			if (dtw.options.col_offset) {
				iColumn -= dtw.options.col_offset;
			}

			if (dtw.options.rest && dtw.options.ready) {
				if (!dtw.options.rest_params) {
					dtw.options.rest_params = {};
				}

				dtw.options.rest_params.sortby = dtw.getColumnName(iColumn);
				dtw.options.rest_params.sortorder = oSettings.aaSorting[0][1]; // Pull the sort order from dataTables.net

				dtw.refresh.call(dtw);
				$table.triggerHandler('sortRows');
				bContinue = false;
			}
		}
		return bContinue;
	};


	$.extend( $.fn.dataTableExt.oSort, {
		// HTML sorting should only consider the initial SPAN, if there is one
		"html-pre": function ( a ) {
			return getSortText(a);
		},
		"html-asc": function ( a, b ) {
			var content_a, content_b;

			content_a = getSortText(a);
			content_b = getSortText(b);

			return ((content_a < content_b) ? -1 : ((content_a > content_b) ? 1 : 0));
		},
		"html-desc": function ( a, b ) {
			var content_a, content_b;

			content_a = getSortText(a);
			content_b = getSortText(b);

			return ((content_a < content_b) ? 1 : ((content_a > content_b) ? -1 : 0));
		},

		"num-html-pre": function ( a ) {
			var x = a.replace( /<.*?>/g, "" );
			return parseFloat( x );
		},

		"num-html-asc": function ( a, b ) {
			var a_num = parseFloat(getSortText(a)), b_num = parseFloat(getSortText(b));
			return cmp(a_num, b_num);
		},

		"num-html-desc": function ( a, b ) {
			var a_num = parseFloat(getSortText(a)), b_num = parseFloat(getSortText(b));
			return -(cmp(a_num, b_num));
		},

		"monthYear-pre": function ( s ) {
			var a = s.split(' ');
			// Date uses the American "MM DD YY" format
			return new Date(a[0]+' 01 '+a[1]);
		},

		"monthYear-asc": function ( a, b ) {
			return ((a < b) ? -1 : ((a > b) ? 1 : 0));
		},

		"monthYear-desc": function ( a, b ) {
			return ((a < b) ? 1 : ((a > b) ?  -1 : 0));
		},

		"ip-address-pre": function ( a ) {
			var m = a.split("."), x = "";

			for(var i = 0; i < m.length; i++) {
				var item = m[i];
				if(item.length == 1) {
					x += "00" + item;
				} else if(item.length == 2) {
					x += "0" + item;
				} else {
					x += item;
				}
			}

			return x;
		},

		"ip-address-asc": function ( a, b ) {
			return ((a < b) ? -1 : ((a > b) ? 1 : 0));
		},

		"ip-address-desc": function ( a, b ) {
			return ((a < b) ? 1 : ((a > b) ? -1 : 0));
		},


		"formatted-num-pre": function ( a ) {
			a = (a==="-") ? 0 : a.replace( /[^\d\-\.]/g, "" );
			return parseFloat( a );
		},

		"formatted-num-asc": function ( a, b ) {
			return a - b;
		},

		"formatted-num-desc": function ( a, b ) {
			return b - a;
		},


		"file-size-pre": function ( a ) {
			var x = a.substring(0,a.length - 2);

			var x_unit = (a.substring(a.length - 2, a.length) == "MB" ?
						  1000 : (a.substring(a.length - 2, a.length) == "GB" ? 1000000 : 1));

			return parseInt( x * x_unit, 10 );
		},

		"file-size-asc": function ( a, b ) {
			return ((a < b) ? -1 : ((a > b) ? 1 : 0));
		},

		"file-size-desc": function ( a, b ) {
			return ((a < b) ? 1 : ((a > b) ? -1 : 0));
		},

		"dhms-asc": function (a, b) {
			var a_parts, b_parts, a_sec = 0, b_sec = 0, parts, a_idx, b_idx, multipliers = { d: 86400, h: 3600, m: 60, s: 1 };

			a_parts = a.split(' ');
			b_parts = b.split(' ');

			for (a_idx = 0; a_idx < a_parts.length; a_idx++) {
				parts = a_parts[a_idx].match(/([0-9\.]+)([dhms])/);
				if (parts) {
					a_sec += Number(parts[1]) * multipliers[parts[2]];
				}
			}

			for (b_idx = 0; b_idx < b_parts.length; b_idx++) {
				parts = b_parts[b_idx].match(/([0-9\.]+)([dhms])/);
				if (parts) {
					b_sec += Number(parts[1]) * multipliers[parts[2]];
				}
			}

			return ((a_sec < b_sec) ? -1 : ((a_sec > b_sec) ? 1 : 0));
		},

		"dhms-desc": function (a, b) {
			var a_parts, b_parts, a_sec = 0, b_sec = 0, parts, a_idx, b_idx, multipliers = { d: 86400, h: 3600, m: 60, s: 1 };

			a_parts = a.split(' ');
			b_parts = b.split(' ');

			for (a_idx = 0; a_idx < a_parts.length; a_idx++) {
				parts = a_parts[a_idx].match(/([0-9\.]+)([dhms])/);
				if (parts) {
					a_sec += Number(parts[1]) * multipliers[parts[2]];
				}
			}

			for (b_idx = 0; b_idx < b_parts.length; b_idx++) {
				parts = b_parts[b_idx].match(/([0-9\.]+)([dhms])/);
				if (parts) {
					b_sec += Number(parts[1]) * multipliers[parts[2]];
				}
			}

			return -((a_sec < b_sec) ? -1 : ((a_sec > b_sec) ? 1 : 0));
		}
	} );
})(jQuery);
