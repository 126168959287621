/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){

	var containerWidget = $.extend({}, $.ui.widget.prototype, CUI.htmlEntityClass, CUI.htmlContainerClass, {
		_afterInit: function() {
			this.element.addClass('containerWidgetType');
		}
	});

	add_widget('containerWidget', containerWidget);
})(jQuery);
