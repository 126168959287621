/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){
	var pingAopbStateWidget = $.extend(true, {}, $.ui.aopbStateWidget.prototype, {
		options: {
			accept_data_from_parent: false,
			ping_time: 2000
		},

		_beforeDOMReady: function () {
			var self = this, $self = this.element, output;
			output = $.ui.aopbStateWidget.prototype._beforeDOMReady.apply(self, arguments);
			self._addDestroyCallback(self._stopPing.bind(self));
			self.options._interval = setInterval(self.ping.bind(self), self.options.ping_time);
			return output;
		},

		ping: function () {
			this._dataFill();
		},

		_stopPing: function () {
			clearInterval(this.options._interval);
		}
	});

	add_widget('pingAopbStateWidget', pingAopbStateWidget);
})(jQuery);
