/* This screen-def provides both the tdm_analog_detail and the provisioned_analog_detail screens. (See the function calls at the bottom.) */

(function () {
    var register_analog_screen = function (screen_name, prefix, rest_controller) {
	var extension_page_module_elements = [
	    {
		entity_template: 'data_item',
		title: 'Send incoming calls to extension:',
		elements: [
		    {
			entity_template: 'extension_picker_select',
			name: 'bbx_extension_id',
			type: 'all',
			allow_nothing: true
						}
		]
	    }
	];

	if (prefix === 'bbx_provider_gateway') {
	    extension_page_module_elements.unshift({
		entity_template: 'input_item',
		title: 'Line Label:',
		info: 'Enter identifying information into this field, such as the line' + entity.rsquo + 's purpose or incoming number',
		input_options: {
		    attributes: {
			name: 'bbx_provider_gateway_label',
			size: '40'
		    }
		}
	    });
	} else {
	    extension_page_module_elements.unshift({
		entity_template: 'input_item',
		title: 'Line Label:',
		info: 'Enter identifying information into this field, such as the line' + entity.rsquo + 's purpose or incoming number',
		input_options: {
		    attributes: {
			name: 'bbx_tdm_card_port_label',
			size: '40'
		    }
		}
	    });
	}
	
	register_screen(screen_name, {
	    elements: [
		{ entity_template: 'page_title', field: prefix + '_name', rest: rest_controller },
		{ entity: "div", "attributes":{"class": "page_messages"} },
		{ entity_template: 'page_buttons', buttons: [(prefix === 'bbx_provider_gateway' ? {
			type: 'delete',
			rest: '/gui/gateway/sip',
			fields: 'bbx_provider_gateway_id',
			confirm: {
			    title: 'Delete Provider',
			    body: '<span>Delete this SIP provider?<br /><br /><ul class="bullet-list"><li>This provider will be deleted permanently</li><li>Any Call Routing entries directing calls to this provider will be deleted permanently</li></ul><br />This cannot be undone.</span>'
			},
			dataEvents: {
			    trigger: 'provider_external'
			}
		} : undefined), { type: 'cancel' }, { type: 'save' } ] },
		{
		    entity_template: "page_content", elements: [
			{
			    entity_template: 'form',
			    action: rest_controller,
			    method: 'PUT',
			    data_js: [ prefix + '_id' ],
			    elements: [
				{
				    entity_template: 'page_module',
				    legend: 'Extension',
				    buttons: ['help'],
				    help_id: 'analog_incoming_calls_destination',
				    elements: extension_page_module_elements // page_module 1 elements
				}, // END page_module 1
				{
				    entity_template: 'page_module',
				    legend: 'Services',
				    buttons: ['help'],
				    help_id: 'analog_port_services',
				    elements: [
					{
					    entity_template: 'input_item',
					    title: 'Use for Fax Transmission:',
					    type: 'checkbox',
					    input_name: 'outbound_fax'
					},
					{
					    entity_template: 'data_item',
					    title: 'Use T.38 Reinvite on Fax Transmission:',
					    conditional_display: (prefix === 'bbx_provider_gateway'), // Only show on Provisioned Ports screen
					    elements: [
						{
						    entity: 'input',
						    attributes: { type: 'checkbox', name: 'fax_enable_t38_request' },
						    widgets: ['aopbStateWidget'],
						    widget_options: { aopbStateWidget: {
							states: [{
							    conditions: { a: '$outbound_fax', op: '==', b: '_TRUEISH_' },
							    actions: { enabled: true },
							    else_actions: { enabled: false }
							}]
						    }}
						}
					    ]
					}
				    ]
				}, // END page module 2
				{
				    entity_template: 'page_module',
				    legend: 'Port Status',
				    buttons: ['help'],
				    help_id: 'analog_port_status',
				    elements: [
					{
					    entity_template: 'data_item',
					    title: 'Port Status:',
					    elements: [
						{
						    entity_template: 'hardware_port_status'
						}
					    ]
					}
				    ]
				}
			    ] // END of form elements
			} // END of entity template form
		    ] // END page_content elements
		} // END page_content
	    ] // END template elements
	}); // END template
    }; // END register_analog_screen function
    
    register_analog_screen('tdm_analog_detail', 'bbx_tdm_card_port', '/gui/gateway/hardware');
    register_analog_screen('provisioned_analog_detail', 'bbx_provider_gateway', '/gui/gateway/sip');
    
})(); // END scoping function
