/* jshint jquery: true, browser: true */
/* global CUI, classCUI, trueish */
(function ($) {
	classCUI.prototype.getInputElementValue = function ($elem) {
		if ($elem.is(':checkbox')) {
			if ($elem.is(':checked')) {
				return 1;
			} else {
				return 0;
			}
		}

		if ($elem.is(':radio')) {
			return $elem.closest('form').find('[name=' + name + ']:checked').val();
		}

		if ($elem.is('input, select, textarea')) {
			return $elem.val();
		}

		return $elem.text();
	};

	classCUI.prototype.setInputElementValue = function ($elem, value, params) {
		/* Params:

	   allow_html: false | true

	 */
		params = params || {};

		// If the element we are setting the value for is a checkbox
		if ($elem.is(':checkbox')) {
			if (trueish(value)) {
				$elem.attr('checked', 'checked');
			} else {
				$elem.removeAttr('checked');
			}
			return;
		}

		// Or if it is a radio button
		if ($elem.is(':radio')) {
			$elem.closest('form').find('[name=' + name + '],[value-from=' + name + ']').filter('[value=' + value +']').attr('checked', 'checked');
			return;
		}

		// Or if it is an input
		if ($elem.is('input, select, textarea')) {
			$elem.val(value);
		} else {
			// Coerce numbers and booleans to string, because jQuery may not handle these properly in the text() and html() methods
			if (typeof value == 'number' || typeof value == 'boolean') {
				value = value.toString();
			}

			// It is a div, span, p, etc.
			if ($elem.is('[allow-html]') || params.allow_html) {
				$elem.html(value);
			} else {
				$elem.text(value);
			}
			return;
		}
	};

	classCUI.prototype.getValueWidgets = function ($elem, params) {
		var widgets, w_idx, widget, w_out = [];
		params = params || {};

		if ($elem.is('.widgetType.widgetValueWidget')) {
			widgets = $elem.getCUIWidgets();

			widgetLoop: for (w_idx = 0; w_idx < widgets.length; w_idx++) {
				widget = widgets[w_idx];
				if (widget.value_widget && (params.include_never_submit || !widget.options.never_submit) ) {
					w_out.push(widgets[w_idx]);
					if (params.first_value || params.first) { return w_out; }
				}
			}
		}

		return w_out;
	};

	classCUI.prototype.getWidgetElementValue = function ($elem, params) {
		// Get its value
		var values = {}, value_widgets = [], w_idx, input_name;
		params = params || {};

		if ($elem.is('.widgetType.widgetValueWidget')) {
			value_widgets = CUI.getValueWidgets($elem, params);
			for (w_idx = 0; w_idx < value_widgets.length; w_idx++) {
				if (params.first_value) {
					return value_widgets[w_idx].getFirstValue();
				} else {
					$.extend(values, value_widgets[w_idx].getValue());
				}
			}
		}


		if (value_widgets[0]) {
			return values;
		} else {
			if ($elem.is(':input')) {
				input_name = CUI.getElementName($elem);

				if (params.first_value) {
					return CUI.getInputElementValue($elem);
				} else if (input_name || params.allow_no_name_inputs) {
					values[ input_name || '_NO_NAME' ] = CUI.getInputElementValue($elem);
					return values;
				}
			} else {
				// This is neither a value-widget nor an INPUT of any sort. Return the most useful "undefined" value for the context.
				return (params.first_value ? undefined : {});
			}
		}
	};
})(jQuery);
