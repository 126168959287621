/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, widgetize_children */
/*

translateWidget: This widget simply changes a known incoming value (original text) to a different value (translated text)

USAGE:

{
    translations: {
	'original text 1': 'translated text', // Simple a->b text translation, or...
	'original text 2': { text: 'translated text', 'class': 'className', css: { (style definition) } }, // Add a class or CSS styles, or...
	'original text 3': $jqObject, // Use a pre-prepared jQuery object. Cloned before placement -- variable references will not be maintained.
	'original text 4': { ... element specification ... }
    },
    default_translation:   'text' | { ... } | false,  // For any other values not covered in the translations object
    undefined_translation: 'text' | { ... } | false,   // For cases of an "undefined" value
    render: 'setValue' | 'text' | 'html' // Render string translations as a "setValue" call, text, or HTML
}

*/

(function ($) {
	var translateWidget = $.extend({}, $.ui.widget.prototype, {
		set_value_widget: true,

		options: {
			translations: {
				/*
		  // THIS IS AN OBJECT--NOT AN ARRAY OF OBJECTS!

		  'original text' : 'translated text', // Simple text, same style
		  'original text' : { text : 'translated text', 'class' : 'classname', css : { style definition } }, // Text with style and/or class
		  'original text' : $(...),            // jQuery DOM object, pre-formatted-- cloned before use
		  ...
		*/
			},
			undefined_translation: false,
			default_translation: false,
			render: 'setValue'
			/* Same as any "translations" value portion in the object above. "False" passes the value as-is. */
		},

		_beforeInit: function () {
			var self = this;
			var $self = self.element;
		},

		fillData: function (d, fromSelf) {
			this.options._all_data = d;
			$.ui.widget.prototype.fillData.apply(this, arguments);
		},

		_getTranslation: function (v) {
			var self = this;
			if (v === undefined) {
				return self.options.undefined_translation || undefined;
			} else {
				return self.options.translations[v] || self.options.default_translation || undefined;
			}
		},

		setValue: function (v) {
			var self = this, $self = self.element, translation = false, el_translation, args = [];
			translation = self._getTranslation(v);

			if (typeof translation === 'string') {
				switch (self.options.render) {
					case 'setValue':
						args[0] = translation;
						return $.ui.widget.prototype.setValue.apply(self, args);
					case 'text':
						$self.text(translation);
						break;
					case 'html':
						$self.html(translation);
						break;
				}
			} else if (translation instanceof jQuery) {
				$self.html(translation);
			} else if (translation && $.isPlainObject(translation)) {
				if (translation.entity || translation.entity_template) {
					$self.children().remove();
					el_translation = CUI.htmlEntityClass.getHTML(translation);
					$self.append(el_translation);
					widgetize_children($self);
					if (self.options._all_data) {
						self.fillDataChildren( self.options._all_data );
					}
				} else {
					if (translation['class']) {
						$self.addClass(translation['class']);
					}

					if (translation.css) {
						$self.css(translation.css);
					}

					if (translation.text !== undefined) { v = translation.text; }
					args[0] = v;
				}

				return $.ui.widget.prototype.setValue.apply(self, args);
			} else {
				args[0] = v;
				return $.ui.widget.prototype.setValue.apply(self, args);
			}
		}
	});

	add_widget('translateWidget', translateWidget);

})(jQuery);
