register_screen('ldap_settings', {
    elements: [
	{ entity_template: 'page_title', title: 'Synchronization Options' },
	{ entity_template: 'page_buttons', buttons: [ { type: 'cancel' }, { type: 'save' } ] },
	{
	    entity_template: 'page_content', elements: [{
		entity_template: 'aopb_container',
		rest: '/gui/sync/server',
		dataEvents: {
		    bind: {
			'ldap_settings': ''
		    }
		},
		states: [
		    {
			conditions: { a: '@bbx_sync_server_name', op: '!=', b: '_TRUEISH_' },
			// Screen def when LDAP is not configured
			elements: [
			    {
				entity_template: 'page_module',
				legend: 'No Synchronization Configured',
				buttons: ['help'],
				help_id: 'ldap_setup_unconfigured',
				elements: [
				    {
					entity_template: 'container',
					attributes: {
					    'class': 'ldap_settings_no_server'
					},
					elements: [
					    {
						entity: 'div',
						text: 'No synchronization source is configured. Click the button to begin setup.'
					    },
					    {
						entity_template: 'overlay_button',
						overlay: 'ldapcreate',
						text: 'Set Up Synchronization...'
					    }
					]
				    }
				]
			    }
			],
			// Screen def when LDAP is configured
			else_elements: [
			    {
				entity_template: 'page_module',
				legend: 'Synchronized Groups',
				buttons: ['help'],
				help_id: 'ldap_sync_groups',
				form_action: '/gui/sync/ous',
				form_data_js: { method: 'POST' },
				elements: [
				    {
					entity_template: 'data_item',
					title: 'Select Synchronized Groups:',
					elements: [
					    {
						entity: 'div',
						attributes: { name: 'ou' },
						widgets: ['treeWidget']
					    },
					    {
						entity: 'div',
						attributes: { 'class':'info' },
						text: 'Changes will not take effect until they are saved, and the next Synchronization is run. To manually run a Synchronization, click the "Synchronize Now" button at the bottom of this page, after saving.'
					    }
					]
				    }
				]
			    },
			    {
				entity_template: 'page_module',
				legend: 'Authentication',
				buttons: ['help'],
				help_id: 'ldap_auth_settings',
				form_action: '/gui/sync/server',
				form_options: {
				    method: 'PUT',
				    include_data_keys: [ 'bbx_sync_server_id' ]
				},
				elements: [
				    {
					entity_template: 'input_item',
					title: 'Log-in Authentication Method:',
					type: 'select',
					input_name: 'login_method',
					select_options: [
					    { value: 'BOTH', text: 'Allow both Extension and LDAP/AD Login' },
					    { value: 'SYNC', text: 'LDAP/AD Synchronized Username and Password Only' },
					    { value: 'PIN', text: 'Extension Number and PIN Only' }
					]
				    },
				    {
					entity_template: 'input_item',
					input_name: 'bbx_sync_server_default_auth_suffix',
					type: 'text',
					title: 'Authorization Domain/Suffix:',
					input_options: {
					    widgets: ['aopbStateWidget'],
					    widget_options: {
						aopbStateWidget: {
						    states: [{
							conditions: { a: '$login_method', op: '!=', b: 'PIN' },
							actions: { enabled: true },
							else_actions: { enabled: false }
						    }]
						}
					    }
					}
				    }
				]
			    },
			    {
				entity_template: 'page_module',
				legend: 'Contact Search Sychronization',
                                form_action: '/gui/sync/server',
				form_options: {
				    method: 'PUT',
				    include_data_keys: [ 'bbx_sync_server_id' ]
				},
				buttons: ['help'],
				help_id: 'ldap_contact',
				elements: [
				    {
					entity_template: 'input_item',
					input_name: 'enable-ldap-contact-sync',
					type: 'checkbox',
					title: 'Synchronize to Contact Search:',
					info: 'Make all contact information from this source (all groups) available in Contact Search.'
				    }
				]
			    },
			    {
				entity_template: 'page_module',
				legend: 'Synchronization Server Settings',
				buttons: ['help'],
				help_id: 'ldap_attributes',

				elements: [
				    {
					entity_template: 'input_item',
					input_name: 'bbx_sync_server_uid',
					type: 'div',
					title: 'UID Attribute:'
				    },
				    {
					entity_template: 'input_item',
					input_name: 'bbx_sync_server_base_dn',
					type: 'div',
					title: 'Base DN:'
				    },
				    {
					entity_template: 'input_item',
					input_name: 'bbx_sync_server_groups_members',
					type: 'div',
					title: entity.ldquo + 'Member of' + entity.rdquo + ' Attribute:'
				    },
				    {
					entity_template: 'input_item',
					input_name: 'bbx_sync_server_members_groups',
					type: 'div',
					title: entity.ldquo + 'Group Member' + entity.rdquo + ' Attribute:'
				    }
				]
			    },
 			    {
				entity_template: 'page_module',
				legend: 'Actions',
				buttons: ['help'],
				help_id: 'ldap_actions',
				elements: [
				    {
					entity_template: 'container',
					attributes: { style: 'text-align: center' },
					elements: [
					    {
						entity_template: 'blanker_button',
						text: 'Synchronize Now',
						confirm: {
						    text: 'Synchronization will lock the Web interface while it is in progress. Continue?',
						    title: 'Synchronize Now'
						},
						rest: '/gui/sync/sync',
						method: 'POST',
						live: true,
						context: 'ldap_import',
						flyby_start: 'ldap_import_start',
						flyby_complete: 'ldap_import_complete',
						blanker_options: {
						    title: 'Active Directory Synchronization in Progress',
						    text: 'To protect data integrity, the administrative interface is locked while the synchronization is in progress.',
						    blanker: true,
						    flyby: 'Loading....',
						    progress: true,
						    icon: '/images/ldap_large.png',
						    iconCSS: { top: '50px', left: '50px' },
						    buttons: false
						},
						widgets: ['aopbStateWidget'],
						widget_options: {
						    aopbStateWidget: {
							closest: '.cui-page-content',
							watch_also: [ { events: 'dirty' }, { events: 'undirty' } ],
							states: [{
							    conditions: { a: 'form', a_is: 'element', op: '==', b: '.is-dirty', b_is: 'jq_is' },
							    actions: { enabled: false },
							    else_actions: { enabled: true }
							}]
						    }
						}
					    },
					    {
						entity_template: 'action_button',
						text: 'Remove Synchronization',
						rest: '/gui/sync/server',
						visual_feedback: true,
						confirm: {
						    title: 'Remove LDAP Synchronization',
						    text: 'Are you sure you wish to completely remove LDAP synchronization?'
						},
						method: 'DELETE',
						reload: true
					    },
					    {
						entity_template: 'form_dialog_button',
						text: 'Change Connection Settings',
						title: 'LDAP Server Settings',
						submit_button: 'Save',
						submit_first: true,
						cancel_button: 'Cancel',

						include_data: [
						    'bbx_sync_server_bind_dn',
						    'bbx_sync_server_bind_pass',
						    'bbx_sync_server_host',
						    'bbx_sync_server_port',
						    'bbx_sync_server_encryption',
						    'bbx_sync_server_id'
						],

						elements: [
						    {
							entity_template: 'input_item',
							input_name: 'bbx_sync_server_bind_dn',
							type: 'text',
							title: 'Bind Username:',
							attributes: {
							    'class': 'ldap_settings'
							}
						    },
						    {
							entity_template: 'input_item',
							input_name: 'bbx_sync_server_bind_pass',
							type: 'password',
							title: 'Password:',
							attributes: {
							    'class': 'ldap_settings'
							}
						    },
						    {
							entity_template: 'input_item',
							input_name: 'bbx_sync_server_host',
							type: 'text',
							title: 'Address:',
							attributes: {
							    'class': 'ldap_settings'
							}
						    },
						    {
							entity_template: 'input_item',
							input_name: 'bbx_sync_server_port',
							type: 'text',
							title: 'Port:',
							attributes: {
							    'class': 'ldap_settings'
							}
						    },
						    {
							entity_template: 'input_item',
							input_name: 'bbx_sync_server_encryption',
							type: 'select',
							title: 'Encryption:',
							select_options: [
							    { text: 'None', value: 'none' },
							    { text: 'StartTLS', value: 'tls' },
							    { text: 'SSL', value: 'ssl' }
							]
						    },
						    {
							entity_template: 'data_item',
							title: 'Test Settings:',
							elements: [
							    {
								entity_template: 'fill_data_button',
								text: 'Test Server Settings',
								rest: '/gui/sync/test',
								field_map: [ {'test': 'test'}, {'test': 'test_result'} ],
								include_field_data: {
								    bbx_sync_server_host: 'bbx_sync_server_host',
								    bbx_sync_server_port: 'bbx_sync_server_port',
								    bbx_sync_server_bind_dn: 'bbx_sync_server_bind_dn',
								    bbx_sync_server_bind_pass: 'bbx_sync_server_bind_pass',
								    bbx_sync_server_type: 'bbx_sync_server_type',
									bbx_sync_server_encryption: 'bbx_sync_server_encryption'
								},
								only_rest_params: [],
								method: 'POST'
							    }
							]
						    },
						    {
							entity_template: 'input_item',
							type: 'div',
							title: 'Result:',
							input_name: 'test',
							info: 'Test must be successful before saving changes',
							input_options: {
							    text: 'Test Not Yet Run.',
							    widgets: ['translateWidget'],
							    widget_options: {
								translateWidget: {
								    translations: {
									'PASS': 'Test Successful'
								    },
								    undefined_translation: 'Test Not Yet Run.'
								}
							    }
							}
						    },
						    {
							entity: 'div',
							attributes: { name: 'test_result', hidden: true },
							widgets: ['widget']
						    }
						], // END FD form contents

						rest: '/gui/sync/server',
						method: 'PUT',

						form_widget_template: 'dirty_form',

						form_options:   {
						    dirty_elements: ['test_result'],
						    dirty_true: 'PASS',
						    include_data_keys: ['bbx_sync_server_id'],
						    dataEvents: {
							trigger: 'ldap_settings'
						    },
						    invalidate: [
							{
							    selector: '.ldap_settings',
							    message: 'Please re-run the test after modifying settings!'
							}
						    ],
						    required_fields: [ 'bbx_sync_server_bind_dn' ]
						}
					    }, // END form_dialog_button
					    {
						entity_template: 'overlay_button',
						text: 'Manage Deleted People',
						overlay: 'ldap_undelete'
					    }
					]
				    }
				]
			    }
			]
		    }
		]
	    }]
	}
    ]
}); // END template
