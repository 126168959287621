/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/* A widget to launch a popup window on click. Apply to a button. */

(function( $ ){
	var popupButtonWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			handle: undefined,
			width: 640,
			height: 480,
			scroll: 1,
			resizable: 0
		},
		_afterInit: function () {
			var self = this, $self = this.element;
			self._bind($self, 'click', self.launch.bind(self));
		},
		launch: function () {
			var self = this;
			self.options.handle = window.open(self.options.url, self.options.window_name || self.options.widget_id,
				'width=' + self.options.width || 640 +
				',height=' + self.options.height || 480 +
				',location=0,menubar=0,scrollbars=' +
				self.options.scroll ? 1 : 0 +
				',status=0,toolbar=0,resizable=' + self.options.resizable ? 0 : 1
			);

			if (navigator.userAgent.indexOf('Chrome') > -1) {
				self._setTimeout(self._complete.bind(self), 500);
			} else {
				self._complete();
			}
		},
		_complete: function () {
			var self = this;
			if (self.options.handle) { self.options.handle.focus(); }
		}
	});

	add_widget('popupButtonWidget', popupButtonWidget);
})(jQuery);
