register_screen('agent_monitor', {
    elements: [
	{ entity_template: "page_title", title: "Agent Monitor" },
	{
	    entity_template: "page_buttons",
	    buttons: [{ type: "fullscreen" }, {'type': 'help', help_id:'switchboard_agent_monitor'}],
	    elements: [
		{
		    entity: "button",
		    attributes: {
			"class": "page_button"
		    },
		    widgets: [ "classToggleButtonWidget" ],
		    widget_options: {
			classToggleButtonWidget: {
			    text_off: "Hide Offline",
			    text_on: "Show Offline",
			    selector: '.agentwall_agents',
			    closest: '.screen',
			    css_class: "hide_offline"
			}
		    }
		},
		{
		    entity: "button",
		    attributes: {
			"class": "page_button"
		    },
		    widgets: [ "classToggleButtonWidget" ],
		    widget_options: {
			classToggleButtonWidget: {
			    text_off: "Hide Phones",
			    text_on: "Show Phones",
			    selector: '.agentwall_agents',
			    closest: '.screen',
			    css_class: "hide_phone_info"
			}
		    }
		}
	    ]
	},
	{ entity_template: "page_content",
	  "class": "switchboard",
	  elements: [
	      {
		  entity_template: "container",
		  "class": "agentwall_callers bubble_table",
		  elements: [
		      {
			  entity_template: "callers_waiting_table",
			  disable_cui_wrapper: true,
			  name: "agentmonitor_callers"
		      },
		      {
			  entity_template: "wallboard_resizer",
			  align: 'left',
			  min_font_size: 80,
			  max_font_size_docked: 250,
			  max_font_size_fullscreen: 350,
			  bubble_width: 30,
			  bubble_height: 3.7,
			  max_columns: 1
		      }
		  ]
	      },
	      {
		  entity_template: "container",
		  id: "agentwall_agents",
		  "class": "agentwall_agents bubble_table hide_phone_info hide_offline",
		  elements: [
		      {
			  entity_template: "wallboard_data_table",
			  disable_cui_wrapper: true,
			  name: "agentmonitor_agents",
			  live_table: "live_fifo_agents",
			  live_table_key: "fifo_agents",
			  js_sort_by: [[ 'agent_name', 'asc' ]],
//			  live_table_distinct_on: "call_state, bbx_user_id",
//			  live_table_allow_null_distinct: true,
			  data_source: "live",
			  page_size: 10000,
			  filtering: true,
			  filter_in_page_title: true,
			  extension_filter: true,
			  extension_filter_options: {
			      type_text: 'By Queue',
			      nothing_text: 'Show All Queues',
			      value_key: 'bbx_queue_id',
			      la_filter_field: 'queue_membership',
			      list_field: true,
			      type: 'queue',
			      primary: true
			  },
			  length_change: false,
			  paginate: false,
			  processing: false,
			  sortable: false,
			  hidden_rows: {
			      classes: [ 'offline' ],
			      message: 'All agents are offline.'
			  },
			  oLanguage: {
			      sZeroRecords: "No agents found matching the selected filter."
			  },
			  extra_row_data: [ "bbx_queue_name", "fifo_name", "agent_extension_value", "caller_caller_id_name", "caller_caller_id_number",
					    "bridge_start", "agent_status_name", "agent_status_last_changed", "bbx_user_status_warning_seconds",
					    "bbx_user_status_critical_seconds", "bbx_queue_consumer_wrapup_time", "bbx_queue_outbound_name", "call_state",
					    "current_time", "last_state_change", "deleted", "delete_delay", "agent_missed_calls", "state_duration",
					    "state_start", "bbx_phone_desc", "bbx_phone_model", "total_missed_calls", "extension_owner", "bbx_user_id",
					    "last_call_end", "last_call_missed", "primary_extension", "last_call_from_queue" ],
			  columns: [
			      {
				  header: "Agent",
				  column: "agent_name",
				  sortable: false,
				  view_elements: [
				      { entity_template: "agent_monitor" }
				  ]
			      },
			      {
				  header: "Agent Status",
				  column: "agent_status_name",
				  visible: false
			      },
			      {
				  header: "Call State",
				  column: "call_state",
				  visible: false
			      },
			      {
				  header: "Agent Extension",
				  column: "agent_extension_value",
				  visible: false
			      },
			      {
				  header: "Caller ID Name",
				  column: "caller_caller_id_name",
				  visible: false
			      },
			      {
				  header: "Caller ID Number",
				  column: "caller_caller_id_number",
				  visible: false
			      }
			  ]
		      },
		      {
			  entity_template: "wallboard_resizer",
//			  closest: ".conatinerWidgetType",
			  align: 'right',
			  min_font_size: 80,
			  max_font_size_docked: 200,
			  max_font_size_fullscreen: 250,
			  bubble_width: 24,
			  bubble_height: 7.1,
			  include_offline: true,
			  monitor_container: {
			      selector: '#agentwall_agents',
			      css_classes: {
				  'hide_phone_info': {
				      bubble_height: 5.1
				  },
				  'hide_offline': {
				      include_offline: false
				  }
			      }
			  }
		      }
		  ]
	      }
	  ]
	}
    ]
});
