register_screen('phone_model_details', {
    rest: '/gui/phone/phone',
    rest_container: 'phone',
    elements: [
	{ entity_template: 'phone_model_masthead' },
	{ entity_template: 'page_content', elements: [
	    {
		entity_template: 'page_module',
		legend: 'Basic Information',
		buttons: [ 'help' ],
		help_id: 'phones_basic_information',
		elements: [
		    {
			entity_template: 'input_item',
			title: 'Manufacturer:',
			input_name: 'bbx_phone_manufacturer_name',
			type: 'div'
		    },
		    {
			entity_template: 'input_item',
			title: 'Model:',
			input_name: 'bbx_phone_model_name',
			type: 'div'
		    },
		    {
			entity_template: 'input_item',
			title: 'MAC Address:',
			type: 'div',
			input_options: {
			    widgets: ['formatWidget'],
			    widget_options: {
				formatWidget: { format: {
				    field: '@bbx_phone_mac',
				    formatter: 'mac'
				}}
			    }
			}
		    },
		    {
			entity_template: 'input_item',
			title: 'Last Registered Time:',
			input_name: 'bbx_phone_last_registered',
			type: 'div'
		    },
		    {
			entity_template: 'input_item',
			title: 'Last Registered IP Address:',
			input_name: 'bbx_phone_last_ip',
				type: 'div'
		    }
		]
	    },
	    elgen('phone_model_detail')
	]}
    ]
});
