/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/* A single-purpose widget to launch the CCC on init and click. Apply to a button. */

(function( $ ){
	var ccc_win;

	var cccLauncherWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			_tried: false
		},

		_afterInit: function () {
			var self = this, $self = this.element;
			self.launch();
			self._bind($self, 'click', self.launch.bind(self));
		},

		launch: function () {
			var self = this, sid;
			if (!ccc_win || ccc_win.closed) {
				try {
					sid = window.getSessionID ? getSessionID() : 'unknown_session';
					ccc_win = window.open(
						'/ajax-html/callcontrol.html', 'client_' + sid,
						'width=640,height=480,location=0,menubar=0,scrollbars=1,status=0,toolbar=0,resizable=1');
				} catch (e) {

				}
			}

			if (navigator.userAgent.indexOf('Chrome') > -1) {
				self._setTimeout(self._complete.bind(self), 500);
			} else {
				self._complete();
			}
		},

		_complete: function () {
			var self = this;
			if (ccc_win) { ccc_win.focus(); }
			if (ccc_win && ccc_win.outerHeight && !self.options.tried) {
				if (!self.options.tried) {
					history.go(-1);
				}
			} else {
				self.options.tried = true;
			}
		}
	});

	add_widget('cccLauncherWidget', cccLauncherWidget);
})(jQuery);
