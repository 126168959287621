/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/*

  saveAndUpButtonWidget: A button that saves all the page's forms (like a normal save_button), then navigates to the parent screen or overlay.

*/

(function( $ ){
	var saveAndUpButtonWidget = $.extend(true, {}, $.ui.pageSaveButtonWidget.prototype, $.ui.upButtonWidget.prototype, {
		_beforeInit: function () {
			var self = this, $self = this.element;
			$self.addClass('page_save_button');
			self._bind($self.closest('.pageWidgetType'), 'pageSubmitComplete', self._complete.bind(self));
			return $.ui.pageButtonWidget.prototype._beforeInit.apply(this, arguments);
		},

		_complete: function (e, d) {
			var self = this, $self = this.element;
			if (!d.error_count) {
				self._link();
			}
		}
	});

	add_widget('saveAndUpButtonWidget', saveAndUpButtonWidget);
})(jQuery);
