(function () {
    register_screen('report_detail', {
	rest: '/gui/reporting/report',
	dataEvents: {
	    bind: {
		'reporting': '',
		allow_delete: true
	    }
	},
	elements: [
	    {
		entity_template: 'format',
		entity: 'h2',
		'class':'page_title',
		format: 'Report Options: $1',
		fields: [{ field: 'bbx_reporting_name' }]
	    },
	    {
		entity_template: 'page_buttons',
		buttons: [
		    {
			type: 'delete',
			rest: '/gui/reporting/report',
			fields: 'bbx_reporting_id',
			confirm: { text: 'Are you sure you would like to delete this report?' }
		    },
		    {
			type: 'run_report',
			text: 'Run and View'
		    },
		    {
			type: 'simple',
			text: 'Run and Email',
			options: {
			    widgets: ['formDialogButtonWidget','emailReportPageButtonWidget'],
			    widget_options: { formDialogButtonWidget: {
				form_options: {
				    always_dirty: true
				},
				rest: '/gui/reporting/email',
				method: 'POST',
				title: 'Email Report',
				submit_button: 'Run and Email',
				cancel_button: 'Cancel',
				elements: [
				    {
					entity: 'div',
					text: 'This will run the report and send the results to the email address(es) listed in the report options.'
				    }
				]
			    }}
			}
		    },
		    {
			type: 'simple',
			text: 'Run and Download (CSV)',
			options: {
			    widgets: ['linkButtonWidget', 'dirtyPageButtonWidget'],
			    widget_options: { linkButtonWidget: {
				include_rest_params: ['bbx_reporting_id'],
				include_token: true,
				href: '/gui/reporting/csv'
			    }}
			}
		    },
		    { type: 'cancel' },
		    { type: 'save' }
		]
	    },
	    {
		entity_template: 'page_content', elements: [
		    {
			entity_template: 'form',
			rest: '/gui/reporting/report',
			dataEvents: {
			    trigger: 'reporting'
			},
			elements: [
			    {
				entity_template: 'page_module',
				legend: 'Name and Schedule',
				buttons: [ 'help' ],
				help_id: 'report_basic_schedule',
				elements: [
				    {
					entity_template: 'input_item',
					title: 'Report Name:',
					type: 'text',
					input_name: 'bbx_reporting_name',
					validate: { conditions: '_TRUE_', message: 'A report name is required.' }
				    },
				    {
					entity_template: 'input_item',
					title: 'Schedule:',
					type: 'select',
					select_options: [
					    { text: 'None (Manual Only)', value: 0 },
					    { text: 'Monthly', value: 3 },
					    { text: 'Weekly', value: 2 },
					    { text: 'Daily', value: 1 }
					],
					input_name: 'bbx_reporting_recurring_type'
				    },
				    {
					entity_template: 'aopb_container',
					states: [
					    {
						conditions: { a: '$bbx_reporting_recurring_type', op: '==', b: '3' },
						elements: [
						    {
							entity_template: 'data_item',
							title: '',
							elements: [
							    {
								entity_template: 'container',
								elements: [
								    {
									entity: 'span',
									text: 'Day '
								    },
								    {
									entity_template: 'select_range',
									start: 1,
									end: 31,
									name: 'bbx_reporting_recurring_day_of_month'
								    },
								    {
									entity: 'span',
									text: ' of the month'
								    }
								]
							    },
							    {
								entity_template: 'aopb_state',
								closest: 'dd',
								'class': 'info',
								states: [
								    {
									conditions: { a: '$bbx_reporting_recurring_day_of_month', op: '==', b: '29' },
									actions: { text: 'The report will only run during February on a leap year.' },
									last_if_true: true
								    },
								    {
									conditions: { a: '$bbx_reporting_recurring_day_of_month', op: '==', b: '30' },
									actions: { text: 'The report will not run during the month of February.' },
									last_if_true: true
								    },
								    {
									conditions: { a: '$bbx_reporting_recurring_day_of_month', op: '==', b: '31' },
									actions: { text: 'The report will not run on months with 28, 29, or 30 days.' },
									else_actions: { text: '' }
								    }
								]
							    },
							    {
								entity: 'div',
								attributes: { style: 'display:none' },
								widgets: ['staticValueWidget'],
								widget_options: { staticValueWidget: {
								    data: { bbx_reporting_recurring_day_of_week: '' }
								}}
							    }
							]
						    }
						],
						last_if_true: true
					    }, // END mday state
					    {
						conditions: { a: '$bbx_reporting_recurring_type', op: '==', b: '2' },
						elements: [
						    {
							entity_template: 'data_item',
							title: '',
							elements: [
							    {
								entity_template: 'select_range',
								start: 1,
								end: 7,
								text_for_number: {
								    1: 'Sunday',
								    2: 'Monday',
								    3: 'Tuesday',
								    4: 'Wednesday',
								    5: 'Thursday',
								    6: 'Friday',
								    7: 'Saturday'
								},
								name: 'bbx_reporting_recurring_day_of_week'
							    },
							    {
								entity: 'div',
								attributes: { style: 'display:none' },
								widgets: ['staticValueWidget'],
								widget_options: { staticValueWidget: {
								    data: { bbx_reporting_recurring_day_of_month: '' }
								}}
							    }
							]
						    }
						],
						last_if_true: true
					    } // END w day state
					]  // END states
				    }, // END aopb_container
				    {
					entity_template: 'aopb_container',
					states: [{
					    conditions: { a: '$bbx_reporting_recurring_type', op: '==', b: '0' },
					    elements: [{
						entity: 'div',
						attributes: { style: 'display:none' },
						widgets: [ 'staticValueWidget' ],
						widget_options: { staticValueWidget: {
						    data: {
							bbx_reporting_recurring_day_of_week: '',
							bbx_reporting_recurring_day_of_month: '',
							bbx_reporting_schedule_when: ''
						    }
						}}
					    }],
					    else_elements: [{
						entity_template: 'data_item',
						title: '',
						elements: [
						    {
							entity_template: 'time_select',
							allow_seconds: false,
							name: 'bbx_reporting_recurring_time_of_day'
						    }
						]
					    }]
					}]
				    },

				    {
					entity_template: 'input_item',
					type: 'text',
					title: 'Email To:',
					input_name: 'bbx_reporting_email_address',
					size: 60,
					info: 'Separate multiple recipients with a comma',
					input_options: {
					    widgets: ['aopbStateWidget'],
					    widget_options: {
						states: [{
						    conditions: [
							{ conditions: [
							    {              a: '$bbx_reporting_recurring_type', op: '!=', b: '0' },
							    { join: 'and', a: '$$', op: '!=', b: '_TRUE_' }
							]},
							{ join: 'or', conditions: [
							    {              a: '$$', op: '!=', b: '^(.+@.+)', b_is: 'regexp' },
							    { join: 'and', a: '$$', op: '==', b: '_TRUE_' }
							]}
						    ],
						    actions: { invalid: 'Enter one or more valid email addresses to send scheduled reports.' },
						    else_actions: { invalid: false }
						}]
					    }
					}
				    }
				] // END page module elements
			    }, // END page module
			    {
				entity_template: 'page_module',
				legend: 'Filtering and Aggregation',
				buttons: [ 'help' ],
				help_id: 'report_filtering',
				elements: [
				    {
					entity_template: 'input_item',
					title: 'Show one row for each:',
					input_name: 'bbx_reporting_type',
					type: 'div',
					input_options: {
					    widgets: ['translateWidget'],
					    widget_options: { translateWidget: {
						translations: {
						    group: 'Group',
						    queue: 'Queue',
						    user: 'Person'
						}
					    }}
					}
				    },
				    {
					entity_template: 'switch',
					selector: '@bbx_reporting_type',
					init_before_data: true,
					switch_elements: {
					    group: {
						entity_template: 'data_item',
						title: 'Filter (Show Only):',
						elements: [
						    {
							entity_template: 'extension_picker_select',
							allow_none: true,
							nothing_text: 'All Groups',
							type: ['group'],
							value_key: 'bbx_extension_id',
							name: 'bbx_reporting_filter_extension_id'
						    },
						    {
							entity: 'div',
							attributes: {'class':'info'},
							text: 'Select a Group, or choose '+entity.ldquo+'All Groups'+entity.rdquo+'.'
						    }
						]
					    },
					    queue: {
						entity_template: 'data_item',
						title: 'Filter (Show Only):',
						elements: [
						    {
							entity_template: 'extension_picker_select',
							allow_none: true,
							nothing_text: 'All Queues',
							type: ['queue'],
							value_key: 'bbx_extension_id',
							name: 'bbx_reporting_filter_extension_id'
						    },
						    {
							entity: 'div',
							attributes: {'class':'info'},
							text: 'Select a Queue, or choose '+entity.ldquo+'All Queues'+entity.rdquo+'.'
						    }
						]
					    },
					    user: {
						entity_template: 'data_item',
						title: 'Filter (Show Only):',
						elements: [
						    {
							entity_template: 'extension_picker_select',
							allow_none: true,
							nothing_text: 'Everyone',
							type: ['group','queue', 'user'],
							value_key: 'bbx_extension_id',
							name: 'bbx_reporting_filter_extension_id'
						    },
						    {
								entity: 'div',
							attributes: {'class':'info'},
							text: 'Select a Group, Queue, Person, or choose '+entity.ldquo+'Everyone'+entity.rdquo+'.'
						    }
						]
					    }
					},
					default_elements: '',
					invalid_action: 'default'
				    },
				    {
					entity_template: 'aopb_container',
					states: [
					    {
						conditions: { a: '@bbx_reporting_type', op: '==', b: 'queue' },
						elements: [
						    {
							entity_template: 'input_item',
							title: 'Aggregate Every:',
							type: 'select',
							input_name: 'bbx_reporting_group_by_interval',
							select_options: [
							    { text: 'Hour', value: '1' },
							    { text: 'Day', value: '2' },
							    { text: 'Week', value: '3' },
							    { text: 'Month', value: '4' },
							    { text: '3 Months', value: '5' },
							    { text: 'Year', value: '6' }
							]
						    }
						],
						else_elements: [
						    {
							entity_template: 'input_item',
							title: 'Aggregate Every:',
							type: 'select',
							input_name: 'bbx_reporting_group_by_interval',
							select_options: [
							    { text: 'Day', value: '2' },
							    { text: 'Week', value: '3' },
							    { text: 'Month', value: '4' },
							    { text: '3 Months', value: '5' },
							    { text: 'Year', value: '6' }
							]
						    }
						]
					    } // END state
					]
				    },
				    {
					entity_template: 'input_item',
					title: 'Report Covers Time Period:',
					type: 'select',
					input_options: {
					    attributes: { 'class': 'date-range-type', name: 'report_period_type' }
					},
					select_options: [
					    { text: 'Days Before Report', value: 'past' },
					    { text: 'Current/Previous', value: 'relative' },
					    { text: 'Date Range', value: 'range' }
					]
				    },
				    {
					entity_template: 'switch',
					closest: 'form',
					selector: '.date-range-type',
					switch_elements: {
					    past: [
						{
						    entity_template: 'data_item',
						    title: '',
						    elements: [
							{
							    entity: 'input',
							    attributes: {
							 	name: 'bbx_reporting_days_before',
								type: 'text',
								size: 3,
								'alt-message-container': 'next .messages'
							    },
							    widgets: ['aopbStateWidget'],
							    widget_options: { aopbStateWidget: {
								states: [{
								    conditions: [
									{ conditions: [
									    { a: '$$', op: '!=', b: '^[0-9]+$', b_is: 'regexp' },
									    { join: 'or', a: '$$', op: '<', b: 1 }
									]},
									{ join: 'and', a: '.date-range-type', op: '==', b: 'past' }
								    ],
								    actions: { invalid: 'Enter a number.' },
								    else_actions: { invalid: false }
								}]
							    }}
							},
							{
							    entity: 'span',
							    text: ' full days preceding the report'
							},
							{
							    entity: 'div',
							    attributes: { 'class': 'info' },
							    text: 'Number of full days preceding the report date. The day of the report is not included.'
							},
							{
							    entity: 'div',
							    attributes: { 'class': 'messages' }
							}
						    ]
						}
					    ], // END past condition elements
					    relative: [
						{
						    entity_template: 'data_item',
						    title: '',
						    elements: [
							{
							    entity: 'select',
							    attributes: { name: 'bbx_reporting_date_relative' },
							    select_options: [
								{ text: 'Current Day to Now', value: '1' },
								{ text: 'Previous Day', value: '2' },
								{ text: 'Current Week to Date', value: '3' },
								{ text: 'Previous Week', value: '4' },
								{ text: 'Current Month to Date', value: '5' },
								{ text: 'Previous Month', value: '6' },
								{ text: 'Current Quarter to Date', value: '7' },
								{ text: 'Previous Quarter', value: '8' },
								{ text: 'Current Year to Date', value: '9' },
								{ text: 'Previous Year', value: '10' }
							    ]
							},
							{
							    entity_template: 'aopb_state',
							    closest: 'dl',
							    attributes: { 'class':'info' },
							    states: [{
								conditions: { a: '$bbx_reporting_date_relative', op: '==', b: '^[3579]$', b_is: 'regexp' },
								actions: { text: 'Reports ' + entity.ldquo + 'To Date' + entity.rdquo + ' are aggregated up to the end of the previous full day.' },
								else_actions: { text: '' }
							    }]
							}
						    ]
						}						    
					    ],
					    range: [{
						entity_template: 'data_item',
						title: '',
						elements: [
						    {
							entity: 'div',
							attributes: { name: 'bbx_reporting_date_range' },
							widgets: ['dateRangePickerWidget'],
							widget_options: { dateRangePickerWidget: {
							    date_format: 'mm/dd/yy',
							    maxDate: '-1d'
							}}
						    }
						]
					    }] // END range elements
					} // END switch_elements
				    } // END switch
				] // END page module elements
			    }, // END page module
			    {
				entity_template: 'page_module',
				legend: 'Report Fields',
				buttons: [ 'help' ],
				help_id: 'report_filtering',
				elements: [{
				    entity_template: 'data_item',
				    title: 'Report Fields:',
				    elements: [{
					entity: 'div',
					attributes: { name: 'bbx_reporting_fields' },
					widgets: ['reportDLPWidget', 'aopbStateWidget'],
					widget_options: {
					    reportDLPWidget: {
						selector: '@bbx_reporting_type',
						
						height: 500,
						
						available_rest: '/gui/reporting/fields',
						available_rest_container: 'fields',
						
						available_item_title: 'text',
						item_id: 'value',
						
						separator: ','
					    },
					    aopbStateWidget: {
						states: [{
						    conditions: { a: '$$', op: '==', b: '_TRUE_' },
						    actions: { invalid: false },
						    else_actions: { invalid: 'The report must contain one or more fields.' }
						}]
					    }
					} // END widget_options
				    }] // END data_item elements
				}] // END data_item
			    } // END page module
			]}// END form
		] // END page content elements
	    } // END page content
	] // END page elements
    }); // END screen def
})();
