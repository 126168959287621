(function () {
    register_screen('smtp_settings', {
		elements: [
			{ entity_template: 'page_title', title: 'Mail' },
			{ entity_template: 'page_buttons', buttons: [{ type: 'cancel' }, { type: 'save' }] },
			{
				entity_template: 'page_content', elements: [
					{
						entity_template: 'page_module',
						legend: 'SMTP Mail Server Settings',
						form_action: '/gui/mail/settings',
						buttons: ['help'],
						help_id: 'configuration_mail_server_settings',
						elements: [
							{
								entity_template: 'input_item',
								input_name: 'bbx_smtp_type',
								type: 'select',
								title: 'SMTP Set Up Options',
								select_options: [
			 						{ value: 'standalone', text: 'Standalone' },
									{ value: 'smarthost', text: 'Smart Host' },
									{ value: 'manual', text: 'Use My Server' }
								]
		 					},
							{
								entity_template: 'input_item',
								type: 'text',
								input_name: 'bbx_smtp_sender_address',
								title: entity.ldquo + 'From' + entity.rdquo + ' Address:',
								size: 60,
								input_options: {
									widgets: ['aopbStateWidget'],
									widget_options: {
										aopbStateWidget: {
											states: {
												// No spaces allowed (blank is okay)
												conditions: { a: '$$', op: '==', b: ' +', b_is: 'regexp' },
												actions: { invalid: 'Invalid SMTP sender address' },
												else_actions: { invalid: false }
											}
										}
									}
								}
							},
							{
								entity_template: 'input_item',
								type: 'text',
								input_name: 'bbx_smtp_primary_hostname',
								title: 'Host:',
								input_options: {
									widgets: ['aopbStateWidget'],
									widget_options: {
										aopbStateWidget: {
											states: {
												// No spaces allowed (blank is okay)
												conditions: { a: '$$', op: '==', b: ' +', b_is: 'regexp' },
												actions: { invalid: 'Invalid IP Address' },
												else_actions: { invalid: false }
											}
										}
									}
								}
							},
							{
								entity_template: 'input_item',
								type: 'text',
								input_name: 'bbx_smtp_port',
								title: 'Port',
								size: 4,
								input_options: {
									widgets: ['aopbStateWidget'],
									widget_options: {
										aopbStateWidget: {
											states: {
												conditions: [
													{ a: '$$', op: '==', b: '_PORT_' },
													{ join: 'or', a: '$$', op: '==', b: '' }
												],
												actions: { invalid: false },
												else_actions: { invalid: 'Invalid SMTP port number' }
											}
										}
									}
								}
							},
							{
								entity_templates: ['input_item', 'aopb_state'],
								input_item: {
									type: 'text',
									input_name: 'bbx_smtp_relay_ip',
									title: 'Relay/Server IP:',
									input_options: {
										widgets: ['aopbStateWidget'],
										widget_options: {
											aopbStateWidget: {
												states: {
													// No spaces allowed (blank is okay)
													conditions: { a: '$$', op: '==', b: ' +', b_is: 'regexp' },
													actions: { invalid: 'Invalid IP Address' },
													else_actions: { invalid: false }
												}
											}
										}
									}
								},
								aopb_state:{
									states: [{
										conditions:[{a: '$bbx_smtp_type', op: '==', b: '^(smarthost|manual)$', b_is: 'regexp' }],
										actions:{visible: true, enabled: true, invalid: false, _cascade: true },
										else_actions:{visible: false, enabled: false, _cascade: true }
									}]
								}
							},
							{
								entity_templates: ['input_item', 'aopb_state'],
								input_item:{
									type: 'text',
									input_name: 'bbx_smtp_smart_host_username',
									title: 'Username:',
									input_options: {
										widgets: ['aopbStateWidget'],
										widget_options: {
											aopbStateWidget: {
												states: {
													// No spaces allowed (blank is okay)
													conditions: { a: '$$', op: '==', b: ' +', b_is: 'regexp' },
													actions: { invalid: 'Invalid SMTP Username' },
													else_actions: { invalid: false }
												}
											}
										}
									}
								},
								aopb_state:{
									states: [
										{
											conditions: {a: '$bbx_smtp_type', op:'!=' , b: 'manual'},
											actions: {visible: false, enabled: false, invalid: false, _cascade: true},
											else_actions:{ visible: true, enabled: true, _cascade: true }
										}
									]
								}
							},
							{
								entity_template: 'aopb_container',
								states: {
									conditions: {a: '$bbx_smtp_type', op: '==', b: 'manual' },
									elements: [
										{
											entity_template: 'input_item',
											type: 'password',
											input_name: 'bbx_smtp_smart_host_password',
											title: 'Password:'
										}
									]
								}
							},
							{
								entity_template: 'aopb_container',
								states: {
								conditions: {a: '$bbx_smtp_type', op: '==', b: '^(smarthost|manual)$', b_is: 'regexp'},
								elements: [{
									entity_template: 'input_item',
									type: 'text',
									input_name: 'bbx_smtp_smart_host',
									title: 'Alternate Hostname:',
									info: 'This setting is only required if your mail server requires a different hostname/domain to be sent for authentication. Otherwise, leave it blank to use the system' + entity.rsquo + 's default hostname.'
									}]
								}
							}
						]
					}, // END page module 1
					{
						entity_template: 'page_module',
						legend: 'Event Notifications',
						form_action: '/gui/mail/notification',
						buttons: ['help'],
						help_id: 'configuration_mail_event_settings',
						elements: [

							{
								entity_template: 'input_item',
								type: 'select',
								input_name: 'level',
								title: 'Warning Level:',
								info: 'Warnings at this level (or above) are collected and sent periodically (based on the ' + entity.ldquo + 'Send Warning Report Every' + entity.rdquo + ' setting).',
								select_options: [
									{ value: 0, text: 'None: Do not send warning messages' },
									{ value: 1, text: 'Emergency: System is unusable' },
									{ value: 2, text: 'Alert: Action must be taken immediately' },
									{ value: 3, text: 'Critical: Critical conditions' },
									{ value: 4, text: 'Error: An error has occurred' },
									{ value: 5, text: 'Warning: Warning conditions' },
									{ value: 6, text: 'Notice: Normal but significant condition' }
								]
							},
							{
								entity_template: 'input_item',
								type: 'select',
								input_name: 'interval',
								title: 'Send Warning Report Every:',
								select_options: [
									{ value: 300, text: '5 Minutes' },
									{ value: 600, text: '10 Minutes' },
									{ value: 1200, text: '20 Minutes' },
									{ value: 1800, text: '30 Minutes' },
									{ value: 3600, text: '1 Hour' }
								]
							},
							{
								entity_template: 'input_item',
								type: 'select',
								input_name: 'immediate_level',
								title: 'Immediate Warning Level:',
								info: 'Warnings at this level (or above) are sent immediately as they happen.',
								select_options: [
									{ value: 0, text: 'None: Do not send Immediate Warnings' },
									{ value: 1, text: 'Emergency: System is unusable' },
									{ value: 2, text: 'Alert: Action must be taken immediately' },
									{ value: 3, text: 'Critical: Critical conditions' },
									{ value: 4, text: 'Error: An error has occurred' },
									{ value: 5, text: 'Warning: Warning conditions' },
									{ value: 6, text: 'Notice: Normal but significant condition' }
								]
							},
							{
								entity_template: 'input_item',
								type: 'text',
								input_name: 'addresses',
								title: 'Send Warning Email To:',
								info: 'Separate multiple email addresses with commas.',
								validate: {
									conditions: "_TRUEISH_", message: "An email address is required"
								},
								size: 60
							}


						] // END page module elements
					}, // END page module 2
					{
						entity_template: 'page_module',
						legend: 'Operations',
						buttons: ['help'],
						help_id: 'configuration_mail_operations',

						elements: [
							{
								entity_template: 'container',
								'class': 'center',
								elements: [

									{
										entity_template: 'action_button',
										text: 'Send a Test Message',
										type: 'button',
										no_initial_get: true,
										rest: '/gui/mail/test',
										method: 'POST',
										dialog_after_success: {
											title: 'Test Message Sent',
											text: 'A test message has been sent to the configured Warning Email Address.'
										}
									},
									{
										entity_template: 'action_button',
										text: 'Send All Queued Mail Now',
										no_initial_get: true,
										rest: '/gui/mail/flush',
										type: 'button',
										method: 'POST',
										dialog_after_success: {
											title: 'Queued Messages Sent',
											text: 'The message queue has been flushed. All queued mail message have been sent.'
										}
									}
								]
							} // END container
						]
					} // END page module 3
				] // END page_content elements
			} // END page_content
		] // END template elements
    }); // END template
})(); // END scoping function
