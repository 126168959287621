/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){

	var dataItemWidget = $.extend({}, $.ui.widget.prototype, CUI.htmlEntityClass, {

		_afterInit: function() {
			var self = this, $self = this.element;
			$self.addClass('dataItemWidgetType');
		},

		_beforeDOMReady: function() {
			var self = this;
			var $self = this.element;

			var fragment = document.createDocumentFragment();

			var dt = document.createElement('dt');
			if (self.options.title) {
				var text = document.createTextNode(self.options.title);
				dt.appendChild(text);
			} else {
				dt.innerHTML = '&nbsp;';
			}
			fragment.appendChild(dt);

			var dd = document.createElement('dd');
			if (self.options.elements) {
				var dd_fragment = document.createDocumentFragment();
				var $entities = self.getEntitiesHTML(self.options.elements, self.options.data);
				if ($entities && $entities[0]) {
					var entities = $entities[0];
					for (var child = 0, num_children = entities.children.length; child < num_children; ++child) {
						dd_fragment.appendChild(entities.children[0]);
					}
					dd.appendChild(dd_fragment);
				}
			} else {
				dd.innerHTML = '&nbsp;';
			}
			fragment.appendChild(dd);
			$self[0].appendChild(fragment);
		}


	});

	add_widget('dataItemWidget', dataItemWidget);
})(jQuery);
