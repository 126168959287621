/* jshint jquery: true, unused: vars, -W018 */
/* global CUI, add_widget, loginWidget: true */
/* REQUIRES: (this list may not be complete, please expand it if you see unlisted dependencies)

   jquery.disable.js
*/

(function( $ ){
	var loginWidgetClass = $.extend({}, $.ui.widget.prototype, {

		_afterInit: function() {
			var $self = $(this.element);
			var self = this;

			loginWidget = self;

			self.options.logged_in = false;
			self.options.html = $self.closest('#cui-content-inner').html();
			self.options.actions = {};

			$self.addClass('loginWidgetType');

			self._setupLoginClosures();
			self._bind($(window), 'logout', self.options.logout_callback);
			self._defineActions();

			self._doURILoginAndStatusCheck();

			self._setupClickHandlers();
		},

		_beforeDOMReady: function () {
			var self = this, $self = this.element;

			self.options.$username = $self.find('input[name="__auth_user"]');
			self.options.$password = $self.find('input[name="__auth_pass"]');
		},

		_setupLoginClosures: function() {
			var $self = $(this.element);
			var self = this;

			self.options.logout_callback = function() {
				// debugLog('loginWidget logout callback.');
				self.options.logged_in = false;
				delete self.options.data;
			};

			self.options.button_click_closure = function(e) {
				self._buttonClickHandler(e);
			};

			self.options.status_check_closure = function() {
				self._doLoginStatusCheck();
			};

			self.options.login_success_closure = function(data, status, xhr) {
				self._unlockSubmit();
				self._doLoginSuccess(data, status, xhr);
			};

			self.options.login_error_closure = function(xhr, status, error) {
				self._unlockSubmit();
				self._doLoginError(xhr, status, error);
			};
		},

		_setupClickHandlers: function() {
			var $self = $(this.element);
			var self = this;

			$self.find('button').on('click', self.options.button_click_closure);
		},

		_setActionCallback: function(name, callback) {
			var $self = $(this.element);
			var self = this;

			self.options.actions[name] = callback;
		},

		/**
	 * @fn _defineActions()
	 * @brief This should be overriden in the child class to define what to do when a button is clicked.
	 */
		_defineActions: function() {

		},

		_login: function() {
			var $self = $(this.element);
			var self = this;


		},

		_buttonClickHandler: function(e) {
			var $self = $(this.element);
			var self = this;

			e.preventDefault();
			e.stopPropagation();
			var $target = $(e.target);
			var id = $target.attr('id');

			self.delMessage('error', 'error', true);
			if (id in self.options.actions && typeof self.options.actions[id] == 'function') {
				self.options.actions[id](e);
			}
		},

		_lockSubmit: function (e) {
			var $self = this.element;
			$self.find('button').disable('loginWidget_locked');
		},

		_unlockSubmit: function (e) {
			var $self = this.element;
			$self.find('button').enable('loginWidget_locked');
		},

		_checkLoginStatus: function(login_callback) {
			var $self = $(this.element);
			var self = this;

			// debugLog('loginWidget checkLoginStatus');
			self.options.login_callback = login_callback;

			CUI.ajax({
				url: self.options.status_url,
				type: 'GET',
				dataType: 'json',
				data: {},
				success: self.options.login_success_closure,
				error: self.options.login_error_closure,
				cache: false
			});
		},

		_attemptLogin: function (login_callback) {
			var $self = $(this.element);
			var self = this;

			CUI.ajax({
				url: self.options.login_url,
				type: 'POST',
				dataType: 'json',
				data: self.options.data,
				success: self.options.login_success_closure,
				error: self.options.login_error_closure,
				no_xsrf_key: true,
				cache: false
			});

		},

		_doLoginStatusCheck: function() {
			var $self = $(this.element);
			var self = this;

			if ('status_interval' in self.options && self.options.status_interval > 10) {
				setTimeout(self.options.status_check_closure, self.options.status_interval * 1000);
			}
			self.options.login_success_closure = function() { };
			self._checkLoginStatus(function(){});
		},

		_doURILoginAndStatusCheck: function(login_callback) {
			var $self = this.element;
			var self = this;

			// This section allows logging into the UI using only a URL, with __auth_user / __auth_pass on the URL

			var loc = window.location, u_match, p_match, username, password;

			username = (u_match = loc.search.match(/__auth_user=([^&#]+)/)) ? u_match[1] : undefined;
			password = (p_match = loc.search.match(/__auth_pass=([^&#]+)/)) ? p_match[1] : undefined;

			if (username && password) {
				self.options.data = self.options.data || {};
				self.options.data.__auth_user = username;
				self.options.data.__auth_pass = password;

				self._attemptLogin(login_callback);
			} else if (!!username !== !!password) {
				// Only one of the two creds were there... throw a dialog warning.

				var message = ['Not enough URL information to log in','Both the username and password must be supplied in the URL to use URL-based login. If you are already logged in, your existing session will be used. Otherwise, you will need to enter your credentials to continue.'];

				if (typeof CUI !== 'undefined' && CUI.Dialog) {
					new CUI.Dialog({
						title: message[0],
						text: message[1],
						blanker: true
					});
				} else {
					alert(message.join("\n\n"));
				}
			}

			// END URL login code.

			self._checkLoginStatus(login_callback);

		},

		_doLogin: function(login_callback) {
			var $self = $(this.element);
			var self = this;

			if (!('data' in self.options)) {
				self.options.data = {};
			}

			$self.find('[name]').each(function() {
				self.options.data[$(this).attr('name')] = $(this).val();
			});


			self._attemptLogin(login_callback);
		},

		_beforeLoginSuccess: function(data, status, xhr, callback) {
			var $self = $(this.element);
			var self = this;

			return false;
		},

		_doLoginSuccess: function(data, status, xhr) {
			var $self = $(this.element);
			var self = this;

			var end_callback = function () {
				if (!self._beforeLoginSuccess(data, status, xhr, function() { self._loginSuccess(data, status, xhr); })) {
					self._loginSuccess(data, status, xhr);
				}
			};
			self._callEventHooks('LoginSuccess', end_callback);
		},

		_loginSuccess: function(data, status, xhr) {
			var $self = $(this.element);
			var self = this;

			// debugLog(data);
			// debugLog(status);
			// debugLog(xhr);

			self.options.logged_in = true;
			$self.find('button').removeClass('button-click');
			delete self.options.data;

			self._trigger('LoginSuccess');
			$self.trigger('LoginSuccess');

			if ('login_callback' in self.options && typeof self.options.login_callback == 'function') {
				self.options.login_callback(status, data);
			}

			$('#cui-content').removeAttr('class');

			if (!self.options.inhibit_logged_in_display) {
				$('body').addClass('no-animations logged_in').removeClass('no-animations');
			}

			if (!self.options.show_loading_on_login) {
				self.hideLoadingIndicator();
			}

			if ('status_interval' in self.options && self.options.status_interval > 10) {
				setTimeout(self.options.status_check_closure, self.options.status_interval * 1000);
			}
		},

		_beforeLoginError: function() {
			var $self = $(this.element);
			var self = this;

			return false;
		},

		_doLoginError: function(xhr, status, error) {
			var $self = $(this.element);
			var self = this;

			var end_callback = function () {
				if (!self._beforeLoginError(function() { self._loginError(xhr, status, error); })) {
					self._loginError(xhr, status, error);
				}
			};
			self._callEventHooks('LoginError', end_callback);
		},

		_loginError: function(xhr, status, error) {
			var $self = $(this.element);
			var self = this;

			// debugLog(xhr);
			// debugLog(status);
			// debugLog(error);

			self.options.logged_in = false;
			$self.find('button').removeClass('button-click');

			self.showLogin();
			self.options.$username.focus();

			var error_obj = false;
			if (xhr && !(/<html/.test(xhr.responseText))) {
				error_obj = jQuery.parseJSON(xhr.responseText);
			}
			if (error_obj !== null && typeof error_obj === 'object') {
				var mesg;
				switch (error_obj.error) {
					case "RESTORING":
						mesg = 'This Barracuda Phone System is currently busy. Please try again in a few minutes.';
						break;
					case "LOCKED":
						mesg = 'Your account has been locked (probably from too many unsuccessful login attempts). Please try again in a few minutes.';
						break;
					case "INVALID":
						mesg = 'The username and/or password is incorrect.';
						break;
					case "FORBIDDEN":
						mesg = 'The username and/or password is incorrect.';
						var header = xhr.getResponseHeader('X-Auth-Error');
						if (header === 'LOCKED') {
							mesg = 'Your account has been locked (probably from too many unsuccessful login attempts).';
						}
						break;
					case "NOTAUTHORIZED":
						mesg = false;
						break;
					default:
						mesg = 'There was an unspecified login error';
				}
				if (mesg) {
					self.addMessage('error', 'error', mesg);
				}
			} else {
				self.addMessage('error', 'error', error);
			}

			$('body').removeClass('logged_in');
			self._trigger('LoginError');
			$self.trigger('LoginError');

			if ('login_callback' in self.options && typeof self.options.login_callback == 'function') {
				self.options.login_callback(status);
			}

			self.hideLoadingIndicator();
		},

		addMessage: function(type, name, mesg) {
			var $self = this.element;
			var self = this;

			var $message = false;
			$message = $self.closest('.page_module').find('.message');
			if (!$message.find('#' + name + '-' + type).is('span')) {
				$message.append('<span id="' + name + '-' + type + '" class="' + type + '">' + mesg + '</span> ');
			} else {
				$message.find('#' + name + '-' + type).html(mesg);
			}
			$message.show();
		},

		delMessage: function(type, name, skip_fade) {
			var $self = this.element;
			var self = this;

			var $elem = $self.closest('.page_module').find('#' + name + '-' + type);
			if (skip_fade) {
				$elem.remove();
			} else {
				$elem.hide('fade', {}, 1000);
				setTimeout(function() { $elem.remove(); }, 1000);
			}
		},

		clearMessages: function(skip_fade) {
			var $self = this.element;
			var self = this;

			var $elems = $self.closest('.page_module').find('.message').find('> *');
			if (skip_fade) {
				$elems.remove();
			} else {
				$elems.hide('fade', {}, 1000);
				setTimeout(function() { $elems.remove(); }, 1000);
			}
		},

		isLoggedIn: function() {
			var $self = $(this.element);
			var self = this;

			return self.options.logged_in;
		},

		hideLoadingIndicator: function() {
			var $self = $(this.element);
			var self = this;

			$('#global_loading_indicator').hide();
		},

		showLogin: function() {
			var $self = $(this.element);
			var self = this;

			$('body').addClass('no-animations').removeClass('logged_in').addClass('cui-signin-screen-styles no-header').removeClass('no-animations');
			$('#cui-content').addClass('login');
			$('#cui-content-inner').removeAttr('style');
			self.options.$password.val('');
			self.options.$username.val('');
			$('#cui-login').show();
			self.options.$username.focus();
			$(window).trigger('logout');
		}

	});
	add_widget('loginWidget', loginWidgetClass);
})(jQuery);
