/* jshint jquery: true, unused: vars */
/* global debugLog, add_widget */
(function( $ ){
	var createButtonWidget = $.extend({}, $.ui.widget.prototype, {
		options: {
			detail: false, // Name of the overlay to detail the object
			params: false, // Array of params required for the overlay
			key: false,    // This option and the key it refers to (bbx_something_id) are passed to the overlay function
			accept_data_from_parent: true
		},

		_beforeInit : function () {
			var self = this;
			var $self = this.element;

			var $formWidget = $self.closest('form.widgetType');
			var formWidget = $formWidget.getCUIWidget();
			var $pageWidget = $self.closest('.pageWidgetType');
			var pageWidget = $pageWidget.getCUIWidget();

			// Will we ever not have a dataTableWidget on the left of a create button?
			var $dataTableWidgets = $self.closest('.slide-container').find('.dataTables_wrapper table');

			$formWidget.on('SubmitSuccess', function () {
				if (self.options.key && self.options.detail) {
					var key = self.options.key, overlayOptions = {}, p;

					for (p in self.options.params) {
						var param_name = self.options.params[p];
						overlayOptions[param_name] = formWidget.options.data[param_name];
					}
					overlayOptions.key = key;
					overlayOptions[key] = formWidget.options.data[key];

					pageWidget.showOverlay(self.options.detail, overlayOptions, {}, $self, true, true);
					$dataTableWidgets.each(function() {
						var widget = $(this).getCUIWidget();
						widget.refresh.apply(widget);
					});
				} else {
					debugLog('jquery.createButton.js: Missing "key" or "detail" in options: ', self.options, '. Cannot create the overlay. -- ', $self);
				}
			});
		}
	});

	add_widget('createButtonWidget', $.extend({}, $.ui.widget.prototype, createButtonWidget));
})(jQuery);
