/* jshint jquery: true, browser: true, multistr: true */
/* global CUI, classCUI, debugLog */
/*
 * CUI/Barracuda Generic All-Purpose Blanker - Updated for 3.x

 REQUIRES:
   * For key-based state management, requires jquery.disable.js to be loaded. Otherwise, falls back to only setting the "disabled" attribute.
   * Requires dialog.less CSS file

 BASIC USAGE (default values are listed first):

  new CUI.Dialog({
      // Animate the dialog in/out?
      animate: true | false,

      // Whether the dialog is "always on top"
      important: false | true,

      // Title of the dialog
      title: 'Barracuda Phone System',

      // Body text
      text: false | 'Message goes here',

      // Body HTML/jQ
      body: false | $(...) | '<html content>',

      // Show the progress stripe animation?
      progress: false | true,

      // Icon URL
      icon: false | '/url/of/image',

      // Extra CSS to apply to the icon
      icon_css: false | { 'property' : 'value', ... },

      // A space for showing new contextual information (see USING FLYBY below)
      flyby: false | true | 'Initial text to show',

      // A visible shade behind the dialog
      blanker: false | true,

      // An invisble layer that prevents interacting with the page
      prevent_interaction: true | false,

      // Prevent changing the current page via back/forward buttons
      prevent_navigation: false,

      // An array of button titles
      buttons: ['OK'] | ['Button Zero','Button One',...] | false,

      // The default button that is automatically focused on popup. Zero-based index of the array above
      default_button: 0,

      // Array of callbacks for each button.
      // Run as: fn(buttonIndex) { this == $button; }
      callbacks: [ DialogClass.cancel ] | [ function_zero, function_one, ... ]

      // Alternative callback method-- calls a single callback on all buttons, but the value varies
      // This will overrule and ignore the "callbacks" array
      value_callback: false | function (value, buttonIndex) { this = $button },
      value_callback_args: false | list of args to send to the callback function.
      value_callback_auto_close: true, // Handle closing the dialog automatically (not in the callback)
      values: false | [0, 1, ...],

      // Ping a URL or function on a specified interval until success is indicated
      // Ping a URL every 20 seconds, waiting for a non-error-status response
      //        ping: { time: 20000, url: '/url/to/ping' }

      // Ping a URL every 20 seconds. Process non-error-status response via success_function(returned_data).
      // Clear the blanker only if success_function returns true.
      //        ping: { time: 20000, url: '/url/to/ping', success_function: fn }

      // Run ping_function(clear_callback, $blanker) every 20 seconds. If the function returns true, clear the blanker.
      // If the callback is called, clear the blanker (allows async).
      //        ping: { time: 20000, ping_function: fn }

      ping: false | { (see examples) }
  });



USING FLYBY:
  // The Dialog must be instanced to a variable
  var dialog_instance = CUI.Dialog({
      flyby: '(Optional) Initial Text Goes Here',
      ...
  });

  // When something happens, change the text
  dialog_instance.flyby('New Text to indicate progress');

*/

(function () {
	var DialogClass = function (options) {
		if (this === window) {
			return new CUI.Dialog(options);
		}
		this.options = $.extend(false, this.options, options);
		this._build();
		return this;
	}; // END constructor CUI.Dialog

	DialogClass.common = {
		upload: {
			title: 'Uploading File...',
			text: 'Please wait while your file is uploaded...',
			buttons: false,
			blanker: true,
			progress: true
		}
	};

	DialogClass.prototype = {
		options: {
			animate: true,                               // boolean
			important: false,                            // Cannot be overridden by a later blanker (unless the later blanker is also important)
			name: false,                                 // String name, or false for a random assigned value -- Identifier, not shown
			title: 'Barracuda Phone System',             // Title
			text: false,                                 // Body text
			body: false,                                 // Body HTML/jQuery
			progress: false,                             // true: show operation-in-progress stripes
			icon: false,                                 // Icon URL or false
			icon_css: false,                             // False or CSS object like { top: '50px', left: '50px' }
			flyby: false,                                // boolean
			blanker: false,                              // boolean
			prevent_interaction: true,                   // boolean -- put an invisible layer that prevents interaction on the page (mut. exclusive to "blanker")
			prevent_navigation: false,                   // boolean -- Only valid in "blanker" mode
			buttons: ['OK'],                             // Can also be a string for a single button, or false for no buttons
			default_button: 0,                           // Zero-indexed default button number

			callbacks: [ DialogClass.cancel ],           // Callbacks for each button. Can be one function, if there is only one button.
			// callback(buttonIndex) where "this" is the button. Triggers on the button should bubble up.

			value_callback: false,
			value_callback_auto_close: true, // Handle closing the dialog automatically (not in the callback)
			values: false,

			ping: false,                                 // false, or object (see below)

			/*
	      USAGE of "ping"--

	      // Ping a URL every 20 seconds, waiting for a non-error-status response
	      ping: { time: 20000, url: '/url/to/ping' }

	      // Ping a URL every 20 seconds. Process non-error-status response via success_function(returned_data).
	      // Clear the blanker only if success_function returns true.
	      ping: { time: 20000, url: '/url/to/ping', success_function: fn }

	      // Run ping_function(clear_callback, $blanker) every 20 seconds. If the function returns true, clear the blanker.
	      // If the callback is called, clear the blanker (allows async).
	      ping: { time: 20000, ping_function: fn }
	    */

			// Internal use only...
			_anim_height: '30px',
			_anim_time: 150
		},

		_button_class: 'dialogActionButton',

		flyby: function (text, as_html) {
			var self = this;
			var $self = this.$dialog;
			if (!$self) {
				debugLog('cui.Dialog.js: Attempt to access a destroyed Dialog instance');
				return false;
			}

			$self.find('.flyby').eq(0)[as_html ? 'html' : 'text'](text || '');

			return self;
		}, // END function set_flyby

		remove: function () {
			var self = this;
			var normal = DialogClass._normal;
			var important = DialogClass._important;
			var i;

			// Find a matching regular dialog by looping through the array backwards
			i = normal.length;
			while (i--) {
				if (normal[i] === self) {

					normal[i]._destroy();
					normal.splice(i,1);

					if (i && i === normal.length && !important.length) {
						// If we popped the last item, show the next-to-last
						normal[normal.length - 1]._render_dialog();
					}

					if (!normal.length && !important.length) {
						// If this is the last dialog shown, restore focus
						DialogClass._restore_focus();
					}

					// If we didn't pop the last item, or if there are no more items, or if there is an "important" on the screen,
					// there is no need to do anything else-- everything is as it should be.

					return;
				}
			}

			// Find a matching important dialog, if no regular was found
			i = important.length;
			while (i--) {
				if (important[i] === self) {

					important[i]._destroy();
					important.splice(i,1);

					if (i && i === important.length && !normal.length) {
						important[i - 1]._render_dialog();
					} else if (normal.length) {
						normal[normal.length - 1]._render_dialog();
					} else {
						// There are no more dialogs showing-- restore the focus.
						DialogClass._restore_focus();
						return;
					}
				}
			}
		}, // END function remove

		setButtonState: function (btn_idx, state, key) {
			var self = this, $dialog = self.$dialog, $button = $dialog.find('.' + self._button_class).eq(btn_idx);
			switch (state) {
				case 'enabled':
					if ($.fn.enable) {
						$button.removeClass('state-disabled').enable(key);
					} else {
						$button.removeClass('state-disabled').removeAttr('disabled');
					}
					break;
				case 'disabled':
					if ($.fn.disable) {
						$button.addClass('state-disabled').disable(key);
					} else {
						$button.addClass('state-disabled').attr('disabled', 'disabled');
					}
					break;
			}
		},

		_build: function () {
			var self = this;
			var $dialog = $(DialogClass.$template);
			self.$dialog = $dialog;

			if (self.options.title) {
				$dialog.find('.header').text(self.options.title);
			}

			if (self.options.body) {
				$dialog.find('.text').append($(self.options.body));
			} else if (self.options.text !== false) {
				$dialog.find('.text').text(self.options.text);
			} else {
				$dialog.find('.text').remove();
			}

			if (self.options.progress) {
				$dialog.addClass('progress');
			}

			if (self.options.icon === false) {
				$dialog.find('.icon').remove();
			} else {
				$dialog
					.addClass('has-icon')
					.find('.icon').attr('src', self.options.icon);
				if (self.options.icon_css) {
					$dialog.find('.icon').css(self.options.icon_css);
				}
			}

			if (self.options.flyby === false) {
				$dialog.find('.flyby-wrap').remove();
			} else if (self.options.flyby !== true) {
				self.flyby(self.options.flyby);
			}

			if (self.options.buttons && typeof self.options.buttons === 'string') {
				self.options.buttons = [ self.options.buttons ];
			}

			if (self.options.callbacks && $.isFunction(self.options.callbacks)) {
				self.options.callbacks = [ self.options.callbacks ];
			}

			if (self.options.buttons && self.options.buttons.length) {
				var $buttons = $dialog.find('.CUIDialogButtons');
				var value_callback = self.options.value_callback;

				// Need to use a function factory to make callbacks inside a for-loop.
				var applier = function (fn, args, autoclose) {
					return (function () {
						fn.apply(this, args);
						if (autoclose) {
							DialogClass.cancel.call(this);
						}
					});
				};

				for (var i=0; i<self.options.buttons.length; i++) {
					var button_name = self.options.buttons[i];
					var button_callback = (self.options.callbacks || [])[i];
					var self_value = (self.options.values || [])[i];

					if (value_callback) {
						button_callback = applier(
							self.options.value_callback, [ self_value, i, self.options.value_callback_args ], self.options.value_callback_auto_close
						);
					} else if (button_callback) {
						button_callback = applier(button_callback, [ i ]);
					} else {
						button_callback = DialogClass.cancel;
					}

					$('<button type="button" />')
						.addClass(self._button_class)
						.on('click', button_callback)
						.appendTo($buttons)
						.text(button_name);
				}
			} else {
				$dialog.find('.CUIDialogButtons').remove();
			}

			if (self.options.blanker || self.options.prevent_interaction) {
				$dialog.removeClass('CUIDialogRoot');
				$dialog = self.$dialog = $('<div class="CUIDialogRoot"><div class="CUIDialogBackground">\u00A0</div></div>').append(self.$dialog);
				$dialog.find('> .CUIDialogBackground').addClass(self.options.blanker ? 'CUIDialogBlankerBackground' : 'CUIDialogInvisibleBackground');
			}

			/*
	      USAGE of "ping"--

	      // Ping a URL every 20 seconds, waiting for a non-error-status response
	      ping: { time: 20000, url: '/url/to/ping' }

	      // Ping a URL every 20 seconds. Process non-error-status response via success_function(returned_data).
	      // Clear the blanker only if success_function returns true.
	      ping: { time: 20000, url: '/url/to/ping', success_function: fn }

	      // Run ping_function(clear_callback, $blanker) every 20 seconds. If the function returns true, clear the blanker.
	      // If the callback is called, clear the blanker (allows async).
	      ping: { time: 20000, ping_function: fn }
	    */

			if (self.options.ping) {
				if (!self.options.ping.time) {
					debugLog('cui.Dialog.js: "ping" parameter does not have a "time" attribute. Using the default (5 sec).');
					self.options.ping.time = 5000;
				}
			}

			self.$dialog
				.data('self.options', self.options)
				.one('close_dialog', function () { self.remove(); });

			self._push_dialog();

			return self;
		}, // END function show_dialog


		// For internal use only. To remove a dialog, use ".remove()"
		_destroy: function () {
			var self = this, $dialog = self.$dialog;
			self.$dialog.find('*').off();

			if (self.options.animate) {
				self.$dialog.find('.CUIDialogBackground').hide();
				$dialog.find('.CUIDialog').animate({ 'margin-top': self.options._anim_height, opacity: 0 }, self.options._anim_time, function () {
					$dialog.remove();
				});
			} else {
				self.$dialog.empty().remove();
			}

			self.options = null;
			self.$dialog = null;

			return undefined;
		},


		_push_dialog: function () {
			var self = this;

			var important = DialogClass._important;
			var normal = DialogClass._normal;

			if (document.activeElement && !normal.length && !important.length) {
				DialogClass._save_focus();
			}

			if (normal.length && !important.length) {
				// Remove the current dialog if it is not an "important"-- this is required whether the current is "important"
				normal[normal.length - 1]._background();
			}

			if (self.options.important) {
				if (important.length) {
					// Remove the current "important" dialog, because newer ones are even *more* important
					important[important.length - 1]._background();
				}

				// Add our dialog to the "important" list, and show it
				important.push(self);
				self._render_dialog();
			} else {
				normal.push(self);
				// Only show this if there are no "important" dialogs currently normal
				if (!important.length) {
					self._render_dialog();
				}
			}
		},

		_render_dialog: function () {
			var self = this, $dialog_box = self.$dialog.find('.CUIDialog');

			// This function takes the dialog jQuery object and actually puts it on screen. If there are any actions that need to happen every time
			// a dialog goes on-screen, here's your place to do them.


			if (self.options.animate) { $dialog_box.hide(); }
			self.$dialog.prependTo('body');

			if (self.options.animate) {
				$dialog_box.css({ opacity: 0, 'margin-top': ('-' + self.options._anim_height) });
				$dialog_box.show();
				$dialog_box.animate({ opacity: 1, 'margin-top': 0 }, self.options._anim_time);
			}

			self.is_visible = true;

			if (self.options.buttons && self.options.default_button !== false) {
				var $dbtn = self.$dialog.find('.CUIDialogButtons button');
				if ($dbtn[self.options.default_button]) {
					// T/C because IE throws an error when you focus something that's invisible
					try { $dbtn[self.options.default_button].focus(); } catch (e) { }
				}
			}
			if (self.options.ping) {
				self._ping();
			}

		},

		_background: function () {
			var self = this;
			self.$dialog.detach();
		},

		_ping: function() {
			var self = this;

			if (self.options.ping.ping_function) {
				setInterval(self.options.ping.ping_function, self.options.ping.time);
			}
			else {
				var bounce = function () {
					clearInterval();
					location.reload(true);
				};

				if (self.options.ping.success_function) {
					bounce = self.options.ping.success_function;
				}

				setInterval(function () {
					CUI.getREST(self.options.ping.url, {}, {
						success: bounce,
						error: function (xhr) {
							if (xhr.status == 401 && (xhr.getResponseHeader('X-Reason') != 'Role Failed')) {
								// If it's a permission error, but not a role error, bounce
								bounce();
							}
						}
					});
				}, self.options.ping.time );
			}
		}
	};


	// Static properties and methods

	DialogClass._normal = [];
	DialogClass._important = [];
	DialogClass._saved_focus = [false,false];
	DialogClass.$template = '<div class="CUIDialog CUIDialogRoot">'+
'<img class="icon" />'+
'<div class="header"></div>'+
'<div class="cui-dialog-content">'+
'<div class="text"></div>'+
'<div class="flyby-wrap">'+
'<div class="flyby"></div>'+
'</div>'+
'</div>'+
'<div class="CUIDialogButtons"></div>'+
'</div>';
	DialogClass.cancel = function () { $(this).trigger('close_dialog'); };

	DialogClass._save_focus = function () {
		var focused = document.activeElement, position = false;
		if ($(focused).is('input:text, textarea')) {
			DialogClass._saved_focus[0] = focused;

			if (document.selection) {
				var sel = document.selection.createRange();
				sel.moveStart('character', -focused.value.length);
				position = sel.text.length;
			} else if (typeof focused.selectionStart !== 'undefined') {
				position = focused.selectionStart;
			}

			DialogClass._saved_focus[1] = position;
		} else {
			DialogClass._saved_focus[1] = false;
		}
	}; // END function DialogClass._save_focus

	DialogClass._restore_focus = function () {
		var focus = DialogClass._saved_focus[0], position = DialogClass._saved_focus[1];

		if (focus && focus.focus) {
			// T/C because IE throws an error when you focus something that's invisible
			try { focus.focus(); } catch (e) { }

			if (position !== false) {
				if (focus.setSelectionRange) {
					focus.setSelectionRange(position,position);
				} else if (focus.createTextRange) {
					var range = focus.createTextRange();
					range.collape(true);
					range.moveEnd('character', position);
					range.moveStart('character', position);
					range.select();
				}
			}
		}

		DialogClass._saved_focus = [false,false];
	}; // END function DialogClass.restore_focus

	classCUI.prototype.Dialog = DialogClass;
})();
