register_screen('phone_settings', {
	elements: [
		{ entity_template: 'page_title', title: 'Phones' },
		{ entity_template: 'page_buttons', buttons: [{ type: 'cancel' }, { type: 'save' }] },
		{ entity_template: 'page_content', elements: [
			{
				entity_template: 'page_module',
				legend: 'Extension Guide',
				buttons: ['help'],
				help_id: 'printable_extension_guide',
				requires: ['VIEW_PHONE_SETTINGS'],
				elements: [
					{
						entity_template: 'data_item',
						title: 'Printable Extension Guide:',
						elements: [ {
							entity: 'a',
							attributes: {
								href: '/ajax-html/extension-printable-list.html',
								target: '_blank'
							},
							text: 'Open a printable extension guide'
						} ]
					}

				]
			},
			{
				entity_template: 'form',
				action: '/gui/system/phones',
				submit_all: false,
				requires: ['VIEW_PHONE_SETTINGS'],
				elements: [
					{
						entity_template: 'page_module',
						legend: 'Area Code',
						buttons: [ 'help' ],
						help_id: 'area_code',
						elements: [
							elgen('area_code')
						]
					},
					{
						entity_template: 'page_module',
						legend: 'Ringback Tone:',
						buttons: [ 'help' ],
						help_id: 'ringback_setting',
						elements: [
							{
								entity_template: 'data_item',
								title: 'Ringback Tone:',
								elements: [
									{
										entity: 'select',
										attributes: { name: 'ringback' },
										select_options: [
											{ text: 'Custom (Music on Hold Class)', value: '' },
											{ text: 'Canada', value: 'ca-ring' },
											{ text: 'China', value: 'cn-ring' },
											{ text: 'Cyprus', value: 'cy-ring' },
											{ text: 'Czech Republic', value: 'cz-ring' },
											{ text: 'Germany', value: 'de-ring' },
											{ text: 'Denmark', value: 'dk-ring' },
											{ text: 'Algeria', value: 'dz-ring' },
											{ text: 'Egypt', value: 'eg-ring' },
											{ text: 'Finland', value: 'fi-ring' },
											{ text: 'France', value: 'fr-ring' },
											{ text: 'Hong Kong', value: 'hk-ring' },
											{ text: 'Hungary', value: 'hu-ring' },
											{ text: 'Israel', value: 'il-ring' },
											{ text: 'India', value: 'in-ring' },
											{ text: 'Japan', value: 'jp-ring' },
											{ text: 'Republic of Korea', value: 'ko-ring' },
											{ text: 'Pakistan', value: 'pk-ring' },
											{ text: 'Poland', value: 'pl-ring' },
											{ text: 'Romania', value: 'ro-ring' },
											{ text: 'Serbia', value: 'rs-ring' },
											{ text: 'Russia', value: 'ru-ring' },
											{ text: 'Saudi Arabia', value: 'sa-ring' },
											{ text: 'Turkey', value: 'tr-ring' },
											{ text: 'United Kingdom', value: 'uk-ring' },
											{ text: 'United States', value: 'us-ring' }
										]
									},
									{
										entity: 'select',
										attributes: { name: 'ringback_moh_id' },
										widgets: [ 'selectPopulateWidget', 'aopbStateWidget' ],
										widget_options: {
											selectPopulateWidget: {
												rest: '/gui/moh/class',
												rest_params: { omit_ringing: 1 },
												text_field: 'bbx_moh_name',
												value_field: 'bbx_moh_id'
											},
											aopbStateWidget: {
												states: [{
													conditions: { a: '$ringback', op: '==', b: '_TRUE_' },
													actions: { visible: false, enabled: false, _cascade: true },
													else_actions: { visible: true, enabled: true, _cascade: true }
												}]
											}
										}
									}
								]
							},
							{
								entity_template: 'data_item',
								title: 'Ringback Tone on Transfer:',
								elements: [
									{
										entity: 'select',
										attributes: { name: 'transfer_ringback' },
										select_options: [
											{ text: 'Custom (Music on Hold Class)', value: '' },
											{ text: 'Canada', value: 'ca-ring' },
											{ text: 'China', value: 'cn-ring' },
											{ text: 'Cyprus', value: 'cy-ring' },
											{ text: 'Czech Republic', value: 'cz-ring' },
											{ text: 'Germany', value: 'de-ring' },
											{ text: 'Denmark', value: 'dk-ring' },
											{ text: 'Algeria', value: 'dz-ring' },
											{ text: 'Egypt', value: 'eg-ring' },
											{ text: 'Finland', value: 'fi-ring' },
											{ text: 'France', value: 'fr-ring' },
											{ text: 'Hong Kong', value: 'hk-ring' },
											{ text: 'Hungary', value: 'hu-ring' },
											{ text: 'Israel', value: 'il-ring' },
											{ text: 'India', value: 'in-ring' },
											{ text: 'Japan', value: 'jp-ring' },
											{ text: 'Republic of Korea', value: 'ko-ring' },
											{ text: 'Pakistan', value: 'pk-ring' },
											{ text: 'Poland', value: 'pl-ring' },
											{ text: 'Romania', value: 'ro-ring' },
											{ text: 'Serbia', value: 'rs-ring' },
											{ text: 'Russia', value: 'ru-ring' },
											{ text: 'Saudi Arabia', value: 'sa-ring' },
											{ text: 'Turkey', value: 'tr-ring' },
											{ text: 'United Kingdom', value: 'uk-ring' },
											{ text: 'United States', value: 'us-ring' }
										]
									},
									{
										entity: 'select',
										attributes: { name: 'transfer_ringback_moh_id' },
										widgets: [ 'selectPopulateWidget', 'aopbStateWidget' ],
										widget_options: {
											selectPopulateWidget: {
												rest: '/gui/moh/class',
												text_field: 'bbx_moh_name',
												value_field: 'bbx_moh_id'
											},
											aopbStateWidget: {
												states: [{
													conditions: { a: '$transfer_ringback', op: '==', b: '_TRUE_' },
													actions: { visible: false, enabled: false, _cascade: true },
													else_actions: { visible: true, enabled: true, _cascade: true }
												}]
											}
										}
									}
								]
							}
						]
					},
					{
						entity_template: 'page_module',
						legend: 'User Operator Destination',
						buttons: [ 'help' ],
						help_id: 'operator_extension',
						elements: [
							{
								entity_template: 'data_item',
								title: 'User Operator Destination:',
								elements: [
									{
										entity_template: 'extension_picker_suggest',
										value_key: 'bbx_extension_value',
										name: 'zero_dest',
										allow_none: true
									}
								],
								info: 'This extension is reached when a user dials ' + entity.ldquo + '0' + entity.rdquo
							}
						]
					},

					elgen('auto_provision_page_module'),

					{
						entity_template: 'page_module',
						legend: 'Phone VLAN',
						buttons: [ 'help' ],
						help_id: 'voice_vlan',
						elements: [
							{
								entity_template: 'data_item',
								title: 'Use Voice VLAN:',
								elements: [
									{ entity: 'input',
									  attributes: {
										  type: 'checkbox',
										  name: 'internet_port_vlan_enabled'
									  }
									}
								]
							},
							{
								entity_template: 'data_item',
								title: 'Voice VLAN ID:',
								elements: [
									{
										entity_template: 'aopb_state',
										entity: 'input',
										attributes: {
											type: 'number',
											max: '4095',
											min: '1',
											name: 'internet_port_vlan_id'
										},
										states: [
											{
												conditions: { a: '$internet_port_vlan_enabled', op: '==', b: '_TRUEISH_' },
												actions: { enabled: true },
												else_actions: { enabled: false, invalid: false },
												last_if_false: true
											},
											{
												conditions: { a: '$$', op: '==', b: '1-4095' },
												actions: { invalid: false },
												else_actions: { invalid: 'VLAN ID must be between 1 and 4095' }
											}
										]
									}
								]
							},
							{
								entity_template: 'input_item',
								type: 'checkbox',
								input_name: 'pc_port_vlan_enabled',
								title: 'Use PC Port VLAN:',
								info: 'This feature is not supported on all phone models.'
							},
							{
								entity_template: 'data_item',
								title: 'PC Port VLAN ID:',
								elements: [
									{
										entity_template: 'aopb_state',
										entity: 'input',
										attributes: {
											type: 'number',
											max: '4095',
											min: '1',
											name: 'pc_port_vlan_id'
										},
										states: [
											{
												conditions: { a: '$pc_port_vlan_enabled', op: '==', b: '_TRUEISH_' },
												actions: { enabled: true },
												else_actions: { enabled: false, invalid: false },
												last_if_false: true
											},
											{
												conditions: { a: '$$', op: '==', b: '1-4095' },
												actions: { invalid: false },
												else_actions: { invalid: 'PC Port VLAN ID must be between 1 and 4095' }
											}
										]
									}
								]
							}
						]
					},
					{
						entity_template: 'page_module',
						legend: 'Codecs',
						buttons: [ 'help' ],
						help_id: 'codecs',
						elements: [
							{
								entity_template: 'data_item',
								title: 'Codecs for Inbound Calls:',
								elements: [
									{
										entity_template: 'codec_picker',
										name: 'codecs_inbound'
									}
								]
							},
							{
								entity_template: 'data_item',
								title: 'Codecs for Outbound Calls:',
								elements: [
									{
										entity_template: 'codec_picker',
										name: 'codecs_outbound'
									}
								]
							}

						]
					},
					{
						entity_template: 'page_module',
						legend: 'Intercom',
						buttons: [ 'help' ],
						help_id: 'intercom',
						elements: [
							{
								entity_template: 'input_item',
								title: 'Disable Intercom Calls:',
								info: 'Intercom calling allows a caller to dial **-(extension)-*1 and automatically pick up the recipient' + entity.rsquo + 's speakerphone. Check the box to disable this feature. If disabled, intercom calls can still be enabled for users of specific groups by adding the "Intercom" permission to the group.',
								input_name: 'intercom_disabled',
								type: 'checkbox'
							}
						]
					},
					{
						entity_template: 'page_module',
						legend: 'Voicemail Settings',
						buttons: [ 'help' ],
						help_id: 'voicemail_settings',
						elements: [
							{
								entity_template: 'input_item',
								title: 'Ring Time Before Voicemail:',
								input_name: 'bbx_call_timeout',
								type: 'text',
								info: 'Time in seconds',
								size: 8,
								validate: {
									conditions: '_NUMERIC_NONNEGATIVE_', message: 'Please enter a valid number of seconds'
								}
							},
							{
								entity_template: 'input_item',
								title: 'Voicemail Auto-Login:',
								info: 'Allow users to access their voicemail from their own phone without entering a password.',
								input_name: 'voicemail_authorized',
								type: 'checkbox'
							}
						]
					}
				]
			},
			{
				entity_template: 'page_module',
				legend: 'Cisco Firmware Upload',
				buttons: [ 'help' ],
				help_id: 'cisco_firmware_upload',
				requires: ['VIEW_PHONE_SETTINGS'],
				elements: [
					{
						entity_template: 'data_table',
						name: 'ciscoFirmwareList',
						rest: '/gui/phone/cisco/list',
						rest_params: { sortby: 'model', sortorder: 'asc' },
						data_source: 'list',
						click_action: 'edit',
						page_size: 100000,
						allow_scroll: false,
						paginate: false,
						length_menu: [],
						refresh_on: [
							{ method: 'closest_find', closest: '.page_module', find: '.formWidget', event: 'SubmitSuccess' }
						],
						columns: [
							{
								header: 'Phone Model',
								column: 'model'
							},
							{
								header: 'Firmware File Name',
								column: 'file'
							},
							{
								header: 'Installed',
								column: 'is_installed',
								view_element: {
									entity: 'span',
									widgets: [ 'aopbStateWidget' ],
									widget_options: {
										states: {
											conditions: { a: '@is_installed', op: '==', b: '_TRUEISH_' },
											actions: { value: 'Installed' },
											else_actions: { value: '' }
										}
									}
								}
							}
						],
						actions: [
							{ type: 'add_row', text: 'Upload Firmware' }
						]
					},
					{
						entity_template: 'form',
						action: '/gui/cisco/upload',
						method: 'POST',
						no_initial_get: true,
						clear_file_input_on_success: true,
						dialog_during_submit: {
							buttons: [],
							title: 'Uploading...',
							text: 'Please wait while the file is uploaded...',
							progress: 'true'
						},
						elements: [
							{
								entity_template: 'data_item',
								title: 'Upload a Cisco Firmware ZIP file:',
								elements: [
									{
										entity: 'input',
										attributes: { type: 'file', name: 'upload_file' }
									},
									{
										entity: 'div',
										attributes: { 'class':'info' },
										html: '<p>To use Cisco phones with the Phone System, you must first upload the Cisco phone firmware. You can download the firmware <a target="_blank" href="http://software.cisco.com/download/navigator.html?a=a&i=rpm">directly from Cisco</a> (Downloading firmware updates from the Cisco website will require a Cisco support contract.) Upload the compressed ZIP file, and wait for confirmation.</p>'
									}
								]
							}
						]
					}
				]
			} // END page module Cisco Firmware Update
		]} // END page contents
	]
});
