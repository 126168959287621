/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){
	var duplicateValueWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			closest: 'form',
			source: undefined
		},

		value_widget: true,

		_beforeDOMReady: function () {
			var self = this, $self = this.element, selector = self.options.source;
			self.options.$closest = $self.closest(self.options.closest);

			if (selector.charAt(0) === '$') {
				selector = selector.replace(/^\$(.+)/, '[name=%1]');
			}

			self.options.$source = self.options.$closest.find(selector);
			self.options.$closest.on('change', selector, self._dvwChangeHandler.bind(self));
		},

		_dvwChangeHandler: function () {
			var self = this;
			self.setValue(CUI.getWidgetElementValue(self.options.$source, { first_value: true }));
		}
	});

	add_widget('duplicateValueWidget', duplicateValueWidget);
})(jQuery);
