register_screen('utilities', {
    elements: [
	{ entity_template: 'page_title', title: 'Troubleshooting' },
	{
	    entity_template: 'page_buttons',
	    buttons: [ { type: 'cancel' }, { type: 'save' } ]
	},
	{ entity_template: 'page_content', elements: [
	    {
		entity_template: 'form',
		rest: '/gui/system/tunnel_enabled',
		elements: [

		    elgen('support_tunnel_page_module'),

		    {
			entity_template: 'page_module',
			legend: 'Enable/Disable Tunnel',
	    		buttons: ['help'],
			help_id: 'configuration_utilities_tunnel',
			'class': 'tunnel_enabled',
			elements: [
			    {
				entity_template: 'input_item',
				title: 'Support Tunnel:',
				type: 'select',
				input_name: 'tunnel_enabled',
				select_options: [
				    {
					text: 'Disabled',
					value: ''
				    },
				    {
					text: 'Enabled',
					value: '1'
				    }
				]
			    }
			]
		    }
		]
	    },
	    {
		entity_template: 'page_module',
		legend: 'Utilities',
	    	buttons: ['help'],
		help_id: 'configuration_utilities_settings',
		elements: [
		    {
			entity_template: 'data_item',
			title: 'System Shutdown:',
			elements: [
			    {
				entity_template: 'blanker_button',
				rest: '/gui/system/shutdown',
				method: 'POST',
				text: 'System Shutdown',

				confirm: {
				    text: 'Are you sure you want to shut down your Phone System? All calls will end, and the system will have to be manually re-started.',
				    title: 'System Shutdown',
				    buttons: ['Shut Down', 'Cancel']
				},
				blanker_options: {
				    title: 'Shutdown in progress',
				    text: 'The Barracuda Phone System is being shut down. You may close this browser window.',
				    blanker: true,
				    flyby: false,
				    progress: true,
				    icon: false,
				    buttons: false
				}
			    },
			    {
				entity: 'div',
				text: 'This action will cause all active calls to end, and you will need to manually re-start your Barracuda Phone System before using it again.',
				attributes: {
				    'class': 'info'
				}
			    }
			]
		    },
		    {
			entity_template: 'data_item',
			title: 'System Restart:',
			elements: [
			    {
				entity_template: 'blanker_button',
				rest: '/gui/system/reboot',
				method: 'POST',
				text: 'System Restart',

				confirm: {
				    text: 'Are you sure you want to restart your system? If so, all active calls will be ended, and the system will be unavailable until the restart is complete.',
				    title: 'System Restart',
				    buttons: ['Restart', 'Cancel']
				},

				blanker_options: {
				    title: 'Restart in progress',
				    text: 'The Barracuda Phone System is being restarted.',
				    blanker: true,
				    flyby: false,
				    progress: true,
				    icon: false,
				    buttons: false,
				    ping: { url: '/gui/sysinfo/rebooting', time: 20000 }
				}
			    },
			    {
				entity: 'div',
				text: 'This action will cause all active calls to end.',
				attributes: {
				    'class': 'info'
				}
			    }

			]
		    },
		    {
			entity_template: 'data_item',
			title: 'Restart Telephony Engine:',
			elements: [
			    {
				entity_template: 'action_button',
				rest: '/gui/system/restart_fs',
				method: 'POST',
				text: 'Restart Telephony Engine',

				confirm: {
				    text: 'Are you sure you want to restart the telephony engine? There may be a brief waiting period before new calls can be placed or received.',
				    title: 'Restart Telephony Engine',
				    buttons: ['Restart Telephony Engine', 'Cancel']
				}
			    },
			    {
				entity: 'div',
				text: 'This action will cause all active calls to end.',
				attributes: {
				    'class': 'info'
				}
			    }

			]
		    },
		    {
			entity_template: 'data_item',
			title: 'Reboot All Phones:',
			elements: [
			    {
				entity_template: 'action_button',
				rest: '/gui/system/reboot_phones',
				method: 'POST',
				text: 'Reboot All Phones',
				confirm: {
				    text: 'Are you sure you want to reboot all phones? Phones will be unavailable while they are rebooting.',
				    title: 'Reboot All Phones',
				    buttons: ['Reboot Phones', 'Cancel']
				}
			    },
			    {
				entity: 'div',
				text: 'This action will reboot all phones connected to this Phone System. Phones with existing calls will not be interrupted' + entity.mdash + ' they will restart after the current call is completed. There may, however, be a short wait while the phones start up, during which new calls cannot be placed or received. Phones are restarted in a delayed sequence, so all phones may not restart immediately, or at the same time.',
				attributes: {
				    'class': 'info'
				}
			    }
			]
		    }
		]
	    } // END utilities page module
	]
	}
    ]
});
