/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, entity */
(function( $ ){
	var callflowDescriptionWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {},
		fillData: function (d) {
			var self = this, $self = self.element, action, actions = [], a_idx, actions_text = '', final_text = '', options = [], options_text, o_idx, numbers, n_idx;

			var final_texts = {
				hangup: 'disconnect the call',
				call_registered_phones: 'call my phones',
				transfer: 'transfer the call to %NUM',
				voicemail: 'transfer to extension %NUM voicemail',
				fax: 'wait for a fax',
				voicemail_login: 'transfer to the voicemail login prompt',
				page_group: 'page the group at %NUM',
				echo: 'perform an Echo Test',
				milliwatt: 'play the Milliwatt tone',
				route: 'send the call to a Provider Gateway or Port'
			};

			var options_texts = [
				{ key: 'bbx_callflow_silent_hold', text: 'silent hold' },
				{ key: 'bbx_callflow_record_session', text: 'call recording' },
				{ key: 'bbx_callflow_caller_id', text: 'custom caller ID' },
				{ key: 'bbx_callflow_popup', text: 'URL popup' },
				{ key: 'bbx_callflow_anonymous_call_blocking', text: 'anonymous call blocking' },
				{ key: 'bbx_callflow_detect_fax', text: 'fax detection' },
				{ key: 'bbx_callflow_t38', text: 'T.38 reinvite' }
			];


			if (!d.bbx_callflow_id) { return; }

			// Detail actions...
			if (d.actions && d.actions.length) {
				for (a_idx = 0; a_idx < d.actions.length; a_idx++) {
					action = '';

					switch (d.actions[a_idx].bbx_callflow_action_type) {

						case 'try_phone_numbers':

							// TODO: actually get the numbers once they're available...
							numbers = d.actions[a_idx].number_list;

							if (numbers.length) {
								action = 'call ';

								for (n_idx = 0; n_idx < numbers.length; n_idx++) {
									numbers[n_idx] = CUI.formatter.doFormat({ n: numbers[n_idx] }, { format: { field: '@n', formatter: 'phone_number' } });
								}

								if (numbers.length > 1) {
									numbers[numbers.length - 1] = 'and ' + numbers[numbers.length - 1];
								}

								action += numbers.join(', ');
							}

							break;
						case 'playback':
							action = 'play the sound ' + entity.ldquo + d.actions[a_idx].bbx_sound_name + entity.rdquo;
							break;
						case 'try_calling_registered_phones':
							action = 'try calling my phones';
							break;
						case 'ivr_dialbyname':
							action = 'go to the Dial-by-name Directory';
							break;
						case 'ivr_disa':
							action = 'go to Direct Inward System Access (DISA)';
							break;
						case 'execute_extension':
							action = 'try calling the local extension ' +
								entity.ldquo +
								d.actions[a_idx].extension_show_name +
								entity.rdquo +
								' (' +
								CUI.formatter.doFormat(d.actions[a_idx], { format: { field: '@bbx_extension_value', formatter: 'phone_number' } }) +
								')';
							break;
						case 'campon':
							action = 'try to reach me ' + d.actions[a_idx].bbx_callflow_action_repeats + ' times with ' + entity.ldquo + d.actions[a_idx].bbx_moh_name + entity.rdquo + ' hold music';
							break;
						default:
							action = 'do ' + entity.ldquo + d.actions[a_idx].bbx_callflow_action_type + entity.rdquo + ' (an unknown action)';
					}

					if (action) { actions.push(action); }
				}
			}

			if (actions.length) {
				if (actions.length > 1) {
					actions[actions.length - 1] = 'then ' + actions[actions.length - 1];
				}

				actions_text = actions.join(', ');
				actions_text = actions_text.charAt(0).toUpperCase() + actions_text.slice(1) + '.';
			}

			// Detail final actions...
			switch (d.bbx_callflow_final_action) {
				case 'transfer':
				case 'voicemail':
				case 'page_group':
					var num = CUI.formatter.doFormat({ val: d.bbx_callflow_final_action_extension_value }, { format: { field: '@val', formatter: 'phone_number' } });
					final_text = (final_texts[d.bbx_callflow_final_action] ? final_texts[d.bbx_callflow_final_action].replace(/%NUM/g, num) : final_texts.hangup);
					break;
				default:
					final_text = final_texts[d.bbx_callflow_final_action] || final_texts.hangup;
			}

			final_text = (actions.length ? ('If the call is not answered, ' + final_text + '. ') : (final_text.charAt(0).toUpperCase() + final_text.slice(1) + '. '));

			// Detail options...
			for (o_idx = 0; o_idx < options_texts.length; o_idx++) {
				if (d[options_texts[o_idx].key]) {
					options.push(options_texts[o_idx].text);
				}
			}

			options_text = '';

			if (options.length > 2) {
				options[options.length - 1] = ' and ' + options[options.length - 1];
				options_text = options.join(', ');
			} else if (options.length === 2) {
				options_text = options.join(' and ');
			} else if (options.length) {
				options_text = options[0];
			}

			if (options_text) {
				options_text = options_text.charAt(0).toUpperCase() + options_text.slice(1) + ' are set.';
			}

			$self.text(actions_text + ' ' + final_text + ' ' + options_text);
		}

	});

	add_widget('callflowDescriptionWidget', callflowDescriptionWidget);
})(jQuery);
