/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, widgetize_children */
/*

  A container that renders its children only after sufficent data has been received, and sets the child rest_params to parent-data items as specified.

  OPTIONS:
  {
    // The widget will only render the child element once all keys in data_to_rest_params have been filled
    data_to_rest_params: ['key', ...]
  }

  CAVEATS:
  * This will only set a give rest_params entry the first time data is recieved that matched the data_to_rest_params entry. Subsequent matching "fillData" will
    be ignored, and the rest_params entry will not change.

  * The container will not be rendered until all data_to_rest_params entries are filled.
*/

(function( $ ){
	var dataToRestParamsContainerWidget = $.extend(true, {}, CUI.htmlEntityClass, $.ui.widget.prototype, CUI.aopbParser, {
		set_value_widget: true,

		options: {
			// data_to_rest_params: [ ... ]
			rest_params: {}
		},

		_beforeInit: function () { },

		fillData: function (d, from_self) {
			var self = this, $self = this.element, dr_idx, dr, incomplete;

			if (self.options._children_widgetized_ok) {
				self.fillDataChildren(d, from_self);
				return;
			}

			for (dr_idx = 0; dr_idx < self.options.data_to_rest_params.length; dr_idx++) {
				dr = self.options.data_to_rest_params[dr_idx];
				if (dr in d) {
					self.options.rest_params[dr] = d[dr];
				} else if (!(dr in self.options.rest_params)) {
					incomplete = true;
				}
			}

			if (!incomplete) {
				self._buildContainer(d, from_self);
			}
		},

		setValue: function () { },

		_buildContainer: function (d, from_self) {
			var self = this, $self = this.element, $content, $widgets, w_idx, w_opts, $w;

			$content = self.getEntitiesHTML(self.options.elements, self.options.data);
			$self.empty().append($content.contents());
			$widgets = $self.findNearest('.widgetType:not(.widgetized)');

			if ($widgets[0]) {
				// Add new rest_params via .data('extra_widget_options')
				for (w_idx = 0; w_idx < $widgets.length; w_idx++) {
					$w = $widgets.eq(w_idx);
					w_opts = $w.data('extra_widget_options') || {};
					w_opts.rest_params = $.extend(w_opts.rest_params || {}, self.options.rest_params || {});
					$w.data('extra_widget_options', w_opts);
				}

				widgetize_children($self);
				self.options._children_widgetized_ok = true;
				self.fillDataChildren(d || {}, from_self);
			}		

			// Trigger this event so formWidgets know to re-scan for dirtiness
			$self.trigger('formElementChange');
		}
	});

	add_widget('dataToRestParamsContainerWidget', dataToRestParamsContainerWidget);
})(jQuery);
