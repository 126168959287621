register_screen('user_import', {
    elements: [
	{ entity_template: 'page_title', title: 'User Import' },
	{ entity_template: 'page_buttons', buttons: [{ type: 'cancel' }, { type: 'save' }] },
	{ entity_template: 'page_content', elements: [
	    {
		entity_template: 'form',
		action: '/gui/user_import/upload',
		submit_all: true,
		no_initial_get: true,
		force_reset: true,
		dialog_during_submit: {
		    buttons: [],
		    title: 'Uploading...',
		    text: 'Please wait while the file is uploaded...',
		    progress: 'true'
		},
		dialog_after_success: {
		    title: 'Import Complete',
		    text: 'The import was completed successfully. See the Extensions page for the newly-added extensions.'
		},
		elements: [
		    {
			entity_template: 'page_module',
			legend: 'Upload CSV File',
			buttons: [ 'help' ],
			help_id: 'import_users_csv',
			elements: [
			    {
				entity_template: 'input_item',
				title: 'Specify a CSV file:',
				
				/* TODO: Replace this with better descriptive text */
				validate: { conditions: '_TRUE_', message: 'Select a file' },
				info: 'Upload a CSV file with names and information about users. See your Administrator' + entity.rsquo + 's Guide for more information on properly formatting the CSV file.',
				type: 'file',
				input_name: 'upload_file'
			    },
			    {
				entity_template: 'input_item',
				type: 'checkbox',
				input_name: 'ignore_first_row',
				title: 'Ignore First Row:',
				info: 'If the first row of the CSV file contains labels that should not be processed, check this option.'
			    },
			    {
				entity_template: 'data_item',
				title: 'Downloadable CSV Template:',
				elements: [
				    {
					entity: 'a',
					attributes: { href: '/ajax-html/Barracuda_Phone_User_Import.csv' },
					text: 'CSV File Template'
				    },
				    {
					entity: 'div',
					attributes: { 'class': 'info' },
					text: 'Use this template to construct a valid CSV file. Use the '+entity.ldquo+'Ignore First Row'+entity.ldquo+' option if you retain the label row. See the Help documentation for this page for more information.'
				    }
				]
			    }
			]
		    }
		]
	    } // END page module
	]} // END page contents
    ]
});
