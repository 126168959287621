/* jshint jquery: true, browser: true */
/* global classCUI */
/*
  CUI getTemplate: Handles loading and caching HTML snippets
  REQUIRES cui.ajax.js
*/

classCUI.prototype.templates = {};
classCUI.prototype.templateLoadStatus = {};

classCUI.prototype.getTemplate = function (url, callback) {
	var self = this;

	if (self.templateLoadStatus[url]) {
		if (self.templates[url]) {
			callback(self.templates[url]);
		} else {
			var interval = setInterval(function () {
				if (self.templates[url]) {
					clearInterval(interval);
					callback(self.templates[url]);
				}
			}, 20);
		}
	} else {
		self.templateLoadStatus[url] = 1;
		var inline = $('div.widgetTemplates div[templatefile=\''+url+'\']');
		if (inline[0]) {
			self.templates[url] = inline[0].innerHTML;
			self.templateLoadStatus[url] = 2;
			callback(inline[0].innerHTML);
		} else {
			self.ajax({url: url,
					   cache: false,
					   type: 'GET',
					   dataType: 'html',
					   success: function (html) {
						   self.templates[url] = html;
						   self.templateLoadStatus[url] = 2;
						   callback(html);
					   }});
		}
	}
};
