(function () {
    register_screen('messages', {
	elements: [
	    { entity_template: 'page_title', title: 'Voicemail and Fax' },
	    {
		entity_template: 'page_buttons',
		buttons: [
		    {
			type: 'help',
			help_id: 'voice_mail_and_fax'
		    }
		]
	    },
	    {
		entity_template: 'page_content', elements: [
		    {
			entity_template: 'page_module',
			legend: 'Received Voicemail and Faxes',
			elements: [
			    {
				entity_template: 'data_table',
				null_value: '_OMIT_',
				on_event: {
				    event_name: 'user_notify',
				    match_any_keys: {
					inbox_refresh: true,
					vm_action: 'leave_message'
				    },
				    action: 'refresh'
				},
				'class': 'msgs-in-table',
				rest: '/gui/voicemailfax/list',
				rest_params: { sortby: 'created_epoch', sortorder: 'desc' },
				primary_keys: ['voicemail_msgs_id','bbx_fax_inbound_id'],
				extra_row_data: ['bbx_extension_value','voicemail_msgs_id','bbx_fax_inbound_id','read_epoch','seconds','bbx_fax_inbound_pages_total','type'],
				page_size: 10,
				oLanguage: {
				    sZeroRecords: 'No Received VMs or Faxes'
				},
				columns: [
				    {
					column: 'type',
					width: '15px',
					'class': 'center nowrap',
					sortable: false,
					view_element: {
					    entity_template: 'translate',
					    render: 'html',
					    name: 'type',
					    translations: {
						FAX: CUI.sprite('fax_message', 'Fax', 'Fax', true),
						VOICEMAIL: CUI.sprite('vm_message', 'Voicemail', 'Voicemail', true)
					    },
					    default_translation: '',
					    undefined_translation: ''
					}
				    },
				    {
					header: 'To',
					column: 'username',
					width: '3em',
					'class': 'nowrap',
					formatted: { format: { field: '@bbx_extension_value', formatter: 'phone_number' } }
				    },
				    {
					header: 'Caller Name',
					column: 'cid_name',
					formatted: { format: { field: '@cid_name', formatter: 'cid_name' } }
				    },
				    {
					column: 'cid_number',
					header: 'Caller Number',
					view_element: {
					    entity_templates: ['form_dialog_link','format'],
					    form_dialog_link: {
						accept_data_from_parent: true,
						title: 'Call',
						submit_button: 'Call',
						disabled: false,
						include_data_as: { cid_number: 'destination' },
						rest: '/gui/freeswitch/originate',
						elements: [
						    {
							entity: 'span',
							attributes: { 'class': 'always-dirty-indicator' },
							text: 'Would you like to call this number? Your phone will ring, then you will be connected to the destination.'
						    }
						]
					    },
					    format: {
						format: { field: '@cid_number', formatter: 'phone_number' }
					    }
					}
				    },
				    {
					header: 'Length',
					column: 'length',
					width: '5em',
					'class': 'nowrap',
					sortable: false,
					formatted: {
					    format: '$1$2',
					    fields: [
						{
						    field: '@bbx_fax_inbound_pages_total',
						    formatter: 'plural',
						    options: { plurals: ['pages','page','pages'], abort_undefined: true, abort_null: true }
						},
						{
						    field: '@seconds',
						    formatter: 'seconds_duration',
						    options: { seconds_duration: { abort_undefined: true, abort_null: true } }
						}
					    ]
					}
				    },
				    {
					header: 'Time',
					column: 'created_epoch',
					formatted: { format: { field: '@created_epoch', formatter: 'epoch', options: { format: '%Y-%0M-%0d %H:%0m:%0s' } } },
					data_type: 'numeric',
					sort_data_type: 'numeric-asc'
				    },
				    {
					header: 'Status',
					width: '5em',
					column: 'read',
					view_elements: [{
					    entity_template: 'read_unread'
					}]
				    },
				    {
					'class': 'small-button-margins nowrap',
					width: '130px',
					view_element: {
					    entity_template: 'aopb_container',
					    accept_data_from_parent: true,
					    states: [
						{
						    conditions: { a: '@bbx_fax_inbound_id', op: '==', b: '_TRUEISH_' },
						    elements: [
							{
							    entity_template: 'fax_view'
							}
						    ],
						    last_if_true: true
						},
						{
						    conditions: { a: '@voicemail_msgs_id', op: '==', b: '_TRUEISH_' },
						    elements: [
							{
							    entity_template: 'sound_player',
							    url: '/gui/voicemail/file',
							    include_keys: ['voicemail_msgs_id']
							}
						    ],
						    else_elements: [{ entity: 'div', text: 'Unknown type' }]
						}
					    ]
					}
				    },
				    {
					'class': 'small-button-margins nowrap',
					width: '20px',
					view_element: {
					    entity_template: 'aopb_container',
					    states: [
						{
						    conditions: { a: '@bbx_fax_inbound_id', op: '==', b: '_TRUEISH_' },
						    elements: [
							{
							    entity_template: 'form_dialog_button',
							    text: 'Forward',
							    rest: '/gui/fax/forward',
							    method: 'POST',
							    include_data: ['bbx_fax_inbound_id'],
							    elements: [{
								entity_template: 'extension_picker_select',
								allow_nothing: false,
								omit_undefined: true,
								perform_lookup: false,
								type: ['user','group'],
								value_key: ['bbx_user_id','bbx_group_id']
							    }]
							}
						    ],
						    last_if_true: true
						},
						{
						    conditions: { a: '@voicemail_msgs_id', op: '==', b: '_TRUEISH_' },
						    elements: [
							{
							    entity_template: 'form_dialog_button',
							    text: 'Forward',
							    rest: '/gui/voicemail/forward',
							    method: 'POST',
							    include_data: ['voicemail_msgs_id'],
							    elements: [{
								entity_template: 'extension_picker_select',
								allow_nothing: false,
								omit_undefined: true,
								perform_lookup: false,
								type: ['user','group'],
								value_key: ['bbx_user_id','bbx_group_id']
							    }]
							}
						    ],
						    else_elements: [{ entity: 'div', text: 'Unknown type' }]
						}
					    ]
					}
				    }
				],
				delete_action: {
				    rest: '/gui/voicemailfax/item',
				    method: 'DELETE'
				},
	
				table_actions: {
				    delete_rows: {
					text: 'Delete Selected Messages',
					location: 'bottom',
					confirm: {
					    title: 'Delete Voicemail and Fax Messages',
					    text:  'The selected messages will be deleted. This operation cannot be undone.'
					}
				    },
				    select_row: true,
				    select_all_rows: true
				}
			    } // END DTW
			] // END page_module elements
		    }, // END page_module "Voicemail and Fax Inbox"


		    {
			entity_template: 'page_module',
			legend: 'Sent Faxes',
			elements: [
			    {
				entity_template: 'data_table',
				'class': 'faxOutTable',
				null_value: '_OMIT_',

				live_table: 'bbx_fax_outbound',
				live_table_unique_ident: true,
				js_sort_by: [[ 'bbx_fax_outbound_timestamp_queued', 'desc' ]],
				search_user_id: 'bbx_user_id',
				primary_keys: ['bbx_fax_outbound_id'],
				extra_row_data: ['bbx_fax_outbound_max_attempts','bbx_fax_outbound_attempt_number','bbx_fax_outbound_id','bbx_fax_outbound_pages_total','bbx_fax_outbound_error_string'],
				page_size: 10,
				sort_by: 'answer_timestamp',
				sort_order: 'desc',
				oLanguage: {
				    sZeroRecords: 'No sent faxes found'
				},
				columns: [
				    {
					header: 'From',
					column: 'bbx_fax_outbound_fax_from',
					formatted: { format: { field: '@bbx_fax_outbound_fax_from', formatter: 'phone_number' } }
				    },
				    {
					header: 'To',
					width: '3em',
					'class': 'nowrap',
					column: 'bbx_fax_outbound_fax_to',
					formatted: { format: { field: '@bbx_fax_outbound_fax_to', formatter: 'phone_number' } }
				    },
				    {
					header: 'Length',
					width: '5em',
					'class': 'nowrap',
					column: 'bbx_fax_outbound_pages_total',
					formatted: {
					    format: {
						field: '@bbx_fax_outbound_pages_total',
						formatter: 'plural',
						options: { plurals: ['pages','page','pages'] }
					    }
					}
				    },
				    {
					header: 'Sent Time',
					column: 'bbx_fax_outbound_timestamp_queued',
					formatted: { format: { field: '@bbx_fax_outbound_timestamp_queued', formatter: 'match', options: { regexp: '[^.]+' } } },
					data_type: 'string'
				    },
				    {
					header: 'Delivered Time',
					column: 'bbx_fax_outbound_timestamp_completed',
					formatted: { format: { field: '@bbx_fax_outbound_timestamp_completed', formatter: 'match', options: { regexp: '[^.]+' } } },
					data_type: 'string'
				    },
				    {
					header: 'Status',
					width: '5em',
					column: 'bbx_fax_outbound_success',
					view_element: {
					    entity_template: 'aopb_container',
					    accept_data_from_parent: true,
					    states: [
						{
						    conditions: { a: '@bbx_fax_outbound_success', op: '==', b: '^[t1]$', b_is: 'regexp' },
						    elements: [{
							entity: 'span',
							attributes: {
							    style: 'color #000'
							},
							text: 'Sent'
						    }],
						    last_if_true: true
						},
						{
						    conditions: { a: '@bbx_fax_outbound_attempt_number', op: '>=', b: '@bbx_fax_outbound_max_attempts' },
						    elements: [{
							entity_template: 'format',
							entity: 'span',
							attributes: {
							    style: 'color: #c00'
							},
							format: 'Failed: $1',
							fields: [
							    { field: '@bbx_fax_outbound_error_string' }
							]
						    }],
						    else_elements: [{
							entity: 'span',
							attributes: {
							    style: 'color: #aaa'
							},
							text: 'Queued'
						    }]
						}
					    ]
					}
				    },
				    {
					'class': 'small-button-margins nowrap center',
					width: '130px',
					view_element: {
					    entity_template: 'fax_view'
					}
				    },
				    {
					'class': 'small-button-margins nowrap',
					width: '50px',
					view_element: {
					    entity_template: 'form_dialog_button',
					    text: 'Forward',
					    rest: '/gui/fax/forward',
					    method: 'POST',
					    include_data: ['bbx_fax_outbound_id'],
					    elements: [{
						entity_template: 'extension_picker_select',
						allow_nothing: false,
						omit_undefined: true,
						perform_lookup: false,
						type: ['user','group'],
						value_key: ['bbx_user_id','bbx_group_id']
					    }]
					}
				    }
				],
				delete_action: {
				    rest: '/gui/voicemailfax/item'
				},
				table_actions: {
				    delete_rows: {
					text: 'Delete Selected Faxes',
					location: 'bottom',
					confirm: {
					    title: 'Delete Faxes',
					    text:  'The selected outbound faxes will be deleted. Faxes that are queued or failed and waiting to be re-sent will not be sent.'
					}
				    },
				    select_row: true,
				    select_all_rows: true
				}
			    } // END DTW
			] // END page_module elements
		    } // END page_module "Fax Outbox"
		] // END page_content elements
	    } // END page_content
	] // END template elements
    }); // END template
})(); // END scoping function
