/* jshint jquery: true, unused: vars */
/* global add_widget, entity */
(function( $ ){

	var channel_maps = {
		// [ LAST, SKIP ]
		e1: [ 31, 15 ],
		t1: [ 23, false ]
	};

	var tdmChannelWidget = $.extend({}, $.ui.widget.prototype, {

		value_widget: true,
		set_value_widget: true,
		manages_own_descendent_events: true,
		manages_own_descendent_value: true,

		options: {
			template: false,
			template_html: '<div class="tdmChannelWrap" style="margin-right: 15px"><div class="tdmChannelDisplay"></div><div class="tdmSlider"></div></div>',

			// Options you'll probably never touch
			send_empty_for_all: true,

			// How to determine which signal type we're using:
			signal_selector_closest: 'form',
			signal_selector: 'select[name=bbx_tdm_card_port_signal_type]',

			// Signal types that use the "euro" channel layout. All others use T1
			signal_types: {
				e1: ['euroisdn'],
				t1: ['national','5ess','dms100']
			},

			// Internal use
			_signal_type: false,
			_value: undefined
		},

		_calcInternalValue: function (str_val) {
			var self = this;
			var cm = channel_maps[self.options._signal_type];
			var right_value = str_val.match(/\d+$/) || cm[0];
			var gap = (cm[1] && right_value >= cm[1]) ? 1 : 0;

			return right_value ? right_value[0]-gap : cm[0]-gap;
		},

		_setSignalType: function (signal_type) {
			var self = this;
			var $self = this.element;

			signal_type = signal_type || $self
			.closest(self.options.signal_selector_closest || 'form')
			.find(self.options.signal_selector || 'select[name=bbx_tdm_card_port_signal_type]')
			.val();

			scanLoop:
			for (var k in self.options.signal_types) {
				for (var i in self.options.signal_types[k]) {
					if (signal_type === self.options.signal_types[k][i]) {
						var old_type = self.options._signal_type;
						if (k !== old_type) {
							self.options._signal_type = k;
						}

						break scanLoop;
					}
				}
			}

		},

		_trackSignalType: function () {
			var self = this;
			var $self = this.element;

			var $sel = $self
			.closest(self.options.signal_selector_closest || 'form')
			.find(self.options.signal_selector || 'select[name=bbx_tdm_card_port_signal_type]');

			$sel.on('change', function () {
				self._setSignalType($sel.val());
				self._rebuildSlider();
				$self.trigger('change');
			});

			$sel.closest('form').on('formReset', function () {
				var val = self.options._value;
				self._setSignalType($sel.val());
				self._rebuildSlider( self._calcInternalValue(val) );
				$self.trigger('change');
			});
			self._setSignalType($sel.val());
		},

		_rebuildSlider: function (internal_value) {
			var self = this;
			var $self = this.element;

			var ns = self.options.widget_id; // Namespace for binds

			var cm = channel_maps[self.options._signal_type];
			var internal_max = cm[0] - (cm[1] ? 1 : 0);

			// "Internal" value param should be -1 if past the gap (hence "internal" value used by the slider)
			var value = internal_value || internal_max;
			var $slider = $('.tdmSlider', $self);

			if ($slider.is('.ui-slider')) {
				$slider.slider('destroy');
			}

			$('.tdmSlider', $self).slider({
				value: value,
				min: 1,
				max: internal_max,
				range: 'min',
				step: 1
			})
			.off('slide.' + ns)
			.off('slidechange.' + ns)
			.on('slide.' + ns, function (e,ui) {
				var value = ui.value;
				var value_out = '';

				if (cm[1] && (value >= cm[1])) {
					value_out = '1-' + (cm[1]-1) + ',' + (cm[1] + 1) + '-' + (value + 1);
				} else {
					value_out = '1-' + value;
				}

				var all_selected = false;

				if (value === internal_max) {
					all_selected = true;
				}

				$self.find('.tdmChannelDisplay').text(value_out.replace(/-/g, entity.ndash).replace(/,/, ', ') + (all_selected ? ' (All Channels)' : ''));

				if (self.options.send_empty_for_all && all_selected) {
					self.options._value = '';
				} else {
					self.options._value = value_out;
				}
			})
			.on('slidechange.' + ns, function (e,ui) {
				if (e.originalEvent) {
					$self.trigger('change');
				}
			})
			.triggerHandler('slide', [{ value: value, initializing: true }]);
		},

		_beforeDOMReady: function () {
			var self = this;
			var $self = this.element;

			self._trackSignalType();
		},

		_getWidgetValue: function () {
			return this._wrapValue(this.options._value);
		},

		setValue: function (value) {
			var self = this;
			var $self = this.element;

			self._setSignalType();
			self._rebuildSlider( self._calcInternalValue(value) );
		}
	});

	add_widget('tdmChannelWidget', tdmChannelWidget);
})(jQuery);
