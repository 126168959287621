/* jshint jquery: true, browser: true */
/* global classCUI */
(function () {
    var applyValid = function ($elem, error_keys) {
	if (error_keys.length) {
	    $elem.addClass('is-invalid');
	    $elem.removeClass('is-valid');
	    $elem.data('error', error_keys[error_keys.length - 1][1]);
	} else {
	    $elem.removeClass('is-invalid');
	    $elem.addClass('is-valid');
	    $elem.removeData('error');
	}
    };
    
    classCUI.prototype.setValid = function ($elem, key) {
	var error_keys, ik_idx;
	key = key || 'NO_KEY';
	
	error_keys = $elem.data('error_keys') || [];
	ik_idx = error_keys.length;
	while (ik_idx--) {
	    if (error_keys[ik_idx][0] === key) {
		error_keys.splice(ik_idx, 1);
	    }
	}
	
	$elem.data('error_keys', error_keys);
	applyValid($elem, error_keys);
    };
    
    classCUI.prototype.forceValid = function ($elem) {
        $elem.data('error_keys', []);
        applyValid($elem, []);
    };
    
    classCUI.prototype.setInvalid = function ($elem, error, key) {
	var error_keys;
	key = key || 'NO_KEY';
	error_keys = $elem.data('error_keys') || [];
	error_keys.push([key, error]);
	$elem.data('error_keys', error_keys);
	applyValid($elem, error_keys);
    };
    
})();
