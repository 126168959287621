/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){
	var mohPickerWidget = $.extend({}, $.ui.widget.prototype, {

		options: {
			name: 'bbx_moh_id',   // Form-element NAME of the selector
			id: false  ,           // ID of the selector (if false, omitted)
			accept_data_from_parent: true
		},

		_beforeDOMReady: function (cb) {
			var $elem = $(this.element);
			var my = this;

			$elem.html('<select><option></option></select>'); // Dummy one in for now

			CUI.getREST('/gui/moh/class', function (d) {
				var $sel = $('<select class="mohPicker"></select>');
				$sel.attr('name', my.options.name);

				if (my.options.id) {
					$sel.attr('id', my.options.id);
				}

				$.each(d['class'], function (idx, cl) {
					$('<option></option>').val(cl.bbx_moh_id).text(cl.bbx_moh_name).appendTo($sel);
				});

				$sel.appendTo($elem.empty());

				cb();
			});

			return true; // Deferred
		}
	});

	add_widget('mohPickerWidget', $.extend({}, $.ui.widget.prototype, mohPickerWidget));
})(jQuery);
