/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/* This class is meant to be used as a base class by other page button widgets. */

(function( $ ){
	var pageButtonWidget = $.extend({}, $.ui.widget.prototype, {
		options: {
			href: '#'
		},

		_DOMReady: function () {
			var self = this, $self = this.element;
			self.options.$pageWidget = $self.closest('.pageWidgetType');
			self._one(self.options.$pageWidget, 'screenReady', function() {
				self.options.$screen = $self.closest('.screen, .overlay');
				self.options.$forms = self.options.$screen.find('form');
				self.options.$dtws  = self.options.$screen.find('table.dataTableWidget');

				self._bind(self.options.$pageWidget, 'pageSubmitStart', self._submitStartHandler.bind(self));
				self._bind(self.options.$pageWidget, 'pageSubmitComplete', self._submitCompleteHandler.bind(self));

				self._bind(self.options.$forms, 'dirty undirty validation formReset SubmitSuccess', self._formChange.bind(self));
				self._bind(self.options.$dtws, 'DeleteSuccess validation', self._formChange.bind(self));

				self._bind(self.options.$screen, 'variableContainerChange', self._formCheck.bind(self));
				self._formChange();
			});
			$.ui.widget.prototype._DOMReady.call(self);
		},

		_submitStartHandler: function () {
			var self = this, $self = this.element;
			self.disable('saving');
		},

		_submitCompleteHandler: function () {
			var self = this, $self = this.element;
			self.enable('saving');
		},

		_formChange: function (e) {
			var self = this, $self = this.element;

			var $dirty_forms = self.options.$screen.find('form.is-dirty');
			var $invalid_forms = self.options.$screen.find('form.is-invalid');
			var $invalid_dtws  = self.options.$screen.find('div.dataTables_wrapper.is-invalid');

			if ($dirty_forms[0]) {
				self.options.dirty = true;
			} else {
				self.options.dirty = false;
			}
			if ($invalid_forms[0] || $invalid_dtws[0]) {
				self.options.invalid = true;
			} else {
				self.options.invalid = false;
			}

			if (self._afterFormChange) {
				self._afterFormChange();
			}
		},

		_formCheck: function () {
			var self = this, $self = this.element;

			var $forms = self.options.$screen.find('form');
			var $dtws  = self.options.$screen.find('table.dataTableWidget');
			if (!CUI.compareObjects(self.options.$forms, $forms)) {
				self._bind($forms, 'dirty undirty validation formReset SubmitSuccess', self._formChange.bind(self));
				self.options.$forms = $forms;
			}
			if ($dtws.length != self.options.$dtws.length) {
				self._bind($dtws, 'dirty undirty validation', self._formChange.bind(self));
				self.options.$dtws = $dtws;
			}
		}
	});

	add_widget('pageButtonWidget', 'ui.pageButtonWidget', 'pageButtonWidget', pageButtonWidget);
})(jQuery);
