/* jshint jquery: true, browser: true, unused: vars */
/* global CUI, classCUI */
classCUI.prototype.widgetBaseRestExtend = new function () {
	this.restDataEventMap = $.extend({}, {
		"/gui/extension/user" : {
			bind : {
				"user" : "bbx_user_id",
				"extension" : "bbx_extension_id"
			},
			trigger : "user extension"
		},
		"/gui/extension/group" : {
			bind : {
				"group" : "bbx_group_id",
				"extension" : "bbx_extension_id"
			},
			trigger : "group extension"
		},
		"/gui/extension/valet_parking" : {
			bind : {
				"valet_parking" : "bbx_valet_parking_id",
				"extension" : "bbx_extension_id"
			},
			trigger : "valet_parking extension"
		},
		"/gui/extension/queue" : {
			bind : {
				"queue" : "bbx_queue_id",
				"extension" : "bbx_extension_id"
			},
			trigger : "queue extension"
		},
		"/gui/extension/conference" : {
			bind : {
				"conference" : "bbx_conference_id",
				"extension" : "bbx_extension_id"
			},
			trigger : "conference extension"
		},
		"/gui/extension/phone" : {
			bind : {
				"phone" : "bbx_phone_id",
				"extension" : "bbx_extension_id"
			},
			trigger : "phone extension"
		},
		"/gui/sound/category" : {
			bind : {
				"sound_category" : "bbx_sound_category_id"
			},
			trigger : "sound_category"
		},
		"/gui/reporting/report" : {
			bind : {
				"reporting" : "bbx_reporting_id"
			},
			trigger : "reporting"
		}
	});

	this.doREST = function (method, url, data, callbackOrOptions) {
		var self = this;

		if (method != "GET" && !self.options.noDataEventTrigger) {
			callbackOrOptions = self._restDataTrigger(method, url, data, callbackOrOptions);
		}

		CUI.doREST(method, url, data, callbackOrOptions);

		if (method == "GET") {
			self._restDataBind(url, data, callbackOrOptions);
		}
	};

	this.getREST = function (url, params, callbackOrOptions, expireSeconds) {
		var self = this;

		CUI.getREST(url, params, callbackOrOptions, expireSeconds);

		self._restDataBind(url, params, callbackOrOptions);
	};

	this.putREST = function(url, data, callbackOrOptions) {
		var self = this;

		if (!self.options.noDataEventTrigger) {
			callbackOrOptions = self._restDataTrigger("PUT", url, data, callbackOrOptions);
		}

		CUI.putREST(url, data, callbackOrOptions);
	};

	this.postREST = function(url, data, callbackOrOptions) {
		var self = this;

		if (!self.options.noDataEventTrigger) {
			callbackOrOptions = self._restDataTrigger("POST", url, data, callbackOrOptions);
		}

		CUI.postREST(url, data, callbackOrOptions);
	};

	this.deleteREST = function(url, data, callbackOrOptions) {
		var self = this;

		if (!self.options.noDataEventTrigger) {
			callbackOrOptions = self._restDataTrigger("DELETE", url, data, callbackOrOptions);
		}

		CUI.deleteREST(url, data, callbackOrOptions);
	};

	this._restDataBind = function (url, params, callbackOrOptions) {
		var self = this;

		if (!self.options.dataEvents && self.restDataEventMap[url]) {
			self.options.dataEvents = $.extend({}, self.restDataEventMap[url]);
		}

		// Unbind and Rebind tags for dataEvent callbacks.
		if (self.options.dataEvents && self.options.dataEvents.bind) {
			var rest_params;

			if (typeof params == "object") {
				rest_params = $.extend({}, self.options.rest_params, params);
			} else {
				rest_params = $.extend({}, self.options.rest_params);
			}

			CUI.dataEvents.unbind(self);
			self.options.dataEvents.tags = {};

			for (var tags in self.options.dataEvents.bind) {
				if (self.options.dataEvents.bind[tags]) {
					if (typeof self.options.dataEvents.bind[tags] == "string") {
						self.options.dataEvents.bind[tags] = self.options.dataEvents.bind[tags].split(/\s+/);
					}

					// Set tags with no matches.
					self.options.dataEvents.tags[tags] = "";

					// If we have a rest_params for matching, set values to tags match object.
					if (self.options.dataEvents.bind[tags] instanceof Array) {
						var match = {};
						var matchParams = self.options.dataEvents.bind[tags];
						var param_match;

						for (var i=0; i<matchParams.length; i++) {
							if (rest_params[matchParams[i]]) {
								match[matchParams[i]] = rest_params[matchParams[i]]; 
							}
						}

						for (param_match in match) {
							self.options.dataEvents.tags[tags] = match;
							break;
						}
					}
				} else if (typeof self.options.dataEvents.bind[tags] == "string") {
					self.options.dataEvents.tags[tags] = "";
				}
			}

			// Bind event callback to _doDataFill
			CUI.dataEvents.bind(self, self.options.dataEvents.tags, function (e) {
				// Don't do a doDataFill if the target object is in the process of being destroyed
				// This should obsolete allow_delete being required as a flag
				if (self.options.destroyed) { return; }

				self._doDataFill();
			});
		}
	};

	this._restDataTrigger = function (method, url, data, callbackOrOptions) {
		var self = this;

		if (!self.options.dataEvents && self.restDataEventMap[url]) {
			self.options.dataEvents = $.extend({}, self.restDataEventMap[url]);
		}

		if (typeof callbackOrOptions == "function") {
			callbackOrOptions = self._generateSuccessTriggerFunction(callbackOrOptions, method, data);
		} else if (typeof callbackOrOptions == "object") {
			if (typeof callbackOrOptions.success == "function") {
				callbackOrOptions.success = self._generateSuccessTriggerFunction(callbackOrOptions.success, method, data);
			}
		}

		return callbackOrOptions;
	};

	this._generateSuccessTriggerFunction = function (originalFunction, method, post_data) {
		var self = this;

		var newSuccessFunction = function (d) {
			// Trigger data event
			if (self.options.dataEvents && self.options.dataEvents.trigger) {
				CUI.dataEvents.trigger(self.options.dataEvents.trigger, method, post_data);
			}

			// This was moved from BEFORE the above block to after it, in order to fix an issue where
			// a formDialog would be destroyed (and have its options wiped out) before we could inspect self.options.dataEvents
			originalFunction(d);
		};

		return newSuccessFunction;
	};

};
