/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){
	var flyoutPhoneNumberSearchWidget = $.extend(true, {}, $.ui.flyoutSearchWidget.prototype, {
		options: {
			filter_numbers: true,
			allow_uri_types: true,   // app: lua: sip: hangup:
			allow_none: false,   // allows blank input
			message: false
		},

		_beforeDOMReady: function () {
			var self = this, $self = this.element;

			$.ui.flyoutSearchWidget.prototype._beforeDOMReady.apply(self, arguments);

			self._bind(self.options._$input, 'keyup change', self._validateInput.bind(self));
		},

		// Apply the selected value to the widget or control. You may need to override this if your selection process is more complex.
		_validateInput: function () {
			var self = this, $self = this.element, numbers_regex, uri_regex, combined_regex, valid = false, $messages_template, mesg, v;

			v = self.options._$input.val();
			$messages_template = $('<div class="messages" />');
			mesg = self.options.message || 'Invalid entry!';

			numbers_regex  = /^\+?[*#0-9]+$/;
			uri_regex      = /^(?:sip:|app:|lua:|hangup:)/;
			combined_regex = /^(?:\+?[*#0-9]+$|sip:|app:|lua:|hangup:)/;

			if (self.options.allow_none && v === '') {
				valid = true;
			} else if (self.options.filter_numbers && !self.options.allow_uri_types) {
				valid = numbers_regex.test(v);
			} else if (self.options.allow_uri_types && !self.options.filter_numbers) {
				valid = uri_regex.test(v);
			} else if (self.options.allow_uri_types && self.options.filter_numbers) {
				valid = combined_regex.test(v);
			} else {
				valid = true;
			}

			if (!valid) {
				$self.addClass('is-invalid');
				$self.data('error', self.options.message || 'Invalid Entry!');
			} else { 
				$self.removeClass('is-invalid');
			}
		},

		_applyValue: function(v) {
			var self = this, $self = this.element;
			$self.removeClass('is-invalid');
			$.ui.flyoutSearchWidget.prototype._applyValue.apply(self, arguments);
		}
	});

	add_widget('flyoutPhoneNumberSearchWidget', flyoutPhoneNumberSearchWidget);
})(jQuery);
