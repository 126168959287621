/* global CUI, classCUI */
/*

  CUI.combineParams(a, b, ...) returns OBJECT

  Combines two or more objects together, and creates or combines array values if the same key exists in both. Does not modify the original objects.

  EXAMPLE

  CUI.combineParams({ foo: 1, bar: 2, baz: 3 }, { foo: 11, bar: 22 }, { foo: 111 });
  returns: { foo: [ 1, 11, 111 ], bar: [ 2, 22 ], baz: 3 }

*/

(function () {
	var notObj = function (obj) {
		return (Object.prototype.toString.call(obj) === '[object Array]' || obj === null || obj === undefined || typeof obj !== 'object');
	};

	var isArray = function (obj) {
		return Object.prototype.toString.call(obj) === '[object Array]';
	};

	classCUI.prototype.combineParams = function (a, b, etc) {
		var out = {}, k;
		a = a || {};
		b = b || {};

		if (notObj(a)) { a = { '_NO_NAME' : a }; }
		if (notObj(b)) { b = { '_NO_NAME' : b }; }

		// Simple object copy...
		for (k in a) { if (a.hasOwnProperty(k)) { out[k] = a[k]; } }

		// Now, merge the "b" properties in...
		for (k in b) {
			if (out[k] !== undefined) {
				// Uses "concat" so we don't end up messing up existing refs--
				if (isArray(out[k]) && isArray(b[k])) {
					out[k] = out[k].concat(b[k]);
				} else if (isArray(out[k])) {
					out[k] = out[k].concat([ b[k] ]);
				} else if (isArray(b[k])) {
					out[k] = [ out[k] ].concat(b[k]);
				} else {
					out[k] = [ out[k], b[k] ];
				}
			} else {
				out[k] = b[k];
			}
		}

		if (etc) {
			return CUI.combineParams.apply(this, [ out ].concat( Array.prototype.slice.call(arguments, 2) ));
		} else {
			return out;
		}
	};
})();
