/* jshint jquery: true, unused: vars */
/* global add_widget, debugLog */
/*
  Page-create "submit" button for create pages. This only handles changing the page after submit-- the button gets the class "page_save_button", so its states
  and save action are handled by formWidget. A few assumptions are made:

  1.) There will only be one form on the create page (this really isn't feasible for a create page, anyway, so it's a valid assumptions).
  2.) The formWidget will handle submitting the form when the button is clicked, because the button has the class "page_save_button".
  3.) The button widget is under a screen or overlay, which also contains the form.
*/


(function( $ ){
	var pageCreateButtonWidget = $.extend(true, {}, $.ui.pageSaveButtonWidget.prototype, {
		options: {
		},

		_beforeInit: function () {
			var self = this, $self = this.element;
			$self.addClass('page_save_button');
			$self.closest('.screen, .overlay').on('SubmitSuccess', '.formWidget', function () {
				var p, param_name, formWidget, overlay;
				formWidget = $(this).getCUIWidget('formWidget');

				if (self.options.save_in_progress) { return; }

				if (self.options.key && self.options.detail) {
					var key = self.options.key;
					var overlayOptions = {};

					for (p in self.options.params) {
						param_name = self.options.params[p];
						overlayOptions[param_name] = formWidget.options.data[param_name];
					}
					overlayOptions.key = key;
					overlayOptions[key] = formWidget.options.data[key];

					overlay = $self.closest('.pageWidgetType').getCUIWidget();
					overlay.showOverlay(self.options.detail, overlayOptions, {}, $self.closest('.overlay'), true, true);
				} else {
					debugLog('jquery.createButton.js: Missing "key" or "detail" in options:', self.options, '. Cannot create the overlay. -- ', $self);
				}

			});
		}

	});

	add_widget('pageCreateButtonWidget', pageCreateButtonWidget);
})(jQuery);
