/* jshint jquery: true, unused: vars */
/* global add_widget */
/*
  Generates Page "Link" buttons, instead of page cancel/save/delete buttons

  USAGE:
  $(...).pageLinkWidget({ self.options.links: [ { href: '#!/bps/users/overlay/user-create/{}', text: 'Create User', ... ] });

*/
(function( $ ){
	var pageLinkWidget = $.extend({}, $.ui.widget.prototype, {
		options: {
			buttons: [],
			template_html: '<div><div class="status"><div class="spinner"></div><div class="error"></div></div></div>'
		},

		_processStaticBody: function ($staticBody) {
			var self = this, $self = self.element, i, link;

			$self.addClass('page_buttons');
			for (i = 0; i < (self.options.links || []).length; i++) {
				link = self.options.links[i];
				$('<a />')
					.attr('href', link.href)
					.text(link.text || '')
					.addClass('page_button')
					.appendTo($staticBody);
			}

			return $staticBody;
		}
	});

	add_widget('pageLinkWidget', $.extend({}, $.ui.widget.prototype, pageLinkWidget));
})(jQuery);
