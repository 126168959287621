/* jshint jquery: true, unused: vars, multistr: true */
/* global CUI, add_widget, getUnique */
(function( $ ){
	var soundPlayerWidget = $.extend({}, $.ui.widget.prototype, {
		requires_destroy: true,

		options: {
			no_initial_get: true,

			size: 'expand', // mini/full/expand
			allow_download: true,
			allow_phone: false,

			// These need to be set to determine what keys get passed
			url: '/gui/sound_file/playback',

			// For both download and playback...
			include_keys: [],       // Param keys to pass through
			params: {},             // Explicit params to set

			download_url: false, // Defaults to the same as URL
			download_params: {}  // These extend, not replace, the params object-- to remove a param from the download link, set it to false
		},

		_beforeInit : function () {
			var self = this;
			var $self = this.element;

			self.options.download_url = self.options.download_url || self.options.url;
		},

		_constructDownloadURL : function () {
			var self = this, $self = this.element, params = $.extend({}, self.options.params);

			if (self.options.data) {
				for (var i=0; i<self.options.include_keys.length; i++) {
					var kp = self.options.include_keys[i];
					if (typeof self.options.data[kp] !== undefined) {
						params[kp] = self.options.data[kp];
					}
				}
			}

			$.extend(params, self.options.download_params);

			// Delete any false elements-- this allows us to specify (in download_params) any param keys to omit
			for (var k in params) {
				if (params[k] === false) {
					delete params[k];
				}
			}

			return self.options.download_url + '?' + $.param(params);
		},

		_beforeDOMReady : function () {
			var self = this;
			var $self = this.element;
			var player_id, $spw, right_buttons = 2;

			if (!$self.is('[id]')) {
				player_id = self.options.widget_id || getUnique('soundPlayerWidget');
				$self.attr('id', player_id);
			} else {
				player_id = $self.attr('id');
			}

			$spw = $('<div>'+
'<span class="sp-buttonset sp-buttonset-left">'+
'<button type="button" class="sp-button sp-play">Play</button><button type="button" class="sp-button sp-pause">Pause</button><button type="button" class="sp-button sp-stop">Stop</button>'+
'</span>'+
'<span class="sp-scrub">'+
'<span class="sp-scrub-total">'+
'<span class="sp-scrub-played"></span>'+
'</span>'+
'</span>'+
'<span class="sp-buttonset sp-buttonset-right">'+
'<a class="sp-button sp-download" href="javascript:void(0)">Download</a><button type="button" class="sp-button sp-phone">Play on Phone</button>'+
'</span>'+
'</div>');
			$self.addClass(self.options.size);

			$spw.find('.sp-pause').hide();

			if (self.options.size === 'mini') {
				$spw.find('.sp-scrub').remove();
			}

			if (!self.options.allow_download) {
				$spw.find('.sp-download').remove();
				right_buttons--;
			}

			if (!self.options.allow_phone) {
				$spw.find('.sp-phone').remove();
				right_buttons--;
			}

			if (!right_buttons) {
				$spw.find('.sp-buttonset-right').remove();
			}

			$spw.find('.sp-scrub').css('margin-right', (right_buttons * 15 + 10) + 'px');
			self.options.$jPlayer = $('<div style="position: absolute; left: -999em" />').appendTo($self);
			$spw.contents().appendTo($self);

			self.options.jPlayer_options = {
				// Using Flash as the default because the non-caching response means that HTML5 audio doesn't allow scrubbing
				// warningAlerts: true, // -- For debugging
				solution: 'flash, html',
				supplied: 'mp3',
				swfPath: '/js/contrib/',
				cssSelectorAncestor: '#' + player_id,
				cssSelector: {
					play: '.sp-play',
					pause: '.sp-pause',
					stop: '.sp-stop'
				},
				error: function (e) {
					if (e.jPlayer.error.type === $.jPlayer.error.URL) {
						new CUI.Dialog({ title: 'Problem Playing Sound', text: 'The sound file was missing or not playable.' });
					}
				},
				ready: CUI.FunctionFactory.build(self._firstReady, self, { context: 'argument', first: 'context' }),
				preload: 'none'
			};

			if (/(full|expand)/.test(self.options.size || '')) {
				$.extend(true, self.options.jPlayer_options, {
					cssSelector: {
						seekBar: '.sp-scrub-total',
						playBar: '.sp-scrub-played'
					}
				});
			}

			$self.find('.sp-play').one('click', self._firstPlay.bind(self));
		},

		// Call refreshMedia as late as possible, to prevent preloading
		_firstPlay: function () {
			var self = this;
			this.options.$jPlayer.jPlayer(this.options.jPlayer_options);
		},

		_firstReady: function (context, e) {
			var self = this, $self = this.element;

			if (!self.options._media_specified) {
				self.refreshMedia();
				self.options._media_specified = true;
			}

			$self.find('.sp-play').addClass('sp-play-ready');
			self.options.$jPlayer.jPlayer('play');
		},

		refreshMedia: function () {
			var self = this, $self = this.element;
			var params_out = {}, i;

			if (self.options.destroyed) { return; }

			for (i=0; i<self.options.include_keys.length; i++) {
				params_out[self.options.include_keys[i]] = self.options.data[self.options.include_keys[i]];
			}

			$.extend(params_out, self.options.params || {});

			self.setMedia(self.options.url, params_out);
		},

		setMedia: function (url, params) {
			var self = this, $self = this.element;
			self.options.url = url;
			self.options.params = params;

			if (self.options.$jPlayer) {
				self.options.$jPlayer.jPlayer('setMedia', { mp3: url + '?' + $.param(params) });
			}
		},

		clearMedia: function() {
			var self = this, $self = this.element;
			self.options.$jPlayer.jPlayer('clearMedia');
		},

		// Extend fillData to regenerate the download link whenever new data comes in
		fillData: function (d) {
			var self = this, $self = this.element;
			var changed = false, i, k;

			self.options.data = self.options.data || {};

			// Need to do this BEFORE the widget fillData, otherwise we can't compare old with new to see if anything's changed
			// The check for s.o.data will be false if this is the first time we've done a fillData (i.e., when the widget is initializing)
			for (i=0; i<self.options.include_keys.length; i++) {
				k = self.options.include_keys[i];
				if (d === undefined || d[k] === undefined) { continue; }
				if (d[k] !== self.options.data[k]) {
					self.options.data[k] = d[k];
					changed = true;
				}
			}

			if (changed && self.options.$jPlayer) {
				self.refreshMedia();
			}

			$.ui.widget.prototype.fillData.apply(this, arguments);
			$('a.sp-download', $self).attr('href', self._constructDownloadURL());
		},

		_destroy: function () {
			var self = this;
			if (self.options.$jPlayer) {
				self.options.$jPlayer.jPlayer('destroy');
				self.options.$jPlayer.remove();
				delete self.options.$jPlayer;
			}
		}

	});

	add_widget('soundPlayerWidget', $.extend({}, $.ui.widget.prototype, soundPlayerWidget));
})(jQuery);
