/* jshint jquery: true, browser: true */
/* global classCUI, debugLog */

classCUI.prototype.bubbleInfoClass = $.extend({}, new function () {

	this._bubbleInfoInit = function(count_down_selector, timer_selector) {
		var self = this;
		var $self = this.element;

		self._setupBubbleInfoClosures();

		self.options.$timer = $self.find(timer_selector);
		self.options.$count_down = $self.find(count_down_selector);

		if (!self.options.$tr) {
			self.options.$tr = $self.closest('tr');
		}
	};

	this._setupBubbleInfoClosures = function() {
		var self = this;

		self.options.tick_callback_closure = function() {
			self._updateCallTime();
		};

		self.options.countdown_callback_closure = function() {
			self._updateCountdown();
		};

		self.options.animate_callback_closure = function() {
			self.options.$tr.animate({opacity:0}, 500);
		};
	};

	this._bubbleInfoDestroy = function() {
		var self = this;

		self._removeCallTimeTimeout();

		if (self.options.$timer) {
			delete self.options.$timer;
		}
		if (self.options.$count_down) {
			delete self.options.$count_down;
		}
		if (self.options.$tr) {
			delete self.options.$tr;
		}
		if (self.options.tick_callback_closure) {
			delete self.options.tick_callback_closure;
		}
		if (self.options.countdown_callback_closure) {
			delete self.options.countdown_callback_closure;
		}
		if (self.options.countdown_callback) {
			delete self.options.countdown_callback;
		}
		if (self.options.animate_callback_closure) {
			delete self.options.animate_callback_closure;
		}

		self._unbind($(window), 'tick.' + self.options.widget_id + '_countup');
		self._unbind($(window), 'tick.' + self.options.widget_id + '_countdown');

	};



	this._updateCallTime = function() {
		var self = this;
		var $self = this.element;

		if (!self.options.using_timer || !self.options.$timer) {
			if (self.options.$timer) {
				self.options.$timer.text(' ');
			}
			return;
		}

		self._realUpdateCallTime();
	};

	this._realUpdateCallTime = function() {
		var self = this;
		var $self = this.element;

		var cur_time = new Date().getTime() / 1000;
		var server_diff = Math.max(self.options.server_current_time - self.options.server_start_time, 0);
		var js_diff = cur_time - self.options.start_time;
		var diff = Math.floor(server_diff + js_diff);

		//	debugLog('Timer diff: ' + diff);

		var day = Math.floor(diff / 86400);
		var removed = day * 86400;
		var hr = Math.floor((diff - removed) / 3600);
		removed += hr * 3600;
		var min = Math.floor((diff - removed) / 60);
		removed += min * 60;
		var sec = (diff - removed);

		var str = '';

		if (day) {
			str += day + 'd ';
		}
		if (hr) {
			str += hr + 'h ';
		}
		if (min || hr) {
			str += min + 'm ';
		}
		if (sec || min || hr) {
			str += sec + 's ';
		}

		//	debugLog('Timer object for ' + self.options.widget_id + ': ', self.options.$timer + '; time: ' + str);
		//	debugLog(str, self.options.$time, selector, $self.find(selector));
		self.options.$timer.text(str);

		if (self.options.critical_seconds && self.options.critical_seconds > 0 && diff > self.options.critical_seconds) {
			self.options.$tr.addClassDefer('critical').removeClassDefer('warning');
		} else if (self.options.warning_seconds && self.options.warning_seconds > 0 && diff > self.options.warning_seconds) {
			self.options.$tr.addClassDefer('warning').removeClassDefer('critical');
		} else {
			self.options.$tr.removeClassDefer('warning critical');
		}
		self.options.$tr.applyClassDefer();
	};

	this._updateCountdown = function() {
		var self = this;
		var $self = this.element;

		if (!self.options.counting_down || !self.options.$count_down) {
			if (self.options.$count_down) {
				self.options.$count_down.text(' ');
			}
			return;
		}

		var sec = self.options.count_down_sec;
		var cur_time = new Date().getTime() / 1000;
		var js_diff = cur_time - self.options.original_start_time;
		var diff = Math.floor(js_diff);

		//	debugLog('State start: ' + self.options.server_state_start_time);
		//	debugLog('Server current: ' + self.options.server_current_time);

		//debugLog("Seconds to start (" + sec + ") and used on server (" + server_diff + ") and used in JS (" + js_diff + ") and left: " + (sec - diff));
		if (diff < sec) {
			self.options.$count_down.text((sec - diff) + 's');
		} else {
			self.options.$count_down.text('');
			self._removeCountdownTimeout();
		}

	};

	this._setCallTimeTimeout = function() {
		var self = this, $self = this.element;

		if (self.options.using_timer) {
			return;
		}

		self.options.using_timer = true;
		self._bind($(window), 'tick.' + self.options.widget_id + '_countup', self.options.tick_callback_closure);
		if (self.options && self.options.tick_callback_closure) {
			// Make sure it exists before calling it in case this is called during destruction.
			self.options.tick_callback_closure();
		}
	};

	this._setCountdownTimeout = function(sec, callback) {
		var self = this;
		var $self = this.element;

		self.options.count_down_sec = sec;
		self.options.countdown_callback = callback;

		if (self.options.counting_down) {
			debugLog('cui.bubbleInfoClass.js: Already counting down. -- ', $self);
			return;
		}

		self.options.counting_down = true;
		self._bind($(window), 'tick.' + self.options.widget_id + '_countdown', self.options.countdown_callback_closure);
		self.options.countdown_callback_closure();
	};

	this._removeCallTimeTimeout = function() {
		var self = this;
		var $self = this.element;

		self._unbind($(window), 'tick.' + self.options.widget_id + '_countup');
		self.options.using_timer = false;
		self.options.$timer.text(' ');
	};

	this._removeCountdownTimeout = function() {
		var self = this;
		var $self = this.element;
		var callback = self.options.countdown_callback;

		self.options.countdown_callback = null;
		self._unbind($(window), 'tick.' + self.options.widget_id + '_countdown');
		self.options.counting_down = false;
		self.options.$count_down.text(' ');
		if (typeof callback == 'function') {
			callback();
		}
	};

	this._clearCounter = function() {
		var self = this;
		self.options.$timer.text(' ');
		//	debugLog('Cleared ', self.options.$timer, ' for ' + self.options.widget_id);
	};

	this._clearCountdown = function() {
		var self = this;
		self.options.$count_down.text(' ');
		//	debugLog('Cleared ', self.options.$count_down, ' for ' + self.options.widget_id);
	};
});
