/* jshint jquery: true, browser: true */
/* global classCUI, debugLog */
(function () {
	// Terminology note: The "code" is the number like "40" or "65", the "name" is the name like "DOWN" or "a"

	var KeyCode = function (key_code) {
		this.key_code = key_code || 0;
	};

	KeyCode.names = {
		0: 'UNKNOWN',
		8: 'BACKSPACE',
		9: 'TAB',
		13: 'ENTER',
		16: 'SHIFT',
		17: 'CTRL',
		18: 'ALT',
		19: 'PAUSE',
		20: 'CAPSLOCK',
		27: 'ESC',
		32: ' ',
		33: 'PGUP',
		34: 'PGDN',
		35: 'END',
		36: 'HOME',
		37: 'LEFT',
		38: 'UP',
		39: 'RIGHT',
		40: 'DOWN',
		45: 'INSERT',
		46: 'DELETE',
		48: '0',
		49: '1',
		50: '2',
		51: '3',
		52: '4',
		53: '5',
		54: '6',
		55: '7',
		56: '8',
		57: '9',
		65: 'a',
		66: 'b',
		67: 'c',
		68: 'd',
		69: 'e',
		70: 'f',
		71: 'g',
		72: 'h',
		73: 'i',
		74: 'j',
		75: 'k',
		76: 'l',
		77: 'm',
		78: 'n',
		79: 'o',
		80: 'p',
		81: 'q',
		82: 'r',
		83: 's',
		84: 't',
		85: 'u',
		86: 'v',
		87: 'w',
		88: 'x',
		89: 'y',
		90: 'z',
		91: 'LWIN',
		92: 'RWIN',
		93: 'MENU',
		96: 'NUM0',
		97: 'NUM1',
		98: 'NUM2',
		99: 'NUM3',
		100: 'NUM4',
		101: 'NUM5',
		102: 'NUM6',
		103: 'NUM7',
		104: 'NUM8',
		105: 'NUM9',
		106: 'NUM*',
		107: 'NUM+',
		109: 'NUM-',
		110: 'NUM.',
		111: 'NUM/',
		112: 'F1',
		113: 'F2',
		114: 'F3',
		115: 'F4',
		116: 'F5',
		117: 'F6',
		118: 'F7',
		119: 'F8',
		120: 'F9',
		121: 'F10',
		122: 'F11',
		123: 'F12',
		144: 'NUMLOCK',
		145: 'SCROLLLOCK',
		186: ';',
		187: '=',
		188: ',',
		189: '-',
		190: '.',
		191: '/',
		192: '`',
		219: '[',
		220: '\\',
		221: ']',
		222: '.'
	};

	KeyCode.codes = {};

	for (var code in KeyCode.names) {
		KeyCode.codes[KeyCode.names[code]] = code;
	}

	// Static "named sets"

	KeyCode.sets = {

		TEXT: [[8,8],[32,32],[46,46],[48,90],[96,111],[186,192],[219,222]],
		// [[ BACKSPACE ], [ {space} ], [ DELETE ], [ 0-9, a-z ], [ NUM0-NUM/ ], [ ;-. ]]

		NONPRINT: [[0,0],[17,20],[27,27],[33,40],[45,46]],
		// [[ UNKNOWN ], [ CTRL, ALT, PAUSE, CAPSLOCK ], [ ESC ], [ PGUP, PGDN, END, HOME, LEFT, UP, RIGHT, DOWN ], [ INSERT, DELETE ]]
		// Note that NONPRINT *does* include TAB!

		VARROW: [[38,38],[40,40]], // Up/down
		// [[ UP ], [ DOWN ]]
		HARROW: [[37,37],[39,39]]  // Left/right
		// [[ LEFT ], [ RIGHT ]]
	};

	KeyCode.sets.TEXTAREA = KeyCode.sets.TEXT.concat([[13,13]]); // Adds "Enter"
	KeyCode.sets.ARROW = KeyCode.sets.HARROW.concat(KeyCode.sets.VARROW); // Combines H/VARROW
	KeyCode.sets.MOVE = KeyCode.sets.ARROW.concat([[33,36]]); // Adds Page and Home/end keys

	// Static Methods and prototype counterparts--

	KeyCode.codeToName = function (code) {
		return KeyCode.names[code] || KeyCode.names[0];
	};

	KeyCode.nameToCode = function (name) {
		return KeyCode.codes[name] || 0;
	};

	KeyCode.isFunctionKey = function (code) {
		// This relies on the fact that all function keys except numpad keys have multi-character names.
		// If this ever changes, this function will need to be reworked.
		return (KeyCode.codeToName(code).search(/^(NUM)?.$/) !== 0);
	};

	KeyCode.prototype.isFunctionKey = function () {
		return KeyCode.isFunctionKey(this.key_code);
	};

	KeyCode.getSet = function (set_name) {
		// This is "get the keycodes in the set", not "get and set" anything -- Returns an array of all keycode numbers in the given set
		var set_out = [], set, set_idx, key_idx;

		if (KeyCode.sets[set_name]) {
			for (set_idx = 0; set_idx < KeyCode.sets[set_name].length; set_idx++) {
				set = KeyCode.sets[set_name][set_idx];

				if (set[0] === set[1]) {
					set_out.push(set[0]);
				} else {
					for (key_idx = set[0]; key_idx <= set[1]; key_idx++) {
						set_out.push(key_idx);
					}
				}

			}
		}

		return set_out;
	};

	KeyCode.inSet = function (key_code, set) {
		var i;
		for (i = 0; i < set.length; i++) {
			if (key_code >= set[i][0] && key_code <= set[i][1]) {
				return true;
			} 
		}
		return false;
	};

	KeyCode.prototype.getName = function () {
		return KeyCode.codeToName(this.key_code);
	};

	KeyCode.prototype.getCode = function () {
		return KeyCode.key_code;
	};

	// stringToCodes takes a string and returns an array of key-codes. For special characters, surround the key code in [...] brackets.
	// For brackets, use [LEFTBRACKET] and [RIGHTBRACKET]
	// Example:
	//           CUI.KeyCode.stringToCodes('[UP][UP][DOWN][DOWN][LEFT][RIGHT][LEFT][RIGHT]abc[ENTER]');


	KeyCode.stringToCodes = function (str) {
		var matches = str.match(/\[[^\]]+\]|./g);
		var codes = [];

		if (!matches) {
			return [];
		}

		for (var i = 0; i < matches.length; i++) {
			var match = matches[i];

			switch (match) {
				case '[LEFTBRACKET]':
					match = '[';
					break;
				case '[RIGHTBRACKET]':
					match = ']';
					break;
			}

			if (match.length > 1) {
				match = match.replace(/[\[\]]/g, '').toUpperCase();
			} else {
				match = match.toLowerCase();
			}

			var code = KeyCode.nameToCode(match);

			if (code) {
				codes.push(code);
			} else {
				debugLog('KeyCode.js stringToCodes function: The key "' + matches[i] + '" in string "', str, '" was not a valid key code. It will be ignored.');

			}
		}

		return codes;
	};

	// Instance Methods

	// "Match" caveats:
	// This takes a regex and matches against the name of the pressed key. Remember that...
	//  - Single-letter key names are in lowercase, special names are in uppercase-- see the table
	//  - You should still use ^...$ to specify a specific key

	// Returns true if the key name matches the regex

	KeyCode.prototype.match = function (regex) {
		return KeyCode.match(this.key_code, regex);
	};

	KeyCode.match = function (code, regex) {
		if (!(regex instanceof RegExp)) {
			regex = new RegExp(regex);
		}

		return (KeyCode.codeToName(code).search(regex) > -1);
	};

	// "set" param is an array of two-element range arrays ([first, last], inclusive). End-users will just use the presets: CUI.KeyCode.TEXT and the like.
	KeyCode.prototype.inSet = function (set) {
		return KeyCode.inSet(this.key_code, set);
	};

	classCUI.prototype.KeyCode = KeyCode;

})();
