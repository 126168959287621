(function () {
    register_screen('autodial_settings', {
	rest: '/gui/autodial/autodial',
	rest_container: 'autodial',
	elements: [
	    { entity_template: 'page_title', title: 'Web Click-to-Dial Settings' },
	    { entity_template: 'page_buttons', buttons: [ { type: 'cancel' }, { type: 'save' }] },
	    { entity_template: 'page_content', elements: [
		{
		    entity_template: 'page_module',
		    legend: 'URL Redirect Presets',
		    buttons: [ 'help' ],
		    help_id: 'auto_dial_presets',
		    elements: [
			{
			    entity_template: 'data_table',
			    rest: '/gui/autodial/autodial',
			    data_source: 'redirects',
			    no_initial_get: true,
			    accept_data_from_parent: true,
			    click_action: 'edit',
			    primary_keys: ['bbx_redirect_id'],
			    extra_row_data: ['bbx_redirect_id'],
			    add_edit_action: {
				rest: '/gui/autodial/redirect'
			    },
			    delete_action: {
				rest: '/gui/autodial/redirect'
			    },
			    row_actions: {
				add_cancel: { text: 'Cancel' },
				edit_cancel: { text: 'Cancel' }
			    },
			    table_actions: {
				select_row: true,
				select_all_rows: true,

				add_row: {
				    text: 'Add URL Redirect Preset',
				    location: "both"
				},
				
				delete_rows: {
				    text: 'Delete Selected Presets',
				    location: "both",
				    confirm: {
					title: 'Delete Presets?',
					text:  'Delete the selected redirect presets? This action cannot be undone.'
				    }
				}
			    },
			    columns: [
				{
				    column: 'bbx_redirect_name',
				    width: '20%',
				    header: 'Redirect Key (Name)',
				    sortable: false,
				    edit_element: {
					entity_template: 'aopb_state',
					entity: 'input',
					attributes: { type: 'text', name: 'bbx_redirect_name', style: 'width: 100%' },

					closest: 'td',
					states: [{
					    conditions: { a: '$$', op: '==', b: '^[-a-zA-Z0-9_]+$', b_is: 'regexp' },
					    actions: { invalid: false },
					    else_actions: { invalid: 'Keys may only use letters a-z, numbers, hyphen, and underscore' }
					}]
				    }
				},
				{
				    column: 'bbx_redirect_template',
				    header: 'URL Template',
				    sortable: false,
				    edit_element: {
					entity: 'input',
					attributes: { type: 'text', name: 'bbx_redirect_template', style: 'width: 100%' }
				    }
				}
			    ]
			}
		    ]
		}
	    ]}
	]
    });
})();
