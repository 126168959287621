/* jshint jquery: true, unused: vars */
/* global classCUI */
/*
 * CUI Cache Class
 * This a globally referenced cache class found under Cudatel.cache.
 *
 * get, set
 */

classCUI.prototype.cache = new function () {

	this.initialize = function () {
		var self = this;
		self.store = {};

		self.intervalId = window.setInterval(function () { self.clean(); }, (60*1000));
	};

	this.set = function (name, val, expireSeconds) {
		var self = this;

		if (typeof expireSeconds != 'undefined' && expireSeconds >= 0) {
			self.store[name] = { timestamp : (+new Date()), expire : (expireSeconds*1000), value : val};
		} else {
			self.store[name] = { timestamp : (+new Date()), value : val};
		}
	};

	this.get = function (name) {
		var self = this;
		var stored = self.store[name];

		// Check to see if we found the item they are looking for.
		if (stored) {
			// Check to see if key expires
			if (stored.expire) {
				// Check to see if key has expired. Return null if key has expired.
				if ((+new Date() - self.store[name].timestamp) < self.store[name].expire) {
					return self.store[name].value;
				} else {
					return null;
				}
			} else {
				// Return value if value does not expire.
				return self.store[name].value;
			}
		}

		return null;
	};

	this.remove = function (name) {
		var self = this;

		if (self.store[name]) {
			delete self.store[name];
		}
	};

	/*
     * Clean function clears out expired cache keys every minute to clear memory.
     */
	this.clean = function () {
		var self = this, key;

		// Loop through keys
		for (key in self.store) {
			// Check to see if key expires.
			if (self.store[key].expire) {
				// Delete key if key has expired.
				if ((+new Date() - self.store[key].timestamp) >= self.store[key].expire) {
					delete self.store[key];
				}
			}
		}
	};

	this.initialize();
};
