$.soundEffect = function (mp3) {
    var sfxID = getUnique('sfx');
    var $player = $('<div style="position: absolute; left: -999em;" />').appendTo('body');

    $player.jPlayer({
	supplied: 'mp3',
	solution: 'flash, html',
	noVolume: { all: /./ },
	preload: 'auto',
	swfPath: '/js/contrib/',
	ready: function () {
	    $(this)
		.jPlayer('setMedia', { mp3: mp3 })
		.jPlayer('play');
	},
	ended: function () {
	    $(this)
		.jPlayer('destroy')
		.remove();
	}
    });
};
