register_screen('phone_detail', {
    rest: '/gui/extension/phone',
    rest_container: 'phone',
    dataEvents: {
	bind: {
	    'primary_extension': '',
	    'phone': ''
	}
    },
    elements: [
	{ entity_template: 'masthead' },
	{
	    entity_template: 'page_buttons',
	    buttons: [
		{ type: 'extension', extension_type: 'phone' },
		{
		    type: 'delete',
		    rest: '/gui/extension/phone',
		    fields: 'bbx_phone_id key',
		    confirm: {
			text: 'Are you sure you would like to remove this phone? The phone may re-provision as an Unassigned Phone if Automatic Provisioning is enabled.',
			title: 'Remove Phone'
		    },
		    dataEvents: {
			trigger: 'phone'
		    }
		},
		{ type: 'cancel' },
		{ type: 'save' }
	    ]
	},
	{ entity_template: 'page_content', elements: [
	    {
		entity_template: 'form',
		action: '/gui/phone/phone',
		dataEvents: {
		    trigger: 'phone'
		},
		elements: [
		    {
			entity_template: 'page_module',
			legend: 'Basic Information',
			buttons: [ 'help' ],
			help_id: 'phones_basic_information',
			elements: [
			    {
				entity_template: 'input_item',
				title: 'Name:',
				input_name: 'bbx_phone_desc',
				type: 'text',
				input_options: { attributes: { maxlength: '255' }},
				size: 80,
				validate: { conditions: "_TRUE_", message: 'A name is required.' },
				info: 'This phone is currently unassigned. You can assign this phone and extension to a new user by using the ' + entity.ldquo + 'Select by Choosing a Phone' + entity.rdquo + ' option in the Add New Person configuration screen. You can add it to an existing user' + entity.rsquo + 's extension by using the '+ entity.ldquo + 'Add Phone' + entity.rdquo +' button in the user' + entity.rsquo + 's pull-down detail screen.'
			    },
			    {
				entity_template: 'input_item',
				title: 'Manufacturer:',
				input_name: 'bbx_phone_manufacturer_name',
				type: 'div'
			    },
			    {
				entity_template: 'input_item',
				title: 'Model:',
				input_name: 'bbx_phone_model_name',
				type: 'div'
			    },
			    {
				entity_template: 'input_item',
				title: 'MAC Address:',
				// input_name: 'bbx_phone_mac',
				type: 'div',
				input_options: {
				    widgets: ['formatWidget'],
				    widget_options: {
					formatWidget: { format: {
					    field: '@bbx_phone_mac',
					    formatter: 'mac'
					}}
				    }
				}
			    },
			    {
				entity_template: 'input_item',
				title: 'Last Registered Time:',
				input_name: 'bbx_phone_last_registered',
				type: 'div'
			    },
			    {
				entity_template: 'input_item',
				title: 'Last Registered IP Address:',
				input_name: 'bbx_phone_last_ip',
				type: 'div'
			    }
			]
		    },
		    elgen('phone_model_detail')
		]
	    }
	]}
    ]
});
