/* jshint jquery: true, unused: vars */
/* global add_widget, trueish */
(function( $ ){
	var ldapExtensionTableWidget = $.extend(true, {}, $.ui.dataTableWidget.prototype, {

		// The group_sync_id is extra data
		extra_data_to_row_actions: true,

		fillData: function (d) {
			var self = this;
			$.ui.dataTableWidget.prototype.fillData.apply(self, arguments);
		},

		_isRowSelectable: function (row_data) {
			return !trueish(row_data.group_sync_id);
		}
	});

	add_widget('ldapExtensionTableWidget', ldapExtensionTableWidget);
})(jQuery);
