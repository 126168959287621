/* jshint jquery: true, unused: vars */
/* global add_widget */
/*
  emailReportPageButtonWidget: Manages the enable-disable state for the "Email Report" button. Single-purpose.
*/

(function( $ ){
	var emailReportPageButtonWidget = $.extend(true, {}, $.ui.pageButtonWidget.prototype, {
		_afterFormChange: function () {
			var self = this, $self = this.element, enabled;

			enabled = !(self.options.dirty || self.options.invalid);

			if (enabled && self.options.$screen.find('input[name=bbx_reporting_email_address]').val()) {
				self.enable(self.options.widget_id);
			} else {
				self.disable(self.options.widget_id);
			}
		}
	});

	add_widget('emailReportPageButtonWidget', emailReportPageButtonWidget);
})(jQuery);
