/* jshint jquery: true, unused: vars */
/* global add_widget */
/*
  Populates a SELECT element from an array in a fillData. Unlike the selectPopulateWidget, this does not make a REST call to get the entries.

  Apply this widget to a SELECT element.
*/

(function( $ ){
	var selectSelfPopulateWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			// This should be an array, in the data coming to fillData
			options_container: undefined,

			// If options_container is an array of objects, which keys hold the text and value?
			option_text_key: undefined,
			option_value_key: undefined,

			// If options_container is an array of strings, specify "index" or "value" to indicate where the text/value of the OPTIONs come from.
			option_text_from: 'value',  // | 'index'
			option_value_from: 'value', // | 'index'
			option_index_offset: 0, // Offset to add to the index in "index" cases

			accept_option_data_from_parent: true,

			_select_built: false
		},

		fillData: function (d, from_self) {
			var self = this, $self = this.element, $before, $after, o_idx, o, $o, options_container = self.options.options_container;
			if (from_self !== self.options.option_data_from_parent && d[options_container] && !self.options._select_built) {
				// prepend options
				if (self.options.before) {
					for (o_idx = 0; o_idx < self.options.before.length; o_idx++) {
						$before = $('<option />');
						$before.text(self.options.before[o_idx].text);
						$before.val(self.options.before[o_idx].value);
						$before.appendTo($self);
					}
				}
				// Pull option data...
				for (o_idx = 0; o_idx < d[options_container].length; o_idx++) {
					o = d[options_container][o_idx];
					$o = $('<option />');
					if (typeof o === 'string') {
						$o.text(self.options.option_text_from === 'index' ? o_idx + self.options.option_index_offset : o);
						$o.val(self.options.option_value_from === 'index' ? o_idx + self.options.option_index_offset : o);
					} else {
						$o.text(o[self.options.option_text_key]);
						$o.val(o[self.options.option_value_key]);
					}
					$o.appendTo($self);
				}
				// append options
				if (self.options.after) {
					for (o_idx = 0; o_idx < self.options.after.length; o_idx++) {
						$after = $('<option />');
						$after.text(self.options.after[o_idx].text);
						$after.val(self.options.after[o_idx].value);
						$after.appendTo($self);
					}
				}

				self.options._select_built = true;
			}

			$.ui.widget.prototype.fillData.apply(self, arguments);
		}
	});

	add_widget('selectSelfPopulateWidget', selectSelfPopulateWidget);
})(jQuery);
