(function () {
    var SC_IF_INBOUND = {
	closest : 'form',
	states: [{
	    conditions: { a: '$bbx_provider_gateway_flag_inbound' , op: '==', b: '_TRUEISH_' },
	    actions: { enabled: true, visible: true },
	    else_actions: { enabled: false, visible: false }
	}]
    };

    var SC_IF_OUTBOUND = {
	closest : 'form',
	states: [{
	    conditions: { a: '$bbx_provider_gateway_flag_outbound' , op: '==', b: '_TRUEISH_' },
	    actions: { enabled: true, visible: true },
	    else_actions: { enabled: false, visible: false }
	}]
    };

    var SC_LOCAL_REG = {
	closest : 'form',
	states: [{
	    conditions: { a: '$local_registration' , op: '==', b: '_TRUEISH_' },
	    actions: { visible: true },
	    else_actions: { visible: false }
	}]
    };

    register_screen('provider_gateway_detail', {
	elements: [
	    { entity_template: 'page_title', field: 'bbx_provider_gateway_name', rest: '/gui/gateway/sip' },
	    { entity: "div", "attributes" : { "class": "page_messages" } },
	    {
		entity_template: 'page_buttons',
		buttons: [
		    {
			type: 'delete',
			rest: '/gui/gateway/sip',
			fields: 'bbx_provider_gateway_id',
			confirm: {
			    title: 'Delete Provider',
			    body: '<span>Delete this SIP provider?<br /><br /><ul class="bullet-list"><li>This provider will be deleted permanently</li><li>Any external numbers not associated with other objects on the system will be deleted permanently</li><li>Any Call Routing entries directing calls to this provider will be deleted permanently</li></ul><br />This cannot be undone.</span>'
			},
			dataEvents: {
			    trigger: 'provider_external'
			}
		    },
		    { type: 'cancel' }, { type: 'save' }
		]
	    },
	    { entity_template: "page_content",
	      elements: [
		  {
		      entity_template: 'form',
		      no_initial_get: false,
		      dataEvents: { trigger: 'provider_external' },
		      action: '/gui/gateway/sip',
		      method: 'PUT',
		      elements: [
			  {
			      entity_template: "page_module",
			      legend: "Basic Information",
			      buttons: [ "help" ],
			      help_id: 'providers_basic_information',
			      elements: [
				  {
				      entity_template: "data_item",
				      title: "Provider Type:",
				      elements: [
					  {
					      entity: "div",
					      attributes: {
						  name: "bbx_provider_name"
					      },
					      widgets: [ 'widget' ]
					  }
				      ]
				  },
				  {
				      entity_template: 'input_item',
				      title: 'Gateway Name:',
				      input_name: 'bbx_provider_gateway_name',
				      type: 'text',
				      input_options: { attributes: { maxlength: '255' }}
				  },
				  {
				      entity_template: 'input_item',
				      title: 'Host:',
				      input_name: 'bbx_provider_gateway_host',
				      type: 'text'
				  },
				  {
				      entity_template: 'input_item',
				      title: 'Realm:',
				      input_name: 'bbx_provider_gateway_realm',
				      type: 'text'
				  },
				  {
				      entity_template: 'input_item',
				      title: 'Port:',
				      input_name: 'bbx_provider_gateway_port',
				      type: 'text',
				      size: 8,
				      input_options: {
					  widgets: ['aopbStateWidget'],
					  widget_options: { aopbStateWidget: { states: [ {

					      conditions: [
						  {             a: '$$', op: '==', b: '_PORT_' },
						  { join: 'or', a: '$$', op: '==', b: '', b_is: 'literal' }
					      ],
					      actions: { invalid: false },
					      else_actions: { invalid: 'Please enter a valid port number' }

					  } ] } }
				      }
				  },
				  {
				      entity_template: 'input_item',
				      title: 'Username:',
				      input_name: 'bbx_provider_gateway_username',
				      type: 'text',
				      input_options: {
					  widgets: ['aopbStateWidget'],
					  widget_options: {
					      states: [{
						  conditions: [
						      { a: '$$', op: '!=', b: '_TRUE_' },
						      { join: 'and', a: '$bbx_provider_gateway_flag_register', op: '==', b: '_TRUEISH_' }
						  ],
						  actions: { invalid: 'A Username is required to register to the provider' },
						  else_actions: { invalid: false }
					      }]
					  }
				      }
				  },
				  {
				      entity_template: 'input_item',
				      title: 'Authorization Username:',
				      input_name: 'bbx_provider_gateway_auth_username',
				      type: 'text'
				  },
				  {
				      entity_template: 'input_item',
				      title: 'Password:',
				      input_name: 'bbx_provider_gateway_password',
				      type: 'password',
				      input_options: {
					  widgets: ['aopbStateWidget'],
					  widget_options: {
					      states: [{
						  conditions: [
						      { a: '$$', op: '!=', b: '_TRUE_' },
						      { join: 'and', a: '$bbx_provider_gateway_flag_register', op: '==', b: '_TRUEISH_' }
						  ],
						  actions: { invalid: 'A Password is required to register to the provider' },
						  else_actions: { invalid: false }
					      }]
					  }
				      }
				  }
			      ]
			  },
			  {
			      entity_template: 'page_module',
			      legend: 'Provider Registration',
			      buttons: [ 'help' ],
			      help_id: 'providers_provider_registration',
			      elements: [
				  {
				      entity_template: 'input_item',
				      title: 'Register to Provider:',
				      input_name: 'bbx_provider_gateway_flag_register',
				      type: 'checkbox'
				  },
				  {
				      entity_template: 'input_item',
				      title: 'Registration Expire Time (Seconds):',
				      input_name: 'bbx_provider_gateway_expire',
				      type: 'text',
				      info: 'minimum recommended setting: 30s',
				      input_options: {
					  widgets: [ 'aopbStateWidget' ],
					  widget_options: {
					      aopbStateWidget: {
						  closest: '.pageModuleWidget',
						  states: [
						      {
							  conditions: { a: '$bbx_provider_gateway_flag_register', op: '==', b: '_TRUEISH_' },
							  actions: { enabled: true },
							  else_actions: { enabled: false },
							  last_if_false: true
						      },
						      {
							  conditions: [ { a: '$$', op: '==', b: '_NUMERIC_NONZERO_' },
									{ join: 'and',  a: '$$', op: '<', b: '4294967296' } ],
							  actions: { invalid: false },
							  else_actions: { invalid: 'Invalid Registration Expire Time.' }
						      }
						  ]
					      }
					  }
				      }
				  },
				  {
				      entity_template: 'data_item',
				      title: '',
				      elements: [
					  {
					      entity_template: 'action_button',
					      text: 'Refresh Registration',
					      rest: '/gui/gateway/sip',
					      rest_params: { bbx_provider_gateway_state: 'REFRESH' },
					      include_data: ['bbx_provider_gateway_id']
					  }
				      ]
				  }
			      ]
			  },
			  {
			      entity_template: "page_module",
			      legend: "Services",
			      buttons: [ "help" ],
			      help_id: 'providers_services',
			      elements: [
				  {
				      entity_template: "input_item",
				      title: "Inbound Calls:",
				      input_name: "bbx_provider_gateway_flag_inbound",
				      type: "checkbox"
				  },
				  {
				      entity_template: "input_item",
				      title: "Outbound Calls:",
				      input_name: "bbx_provider_gateway_flag_outbound",
				      type: "checkbox"
				  },
				  {
				      entity_template: 'input_item',
				      widgets: ['aopbStateWidget'],
				      title: 'Outbound Fax:',
				      input_name: 'outbound_fax',
				      type: 'checkbox',
				      widget_options: {
					  aopbStateWidget: {
					      closest: 'form',
					      states: [
						  {
						      conditions: { a: '$bbx_provider_gateway_flag_outbound', op: '==', b: '_TRUE_' },
						      actions: { enabled: true, _cascade: true },
						      else_actions: { enabled: false, invalid: false, _cascade: true }
						  }
					      ]
					  }
				      }
				  }
			      ]
			  },
			  {
			      entity_templates: [ "page_module", "aopb_state" ],
			      page_module: {
				  legend: "Inbound Registration",
				  buttons: [ "help" ],
				  help_id: 'providers_inbound_registration',
				  elements: [
				      {
					  entity_template: 'input_item',
					  title: "Allow Inbound Registration:",
					  input_name: "local_registration",
					  type: "checkbox"
				      },
				      {
					  entity_templates: ["data_item", "aopb_state"],
					  aopb_state: SC_LOCAL_REG,
					  data_item: {
					      title: "Inbound Registration Username:",
					      elements: [
						  {
						      entity: "div",
						      widgets: ['widget'],
						      attributes: {
							  name: "bbx_registration_username",
							  type: "text",
							  size: 32
						      }
						  }
					      ]
					  }
				      },
				      {
					  entity_templates: ["data_item", "aopb_state"],
					  aopb_state: SC_LOCAL_REG,
					  data_item: {
					      title: "Inbound Registration Password:",
					      elements: [
						  {
						      entity: "div",
						      widgets: ['widget'],
						      attributes: {
							  name: "bbx_registration_password",
							  type: "text",
							  size: 32
						      }
						  }
					      ]
					  }
				      },
				      {
					  entity_templates: ["data_item", "aopb_state"],
					  aopb_state: SC_LOCAL_REG,
					  data_item: {
					      title: "External IP:",
					      elements: [
						  {
						      entity: "div",
						      widgets: ['widget'],
						      attributes: {
							  name: "external_inet_ip",
							  type: "text",
							  size: 32
						      }
						  }
					      ]
					  }
				      },
				      {
					  entity_templates: ["data_item", "aopb_state"],
					  aopb_state: SC_LOCAL_REG,
					  data_item: {
					      title: "Local IP:",
					      elements: [
						  {
						      entity: "div",
						      widgets: ['widget'],
						      attributes: {
							  name: "system_ip",
							  type: "text",
							  size: 32
						      }
						  }
					      ]
					  }
				      },
				      {
					  entity_templates: ["data_item", "aopb_state"],
					  aopb_state: SC_LOCAL_REG,
					  data_item: {
					      title: "Local Port:",
					      elements: [
						  {
						      entity: "div",
						      widgets: ['widget'],
						      attributes: {
							  name: "local_system_port",
							  type: "text",
							  size: 32
						      },
						      text: "5065"
						  }
					      ]
					  }
				      }
				  ]

			      },
			      aopb_state: SC_IF_INBOUND
			  },
			  {
			      entity_templates: ["container", "aopb_state"],
			      aopb_state: SC_IF_OUTBOUND,
			      container: {
				  elements: [ elgen('cid_selector', { type: 'bbx_provider_gateway', buttons: ['help'], help_id: 'providers_caller_id' }) ]
			      }
			  },
			  {
			      entity_templates: ["page_module", "aopb_state"],
			      aopb_state: SC_IF_OUTBOUND,
			      page_module: {
				  legend: "Music on Hold",
				  buttons: [ "help" ],
				  help_id: 'providers_music_on_hold',
				  elements: [ /* Page Module */
				      {
					  entity_template: "data_item",
					  title: "Music on Hold Class:",
					  elements: [
					      {
						  entity: "select",
						  attributes: {
						      name: "bbx_moh_id",
						      placeholder: "Music on Hold"
						  },
						  widgets: ["selectPopulateWidget"],
						  widget_options: {
						      selectPopulateWidget: {
							  rest: "/gui/moh/class",
							  text_field: "bbx_moh_name",
							  value_field: "bbx_moh_id"
						      }
						  }
					      }
					  ]
				      }
				  ]
			      }
			  },
			  {
			      entity_template: "page_module",
			      legend: "Codec Restriction",
			      buttons: [ "help" ],
			      help_id: 'providers_codec_restriction',
			      elements: [ /* Page Module */
				  {
				      entity_template: "data_item",
				      title: "Codec Restriction:",
				      elements: [
					  {
					      entity_template: "codec_picker",
					      name: "codecs",
					      selected_title: 'Provider Codecs Used'
					  }
				      ]
				  },
				  {
				      entity_template: "data_item",
				      title: '',
				      elements: [
					  {
					      entity: 'div',
					      attributes: { 'class': 'info' },
					      text: 'Codec restriction is only in place if there are codecs in the list. If the list is empty, restriction is disabled and all active codecs are allowed on this gateway.'
					  }
				      ]
				  }
			      ]
			  },
			  {
			      entity_template: 'page_module',
			      legend: 'Advanced Settings',
			      buttons: ['help'],
			      help_id: 'providers_advanced_settings',
			      elements: [
				  {
				      entity_template: 'input_item',
				      type: 'checkbox',
				      title: 'Use T.38 Re-Invite on Fax transmission:',
				      input_name: 'fax_enable_t38_request',
				      input_options: {
					  widgets: ['aopbStateWidget'],
					  widget_options: { aopbStateWidget: {
					      states: [{
						  conditions: { a: '$outbound_fax', op: '==', b: '_TRUEISH_' },
						  actions: { enabled: true },
						  else_actions: { enabled: false }
					      }]
					  }}
				      }
				  },
				  {
				      entity_template: 'input_item',
				      type: 'checkbox',
				      title: 'Ignore CID display updates sent after call is in progress:',
				      input_name: 'ignore_display_updates'
				  },
				  {
				      entity_template: 'input_item',
				      type: 'select',
				      title: 'Change Provider Template:',
				      input_name: 'bbx_provider_id',
				      input_options: {
					  widgets: ['selectPopulateWidget'],
					  widget_options: {
					      selectPopulateWidget: {
						  rest: '/gui/provider/provider',
						  text_field: 'bbx_provider_name',
						  value_field: 'bbx_provider_id'
					      }
					  }
				      }
				  },
				  {
				      entity_template: 'input_item',
				      type: 'select',
				      title: 'Caller ID Type:',
				      input_name: 'sip_cid_type',
				      select_options: [
					  { value: '',     text: 'Default' },
					  { value: 'none', text: 'None' },
					  { value: 'rpid', text: 'Remote-Party-ID' },
					  { value: 'pid',  text: 'P-Asserted-Identity' }
				      ]
				  },
				  {
				      entity_template: 'input_item',
				      type: 'text',
				      title: 'SIP from URI:',
				      input_name: 'sip_from_uri'
				  }
			      ]
			  }
		      ]
		  },
		  {
		      entity_templates: ['aopb_state','container'],
		      aopb_state: $.extend({}, SC_IF_INBOUND, {
			  closest: '.cui-page-content',
			  watch_also: [{ selector: 'form', events: 'producerFillData' }]
		      }),
		      container: {
			  elements: elgen('did_page_modules')
		      }
		  }
	      ]
	    }
	]
    });
})();
