/* jshint jquery: true, unused: vars */
/* global CUI, debugLog */
$.doREST = function (method, url, data, callbackOrOptions) {
    debugLog("Error: $.doREST is not to be used anymore. Use CUI.doREST.");
    CUI.doREST(method, url, data, callbackOrOptions);
};

$.getREST = function (url, params, callbackOrOptions, expireSeconds) {
    debugLog("Error: $.getREST is not to be used anymore. Use CUI.getREST.");
    CUI.getREST(url, params, callbackOrOptions, expireSeconds);
};

$.putREST = function (url, params, callbackOrOptions, expireSeconds) {
    debugLog("Error: $.putREST is not to be used anymore. Use CUI.putREST.");
    CUI.putREST(url, params, callbackOrOptions, expireSeconds);
};


$.postREST = function (url, params, callbackOrOptions, expireSeconds) {
    debugLog("Error: $.postREST is not to be used anymore. Use CUI.postREST.");
    CUI.postREST(url, params, callbackOrOptions, expireSeconds);
};


$.deleteREST = function (url, params, callbackOrOptions, expireSeconds) {
    debugLog("Error: $.deleteREST is not to be used anymore. Use CUI.deleteREST.");
    CUI.deleteREST(url, params, callbackOrOptions, expireSeconds);
};
