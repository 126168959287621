// Generates a "tick" event every second.
(function () {
    var _timer = null;
    var _ticker = null;
    var _tickCounter = 0;

    if (!window.TICKING) {
	$(document).ready(function () {
	    _ticker = function(fun) {
		_tickCounter++;
		//	    $(window).triggerHandler('tenthTick');
		//	    if (_tickCounter % 5 == 0) {
		$(window).triggerHandler('halfTick');
		//	    }
	    if (_tickCounter % 2 == 0) {
		$(window).triggerHandler('tick');
		_tickCounter = 0;
	    }
		//	    _timer = setTimeout(function(){fun(fun);}, 100);
		_timer = setTimeout(function(){fun(fun);}, 500);
	    }
	    _ticker(_ticker);
	});
    }

})();

TICKING = true;
