register_dependent_screen('firstrun_network', ['network'], function (screens) {
    var network_screen_body = $.extend(true, {}, screens.network.elements[2]);
    delete network_screen_body.elements[0].confirm;

    return ({
	elements: [
	    { entity_template: 'page_title', title: 'Network' },
	    { entity_template: 'page_buttons',
	      buttons: [{ type: 'cancel', text: 'Reset Form' }, { type: 'save_continue', text: 'Continue', confirm_if_invalid: true, href: '#!/bps/firstrun_activation' }]
	    },
	    network_screen_body
	]
    });
});
