(function () {
    register_screen('tdm_providers', {
	elements: [
	    { entity_template: 'page_title', title: 'Hardware Providers' },
	    { entity: 'div', attributes: { 'class': 'page_messages'} },
            {
                entity_template: 'page_buttons',
                buttons: [{ type: 'help', help_id: 'providers_hardware_providers' }]
            },

	    {
		entity_template: 'page_content',
		elements: [
		    {
			entity_template: 'data_table',
			live_table: 'bbx_tdm_card_port',
			js_sort_by: [['bbx_tdm_card_port_number','asc']],
			search: {
			    bbx_tdm_card_port_trunk_type: '^(?!FXS).*'
			},

			click_action: 'variable_overlay',

			variable_overlay: {
			    column: 'bbx_tdm_card_port_type',
			    DIGITAL: {
				overlay_name: 'tdm_digital_detail',
				overlay_params: ['bbx_tdm_card_port_id']
			    },
			    ANALOG: {
				overlay_name: 'tdm_analog_detail',
				overlay_params: ['bbx_tdm_card_port_id']
			    }
			},

			oLanguage: {
			    sZeroRecords: 'No hardware ports are available. If hardware settings have been changed, the Telephony Engine may currently be restarting.'
			},
			
			overlay_name: 'tdm_digital_detail',
			page_size: 25,
			extra_row_data: ['bbx_tdm_card_port_id','bbx_tdm_card_port_type','bbx_tdm_card_port_name','bbx_tdm_card_port_status','bbx_tdm_card_port_alarm_red','bbx_tdm_card_port_alarm_blue','bbx_tdm_card_port_alarm_yellow', 'bbx_tdm_card_port_alarm_rai', 'bbx_tdm_card_port_alarm_ais', 'bbx_tdm_card_port_alarm_general'],
			overlay_params: [
			    'bbx_tdm_card_port_id'
			],

			overlay_data: {
			    key: 'bbx_tdm_card_port_id'
			},

			columns: [
			    {
				header: 'Port',
				column: 'bbx_tdm_card_port_number',
				width: '30%',
				sort_data_type: 'numeric-asc',
				formatted: { format: { field: 'bbx_tdm_card_port_name' } }
			    },
			    {
				header: 'Alarm Status',
				view_element: {
				    entity_template: 'hardware_port_status'
				}
			    }
			]
		    }
		] // END page_content elements
	    } // END page_content
	] // END template elements
    }); // END template
})(); // END scoping function
