/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){
	var dropTargetWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			scope: "default",              // Scope for drop targets; if set here and on the draggable, limits what draggables this drop target will accept
			accept: "*",                   // In addition to scope, this may be used to provide a selector for suitable draggables
			hoverClass: "dropTargetHover", // CSS Class to be applied when a suitable draggable is hovered over this widget
			tolerance: "pointer"           // 'fit', 'intersect', 'pointer', or 'touch' (see jQuery UI docs)
		},

		_beforeDOMReady: function () {
			var self = this, $self = this.element;

			self._initDroppable();
		},

		_initDroppable: function() {
			var self = this, $self = this.element;

			$self.droppable({
				scope: self.options.scope,
				accept: self.options.accept,
				hoverClass: self.options.hoverClass,
				tolerance: self.options.tolerance,
				drop: self._dropHandler.bind(self),
				over: self._overHandler.bind(self),
				out: self._outHandler.bind(self)
			});
		},

		_dropHandler: function(event, ui) {
			var self = this, $self = this.element;
			if (typeof self._drop === 'function') {
				self._drop(event, ui);
			}
		},

		_overHandler: function(event, ui) {
			var self = this, $self = this.element;
			if (typeof self._over === 'function') {
				self._over(event, ui);
			}
		},

		_outHandler: function(event, ui) {
			var self = this, $self = this.element;
			if (typeof self._out === 'function') {
				self._out(event, ui);
			}
		},

		_destroy: function() {
			var self = this, $self = this.element;
			$self.droppable('destroy');
		}
	});

	add_widget('dropTargetWidget', dropTargetWidget);
})(jQuery);
