/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){
	var phoneLineAssignButtonWidget = $.extend(true, {}, $.ui.valueFormDialogButtonWidget.prototype, {
		options: {
			show_dialog_on_load: false
		},

		submitDialog: function () {
			var self = this, $self = this.element;
			$.ui.valueFormDialogButtonWidget.prototype.submitDialog.apply(self, arguments);
			self._setButtonText(self.options.value);
		},

		fillData: function (d, from_self) {
			var self = this;

			// BNPH-7789 : hack to stop from autofilling extension when the button was set to repeat previously
			if (d.bbx_phone_button_type != 'repeat') { self.options.data = $.extend(self.options.data || {}, d); }
			self._setButtonText(d);
			$.ui.valueFormDialogButtonWidget.prototype.fillData.apply(this, arguments);
			if (self.options.show_dialog_on_load) {
				self._createDialogEvent();
			}
		},

		_setButtonText: function (d) {
			var self = this, $self = this.element;
			$self.removeClass('is-invalid');

			if (!self.options._initial_dialog && d.bbx_phone_button_value) {
				$self.text(self._format(d.bbx_phone_button_value));
			} else if (d.bbx_extension_value && d.bbx_extension_value != 'undefined') {
				$self.text(self._format(d.bbx_extension_value));
			} else if (d.bbx_extension_block_begin && d.bbx_extension_block_end) {
				$self.text(self._format(d.bbx_extension_block_begin + '-' + d.bbx_extension_block_end));
				self.options.value = $.extend( self.options.value, { bbx_extension_value: d.bbx_extension_block_begin + '-' + d.bbx_extension_block_end });
			} else if (d.extension_next_free && self.options._initial_dialog) {
				$self.text('(Next Free Extension)');
			} else if (d.bbx_extension_id) {
				self._showInfoFor(d.bbx_extension_id);
			} else if (d.bbx_phone_button_value && d.bbx_phone_button_value.search(/^[0-9]+$/) === 0) {
				$self.text(self._format(d.bbx_phone_button_value));
			} else {
				//		debugLog('Unknown button destination: ', d, ' -- ', $self);
				$self.text('(Choose Extension)');
				$self.addClass('is-invalid');
			}

			$self.trigger('change');
		},

		_showInfoFor: function (id) {
			var self = this;
			CUI.getREST('/gui/extension/extension', { bbx_extension_id: id }, self._displayInfoFor.bind(self));
		},

		_displayInfoFor: function (data) {
			var self = this, $self = this.element;
			if (data.extension) {
				$self.text( self._format(data.extension.bbx_extension_value) );
			} else {
				$self.text('(Unknown Extension)');
			}
		},

		_format: function (value) {
			return CUI.formatter.doFormat({ value: value }, { format: { field: 'value', formatter: 'phone_number' } });
		}

	});

	add_widget('phoneLineAssignButtonWidget', phoneLineAssignButtonWidget);
})(jQuery);
