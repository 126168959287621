/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){
    var faxSaveButtonWidget = $.extend(true, {}, $.ui.pageSaveButtonWidget.prototype, {
	options: {
	},

	_beforeInit: function () {
	    var self = this, $self = this.element;
	    $self.addClass('page_save_button');
	    $self.closest('.screen, .overlay').on('SubmitSuccess', '.formWidget', self._showPopup.bind(self));
	},


	_showPopup: function () {
	    var self = this, $self = this.element;
	    self.options._popup = new CUI.Dialog({
		title: 'Your Fax is Being Sent...',
		text: 'Would you like to send another fax, or view your Message Center?',
		prevent_navigation: true,
		buttons: ['Send Another Fax','Message Center'],
		callbacks: [
		    self._clearForm.bind(self),
		    self._goMessageCenter.bind(self)
		]
	    });
	},

	_goMessageCenter: function (result, popup) {
	    this.options._popup.remove();
	    location.href = '#!/bps/messages';
	},

	_clearForm: function (result, popup) {
	    var self = this, $self = this.element, $btn;
	    self.options._popup.remove();
	    $btn = $self.closest('.page_buttons').find('.page_cancel_button');
	    $btn.trigger('click');
	}
	    
    });
    
    add_widget('faxSaveButtonWidget', faxSaveButtonWidget);
})(jQuery);
