/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/*
  A single-purpose extension to refresh the dualListPickWidget options when a SELECT box changes.
*/
(function( $ ){
	var report_dlp_cache;

	var reportDLPWidget = $.extend(true, {}, $.ui.dualListPickWidget.prototype, {
		options: {
			closest: 'form',
			selector: 'select[name=report_group_type]',
			field_map: {
				group: 'group',
				user: 'user',
				queue: 'queue'
			},
			wait_for_selected: false
		},

		fillData: function (d) {
			var self = this, $self = this.element;
			$.ui.dualListPickWidget.prototype.fillData.apply(this, arguments);

			if (self.options.selector.charAt(0) === '@' && d[self.options.selector.slice(1)]) {
				self.options._data_selector = d[self.options.selector.slice(1)];
				self._selectChangeHandler();
			}
		},

		_buildItemDetail: function (item) {
			var self = this, $out;
			$out = $('<div />')	    
				.addClass(this._item_class);

			$out.append($('<div class="report-item-name" />').text(item[this.options.available_item_title] || ''));
			$out.append($('<div class="report-item-desc" />').text(item.description || ''));

			return $out;
		},

		_beforeInit: function () {
			var self = this, $self = this.element, $overlay;
			$.ui.dualListPickWidget.prototype._beforeInit.apply(self, arguments);

			$overlay = $self.closest('.overlay');

			if ($overlay.getCUIWidget().options.ready) {
				self._selectListen();
			} else {
				$overlay.one('ready', self._selectListen.bind(self));
			}
		},

		_beforeDOMReady: function () {
			var self = this, $self = this.element;
			if (!self.options._$select && self.options.selector.charAt(0) !== '@') {
				self.options._$select = $self.closest(self.options.closest).find(self.options.selector);
			}
			return $.ui.dualListPickWidget.prototype._beforeDOMReady.apply(this, arguments);
		},

		_selectListen: function () {
			var self = this, $self = this.element, $select;

			if (self.options.selector.charAt(0) !== '@') {
				$select = self.options._$select || (self.options._$select = $self.closest(self.options.closest).find(self.options.selector));
				self._bind($select, 'change', self._selectChangeHandler.bind(self));
				if (!$select.getCUIWidget('selectPopulateWidget')) {
					self._selectChangeHandler();
				}
			} else {
				self._selectChangeHandler();
			}
		},

		_selectChangeHandler: function (e,d) {
			var self = this;
			self._setCachedAvailable();
			self._checkDataReady();
		},

		_getAvailable: function () {
			var self = this, $self = this.element;
			if (report_dlp_cache) {
				self._setCachedAvailable();
				self._checkDataReady();
			} else if (self.options.available_rest) {
				self._getAvailableFromREST();
			} else {
				self._receiveAvailableFromData(self.options.data[self.options.available_container]);
				self._checkDataReady();
			}
		},

		_receiveAvailableFromData: function (d) {
			var self = this;

			report_dlp_cache = d;

			self._setCachedAvailable();
			self._checkDataReady();
		},

		_receiveAvailableFromREST: function (d) {
			var self = this;

			report_dlp_cache = self.options.available_rest_container ? d[self.options.available_rest_container] : d;
			report_dlp_cache._default = d['default'];

			self._setCachedAvailable();
			self._checkDataReady();
		},

		_setCachedAvailable: function () {
			var self = this, $self = this.element, key;

			if (report_dlp_cache) {
				if (self.options._data_selector) {
					key = self.options._data_selector;
				} else if (self.options._$select) {
					key = self.options._$select.val();
				}

				self.options._available = report_dlp_cache[self.options.field_map[key]];
				if (self.options.fill_defaults) {
					self.setValue(report_dlp_cache._default[self.options.field_map[key]]);
				} else {
					self._checkDataReady();
				}
			}
		}

	});

	add_widget('reportDLPWidget', reportDLPWidget);
})(jQuery);
