/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, cmp, cmp_version, entity */
/* global loginData */
(function () {
	var updateInstallButtonWidget = $.extend(true, {}, $.ui.blankerButtonWidget.prototype, {
		options: {
			confirm: {}
		},

		fillData: function (d, from_self) {
			var self = this, $self = this.element, ver_key = (self.options.rest_params && self.options.rest_params.type + '_version') || 'ga_version';
			if (d && d[ver_key]) {
				self.options.version_to_install = d[ver_key];
			}

			return $.ui.blankerButtonWidget.prototype.fillData.apply(self, arguments);
		},

		// From jquery.actionButtonWidget
		_showConfirmDialog: function () {
			var self = this, $self = this.element;
			self.options.$dialog_body = $('<div class="release-notes waiting">Loading release notes and important notices. Please wait...</div>');
			self.options._dialog = new CUI.Dialog({
				title: 'Important Update Information',
				body: self.options.$dialog_body,
				buttons: ['Install Update', 'Cancel Installation'],
				callbacks: [
					function () {
						CUI.Dialog.cancel.call(this);
						self._afterConfirm();
					},
					CUI.Dialog.cancel
				]
			});

			self.options._dialog.setButtonState(0, 'disabled');
			setTimeout(function () {
				if (self.options._dialog) { self.options._dialog.setButtonState(0, 'enabled'); }
			}, 3000);

			$.ajax({
				url: '/gui/update/release_notes',
				type: 'GET',
				data: { version: self.options.version_to_install, trim: 1, wrap: 0 },
				dataType: 'text',
				success: self._handleRNLoad.bind(self)
			});
		},

		_handleRNLoad: function (html_text) {
			var self = this, $self = this.element, current_version, $rel_notes, $vnotes, $vnote, vn_idx, $warnings_div, w_idx, $warning, $warnings, w_ver, has_warnings, $rn_welcome;

			current_version = loginData.firmware_version;
			$rel_notes = $('<div />').html(html_text);

			$vnotes = $rel_notes.find('.version-notes');
			// If this is a "new-style" release notes, it can be scanned for important changes...
			if ($vnotes.length) {
				for (vn_idx = 0; vn_idx < $vnotes.length; vn_idx++) {
					$vnote = $vnotes.eq(vn_idx);
					w_ver = $vnote.attr('data-version');
					if (w_ver && cmp(current_version, w_ver) > 0) {
						$vnote.addClass('current-previous-version');
					}
				}

				$warnings_div = $('<div class="upgrade-warnings"><h1>Important Notices About This Upgrade</h2></div>');
				$warnings = $rel_notes.find('.upgrade-warning');
				if ($warnings.length) {
					for (w_idx = 0; w_idx < $warnings.length; w_idx++) {
						$warning = $warnings.eq(w_idx);
						w_ver = $warning.attr('data-version') || $warning.closest('.version-notes[data-version]').attr('data-version');
						if (w_ver && (cmp_version(current_version, w_ver) < 0)) {
							$warning = $warning.clone();
							$warning.addClass('current');
							$warning.prepend($('<div class="upgrade-warning-version" />').text(w_ver));
							$warnings_div.append($warning);
							has_warnings = true;
						}
					}

					if (has_warnings) {
						$warnings_div.prependTo($rel_notes);
					}
				}
			}

			$rn_welcome = $('<h1>Firmware Update</h1><p>You are about to update your Barracuda Phone System firmware from version <span class="fw-was" /> to version <span class="fw-will-be" />. Please read the following update information, then click ' + entity.ldquo + 'Install Update' + entity.ldquo + ' at the bottom of this window to install the selected update.</p>');
			$rn_welcome.find('.fw-was').text(current_version);
			$rn_welcome.find('.fw-will-be').text(self.options.version_to_install);
			$rel_notes.prepend($rn_welcome);

			self.options._dialog.setButtonState(0, 'enabled');
			self.options.$dialog_body.html($rel_notes.contents());
		}
	});
	add_widget('updateInstallButtonWidget', updateInstallButtonWidget);
})();
