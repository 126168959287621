/* jshint jquery: true, browser: true */
/* global CUI, classCUI, printData, debugLog, loginWidget, AJAXErrorHandler */
/*
  Common CUI class AJAX and REST routines

  CUI.ajax
  CUI.doREST
  CUI.putREST
  CUI.postREST
  CUI.deleteREST

  REQUIRES:
  jQuery
  cuiClass.js
  cui.cache.js * Not required, but used if it is loaded
  cui.FunctionFactory.js

  WARNING TO FUTURE DEVELOPERS:
  This code is used in standalone pages. Do not add extra dependencies unless absolutely necessary, and if you do, be sure to check and update all
  pages that refer to this JS file.

*/

var loginData = {};
var XSRF_URL_MATCH = new RegExp('(/gui|gui/|/modules|modules/|cgi)');
var SUPPRESS_ERROR_DISPLAY = false;

$.extend(classCUI.prototype, {
	restQueue: {},
	ajax: function(params) {
		var xsrfkey, handler;

		if (document.cookie) {
			xsrfkey = document.cookie.match(/(^|; )xsrfkey=([a-zA-Z0-9]+)/);
			xsrfkey = xsrfkey && xsrfkey[2];
		}

		handler = params.ajax_error_handler || new AJAXErrorHandler({ after_error: params.afterError });

		params.error = ('error' in params) ? params.error : function () { handler.handle.apply(handler, arguments); };

		if (!params.data && params.type == 'POST') {
			params.data = { 'junk' : 1 };
		}

		if ((!params.data || params.data == {} || params.data === '' || params.data === []) && (params.type == 'POST' || !params.type)) {
			params.type = 'GET';
		}

		// Add the xsrfkey to all dynamic requests-- used for XSRF prevention
		// Use XSRF_URL_MATCH to eliminate the noise when requesting static content
		if (!params.no_xsrf_key) {
			if (params.url.search(XSRF_URL_MATCH) > -1 && (params.type || 'get').toLowerCase() !== 'get' && xsrfkey) {
				if (typeof params.data === 'string') {
					if (params.data.search(/[&?]xsrfkey=[^&]*/) === -1) {
						if (params.data) {
							params.data = params.data + '&';
						}
						params.data = params.data + 'xsrfkey=' + xsrfkey;
					}
				} else {
					params.data = params.data || {};
					params.data.xsrfkey = xsrfkey;
				}
			}

			if (params.accept) {
				params.beforeSend = function(xhr) {
					xhr.setRequestHeader("Accept", params.accept);
				};
			}
		}

		// TODO: Pull the actual jQuery prototype, instead of using ajax_orig
		return $.ajax_orig(params);
	},

	doREST: function (method, url, data, callbackOrOptions) {
		var self = this;

		if (typeof data == "function") {
			callbackOrOptions = data;
			data = "";
		}
		if (!data) {
			data = "";
		}
		if (!callbackOrOptions) {
			callbackOrOptions = {};
		}

		var params = {
			url: url,
			data: data,
			type: method,
			accept: 'application/json',
			dataType: 'json'
		};

		if (typeof callbackOrOptions == 'function') {
			callbackOrOptions = {
				success: callbackOrOptions
			};
		}
		$.extend(params, callbackOrOptions);

		return self.ajax(params);
	},

	_handleGetCacheCallbacks: function (callbackOrOptions, cacheValue) {
		var cache_copy = $.extend(true, {}, cacheValue);
		if (typeof callbackOrOptions == "function") {
			if (cache_copy.callback == "success") {
				callbackOrOptions(cache_copy.data, cache_copy.textStatus, cache_copy.jqXHR);
			}
		} else if (typeof callbackOrOptions == "object") {
			if (cache_copy.callback == "success" && callbackOrOptions.success) {
				callbackOrOptions.success(cache_copy.data, cache_copy.textStatus, cache_copy.jqXHR);
			} else if (cache_copy.callback == "error" && callbackOrOptions.error) {
				callbackOrOptions.error(cache_copy.jqXHR, cache_copy.textStatus, cache_copy.errorThrown);
			} else if (cache_copy.callback == "error" && !callbackOrOptions.error) {
				new AJAXErrorHandler().handle(cache_copy.jqXHR, cache_copy.textStatus, cache_copy.errorThrown);
			}

			if (callbackOrOptions.complete) {
				callbackOrOptions.complete(cache_copy.jqXHR, cache_copy.textStatus);
			}
		}
	},

	getREST: function (url, url_params, callbackOrOptions, expireSeconds) {
		var self = this;
		var cacheKey = 'GET:'+url+'?'+$.param(url_params);
		var cacheValue;

		if (typeof expireSeconds == 'undefined' || expireSeconds < 0 ) {
			expireSeconds = 1;
		}

		// Find cache value
		if (self.cache && (cacheValue = self.cache.get(cacheKey))) {
			// Call callback with cache value.
			self._handleGetCacheCallbacks(callbackOrOptions, cacheValue);
		} else {
			// Look for rest queue
			if (self.restQueue[cacheKey]) {
				if (typeof callbackOrOptions == "function") {
					// Add callback to queue
					self.restQueue[cacheKey].push({ success : callbackOrOptions });
				} else if (typeof callbackOrOptions == "object") {
					self.restQueue[cacheKey].push(callbackOrOptions);
				}

				return undefined; // This indicates that no AJAX call was actually made
			} else {
				// Create rest queue
				self.restQueue[cacheKey] = [];

				if (typeof callbackOrOptions == "function") {
					// Set callbackOrOptions to Options object if callback function.
					callbackOrOptions = { success : callbackOrOptions };
				}

				// Add callbacks into queue.
				self.restQueue[cacheKey].push(callbackOrOptions);

				var rest_options = $.extend({}, callbackOrOptions);

				rest_options.success = function (data, textStatus, jqXHR) {
					var cacheValue = { callback : "success", data : data, textStatus : textStatus, jqXHR : jqXHR };

					// Place return in cache
					if (self.cache) { self.cache.set(cacheKey, cacheValue, expireSeconds); }

					// Call callbacks in queue.
					for (var i=0; i<self.restQueue[cacheKey].length; i++) {
						self._handleGetCacheCallbacks(self.restQueue[cacheKey][i], cacheValue);
					}

					// Delete rest queue
					delete self.restQueue[cacheKey];
				};

				rest_options.error = function (jqXHR, textStatus, errorThrown) {
					var cacheValue = { callback : "error", textStatus : textStatus, jqXHR : jqXHR, errorThrown : errorThrown };

					// Don't do anything on aborted requests
					if (jqXHR.status === 0) {
						if (self.restQueue[cacheKey].length > 1) {
							self.restQueue[cacheKey].shift();
							// Retry the failed request if there are further callbacks that need the same data
							debugLog('cui.ajax.js Notice: getREST request aborted, but there are similar queued requests. Retrying for remaining.');
							self.doREST('GET', url, url_params, rest_options);
						} else {
							debugLog('cui.ajax.js Notice: getREST request aborted and there are no similar queued requests.');
						}
					} else {
						// Place return in cache
						if (self.cache) { self.cache.set(cacheKey, cacheValue, expireSeconds); }

						// Call callbacks in queue.
						for (var i=0; i<self.restQueue[cacheKey].length; i++) {
							self._handleGetCacheCallbacks(self.restQueue[cacheKey][i], cacheValue);
						}
					}

					// Delete rest queue
					delete self.restQueue[cacheKey];
				};

				if (rest_options.oncomplete) {
					delete rest_options.oncomplete;
				}

				return self.doREST('GET', url, url_params, rest_options); // This returns the AJAX call object, so it can be used later
			}
		}
	},
	putREST: function(url, data, callbackOrOptions) { return this.doREST('PUT', url, data, callbackOrOptions); },
	postREST: function(url, data, callbackOrOptions) { return this.doREST('POST', url, data, callbackOrOptions); },
	deleteREST: function(url, data, callbackOrOptions) { return this.doREST('DELETE', url, data, callbackOrOptions); }
});

// wrap the $.ajax function so that we can apply a default error handler
$.ajax_orig = $.ajax;
$.ajax = function (params) {
	return CUI.ajax(params);
};
