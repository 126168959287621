/* jshint jquery: true, unused: vars */
/* global add_widget, debugLog */
/*
 * @class restDataFillButtonWidget(options)
 *
 * restDataFillButtonWidget class is a child class extended from actionButtonWidget class. restDataFillButtonWidget class does an AJAX call when
 * clicked. Params for the AJAX call can be mapped to HTML page elements. The data returned can be remapped into different JS objects. The selector
 * options is the used to find widget elements in the HTML page. We then send the JS objects hold the mapped data to the widget elements using the
 * fillData function.
 *
 * @par Usage:
 *
 * data-js is used to pass an associated array. The array key holds the condition and value holds the state actions.
 *
 * @param rest sets the url that will be used for an Ajax REST execution. This param is optional and if not found no Ajax action will
 *        be performed.
 *
 * @param type sets the Ajax REST action type. e.g. get, post, put, delete.
 *
 * @param fields is a space seperated list of rest_params which will be sent to in the Ajax rest call. Default is empty and no rest_params are sent.
 *        e.g.: 'key bbx_user_id bbx_extension_id'
 *
 * @param request_params is JSON string to pass parameters for the rest call. The params can bind to input elements to retrive values.
 *        Param values found contained in {} will be used as jQuery selectors, anything else is considered a string value.
 *        e.g.: { "ip" : "{[name=wan_ip_address]}", "subnet" : "255.255.255.0" }
 *
 * @param confirm sets the confirm message for a user confirmation box and launches the confirmation box when the users clicks the button.
 *
 * @param selector is a jquery selector string. This data can be sent to multiple objects if needed.
 *
 * @param rest_container is a string name for a key that will hold mapped data. If string is empty or null we will mapped data will not be in a container
 *        when data is submitted to fillData of selected object.
 *
 * @param container_mapping is a coma delimited string where mappings start, setting to false will pass the data as-is, Examples: 
 *        "container_mapping" : "lan,wan", "wan[:data_container]" : "<mappings>" , "lan[:data_container]" : "<mappings>"
 *        OR
 *        "container_mapping" : "<mappings>"
 *
 * data-js='{ 
 *             "rest" : "/path",
 *             "request_params" : { "param1" : "textparam", "param2" : "{<jQuery Selector>}" }
 *             "container_mapping" : "<mappings>",
 *             ["type" : "PUT"], ["fields" : "field_name field_name field_name"], ["confirm" : "confirmation message"],
 *             ["rest_container" : "container_name"], 
 *             "selector" : "<jQuery Selector>"
 *          }'
 *
 *
 * A few mapping examples:
 *
 * // Single container
 *
 * "rest_container" : "", "container_mapping" : "network", "network" : "external_inet_ip:ip" // data1
 * "rest_container" : "", "container_mapping" : "network", "network" : "external_inet_ip:ip,external_inet_subnet:subnet" // data4
 * "rest_container" : "", "container_mapping" : "network:ip", "network:ip" : "external_inet_ip:real_ip" // data2
 * "rest_container" : "", "container_mapping" : "network:ip", "network:ip" : "external_inet_ip:ip,external_inet_subnet:subnet" // data3
 *
 * "rest_container" : "network", "container_mapping" : "external_inet_ip:ip" // data1
 * "rest_container" : "network", "container_mapping" : "external_inet_ip:ip,external_inet_subnet:subnet" // data4
 *
 *
 * // Submit with no rest_container.
 *
 * "container_mapping" : "external_inet_ip:ip" // data1
 * "container_mapping" : "external_inet_ip:ip,external_inet_subnet:subnet" // data4
 *
 *
 * // Multiple containers to be placed into rest_container
 *
 * "rest_container" : "network", "container_mapping" : "wan:ip,lan:ip", "wan:ip" : "ip:wan_ip", "lan:ip" : "ip,lan_ip"
 * "rest_container" : "network", "container_mapping" : "wan,lan", "wan" : "ip:wan_ip", "lan" : "ip,lan_ip"
 *
 *
 * // No rest_container and one or more containers returned in rest call.
 *
 * "container_mapping" : ":ip", ":ip" : "ip:wan_ip"
 * "container_mapping" : ":wan,:lan", ":wan" : "wan_ip:ip", ":lan" : "lan_ip:ip"
 */

(function( $ ){

	var restDataFillButtonWidget = $.extend({}, $.ui.actionButtonWidget.prototype, {
		options: {
			method : "get",
			fields : "",
			data : {}, // This will be deleted after every afterInit
			container_mapping: false
		},

		/*
	 * @fn _getRESTParams()
	 * @brief This functions is used to setup rest parameters for the Ajax call.
	 */
		_getRESTParams : function () {
			var self= this, $self = $(this.element), rest_params = {}, $elem;

			if (self.options.rest_params) {
				// If rest_params option is not null then filter.
				if (typeof self.options.fields == "string" && self.options.fields.length > 0) {
					// If fields option is a string and its length is greater than 0 then split the fields.
					var arrFields = self.options.fields.split(" ");

					for (var i=0; i<arrFields.length; i++) {
						// Loop through fields.
						if (self.options.rest_params[arrFields[i]]) {
							// Add fields matched in rest_params options to rest_params local variable.
							rest_params[arrFields[i]] = self.options.rest_params[arrFields[i]];
						}
					}
				}
			}

			// if request_params option is found and it is typeof object then
			if (self.options.request_params && typeof self.options.request_params == "object") {
				var request_params = self.options.request_params, key;

				// Loop through request_params key values
				for (key in request_params) {
					// if we find text wrapped in {} brackets, we will use the wrapped text as a jQuery selector.
					if (request_params[key][0] == "{" && request_params[key][(request_params[key].length-1)] == "}") {
						// Substring wrapped texted into selector variable.
						var selector = request_params[key].substring(1,request_params[key].length-1);

						// Find element objects using selector.
						var $objs = $self.closest('div.formWidget, div.screen, div.overlay').find(selector);

						// If we match an element object get value its value and set key value to rest_params.
						if ($objs && $objs.length > 0) {
							$objs.each(function() {

								$elem = $(this);
								if ($elem.hasClass('widgetType') && $elem.getCUIWidget() && $elem.getCUIWidget().inputValue) {
									rest_params[key] = $elem.getCUIWidget().inputValue();
								}

								if ($elem.is(':checkbox')) {
									if ($elem.is(':checked')) {
										rest_params[key] = 1;
									} else {
										rest_params[key] = 0;
									}
								}
								if ($elem.is(':radio')) {
									rest_params[key] = $elem.closest('form').find('[name=' + name + ']:checked').val();
								}
								if ($elem.is('input, select, textarea')) {
									rest_params[key] = $elem.val();
								} else {
									rest_params[key] = $elem.contents();
								}
							});
						} else {
							debugLog("jquery.restDataFillButtonWidget.js: Error: could not match any elements using selector", request_params[key]);
						}
					} else {
						// If text is not wrapped in {} then just add key value to rest_params
						rest_params[key] = request_params[key];
					}
				}
			} else if (self.options.request_params && typeof self.options.request_params != "object") {
				debugLog("jquery.restDataFillButtonWidget.js: received request_params option is not a JS object.");
			}

			// Because our button can be clicked over and over we are not overriding the rest_params option.
			return rest_params;
		},

		/*
	 * @fn _success()
	 * @brief On success of ajax call, set data for fillData calls. Use selector to find all objects which require the data object.
	 *        Submit data to all found objects by calling fillData.
	 */
		_success: function(data, status, jqXHR) {
			var self = this;
			var $self = $(this.element);
			var $objsForDataFill;

			if (self.options._dialog) {
				self.options._dialog.remove();
				delete self.options._dialog;
			}

			// Check to see if selector was set in options.
			if (self.options.selector) {
				// Find objects with selector.
				$objsForDataFill = $self.closest('div.formWidget, div.screen, div.overlay').find(self.options.selector);

				// Send error if we do not match any objects with selector.
				if ($objsForDataFill.length < 1) {
					debugLog("jquery.restDataFillButtonWidget.js: selector failed to find any matches");
					return;
				}
			} else {
				// Send error if no selector was found in options.
				debugLog("jquery.restDataFillButtonWidget.js: No selector found in options.");
				return;
			}

			// Check to see if container_mapping was set in options
			if (self.options.container_mapping) {
				// parse container mapping.
				self.options.data = self._setDataMapping(self.options.container_mapping, data);

			} else {
				self.options.data = data;
			}

			// If rest_container is set, encapsulate returned data in a new object with rest_container key.
			if (self.options.rest_container) {
				var data_tmp = {};
				data_tmp[self.options.rest_container] = data;
				self.options.data = data_tmp;
			}

			// Call fillData function in selected objects.
			$objsForDataFill.each(function() {
				$(this).getCUIWidget().fillData(self.options.data);
			});
		},

		/*
	 * @fn _setDataMapping()
	 * @brief Parse container data mapping structures and return a data object to send to fillData.
	 * @param container_mapping is a container_mapping mapping formatted string.
	 * @rest_data is the container in which to looking for data for our data mapping. It is optional for going up a container tree.
	 */
		_setDataMapping : function(container_mapping, rest_data) {
			var self = this, data = {}, data_tmp, key, container_mappings, mappings;

			// Check to see if received a data cointainer name.
			if (!rest_data) {
				// Set rest_data to be data base.
				rest_data = self.options.data;
			}

			// If we find container_mapping option, remove all spaces.
			if (container_mapping) {
				container_mapping = container_mapping.replace(/[\s]/g, "");
			} else {
				debugLog("jquery.restDataFillButtonWidget.js: received empty or null container_mapping");
			}

			// Split container_mapping using comas.
			container_mappings = container_mapping.split(',');

			// Loop through container_mappings to parse data mapping.
			for (var i=0; i<container_mappings.length; i++) {
				// Split string using :.
				mappings = container_mappings[i].split(":");

				if (mappings.length == 1) {
					// If we only find one mapping and mapping is not null or empty then call _setDataMapping.
					if (self.options[mappings[0]]) {
						data[mappings[0]] = self._setDataMapping(self.options[mappings[0]], rest_data);
					} else {
						debugLog("jquery.restDataFillButtonWidget.js: could not find mapping", mappings[0], "in options");
					}
				} else {
					// If mappings length is not equal to 1 then we assume length is 2.
					if (mappings[0]) {
						if (rest_data[mappings[1]]) {
							if (typeof rest_data[mappings[1]] == "object") {
								// If we find mappings[0] and we find rest_data[mappings[1]] and rest_data[mappings[1]] is of type object.
								if (self.options[container_mappings[i]]) {
									// If self.options[container_mappings[i]] is not null or empty then call _setDataMapping.
									data_tmp = self._setDataMapping(self.options[container_mappings[i]], rest_data[mappings[1]]);

									if (data[mappings[0]]) {
										// If data key value found then loop through returned JS objects key values and copy them to data.
										for (key in data_tmp) {
											data[mappings[0]][key] = data_tmp[key];
										}
									} else {
										// If self.options[container_mappings[i]] is null or empty then set data key value
										data[mappings[0]] = data_tmp;
									}
								} else {
									debugLog("jquery.restDataFillButtonWidget.js: could not find mapping", container_mappings[i], "in options");
								}
							} else {
								data[mappings[0]] = rest_data[mappings[1]];
							}
						} else {
							debugLog("jquery.restDataFillButtonWidget.js: could not find data container", mappings[1], "in options.data");
						}
					} else {
						// If we did not find mappings[0]
						if (rest_data[mappings[1]]) {
							if (typeof rest_data[mappings[1]] == "object") {
								// If we find rest_data[mappings[1]] and rest_data[mappings[1]] is of type object.
								if (self.options[container_mappings[i]]) {
									// If self.options[container_mappings[i]] is not null or empty then call _setDataMapping.
									data_tmp = self._setDataMapping(self.options[container_mappings[i]], rest_data[mappings[1]]);

									// Loop through returned JS objects key values and copy them to data.
									for (key in data_tmp) {
										data[key] = data_tmp[key];
									}
								} else {
									debugLog("jquery.restDataFillButtonWidget.js: could not find mapping", container_mappings[i], "in options");
								}
							} else {
								debugLog("Warning: you are mapping a 1 to 1 string variable with", container_mappings[i], 
										 (". the result is the same as "+mappings[1]+":"+mappings[1]));
								data[mappings[1]] = rest_data[mappings[1]];
							}
						} else {
							debugLog("jquery.restDataFillButtonWidget.js: could not find data container", mappings[1], "in options.data");
						}
					}
				}
			}

			return data;
		},

		/*
	 * @fn _onComplete
	 * @brief This function is always called at the end of the action button process.
	 */
		_complete: function() {
		}
	});

	add_widget('restDataFillButtonWidget', 'ui.restDataFillButtonWidget', 'restDataFillButtonWidget', restDataFillButtonWidget);
})(jQuery);
