/* ****************************************************************************************************************************************
*
*  WARNING:  changes to this screen def may affect the first run screen: firstrun_extensions.js , please test any changes on both screens.
*
****************************************************************************************************************************************** */

register_screen('extension_blocks', {
    elements: [
	{
	    entity_template: 'page_title',
	    title: 'Extension Blocks'
	},
	{
	    entity_template: 'page_buttons',
	    buttons: [{ type: 'help', help_id: 'extension_blocks' }]
	},
	{
	    entity_template: 'page_content',
	    "elements": [
		{
		    entity_template: 'data_table',
		    rest: '/gui/extension/block',
		    add_edit_action: { rest: '/gui/extension/block' },
		    delete_action: { rest: '/gui/extension/block' },
		    extra_row_data: [ "bbx_extension_id", "bbx_extension_block_begin", "bbx_extension_block_end", "xsrfkey" ],
		    primary_keys: [ "bbx_extension_id" ],
		    processing: false,
		    paginate: false,
		    length_change: false,
		    oLanguage: {
			sZeroRecords: 'No Extensions in table'
		    },
		    name_column: 'bbx_extension_value',
		    details_row: {
			add_elements: [
			    {
				entity_template: 'extension_select',
				name: 'bbx_extension_value',
				allow: 'block',
				allow_lock_block_toggle: false,
				lock_block: false,
				require_super_block: true,
				show_valid_extension_ranges: false
			    },
			    {
				entity: 'div',
				attributes: { 'class': 'info bare-info' },
				text: 'It is recommended not to use single-digit extension ranges, or ranges that may overlap phone numbers or emergency-services numbers, as doing so may interfere with dialing or using some phone features.'
			    }
			]
		    },
		    columns: [
			{
			    header: 'Extension Block',
			    column: 'bbx_extension_value',
			    data_type: 'num-html',
			    formatted: {
				format: {
				    field: '@bbx_extension_value',
				    formatter: 'phone_number'
				}
			    }
			},
			{
			    header: 'Extension Type',
			    column: 'type',
			    view_element: {
				entity_template: 'translate',
				name: 'type',
				translations: {
				    'user' : 'Person',
				    'group' : 'Group',
				    'queue' : 'Inbound Call Queue',
				    'valet_parking' : 'Valet Parking Block',
				    'conference' : 'Multi-User Conference',
				    'auto_attendant' : 'Automated Attendant',
				    'router' : 'Advanced Call Router'
				},
				default_translation: 'Allocation Block',
				undefined_translation: 'Unknown Type'
			    }
			},
			{
			    header: 'Extension Name',
			    column: 'show_name',
			    view_element: {
				entity_template: 'translate',
				name:'show_name',
				translations: {
				    block: 'Conference'
				}
			    }
			}
		    ],
		    row_actions: {
			animate: false,
			animate_speed: 'slow',
			type: 'expand',
			edit_save: { text: 'Save' },
			edit_cancel: { text: 'Cancel' },
			add_save: { text: 'Add' },
			add_cancel: { text: 'Cancel' },
			delete_row: {
			    text: 'Delete',
			    confirm: {
				title: 'Delete extension block',
				text: 'Delete the selected extension block? This will delete the extension block and all extensions within it.'
			    }
			}
		    },
		    table_actions: {
			add_row: {
			    text: 'New Extension Block',
			    'class': 'hide-row'
			},
			delete_rows: {
			    text: 'Delete Selected',
			    confirm: {
				title: 'Delete selected extension blocks',
				text: 'Delete the selected extension blocks? This will delete the extension blocks and all extensions within them.'
			    }
			},
			select_row: true,
			select_all_rows: true,
			delete_row: { text: 'Delete' }
		    }
		} // DTW element
	    ] // inner elements list
	} // page content element
    ] // outer elements list
}); // register_screen function
