/* jshint jquery: true, unused: vars */
/* global add_widget */

(function($){

	var simpleDatePickerWidget = $.extend({}, $.ui.widget.prototype, {

		value_widget: true,
		set_value_widget: true,
		manages_own_descendent_value: true,
		manages_own_descendent_events: true,

		options: {
			gotoCurrent: true,
			//changeMonth: false,      // Enable these lines to allow the user to switch the Month and Year with a standard browser select box
			//changeYear: false,       //
			dateFormat: 'mm-dd-yy',
			showOtherMonths: true,
			selectOtherMonths: true,
			debugging: false // Change this to 'true' to allow debugLog() messages and Set/Get buttons to be displayed.
		},

		_beforeDOMReady : function () {
			var $self = this.element;
			var self = this;

			self._initializeInputObjects();
		},

		_initializeInputObjects: function () {
			var self = this;
			self.options.inputObjects = self.options.inputObjects || {};

			self.options.inputObjects.$input_box = $('<input type="text" class="datepicker" />');
			self.element.append(self.options.inputObjects.$input_box);

			// Apply the datepicker jQ-UI widget
			self.options.inputObjects.$input_box.datepicker(self.options);

			// $self is not enclosed, so an anonymous function is safe
			self.options.inputObjects.$input_box.on("keyup change", function () { self._updateState(); });

			self.options.date_value = self.options.inputObjects.$input_box.val();
		},

		_updateState: function() {
			var $self = this.element;
			var self = this;
			var inputObjects = self.options.inputObjects;

			self.options.date_value = inputObjects.$input_box.val();
			$self.trigger('change');
		},

		_getWidgetValue: function () {
			return this._wrapValue(this.options.date_value);
		},

		setValue: function(v) {
			var $self = this.element;
			var self = this;
			var inputObjects = self.options.inputObjects;

			self.options.date_value = v;
			inputObjects.$input_box.datepicker("setDate", self.options.date_value);
		}
	});

	add_widget('simpleDatePickerWidget', simpleDatePickerWidget);
})(jQuery);
