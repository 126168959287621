/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, format_information, entity */
(function( $ ){
	var queueMonitorWidget = $.extend({}, $.ui.widget.prototype, CUI.htmlEntityClass, CUI.bubbleInfoClass, {

		options: {
			accept_data_from_parent: true,
			trim_name: 13
		},

		_beforeInit: function () {
			var self = this;
			var $self = this.element;

			$self.addClass('queueMonitorWidgetType');
			self.options.$tr = $self.closest('tr');

			return false;
		},

		fillData: function(data, from_self) {
			var self = this;
			var $self = this.element;

			$self.empty();

			self.options.server_start_time = parseInt(data.timestamp);
			self.options.server_current_time = data.current_time;
			self.options.start_time = new Date().getTime() / 1000;
			self.options.warning_seconds = data.bbx_queue_warning_seconds;
			self.options.critical_seconds = data.bbx_queue_critical_seconds;
			self.options.warning_calls = data.bbx_queue_warning_calls;
			self.options.critical_calls = data.bbx_queue_critical_calls;

			data.bbx_extension_value = format_information(data.bbx_extension_value);

			var active_agents = parseInt(data.active_agents) || 0;
			var total_agents = parseInt(data.total_agents) || 0;
			var callers_waiting = parseInt(data.callers_waiting) || 0;
			var total_calls = parseInt(data.total_calls) || 0;
			var trim = self.options.trim_name;
			var name_trimmed = data.bbx_queue_name;

			if (trim && data.bbx_queue_name.length > trim) {
				name_trimmed = data.bbx_queue_name.slice(0, trim-1) + entity.hellip;
			}

			$self.append("<div class='queue_name'><span class='queue'>" + name_trimmed + "</span>, <span class='extension'>" + 
						 data.bbx_extension_value + "</span></div>");
			$self.append("<div class='agents'><span class='active'>" + active_agents  + "</span>/<span class='total'>" + total_agents + 
						 "</span> avail.</div><br/>");
			$self.append("<div class='callers'><span class='waiting'>" + callers_waiting + "</span>/<span class='total'>" + total_calls + 
						 "</span> waiting</div>");
			$self.append("<div class='timestamp'></div>");

			if (self.options.server_start_time > 0) {
				if (!self.options.ticking) {
					self._setCallTimeTimeout('.timestamp');
				}
			} else {
				if (self.options.ticking) {
					self._removeCallTimeTimeout();
				}
				$self.closest('tr').removeClass('warning').removeClass('critical');
			}

			if (self.options.critical_calls && self.options.critical_calls > 0 && self.options.critical_calls <= callers_waiting) {
				self.options.$tr.addClassDefer('critical').removeClassDefer('warning');
			} else if (self.options.warning_calls && self.options.critical_calls > 0 && self.options.warning_calls <= callers_waiting) {
				self.options.$tr.addClassDefer('warning').removeClassDefer('critical');
			} else {
				self.options.$tr.removeClassDefer('warning critical');
			}
			self.options.$tr.applyClassDefer();


		}

	});    
	add_widget('queueMonitorWidget', queueMonitorWidget);
})(jQuery);
