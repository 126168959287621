register_screen('calltypes', {
    elements: [
	{ entity_template: 'page_title', title: 'Call Types' },
	{
	    entity_template: 'page_buttons',
	    buttons: [
		{
		    type: 'help',
		    help_id: 'providers_call_types'
		}
	    ]
	},
	{
	    entity_template: 'page_content', elements: [
		{
		    entity_template: 'data_table',
		    click_action: "edit",
		    rest: '/gui/route/route',
		    primary_keys: [ 'bbx_outbound_route_id' ],
		    extra_row_data: [ 'bbx_outbound_route_id' ],
		    add_edit_action: { rest: '/gui/route/route' },
		    delete_action: { rest: '/gui/route/route' },
		    oLanguage: {
			sZeroRecords: 'No Call Types Configured'
		    },
		    paginate:      false,
		    length_change: false,
		    filtering:     false,
		    processing:    false,
		    sortable:      false,
		    columns: [
			{
			    header: 'Call Type Name',
			    column: 'bbx_outbound_route_name',
				'class': 'break-long',
				sortable: false,
			    add_element: {
				entity: 'input',
					attributes: { type: 'text', name: 'bbx_outbound_route_name' },
					widgets: ['aopbStateWidget'],
					widget_options: { aopbStateWidget: {
						closest: 'td',
						states: [{
						conditions: { a: '$$', op: '==', b: '^ *$', b_is: 'regexp' },
						actions: { invalid: 'Enter a name' },
						else_actions: { invalid: false }
					}]}}
			    },
			    edit_element: {
				entity: 'input',
					attributes: { type: 'text', name: 'bbx_outbound_route_name' },
					widgets: ['aopbStateWidget'],
					widget_options: { aopbStateWidget: {
						closest: 'td',
						states: [{
						conditions: { a: '$$', op: '==', b: '^ *$', b_is: 'regexp' },
						actions: { invalid: 'Enter a name' },
						else_actions: { invalid: false }
					}]}}
			    }
			},
			{
			    header: 'Match Condtion',
			    column: 'bbx_outbound_route_regex',
				'class': 'break-long',
			    sortable: false,
			    add_element: {
				entity: 'input',
				attributes: { type: 'text', name: 'bbx_outbound_route_regex' }
			    },
			    edit_element: {
				entity: 'input',
				attributes: { type: 'text', name: 'bbx_outbound_route_regex' }
			    }
			},
			{
			    header: 'Route Type',
			    column: 'bbx_outbound_route_type',
			    sortable: false,
			    translate: {
				subscriber: 'Internal',
				local: 'Local',
				national: 'Long Distance',
				international: 'International',
				emergency: 'Emergency',
				information: 'Information'
			    },
			    add_element: {
				entity: 'select',
				attributes: { name: 'bbx_outbound_route_type' },
				select_options: [
				    { value: 'subscriber', text: 'Internal' },
				    { value: 'local', text: 'Local' },
				    { value: 'national', text: 'Long Distance' },
				    { value: 'international', text: 'International' },
				    { value: 'emergency', text: 'Emergency' },
				    { value: 'information', text: 'Information' }
				]
			    },
			    edit_element: {
				entity: 'select',
				attributes: { name: 'bbx_outbound_route_type' },
				select_options: [
				    { value: 'subscriber', text: 'Internal' },
				    { value: 'local', text: 'Local' },
				    { value: 'national', text: 'Long Distance' },
				    { value: 'international', text: 'International' },
				    { value: 'emergency', text: 'Emergency' },
				    { value: 'information', text: 'Information' }
				]
			    }
			}
		    ],
		    row_actions: {
			animate: false,
			type: 'expand',
			edit_save: { text: 'Save' },
			edit_cancel: { text: 'Cancel' },
			add_save: { text: 'Add' },
			add_cancel: { text: 'Cancel' },
			delete_row: { 'text' : 'Delete' }
		    },
		    table_actions: {
			add_row: {
			    text: 'Add Call Type',
			    location: 'both'
			},

			delete_rows: {
			    text: 'Remove Selected',
			    location: 'both',
			    confirm: {
				title: 'Remove Selected',
				text: 'Are you sure you want to remove the selected call types?'
			    }
			},

			select_row: true,
			select_all_rows: true
		    }


		} // END data_table

	    ] // END page_content elements
	} // END page_content
    ] // END template elements
}); // END template
