/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */

(function( $ ){
	var showHideSwitchWidget = $.extend(true, {}, $.ui.switchWidget.prototype, {
		options: {
			_built: {},
			_elements: {}
		},

		_clear: function () {
			var $self = this.element;
			$self.children().hide();
		},

		_afterInit: function () {
			var self = this, $self = this.element;
			self._delegate($self, ':input', 'change', CUI.FunctionFactory.build(self._updateData, self, { context: 'argument', first: 'context' }));
		},

		_updateData: function(elem, e, d) {
			var self = this, $self = this.element, $elem = $(elem);
			self.options._data_store = self.options._data_store || {};
			self.options._data_store[$elem.attr('name')] = $elem.val();
		},

		_handleChange: function (elem, e, d) {
			var self = this, $self = this.element, $elem = $(elem);
			var val = CUI.getWidgetElementValue($elem, { first_value: true });
			if (self.options._previous !== val) {
				if (self.options._built[val]) {
					self._clear();
					self.options._elements[val].show();
				} else {
					self.options._built[val] = true;
					$.ui.switchWidget.prototype._handleChange.apply(self, arguments);
					self.options._elements[val] = self.options._$current_element;
				}
			} else {
				return;
			}
		}
	});

	add_widget('showHideSwitchWidget', showHideSwitchWidget);
})(jQuery);

