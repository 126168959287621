/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, format_information, cmp */
/*
  extensionSelectWidget: A widget used to select an extension. Creates its own data_item (DL/DT/DD) elements. This is a WRITE ONLY widget, and does not
                         properly implement setValue, etc.

  Apply to a DIV or other block element. Do not apply this widget within a data_item or DD.

  Options:
    // Which extension types can be created with this widget?
    allow: ['nextfree','single','block','existing_block','external'] | <Any combination of these. At least one is required.>,

    // This only applies when "block" is not specified, but "external" is. If false, block external-extensions will not be listed in the External list.
    allow_block_externals: false | true, 

    allow_super: true | false,   // Allow creating an extension or block outside an existing block
    require_super: false | true, // Allow ONLY super-block extensions

    show_valid_extension_ranges: true | false, // Show/hide the "Valid Extension Ranges" display

    lock_block: false | true,              // Should blocks be created (and validated) with "flag_locked" set
    allow_lock_block_toggle: true | false, // Should the UI allow changing "flag_locked". If false, the value of "locked_block" is always applied.

    min_block_size: 2 | <positive integer> | undefined, // Minimum allowed block size. This does not affect single or external extensions.
    max_block_size: undefined | <positive integer>,     // Maximum allowed block size.

    next_free_value: { extension_next_free: 1 } | <object> | undefined, // What key/value to return when "Next Free Extension" is selected

    // If true, this will combine block extensions into one string ("xxxx-yyyy"), return the value keyed from the name of the element, like...
    //
    //    { <name of element>: '2000-2005' }
    //
    // ...this will also implicitly set "allow_lock_block_toggle" false, as the simple string cannot encompass that value.
    // If false, the value from this widget will be returned in an object with predefined bbx_extension_... keys, regardless of the name of the element.
    return_string_value: false | true,

  Requires:
    cmp (helpers.js)
    format_information (helpers.js)
    CUI.FunctionFactory
    CUI.hasKeys (cui.objectKeys.js)

*/

(function( $ ){
	var error_messages = {
		// Server-generated errors
		IN_USE: 'The extension you have selected is currently in use.',
		INTERSECTS: 'The block intersects with another block.',
		NO_EXT: 'No extension was given.',
		LOCKED_CONTAINS_OBJECTS: 'This block already contains assigned extensions. You must allow extensions within the block to assign it.',
		BLOCK_LOCKED: 'This extension is within a block that does not allow extensions.',
		INVALID: 'This is not a valid extension number.',
		MIXED:   'This range contains existing external numbers.',

		// Frontend-generated errors
		VARYING_LENGTHS: 'Start and end extensions must have the same number of digits.',
		REVERSED: 'Start must be a smaller number than end.',
		BAD_CHARS: 'Enter digits only.',
		OUTSIDE: 'The extension must be within an existing block.',
		TOO_SMALL: 'The block must be at least % extensions in length.',
		TOO_LARGE: 'The block may not be more than % extensions in length.',

		// Frontend errors generated from server response
		SUPER_NOT_ALLOWED: 'The extension is not within the extension allocation space.',
		NOT_SUPER: 'The block must is within another existing block',

		// We should never receive this.
		_UNKNOWN: 'Invalid Extension (Unrecognized Error)'
	};


	var extensionSelectWidget = $.extend({}, $.ui.widget.prototype, {
		value_widget: true,
		manages_own_descendent_value: true,
		manages_own_descendent_state: true,

		options: {
			// allow: ['nextfree','single','block','existing_block','external'] | <any combination thereof, at least one is required>,
			allow_block_externals: false, // If "block" is not specified, but "external" is, "external" will not show block extensions unless this is true

			show_valid_extension_ranges: true,
			allow_super: true,    // Allow creating an extension or block outside an existing block
			require_super: false, // Allow ONLY super-block extensions

			// If TRUE, this will combine block extensions into one string ("xxxx-yyyy"), return the value keyed from the name of the element, and set
			// "allow_lock_block_toggle" to FALSE, as the string cannot encompass that value. If FALSE, the value from this widget will be returned in an
			// object with predefined bbx_extension_... keys, regardless of the name of the element.
			return_string_value: false,

			lock_block: false,
			allow_lock_block_toggle: true,

			min_block_size: 2,
			max_block_size: undefined,

			reset_on_form_data_ready: true,

			// Set in beforeInit to avoid extending when you mean to clobber...
			// next_free_value: { extension_next_free: 1 }

			_allow_lookup: {}, // s.o.allow, in a handy lookup object
			_prev_vals: {}
		},

		_beforeInit: function () {
			var self = this, $self = this.element, a_idx;

			self._setSelfInvalid('pre_render');

			if (self.options.return_string_value) {
				// The "locked" setting cannot be added to the simple text string, so toggling is disabled. (The "lock_block" option is still consulted when
				// validating, but is not represented in the value returned by this widget.)
				self.options.allow_lock_block_toggle = false;
			}

			if (!self.options.allow) {
				self.options.allow = ['nextfree','single','block','existing_block','external'];
			} else if (typeof self.options.allow === 'string') {
				self.options.allow = self.options.allow.split(' ');
			}

			a_idx = self.options.allow.length;
			while (a_idx--) {
				self.options._allow_lookup[self.options.allow[a_idx]] = true;
			}

			if (!('next_free_value' in self.options)) {
				self.options.next_free_value = { extension_next_free: 1 };
			}

			self._bind($self, 'click change stateChange variableContainerChange', CUI.FunctionFactory.build(self._trimEvents, self, { context: 'argument', first: 'context' }));
		},


		_beforeDOMReady: function () {
			var self = this, $self = this.element;

			if (self.options.allow.length > 1) {
				self._bind($self, 'variableContainerChange', self._typeChangeHandler.bind(self));
			} else {
				$self.find('.extension-type-select-row').hide();
			}

			CUI.getREST('/gui/extension/availableblocks', {}, self._dataHandler.bind(self, 'valid'));

			if (self.options._allow_lookup.external) {
				self.getREST('/gui/extension/availableexternals', {}, self._dataHandler.bind(self, 'externals'));
			}

			if (self.options._allow_lookup.single) {
				self._delegate($self, '.single-extension-input', 'keyup change', self._validateSingleHandler.bind(self));
			}

			if (self.options._allow_lookup.block) {
				self._delegate($self, '.lock-block-checkbox', 'change', self._validateBlockHandler.bind(self));
				self._delegate($self, '.block-extension-input', 'keyup change', self._validateBlockHandler.bind(self));
			}

			if (self.options._allow_lookup.existing_block) {
				self._delegate($self, '.existing-block-select,.lock-existing-block-checkbox', 'change', self._validateExistingBlockHandler.bind(self));
			}
		},

		// This function centralizes pre-render REST responses and checks that all data is ready before rendering the widget.
		_dataHandler: function (source, d) {
			var self = this;
			if (self.options.destroyed) { return; }

			self.options._d = self.options._d || {};

			switch (source) {
				case 'externals':
					self.options._d.externals = d;
					break;
				case 'valid':
					self.options._d.valid = d;
					break;
			}

			if ((self.options._d.externals || !self.options._allow_lookup.external) && self.options._d.valid) {
				// Continue making the widget, all data is here
				self._render();
				self._buildAvailableBlocks(self.options._d.valid);
				if (self.options._allow_lookup.external) { self._buildAvailableExternals(self.options._d.externals); }

				self._setSelfValid('pre_render');
				self._typeChangeHandler();
				delete self.options._d;
			}
		},

		_trimEvents: function (elem, e) {
			var self = this, $self = this.element;
			if (e.target !== $self[0]) {
				e.stopPropagation();
			}
		},

		_typeChangeHandler: function () {
			var self = this, $self = this.element, type;

			if (self.options.allow.length > 1) {
				type = $self.find('.extension-type-select').val();
			} else {
				type = self.options.allow[0];
			}

			self._setSelfValid('testing');
			self._setSelfInvalid('invalid_extension');

			delete self.options._value;
			self._prev_vals = {};
			self.options._type = type;

			switch (type) {
				case 'nextfree':
					self._setSelfValid('invalid_extension');
					if (self.options.next_free_value !== undefined) {
						self.options._value = self.options.next_free_value;
					}
					break;
				case 'single':
					break;
				case 'block':
					break;
				case 'existing_block':
					self._fillExistingBlockSelect();
					break;
				case 'external':
					self._fillExternalSelect();
					break;
			}

			$self.trigger('change');
		},

		_validateSingleHandler: function () {
			var self = this, $self = this.element, $ext, ext, n_ext, b_idx, bs_idx, block_set, block, within;

			if (self.options._current_ajax) { self.options._current_ajax.abort(); }
			delete self.options._current_ajax;

			$ext = $self.find('.single-extension-input');
			ext = $ext.val();

			// Check if anything has changed. If we just changed on a keyup, and this is the change event, there is no need to re-validate.
			// This prevents a collision when you click to submit the form. (Otherwise, the blur causes a change, this widget goes into invalid while it
			// waits for the validation return, and the submission fails because the formerly-valid form is now invalid.)
			if (ext === self.options._prev_vals.ext) { return; }
			self.options._prev_vals.ext = ext;

			// Start validating...
			if (ext.search(/[^0-9]/) !== -1) {
				self._setControlInvalid($ext, 'invalid_extension', error_messages.BAD_CHARS);
				return;
			}

			if (ext === '') {
				self._setControlValid($ext, 'invalid_extension');
				self._setSelfInvalid('invalid_extension');
				return;
			}

			n_ext = Number(ext);

			bsLoop: for (bs_idx = 0; bs_idx < self.options._block_ranges.length; bs_idx++) {
				block_set = self.options._block_ranges[bs_idx];
				bLoop: for (b_idx = 0; b_idx < block_set.length; b_idx++) {
					block = block_set[b_idx];

					if (Number(block.start) === n_ext) {
						// Start is the same as selected, so within.
						within = block;
						break bsLoop;
					} else if (Number(block.start) < n_ext) {
						// Start is less than selected...
						if (Number(block.end) >= n_ext) {
							// ...and end is after, so within.
							within = block;
							break bsLoop;
						} else {
							// ...and end is after, so not within. Check the next (if any) block on this set.
							continue bLoop;
						}
					} else {
						// Start is after selected, and since they're sorted, we have no chance of finding a matching block.
						break bsLoop;
					}
				}
			}

			if (!within) {
				self._setControlInvalid($ext, 'invalid_extension', error_messages.OUTSIDE);
				return;
			}

			self._setSelfInvalid('testing');

			// Pass to the backend validation
			self.options._current_ajax = CUI.getREST('/gui/extension/valid', { bbx_extension_value: ext }, self._testSingleHandler.bind(self));

		},

		_testSingleHandler: function (d) {
			var self = this, $self = this.element, $ext, data = d.valid, why;
			self._setSelfInvalid('testing');

			if (self.options.destroyed) { return; }

			delete self.options._current_ajax;
			self._setSelfValid('testing');

			$ext = $self.find('.single-extension-input');

			if (data.valid) {
				if (self.options.return_string_value) {
					self.options._value = $ext.val();
				} else {
					self.options._value = { bbx_extension_value: $ext.val() };
				}

				self._setControlValid($ext, 'invalid_extension');
				self._setSelfValid('invalid_extension');
				$self.trigger('change');
				return;
			} else {
				why = error_messages[data.why] || error_messages.UNKNOWN;
				self._setControlInvalid($ext, 'invalid_extension', error_messages[data.why] || error_messages.UNKNOWN);
				return;
			}
		},

		_validateBlockHandler: function () {
			var self = this, $self = this.element, $start, start, $end, end, locked;

			if (self.options._current_ajax) { self.options._current_ajax.abort(); }
			delete self.options._current_ajax;

			$start = $self.find('.block-start-input');
			$end =   $self.find('.block-end-input');

			start =  $start.val();
			end =    $end.val();

			if (self.options.allow_lock_block_toggle) {
				locked = !!($self.find('.lock-block-checkbox:checked')[0]);
			} else {
				locked = !!self.options.lock_block;
			}

			// Check if anything has changed, same as above.
			if (start === self.options._prev_vals.start && end === self.options._prev_vals.end && locked === self.options._prev_vals.locked) { return; }
			self.options._prev_vals.start = start;
			self.options._prev_vals.end = end;
			self.options._prev_vals.locked = locked;

			// Start validating...
			if ((start + end).search(/[^0-9]/) !== -1) {
				self._setControlInvalid($end, 'invalid_extension', error_messages.BAD_CHARS);
				return;
			}

			if (start === '' || end === '') {
				self._setControlValid($end, 'invalid_extension');
				self._setSelfInvalid('invalid_extension');
				return;
			}

			if (Number(start) >= Number(end)) {
				self._setControlInvalid($end, 'invalid_extension', error_messages.REVERSED);
				return;
			}

			if (start.length !== end.length) {
				self._setControlInvalid($end, 'invalid_extension', error_messages.VARYING_LENGTHS);
				return;
			}

			if (self.options.min_block_size && (Number(end) - Number(start) + 1) < Number(self.options.min_block_size)) {
				self._setControlInvalid($end, 'invalid_extension', error_messages.TOO_SMALL.replace(/%/g, self.options.min_block_size));
				return;
			}

			if (self.options.min_block_size && (Number(end) - Number(start) + 1) > Number(self.options.max_block_size)) {
				self._setControlInvalid($end, 'invalid_extension', error_messages.TOO_LARGE.replace(/%/g, self.options.min_block_size));
				return;
			}
			self._setSelfInvalid('testing');

			// Pass to the backend validation
			self.options._current_ajax = CUI.getREST('/gui/extension/valid', { bbx_extension_block_begin: start, bbx_extension_block_end: end, flag_locked: locked ? 1 : 0 }, self._testBlockHandler.bind(self));
		},

		// This is used by BOTH the "block" and "existing block" responses
		_testBlockHandler: function (d) {
			var self = this, $self = this.element, data = d.valid, why, start, end, $err_input, locked;
			if (self.options.destroyed) { return; }

			delete self.options._current_ajax;
			self._setSelfValid('testing');

			// Search for either-- this allows us to use this function for both the manual and existing block UI
			$err_input = $self.find('.block-end-input,.existing-block-select');

			if (data.valid) {
				if (data.why === 'SUPER' && !self.options.allow_super) {
					why = 'SUPER_NOT_ALLOWED';
				} else if (data.why !== 'SUPER' && self.options.require_super) {
					why = 'NOT_SUPER';
				} else {
					switch (self.options._type) {
						case 'block':
							start = $self.find('.block-start-input').val();
							end = $self.find('.block-end-input').val();
							break;
						case 'existing_block':
							var $select = $err_input, matches;
							matches = $select.val().split('-');
							start = matches[0];
							end = matches[1];
							break;
					}

					if (self.options.return_string_value) {
						self.options._value = start + '-' + end;
					} else {
						locked = (self.options.allow_lock_block_toggle ? $self.find('.lock-block-checkbox:checked')[0] : self.options.lock_block) ? 1 : 0;
						self.options._value = { bbx_extension_block_begin: start, bbx_extension_block_end: end, flag_locked: locked };
					}

					self._setControlValid($err_input, 'invalid_extension');
					self._setSelfValid('invalid_extension');
					$self.trigger('change');
				}
			} else {
				why = error_messages[data.why] || error_messages.UNKNOWN;
			}


			if (why) {
				self._setControlInvalid($err_input, 'invalid_extension', error_messages[data.why]);
				return;
			}
		},

		_render: function () {
			var self = this, $self = this.element, $content, el_def, types_select_options, only_select_options, block_elements, existing_block_elements, switch_elements, se_key, idx;

			types_select_options = [
				{ text: 'Next Free', value: 'nextfree' },
				{ text: 'Single Extension', value: 'single' },
				{ text: 'Block Extension', value: 'block' },
				{ text: 'Existing Block', value: 'existing_block' },
				{ text: 'External Number', value: 'external' }
			];

			// Build the "block" elements list 
			block_elements = [
				{
					entity: 'input',
					attributes: { type: 'text', size: '6', 'class':'block-start-input block-extension-input' }
				},
				{
					entity: 'input',
					attributes: { type: 'text', size: '6', name: 'end', 'class':'block-end-input block-extension-input' }
				}
			];

			// Build the "existing block" elements
			existing_block_elements = [{
				entity: 'select',
				attributes: { name: 'existing_block', 'class':'existing-block-select' }
			}];

			if (self.options.allow_lock_block_toggle) {
				var lock_block_checkbox = {
					entity_template: 'container',
					entity: 'label',
					attributes: { 'class':'lock-block-container', style: 'display: block' },
					elements: [
						{
							entity: 'input',
							attributes: { type: 'checkbox', 'class':'lock-block-checkbox' }
						},
						{
							entity: 'span',
							text: ' Do not allow extensions within this block'
						}
					]
				};

				block_elements.push($.extend(true, {}, lock_block_checkbox));

				lock_block_checkbox.elements[0].attributes['class'] = 'lock-existing-block-checkbox';
				existing_block_elements.push(lock_block_checkbox);
			}

			switch_elements = {
				nextfree: [{
					entity_template: 'data_item',
					title: '',
					elements: [{ entity: 'div', text: '(The next free extension number will be used.)' }]
				}],
				single: [{
					entity_template: 'input_item',
					input_name: 'single',
					title: 'Extension:',
					type: 'text',
					input_options: { attributes: { 'class':'single-extension-input' } }
				}],
				block: [{
					entity_template: 'data_item',
					title: 'Block Start/End:',
					elements: block_elements // Built above
				}],
				existing_block: [{
					entity_template: 'data_item',
					title: 'Block:',
					elements: existing_block_elements // Built above
				}],
				external: [{
					entity_template: 'input_item',
					title: 'External Number:',
					input_name: 'external',
					type: 'select',
					input_options: { attributes: { 'class':'external-extension-select' } }
				}]
			};

			// Remove any switch_elements entries that were not in the "allow" array...
			for (se_key in switch_elements) {
				if (!switch_elements.hasOwnProperty(se_key)) { continue; }
				if (!self.options._allow_lookup[se_key]) { delete types_select_options[se_key]; }
			}

			// Build the el_def (elements definition)
			el_def = [];

			// Valid extension ranges element definition
			if (self.options.show_valid_extension_ranges) {
				el_def = el_def.concat([{
					entity_template: 'data_item',
					title: 'Valid Extension Ranges:',
					elements: [{
						entity: 'div',
						attributes: { 'class':'extension-select-valid-ranges' }
					}]
				}]);
			}

			// Trim the SELECT options
			only_select_options = [];
			for (idx = 0; idx < types_select_options.length; idx++) {
				if (self.options._allow_lookup[types_select_options[idx].value]) {
					only_select_options.push(types_select_options[idx]);
				}
			}

			// SELECT box and switchWidget
			if (only_select_options.length > 1) {
				el_def = el_def.concat([{
					entity_template: 'input_item',
					title: 'Extension Type:',
					type: 'select',
					'class':'extension-type-select-row',
					select_options: only_select_options,
					input_options: { attributes: { 'class':'extension-type-select' } }
				},
										{
											entity_template: 'switch',
											init_before_data: true,
											closest: '.extensionSelectWidget',
											selector: '.extension-type-select',
											switch_elements: switch_elements
										}]);
			} else if (switch_elements[self.options.allow[0]]) {
				// If only one option, just append the element without needing the switchWidget
				el_def = el_def.concat(switch_elements[self.options.allow[0]]);
			}

			// Render it!
			if (!(el_def instanceof Array)) { el_def = [el_def]; }
			$content = CUI.htmlEntityClass.getEntitiesHTML(el_def, self.options.data);

			$self.empty().append($content);

			if ($content.find('.widgetType:not(.widgetized)')[0]) {
				self.widgetizeChildren($content);
				self.fillDataChildren({}, true);
			}

			$self.trigger('formElementChange');
			$self.trigger('variableContainerChange');
		},

		_setSelfValid: function (key) {
			var self = this, $self = this.element;
			CUI.setValid($self, key || self.options.widget_id);
			$self.trigger('stateChange');
		},

		_setSelfInvalid: function (key, message) {
			var self = this, $self = this.element;
			CUI.setInvalid($self, message, key || self.options.widget_id);
			$self.trigger('stateChange');
		},

		// This does NOT key the is-invalid on the control-- multiple invalid states on the same control should not be used!
		_setControlValid: function ($elem, key) {
			var self = this, $self = this.element;
			CUI.setValid($elem, key || self.options.widget_id);
			$self.trigger('change');
		},

		_setControlInvalid: function ($elem, key, message) {
			var self = this, $self = this.element;
			CUI.setInvalid($elem, message, key || self.options.widget_id);
			self._setSelfInvalid(key);
			$self.trigger('change');
		},

		_buildAvailableExternals: function (d) {
			var self = this, $self = this.element, ae = d.availableexternals, externals, idx, ext, ext_data;

			if ( self.options.destroyed ) { return; }

			externals = { block: [], single: [] };

			// Fill in External Blocks
			if (ae.blocks) {
				for (idx = 0; idx < ae.blocks.length; idx++) {
					ext = ae.blocks[idx];
					ext_data = $.extend(true, {}, ext, {
						text: format_information(ext.bbx_extension_value, { ndash: true })
					});

					externals.block.push(ext_data);
				}
			}

			// Fill in Single Extensions
			if (ae.singles) {
				for (idx = 0; idx < ae.singles.length; idx++) {
					ext = ae.singles[idx];

					externals.single.push({
						text: format_information(ext),
						bbx_extension_value: ext
					});
				}
			}

			self.options._available_externals = externals;
		},

		// Fill the "External Extensions" SELECT box with the values gathered from /gui/extension/availableexternals
		_fillExternalSelect: function () {
			var self = this, $self = this.element, $select, $optgroup, externals, idx, ext, show_blocks, show_singles, external_index = 0;

			if (self.options.destroyed) { return; }

			self.options._external_select_values = [];
			externals = self.options._available_externals;
			show_blocks = !!(externals.block.length && (self.options._allow_lookup.block || self.options.allow_block_external));
			show_singles = !!externals.single.length;

			$select = $self.find('.external-extension-select');
			if (!(externals.block.length || externals.single.length)) {
				$select.empty().append('<option value="">(No External Extensions)</option>').disable('no_external');
				self._setSelfInvalid('invalid_extension');
				return;
			}

			$select.empty();

			if (show_blocks) {
				if (show_singles) {
					$optgroup = $('<optgroup label="Block Numbers" />');
				} else {
					$optgroup = $select;
				}

				for (idx = 0; idx < externals.block.length; idx++) {
					ext = externals.block[idx];
					self.options._external_select_values[external_index] = { bbx_extension_value: ext.bbx_extension_value, bbx_extension_id: ext.bbx_extension_id };
					// data-ext-value is used only to facilitate testing by creating a content-addressable value for each OPTION
					$('<option />').text(ext.text).val(external_index++).attr('data-ext-value', ext.bbx_extension_value).appendTo($optgroup);
				}

				if (show_singles) {
					$optgroup.appendTo($select);
				}
			}

			if (show_singles) {
				if (show_blocks) {
					$optgroup = $('<optgroup label="Individual Numbers" />');
				} else {
					$optgroup = $select;
				}

				for (idx = 0; idx < externals.single.length; idx++) {
					ext = externals.single[idx];
					self.options._external_select_values[external_index] = { bbx_extension_value: ext.bbx_extension_value, bbx_extension_id: ext.bbx_extension_id };
					// data-ext-value is used only to facilitate testing by creating a content-addressable value for each OPTION
					$('<option />').text(ext.text).val(external_index++).attr('data-ext-value', ext.bbx_extension_value).appendTo($optgroup);
				}

				if (show_blocks) {
					$optgroup.appendTo($select);
				}
			}

			self._setSelfValid('invalid_extension');
			$select.trigger('change');
		},

		_buildAvailableBlocks: function (d) {
			var self = this, $self = this.element, block_data = d.availableblocks, block_ranges = [], bl_idx, ver = [], idx, block_size;

			block_data = block_data['super'] || [];
			block_data = block_data.sort(function (a,b) { return cmp(Number(a.bbx_extension_block_begin), Number(b.bbx_extension_block_begin)); });

			for (idx = 0; idx < block_data.length; idx++) {
				// Fill in the "Valid Extension Ranges"
				ver.push(format_information(block_data[idx].bbx_extension_value, { ndash: true }));

				bl_idx = block_ranges.length - 1;

				if (!idx || Number(block_data[idx].bbx_extension_block_begin) !== block_ranges[bl_idx].start) {
					block_ranges.push([]);
					bl_idx++;
				}

				// Use this to determine whether extensions are in-range
				block_ranges[bl_idx].push({
					start:  block_data[idx].bbx_extension_block_begin,
					end:    block_data[idx].bbx_extension_block_end,
					digits: ((block_data[idx].bbx_extension_value.length - 1) / 2)
				});
			}

			self.options._block_ranges = block_ranges; 
			self.options._block_existing = d.availableblocks.unused || [];

			// Remove blocks that do not meet the size requirement
			if (self.options.min_block_size || self.options.max_block_size) {
				idx = self.options._block_existing.length;
				while (idx--) {
					block_size = Number(self.options._block_existing[idx].bbx_extension_block_end) - Number(self.options._block_existing[idx].bbx_extension_block_begin) + 1;
					if (
						(self.options.min_block_size && block_size < Number(self.options.min_block_size)) ||
						(self.options.max_block_size && block_size > Number(self.options.max_block_size))
					) {
						self.options._block_existing.splice(idx, 1);
					}
				}
			}

			$self.find('.extension-select-valid-ranges').text(ver.join(', '));
			self._setSelfValid('loading_blocks');
		},

		_fillExistingBlockSelect: function () {
			var self = this, $self = this.element, $select, blocks, idx;
			if (self.options.destroyed) { return; }

			blocks = self.options._block_existing;
			$select = $self.find('.existing-block-select');

			if (!blocks || !blocks.length) {
				$select.empty().append('<option value="">(No existing blocks available)</option>');
				$select.add($self.find('.lock-existing-block-checkbox')).disable('no_existing');
				self._setSelfInvalid('invalid_extension');
				return;
			}

			$select.empty();

			for (idx = 0; idx < blocks.length; idx++) {
				$select.append($('<option />').text(format_information(blocks[idx].bbx_extension_value)).val(blocks[idx].bbx_extension_value));
			}

			$select.trigger('change');
		},

		_validateExistingBlockHandler: function () {
			var self = this, $self = this.element, block, ext_match, start, end, locked;
			if (self.options._current_ajax) { self.options._current_ajax.abort(); }
			delete self.options._current_ajax;

			block = $self.find('.existing-block-select > option:selected').val();
			ext_match = block.split('-');
			start = ext_match[0];
			end = ext_match[1];
			locked = $self.find('.lock-existing-block-checkbox:checked')[0] ? 1 : 0;

			// Pass to the backend validation -- this uses the same post-validation function as the regular block extension
			self.options._current_ajax = CUI.getREST('/gui/extension/valid', { bbx_extension_block_begin: start, bbx_extension_block_end: end, flag_locked: locked ? 1 : 0 }, self._testBlockHandler.bind(self));
		},

		_getWidgetValue: function () {
			var self = this, $self = this.element, value;

			switch (self.options._type) {
				case 'external':
					value = self.options._external_select_values[Number($self.find('.external-extension-select').val())];
					if (value === undefined) { return; } // No extensions available

					if (self.options.return_string_value) {
						value = value.bbx_extension_value;
					} else {
						value = value.bbx_extension_id ? { bbx_extension_id: value.bbx_extension_id } : { bbx_extension_value: value.bbx_extension_value };
					}

					break;
				default:
					value = self.options._value;
			}

			// This will vary based on s.o.return_string_value, but this determination is made in the individual type processing code.
			if (typeof value === 'string') {
				return self._wrapValue(value);
			} else {
				return value;
			}
		},

		fillData: function () {},
		setValue: function () {} // This is a write-only widget

	});

	add_widget('extensionSelectWidget', extensionSelectWidget);
})(jQuery);
