register_screen('queue_monitor', {
    "elements" : [
	{ "entity_template" : "page_title", "title" : "Queue Monitor" },
	{
	    "entity_template" : "page_buttons",
	    "buttons" : [{ "type" : "fullscreen" }, {'type': 'help', help_id:'switchboard_queue_monitor'}]
	},
	{ "entity_template" : "page_content",
	  "class" : "switchboard",
	  "elements" : [
	      {
		  "entity_template" : "container",
		  "class" : "queuewall_callers bubble_table",
		  "elements" : [
		      {
			  "entity_template" : "callers_waiting_table",
			  "disable_cui_wrapper" : true,
			  "name" : "queuemonitor_callers"
		      },
		      {
			  "entity_template" : "wallboard_resizer",
			  align: 'left',
			  "min_font_size" : 80,
			  "max_font_size_docked" : 250,
			  "max_font_size_fullscreen" : 350,
			  "bubble_width" : 30,
			  "bubble_height" : 3.7,
			  "max_columns" : 1
		      }
		  ]
	      },
	      {
		  "entity_template" : "container",
		  "class" : "queuewall_queues bubble_table",
		  "elements" : [
		      {
			  "entity_template" : "data_table",
			  "disable_cui_wrapper" : true,
			  "name" : "queuemonitor_queues",
			  "live_table": "live_fifo_queues",
			  "live_table_key": "fifo_queues",
			  "data_source" : "live",
			  "page_size" : 10000,
			  "filtering" : false,
			  "length_change" : false,
			  "paginate" : false,
			  "processing" : false,
			  "sortable" : false,
			  "oLanguage" : {
			      "sZeroRecords" : "No queues."
			  },
			  "extra_row_data" : [
			      "bbx_queue_name",
			      "bbx_extension_value",
			      "bbx_queue_warning_seconds",
			      "bbx_queue_warning_calls",
			      "bbx_queue_critical_seconds",
			      "bbx_queue_critical_calls",
			      "bbx_queue_outbound_name",
			      "active_agents",
			      "total_agents",
			      "callers_waiting",
			      "total_calls",
			      "current_time",
			      "timestamp"
			  ],
			  "columns" : [
			      {
				  "header" : "Queue",
				  "column" : "bbx_queue_name",
				  "sortable" : false,
				  "view_elements" : [
				      { "entity_template" : "queue_monitor" }
				  ]
			      }
			  ]
		      },
		      {
			  "entity_template" : "wallboard_resizer",
			  align: 'right',
			  "min_font_size" : 80,
			  "max_font_size_docked" : 200,
			  "max_font_size_fullscreen" : 250,
			  "bubble_width" : 40,
			  "bubble_height" : 2.7
		      }
		  ]
	      }
	  ]
	}
    ]
});
