/* jshint jquery: true, unused: vars */
/*

  jQuery plugin to support positioning an element, or getting its position, relative to another element

  USAGE:
  $(...).relative($relative_to); RETURNS OBJECT { top: ..., left: ... }
  $(...).relative($relative_to, { top: ..., left: ... }); RETURNS JQUERY (chainable)

*/

(function ($) {
	$.fn.relative = function (rel_to, coords) {
		var rel_pos, new_pos, this_offs, rel_offs;
		rel_offs = $(rel_to).offset() || { top: 0, left: 0 };
		if (coords) {
			new_pos = { top: rel_offs.top + (coords.top || 0), left: rel_offs.left + (coords.left || 0) };
			return this;
		} else {
			this_offs = this.offset();
			rel_pos = { top: this_offs.top - rel_offs.top, left: this_offs.left - rel_offs.left };
			return rel_pos;
		}
	};
})(jQuery);
