(function () {
    var _scr_names = ['firstrun_activation','activation'];
    for (var s_idx = 0; s_idx < _scr_names.length; s_idx++) {
	var _scr = _scr_names[s_idx];

	var _scr_def = {
	    elements: [
		{ entity_template: 'page_title', title: 'Activation' }
	    ]
	};

	if (_scr === 'firstrun_activation') {
	    _scr_def.elements.push({
		entity_template: 'page_buttons',
		buttons: [{ type: 'continue', text: 'Continue', href: '#!/bps/firstrun_update' }]
	    })
	};

	_scr_def.elements = _scr_def.elements.concat([
	    {
		entity_template: 'page_content', elements: [
		    {
			entity_template: 'page_module',
			legend: 'Activate Now',
			buttons: ['help'],
			help_id: 'firstrun_activation',
			form_action: '/gui/sysinfo/activation',
			form_data_js: {
			    method: 'post'
			},
			elements: [
			    {
				entity_template: 'aopb_container',
				states: [{
				    conditions: { a: '@activated', op: '==', b: '_TRUEISH_' },
				    elements: [
					{
					    entity: 'div',

					    text: 'This Barracuda Phone System has been properly activated. You do not need to take any further action at this time.',
					    attributes: { style: 'text-align: center' }
					}
				    ],
				    else_elements: [
					{
					    entity_template: 'data_item',
					    title: 'Activation State:',
					    elements: [{
						entity_template: 'ping_aopb_state',
						rest: '/gui/sysinfo/activation',
						ping_time: 5000,
						states: [{
						    conditions: { a: '@activated', op: '==', b: '_TRUEISH_' },
						    actions: { html: 'Activated. Ready to Continue.<div class="info">Click '+entity.ldquo+'Continue'+entity.rdquo+' above to continue setup. No further action is required on this page.</div>' },
						    else_actions: { html: 'Not Activated.<div class="info">If you have just Activated your Barracuda Phone System, it may be a moment before the status changes.' + (_scr === 'firstrun_activation' ? ' You may wait, or proceed by pressing '+entity.ldquo+'Continue'+entity.rdquo+' above.' : '') }
						}]
					    }]
					},
					{
					    entity_template: 'data_item',
					    title: 'Activation Form:',
					    elements: [
						{
						    entity_template: 'container',
						    elements: [
							{
							    entity: 'a',
							    widgets: ['activationLinkWidget'],
							    text: 'Go to the Barracuda Networks Online Activation Form'
							},
							{
							    entity_template: 'aopb_state',
							    'class': 'info',
							    accept_data_from_parent: true,
							    states: [
								{
								    conditions: { a: '@activation_online', op: '==', b: '_TRUEISH_' },
								    actions: {
									text: 'To complete activation, complete the linked form (opens in a new window). Activation will complete automatically after the form is submitted.'
								    },
								    else_actions: {
									text: 'To complete offline activation, complete the linked form (opens in a new window), and enter the Offline Activation Code you are given after the form is submitted.'
								    }
								}
							    ]
							}
						    ]
						}
					    ]
					},
					{
					    entity_template: 'data_item',
					    title: 'Offline Activation Code:',
					    elements: [
						{
						    entity: 'input',
						    attributes: {
							type: 'text',
							name: 'activation_code',
							size: '6'
						    }
						},
						{
						    entity: 'button',
						    text: 'Apply Activation Code',
						    attributes: { type: 'button', disabled: 'disabled', 'class': 'state-disabled' },
						    widgets: ['aopbStateWidget','formSubmitButtonWidget'],
						    widget_options: {
							aopbStateWidget: {
							    states: {
								closest: 'form',
								conditions: { a: '$activation_code', op: '!=', b: '_EMPTY_' },
								actions: { enabled: true },
								else_actions: { enabled: false }
							    }
							}
						    }
						},
						{
						    entity_template: 'aopb_state',
						    'class': 'info',
						    accept_data_from_parent: true,
						    states: [
							{
							    conditions: { a: '@activation_online', op: '==', b: '_TRUEISH_' },
							    actions: {
								html: '<b>You do not need to enter this code if you are using the Online Activation Form. Activation will proceed automatically after the Online Activation form is completed.</b> If you have an offline activation code, enter it here and click '+entity.ldquo+'Apply Activation Code'+entity.rdquo+'.'
							    },
							    else_actions: {
								html: 'To complete offline activation, enter the code from the activation form here, and click '+entity.ldquo+'Apply Activation Code'+entity.rdquo+'.'
							    }
							}
						    ]
						}
					    ]
					}
				    ] // END else_elements
				}]
			    }
			]
		    }
		]
	    }
	]);

	register_screen(_scr, _scr_def);
    }
})();
