/* jshint jquery: true, browser: true, unused: vars */
/* global classCUI */
/*
 * keyCapture Class
 * This calls allows for easy key filtering, key triggers, and forced formats.
 * addSpecialKeyBlock(obj, keyCodes, callback)
 * removeSpecialKeyBlock(obj, keyCodes, callback)
 * addFilter(obj, filter[, callback])
 * removeFilter(obj, filter[, callback])
 * addTrigger(obj, keys, callback)
 * removeTrigger(obj, keys, callback)
 */

classCUI.prototype.keyCapture = new function () {

	this.initialize = function () {
		var self = this;

		self.arrKeysDown = [];
	};

	self._compareNumbers = function(a, b) {
		return a - b;
	};

	this._parseEventKeys = function (keys) {
		var arrKeys = [];
		var strEvent = "keydown";

		if (typeof keys == "string") {
			strEvent = "keypress";
			arrKeys.push(keys.charCodeAt(0));
		} else if (typeof keys == "number") {
			arrKeys.push(keys);
		} else if (keys instanceof Array) {
			for (var i=0; i<keys.length; i++) {
				if (typeof keys[i] == "string") {
					strEvent = "keypress";
					arrKeys.push(keys[i].charCodeAt(0));
				} else if (typeof keys[i] == "number") {
					arrKeys.push(keys[i]);
				}
			}

			arrKeys.sort(self._compareNumbers);
		}

		return {"event" : strEvent, "key" : arrKeys.toString(), "arrKeys" : arrKeys};
	};

	this.addSpecialKeyBlock = function (obj, keys, callback) {
	};

	this.removeSpecialKeyBlock = function (obj, keys, callback) {
	};


	this.addTrigger = function (obj, keys, callback) {
		var self = this;
		var $element = $(obj);
		var eventKey;

		// Set bildings to an object.
		self._bindToObj($element);

		// Check to see if triggers object exists.
		if (!$element.data("keyCaptureTriggers")) {
			$element.data("keyCaptureTriggers", {});
		}

		eventKey = self._parseEventKeys(keys);

		// Check to see if events object is found.
		if (!$element.data("keyCaptureTriggers")[eventKey.event]) {
			$element.data("keyCaptureTriggers")[eventKey.event] = {};
		}

		// Check to see if we find strKeys key in event object.
		if (!$element.data("keyCaptureTriggers")[eventKey.event][eventKey.key]) {
			$element.data("keyCaptureTriggers")[eventKey.event][eventKey.key] = [];
		}

		if ($element.data("keyCaptureTriggers")[eventKey.event][eventKey.key].indexOf() < 0) {
			$element.data("keyCaptureTriggers")[eventKey.event][eventKey.key].push(callback);
		}
	};

	this.removeTrigger = function (obj, keys, callback) {
		var self = this;
		var $element = $(obj);
		var eventKey;
		var callbackIndex;

		if (keys) {
			eventKey = self._parseEventKeys(keys);
		}

		if (obj && keys && callback) {
			if ($element.data("keyCaptureTriggers") && $element.data("keyCaptureTriggers")[eventKey.event] &&
				$element.data("keyCaptureTriggers")[eventKey.event][eventKey.key] &&
				(callbackIndex = $element.data("keyCaptureTriggers")[eventKey.event][eventKey.key].indexOf(callback)) >= 0) {
				$element.data("keyCaptureTriggers")[eventKey.event][eventKey.key].splice(callbackIndex, 1, 0);
			}
		} else if (obj && keys) {
			if ($element.data("keyCaptureTriggers") && $element.data("keyCaptureTriggers")[eventKey.event] &&
				$element.data("keyCaptureTriggers")[eventKey.event][eventKey.key]) {
				delete $element.data("keyCaptureTriggers")[eventKey.event][eventKey.key];
			}
		} else if (obj) {
			if ($element.data("keyCaptureTriggers")) {
				$element.removeData("keyCaptureTriggers");
			}
		}

		return;
	};

	this.addFilter = function (obj, filter, callback) {
		var self = this;
		var $element = $(obj);

		// Set bildings to an object.
		self._bindToObj($element);

		// Check to see if filter array exists.
		if (!$element.data("keyCaptureFilters")) {
			$element.data("keyCaptureFilters", []);
		}

		// Find index of filter string in filter array. If we do not find the filter then add the filter to the filter array.
		if ($element.data("keyCaptureFilters").indexOf(filter) < 0) {
			$element.data("keyCaptureFilters").push(filter);
		}

		// If we received a callback function, add function to filter callback queue.
		if (callback && typeof callback == "function") {
			if (!$element.data("keyCaptureFilterCallbacks")) {
				$element.data("keyCaptureFilterCallbacks", {});
			}

			if (!$element.data("keyCaptureFilterCallbacks")[filter]) {
				$element.data("keyCaptureFilterCallbacks")[filter] = [];
			}

			if ($element.data("keyCaptureFilterCallbacks")[filter].indexOf(callback) < 0) {
				$element.data("keyCaptureFilterCallbacks")[filter].push(callback);
			}
		}
	};

	this.removeFilter = function (obj, filter, callback) {
		var $element = $(obj);
		var callbackIndex, filterIndex;

		if (obj && filter && callback) {
			if ($element.data("keyCaptureFilters") && $element.data("keyCaptureFilters").indexOf(filter) >= 0 &&
				$element.data("keyCaptureFilterCallbacks") && $element.data("keyCaptureFilterCallbacks")[filter] &&
				(callbackIndex = $element.data("keyCaptureFilterCallbacks")[filter].indexOf(callback)) >= 0) {
				$element.data("keyCaptureFilterCallbacks")[filter].splice(callbackIndex, 1, 0);
			}
		} else if (obj && filter) {
			if ($element.data("keyCaptureFilters") && (filterIndex = $element.data("keyCaptureFilters").indexOf(filter)) >= 0) {
				$element.data("keyCaptureFilters").splice(filterIndex, 1, 0);
				if ($element.data("keyCaptureFilterCallbacks") && $element.data("keyCaptureFilterCallbacks")[filter]) {
					delete $element.data("keyCaptureFilterCallbacks")[filter];
				}
			}
		} else if (obj) {
			if ($element.data("keyCaptureFilters")) {
				$element.removeData("keyCaptureFilters");
			}

			if ($element.data("keyCaptureFilterCallbacks")) {
				$element.removeData("keyCaptureFilterCallbacks");
			}
		}

		return;
	};

	this._processEventFilters = function (e) {
		var self = this;
		var $currentTarget = $(e.target);

		if (!e.altKey && !e.ctrlKey && !e.metaKey) {
			var character = String.fromCharCode(e.charCode);

			if ($currentTarget.data("keyCaptureFilters") && $currentTarget.data("keyCaptureFilters").length > 0) {
				var filters = $currentTarget.data("keyCaptureFilters");
				for(var i=0; i<filters.length; i++) {
					if (character.match(filters[i])) {
						if ($currentTarget.data("keyCaptureFilterCallbacks") && $currentTarget.data("keyCaptureFilterCallbacks")[filters[i]] &&
							$currentTarget.data("keyCaptureFilterCallbacks")[filters[i]].length > 0) {
							var callbacks = $currentTarget.data("keyCaptureFilterCallbacks")[filters[i]];
							for (var x=0; x<callbacks.length; x++) {
								callbacks[x](e,filters[i], character);
							}
						} else {
							return true;
						}
					}
				}

				e.preventDefault();
				return false;
			}

			// debugLog("Got keypress: ", e);

		}

		return true;

	};

	this._processEventTriggers = function ($currentTarget, eventType, keys) {
		var self = this;
		var eventKey = self._parseEventKeys(keys);

		if ($currentTarget.data("keyCaptureTriggers") && $currentTarget.data("keyCaptureTriggers")[eventType] &&
			$currentTarget.data("keyCaptureTriggers")[eventType][eventKey.key] &&
			$currentTarget.data("keyCaptureTriggers")[eventType][eventKey.key].length > 0) {
			var callbacks = $currentTarget.data("keyCaptureTriggers")[eventType][eventKey.key];

			for (var i=0; i<callbacks.length; i++) {
				callbacks[i]($currentTarget, eventType, keys);
			}

			return true;
		}

		return false;
	};

	this._bindToObj = function ($element) {
		var self = this;

		if (!$element.data("keyCaptureOnKeyDown")) {
			$element.data("keyCaptureOnKeyDown", true);
			$element.on("keydown", function (e) {
				return self._onKeyDown(e);
			});
		}

		if (!$element.data("keyCaptureOnKeyPress")) {
			$element.data("keyCaptureOnKeyPress", true);
			$element.on("keypress", function (e) {
				return self._onKeyPress(e);
			});
		}

		if (!$element.data("keyCaptureOnKeyUp")) {
			$element.data("keyCaptureOnKeyUp", true);
			$element.on("keyup", function (e) {
				return self._onKeyUp(e);
			});
		}
	};

	this._onKeyDown = function (e) {
		var self = this;
		var $currentTarget = $(e.target);

		// Always stop propagation
		e.stopPropagation();

		// This is a variable holder to keep track of keys been pressed for keycode combinations.
		if (!self.arrKeysDown) {
			self.arrKeysDown = [];
		}

		if (self.arrKeysDown.indexOf(e.keyCode) < 0) {
			self.arrKeysDown.push(e.keyCode);
		}

		// debugLog(self.arrKeysDown);
		// debugLog("Keydown:", e);

		if (self._processEventTriggers($currentTarget, "keydown", self.arrKeysDown)) {
			e.preventDefault();
			return false;
		}

		return true;
	};

	this._onKeyPress = function (e) {
		var self = this;
		var $currentTarget = $(e.target);
		var keys = self.arrKeysDown.slice(0,-1);

		// Always stop propagation
		e.stopPropagation();

		if (e.charCode) {
			keys.push(e.charCode);

			// debugLog("Keypress:", e);

			if (self._processEventTriggers($currentTarget, "keypress", keys)) {
				e.preventDefault();
				return false;
			}

			return self._processEventFilters(e);
		}

		return true;
	};

	this._onKeyUp = function (e) {
		var self = this;
		var keyIndex;

		// Always stop propagation
		e.stopPropagation();

		// debugLog("Keyup:", e);

		if (!e.altKey && !e.ctrlKey && !e.metaKey) {
			self.arrKeysDown = [];
		} else {
			if ((keyIndex = self.arrKeysDown.indexOf(e.keyCode)) >= 0) {
				self.arrKeysDown.splice(keyIndex,1,0);
			}
		}

		return true;
	};

	this.initialize();
};
