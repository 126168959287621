/*
  dynamicLinkWidget: A widget that needs documentation. Apply to a DIV or other block element.
*/

(function( $ ){
    var dynamicLinkWidget = $.extend(true, {}, $.ui.widget.prototype, {
		set_value_widget: true,

		options: {
			template: false,
			template_selector: false,
			link_text: false,  
			base: false,
			target: false
		},
		
		setValue: function(v){
			var self = this, $self = this.element, $a;

			if (!v) { return; }

			// 1. Clear $self
			// 2. Append a link ("a") element, with the href attribute being s.o.base + v, the target being s.o.target (if one exists), and the text being s.o.link_text or v.
			
			$a = $('<a />');
			$a.attr('href', (self.options.base || '') + v);

			if (self.options.target) {
				$a.attr('target', self.options.target);
			}

			$a.text(self.options.link_text || v);

			$self.empty();
			$self.append($a);
			
		}

    });
    
    add_widget('dynamicLinkWidget', dynamicLinkWidget);
})(jQuery);
