/* jshint jquery: true, unused: vars */
/* global CUI, ApeConnection, add_widget, entity, getSessionID, trueish */
/* global loginData: true, validUsername: true, validUsernamePrintable: true, validUserID: true */
var Ape, CurrentUser = false, UserStatus = false;
var ccc_win; // TODO: Remove this when CCC is remade.

(function( $ ){
	var DEMO_USER_PASSWORD = { guestuser: 'guest', guestadmin: 'guest' };

	var loginWidget = $.extend({}, $.ui.loginWidget.prototype, {

		_defineActions: function() {
			var self = this, $self = this.element;

			self.options.status_interval = 3600;

			self._setupCudatelLoginClosures();

			self.options.show_loading_on_login = true;
			self.options.inhibit_logged_in_display = true;

			self._one($(window), 'logout', self.options.logout_callback);

			$.extend(self, {
				_beforeLoginSuccess: self.options.manage_login_callback_closure
			});

			self._setActionCallback('manage', self.options.manage_click_closure);
			self._setActionCallback('call_control', self.options.call_control_click_closure);
		},

		_setupCudatelLoginClosures: function() {
			var self = this, $self = this.element;

			self.options.logout_callback = function() {
				CurrentUser = false;
				UserStatus = false;
				self._checkDemoMode();
				$(document).attr('title', self.options.title);
			};

			self.options.manage_click_closure = function(e) {
				self._lockSubmit(e);
				self._doManageClick(e);
			};

			self.options.call_control_click_closure = function(e) {
				self._lockSubmit(e);
				self._doCallControlClick(e);
			};

			self.options.manage_login_callback_closure = function(data, status, xhr, callback) {
				self._manageLoginCallback(data, status, xhr, callback);
			};

			self.options.call_control_login_callback_closure = function(data, status, xhr, callback) {
				self._manageLoginCallback(data, status, xhr, callback);
				self._callControlLoginCallback(data, status, xhr, callback);
			};
		},

		_doManageClick: function(e) {
			var self = this, $self = this.element;
			var button = e ? e.target : false;

			if (button) {
				$(button).addClass('button-click');
			}

			$.extend(self, {
				_beforeLoginSuccess: self.options.manage_login_callback_closure
			});

			if (!window.location.search.match(/embedded/)) {
				$('#global_loading_indicator').show();
			}
			self._doLogin();
		},

		_doCallControlClick: function(e) {
			var $self = this.element;
			var self = this;
			var button = e ? e.target : false;

			if (button) {
				$(button).addClass('button-click');
			}

			$.extend(self, {
				_beforeLoginSuccess: self.options.call_control_login_callback_closure
			});

			self._doLogin();
		},

		_manageLoginCallback: function(data, status, xhr, callback) {
			var self = this, $self = this.element;

			$('#cui-login').hide();
			if (!window.location.search.match(/embedded/)) {
				$('#global_loading_indicator').show();
			}

			loginData = data.data;
			CurrentUser = data.data;

			/* Set up global variables with user name and ID */
			validUsernamePrintable = data.data.bbx_user_username_printable;
			validUsername = data.data.bbx_user_username;
			validUserID = data.data.bbx_user_id;

			var copyright_year = data.data.copyright;
			$('#cui-footer-copyright-year').text(copyright_year || '2012');

			var name = data.data.bbx_user_username_printable;
			$('body .signedInUser').text( (name.length > 60) ? (name.slice(0,60) + entity.hellip) : name );
			if (loginData.serial) {
				$('#system-serial').text("System Serial: " + loginData.serial);
			}
			self.options.title = $(document).attr('title');
			$(document).attr('title', self.options.title + ' (' + data.data.bbx_user_full_name + ')');

			CUI.getREST('/gui/user/statuses', {}, function (d) {
				UserStatus = d.data;
				$(window).triggerHandler('statuses');
			});
			CUI.getREST('/gui/permission/user_permissions', {}, function (d) {
				CurrentUser.permissions = d.user_permissions;
				$(window).triggerHandler('permissions');
			});

			//	    if (Ape instanceof ApeConnection) {
			//		self._afterApe();
			//	    } else {
			self._initApe(function() { self._afterApe(); });
			//	    }


		},

		_afterApeOnce: function () {
			var self = this;

			Ape.subscribe('user_' + validUsername);
			$(window).on('meteor_user_' + validUsername, self._userEvent.bind(self));

			var do_sub_admin = function () {
				if (CurrentUser.permissions.ADMIN) {
					Ape.subscribe('admin');
					$(window).on('meteor_admin', self._adminEvent.bind(self));

					// Throw any "syswarn" messages (about discontinuation). FYI: "addSyswarnStripe()" is in js/3/syswarn.js
					CUI.getREST('/gui/syswarn/syswarn', {}).then(function (d) {
						if (d && d.syswarn && 'text' in d.syswarn && 'key' in d.syswarn) {
							addSyswarnStripe(d.syswarn);
						}
					});
				}
			};

			if (!window.CurrentUser || !CurrentUser.permissions) {
				self._one($(window), 'permissions', do_sub_admin);
			} else {
				do_sub_admin();
			}
		},

		_afterApe: function() {
			var self = this, $self = this.element;

			if (!UserStatus) {
				self._one($(window), 'statuses', function() { self._afterStatusesLoaded(); });
			} else {
				self._afterStatusesLoaded();
			}

			if (!self.options._after_ape_once) {
				self.options._after_ape_once = true;
				self._afterApeOnce();
			}
		},

		_afterStatusesLoaded: function() {
			var self = this, $self = this.element;

			if (!CurrentUser.permissions) {
				self._one($(window), 'permissions', function() { self._afterPermissionsLoaded(); });
			} else {
				self._afterPermissionsLoaded();
			}
		},

		_afterPermissionsLoaded: function() {
			var $self = this.element;
			var self = this;

			self.options.$pageWidget = $(document.getElementById('cui-content-inner'));

			$('body').addClass('no-animations logged_in').removeClass('no-header cui-signin-screen-styles').removeClass('no-animations');

			if (self.options.$pageWidget.is('.widgetized')) {
				// debugLog('afterPermissionsLoaded pagewidget widgetized already.');
				self._one(self.options.$pageWidget, 'screenReady', function() { self._afterPageWidgetReady(); });
				self.options.pageWidget.reinitialize();
			} else {
				// debugLog('Initializing page widget for the first time.');
				self._one(self.options.$pageWidget, 'ready', function() { self._afterPageWidgetReady(); });
				self.options.$pageWidget.pageWidget({ "rest" : "/gui/nav/nav3", "rest_container" : "nav" });
				self.options.pageWidget = self.options.$pageWidget.getCUIWidget();
			}

		},

		_userEvent: function (e,d) {
			var self = this, data;
			data = d ? d.json || {} : {};
			if (data.logout && data.session_id === getSessionID()) {
				self.triggeredLogoutHandler();
			}

			if (data.reconfig_refresh) {
				new CUI.Dialog({
					title: 'Configuration Changes',
					text:  'Some of your personal settings or permissions have changed. This may be the result of your own changes, or an Administrator on the system. The display must be refreshed to reflect the new settings before you can continue.',
					blanker: true,
					prevent_interaction: true,
					prevent_navigation: true,
					buttons: ['Refresh Page'],
					callbacks: [function () {
						location.reload();
						$('#global_loading_indicator').show();
						$(this).trigger('close_dialog');
					}]
				});
			}
		},

		_adminEvent: function (e, d) {
			var data = d ? d.json || {} : {};

			if (data.notify && data.notify === 'phone_added') {
				new CUI.PopDialog({ text: 'A new phone has been connected, and has been assigned to extension ' + data.bbx_extension_value + '.' });
			}
		},

		_afterPageWidgetReady: function() {
			var $self = this.element;
			var self = this;

			self._displayInterface();
		},

		_displayInterface: function() {
			$('#global_loading_indicator').hide();
		},

		_cccWindowOpen: function(options) {
			var presets = {
				ccc: 'width=350,height=500,location=0,menubar=0,scrollbars=1,status=0,toolbar=0,resizable=1',
				fullscreen: 'width=' + (screen.width - 200) + 'height=' + (screen.height - 200) + 'location=0,menubar=0,scrollbars=auto,status=0,toolbar=0,resizable=1',
				fullscroll: 'width=' + (screen.width - 200) + 'height=' + (screen.height - 200) + 'location=0,menubar=0,scrollbars=1,status=0,toolbar=0,resizable=1'
			};

			var url = options.url;
			var name = options.name || ('win'+Math.floor(Math.random()));
			var opts = options.options || presets[options.preset];

			return window.open(url,name,opts);
		},

		_callControlLoginCallback: function(data, status, xhr, callback) {
			var self = this, $self = this.element;

			if (!ccc_win || ccc_win.closed) {
				ccc_win = self._cccWindowOpen({ url:'/ajax-html/callcontrol.html', name:'client', preset: 'ccc' });
			}
			ccc_win.focus();
		},

		_initApe: function(handler) {
			Ape = new ApeConnection(handler, { channel: ApeConnection.jqChannelEventBridge });
		},

		_doLoginError: function (xhr, status, error) {
			// Shim in to grab the demo status
			var self = this, metadata;

			$('#global_loading_indicator').hide();

			try {
				metadata = JSON.parse(xhr.responseText);
			} catch (e) {}

			if (metadata && metadata.data) {
				/* global: */ loginData = metadata.data;
			}

			self._checkDemoMode();
			$.ui.loginWidget.prototype._doLoginError.apply(self, arguments);
		},

		_checkDemoMode: function () {
			var self = this;
			if (trueish(loginData.demo)) {
				self._initDemoMode();
			} else {
				self._exitDemoMode();
			}
		},

		_initDemoMode: function () {
			var self = this, $self = this.element;
			$self.find('.login-field').hide();
			$self.find('.login-demo, .login-demo-normal-mode').show();

			if (!self.options._demo_bound) {
				self.options._demo_bound = true;
				$self.find('.login-demo-normal-mode').on('click', function (e) {
					e.preventDefault();
					self._exitDemoMode();
				});

				$self.find('.login-demo-user-select').on('click change', function () {
					var username = $(this).val();
					$self.find('[name=__auth_user]').val(username);
					$self.find('[name=__auth_pass]').val(DEMO_USER_PASSWORD[username] || '');
				});
			}

			$self.find('.login-demo-user-select').trigger('change');
		},

		_exitDemoMode: function () {
			var self = this, $self = this.element;
			$self.find('.login-field').show();
			$self.find('.login-demo, .login-demo-normal-mode').hide();
		},


		/* This is called when...
	   a.) A logout message is recieved over Ape/livedata (usu. when someone has logged out from another browser window), or . . .
	   b.) A NOTAUTHORIZED header is given to an AJAX response, indicating that the user's session is invalid (usu. when a session has expired)

	   The wait is there so the action doesn't happen multiple times during a legitimate logout-- the explicit "logout" function should handle explicit logouts, not this.
	*/
		triggeredLogoutHandler: function () {
			var self = this;
			setTimeout(function () {
				if (self.options.logged_in) {
					location.reload();
				}
			}, 1000);
		},

		// Shim to apply the demo username if demo mode is on -- showLogin in loginWidget clears the values after logout, so they must be reapplied
		showLogin: function () {
			var self = this, $self = this.element, ret;
			ret = $.ui.loginWidget.prototype.showLogin.apply(self, arguments);
			if (trueish(loginData.demo)) {
				$self.find('.login-demo-user-select').trigger('change');
			}
			return ret;
		}

	});

	add_widget('cudatelLoginWidget', loginWidget);
})(jQuery);
