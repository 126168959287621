register_screen('user_phone_detail', {
    rest: '/gui/phone/phone',
    dataEvents: {
	bind: {
	    'phone_properties': ''
	}
    },
    elements: [
	{ entity_template: 'page_title', title: 'Phone Details' },
	{
	    entity_template: 'page_buttons',
	    buttons: [{ type: 'cancel' }, { type: 'save' }]
	},
	{ entity_template: 'page_content', elements: [
	    {
		entity_template: 'form',
		rest: '/gui/phone/phone',
		dataEvents: { trigger: 'phone_properties' },
		elements: [
		    {
			entity_template: 'page_module',
			legend: 'Phone Options',
			buttons: ['help'],
			help_id: 'user_phone_basic_options',
			elements: [
			    {
				entity_template: 'input_item',
				title: 'Phone Name:',
				type: 'text',
				input_name: 'bbx_phone_desc',
				input_options: { attributes: { maxlength: '255' }}
			    },
			    {
				entity_template: 'aopb_container',
				states: [{
				    conditions: { a: '@bbx_phone_softphone', op: '==', b: '_TRUEISH_' },
				    elements: [{
					entity_template: 'input_item',
					title: 'Lines:',
					type: 'text',
					size: 3,
					input_name: 'generic_lines'
				    }],
				    else_elements: [{ entity: 'div', attributes: { style: 'display: none' } }]
				}]
			    },
			    {
				entity_template: 'aopb_container',
				states: [{
				    conditions: { a: '@sidecar_type_list', op: '==', b: '_ARRAY_HAS_ELEMENTS_' },
				    elements: [
					{
					    entity_template: 'data_item',
					    title: 'Sidecar Type:',
					    elements: [{
						entity_template: 'select_self_populate',
						name: 'sidecar_type',
						options_container: 'sidecar_type_list',
						option_text_from: 'value',
						option_value_from: 'index',
						option_index_offset: 1
					    }]
					}
				    ]
				}]
			    },
			    {
				entity_template: 'aopb_container',
				states: [{
				    conditions: { a: '@max_sidecars', op: '>', b: '0' },
				    elements: [
					{
					    entity_template: 'data_item',
					    title: 'Installed Sidecars:',
					    elements: [{
						entity_template: 'select_range',
						start: 0,
						text_for_number: { 0: 'None' },
						end: 'max_sidecars',
						name: 'installed_sidecars'
					    }]
					}
				    ],
				    else_elements: [{ entity: 'div', attributes: { style: 'display: none' } }]
				}]
			    },
			    {
				entity_template: 'aopb_container',
				states: [{
				    conditions: { a: '@allow_fax', op: '==', b: '_TRUEISH_' },
				    elements: [{
					entity_template: 'input_item',
					title: 'T.38 Fax Mode:',
					type: 'select',
					select_options: [
					    { text: 'ReINVITE (Default)', value: '1' },
					    { text: 'None', value: 'none' }
					],
					input_name: 'fax_method'
				    }],
				    else_elements: [{ entity: 'div', attributes: { style: 'display: none' } }]
				}]
			    },
			    {
				entity_template: 'aopb_container',
				states: [{
				    conditions: { a: '@allow_radio_id', op: '==', b: '_TRUEISH_' },
				    elements: [{
					entity_template: 'input_item',
					title: 'Handset ID (IPEI):',
					type: 'text',
					input_name: 'dect_handset'
				    }],
				    else_elements: [{ entity: 'div', attributes: { style: 'display: none' } }]
				}]
			    },
			    {
				entity_template: 'data_item',
				elements: [
				    {
					entity: 'button',
					text: 'Remove Phone from User',
					widgets: ['removePhoneActionButtonWidget'],
					widget_options: {
					    removePhoneActionButtonWidget: {
						rest: '/gui/phone/phone',
						method: 'DELETE',
						include_data: ['bbx_phone_id'],
						confirm: {
						    text: 'The phone will no longer be associated with this user. All line and button settings for this phone will be deleted. If the phone is currently connected, it will reboot be assigned a new extension as an Unassigned Phone.',
						    title: 'Remove Phone',
						    buttons: ['Remove', 'Cancel']
						},
						up_on_success: true
					    }
					}
				    },
				    {
					entity_template: 'action_button',
					text: 'Reboot Phone',
					rest: '/gui/phone/reboot',
					method: 'POST',
					confirm: {
					    title: 'Reboot Phone?',
					    text: 'The phone will not be usable during the reboot process, which usually lasts about 1' + entity.ndash + '3 minutes. If a call is currently active, the phone may reboot after the active call is complete.',
					    buttons: ['Reboot', 'Cancel']
					}
				    },
				    {
					entity: 'div',
					attributes: { 'class':'info' },
					text: 'Changes on this screen will initiate a phone reboot upon leaving the page. If this phone is currently on a call, it will reboot after the call has completed.'
				    }
				]
			    }
			]
		    }
		] // END form elements
	    },
	    {
		entity_template: 'page_module',
		legend: 'Phone Details',
		buttons: ['help'],
		help_id: 'user_phone_details',
		elements: [
		    {
			entity_template: 'input_item',
			title: 'Model:',
			type: 'div',
			input_name: 'bbx_phone_model'
		    },
		    {
			entity_template: 'data_item',
			title: 'MAC Address:',
			elements: [{
			    entity_template: 'format',
			    format: {
				field: 'bbx_phone_mac',
				formatter: 'mac'
			    }
			}]
		    },
		    {
			entity_template: 'data_item',
			title: 'Last Registration Time:',
			elements: [{
			    entity_template: 'format',
			    format: {
				field: 'bbx_phone_last_registered',
				formatter: 'match',
				options: { regexp: '^[^\.]+' }
			    }
			}]
		    },
		    {
			entity_template: 'input_item',
			title: 'Last Registration IP Address:',
			type: 'div',
			input_name: 'bbx_phone_last_ip'
		    },
		    {
			entity_template: 'data_item',
			elements: [{
			    entity_template: 'overlay_button',
			    text: 'Advanced Phone Details',
			    overlay: 'phone_model_details',
			    data_to_params: ['bbx_phone_id']
			}]
		    }
		]
	    },
	    {
		entity_template: 'page_module',
		legend: 'Lines and Utility Buttons',
		buttons: ['help'],
		help_id: 'user_phone_lines',
		elements: [
		    { entity_template: 'data_holder_container', elements: [
			{
			    entity: 'table',
			    widgets: ['phoneButtonsDataTableWidget'],
			    widget_options: {
				dataEvents: {
				    trigger: 'phone_assign'
				},
				bind_clickRow_to_rowReady: true, // optional fix for tables that refresh after saving a row
				accept_data_from_parent: false,
				allow_scroll: false,
				primary_keys: ['bbx_phone_button_id'],
				extra_row_data: [
				    'bbx_phone_button_id',
				    'bbx_phone_button_value',
				    'bbx_user_id',
				    'line_state',
				    'bbx_extension_id',
				    'bbx_extension_value',
				    'extension_next_free',
				    'ringtone_type_list',
				    'bbx_phone_id'
				],
				live_table: 'bbx_phone_button',
				live_table_action_data_source: 'button',
				allow_parent_extra_row_data: true,
				live_unique_ident: true,
				submit_only_dirty: true,
				search_parent_field: 'bbx_phone_id',

				// Added this to prevent this dtw from only loading 25 results, which was an issue when there were 2 sidecars
				page_size: 10000,

				dirty_exclusion_lists: [
				    [ 'bbx_extension_value', 'bbx_extension_id' ],
				    [ 'bbx_extension_id', 'extension_next_free']
				],
				click_action: 'edit',
				row_actions: {
				    edit_save: { text: 'Save' },
				    edit_cancel: { text: 'Cancel' },
				    add_save: { text: 'Add' },
				    add_cancel: { text: 'Cancel' }
				},

				add_edit_action: {
				    rest: '/gui/phone/button'
				},
				sortable: {
				    field: 'bbx_phone_button_position',
				    also_submit: ['bbx_phone_button_id']
				},
				columns: [
				    {
					header: 'Button',
					column: 'bbx_phone_button_position',
					'class': 'phone-button-position center nowrap phone_button',
					width: '2em',
					view_element: {
					    entity_template: 'phone_button_number_status'
					},
					edit_element: {
					    entity_template: 'phone_button_number_status'
					}
				    },
				    {
					header: 'Label',
					column: 'bbx_phone_button_label',
					'class': 'phone_button_label',
					edit_element: {
					    entity_template: 'aopb_container',
					    closest: 'tr',
					    states: [{
						conditions: { a: '$bbx_phone_button_type', op: '==', b: 'repeat' },
						elements: [{ entity: 'div' }],
						else_elements: [{
						    entity: 'input',
						    attributes: {
							type: 'text',
							name: 'bbx_phone_button_label',
							//'class': 'no-auto-fill',
							size: '12'
						    },
						    widgets: ['widget']
						}]
					    }]
					}
				    },
				    {
					header: 'Function',
					column: 'bbx_phone_button_type',
					'class': 'phone_button_function',
					translate: {
					    blank:     'No Function',
					    line:      'Line',
					    repeat:    'Repeat Line',
					    blf:       'BLF/Line Monitor',
					    transfer:  'Transfer',
					    speed_dial:'Speed Dial',
					    park:      'Call Park',
					    dnd:       'Do Not Disturb'
					},
					// translate_default: 'Unknown Type',

					edit_element: {
					    entity_template: 'aopb_container',
					    closest: 'tr',
					    states: [
						{
						    conditions: { a: '@bbx_phone_button_position', op: '==', b: '1' },
						    elements: [
							{
							    entity: 'select',
							    attributes: { name: 'bbx_phone_button_type', disabled: 'disabled' },
							    select_options: [ {text: 'Line', value: 'line'} ]
							}
						    ],
						    else_elements: [
							{
							    entity: 'select',
							    attributes: { name: 'bbx_phone_button_type' },
							    widgets: ['phoneButtonFunctionWidget'],
							    widget_options: {
								phoneButtonFunctionWidget: {
								    closest: '.dataHolderContainerWidget',
								    widget: 'dataHolderContainerWidget',
								    select_options: [
									{ text: 'No Function', value: 'blank' }, //
									{ text: 'Line', value: 'line' },
									{ text: 'Repeat Line', value: 'repeat', flag: 'button_type_repeat' }, //
									{ text: 'BLF/Line Monitor', value: 'blf', flag: 'button_type_blf' },
									{ text: 'Call Park', value: 'park', flag: 'button_type_park' }, //
									{ text: 'Speed Dial', value: 'speed_dial', flag: 'button_type_speeddial' }, //
									{ text: 'Transfer', value: 'transfer', flag: 'button_type_transfer' }, //
									{ text: 'Do Not Disturb', value: 'dnd', flag: 'button_type_dnd' } //
								    ]
								}
							    }
							}
						    ]
						}
					    ]
					}
				    },
				    {
					header: 'Extension/Number',
					'class': 'phone_button_extension break-long',
					view_element: {
					    entity_template: 'phone_clone_lookup'
					},
					edit_element: {
					    entity_template: 'aopb_container',
					    closest: 'tr',
					    data_on_interactive_change: {
						bbx_extension_id: '',
						bbx_extension_value: '',
						bbx_phone_button_value: '',
						extension_next_free: ''
					    },
					    states: [
						{
						    conditions: { a: '@bbx_phone_button_position', op: '==', b: '1' },
						    elements: [{
							entity_template: 'format',
							format: { field: '@bbx_phone_button_value', formatter: 'phone_number' }
						    }],
						    last_if_true: true
						},
						{
						    conditions: { a: '$bbx_phone_button_type', op: '==', b: '^(blank|repeat|dnd)$', b_is: 'regexp' },
						    elements: [{ entity: 'div', text: 'No configuration required.' }],
						    last_if_true: true
						},
						{
						    conditions: { a: '$bbx_phone_button_type', op: '==', b: 'park' },
						    last_if_true: true,
						    elements: [{
							entity_template: 'extension_picker_suggest_validate',
							accept_data_from_parent: true,
							type: ['valet_parking'],
							expand_blocks: true,
							primary: 1,
							search_rest: '/gui/extension/within_block',
							validate_rest: '/gui/extension/valid_parking',
							value_key: 'bbx_extension_value',
							name: 'bbx_phone_button_value'
						    }]
						},
						{
						    conditions: { a: '$bbx_phone_button_type', op: '==', b: '^(speed_dial|transfer)$', b_is: 'regexp' },
						    last_if_true: true,
						    elements: [{
							entity_template: 'extension_picker_suggest',
							type: ['all'],
							primary: 0,
							name: 'bbx_phone_button_value',
							search_key: 'bbx_extension_value',
							value_key: 'bbx_extension_value'
						    }]
						},
						{
						    conditions: { a: '$bbx_phone_button_type', op: '==', b: 'blf', b_is: 'literal' },
						    last_if_true: true,
						    elements: [{
							entity_template: 'extension_picker_suggest_validate',
							accept_data_from_parent: true,
							type: ['user','conference','valet_parking','phone'],
							primary: 0,
							expand_blocks: true,
							search_rest: '/gui/extension/within_block',
							validate_rest: '/gui/extension/valid_blf',
							value_key: 'bbx_extension_value',
							name: 'bbx_phone_button_value'
						    }]
						},
						{
						    conditions: { a: '$bbx_phone_button_type', op: '==', b: 'line' },
						    last_if_true: true,
						    elements: [{
							entity: 'button',
							attributes: { type: 'button', name: 'phone_button_type' },
							text: '',
							widgets: ['phoneLineAssignButtonWidget'],
							widget_options: {
							    phoneLineAssignButtonWidget: {
								accept_data_from_parent: true,
								title: 'Select a new extension',
								submit_button: 'Choose extension',
								include_data_as_rest_params: ['bbx_user_id', 'bbx_phone_button_id'],
								elements: [
								    {
									entity: 'select',
									attributes: { 'class': 'select-line-type' },
									select_options: [
									    { text: 'Select an unused extension or number', value: 'new' },
									    { text: 'Apply a current secondary extension or number', value: 'sec' },
									    { text: 'Share a line', value: 'clone' }
									]
								    },
								    {
									entity_template: 'switch',
									closest: 'form',
									selector: '.select-line-type',
									switch_elements: {
									    'new': [
										{
										    entity: 'div',
										    widgets: [ 'extensionSelectWidget' ],
										    attributes: {
											name: 'bbx_extension_id'
										    },
										    widget_options: {
											extensionSelectWidget: {
											    allow: 'nextfree single external'
											}
										    }
										}
									    ],
									    'sec': [
										{
										    entity_templates: ['select_populate', 'aopb_state'],
										    select_populate: {
											name: 'bbx_extension_id',
											rest: '/gui/phone/button',
											options_container: 'secondaries',
											value_field: 'bbx_extension_id',
											text_format: {
											    format: {
												field: 'bbx_extension_value',
												formatter: 'phone_number'
											    }
											}
										    },
										    aopb_state: {
											states: [{
											    conditions: { a: '$$', op: '!=', b: '_TRUEISH_' },
											    actions: { invalid: 'No secondary extension are available for use.', visible: false },
											    else_actions: { invalid: false, visible: true }
											}]
										    }
										}
									    ],
									    'clone': [
										{
										    entity_template: 'extension_picker_select',
										    allow_nothing: false,
										    primary: 0,
										    type: 'user',
										    name: 'bbx_extension_id',
										    value_key: 'bbx_extension_id'
										}
									    ]
									}
								    }
								]
							    } // END valueFormDialogButtonWidget options
							} // END widget_options
						    }],
						    last_if_true: true
						},
						{
						    conditions: { a: '$bbx_phone_button_type', op: '==', b: 'transfer' },
						    elements: [{
							entity_template: 'extension_picker_suggest',
							name: 'bbx_phone_button_value',
							search_key: 'bbx_extension_value'
						    }],
						    last_if_true: true
						}
					    ]
					}
				    },
				    {
					header: 'Aliases',
					'class': 'small-button-margins nowrap phone_button_aliases',
					width: '3em',
					edit_element: {
					    entity: 'div',
					    text: '(Settings must be applied)'
					},
					view_element: {
					    entity_template: 'aopb_container',
					    states: [{
						conditions: { a: '@bbx_phone_button_type', op: '==', b: 'line' },
						elements: [{
						    entity_template: 'overlay_button',
						    overlay: 'phone_line_aliases',
						    text: 'Manage Aliases',
						    data_to_params: ['bbx_phone_button_id']
						}],
						else_elements: [{
						    entity: 'span',
						    attributes: { style: 'display: none' }
						}]
					    }]
					}
				    },
				    {
					header: 'Shared Line',
					'class': 'nowrap phone_button_shared_line',
					width: '3em',
					view_element: {
					    entity_template: 'aopb_container',
					    closest: 'tr',
					    states: [
						{
						    conditions: { a: '@bbx_phone_button_type', op: '==', b: 'line' },
						    elements: [
							{
							    entity_template: 'button_sla',
							    edit_mode: false
							}
						    ],
						    else_elements: [{
							entity: 'div'
						    }]
						}
					    ]
					},
					edit_element: {
					    entity_template: 'aopb_container',
					    closest: 'tr',
					    states: [
						{
						    conditions: { a: '$bbx_phone_button_type', op: '==', b: 'line' },
						    elements: [
							{
							    entity_template: 'button_sla',
							    name: 'button_sla',
							    edit_mode: true
							}
						    ],
						    else_elements: [{
							entity: 'div'
						    }]
						}
					    ]
					}
					// TODO: Need a combined column for this
				    },
				    {
					header: 'Ringtone',
					column: 'bbx_phone_button_ring_tone',
					'class': 'small-button-margins nowrap',
					width: '3em',
					view_element: {
					    entity_template: 'aopb_container',
					    closest: 'tr',
					    states: [
						{
						    conditions: { a: '@bbx_phone_button_type', op: '==', b: 'line' },
						    elements: [{
							entity_template: 'button_ringtone',
							name: 'bbx_phone_button_ring_tone',
							edit_mode: false,
							before: [{ text: 'Default', value: '0' }]
						    }],
						    else_elements: [{
							entity: 'div'
						    }]
						}
					    ]
					},
					edit_element: {
					    entity_template: 'aopb_container',
					    closest: 'tr',
					    states: [
						{
						    conditions: { a: '$bbx_phone_button_type', op: '==', b: 'line' },
						    elements: [{
							entity_template: 'button_ringtone',
							name: 'bbx_phone_button_ring_tone',
							edit_mode: true,
							before: [{ text: '(Default)', value: '0' }]
						    }],
						    else_elements: [{
							entity: 'div'
						    }]
						}
					    ]
					}
				    },
				    {
					header: 'Utilities',
					'class': 'small-button-margins nowrap phone_button_utilities',
					width: '3em',
					view_elements: [
					    {
						entity_templates: ['action_button','aopb_state'],
						aopb_state: {
						    states: [
							{
							    conditions: { a: '@bbx_phone_button_type', op: '==', b: '^(repeat|line)$', b_is: 'regexp' },
							    actions: { visible: true },
							    else_actions: { visible: false }
							}
						    ]
						},
						// TODO: data to rest params
						action_button: {
						    text: 'Echo Test',
						    rest: '/gui/phone/line/echo_test',
						    include_data: ['bbx_phone_id'],
						    include_data_as: { 'bbx_phone_button_position': 'line'},
						    method: 'POST',
						    dialog_after_success: {
							title: 'Call Initiated',
							text: 'The Echo Test call has been made.'
						    }
						}
					    },
					    {
						entity_templates: ['container_span','ancestor_data_mixer'],
						ancestor_data_mixer: {
						    closest: '.dataHolderContainerWidget',
						    widget: 'dataHolderContainerWidget'
						},
						container_span: {
						    elements: [
							{
							    entity_templates: ['value_form_dialog_button','aopb_state'],
							    aopb_state: {
								_aopb_binds: 'click change keyup',
								states: [{
								    conditions: [
									{              a: '@bbx_phone_softphone', op: '==', b: '_TRUEISH_' },
									{ join: 'and', a: '@bbx_phone_button_type', op: '==', b: 'line' }
								    ],
								    actions: { visible: true },
								    else_actions: { visible: false }
								}]
							    },
							    value_form_dialog_button: {
								text: 'Registration Information',
								title: 'SIP Registration Information',
								rest: '/gui/phone/button/',
								rest_container: 'button',
								method: 'GET', // Dummy form
								submit_button: false,
								cancel_button: 'Done',
								no_initial_get: false,
								include_data: ['bbx_phone_button_id'],
								elements: [
								    {
									entity_template: 'input_item',
									type: 'text',
									title: 'Domain (Internal):',
									input_name: 'internal_ip',
									input_attributes: { readonly: 'readonly' }
								    },
								    {
									entity_template: 'input_item',
									type: 'text',
									title: 'Domain (External):',
									input_name: 'external_ip',
									input_attributes: { readonly: 'readonly' }
								    },
								    {
									entity_template: 'data_item',
									title: 'Port:',
									elements: [{ entity: 'input', attributes: { value: '5065', size: 5, readonly: 'readonly', type: 'text' } }]
								    },
								    {
									entity_template: 'input_item',
									type: 'text',
									title: 'Username:',
									input_name: 'bbx_extension_value',
									input_attributes: { readonly: 'readonly' }
								    },
								    {
									entity_template: 'input_item',
									type: 'text',
									title: 'Authorization Username:',
									input_name: 'bbx_registration_username',
									input_attributes: { readonly: 'readonly' }
								    },
								    {
									entity_template: 'input_item',
									type: 'text',
									title: 'Password',
									input_name: 'bbx_registration_password',
									input_attributes: { readonly: 'readonly' }
								    }
								]
							    }
							}
						    ]
						}
					    }
					],
					edit_elements: [{
					    entity: 'div',
					    text: ''
					}]
					// TODO: Need to aopb this
				    }
				]
			    }
			}
		    ] } // container
		]
	    },
	    {
		entity: 'div',
		attributes: { style: 'display: none' },
		widgets: ['destroyActionWidget'],
		widget_options: { destroyActionWidget: {
		    rest: '/gui/phone/reboot',
		    fields: ['bbx_phone_id'],
		    rest_params: { if_scheduled: 1 }
		}}
	    }
	]} // end page content template
    ]
});
