/* global classCUI */
(function () {

	var getObjectAtString = function (str, scope) {
		var split, next_part;
		scope = scope || window;
		split = str.split('.');
		next_part = split[0];

		if (typeof scope === 'object' && split[0] in scope) {
			if (split[1]) {
				return getObjectAtString(split.slice(1).join('.'), scope[split.shift()]);
			} else {
				return scope[split[0]];
			}
		} else {
			return undefined;
		}
	};

	/*
      Takes a dotted string ("foo.bar.baz") and returns the object it refers to (foo.bar.baz). Only supports dots, not brackets.
    */
	classCUI.prototype.getObjectAtString = function (/* str, scope */) {
		return getObjectAtString.apply(this, arguments);
	};
})();
