/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/* ****************************************************************************************************************************

dirtyDisplayWidget:  this widget will display an element by tracking whether a dataProducer element has the 'is-dirty' class

options: 
*******************************************************************************************************************************/


(function( $ ){
    var dirtyDisplayWidget = $.extend(true, {}, $.ui.widget.prototype, {
	options: {
	    closest: '.dataProducer',
	    elements: {},
	    dirty_elements: {}
	},

	_beforeDOMReady: function () {
	    var self = this, $self = this.element;
	    self._watchEvents();
	},

	_watchEvents: function () {
	    var self = this, $self = this.element;
	    var ref__handleChange = CUI.FunctionFactory.build(self._handleChange, self, { context: 'argument', first: 'context' });
	    self._delegate($self, '.actionSummary', 'change', ref__handleChange);
	},

	_handleChange: function () {
	    var self = this, $self = this.element, dirty;
	    var $element_to_check = $self.closest(self.options.closest);
	    if ($element_to_check.is-dirty) {
		dirty = true;
	    } else {
		dirty = false;
	    }
	    self._refreshWith(dirty);
	},

	_refreshWith: function (dirty) {
	    var self = this, $self = this.element;

	    if (dirty) {
		$self.empty().append(self.options.dirty_elements);
	    } else {
		$self.empty().append(self.options.elements);
	    }
	}

    });
    
    add_widget('dirtyDisplayWidget', dirtyDisplayWidget);
})(jQuery);
