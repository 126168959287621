register_screen('send_fax', {
    elements: [
	{ entity_template: 'page_title', title: 'Send a Fax' },
	{ entity_template: 'page_buttons', buttons: [ { type: 'cancel', text: 'Clear Form' }, { type: 'fax_save' } ] },
	{
	    entity_template: 'page_content',
	    elements: [
		{
		    entity_template: 'form',
		    method: 'POST',
		    action: '/gui/fax_file/upload',
		    no_initial_get: true,
		    submit_all: true,
		    elements: [
			{
			    entity_template: 'page_module',
			    legend: 'Your Information',
			    buttons: ['help'],
			    help_id: 'user_fax_send_information',

			    elements: [
				{
				    entity_template: 'data_item',
				    title: 'Sender Fax Number:',
				    elements: [
					{
					    entity_templates: ['extension_picker_suggest', 'aopb_state'],
					    extension_picker_suggest: {
						value_key: 'bbx_extension_block_begin',
						name: 'bbx_fax_outbound_fax_from'
					    },
					    aopb_state: {
						states: [
						    {
							conditions: {a: '$$', op: '==', b: '_NUMERIC_' },
							actions: { invalid: false },
							else_actions: { invalid: 'Please enter a valid number, without spaces or separators.' }
						    }
						]
					    }
					}
				    ]
				},
				{
				    entity_template: 'input_item',
				    title: 'Fax Header Text:',
				    type: 'text',
				    input_name: 'bbx_fax_outbound_header'
				}
			    ]
			},
			{
			    entity_template: 'page_module',
			    legend: 'Sending',
			    buttons: ['help'],
			    help_id: 'user_fax_send_sending',

			    elements: [
				{
				    entity_template: 'data_item',
				    title: 'Recipient Fax Number:',
				    elements: [
					{
					    entity: 'input',
					    attributes: { type: 'text', name: 'bbx_fax_outbound_fax_to' },
					    widgets: ['aopbStateWidget'],
					    widget_options: {
						aopbStateWidget: {
						    states: [
							{
							    conditions: [
								{ a: '$$', op: '==', b: '_NUMERIC_' },
								{ join: 'and', a: '$$', op: '!=', b: '', b_is: 'literal' }
							    ],
							    actions: { invalid: false },
							    else_actions: { invalid: 'Please enter a valid number, without spaces or separators.' }
							}
						    ]
						}
					    }
					}
				    ]
				},
				{
				    entity_template: 'input_item',
				    title: 'Upload PDF File:',
				    type: 'file',
				    input_name: 'upload_file',
				    input_options: {
					widgets: ['aopbStateWidget'],
					widget_options: {
					    aopbStateWidget: {
						states: [{
						    conditions: { a: '$$', op: '!=', b: '', b_is: 'literal' },
						    actions: { invalid: false },
						    else_actions: { invalid: 'No file selected.' }
						}]
					    }
					}
				    }
				}
			    ]
			},
			{
			    entity_template: 'page_module',
			    legend: 'Cover Sheet',
			    buttons: ['help'],
			    help_id: 'user_fax_send_cover_sheet',

			    elements: [
				{
				    entity_template: 'input_item',
				    title: 'Include a Cover Sheet:',
				    type: 'checkbox',
				    input_name: 'use_cover'
				},
				{
				    entity_templates: ['aopb_state', 'container' ],
				    aopb_state: {
					states: [
					    {
						conditions: { a: '$use_cover', op: '==', b: '_TRUEISH_' },
						actions: { enabled: true, _cascade: true },
						else_actions: { enabled: false, _cascade: true }
					    }
					]
				    },
				    container: {
					elements: [
					    {
						entity_template: 'input_item',
						title: 'Your Name:',
						type: 'text',
						input_name: 'cs_from_name'
					    },
					    {
						entity_template: 'input_item',
						title: 'Recipient Name:',
						type: 'text',
						input_name: 'cs_to_name'
					    },
					    {
						entity_template: 'input_item',
						title: 'Recipient Company:',
						type: 'text',
						input_name: 'cs_to_company'
					    },
					    {
						entity_template: 'input_item',
						title: 'Re (Regarding):',
						type: 'text',
						input_name: 'cs_re'
					    },
					    {
						entity_template: 'input_item',
						title: 'Comments:',
						type: 'textarea',
						input_name: 'cs_comments'
					    }
					]
				    }
				}
			    ]
			}
		    ]
		}
	    ]
	}
    ] // END template elements
}); // END template
