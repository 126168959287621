/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){
	var voicemailPieChartWidget = $.extend(true, {}, $.ui.piechartWidget.prototype, {
		options: {
		},

		_formatPieTooltip: function (pie) {
			var time = CUI.formatter.doFormat({ sec: pie.y }, {
				format: {
					field: 'sec',
					formatter: 'seconds_duration',
					options: { size: 'short_space' }
				}
			});

			return '<b>' + pie.point.name + '</b><br>' + time + ',<br>'+ pie.point.secondary + ' messages';
		}
	});

	add_widget('voicemailPieChartWidget', voicemailPieChartWidget);
})(jQuery);
