/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
// Adds a click handler to the element (usually a BUTTON) to go to a URL

(function( $ ){
    var linkButtonWidget = $.extend({}, $.ui.widget.prototype, {
	options: {
	    href: '#',
	    include_rest_params: false, // false | ['array','of','params'] -- Include some rest_params items with the request
	    include_token: false        // false | true -- Include the anti-CSRF token as a parameter. Required for linking directly to dynamic sources.
	},

	_beforeDOMReady: function () {
	    var self = this, $self = this.element;
	    self._bind($self, 'click', self._link.bind(self));
	},

	_link: function () {
	    var self = this, $self = this.element, link = self.options.href, p_idx, params, p_str;

	    if (self.options.include_rest_params) {
		for (p_idx = 0; p_idx < self.options.include_rest_params.length; p_idx++) {
		    if (self.options.include_rest_params[p_idx] in self.options.rest_params) {
			params = params || {};
			params[self.options.include_rest_params[p_idx]] = self.options.rest_params[self.options.include_rest_params[p_idx]];
		    }
		}
	    }

	    if (params) {
		p_str = $.param(params);

		if (link.indexOf('?') > -1) {
		    link = link.replace(/\?/, '?' + p_str + '&');
		} else if (link.indexOf('#') > -1) {
		    link = link.replace(/#/, '?' + p_str + '#');
		} else {
		    link = link + '?' + p_str;
		}
	    }

	    if (self.options.new_window) {
	    	window.open(link, '_blank').focus();
	    } else {
		window.location.href = link;
	    }

	}
    });

    add_widget('linkButtonWidget', 'ui.linkButtonWidget', 'linkButtonWidget', linkButtonWidget);
})(jQuery);
