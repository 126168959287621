/* jshint jquery: true, unused: vars */
/* global add_widget, debugLog, isAllowed */
/*
USAGE:

This allows showing, hiding, enabling, or disabling an object based upon whether or not the user has a given permission specification.

PARAMETERS:

{
    rules: [
	{
	    permission: 'PERMISSION_NAME' | [ array of permission names (ORed) ] | { single permission specification } | [ complex permission specification ]
	    action: 'show' | 'hide' | 'enable' | 'disable',
	},
	{
	    // "Else" condition
	    action: 'show' | 'hide' | 'enable' | 'disable'
	}
    ]
}

NOTES:

The first matching permission rule is applied, then matching stops. If the last item has no permission key, it is considered the "else" condition and is
followed regardless.

See isAllowed.js for specifications on the "permission" object.

This has not been tested with other state-changing widgets such as aopbStateWidget. It probably will collide. Don't try it. Use a wrapper object if you
really need to.

*/

(function( $ ){
	var permissionActionWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			rules: []
		},

		_beforeDOMReady: function () {
			var self = this;
			var $self = this.element;

			for (var ri=0; ri<self.options.rules.length; ri++) {
				var rule = self.options.rules[ri];
				var action = self._parseRule(rule || [], (ri === self.options.rules.length-1));
				if (action) {
					self._applyActions(action);
					break;
				}
			}
		},

		_applyActions: function (action) {
			var self = this;
			var $self = this.element;
			var actions = action.split(/ /);
			for (var ai=0; ai<actions.length; ai++) {
				switch (actions[ai]) {
					case 'show':
						$self.show();
						break;
					case 'hide':
						$self.hide();
						break;
					case 'enable':
						self._enable();
						break;
					case 'disable':
						self._disable();
						break;
					default:
						debugLog('jquery.permissionActionWidget.js: An invalid action was given for this permission -- ', actions[ai], ' >> ', $self);
				}
			}
		},

		_parseRule: function (rule, isLast) {
			if (!rule.permission && !rule.isLast) {
				return rule.action;
			} else {
				return isAllowed(rule) ? rule.action : false;
			}
		},

		_runEnableDisable: function (e_or_d, args) {
			var self = this;
			var $self = this.element;
			var widgets = $self.getCUIWidgets();

			self.options.screen_is_ready = true;

			for (var i=0; i<widgets.length; i++) {
				if (widgets[i] !== self) {
					return widgets[i][e_or_d].apply(widgets[i], args);
				}
			}
		},

		disable: function () {
			var self = this;
			var $self = this.element;
			var $pw = $self.closest('.pageWidgetType');

			if (self.options.screen_is_ready) {
				return self._runEnableDisable('disable', arguments);
			}

			if ($pw[0]) {
				if ($pw.is('.screenReady')) {
					return self._runEnableDisable('disable', arguments);
				} else {
					$pw.one('screenReady', function () {
						return self._runEnableDisable('disable', arguments);
					});
				}
			} else {
				return self._runEnableDisable('disable', arguments);
			}
		},

		enable: function () {
			var self = this;
			var $self = this.element;
			var $pw = $self.closest('.pageWidgetType');

			if (self.options.screen_is_ready) {
				return self._runEnableDisable('enable', arguments);
			}

			if ($pw[0]) {
				if ($pw.is('.screenReady')) {
					return self._runEnableDisable('enable', arguments);
				} else {
					$pw.one('screenReady', function () {
						return self._runEnableDisable('enable', arguments);
					});
				}
			} else {
				return self._runEnableDisable('enable', arguments);
			}
		}

	});

	add_widget('permissionActionWidget', permissionActionWidget);
})(jQuery);
