/* global classCUI */
/*
  CUI.formatDate: Format a Date object (or something that can be converted to a Date object)

  USAGE:

  CUI.formatDate(datelike_object, 'format-string');

  FORMAT-STRING:

  Prefixing "0" on most numeric values (ex: %0m for minutes) will render the value padded to two places, with a leading "0"
  Prefixing "W" for weekdays and months will give the word, "S" will give the short (abbreviated) word (ex: %Ww or %Sw for weekday)

  %L - Locale-dependent full string value
  %d / %0d - Day of the month (1-31)
  %w / %0w - Weekday (1-7)
  %Ww / %Sw - Weekday (Sunday-Saturday / Sun-Sat)
  %M / %0M - Month (1-12)
  %WM / %SM - Month (January-December / Jan-Dec)
  %y - 4-digit year
  %h / %0h - Hour (locale-dependent 12/24 hour, either 1-12 or 0-23)
  %H / %0H - Hour (24-hour zero-indexed 0-23)
  %a - AM/PM (Locale-dependent 'am', 'pm', or '')
  %A - AM/PM (Locale-dependent 'AM', 'PM', or '')
  %m / %0m - Minutes (0-59)
  %s / %0s - Seconds  

  CAVEATS:

  If a number is used, it will be in milliepoch (JavaScript style), not epoch (UNIX style). If a UNIX epoch time is used, multiply by 1000.
*/

(function () {
	classCUI.prototype.formatDate = function (date, format) {
		if (!(date instanceof Date)) {
			date = new Date(date);
		}

		if (format === '%L') {
			// This is the default-- don't waste cycles on parsing it
			return date.toLocaleString();
		} else {
			var locale_hours = date.toLocaleTimeString().match(/^\D*(\d+)/);
			locale_hours = locale_hours ? locale_hours[1] : date.getHours();

			var parts = {
				// Prefixing "0" on most numeric values (ex: %0m for minutes) will render the value padded to two places, with a leading "0"
				// Prefixing "W" for weekdays and months will give the word, "S" will give the short (abbreviated) word (ex: %Ww or %Sw for weekday)

				// %L - Locale-dependent full string value
				'L': date.toLocaleString(),

				// %d / %0d - Day of the month (1-31)
				'd': date.getDate(),

				// %w / %0w - Weekday (1-7)
				'w': date.getDay() + 1,

				// %Ww / %Sw - Weekday (Sunday-Saturday / Sun-Sat)
				'Ww': getDayWord(date),
				'Sw': getDayWordShort(date),

				// %M / %0M - Month (1-12)
				'M': date.getMonth() + 1,

				// %WM / %SM - Month (January-December / Jan-Dec)
				'WM': getMonthWord(date),
				'SM': getMonthWordShort(date),

				// %Y - 4-digit year
				'Y': date.getFullYear(),

				// %h / %0h - Hour (locale-dependent 12/24 hour, either 1-12 or 0-23)
				'h': locale_hours,

				// %H / %0H - Hour (24-hour zero-indexed 0-23)
				'H': date.getHours(),

				// %a - AM/PM (Locale-dependent 'am', 'pm', or '')
				'a': getAMPM(date).toLowerCase(),

				// %A - AM/PM (Locale-dependent 'AM', 'PM', or '')
				'A': getAMPM(date).toUpperCase(),

				// %m / %0m - Minutes (0-59)
				'm': date.getMinutes(),

				// %s / %0s - Seconds
				's': date.getSeconds()
			};

			for (var k in parts) {
				var has_matches = format.indexOf('%' + k);
				var has_pad_matches = format.indexOf('%0' + k);
				var repl = parts[k];

				if (has_matches > -1) {
					format = format.replace(new RegExp('%' + k, 'g'), repl);
				}

				if (has_pad_matches > -1) {
					format = format.replace(new RegExp('%0' + k, 'g'), zero_pad(repl, 2));
				}

			}

			return format;
		}
	}; // END CUI.formatDate


	// HELPER FUNCTIONS:

	var getAMPM = function (date) {
		// Just yank any letters off the locale string. If there are none, the locale is 24h clock, and return an empty string.
		var ampm_match = date.toLocaleTimeString().match(/[a-zA-Z]+$/);
		return ampm_match ? ampm_match[0] : '';
	};

	var getDayWord = function (date) {
		var days = [
			'Sunday',
			'Monday',
			'Tuesday',
			'Wednesday',
			'Thursday',
			'Friday',
			'Saturday'
		];

		return days[date.getDay()];
	};

	var getDayWordShort = function (date) {
		var days = [
			'Sun',
			'Mon',
			'Tue',
			'Wed',
			'Thu',
			'Fri',
			'Sat'
		];

		return days[date.getDay()];
	};

	var getMonthWord = function (date) {
		var months = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December'
		];

		return months[date.getMonth()];
	};

	var getMonthWordShort = function (date) {
		var months = [
			'Jan',
			'Feb',
			'Mar',
			'Apr',
			'May',
			'Jun',
			'Jul',
			'Aug',
			'Sep',
			'Oct',
			'Nov',
			'Dec'
		];

		return months[date.getMonth()];
	};

	var zero_pad = function (str, len) {
		len = len || 2;
		str = str.toString();
		var strlen = str.length;
		if (strlen >= len) {
			return str;
		} else {
			return new Array(len - strlen + 1).join('0') + str;
		}
	};
})();
