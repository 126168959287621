/* jshint jquery: true, browser: true */
/* global classCUI */
(function () {

	classCUI.prototype.name_attrs = ['name','value-from'];
	classCUI.prototype.getElementName = function ($elem, extra_attrs) {
		var attrs = (extra_attrs || []).concat(this.name_attrs), i, attr;

		for (i=0; i<attrs.length; i++) {
			attr = $elem.attr(attrs[i]);
			if (attr !== undefined) {
				return attr;
			}
		}
		return undefined;
	};
})();
