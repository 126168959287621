/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/* ****************************************************************************************************************************
    dirtyFormatWidget

    This widget extends the formWidget and allows for setting the dirty state of a form based on a specific element's value.  Uses
    for this include allowing a user to save settings after testing their settings.

    options:
       dirty_element  REQUIRED -- this is the name of the input element the form should track
       dirty_true     this is the value that will cause the form to be dirty.
       invalidate     Provide a selector + invalid message to monitor after this form becomes dirty. This allows us to force the
                      user to return the form to the dirty-causing state in order for the form to become valid again.

		      This works by recording the form state at the time that this form becomes dirty (when a test is run and 
		      passes, for instance), and monitoring all changes to the elements matching the selector. Re-running the test
		      and having it return a passing value (i.e. one that causes this dirtyFormWidget to be dirty) will negate
		      the invalidation and cause the changed input elements to be 'valid' again.

                      Example (from ldapcreate screen):

		      invalidate: [
		          {
			      selector: '.ldap_settings',
			      message: 'Please re-run the test after modifying settings!'
			  }
		      ]
****************************************************************************************************************************** */
(function( $ ){
	var dirtyFormWidget = $.extend(true, {}, $.ui.formWidget.prototype, {
		options: {
			submit_all: true,
			dirty_elements: [],
			dirty_true: false,
			disable_buttons: false
		},

		_afterInit: function () {
			var self = this, $self = this.element;

			// pageWidget expects formWidget data when submitting
			$self.data('formWidget', $self.data('dirtyFormWidget'));

			$.ui.formWidget.prototype._afterInit.apply(this, arguments);
		},

		_installChangeHandlers: function() {
			var self = this, $self = this.element, e_idx, e_length;

			if (self.options.required_fields) {
				self._bind($self, 'change keyup', self._validateRequiredFields.bind(self));
			}

			e_length = self.options.dirty_elements.length;

			// Find form fields that have the correct name attribute
			for (e_idx = 0; e_idx < e_length; e_idx++) {
				$self.on('click.fwch keyup.fwch change.fwch enable-disable.fwch', '[name="' + self.options.dirty_elements[e_idx] + '"]', self.options.dirtyHandler);
			}
		},

		_recordFormState: function() {
			var self = this, $self = this.element;

			// Let's record a valid state of the selected inputs to compare against later

			for (var i in self.options.invalidate) {
				var invalidator = self.options.invalidate[i], $elems = $self.find(invalidator.selector + ' input, ' + invalidator.selector + ' select' );
				invalidator.formState = invalidator.formState || {};

				$elems.each(function(){
					invalidator.formState = $.extend({}, invalidator.formState, CUI.getWidgetElementValue($(this)));
				});

				self._compareFormState(invalidator);
			}
		},

		_compareFormState: function(invalidator) {
			var self = this, $self = this.element;
			var $elems = $self.find(invalidator.selector + ' input, ' + invalidator.selector + ' select' ), compareState = {};

			$elems.each(function(){
				compareState = $.extend({}, compareState, CUI.getWidgetElementValue($(this)));
			});

			for (var key in invalidator.formState) {
				var val = invalidator.formState[key], $field = $('[name="' + key + '"]');

				if (val != compareState[key]) {
					$field.addClass('is-invalid');
					if (invalidator.message) {
						$field.data('error', invalidator.message);
						$field.data('invalid_dirty_form', true);
					}
				} else {
					$field.removeClass('is-invalid');
					$field.data('invalid_dirty_form', false);
				}
			}

			self._validateRequiredFields();

			self.computeValidState(true);
		},

		_validateRequiredFields: function() {
			var self = this, $self = this.element;

			if (self.options.required_fields) {
				for (var i in self.options.required_fields) {
					var field = self.options.required_fields[i];
					var $field = $self.find('[name="' + field + '"]');

					if ($field.length > 0) {
						var val = CUI.getWidgetElementValue($field)[field];

						if (val === '' || val === null || val === undefined) {
							$field.addClass('is-invalid').data('error', 'This field is required');
						} else {
							if (!$field.data('invalid_dirty_form')) {
								$field.removeClass('is-invalid');
							}
						}
					}
				}

				self.computeValidState(true);
			}
		},

		computeDirtyState: function () {
			var self = this, $self = this.element, $elem, e_idx, e_length, value, dirty_check;

			e_length = self.options.dirty_elements.length;

			self.options.dirty = false;

			for (e_idx = 0; e_idx < e_length; e_idx++) {
				$elem = $self.find('[name="' + self.options.dirty_elements[e_idx] + '"]');

				// Assume we are not dirty
				$elem.removeClass('is-dirty');

				value = CUI.getInputElementValue($elem);

				// check value against pre-defined dirty state
				if (self.options.dirty_true) {
					dirty_check = self.options.dirty_true;

					if (value === dirty_check) {
						self.options.dirty = true;
						$elem.addClass('is-dirty');
					}
				}
				else {
					dirty_check = self.options.originalData[self.options.dirty_elements[e_idx]];

					if ( !CUI.compareObjects(value, dirty_check, { null_equals_blank: true, loose_compare_basics: true }) ) {
						self.options.dirty = true;
						$elem.addClass('is-dirty');
					}
				}
			}

			if (self.options.dirty && self.options.invalidate) {
				self._recordFormState();
				for (var i in self.options.invalidate) {
					var invalidator = self.options.invalidate[i];
					if (!invalidator.bound) {
						self._bind($self, 'change keyup', self._compareFormState.bind(self, invalidator));
						invalidator.bound = true;
					}
				}
			}

			self._applyDirtyState();
		},

		enableSubmitButtons: function() {
			var self = this, $self = this.element, $save_button;

			if (self.options.disable_buttons) {
				$save_button = $self.closest('.screen, .overlay').find('.page_save_button');
				if ( !($save_button.hasClass('state-disabled')) ) {
					$save_button.attr('disabled', 'disabled').addClass('state-disabled');
				}
				return;
			}

			$.ui.formWidget.prototype.enableSubmitButtons.apply(this, arguments);
		},

		enableCancelButtons: function() {
			var self = this, $self = this.element, $cancel_button;

			if (self.options.disable_buttons) {
				$cancel_button = $self.closest('.screen, .overlay').find('.page_cancel_button');
				if ( !($cancel_button.hasClass('state-disabled')) ) {
					$cancel_button.attr('disabled', 'disabled').addClass('state-disabled');
				}
				return;
			}

			$.ui.formWidget.prototype.enableCancelButtons.apply(this, arguments);
		},

		disableSubmitButtons: function() {
			var self = this, $self = this.element, $save_button;

			if (self.options.disable_buttons) {
				$save_button = $self.closest('.screen, .overlay').find('.page_save_button');
				if ( !($save_button.hasClass('state-disabled')) ) {
					$save_button.attr('disabled', 'disabled').addClass('state-disabled');
				}
				return;
			}

			$.ui.formWidget.prototype.disableSubmitButtons.apply(this, arguments);
		},

		disableCancelButtons: function() {
			var self = this, $self = this.element, $cancel_button;

			if (self.options.disable_buttons) {
				$cancel_button = $self.closest('.screen, .overlay').find('.page_cancel_button');
				if ( !($cancel_button.hasClass('state-disabled')) ) {
					$cancel_button.attr('disabled', 'disabled').addClass('state-disabled');
				}
				return;
			}

			$.ui.formWidget.prototype.disableCancelButtons.apply(this, arguments);
		}

	});

	add_widget('dirtyFormWidget', dirtyFormWidget);
})(jQuery);
