/* jshint jquery: true, unused: vars */
/* global CUI */
(function( $ ){
	var mousePos = false;

	// Functioned out like this so it only starts listening if we need to
	var watchMousePosition = function () {
		if (mousePos) { return; }

		$(document).on('mousemove', function (e) {
			mousePos = [e.pageX, e.pageY];
		});
	};

	var flyoutBaseWidget = {
		options: {
			template: false,

			$target: false, // $jQ element
			$content: false, // $jQ element

			attach: 'body', // Looks for a "closest" of the given selector, unless the selector is "body"

			// This will anchor the top left of the dropdown to the bottom left of the target
			self_anchor:   'top left',
			target_anchor: 'bottom left',  // top/bottom left/right, or just "mouse" to align to the mouse
			anchor_offset: [0,0], // x, y --NOT IMPLEMENTED!

			anti_clip: {
				'bottom' : {
					// ...use these settings instead
					self_anchor:   'bottom left',
					target_anchor: 'top left',
					anchor_offset: [0,0]
				}
			},

			fade_in:  100,
			fade_out: 100,

			positioner_css: {
				// Constants or functions are allowed-- functions have "this" set to the instance
			},

			flyout_css: {
				// Constants or functions are allowed-- functions have "this" set to the instance
				'min-width': function () { return this.options.$target.outerWidth() - 2; },
				'background': function () { return this.options.$target.css('background'); }
			},

			positioner_class: 'flyout',

			// For internal use
			flyout_generated: false,
			$flyout: false
		},

		_initFlyoutBaseWidget: function () {
			var self = this;
			var $self = self.element;

			if (self.options.target_anchor === 'mouse') {
				watchMousePosition(); // Yes, we will be using mouse tracking
			}
		},

		// Override this function to do something else when the flyout goes offscreen
		_flyoutOffscreen: function () {
			this.hideFlyout();
		},

		generateFlyout: function () {
			var self = this;
			var $self = self.element;

			self.options.$flyout = $('<div class="flyoutPositioner"><div class="flyout"></div></div>');
			self.options.$flyout
				.css({
				position : 'absolute',
				'z-index' : '999999999999'
			})
				.find('>.flyout').css({
				position: 'absolute'
			});

			if (self.options.positioner_class) {
				self.options.$flyout.addClass(self.options.positioner_class);
			}

			self.options.flyout_generated = true;
		},

		_setAttach: function () {
			var self = this;
			var $self = this.element;

			var selAttach = self.options.attach;
			if (selAttach === 'body' || selAttach.search(/^#[-a-zA-Z\.]+$/) > -1) {
				// No need to go "closest" on an ID or "body" search
				self.options.$attach = $(selAttach);
			} else {
				self.options.$attach = $self.closest(selAttach);
			}
		},

		_repositionFlyout: function (ignoreClipCheck) {
			var self = this;
			var $self = self.element;

			if (!ignoreClipCheck) {
				self._clipCheck();
			}

			var target_anchor = self.options.clip_alt.target_anchor || self.options.target_anchor;
			var self_anchor =   self.options.clip_alt.self_anchor   || self.options.self_anchor;

			var $flyout = self.options.$flyout;
			var $target = self.options.$target.addClass('flyout-target');
			var offset = $target.offset();

			var posTop = offset.top;
			var posLeft = offset.left;

			var $contentWrap = $('>.flyout', self.options.$flyout);

			// Pin the "flyout's" proper corner
			var opposites = ['bottom','top','right','left'];
			$.each(['top','bottom','left','right'], function (idx, side) {
				if (self_anchor.indexOf(side) > -1) {
					$contentWrap
						.css(side, '0')
						.css(opposites[idx], '')
						.css(self._processCSS(self.options.flyout_css));
				}
			});

			if (target_anchor === 'mouse') {
				posLeft = mousePos[0];
				posTop = mousePos[1];
			} else {
				if (target_anchor.indexOf('bottom') > -1) {
					posTop += $target.height();
				}

				if (target_anchor.indexOf('right') > -1) {
					posLeft += $target.width();
				}
			}


			if (self.options.fade_in && !$flyout.closest('body')[0]) {
				$flyout
					.appendTo(self.options.$attach)
					.css($.extend(true, {
					top: posTop,
					left: posLeft
				}, self._processCSS(self.options.flyout_css)))
					.hide()
					.fadeIn(self.options.fade_in);
			} else {
				$flyout
					.appendTo(self.options.$attach)
					.css($.extend(true, {
					top: posTop,
					left: posLeft
				}, self._processCSS(self.options.flyout_css)));
			}
		},

		_clipCheck: function () {
			var self = this;
			var $self = self.element;

			var $flyout = self.options.$flyout.find('>.flyout');
			var anti_clip = self.options.anti_clip;

			// Remove clipping to check if it will be clipped -- Can't tell unless it's real, due to possible CSS differences
			if (self.options.clip_alt) {
				self.options.clip_alt = false;
				self._repositionFlyout(true);
			}

			var offset = $flyout.offset();
			offset.bottom = offset.top + $flyout.outerHeight();
			offset.right = offset.left + $flyout.outerWidth();

			$flyout.removeClass('anti-clip anti-clip-top anti-clip-bottom anti-clip-left anti-clip-right');
			self.options.$target.removeClass('flyout-target-anti-clip flyout-target-anti-clip-top flyout-target-anti-clip-bottom flyout-target-anti-clip-left flyout-target-anti-clip-right');

			if (anti_clip.top && offset.top < 0) {
				self.options.clip_alt = anti_clip.top;
				$flyout.addClass('anti-clip anti-clip-top');
				self.options.$target.addClass('flyout-target-anti-clip flyout-target-anti-clip-top');
			} else if (anti_clip.bottom && offset.bottom > $(window).height()) {
				self.options.clip_alt = anti_clip.bottom;
				$flyout.addClass('anti-clip anti-clip-bottom');
				self.options.$target.addClass('flyout-target-anti-clip flyout-target-anti-clip-bottom');
			} else if (anti_clip.left && offset.left < 0) {
				self.options.clip_alt = anti_clip.left;
				$flyout.addClass('anti-clip anti-clip-left');
				self.options.$target.addClass('flyout-target-anti-clip flyout-target-anti-clip-left');
			} else if (anti_clip.right && offset.right > $(window).width()) {
				self.options.clip_alt = anti_clip.right;
				$flyout.addClass('anti-clip anti-clip-right');
				self.options.$target.addClass('flyout-target-anti-clip flyout-target-anti-clip-right');
			} else {
				self.options.clip_alt = false;
			}

			if (self.options.clip_alt) {
				self._repositionFlyout(true);
			}
		},

		showFlyout: function () {
			var self = this;
			var $self = self.element;

			if (!self.options.flyout_generated) {
				self.generateFlyout();
			}

			self.options.$content = self.options.$content || $('<div>The flyout widget was not properly configured.<br />Be sure the subclass defines self.options.$content</div>');
			self.options.$target =  self.options.$target  || $self;

			self.options.$content.appendTo(self.options.$flyout.find('>.flyout'));

			self._setAttach();
			self._repositionFlyout();
			self.options.$target.addClass('flyout-target-active');

			self.options.$target.scrollDetect();
			// self._unbind($(document), 'scrollDetect.' + self.options.widget_id);
			$(document).off('scrollDetect.' + self.options.widget_id);
			// self._bind($(document), 'scrollDetect.' + self.options.widget_id, function (e) {
			$(document).on('scrollDetect.' + self.options.widget_id, function (e) {
				if (self.options.$target.isScrolledOff()) {
					self._flyoutOffscreen();
				} else {
					self._repositionFlyout();
				}
			});

		},

		hideFlyout: function () {
			var self = this;
			var $self = self.element;

			// self._unbind($(document), 'scrollDetect.' + self.options.widget_id);
			self._unbind($(document), 'scrollDetect.' + self.options.widget_id);
			self.options.$target.removeClass('flyout-target-active');

			if (self.options.fade_out) {
				self.options.$flyout.fadeOut(self.options.fade_out, function () {
					$(this).detach();
				});

			} else {
				self.options.$flyout.detach();
			}
		},

		// Used to slightly modify the CSS so CSS functions have "this" set to the class instance
		_processCSS: function (cssObj) {
			var self = this;

			$.each(cssObj, function (key, val) {
				if ($.isFunction(val)) {
					cssObj[key] = function () { return val.call(self); };
				}
			});

			return cssObj;
		}
	};

	CUI.mixin.register('widget.flyoutBaseWidget', flyoutBaseWidget);

})(jQuery);
