/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, Highcharts */
(function( $ ){
	var timeSeriesWidget = $.extend({}, $.ui.widget.prototype, {
		options: {
			accepts_data_from_parent: true,
			renderTo: 'container',
			title: 'timeSeries',
			units: '%',

			display_controls: true,

			_default_ranges: [
				{ text: 'Last 6 Months', value: 2678400 * 6 },
				{ text: 'Last 3 Months', value: 2678400 * 3 },
				{ text: 'Last Month', value: 2678400 },
				{ text: 'Last Week', value: 604800 },
				{ text: 'Last Day', value: 86400, selected: true },
				{ text: 'Last Hour', value: 3600 }
			],

			_default_interpolations: [
				{ text: 'Min Value', value: 'MIN' },
				{ text: 'Avg Value', value: 'AVERAGE' },
				{ text: 'Max Value', value: 'MAX', selected: true }
			],

			template_html: '<div class="timeSeriesControls"> <span class="interpolationControl">Points Show: <select class="interpolationSelect"></select></span> <span class="rangeControl">Time Range: <select class="rangeSelect"></select></span> </div>'
		},

		_beforeInit: function() {
			var self = this;

			if (! self.options.rest_params) {
				self.options.rest_params = {};
			}

			self.options.ranges = self.options.ranges || self.options._default_ranges;
			self.options.interpolations = self.options.interpolations || self.options._default_interpolations;

			// Infer series_history + interpolation from our select options
			self._initSeriesHistory();
			self._initInterpolation();

			// Set the rest_params based on options
			self._setRange();
			self._setInterpolation();

			self.options.rest_params.type = self.options.type;

			// Use local time. This only appears to be able to be set as a global option
			Highcharts.setOptions({ global: { useUTC: false } });
		},

		_beforeDOMReady: function() {
			var self = this, $self = this.element;
			self._setupSelect($self.find('.interpolationSelect'), self.options.interpolations, '_interpolationSelectChangeHandler');
			self._setupSelect($self.find('.rangeSelect'), self.options.ranges, '_rangeSelectChangeHandler');

			if (self.options.display_controls === false) {
				$self.find('.timeSeriesControls').hide();
			}
		},

		_afterInit: function() {
			var $self = this.element;
			var self = this;

			// Correct? to check for data exists and is defined.
			if (self.options.values) {
				self._redrawChart();
			}
		},

		_initSeriesHistory: function() {
			// Infer the value of self.options.series_history from the SELECT options; fallback to a default
			var self = this;
			if (!self.options.series_history) {
				// Let's get the default from the range definitions. We're looking for the 'selected' range
				for (var i=0;i<self.options.ranges.length;i++) {
					if (self.options.ranges[i].selected) {
						self.options.series_history = self.options.ranges[i].value;
					}
				}

				// We STILL don't have a series_history...
				if (!self.options.series_history) {
					self.options.series_history = self.options.ranges[0].value || 86400;
				}
			}
		},

		_initInterpolation: function() {
			// Infer the value of self.options.interpolation from the SELECT options; fallback to a default
			var self = this;
			if (!self.options.interpolation) {
				// Let's get the default from the interpolation definitions. We're looking for the 'selected' interpolation
				for (var i=0;i<self.options.interpolations.length;i++) {
					if (self.options.interpolations[i].selected) {
						self.options.interpolation = self.options.interpolations[i].value;
					}
				}

				// We STILL don't have an interpolation...
				if (!self.options.interpolation) {
					self.options.interpolation = self.options.interpolations[0].value || 'MAX';
				}
			}
		},

		_setRange: function() {
			var self = this;

			self.options.rest_params.end = parseInt(new Date() / 1000);
			self.options.rest_params.start = self.options.rest_params.end - self.options.series_history;
		},

		_setInterpolation: function() {
			var self = this;

			self.options.rest_params.interpolation = self.options.interpolation;
		},

		_interpolationSelectChangeHandler: function() {
			var self = this, $self = this.element, $select = $self.find('.interpolationSelect');


			self.options.interpolation = $select.val();
			self._setInterpolation();
			self._doDataFill();
		},

		_rangeSelectChangeHandler: function() {
			var self = this, $self = this.element, $select = $self.find('.rangeSelect');

			self.options.series_history = $select.val();
			self._setRange();
			self._doDataFill();
		},

		_setupSelect: function($select, options, callback) {
			var self = this, $self = this.element;

			for(var i=0; i<options.length; i++) {
				var option = options[i];
				var $option = $('<option>', { text: option.text, value: option.value });
				if (option.selected) {
					$option.prop('selected', 'selected');
				}
				$select.append($option);
			}

			if (typeof self[callback] == 'function') {
				self._bind(
					$select,
					'change',
					self[callback].bind(self)
				);
			}
		},

		fillData: function(d, from_self) {
			var $self = this.element;
			var self = this;

			if (from_self || !from_self && self.options.accepts_data_from_parent) {
				self.options.$container = $(document.createElement('div'));
				self.options.$container.prop('id', self.options.renderTo);
				$self.append(self.options.$container);

				self.options.data = d;
				self._redrawChart();
			}
			return;
		},

		_redrawChart: function(update_data_only) {
			var self = this, $self = this.element, table_values, row, points, direction, colors, color_idx;

			if (self.options.chart instanceof Highcharts.Chart) {
				self.options.$container.css('height', self.options.$container.css('height'));
			}

			colors = ['#D07446', '#4D8FCD' ];
			color_idx = 0;

			if (self.options.data ){
				for ( direction in self.options.data ) {
					var seriesData = {
						name: self.options.data[direction].label,
						pointStart: self.options.rest_params.start *1000,
						type: "area",
						pointInterval: self.options.data[direction].data[1][0] - self.options.data[direction].data[0][0],
						color: colors[color_idx]
					};
					var dataPoints = [];
					for ( points in self.options.data[direction].data ) {
						dataPoints.push(parseInt(self.options.data[direction].data[points][1]));
					}
					seriesData.data = dataPoints;
					if(table_values) {
						table_values.push(seriesData);
					} else {
						table_values = [seriesData];
					}

					color_idx++;
					if (color_idx === colors.length) { color_idx = 0; }
				}
			}

			if (! table_values) {
				table_values = [{ "name": "empty", "y": 1}];
			}

			/*
	     * Confirm that any values are Ints
	     */
			if (self.options.values) {
				for (row in self.options.values) {
					if (parseInt(self.options.values[row].y) > 0) {
						table_values = self.options.values;
					}
				}
			}

			self.options.chart = new Highcharts.Chart({
				chart: {
					renderTo: self.options.renderTo,
					zoomType: 'x',
					spacingRight: 20
				},
				credits: {
					enabled: false
				},
				title: {
					text: self.options.title
				},
				tooltip: {
					formatter: function() {
						var y, secondary;
						y = Number.prototype.toLocaleString ? Number(this.y).toLocaleString() : this.y;
						if (this.point.secondary) {
							secondary = Number.prototype.toLocaleString ? Number(this.point.secondary).toLocaleString() : this.point.secondary;
							return y + ' ' + self.options.unit + ', ' + secondary + ' ' + self.options.secondary_unit;
						} else {
							return y + ' ' + self.options.unit;
						}
					}
				},
				xAxis: {
					type: 'datetime',
					//maxZoom: 3600000, // fourteen days
					title: {
						text: self.options.x_axis
					}
				},
				yAxis: {
					title: {
						text: self.options.y_axis
					},
					min: 0.6,
					startOnTick: false,
					showFirstLabel: false
				},
				plotOptions: {
					area: {
						fillColor: {
							linearGradient: [0, 0, 0, 300],
							stops: [
								[0, Highcharts.getOptions().colors[0]],
								[1, 'rgba(2,0,0,0)']
							]
						},
						lineWidth: 1,
						marker: {
							enabled: false,
							states: {
								hover: {
									enabled: true,
									radius: 5
								}
							}
						},
						shadow: false,
						states: {
							hover: {
								lineWidth: 1
							}
						}
					}
				},
				legend: {
					enabled: true
				},
				series: table_values
			});
		}
	});
	add_widget('timeSeriesWidget', timeSeriesWidget);
})(jQuery);
