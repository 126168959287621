/* jshint jquery: true */
/* global add_widget */
/*

activeCallsDataTableWidget: This widget expands dataTableWidget for the active calls screen.  When a call is being placed, two entries are added
                            to the database.  This subclass filters out the duplicate entries based on "bbx_user_id".

			    *note: call_state for both db entries is 'ringing' so filtering by state is not possible
*/

(function( $ ){
	"use strict";

	var activeCallsDataTableWidget = $.extend(true, {}, $.ui.dataTableWidget.prototype, {

		options: {
			debounce_la_modify: true,
			debounce_la_modify_time: 100
		},

		_afterDataTableWidgetBeforeInit: function() {
			var self = this, dtw;

			self.options._rows_shown_by_uid = {};

			// Load a global custom filtering function so we hit the custom fnFilterCustom function code above

			$.fn.dataTableExt.afnFiltering.push(
				function(oSettings, aData, iDataIndex) { 
					dtw = $(oSettings.nTable).getCUIWidget('activeCallsDataTableWidget');

					if (dtw && oSettings && aData && iDataIndex >= 0) {
						// console_log('Filter');
						var r = dtw.filterRow.call(dtw, oSettings, aData, iDataIndex);
						return r;
					}
					return true;
				}
			);
		},

		/*
	  This shim (next 2 methods) flips A and B legs when the call comes from a queue (queue_id true on the row). This is a temporary fix to BNPH-9428,
	  and can (and should) be REMOVED while/after fixing BNPH-9436.
	*/

		_swapQueueRowData: function (row_data) {
			var self = this, swapped = {}, swaps = [['a_','b_'], ['cid_','ceid_']], s_idx, key;
			if (row_data.queue_id && row_data.b_uuid) {
				for (key in row_data) {
					swaploop: for (s_idx = 0; s_idx < swaps.length; s_idx++) {
						if (key.indexOf(swaps[s_idx][0]) === 0) {
							swapped[key.replace(new RegExp('^'+swaps[s_idx][0]), swaps[s_idx][1])] = row_data[key];
							break swaploop;
						} else if (key.indexOf(swaps[s_idx][1]) === 0) {
							swapped[key.replace(new RegExp('^'+swaps[s_idx][1]), swaps[s_idx][0])] = row_data[key];
							break swaploop;
						}
					}
				}

				$.extend(row_data, swapped);
			}
			return row_data;
		},

		// TODO: Add a shim in here to _markNotShown the row if it is removed from the table.

		setOriginalRowData: function (index, row) {
			var self = this, fn_args = Array.prototype.slice.call(arguments);
			fn_args[1] = self._swapQueueRowData(row);
			$.ui.dataTableWidget.prototype.setOriginalRowData.apply(self, fn_args);
		},

		_markShown: function (idx) {
			this.setExtraRowData(idx, '_shown', true);
		},

		_markNotShown: function (idx) {
			this.setExtraRowData(idx, '_shown', false);
		},

		_isShown: function (idx) {
			return this.getExtraRowData(idx, '_shown');
		},

		/* END BNPH-9428 shim */

		filterRow: function (oSettings, aData, iDataIndex) {
			var self = this, $self = this.element, legs, active_leg, other_leg, displayed, a_callstate, b_callstate;
			var row_data = self.getAllRowData(iDataIndex);

			if (aData === undefined || iDataIndex === undefined) {
				return true;
			}

			legs = 0;
			if (row_data.b_callstate !== 'DOWN') { legs++; active_leg = 'b'; other_leg = 'a'; }
			if (row_data.a_callstate !== 'DOWN') { legs++; active_leg = 'a'; other_leg = 'b'; }

			//$tr = $(self.options.$oTable.fnGetNodes(iDataIndex));

			// Do not show B-leg only calls (eavesdrop calls) by default
			if (active_leg === 'b' && !self.options.show_b_leg_calls) {
				self._markNotShown(iDataIndex);
				//$tr.data('displayed', false);
				return false;
			}

			// FYI: "?:" just prevents the parens from capturing
			var state_regex = /^(?:ACTIVE|HELD|UNHOLD)/;

			displayed = !!row_data._shown; //$tr.data('displayed');

			// Don't display calls that are ringing
			a_callstate = row_data.a_callstate;
			b_callstate = row_data.a_callstate;

			if ( !(
				state_regex.test(row_data[active_leg + '_callstate']) &&
				(state_regex.test(row_data[other_leg + '_callstate']) || row_data[other_leg + '_callstate'] === 'DOWN' /* one-leg, this is okay */ )
			)) {
				//$tr.data('displayed', false);
				self._markNotShown(iDataIndex);
				return false;
			}

			// Check to see if we are already displaying a row with this user id, if we have a user id.
			var current_row_user_id, check_row_user_id;
			current_row_user_id = row_data[active_leg + '_bbx_user_id'];
			if ( current_row_user_id ) {
				var user_id_col_num = self.getColumnNum(active_leg + '_bbx_user_id');

				for (var row = 0, num_rows = oSettings.aiDisplay.length; row < num_rows; ++row) {
					if (user_id_col_num >= 0) {
						check_row_user_id = self.getCellData(oSettings.aiDisplay[row], user_id_col_num);
					} else {
						check_row_user_id = self.getExtraRowData(oSettings.aiDisplay[row], "a_bbx_user_id");
					}

					if (oSettings.aiDisplay[row] == iDataIndex) {
						// We hit the place in the display list where our current row is at, so break the loop
						break;
					}

					if (check_row_user_id == current_row_user_id) {
						// We are already showing this user, so don't show another row for this user
						if (displayed) {
							self.options.resize = true;
							// $tr.data('displayed', false);
							self._markNotShown(iDataIndex);
						}
						return false;
					}
				}
			}

			// if no other rules have applied, display the row
			if (!displayed) {
				self.options.resize = true;
				self._markShown(iDataIndex);
				// $tr.data('displayed', true);
			}
			return true;
		}
	});

	add_widget('activeCallsDataTableWidget', activeCallsDataTableWidget);
})(jQuery);
