/* jshint jquery: true, unused: vars */
/* global add_widget */
/* Slider widget

OPTIONS:
{
    default_value: false,

    // All the following values are passed through directly to the jQuery UI slider widget
    animate: false,
    min: 0,
    max: 100,
    orientation: 'horizontal',
    range: false | true | 'max', 'min',
    step: 1,
    values: null | [Array]
}

*/

(function( $ ){

	var sliderWidget = $.extend({}, $.ui.widget.prototype, {

		options: { },
		value_widget: true,
		set_value_widget: true,
		manages_own_descendent_values: true,

		_beforeInit: function() {
			var $self = this.element;
			var self = this;

			self._setupSliderCallbackClosures();

			if (!self.options.default_value) {
				self.options.default_value = self.options.min;
			}

			self.options.value = self.options.default_value;

			var text = (self.options.value_map ? self.options.value_map[self.options.default_value] : '');

			$self.wrap('<div class="slider_wrapper" />');
			$self.wrap('<div class="slider_container" />');
			self.options.$wrapper = $self.closest('.slider_wrapper');
			self.options.$wrapper.append('<span class="slider_text">' + text + '</span>');
			self.options.$text = self.options.$wrapper.find('.slider_text');

			$.extend(self.options, { 
				'slide' : self.options.slider_change_callback_closure,
				'change' : self.options.slider_change_callback_closure,
				'value' : self.options.default_value
			});
			$self.slider(self.options);
		},

		_setupSliderCallbackClosures: function() {
			var $self = this.element;
			var self = this;

			self.options.slider_change_callback_closure = function(event, ui) {
				self._sliderChangeCallback(event, ui);
			};
		},

		_sliderChangeCallback: function(event, ui) {
			var $self = this.element;
			var self = this;

			var text = self.options.value_map[ui.value];
			self.options.value = ui.value;
			self.options.$text.empty().html(text);
			$self.trigger('change');
		},

		setValue: function(v) {
			var $self = this.element;
			var self = this;

			var text = self.options.value_map[v];
			self.options.value = v;
			self.options.$text.empty().html(text);
			$self.slider('value', v);
		},

		_getWidgetValue: function() {
			var $self = this.element;
			var self = this;

			return self._wrapValue(self.options.value);
		},

		enable: function () {
			$.ui.widget.prototype.enable.apply(this, arguments);
			if (!this.element.hasClass('state-disabled')) {
				this.element.slider('option', 'disabled', false);
			}
		},

		disable: function () {
			$.ui.widget.prototype.enable.apply(this, arguments);
			if (!this.element.hasClass('state-disabled')) {
				this.element.slider('option', 'disabled', true);
			}
		}

	});

	add_widget('sliderWidget', sliderWidget);
})(jQuery);
