/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/*

  Message Button Widget:

  A button that pops up a Dialog. The dialog (generally) doesn't do anything, and is used only as an informational device.

  OPTIONS:
  {
    message: { <CUI.Dialog options> }
  }

  Apply to a BUTTON element.
*/

(function( $ ){
	var preventDefault = function (e) { e.preventDefault(); };

	var messageButtonWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			message: { title: 'Message', text: 'A message was not specified' }
		},

		_beforeInit: function () {
			var self = this, $self = this.element;
			self._bind($self, 'click', preventDefault);
			self._bind($self, 'click', self._createDialogEvent.bind(self));
			self._addDestroyCallback(self.closeDialog.bind(self));
		},

		_createDialogEvent: function () { this._createDialog(); }, // Stub this in in case we need to do any other event/bind handling later

		_createDialog: function () {
			var self = this, $self = this.element, ref_closeDialog;

			ref_closeDialog = self.closeDialog.bind(self);

			self.options._dialog = new CUI.Dialog($.extend({}, self.options.message, {
				buttons: [ 'OK' ],
				callbacks: [ ref_closeDialog ]
			}));

		},

		closeDialog: function () {
			// A "mini-destroy" for when the dialog goes away...
			var self = this, $self = this.element, dialog = self.options._dialog;

			// If this isn't there, either we've already closed the dialog, or we never opened one, so do nothing.
			// (This is called as a widget destroy callback, so this is a very real possibility.)
			if (!self.options._dialog) { return; }

			dialog.remove();
			delete self.options._dialog;
		}
	});

	add_widget('messageButtonWidget', messageButtonWidget);
})(jQuery);
