/* jshint jquery: true, browser: true */
/* global classCUI, getUnique, debugLog, isObjectEmpty */
/*
 * dataEvents Class
 * This class is for other widgets to bind to tags and be called back when the tag is triggered. This will work similar to jQuery bind
 * but will make sure events that watch multiple tags will not get triggered multiple times when multiple tags are triggered in a simple trigger.
 * bind(object, <tags>, callback)
 * unbind(object, <tags>, callback])
 * trigger("<tag> <tag>", "<type>"[, <rest_params>])

tags =  {
  "<tag>[ <tag>]" : "", // Returns e object { tag : "<tag>", type : "put|post|delete", rest_params : <rest_params> }
  "<tag>[ <tag>]" : {<rest_param> : <value>} // Same as above, but params must match.
}

dataEvents : {
  "bind" : {
    "<tag>[ <tag>]" : "", // Returns e object { tag : "<tag>", type : "put|post|delete", rest_params : <rest_params> }
    "<tag>[ <tag>]" : "<rest_param> <rest_param>" // Same as above, but params must match.
    "<tag>[ <tag>]" : ["<rest_param>", "<rest_param>"] // Same as above, but params must match.
  },
  "trigger" : "",
  "allow_delete" : false | true // Normally, DELETE events do NOT trigger a bind, because updating a deleted event is no longer possible.
                                // However, things like list tables may actually need to bind on DELETE events
}

 */

classCUI.prototype.dataEvents = new function () {
	this.initialize = function () {
		var self = this;

		self.objectTrack = {};
		self.boundObjects = {};
		self.tags = {};
	};

	this.trigger = function (tags, type, rest_params) {
		var self = this, i, arrTags = tags.split(/\s+/), arrQueuedObjects = [], arrCallbacks = [], arrUnbind = [], obj, rest_param;


		for (i=0; i<arrTags.length; i++) {
			if (self.tags[arrTags[i]]) {

				// Create event object.
				var event = { 
					tag : arrTags[i],
					type : type, 
					rest_params : rest_params 
				};

				for (obj in self.tags[arrTags[i]]) {
					if (self.tags[arrTags[i]][obj].match) {
						var match = false;
						for (rest_param in self.tags[arrTags[i]][obj].match) {
							if (self.tags[arrTags[i]][obj].match[rest_param] && rest_params && rest_params[rest_param] &&
								self.tags[arrTags[i]][obj].match[rest_param] == rest_params[rest_param]) {
								match = true;
							} else {
								match = false;
							}
						}
						if (match && $.inArray(obj, arrQueuedObjects) < 0) {
							arrQueuedObjects.push(obj);
							if (self.objectTrack[obj].element.closest("body").length > 0) {
								arrCallbacks.push({ callback : self.tags[arrTags[i]][obj].callback, event : event });
							} else {
								arrUnbind.push(self.objectTrack[obj]);
							}
						}
					} else {
						if ($.inArray(obj, arrQueuedObjects) < 0) {
							arrQueuedObjects.push(obj);
							if (self.objectTrack[obj].element.closest("body").length > 0) {
								arrCallbacks.push({ callback : self.tags[arrTags[i]][obj].callback, event : event });
							} else {
								arrUnbind.push(self.objectTrack[obj]);
							}
						}
					}
				}
			}
		}

		if (arrCallbacks.length > 0) {
			window.setTimeout(function () {
				for (var i=0; i<arrCallbacks.length; i++) {
					arrCallbacks[i].callback(arrCallbacks[i].event);
				}
			}, 1);
		}

		for (i=0; i<arrUnbind.length; i++) {
			self.unbind(arrUnbind[i]);
		}
	};

	this.bind = function (obj, tags, callback) {
		var self = this;

		if (!obj.CUI_ID) {
			obj.CUI_ID = getUnique('CUI');
		}

		if (typeof tags != "object") {
			debugLog("cui.dataEvents.js: received invalid tags. Tags must be a tags object structure.", obj, tags, callback);
			return;
		}

		// Check to see if object has bound before. If not add object tags array.
		if (!self.boundObjects[obj.CUI_ID]) {
			self.boundObjects[obj.CUI_ID] = {};
			self.objectTrack[obj.CUI_ID] = obj;
		}

		for (var key in tags) {
			var arrTags = key.split(/\s+/);

			for (var i=0; i<arrTags.length; i++) {
				var tagObject = {};
				// If we do not find tag in tags object then create tag object.
				if (!self.tags[arrTags[i]]) {
					self.tags[arrTags[i]] = {};
				}

				// Process matching
				if (!tags[key]) {
					tagObject.callback = callback;
				} else if ($.isPlainObject(tags[key])) {
					tagObject.callback = callback;
					tagObject.match = tags[key];
				} else {
					debugLog("cui.dataEvents.js: match value found that is not of a plain object.", obj, tags, callback);
					continue;
				}

				// Set tag object on tags object with object as key
				self.tags[arrTags[i]][obj.CUI_ID] = tagObject;

				// Set tag object on boundObject object with tag as key
				self.boundObjects[obj.CUI_ID][arrTags[i]] = tagObject;
			}
		}

	};

	this.unbind = function (obj, tags) {
		var self = this, key;
		if (!obj.CUI_ID) {
			return;
		}

		if (obj && tags) {
			if (self.boundObject && self.boundObject[obj.CUI_ID]) {
				for (key in tags) {
					var arrTags = tags.split(/\s+/);
					for (var i=0; i<arrTags.length; i++) {
						if (self.boundObjects[obj.CUI_ID][arrTags[i]]) {
							delete self.tags[arrTags[i]][obj.CUI_ID];
							delete self.boundObjects[obj.CUI_ID][arrTags[i]];

							var empty = true;
							for (var k in obj) {
								empty = false;
								break;
							}

							if (isObjectEmpty(self.tags[arrTags[i]])) {
								delete self.tags[arrTags[i]];
							}

							if (isObjectEmpty(self.boundObjects[obj.CUI_ID])) {
								delete self.boundObjects[obj.CUI_ID];
							}
						}
					}
				}
			}
		} else if (obj) {
			if (self.boundObjects && self.boundObjects[obj.CUI_ID]) {
				for (key in self.boundObjects[obj.CUI_ID]) {
					// Delete object from boundObjects and tags.
					delete self.tags[key][obj.CUI_ID];
					delete self.boundObjects[obj.CUI_ID][key];

					// Delete tag key from tags object if length == 0
					if (isObjectEmpty(self.tags[key])) {
						delete self.tags[key.CUI_ID];
					}

					// Delete bound object if length == 0
					if (isObjectEmpty(self.boundObjects[obj.CUI_ID])) {
						delete self.boundObjects[obj.CUI_ID];
						delete self.objectTrack[obj.CUI_ID];
					}
				}
			}
		}
	};

	this.initialize();
};
