register_screen('user_create', {
    elements: [
	{ entity_template: 'page_title', title: 'Add a Person' },
	{
	    entity_template: 'page_buttons',
	    buttons: [
		{ type: 'cancel_back' },
		{
		    type: 'create',
		    key: 'bbx_user_id',
		    detail: 'user_detail'
		}
	    ]
	},
	{ entity_template: 'page_content', elements: [
	    {
		entity_template: 'form',
		method: 'POST',
		action: '/gui/extension/user',
		no_initial_get: true,
		submit_all: true,
		always_dirty: true,
		elements: [
		    {
			entity_template: 'page_module',
			legend: 'Add a Person',
			buttons: [ 'help' ],
			help_id: 'add_new_person',
			create: {
			    detail: 'user',
			    params: [ 'bbx_extension_id', 'bbx_user_id' ],
			    key: 'bbx_user_id'
			},
			set_focus: true,
			elements: [
			    {
				entity_template: 'input_item',
				title: 'First Name:',
				type: 'text',
				input_name: 'bbx_contact_first_name',
				input_options: {
						widgets: ['aopbStateWidget'],
						attributes: { maxlength: '255' },
						widget_options: {
							aopbStateWidget: {
								states:[
									{
										conditions: [
											{             a: '$$', op: '!=', b: '_SAFE_CHARACTERS_' },
											{ join: 'or', a: '$$', op: '==', b: '(^\\W|\\W$)', b_is: 'regexp' }
										],
										actions: { invalid: 'A Valid First Name is required' },
										else_actions: { invalid: false }
									}
								]
							}
						}
					}
			    },
			    {
				entity_template: 'input_item',
				title: 'Last Name:',
				type: 'text',
				input_name: 'bbx_contact_last_name',
				input_options: {
						widgets: ['aopbStateWidget'],
						attributes: { maxlength: '255' },
						widget_options: {
							aopbStateWidget: {
								states:[
									{
										conditions: [
											{             a: '$$', op: '!=', b: '_SAFE_CHARACTERS_' },
											{ join: 'or', a: '$$', op: '==', b: '(^\\W|\\W$)', b_is: 'regexp' }
										],
										actions: { invalid: 'A Valid Last Name is required' },
										else_actions: { invalid: false }
									}
								]
							}
						}
					}
			    },
			    {
				entity_template: 'input_item',
				title: 'Voicemail/Login PIN:',
				type: 'text',
				input_name: 'bbx_user_pin',
				size: 5,
				input_options: {
				    widgets: ['aopbStateWidget'],
				    widget_options: {
					aopbStateWidget: {
					    states:[
						{
						    actions: { invalid: false }
						},
						{
						    conditions: { a: '$$', op: '==', b: '_EMPTY_' },
						    actions: { invalid: 'A PIN is required' },
						    last: true
						},
						{
						    conditions: { a: '$$', op: '!=', b: '^[0-9]{3}[0-9]+$', b_is: 'regexp' },
						    actions: { invalid: 'The PIN must be 4 or more digits, and may only contain digits' },
						    last: true
						}
					    ]
					}
				    }
				}
			    },
			    {
				entity_template: 'input_item',
				title: 'Add This Person to a Group:',
				type: 'checkbox',
				input_options: {
				    attributes: {
					'class': 'activate-group-checkbox'
				    }
				}
			    },
			    {
				entity_templates: ['data_item', 'aopb_state'],
				data_item: {
				    attributes: { hidden: 'hide-on-load' },
				    title: 'Choose a Group:',
				    elements: [
					{
					    entity_templates: ['extension_picker_select', 'aopb_state'],
					    extension_picker_select: {
						type: 'group',
						primary: 1,
						allow_nothing: false,
						name: 'bbx_group_id'
					    },
					    aopb_state: {
						closest: 'form',
						states: [{
						    conditions: { a: '.activate-group-checkbox', op: '==', b: '_TRUEISH_' },
						    actions: { enabled: true },
						    else_actions: { enabled: false }
						}]
					    }
					},
					{
					    entity: 'div',
					    text: 'Additional groups may be added and removed in the person' + entity.rsquo + 's detail screen, or in the detail screen for the relevant Group.',
					    attributes: { 'class' : 'info' }
					}
				    ]
				},
				aopb_state: {
				    closest: 'form',
				    states: [{
					conditions: { a: '.activate-group-checkbox', op: '==', b: '_TRUEISH_' },
					actions: { visible: true },
					else_actions: { visible: false }
				    }]
				}
			    }
	 		]
		    }, // END page module 1
		    {
			entity_template: 'page_module',
			legend: 'Extension/Phone',
			buttons: [ 'help' ],
			help_id: 'select_extension',
			elements: [
			    {
				entity_template: 'input_item',
				title: 'Extension Assignment:',
				type: 'select',
				select_options: [
				    { text: 'Select an Extension', value: 'EXTENSION' },
				    { text: 'Select an Unassigned Phone', value: 'PHONE' }
				],
				input_options: {
				    attributes: {
					'class': 'extension-type'
				    }
				}
			    },
			    {
				entity_template: 'aopb_container',
				closest: '.page_module',
				states: [
				    {
					conditions: { a: '.extension-type', op: '==', b: 'EXTENSION', b_is: 'literal' },
					elements: [
					    {
						entity_template:'extension_select',
						name: 'bbx_extension_value',
						allow: 'nextfree single'
					    }
					],
					last_if_true: true
				    },
				    {
					conditions: { a: '.extension-type', op: '==', b: 'PHONE', b_is: 'literal' },
					elements: [
					    {
						entity_templates: ['data_table', 'aopb_state'],
						data_table: {
						    attributes: { 'class': 'valid-check' },
						    rest: '/gui/extension/list',
						    rest_params: {
							primary: 1,
							type: 'phone',
							sortby: 'bbx_extension_value'
						    },
						    unique_id: 'bbx_extension_id',
						    oLanguage: {
							sZeroRecords: 'No Unassigned Phones Available'
						    },
						    return_selected: 'bbx_extension_value',
						    select_single: true,
						    click_action: 'select',
						    columns: [
							{
							    header: 'Ext.',
							    column: 'bbx_extension_value',
							    'class': 'extension-column'
							},
							{
							    header: 'Name',
							    column: 'show_name'
							}
						    ],
						    table_actions: {
						    }
						},
						aopb_state: {
						    states: [{
							conditions: {a: '$$', op: '!=', b: '_TRUEISH_' },
							actions: { invalid: 'Select a phone' },
							else_actions: { invalid: false }
						    }]
						}
					    }
					],
					last_if_true: true
				    }
				]
			    }
			]
		    }
		]
	    }
	]}
    ]
});
