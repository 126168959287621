/* jshint jquery: true, unused: vars */
/* global add_widget, trueish */
(function( $ ){
	var rowHighlightWidget = $.extend({}, $.ui.widget.prototype, {
		set_value_widget: true,

		options: {
			highlight_class: 'highlighted'
		},

		_afterInit: function() { },

		setValue: function(val, name) {
			var self = this;
			var $self = this.element;

			//debugLog('SetValue called on rowHighlightWidget: ', val, name);
			if (val != 'f' && val != 'false' && val != 'FALSE' && trueish(val)) {
				$self.closest('tr').addClass(self.options.highlight_class);
			} else {
				$self.closest('tr').removeClass(self.options.highlight_class);
			}
		},

		fillData: function(d) {
			var $self = this.element;
			var self = this;

			if (d && $self.attr('name') in d) {
				self.setValue(d[$self.attr('name')]);
			} else {
				self.setValue(0);
			}
		}

	});
	add_widget('rowHighlightWidget', rowHighlightWidget);
})(jQuery);
