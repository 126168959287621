/* jshint jquery: true */
/* global debugLog, CurrentUser */
/* exported isAllowed */
/*

isAllowed - Determine whether a user has a given permission or set of permissions. Supports boolean logic and multiple permissions.

RETURNS: Boolean true/false. If the test object or the permissions object are missing, true is returned by default.

USAGE:
BOOLEAN isAllowed(OBJECT test | STRING test, OPTIONAL OBJECT permissionsObject);

You may pass any object with the required "permission" key to this function-- only "permission", "op", and "mod" keys are looked at.
If, for some odd reason, your object contains an "op" or "mod" property that you don't want read by the permission parser, use the
root key "permissions" (with an "s") instead, and the op and mod will be ignored. You may also pass a string, which will check one
permission only. Permission names are case sensitive, however op and mod are not.

Single permission check:
isAllowed('PERMISSION_NAME');

Permission check within an object:
isAllowed({
    ...,
    permission: 'SOME_PERMISSION'
});

Check that a single permission does not exist:
isAllowed({
    permission: { mod: 'not', permission: 'DO_NOT_WANT' }
});

An array of strings in the "permission" object acts as a shorthand "OR" operation:
isAllowed({
    permission: ['THIS', 'OR_THIS', 'OR_THIS']
});

An array of objects allows making "AND" and "OR" operations. Use the "op" key for and/or ("or" is default). Use "mod":"not" to invert
the check:

isAllowed({
    permission: [
	{ permission: 'THIS' },
	{ permission: 'OR_THIS' },
	{ op: 'or', permission: 'OR_EVEN_THIS' },
	{ op: 'and', permission: 'AND_THIS' },
	{ op: 'and', mod: 'not', permission: 'AND_THE_LACK_OF_THIS' }
    ]
});

Nesting is allowed for complex permission sets:
isAllowed({
    permission: [
	{ permission: 'THIS' },
	{ op: 'or', permission: [
	    { permission: 'OR_A_COMBINATION_OF_THIS' },
	    { op: 'and', permission: 'AND_THIS' },
	    { op: 'and', mod: 'not', permission: 'AND_NOT_THIS' }
	]}
    ]
};

The second parameter (OPTIONAL OBJECT permissionObject) is an object with the user's permissions, structured like the CurrentUser object.
Usually, this can be omitted, and the global CurrentUser object will be used if it is set.

{
   ...
   permissions: {
      PERMISSION_NAME: 0 | 1,
      ...
   }
}


*/

function isAllowed(obj, userPerm) {
	var state = false;

	// If we pass in a string, make it an object.
	// Arrays also typeof as "object", so this won't alter arrays or objects
	if (typeof obj !== 'object') {
		obj = { permission: obj };
	}

	// Edge case-- if you have keys called "op" or "mod" in your root object that don't relate to the permission,
	// make the permission root key "permissions" with an "s" and only the "permissions" key will be parsed.
	if (obj.permissions && !obj.permission) {
		obj = { permission: obj.permissions };
	}

	// If there aren't any permissions on the object, everything is allowed.
	if (!obj.permission) {
		return true;
	}

	// Be nice and support non-wrapped single permission objects -- turn them into an array with a basic permissions object.
	// (Make a new object, don't modify the real one-- that's rude)
	if ($.isPlainObject(obj.permission)) {
		obj = {
			permission: [obj.permission],
			op: obj.op || undefined,
			mod: obj.mod || undefined
		};
	}

	// Did we get a userPerm object? Fall back to CurrentUser.permissions if not. Fail utterly if that doesn't exist.
	if (!userPerm && typeof CurrentUser === 'object' && CurrentUser.permissions) {
		userPerm = CurrentUser.permissions;
	} else if (!userPerm) {
		debugLog('isAllowed.js: Cannot check permissions without a user permissions object. CurrentUser.permissions also did not exist. Permission check impossible, assuming success.');
		return true;
	}

	if ($.isArray(obj.permission)) {
		for (var p_ct=0; p_ct<obj.permission.length; p_ct++) {
			var p_obj = obj.permission[p_ct];

			if (typeof p_obj === 'string') {
				// If the array item is just a string, it's the shorthand form of a basic permission object. Expand (normalize) it to an object we can use.
				p_obj = { permission: p_obj };
			}

			var op = (p_obj.op || 'or').toLowerCase(); // "and"/"or"
			var mod = (p_obj.mod || '').toLowerCase();  // "not" is the only "mod" supported

			var this_state = isAllowed(p_obj.permission, userPerm);

			if (mod) {
				switch (mod) {
					case 'not':
						this_state = !this_state;
						break;
				}
			}

			switch (op) {
				case 'and':
					state = state && this_state;
					break;
				case 'or': // jshint ignore:line
					//            (Intentional fallthrough)
				default:
					state = state || this_state;
					break;
			}
		}
	} else if (typeof obj.permission === 'string') {
		state = !!userPerm[obj.permission];
	}

	return state;
}
