/* jshint jquery: true, unused: vars */
/* global add_widget, debugLog */
/*
  Fills another form field with the result of a request.

  USAGE:

  fillDataButtonWidget({
    closest: 'form', // jQuery selector, finds the root object to search for the input(s)
    field_map: [
      'bbx_field_name', // If the NAME of the form field is the same as the data key
      { 'bbx_original_field_name' : 'target_field_name' } // To use the data key in a different NAMEd form field
    ],
    rest_container: 'rest_container_name'
*/

(function( $ ){
    var fillDataButtonWidget = $.extend({}, $.ui.actionButtonWidget.prototype, {
	options: {
	    fields: [],
	    closest: 'form'
	},

	_success: function (d) {
	    var self = this, $self = $(this.element);

	    self.options.data = d;

	    var $closest = $self.closest(self.options.closest || 'form');
	    if (!$closest[0]) {
		debugLog('jquery.fillDataButtonWidget.js: Bad "closest" parameter "', self.options.closest, '" -- ', $self);
		return;
	    }

	    for (var i in self.options.field_map) {
		var field = self.options.field_map[i];
		var mapKey, mapVal;

		if (typeof field === 'string') {
		    mapKey = field;
		    mapVal = field;
		} else if ($.isPlainObject(field)) {
		    for (var k in field) {
			if (field.hasOwnProperty(k)) {
			    mapKey = field[k];
			    mapVal = k;
			    break;
			}
		    }
		}

		var data = (self.options.rest_container && d[self.options.rest_container]) ? d[self.options.rest_container] : d;
		var $fields = $closest.find('[name=' + mapKey + ']');

		$fields.each(function (idx, field) {
		    var $field = $(field);
		    var widget = $field.getCUIWidget();
		    if (data[mapVal] !== null && data[mapVal] !== undefined ) {
			if (widget && widget.setValue) {
			    widget.setValue(data[mapVal]);
			} else {
			    $field.val(mapVal);
			}
		    }
		});

	    }
	    // Send it to the superclass _success...
	    return $.ui.actionButtonWidget.prototype._success.apply(this, arguments);
	}
    });

    add_widget('fillDataButtonWidget', fillDataButtonWidget);
})(jQuery);
