(function () {
    var sites_subnet_table = {
	entity_template: 'data_table',
	rest_container: 'ip', /* BNPH-7101 -- Need this because no global rest action is set for widget */
	add_edit_action: {
	    rest: '/gui/acl/ip',
	    method: 'POST',
	    include_data_from_parent: ['bbx_acl_id']
	},
	delete_action: {
	    rest: '/gui/acl/ip'
	},
	extra_row_data: [ 'bbx_acl_ip_id', 'bbx_acl_id', 'bbx_site_id' ],
	dataEvents: { trigger: 'sites_acl_ip' },
	paginate:      false,
	length_change: false,
	filtering:     false,
	processing:    false,
	sortable:      false,
	allow_scroll:  false,
	accept_data_from_parent: true,
	data_source: 'bbx_acl_ips',
	primary_keys: ['bbx_acl_ip_id'],
	columns: [
	    {
		header: 'Network IP',
		column: 'bbx_acl_ip_ip',
		width: '20px',
		add_element: {
		    entity: 'input',
		    attributes: {
			type: 'text',
			name: 'bbx_acl_ip_ip'
		    },
		    widgets: ['aopbStateWidget' ],
		    widget_options: {
			aopbStateWidget: {
			    closest: 'td',
			    states: [
				{
				    conditions: [
					{ a: '$$', op: '==', b: '_IP_V4_' },
					{ join: 'and', a: '$$', op: '!=', b: '^0+\.0+\.0+\.0+$', b_is: 'regexp' }
				    ],
				    actions: { invalid: false },
				    else_actions: { invalid: 'Invalid IP Address.' }
				}
			    ]
			}
		    }
		}
	    },
	    {
		header: 'Subnet Mask',
		column: 'bbx_acl_ip_mask',
		width: '20px',
		add_element: {
		    entity: 'input',
		    attributes: {
			type: 'text',
			name: 'bbx_acl_ip_mask'
		    },
		    widgets: ['aopbStateWidget' ],
		    widget_options: {
			aopbStateWidget: {
			    closest: 'td',
			    states: [
				{
				    conditions: { a: '$$', op: '==', b: '_IP_V4_MASK_' },
				    actions: { invalid: false },
				    else_actions: { invalid: 'Invalid Subnet Mask.' }
				}
			    ]
			}
		    }
		}
	    }
	],
	oLanguage: {
	    sZeroRecords: 'No Ranges Configured'
	},
	row_actions: {
	    animate: false,
	    type: 'expand',
	    edit_save: { text: 'Save' },
	    edit_cancel: { text: 'Cancel' },
	    add_save: { text: 'Add' },
	    add_cancel: { text: 'Cancel' },
	    delete_row: { text: 'Delete IP' }
	},
	table_actions: {
	    add_row: {
		text: 'Add Range',
		location: "bottom"
	    },

	    delete_rows: {
		text: 'Remove Range',
		location: "bottom",
		confirm: {
		    title: 'Remove Selected Ranges',
		    text: 'Are you sure you wish to remove these ranges?'
		}
	    },
	    select_row: true,
	    select_all_rows: true
	}
    };


    var inbound_registration_info = [
	{
	    entity_template: 'container',
	    rest: '/gui/system/network',
	    elements: [
		{
		    entity_template: 'input_item',
		    type: 'text',
		    input_options: {
			attributes: { readonly: 'readonly', 'class': 'copy-helper-textbox' }
		    },
		    title: 'Host:',
		    input_name: 'system_ip',
		    accept_data_from_parent: true
		},
		{
		    entity_template: 'input_item',
		    type: 'text',
		    input_options: {
			attributes: { readonly: 'readonly', 'class': 'copy-helper-textbox' }
		    },
		    input_name: 'external_inet_ip',
		    title: 'Outside LAN:',
		    accept_data_from_parent: true
		}
	    ]
	},
	{
	    entity_template: 'data_item',
	    title: 'Port:',
	    elements: [
		{
		    entity: 'input',
		    attributes: { readonly: 'readonly', 'class': 'copy-helper-textbox', type: 'text', value: '5065' }
		}
	    ]
	},
	{
	    entity_template: 'input_item',
	    type: 'text',
	    input_options: {
		attributes: { readonly: 'readonly', 'class': 'copy-helper-textbox' }
	    },
	    title: 'Username:',
	    input_name: 'bbx_registration_username'
	},
	{
	    entity_template: 'input_item',
	    type: 'text',
	    input_options: {
		attributes: { readonly: 'readonly', 'class': 'copy-helper-textbox' }
	    },
	    title: 'Password:',
	    input_name: 'bbx_registration_password'
	}
    ];


    register_screen('sites', {
	elements: [
	    { entity_template: 'page_title', title: 'Sites' },
	    {
		entity_template: 'page_buttons',
		buttons: [{ type: 'help', help_id: 'sites' }]
	    },
	    {
		entity_template: 'page_content', elements: [
		    {
			entity_template: 'data_table',
			click_action: "edit",
			rest: '/gui/site/site',
			extra_row_data: [
			    'bbx_acl_id',
			    'bbx_acl_ips',
			    'bbx_provider_gateway_id',
			    'bbx_tdm_card_port_id',
			    'bbx_phone_registration_id',
			    'bbx_site_id',
			    'bbx_registration_username',
			    'bbx_registration_password',
			    'bbx_acl_auth',
			    'bbx_site_timezone'
			],
			add_edit_action: { rest: '/gui/site/site' },
			delete_action: { rest: '/gui/site/site' },
			primary_keys: [ 'bbx_site_id' ],
			dataEvents: {
			    bind: {
				'sites_acl_ip' : ''
			    }
			},
			oLanguage: {
			    sZeroRecords: 'No Sites Configured'
			},
			paginate:      false,
			length_change: false,
			filtering:     false,
			processing:    false,
			sortable:      false,
			allow_scroll:  false,
			columns: [
			    {
				header: 'Name',
				width: 150,
				column: 'bbx_site_name',
				sortable: true,
				add_element: {
				    entity: 'input',
				    attributes: {
					name: 'bbx_site_name',
					type: 'text'
				    },
				    widgets: ['aopbStateWidget'],
				    widget_options: {
					aopbStateWidget: {
					    closest: 'tr',
					    states: [
						{
						    conditions: { a:'$$', op: '==', b:'' },
						    actions: { invalid: 'Please enter a site name' },
						    else_actions: { invalid: false }
						}
					    ]
					}
				    }
				},
				edit_element: {
				    entity: 'input',
				    attributes: {
					name: 'bbx_site_name',
					type: 'text'
				    },
				    widgets: ['aopbStateWidget'],
				    widget_options: {
					aopbStateWidget: {
					    closest: 'tr',
					    states: [
						{
						    conditions: { a:'$$', op: '==', b:'' },
						    actions: { invalid: 'Please enter a site name' },
						    else_actions: { invalid: false }
						}
					    ]
					}
				    }
				}
			    },
			    {
				header: 'Source Type',
				width: 150,
				sortable: true,
				view_element: {
				    entity_template: 'widget_div',
				    widgets: ['aopbStateWidget'],
				    widget_options: {
					aopbStateWidget: {
					    closest: 'td',
					    states: [
						{
						    conditions: {a: '@bbx_acl_id', op: '==', b: '_TRUE_' },
						    actions: { text: 'Subnet' },
						    last_if_true: true
						},
						{
						    conditions: [
							{ a: '@bbx_provider_gateway_id', op: '==', b: '_TRUE_' },
							{ join: 'or', a: '@bbx_tdm_card_port_id', op: '==', b: '_TRUE_' }
						    ],
						    actions: { text: 'Provider/Port' },
						    last_if_true: true
						},
						{
						    conditions: { a: '@bbx_phone_registration_id', op: '==', b: '_TRUE_' },
						    actions: { text: 'Inbound Authorization' },
						    else_actions: { text: 'Invalid Source' }
						}
					    ]
					}
				    }
				},
				edit_element: {
				    entity_template: 'aopb_state',
				    closest: 'td',
				    states: [
					{
					    conditions: {a: '@bbx_acl_id', op: '==', b: '_TRUE_' },
					    actions: { text: 'Subnet' },
					    last_if_true: true
					},
					{
					    conditions: [
						{ a: '@bbx_provider_gateway_id', op: '==', b: '_TRUE_' },
						{ join: 'or', a: '@bbx_tdm_card_port_id', op: '==', b: '_TRUE_' }
					    ],
					    actions: { text: 'Provider/Port' },
					    last_if_true: true
					},
					{
					    conditions: { a: '@bbx_phone_registration_id', op: '==', b: '_TRUE_' },
					    actions: { text: 'Inbound Authorization' },
					    else_actions: { text: 'Invalid Source' }
					}
				    ]
				},
				add_element: {
				    entity: 'select',
				    attributes: { 'class': 'source-type-add' },
				    select_options: [
					{ value: 'acl', text: 'Subnet/IP address' },
					{ value: 'gateway', text: 'Provider/Port' },
					{ value: 'registration', text: 'Inbound Registration' }
				    ]
				}
			    },
			    {
				header: 'Source',
				sortable: true,
				view_element: {
				    entity_template: 'aopb_container',
				    closest: 'td',
				    states: [
					{
					    conditions: {a: '@bbx_acl_id', op: '==', b: '_TRUE_' },
					    elements: [
						sites_subnet_table,
						{
						    entity_template: 'translate',
						    attributes: { 'class': 'fg-toolbar ui-toolbar ui-widget-header ui-corner-tl ui-corner-tr ui-corner-bl ui-corner-br ui-helper-clearfix sites-ip-auth' },
						    name: 'bbx_acl_auth',
						    translations: {
							1: 'These IPs are automatically authorized',
							0: 'These IPs are not automatically authorized'
						    }
	 					}
					    ]
					},
					{
					    conditions: { a: '@bbx_phone_registration_id', op: '==', b: '_TRUE_' },
					    elements: inbound_registration_info
					},
					{
					    conditions: { a: '@bbx_provider_gateway_id', op: '==', b: '_TRUE_' },
					    elements: [
						{
						    enity: 'div',
						    widgets: ['lookupWidget', 'containerWidget'],
						    widget_options: {
							lookupWidget: {
							    search_rest: '/gui/gateway/sip',
							    search_keys: ['bbx_provider_gateway_id']
							},
							containerWidget: {
							    elements: [
								{
								    entity_template: 'widget_div',
								    name: 'bbx_provider_gateway_name'
								}
							    ]
							}
						    }
						}
					    ]
					},
					{
					    conditions: { a: '@bbx_tdm_card_port_id', op: '==', b: '_TRUE_' },
					    elements: [
						{
						    entity: 'div',
						    widgets: ['lookupWidget', 'containerWidget'],
						    widget_options: {
							lookupWidget: {
							    search_rest: '/gui/gateway/hardware',
							    search_keys: ['bbx_tdm_card_port_id']
							},
							containerWidget: {
							    elements: [
								{
								    entity_template: 'widget_div',
								    name: 'bbx_tdm_card_port_name'
								}
							    ]
							}
						    }
						}
					    ]
					}
				    ]
				},
				edit_element: {
				    entity_template: 'aopb_container',
				    closest: 'td',
				    states: [
					{
					    conditions: { a: '@bbx_acl_id', op: '==', b: '_TRUE_' },
					    last_if_true: true,
					    elements: [
						sites_subnet_table,
						{
						    entity_template: 'label',
						    attributes: { 'class': 'fg-toolbar ui-toolbar ui-widget-header ui-corner-tl ui-corner-tr ui-corner-bl ui-corner-br ui-helper-clearfix sites-ip-auth' },
						    elements: [
							{
							    entity: 'span',
							    text: 'Automatically Authorize these IP Ranges:'
							},
							{
							    entity: 'input',
							    attributes: { name: 'bbx_acl_auth', type: 'checkbox' }
							}
						    ]
						}
					    ]
					},
					{
					    conditions: { a: '@bbx_phone_registration_id', op: '==', b: '_TRUE_' },
					    elements: inbound_registration_info,
					    last_if_true: true
					},
					{
					    condtions: [
						{ a: '@bbx_provider_gateway_id', op: '==', b: '_TRUE_' },
						{ join: 'or', a: '@bbx_tdm_card_port_id', op: '==', b: '_TRUE_' }
					    ],
					    elements: [
						{
						    entity: 'select',
						    widgets: ['aopbSelectPopulateWidget'],
						    attributes : { name : 'bbx_provider_gateway_name' },
						    widget_options: {
							aopbSelectPopulateWidget: {
							    rest: '/gui/gateway/combined',
							    parsers: [
								{
								    conditions: [{ a: '@bbx_provider_gateway_id', op: '==', b: '_TRUE_' }],
								    option: {
									name: 'bbx_provider_gateway_id',
									text: '@bbx_provider_gateway_name',
									value: '@bbx_provider_gateway_id'
								    },
								    else_option: {
									name: 'bbx_tdm_card_port_id',
									text: '@bbx_tdm_card_port_name',
									value: '@bbx_tdm_card_port_id'
								    }
								}
							    ]
							}
						    }
						}
					    ],
					    last_if_true: true
					}
				    ]
				},
				add_element: {
				    entity_template: 'switch',
				    closest: 'tr',
				    selector: '.source-type-add',
				    switch_elements: {
					'acl': {
					    entity_template: 'container',
					    elements: [
						{
						    entity: 'div',
						    text: 'IP addresses can be added after site creation'
						},
						{
						    entity: 'input',
						    attributes: {
							type: 'text',
							hidden: true,
							name: 'ip_acl',
							value: '1'
						    }
						}
					    ]
					},
					'gateway': {
					    entity: 'select',
					    widgets: ['aopbSelectPopulateWidget'],
					    attributes : { name : 'bbx_provider_gateway_name' },
					    widget_options: {
						aopbSelectPopulateWidget: {
						    rest: '/gui/gateway/combined',
						    parsers: [
							{
							    conditions: { a: '@bbx_provider_gateway_id', op: '==', b: '_TRUE_' },
							    option: {
								name: 'bbx_provider_gateway_id',
								text: '@bbx_provider_gateway_name',
								value: '@bbx_provider_gateway_id'
							    },
							    else_option: {
								name: 'bbx_tdm_card_port_id',
								text: '@bbx_tdm_card_port_name',
								value: '@bbx_tdm_card_port_id'
							    }
							}
						    ]
						}
					    }
					},
					'registration': {
					    entity_template: 'container',
					    elements: [
						{
						    entity: 'div',
						    text: 'Login information will be shown after site creation.'
						},
						{
						    entity: 'input',
						    attributes: {
							type: 'text',
							hidden: true,
							name: 'inbound_auth',
							value: '1'
						    }
						}
					    ]
					}
				    }
				}
			    },
			    {
				header: 'Time Zone',
				width: 250,
				view_element: {
				    entity_template: 'aopb_container',
				    rest: '/gui/system/timezones',
				    rest_container: false,
				    closest: 'td',
				    states: [
					{
					    conditions: [
						{ a: '@bbx_site_timezone', op: '==', b: '' },
						{ join: 'or', a: '@bbx_site_timezone', op: '==', b: '_NULL_' }
					    ],
					    elements: [
						{
						    entity_template: 'format',
						    format: 'System Default ($1)',
						    fields: [ { field: 'timezone' } ]
						}
					    ],
					    else_elements: [
						{
						    entity_template: 'widget_div',
						    attributes: { name: 'bbx_site_timezone' }
						}
					    ]
					}
				    ]
				},
				add_element: {
				    entity_template: 'select_populate',
				    name: 'bbx_site_timezone',
				    rest: '/gui/system/timezones',
				    before: [ { text: 'System Default', value: '' } ]
				},
				edit_element: {
				    entity_template: 'select_populate',
				    name: 'bbx_site_timezone',
				    rest: '/gui/system/timezones',
				    before: [ { text: 'System Default', value: '' } ]
				}
			    }
			],
			row_actions: {
			    animate: false,
			    type: 'expand',
			    edit_save: { text: 'Save' },
			    edit_cancel: { text: 'Cancel' },
			    add_save: { text: 'Add' },
			    add_cancel: { text: 'Cancel' },
			    delete_row: { text: 'Delete Site' }
			},
			table_actions: {
			    add_row: {
				text: 'Add Site',
				location: 'both'
			    },

			    delete_rows: {
				text: 'Remove Sites',
				location: 'both',
				confirm: {
				    title: 'Remove Selected Sites',
				    text: 'Are you sure you wish to remove these sites?'
				}
			    },

			    select_row: true,
			    select_all_rows: true
			}
		    }
		] // END page_content elements
	    } // END page_content
	] // END template elements
    });
})();
