(function () {
    var ALARM_INPUT_OPTIONS = {
	widgets: ['translateWidget'],
	widget_options: { translations: {
	    ON: { text: 'Alarm', css: {
		display: 'inline-block',
		padding: '0 1em',
		'background-color' : '#d00',
		color: '#fff'
	    } },
	    OFF: { text: 'No Alarm', css: { color: '#060' } }
	}}
    };
    
    register_screen('tdm_troubleshooting', {
	elements: [
	    {
		entity: 'h2',
		'class': 'page_title',
		entity_template: 'format',
		format: 'Troubleshooting Information ' + entity.mdash + ' $1',
		fields: [{ field: 'bbx_tdm_card_port_name' }],
		rest: '/gui/gateway/pri_status'
	    },
	    { entity_template: 'page_buttons', buttons: [ { type: 'up', text: 'Back to Port Configuration' } ] },
	    {
		entity_template: "page_content", elements: [
		    {
			entity_template: 'data_poller',
			rest: '/gui/gateway/pri_status',
			rest_container: 'pri_status',
			elements: [
			    {
				entity_template: 'page_module',
				legend: 'Connection Information',
				buttons: ['help'],
				help_id: 'port_troubleshooting',
				elements: [
				    {
					entity_template: 'input_item',
					input_name: 'total_rx_packets',
					type: 'div',
					title: 'Total rx packets:'
				    },
				    {
					entity_template: 'input_item',
					input_name: 'total_tx_packets',
					type: 'div',
					title: 'Total tx packets:'
				    },
				    {
					entity_template: 'input_item',
					input_name: 'total_rx_bytes',
					type: 'div',
					title: 'Total rx bytes:'
				    },
				    {
					entity_template: 'input_item',
					input_name: 'total_tx_bytes',
					type: 'div',
					title: 'Total tx bytes:'
				    },
				    {
					entity_template: 'input_item',
					input_name: 'bad_rx_packets',
					type: 'div',
					title: 'Bad rx packets:'
				    },
				    {
					entity_template: 'input_item',
					input_name: 'packet_tx_problems',
					type: 'div',
					title: 'Packet tx problems:'
				    },
				    {
					entity_template: 'input_item',
					input_name: 'rx_frames_dropped',
					type: 'div',
					title: 'Rx frames dropped:'
				    },
				    {
					entity_template: 'input_item',
					input_name: 'tx_frames_dropped',
					type: 'div',
					title: 'Tx frames dropped:'
				    },
				    {
					entity_template: 'input_item',
					input_name: 'multicast_rx_packets',
					type: 'div',
					title: 'Multicast rx packets:'
				    },
				    {
					entity_template: 'input_item',
					input_name: 'tx_collisions',
					type: 'div',
					title: 'Tx collisions:'
				    },
				    {
					entity_template: 'input_item',
					input_name: 'rx_length_errors',
					type: 'div',
					title: 'Rx length errors:'
				    },
				    {
					entity_template: 'input_item',
					input_name: 'rx_overrun_errors',
					type: 'div',
					title: 'Rx overrun errors:'
				    },
				    {
					entity_template: 'input_item',
					input_name: 'crc_errors',
					type: 'div',
					title: 'CRC errors:'
				    },
				    {
					entity_template: 'input_item',
					input_name: 'abort_frames',
					type: 'div',
					title: 'Abort frames:'
				    },
				    {
					entity_template: 'input_item',
					input_name: 'rx_fifo_overrun',
					type: 'div',
					title: 'Rx fifo overrun:'
				    },
				    {
					entity_template: 'input_item',
					input_name: 'rx_missed_packet',
					type: 'div',
					title: 'Rx missed packet:'
				    }
				]
			    },
			    {
				entity_template: 'page_module',
				legend: 'T1 Alarms',
				elements: [
				    {
					entity_template: 'input_item',
					input_name: 'alos',
					type: 'div',
					title: 'ALOS (Loss of Signal):',
					input_options: ALARM_INPUT_OPTIONS
				    },
				    {
					entity_template: 'input_item',
					input_name: 'los',
					type: 'div',
					title: 'LOS (Loss of Signal):',
					input_options: ALARM_INPUT_OPTIONS
				    },
				    {
					entity_template: 'input_item',
					input_name: 'lof',
					type: 'div',
					title: 'LOF (Loss of Framing):',
					input_options: ALARM_INPUT_OPTIONS
				    },
				    {
					entity_template: 'input_item',
					input_name: 'red',
					type: 'div',
					title: 'Red (Local Alarm):',
					input_options: ALARM_INPUT_OPTIONS
				    },
				    {
					entity_template: 'input_item',
					input_name: 'ais',
					type: 'div',
					title: 'AIS/Blue (Intermediate Alarm):',
					input_options: ALARM_INPUT_OPTIONS
				    },
				    {
					entity_template: 'input_item',
					input_name: 'rai',
					type: 'div',
					title: 'RAI (Remote Alarm):',
					input_options: ALARM_INPUT_OPTIONS
				    }
				]
			    },
			    {
				entity_template: 'page_module',
				legend: 'Performance Monitor (PMON) Counters',
				elements: [
				    {
					entity_template: 'input_item',
					input_name: 'line_code_violation',
					type: 'div',
					title: 'Line Code Violation:'
				    },
				    {
					entity_template: 'input_item',
					input_name: 'bit_errors',
					type: 'div',
					title: 'Bit Errors:'
				    },
				    {
					entity_template: 'input_item',
					input_name: 'out_of_frame_errors',
					type: 'div',
					title: 'Out of Frame Errors:'
				    }
				]
			    }
			]
		    }
		]
	    }
	]
    });
})();
