/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, Highcharts */
/*
 * <div class="widgetType piechartWidget" data-js='{
 *     "live_table" : "system_stats",
 *     "key_name" : "cpu_usage",
 *     "key_column" : "stat_name",
 *     "value_column" : "stat_value"
 * }' ></div>
 *
 */

(function( $ ){
	var piechartWidget = $.extend({}, $.ui.widget.prototype, CUI.dataTableClass, {
		set_value_widget: true,

		options: {
			title: 'pieChart',
			units: '%',
			background: 'transparent',

			name_key: 'name',
			value_key: 'value',
			default_value: 0,
			items: [],
			_items: {},
			_values: 0,
			_$empty_chart: false
		},

		_beforeInit: function () {
			var $self = this.element, self = this, i_idx;
			$self.addClass('pieChartWidgetType');

			if (self.options.live_table) {
				self.options.native_live_table = true;
			}

			for (i_idx = 0; i_idx < self.options.items.length; i_idx++) {
				self.options._items[ self.options.items[i_idx][self.options.name_key] ] = self.options.default_value;
			}

			self._dataTableInit();
			self._setupDataSource();
			self._buildColumnIndexMap();

			self.options._auto_update_size_interval = self._setInterval(self._autoUpdateSize.bind(self), 250);
		},

		_autoUpdateSize: function () {
			var self = this, $self = this.element, wh, last_wh;

			if (self.options.destroyed || !self.options.chart) {
				return;
			}

			last_wh = self.options._last_wh = self.options._last_wh || [undefined, undefined];
			wh = [$self.innerWidth(), $self.innerHeight()];

			if (wh[0] === last_wh[0] && wh[1] === last_wh[1]) { return; }
			self.options._last_wh = wh;

			self.options.chart.setSize(wh[0], wh[1]);
			
			$self.hide();
			setTimeout(function () { $self.show(); }, 10);
		},

		_afterInit: function() {
			var $self = this.element, self = this;

			// Correct? to check for data exists and is defined.
			if (self.options.values) {
				self._redrawChart();
			}
		},

		_afterAddRow: function (data, index) {
			this._updateData(data);
		},

		_afterRowUpdate: function (index, data) {
			this._updateData(data);
		},

		_updateData: function (data) {
			var self = this, $self = this.element, key = data[self.options.name_key];
			if (key in self.options._items) {
				self.options._items[ key ] = data[ self.options.value_key ];
			}

			self._redrawChart();
		},

		/*
	_afterDeleteRow: function (index) {
	    // Do we actually need to do anything here? We're working off key-value pairs
	},
	*/

		fillData: function(d) {
			var self = this, $self = this.element;

			return;
		},

		setValue: function(key, v) {
			var self = this, $self = this.element, row;
			/*
	   * Update single piece of the pie, or multiple pieces of the pie.
	   *
	   */

			for (row in self.options.values) {
				if (self.options.values[row].key == key ) {
					self.options.values[row].y = parseInt(v);
					break;
				}
			}
		},

		_redrawChart: function() {
			var self = this, $self = this.element, table_values = [], row, row_data = {}, chart_options, plot_pie_options, sec_row;

			if (self.options.data && self.options.data.live) {
				/*
		 * Using liveTable data.
		 */
				self.options.data.live.sort(function(a, b) { return a.id-b.id; });
				for ( row in self.options.data.live) {
					if ( self.options.primary_fields[self.options.data.live[row].name] && parseInt(self.options.data.live[row].value) > 0 ) {
						self.options._values += parseInt(self.options.data.live[row].value);
						row_data = { name: self.options.labels[self.options.data.live[row].name],
									y: parseInt(self.options.data.live[row].value) };

						if (self.options.secondary_fields && self.options.secondary_fields[self.options.data.live[row].name] ) {
							for ( sec_row in self.options.data.live) {
								if ( self.options.data.live[sec_row].name === self.options.secondary_fields[self.options.data.live[row].name]){
									self.options._values += parseInt(self.options.data.live[sec_row].value);
									row_data.secondary = self.options.data.live[sec_row].value;
									break;
								}
							}
						}
						if (self.options._values === 0) {
							// table_values = [{ name: 'empty', y: 1 }];
						} else {
							if (table_values) {
								table_values.push(row_data);
							} else {
								table_values = [row_data];
							}
						}
					}
				}
			} else {
				/*
		 * Using non liveTable data.
		 */
			}
			if (!table_values.length) {
				self.options._$empty_table = $('<span class="empty-pie-chart">No Unread/Read Voicemails</span>');
				//		table_values = [{ "name": "empty", "y": 1 }];
			} else if (self.options._$empty_table) {
				$self.find('span.empty-pie-chart').remove();
				self.options._$empty_table = false;
			}

			/*
	     * Confirm that any values are Ints
	     */
			if (self.options.values) {
				for ( row in self.options.values ) {
					if (parseInt(self.options.values[row].y) > 0) {
						table_values = self.options.values;
					}
				}
			}

			chart_options = {
				renderTo: $self[0],
				backgroundColor: self.options.background,
				plotBackgroundColor: 'transparent',
				plotBorderColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				animation: false
			};
			if (self.options.chart_options) {
				$.extend(chart_options, self.options.chart_options);
			}

			plot_pie_options = {
				allowPointSelect: false,
				dataLabels: {
					enabled: false
				},
				showInLegend: true,
				animation: false,
				borderColor: "black",
				borderWidth: 1
			};
			if (self.options.plot_pie_options) {
				$.extend(plot_pie_options, self.options.plot_pie_options);
			}

			if (self.options._$empty_table && !($self.find('span.empty-pie-chart')[0]) ) {
				$self.append(self.options._$empty_table);
			}
			if (table_values.length) {
				self.options.chart = new Highcharts.Chart({
					chart: chart_options,
					credits: {
						enabled: false
					},
					title: {
						text: self.options.title
					},
					tooltip: {
						formatter: CUI.FunctionFactory.build(self._formatPieTooltip, self, { context: 'argument', first: 'context' })
					},
					plotOptions: {
						pie: plot_pie_options
					},
					series: [{
						type: 'pie',
						name: self.options.title,
						data: table_values
					}]
				});
			}
		},

		_formatPieTooltip: function (pie) {
			var self = this;
			if (pie.point.secondary) {
				return '<b>' + pie.point.name + '</b><br>' + pie.y + ' ' + self.options.unit + ',<br>'+ pie.point.secondary + ' ' + self.options.secondary_unit;
			} else {
				return '<b>' + pie.point.name +'</b>:<br>'+ pie.y + ' ' + self.options.unit;
			}
		}

	});
	add_widget('piechartWidget', piechartWidget);

})(jQuery);
