/* jshint jquery: true, unused: vars */
/* global add_widget */
/* ***************************************************************

listGenerationWIdget:
   Generates a list of during fillData based on an set of possible data keys.  The keys are objects containing the title for the key

   options: {
     data: {},
     data_items: {
       'possible_data_key1': { title: 'key description' },
       'possible_data_key2': { title: 'key_description' },
	...
     }

*****************************************************************/

(function( $ ){
    var listGenerationWidget = $.extend(true, {}, $.ui.widget.prototype, {
	options: {
	    data: {},
	    data_items: {}
	},

	fillData: function (d) {
	    var self = this, $self = this.element;

	    for ( var key in self.options.data_items ) {
		if ( d.hasOwnProperty(key) ) {
		    if (self.options.data_items[key].type && self.options.data_items[key].type === 'boolean') {
			self.options.data[key] = d[key] == '1' ? 'Yes' : 'No';
		    } else {
			self.options.data[key] = d[key]; }
		}
	    }

	    self._generateDataItems();
	},

	_generateDataItems: function () {
	    var self = this, $self = this.element;
	    var fragment = document.createDocumentFragment();

	    for (var item in self.options.data) {
		var dt = document.createElement('dt');
		var text = false;
		if (self.options.data_items[item].title) {
		    text = document.createTextNode(self.options.data_items[item].title);
		    dt.appendChild(text);
		} else {
		    dt.innerHTML = '&nbsp;';
		}
		fragment.appendChild(dt);
		var dd = document.createElement('dd');
		text = document.createTextNode(self.options.data[item]);
		dd.appendChild(text);
		fragment.appendChild(dd);
	    }

	    $self[0].appendChild(fragment);
	    self.options.$dd_list = fragment;
	}
    });

    add_widget('listGenerationWidget', listGenerationWidget);
})(jQuery);
