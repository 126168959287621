/* global CUI, classCUI */
// REQUIRES: CUI.objectKeys.js

(function () {
	var getType = function (obj) {
		// "Object.prototype.toString.call(thing)" is a more verbose alternative to "typeof thing"--
		return (obj === null) ? '[object Null]' : Object.prototype.toString.call(obj);
	};

	var DEFAULT_TTL = 5;

	// Compares objects or simple types by value. The only exceptions:
	// - Functions/methods: If functions' properties do not differ, they are considered equal. Functions with properties (i.e., using a function as a class
	//   base) may differ, if the non-function properties or any property names differ.
	// 
	// Params:
	//    loose_compare_basics:     false | true // - Compare basic types (non-Object/Array) with == vs. ===
	//    null_equals_blank:        false | true // - Whether a null value is considered equal to an empty string
	//    empty_array_equals_blank: false | true // - Whetehr an empty array is considered equal to an empty string
	//    empty_string_equals_zero: false | true // - Whether an empty string is equal to 0
	//
	// Returns true if the objects are similar, false if different.

	classCUI.prototype.compareObjects = function (a, b, params, ttl) {
		var a_keys, b_keys, i, k, eq;
		params = params || {};
		eq = function (a, b) { return (params.loose_compare_basics ? a == b : a === b); };


		if (ttl === undefined) { ttl = DEFAULT_TTL; }

		if (!ttl) {
			throw 'Circular reference, or too deep of an object. Current default TTL is ' + DEFAULT_TTL + '. Set it to a larger value if you wish to support deeper objects.';
		}

		ttl--;

		// Compare the refs
		if (a === b) { return true; }

		// Compare NaN
		if (isNaN(a) && isNaN(b) && typeof(a) === 'number' && typeof(b) === 'number') { return true; }

		// Handle comparing null(db) with an empty string(js)
		if (params.null_equals_blank) {
			if ((eq(a, null) && b === '') || (a === '') && eq(b, null)) { return true; }
		}

		// Compare an empty array to an empty string, if allowed
		if (params.empty_array_equals_blank && (($.isArray(a) && a.length === 0 && b === '') || ($.isArray(b) && b.length === 0 && a === ''))) {
			return true;
		}

		if (!params.empty_string_equals_zero && (eq(a, 0) && b === '') || (eq(b, 0) && a === '')) {
			return false;
		}

		// Check for basic types. Check for null explicitly, since it typeof's as an object. Thanks, JavaScript!
		if (params.loose_compare_basics && ((a === null && b == 0) || (b === null && a == 0))) { return true; }
		if (a === null || b === null) { return false; }
		if (/(undefined|boolean|number|string)/.test((typeof a) + (typeof b))) {
			return (params.loose_compare_basics ? (a == b) : false);
		}

		if (getType(a) !== getType(b)) { return false; }

		// This is a comparison between a literal and a new, both of the same type. Use a coercing comparison to check them, not an object comparison.
		// (Or it's a comparison between an object and something completely different, which will kick back false.)
		if (typeof a !== typeof b && (typeof a === 'object' || typeof b === 'object')) { return a == b; }

		// Compare the keys...
		a_keys = CUI.keys(a);
		b_keys = CUI.keys(b);
		if (a_keys.length !== b_keys.length) { return false; }
		i = a_keys.length;

		propertyLoop: while (i--) {
			k = a_keys[i];

			// Quick affirmative checks--
			if (a[k] === b[k]) { continue propertyLoop; }

			if (getType(a[k]) !== getType(b[k])) { return false; }
			if (typeof a[k] === 'number' && isNaN(a[k]) && isNaN(b[k])) { continue propertyLoop; }

			// We can compare with optional type coersion now, because we already know the types are the same. This will catch the (rare) case of new vs
			// literal, such as "new String('abc') == 'abc'".
			if (a[k] == b[k]) { continue propertyLoop; }

			// If we're still going, a and b are object refs to different objects, that need to be compared
			if (CUI.compareObjects(a[k], b[k], params, ttl)) {
				continue propertyLoop;
			} else {
				return false;
			}
		}

		return true;
	};

})();
