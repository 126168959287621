/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/* A time-range/date selector for the CDR page. This should probably be replaced with a combination of aopb widgets and the dateRangeWidget at some point */

(function( $ ){
	var cdrDateRangeSelectorWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			param_names: { single: 'since', range: 'between' },
			range_single: 'RANGE',
			default_range: 'last_day',

			_default_ranges: ['last_hour', 'last_day', 'last_week', 'last_month', 'range', 'all'],
			_default_range_types: {
				last_hour: { text: 'Last Hour', value: '1 hour' },
				last_day: { text: 'Last Day', value: '1 day' },
				last_week: { text: 'Last Week', value: '1 week' },
				last_month: { text: 'Last Month', value: '1 month' },
				range: { text: 'Date Range', special: 'range' },
				all: { text: 'Show All', value: '' }
			},

			_dateRangeSelectorVisible: false,

			template_html: 'Show records from: <select class="dateRangeSelectorSelect"></select>'
		},

		value_widget: true,
		manages_own_descendent_value: true,
		// manages_own_descendent_events: true,

		_beforeInit: function () {
			var self = this, $self = this.element;
			// Clobber, don't extend range_types and ranges
			self.options.date_format = self.options.date_format || 'MM d, yy';
			self.options.ranges = self.options.ranges || self.options._default_ranges;
			self.options.range_types = self.options.range_types || self.options._default_range_types;
			self.options.$dateRangeSelector = self._generateDateRangeSelector();
		},

		_beforeDOMReady: function () {
			var self = this, $self = this.element, $select = $self.find('.dateRangeSelectorSelect');

			// Initialize the jq datepicker
			self._initializeDatePickers(); 

			// Add options to SELECT
			self._populateSelect();

			// Bind to the SELECT's onChange event
			self._bind(
				$select, 
				'change', 
				self._rangeSelectChangeHandler.bind(self)
			);

			$select.trigger( 'change' );
		},

		_populateSelect: function() {
			var self = this, $self = this.element, $select = $self.find('.dateRangeSelectorSelect');

			for(var i=0; i<self.options.ranges.length; i++) {
				var range = self.options.ranges[i];
				var range_def = self.options.range_types[range];
				var $option = $('<option>', { text: range_def.text, value: range });

				if(self.options.default_range == range) {
					$option.prop('selected', 'selected');
				}

				$select.append($option);
			}
		},

		_rangeSelectChangeHandler: function() {
			var self = this, $self = this.element, $select = $self.find('.dateRangeSelectorSelect'), range_def = self.options.range_types[$select.val()];

			if(range_def.special && range_def.special === 'range') {
				self._showDateRangeSelector();
				self._setDateRange();
			} else {
				self._hideDateRangeSelector();
				self.options.value = range_def.value;
			}
		},

		_showDateRangeSelector: function() {
			var self = this, $self = this.element, $dateRangeSelector = self.options.$dateRangeSelector;
			if(!self.options._dateRangeSelectorVisible) {
				$self.append($dateRangeSelector);
				self.options._dateRangeSelectorVisible = true;
			}
		},

		_hideDateRangeSelector: function() {
			var self = this, $self = this.element, $dateRangeSelector = self.options.$dateRangeSelector;
			if(self.options._dateRangeSelectorVisible) {
				$dateRangeSelector.detach();
				self.options._dateRangeSelectorVisible = false;
			}
		},

		_generateDateRangeSelector: function() {
			var self = this, $self = this.element;

			return $('<span />').addClass('dateRangeSelectorDateRangeContainer')
				.append($('<input />').addClass('dateStart').attr('readonly', 'readonly'))
				.append("to")
				.append($('<input />').addClass('dateEnd').attr('readonly', 'readonly'));
		},

		_initializeDatePickers: function () {
			// Initialize the datepickers and set their date to the current one
			var self = this, $self = this.element, $dateRangeSelector = self.options.$dateRangeSelector;

			$('.dateEnd, .dateStart', $dateRangeSelector).datepicker({
				showAnim: 'fadeIn',
				changeMonth: true,
				changeYear: true,
				dateFormat: self.options.date_format,
				autoSize: true,
				onSelect: self._datepickerSelectHandler.bind(self)
			}).datepicker("setDate", new Date());
		},

		_getWidgetValue: function () {
			var self = this, $self = this.element, out = {};

			// self.options.value should be a string for a "single" value, or a two-element array for a "range" value

			if ($.isArray(self.options.value)) {
				// We are using a "range" value
				if (self.options.range_single || self.options.range_single === '') {
					out[self.options.param_names.single] = self.options.range_single;
				}

				out[self.options.param_names.range] = self.options.value;
			} else {
				// We are using a "single" value
				out[self.options.param_names.single] = self.options.value;
			}

			return out;
		},

		_datepickerSelectHandler: function(dateStr, datePickerInstance) {
			var self = this, $self = this.element, $datepicker = datePickerInstance.input;	    

			// Figure out which datepicker triggered the event, then store the date accordingly
			// For now, storing these as strings since they are sent to the server in this format

			if ($datepicker.hasClass( 'dateStart' )) {
				self.options._dateStart = dateStr;
			} else if($datepicker.hasClass('dateEnd')) {
				self.options._dateEnd = dateStr;
			}

			self._setDateRange();

			$datepicker.trigger('change');
		},

		_setDateRange: function () {
			var self = this, $self = this.element;

			// Set our widget's value to an array of two dates; this should be called whenever 'range' is selected,
			// or when 'range' is already selected and one of the datepickers gets a new date.

			self.options.value = [
				self.options._dateStart || $.datepicker.formatDate(self.options.date_format, new Date()),
				self.options._dateEnd || $.datepicker.formatDate(self.options.date_format, new Date())
			];
		},

		_destroy: function() {
			var self = this, $self = this.element;

			// Let's hide the datepicker in the event that we leave the screen without clicking (i.e. via browser back/forward)
			$self.find('.dateEnd, .dateStart').datepicker('hide');
		}

	});

	add_widget('cdrDateRangeSelectorWidget', cdrDateRangeSelectorWidget);
})(jQuery);
