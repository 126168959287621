
register_template('page_content', function(field_def) {
    var fdc = field_def['class'] || '';
    var page_content = {
        "entity" : "div",
        "attributes" : {
          "class" : "cui-page-content " + fdc
        },
        "widgets" : [ "containerWidget" ],
        "widget_options" : {
	}
    };

    if (field_def.form_action) {
	page_content.widget_options.elements = [
	    $.extend({
		entity_template: 'form',
		rest: field_def.form_action,
		dataEvents: field_def.dataEvents,
		elements: field_def.elements || []
	    }, field_def.form_data_js || {})
	];
    } else if (field_def.elements) {
	page_content.widget_options.elements = field_def.elements;
    }

    return page_content;
});

