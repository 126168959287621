register_screen('queue_detail', {
    rest: '/gui/extension/queue',
    rest_container: 'queue',
    dataEvents: {
	bind: {
	    'primary_extension extension': ''
	}
    },
    elements: [
	{ entity_template: 'masthead' },
	{
	    entity_template: 'container',
	    requires: ['EDIT_QUEUE'],
	    elements: [
		{
		    entity_template: 'page_buttons',
		    buttons: [
			{ type: 'extension', extension_type: 'queue' },
			{
			    type: 'delete',
			    rest: '/gui/extension/queue',
			    fields: 'bbx_queue_id key',
			    confirm: {
				title: 'Delete Queue',
				text: 'Are you sure you would like to delete this queue?'
			    }
			},
			{ type: 'cancel' }, { type: 'save' }
		    ]
		}
	    ]
	},
	{ entity_template: 'page_content', elements: [
	    {
		entity_template: 'page_module',
		legend: 'Basic Information',
		buttons: [ 'help' ],
		help_id: 'queues_basic_information',
		form_action: '/gui/extension/queue',
		form_data_js: { compare_params: { empty_string_equals_zero: false } },
		widgets: ['aopbStateWidget'],
		widget_options: {
		    aopbStateWidget: {
			states: [{
			    conditions: { a: '_USER_PERMISSIONS_', op: '!=', b: '"EDIT_QUEUE"', b_is: 'regexp' },
			    actions: { enabled: false, _cascade: true }
			}]
		    }
		},
		elements: [
		    {
			entity_template: 'input_item',
			title: 'Queue Name:',
			input_name: 'bbx_queue_name',
			type: 'text',
			input_options: { attributes: { maxlength: '255' }},
			validate: {
			    conditions: '', operator: '!=', message: 'This field is required'
			}
		    },
		    {
			entity_template: 'input_item',
			title: 'Music on Hold:',
			type: 'select',
			input_name: 'bbx_moh_id',
			input_options: {
			    widgets: [ 'selectPopulateWidget' ],
			    widget_options: {
				selectPopulateWidget: {
				    rest: '/gui/moh/class',
				    text_field: 'bbx_moh_name',
				    value_field: 'bbx_moh_id'
				}
			    }
			}
		    },
		    {
			entity_template: 'data_item',
			title: 'Break-in Announcement Frequency:',
			elements: [
			    {
				entity_template: 'multiplied_input',
				name: 'bbx_queue_chime_freq',
				invalid_message: 'Invalid Break-In Announcement Frequency'
			    },
			    {
				entity: 'div',
				attributes: { 'class':'info' },
				text: 'Use the table below to add and manage Break-in Announcements.'
			    }
			]
		    }
        //            {
        //                entity_template: 'input_item',
        //                type: 'checkbox',
        //                input_name: 'bbx_queue_announce_position',
        //                title: 'Announce caller position when entering queue:'
        //            }

		]
	    },
	    {
		entity_template: 'page_module',
		legend: 'Fallback/Exit Extension',
		buttons: [ 'help' ],
		help_id: 'queues_fallback',
		form_action: '/gui/extension/queue',
		widgets: ['aopbStateWidget'],
		widget_options: {
		    aopbStateWidget: {
			states: [{
			    conditions: { a: '_USER_PERMISSIONS_', op: '!=', b: '"EDIT_QUEUE"', b_is: 'regexp' },
			    actions: { enabled: false, _cascade: true }
			}]
		    }
		},
		elements: [
		    {
			entity_template: 'data_item',
			title: 'Fallback/Exit Extension:',
			elements: [
			    {
				entity_templates: [ 'extension_picker_select' ],
				extension_picker_select: {
				    name: 'bbx_queue_orbit_extension_id',
				    value_key: 'bbx_extension_id',
				    allow_nothing: true
				}
			    }
			]
		    },
		    {
			entity_template: 'data_item',
			title: 'Transfer After Wait Time:',
			elements: [
			    {
				entity_templates: ['multiplied_input', 'aopb_state'],
				multiplied_input: {
				    multipliers: [{ text: 'minutes', multiplier: 60 }, { text: 'seconds', multiplier: 1 }],
				    name: 'bbx_queue_orbit_timeout',
				    invalid_message: 'Caller wait time must be set if the Fallback extension is in use.'
				},
				aopb_state: {
				    closest: 'form',
				    states: [
					{
					    conditions: { a: '$bbx_queue_orbit_extension_id', op: '==', b: '_TRUE_' },
					    actions: { enabled: true },
					    else_actions: { enabled: false },
					    last_if_false: true
					},
					{
					    conditions: [
						{ a: '$$', op: '<', b: '1' },
						{ join: 'and', a: '$bbx_queue_orbit_extension_id', op: '==', b: '_TRUE_' }
					    ],
					    actions: { invalid: 'Caller wait time must be 1 second or more.' },
					    else_actions: { invalid: false }
					}
				    ]
				}
			    }
			]
		    },
		    {
			entity_template: 'input_item',
			title: 'Transfer if Caller Presses:',
			type: 'select',
			input_name: 'bbx_queue_caller_exit_key',
			select_options: [
			    { text: '(Off)', value: ' ' }, // Apparently a space is the "off" value
			    { text: '1', value: '1' },
			    { text: '2', value: '2' },
			    { text: '3', value: '3' },
			    { text: '4', value: '4' },
			    { text: '5', value: '5' },
			    { text: '6', value: '6' },
			    { text: '7', value: '7' },
			    { text: '8', value: '8' },
			    { text: '9', value: '9' },
			    { text: '0', value: '0' },
			    { text: '*', value: '*' },
			    { text: '#', value: '#' }
			],
			input_options: {
			    widgets: ['aopbStateWidget'],
			    widget_options: {
				closest: 'form',
				states: [{
				    conditions: { a: '$bbx_queue_orbit_extension_id', op: '==', b: '_TRUE_' },
				    actions: { enabled: true },
				    else_actions: { enabled: false }
				}]
			    }
			}
		    }
		]
	    },
	    {
		entity_template: 'page_module',
		legend: 'Queue Agents',
		buttons: [ 'help' ],
		help_id: 'queue_agents',
		requires: ['EDIT_QUEUE_MEMBERSHIP'],
		elements: [
		    {
			entity_template: 'data_table',

			accept_data_from_parent: true,
			refresh_rest: '/gui/extension/queue',
			data_source: 'bbx_queue_extension_maps',
			primary_keys: ['bbx_extension_id'],

			extra_row_data: ['bbx_extension_id'],
			add_edit_action: {
			    rest: '/gui/extension/queue/agent',
			    rest_container: 'agent',
			    only_rest_params: [ 'bbx_queue_id', 'bbx_user_id' ]
			},
			delete_action: {
			    rest: '/gui/extension/queue/agent',
			    include_data_from_parent: ['bbx_queue_id'],
			    only_rest_params: []
			},

			paginate: false,
			length_change: false,
			filtering: false,

			oLanguage: {
			    sZeroRecords: 'There are no agents on this queue.'
			},

			columns: [
			    {
				header: 'Extension',
				column: 'bbx_extension_value',
				width: '8em',
				data_type: 'num-html',
				formatted: {
				    format: {
					field: '@bbx_extension_value',
					formatter: 'phone_number'
				    }
				}
			    },
			    {
				header: 'Name',
				column: 'show_name'
			    }
			],
			row_actions: {
			    animate: false,
			    animate_speed: 'slow',
			    type: 'expand',
			    edit_save: { text: 'Save' },
			    edit_cancel: { text: 'Cancel' },
			    add_save: { text: 'Add' },
			    add_cancel: { text: 'Cancel' },
			    delete_row: {
				text: 'Delete',
				confirm: {
				    title: 'Confirm Queue Agent Removal',
				    text: 'Are you sure you would like to remove this agent?'
				}
			    }
			},
			table_actions: {
			    add_row: {
				text: 'Add Queue Agent',
				location: 'bottom',
				show: false
			    },
			    delete_rows: {
				text: 'Delete Selected',
				location: 'bottom',
				confirm: {
				    title: 'Delete Selected Agents',
				    text: 'Are you sure you would like the remove these agents from the queue?'
				}
			    },
			    select_row: true,
			    select_all_rows: true
			},
			details_row: {
			    add_elements: [{
				entity_template: 'input_item',
				type: 'div',
				input_name: 'bbx_extension_id',
				title: 'Extension:',
				input_options: {
				    entity_template: 'extension_picker_select',
				    type: 'user',
				    primary: true,
				    table_filter: true,
				    allow_nothing: false
				}
			    }]
			}
		    }
		]
	    },
	    {
		entity_template: 'page_module',
		legend: 'Agent Settings',
		form_action: '/gui/extension/queue',
		form_data_js: { compare_params: { empty_string_equals_zero: false } },
		buttons: [ 'help' ],
		help_id: 'queues_agent_settings',
		widgets: ['aopbStateWidget'],
		widget_options: {
		    aopbStateWidget: {
			states: [{
			    conditions: { a: '_USER_PERMISSIONS_', op: '!=', b: '"EDIT_QUEUE"', b_is: 'regexp' },
			    actions: { enabled: false, _cascade: true }
			}]
		    }
		},

		elements: [
		    {
			entity_template: 'input_item',
			title: 'Queue Name on Phone Display:',
			input_name: 'outbound_name',
			type: 'text',
			input_options: {
			    attributes: { style: 'width: 21em', maxlength: '40' }
			}
		    },

		    {
			entity_template: 'input_item',
			type: 'checkbox',
			input_name: 'has_popup_url',
			title: 'Launch Web Application Popup for Calls:'
		    },
		    {
			entity_template: 'data_item',
			title: '',
			elements: [
			    {
				entity_template: 'aopb_state',
				entity: 'input',
				attributes: { type: 'text', size: '60' },
				name: 'bbx_queue_popup_url',
				closest: 'form',
				states: [
				    {
					conditions: { a: '$has_popup_url', op: '==', b: '_TRUEISH_' },
					actions: { enabled: true },
					else_actions: { enabled: false }
				    },
				    {
					conditions: [
					    {              a: '$has_popup_url', op: '==', b: '_TRUEISH_' },
					    { join: 'and', a: '$$', op: '!=', b: '^([a-zA-Z0-9_]+ |)[-+\\.A-Za-z0-9]+://[^ ]+$', b_is: 'regexp' }
					],
					actions: { invalid: 'You must specify a valid URL beginning with '+entity.ldquo+'http'+entity.rdquo+':// (or another protocol) and no spaces.' },
					else_actions: { invalid: false }
				    }
				]
			    },
			    { entity: 'div', text: 'Web Application Popup URL', attributes: { 'class': 'info' } }
			]
		    },

		    {
			entity_template: 'input_item',
			title: 'Agents per Call:',
			input_name: 'outbound_per_cycle',
			type: 'text',
			size: 3,
			input_options: {
			    widgets: ['aopbStateWidget', 'keyFilterInputWidget'],
			    widget_options: {
				aopbStateWidget: {
				    states: [{
					conditions: { a: '$$', op: '==', b: "_NUMERIC_NONNEGATIVE_" },
					actions: { invalid: false },
					else_actions: { invalid: "Agents Per Call must be a number" }
				    }]
				},
				keyFilterInputWidget: {
				    allow_keys_regexp: '(NUM)?[0-9]',
				    allow_shift: false
				}
			    }
			}
		    },
		    {
			entity_template: 'input_item',
			type: 'text',
			title: 'Agent Wrap-up Time Between Calls:',
			input_name: 'bbx_queue_consumer_wrapup_time',
			size: 3,
			input_options: {
			    widgets: ['aopbStateWidget', 'keyFilterInputWidget'],
			    widget_options: {
				aopbStateWidget: {
				    states: [ {
					conditions: { a: '$$', op: '==', b: '^[0-9]+$', b_is: 'regexp' },
					actions: { invalid: false },
					else_actions: { invalid: 'Agent Wrap-up Time must be a number' }
				    } ]
				},
				keyFilterInputWidget: {
				    allow_keys_regexp: '(NUM)?[0-9]'
				}
			    }
			},
			input_units: 'seconds'
		    },
		    {
			entity_template: 'data_item',
			title: 'Individual Agent Ring Time:',
			elements: [
			    {
				entity_template: 'multiplied_input',
				name: 'bbx_queue_ring_timeout',
				multipliers: [
				    { text: 'Seconds', multiplier: 1 },
				    { text: 'Minutes', multiplier: 60 }
				],
				invalid_message: 'Invalid Agent Ring Time'
			    }
			]
		    },
		    {
			entity_template: 'data_item',
			title: 'Queue Call Priority:',
			elements: [ {
			    entity_template: 'slider',
			    name: 'outbound_priority',
			    step: 1,
			    min: 1,
			    max: 10,
			    orientation: 'horizontal',
			    animate: false,
			    disabled: false,
			    default_value: 1,
			    value_map: {
				1:  '1 (Highest)',
				2:  '2 (High)',
				3:  '3 (High)',
				4:  '4 (Medium)',
				5:  '5 (Default)',
				6:  '6 (Medium)',
				7:  '7 (Low)',
				8:  '8 (Low)',
				9:  '9 (Low)',
				10:'10 (Lowest)'
			    }
			} ]
		    }
		]
	    },
	    {
		entity_template: 'page_module',
		legend: 'Queue Management Display',
		buttons: [ 'help' ],
		help_id: 'queue_management_display',
		form_action: '/gui/extension/queue',
		form_data_js: { compare_params: { empty_string_equals_zero: false } },
		widgets: ['aopbStateWidget'],
		widget_options: {
		    aopbStateWidget: {
			states: [{
			    conditions: { a: '_USER_PERMISSIONS_', op: '!=', b: '"EDIT_QUEUE"', b_is: 'regexp' },
			    actions: { enabled: false, _cascade: true }
			}]
		    }
		},

		elements: [
		    {
			entity_template: 'input_item',
			title: 'Caller Count Warning Level:',
			input_name: 'bbx_queue_warning_calls',
			type: 'text',
			size: 4,
			input_units: 'calls',
			input_options: {
			    widgets: ['aopbStateWidget', 'keyFilterInputWidget'],
			    widget_options: {
				aopbStateWidget: {
				    states: [
					{
					    conditions: [
						{              a: '$$', op: '>=', b: '$bbx_queue_critical_calls' },
						{ join: 'and', a: '$$', op: '==', b: '_TRUEISH_' }
					    ],
					    actions: {
						invalid: 'Caller Count: The Warning level must be lower than the Critical level',
						_invalid_key: 'FLIPPED'
					    },
					    else_actions: {
						invalid: false,
						_invalid_key: 'FLIPPED'
					    }
					},
					{
					    conditions: { a: '$$', op: '==', b: '^[0-9]+$', b_is: 'regexp' },
					    actions: { invalid: false, _invalid_key: 'NUMBER' },
					    else_actions: { invalid: 'Caller Count Warning Level must be a number', _invalid_key: 'NUMBER' }
					}
				    ]
				},
				keyFilterInputWidget: {
				    allow_keys_regexp: '(NUM)?[0-9]'
				}
			    }
			}
		    },
		    {
			entity_template: 'input_item',
			title: 'Caller Count Critical Level:',
			input_name: 'bbx_queue_critical_calls',
			type: 'text',
			size: 4,
			input_units: 'calls',
			input_options: {
			    widgets: ['aopbStateWidget', 'keyFilterInputWidget'],
			    widget_options: {
				aopbStateWidget: {
				    states: [
					{
					    conditions: { a: '$$', op: '==', b: '^[0-9]+$', b_is: 'regexp' },
					    actions: { invalid: false },
					    else_actions: { invalid: 'Caller Count Critical Level must be a number' }
					}
				    ]
				},
				keyFilterInputWidget: {
				    allow_keys_regexp: '(NUM)?[0-9]'
				}
			    }
			}
		    },
		    {
			entity_template: 'data_item',
			title: 'Wait Time Warning Level:',
			elements: [
			    {
				entity_templates: ['aopb_state', 'multiplied_input'],
				multiplied_input: {
				    name: 'bbx_queue_warning_seconds',
				    multipliers: [
					{ text: 'Seconds', multiplier: 1 },
					{ text: 'Minutes', multiplier: 60 }
				    ]
				},
				aopb_state: {
				    states: [
					{
					    conditions: [
						{              a: '$$', op: '>=', b: '$bbx_queue_critical_seconds' },
						{ join: 'and', a: '$$', op: '==', b: '_TRUEISH_' }
					    ],
					    actions: {
						invalid: 'Wait Time: The Warning level must be lower than the Critical level',
						_invalid_key: 'FLIPPED'
					    },
					    else_actions: {
						invalid: false,
						_invalid_key: 'FLIPPED'
					    }
					}
				    ]
				}
			    }
			]
		    },
		    {
			entity_template: 'data_item',
			title: 'Wait Time Critical Level:',
			elements: [
			    {
				entity_template: 'multiplied_input',
				name: 'bbx_queue_critical_seconds',
				multipliers: [
				    { text: 'Seconds', multiplier: 1 },
				    { text: 'Minutes', multiplier: 60 }
				]
			    }
			]
		    }
		]
	    },
	    {
		entity_templates: ['aopb_state','container'],
		aopb_state: {
		    states: [{
			conditions: { a: '_USER_PERMISSIONS_', op: '!=', b: '"EDIT_QUEUE"', b_is: 'regexp' },
			actions: { enabled: false, _cascade: true }
		    }]
		},
		container: {
		    elements: [
			elgen('recording_policy', { rest: '/gui/extension/queue' }, { help_id: 'queue_call_recording' })
		    ]
		}
	    },
	    {
		entity_templates: ['aopb_state','container'],
		aopb_state: {
		    states: [{
			conditions: { a: '_USER_PERMISSIONS_', op: '!=', b: '"EDIT_QUEUE"', b_is: 'regexp' },
			actions: { enabled: false, _cascade: true }
		    }]
		},
		container: {
		    elements: [
			{
			    entity_template: 'page_module',
			    legend: 'Break-in Announcements',
			    buttons: [ 'help' ],
			    help_id: 'break_in_announcements',
			    elements: [ {
				entity_template: 'data_table',

				refresh_rest: '/gui/extension/queue/chimes',
				refresh_rest_container: false,
				refresh_data_source: 'chimes',

				data_source: 'bbx_queue_chime_maps',

				name_column: 'bbx_sound_name',

				sortable: {
				    field: 'bbx_queue_chime_map_order',
				    also_submit: ['bbx_queue_chime_map_id','bbx_queue_id']
				},
				add_edit_action: {
				    rest: '/gui/extension/queue/chime',
				    rest_container: 'chime',
				    include_row_data: ['bbx_queue_id','bbx_sound_id']
				},
				delete_action: {
				    rest: '/gui/extension/queue/chime',
				    include_row_data: ['bbx_sound_id'],
				    only_rest_params: ['bbx_queue_id']
				},


				paginate: false,
				length_change: false,
				filtering: false,
				oLanguage: {
				    sZeroRecords: 'There are no break-in announcements mapped to this queue.'
				},
				accept_initial_data_from_parent: true,
				primary_keys: ['bbx_queue_chime_map_id'],

				columns: [
				    {
					header: 'Order',
					column: 'bbx_queue_chime_map_order',
					visible: false
				    },
				    {
					header: 'Sound Category',
					column: 'bbx_sound_category_name',
					view_element: {
					    entity: 'div',
					    widgets: [ 'formatWidget' ],
					    widget_options: {
						formatWidget: {
						    format: { field: '@bbx_sound_category_name' }
						}
					    }
					}
				    },
				    {
					header: 'Sound Name',
					column: 'bbx_sound_name',
					view_element: {
					    entity: 'div',
					    widgets: [ 'formatWidget' ],
					    widget_options: {
						formatWidget: {
						    format: { field: '@bbx_sound_name' }
						}
					    }
					}
				    },
				    {
					header: 'Preview',
					column: 'bbx_sound_id',
					view_element: {
					    entity_template: 'sound_player',
					    url: '/gui/sound_file/playback',
					    include_keys: ['bbx_sound_id'],
					    accept_data_from_parent: true
					}
				    }
				],
				row_actions: {
				    animate: false,
				    animate_speed: 'slow',
				    type: 'expand',
				    edit_save: { text: 'Save' },
				    edit_cancel: { text: 'Cancel' },
				    add_save: { text: 'Add' },
				    add_cancel: { text: 'Cancel' },
				    delete_row: {
					text: 'Delete',
					confirm: {
					    title: 'Confirm Announcement Removal',
					    text: 'Are you sure you would like to remove this announcement?'
					}
				    }
				},
				table_actions: {
				    add_row: {
					text: 'Add Announcement',
					location: 'bottom',
					'class': 'hide-row'
				    },
				    delete_rows: {
					text: 'Delete Selected',
					location: 'bottom',
					confirm: {
					    title: 'Confirm Removal of Announcements',
					    text: 'Are you sure you would like the remove these announcements?'
					}
				    },
				    select_row: true,
				    select_all_rows: true
				},
				details_row: {
				    add_elements: [
					{
					    entity_template: 'container',
					    elements: [
						{
						    entity_template: 'data_item',
						    title: 'Select a sound file:',
						    elements: [
							{
							    entity_template: 'sound_picker',
							    name: 'bbx_sound_id',
							    allow_none: false
							}
						    ]
						}
					    ]
					}
				    ]
				}
			    } ]
			},
			elgen('secondary_page_module', {
			    type: 'queue',
			    legend: 'Agent Access Extensions',
			    header: 'Agent Access Extension',
			    add_text: 'Add Agent Access Extension',
			    add_rest: '/gui/extension/queue/aae',
			    delete_rest: '/gui/extension/queue/aae',
			    data_source: 'aaes'
			}, { help_id: 'agent_access_extensions' }),
			elgen('secondary_page_module', { type: 'queue' }, { help_id: 'secondary_extensions' })
		    ]
		}
	    }
	]
	}
    ]
});
