register_screen('queue_create', {
    elements: [
	{ entity_template: 'page_title', title: 'Create Queue' },
	{
	    entity_template: 'page_buttons',
	    buttons: [
		{ type: 'cancel_back' },
		{
		    type: 'create',
		    key: 'bbx_queue_id',
		    detail: 'queue_detail'
		}
	    ]
	},
	{ entity_template: 'page_content', elements: [
	    {
		entity_template: 'page_module',
		legend: 'Add New Queue',
		form_action: '/gui/extension/queue',
		form_data_js: {
		    method: 'POST',
		    no_initial_get: true,
		    submit_all: true,
		    always_dirty: true
		},
		buttons: [ 'help' ],
		help_id: 'add_new_queue',
		set_focus: true,
		elements: [
		    {
			entity_template: 'input_item',
			title: 'Queue Name:',
			input_name: 'bbx_queue_name',
			type: 'text',
			validate: {
			    conditions: '', operator: '!=', message: 'This field is required'
			},
			input_options: { attributes: { 'class': 'focus', maxlength: '255' } }
		    },
		    {
			entity_template: 'input_item',
			title: 'Music on Hold:',
			type: 'select',
			input_name: 'bbx_moh_id',
			input_options: {
			    widgets: [ 'selectPopulateWidget' ],
			    widget_options: {
				selectPopulateWidget: {
				    rest: '/gui/moh/class',
				    text_field: 'bbx_moh_name',
				    value_field: 'bbx_moh_id'
				}
			    }
			}
		    },
		    {
			
			name: 'bbx_extension_value',
			entity_template: 'extension_select'
		    }
	 	]
	    }
	]
	}
    ]
});
