register_screen('conference_create', {
    elements: [
	{ entity_template: 'page_title', title: 'Create Conference' },
	{
	    entity_template: 'page_buttons',
	    buttons: [
		{ type: 'cancel_back' },
		{
		    type: 'create',
		    key: 'bbx_conference_id',
		    detail: 'conference_detail'
		}
	    ]
	},
	{ entity_template: 'page_content', elements: [
	    {
		entity_template: 'page_module',
		legend: 'Add New Conference',
		form_action: '/gui/extension/conference',
		form_data_js: {
		    method: 'POST',
		    no_initial_get: true,
		    submit_all: true,
		    always_dirty: true
		},
		buttons: [ 'help' ],
		help_id: 'add_new_conference',
		create: {
		    detail: 'conference_detail',
		    params: [ 'bbx_extension_id', 'bbx_conference_id' ],
		    key: 'bbx_conference_id'
		},
		set_focus: true,
		elements: [
		    {
			entity_template: 'input_item',
			title: 'Conference Name:',
			input_name: 'bbx_conference_name',
			type: 'text',
			validate: { conditions: '_TRUE_', message: 'A Conference Name is required' },
			input_options: { attributes: { 'class': 'focus', maxlength: '255' } }
		    },
		    {
			entity_template: 'input_item',
			title: 'Waiting Music:',
			type: 'select',
			input_name: 'bbx_moh_id',
			input_options: {
			    widgets: [ 'selectPopulateWidget' ],
			    widget_options: {
				selectPopulateWidget: {
				    rest: '/gui/moh/class',
				    text_field: 'bbx_moh_name',
				    value_field: 'bbx_moh_id'
				}
			    }
			}
		    },
		    {
			entity_template: 'input_item',
			title: 'Access Code',
			input_name: 'bbx_conference_pin',
			type: 'text',
			size: 4,
			info: 'If a conference PIN is given, the conference will be locked, and the given PIN will be required for entry. Leave this field blank to create an unlocked conference.',
			input_options: {
			    widgets: ['aopbStateWidget', 'keyFilterInputWidget'],
			    widget_options: {
				aopbStateWidget: {
				    states: [{
					conditions: [
					    { a: '$$', op: '==', b: '_NUMERIC_NONNEGATIVE_' },
					    { join: 'or', a: '$$', op: '==', b: '' }
					],
					actions: { invalid: false },
					else_actions: { invalid: 'Please use a numeric entry' }
				    }]
				},
				keyFilterInputWidget: {
				    allow_keys_regexp: '(NUM)?[0-9]',
				    allow_shift: false
				}
			    }
			}
		    },
		    {
			entity: 'div',
			attributes: {
			    name: 'extensionBlock'
			},
			widgets: [ 'extensionSelectWidget' ]
		    }
	 	]
	    }
	]
	}
    ]
});
