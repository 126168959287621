register_screen('people_on_call', { "elements" : [
    { "entity_template" : "page_title", "title" : "People on Call" },
    {
	"entity_template" : "page_buttons",
	"buttons" : [{ "type" : "fullscreen" }, {'type': 'help', help_id:'switchboard_people_on_call' }]
    },
    { "entity_template" : "page_content", "elements" : [ {
	"entity_template" : "container",
	"class" : "bubble_table people_on_call hide_phone_info",
	"elements" : [
	    {
		"entity_template" : "wallboard_data_table",
		"disable_cui_wrapper" : true,
		"class" : "people_on_call",
		"name" : "people_on_call",
		"live_table" : "live_call_stats",
		"live_table_key": "call_stats",
		"live_table_distinct_on": "bbx_extension_id, bbx_phone_registration_id",
		"page_size" : 10000,
		"sort_by" : "show_name",
		"filtering" : true,
		"filter_extra_columns" : ["bbx_extension_value"],
		"filter_in_page_title" : true,
		"filter_regexp_flags": 'i',
		"extension_filter" : true,
		extension_filter_options: {
		    type_text: 'By Group',
		    nothing_text: 'Show All Groups',
		    value_key: 'bbx_extension_value',
		    la_filter_field: 'bbx_group_extension',
		    type: 'group',
		    primary: true
		},

		"length_change" : false,
		"paginate" : false,
		"processing" : false,
		"sortable" : false,
		"oLanguage" : {
		    "sZeroRecords" : "There are no people to display."
		},
		"extra_row_data" : [
		    "show_name", "bbx_extension_value", "destination_formatted", "caller_id_name", "caller_id_number", "callee_id_name",
		    "callee_id_number", "call_state", "call_start", "online", "destination_number", "missed_calls", "last_state_change",
		    "bbx_phone_desc", "bbx_phone_mac", "extension_owner", "current_time", "bbx_user_id", "primary_extension"
		],
		"columns" : [
		    {
			"header" : "Caller",
			"column" : "show_name",
			"sortable" : false,
			"view_elements" : [
			    { "entity_template" : "people_on_call" }
			]
		    }
		]
	    },
	    {
		"entity_template" : "wallboard_resizer",
//		"closest" : ".conatinerWidgetType",
		"font_family" : "Trebuchet MS",
		"min_font_size" : 100,
		"max_font_size_docked" : 200,
		"max_font_size_fullscreen" : 250,
		"bubble_width" : 45,
		"bubble_height" : 4.5
	    }
	]
    } ] }
] });
