/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/*
  A single-purpose widget to show and set the ringtone selector on the phone buttons table.
*/

(function( $ ){
	var buttonRingtoneWidget = $.extend(true, {}, $.ui.selectSelfPopulateWidget.prototype, {
		options: {
			options_container: 'ringtone_type_list',
			option_text_key: 'name',
			option_value_key: 'value',

			_phone_data: false,
			_current_val: false,
			_original_fillData: {}
		},

		fillData: function (d, from_self) {
			var self = this, $self = this.element, $pb_table, phone_buttons_dtw_data;
			$pb_table = $self.closest('.phoneButtonsDataTableWidget');

			self.options._original_fillData = $.extend(true, self.options._originalfillData, d);
			phone_buttons_dtw_data = $pb_table.getCUIWidget('phoneButtonsDataTableWidget');

			if (phone_buttons_dtw_data) {
				self.options._phone_data = phone_buttons_dtw_data.options.phone_data;

				if (self.options._phone_data && self.options._phone_data[self.options.options_container]) {
					$.ui.selectSelfPopulateWidget.prototype.fillData.apply(this, [self.options._phone_data, false]);
					if (self.options._redo_setValue) {
						self.setValue(self.options._redo_setValue);
					}
				} else if (self.options.edit_mode) {
					$self.after('Default');
					$self.remove();
					self.destroy();
				} else {
					$pb_table.one('fillPhoneData', self.fillData.bind(self, d, from_self));
					return;
				}
			}
		},

		setValue: function (v) {
			var self = this, $self = this.element, value, $td, $opts, o_idx, ringtones;

			$td = $self.closest('td');
			$opts = $self.find('option');

			// If this has not been fully initialized, we have to wait for that.
			if (!self.options.edit_mode && !self.options._select_built) {
				self.options._redo_setValue = v;
				return;
			}

			if (v !== self.options._current_val) {
				self.options._current_val = v;
				$.ui.selectSelfPopulateWidget.prototype.setValue.apply(this, arguments);

				if (!self.options.edit_mode) {
					if (!self.options._ringtones) {
						self.options._ringtones = {};
						for (o_idx = 0; o_idx < $opts.length; o_idx++) {
							self.options._ringtones[$opts.eq(o_idx).val()] = $opts.eq(o_idx).text();
						}
					}

					// Set this locally because the empty will destroy s.o
					ringtones = self.options._ringtones;

					value = $self.val();
					$td.empty();
					$td.append($('<div>').text((value in ringtones) ? ringtones[value] : 'Default'));
				}
			}
		}

	});

	add_widget('buttonRingtoneWidget', buttonRingtoneWidget);
})(jQuery);
