/* jshint jquery: true, browser: true */
/* global classCUI */
(function ($) {
	/*
      QuickShow/QuickHide -- A faster alternative to jQuery show/hide.

      These function show or hide a DOM/jQuery object much more quickly than the jQuery standard show/hide, because they do less checking of the former
      "display" style setting, checking or setting only the current object's style, not the computed style. These functions are NOT compatible with jQuery
      show/hide, so if quickHide is used to hide an element, quickShow should be used to show it.

      Functions return the object passed to them in a jQuery wrapper.
    */

	classCUI.prototype.quickHide = function (elem) {
		var $elem = $(elem);
		if ($elem[0]) {
			if ($elem.data('cui_former_display') || $elem[0].style.display === 'none' || parseInt($elem[0].style.opacity) === 0) { return; } // Already hidden!
			$elem.data('cui_former_display', $elem[0].style.display);
			$elem.data('cui_former_opacity', $elem[0].style.opacity);
			$elem[0].style.display = 'none';
			$elem[0].style.opacity = 0;
		}
		return $elem;
	};

	classCUI.prototype.quickShow = function (elem) {
		var $elem = $(elem), former_display = $elem.data('cui_former_display') || '', former_opacity = $elem.data('cui_former_opacity') || '';
		if ($elem[0]) {
			$elem.removeData('cui_former_display')[0].style.display = former_display || '';
			$elem.removeData('cui_former_opacity')[0].style.opacity = former_opacity || '';
		}
		return $elem;
	};

	classCUI.prototype.quickHide2 = function (elem) {
		var $elem = $(elem);
		if ($elem[0]) {
			if ($elem.data('cui_former_opacity')) { return; } // Already hidden!
			$elem.data('cui_former_opacity', $elem[0].style.opacity);
			$elem[0].style.display = 'none';
			$elem[0].style.opacity = 0;
		}
		return $elem;
	};

	classCUI.prototype.quickShow2 = function (elem) {
		var $elem = $(elem), former_opacity = $elem.data('cui_former_opacity') || '';
		if ($elem[0]) {
			$elem.removeData('cui_former_opacity')[0].style.opacity = former_opacity || '';
		}
		return $elem;
	};

})(jQuery);
