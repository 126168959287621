/* jshint jquery: true, unused: vars */
(function ($) {

	var classDefer = function (value, state) {
		var tclasses = ( typeof value == 'string' ? value.split(/ /) : [ value ] );
		for (var e = 0, el = this.length; e < el; ++e) {
			var elem = this[e];
			var $elem = $(elem);

			if (elem.nodeType === 1) {
				var classes = $elem.data('deferClasses') || {};

				for (var c = 0, cl = tclasses.length; c < cl; ++c) {
					classes[tclasses[c]] = state;
				}
				$elem.data('deferClasses', classes);
			}
		}
		return this;
	};

	$.fn.addClassDefer = function (value) {
		return classDefer.call(this, value, true);
	};

	$.fn.removeClassDefer = function (value) {
		return classDefer.call(this, value, false);
	};

	$.fn.applyClassDefer = function () {
		for (var e = 0, el = this.length; e < el; ++e) {
			var elem = this[e];
			var $elem = $(elem);
			if (elem.nodeType === 1) {
				var elem_dest_classes = $elem.data('deferClasses');
				if (!elem_dest_classes) { continue; }

				var elem_classes_cur = (elem.className ? elem.className : '').split(/ /);
				var final_classes = {};
				for (var c = 0, cl = elem_classes_cur.length; c < cl; ++c) {
					if (elem_classes_cur[c] && elem_classes_cur[c].length) {
						final_classes[elem_classes_cur[c]] = true;
					}
				}
				var orig_classes = $.extend({}, final_classes);

				for (var dest_class in elem_dest_classes) {
					if (dest_class && dest_class.length) {
						var include = elem_dest_classes[dest_class];
						final_classes[dest_class] = include;
					}
				}

				// Only apply the class changes if one or more of the classes was added or removed
				var apply_changes = false;
				for (var fclass in final_classes) {
					if ((!(fclass in orig_classes) && final_classes[fclass]) || (orig_classes[fclass] !== undefined && orig_classes[fclass] != final_classes[fclass])) {
						apply_changes = true;
						break;
					}
				}

				if (apply_changes) {
					var final_classes_str = '';
					for (var fclass in final_classes) {
						if (final_classes[fclass]) {
							final_classes_str += fclass + ' ';
						}
					}
					elem.className = $.trim(final_classes_str);
					$elem.trigger('applyClassDefer');
				}
			}
			$elem.data('deferClasses', {});
			$elem.removeData('deferClasses');
		}
		return this;
	};


})(jQuery);
