/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, debugLog */
/* Refreshes the containing form when clicked. Apply to a button. */

(function( $ ){
	var formRefreshButtonWidget = $.extend(true, {}, $.ui.widget.prototype, {
		_beforeInit: function () {
			var self = this, $self = this.element;
			self._bind($self, 'click', self._refreshForm.bind(self));
		},

		_refreshForm: function () {
			var self = this, $self = this.element, $fw, fw;
			$fw = $self.closest('.formWidget');
			if ($fw[0]) {
				fw = $fw.getCUIWidget('formWidget');
				if (fw) {
					fw.refresh();
				} else {
					debugLog('jquery.formRefreshButtonWidget: Closest .formWidget ', $fw, ' did not have formWidget data. -- ', $self);
				}
			} else {
				debugLog('jquery.formRefreshButtonWidget: The widget is not inside a form. Nothing to do! -- ', $self);
			}
		}
	});

	add_widget('formRefreshButtonWidget', formRefreshButtonWidget);
})(jQuery);
