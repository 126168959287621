/* jshint browser: true */
/* global classCUI */
/*

OBJECT CUI.combineObjects(obj1, obj2, ...)

Returns an object that is the combination of the objects given. Does not do a "deep" extend, but it does combine "undefined" value properties, which $.extend
does not do. Also, does not copy non-hasOwnProperty properties.

*/

(function () {
	classCUI.prototype.combineObjects = function () {
		var args = Array.prototype.slice.call(arguments,0), a_idx, o_key, out = {};
		for (a_idx = 0; a_idx < args.length; a_idx++) {
			if (typeof args[a_idx] !== 'object') { continue; }
			for (o_key in args[a_idx]) {
				if (args[a_idx].hasOwnProperty(o_key)) {
					out[o_key] = args[a_idx][o_key];
				}
			}
		}
		return out;
	};
})();
