/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, getUnique, debugLog, setCookie, widgetize_children */
/* global unescape */
/* exported register_dependent_screen */
var screens = {};

function register_screen(screen_name, screen_def) {
	screens[screen_name] = screen_def;
	$(document).trigger('registerScreen', screen_name);
}

//
// register_dependent_screen
//
// Allows registering a screen only after other specified screens are registered. For instance, this allows putting parts of one screendef into another.
//
// To use:
//
//     register_dependent_screen( 'screen_name', ['dependent_screen_names'], function (screens) { return ({ screen def }); });
//
// The callback function is called after all dependencies are registered, and the screen defs for those screens are in the object passed to the callback.
//

function register_dependent_screen(screen_name, dependencies, callback) {
	var d_idx, d_map = {}, cb_local, defs = {}, binding = getUnique('registerScreen.rds');
	cb_local = function (e, scr) {
		var d_idx;
		if (d_map[scr]) {
			delete d_map[scr];

			for (d_idx = 0; d_idx < dependencies.length; d_idx++) {
				if (dependencies[d_idx] === scr) {
					defs[dependencies[d_idx]] = screens[dependencies[d_idx]];
					dependencies.splice(d_idx, 1);
				}
			}

			if (!dependencies.length) {
				$(document).off(binding);
				register_screen(screen_name, callback(defs));
			}
		}
	};

	// Check if dependencies are already fulfilled...
	for (d_idx = 0; d_idx < dependencies.length; d_idx++) {
		if (screens[dependencies[d_idx]]) {
			defs[dependencies[d_idx]] = screens[dependencies[d_idx]];
			dependencies.splice(d_idx, 1);
		} else {
			d_map[dependencies[d_idx]] = true;
		}
	}

	// Wait for any remaining...
	if (dependencies.length) {
		$(document).on(binding, cb_local);
	} else {
		register_screen(screen_name, callback(defs));
	}
}

(function( $ ){
	var pageWidget = $.extend({}, $.ui.widget.prototype, {
		options : {
			animate_type: "slide",
			accept_data_from_parent: true,
			page_state: { dirty: false, valid: true }, // Starting state
			slide_overlays: false,
			screen_mode: 'docked',
			left_hand_nav: true,
			_form_count: 0,
			_form_fail_count: 0,
			product_prefix: 'bps'
		},

		_afterInit: function() {
			var $self = this.element;
			var self = this;

			// debugLog('pageWidget afterInit');
			$self.addClass('pageWidgetType');

			self.initialize();

			self._one($(window), 'logout', self._logoutHandler.bind(self));

			// This should only be done once, after the page has been built the first time, but before the
			// selected page content is drawn
			self._bind($(window), 'hashchange', self._hashChangeHandler.bind(self));

			self._one($self, 'screenReady', self._scrollCheck.bind(self));
			self._one($self, 'screenChangeFinished', self._scrollCheck.bind(self));

			self._bind($self, 'pageSubmitStart', self._pageSubmitStartHandler.bind(self));
			self._bind($self, 'pageSubmitComplete', self._pageSubmitCompleteHandler.bind(self));

			self._delegate($self, '.overlay .page_title', 'change', self._buildBreadcrumbs.bind(self));
			self._delegate($self, '.screen .page_title', 'change', self._buildBreadcrumbs.bind(self));

			self._bind($('body'), 'classChange', self._fullscreenCheck.bind(self));

			self._hashChangeHandler();
		},

		reinitialize: function() {
			var $self = this.element;
			var self = this;

			// debugLog('pageWidget re-initialize.');
			if (window.location.search.match(/embedded/) === null) {
				self.options.embedded = true;
				self.options.animate_type = 'none';
				$('#global_loading_indicator').show();
			}
			$('div.slide-container > div').remove();
			$('div.slide-outer').remove();
			$(document.getElementById('cudatel_nav')).children().remove();
			self.options.data = {};
			self.options.nav = null;
			self.options.inverse_nav = null;
			self.options.hash = null;
			self.options.hash_arr = null;
			self.options.last_hash = null;
			self.options.last_hash_arr = null;
			self.options.requested_overlays = null;
			delete self.options._scrolling_on;
			var loc = window.location.hash;
			self.options.current_screen = '';
			loc = loc.replace(/^#/, '');
			self._one($self, 'dataReady', function() {
				self.initialize();
				self._one($(window), 'logout', self._logoutHandler.bind(self));
				self._hashChangeHandler();
			});
			self._doDataFill();

		},

		initialize: function() {
			var $self = this.element;
			var self = this;

			// debugLog('Initializing pageWidget...');

			self.options.current_screen = '';
			self.options.overlays = [];
			self.options.last_overlay = 0;
			if (!self.options.animate_length) {
				self.options.animate_length = 500;
			}
			self.options.animate_in_progress = false;
			self.options.up = false;
			self.options.last_hash = '';
			self.options.last_hash_arr = [];

			self._setupClosures();
			self._buildScreenContainers();
			self._buildNav();
			self._setupUnload();
			self._bindPageButtons();
		},

		_setupUnload: function() {
			var self = this, $self = this.element;

			window.onbeforeunload = function (e) {
				e = e || window.event;

				self._locateButtons();

				if (self.options.save_button[0] && !(self.options.save_button.hasClass('state-disabled')) && !(self.options.save_button.getCUIWidget().options.allow_nav_on_dirty)) {
					// For IE and Firefox prior to version 4
					if (e) {
						e.returnValue = 'There are unsaved changes.';
					}
					// For Safari
					return 'There are unsaved changes.';
				} else {
					return undefined;
				}
			};

			self._bind($('a'), 'click', self._confirmContinue.bind(self));
		},

		_setupClosures: function() {
			var $self = this.element;
			var self = this;

			self.options.add_overlay_animate_step_function_closure = function(now, fx) {
				self._addOverlayStepFunctionCallback(now, fx);
			};

			self.options.remove_overlay_animate_step_function_closure = function(now, fx) {
				self._removeOverlayStepFunctionCallback(now, fx);
			};

			self.options.page_save_closure = function(e) {
				self._savePageHandler(e, $(this));
			};

			self.options.page_cancel_closure = function(e) {
				self._resetPageHandler(e, $(this));
			};
		},

		_buildScreenContainers: function() {
			var $self = this.element;
			var self = this;

			// Get the current content viewport and its width
			self.options.$container = $('#cui-content-inner');

			// Build a set of divs for sliding screens and overlays around. The outer div is an anchor
			// to the content-inner div and gives us a block element to use for absolute positioning of
			// of the inner 'sliding' div.
			$('<div class="slide-outer"><div class="slide-container"></div></div>').appendTo(self.options.$container);
			// This is the div which will contain the content and slide around
			self.options.$container = self.options.$container.find('.slide-container');

			self.options.$button_bar = $('#cudatel_nav');
			// debugLog('Button Bar: ', self.options.$button_bar);
			self.options.$nav_back = $(document.getElementById('nav_level_2_background'));
			self.options.$content = $(document.getElementById('cui-content'));
			self.options.$cui_source_inner = $(document.getElementById('cui-source-inner'));
			self.options.$cui_content_inner = $(document.getElementById('cui-content-inner'));
			self.options.$cui_nav = $(document.getElementById('cui-nav'));
			self.options.$cudatel_nav = $(document.getElementById('cudatel_nav'));
			self.options.$body = $('body');

			self.options.$scroll_left = $(document.getElementById('nav_scroll_left'));
			self.options.$scroll_right = $(document.getElementById('nav_scroll_right'));

			// debugLog(self.options.$scroll_left);

			self.options.$scroll_left.hide();
			self.options.$scroll_right.hide();
			self._bind($(window), 'resize', self._scrollCheck.bind(self));
			if (self.options.slide_overlays) {
				self._bind($(window), 'resize', self._resize.bind(self));
			}
			self._bind($(window), 'resize', self.resizeCUIContentInner.bind(self));
			self.resizeCUIContentInner();
		},

		resizeCUIContentInner: function() {
			var self = this, $self = this.element;

			var header_height = 70;
			var footer_height = 15;
			var $breadcrumbs = $('#breadcrumbs');
			var breadcrumbs_height = parseInt($breadcrumbs.outerHeight());
			var window_height = window.innerHeight;
			var new_height = window_height - (header_height + footer_height);

			if (self.options.$body.hasClass('logged_in')) {
				self.options.$cui_content_inner.css({ 'height': new_height + 'px' });
			} else {
				self.options.$cui_content_inner.removeAttr('style');
			}

			var $login_content = $('#cui-login .cui-page-content');
			var $content = self.options.$cui_content_inner.find('.cui-page-content').not($login_content);
			var $header = self.options.$cui_content_inner.find('.page_title').eq(-1);
			var page_content_height = parseInt(self.options.$cui_content_inner.height()) - header_height - breadcrumbs_height;

			if (navigator.userAgent.indexOf('MSIE 8') !== -1) {
				page_content_height = page_content_height - 80;
			}

			$content.css({ 'height': page_content_height + 'px' });

		},

		_resize: function() {
			var self = this, $self = this.element;

			var content_width = parseInt(self.options.$content.width());
			self.options.viewport_width = content_width;

			var $divs = self.options.$container.find('>div');
			var div_count = $divs.length;
			var cur_div = div_count;
			$divs.each(function() {
				--cur_div;
				var $div = $(this);
				$div.css({ 'width': content_width + 'px', 'left': (content_width * cur_div) + 'px' });
			});
			self.options.$container.css({ 'width': (content_width * div_count) + 'px', 'left': (content_width * (div_count-1) * -1) + 'px' });
		},

		_enableScrolling: function() {
			var $self = this.element;
			var self = this;

			self._bind(self.options.$scroll_left, 'click', self._clickScrollLeft.bind(self));
			self._bind(self.options.$scroll_left, 'mouseenter', self._enterScrollLeft.bind(self));
			self._bind(self.options.$scroll_left, 'mouseleave', self._leaveScroll.bind(self));

			self._bind(self.options.$scroll_right, 'click', self._clickScrollRight.bind(self));
			self._bind(self.options.$scroll_right, 'mouseenter', self._enterScrollRight.bind(self));
			self._bind(self.options.$scroll_right, 'mouseleave', self._leaveScroll.bind(self));

		},

		_scrollCheck: function() {
			var $self = this.element;
			var self = this;
			var tab_not_visible_left = false;
			var tab_not_visible_right = false;

			self.options.$scroll_right.show();
			var right_scroller_left = self.options.$scroll_right.position().left;
			self.options.$scroll_right.hide();
			self.options.$scroll_left.show();
			var left_scroller_left = self.options.$scroll_left.position().left;
			self.options.$scroll_left.hide();
			var content_width = parseInt(self.options.$content.width());
			var content_left = parseInt(self.options.$content.position().left);
			self.options.$cui_nav.css({ 'width': (content_width + content_left) + 'px' });
			var cudatel_width = (content_width + content_left) - (parseInt(self.options.$cudatel_nav.position().left) + 10);

			self.options.$cudatel_nav.css({ 'width': cudatel_width + 'px' });
			for(var n in self.options.nav) {
				var tab = self.options.nav[n];
				var $tab = $(document.getElementById(tab.name+'-menu'));
				var tab_right = parseInt($tab.position().left) + parseInt($tab.width());
				tab_right += left_scroller_left + 20;
				if (!self._tabVisibleLeft($tab)) {
					tab_not_visible_left = true;
					self.options.$scroll_left.show();
				}
				if (!self._tabVisibleRight($tab)) {
					tab_not_visible_right = true;
					self.options.$scroll_right.show();
				}
				if (right_scroller_left < tab_right) {
					tab_not_visible_right = true;
					self.options.$scroll_right.show();
				}
			}
			if (!tab_not_visible_left) {
				self.options.$scroll_left.hide();
			}
			if (!tab_not_visible_right) {
				self.options.$scroll_right.hide();
			}
		},

		_fullscreenCheck: function(e, d) {
			var self = this, $self = this.element;

			if (self.options.$body.hasClass('fullscreen')) {
				self.options.screen_mode = 'fullscreen';
			} else {
				self.options.screen_mode = 'docked';
			}
		},

		_tabVisibleRight: function ($tab) {
			var self = this;

			var tab_left = parseInt($tab.position().left);
			var tab_width = parseInt($tab.width());
			var button_bar_width = parseInt(self.options.$button_bar.width());
			if (tab_left + tab_width > button_bar_width) {
				return false;
			}
			return true;
		},

		_tabVisibleLeft: function ($tab) {
			var self = this;

			var tab_left = parseInt($tab.position().left);
			var scroll_left = parseInt(self.options.$button_bar.scrollLeft());
			if (tab_left < scroll_left) {
				return false;
			}
			return true;
		},

		_clickScrollRight: function() {
			var self = this;

			self.options.$button_bar.scrollLeft(parseInt(self.options.$button_bar[0].scrollWidth));
			self._scrollCheck();
		},

		_clickScrollLeft: function() {
			var self = this;

			self.options.$button_bar.scrollLeft(0);
			self._scrollCheck();
		},

		_enterScrollRight: function() {
			var self = this;
			var increment = 10;

			if (self.options.scroll_timeout) {
				self._clearTimeout(self.options.scroll_timeout);
			}

			var cur_scroll = parseInt(self.options.$button_bar.scrollLeft());
			var scroll_width = parseInt(self.options.$button_bar[0].scrollWidth);
			if (cur_scroll <= (scroll_width - increment)) {
				cur_scroll += increment;
				self.options.$button_bar.scrollLeft(cur_scroll);
				self.options.scroll_timeout = self._setTimeout(self._enterScrollRight.bind(self), 50);
			} else {
				self.options.$button_bar.scrollLeft(scroll_width);
			}
			self._scrollCheck();
		},

		_enterScrollLeft: function() {
			var self = this;
			var increment = 10;

			if (self.options.scroll_timeout) {
				self._clearTimeout(self.options.scroll_timeout);
			}

			var cur_scroll = parseInt(self.options.$button_bar.scrollLeft());
			if (cur_scroll >= increment) {
				cur_scroll -= increment;
				self.options.$button_bar.scrollLeft(cur_scroll);
				self.options.scroll_timeout = self._setTimeout(self._enterScrollLeft.bind(self), 50);
			} else {
				self.options.$button_bar.scrollLeft(0);
			}
			self._scrollCheck();
		},

		_leaveScroll: function() {
			var self = this;

			self._clearTimeout(self.options.scroll_timeout);
			self._scrollCheck();
		},

		_scrollToTab: function($tab, first, last) {
			var self = this;

			if (self.options.scroll_timeout) {
				self._clearTimeout(self.options.scroll_timeout);
			}

			var cur_scroll = parseInt(self.options.$button_bar.scrollLeft());
			var bar_width = parseInt(self.options.$button_bar.width());
			var tab_left = parseInt($tab.position().left);
			var tab_width = parseInt($tab.width());

			// Check if we need to scroll left
			if (tab_left < cur_scroll) {
				cur_scroll -= (Math.abs(tab_left) + (first ? 0 : 50));
				self.options.$button_bar.scrollLeft(cur_scroll);
			} else if (tab_left + tab_width > bar_width) {
				cur_scroll += ((tab_width - (bar_width - tab_left)) + (last ? 0 : 50));
				self.options.$button_bar.scrollLeft(cur_scroll);
			}
			self._scrollCheck();
		},

		// Build/refresh our breadcrumbs
		_buildBreadcrumbs: function() {
			var self = this, $self = this.element, i, screens = [], current_screen = {}, $copy, link_text, current_screen_name = self.options.current_screen, $breadcrumbs = $('#breadcrumbs');

			if (!self.options.inverse_nav || !current_screen_name) {
				// We're being called before we have what we need, so let's bail
				return;
			}

			// Get the name of the category/main nav tab
			var root_category = $.extend({}, self.options.inverse_nav[current_screen_name].parent);
			root_category.name = self.options.inverse_nav[current_screen_name].parent.items[0].name;

			// Find the current screen object under the list of items in the tab/category
			for (i in root_category.items) {
				var subitem = root_category.items[i];
				if (subitem.name === current_screen_name) {
					current_screen = $.extend({}, subitem);
					continue;
				}
			}

			// Add the category and current screen to the breadcrumb list
			screens.push(root_category);
			screens.push(current_screen);

			// Add the overlays to the breadcrumb list
			for (i in self.options.overlays) {
				screens.push($.extend({is_overlay: true, level: i, levels: self.options.overlays.length}, self.options.overlays[i]));
			}

			// Setup our UL element which will visually represent the breadcrumbs
			var $ul = $('<ul />');

			// Loop through screens to create a list of breadcrumbs
			for (i in screens) {
				var screen = screens[i], $a;

				if(screen.items) {
					// This is the top-level breadcrumb. Use the title
					link_text = screen.title;
				} else {
					if(screen.is_overlay) {
						// Find the .page_title in this overlay
						$copy = $self.find('.overlay.' + screen.overlay).find('.page_title').eq(0).clone();
					} else {
						// Find the .page_title in this screen
						$copy = $self.find('.screen').find('.page_title').eq(0).clone();
					}

					// Remove various hidden or otherwise undesirable elements from the .page_title copy
					$copy.find('[name="bbx_extension_value"], .custom_filter, .data-table-widget-custom-filter').remove();
					link_text = $copy.text();
				}

				// Check to see if this is the current screen; if yes, does not need to be a link
				if(i != (screens.length - 1)) {
					// Setup the A tag we will use as our breadcrumb link
					$a = $('<a />').text(link_text);
					if(screen.is_overlay) {
						// Setup the URL for our link
						var link = "#!/" + self.options.product_prefix + "/";

						// Loop through screens to determine the components of the URL for the link
						for(var k in screens) {
							var scr = screens[k];
							if(!scr.items && k <= i) {
								if(!scr.is_overlay) {
									// add the screen name to the URL
									link = link + scr.name + "/";
								} else {
									// add our overlay name and our overlay params to the URL
									link = link + "overlay/" + scr.overlay + "/" + encodeURI(scr.overlay_params) + "/";
								}
							}
						}

						// Set the href of our A tag to our URL
						$a.attr('href', link);
					} else {
						// This breadcrumb is for the top or second-level nav, so we don't have to look at overlays to determine the link
						$a.attr('href', "#!/" + self.options.product_prefix + "/" + screen.name);
					}
					self._bind($a, 'click', self._confirmContinue.bind(self));

				} else {
					// Use plain text instead of a link, since it's the current page breadcrumb. Use createTextNode to prevent HTML injection.
					$a = document.createTextNode(link_text);
				}

				// Append our link (or plain text) to a LI which is then appended to our UL
				$ul.append($('<li />').append($a));
			}

			// Set the contents of the #breadcrumbs element to our UL
			$breadcrumbs.empty().append($ul);
		},

		_buildNav: function() {
			var $self = this.element;
			var self = this;

			// Store the navigation elements somewhere else
			self.options.nav = self.options.data;

			// We need a place to store reverse lookups from secondary nav items to primary nav items
			self.options.inverse_nav = {};

			// Get the template for building tabs
			self.options.menu_tab_template = '<li id="$tab.name-menu" class=""><a href="#!/' + self.options.product_prefix + '/$tab.items[0].name"><span class="spinner"></span><span>$tab.title</span></a><div id="$tab.name-nav-level-2" class="nav_level_2" style="width: 1045px; min-width: 239px;"><ul></ul></div></li>';
			self.options.secondary_nav_template = '<li><a id="$item.name-link" href="#!/' + self.options.product_prefix + '/$item.name">$item.title</a></li>';

			if (self.options.left_hand_nav) {
				self.options.$nav_back.empty().detach();
				self.options.$cui_source_inner.append(self.options.$nav_back);
				self.options.$body.removeClass('no-source').addClass('left-hand-nav');
			}

			// Loop through all tabs
			for(var n in self.options.nav) {
				var tab = self.options.nav[n];

				// Build an array of visible secondary menu items based on whether they have the 'hidden' attribute set to 1
				var visible = $.grep(tab.items, function(n) { return !n.hidden; });

				// If there is an image for the tab, then we setup active and inactive images such that
				// the inactive image is the background image on the <td> element of the tab and the
				// active image is the <img> element inside the <td>. Then we can just show or hide the <img>
				// element to determine whether the active or inactive image is displayed.
				var active_img = '';
				if (tab.img) {
					active_img = tab.img.replace(/(\.[^.]+)"\)$/, '-active$1');
				}

				// Build the tab
				var tab_html = self.options.menu_tab_template;
				tab_html = tab_html.replace(/\$tab\.name/g, tab.name);
				tab_html = tab_html.replace(/\$tab\.items\[0]\.name/g, tab.items[0].name);
				tab_html = tab_html.replace(/\$active_img/g, active_img);
				tab_html = tab_html.replace(/\$tab\.title/g, tab.title);

				// Append to the menu bar and make visible if it has visible secondary nav items
				$(tab_html).toggle(visible.length > 0).appendTo(self.options.$button_bar);

				// Get the image <td> cell for the tab
				var $tab = self.options.$button_bar.find('li#'+tab.name+'-menu');

				self._enableScrolling();
				// Tabs always start with tabVisibleLeft returning true
				if (!self._tabVisibleRight($tab)) {
					// Some or all of the tab will be off-screen
					self.options.$scroll_right.show();
				}

				var $secondary = self.options.$button_bar.find('#' + tab.name + '-nav-level-2');
				$secondary.appendTo(self.options.$nav_back);
				$secondary.hide();
				$secondary = $secondary.find('ul');

				// Build secondary navigation links
				for (var i in tab.items) {
					var item = tab.items[i];

					// Reverse navigation lookup table
					item.parent = tab;
					item.$parent = $tab;
					self.options.inverse_nav[item.name] = { 'parent' : tab, '$parent' : $tab };

					// Only add the secondary nav item if it is not hidden
					if (!item.hidden) {
						var item_html = self.options.secondary_nav_template;
						item_html = item_html.replace(/\$tab\.name/g, tab.name);
						item_html = item_html.replace(/\$tab\.items\[0]\.name/g, tab.items[0].name);
						item_html = item_html.replace(/\$active_img/g, active_img);
						item_html = item_html.replace(/\$tab\.tittle/g, tab.title);
						item_html = item_html.replace(/\$item\.name/g, item.name);
						item_html = item_html.replace(/\$item\.title/g, item.title);
						$secondary.append(item_html);
					}
				}
				$secondary.css({ 'height': 'auto' });
			}
			if (self.options.left_hand_nav) {

				self.options.$cui_source_inner.find('li').unwrap().wrap('<div class="cui-source-item-product"/>');
				self.options.$cui_source_inner.find('a').unwrap().wrap('<div class="cui-source-item-inner"/>');
				self.options.$cui_source_inner.find('div.cui-source-item-inner').append('<div class="status"/><div class="chevron"/>');
				self.options.$cui_source_inner.find('.nav_level_2').css({ 'width': '210px', 'min-width': '210px' });
				self._bind(self.options.$nav_back.find('div.cui-source-item-product'), 'click', function(e) {
					if (self._confirmContinue(e)) {
						window.location = $(this).find('a').attr('href');
					}
				});
			}
			//self.options.$button_bar.css({ 'height': max_tab_height + 'px' });
			self.options.$button_bar.show();
		},

		_confirmContinue: function (e) {
			var self = this, $self = this.element;

			if (self.allow_nav_on_dirty) { return true; }

			self._locateButtons();
			e.stopPropagation();

			if (self.options.save_button[0] && !(self.options.save_button.hasClass('state-disabled')) && !(self.options.save_button.getCUIWidget().options.allow_nav_on_dirty)) {
				// A save button is present on the screen and is enabled
				return confirm('There are unsaved changes, are you sure you would like to continue?');
			} else {
				return true;
			}
		},

		_storeURIParams: function($caller, params) {
			var $self = this.element;
			var self = this;

			if (!$caller.attr('uri_id')) {
				debugLog('jquery.pageWidget.js: Missing uri_id on widget attempting to set URI params: ', $caller, ' -- ', $self);
				return;
			}
			// If we don't have a uri_id set on this widget, keep it out of the URI Params chain
			if (!$self.attr('uri_id')) {
				if (self.options.parent && self.options.parent._storeURIParams) {
					self.options.parent._storeURIParams($caller, params);
				}
			}
			var caller_uri_id = $caller.attr('uri_id');

			var uri_params = {};

			uri_params[caller_uri_id] = params;

			var params_string = "/widgets/" + JSON.stringify(uri_params);
			// debugLog(self.options);
			// debugLog(window.location);
			var hash = self.options.hash.replace(/\/+widgets.+/, '') + params_string;
			// debugLog('Page Widget stored URI params to hash location: ', hash);
			location.hash = hash;
		},

		_hashChangeHandler: function() {
			var self = this, $self = this.element, hash;

			hash = unescape(location.hash.replace(/[^#]*#/, '').replace(/\/\//g, '/'));

			// If there is no hash, make one out of the first screen name
			hash = hash || '!/' + self.options.product_prefix + '/' + self.options.nav[0].items[0].name;

			// debugLog('Incoming hash: ', hash);

			self.options.screen_ready = false;
			var loc = window.location;
			if (/embedded/.test(loc.search)) {
				self.options.embedded = true;
				self.options.animate_type = 'none';
				setCookie('embedded_url', loc, 1);
			}

			if (self.options.screen_mode == 'fullscreen') {
				$('body').removeClass('fullscreen');
				self.options.screen_mode = 'docked';
			}

			var orig_hash = hash;
			// Remove the base of the hash that tells us we are on the Barracuda Phone System platform
			hash = hash.replace('!/' + self.options.product_prefix + '/', '');

			if (self.options.hash) {
				self.options.last_hash = self.options.hash;
			}

			self.options.hash = hash;

			// Remove the base of the hash that tells us we are on the Barracuda Phone System platform
			hash = hash.replace('!/' + self.options.product_prefix + '/', '');

			// Split the hash on slashes into a big array
			self.options.hash_arr = hash.split('/');

			// Set up a callback to fill the main widgets on the page with widget URI params
			var uri_param_str = hash.replace(/^.*\/widgets\//, '');
			var uri_param_callback = function(e) {
				var params = {};
				if (uri_param_str.charAt(0) === '{') {
					params = $.parseJSON(uri_param_str);
				}
				self.setURIParams(params);
			};

			if (uri_param_str != hash) {
				if (uri_param_str && (
					!self.options.last_hash || (
						self.options.last_hash && self.options.last_hash.replace(/\/+widgets.+/, '') != self.options.hash.replace(/\/+widgets.+/, '')
					)
				)) {
					self._one($self, 'screenReady', uri_param_callback);
					self._one($self, 'dataTableFinish', uri_param_callback);
				}
			}

			self._showContent(hash);
			self.options.hash = hash;

			// debugLog('Last hash: ', self.options.last_hash);
			// debugLog('Hash: ', self.options.hash);

			if (
				self.options.last_hash &&
				self.options.last_hash.replace(/\/+widgets.+/, '') != self.options.hash.replace(/\/+widgets.+/, '') &&
				(self.options.hash.match(/\/+widgets.+/) || self.options.last_hash.match(/\/+widgets.+/))
			) {
				uri_param_callback('HashChange');
			}

			// debugLog('Outgoing hash: ', orig_hash);

			self.options.hash = orig_hash;
			self.options.last_hash = orig_hash;
			self.options.last_hash_arr = self.options.hash_arr;
		},

		isScreenReady: function() {
			var self = this;
			return self.options.screen_ready;
		},

		getScreen: function() {
			var $self = this.element;
			var self = this;

			return self.options.current_screen;
		},

		getOverlays: function() {
			var $self = this.element;
			var self = this;

			return self.options.overlays;
		},

		getRequestedOverlays: function() {
			var $self = this.element;
			var self = this;

			return self.options.requested_overlays;
		},

		_displayScreen: function(screen) {
			var $self = this.element;
			var self = this;

			var loc = window.location;
			// debugLog("Window.location: ", loc);
			$self.removeClass('screenReady');
			if (/fullscreen/.test(loc.search)) {
				setTimeout(function() { $self.closest('body').addClass('fullscreen'); }, 1);
			}
			if (/embedded/.test(loc.search)) {
				self.options.embedded = true;
				self.options.animate_type = 'none';
				setTimeout(function() { $self.closest('body').addClass('embedded'); }, 1);
			}

			if (!(screen in self.options.inverse_nav)) {
				debugLog('jquery.pageWidget.js: Unknown screen requested: ', screen, ' -- ', $self);
				screen = self.options.nav[0].items[0].name;
				self.options.overlays = [];
			}

			if (screen != self.options.current_screen) {
				self._renderScreen(screen);
			}
		},

		_getComputedWidth: function($elem) {
			var $self = this.element;
			var self = this;

			return $elem.width();
		},

		_renderScreen: function(screen) {
			var $self = this.element;
			var self = this;

			var end_callbacks = function() {
				self._realScreenChange(screen);
			};

			self._callEventHooks('ScreenChange', end_callbacks);
		},

		_getScreenHTML: function(screen) {
			var $self = this.element;
			var self = this;

			var $screen;

			// Screen HTML is actually generated HERE...
			if (screen in screens) {
				$screen = $('<div class="widgetType containerWidget screen-item-container" />').data('data-js', screens[screen]);
			}
			return $screen;
		},

		_realScreenChange: function(screen) {
			var $self = this.element;
			var self = this;

			var $clone = {};
			var $screen = {};

			// Set up a widgetization context for param passing
			var options = {};
			var old_screen = self.options.current_screen;
			var screen_name = screen;
			if (!screen in screens) { screen_name = 'UNKNOWN-SCREEN'; }

			if (!self.options.slide_overlays) {
				$('<div class="screen ' + screen_name + '-screen"></div>').prependTo(self.options.$container);
				$screen = self.options.$container.find('.screen:first');
				$screen.append(self._getScreenHTML(screen));
				$screen.attr('screen-name', screen);

				self._one($screen, 'ready', function() {
					// On a screen change, we remove all other screens and overlays
					if (!self.options.overlays.length) {
						self.options.$container.find('.screen, .overlay').not(self.options.$container.find('.screen:first')).each(function() {
							$(this).remove();
						});
					}

					self._hideSecondaryNav(old_screen);
					self._showSecondaryNav(screen);

					var $content = $screen.find('.cui-page-content');
					var $header = $screen.find('.page_title');
					var $breadcrumbs = $('#breadcrumbs');
					var header_height = parseInt($header.height());
					var breadcrumbs_height = parseInt($breadcrumbs.outerHeight());
					var page_content_height = parseInt(self.options.$cui_content_inner.height()) - header_height - breadcrumbs_height;

					if (navigator.userAgent.indexOf('MSIE 8') !== -1) {
						page_content_height = page_content_height - 80;
					}

					$content.css({ 'height': page_content_height + 'px', 'top': header_height + 'px' });

					self.options.screen_ready = true;
					$self.addClass('screenReady').trigger('screenReady');
					self._trigger('screenReady');

					// Remove any old screen
					var $screens = self.options.$container.find('.screen');
					if ($screens.size() > 1) {
						$screens.filter(':last').remove();
					}

					if (old_screen) {
						self._hideSecondaryNav(old_screen);
					}
					self._showSecondaryNav(screen);
					self.options.current_screen = screen;

					self._trigger('ScreenChangeFinished');
					$self.trigger('ScreenChangeFinished');
					if (!self.options.overlays.length) {
						$screen.show();
					}

					self._buildBreadcrumbs();
				});

				// Then widgetize the screen's contents
				$screen.widget(options);
			} else {
				// If we already have a screen, then do a fancy slide to add the new screen
				if (self.options.viewport_width && self.options.overlays.length) {
					// Make a deep clone of the slide container and put it in the background so we don't see changes to it
					$clone = self.options.$container.clone();

					$clone.prependTo(self.options.$container.closest('.slide-outer'));
					self.options.$container.addClass('clone');
					var container_left = parseInt(self.options.$container.css('left'));

					// Add another viewport of width to it for the new screen
					self.options.$container.css('width', (self._getComputedWidth(self.options.$container) + self.options.viewport_width) + 'px');

					// Prepend the new screen to the slide container
					$('<div class="screen"></div>').prependTo(self.options.$container);
					$screen = self.options.$container.find('.screen:first');
					$screen.css('width', self.options.viewport_width + 'px');
					$screen.append(self._getScreenHTML(screen));

					// Move the old content to the right
					self.options.$container.find('.screen, .overlay').not(self.options.$container.find('.screen:first')).each(function() {
						$(this).css('left', (parseInt($(this).css('left')) + self.options.viewport_width) + 'px');
					});

					// Position the new slide container so the same content is in the viewport
					self.options.$container.css('left', (container_left - self.options.viewport_width) + 'px');

					// Bind to screen ready event to know when all elements are fully done
					self._one($screen, 'ready', function() {
						// debugLog('Emitting screenReady.');
						self.options.screen_ready = true;
						$self.addClass('screenReady').trigger('screenReady');
						self._trigger('screenReady');

						// Remove the old slide container and show the clone
						self.options.$container.removeClass('clone');
						$clone.remove();

						// Slide the slide-container to the right until the new screen is visible
						self.options.animate_in_progress = true;
						self.options.$container.animate({
							left: '0px'
						}, {
							duration: self.options.animate_length,
							// When done, remove the old screen and overlays
							complete: function() {
								self.options.$container.find('.screen, .overlay').not(self.options.$container.find('.screen:first')).remove();
								self.options.$container.css('width', self.options.viewport_width + 'px');
								self.options.last_overlay = 0;
								self.options.overlays = [];
								self.options.animate_in_progress = false;

								self._hideSecondaryNav(old_screen);
								self._showSecondaryNav(screen);

								// We are now on the new screen
								self.options.current_screen = screen;
								self._trigger('ScreenChangeFinished');
								$self.trigger('ScreenChangeFinished');
							}
						});
					});

					// Widgetize the screen
					$screen.widget(options);

				} else {
					// Make a deep clone of the slide container and put it in the background so we don't see changes to it
					$clone = self.options.$container.clone();

					$clone.prependTo(self.options.$container.closest('.slide-outer'));
					self.options.$container.addClass('clone');

					$('<div class="screen"></div>').prependTo(self.options.$container);
					$screen = self.options.$container.find('.screen:first');
					$screen.append(self._getScreenHTML(screen));

					// Get the computed width of the screen
					self.options.viewport_width = parseInt(self.options.$content.width());
					// debugLog("Calculated width: ", self.options.viewport_width);

					// Update the width of the screen so it is in pixels
					$screen.css('width', self.options.viewport_width + 'px');

					// Bind to screen ready event to know when all elements are fully done
					self._one($screen, 'ready', function() {
						// debugLog('Emitting screenReady.');
						self.options.screen_ready = true;

						// Remove the old slide container and show the clone
						self.options.$container.removeClass('clone');
						$clone.remove();

						$self.addClass('screenReady').trigger('screenReady');
						self._trigger('screenReady');

						// Remove any old screen
						var $screens = self.options.$container.find('.screen');
						if ($screens.size() > 1) {
							$screens.filter(':last').remove();
						}

						if (old_screen) {
							self._hideSecondaryNav(old_screen);
						}
						self._showSecondaryNav(screen);
						self.options.current_screen = screen;
						self._trigger('ScreenChangeFinished');
						$self.trigger('ScreenChangeFinished');
					});

					// Then widgetize the screen's contents
					$screen.widget(options);
				}
			}
		},

		_hideSecondaryNav: function(screen) {
			var $self = this.element;
			var self = this;

			if (!self.options.inverse_nav || !self.options.inverse_nav[screen] || !self.options.inverse_nav[screen].parent) {
				return;
			}
			var tab = self.options.inverse_nav[screen].parent;
			var $tab = self.options.inverse_nav[screen].$parent;
			var $secondary = $(document.getElementById(tab.name + '-nav-level-2'));
			$secondary.hide();
			if (self.options.left_hand_nav) {
				$secondary.find('div.cui-source-item-product.state-selected').removeClass('state-selected');
			} else {
				$secondary.find('li').removeClass('state-selected');
			}
			$tab.removeClass('state-selected');
		},

		_showSecondaryNav: function(screen) {
			var $self = this.element;
			var self = this;

			var tab = self.options.inverse_nav[screen].parent;
			var $tab = self.options.inverse_nav[screen].$parent;
			var $secondary = $(document.getElementById(tab.name + '-nav-level-2'));
			$secondary.show();

			//	    var secondary_nav_height = 38 + (((getHeight($secondary) / 19) - 1) * 19);
			var secondary_nav_height = 19 + $secondary.outerHeight();
			self.options.$nav_back.css({ 'height': secondary_nav_height + 'px' });
			if (!self.options.left_hand_nav) {
				self.options.$content.css({ 'top': (70 + secondary_nav_height) + 'px' });
			} else {
				self.options.$content.css({ 'top': '70px', 'border-top': '0px'});
			}
			if (secondary_nav_height / 28 > 2) {
				$self.find('.cui-page-content').addClass('double-high-nav');
			} else {
				$self.find('.cui-page-content').removeClass('double-high-nav');
			}
			// debugLog('Secondary nav height: ', $secondary, secondary_nav_height);
			// debugLog('Cui content: ', self.options.$content);
			// debugLog('Secondary display: ', $secondary.css('display'), '; height: ', $secondary.css('height'), '; position: ', $secondary.css('position'));
			$tab.addClass('state-selected');
			if (self.options.left_hand_nav) {
				self.options.$nav_back.find('div.cui-source-item-product.state-selected').removeClass('state-selected');
				$secondary.find('#' + screen + '-link').closest('div.cui-source-item-product').addClass('state-selected');
			} else {
				self.options.$nav_back.find('li.state-selected').removeClass('state-selected');
				$secondary.find('#' + screen + '-link').closest('li').addClass('state-selected');
			}
		},

		/**
	 * @fn _showContent(hash)
	 * @brief This method is called when a link is clicked and the URL hash location is updated, or when
	 *        the page is initially loaded and the hash location is set. It parses the hash and displays
	 *        the screen and any overlays requested.
	 */
		_showContent: function(hash) {
			var self = this, $self = this.element, overlay_params, widget_params;

			// Clone the hash array
			var hash_arr = [];
			for(var i in self.options.hash_arr) {
				hash_arr[i] = self.options.hash_arr[i];
			}

			// First item in the array is the screen name
			var screen = hash_arr.shift();

			if (screen == 'statusfull') {
				$self.closest('body').addClass('fullscreen');
				screen = 'status';
			}

			var tmp = '';
			var overlays = {}, overlay;
			self.options.requested_overlays = [];

			// remaining items in the array are 'overlay'/overlay_name/overlay_params
			// and optionally a /widgets/widget_params section after overlay_params
			// if there is no overlay in the first shift, the widget params are for the screen
			while((tmp = hash_arr.shift()) == 'overlay' || tmp == 'widgets') {
				if (tmp == 'overlay') {
					var overlay_name = hash_arr.shift();
					overlay_params = hash_arr.shift();
					var widgets = hash_arr.shift();
					if (widgets === 'widgets') {
						widget_params = hash_arr.shift();
					} else { // If it wasn't the optional 'widgets' section, then put the value back on for the next round
						hash_arr.unshift(widgets);
					}
					overlays[overlay_name] = { 'overlay_params': overlay_params, 'widget_params': widget_params };
					self.options.requested_overlays.push({ 'name': overlay_name, 'overlay_params': overlay_params, 'widget_params': widget_params });
				} else if (tmp == 'widgets') {
					self.options.screen_widget_params = hash_arr.shift();
				}
			}
			// debugLog('Overlays: ', overlays);
			// debugLog('Requested Overlays: ', self.options.requested_overlays);
			// debugLog('Current screen: ', self.options.current_screen);
			// debugLog('Screen: ', screen);
			var $tab;
			// If we are not on the correct screen, then we need to display the correct one for any overlays
			if ((self.options.current_screen && self.options.current_screen != screen) || !self.options.current_screen) {
				if (!self.options.inverse_nav[screen]) {
					window.location = window.location.protocol + '//' + window.location.host +
						(window.location.port == 80 ? '' : ':' + window.location.port) +
						window.location.pathname + window.location.search;
					return;
				}
				$tab = self.options.inverse_nav[screen].$parent;
				$tab.addClass('state-loading');
				self._scrollToTab($tab);
				$self.one('ScreenChangeFinished', function() {
					$tab.removeClass('state-loading');
				});
				// debugLog('Displaying screen: ', screen);
				self._displayScreen(screen);
			}

			// After all overlays requested are guaranteed to be on the screen, we must remove any overlays
			// that are no longer supposed to be there
			if (!self.options.suppress_remove) {
				// debugLog('Removing missing overlays.');
				self._removeMissingOverlays(overlays);
			} else {
				// debugLog('Suppressing removal of overlays.');
				self.options.suppress_remove = false;
			}

			// Then display all the overlays
			for(overlay in overlays) {
				if (self.options.current_screen && self.options.current_screen in self.options.inverse_nav) {
					$tab = self.options.inverse_nav[self.options.current_screen].$parent;
					$tab.addClass('state-loading');
					$self.one('OverlayChangeFinished', function() {
						$tab.removeClass('state-loading');
					});
				}

				overlay_params = overlays[overlay].overlay_params;
				widget_params = overlays[overlay].widget_params;
				// debugLog('Drawing overlay: ', overlay, overlay_params, widget_params);
				// Draw each overlay
				self._addOverlay(overlay, overlay_params, widget_params);
			}

			// After all overlays requested are guaranteed to be on the screen, we must remove any overlays
			// that are no longer supposed to be there
			if (!self.options.suppress_remove) {
				// debugLog('Removing missing overlays.');
				self._removeMissingOverlays(overlays);
			} else {
				// debugLog('Suppressing removal of overlays.');
				self.options.suppress_remove = false;
			}

		},

		/**
	 * @fn addOverlay(overlay, params)
	 * @brief This method is the starting point for adding an overlay onto the screen. It must preserve
	 *        existing overlays and simply collapse them and add a new one.
	 */
		_addOverlay: function(overlay, overlay_params, widget_params) {
			var self = this, $self = this.element;

			var end_callback = function() {
				self._realAddOverlay(overlay, overlay_params, widget_params);
			};

			self._callEventHooks('OverlayChanged', end_callback);
		},

		_realAddOverlay: function(overlay, overlay_params, widget_params) {
			var self = this, $self = this.element, i, $overlay;

			if (self.options.last_overlay < 0) {
				self.options.last_overlay = 0;
			}
			overlay_params = jQuery.parseJSON(overlay_params) || {};
			widget_params = jQuery.parseJSON(widget_params) || {};

			// debugLog('Adding overlay.');
			// Check if the overlay already exists
			$overlay = $self.find('.overlay.' + overlay);
			if ($overlay[0]) {
				// We already have this overlay, check if params are the same
				var overlay_widget = $overlay.getCUIWidget();

				if (JSON.stringify(overlay_widget.getRestParams()) == JSON.stringify(overlay_params)) {
					// debugLog('Updating widget params for existing overlay.');
					overlay_widget.setURIParams(widget_params);
				}
				// self.options.suppress_remove = true;
				self.options.screen_ready = true;
				$self.addClass('screenReady').trigger('screenReady');
				self._trigger('screenReady');
				var $tab = self.options.inverse_nav[self.options.current_screen].$parent;
				$tab.removeClass('state-loading');
				return;
			}



			var vertical = false;
			var cur_overlay_count = 0;
			for (i in self.options.last_hash_arr) {
				if (self.options.last_hash_arr[i] == 'overlay') {
					cur_overlay_count++;
				}
			}
			var new_overlay_count = 0;
			for (i in self.options.hash_arr) {
				if (self.options.hash_arr[i] == 'overlay') {
					new_overlay_count++;
				}
			}
			// debugLog(new_overlay_count + ' == ' + cur_overlay_count);

			self.options.last_overlay++;
			if (self.options.last_overlay == 1) {
				self.options.suppress_remove = true;
			}

			// debugLog('Vertical: ' + vertical);

			var $container = self.options.$container;

			// Set up a widgetization context for param passing
			var context = { options: {} };

			if ('rest_params' in context.options) {
				$.extend(context.options.rest_params, overlay_params);
			} else {
				context.options.rest_params = overlay_params;
			}

			if (!self.options.slide_overlays) {
				$overlay = $('<div class="overlay overlay_' + self.options.last_overlay + ' ' + overlay + '-screen ' + overlay + '"></div>');
				$overlay.appendTo($container);
				$overlay = $container.find('> .overlay_' + self.options.last_overlay + ':last');
				$overlay.attr('screen-name', overlay);

				// Append the overlay's contents to the cloned container's new overlay div
				$overlay.append(self._getScreenHTML(overlay));

				// Bind to screen ready event to know when all elements are fully done
				self._one($overlay, 'ready', function() {
					$container.find('.overlay, .screen').not($overlay).each(function() {
						$(this).hide();
					});

					var $content = $overlay.find('.cui-page-content');
					var $header = $overlay.find('.page_title');
					var $breadcrumbs = $self.find('#breadcrumbs');
					var header_height = parseInt($header.height());
					var breadcrumbs_height = parseInt($breadcrumbs.outerHeight());
					var page_content_height = parseInt(self.options.$cui_content_inner.height()) - header_height - breadcrumbs_height;

					if (navigator.userAgent.indexOf('MSIE 8') !== -1) {
						page_content_height = page_content_height - 80;
					}

					$content.css({ 'height': page_content_height + 'px', 'top': header_height + 'px' });

					self.options.screen_ready = true;
					$self.addClass('screenReady').trigger('screenReady');
					self._trigger('screenReady');

					self.options.animate_in_progress = false;

					self._trigger('OverlayChangeFinished');
					$self.trigger('OverlayChangeFinished');
					$overlay.show();
				});

				// Then widgetize the contents of the overlay
				$overlay.widget(context.options);
			} else {
				if (!vertical) {
					// debugLog('Doing horizontal overlay addition.');
					// Make a visual clone of the slide container so nothing changes on screen
					var $clone = $container.clone();
					$clone.prependTo($container.closest('div.slide-outer'));
					$container.addClass('clone');

					// Increase the width of the slide container to hold a new overlay
					var old_width = parseInt($container.css('width'));
					if (self.options.animate_type == 'width') {
						$container.css('width', (old_width + (self.options.viewport_width / 2)) + 'px');
						// debugLog('#1 Old width: ', old_width, '; viewport width: ', self.options.viewport_width, '; new width: ', (old_width + (self.options.viewport_width / 2)));
					} else if (self.options.animate_type == 'none') {
						$container.css('width', old_width + 'px');
						// debugLog('#2 Old width: ', old_width, '; viewport width: ', self.options.viewport_width, '; new width: ', old_width);
					} else {
						$container.css('width', (self.options.viewport_width * (self.options.last_overlay + 1)) + 'px');
						// debugLog('#3 Old width: ', old_width, '; viewport width: ', self.options.viewport_width, '; new width: ', (self.options.viewport_width * (self.options.last_overlay + 1)));
						old_width = (self.options.viewport_width * self.options.last_overlay);
					}

					// Add a container for the overlay to the slide container
					$overlay = $('<div class="overlay overlay_' + self.options.last_overlay + ' ' + overlay + '"></div>');
					$overlay.appendTo($container);
					$container = $container.find('> .overlay_' + self.options.last_overlay);
					$container.css({
						'left': old_width + 'px',
						'top': '0px'
					});

					// Append the overlay's contents to the cloned container's new overlay div
					$container.append(self._getScreenHTML(overlay));

					// Bind to screen ready event to know when all elements are fully done
					self._one($container, 'ready', function() {
						var animate_prop;
						$overlay.css({
							'width': self.options.viewport_width + 'px',
							'height': '100%'
						});
						// debugLog('Overlay starting left: ', old_width, '; top: 0px');
						// debugLog('Emitting screenReady.');
						self.options.screen_ready = true;
						$self.addClass('screenReady').trigger('screenReady');
						self._trigger('screenReady');

						// Display the original container instead of the visual clone container
						self.options.$container.removeClass('clone');
						// debugLog(self.options);
						if (self.options.animate_type == 'none') {
							self.options.$container.find('.overlay, .screen').eq(0).hide();
						}
						$clone.remove();

						if (self.options.last_overlay == 1) {
							animate_prop = {};
							if (self.options.animate_type == 'width') {
								$.extend(animate_prop, { width : (self.options.viewport_width / 2) + 'px' });
								// debugLog('Animating width to: ', self.options.viewport_width / 2);
								self.options.$container.find('.overlay').addClass('half-width');
							} else if (self.options.animate_type == 'slide') {
								$.extend(animate_prop, { left : '-' + self.options.viewport_width + 'px'});
								// debugLog('Animating left to: ', -1 * self.options.viewport_width);
							}
							self.options.animate_in_progress = true;
							self.options.$container.find('.screen').animate(animate_prop, {
								duration: self.options.animate_length,
								step: self.options.add_overlay_animate_step_function_closure,
								complete: function() {
									if (self.options.animate_type == 'width') {
										self.options.$container.css('width', self.options.viewport_width + 'px');
									}
									self.options.animate_in_progress = false;

									self._trigger('OverlayChangeFinished');
									$self.trigger('OverlayChangeFinished');
								}
							});
						} else {
							animate_prop = {};
							if (self.options.animate_type == 'width') {
								$.extend(animate_prop, { left : (parseInt($clone.css('left')) - (self.options.viewport_width / 2)) + 'px' });
								self.options.$container.find('.overlay').addClass('half-width');
							} else if (self.options.animate_type == 'slide') {
								// debugLog('Viewport width: ', self.options.viewport_width);
								// debugLog('Animating left to: ', (-1 * (self.options.viewport_width * self.options.last_overlay)) + 'px');
								$.extend(animate_prop, { left : (-1 * (self.options.viewport_width * self.options.last_overlay)) + 'px'});
							}
							self.options.animate_in_progress = true;
							self.options.$container.find('.screen').animate(animate_prop, {
								duration: self.options.animate_length,
								step: self.options.add_overlay_animate_step_function_closure,
								complete: function() {
									self.options.animate_in_progress = false;

									self._trigger('OverlayChangeFinished');
									$self.trigger('OverlayChangeFinished');
								}
							});
						}
					});

					// Then widgetize the contents of the overlay
					$container.widget(context.options);
				} else {
					// debugLog('Doing vertical overlay addition.');
					// For a vertical animation, remove the scroll bar
					// This hides the absolutely positioned elements!
					//		$container.css('overflow-y', 'hidden');

					// Add a container for the overlay to the cloned slide container
					var $new_div = $('<div class="overlay overlay_' + self.options.last_overlay + '" style="opacity: 0;"></div>');
					var $old_overlay = {};
					var $new_overlay = {};
					if (self.options.up) {
						$new_div.appendTo($container);
						$old_overlay = $container.find('> .overlay_' + self.options.last_overlay + ':first');
						$new_overlay = $container.find('> .overlay_' + self.options.last_overlay + ':last');
						$container = $new_overlay;
					} else {
						$new_div.prependTo($container);
						$old_overlay = $container.find('> .overlay_' + self.options.last_overlay + ':last');
						$new_overlay = $container.find('> .overlay_' + self.options.last_overlay + ':first');
						$container = $new_overlay;
					}
					if (self.options.animate_type == 'width') {
						$container.css('left', (self.options.viewport_width / 2) + 'px');
					} else {
						$container.css('left', self.options.viewport_width + 'px');
					}

					// Append the overlay's contents to the cloned container's new overlay div
					$container.append(self._getScreenHTML(overlay));
					if (self.options.up) {
						$container.css('top', $old_overlay.height() + 'px');
					} else {
						$container.css('top', (-1 * $new_overlay.height()) + 'px');
					}

					// Bind to screen ready event to know when all elements are fully done
					self._one($container, 'ready', function() {
						$new_overlay.css({
							'width': self.options.viewport_width + 'px',
							'height': '100%'
						});
						// debugLog('Emitting screenReady.');
						self.options.screen_ready = true;
						$self.addClass('screenReady').trigger('screenReady');
						self._trigger('screenReady');

						$container.css('opacity', '1');

						if (self.options.up) {
							self.options.animate_in_progress = true;
							$new_overlay.animate({
								top: '0px'
							}, {
								//			duration: (self.options.animate_length * 2),
								duration: 0,
								step: self.options.add_overlay_animate_step_function_closure,
								complete: function() {
									$old_overlay.remove();
									self.options.animate_in_progress = false;
									self.options.up = false;

									self._trigger('OverlayChangeFinished');
									$self.trigger('OverlayChangeFinished');
								}
							});
						} else {
							self.options.animate_in_progress = true;
							$new_overlay.animate({
								top: '0px'
							}, {
								//			duration: (self.options.animate_length * 2),
								duration: 0,
								step: self.options.add_overlay_animate_step_function_closure,
								complete: function() {
									$old_overlay.remove();
									self.options.animate_in_progress = false;

									self._trigger('OverlayChangeFinished');
									$self.trigger('OverlayChangeFinished');
								}
							});
						}
					});

					// Then widgetize the contents of the overlay
					$container.widget(context.options);
				}
			}

			// We are now on the new screen, so save it to our array
			var overlay_obj = { 'overlay' : overlay, 'overlay_params': JSON.stringify(overlay_params), 'widget_params': JSON.stringify(widget_params)};
			// debugLog('Adding overlay to overlay list: ', overlay_obj);
			self.options.overlays.push(overlay_obj);

			self._buildBreadcrumbs();
		},


		_addOverlayStepFunctionCallback: function(now, fx) {
			var $self = this.element;
			var self = this;

			var $screen = self.options.$container.find('.screen');
			var $first_overlay = self.options.$container.find('.overlay:first');
			var $last_overlay = self.options.$container.find('.overlay:last');

			if (fx.prop == 'width') {
				// We are animating the first overlay addition
				$screen.css('width', now + 'px');
				$first_overlay.css('left', parseInt($screen.css('width')) + 'px');
			} else if(fx.prop == 'left' && fx.start > fx.end) {
				// We are animating the second or later overlay addition
				self.options.$container.css('left', now + 'px');
			} else if(fx.prop == 'left' && fx.start < fx.end) {
				self.options.$container.css('left', now + 'px');
			} else if(fx.prop == 'top') {
				var height = $first_overlay.height();
				if (self.options.up) {
					// debugLog('Going up: first overlay = ' + (now - height) + 'px ; last overlay = ' + now + 'px');
					$first_overlay.css('top', (now - height) + 'px');
					$last_overlay.css('top', now + 'px');
				} else {
					// debugLog('Going down: first overlay = ' + now + 'px ; last overlay = ' + (now + height) + 'px');
					$first_overlay.css('top', now + 'px');
					$last_overlay.css('top', (now + height) + 'px');
				}
			}
		},

		/**
	 * @fn removeMissingOverlays(overlays)
	 * @brief This method removes any overlays that were on the screen already but are no longer supposed
	 *        to be there according to the hash location.
	 */
		_removeMissingOverlays: function(overlays) {
			var $self = this.element;
			var self = this;

			var remove_count = 0;
			for (var o = self.options.overlays.length - 1; o >= 0; o--) {
				var remove = true;
				for (var overlay in overlays) {
					var params = overlays[overlay].overlay_params;
					if (self.options.overlays[o].overlay == overlay && self.options.overlays[o].overlay_params == params) {
						remove = false;
						break;
					}
				}
				if (remove) {
					remove_count++;
				}
			}


			// debugLog('Remove count: ', remove_count);
			// debugLog('Overlays: ', overlays);
			// debugLog('self.options.overlays: ', $.extend(true, [], self.options.overlays));
			var orig_remove_count = remove_count;
			// Create a closure for removing the last overlay and tracking remove_count
			if (remove_count) {
				if (self.options.current_screen && self.options.current_screen in self.options.inverse_nav) {
					var $tab = self.options.inverse_nav[self.options.current_screen].$parent;
					$tab.addClass('state-loading');
					$self.one('OverlayChangeFinished', function() {
						$tab.removeClass('state-loading');
					});
				}

				self.removeLastOverlay(orig_remove_count, remove_count);
			}
		},

		removeLastOverlay: function(orig_remove_count, remove) {
			var $self = this.element;
			var self = this;
			var animate_prop;

			if (remove > 0) {
				remove--;
				if (!self.options.slide_overlays) {
					self.options.$container.find('.overlay_' + self.options.last_overlay).remove();
					self.options.$container.find('.overlay, .screen').eq(-1).show();
					self.options.last_overlay--;
					self.options.overlays.splice(self.options.last_overlay, 1);
					// debugLog('Last overlay: ', self.options.last_overlay);
					// debugLog('self.options.overlays: ', $.extend(true, [], self.options.overlays));

					// Call the bindings for each overlay change we do
					self._trigger('OverlayChangeFinished');
					$self.trigger('OverlayChangeFinished');
					self.options.animate_in_progress = false;
					if (remove) {
						self.removeLastOverlay(orig_remove_count, remove);
					}
				} else {
					if (self.options.last_overlay == 1) {
						self.options.animate_in_progress = true;
						animate_prop = {};
						if (self.options.animate_type == 'width') {
							self.options.$container.css('width', (self.options.viewport_width + (self.options.viewport_width / 2)) + 'px');
							$.extend(animate_prop, { width: self.options.viewport_width + 'px'});
						} else if (self.options.animate_type == 'slide') {
							$.extend(animate_prop, { left: '0px' });
						}
						self.options.$container.find('.screen').animate(animate_prop, {
							duration: (self.options.animate_length / orig_remove_count),
							step: self.options.add_overlay_animate_step_function_closure,
							complete: function() {
								self.options.$container.find('.overlay_' + self.options.last_overlay).remove();
								self.options.$container.find('.overlay, .screen').eq(-1).show();
								self.options.$container.css('width', self.options.viewport_width + 'px');
								self.options.last_overlay--;
								self.options.overlays.splice(self.options.last_overlay, 1);
								// debugLog('Last overlay: ', self.options.last_overlay);
								// debugLog('self.options.overlays: ', $.extend(true, [], self.options.overlays));

								// Call the bindings for each overlay change we do
								self._trigger('OverlayChangeFinished');
								$self.trigger('OverlayChangeFinished');
								self.options.animate_in_progress = false;
								if (remove) {
									self.removeLastOverlay(orig_remove_count, remove);
								}
							}
						});
					} else if (self.options.last_overlay > 1) {
						// debugLog('Removing overlay > 1');
						animate_prop = {};
						if (self.options.animate_type == 'width') {
							$.extend(animate_prop, { left: (parseInt(self.options.$container.css('left')) + (self.options.viewport_width / 2)) + 'px' });
						} else if (self.options.animate_type == 'slide') {
							// debugLog('Current left: ', parseInt(self.options.$container.css('left')));
							// debugLog('Viewport width: ', self.options.viewport_width);
							// debugLog('Animating left to: ', (parseInt(self.options.$container.css('left')) + self.options.viewport_width) + 'px');
							$.extend(animate_prop, { left: (parseInt(self.options.$container.css('left')) + self.options.viewport_width) + 'px' });
						}
						self.options.$container.find('.screen').animate(animate_prop, {
							duration: (self.options.animate_length / orig_remove_count),
							step: self.options.add_overlay_animate_step_function_closure,
							complete: function() {
								self.options.$container.find('.overlay_' + self.options.last_overlay).remove();
								self.options.$container.find('.overlay, .screen').eq(-1).show();
								self.options.$container.css('width', self.options.viewport_width + 'px');
								self.options.last_overlay--;
								self.options.overlays.splice(self.options.last_overlay, 1);
								// debugLog('Last overlay: ', self.options.last_overlay);
								// debugLog('self.options.overlays: ', $.extend(true, [], self.options.overlays));

								// Call the bindings for each overlay change we do
								self._trigger('OverlayChangeFinished');
								$self.trigger('OverlayChangeFinished');
								self.options.animate_in_progress = false;
								if (remove) {
									self.removeLastOverlay(orig_remove_count, remove);
								}
							}
						});
					}
				}
			} else {
				self.options.animate_in_progress = false;
			}

			self._buildBreadcrumbs();
		},

		_forceFullScreen: function() {
			$('#cudatel_nav li').not('.cui-logo').hide();
			$('body').addClass('fullscreen');
		},

		_locateButtons: function() {
			var $self = this.element;
			var self = this;

			self.options.save_button = $self.find('.page_save_button');
			self.options.cancel_button = $self.find('.page_cancel_button');
		},

		_bindPageButtons: function() {
			var $self = this.element;
			var self = this;

			$self.on('click', '.page_save_button', self.options.page_save_closure);
			$self.on('click', '.page_cancel_button', self.options.page_cancel_closure);
		},

		_processPage: function() {
			var $self = this.element;
			var self = this;

			widgetize_children($self, self);
		},

		_savePageHandler: function (e) {
			e.preventDefault();
			this.savePage();
		},

		savePage: function () {
			var self = this, $self = this.element, f_idx, $forms = $self.find('.formWidgetType.is-dirty').not('.is-invalid'), ref__countSubmit, formWidget;

			if (self.options._form_count) {
				// Something is already submitting!
				return false;
			}

			$self.trigger('pageSubmitStart');

			self.options._form_count = $forms.length;

			ref__countSubmit = CUI.FunctionFactory.build(self._countSubmit, self, { context: 'argument', first: 'context' });
			self._bind($forms, 'SubmitSuccess', ref__countSubmit);
			self._bind($forms, 'SubmitError', ref__countSubmit);
			self._bind($forms, 'SubmitAbort', ref__countSubmit);

			for (f_idx = 0; f_idx < $forms.length; f_idx++) {
				formWidget = $forms.eq(f_idx).getCUIWidget('formWidget') ? $forms.eq(f_idx).getCUIWidget('formWidget') : $forms.eq(f_idx).getCUIWidget('dirtyFormWidget');
				if (formWidget) {
					formWidget.submit();
				}
			}

			return true;
		},

		_countSubmit: function (elem, e) {
			var self = this, $self = this.element;
			if (e.type === 'SubmitError') {
				++self.options._form_fail_count;
			}

			if (--self.options._form_count < 1) {
				$self.trigger('pageSubmitComplete', { error_count: self.options._form_fail_count });
				self.options._form_fail_count = 0;
				self.options._form_count = 0;
			}
		},

		_pageSubmitStartHandler: function() {
			var self = this, $self = this.element;
			self._enableWaitingState();
		},

		_pageSubmitCompleteHandler: function() {
			var self = this, $self = this.element;
			self._disableWaitingState();
		},

		// Indicate that we are waiting for a response from the server
		_enableWaitingState: function() {
			var self = this, $self = this.element;
			// Put this on an interval, so that if our request returns in half a second, we don't go into a 'wait' state
			if(!self.options._save_interval) {
				self.options._save_interval = setInterval(function(){
					$('.page_buttons .spinner').show();
					$('body').css('cursor','wait');
					$self.addClass('waiting');
				}, self.options.save_indicator_threshold || 500);
			}
			if(!self.options._wait_cursor_interval) {
				// Reset our cursor in case it takes particularly long / there's an error / etc.
				self.options._wait_cursor_interval = setInterval(function(){
					$('body').css('cursor', 'auto');
				}, self.options.wait_cursor_max_time || 8000);
			}
		},

		// All forms have been submitted and received a response. Disable the indicator
		_disableWaitingState: function() {
			var self = this, $self = this.element;
			clearInterval(self.options._save_interval);
			clearInterval(self.options._wait_cursor_interval);
			delete self.options._save_interval;
			delete self.options._wait_cursor_interval;
			$('.page_buttons .spinner').hide();
			$('body').css('cursor','auto');
			$self.removeClass('waiting');
		},

		_resetPageHandler: function (e) {
			e.preventDefault();
			this.resetPage();
		},

		resetPage: function() {
			var self = this, $self = this.element, f_idx, $forms = $self.find('.formWidgetType.is-dirty');
			for (f_idx = 0; f_idx < $forms.length; f_idx++) {
				$forms.eq(f_idx).getCUIWidget('formWidget').reset();
			}
		},

		/*
	enableSaveButton: function($elem) {
	    var $self = this.element;
	    var self = this;

	    var $button = $elem.closest('.screen, .overlay').find('.page_save_button');
	    $button.removeClass('state-disabled').removeAttr('disabled');
	},

	enableCancelButton: function($elem) {
	    var $self = this.element;
	    var self = this;

	    var $button = $elem.closest('.screen, .overlay').find('.page_cancel_button');
	    $button.removeClass('state-disabled').removeAttr('disabled');
	},

	disableSaveButton: function($elem) {
	    var $self = this.element;
	    var self = this;

	    var $button = $elem.closest('.screen, .overlay').find('.page_save_button');
	    $button.addClass('state-disabled').attr('disabled', 'disabled');
	},

	disableCancelButton: function($elem) {
	    var $self = this.element;
	    var self = this;

	    var $button = $elem.closest('.screen, .overlay').find('.page_cancel_button');
	    $button.addClass('state-disabled').attr('disabled', 'disabled');
	},
	*/

		addMessage: function(type, name, mesg) {
			var $self = this.element;
			var self = this;

			var $message = $self.find('.page_messages');
			if (!$message.find('#' + name + '-' + type).is('span')) {
				$message.append('<span id="' + name + '-' + type + '" class="' + type + '">' + mesg + '</span>');
			} else {
				$message.find('#' + name + '-' + type).html(mesg);
			}
			$message.addClass('state-error');
		},

		delMessage: function(type, name) {
			var $self = this.element;
			var self = this;

			var $message = $self.find('.page_messages');
			$message.find('#' + name + '-' + type).remove();
			if (!$message.context.children.length) {
				$message.removeClass('state-error');
			}
		},

		clearMessages: function() {
			var $self = this.element;
			var self = this;

			var $message = $self.find('.page_messages');
			$message.empty();
			$message.removeClass('state-error');
		},

		back: function() {
			var $self = this.element, self = this;
			window.history.back();
		},

		showScreen: function(name) {
			var $self = this.element;
			var self = this;

			if (!self.options.animate_in_progress) {
				self._processPage();
			}
		},

		_logoutHandler: function () {
			var self = this, $self = this.element;
			$self.find('div.slide-container > div').remove();
			$self.find('div.slide-outer').remove();
			$('#cudatel_nav').children().remove();

			self.options.data = {};
			self.options.nav = null;
			self.options.inverse_nav = null;
			self.options.hash = null;
			self.options.hash_arr = null;
			self.options.last_hash = null;
			self.options.last_hash_arr = null;
			self.options.requested_overlays = null;
		},

		showOverlay: function(name, params, widget_params, $caller, up, suppress_history) {
			var $self = this.element;
			var self = this;

			if (!self.options.animate_in_progress) {
				// Build a new hash location reflecting the open screen and all overlays
				var hash = window.location.hash;
				hash = decodeURIComponent(hash);

				self.options.up = false;

				if ($caller.closest('.screen')[0]) {
					if (up) {
						// debugLog('Told to slide up.');
						self.options.up = true;
					} else {
						// debugLog('Told to slide down.');
						self.options.up = false;
					}
					hash = hash.replace(/\/overlay.+/, '');
				} else if ($caller.closest('.overlay')[0] && up) {
					// #!/cudatel/parking/overlay/parking-create/{}
					hash = hash.replace(/\/overlay\/[^\/]+\/\{[^\}]*\}$/, '');
					self.options.up = true;
				}

				hash = hash.replace(/\/widgets\/\{[^\}]*\}$/, '');
				hash += '/overlay/' + name + '/' + (typeof params == 'string' ? params : JSON.stringify(params));
				if (widget_params) {
					hash += '/widgets/' + (typeof params == 'string' ? widget_params : JSON.stringify(widget_params));
				}
				hash = hash.replace(/^#/, '');

				// TODO: Look at ways to suppress storing current page in history
				location.hash = hash;
				return true;
			}
			return false;
		}

	});

	add_widget('pageWidget', pageWidget);

})(jQuery);
