/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, debugLog */
(function( $ ){
	var pageModuleWidget = $.extend({}, $.ui.widget.prototype, CUI.htmlEntityClass, {

		options: {
			legend: false,
			buttons: {}, // May be an array, or an object with { "button" : true/false }
			// cancel/save/help/create are the options
			accept_data_from_parent: true,
			ie_selector_hack: true,
			set_focus: false  // used to apply the input focus to a specific element, element that needs focus must have class 'focus'
		},

		_beforeInit: function () {
			var self = this;
			var $self = this.element;

			$self.addClass('cui-page-module page_module');

			if (!self.options.template_html) {
				self.options.template_html = $self.contents();
			}

			self.options.append_buttons = {};
			for (var i=0, len = self.options.buttons.length; i<len; ++i) {
				self.options.append_buttons[self.options.buttons[i]] = true;
			}

			self._one($self.closest('.pageWidgetType'), 'screenReady', self._resize.bind(self));

			if (self.options.set_focus) {
				self._one($self.closest('.pageWidgetType'), 'screenReady', self._setFocus.bind(self));
			}
			return false;
		},

		_setFocus: function () {
			var self = this, $self = this.element;
			var $focus_element = $self.find('.focus');
			if ($focus_element[0]) {
				$focus_element[0].focus();
			}
		},

		_destroy: function() {
			var self = this, $self = this.element;

			$self.triggerHandler('destroy');
		},

		_resize: function() {
			var self = this, $self = this.element, maxheight, selfheight, selftop, leftheight, rightheight;

			if ($self.hasClass('page_module_half_width')) {
				selfheight = parseInt($self.outerHeight());
				selfheight = Math.max(parseInt($self[0].scrollHeight), selfheight);
				selftop = parseInt($self.position().top);
				maxheight = selfheight + 30;
				var $left = $self.prevAll().eq(0);
				var $right = $self.nextAll().eq(0);
				if ($left[0] && $left.hasClass('pageModuleWidget') && $left.hasClass('page_module_half_width') && parseInt($left.position().top) == selftop) {
					leftheight = parseInt($left.outerHeight());
					maxheight = (maxheight > leftheight ? maxheight : leftheight);
				}
				if ($right[0] && $right.hasClass('pageModuleWidget') && $right.hasClass('page_module_half_width') && parseInt($right.position().top) == selftop) {
					rightheight = parseInt($right.outerHeight());
					maxheight = (maxheight > rightheight ? maxheight : rightheight);
				}
				if (maxheight > selfheight) {
					$self.css({ height: maxheight + 'px' });
				}
			}
		},

		_processStaticBody: function ($static_body) {
			var self = this;
			var $self = this.element;
			var $form = $static_body.children('form, .page_module_body');
			var button_text;

			var body = $form[0];
			var new_body_fragment = document.createDocumentFragment();

			var source_body = body;
			if (!body) {
				// If there is no "form" specified, default to using a <div class="page_module_body">
				source_body = $static_body[0];
				// Set form action if passed in
				if(self.options.form_action) {
					body = document.createElement('form');
					body.className = 'widgetType formWidget';
					var action = document.createAttribute('action');
					action.nodeValue = self.options.form_action;
					body.setAttributeNode(action);
					if (self.options.form_data_js) {
						var data_js = document.createAttribute('data-js');
						data_js.nodeValue = window.JSON.stringify(self.options.form_data_js);
						body.setAttributeNode(data_js);

						if (self.options.form_data_js.method) {
							body.method = self.options.form_data_js.method;
						}
					}

					if (self.options.form_options) {
						$(body).data('extra_widget_options', self.options.form_options);
					}
				} else {
					body = document.createElement('div');
					body.className = 'page_module_body';
				}
			}
			var $old_body_fragment = $(source_body);

			var $front_drop = $old_body_fragment.children('.page_module_frontdrop');
			var front_drop = $front_drop[0];
			if (!front_drop) {
				front_drop = document.createElement('div');
				front_drop.className = 'page_module_frontdrop';
			}
			new_body_fragment.appendChild(front_drop);

			var $form_legend = $old_body_fragment.children('.form_legend');
			var form_legend = $form_legend[0];
			if (!form_legend) {
				form_legend = document.createElement('div');
				form_legend.className = 'form_legend';
				form_legend.appendChild(document.createTextNode(self.options.legend || ''));
			}
			new_body_fragment.appendChild(form_legend);

			var $help = $old_body_fragment.children('.help');
			var help = $help[0];
			if (!help) {
				help = document.createElement('div');
				help.className = 'help';
			}
			new_body_fragment.appendChild(help);

			var $message = $old_body_fragment.children('.message');
			var message = $message[0];
			if (!message) {
				message = document.createElement('div');
				message.className = 'message';
			}
			new_body_fragment.appendChild(message);

			var $buttons = $old_body_fragment.children('.buttons');
			var buttons = $buttons[0];
			if (!buttons) {
				buttons = document.createElement('div');
				buttons.className = 'buttons';
			}
			$buttons = $(buttons);

			var $status = $buttons.children('.status');
			var status = $status[0];
			if (!status) {
				status = document.createElement('div');
				status.className = 'status';
			}
			$status = $(status);

			var $spinner = $status.children('.spinner');
			var spinner = $spinner[0];
			if (!spinner) {
				spinner = document.createElement('div');
				spinner.className = 'spinner';
			}
			status.appendChild(spinner);

			var $error = $status.children('.error');
			var error = $error[0];
			if (!error) {
				error = document.createElement('div');
				error.className = 'error';
			}
			status.appendChild(error);
			buttons.appendChild(status);

			if (self.options.append_buttons.cancel) {
				var $cancel = $buttons.children('.module_cancel_button');
				var cancel = $cancel[0];
				if (!cancel) {
					cancel = document.createElement('button');
					cancel.className = 'module_cancel_button';
					button_text = document.createTextNode("Cancel");
					cancel.appendChild(button_text);
				}
				buttons.appendChild(cancel);
			}

			if (self.options.append_buttons.create) {
				var $create = $buttons.children('.module_create_button');
				var create = $create[0];
				if (!create) {
					create = document.createElement('button');
					create.className = 'module_create_button widgetType createButtonWidget validated_button';
					button_text = document.createTextNode("Create");
					create.appendChild(button_text);
				}
				if (typeof self.options.create == "object") {
					$(create).attr("data-js", window.JSON.stringify(self.options.create));
				}
				buttons.appendChild(create);
			}

			if (self.options.append_buttons.save) {
				var $save = $buttons.children('.module_save_button');
				var save = $save[0];
				if (!save) {
					save = document.createElement('button');
					save.className = 'module_save_button validated_button';
					button_text = document.createTextNode("Save");
					save.appendChild(button_text);
				}
				buttons.appendChild(save);
			}

			if (self.options.append_buttons.help) {
				var $helpbut = $buttons.children('.module_help_button:eq(0)');
				var helpbut = $helpbut[0];

				if (!helpbut) {
					// This is the usual case--

					helpbut = document.createElement('button');
					$helpbut = $(helpbut);
					button_text = document.createTextNode('Help');
					helpbut.appendChild(button_text);
				}

				$helpbut.addClass('module_help_button widgetType helpButtonWidget');

				if (self.options.help_id) {
					$helpbut.data('extra_widget_options', { help_id: self.options.help_id });
				} else {
					debugLog('pageModuleWidget: "help" button specified but no "help_id" option was set: ', $self);
				}

				buttons.appendChild(helpbut);
			}

			// Append Buttons
			new_body_fragment.appendChild(buttons);

			if (self.options.elements) {
				var $entities = self.getEntitiesHTML(self.options.elements, self.options.data);
				if ($entities && $entities[0]) {
					var entities = $entities[0];
					for (var child = 0, num_children = entities.children.length; child < num_children; ++child) {
						new_body_fragment.appendChild(entities.children[0]);
					}
				}
			}

			var $remaining_old_elems = $old_body_fragment.contents();
			$remaining_old_elems = $remaining_old_elems.not('.page_module_frontdrop, .form_legend, .message, .buttons, .help');
			$remaining_old_elems.each(function() {
				new_body_fragment.appendChild(this);
			});

			body.appendChild(new_body_fragment);
			$static_body = $('<div/>').append(body);

			return $static_body;
		}
	});    
	add_widget('pageModuleWidget', pageModuleWidget);
})(jQuery);
