register_screen('group_detail', {
    rest: '/gui/extension/group',
    rest_container: 'group',
    dataEvents: {
	bind: {
	    'primary_extension extension': ''
	}
    },
    elements: [
	{ entity_template: 'masthead' },
	{
	    entity_templates: ['aopb_state', 'container'],
	    aopb_state: {
		watch_self_fillData: true,
		states: [{
		    conditions: { a: '@bbx_sync_id', op: '==', b: '_TRUEISH_' },
		    actions: { visible: false },
		    else_actions: { visible: true }
		}]
	    },
	    container: {
		elements: [
		    {
			entity_template: 'page_buttons',
			buttons: [
			    { type: 'extension', extension_type: 'group' },
			    {
				type: 'delete',
				rest: '/gui/extension/group',
				fields: 'bbx_group_id',
				confirm: { title: 'Delete Group', text: 'Are you sure you would like to delete this group?' }
			    },
			    { type: 'cancel' }, { type: 'save' }
			]
		    }
		]
	    }
	},
	{
	    entity_templates: ['aopb_state', 'container'],
	    aopb_state: {
		watch_self_fillData: true,
		states: [{
		    conditions: { a: '@bbx_sync_id', op: '==', b: '_TRUEISH_' },
		    actions: { visible: true },
		    else_actions: { visible: false }
		}]
	    },
	    container: {
		elements: [
		    {
			entity_template: 'page_buttons',
			buttons: [
			    { type: 'extension', extension_type: 'group' },
			    {
				type: 'message',
				text: 'Delete',
				message: {
				    title: 'Cannot Delete a Synchronized Group',
				    text: 'This group is managed by LDAP/Active Directory. It cannot be deleted from this list.'
				}
			    },
			    { type: 'cancel' }, { type: 'save' }
			]
		    }
		]
	    }
	},
	{ entity_template: 'masthead' },
	{
	    entity_template: 'page_content', elements: [
		{
		    entity_template: 'page_module',
		    legend: 'Basic Information',
		    buttons: ['help'],
		    help_id: 'groups_basic_information',
		    form_action: '/gui/extension/group',
		    elements: [
			{
			    entity_template: 'input_item',
			    title: 'Group Name:',
			    type: 'text',
			    input_name: 'bbx_group_name',
			    input_options: { attributes: { maxlength: '255' }},
			    validate: { conditions: "_TRUE_", message: 'Group Name is required.' }
			},
			{
			    entity_template: 'input_item',
			    title: 'Disable Calling to This Group:',
			    type: 'checkbox',
			    input_name: 'bbx_group_calling_disabled'
			},
			{
			    entity_template: 'input_item',
			    title: 'Multiple Ringing Calls:',
			    type: 'select',
			    input_name: 'bbx_group_serialized',
			    info: 'If multiple ringing calls are Prevented, incoming calls to the group will only ring available members' + entity.rsquo + ' phones only after previous ringing calls have been answered or rejected.',
			    select_options: [
				{ text: 'Allow Multiple Ringing Calls', value: '0' },
				{ text: 'Prevent Multiple Ringing Calls', value: '1' }
			    ]
			},

			{
			    entity_template: 'input_item',
			    type: 'checkbox',
			    input_name: 'has_popup_url',
			    title: 'Launch Web Application Popup for Calls:'
			},
			{
			    entity_template: 'data_item',
			    title: '',
			    elements: [
				{
				    entity_template: 'aopb_state',
				    entity: 'input',
				    attributes: { type: 'text', size: '60' },
				    name: 'bbx_group_popup_url',
				    closest: 'form',
				    states: [
					{
					    conditions: { a: '$has_popup_url', op: '==', b: '_TRUEISH_' },
					    actions: { enabled: true },
					    else_actions: { enabled: false }
					},
					{
					    conditions: [
						{              a: '$has_popup_url', op: '==', b: '_TRUEISH_' },
						{ join: 'and', a: '$$', op: '!=', b: '^([a-zA-Z0-9_]+ |)[-+\\.A-Za-z0-9]+://[^ ]+$', b_is: 'regexp' }
					    ],
					    actions: { invalid: 'You must specify a valid URL beginning with '+entity.ldquo+'http'+entity.rdquo+':// (or another protocol) and no spaces.' },
					    else_actions: { invalid: false }
					}
				    ]
				},
				{ entity: 'div', text: 'Web Application Popup URL', attributes: { 'class': 'info' } }
			    ]
			},
			{
			entity_template: 'input_item',
				title: 'Custom Outbound Caller ID Number:',
				type: 'div',
				input_name: 'bbx_group_external_caller_id_number',
				info: 'If set, this number will be used instead of the person' + entity.rsquo + 's number or extension when making outbound calls. Leave this entry blank to disable it, and use the person' + entity.rsquo + 's individual Caller ID information. If a user has a DID, that will override this value.',
				input_options: {
					widgets: ['aopbStateWidget', 'optionalValueTextboxWidget'],
					widget_options: {
					optionalValueTextboxWidget: {},
					aopbStateWidget: {
						states: [{
						conditions: [
						{ a: '$$', op: '!=', b: '^\\+?[0-9]+$', b_is: 'regexp' },
						{ join: 'and', a: '[name=bbx_group_external_caller_id_number] .optional-value-checkbox', a_is: 'element', op: '==', b: ':checked', b_is: 'jq_is' }
						],
					 	actions: { invalid: 'The Caller ID number must contain only digits' },
					 	else_actions: { invalid: false }
						}]
						}
					}
				}
			},
			{
			    entity_template: 'input_item',
			    title: 'Show Group Name on Phone Display:',
			    type: 'checkbox',
			    info: 'For calls made to the group, show the group name on the destination phone.',
			    input_name: 'bbx_group_append_group_name'
			}
		    ] // END page module elements
		}, // END page module 1
		{
		    entity_template: 'page_module',
		    legend: 'Membership',
		    buttons: ['help'],
		    help_id: 'groups_membership',
		    elements: [

			{
			    entity_template: 'data_table',
			    rest: '/gui/group/members',
			    accept_data_from_parent: false,
			    paginate: true,
			    length_change: true,
				filtering: false,
			    sort_by: 'bbx_extension_value',
			    sort_order: 'asc',
			    rest_params: { sortby: 'bbx_extension_value', sortorder: 'asc' },
			    add_edit_action: {
				rest: '/gui/group/members',
				method: 'POST',
				include_data_from_parent: ['bbx_group_id']
			    },
			    click_action: 'overlay',
			    overlay_name: 'user_detail',
			    overlay_params: [ 'bbx_extension_id', 'bbx_user_id' ],
			    overlay_data: { key: 'bbx_user_id' },
			    // alternate_multi_delete: true,
			    delete_action: {
				rest: '/gui/group/members',
				// rest: '/gui/test/multi',
				include_data_from_parent: ['bbx_group_id']
			    },
			    dataEvents: {
				trigger: 'members_external'
			    },
			    name_column: 'show_name',
			    primary_keys: ['bbx_user_id'],
			    extra_row_data: ['show_name','bbx_extension_value','bbx_user_id','bbx_extension_id'],
			    table_actions: {
				add_row: {
				    text: 'Add a Member',
				    location: 'bottom',
				    "class": 'hide-row',
				    button_class: 'group_add_member'
				},
				delete_rows: {
				    text: 'Remove Selected Members',
				    location: 'bottom',
				    confirm: {
					title: 'Remove Selected Members',
					text: 'Are you sure you want to remove the selected members from the group? If permissions settings are applied via this group, they may be removed.'
				    }
				},
				select_row: true,
				select_all_rows: true
			    },
			    details_row: {
				add_elements: [{
				    entity_template: 'data_item',
				    title: 'Select a person:',
				    elements: [
					{
					    entity_template: 'extension_picker_select',
					    type: 'user',
					    primary: 1,
					    table_filter: true,
					    allow_nothing: false,
					    name: 'bbx_user_id'
					}
				    ]
				}]
			    },
			    row_actions: {
				animate: false,
				animate_speed: 'slow',
				type: 'expand',
				add_save: { text: 'Add' },
				add_cancel: { text: 'Cancel' }
			    },
			    columns: [
				{
				    header: 'Extension',
				    column: 'bbx_extension_value',
				    'class': 'group_membership_extension',
				    width: '8em',
				    formatted: {
					format: {
					    field: '@bbx_extension_value',
					    formatter: 'phone_number'
					}
				    }
				},
				{
				    header: 'Name',
				    column: 'show_name',
				    'class': 'group_membership_name'
				}
			    ]
			} // END DTW

		    ] // END page module elements
		}, // END page module 2
		{
		    entity_template: 'page_module',
		    legend: 'Permissions',
		    buttons: ['help'],
		    help_id: 'groups_permissions',
		    elements: [
			{
			    entity_template: 'data_table',
			    rest: '/gui/permission_class/group_class',
			    accept_data_from_parent: false,
			    paginate: false,
			    add_edit_action: {
				rest: '/gui/permission_class/group_class'
			    },
			    delete_action: {
				rest: '/gui/permission_class/group_class'
			    },
			    name_column: 'bbx_permission_name',
			    table_actions: {
				add_row: {
				    text: 'Add a Permission',
				    location: 'bottom',
				    button_class: 'group_add_permission'
				},
				delete_rows: {
				    text: 'Remove Selected Permissions',
				    location: 'bottom',
				    confirm: {
					title: 'Remove Selected Permissions',
					text: 'Are you sure you want to remove the selected permissions from the group?'
				    }
				},
				select_row: true,
				select_all_rows: true
			    },
			    row_actions: {
				animate: false,
				animate_speed: 'slow',
				type: 'expand',
				edit_save: { text: 'Save' },
				edit_cancel: { text: 'Cancel' },
				add_save: { text: 'Add' },
				add_cancel: { text: 'Cancel' },
				delete_row: {
				    text: 'Delete',
				    confirm: {
					title: 'Confirm Group Permission Removal',
					text: 'Are you sure you would like to remove this permission from the group?'
				    },
				    only_rest_params: []
				}

			    },
			    primary_keys: ['bbx_permission_class_id'],
			    extra_row_data: [ "show_name", "bbx_permission_class_name", "bbx_permission_class_id" ],
			    columns: [
				{
				    header: 'Permission',
				    column: 'bbx_permission_class_name',
				    width: '15em',
				    'class': 'group_permissions_name',
				    add_element: {
					entity_template: 'select_populate',
					attributes: {'class': 'always-dirty-indicator' },
					name: 'bbx_permission_class_id',
					rest: '/gui/permission_class/classes',
					rest_container: 'classes',
					table_filter: true,
					text_field: 'bbx_permission_class_name',
					value_field: 'bbx_permission_class_id',
					store_row_data: true
				    }
				},
				{
				    header: 'Description',
				    column: 'bbx_permission_class_description',
				    'class': 'group_permissions_description',
				    add_element: {
					entity: 'div',
					widgets: ['watcherWidget'],
					widget_options: {
					    watch: {
						closest: 'tr',
						find: 'td select[name=bbx_permission_class_id]',
						event: 'click change'
					    },
					    interpret: {
						closest: '*',
						find: 'option:selected',
						attribute: 'data',
						data_key: 'row_data',
						data_object_key: 'bbx_permission_class_description'
					    }
					}
				    }
				}
			    ]
			} // END DTW
		    ] // END page module elements
		}, // END page module 3

		elgen('recording_policy', { rest: '/gui/extension/group', type: 'group' }, {help_id: 'groups_call_recording' }),
		elgen('secondary_page_module', { type: 'group' }, {help_id: 'secondary_extensions' })

	    ] // END page_content elements
	} // END page_content
    ] // END template elements
}); // END template
