(function () { 
    var restrictions_table = {
	entity_template: 'data_table',
	accept_data_from_parent: true,
	rest_container: 'restriction',
	data_source: 'restrictions',
	click_action : 'edit',
	paginate:      false,
	length_change: false,
	filtering:     false,
	processing:    false,
	sortable:      false,
	allow_scroll:  false,
	invalid_while_row_open: true,
	null_value:    '_OMIT_',

	oLanguage: {
	    sZeroRecords: 'No restrictions. This route will be used for any matching calls.'
	},

	add_edit_action : {
	    rest: '/gui/route/restriction',
	    include_data_from_parent: ['bbx_outbound_route_map_id']
	},
	delete_action: {
	    rest: '/gui/route/restriction',
	    include_data_from_parent: ['bbx_outbound_route_map_id']
	},
	primary_keys: ['bbx_group_id', 'bbx_site_id'],
	clear_row_data_on_change: true,
	extra_row_data : [
	    'bbx_site_name',
	    'bbx_group_name',
	    'bbx_site_id',
	    'bbx_group_id',
	    'bbx_extension_id'
	],
	columns : [
	    {
		header: 'Group / Site',
		view_element: {
		    entity: 'div',
		    widgets: ['aopbFormatWidget'],
		    widget_options: {
			aopbFormatWidget: {
			    states: [
				{
				    conditions: { a:'@bbx_site_name', op:'==', b:'_TRUEISH_' },
				    format: { format: { field: '@bbx_site_name' } },
				    else_format: { format: { field: '@bbx_group_name' } }
				}
			    ]
			}
		    }
		},

		add_element: {
		    entity_template: 'data_item',
		    title: 'Group or Site:',
		    elements: [
			{
			    entity: 'select',
			    attributes: { name: 'restrict_select' },
			    select_options: [
				{ value: 'group', text: 'Group' },
				{ value: 'site', text: 'Site' }
			    ]
			},
			{
			    entity_template: 'switch',
			    closest: 'td',
			    selector: '$restrict_select',
			    switch_elements: {
				'group': {
				    entity_template: 'input_item',
				    type: 'div',
				    input_name: 'bbx_group_id',
				    title: 'Extension:',
				    input_options: {
					entity_template: 'extension_picker_select',
					type: ['group'],
					primary: 1,
					allow_nothing: false,
					table_filter: true,
					search_key: 'bbx_group_id'
				    }
				},
				'site': {
				    entity_template: 'container',
				    elements: [
					{
					    entity_templates: ['new_row_validator', 'container'],
  					    new_row_validator: {
						apply_to: 'child'
					    },
					    container: {
						elements: [
						    {
							entity_template: 'select_populate',
							rest: '/gui/site/site',
							name: 'bbx_site_id',
							text_field: 'bbx_site_name',
							value_field: 'bbx_site_id'
						    }
						]
					    }
					}
				    ]
				}
			    }
			}
		    ]
		}
	    }
	],

	row_actions: {
	    animate: false,
	    type: 'expand',
	    edit_save: { text: 'Save' },
	    edit_cancel: { text: 'Cancel' },
	    add_save: { text: 'Add' },
	    add_cancel: { text: 'Cancel' }
	},

	table_actions: {
	    add_row: {
		text: 'Add Restriction',
		location: 'bottom'
	    },
	    delete_rows: {
		text: 'Delete Restriction',
		location: 'bottom',
		confirm: { title: 'Delete Selected', text: 'Are you sure you want to delete the selected restrictions?' }
	    },
	    select_row: true,
	    select_all_rows: true
	}
    };

    var restrictions_table_slim = $.extend({},restrictions_table, { attributes: {'class': 'call_routing_restrictions_slim'}, table_actions: undefined });

    var dialog_showing = false;
    var neverAgain = function () {
	setCookie('never_show_routing_message', '1', 365);
	cancelDialog.apply(this, []);
    };

    var cancelDialog = function () {
	dialog_showing = false;
	if (this !== window) {
	    CUI.Dialog.cancel.apply(this,[]);
	}
    }

    register_screen('call_routing', {
	fn_before_init: function () {
	    if (dialog_showing) { return; }

	    dialog_showing = true;

	    if (getCookie('never_show_routing_message')) {
		neverAgain();
	    } else {
		new CUI.Dialog({
		    title: 'Outbound Call Routing',
		    body: '<div>Allowing outbound calls using paid providers can cost you money. Before configuring Call Routing, please consult the <a href="https://techlib.barracuda.com/PHONE/OutboundRouting" target="_new">Outbound Calling Best Practices document</a>, in order to ensure that your Barracuda Phone System is properly secured and configured to prevent unauthorized or unwanted calls.</div>',
		    blanker: true,
		    buttons: ['Don'+entity.rsquo+'t Show This Again', 'OK'],
		    callbacks: [neverAgain, cancelDialog]
		});
	    }
	},
	elements: [
	    { entity_template: 'page_title', title: 'Call Routing' },
	    {
		entity_template: "page_buttons",
		buttons: [{ type: 'help', help_id:'providers_routing' }]
	    },
	    {
		entity_template: 'page_content',
		elements: [
		    {
			entity_template: 'data_table',
			allow_scroll:  false,
			paginate:      false,
			length_change: false,
			filtering:     false,

			rest : '/gui/route/map',
			click_action : 'edit',
			add_edit_action : {
			    rest : '/gui/route/map',
			    filter_params: [
				'bbx_tdm_card_port_name',
				'bbx_provider_gateway_name',
				'bbx_outbound_route_name',
				'restrictions',
				'bbx_group_id',
				'bbx_group_name',
				'bbx_site_id',
				'bbx_site_name'
			    ],
			    filter_rest_params : [ 'page', 'rows' ]
			},
			delete_action: { rest: '/gui/route/map' },
			sortable : {
			    field : 'bbx_outbound_route_map_priority',
			    also_submit: ['bbx_outbound_route_map_id']
			},
			dirty_exclusion_lists: [
			    [ 'bbx_tdm_card_port_id', 'bbx_provider_gateway_id' ],
			    [ 'bbx_tdm_card_port_id', 'block' ],
			    [ 'bbx_provider_gateway_id', 'block']
			],
			primary_keys : [ 'bbx_outbound_route_map_id' ],
			extra_row_data : [
			    'bbx_outbound_route_map_id',
			    'bbx_tdm_card_port_id',
			    'bbx_tdm_card_port_name',
			    'bbx_provider_gateway_id',
			    'bbx_provider_gateway_name',
			    'bbx_outbound_route_name',
			    'block',
			    'restrictions'
			],
			name_column: 'bbx_outbound_route_name',
			columns : [
			    {
				header : 'Call Type',
				column : 'bbx_outbound_route_id',
				sortable : false,
				view_element : {
				    entity : 'div',
				    widgets : [ 'formatWidget' ],
				    widget_options : {
					formatWidget : {
					    format: { field: '@bbx_outbound_route_name' }
					}
				    }
				},
				edit_elements : [
				    {
					entity : 'select',
					attributes : {
					    name: 'bbx_outbound_route_id'
					},
					widgets : [ 'selectPopulateWidget' ],
					widget_options : {
					    selectPopulateWidget : {
						rest : '/gui/route/route',
						text_field : 'bbx_outbound_route_name',
						value_field : 'bbx_outbound_route_id'
					    }
					}
				    },
				    {
					entity_template: 'overlay_button',
					overlay: 'calltypes',
					text: 'Manage...'
				    },
				    {
					entity: 'div',
					text: 'Click '+entity.ldquo+'Manage...'+entity.rdquo+' to edit or create Call Types.',
					attributes: { 'class':'info', css: 'max-width: 80%' }
				    }
				]
			    },
			    {
				header : 'Restrict To',
				sortable : false,
				view_element : {
				    entity_template: 'container',
				    elements: [ restrictions_table_slim ]
				},
				add_element: {
				    entity: 'div',
				    text: 'Restrictions are not available until the route is created.'
				},
				edit_element: {
				    entity_template: 'container',
				    elements: [ restrictions_table ]
				}
			    },
			    {
				header: 'System Use',
				column: 'bbx_outbound_route_map_allow_system',
				sortable: false,
				translate: {
				    '1': 'Allowed',
				    '0': 'No'
				},
				add_element: {
				    entity_template: 'input_item',
				    type: 'checkbox',
				    input_name: 'bbx_outbound_route_map_allow_system',
				    title: 'Allow:',
				    attributes: {'class': 'dtw-checkbox'}
				},
				edit_element: {
				    entity_template: 'input_item',
				    type: 'checkbox',
				    input_name: 'bbx_outbound_route_map_allow_system',
				    title: 'Allow:',
				    attributes: {'class': 'dtw-checkbox'}
				}
			    },
			    {
				header : 'Send Calls To',
				sortable : false,

				view_element : {
				    entity : 'div',
				    widgets : [ 'aopbFormatWidget' ],
				    widget_options : {
					aopbFormatWidget: {
					    states: [
						{
						    conditions: [
							{              a: '@bbx_provider_gateway_name', op: '==', b: '_FALSE_' },
							{ join: 'and', a: '@bbx_tdm_card_port_name', op: '==', b: '_FALSE_' }
						    ],
						    format: { format: 'Block Matching Calls' },
						    last_if_true: true
						},
						{
						    conditions: [
							{              a: '@bbx_provider_gateway_name', op: '==', b: '_FALSE_' },
							{ join: 'and', a: '@bbx_tdm_card_port_name', op: '==', b: '_TRUE_' }
						    ],
						    format: { format: { field: '@bbx_tdm_card_port_name' } },
						    last_if_true: true
						},
						{
						    conditions: [
							{              a: '@bbx_provider_gateway_name', op: '==', b: '_TRUE_' },
							{ join: 'and', a: '@bbx_tdm_card_port_name',    op: '==', b: '_FALSE_' }
						    ],
						    format: { format: { field: '@bbx_provider_gateway_name' } },
						    else_format: { format: 'Unknown' }
						}
					    ]
					}
				    }
				},

				edit_element : {
				    entity : 'select',
				    attributes: { name: 'combined_edit_select' },
				    widgets : [ 'aopbSelectPopulateWidget' ],
				    widget_options : {
					'aopbSelectPopulateWidget': {
					    after: [{ text: '(Block Matching Calls)', value: { name: 'block', value: '1' }}],
					    rest : '/gui/gateway/combined',
					    parsers: [
						{
						    conditions: [{ a: '@bbx_provider_gateway_id', op: '==', b: '_TRUE_' }],
						    option: {
							name: 'bbx_provider_gateway_id',
							text: '@bbx_provider_gateway_name',
							value: '@bbx_provider_gateway_id'
						    },
						    last_if_true: true
						},
						{
						    conditions: [{ a: '@bbx_tdm_card_port_id', op: '==', b: '_TRUE_' }],
						    option: {
							name: 'bbx_tdm_card_port_id',
							text: '@bbx_tdm_card_port_name',
							value: '@bbx_tdm_card_port_id'
						    },
						    last_if_true: true
						},
						{
						    conditions: [{ a: '@block', op: '==', b: '_TRUE_' }],
						    option: {
							name: 'block',
							text: '(Block Matching Calls)',
							value: '1'
						    }
						}
					    ]
					}
				    }
				}
			    }
			],
			row_actions : {
			    animate: false,
			    type: 'expand',
			    edit_save: { text: 'Save' },
			    edit_cancel: { text: 'Cancel' },
			    add_save: { text: 'Add' },
			    add_cancel: { text: 'Cancel' },
			    delete_row: { text: 'Delete Route' }
			},
			table_actions : {
			    add_row : {
				text : 'Add Route',
				location: 'bottom'
			    },
			    delete_rows : {
				text : 'Delete Selected',
				location: 'bottom',
				confirm: { title: 'Delete Selected', text: 'Are you sure you want to delete the selected routes?' }
			    },
			    action_elements: {
				actions: [
				    {
					entity_template: 'overlay_button',
					overlay: 'calltypes',
					text: 'Manage Call Types...'
				    }
				]
			    },
			    select_row : true,
			    select_all_rows : true
			}
		    } // END DTW
		] // END page_content elements
	    } // END page_content
	] // END template elements
    }); // END template
})(); // END scoping function
