/* jshint jquery: true, unused: vars */
/* global add_widget */
(function( $ ){
	var secondaryExtensionWidget = $.extend({}, $.ui.widget.prototype, {
		options: {
			template: false
		},

		_beforeInit : function () {
			this.element.html('<div style="margin: 10px 0; border: 1px solid #333; padding: 50px 0; text-align: center; background-color: #ddd">(Secondary Extension widget goes here)</div>');
		}
	});

	add_widget('secondaryExtensionWidget', $.extend({}, $.ui.widget.prototype, secondaryExtensionWidget));
})(jQuery);
