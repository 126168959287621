/* jshint jquery: true, unused: vars */
/* global add_widget */
(function( $ ){
	var action_types = [
		{ key: 'try_phone_numbers', text: 'Call Phone Numbers', fmfm: true, acr: true },
		{ key: 'playback', text: 'Play a Sound File', fmfm: false, acr: true },
		{ key: 'ivr_dialbyname', text: 'Go to the Dial-by-name Directory', fmfm: false, acr: true },
		{ key: 'ivr_disa', text: 'Go to the Direct Inward System Access (DISA)', fmfm: false, acr: true },
		{ key: 'execute_extension', text: 'Try Calling an Extension', fmfm: false, acr: true },
		{ key: 'try_calling_registered_phones', text: 'Try Calling My Phones', fmfm: true, acr: false },
		{ key: 'campon', text: 'Keep Trying to Reach Me', fmfm: true, acr: false }
	];

	var action_types_lookup = {};
	for (var idx = 0; idx < action_types.length; idx++) {
		action_types_lookup[action_types[idx].key] = action_types[idx].text;
	}

	var callflowActionSelectWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			mode: 'acr' // 'acr' | 'fmfm'
		},

		set_value_widget: true,

		_beforeInit: function () {
			var self = this, $self = this.element, at_idx, at;
			if ($self.is('select')) {
				self.options._is_select = true;
				$self.empty();
				for (at_idx = 0; at_idx < action_types.length; at_idx++) {
					at = action_types[at_idx];
					if (at[self.options.mode || 'acr']) {
						$self.append($('<option />').val(at.key || '').text(at.text || ''));
					}
				}
			}
		},

		fillData: function (d) {
			var self = this, $self = this.element;

			if (!d.bbx_callflow_action_id) { return; }

			if (self.options._is_select) {
				// Just fill the widget
				$.ui.widget.prototype.fillData.apply(this, arguments);
			} else {
				// Display the value
				$self.text(action_types_lookup[d.bbx_callflow_action_type]);
			}
		},

		setValue: function (v) {
			var self = this, $self = this.element;
			if (self.options._is_select) {
				$self.val(v);
			}
		}
	});

	add_widget('callflowActionSelectWidget', callflowActionSelectWidget);
})(jQuery);
