/* jshint jquery: true */
/* global classCUI, CUI, debugLog, format_information */
/*
FORMATTER CLASS: Perform complex formatting using fields, functions, and text replacement

EXTENDING THIS CLASS:

FUNCTION doFormat(data, OPTIONAL format_def) RETURNS STRING

    Returns the formatted text, given the format definition (described below) and a data object. In the case of a simple "format widget", you can omit
    format_def, and it will fall back to self.options. The original object is not changed, and extraneous object properties will be ignored. The usual use
    case for this is to extend fillData as such:

    fillData: function (data) {
	...
	setValue(self.doFormat(data));
    }

    If you are conditionally formatting, or using some other widget that requires varying formats, you can pass those in as the second format_def param.
    In any case, this should be run when a value is received for formatting, and the return value should be used or displayed.

FUNCTION _getDataFromFieldname(path, data) RETURNS ANY TYPE

    Given a fieldname, this returns the relevant data. If your widget grabs data from places other than the "data" object, or needs to do special things
    like drilling down into data structures, this is the place to implement it. This is the sole place where the fieldname is parsed, so if you want to
    support things like type symbols or the like, this is the place to do it.

OBJECT of FUNCTIONS _formatters

    Extend this object to add new formatting functions. The basic format of the functions is:

    formatter_name: function (options, value, all_data) {
	...
	return formatted_data;
    }

    Options is always an object, containing arbitrary parameters taken from the format_def object. Value is usually a string or other basic type, consisting
    of the data at all_data[fieldname], and all_data is the entire data object passed to doFormat. The return from this function should usually be a string,
    number, or displayable basic type.

FORMAT OF SELF.OPTIONS.FORMAT_DEF:

{
    format: 'Format string using $n' | { field definition (see below) },

    // If the format is a string, you need the fields property:
    fields: [
	// This is the field definition for field $1. An object like this can be used in place of the "format" string, if a simple formatted value is all that
	// is desired. In that case, omit the "fields" array entirely.
	{
	    field: '@property_name',
	    formatter: 'formatter_name' | [ 'formatter_first', 'formatter_second', 'formatter_third' ],
	    options: {
		// Options are not required. If the formatter is specified as a single string, you do not need the wrapping object.
		formatter_first: {
		    option_key: option_value,
		    ...
		},
		...
	    }
	}
    ],
    ...
}


EXAMPLE 1: COMPLEX STRING REPLACEMENT USING A FORMAT STRING AND AND ARRAY OF FIELDS

doFormat(

    // Data
    { time_remaining: 350, event: 'the end of the world' },

    // Format definition -- The "format" is a string where $1, $2... are filled in from the fields array
    {
	format: '$1 until $2',
	fields: [
	    {
		field: '@time_remaining',
		formatter: 'seconds_duration',
		options: { size: 'short' }
	    },
	    {
		field: '@event',
		formatter: 'uc'
	    }
	]
    }

); // Returns "5m50s until THE END OF THE WORLD"

EXAMPLE 2: SIMPLE DATA FORMATTING USING A SINGLE FORMAT OBJECT AND NO FORMAT STRING

doFormat(

    // Data
    { time_remaining: 350 },

    // Format definition -- There is no "format" string or "fields" array, just a single format definition.
    {
	format: {
	    field: 'time_remaining',
	    formatter: 'seconds_duration',
	    options: { size: 'short' }
	}
    }

); // Returns "5m30s"

*/

(function( $ ){
	classCUI.prototype.formatter = {

		_getDataFromFieldName: function (field_name, data) {
			return data[field_name.replace(/^@/,'')];
		},

		_runFormatter: function (formatter, options, data, all_data) {
			var self = this, $self = self.element;

			if (options.set_undefined_to && data === undefined) {
				data = self.options.set_undefined_to;
			}

			if (options.abort_undefined && data === undefined) {
				return '';
			}

			if (options.set_null_to && data === null) {
				data = self.options.set_null_to;
			}

			if (options.abort_null && data === null) {
				return '';
			}

			if (self._formatters[formatter]) {
				return self._formatters[formatter](options || {}, data, all_data);
			} else {
				debugLog('cui.formatter.js: Formatter not found: ', formatter, '. Default to passthrough. -- ', $self);
				return data;
			}
		},

		doFormat: function (data, format_def) {
			var self = this, format_string = '$1', fields = [], field_values = [];

			format_def = format_def || self.options;

			if ($.isPlainObject(format_def.format)) {
				fields = [ format_def.format ];
			} else {
				format_string = format_def.format;
				if ($.isPlainObject(format_def.fields)) {
					fields = [ format_def.fields ];
				} else if ($.isArray(format_def.fields)) {
					fields = format_def.fields;
				} else {
					fields = [];
				}
			}

			var field_idx = fields.length, f;

			while (field_idx--) {
				f = fields[field_idx];
				var current_value = self._getDataFromFieldName(f.field, data);
				f.options = f.options || {};

				if ($.isArray(f.formatter)) {
					if (f.formatter.length === 1) {
						current_value = self._runFormatter(f.formatter, f.options[f.formatter] || f.options, current_value, data);
					} else {
						for (var fmtr_idx=0; fmtr_idx<f.formatter.length; fmtr_idx++) {
							current_value = self._runFormatter(f.formatter[fmtr_idx], f.options[f.formatter[fmtr_idx]] || {}, current_value, data);
						}
					}

					field_values[field_idx] = current_value;
				} else if (f.formatter) {
					field_values[field_idx] = self._runFormatter(f.formatter, f.options[f.formatter] || f.options || {}, current_value, data);
				} else {
					field_values[field_idx] = current_value;
				}
			} // END iterate over fields

			// Apply the values to $1, $2, etc. in the format string
			var format_string_split = [], remainder = format_string, r_matches, match;

			if ( navigator.userAgent.indexOf('MSIE 8') !== -1) {
				// IE LTE 8 doesn't handle split with capturing, so we must make one ourselves
				while (remainder) {
					r_matches = remainder.match(/(.*?)(\$[0-9]+)/);
					if (!r_matches) {
						format_string_split.push(remainder);
						break;
					}

					if (r_matches[1]) { format_string_split.push(r_matches[1]); }
					if (r_matches[2]) { format_string_split.push(r_matches[2]); }

					remainder = remainder.slice(r_matches[0].length);
				}
			} else {
				// Meanwhile, back in civilization...
				format_string_split = format_string.split(/(\$\d+)/);
			}

			for (var fs_idx = 0; fs_idx < format_string_split.length; fs_idx++) {
				match = format_string_split[fs_idx].match(/^\$(\d+)$/);
				if (match) {
					if ((Number(match[1]) - 1) in field_values) {
						format_string_split[fs_idx] = field_values[ Number(match[1]) - 1 ];
					}
				}
			}

			format_string = format_string_split.join('');

			field_idx = field_values.length;
			while (field_idx--) {
				var rx = '\\$' + (field_idx + 1) + '(?![0-9])';
				format_string = format_string.replace(new RegExp(rx,'g'), field_values[field_idx]);
			}

			return format_string;
		}, // END doFormat

		_formatters: {
			value: function (opt, value) { return value; },
			value_fallback: function (opt, value) { return value || opt.fallback; },
			uc: function (opt, value) { return value.toString().toUpperCase(); },
			lc: function (opt, value) { return value.toString().toLowerCase(); },
			title_case: function (opt, value) {
				var arrSplit = value.split(" ");

				for (var i=0; i<arrSplit.length; i++) {
					if (arrSplit[i].length > 1) {
						arrSplit[i] = arrSplit[i][0].toUpperCase() + arrSplit[i].substr(1).toLowerCase();
					} else if (arrSplit[i].length == 1) {
						arrSplit[i] = arrSplit[i].toUpperCase();
					}
				}

				return arrSplit.join(" ");
			},

			cid_case: function (opt, value) {
				var parts = (value || '').split(' ');
				var abbrs = {'AL':true,'AK':true,'AS':true,'AZ':true,'AR':true,'CA':true,'CO':true,'CT':true,'DE':true,'DC':true,'FM':true,'FL':true,'GA':true,'GU':true,'HI':true,'ID':true,'IL':true,'IN':true,'IA':true,'KS':true,'KY':true,'LA':true,'ME':true,'MH':true,'MD':true,'MA':true,'MI':true,'MN':true,'MS':true,'MO':true,'MT':true,'NE':true,'NV':true,'NH':true,'NJ':true,'NM':true,'NY':true,'NC':true,'ND':true,'MP':true,'OH':true,'OK':true,'OR':true,'PW':true,'PA':true,'PR':true,'RI':true,'SC':true,'SD':true,'TN':true,'TX':true,'UT':true,'VT':true,'VI':true,'VA':true,'WA':true,'WV':true,'WI':true,'WY':true,'USA':true};

				for (var i in parts) {
					if (!(opt.ignore_abbr && abbrs[parts[i]])) {
						parts[i] = parts[i].toUpperCase().substr(0,1) + parts[i].toLowerCase().substr(1,parts[i].length);
					}
				}

				return parts.join(' ');
			},

			mac: function (opts, value) { return ((value || '').match(/../g) || []).join(':').toUpperCase(); },
			seconds_from_now: function (opts, value) {
				var date = Number(value) * 1000 + new Date().getTime();

				if (!value) { return ''; }

				opts = opts || {};
				opts.format = opts.format || '%L';
				return CUI.formatDate(date, opts.format);
			},
			seconds_duration: function (opts, value) {
				value = Math.floor(Number(value));

				var lbl = {
					_short: ['yr ', 'd ', 'h', 'm', 's'],
					_short_space: ['yr ', 'd ', 'h ', 'm ', 's'],
					_med:   [' yrs ', ' days ', ' hrs ', ' mins ', ' secs '],
					_long:  [' years ', ' days ', ' hours ', ' minutes ', ' seconds '],
					_colon: ['yr ', 'd ', ':', ':', '']
				}['_' + (opts.size || 'short')], div = [31536000, 86400, 3600, 60, 1], div_idx, str_out = '', cur_num, leftover = Number(value);

				if (isNaN(leftover)) { return value; }

				for (div_idx=0; div_idx<div.length; div_idx++) {
					cur_num = Math.floor(leftover / div[div_idx]);

					if (cur_num) {
						str_out += cur_num + lbl[div_idx];
					}

					leftover = leftover % div[div_idx];
				}

				str_out = str_out.replace(/[ ,]+$/, '');

				return str_out || '0' + lbl[lbl.length-1];
			},

			epoch: function (opts, value) {
				// "epoch" converts UNIX epoch times to a date string, as detailed in cui.formatDate.js

				if (!value) { return ''; }

				opts = opts || {};
				opts.format = opts.format || '%L';
				return CUI.formatDate(Number(value) * 1000, opts.format);
			},

			mins_tod: function (opts, value) {
				// Minutes of day to time of day

				var h, m, pm, mins = Number(value);

				h = Math.floor(mins / 60);
				m = mins % 60;

				if (m < 10) { m = '0' + m; }

				if (opts.time_mode !== '24h') {
					if (h > 12) {
						h -= 12;
						pm = ' PM';
					}

					if (h === 0) {
						h = 12;
					}

					pm = pm || ' AM';
				} else {
					pm = '';
				}

				return (h + ':' + m + pm);
			},

			phone_number: function (opts, value) {

				/* This is a LIGHT version of format_information in JavaScript. At this time, it only implements:
		 *
		 * Opts:
		 * ndash: false | true   // True: Use ndash as the range seperator, False (default): Use ASCII minus/hyphen as a range seperator
		 * no_auto_split: false | true // True: Don't split xxxx-yyyy numbers into ranges.
		 *
		 * x1234
		 * x1234-x5678
		 * 666-7777
		 * (555)666-7777
		 * (555)666-7777-(555)666-7789
		 *
		 * opts.no_ten_digit
		 * opts.no_extension
		 * opts.no_auto_split
		 *
		 *  */

				if (typeof(value) === 'undefined' || value === null) {
					return;
				}

				var match;
				opts = opts || {};

				var dash = opts.ndash ? '\u2013' : '-';

				// Format hyphen-delimited ranges
				if ((match = value.match(/(\d+)-(\d+)/)) && !opts.no_auto_split) {
					return format_information(match[1]) + dash + format_information(match[2]);
				}

				if (!opts.no_seven_digit) {
					if (value.search(/^(\d{3})([\da-zA-Z]{4})$/) > -1) { return value.replace(/^(\d{3})([\da-zA-Z]{4})$/, '$1-$2'); }
					if (value.search(/^1(\d{3})([\da-zA-Z]{4})$/) > -1) { return value.replace(/^1(\d{3})([\da-zA-Z]{4})$/, '1-$1-$2'); }
				}

				if (!opts.no_ten_digit) {
					if (value.search(/^(\d{3})([\da-zA-Z]{3})([\da-zA-Z]{4})$/) > -1) {
						return value.replace(/^(\d{3})([\da-zA-Z]{3})([\da-zA-Z]{4})$/, '($1) $2-$3');
					}
					if (value.search(/^(1|\+1)(\d{3})([\da-zA-Z]{3})([\da-zA-Z]{4})$/) > -1) {
						return value.replace(/^(1|\+1)(\d{3})([\da-zA-Z]{3})([\da-zA-Z]{4})$/, '$1($2) $3-$4');
					}
				}

				if (!opts.no_extension) {
					var ext_mark = opts.ext_mark || 'x';
					if (value.search(/^(\d{1,6})$/) > -1) { return value.replace(/^(\d{1,6})$/, ext_mark + '$1'); }
				}

				return value;
			},

			day_of_the_month: function(opts, value) {
				var suffix, out, val_str;
				suffix = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];

				val_str = value.toString();

				if (val_str === '-1') {
					out = 'Last day';
				} else if (val_str.length < 2) {
					out = val_str + suffix[value];
				}
				else if (val_str.charAt(0) == '1') {
					out = value + 'th';
				}
				else {
					out = value + suffix[parseInt(val_str.charAt(1))];
				}

				return out;
			},

			value_to_weekday: function(opts, value) {
				var day = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

				//debugLog('Incoming value: ', value);
				return day[value];
			},

			date: function (opts, value) {
				var out;

				// converts YYYY-MM-DD HH:MM:SS.xxxx to YYYY-MM-DD HH:MM:SS
				if (opts.strip_micro) {
					out = value.replace(/\..+$/,'');
				}
				else {
					out = value;
				}

				return out;
			},

			address: function (opts, value) {
				var separator, idx, parsed, labels, out = '';

				labels = ['PO Box', 'Attn', 'Street', 'City', 'State', 'ZIP', 'Country' ];
				separator = opts.separator || ';';
				parsed = value.split(separator);

				for (idx = 0; idx < parsed.length; idx++ ) {
					if (parsed[idx]) {
						if (labels[idx] == 'PO Box' || labels[idx] == 'Attn') {
							out += labels[idx] + ' ' + parsed[idx] + '\n';
						}
						else if (labels[idx] == 'City' || labels[idx] == 'State') {
							out += parsed[idx] + ' ';
						}
						else {
							out += parsed[idx] + '\n';
						}
					}
				}

				return out;
			},

			contact_type: function (opts, value) {
				var types = value.split(','), type_translate;

				type_translate = {
					aim: 'AIM',
					icq: 'ICQ',
					xmpp: 'XMPP',
					yahoo: 'Yahoo!'
				};

				for (var i=0; i<types.length; i++) {
					if (type_translate[types[i]]) {
						types[i] = type_translate[types[i]];
					} else if (types[i].length > 1) {
						types[i] = types[i][0].toUpperCase() + types[i].substr(1).toLowerCase();
					} else if (types[i].length == 1) {
						types[i] = types[i].toUpperCase();
					}
				}

				return types.join(", ");
			},

			plural: function (opts, value) {
				var plurals = opts.plurals;
				value = Number(value || 0);
				if (Math.abs(value) >= plurals.length) {
					return value + ' ' + plurals[plurals.length - 1];
				} else {
					return value + ' ' + plurals[Math.abs(value)];
				}
			},

			match: function (opts, value) {
				var regexp = new RegExp(opts.regexp), output;
				opts = opts || {};

				value = (value === 0) ? 0 : value || '';
				output = value.match(regexp);
				output = output ? output[opts.index || 0] : value;
				return output;
			},

			// Used to chop up and modify an extension value in the form /^[0-9]+(-[0-9]+)$/
			//
			// Options: 
			//   side: 'begin' | 'end'     // Beginning or end of the block. If the extension is not a block, both will return the same number.
			//   offset: <integer>         // Add or subtract from the returned value.
			//   post_format: false | true // Run the "phone_number" formatter on the value after it is determined
			//
			extension_math: function (opts, value, all_data) {
				var matches, side_str, num, out_str, side = opts.side || 'begin', offset = opts.offset || 0;

				if (!(matches = value.match(/^([0-9]+)(-([0-9]+))?/))) {
					return value;
				}

				if (side === 'end') {
					side_str = matches[3] || matches[1];
				} else {
					side_str = matches[1];
				}

				num = Number(side_str);

				// This should never happen, but...
				if (isNaN(num)) { return 'NaN:' + 
					value; }

				if (offset) {
					num += offset;
					out_str = num.toString();

					if (out_str.length < side_str.length) {
						// Zero pad the resulting number
						out_str = (new Array(side_str.length).join('0') + out_str).slice(-side_str.length);
					}
				} else {
					out_str = side_str;
				}

				if (opts.post_format) {
					return this.phone_number(opts, out_str, all_data);
				} else {
					return out_str;
				}
			},

			percent: function (opts, value) {
				var fix = opts.fix || 2, mult = Math.pow(10, fix);

				value = Math.round(Number(value || 0) * mult * 100) / mult;
				value = value.toString() + '%';

				return value;
			},

			// This changes the format if true or false. Use like:
			// { if_true: ':%1', if_false ':None' } -- if $1 is true, prepends a colon. If $1 is false, says "none". Both default to passing through the value.
			// NOTE: The replacement is done with "$[0-9]+", and ONLY uses the current value-- the number after the "$" is actually irrelevant
			'switch': function (opts, value) {
				if (value && opts.if_true === undefined) {
					return opts.if_true.replace(/\$[0-9]+/g, value);
				} else if (!value && opts.if_false === undefined) {
					return opts.if_false.replace(/\$[0-9]+/g, value);
				} else {
					return value;
				}
			}
		}
	};

})(jQuery);
