/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, debugLog */
/*

  This widget allows you to change the rest path that a dataTable widget is using

*/

(function( $ ){
    var dtwRestPathChangeWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			closest: '.pageModuleWidget, .pageWidgetType',
			selector: undefined,            // The selector for the element you are binding to, under closest
			widget_name: 'dataTableWidget', // The widget on that element you are trying to bind to (in case there are multiple)
			own_value_from: undefined,      // undefined uses the first found value_widget, otherwise name the widget that should be used
			events: 'change',               // Which event(s) signify a change?
			refresh_target: true,           // Run [target widget].refresh() after change?
			
			wait_for_screenReady: true,     // Wait for screenReady event before binding and attaching to the target (this should usually be true)

			// Internal use:
			_target_widget: undefined
		},

		_beforeInit: function () {
			var self = this, $self = this.element, $page;
			
			$page = $self.closest('.pageWidgetType');

			if (!$page.hasClass('screenReady') && self.options.wait_for_screenReady) {
				self._one($page, 'screenReady', CUI.FunctionFactory.build(self._initDRPC, self, { context: 'argument' }));
				return;
			}

			self._initDRPC();
		},

		_initDRPC: function () {
			var self = this, $self = this.element;

			if (self.options.closest && self.options.selector) {
				var $closest = $self.closest(self.options.closest);
				var $linked = $closest.find(self.options.selector);

				if ($linked[0]) {
					self.options.widget_name = self.options.widget_name || 'widget';
					self.options._target_widget = $linked.getCUIWidget(self.options.widget_name);

					if (self.options._target_widget) {
						self.options._target_widget.addDataHook('RestPathChange', CUI.FunctionFactory.build(self._restPathChangeHook, self, { context: 'argument' }));
					}
				} 

				if (!self.options._target_widget) {
					debugLog('jquery.dtwRestPathChangeWidget.js: Could not find the linked DOM element (', self.options.widget_name, ' in ', $linked, ' under ', $closest, ') -- ', $self);
				}
			}

			self._bind($self, self.options.events, CUI.FunctionFactory.build(self._changeHandler, self, { context: 'argument' }));
			
			self._changeHandler(); // Execute the change handler the first time, in case our widget already has a value to pass along
		},
		
		fillData: function () {
			var self = this;
			self._changeHandler();
		},

		// This is where the REST path is actually specified. The DTW calls its hook (set above) and expects the hook to return a new REST path. This is that.
		_restPathChangeHook: function(rest_params) {
			var self = this;
			return (self.options.value ? self.options.value.rest : undefined);
		},

		_changeHandler: function () {
			var self = this, $self = this.element, widget, value;

			widget = self.options.own_value_from ? $self.getCUIWidget(self.options.own_value_from) : undefined;

			if (self.options.own_value_from && widget && widget.value_widget) {
				// The specified widget is a value widget-- we can use this!
				value = widget.getValue();
			} else {
				// CUI.getWidgetElementValue handles everything-- scanning and retrieval.
				value = CUI.getWidgetElementValue($self);
			}

			self.options.value = value;

			if (self.options._target_widget && typeof self.options._target_widget.refresh === 'function' && self.options.refresh_target) {
				self.options._target_widget._pageFirst();
				self.options._target_widget.refresh();
			}
		},

		_afterSetURIParams: function () {
			var self = this, $self = this.element;
			var rest_paths = {
				unregistered: '/gui/freeswitch/registrations/select_inactive',
				registered: '/gui/freeswitch/registrations/select_active',
				restricted: '/gui/freeswitch/registrations/select_jailed'
			};

			if (!self.options.uri_params) { return; }

			$self.val(rest_paths[self.options.uri_params.show]);
			self.fillData();
		}
    });
    
    add_widget('dtwRestPathChangeWidget', dtwRestPathChangeWidget);
})(jQuery);
