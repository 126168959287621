/* jshint jquery: true, unused: vars */
/* global Ape, add_widget */

(function( $ ){
    
    var systemTimeWidget = $.extend({}, $.ui.widget.prototype, {
	
	options: {
	    
	},
	
	_afterInit: function() {
	    var $self = this.element;
	    var self = this;
	    
	    self.options.inline = true;
	    
	    self._setupSystemTimeWidgetCallbackClosures();
	   
	    Ape.subscribe('meteor_alive');
	    $(window).on('meteor_meteor_alive', self.options.meteor_alive_callback_closure);
	    $(window).on('meteor_system_status', self.options.meteor_system_status_callback_closure);
	},

	_meteorAlive : function(e, data) {
	    var $self = this.element;
            var self = this;

	    self.options.time = data.json.alive;
	},

	_tick : function(e, data) {
	    var $self = this.element;
            var self = this;

	    self.options.time++;
	    self._updateTimeDisplay();
	},
	
	_meteorSystemStatus : function(e, data) {
	    var $self = this.element;
            var self = this;

            if (data.json.action == 'time') {
		self.options.offset = (data.json.offset + (new Date().getTimezoneOffset() * 60)) * -1;
		self.options.timezone = data.json.zone;
	    }
	},

	fillData: function(d, from_self) {
	    var $self = this.element;
            var self = this;

	    if (!from_self) {
		self.options.data = d;
		self.options.offset = (d.offset + (new Date().getTimezoneOffset() * 60)) * -1;
		self.options.timezone = d.timezone;
		self.options.time = d.time;
		self._updateTimeDisplay();
	    }
	},

	_updateTimeDisplay: function() {
	    var $self = this.element;
            var self = this;

	    if (!self.options.time || !self.options.timezone) {
		return;
	    }
	    
	    var time = new Date();
            time.setTime((self.options.time - self.options.offset) * 1000);
	    var time_str = time.toLocaleDateString() + ' ' + time.toLocaleTimeString() + ' ' + self.options.timezone;
	    
	    $self.html(time_str);
	    setTimeout(self.options.tick_callback_closure, 1000);
	},
	
	_setupSystemTimeWidgetCallbackClosures: function() {
	    var $self = this.element;
	    var self = this;
	    
	    self.options.update_time_display_callback_closure = function() {
		self._updateTimeDisplay();
	    };
	    self.options.meteor_system_status_callback_closure = function(e, data) {
		self._meteorSystemStatus(e, data);
	    };
	    self.options.meteor_alive_callback_closure = function(e, data) {
		self._meteorAlive(e, data);
	    };
	    self.options.tick_callback_closure = function(e, data) {
		self._tick(e, data);
	    };
	    
	}
	
    });
    
    add_widget('systemTimeWidget', systemTimeWidget);
})(jQuery);
