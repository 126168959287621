/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/*
Implementation of CUI.aopbParser (for input parsing) and CUI.formatter (for action). Allows setting simple formats, classes, CSS, and valid/invalid format
based on a-op-b-object formatted conditions.

USAGE:

{
    states: [
	{
	    conditions: [ { ... see cui.aopbParser.js for details on conditions ... } ],

	    // Only one of these are required -- see cui.formatter.js for format options details
	    formatting:      { format: '...' | { ... }, fields: [ ... ] },
	    else_formatting: { format: '...' | { ... }, fields: [ ... ] },

	    // Only the last state will be parsed to create the formatting, so you may need to use last_if_true or last_if false to halt processing early
	    last_if_true: true, // (optional)
	    last_if_true: false // (optional)
	}
    ]
}

*/

(function( $ ){
	"use strict";
	var aopbFormatWidget = $.extend(true, {}, $.ui.widget.prototype, CUI.formatter, CUI.aopbParser, {
		_prefer_own_widget: false,
		_actions_keys: { 'true': 'format', 'false': 'else_format' },

		_beforeInit: function () {
			this._init_states();
		},

		_beforeDOMReady: function () {
			this._watch_events();
		},

		_perform_aopb_actions: function (all_actions) {
			var self = this;
			self.options._current_format = all_actions[all_actions.length - 1];
			self._reformat();
		},

		_reformat: function () {
			var self = this;
			if (self.options._filledData && self.options._current_format) {
				self.setValue(self.doFormat(self.options._filledData, self.options._current_format));
			}
		}
	});

	add_widget('aopbFormatWidget', aopbFormatWidget);
})(jQuery);
