/* jshint jquery: true, unused: vars */
/* global add_widget, trueish */
/* 
   This is a widget that examines an object on an ancestor widget, and determines which select elements to show based on whether or not given keys are
   true(ish). This is a very limited-purpose widget, made for SELECT elements under the phone buttons table.

   Apply this widget to a SELECT element.

   OPTIONS:
   {
     closest:   '.selector',    // This is the element examined
     widget:    'widget',       // This is the widget on that element to examine
     select_options: [
       { text: 'Text of OPTION', value: 'Value of OPTION', flag: 'key from ancestor data' },
       ...
     ]
   }

*/

(function( $ ){
	"use strict";
	var ancestorDependentSelectWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			closest: '.dataHolderContainerWidget',
			widget: 'dataHolderContainerWidget',

			_widget: undefined,
			_value: undefined,
			_options: [],
			_select_ready: false
		},

		// This method tests an option for whether or not it should be included in the SELECT element
		// Override this method to add additional criteria in a subclass (ex: see phoneButtonFunctionWidget)
		_testOption: function(option) {
			var self = this, $self = this.element;
			var widget = self.options._widget;

			if (!option.flag || trueish(widget.options.data[option.flag])) {
				return true;
			} else {
				return false;
			}
		},

		_beforeInit: function () {
			var self = this, $self = this.element;

			self.options._widget = $self.closest(self.options.closest).getCUIWidget(self.options.widget);

			if (self.options._widget) {
				$self.empty();
				for (var s_idx = 0; s_idx < self.options.select_options.length; s_idx++) {
					var select = self.options.select_options[s_idx];

					if (self._testOption(select)) {
						$self.append($('<option />').val(select.value).text(select.text));
					}
				}
			}
		}
	});

	add_widget('ancestorDependentSelectWidget', ancestorDependentSelectWidget);
})(jQuery);
