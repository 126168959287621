/* jshint jquery: true, unused: vars */
/* global add_widget, getXSRFKey */
(function( $ ){
	var csvButtonWidget = $.extend(true, {}, $.ui.actionButtonWidget.prototype, {
		options: {
			closest: '.pageWidgetType',
			selector: '.dataTableWidget',
			target:  'dataTableWidget',

			// Params to filter out
			filter_params: [ 'page', 'rows' ]
		},

		// Hijack _afterConfirm to change the document location, instead of submitting a REST request

		_afterConfirm: function () {
			var self = this, $self = this.element, $closest, $widget, target, params, fparam;

			$closest = $self.closest(self.options.closest);
			$widget = $closest.find(self.options.selector);
			target = $widget.getCUIWidget(self.options.target);

			params = $.extend({}, self._getRESTParams() || {}, target.options.rest_params);

			// Remove filtered params
			for (fparam in self.options.filter_params) {
				delete params[self.options.filter_params[fparam]];
			}

			document.location.href = self.options.rest + '?' + $.param(params);
		}

	}); // end widget variable definition
	add_widget('csvButtonWidget', csvButtonWidget);
})(jQuery);
