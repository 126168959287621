/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){
    var callflowNumberListDisplayWidget = $.extend(true, {}, $.ui.widget.prototype, {
	fillData: function (d, from_self) {
	    var self = this, $self = this.element, nums = [], n_idx;

	    if (d.number_list) {
		for (n_idx = 0; n_idx < d.number_list.length; n_idx++) {
		    nums.push(CUI.formatter.doFormat({n:d.number_list[n_idx]},{ format: { field: '@n', formatter: 'phone_number', options: { no_auto_split: true } } }));
		}

		if (nums.length) {
		    $self.text(nums.join(', '));
		} else {
		    $self.text('(No numbers configured.)');
		}
	    }
	}
    });
    
    add_widget('callflowNumberListDisplayWidget', callflowNumberListDisplayWidget);
})(jQuery);
