register_screen('status', {
    elements: [
	{ entity_template: 'page_title', title: 'Status' },
	{ entity_template: 'page_buttons' },
	{ entity_template: 'page_content', 'class': 'dashboard', elements: [
	    {
		entity_templates: [ 'page_module', 'key_value' ],
		page_module: {
		    legend: 'Phone Status',
		    buttons: ['help'],
		    help_id: 'dashboard_phone_status',
		    'class': 'page_module_half_width nth-child-odd phone_status',
		    elements: [
			{
			    entity_template: 'widget_div',
			    rest_method: 'post',
			    rest: '/gui/update/query',
			    attributes: { style: 'display: none' }
			},
			{
			    entity_template: 'data_item',
			    title: 'Configured Phones:',
			    elements: [ { entity: 'div', attributes: { name: 'provisioned_phones' } } ]
			},			{
			    entity_template: 'data_item',
			    title: 'Assigned to People:',
			    elements: [ { entity: 'a', attributes: { href: '#!/bps/users', name: 'assigned_phones' } } ]
			},
			{
			    entity_template: 'data_item',
			    title: 'Unassigned Phones:',
			    elements: [ { entity: 'a', attributes: { href: '#!/bps/phones', name: 'unassigned_phones' } } ]
			},
			{
			    entity_template: 'data_item',
			    title: 'Phones Online:',
			    elements: [ { entity: 'a', attributes: { href: '#!/bps/registrations', name: 'registered_phones' } } ]
			},
			{
			    entity_template: 'data_item',
			    title: 'Phones Offline:',
			    elements: [ { entity: 'a', attributes: { href: '#!/bps/registrations/widgets/{"dtw_rest_path": {"show": "unregistered"}}', name: 'unregistered_phones' } } ]
			}
		    ]
		},
		key_value: {
		    live_table: 'live_system_stats',
		    live_table_key: 'system_stats',
		    key_column: 'name',
		    key_filters: ['!^build_'],
		    value_column: 'value'
		}
	    },
	    {
		entity_templates: [ 'page_module', 'key_value' ],
		page_module: {
		    legend: 'Updates and Support',
		    buttons: ['help'],
		    help_id: 'dashboard_updates_and_support',
		    'class': 'page_module_half_width nth-child-even',
		    elements: [
			{
			    entity_template: 'data_item',
			    title: 'System:',
			    elements: [ { entity: 'div', attributes: { name: 'full_prodname' } } ]
			},
			{
			    entity_template: 'data_item',
			    title: 'Serial:',
			    elements: [ { entity: 'div', attributes: { name: 'system_serial' } } ]
			},
			{
			    entity_template: 'data_item',
			    title: 'Firmware:',
			    elements: [
				{ entity: 'span', attributes: { name: 'system_version' } },
				{
				    entity_template: 'link_button',
				    text: 'Update Available',
				    href: '#!/bps/updates',
				    widgets: [ 'aopbStateWidget' ],
				    widget_options: {
					aopbStateWidget: {
					    states: [
						{
						    conditions: [
							{ conditions: [
                                                            { a: '@ea_versions', op: '==', b: '_TRUE_' },
                                                            { join: 'and', a: '@ea_versions', op: '!=', b: '@system_version' }
                                                        ]},
							{ join: 'or', a: '@ga_version', op: '>', b: '@system_version' }
						    ],
						    actions: { visible: true },
						    else_actions: { visible: false }
						}
					    ]
					}
				    }
				}
			    ]
			},
			elgen('subscription_status_item', { title: 'Energize Updates', type: 'energize' }),
			elgen('subscription_status_item', { title: 'Instant Replacement', type: 'inst_replace' }),
			elgen('subscription_status_item', { title: 'Premium Support', type: 'premium' }),
			{
			    entity_template: 'input_item',
			    title: 'Product Activation:',
			    type: 'div',
			    input_options: {
				widgets: ['aopbStateWidget'],
				widget_options: {
				    aopbStateWidget: {
					states: [
					    {
						conditions: [{ a: '@activated', op: '==', b: '_TRUEISH_' }],
						actions: { text: 'Activated' },
						else_actions: { text: 'Not Activated' },
						last_if_true: true
					    },
					    {
						// Add this condition so it doesn't show "Not Activated" until the fillData.
						conditions: [{ a: '@activated', op: '==', b: '_UNDEFINED_' }],
						actions: { text: ' ' }
					    }
					]
				    }
				}
			    }
			}
		    ]
		},
		key_value: {
		    live_table: 'live_system_stats',
		    live_table_key: 'update',
		    key_column: 'name',
		    key_filters: ['!^build_'],
		    value_column: 'value'
		}
	    },
	    {
		entity_template: 'page_module',
		legend: 'SIP Providers and Hardware Connections',
		buttons: ['help'],
		help_id: 'dashboard_providers',
		elements: [
		    {
			entity_template: 'data_table',
			live_table: 'live_provider_stats',
			live_table_key: 'provider_stats',
			data_source: 'live',
			allow_scroll: false,
			filtering: false,
			length_change: false,
			paginate: false,
			processing: false,

			search: {
			    // The search never matches a NULL value in the table, so we need to implicitly search for gateways, too
			    bbx_tdm_card_port_trunk_type: '^(?!FXS)',
			    bbx_provider_gateway_id: '.'
			},

			collapse_to_module: true,

			js_sort_by: [['type', 'asc'],['name', 'asc']],
			page_size: 1000,
			oLanguage: {
			    sZeroRecords: 'Loading...'
			},
			columns: [
			    {
				header: 'Name',
				column: 'name',
				sortable: false
			    },
			    {
				header: 'Provider Type',
				column: 'type',
				sortable: false
			    },
			    {
				header: 'Status',
				column: 'status',
				sortable: false,
				view_element: {
				    entity: 'div',
				    widgets: ['aopbStateWidget'],
				    widget_options: {
					aopbStateWidget: {
					    states: [
						{
						    conditions: { a: '@status', op: '==', b: 'NEW', b_is: 'literal' },
						    actions: { color: 'green', text: 'New' },
						    last: true
						},
						{
						    conditions: { a: '@status', op: '==', b: 'NOREG', b_is: 'literal' },
						    actions: { color: 'blue', text: 'No Registration Required' },
						    last: true
						},
						{
						    conditions: { a: '@status', op: '==', b: 'REGED', b_is: 'literal' },
						    actions: { color: 'green', text: 'Registered' },
						    last: true
						},
						{
						    conditions: { a: '@status', op: '==', b: 'REGISTER', b_is: 'literal' },
						    actions: { color: 'red', text: 'Registration Required' },
						    last: true
						},
						{
						    conditions: { a: '@status', op: '==', b: 'UNREGED', b_is: 'literal' },
						    actions: { color: 'pink', text: 'Refreshing Registration' },
						    last: true
						},
						{
						    conditions: { a: '@status', op: '==', b: 'REFRESH', b_is: 'literal' },
						    actions: { color: 'pink', text: 'Refreshing Registration' },
						    last: true
						},
						{
						    conditions: { a: '@status', op: '==', b: 'FAIL_WAIT', b_is: 'regexp' },
						    actions: { color: 'pink', text: 'Retrying Registration' },
						    last: true
						},
						{
						    conditions: { a: '@status', op: '==', b: 'FAIL', b_is: 'regexp' },
						    actions: { color: 'red', text: 'Registration Failed' },
						    last: true
						},
						{
						    conditions: { a: '@status', op: '==', b: 'R', b_is: 'regexp' },
						    actions: { color: 'red', text: 'Local RED' },
						    last: true
						},
						{
						    conditions: { a: '@status', op: '==', b: 'Y', b_is: 'regexp' },
						    actions: { color: 'orange', text: 'Remote YELLOW' },
						    last: true
						},
						{
						    conditions: { a: '@status', op: '==', b: 'A', b_is: 'regexp' },
						    actions: { color: 'pink', text: 'Remote RED' },
						    last: true
						},
						{
						    conditions: { a: '@status', op: '==', b: '' },
						    actions: { color: 'green', text: 'OK' },
						    last: true
						},
						{
						    // Fallback
						    actions: { color: '#ccc', text: 'Unknown' }
						}
					    ]
					}
				    }
				}
			    }
			]
		    }
		]
	    },
	    {
		entity_templates: [ 'page_module' , 'key_value' ],
		page_module: {
		    legend: 'Performance',
		    buttons: ['help'],
		    help_id: 'dashboard_performance',
		    'class': 'page_module_half_width nth-child-odd',
		    elements: [
			{
			    entity_template: 'data_item',
			    title: 'Firmware Storage:',
			    elements: [ { entity: 'div', attributes: { name: 'firmware_storage' }, widgets: [ 'progressWidget' ] } ]
			},
			{
			    entity_template: 'data_item',
			    title: 'Voicemail Storage:',
			    elements: [ { entity: 'div', attributes: { name: 'voicemail_storage' }, widgets: [ 'progressWidget' ] } ]
			},
			{
			    entity_template: 'data_item',
			    title: 'CPU Load:',
			    elements: [ { entity: 'div', attributes: { name: 'cpu_load' }, widgets: [ 'progressWidget' ] } ]
			},
			{
			    entity_template: 'data_item',
			    title: 'CPU Temp:',
          requires: ['!$virtual'],
          elements: [ {
				entity_template: 'progress_bar',
				name: 'cpu_temp',
				text_unit: entity.deg + ' C',
				min_value: '15',
				max_value: '75'
			    } ]
			},
			{
			    entity_template: 'data_item',
			    title: 'System Temp:',
          requires: ['!$virtual'],
			    elements: [ {
				entity_template: 'progress_bar',
				name: 'system_temp',
				text_unit: entity.deg + ' C',
				min_value: '15',
				max_value: '75'
			    } ]
			},
			{
			    entity_template: 'data_item',
			    title: 'CPU Fan:',
			    requires: ['!$model_170', '!$virtual'],
			    elements: [ {
				entity_template: 'progress_bar',
				name: 'cpu_fan',
				text_unit: ' RPM',
				min_value: '1000',
				max_value: '9000'
			    } ]
			},
			{
			    entity_template: 'data_item',
			    title: 'System Fan:',
          requires: ['!$virtual'],
			    elements: [ {
				entity_template: 'progress_bar',
				name: 'system_fan',
				text_unit: ' RPM',
				min_value: '1000',
				max_value: '9000'
			    } ]
			},
			{
			    entity_template: 'data_item',
			    title: 'System Time:',
			    elements: [ {
				entity: 'div',
				widgets: [ 'containerWidget' ],
				widget_options: {
				    rest: '/gui/system/timezone',
				    elements: [ { entity: 'div', widgets: [ 'systemTimeWidget' ] } ]
				}
			    } ]
			}
		    ]
		},
		key_value: {
		    live_table: 'live_system_stats',
		    live_table_key: 'system_stats',
		    key_column: 'name',
		    value_column: 'value'
		}
	    },
	    {
		entity_template: 'page_module',
		'class': 'page_module_half_width nth-child-even',
		buttons: ['help'],
		help_id: 'dashboard_voicemail_chart',
		legend: 'Voicemail Chart',
		elements: [
		    {
			entity_template: 'vm_pie_chart',

			live_table: 'live_system_stats',
			live_table_key: 'voicemail_stats',
			live: true,
			items: [
			    { title: 'Read Length',     name: 'read_length' },
			    { title: 'Unread Length',   name: 'unread_length' },
			    { title: 'Read Count',      name: 'read_count' },
			    { title: 'Unread Count',    name: 'unread_count' }
			],
			columns: [
			    { column: 'id' },
			    { column: 'table_key' },
			    { column: 'name' },
			    { column: 'value' },
			    { column: 'current_time' }
			],
			unit: 'seconds',
			title: 'Voicemail',
			chart_options: {
			    width: '248',
			    height: '248',
			    backgroundColor: null, /* Fix black background in IE 7 & 8 */
			    plotBackgroundColor: null /* Fix black background in IE 7 & 8 */
			},
			primary_fields: {
			    read_length: true,
			    unread_length: true
			},
			labels: {
			    read_length: 'Read',
			    unread_length: 'Unread'
			},
			secondary_fields: {
			    read_length: 'read_count',
			    unread_length: 'unread_count'
			},
			secondary_unit: 'messages',
			background: 'transparent'
		    }
		]
	    },
		{
			entity: 'span',
			attributes: { 'style':'display:none' },
			widgets: 'kcListenerWidget'
		}
	]
	}
    ]
});
