register_screen('queue_activity', {
    elements: [
	{
	    entity: 'h2',
	    attributes: {
		'class': 'page_title'
	    },
	    text: 'Live Queue Activity'
	},
	{
	    entity_template: "page_buttons" ,
	    buttons: [ { type: 'fullscreen' }, { type: 'help', help_id: 'live_queue_statistics' } ]
	},
	{ entity_template: "page_content",
	  "class": "queue_activity",
	  elements: [
	      {
		  entity_template: "container",
		  elements: [ {
		      entity_template: "refreshing_data_table",
		      refresh_hours: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23],
		      include_hour_in_filter: true,

		      dtw_options: {
			  row_key: 'bbx_queue_id',
			  live_table: "live_queue_activity",
			  live_table_key: "",
			  live_unique_ident: true,
			  data_source: "live",
			  page_size: 10000,
			  filtering: false,
			  processing: false,
			  oLanguage: {
			      sZeroRecords: "Waiting for queue statistics..."
			  },
			  columns: [
			      {
				  header: "Queue Name",
				  column: "bbx_queue_name"
			      },
			      {
				  header: "Calls In",
				  column: "calls_in",
				  data_type: "num-html"
			      },
			      {
                                  header: "Transferred In",
                                  column: "calls_transferred_in",
                                  data_type: "num-html"
                              },
			      {
				  header: "Answered",
				  column: "calls_answered",
				  data_type: "num-html"
			      },
			      {
				  header: "Abandoned",
				  column: "calls_abandoned",
				  data_type: "num-html"
			      },
			      {
				  header: "Abandoned (%)",
				  column: "abandoned_percent",
				  data_type: "num-html",
				  formatted: {
				      format: '$1%',
				      fields: [
					  {
					      field: 'abandoned_percent'
					  }
				      ]
				  }
			      },
			      {
				  header: "Missed",
				  column: "calls_agents_missed",
				  data_type: "num-html"
			      },
			      {
				  header: "Rejected",
				  column: "calls_agents_rejected",
				  data_type: "num-html"
			      },
			      {
				  header: "Calls/hour",
				  column: "calls_per_hour",
				  data_type: "num-html"
			      },
			      {
				  header: "Avg Wait Time",
				  column: "average_wait_time",
				  data_type: "num-html",
				  formatted: {
				      format: {
					  field: 'average_wait_time',
					  formatter: 'seconds_duration'
				      }
				  }
			      },
			      {
				  header: "Avg Hold Time",
				  column: "average_hold_time",
				  data_type: "num-html",
				  formatted: {
				      format: {
					  field: 'average_hold_time',
					  formatter: 'seconds_duration'
				      }
				  }
			      },
			      {
				  header: "Avg Talk Time",
				  column: "average_talk_time",
				  data_type: "num-html",
				  formatted: {
				      format: {
					  field: 'average_talk_time',
					  formatter: 'seconds_duration'
				      }
				  }
			      },
			      {
				  header: "Avg Time To Abandoned",
				  column: "average_time_to_abandoned",
				  data_type: "num-html",
				  formatted: {
				      format: {
					  field: 'average_time_to_abandoned',
					  formatter: 'seconds_duration'
				      }
				  }
			      }
			  ]
		      } // END dtw_options
		  } ]
	      } ]
	  }
	]
    });
