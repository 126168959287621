register_screen('router_actions', {
    rest: '/gui/extension/router',
    rest_container: 'router',
    dataEvents: {
	bind: {
	    'callflow': ''
	}
    },
    elements: [
	{ entity_template: 'page_title', title: 'Manage Router Action Sets' },
	{
	    entity_template: 'page_buttons'
	},
	{ entity_template: 'page_content', elements: [
	    {
		entity_template: 'page_module',
		legend: 'Action Sets',
		buttons: [ 'help' ],
		help_id: 'routers_actions',
		elements: [
		    { 
			entity_template: 'data_table',
			rest: '/gui/extension/router/callflows',
			dataEvents: {
			    bind: { callflow: '' },
			    trigger: 'callflow',
			    allow_delete: true
			},
			paginate: true,
			length_change: true,
			table_actions: {
			    add_row: {
				text: 'Create an Action Set',
				location: 'bottom'
			    },
			    delete_rows: {
				text: 'Delete Selected Action Sets',
				location: 'bottom',
				confirm: { title: 'Delete Selected Action Sets', text: 'Are you sure you want to delete the selected actions? Any rules using these actions will be deleted.' },

				validate_rest: '/gui/extension/router/check_delete_callflow',
				validate_rest_container: 'check_delete_callflow'
			    },
			    select_row: true,
			    select_all_rows: true
			},

			click_action: 'overlay',
			primary_keys: ['bbx_callflow_id'],
			delete_action: {
			    rest: '/gui/extension/router/callflow'
			},
			add_edit_action: {
			    rest: '/gui/extension/router/callflow',
			    rest_container: 'callflow',
			    only_rest_params: ['bbx_router_id']
			},

			row_actions: {
			    add_cancel: { text: 'Cancel' }
			},			

			overlay_name: 'router_action_detail',
			overlay_params: [
			    'bbx_callflow_id'
			],
			overlay_data: {
			    key: 'bbx_callflow_id'
			},

			extra_row_data: [
			    'actions',
			    'bbx_callflow_anonymous_call_blocking',
			    'bbx_callflow_caller_id',
			    'bbx_callflow_caller_id_name',
			    'bbx_callflow_caller_id_number',
			    'bbx_callflow_detect_fax',
			    'bbx_callflow_fax_email',
			    'bbx_callflow_final_action',
			    'bbx_callflow_final_action_email',
			    'bbx_callflow_final_action_extension_id',
			    'bbx_callflow_final_action_extension_value',
			    'bbx_callflow_final_action_provider_gateway_id',
			    'bbx_callflow_final_action_tdm_card_port_id',
			    'bbx_callflow_final_action_type',
			    'bbx_callflow_id',
			    'bbx_callflow_name',
			    'bbx_callflow_popup',
			    'bbx_callflow_popup_url',
			    'bbx_callflow_public',
			    'bbx_callflow_record_session',
			    'bbx_callflow_saved',
			    'bbx_callflow_silent_hold',
			    'bbx_callflow_t38_reinvite',
			    'bbx_router_id',
			    'bbx_user_id'
			],

			columns: [
			    {
				header: 'Action Set Name',
				column: 'bbx_callflow_name',
				add_element: {
				    entity: 'input',
				    attributes: { type: 'text', name: 'bbx_callflow_name', size: 40 }
				}
			    },
			    {
				header: 'Scope',
				width: '10em',
				column: 'bbx_callflow_public',
				translate: {
				    1: 'Global'
				},
				translate_default: 'Local (This Router)',
				add_element: {
				    entity: 'select',
				    attributes: { name: 'bbx_callflow_public' },
				    select_options: [
					{ text: 'Local (This Router)', value: 0 },
					{ text: 'Global', value: 1 }
				    ]
				}
			    },
			    {
				header: 'Summary',
				width: '50%',
				column: 'summary',
				add_element: { entity: 'div', text: 'The Router Action Set can be edited after it has been added' }
			    }
			] // END columns
		    } // END DTW
	 	]
	    }//, // END page module "Action Sets"
	]}
    ]
});
