/*
  futureTimeSelectorWidget: Select a future time, either as yyyy-mm-dd hh:mm:ss, or a number of seconds
*/

(function( $ ){
    var futureTimeSelectorWidget = $.extend(true, {}, $.ui.widget.prototype, {
		value_widget: true,
		set_value_widget: true,
		manages_own_descendent_value: true,

		options: {
			template: false,
			template_selector: false,

			specific_field: 'specific_time',
			elapsed_field:  'elapsed_time',
			allow_indefinite: true,

			minute_multiplier: 60, // for seconds

			clear_on_fill_data: true
		},

		_beforeInit: function () {
			var self = this, $self = this.element, $t_sel, $specific, $elapsed, t_idx, html_tmp, $ymd, $hh, $mm, $ap;
			$self.addClass('future-time-selector');

			$t_sel = self.options._$type_select = $('<select class="fts-specific-elapsed"><option value="elapsed">Duration</option><option value="specific">Specific Time</option>' + (self.options.allow_indefinite ? '<option value="indefinite">Until Changed Again</option>' : '') + '</select>');
			$t_sel.on('click change', self._typeSelectHandler.bind(self, $t_sel));

			$elapsed = self.options._$elapsed = $('<div class="fts-elapsed-wrap" />');
			['days','hours','minutes'].forEach(function (type) {
				$elapsed.append('<label><input type="number" min="0" class="fts-elapsed-time-input fts-elapsed-' + type + '" size="3" /> ' + type + '</label>');
			});

			$specific = self.options._$specific = $('<div class="fts-specific-wrap"><input class="fts-specific-date" type="text" readonly="readonly" placeholder="(Select Date)" /><select class="fts-specific-h" /><select class="fts-specific-m" /><select class="fts-specific-a" /></div>');

			$ymd = $specific.find('.fts-specific-date');
			$ymd.datepicker({
				minDate: 0,
				dateFormat: 'MM dd, yy'
			});

			html_tmp = '';
			for (t_idx = 1; t_idx <= 12; t_idx++) {
				html_tmp += '<option value="' + t_idx + '">' + t_idx + '</option>';
			}

			$hh = $specific.find('.fts-specific-h');
			$hh.append(html_tmp);
			$hh.val('1');

			html_tmp = '';
			for (t_idx = 0; t_idx <= 59; t_idx++) {
				html_tmp += '<option value="' + t_idx + '">:' + ('0' + t_idx.toString()).slice(-2) + '</option>';
			}

			$mm = $specific.find('.fts-specific-m').append(html_tmp);
			$ap = $specific.find('.fts-specific-a').append('<option value="a">AM</option><option value="p">PM</option>');

			$self.append($t_sel, $elapsed, $specific);

			self._typeSelectHandler($t_sel);

			$self.on('change', function () { self.options.changed = true; });
		},

		fillData: function (v) {
			var self = this;
			if (!self.options.clear_on_fill_data) { return; }
			self._resetFields();
		},

		_resetFields: function () {
			var self = this, $self = this.element;
			self.options.changed = false;
			$self.find('.fts-elapsed-days, .fts-elapsed-hours, .fts-elapsed-minutes').each(function () { this.value = ''; });
			$self.find('.fts-specific-date').datepicker('setDate', new Date());
			$self.find('.fts-specific-h, .fts-specific-m, .fts-specific-a, .fts-specific-elapsed').each(function () { this.selectedIndex = 0; });
		},

		_typeSelectHandler: function ($elem, e) {
			var self = this, $self = this.element, classes, c, to_show;

			classes = { specific: '.fts-specific-wrap', elapsed: '.fts-elapsed-wrap' };
			to_show = $elem.val();

			for (c in classes) {
				$self.find(classes[c])[ (to_show === c ? 'show' : 'hide') ]();
			}
		},

		isDirty: function () {
			return (this.options.changed);
		},

		_getWidgetValue: function () {
			var self = this, $self = this.element, type, date_str, time_str, elapsed_time, obj_out = {};
			var pad2 = function (num_in) { return (('0' + num_in.toString()).slice(-2)); };

			type = $self.find('.fts-specific-elapsed').val();

			switch (type) {
				case 'specific':
					date = $self.find('.fts-specific-date').datepicker('getDate') || new Date();
					date_str = [date.getFullYear().toString(), pad2(date.getMonth() + 1), pad2(date.getDate())].join('-');

					hour = Number($self.find('.fts-specific-h').val());

					if ($self.find('.fts-specific-a').val() === 'p') {
						hour += 12;
						if (hour === 24) { hour = 0; }
					}

					time_str = [pad2(hour), pad2($self.find('.fts-specific-m').val()), '00'].join(':');
					obj_out[ self.options.specific_field ] = [date_str, time_str].join(' ');
				break;
				case 'elapsed':
					elapsed_time = Number($self.find('.fts-elapsed-minutes').val()) || 0;
					elapsed_time += (Number($self.find('.fts-elapsed-hours').val()) || 0) * 60;
					elapsed_time += (Number($self.find('.fts-elapsed-days').val()) || 0) * 1440;
					elapsed_time *= (self.options.minute_multiplier || 1);
					obj_out[ self.options.elapsed_field ] = elapsed_time;
				break;
			}

			return obj_out;
		}
    });

    add_widget('futureTimeSelectorWidget', futureTimeSelectorWidget);
})(jQuery);
