/* jshint jquery: true, browser: true */
/* global CUI, classCUI */
/*

  A widget-extension base class that iterates through s.o.elements array, and runs "getEntitiesHTML" on each element definition found.

  If _beforeDOMReady is used in an extending class, be sure to run:
    CUI.htmlContainerClass._beforeDOMReady.apply(self, arguments);
  in your extended _beforeDOMReady.

*/

classCUI.prototype.htmlContainerClass = $.extend({}, CUI.htmlEntityClass, new function() {

	this._beforeDOMReady = function() {
		var self = this, $self = this.element, fragment, entities, child, num_children;

		fragment = document.createDocumentFragment();

		if (self.options.elements) {
			var $entities = self.getEntitiesHTML(self.options.elements, self.options.data);
			if ($entities && $entities[0]) {
				entities = $entities[0];
				for (child = 0, num_children = entities.children.length; child < num_children; ++child) {
					fragment.appendChild(entities.children[0]);
				}
				$self[0].appendChild(fragment);
			}
		}
	};
});
