register_template('page_title_format', function(field_def) {

    var page_title_format = {
	entity: 'h2',
	attributes: {
            'class': 'page_title'
        },
	widgets: [ 'formatWidget' ],
	widget_options: {
	    formatWidget: {
		format: '$1 $2',
		fields: [
		    {
			field: 'bbx_extension_value',
			formatter: 'phone_number'
		    },
		    {
			field: 'show_name'
		    }
		]
	    }
	}
    };

    if ( field_def.format ) {
	page_title_format.widget_options.formatWidget = $.extend(true, {}, field_def);
    }

    return page_title_format;

});


