(function( $ ){
    var dirtyStateWidget = $.extend(true, {}, $.ui.stateWidget.prototype, {
	options: {
	    closest: 'form.formWidget'
	    // dirty_actions: { ... },
	    // undirty_actions: { ... }
	},
	_beforeDOMReady: function () {
	    var self = this, $self = this.element, $selector = false;
	    if (self.options.selector) {
		$selector = $self.closest(self.options.closest).find(self.options.selector);
		self._bind($selector, 'dirty undirty',  function (e) { self._do_dirty_actions(e); });
	    } else {
		self._bind($self.closest(self.options.closest), 'dirty undirty', function (e) { self._do_dirty_actions(e); });
	    }
	},

	_do_dirty_actions: function (e) {
	    var self = this;
	    if (e.type === 'dirty' && self.options.dirty_actions && self.options.last_state !== 'dirty') {
		self.options.last_state = 'dirty';
		self._perform_state_actions(self.options.dirty_actions);
	    } else if (e.type === 'undirty' && self.options.undirty_actions && self.options.last_state !== 'undirty') {
		self.options.last_state = 'undirty';
		self._perform_state_actions(self.options.undirty_actions);
	    }
	}
    });
    add_widget('dirtyStateWidget', dirtyStateWidget);
})(jQuery);
