/* jshint jquery: true, unused: vars */
/* global add_widget */
/*
 * <div class="widgetType progressWidget" data-js='{ 
 *     "progressWidget" : {
 *         "live_table" : "system_stats",
 *         "key_name" : "cpu_usage",
 *         "key_column" : "stat_name",
 *         "value_column" : "stat_value"
 *     }
 * }' ></div>
 *
 */

(function( $ ){
	var progressWidget = $.extend({}, $.ui.widget.prototype, {
		set_value_widget: true,

		options: {
			starting_value: 0,
			text_value: "",
			fixed_decimal: 0,
			text_unit: "%",
			min_value: 0,
			max_value: 100
		},

		_beforeInit: function() {
			var $self = this.element;
			var self = this;

			$self.wrap('<div class="progressBar_wrapper" />');
			self.options.$wrapper = $self.closest('.progressBar_wrapper');

			$self.progressbar({ value: self.options.starting_value });

			self.options.$wrapper.css('position', 'relative');
			self.options.$wrapper.css('text-align', 'center');
			self.options.$wrapper.append('<div class="progress_text"></div>');
			self.options.$text = self.options.$wrapper.find('.progress_text');
			self.options.$text.text(self.options.text_value + self.options.text_unit);
			self.options.$text.css('position', 'relative');
			self.options.$text.css('top', '-' + parseInt($self.css('height')) + 'px');
			self.options.$text.css('clear', 'none');
			self.options.$text.css('height', $self.css('height'));
			self.options.$text.css('line-height', $self.css('height'));
			self.options.$text.css('margin', 'auto');

		},

		setValue: function(val) {
			var $self = this.element;
			var self = this;

			var relative_value = (val - self.options.min_value)/(self.options.max_value - self.options.min_value);
			self.options.$text.text(parseFloat(val).toFixed(self.options.fixed_decimal) + self.options.text_unit);
			$self.progressbar("value", parseInt(relative_value * 100) );
		}

	});

	add_widget('progressWidget', progressWidget);

})(jQuery);
