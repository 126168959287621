/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/* global ALLOW_UNLOAD: true */
/*

  saveAndRefreshButtonWidget: A button that saves all the page's forms (like a normal save_button) then refreshes the page

*/

(function( $ ){
    var saveAndRefreshButtonWidget = $.extend(true, {}, $.ui.pageSaveButtonWidget.prototype, {
	options: {
	    allow_nav_on_dirty: true
	    // blanker: { title, text, wait }
	},

	_beforeInit: function () {
	    var self = this, $self = this.element;
	    $self.addClass('page_save_button');
	    self._bind($self, 'click', self._clickHandler.bind(self));
	},

	_save: function () {
	    var self = this, $self = this.element;
	    this.element.closest('.pageWidgetType').getCUIWidget('pageWidget').savePage();
	    $self.closest('.pageWidgetType').one('pageSubmitComplete', self._complete.bind(self));
	},

	_complete: function (d) {
	    var self = this, $self = this.element;
	    if (!d.error_count) {
		ALLOW_UNLOAD = true; // Global: Used by pageWidget to ignore the "Something's changed, really reload?" message
		self._refresh();
	    }
	},

	_refresh: function () {
	    var self = this, dlg, b;
	    if (self.options.blanker) {
		b = self.options.blanker;
		dlg = new CUI.Dialog({
		    blanker: true,
		    buttons: [],
		    title: b.title || 'Please Wait',
		    text: b.text || 'The page will now refresh. Please wait a moment...'
		});
		
		if (self.options.blanker.wait) {
		    setTimeout(self._doRefresh.bind(self), self.options.blanker.wait);
		}
	    } else {
		self._doRefresh();
	    }
	},
	
	_doRefresh: function () {
	    location.reload();
	},
	
	_clickHandler: function (e) {
	    var self = this, $self = this.element;
	    e.preventDefault();
	    if (self.options.dirty && !self.options.invalid) {
		self._save();
	    }
	}
    });
    
    add_widget('saveAndRefreshButtonWidget', saveAndRefreshButtonWidget);
})(jQuery);
