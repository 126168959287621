register_screen('valet_parking_create', {
    elements: [
	{ entity_template: 'page_title', title: 'Create Call Parking Block' },
	{
	    entity_template: 'page_buttons',
	    buttons: [
		{ type: 'cancel_back' },
		{
		    type: 'create',
		    key: 'bbx_valet_parking_id',
		    detail: 'valet_parking_detail'
		}
	    ]
	},
	{ entity_template: 'page_content', elements: [
	    {
		entity_template: 'page_module',
		legend: 'Add New Parking Block',
		form_action: '/gui/extension/valet_parking',
		form_data_js: {
		    method: 'POST',
		    no_initial_get: true,
		    submit_all: true,
		    always_dirty: true
		},

		buttons: [ 'help' ],
		help_id: 'create_parking_block',
		create: {
		    detail: 'valet_parking_detail',
		    params: [ 'bbx_extension_id', 'bbx_valet_parking_id' ],
		    key: 'bbx_valet_parking_id'
		},
		set_focus: true,
		elements: [
		    {
			entity_template: 'input_item',
			title: 'Parking Block Name:',
			input_name: 'bbx_valet_parking_name',
			type: 'text',
			validate: { conditions: '_TRUEISH_', message: 'Parking Block Name is required.' },
			input_options: { attributes: { 'class': 'focus', maxlength: '255' } }
		    },
		    {
			entity_template: 'input_item',
			title: 'Music on Hold:',
			type: 'select',
			input_name: 'bbx_moh_id',
			input_options: {
			    widgets: [ 'selectPopulateWidget' ],
			    widget_options: {
				selectPopulateWidget: {
				    rest: '/gui/moh/class',
				    text_field: 'bbx_moh_name',
				    value_field: 'bbx_moh_id'
				}
			    }
			}
		    },
		    {
			entity_template: 'extension_select',
			name: 'bbx_extension_value',
			allow: 'block',
			min_block_size: 5,
			lock_block: true,
			allow_lock_block_toggle: false
		    }
	 	]
	    }
	]
	}
    ]
});
