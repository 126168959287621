(function () {
    register_screen('firstrun_welcome', {
	elements: [
	    { entity_template: 'page_title', title: 'Welcome' },
	    { entity_template: 'page_buttons', buttons: [{ type: 'continue', href: '#!/bps/firstrun_network' }] },
	    {
		entity_template: 'page_content', elements: [
		    {
			entity_template: 'page_module',
			legend: 'Instructions',
			buttons: ['help'],
			help_id: 'firstrun_welcome',
			elements: [
			    {
				entity_template: 'container',
				'class': 'bodyCopy',
				elements: [
				    
				    {
					entity_template: 'container',
					attributes: { style: 'text-align: center' },
					elements: [
					    {
						entity: 'img',
						attributes: {
						    src: '/images/barracuda_splash_logo.svg',
						    alt: 'Barracuda Networks logo',
						    width: '550',
						    height: '150'
						}
					    }
					]
				    },
				    {
					entity: 'p',
					text: 'Welcome! Before you begin using your Barracuda Phone System, there are a few installation steps you should take to ensure that it is correctly configured' + entity.hellip
				    },
				    {
					entity: 'ul',
					widgets: ['containerWidget'],
					widget_options: {
					    elements: [
						{
						    entity: 'li',
						    text: 'Check your network settings.'
						},
						{
						    entity: 'li',
						    text: 'Activate your Barracuda Phone System'
						},
						{
						    entity: 'li',
						    text: 'Download and install the latest firmware update'
						},
						{
						    entity: 'li',
						    text: 'Configure extension blocks, where new extensions can be placed'
						},
						{
						    entity: 'li',
						    text: 'Set administration and phone options'
						}
					    ]
					}
				    },
				    {
					entity: 'p',
					text: 'Use the ' + entity.ldquo + 'Continue' + entity.rdquo + ' button on the top right corner of the page to guide you through the setup screens. If you would like to switch to a different setup screen, use the navigation along the left side of the page to navigate.'
				    }
				] // END bodyCopy container elements
			    } // END bodyCopy container
			] // END page module elements
		    } // END page module 1
		] // END page_content elements
	    } // END page_content
	] // END template elements
    }); // END template
})(); // END scoping function
