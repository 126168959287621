register_dependent_screen('firstrun_update', ['updates'], function (screens) {
    var screen = {
	elements: [
	    { entity_template: 'page_title', title: 'Update' },
	    { entity_template: 'page_buttons', buttons: [{ type: 'continue', href: '#!/bps/firstrun_extensions' }] }
	]
    };

    var update_screen = $.extend(true, {}, screens.updates.elements[2]);
    var ea_elements = update_screen['elements'][0]['container']['elements'][2]['elements'].splice(2,1);


    screen.elements.push(update_screen);
    // TODO: Remove any parts of the screendef that don't need to be in the first run screen

    return screen;
});
