/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/*

  Lookup widget: Using data from upstream, perform a search using the specified keys, and fillData downstream with the returned result. For instance, this
  is useful when you need to translate an ID (from upstream) to information to fill a downstream widget (such as name, etc.).

  OPTIONS:

  {
    accept_data_from_parent: true | false, // Usually, this should be true

    // REST options pertaining to the lookup call
    search_rest: '/path/to/controller',
    search_rest_params: { extra: 'params' },
    search_rest_container: undefined | 'container_key' | false, // False for no container, undefined for auto-set using the controller name
    search_method: 'GET' | 'POST' | 'PUT',

    search_keys: ['array','of','keys'] // TODO: Support object-notation for custom naming
  }
*/

(function( $ ){
	var lookupWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			accept_data_from_parent: true,
			search_rest: '',
			search_rest_params: {},
			search_rest_container: undefined,
			search_method: 'GET',
			search_keys: [],

			_search_data: {}
		},

		fillData: function (d, from_self) {
			var self = this, s_idx, changed = false;
			if (from_self || self.options.accept_data_from_parent) {
				// Fill in _search_data from the received data...
				for (s_idx = 0; s_idx < self.options.search_keys.length; s_idx++) {
					if (self.options.search_keys[s_idx] in d) {
						changed = true;
						self.options._search_data[self.options.search_keys[s_idx]] = d[self.options.search_keys[s_idx]];
					}
				}

				if (changed) {
					self.lookupSearch(self.options._search_data);
				}
			}

		},

		lookupSearch: function (search_data) {
			// Do the REST call...
			var self = this;
			search_data = $.extend({}, self.options.search_rest_params, search_data);
			CUI.doREST(self.options.search_method, self.options.search_rest, search_data, self._lookupSearchComplete.bind(self));
		},

		_lookupSearchComplete: function (d) {
			// Update the downstream values with fillDataChildren
			var self = this, action, data, rest_container;

			// Get our data in order... A function that mimics validate_rest_container etc. from Widget Base
			rest_container = self.options.search_rest_container;
			if (rest_container === undefined && self.options.search_rest) {
				action = self.options.search_rest.replace(/^.+\//, '');
				if (action in d) {
					rest_container = action;
					data = d[rest_container];
				}
			} else if (rest_container === false) {
				data = d;
			} else {
				data = d[rest_container];
			}

			// Update the values
			self.fillDataChildren(data);
		}
	});

	add_widget('lookupWidget', lookupWidget);
})(jQuery);
