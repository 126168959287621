(function () {

    var genScreenDef = function (params) {
	var type = params.type;
	var create_name = params.create_name;
	var page_title = params.page_title;
	var empty_table = page_title === 'All Extensions' ? 'Extensions' : page_title;
	var screen_name = params.screen_name;

	var key = params.key || 'bbx_' + params.type + '_id';
	var create_link = params.create_link || ('#!/bps/' + screen_name + '/overlay/' + type + '_create/{}');
	var dataEvents = (params.dataEvent || (params.type + ' extension')) + ' primary_extension';
	var delete_action = params.delete_action || ('/gui/extension/' + params.type);
	var overlay_name = params.overlay_name || params.type + '_detail';
	var overlay_params = params.overlay_params || [ 'bbx_extension_id', key ];

	var help_id = params.help_id || 'extension_list_generic';

	var primary = 0;
	if (typeof(params.primary) === 'undefined') { primary = 1; }

	var page_out = {
	    elements: [
		{ entity_template: 'page_title', title: page_title }, // [0]
		{ // [1]
		    entity_template: 'page_buttons',
		    buttons: [
			{
			    type: 'button',
			    href: create_link,
			    attributes: { 'class': params.screen_name + ' extensions_create_button' },
			    text: 'Create New ' + create_name,
			    requires: ['CREATE_' + (type === 'auto_attendant' ? 'AUTOATTENDANT' : type.toUpperCase())]
			},
			{
			    type: 'help',
			    help_id: help_id
			}
		    ]
		},
		{ // [2]
		    entity_template: 'page_content', elements: [
			{ // [2][0]
			    entity_template: (type.match(/(group|allext)/) ? 'ldap_extension_table' : 'data_table'),
			    rest: '/gui/extension/list',
			    rest_params: {
				primary: primary,
				type: type,
				sortby: 'bbx_extension_value'
			    },
			    primary_keys: [ (type === 'phone') ? 'bbx_phone_id' : key ],
			    extra_row_data: ['type', key, 'show_name', 'group_sync_id'],
			    dataEvents: {
				bind: {
				    dataEvents: ''
				},
				allow_delete: true
			    },
			    delete_action: {
				rest: delete_action,
				method: 'DELETE',
				only_rest_params: []
			    },
			    oLanguage: {
				sZeroRecords: 'No ' + empty_table + ' in table'
			    },
			    name_column: 'show_name',
			    unique_id: 'bbx_extension_id',
			    click_action: 'overlay',
			    overlay_name: overlay_name,
			    overlay_params: overlay_params,
			    overlay_data: {
				key: key
			    },
			    columns: [
				{
				    'header': '', column: 'type', width: '20px',
				    view_element: {
					entity: 'div',
					widgets: ['mappedValueWidget'],
					widget_options: {
					    mappedValueWidget: {
						name: 'type',
						render: 'html',
						maps: [
						    { match: 'user', output: CUI.sprite('user','Person', 'Person', true) },
						    { match: 'group', output: CUI.sprite('group', 'Group', 'Group', true) },
						    { match: 'queue', output: CUI.sprite('queue', 'Inbound Call Queue', 'Inbound Call Queue', true) },
						    { match: 'valet_parking', output: CUI.sprite('valet_parking', 'Call Parking Extension', 'Call Parking Extension', true) },
						    { match: 'conference', output: CUI.sprite('conference', 'Conference', 'Conference', true) },
						    { match: 'auto_attendant', output: CUI.sprite('auto_attendant', 'Automated Attendant', 'Automated Attendant', true) },
						    { match: 'router', output: CUI.sprite('router', 'Advanced Call Router', 'Advanced Call Router', true) },
						    { match: 'phone', output: CUI.sprite('phone', 'Unassigned Phone', 'Unassigned Phone', true) }
						]
					    }
					}
				    }
				},
				{
				    'header': 'Ext.',
				    'column': 'bbx_extension_value',
				    'class': 'extension-column',
				    data_type: 'num-html',
				    formatted: {
					format: {
					    field: 'bbx_extension_value',
					    formatter: 'phone_number',
					    options: { no_extension: true }
					}
				    }
				},
				{
				    'header': 'Name',
				    'column': 'sort_name',
				    'class': 'extension-name',
				    formatted: { format: { field: 'show_name' } }
				}
			    ],
			    table_actions: {
				refresh: { text: 'Refresh' },
				delete_rows: {
				    text: 'Delete Selected',
				    confirm: { title: 'Delete Selected', text: 'Are you sure you want to delete the selected ' + empty_table.toLowerCase() + '?' }
				},
				select_row: true,
				select_all_rows: true,

				select_requires: (type === 'allext' ? undefined : ['DELETE_' + (type === 'auto_attendant' ? 'AUTOATTENDANT' : type.toUpperCase()) ])
			    }
			} // END DTW

		    ] // END page_content elements
		} // END page_content
	    ] // END screen elements
	};

	if (type === 'phone') {
	    page_out.elements[2].elements[0].columns.push(
		{
		    header: 'Last Registered',
		    column: 'bbx_phone_last_registered',
		    formatted: { format: { field: 'bbx_phone_last_registered', formatter: 'match', options: { regexp: '^[^\.]+' }}}
		}
	    );
	}

	if (params.no_create) {
	    page_out.elements[1].buttons.splice(0,1);
	}

	var dtw = page_out.elements[2].elements[0];

	dtw.dataEvents.bind = {};
	dtw.dataEvents.bind[dataEvents] = 'extension ' + type;

	if (type == 'allext') {
	    dtw.click_action = 'variable_overlay';
	    var types = ['user','group','queue','valet_parking','conference','auto_attendant','phone','router'];
	    var variable_overlay_spec = { column: 'type' };

	    for (var t_ct=0; t_ct<types.length; t_ct++) {
		var t = types[t_ct];
		variable_overlay_spec[t] = {
		    overlay_name: t + '_detail',
		    overlay_params: [ 'bbx_extension_id', 'bbx_' + t + '_id' ]
		};
	    }
	    dtw.variable_overlay = variable_overlay_spec;
	    dtw.dataEvents.bind[dataEvents]
	}

	return page_out;
    }; // END genScreenDef

    register_screen('users', genScreenDef({
	type: 'user',
	screen_name: 'users',
	page_title: 'People',
	create_name: 'Person',
	help_id: 'extensions_people_list'
    }));

    register_screen('groups', genScreenDef({
	type: 'group',
	screen_name: 'groups',
	page_title: 'Groups',
	create_name: 'Group',
	help_id: 'extensions_groups_list'
    }));

    register_screen('queues', genScreenDef({
	type: 'queue',
	screen_name: 'queues',
	page_title: 'Inbound Call Queues',
	create_name: 'Queue',
	help_id: 'extensions_queues_list'
    }));

    register_screen('parking', genScreenDef({
	type: 'valet_parking',
	screen_name: 'parking',
	create_name: 'Call Parking Block',
	page_title: 'Call Parking Blocks',
	help_id: 'extensions_parking_blocks_list'
    }));

    register_screen('conferences', genScreenDef({
	type: 'conference',
	screen_name: 'conferences',
	page_title: 'Multi-user Conferences',
	create_name: 'Conference',
	help_id: 'extensions_conferences_list'
    }));

    register_screen('auto_attendants', genScreenDef({
	type: 'auto_attendant',
	screen_name: 'auto_attendants',
	page_title: 'Automated Attendants',
	create_name: 'Automated Attendant',
	help_id: 'extensions_automated_attendants_list',
	delete_action: '/gui/extension/autoattendant/autoattendant'
    }));

    register_screen('acrs', genScreenDef({
	type: 'router',
	screen_name: 'acrs',
	page_title: 'Advanced Call Routers',
	create_name: 'Advanced Call Router',
	help_id: 'extensions_advanced_call_routers'
    }));

    register_screen('phones', genScreenDef({
	type: 'phone',
	screen_name: 'phones',
	page_title: 'Unassigned Phones',
	no_create: true,
	help_id: 'extensions_unassigned_phones_list'
    }));

    register_screen('all_exts', genScreenDef({
	type: 'allext',
	screen_name: 'all_exts',
	page_title: 'All Extensions',
	no_create: true,
	help_id: 'extensions_all_extensions_list',
	key: 'bbx_extension_id',
	delete_action: '/gui/extension/extension'
    }));

})();
