/* jslint browser: true, jquery: true */
/*
 * Namespaced element creation and appending
 * Rudy Fleminger / Barracuda Networks
 * 
 * $.ns(STRING xml, STRING namespace) RETURNS jQuery Set
 *   Create a node or nodes with the given namespace from the given XML string.
 * 
 * <jQuery Set>.nsAppend(STRING xml, STRING namespace) RETURNS given jQuery Set
 *   Create a node or nodes with the given XML, and append it to the target element(s). Note that this ONLY works
 *   with XML text, not existing elements, as there is no way to change the namespace of a DOM element. Providing
 *   anything except a string for the first parameter will throw a TypeError.
 *
 */

(function () {
	$.ns = function (xml, ns) {
		"use strict";

		if (ns === undefined) { throw new Error('Missing namespace argument'); }
		ns = (ns in $.ns.namespaces) ? $.ns.namespaces[ns] : ns;

		// Wrap the element in a dummy root used to set the namespace
		var xmldoc = new DOMParser().parseFromString('<root xmlns="'+ns+'">' + xml + '</root>', 'text/xml');
		return $(xmldoc.firstChild.childNodes);
	};

	$.ns.namespaces = {
		svg: 'http://www.w3.org/2000/svg'
	};

	$.fn.nsAppend = function (xml, ns) {
		if (typeof xml !== 'string') {
			throw new TypeError('nsAppend second argument must be an XML string - existing elements are not allowed');
		}

		this.each(function () {
			if (ns === undefined) {
				ns = this.lookupNamespaceURI(null);
			}
			$.ns(xml, ns).appendTo(this);
		});
		
		return this;
	};
})();