/* jshint jquery: true, unused: vars */
/* global add_widget, entity */
(function( $ ){
	function timestampToLocal(ts) 
	{
		if (!ts) { return ''; }

		// Convert a postgres timestamp into something JS can parse...

		//debugLog('Incoming ts: ', ts);

		// Change date seperator to a slash
		ts = ts.replace(/^([0-9]{4})-([0-9]{2})-([0-9]{2})/, "$1/$2/$3");
		// Get rid of the fractional seconds 
		ts = ts.replace(/^([0-9/]+ [0-9:]+)\.?[0-9]*([-+])0?([0-9]+)/, "$1 GMT$2$300");

		//debugLog('Parsed ts: ', ts);

		var jsdate = new Date(Date.parse(ts));
		return (jsdate.toLocaleDateString().replace(/ /g, entity.nbsp) + ", " + jsdate.toLocaleTimeString().replace(/ /g, entity.nbsp));
	}



	var timestampDisplayWidget = $.extend({}, $.ui.widget.prototype, {
		set_value_widget: true,

		options: {
			accept_data_from_parent: true
		},

		setValue: function(val, name) {
			var self = this, $self = this.element;

			$self.text(timestampToLocal(val));
		}

	});
	add_widget('timestampDisplayWidget', timestampDisplayWidget);
})(jQuery);
