
register_template('agent_manager_table', function(field_def) {
    var agent_mgr = execute_template('agent_manager', {});
    var agents = build_standard_entity_options('table', 'wallboardDataTableWidget', field_def);
    $.extend(true, agents, {
	widget_options: {
	    wallboardDataTableWidget: {
		disable_cui_wrapper: true,
		live_table: "live_fifo_agents",
		live_table_key: "fifo_agents",
		data_source: "live",
		page_size: 10000,
		filtering: true,
		filter_in_page_title: true,
		
		extension_filter: true,
		extension_filter_options: {
		    type_text: 'By Queue',
		    nothing_text: 'Show All Queues',
		    value_key: 'bbx_queue_id',
		    la_filter_field: 'queue_membership',
		    list_field: true,
		    type: 'queue',
		    primary: true
		},
		filter_title: (field_def.filter_title || 'Filter: '),

		length_change: false,
		paginate: false,
		processing: false,
		sortable: false,
		oLanguage: {
		    sZeroRecords: "No Agents found."
		},
		filter: {
		    distinct: {
			on: 'bbx_user_id',
			nulls: 'last',
			except: {
			    column: 'call_state',
			    values: [ 'HELD', 'RINGING', 'ACTIVE' ]
			}
		    },
		    except: {
			column: 'call_state',
			values: [ 'OFFLINE' ]
		    }
		},
		extra_row_data: [ "bbx_queue_name", "fifo_name", "agent_extension_value", "caller_caller_id_name", "caller_caller_id_number", "bridge_start",
				  "agent_status_name", "agent_status_last_changed", "bbx_user_status_warning_seconds", "bbx_user_status_critical_seconds",
				  "bbx_queue_consumer_wrapup_time", "bbx_queue_outbound_name", "call_state", "current_time", "last_state_change",
				  "deleted", "delete_delay", "agent_missed_calls", "state_duration", "state_start", "bbx_phone_desc", "bbx_phone_model",
				  "bbx_user_id", "bbx_user_status_id", "consumer_uuid", "caller_uuid", "bbx_user_id", "last_call_end", "last_call_missed",
				  "primary_extension", "last_call_from_queue"
				],
		columns: [
		    {
			header: "Agent",
			column: "agent_name",
			sortable: false,
			view_elements: [
			    agent_mgr
			]
		    },
		    {
			header: "Agent Status",
			column: "agent_status_name",
			visible: false
		    },
		    {
			header: "Call State",
			column: "call_state",
			visible: false
		    },
		    {
			header: "Agent Extension",
			column: "agent_extension_value",
			visible: false
		    },
		    {
			header: "Caller ID Name",
			column: "caller_caller_id_name",
			visible: false
		    },
		    {
			header: "Caller ID Number",
			column: "caller_caller_id_number",
			visible: false
		    }
		]
	    }
	}
    });

    return agents;
});
