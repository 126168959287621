/* jshint jquery: true, unused: vars */
/* global CUI, Ape, add_widget, loginWidget */
/* **********************************************************************************************************************************************
*  Name: blankerButtonWidget
*  Desc: this widget extends actionButton Widget to display a dialog box when the rest call of the button is successfull
*
*  Options:
*	    live:              lets the widget know that you are using an ape event with the widget
*	    context:           the key name for the ape event
*	    blanker_options:   options that define the cui.dialog blanker
*		title:
*		text:
*		blanker:
*		flyby:
*		progress:
*		icon:
*		buttons:
*               ping:
*	    flyby_start:     the key name that will trigger the flyby text
*	    flyby_complete:  the key name that will signal the end of the flyby text
*
*
*********************************************************************************************************************************************** */


(function( $ ){
	var buttonBlanker = false;

	var blankerButtonWidget = $.extend(true, {}, $.ui.actionButtonWidget.prototype, {
		options: {
			live: false,
			context: '',
			method: '',
			blanker_options: {
				title: 'Title',
				text: 'Insert Text Here',
				blanker: true,
				flyby: false,
				progress: true,
				icon: false,
				buttons: ['Ok'],
				ping: false
			},
			flyby_start: false,
			flyby_complete: false,
			suppress_ape_reload: false
		},

		_afterInit: function () {
			var self = this, $self = this.element;
			$self.addClass('blankerButtonType');

			if (self.options.live) {
				self.options.binding = Ape.bindContext(self.options.context, self._statusHandler.bind(self), self);
				self._addDestroyCallback(self._destroyBinding.bind(self));
			}

			return $.ui.actionButtonWidget.prototype._afterInit.apply(self, arguments);
		},

		_success: function () {
			var self = this, $self = this.element;
			self._showBlanker();
		},

		_destroyBinding: function() {
			var self = this, $self = this.element;

			Ape.unBind(self.options.binding);
		},

		_showBlanker: function () {
			var self = this, $self = this.element;

			if (!buttonBlanker) {
				buttonBlanker = new CUI.Dialog(self.options.blanker_options);
			}
			if (self.options.suppress_ape_reload) {
				Ape.no_reload = true;
				loginWidget.options.restoring = true;
			}
		},

		_hideBlanker: function () {
			var self = this;
			buttonBlanker.remove();
			buttonBlanker = false;
			if (self.options.suppress_ape_reload) {
				Ape.no_reload = false;
				loginWidget.options.restoring = true;
			}

		},

		_pushFlyby: function (text) {
			if (buttonBlanker) {
				buttonBlanker.flyby(text);
			}
		},

		_statusHandler: function(data, e) {
			var self = this, $self = this.element;

			if (data && data.json) {
				if (data.json[self.options.flyby_start]) {
					self._showBlanker();
				}

				if (data.json.message) {
					self._pushFlyby(data.json.message);
				}
				else if (data.json.text) {
					self._pushFlyby(data.json.text);
				}

				if (data.json[self.options.flyby_complete]) {
					if (data.json.reason && data.json.reason == 'error') {
						self._setTimeout(self._hideBlanker.bind(self), 20000);
					} else {
						self._setTimeout(self._hideBlanker.bind(self), 5000);
					}
				}
			}
			else if (buttonBlanker) { self._hideBlanker(); }
		}
	});
	add_widget('blankerButtonWidget', blankerButtonWidget);
})(jQuery);
