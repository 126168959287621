register_screen('testpage', {
    elements: [
	{ entity_template: 'page_title', title: 'TEST PAGE OF TESTING AND DONOTCOMMITINGNESS!' },
	{ entity_template: 'page_buttons', buttons: [{ type: 'cancel' }, { type: 'save' }] },
	{ entity_template: 'page_content', elements: [
	    {
		entity_template: 'page_module',
		legend: 'Auto Attendant Dialpad Test',
		no_initial_get: true,
		form_action: '/gui/sync/server',
		elements: [
		    {
			entity_template: 'input_item',
			title: 'Type something. It won'+entity.rsquo+'t work.',
			type: 'text',
			input_name: 'test_fail'
		    }
		]
	    }
	]}
    ]
});
