register_screen('rcMigration', {
    rest: '/gui/rc/rc',
    rest_container: '',
    elements: [
	{ entity_template: 'page_title', title: 'Rc Migration' },
	{
	    entity_template: 'page_buttons',
	    buttons: [{ type: 'cancel' }, { type: 'save' }]
	},
	{
		entity_template: 'page_content', elements: [
			{
			entity_template: 'page_module',
			legend: 'Ring Central Migration',
			buttons: [ 'help' ],
			help_id: 'rc_migration',
			elements: [
				{
				entity_template:'action_button',
				text: 'Download CSV for Ring Central Migration',
				rest: '/gui/rc/rc'
				}
				]
			}
			] //end page content elements
		} // end page concent template
	]
});
