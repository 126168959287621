(function () {
    register_screen('client_launch', {
	elements: [
	    { entity_template: 'page_title', title: 'Communication Command Center' },
	    {
		entity_template: 'page_content',
		elements: [
		    {
			entity: 'div',
			attributes: { style: 'text-align: center; margin: 1em 0;' },
			text: 'To launch the Communication Command Center automatically from the menu item, disable popup blocking for this site, otherwise click the button below:'
		    },
		    {
			entity_template: 'container',
			attributes: { style: 'text-align: center' },
			elements: [{
			    entity: 'button',
			    text: 'Launch Communication Command Center...',
			    attributes: { type: 'button' },
			    widgets: ['cccLauncherWidget']
			}]
		    }
		]
	    }
	] // END template elements
    }); // END template

})(); // END scoping function
