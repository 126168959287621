/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, widgetize_children, CurrentUser, format_information */
(function( $ ){
	"use strict";
	var true_regexp = /^t/i, num_regexp = /\d+/;
	
	var agentManagerWidget = $.extend({}, $.ui.widget.prototype, CUI.htmlEntityClass, CUI.bubbleInfoClass, {
		options: {
			accept_data_from_parent: true,
			defer_duration: 10,
			wrapup_duration: 10
		},

		_beforeInit: function () {
			var self = this;
			var $self = this.element;

			$self.addClass('agentManagerWidgetType');
			self.options.$tr = $self.closest('tr');
			self.options.last_state = '';

			self._setupAgentManagerClosures();

			self._buildHTML();

			self._bubbleInfoInit('div.timestamp', 'div.timestamp');

			return false;
		},

		_buildHTML: function() {
			var self = this;
			var $self = this.element;

			var frag = document.createDocumentFragment();

			self.options.elem = {};

			if (CurrentUser.permissions.EDIT_QUEUE_AGENT_STATUS) {
				self.options.elem.agent_status = document.createElement('div');
				self.options.elem.agent_status.className = 'agent_status';
				self.options.elem.agent_widget = document.createElement('button');
				self.options.elem.agent_widget.className = 'widgetType userStatusWidget';
				var data_js = document.createAttribute('data-js');
				data_js.nodeValue = '{ "requires" : [ "EDIT_QUEUE_AGENT_STATUS" ] }';
				self.options.elem.agent_widget.setAttributeNode(data_js);
				self.options.elem.agent_status.appendChild(self.options.elem.agent_widget);
				frag.appendChild(self.options.elem.agent_status);
			}

			if (trueish(CurrentUser.bbx_extension_id)) {
				self.options.elem.barge = document.createElement('div');
				self.options.elem.barge.className = 'widgetType bargeWidget';
				var data_js_barge = document.createAttribute('data-js');
				data_js_barge.nodeValue = '{ "image" : "/images/client/buttons/talk.png", "height" : "21", "width" : "21", "extension_field" : "agent_extension_value", "cid_field" : "agent_name" }';
				self.options.elem.barge.setAttributeNode(data_js_barge);
				frag.appendChild(self.options.elem.barge);

				if (CurrentUser.permissions.EAVESDROP) {
					self.options.elem.eavesdrop = document.createElement('div');
					self.options.elem.eavesdrop.className = 'widgetType eavesdropWidget';
					var data_js_eavesdrop = document.createAttribute('data-js');
					data_js_eavesdrop.nodeValue = '{ "image" : "/images/client/buttons/eavesdrop.png", "height" : "21", "width" : "21", "uuid_field" : "consumer_uuid", "alt_uuid_field" : "caller_uuid" }';
					self.options.elem.eavesdrop.setAttributeNode(data_js_eavesdrop);
					frag.appendChild(self.options.elem.eavesdrop);
				}
			}

			self.options.elem.agent_name = document.createElement('div');
			self.options.elem.agent_name.className = 'agent_name';
			frag.appendChild(self.options.elem.agent_name);

			self.options.elem.extension = document.createElement('div');
			self.options.elem.extension.className = 'extension';
			frag.appendChild(self.options.elem.extension);


			self.options.elem.call_info = document.createElement('div');
			self.options.elem.call_info.className = 'call_info';
			frag.appendChild(self.options.elem.call_info);

			self.options.elem.call_state = document.createElement('div');
			self.options.elem.call_state.className = 'call_state';
			frag.appendChild(self.options.elem.call_state);

			self.options.elem.timestamp = document.createElement('div');
			self.options.elem.timestamp.className = 'timestamp';
			frag.appendChild(self.options.elem.timestamp);

			self.options.elem.queue = document.createElement('div');
			self.options.elem.queue.className = 'queue';
			frag.appendChild(self.options.elem.queue);

			self.options.elem.missed = document.createElement('div');
			self.options.elem.missed.className = 'missed';
			CUI.quickSetText(self.options.elem.missed, 'Missed Calls');
			frag.appendChild(self.options.elem.missed);

			self.options.elem.calls = document.createElement('div');
			self.options.elem.calls.className = 'calls';
			frag.appendChild(self.options.elem.calls);

			self.options.elem.phone_desc = document.createElement('div');
			self.options.elem.phone_desc.className = 'phone_desc';
			frag.appendChild(self.options.elem.phone_desc);

			self.options.elem.phone_model = document.createElement('div');
			self.options.elem.phone_model.className = 'phone_model';
			frag.appendChild(self.options.elem.phone_model);

			$self.append(frag);

			self.options.$time = $self.find('div.timestamp');
			self.options.$tr = $self.closest('tr');

			widgetize_children($self, self);
		},

		_setupAgentManagerClosures: function() {
			var self = this;

			self.options.finish_wrapup_closure = function() {
				self._finishWrapup();
			};
			self.options.finish_defer_closure = function() {
				self._finishDefer();
			};
		},

		_destroy: function() {
			var self = this;
			var $self = this.element;

			delete self.options.$tr;
			if (self.options.$time) {
				delete self.options.$time;
			}

			delete self.options.finish_wrapup_closure;
			delete self.options.finish_defer_closure;

			for (var e in self.options.elem) {
				delete self.options.elem[e];
			}

			if (self.options.widget_data) {
				delete self.options.widget_data;
			}

			self._bubbleInfoDestroy();
			self._htmlEntityDestroy();
		},

		_finishWrapup: function() {
			var self = this;
			var $self = this.element;

			self.options.$tr.removeClass('wrapup');
			self._clearCountdown();
			self._setAgentStatus();
			self._setCountdownInfo();
		},

		_finishDefer: function() {
			var self = this;
			var $self = this.element;

			self.options.$tr.removeClass('defer');
			self._clearCountdown();
			self._setAgentStatus();
			self._setCountdownInfo();
		},

		_setAgentStatus: function() {
			var self = this;
			var $self = this.element;
			var data = self.options.data;

			self.options.js_used_wrapup = ((new Date().getTime() / 1000) - self.options.original_start_time);
			self.options.remaining_defer = Math.ceil(self.options.defer_duration - (self.options.server_used_wrapup + self.options.js_used_wrapup));
			self.options.remaining_wrapup = Math.ceil(self.options.wrapup_duration - (self.options.server_used_wrapup + self.options.js_used_wrapup));

			self.options.last_in_defer_state = self.options.in_defer_state;
			self.options.last_in_wrapup_state = self.options.in_wrapup_state;
			self.options.in_defer_state = self.options.remaining_defer >= 1 && self.options.last_call_missed && self.options.last_call_from_queue ? true : false;
			self.options.in_wrapup_state = !!(self.options.remaining_wrapup >= 1 && self.options.last_active_call_state !== 'RINGING' && !self.options.last_call_missed && self.options.last_call_from_queue);
			self.options.in_defer_state = data.call_state ? false : self.options.in_defer_state;
			self.options.in_wrapup_state = data.call_state ? false : self.options.in_wrapup_state;

			//debugLog('In wrapup: ', self.options.in_wrapup_state, self.options.remaining_wrapup, self.options.last_call_missed);

			// Call State Display (only when not on a call)
			if (self.options.in_wrapup_state) {
				CUI.quickSetText(self.options.elem.call_state, 'Wrapup');
				CUI.quickShow(self.options.elem.call_state);
				self._removeCallTimeTimeout();
			} else if (self.options.in_defer_state) {
				CUI.quickSetText(self.options.elem.call_state, 'Defer');
				CUI.quickShow(self.options.elem.call_state);
				self._removeCallTimeTimeout();
			} else if (data.call_state == 'ACTIVE' || data.call_state == 'UNHOLD' || data.call_state == 'EARLY') {
				CUI.quickHide(self.options.elem.call_state);
			} else {
				if (!data.call_state) {
					CUI.quickSetText(self.options.elem.call_state, (data.agent_status_name.toLowerCase() + ' for'));
				} else if (data.call_state == 'RINGING' || data.call_state == 'RING_WAIT') {
					CUI.quickSetText(self.options.elem.call_state, 'Ringing');
				} else {
					CUI.quickSetText(self.options.elem.call_state, data.call_state.toLowerCase());
				}
				CUI.quickShow(self.options.elem.call_state);
			}

			// Queue Display
			var have_queue_state = false;
			if (data.fifo_name == 'Manual Call' || (data.bbx_queue_name && data.bbx_queue_name !== null)) {
				CUI.quickSetText(self.options.elem.queue, (
					data.fifo_name == 'Manual Call' ? data.fifo_name : (
						data.bbx_queue_name !== null && data.bbx_queue_name != 'undef' ? data.bbx_queue_name : ''
					)
				));
				CUI.quickShow(self.options.elem.queue);
				have_queue_state = true;
			} else {
				CUI.quickHide(self.options.elem.queue);
			}

			// Missed Calls Display
			if ( data.agent_missed_calls > 0 && data.call_state != 'ACTIVE' && data.call_state != 'EARLY' && data.call_state != 'UNHOLD' && data.call_state != 'HELD' && !self.options.$tr.hasClass('unavailable') && !data.agent_status_name.match(/Offline|Lunch|Meeting|Training|Break/) )
			{
				CUI.quickSetText(self.options.elem.calls, data.agent_missed_calls);
				CUI.quickShow(self.options.elem.calls);
				CUI.quickShow(self.options.elem.missed);

				if (self.options.in_defer_state) {
					self.options.$tr.addClassDefer('missed_calls_flash');
					self._setTimeout(function() {
						if (!self.options.$tr.hasClass('active') && !self.options.$tr.hasClass('held')) {
							self.options.$tr.removeClassDefer('missed_calls_flash').addClassDefer('missed_calls').applyClassDefer();
						} else {
							self.options.$tr.removeClassDefer('missed_calls_flash').applyClassDefer();
						}
					}, 4500);
				} else {
					if ( !self.options.$tr.hasClass('active') && !self.options.$tr.hasClass('held') && !data.agent_status_name.match(/Lunch|Meeting|Training|Break/) )
					{
						self.options.$tr.addClassDefer('missed_calls');
					}
				}
			} else {
				CUI.quickHide(self.options.elem.calls);
				CUI.quickHide(self.options.elem.missed);

				self.options.$tr.removeClassDefer('missed_calls');
			}


			// Offline color change
			if (data.agent_status_name == 'Offline') {
				self.options.$tr.addClassDefer('offline');
			} else {
				self.options.$tr.removeClassDefer('offline');
			}

		},

		_setCountdownInfo: function() {
			var self = this, $self = this.element;
			var data = self.options.data;

			//debugLog('In wrapup: ', self.options.in_wrapup_state, '; In defer: ', self.options.in_defer_state, '; Server Used Wrapup: ', self.options.server_used_wrapup, '; Wrapup remaining: ', self.options.remaining_wrapup, '; Defer remaining: ', self.options.remaining_defer);
			//debugLog('In state: ', data.call_state);

			// State-based color changes and countdown / timers
			if (self.options.in_wrapup_state) {
				self.options.server_state_start_time = parseInt(self.options.last_call_ended);
				self.options.$tr.addClassDefer('wrapup').removeClassDefer('active held ringing defer missed_calls');
				self._setCountdownTimeout(self.options.wrapup_duration, self.options.finish_wrapup_closure);
			} else if (self.options.in_defer_state) {
				self.options.server_state_start_time = parseInt(self.options.last_call_ended);
				self.options.$tr.addClassDefer('defer').removeClassDefer('active ringing held wrapup');
				self._setCountdownTimeout(self.options.defer_duration, self.options.finish_defer_closure);
			} else if (data.call_state == 'ACTIVE' || data.call_state == 'UNHOLD') { // || data.call_state == 'EARLY') {
				self.options.$tr.addClassDefer('active').removeClassDefer('ringing defer held wrapup missed_calls');
				self.options.server_start_time = parseInt(data.bridge_start);
				self._removeCountdownTimeout();
				self._clearCounter();
			} else if (data.call_state == 'EARLY' || data.call_state == 'RINGING' || data.call_state == 'RING_WAIT') {
				self._clearCounter();
				self.options.$tr.addClassDefer('ringing').removeClassDefer('active defer held wrapup');
				//self.options.server_start_time = parseInt(data.state_start);
				self._removeCountdownTimeout();
				self.options.server_start_time = 0;
				//debugLog('Server start time: ', self.options.server_start_time);
				//self._setCountdownTimeout(self.options.state_duration);
			} else if (data.call_state == 'HELD') {
				self.options.$tr.addClassDefer('held').removeClassDefer('active ringing defer wrapup missed_calls');
				self.options.server_start_time = parseInt(data.last_state_change);
				self._removeCountdownTimeout();
				self._clearCounter();
			} else {
				self.options.$tr.removeClassDefer('held active ringing defer wrapup');
				if (data.agent_status_name == 'Available') {
					var last_call_end;
					if ( self.options.in_wrapup_state ) { last_call_end = data.last_call_end ? parseFloat(data.last_call_end) : 0; }
					var last_call_missed = true_regexp.test(data.last_call_missed) ? true : false;
					var offset = (last_call_end ? (last_call_missed ? self.options.defer_duration : self.options.wrapup_duration) : 0);
					self.options.server_start_time = parseInt(data.state_start) || parseInt(data.last_state_change);
					self.options.start_time += offset;
				} else {
					self.options.server_start_time = parseInt(data.agent_status_last_changed);
				}
				self._removeCountdownTimeout();
				self._clearCounter();
			}

			if (data.consumer_uuid || data.caller_uuid) {
				CUI.quickShow($(self.options.elem.eavesdrop).find('button'));
				CUI.quickShow($(self.options.elem.barge).find('button'));
			} else {
				CUI.quickHide($(self.options.elem.eavesdrop).find('button'));
				CUI.quickHide($(self.options.elem.barge).find('button'));
			}		

			if (self.options.server_start_time > 0 && !self.options.in_defer_state && !self.options.in_wrapup_state && ( data.call_state != 'RINGING' || data.call_state != 'RING_WAIT' )) {
				self._setCallTimeTimeout();
			} else {
				self._removeCallTimeTimeout();
			}

			self.options.$tr.applyClassDefer();

		},

		fillData: function(data, from_self) {
			var self = this;
			var $self = this.element;

			$self.data('widgetData', null);

			self.options.data = data;
			self.options.defer_duration = (self.options.defer_duration ? self.options.defer_duration : 10);
			self.options.wrapup_duration = (self.options.wrapup_duration ? self.options.wrapup_duration : 10);

			if (data.call_state) { self.options.last_active_call_state = data.call_state; }

			//self.options.server_start_time = parseInt(data.bridge_start);
			self.options.server_current_time = data.current_time;
			self.options.start_time = new Date().getTime() / 1000;
			self.options.original_start_time = self.options.start_time;
			self.options.warning_seconds = data.bbx_user_status_warning_seconds;
			self.options.critical_seconds = data.bbx_user_status_critical_seconds;
			self.options.last_call_ended = (data.last_call_end ? parseFloat(data.last_call_end) : 0);
			self.options.last_call_missed = true_regexp.test(data.last_call_missed) ? true : false;
			self.options.last_call_from_queue = true_regexp.test(data.last_call_from_queue) ? true : false;
			self.options.server_used_wrapup = self.options.server_current_time - self.options.last_call_ended;

			/*
	      debugLog('server_start_time: ', self.options.server_start_time);
	      debugLog('server_current_time: ', self.options.server_current_time);
	      debugLog('start_time: ', self.options.start_time);
	      debugLog('original_start_time: ', self.options.original_start_time);
	      debugLog('last_call_ended: ', self.options.last_call_ended);
	      debugLog('last_call_missed: ', self.options.last_call_missed);
	      debugLog('server_used_wrapup: ', self.options.server_used_wrapup);
	    */

			if (data.caller_caller_id_name) {
				data.caller_caller_id_name = data.caller_caller_id_name.replace(/^0+$|^$/g, 'Unknown');
			}
			if (data.caller_caller_id_number) {
				data.caller_caller_id_number = data.caller_caller_id_number.replace(/^0+$|^$/g, 'Unknown');
			}
			if (data.caller_caller_id_name == data.caller_caller_id_number || 
				(num_regexp.test(data.caller_caller_id_number) && data.caller_caller_id_name == 'Unknown'))
			{
				data.caller_caller_id_name = '';
			}
			if (data.caller_caller_id_name) {
				data.caller_caller_id_name = data.caller_caller_id_name.replace(/\([^\)]+\)\s*/g, '');
				data.caller_caller_id_name = CUI.formatter.doFormat({ cid: data.caller_caller_id_name }, { format: { field: '@cid', formatter: 'cid_case' }});
			}
			data.caller_caller_id_number = format_information(data.caller_caller_id_number, { no_auto_split: true });
			if (data.agent_extension_value) {
				data.agent_extension_value = format_information(data.agent_extension_value, { no_auto_split: true });
			}

			CUI.quickSetText(self.options.elem.agent_name, data.agent_name);
			CUI.quickSetText(self.options.elem.extension, data.agent_extension_value);
			CUI.quickSetText(self.options.elem.phone_desc, data.bbx_phone_desc);
			CUI.quickSetText(self.options.elem.phone_model, data.bbx_phone_model);

			if (data.caller_caller_id_name || data.caller_caller_id_number) {
				CUI.quickSetText(self.options.elem.call_info, data.caller_caller_id_name + ' ' + data.caller_caller_id_number);
				CUI.quickShow(self.options.elem.call_info);
			} else {
				CUI.quickSetText(self.options.elem.call_info, ' ');
				CUI.quickHide(self.options.elem.call_info);
			}

			// Agent Status Color Change
			if (!data.call_state && data.agent_status_name == 'Available') {
				self.options.$tr.addClassDefer('available').removeClassDefer('unavailable offline');
			} else if (!data.call_state && data.agent_status_name == 'Offline') {
				self.options.$tr.removeClassDefer('available unavailable').addClassDefer('offline');
			} else if (data.call_state) {
				self.options.$tr.removeClassDefer('available offline unavailable');
			} else {
				self.options.$tr.removeClassDefer('available offline').addClassDefer('unavailable');
			}

			self._setAgentStatus();
			self._setCountdownInfo();
			self.fillDataChildren(data, false);
		}

	});    
	add_widget('agentManagerWidget', agentManagerWidget);
})(jQuery);
