(function () {
    register_screen('phone_line_aliases', {
	elements: [
	    { entity_template: 'page_title', title: 'Configure Line Aliases' },
	    {
		entity_template: 'page_content', elements: [
		    {
			entity_template: 'page_module',
			legend: 'Aliases',
			buttons: ['help'],
			help_id: 'button_alias',
			elements: [
			    {
				entity_template: 'data_table',
				click_action: 'edit',
				rest: '/gui/phone/line/aliases',
				add_edit_action: {
				    rest: '/gui/phone/line/alias',
				    rest_container: 'alias'
				},
				delete_action: {
				    rest: '/gui/phone/line/alias',
				    method: 'DELETE',
				    include_parent_data: ['bbx_phone_button_id']
				},
				paginate: false,
				length_change: false,
				filtering: false,
				oLanguage: {
				    sZeroRecords: 'No Line Aliases configured'
				},
				accept_data_from_parent: true,
				primary_keys: ['bbx_extension_id'],
				extra_row_data: [
				    'bbx_extension_value', 'extension_next_free', 'bbx_extension_block_begin', 'bbx_extension_block_end'
				],
				columns: [
				    {
					header: 'Extension',
					column: 'bbx_extension_id',
					formatted: { format: { field: '@bbx_extension_value', formatter: 'phone_number' } },
					add_element: {
					    entity_template: 'extension_select',
					    types: ['single','nextfree','block','external'],
					    attributes: {
						name: 'bbx_extension_id'
					    }
					}
				    }
				],
				row_actions: {
				    edit_save: { text: 'Save' },
				    edit_cancel: { text: 'Cancel' },
				    add_save: { text: 'Add' },
				    add_cancel: { text: 'Cancel' }
				},
				table_actions: {
				    add_row: {
					location: 'bottom',
					text: 'Add a Line Alias'
				    },
				    delete_rows: {
					location: 'bottom',
					confirm: {
					    title: 'Confirm Delete',
					    text: 'Are you sure you wish to remove these line aliases?'
					}
				    },
				    select_row: true,
				    select_all_rows: true
				}
			    }
			] // END page module elements
		    } // END page module 1
		] // END page_content elements
	    } // END page_content
	] // END template elements
    }); // END template
})(); // END scoping function
