/*
  modeScheduleSummaryWidget: A widget that needs documentation. Apply to a DIV or other block element.
*/

(function( $ ){
    var modeScheduleSummaryWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			template: false,
			template_selector: false
		},

		fillData: function (d, from_self) {
			var self = this, $self = this.element, output = [], dates, months, date_parts, week_days, set_time = [], dow_compressed = [], day_range, min_day_range, time_of_day = [];
			months = [undefined, "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
			week_days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

			// Mar 4, 2015-Mar 8, 2015, 8:00 AM-5:00 PM
			// Mar 5-Mar 19, Monday-Wednesday, Friday, 8:00 AM-9:00 AM

			if (d.bbx_mode_condition_map_specific_date) {
				
				dates = d.bbx_mode_condition_map_specific_date.split(/-/); // ['yyyy-mm-dd','yyyy-mm-dd']
				date_parts = [dates[0].split('/')];  //split the date on the slash
				
				if(dates[0] !== dates[1]){
					date_parts.push(dates[1].split('/'));  //if the dates aren't equal, then grab the data parts aray and push date1 into the array so both will be displayed
				}
				
				date_parts.forEach( function (date, idx) {
					date_parts[idx] = months[Number(date[1])] + ' ' + Number(date[2]) + ', ' + Number(date[0]); // "Monthname dd, yyyy"  
				});

				output.push(date_parts.join(' - '));
			} // END if (d.bbx_mode_condition_map_specific_date)

			if(d.bbx_mode_condition_map_recurring_date) {
				dates = d.bbx_mode_condition_map_recurring_date.split('-'); // ['mm/dd', 'mm/dd']
				date_parts = [dates[0].split('/')];
				
				if(dates[0] !== dates[1]){
					date_parts.push(dates[1].split('/'));
				}
				date_parts.forEach( function (date, idx) {
					date_parts[idx] = months[Number(date[0])] + ' ' + Number(date[1]);
				});
				output.push(date_parts.join(' - ') + ' (every year)');
			}

			if (d.bbx_mode_condition_map_tod_wday){
				days_of_week = d.bbx_mode_condition_map_tod_wday.split(',');

				days_of_week.forEach(function (day, idx) {
					day = Number(day);

					if (!day_range) {
						day_range = [day, day];
					} else if (day_range[1] === day - 1) {
						day_range[1] = day;
					} else {
						dow_compressed.push(day_range);
						day_range = [day, day];
					}

					if (idx === days_of_week.length - 1) {
						dow_compressed.push(day_range);
					}
				});

				day_names = dow_compressed.map(function (day){
					if (day[0] === day[1]) {
						return week_days[ Number(day[0]) ];
					} else {
						return week_days[ Number(day[0]) ] + '-' + week_days[ Number(day[1]) ];
					}
				});

				output.push(day_names.join(', '));
			}

			if(d.bbx_mode_condition_map_tod_min_day){
				min_day_range = d.bbx_mode_condition_map_tod_min_day.split('-').map(function (time) {
					var time_string;
					time = Number(time);

					mm = time % 60;
					h = Math.floor(time / 60);
					am_pm = ' AM';

					if (h >= 12) {
						am_pm = ' PM';
						h -= 12;
					}

					if (h === 0) {
						h = 12;
					}

					time_string = h + ":" + ('0' + mm.toString()).slice(-2) + am_pm;
		
					return time_string;
				});

				output.push(min_day_range.join('-'));
			}

			$self.text(output.join(', ') || '(Any Time)');
		}
    });
    
    add_widget('modeScheduleSummaryWidget', modeScheduleSummaryWidget);
})(jQuery);
