/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/*
  Converts tabular data (from a liveTable[1]) into a series of key/values in a fillData to child widgets. New data is fillData'd in as it changes.

  USAGE:
  For example, with the tabular data:

   key | value | ...
  ===================
   a   | Eh    | ...
   b   | Bee
   c   | See
   huh | Irrelevant

   ... and the options:

   {
     key_column: 'key',
     value_column: 'value',
     key_filters: [ '^[a-z]$' ],

     ...all the other dataTable options required to hook into a table...

     elements: [
       ... array of element defs ...
     ]
   }

  ... the widget will pass the following data to the elements below:

  {
    a: 'Eh',
    b: 'Bee',
    c: 'See'
  }

  ("huh: 'Irrelevant'" is omitted because it fails the key_filters test)

  [1] This may work on non-live tables, it may not. This functionality has not yet been tested.


  OPTIONS:
  The options normally required to connect to a dataTable source are also required. See cui.dataTableClass.js (TODO: Document these)
  key_column:   '<column that represents the "key">'
  value_column: '<column that represents the "value">'
  key_filters: [ '<filter>', ... ] // An array of filters. See the inline commentary below.

*/

(function( $ ){
	var keyValueWidget = $.extend({}, $.ui.widget.prototype, CUI.dataTableClass, CUI.htmlContainerClass, {
		options: {
			key_filters: []
			// An array of regexp strings that indicate the keys to pass along. Filters are executed in order, with the result from the previous filter being
			// used on the next. To create a negative filter, use a "!" as the first character. For a literal "!" as the first character, start it with "\\".
			// A leading "\\" will be removed from the regexp string, and the rest will be used as is.
			//
			// 'foo'     --means--> pass if key matches /foo/
			// '!foo'    --means--> pass if key does not match /foo/
			// '\\!foo'  --means--> pass if key matches /!foo/
			// '\\\\foo' --means--> pass if key matches /\\foo/
		},

		_beforeInit: function() {
			var self = this;

			if (self.options.live_table) {
				self.options.native_live_table = true;
			}

			//	    debugLog('Key Value Widget Options: ', self.options);
		},

		_afterInit: function() {},

		_afterAddRow: function(row_data, index) {
			var self = this;
			//	    debugLog('Key Value Widget, row added: ', index, row_data);
			self.refreshDisplayRow(index);
		},

		_beforeLADelete: function (obj, index) {
			var self = this;
			self.removeValue(self.getRowData(index));
		},

		_afterLAOnChange: function(row_data, la_args, index) {
			var self = this;
			switch (la_args.action) {
				case 'add':
				case 'bootstrap_data':
				case 'modify':
					self.refreshDisplay();
					return;
				case 'del':
					return;
				default:
					return;
			}
		},

		fillData: function(d) {
			var $self = this.element;
			var self = this;

			if (!self.options.data) {
				self.options.data = {};
			}
			//debugLog('Key Value Widget fillData: ', d);
			self.refreshDisplay();
		},

		removeValue: function(row) {
			var self = this;
			self.options._current_data[ row[self.options.key_column] ] = '';
			self.fillDataChildren(self.options._current_data);
		},

		refreshDisplay: function() {
			var $self = this.element;
			var self = this;
			var data_out = {}, row_idx, kf_idx, kf, kf_not, kf_pass, rlen = self.getRowCount(), row, key, val;

			if (rlen && self.options.key_column) {

				dataLoop: for (row_idx = 0; row_idx < rlen; ++row_idx) {  // ++row_idx because rows are 1-indexed
					row = self.getRowData(row_idx);
					key = row[self.options.key_column];
					val = row[self.options.value_column];

					if (self.options.key_filters && self.options.key_filters.length) {
						kf_pass = true;

						for (kf_idx = 0; kf_idx < self.options.key_filters.length; kf_idx++) {
							kf = self.options.key_filters[kf_idx];
							kf_not = false;

							// Intentional fallthrough is intentional
							// jshint -W086
							switch (kf.charAt(0)) {
								case '!':
									kf_not = true; 
								case '\\':
									kf = kf.slice(1);
							}
							// jshint +W086

							if ((key.search(new RegExp(kf)) > -1) === kf_not) { // Think of it like a logical "found NXOR invert"
								// This row has failed the filter-- continue to the next row without writing it to data_out.
								continue dataLoop;
							}
						}
					}

					data_out[key] = val;
				}

				// debugLog('Key Value Widget: ', data_out);
				self.options._current_data = data_out;

				self.fillDataChildren(data_out, true);
			}
		},

		refreshDisplayRow: function(row_num, data_obj) {
			var $self = this.element;
			var self = this;

		}
	});
	add_widget('keyValueWidget', keyValueWidget);
})(jQuery);
