/* jshint jquery: true, unused: vars */
/* global CUI, add_widget, debugLog */
/*

This allows a widget or control to send its value, or values it contains, to a Data Table Widget as additional rest_params.

Can be used as a mixin or a secondary widget.

USAGE:
Call "self.initDTWParamAmend()" in your _beforeDOMReady

*/

(function( $ ){
    var dtwParamAmendWidget = $.extend(true, {}, $.ui.widget.prototype, {
	options: {
	    closest: '.pageModuleWidget, .pageWidgetType',
	    selector: undefined,            // The selector for the element you are binding to, under closest
	    widget_name: 'dataTableWidget', // The widget on that element you are trying to bind to (in case there are multiple)
	    own_value_from: undefined,      // undefined uses the first found value_widget, otherwise name the widget that should be used
	    events: 'change',               // Which event(s) signify a change?
	    refresh_target: true,           // Run [target widget].refresh() after change?
	    flush_known_keys: true,         // The DTW rest_params object needs to have un-set values set to "undefined". Set this true to do that.
	    
	    wait_for_screenReady: true,     // Wait for screenReady event before binding and attaching to the target (this should usually be true)

	    // Internal use:
	    _target_widget: undefined,
	    _known_keys: {}
	},

	_beforeInit: function () {
	    var self = this, $self = this.element, $page;
	    
	    $page = $self.closest('.pageWidgetType');

	    if (!$page.hasClass('screenReady') && self.options.wait_for_screenReady) {
			self._one($page, 'screenReady', self._initDPA.bind(self));
		return;
	    }

	    self._initDPA();
	},

	_initDPA: function () {
	    var self = this, $self = this.element;

	    if (self.options.closest && self.options.selector) {
		var $closest = $self.closest(self.options.closest);
		var $linked = $closest.find(self.options.selector);

		if ($linked[0]) {
		    self.options.widget_name = self.options.widget_name || 'widget';
		    self.options._target_widget = $linked.getCUIWidget(self.options.widget_name);

		    if (self.options._target_widget) {
				self.options._target_widget.addDataHook('RestRequest', self._restRequestHook.bind(self));
		    }
		} 

		if (!self.options._target_widget) {
		    debugLog('mixin.dtwParamAmendWidget.js: Could not find the linked DOM element (', self.options.widget_name, ' in ', $linked, ' under ', $closest, ') -- ', $self);
		}
	    }

	    self._bind($self, self.options.events, self._changeHandler.bind(self));
	    
	    self._changeHandler(); // Execute the change handler the first time, in case our widget already has a value to pass along
	},
	
	fillData: function () {
	    this._changeHandler();
	},

	_restRequestHook: function(rest_params) {
	    var self = this;
	    if (!self.options.value) { self._changeHandler(); }
	    return $.extend({}, rest_params, self.options.value || {});
	},

	_changeHandler: function () {
	    var self = this, $self = this.element, widget, value, kk_key;

	    widget = self.options.own_value_from ? $self.getCUIWidget(self.options.own_value_from) : undefined;

	    if (self.options.own_value_from && widget && widget.value_widget) {
		// The specified widget is a value widget-- we can use this!
		value = widget.getValue();
	    } else {
		// CUI.getWidgetElementValue handles everything-- scanning and retrieval.
		value = CUI.getWidgetElementValue($self);
	    }

	    if (self.options.flush_known_keys) {
		for (kk_key in value) {
		    self.options._known_keys[kk_key] = undefined;
		}
		
		self.options.value = CUI.combineObjects(self.options._known_keys, value);
	    } else {
		self.options.value = value;
	    }

	    if (self.options._target_widget && typeof self.options._target_widget.refresh === 'function' && self.options.refresh_target) {
		self.options._target_widget.refresh();
	    }
	}
    });
    
    add_widget('dtwParamAmendWidget', dtwParamAmendWidget);
})(jQuery);
