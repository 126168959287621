
(function () {
    // Simple templates consist of an element with a widget attached, named as a template

    var simple_templates = [
	// [ 'template_name', 'element', 'nameOfWidget', OPTIONAL [ 'widget_reserved_words' ] ],
	// Please use alphabetical order when adding new lines, to assist in finding existing templates.

	// action_button is defined below
	[ 'ancestor_data_mixer',      'div',    'ancestorDataMixerWidget' ],
	[ 'active_calls_data_table',  'table',  'activeCallsDataTableWidget' ],
	[ 'agent_manager',            'div',    'agentManagerWidget' ],
	[ 'agent_monitor',            'div',    'agentMonitorWidget' ],
	[ 'aopb_container',           'div',    'aopbContainerWidget' ],
	[ 'aopb_select_populate',     'select', 'aopbSelectPopulateWidget' ],
	[ 'aopb_state',               'div',    'aopbStateWidget' ],
	[ 'ping_aopb_state',          'div',    'pingAopbStateWidget' ],
	[ 'button_sla',               'div',    'buttonSlaWidget' ],
	[ 'callflow_description',     'span',   'callflowDescriptionWidget'],
	[ 'callers_waiting',          'div',    'callersWaitingWidget' ],
	[ 'check_to_list',            'div',    'checkToListWidget' ],
	[ 'class_toggle_button',      'button', 'classToggleButtonWidget' ],
	[ 'codec_picker',             'div',    'codecPickerWidget' ],
	[ 'container',                'div',    'containerWidget'],
	[ 'container_span',           'span',   'containerWidget'],
	[ 'data_holder_container',    'div',    'dataHolderContainerWidget'],
	[ 'data_item',                'dl',     'dataItemWidget' ],
	[ 'data_poller',              'div',    'dataPollerWidget' ],
	[ 'data_table',               'table',  'dataTableWidget' ],
	[ 'data_to_rest_params_container', 'div',    'dataToRestParamsContainerWidget'],
	[ 'date_range_selector',      'div',    'dateRangeSelectorWidget' ],
	[ 'dhm',                      'span',   'dhmWidget' ],
	[ 'dial_pad',                 'div',    'dialPadWidget' ],
	[ 'digit_action_summary',     'div',    'digitActionSummaryWidget' ],
	[ 'dirty_state',              'div',    'dirtyStateWidget' ],
	[ 'day_picker',               'span',   'dayPickerWidget' ],
	[ 'dynamic_link',             'div',    'dynamicLinkWidget' ],
	[ 'epoch_date',               'div',    'epochDateWidget' ],
	[ 'extension_select',         'div',    'extensionSelectWidget' ], // Validates a new extension
	[ 'fax_view',                 'span',    'faxViewWidget' ],
	// fill_data_button is defined below
	[ 'form',                     'form',   'formWidget', null, ['method','disabled'] ],
	[ 'form_dialog_button',       'button', 'formDialogButtonWidget', null, ['method','disabled'] ],
	[ 'value_form_dialog_button', 'button', 'valueFormDialogButtonWidget', null, ['method','disabled'] ],
	[ 'form_dialog_link',         'a',      'formDialogLinkWidget', null, ['method','disabled'] ],
	[ 'form_refresh_button',      'button', 'formRefreshButtonWidget' ],
	[ 'format',                   'div',    'formatWidget' ],
	[ 'future_time_selector',     'div',    'futureTimeSelectorWidget' ],
	[ 'hardware_port_status',     'div',    'hardwarePortStatusWidget' ],
	[ 'ipv4_input',               'div',    'ipv4InputWidget' ],
	[ 'key_value',                'div',    'keyValueWidget' ],
	[ 'label',                    'label',  'containerWidget' ],
	[ 'ldap_extension_table',     'table',  'ldapExtensionTableWidget' ],
	[ 'link_button',              'button', 'linkButtonWidget' ],
	// masthead is defined below
	[ 'mode_schedule_summary',    'div',    'modeScheduleSummaryWidget'],
	[ 'multiplied_input',         'div',    'multipliedInputWidget'],
	[ 'multi_select_populate',    'div',    'multiSelectPopulateWidget'],
	[ 'new_row_validator',        'div',    'newRowValidatorWidget'],
	[ 'overlay_button',           'button', 'overlayButtonWidget'],
	[ 'page_link',                'div',    'pageLinkWidget' ],
	[ 'page_module',              'div',    'pageModuleWidget' ],
	[ 'people_on_call',           'div',    'peopleOnCallWidget' ],
	[ 'permission_action',        'div',    'permissionActionWidget' ],
	[ 'phone_clone_lookup',       'div',    'phoneCloneLookupWidget' ],
	[ 'phone_button_number_status', 'div',  'phoneButtonNumberStatusWidget' ],
	[ 'pie_chart',                'div',    'piechartWidget' ],
	[ 'vm_pie_chart',             'div',    'voicemailPieChartWidget' ],
	[ 'popup_button',             'button', 'popupButtonWidget'],
	[ 'progress_bar',             'div',    'progressWidget'],
	[ 'queue_monitor',            'div',    'queueMonitorWidget' ],
	[ 'read_unread',              'select', 'readUnreadWidget' ],
	[ 'refreshing_data_table',    'div',    'refreshingDataTableWidget' ],
	[ 'router_rule_display',      'span',   'routerRuleDisplayWidget' ],
	[ 'select_populate',          'select', 'selectPopulateWidget' ],
	[ 'select_range',             'select', 'selectRangeWidget' ],
	[ 'select_self_populate',     'select', 'selectSelfPopulateWidget' ],
	[ 'show_hide_switch',         'div',    'showHideSwitchWidget' ],
	[ 'slider',                   'div',    'sliderWidget', null,             ['min','max'] ],
	[ 'sound_picker',             'div',    'soundPickerWidget' ],
	[ 'sound_player',             'div',    'soundPlayerWidget' ],
	[ 'switch',                   'div',    'switchWidget' ],
	[ 'time_check',               'div',    'timeCheckWidget' ],
	[ 'time_duration',            'span',   'timeDurationWidget' ],
	[ 'time_select',              'div',    'timeSelectWidget' ],
	[ 'time_select_mins',         'span',   'timeSelectMinsWidget' ],
	[ 'toggle_button',            'button', 'toggleButtonWidget' ],
	// translate is defined below
	[ 'wallboard_data_table',     'table',  'wallboardDataTableWidget' ],
	[ 'wallboard_resizer',        'div',    'wallboardResizerWidget' ],
	[ 'widget_div',               'div',    'widget' ],
	[ 'widget_span',              'span',   'widget' ],
	[ 'ldap_discover_button',     'button', 'ldapDiscoverButtonWidget' ],
	[ 'select_container',         'div',    'selectContainerWidget' ],
	[ 'dirty_form',               'form',   'dirtyFormWidget', null, ['method','disabled'] ],
	[ 'sound_music_class_list',   'table',  'soundMusicClassListWidget' ],
	[ 'sound_music_file_list',    'table',  'soundMusicFileListWidget' ],
	[ 'blanker_button',           'button', 'blankerButtonWidget', null, ['method'] ],
	[ 'update_install_button',    'button', 'updateInstallButtonWidget', null, ['method'] ],
	[ 'call_recording_days',      'div',    'callRecordingDaysWidget' ],
	[ 'rest_data_fill_button',    'button', 'restDataFillButtonWidget' ],
	[ 'button_ringtone',          'select', 'buttonRingtoneWidget' ],
	[ 'list_generation',          'dl',    'listGenerationWidget' ]
    ];


    var reg = function (def) {
	register_template(def[0], function (field_def) {
	    var t = build_standard_entity_options(def[1], def[2], field_def, def[3] || null, def[4] || null);
	    if (def[1] === 'button') { t.attributes = { type: 'button' } }
	    return t;
	});
    };

    for (var i = 0; i < simple_templates.length; i++) {
	// Call the function so the closure keeps the value
	reg(simple_templates[i]);
    }

})();


// register_template calls that need more code in the callback--

register_template('masthead', function (field_def) {
    field_def['class'] = 'page_title' + (field_def['class'] || '');
    return build_standard_entity_options('h2', 'mastheadWidget', field_def);
});

register_template('phone_model_masthead', function (field_def) {
    field_def['class'] = 'page_title' + (field_def['class'] || '');
    return build_standard_entity_options('h2', 'phoneModelMastheadWidget', field_def);
});

register_template('translate', function (field_def) {
    var translate = build_standard_entity_options('div', 'translateWidget', field_def);
    translate.widget_data_during_init = true;

    return translate;
});

register_template('truth_translate', function (field_def) {
    var translate = build_standard_entity_options('div', 'truthTranslateWidget', field_def);
    translate.widget_data_during_init = true;

    return translate;
});

register_template('action_button', function (field_def) {
    field_def.type = field_def.type || 'button';
    return build_standard_entity_options('button', 'actionButtonWidget', field_def, null, ['method']);
});

register_template('fill_data_button', function (field_def) {
    field_def.type = field_def.type || 'button';
    return build_standard_entity_options('button', 'fillDataButtonWidget', field_def, null, ['method']);
});
