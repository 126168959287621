/* jshint jquery: true, unused: vars */
/* global add_widget */
/* Wrapper around the keyFilter plugin. Apply to a textbox. */

(function( $ ){
	var keyFilterInputWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			check_function_keys: false
		},

		_beforeInit: function () {
			this.element.keyFilter(this.options);
		}
	});

	add_widget('keyFilterInputWidget', keyFilterInputWidget);
})(jQuery);
