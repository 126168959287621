/* jshint jquery: true, unused: vars */
// Sets up a bubbling "scroll" event on the object and all its ancestors. The "e" event object is the scroll event, with the "type" changed
//
// USAGE:
//    $(...).scrollDetect();
//    $(document).on('scrollDetect', function (e) { ... });

(function ($) {
	$.fn.scrollDetect = function (callback) {

		this.each(function () {
			$(this)
				.parents()
				.filter(function () { return $(this).data('scrollDetect') ? false : true; })
				.data('scrollDetect', true)
				.on('scroll', function (e) {
				if (e.target === this) {
					// At the time of this writing, scroll events do not bubble (hence the need for this plugin). Protect against false alarms, though,
					// if browsers do start bubbling the event.
					$(this).trigger($.extend(true, e, { type: 'scrollDetect' }));
				}
			});
		});
		return this;
	};
})(jQuery);
