(function () {
    register_screen('router_create', {
	elements: [
	    { entity_template: 'page_title', title: 'Create Advanced Call Router' },
	    {
		entity_template: 'page_buttons',
		buttons: [
		    { type: 'cancel_back' },
		    {
			type: 'create',
			key: 'bbx_router_id',
			detail: 'router_detail'
		    }
		]
	    },
	    {
		entity_template: 'page_content', elements: [
		    {
			entity_template: 'form',
			rest: '/gui/extension/router',
			method: 'POST',
			no_initial_get: true,
			submit_all: true,
			always_dirty: true,
			dataEvents: {
			    trigger: 'router'
			},
			elements: [
			    {
				entity_template: 'page_module',
				legend: 'Basic Information',
				buttons: ['help'],
				help_id: 'add_new_call_router',
				set_focus: true,
				elements: [
				    {
					entity_template: 'input_item',
					title: 'Router Name:',
					type: 'text',
					input_name: 'bbx_router_name',
					validate: { conditions: '_TRUEISH_', message: 'A Name is required.' },
					input_options: { attributes: { 'class': 'focus', maxlength: '255' } }
				    },
				    {
					entity_template: 'extension_select',
					name: 'bbx_extension_value'
				    }
				] // END page module elements
			    }, // END page module 1
			    {
				entity_template: 'page_module',
				legend: 'Default Action Set',
				buttons: ['help'],
				help_id: 'add_new_call_router_default_action_set',
				elements: [
				    {
					entity_template: 'input_item',
					title: 'Default Action Set:',
					info: 'To use an existing Action Set by default, select it here. Otherwise, a new Action Set will be created.',
					type: 'select',
					input_name: 'bbx_callflow_id_fallback',
					input_options: {
					    widgets: ['selectPopulateWidget'],
					    widget_options: { selectPopulateWidget: {
						rest: '/gui/extension/router/callflows',
						before: [{ text: '(New Action Set)', value: '' }],
						text_field: 'bbx_callflow_name',
						value_field: 'bbx_callflow_id'
					    }}
					}
				    },
				    {
					entity_template: 'aopb_container',
					closest: 'form',
					states: [{
					    conditions: { a: '$bbx_callflow_id_fallback', op: '!=', b: '_TRUEISH_' },
					    elements: [{
						entity_template: 'input_item',
						input_name: 'bbx_callflow_name',
						type: 'text',
						title: 'Default Action Set Name:',
						info: 'To set up this Action Set, use the ' + entity.ldquo + 'Edit' + entity.rdquo + ' button in the Default Action section, found in the next screen.',
						input_options: {
						    widgets: ['aopbStateWidget'],
						    widget_options: { aopbStateWidget: {
							states: [{
							    conditions: { a: '$$', op: '==', b: '_TRUE_' },
							    actions: { invalid: false },
							    else_actions: { invalid: 'You must specify a name for the Action Set' }
							}]
						    }}
						}
					    }]
					}]
				    }
				]
			    } // END page module 2
			] // END form elements
		    } // END form
		] // END page_content elements
	    } // END page_content
	] // END template elements
    }); // END template
})(); // END scoping function
