register_screen('conference_detail', {
    rest: '/gui/extension/conference',
    dataEvents: {
	bind: {
	    'primary_extension extension': ''
	}
    },
    elements: [
	{ entity_template: 'masthead' },
	{ entity_template: 'page_buttons',
	  buttons: [
	      { type: 'extension', extension_type: 'conference' },
	      {
		  type: 'delete',
		  rest: '/gui/extension/conference',
		  fields: 'bbx_conference_id key',
		  confirm: {
		      title: 'Delete Conference',
		      text: 'Are you sure you would like to delete this conference?'
		  }
	      },
	      { type: 'cancel' }, { type: 'save' }
	  ]
	},
	{ entity_template: 'page_content', elements: [
	    {
		entity_template: 'page_module',
		legend: 'Basic Information',
		form_action: '/gui/extension/conference',
		buttons: [ 'help' ],
		help_id: 'conference_settings',
		elements: [
		    {
			entity_template: 'input_item',
			title: 'Conference Name:',
			input_name: 'bbx_conference_name',
			input_options: { attributes: { maxlength: '255' }},
			validate: { conditions: '_TRUE_', message: 'A Conference Name is required' },
			type: 'text'
		    },
		    {
			entity_template: 'input_item',
			title: 'Access Code:',
			input_name: 'bbx_conference_pin',
			type: 'text',
			input: 'Leave this entry blank to create an unlocked conference.',
			size: 4,
			input_options: {
			    widgets: ['aopbStateWidget', 'keyFilterInputWidget'],
			    widget_options: {
				aopbStateWidget: {
				    states: [{
					conditions: [
					    { a: '$$', op: '==', b: '_NUMERIC_NONNEGATIVE_' },
					    { join: 'or', a: '$$', op: '==', b: '' }
					],
					actions: { invalid: false },
					else_actions: { invalid: 'Please use a numeric entry' }
				    }]
				},
				keyFilterInputWidget: {
				    allow_keys_regexp: '(NUM)?[0-9]',
				    allow_shift: false
				}
			    }
			}
		    },
		    {
			entity_template: 'input_item',
			title: 'Waiting Music:',
			type: 'select',
			input_name: 'bbx_moh_id',
			input_options: {
			    widgets: [ 'selectPopulateWidget' ],
			    widget_options: {
				selectPopulateWidget: {
				    rest: '/gui/moh/class',
				    text_field: 'bbx_moh_name',
				    value_field: 'bbx_moh_id'
				}
			    }
			}
		    }
		]
	    },
	    {
		entity_template: 'page_module',
		legend: 'Conference Options',
		form_action: '/gui/extension/conference',
		buttons: [ 'help' ],
		help_id: 'conference_options',
		elements: [
		    {
			entity_template: 'input_item',
			title: 'Announce Callers by Name:',
			info: 'Prompt participants for a name, and announce it to the conference on arrival.',
			type: 'checkbox',
			input_name: 'bbx_conference_flag_announce'
		    },
		    {
			entity_template: 'input_item',
			title: 'Two Participant Minimum:',
			info: 'End the conferences once fewer than two participants remain.',
			type: 'checkbox',
			input_name: 'bbx_conference_flag_mintwo'
		    },
		    {
			entity_template: 'input_item',
			title: 'Auto Gain Control:',
			info: 'Automatically amplify or quiet participants'+entity.rsquo+' speech to maintain consistent volume.',
			type: 'checkbox',
			input_name: 'bbx_conference_flag_agc'
		    }
	 	]
	    },
	    {
		entity_template: 'page_module',
		legend: 'Conference Viewer/Moderator Groups',
		buttons: [ 'help' ],
		help_id: 'conference_member_group',
		elements: [ {
		    entity_template: 'data_table',
		    accept_data_from_parent: true,
		    data_source: 'groupmap',
		    click_action: 'edit',
		    add_edit_action: {
			rest: '/gui/extension/conference/groupmap',
			include_row_data: ['bbx_group_id'],
			only_rest_params: ['bbx_conference_id']
		    },
		    delete_action: {
			rest: '/gui/extension/conference/groupmap',
			include_row_data: ['bbx_group_id'],
			only_rest_params: ['bbx_conference_id']
		    },
		    paginate: false,
		    length_change: false,
		    filtering: false,
		    extra_row_data: ['bbx_group_name', 'bbx_extension_id'],
		    oLanguage: {
			sZeroRecords: 'No Groups assigned to the Conference'
		    },
		    name_column: 'bbx_group_name',
		    columns: [
			{
			    header: 'Group Name',
			    column: 'bbx_group_id',
			    add_element: {
				entity_template: 'extension_picker_select',
				type: 'group',
				primary: 1,
				allow_nothing: false,
				table_filter: true,
				name: 'bbx_group_id'
			    },
			    view_element: {
				entity: 'div',
				widgets: [ 'formatWidget' ],
				widget_options: {
				    formatWidget: {
					format: { field: '@bbx_group_name' }
				    }
				}
			    }
			},
			{
			    header: 'Moderator',
			    column: 'bbx_conference_group_map_moderator',
			    translate: {
				0: 'Viewer',
				1: 'Moderator'
			    },
			    add_element: {
				entity: 'select',
				attributes: {
				    name: 'bbx_conference_group_map_moderator'
				},
				select_options: [
				    { value: '0', text: 'Viewer' },
				    { value: '1', text: 'Moderator' }
				]
			    },
			    edit_element: {
				entity: 'select',
				attributes: {
				    name: 'bbx_conference_group_map_moderator'
				},
				select_options: [
				    { value: 0, text: 'Viewer' },
				    { value: 1, text: 'Moderator' }
				],
				submit_on_change: true
			    }
			}
		    ],
		    row_actions: {
			edit_save: { text: 'Save' },
			edit_cancel: { text: 'Cancel' },
			add_save: { text: 'Add' },
			add_cancel: { text: 'Cancel' }

		    },
		    table_actions: {
			add_row: {
			    text: 'Add Group',
			    location: 'bottom'
			},
			delete_rows: {
			    text: 'Remove Selected Groups',
			    location: 'bottom',
			    confirm: {
				title: 'Remove Selected Groups',
				confirm: 'Are you sure you want to remove the selected groups from this conference?'
			    }
			},
			select_row: true,
			select_all_rows: true
		    }
		} ]
	    },
	    elgen('secondary_page_module', { type: 'conference' }, { help_id: 'secondary_extensions' })

	]
	}
    ]
});
