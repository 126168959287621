/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/*

@class providerFiller

This widget is used on the Providers Create screen to fill the form based on the provider selected. It is quite specialized (as it does a lot of name
conversion), so it is not used elsewhere.

This widget should be applied to a SELECT control.
This widget does not accept data-- it is assumed to only be used on the provider create page, which needs no data fill.

@par Parameters:

closest: 'form',

*/

(function( $ ){
	var providerFillerWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			closest: 'form',
			ignore: [ 'gateways', 'bbx_provider_id', 'bbx_domain_id', 'bbx_provider_template', 'bbx_provider_did_type', 'bbx_provider_url' ]
		},

		_beforeInit: function () {
			var self = this;
			var $self = this.element;

			CUI.getREST('/gui/provider/preset', {}, function (d) {
				d = d.provider;

				for (var i=0; i<d.length; i++) {
					var prov = d[i];
					var $opt = $('<option />');

					$opt.val(prov.bbx_provider_id);
					$opt.text(prov.bbx_provider_name);

					for (var key in prov) {
						if ($.inArray(key, self.options.ignore) > -1) {
							delete prov[key];
							continue;
						}

						var val = (prov[key] || '').toString().match(/LOCKED:(.+)/);
						val = val ? val[1] : (prov[key] || '');
						var locked = (val !== (prov[key] || '').toString()) ? 'LOCKED:' : '';

						if (key === 'bbx_provider_direction' && val) {
							if (val === 'inbound') {
								prov.bbx_provider_gateway_flag_inbound = locked+'1';
								prov.bbx_provider_gateway_flag_outbound = locked+'0';
							} else if (val === 'outbound') {
								prov.bbx_provider_gateway_flag_inbound =  locked+'0';
								prov.bbx_provider_gateway_flag_outbound =  locked+'1';
							} else if (val === 'both') {
								prov.bbx_provider_gateway_flag_inbound =  locked+'1';
								prov.bbx_provider_gateway_flag_outbound =  locked+'1';
							}

							delete prov.bbx_provider_direction;
						} else {
							prov[key.replace(/^bbx_provider_/, 'bbx_provider_gateway_')] = prov[key];
							delete prov[key];
						}
					}

					$opt.data('providerData', prov);
					$self.append($opt);
				}
			});

			self._bind($self, 'change', function () {
				var $selected = $self.find('option:selected');
				var prov = $selected.data('providerData');
				var provOut = {};

				if (!prov) {
					prov = {
						bbx_provider_gateway_name: '',
						bbx_provider_gateway_auth_username: null,
						bbx_provider_gateway_caller_id: null,
						bbx_provider_gateway_direction: null,
						bbx_provider_gateway_expire: null,
						bbx_provider_gateway_extension: null,
						bbx_provider_gateway_host: null,
						bbx_provider_gateway_password: null,
						bbx_provider_gateway_port: null,
						bbx_provider_gateway_realm: null,
						bbx_provider_gateway_username: null
					};
				}


				for (var key in prov) {
					var v = (prov[key] || '').toString();
					var v_parts = v.split(':');
					var type, value;

					if (!v_parts[1] && v_parts[0] !== 'NONE') {
						type = '';
						value = v_parts[0];
					} else if (v_parts[0] === 'NONE') {
						type = 'NONE';
						value = '';
					} else {
						type = v_parts[0];
						value = v_parts[1];
					}

					if (key === 'bbx_provider_gateway_port' && !type && !value) {
						value = '5060';
					}

					if (type !== 'LOCKED') {
						$self.closest(self.options.closest || 'form').find('[name='+key+']').removeAttr('readonly');
					}

					switch (type) {
						case 'NONE':
							provOut[key] = '';
							break;
						case 'LOCKED':
							$self.closest(self.options.closest || 'form').find('[name='+key+']').attr('readonly','readonly');
							// jshint -W086
							// Intentional fallthrough
						case '':
							// jshint +W086
							provOut[key] = value;
							break;
					}
				}

				$self.closest(self.options.closest || 'form').getCUIWidget().fillData(provOut, true);
			});
		}

	});

	add_widget('providerFillerWidget', providerFillerWidget);
})(jQuery);
