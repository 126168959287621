/* jshint jquery: true, unused: vars, multistr: true */
/* global CUI, add_widget, cmp, debugLog */
/*

  This is a base-class widget, that can be implemented to create things like the Codec Picker, and other such pickers. It is based on Custom Input Widget.

*/

(function( $ ){
	// For some reason, putting this in the options causes Brackets to flip its lid on intending, so I'll just reference it from here.
	var template_html = '<div class="dlp-outer">'+
'<div class="dlp-available-wrap dlp-list-wrap">'+
'<div class="dlp-available-list-title dlp-list-title">Available</div>'+
'<div class="dlp-available dlp-list" />'+
'</div>'+
'<div class="dlp-buttons">'+
'<button type="button" class="dlp-add"><img src="/images/dualListPickWidget/add.png" width="20" height="20" alt="Add" /></button>'+
'<button type="button" class="dlp-remove"><img src="/images/dualListPickWidget/remove.png" width="20" height="20" alt="Remove" /></button>'+
'<button type="button" class="dlp-up"><img src="/images/dualListPickWidget/up.png" width="20" height="20" alt="Move Up" /></button>'+
'<button type="button" class="dlp-down"><img src="/images/dualListPickWidget/down.png" width="20" height="20" alt="Move Down" /></button>'+
'</div>'+
'<div class="dlp-selected-wrap dlp-list-wrap">'+
'<div class="dlp-selected-list-title dlp-list-title">Selected</div>'+
'<div class="dlp-selected dlp-list" />'+
'</div>'+
'<div class="dlp-clear" />'+
'</div>';

	var dualListPickWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			template_html: template_html,

			row_html: '<div class="dlp-item"><div class="dlp-item-content"></div><a href="javascript:void(0)" class="dlp-item-tabstop"></a></div>',

			// If available_rest and available_rest_data are false or undefined, uses the same pull as "rest". Do NOT set this (or available_rest_data) unless
			// the pulls for available and selected are different.
			available_rest: false,
			available_rest_data: false,
			available_rest_container: false,

			// Set these if your Available data has categories. Categories are not currently supported for the Selected side.
			available_category_container: false,
			available_category_title: false,

			// In the unmodified widget, this determines the displayed title on the row.
			available_item_title: false,

			// If true, the value for this element should be given in an array of strings, which are correlated to Available elements via the item_id
			selected_lookup_from_available: true,
			selected_item_title: false,
			// Set this false if the instance does not need to wait for an initial value, e.g., it is a create form with no initial value
			wait_for_selected: true,

			// Set this to the key in the individual items that determines the item's unique ID. Used for determining which "available" item matches a removed
			// "selected"
			item_id: false,
			output_id: false,

			// Set this to the key in the individual items that should be submitted. An array of these keys will be submitted.
			item_submit: false,
			item_submit_name: false,

			// If true, the "available" item will not be removed when it is dragged over, and the same item may be placed multiple times
			allow_multiple_selections: false,

			// If set, the value will be expected and submitted as a single string, delmited by the value. If not set, the value will be expected and submitted
			// as an array.
			separator: false,

			// These items are used internally, and are available to your derivatives, but should not be set on start
			_selected_array: [],
			_available: undefined,
			_last_clicked: { available: undefined, selected: undefined },

			// Height, to be optionally specified, in pixels (do not add 'px')
			height: undefined,

			// If an empty control is submitted, this value will be used instead. If left as an empty array ([]), then the key/value will usually be omitted
			submit_empty_array_as: [], // | [ <array of values> ] | <string value>

			available_title: 'Available',
			selected_title:  'Selected'
		},

		value_widget: true,
		set_value_widget: true,

		manages_own_descendent_value: true,
		manages_own_descendent_events: true,

		/*

	  Properties you may want to override in your subclasses (though you probably don't need to)

	 */

		// Class Names: You may need to change the s.o.template_html in your derived class if you change these expected class names. That is not automatic!
		// You MUST have the relevant class names on the proper objects, or you'll have JS headaches.

		_container_class:      'dlp-outer',

		_available_list_class: 'dlp-available',
		_available_list_wrap_class: 'dlp-available-wrap',
		_available_list_title_class:   'dlp-available-list-title',
		_available_hover_class: 'dlp-drop-hover',
		_selected_list_class:  'dlp-selected',
		_selected_list_wrap_class: 'dlp-selected-wrap',
		_selected_list_title_class:   'dlp-selected-list-title',
		_selected_hover_class: 'dlp-drop-hover',
		_list_class:           'dlp-list',
		_list_wrap_class:      'dlp-list-wrap',
		_list_title_class:     'dlp-list-title',

		_buttons_class:        'dlp-buttons',
		_button_up_class:      'dlp-up',
		_button_down_class:    'dlp-down',
		_button_add_class:     'dlp-add',
		_button_remove_class:  'dlp-remove',

		_category_class:       'dlp-category',
		_category_title_class: 'dlp-category-title',

		_float_clear_class:    'dlp-clear',

		_item_class:           'dlp-item',
		_item_content_class:   'dlp-item-content',
		_item_tabstop_class:   'dlp-item-tabstop',
		_selected_item_class:  'dlp-selected-item',
		_available_item_class: 'dlp-available-item',
		_active_item_class:    'dlp-active-item',
		_also_drag_item_class: 'dlp-also-drag', // The class that active items get when doing a multi-select and drag

		_available_hidden_item_class: 'dlp-available-item-hidden', // Note that this class's CSS does not need to provide the hiddenness, that's done already
		_sort_placeholder_class: 'dlp-sort-placeholder',

		// $element.data( self._row_data_key ) is used to store data
		_row_data_key:         'dlp-data',


		/*

	  Methods you may want to override in your subclasses (and you'll probably want to)

	*/


		_buildItemDetail: function (item) {
			return (
				$('<div />')
				.addClass(this._item_class)
				.text(item[this.options.available_item_title] || '')
			);

		},
		// When overriding, don't forget the addClass! This is important!
		_buildAvailableItemDetail: function (item) {
			return this._buildItemDetail(item).addClass(this._available_item_class);
		},
		_buildSelectedItemDetail:  function (item) { return this._buildItemDetail(item).addClass(this._selected_item_class); },

		// Extend this in your subclass if the item data needs to be modified or normalized before rendering and storage.
		// "metadata" is an object containing { index: INTEGER index }. Return the modified item.
		_processSelectedItemData: function (item, metadata) {
			return item;
		},

		_processAvailableItemData: function (item, metadata) {
			// Ditto the above.
			return item;
		},

		_processAvailableCategoryData: function (items_array, metadata) {
			// Ditto the above, but "items_array" is the array of items in the category. This would be a great time to make the category data an array, if it
			// isn't one.
			return items_array;
		},

		_buildItemsWrapper: function ($into) { return $into; },
		_buildAvailableWrapper: function ($into) { return this._buildItemsWrapper($into); },
		_buildSelectedWrapper:  function ($into) { return this._buildItemsWrapper($into); },

		moveUpActiveItems: function () { this._moveActiveSelectedUp(); },
		moveDownActiveItems: function () { this._moveActiveSelectedDown(); },
		addActiveItems: function (index) { this._moveActiveAvailableToSelected(index); },
		removeActiveItems: function () { this._moveActiveSelectedToAvailable(); },

		_afterAdd: function (item, index, $item) {
			this.options._selected_array.splice(index, 0, item);
			this._afterChange();
		},

		_afterRemove: function (item, index) {
			this.options._selected_array.splice(index, 1);
			this._afterChange();
		},

		_afterMove: function (item, old_index, index) {
			var splice_in = this.options._selected_array.splice(old_index, 1)[0];
			this.options._selected_array.splice(index, 0, splice_in);
			this._afterChange();
		},

		_afterChange: function () {
			this.element.trigger('change');
		},

		_buildCategoryContainer: function (title) {
			title = title || '[jquery.dualListPickWidget: No title data]';

			var $title = $('<div />').addClass(this._category_title_class).text(title);
			var $container = $('<div />').addClass(this._category_class).append($title);

			return $container;
		},

		_buildSelectedCategoryContainer: function (item) {
			var self = this, $self = this.element;
			var title = (item && self.options.selected_category_title) ? item[self.options.selected_category_title] : '[jquery.dualListPickWidget: Improper/missing category title specification]';
			return self._buildCategoryContainer(title);
		},

		_buildAvailableCategoryContainer: function (item) {
			var self = this, $self = this.element;
			var title = (item && self.options.available_category_title) ? item[self.options.available_category_title] : '[jquery.dualListPickWidget: Improper/missing category title specification]';
			return self._buildCategoryContainer(title);
		},

		// "Activating" or "Deactivating" an element is when you click on it, in order to manipulate it using the buttons

		_activateElement: function ($item, state) {
			// If state is provided and false, the element should be deactivated
			var self = this, $self = this.element;
			state = (state || state === undefined) ? true : false;
			$item.toggleClass(self._active_item_class, state);
		},

		_deactivateElement: function ($item) { this._activateElement($item, false); },

		_activeToggleElement: function ($item) {
			this._activateElement($item, !$item.hasClass(this._active_item_class));
		},

		// This is used to determine which item in the "Available" list is the same as the one we just discarded (dragged into it)
		_itemsAreSame: function (a, b) {
			return (a[this.options.item_id] === b[this.options.item_id]);
		},

		/*

	  Methods you probably won't override

	  Common widget methods

	*/

		_beforeInit: function () {
			var self = this, $self = this.element;

			// Remove s.o.rest_container, because fillData should get everything, and split it out by selected_container and available_container
			if (self.options.rest_container && !self.options.selected_container) {
				self.options.selected_container = self.options.rest_container;
				self.options.rest_container = false;
			}

			if (self.options.available_rest || self.options.available_rest_data) {
				self.options.available_rest = self.options.available_rest || self.options.rest;
				self.options.available_rest_data = self.options.available_rest_data || {};
			}
		},

		_beforeDOMReady: function () {
			var self = this, $self = this.element;
			self._getAvailable();
			self._bind($self.find('.' + self._button_add_class), 'click',    function () { self.addActiveItems(); });
			self._bind($self.find('.' + self._button_remove_class), 'click', function () { self.removeActiveItems(); });
			self._bind($self.find('.' + self._button_up_class), 'click',     function () { self.moveUpActiveItems(); });
			self._bind($self.find('.' + self._button_down_class), 'click',   function () { self.moveDownActiveItems(); });

			if (self.options.available_title) {
				$self.find('.' + self._available_list_title_class).text(self.options.available_title);
			}

			if (self.options.selected_title) {
				$self.find('.' + self._selected_list_title_class).text(self.options.selected_title);
			}

			// Set the height of the pickers if it is defined in widget options
			if (!isNaN(parseInt(self.options.height))) {
				$self.find('.' + self._available_list_class + ', .' + self._selected_list_class).css('height', self.options.height);
			}
		},

		setValue: function (v) {
			var self = this, $self = this.element;

			if (self.options.separator) {
				self.options._raw_value_in = v.split(self.options.separator);
			} else {
				self.options._raw_value_in = v;
			}

			self._checkDataReady();
		},

		isDirty: function (key, orig, now) {
			return $.ui.widget.prototype.isDirty.apply(this, arguments);
		},

		_getWidgetValue: function () {
			var self = this, $self = this.element, output_id = self.options.output_id || self.options.item_id, i, value_out = [];
			for (i=0; i<self.options._selected_array.length; i++) {
				value_out.push(self.options._selected_array[i][output_id]);
			}

			if (!value_out.length) {
				value_out = self.options.submit_empty_array_as;
			}

			if (self.options.separator && $.isArray(value_out)) {
				value_out = value_out.join(self.options.seperator);
			}

			return self._wrapValue(value_out);
		},

		/*

	  DLP-specific methods

	 */

		_getAvailable: function () {
			var self = this, $self = this.element;
			if (self.options.available_rest) {
				self._getAvailableFromREST();
			} else {
				self.options._available = self.options.data[self.options.available_container];
				self._checkDataReady();
			}

		},

		_getAvailableFromREST: function () {
			var self = this, $self = this.element;
			self.options._available = undefined;

			CUI.getREST(self.options.available_rest, self.options.available_rest_data, CUI.FunctionFactory.build(self._receiveAvailableFromREST, self, { context: 'argument' }));
		},

		_receiveAvailableFromREST: function (d) {
			var self = this, $self = this.element;

			if (self.options.destroyed) { return; }

			if (self.options.available_rest_container) {
				self.options._available = d[self.options.available_rest_container];
			} else {
				self.options._available = d;
			}

			if (!$.isArray(self.options._available)) {
				debugLog('jquery.dualListPickWidget.js: Available data pull did not return an array. Is your options.available_rest_container correct? -- ', $self);
			}

			self._checkDataReady();
		},

		_checkDataReady: function () {
			var self = this, $self = this.element;
			if ((self.options._raw_value_in || !self.options.wait_for_selected) && self.options._available) {
				self._bothDataReady();
			}
		},

		_bothDataReady: function () {
			var self = this, $self = this.element;
			var $available = $self.find('.' + self._available_list_class), $selected = $self.find('.' + self._selected_list_class);
			var available = self.options._available, selected;
			var $a_wrap = self._buildAvailableWrapper($available), $s_wrap = self._buildSelectedWrapper($selected), $category_wrapper;
			var a_data, ac_data, a_idx, ac_idx, $ac;

			$available.empty();

			if (available) {
				for (a_idx=0; a_idx < available.length; a_idx++) {
					a_data = available[a_idx];

					if (self.options.available_category_container) {
						a_data = self._processAvailableCategoryData(a_data);
						$category_wrapper = self._buildAvailableCategoryContainer(a_data);

						for (ac_idx = 0; ac_idx < a_data[self.options.available_category_container].length; ac_idx++) {
							ac_data = self._processAvailableItemData(a_data[self.options.available_category_container][ac_idx]);
							$ac = self._buildAvailableItemDetail(ac_data);
							if (ac_data) {
								$ac.data(self._row_data_key, ac_data);
							}
							$category_wrapper.append( $ac );
						}

						$available.append($category_wrapper);
					} else {
						a_data = self._processAvailableCategoryData(a_data);
						var $a = self._buildAvailableItemDetail(a_data);

						if (a_data) {
							$a.data(self._row_data_key, a_data);
						}

						$available.append($a);
					}
				}
			}

			$selected.empty();
			self.options._selected_array = [];

			// _raw_value_in might not be set if s.o.wait_for_selected is false, if that's the case, there's nothing to scan
			if (self.options._raw_value_in) {

				// If we are doing a lookup from the Available column, scan through the Available entries, and copy the ones we have a string for
				// in s.o._raw_value_in.

				var found;
				if (self.options.selected_lookup_from_available) {
					selected = [];
					for (a_idx=0; a_idx < available.length; a_idx++) {
						a_data = available[a_idx];
						if (self.options.available_category_container) {

							// Inner loop, if there are subcats...
							for (ac_idx=0; ac_idx < a_data[self.options.available_category_container].length; ac_idx++) {
								ac_data = a_data[self.options.available_category_container][ac_idx];
								found = $.inArray(ac_data[self.options.item_id], self.options._raw_value_in);

								if (found > -1) {
									selected[found] = $.extend({}, ac_data);
								}
							}

						} else {
							found = $.inArray(a_data[self.options.item_id], self.options._raw_value_in);
							if (found > -1) {
								selected[found] = $.extend({}, a_data);
							}
						}
					}
				} else {
					// If we aren't doing a lookup, just copy the array over
					selected = self.options._raw_value_in;
				}
			} else {
				// If we had no value (!s.o.wait_for_selected)
				selected = [];
			}

			// delete self.options._raw_value_in;

			for (var s_idx = 0; s_idx < selected.length; s_idx++) {
				if (selected[s_idx] === undefined) { continue; }
				var s_data = self._processSelectedItemData(selected[s_idx], { index: s_idx });
				self._addSelectedItem(s_data, {remove_available: true});
			}

			self._initSortables();
		},

		_initSortables: function () {
			var self = this, $self = this.element;
			var $selected = $self.find('.' + self._selected_list_class), $available = $self.find('.' + self._available_list_class);
			var available_id = $available.attr('id');

			if (!available_id) {
				available_id = self.options.widget_id + '-available-container';
				$selected.attr('id', available_id);
			}

			$available.find('.' + self._available_item_class)
				.draggable({
				helper: 'clone',
				containment: $self,
				scroll: false,
				connectToSortable: '#' + available_id,
				stop: function (e, ui) {
					var $item = $(this);

					if ($item.data('dlp-trigger-deselect')) {
						self._deactivateElement($item);
					}

					if ($item.data('dlp-trigger-remove')) {
						$item.removeData('dlp-trigger-remove');
						self._removeAvailableElement($item);
					}
				}
			});

			$available.add($selected)
				.delegate('.' + self._available_item_class + ',.' + self._selected_item_class, 'mousedown', function (e) {
				self.options._mousedown_xy = e.pageX + '/' + e.pageY;
			})
				.delegate('.' + self._available_item_class + ',.' + self._selected_item_class, 'mouseup', function (e) {
				if (self.options._mousedown_xy === e.pageX + '/' + e.pageY) {
					// Yes, this is a click, not a drag
					self._itemElementClickEvent($(this), e);
				}
			});

			$available
				.droppable({
				accept: '.' + self._selected_item_class,
				containment: $self,
				over: function (e,ui) { $(this).closest('.' + self._available_list_wrap_class).addClass(self._available_hover_class); },
				out:  function (e,ui) { $(this).closest('.' + self._available_list_wrap_class).removeClass(self._available_hover_class); },
				drop: function (e,ui) {
					var multi = ui.draggable.hasClass(self._active_item_class);
					$(this).closest('.' + self._available_list_wrap_class).removeClass(self._available_hover_class);
					self._removeSelectedElement(ui.draggable);
					if (multi) {
						self._moveActiveSelectedToAvailable();
					}
				}
			});

			$selected
				.sortable({
				items: '.' + self._selected_item_class + ':not(.' + self._also_drag_item_class + '),.' + self._available_item_class,
				placeholder: self._sort_placeholder_class,
				containment: $self,
				over: function (e,ui) { $(this).closest('.' + self._selected_list_wrap_class).addClass(self._selected_hover_class); },
				out:  function (e,ui) { $(this).closest('.' + self._selected_list_wrap_class).removeClass(self._selected_hover_class); },
				start: function (e,ui) {
					ui.item.data('dlp-moving-index', self._getElementIndex(ui.item));

					if (ui.item.hasClass(self._active_item_class)) {
						// Save the active dragged items *in their original order* for use on the "stop" handler
						self.options.__$active_drag_items = $selected.find('.' + self._active_item_class);
						self.options.__$active_drag_items.addClass(self._also_drag_item_class);
					} else {
						self._clearActives($(this));
					}

					self.options.__reorder = true;
				},
				stop: function (e,ui) {
					var dragged_index_was, index, $actives;

					// (__reorder is set on "start" and indicates a sortable change, not a drop from outside. __order_change is set on "change" and
					// indicates that the action actually changed the position [i.e. wasn't picked up and dropped in the same position]).
					if (self.options.__reorder && self.options.__order_change) {
						dragged_index_was = ui.item.data('dlp-moving-index');

						if (dragged_index_was === undefined) {
							return;
						} // This was a drop from the other side

						index = self._getElementIndex(ui.item);
						ui.item.removeData('dlp-moving-index');

						// Move the item they clicked and dragged on. This has already been moved in the DOM (by "sortable"), so it's just a matter of
						// calling _afterMove with the old and new positions

						$actives = $(this).find('.' + self._active_item_class)
							.removeClass(self._also_drag_item_class);

						self._afterMove(ui.item.data(self._row_data_key), dragged_index_was, index);


						// Now, as for the others...

						if (
							ui.item.hasClass(self._active_item_class) && // If it's a select and drag...
							self.options.__$active_drag_items && // ...and we have drag items...
							self.options.__$active_drag_items[1] && // ...and we have 2 or more of them....
							self.options.__order_change // ...and this was an order-change...
						) {

							// ...then there are other active items to move. The dragged one is there already, so loop through the rest, and put them
							// above or below the dragged item, depending on how the items were originally ordered (which was saved in
							// s.o.__$active_drag_items).

							var have_seen_item = false, $old_actives = self.options.__$active_drag_items, $last = ui.item;
							for (var i=0; i<$old_actives.length; i++) {

								var $this_item = $old_actives.eq(i), this_item = $this_item.data(self._row_data_key), index_was = self._getElementIndex($this_item);
								if ($this_item.is(ui.item)) {
									// Nothing to move, it's already been done
									have_seen_item = true;
								} else if (have_seen_item) {
									$this_item.insertAfter($last);
									$last = $this_item;
									index = self._getElementIndex($this_item);
									self._afterMove(this_item, index_was, index);
								} else {
									$this_item.insertBefore(ui.item);
									index = self._getElementIndex($this_item);
									self._afterMove(this_item, index_was, index);
								}
							}
						}
					}

					delete self.options.__reorder;
					delete self.options.__order_change;
					delete self.options.__$active_drag_items;
				},
				change: function (e,ui) {
					self.options.__order_change = true;
				},
				receive: function (e,ui) {
					$(this).closest('.' + self._list_wrap_class).removeClass(self._selected_hover_class);

					// When dragged from one list to another, re-render the item as a "selected" instead of an "available"
					var sortable_data = $(this).data('sortable'), $is = sortable_data.currentItem, $was = ui.item, was_data = $was.data(self._row_data_key);

					if ($was.hasClass(self._active_item_class)) {
						var index = $(this).closest('.' + self._selected_list_class).find('.' + self._item_class).index($is);
						$is.remove();
						self._moveActiveAvailableToSelected(index);
						return;
					}

					var $will_be = self._addSelectedItem(was_data, { $dummy: $is });
					sortable_data.currentItem = $will_be;
					$(this).data('sortable', sortable_data);

					// Draggable is still using the item, so removing it now will cause an error in jquery-ui internals. Set a bunch of flags that we
					// handle in the draggable "stop" event.
					if (self.options.allow_multiple_selections) {
						$was.data('dlp-trigger-deselect', true);
					} else {
						$was.data('dlp-trigger-remove', true);
						self._clearActives($available);
					}
				}
			});

		},

		_clearActives: function ($container, preserve_last_clicked) {
			var self = this, $self = this.element;
			var side = $container.is('.' + self._available_list_class) ? 'available' : 'selected';
			var $items = $container.find('.' + self._item_class);

			if (!preserve_last_clicked) {
				self.options._last_clicked[side] = undefined;
			}

			for (var i=0; i<$items.length; i++) {
				self._deactivateElement($items.eq(i));
			}
		},

		_getElementIndex: function ($item, /* optional */ item_class) {
			var self = this, $self = this.element;
			var $container = $item.closest('.' + self._list_class);
			item_class = item_class || ( $container.is('.' + self._available_list_class) ? self._available_item_class : self._selected_item_class );
			return $container.find('.' + item_class).index($item);

		},

		_itemElementClickEvent: function ($item, e) {
			var self = this, $self = this.element;
			var $container = $item.closest('.' + self._list_class);
			var side = $container.is('.' + self._available_list_class) ? 'available' : 'selected', other_side = side === 'available' ? 'selected' : 'available';
			var $siblings, $range, range;

			self._bringElementIntoView($item, $container);

			self._clearActives($self.find('.' + self['_' + other_side + '_list_class']));

			if (self.options._last_clicked[side] && (e.shiftKey || e.ctrlKey)) {
				if (e.shiftKey) {
					$siblings = $container.find('.' + self._item_class);
					range = [self._getElementIndex($item), self._getElementIndex(self.options._last_clicked[side])].sort(cmp);
					$range = $siblings.slice(range[0],range[1] + 1).not('.' + self._available_hidden_item_class);

					if (!e.ctrlKey) {
						self._clearActives($container, true);
					}

					self._activateElement($range);
				} else if (e.ctrlKey) {
					self._activeToggleElement($item);
					self.options._last_clicked[side] = $item;
				}

				// TODO: Support Shift+Ctrl

			} else {
				self._clearActives($container);
				self.options._last_clicked[side] = $item;
				self._activateElement($item);
			}
		},

		_bringElementIntoView: function ($elem, $container) {
			var self = this, $self = this.element;

			$container = $container || $elem.closest('.' + self._list_class);

			var scroll = { top: $container.scrollTop() }, position = { top: $elem.position().top }, $ofsp = $elem.offsetParent();

			// The scrollable DIV might not be the offset-parent, meaning that the position reading will be wrong. Compensate.
			if (!$ofsp.is($container)) {
				position.top -= $container.position().top;
			}

			scroll.height = $container.innerHeight();
			position.bottom = position.top + $elem.outerHeight();

			if (position.top < 0) {
				$container.scrollTop(scroll.top + position.top);
			} else if (position.bottom > scroll.height) {
				$container.scrollTop(position.bottom + scroll.top - scroll.height);
			}
		},

		// NOTE: This function does NOT remove the item from the Available list-- due to the order of operations in the draggable vs. sortable, that has to
		//       happen separately.
		/*
	  PARAMS:

	    $dummy: $(...)
	      "$dummy" is the element that gets inserted automatically by drag/drop. If this is a drag/drop operation, we clobber the dummy, if it's not
	      ($dummy is undefined, as in the case of using the Move buttons), we just insert the new object

	    index: (number)
	      Use "index" if you want to insert the item at a certain index

	    If neither of these options are set, the item is appended to the end of the list.
	*/

		_addSelectedItem: function (data, params) {
			var self = this, $self = this.element, $item, index;
			var $items;

			// data = data || $dummy.data(self._row_data_key);
			params = params || {};

			$item = self._buildSelectedItemDetail( data );
			$item.data(self._row_data_key, data);

			if (params.remove_available && !self.options.allow_multiple_selections) {
				var $avails = $self.find('.' + self._available_list_class + ' .' + self._available_item_class);
				findLoop: for (var i=0; i<$avails.length; i++) {
					if ($avails.eq(i).data(self._row_data_key)[self.options.item_id] === data[self.options.item_id]) {
						self._removeAvailableElement($avails.eq(i));
						break findLoop;
					}
				}

			}

			$items = $self.find('.' + self._selected_list_class + ' .' + self._selected_item_class);

			if (params.$dummy) {
				params.$dummy.replaceWith($item);
				index = $items.index($item);
			} else if (typeof params.index === 'number' && params.index > -1 && params.index < $items.length) {
				$item.insertBefore($items.eq(params.index));
			} else {
				// Goes on the end
				$items = $self.find('.' + self._selected_list_class + ' .' + self._selected_item_class);
				index = $items.length;
				$item.appendTo($self.find('.' + self._selected_list_class));
			}

			self._afterAdd(data, index, $item);

			return $item;
		},

		_removeSelectedElement: function ($item) {
			var self = this, $self = this.element;
			var data = $item.data(self._row_data_key);
			var index = $self.find('.' + self._selected_list_class + ' .' + self._selected_item_class).index($item);

			$item.remove();
			self._restoreAvailableItem(data);
			self._afterRemove(data, index);
		},

		_removeAvailableElement: function ($item) {
			var self = this, $self = this.element;
			$item
				.addClass(self._available_hidden_item_class)
				.removeClass(self._active_item_class)
				.hide();
		},

		_restoreAvailableItem: function (item) {
			var self = this, $self = this.element;
			var $possibles = $self.find('.' + self._available_list_class + ' .' + self._available_hidden_item_class), $item;

			scanHidden: for (var i=0; i<$possibles.length; i++) {
				var $possible = $possibles.eq(i);
				if (self._itemsAreSame($possible.data(self._row_data_key), item)) {
					$item = $possible;
					break scanHidden;
				}
			}

			if ($item) {
				$item.removeClass(self._available_hidden_item_class).show();
				return $item;
			} else {
				debugLog('jquery.dualListPickWidget: Nothing found matching ', item, ' in ', $possibles, ' when trying to restore an Available item -- ', $self);
			}
		},

		_moveActiveAvailableToSelected: function (index) {
			var self = this, $self = this.element;
			var $selected = $self.find('.' + self._available_list_class + ' .' + self._available_item_class + '.' + self._active_item_class);

			for (var i=0; i<$selected.length; i++) {
				var $this = $selected.eq(i);

				if (typeof index === 'number') {
					self._addSelectedItem($this.data(self._row_data_key), { index: index + i });
				} else {
					self._addSelectedItem($this.data(self._row_data_key));
				}

				if (!self.options.allow_multiple_selections) {
					self._removeAvailableElement($this);
				}
			}
		},

		_moveActiveSelectedToAvailable: function () {
			var self = this, $self = this.element;
			var $selected_all = $self.find('.' + self._selected_list_class + ' .' + self._selected_item_class);
			var $selected_active = $selected_all.filter('.' + self._active_item_class);
			for (var i=0; i<$selected_active.length; i++) {
				var $item = $selected_active.eq(i), item = $item.data(self._row_data_key), index = $selected_all.index($item);

				if (!self.options.allow_multiple_selections) {
					var $avail_item = self._restoreAvailableItem(item);
					if ($avail_item) {
						self._bringElementIntoView($avail_item);
					}
				}

				$item.remove();

				// The "- i" is there because every time we remove an item, the ones later move up one in index.
				self._afterRemove(item, index - i);
			}
		},

		_moveActiveSelectedUp: function () {
			var self = this, $self = this.element;
			var $selected_all = $self.find('.' + self._selected_list_class + ' .' + self._selected_item_class), $selected_active = $selected_all.filter('.' + self._active_item_class), selected_index = $selected_all.index($selected_active.eq(0)), i, active_indices = [];

			if (!$selected_active[0] || (selected_index === 0 && !$selected_active[1])) { return; }

			for (i=0; i<$selected_active.length; i++) {
				active_indices.push($selected_all.index($selected_active.eq(i)));
			}

			if (selected_index > 0) {
				selected_index--;
				$selected_active.insertBefore($selected_all.eq(selected_index));
			} else {
				$selected_active.slice(1).insertAfter($selected_active.eq(0));
			}

			for (i=0; i<active_indices.length; i++) {
				self._afterMove($selected_active.eq(i).data(self._row_data_key), active_indices[i], selected_index++);
			}

			self._bringElementIntoView($selected_active.eq(0));
		},



		_moveActiveSelectedDown: function () {
			var self = this, $self = this.element;
			var $selected_all = $self.find('.' + self._selected_list_class + ' .' + self._selected_item_class), $selected_active = $selected_all.filter('.' + self._active_item_class), selected_index = $selected_all.index($selected_active.last()), i, active_indices = [];

			if (!$selected_active[0] || (selected_index === $selected_all.length - 1 && !$selected_active[1])) { return; }

			for (i=0; i<$selected_active.length; i++) {
				active_indices.push($selected_all.index($selected_active.eq(i)));
			}

			if (selected_index < $selected_all.length - 1) {
				selected_index++;
				$selected_active.insertAfter($selected_all.eq(selected_index));
			} else {
				$selected_active.slice(0,-1).insertBefore($selected_active.last());
			}

			i = active_indices.length;
			while (i--) {
				self._afterMove($selected_active.eq(i).data(self._row_data_key), active_indices[i], selected_index--);
			}

			self._bringElementIntoView($selected_active.last());
		}

	});

	add_widget('dualListPickWidget', dualListPickWidget);
})(jQuery);
