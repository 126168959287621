/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
/*

  saveAndLinkButtonWidget: A button that saves all the page's forms (like a normal save_button), then navigates to a given URL. For use as a page button. Other
                           uses' results not guaranteed.

  OPTIONS:

  {
    confirm_if_no_change: false | true, // If the form is not dirty, confirm before navigating. If false, only the navigation occurs
    confirm_if_invalid:   true | false, // If the form is invalid, confirm before navigating. If false, the form is not saved, and navigation occurs

    // Title/text for confirmation boxes
    no_change_title: '...',
    no_change_text:  '...',
    invalid_title:   '...',
    invalid_text:    '...'
  }

*/

(function( $ ){
	var saveAndLinkButtonWidget = $.extend(true, {}, $.ui.pageSaveButtonWidget.prototype, {
		options: {
			allow_nav_on_dirty: true,

			confirm_if_no_change: false,
			confirm_if_invalid: true,

			flag_if_no_change: false,
			flag_if_invalid: true,

			no_change_title: 'No Changes',
			no_change_text:  'No information was changed. Are you sure you would like to continue?',

			invalid_title: 'Changes will not be saved',
			invalid_text:  'Your changes will not be saved, because some information was incorrect or missing. Would you like to continue without saving, or return to the page and fix the problems?',

			href: '#'
		},

		_afterFormChange: function () {
			// Override this to a null function (from pageSaveButtonWidget), because this should be clickable all the time
		},

		_beforeInit: function () {
			var self = this, $self = this.element;
			$self.addClass('page_save_button_style');
			self._bind($self, 'click', self._clickHandler.bind(self));
		},

		_save: function () {
			var self = this, $self = this.element;
			this.element.closest('.pageWidgetType').getCUIWidget('pageWidget').savePage();
			$self.closest('.pageWidgetType').one('pageSubmitComplete', self._complete.bind(self));
		},

		_complete: function (e, d) {
			var self = this, $self = this.element;
			if (!d.error_count) {
				self._link();
			}
		},

		_link: function () {
			var self = this;

			if (self.options.dialog) {
				self.options.dialog.remove();
				delete self.options.dialog;
			}

			location.href = self.options.href;
		},

		_clickHandler: function (e) {
			var self = this, $self = this.element;
			e.preventDefault();
			if (self.options.dirty && !self.options.invalid) {
				self._save();
			} else if (self.options.dirty) {
				if (self.options.confirm_if_invalid) {
					self.options.dialog = new CUI.Dialog({
						title: self.options.invalid_title,
						text: self.options.invalid_text,
						buttons: ['Continue Without Saving', 'Return to the Page'],
						default_button: 1,
						callbacks: [
							self._link.bind(self),
							CUI.Dialog.cancel
						]
					});
				} else {
					self._link();
				}
			} else {
				if (self.options.confirm_if_no_change) {
					self.options.dialog = new CUI.Dialog({
						title: self.options.no_change_title,
						text: self.options.no_change_text,
						buttons: ['Continue Without Saving', 'Return to the Page'],
						default_button: 1,
						callbacks: [
							self._link.bind(self),
							CUI.Dialog.cancel
						]
					});
				} else {
					self._link();
				}
			}
		}
	});

	add_widget('saveAndLinkButtonWidget', saveAndLinkButtonWidget);
})(jQuery);
