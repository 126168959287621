/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){
	var formSubmitButtonWidget = $.extend(true, {}, $.ui.widget.prototype, {
		_beforeInit: function () {
			var self = this, $self = this.element;
			self._bind($self, 'click', self._findAndSubmitForm.bind(self));
		},

		_findAndSubmitForm: function () {
			var $self = this.element;
			$self.closest('.formWidget').getCUIWidget('formWidget').submit();
		}
	});
	add_widget('formSubmitButtonWidget', formSubmitButtonWidget);
})(jQuery);
