register_screen('active_calls', {
    elements: [
	{ entity_template: 'page_title', title: 'Active Calls' },
	{
	    entity_template: 'page_buttons',
	    buttons: [
		{ type: 'help', help_id: 'activecalls' }
	    ]
	},
	{ entity_template: 'page_content', elements: [
	    {
		entity_template: 'active_calls_data_table',
		live_table : 'live_calls',
		dirty_tracking: false,
		live_table_key: '',
		sortable: false,
		extra_row_data: [
		    'a_uuid',
		    'b_uuid',
		    'bridge_start',
		    'a_bbx_user_id',
		    'b_bbx_user_id',
		    'a_callstate',
		    'b_callstate'
		],
		oLanguage: {
		    sZeroRecords: 'There are currently no active calls'
		},
		columns: [
		    {
			header: 'Time',
			width: '60px',
			sortable: false,
			view_element: {
			    entity_template: 'time_duration',
			    name: 'bridge_start',
			    input_multiplier: 1
			}
		    },
		    {
			header: 'Caller Name',
			column: 'cid_name',
			sortable: false,
			format: {
			    field: '@cid_name',
			    formatter: 'cid_case'
			}
		    },
		    {
			header: 'Caller Number',
			column: 'cid_number',
			sortable: false,
			format: {
			    field: '@cid_number',
			    formatter: 'phone_number'
			}
		    },
		    {
			header: 'Destination Name',
			column: 'ceid_name',
			sortable: false,
			format: {
			    field: '@ceid_name',
			    formatter: 'cid_case'
			}
		    },
		    {
			header: 'Destination Number',
			column: 'ceid_number',
			sortable: false,
			format: {
			    field: '@ceid_number',
			    formatter: 'phone_number'
			}
		    },
		    {
			header: 'Actions',
			sortable: false,
			view_element: {
			    entity_template: 'container',
			    elements: [
				{
				    entity_template: 'form_dialog_button',
				    text: 'Listen In',
				    title: 'Listen In',
				    submit_button: 'Start Listening',
				    rest: '/gui/calls/eavesdrop',
				    method: 'POST',
				    include_data_as: { 'a_uuid': 'uuid' },
				    elements: [
					{
					    entity_template: 'input_item',
					    type: 'div',
					    title: 'Enter your extension to begin listening in on a call',
					    input_name: 'listener',
					    input_options: {
						entity_template: 'extension_picker_select',
						type: ['user','phone'],
						primary: 1,
						allow_nothing: false,
						value_key: 'bbx_extension_value'
					    }

					}
				    ]
				},
				{
				    entity_template: 'action_button',
				    text: 'End Call',
				    confirm: {
					text: 'Are you sure you wish to end this call? The call will be hung up immediately, with no warning to either party.',
					title: 'End Call',
					buttons: ['End Call','Cancel']
				    },
				    rest: '/gui/freeswitch/uuid/uuid_kill',
				    rest_params: {
					template: 'json'
				    },
				    method: 'POST',
				    include_data_as: { 'a_uuid': 'uuid' }
				}
			    ]
			}
		    }
		]
	    }
	]}
    ]
});
