/* jshint jquery: true, unused: vars */
/* global CUI, add_widget */
(function( $ ){
	var extensionRowWidget = $.extend(true, {}, $.ui.widget.prototype, {
		options: {
			keys: {
				number: 'bbx_extension_value',
				name: 'show_name',
				type: 'type'
			},

			template_html: '<div class="extension-row-item"><div class="extension-row-type-icon"/><div class="extension-row-name"/><div class="extension-row-details"><span class="extension-row-number" /> <span class="extension-row-type"></span></div></div>',
			type_icons: {
				unknown: 'phone',
				user: 'user',
				group: 'group',
				auto_attendant: 'auto_attendant',
				queue: 'queue',
				conference: 'conference',
				phone: 'phone',
				queue_service: 'agent_access',
				auto_attendant_non_interactive: 'router',
				router: 'router',
				gateway: 'provider',
				valet_parking: 'valet_parking'
			},

			type_names: {
				unknown: 'Unknown Type',
				user: 'Local Extension',
				group: 'Group',
				auto_attendant: 'Automated Attendant',
				queue: 'Queue',
				conference: 'Conference',
				phone: 'Unassigned Phone',
				queue_service: 'Queue Service Extension',
				router: 'Call Router',
				gateway: 'Gateway',
				valet_parking: 'Call Parking'
			}
		},

		_container_class: 'extension-row-item',
		_name_class: 'extension-row-name',
		_number_class: 'extension-row-number',
		_type_class: 'extension-row-type',
		_highlight_class: 'extension-row-highlight',

		_getTypeIcon: function (type) {
			var self = this;
			return (self.options.type_icons[type] || self.options.type_icons.unknown);
		},

		_getTypeName: function (type) {
			var self = this;
			return (self.options.type_names[type] || self.options.type_names.unknown);
		},

		fillData: function (d, from_self) {
			var self = this, $self = this.element, type_name;
			if (d.bbx_extension_id) {
				self.options.data = self.options.data || {};
				$.extend(self.options.data, d);
				$self.find('.' + self._name_class).text(d[self.options.keys.name]);

				$self.find('.' + self._number_class).text(CUI.formatter.doFormat(d, {
					format: {
						field: '@' + self.options.keys.number,
						formatter: 'phone_number'
					}
				}));

				type_name = self._getTypeName(d[self.options.keys.type]);
				$self.find('.' + self._type_class).text(type_name);
				$self.find('.extension-row-type-icon').empty().append(CUI.sprite(self._getTypeIcon(d[self.options.keys.type]), type_name, type_name, false));
			}
		}

	});

	add_widget('extensionRowWidget', extensionRowWidget);
})(jQuery);
